export const rightSideNavList = [
  {
    text: 'General information',
    query: { type: 'name', value: 'generalInformation' },
  },
  {
    text: 'Availability',
    query: { type: 'name', value: 'availability' },
  },
  {
    text: 'Services',
    query: { type: 'name', value: 'services' },
  },
  // {
  //   text: 'Available States',
  //   query: { type: 'name', value: 'available-states' },
  // },
  {
    text: 'CAQH Access',
    query: { type: 'name', value: 'CAQHAccess' },
  },
  {
    text: 'CAQH Information',
    query: { type: 'name', value: 'CAQHInformation' },
  },
  /* {
    text: 'Education and experience',
    query: { type: 'name', value: 'education-and-experience' },
  }, */
  {
    text: 'Board certifications',
    query: { type: 'name', value: 'boardCertifications' },
  },
  {
    text: 'Licenses',
    query: { type: 'name', value: 'licenses' },
  },
  {
    text: 'DEA / CDS Numbers',
    query: { type: 'name', value: 'DEACDSNumbers' },
  },
  {
    text: 'Degrees',
    query: { type: 'name', value: 'degrees' },
  },
  {
    text: 'Malpractice coverage',
    query: { type: 'name', value: 'malpracticeCoverage' },
  },
];

export const GeneralInfoSections = {
  GENERAL: 'general-information',
  APPROACH: 'approach',
  AVAILABLE_STATES: 'available-states',
};

export const EducationExperienceSections = {
  CLINIC: 'clinic-affiliation',
  PAST_EMPLOYMENT: 'past-employment',
  EDUCATION: 'education',
  CREDENTIALS: 'credentials',
  CERTIFICATIONS: 'certifications',
};

export const educationExperienceSections = [
  {
    id: EducationExperienceSections.CLINIC,
    title: 'Clinic affiliation',
  },
  {
    id: EducationExperienceSections.PAST_EMPLOYMENT,
    title: 'Past employment',
  },
  {
    id: EducationExperienceSections.EDUCATION,
    title: 'Education',
  },
  {
    id: EducationExperienceSections.CREDENTIALS,
    title: 'Credentials',
  },
  {
    id: EducationExperienceSections.CERTIFICATIONS,
    title: 'Certifications',
  },
];

export const certificationsMock = [
  { label: 'PMHNP-BC', value: 'PMHNP-BC' },
  { label: 'ABC', value: 'ABC' },
  { label: 'APRN', value: 'APRN' },
  { label: 'BCD', value: 'BCD' },
  { label: 'DEF', value: 'DEF' },
  { label: 'GHK', value: 'GHK' },
  { label: 'LMN', value: 'LMN' },
  { label: 'OPR', value: 'OPR' },
  { label: 'STU', value: 'STU' },
  { label: 'VWX', value: 'VWX' },
];

export const availableStatesMock = [
  { label: 'Missouri', value: 'Missouri' },
  { label: 'Massachusetts', value: 'Massachusetts' },
  { label: 'Virginia', value: 'Virginia' },
  { label: 'PMHNP-BC', value: 'PMHNP-BC' },
];

export const credentialsMock = [
  { label: 'ABC', value: 'ABC' },
  { label: 'BCD', value: 'BCD' },
  { label: 'DEF', value: 'DEF' },
  { label: 'GHK', value: 'GHK' },
  { label: 'LMN', value: 'LMN' },
  { label: 'OPR', value: 'OPR' },
  { label: 'STU', value: 'STU' },
  { label: 'VWX', value: 'VWX' },
];

export const helperTabs = [
  { label: 'Related information', tabKey: 'information' },
  { label: 'General information', tabKey: 'general-information' },
  { label: 'Ask AI', tabKey: 'ask_ai' },
  { label: 'Contact us', tabKey: 'contact_us' },
];
