import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import { useFormik } from 'formik';
import clsx from 'clsx';
import { Drawer, drawerType } from '../../../../../../packages/ui/organisms/drawer';
import { btnType, Button, fontWeight, IconButton, Text } from '../../../../../../packages';
import { SubstanceUseBottomCard } from '../substance-use-card/SubstanceUseBottomCard';
import SubstanceForm from '../substance-form';
import { SubstanceUseEditSchema } from './SubstanceUseEditing.schema';
import { useStyles } from './SubstanceUseEditing.styles';
import { substanceDefaultValues } from '../SubstanceUse.constants';

const SubstanceUseEditing = ({
  isOpen,
  isNoDrawer,
  onClose,
  item,
  error,
  lookupData,
  isSaving,
  onSubmit,
}) => {
  const classes = useStyles();
  const form = useFormik({
    initialValues: { ...substanceDefaultValues },
    validationSchema: SubstanceUseEditSchema,
    onSubmit: payload => {
      onSubmit && onSubmit(payload);
    },
  });

  useEffect(() => {
    if (item) {
      const {
        tagMetaData: { substanceUse },
        priority,
        ...others
      } = item;
      const newObj = {
        ...others,
        status: priority.name,
        method: substanceUse.methodOfUse,
        lastUse: substanceUse.lastUse,
        notes: item.notes,
        dose: {
          amount: substanceUse.dose || 1,
          unit: substanceUse.unitOfUse || 'MG',
        },
        refillable: substanceUse.refillable,
        frequency: substanceUse.currentFrequencyOfUse,
        duration: substanceUse.howLongUsingThisLevel,
        refillTimes: {
          amount: 1,
          unit: 'TIMES',
        },
      };
      Object.keys(newObj).forEach(async key => {
        await form.setFieldValue(key, newObj[key]);
      });
    }
  }, [item]);

  useEffect(() => {
    if (!isOpen) {
      form.handleReset({ ...substanceDefaultValues });
      form.setErrors({});
    }
  }, [isOpen]);

  const renderContent = () => (
    <Box className={classes.wrapper}>
      <Box className={clsx(classes.header, { [classes.headerNoDrawer]: isNoDrawer })}>
        <Box>
          <Text weight={fontWeight.BOLD} className={classes.headTitle}>
            Update substance use - {item.name}
          </Text>
          <Text weight={fontWeight.BOLD} className={classes.headSubtitle}>
            {item.description}
          </Text>
        </Box>
        <IconButton icon="close" className={classes.closeBtn} onClick={onClose} />
      </Box>
      <Box className={clsx(classes.body, { [classes.bodyNoDrawer]: isNoDrawer })}>
        <Box className={classes.headWrap}>
          <SubstanceUseBottomCard item={item} showBadge lookupData={lookupData} />
        </Box>
        <form className={classes.form} onSubmit={form.handleSubmit}>
          <SubstanceForm form={form} error={error} lookupData={lookupData} substance={item} />
        </form>
      </Box>
      <Box className={classes.footer}>
        <Button variant={btnType.TEXT} onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={form.handleSubmit} disabled={isSaving}>
          {isSaving ? 'Saving...' : 'Update'}
        </Button>
      </Box>
    </Box>
  );

  if (isNoDrawer) {
    return renderContent();
  }

  return (
    <Drawer open={isOpen} onClose={onClose} variant={drawerType.NORMAL} className={classes.drawer}>
      {item && renderContent()}
    </Drawer>
  );
};

export { SubstanceUseEditing };
