import { FC } from 'react';
import { Box, Divider, Modal } from '@mui/material';

import { Heading } from '../../../../../packages/ui/atoms/typography';
import { Icons } from '../../../../../packages/icons';
import { colors } from '../../../../../packages/colors';

import { useStyles } from './KeyboardShortcutsModal.styles';
import { KeyboardShortcutItem } from './KeyboardShortcutItem';

const KeyboardShortcutsModal = ({ open, onClose, showHideKeys }) => {
  const classes = useStyles();

  return (
    <Modal open={open} onClose={onClose}>
      <Box className={classes.modal}>
        <Box className={classes.modalContent}>
          <Box className={classes.content}>
            <Box>
              <Heading className={classes.title}>Keyboard shortcuts</Heading>
              <Heading className={classes.subtitle}>GENERAL</Heading>
              <KeyboardShortcutItem
                text="Next Question"
                buttonTexts={[{ name: 'space', type: 'text' }]}
                shortcutType="GENERAL"
              />
              <Divider />
              <KeyboardShortcutItem
                text="Previous Question"
                buttonTexts={[
                  { name: 'shift', type: 'text' },
                  { name: 'space', type: 'text' },
                ]}
                shortcutType="GENERAL"
              />
              <Divider />
              {showHideKeys && (
                <>
                  <KeyboardShortcutItem
                    text="Show & hide questions"
                    buttonTexts={[
                      { name: 'shift', type: 'text' },
                      { name: 'backspace', type: 'text' },
                    ]}
                    shortcutType="GENERAL"
                  />
                  <Divider />
                  <KeyboardShortcutItem
                    text="Show & hide answer options"
                    buttonTexts={[
                      { name: 'shift', type: 'text' },
                      { name: 'enter', type: 'text' },
                    ]}
                    shortcutType="GENERAL"
                  />
                  <Divider />
                </>
              )}
              <Heading className={classes.subtitle2}>NAVIGATION</Heading>
              <KeyboardShortcutItem
                text="Navigate"
                buttonTexts={[{ name: 'arrow-up' }, { name: 'arrow-down' }]}
                shortcutType="NAVIGATION"
              />
              <Divider />
              <KeyboardShortcutItem
                text="Select option"
                buttonTexts={[{ name: 'arrow-right' }]}
                shortcutType="NAVIGATION"
              />
              <Divider />
              <KeyboardShortcutItem
                text="Deselect option"
                buttonTexts={[{ name: 'arrow-left' }]}
                shortcutType="NAVIGATION"
              />
              <Icons glyph="close" onClick={onClose} className={classes.closeBtn} color={colors.neutral500} />
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export { KeyboardShortcutsModal };
