import { Box } from '@mui/material';
import { btnType, Button } from '../../../packages';
import { colors } from '../../../packages';

import Heading from '../../../components/v2/Heading';
import { HeadingLevel, HeadingWeight } from '../Heading/index.types';
import Typography from '../Typography';
import { typography } from '../Typography/index.constant';
import dayjs from 'dayjs';

import { useStyles } from './index.styles';

export const AvgScoreType = {
  PRIMARY: 'PRIMARY',
  SECONDARY: 'SECONDARY',
};

const AverageFrame = ({ avgScore, onClick, variant = AvgScoreType.PRIMARY }) => {
  const classes = useStyles();
  const isBetter = avgScore?.point < avgScore?.initialScore;
  return (
    <Box className={classes.scorebox}>
      {variant === AvgScoreType.PRIMARY && (
        <Box className={classes.sbflex}>
          <Box pl={2} py={avgScore?.count ? 0 : 2}>
            <Heading variant={HeadingLevel.SUB} fontWeight={HeadingWeight.SEMI_BOLD}>
              {avgScore?.title}
            </Heading>
          </Box>
          {avgScore?.count !== 0 && (
            <Button variant={btnType.TEXT} onClick={onClick}>
              <Typography {...typography.body.s.bold}>View all ({avgScore?.count})</Typography>
            </Button>
          )}
        </Box>
      )}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box
          className={variant === AvgScoreType.PRIMARY && classes.borderTop}
          p={2}
          display="flex"
          gap={1.5}
          alignItems="center"
        >
          <Box className={classes.circle} sx={{ background: avgScore?.color }}>
            <Typography {...typography.body.m.bold}>{Number(avgScore?.point).toFixed(0)}</Typography>
          </Box>
          {avgScore.type !== 'hospitalizations' ? (
            <Box className={classes.cflex}>
              <Typography {...typography.body.s.semibold}>
                <Box component="span" color={isBetter ? '#1E7023' : '#DC2626'}>
                  {Math.abs(avgScore?.point - avgScore?.initialScore).toFixed(0)} points{' '}
                  {isBetter ? 'better' : 'worse'}{' '}
                </Box>
                vs initial
              </Typography>
              <Typography {...typography.body.s.semibold} color={colors.neutral600}>
                avg initial score is{' '}
                <Box component="span" color={colors.destructive500}>
                  {Number(avgScore?.initialScore).toFixed(0)}
                </Box>
              </Typography>
            </Box>
          ) : (
            <Box className={classes.cflex}>
              <Typography {...typography.body.s.semibold}>hospitalization</Typography>
              <Typography {...typography.body.s.medium} color={colors.neutral600}>
                as of {dayjs()?.format('MM/DD/YYYY')}
              </Typography>
            </Box>
          )}
        </Box>
        {variant === AvgScoreType.SECONDARY && avgScore?.count !== 0 && (
          <Button variant={btnType.TEXT} onClick={onClick}>
            <Typography {...typography.body.s.bold}>View all ({avgScore?.count})</Typography>
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default AverageFrame;
