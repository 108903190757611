import { makeStyles } from '@mui/styles';
import { colors } from '../../../colors';

export const useStyles = makeStyles({
  container: {
    boxShadow:
      '0px 4px 8px 0px rgba(0, 0, 0, 0.04), 0px 0px 2px 0px rgba(0, 0, 0, 0.06), 0px 0px 1px 0px rgba(0, 0, 0, 0.04)',
    borderRadius: '8px',
    backgroundColor: 'white',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
    boxShadow:
      '0px 4px 8px 0px rgba(0, 0, 0, 0.04), 0px 0px 2px 0px rgba(0, 0, 0, 0.06), 0px 0px 1px 0px rgba(0, 0, 0, 0.04)',
    borderRadius: '8px 8px 0px 0px',
    padding: '16px 16px 16px 24px',
    borderBottom: `1px solid #E5EBF0`,
  },
  icon: {
    width: '24px',
    height: '24px',
    justifyContent: 'center',
    alignItems: 'center',
  },
  heading: {
    fontSize: '18px',
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    alignSelf: 'stretch',
    gap: '16px',
    padding: '24px ',
    backgroundColor: colors.neutral25,
  },
  question: {
    fontSize: '16px',
  },
  input: {
    height: '48px',
    display: 'flex',
    justifyContent: 'space-between',
    alignSelf: 'stretch',
    borderRadius: '4px',
    border: '1px solid var(--Neutral-200, #D4DDE5)',
    background: 'var(--Generic-White, #FFF)',
  },
  example: {
    fontSize: '14px',
    color: colors.neutral600,
  },
  buttonRow: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
  },
  button: {
    width: '16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '8px 12px',
    color: colors.primary500,
    fontWeight: '700',
    fontSize: '12px',
    backgroundColor: 'white',
    borderRadius: '4px',
    border: `1px solid ${colors.primary500}`,
    '&.Mui-disabled': {
      color: colors.primary500,
      backgroundColor: 'white',
      border: `1px solid ${colors.primary500}`,
    },
  },
  response: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    border: `1px solid ${colors.success500}`,
    borderRadius: '4px',
    backgroundColor: colors.success50,
    padding: '24px',
  },
  responseHeading: {
    fontSize: '16px',
    color: colors.neutral900,
  },
  responseBody: {
    fontSize: '16px',
    color: colors.neutral700,
  },
  error: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    border: `1px solid ${colors.destructive500}`,
    borderRadius: '4px',
    backgroundColor: colors.destructive50,
    padding: '24px',
  },
  errorHeading: {
    fontSize: '16px',
    color: colors.destructive700,
  },
  footer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    padding: '24px ',
    borderTop: `1px solid #E5EBF0`,
  },
  loaderContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: '16px 0px',
    gap: '16px',
    alignItems: 'center',
  },
  loaderText: { color: colors.secondary500, fontSize: '14px', fontWeight: 700 },
  loaderIcon: { width: '32px', height: '32px' },
});
