/* eslint-disable max-len */
// types
// styles
import { useStyles } from './shared/styles';
import cx from 'clsx';

const Keyboard = ({ className, color, onClick }) => {
  const classes = useStyles();
  return (

    <svg
      className={cx({
        [classes.root]: true,
        [className || '']: className,
      })}
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      onClick={onClick}
    >
      <path
        d='M3 17H21V19H3V17ZM3 11H6V14H3V11ZM8 11H11V14H8V11ZM3 5H6V8H3V5ZM13 5H16V8H13V5ZM18 5H21V8H18V5ZM13 11H16V14H13V11ZM18 11H21V14H18V11ZM8 5H11V8H8V5Z'
        fill={color}
      />
    </svg>
  );
};

export default Keyboard;
