import makeStyles from '@mui/styles/makeStyles';
import { colors } from '../../packages';

export const useStyles = makeStyles({
  root: {
    color: colors.white,
  },
  xsmall: {
    width: 40,
    height: 40,
    borderRadius: ({ isRounded }) => (isRounded ? 4 : ''),
    fontSize: 15,
    fontWeight: 600,
    lineHeight: '20px',
  },
  small: {
    width: 48,
    height: 48,
    borderRadius: ({ isRounded }) => (isRounded ? 6 : ''),
    fontSize: 18,
    fontWeight: 500,
    lineHeight: '24px',
  },
});
