import { call, put, takeLatest } from 'redux-saga/effects';

import * as StateService from '../../../services/state/state.service';

import {
  ADD_PAYER,
  ADD_PAYER_FAILED,
  ADD_PAYER_PLAN,
  ADD_PAYER_PLAN_CPT,
  ADD_PAYER_PLAN_CPT_FAILED,
  ADD_PAYER_PLAN_CPT_SUCCESSFUL,
  ADD_PAYER_PLAN_FAILED,
  ADD_PAYER_PLAN_SUCCESSFUL,
  ADD_PAYER_PROVIDER,
  ADD_PAYER_PROVIDER_FAILED,
  ADD_PAYER_PROVIDER_SUCCESSFUL,
  ADD_PAYER_SUCCESSFUL,
  ADD_REGISTERED_ENTITY,
  ADD_REGISTERED_ENTITY_FAILED,
  ADD_REGISTERED_ENTITY_SUCCESSFUL,
  FETCH_CPTS,
  FETCH_CPTS_FAILED,
  FETCH_CPTS_SUCCESSFUL,
  FETCH_ICD10,
  FETCH_ICD10_FAILED,
  FETCH_ICD10_SUCCESSFUL,
  FETCH_PAYER_DETAIL,
  FETCH_PAYER_DETAIL_FAILED,
  FETCH_PAYER_DETAIL_SUCCESSFUL,
  FETCH_PAYER_GROUPS,
  FETCH_PAYER_GROUPS_FAILED,
  FETCH_PAYER_GROUPS_SUCCESSFUL,
  FETCH_PAYERS,
  FETCH_PAYERS_FAILED,
  FETCH_PAYERS_SUCCESSFUL,
  FETCH_STATE_DETAIL,
  FETCH_STATE_DETAIL_FAILED,
  FETCH_STATE_DETAIL_SUCCESSFUL,
  FETCH_STATES,
  FETCH_STATES_FAILED,
  FETCH_STATES_SUCCESSFUL,
  UPDATE_PAYER,
  UPDATE_PAYER_FAILED,
  UPDATE_PAYER_PLAN,
  UPDATE_PAYER_PLAN_CPT,
  UPDATE_PAYER_PLAN_CPT_FAILED,
  UPDATE_PAYER_PLAN_CPT_SUCCESSFUL,
  UPDATE_PAYER_PLAN_FAILED,
  UPDATE_PAYER_PLAN_SUCCESSFUL,
  UPDATE_PAYER_PROVIDER,
  UPDATE_PAYER_PROVIDER_FAILED,
  UPDATE_PAYER_PROVIDER_SUCCESSFUL,
  UPDATE_PAYER_SUCCESSFUL,
  UPDATE_REGISTERED_ENTITY,
  UPDATE_REGISTERED_ENTITY_FAILED,
  UPDATE_REGISTERED_ENTITY_SUCCESSFUL,
  UPDATE_STATE,
  UPDATE_STATE_FAILED,
  UPDATE_STATE_SUCCESSFUL,
} from './actions';
import { LOGIN_SUCCESSFUL } from '../auth/actions';

function* stateFetcher() {
  try {
    const response = yield call(StateService.getStates);

    yield put({
      type: FETCH_STATES_SUCCESSFUL,
      payload: response.data.data,
    });
  } catch (e) {
    const message = e?.errors?.[0]?.endUserMessage || 'Something went wrong';

    yield put({
      type: FETCH_STATES_FAILED,
      payload: { message },
    });
  }
}

function* updateStateHandler(action) {
  const { bodyRequest, pathParams, callback } = action.payload;

  try {
    const { data } = yield call(StateService.updateState, bodyRequest, pathParams);
    yield put({ type: UPDATE_STATE_SUCCESSFUL, payload: data.data });
    yield callback();
  } catch (e) {
    yield put({
      type: UPDATE_STATE_FAILED,
      payload: { message: e.data?.errors?.[0]?.endUserMessage || 'Something went wrong!' },
    });
  }
}

function* stateDetailFetcher(action) {
  try {
    const response = yield call(StateService.getStateById, action.payload);

    yield put({
      type: FETCH_STATE_DETAIL_SUCCESSFUL,
      payload: response.data.data,
    });
  } catch (e) {
    const message = e?.errors?.[0]?.endUserMessage || 'Something went wrong';

    yield put({
      type: FETCH_STATE_DETAIL_FAILED,
      payload: { message },
    });
  }
}

function* addRegisteredEntityHandler(action) {
  const { bodyRequest, callback } = action.payload;

  try {
    const { data } = yield call(StateService.addRegisteredEntity, bodyRequest);
    yield put({ type: ADD_REGISTERED_ENTITY_SUCCESSFUL, payload: data.data });
    yield callback();
  } catch (e) {
    yield put({
      type: ADD_REGISTERED_ENTITY_FAILED,
      payload: { message: e.data?.errors?.[0]?.endUserMessage || 'Something went wrong!' },
    });
  }
}

function* updateRegisteredEntityHandler(action) {
  const { bodyRequest, pathParams, callback } = action.payload;

  try {
    const { data } = yield call(StateService.updateRegisteredEntity, bodyRequest, pathParams);
    yield put({ type: UPDATE_REGISTERED_ENTITY_SUCCESSFUL, payload: data.data });
    yield callback();
  } catch (e) {
    yield put({
      type: UPDATE_REGISTERED_ENTITY_FAILED,
      payload: { message: e.data?.errors?.[0]?.endUserMessage || 'Something went wrong!' },
    });
  }
}

function* payerFetcher(action) {
  try {
    const response = yield call(StateService.getPayers, action.payload);

    yield put({
      type: FETCH_PAYERS_SUCCESSFUL,
      payload: response.data,
    });
  } catch (e) {
    const message = e?.errors?.[0]?.endUserMessage || 'Something went wrong';

    yield put({
      type: FETCH_PAYERS_FAILED,
      payload: { message },
    });
  }
}

function* payerDetailFetcher(action) {
  try {
    const response = yield call(StateService.getPayerById, action.payload);

    yield put({
      type: FETCH_PAYER_DETAIL_SUCCESSFUL,
      payload: response.data.data,
    });
  } catch (e) {
    const message = e?.errors?.[0]?.endUserMessage || 'Something went wrong';

    yield put({
      type: FETCH_PAYER_DETAIL_FAILED,
      payload: { message },
    });
  }
}

function* addPayerHandler(action) {
  const { bodyRequest, pathParams, callback } = action.payload;

  try {
    const { data } = yield call(StateService.addPayer, bodyRequest, pathParams);
    yield put({ type: ADD_PAYER_SUCCESSFUL, payload: data.data });
    yield callback();
  } catch (e) {
    yield put({
      type: ADD_PAYER_FAILED,
      payload: { message: e.data?.errors?.[0]?.endUserMessage || 'Something went wrong!' },
    });
  }
}

function* updatePayerHandler(action) {
  const { bodyRequest, pathParams, callback } = action.payload;

  try {
    const { data } = yield call(StateService.updatePayer, bodyRequest, pathParams);
    yield put({ type: UPDATE_PAYER_SUCCESSFUL, payload: data.data });
    yield callback();
  } catch (e) {
    yield put({
      type: UPDATE_PAYER_FAILED,
      payload: { message: e.data?.errors?.[0]?.endUserMessage || 'Something went wrong!' },
    });
  }
}

function* addPayerPlanHandler(action) {
  const { bodyRequest, pathParams, callback } = action.payload;

  try {
    const { data } = yield call(StateService.addPayerPlan, bodyRequest, pathParams);
    yield put({ type: ADD_PAYER_PLAN_SUCCESSFUL, payload: data.data });
    yield callback();
  } catch (e) {
    yield put({
      type: ADD_PAYER_PLAN_FAILED,
      payload: { message: e.data?.errors?.[0]?.endUserMessage || 'Something went wrong!' },
    });
  }
}

function* updatePayerPlanHandler(action) {
  const { bodyRequest, pathParams, callback } = action.payload;

  try {
    const { data } = yield call(StateService.updatePayerPlan, bodyRequest, pathParams);
    yield put({ type: UPDATE_PAYER_PLAN_SUCCESSFUL, payload: data.data });
    yield callback();
  } catch (e) {
    yield put({
      type: UPDATE_PAYER_PLAN_FAILED,
      payload: { message: e.data?.errors?.[0]?.endUserMessage || 'Something went wrong!' },
    });
  }
}

function* addPayerProviderHandler(action) {
  const { bodyRequest, pathParams, callback } = action.payload;

  try {
    const { data } = yield call(StateService.addPayerProvider, bodyRequest, pathParams);
    yield put({ type: ADD_PAYER_PROVIDER_SUCCESSFUL, payload: data.data });
    yield callback();
  } catch (e) {
    yield put({
      type: ADD_PAYER_PROVIDER_FAILED,
      payload: { message: e.data?.errors?.[0]?.endUserMessage || 'Something went wrong!' },
    });
  }
}

function* updatePayerProviderHandler(action) {
  const { bodyRequest, pathParams, callback } = action.payload;

  try {
    const { data } = yield call(StateService.updatePayerProvider, bodyRequest, pathParams);
    yield put({ type: UPDATE_PAYER_PROVIDER_SUCCESSFUL, payload: data.data });
    yield callback();
  } catch (e) {
    yield put({
      type: UPDATE_PAYER_PROVIDER_FAILED,
      payload: { message: e.data?.errors?.[0]?.endUserMessage || 'Something went wrong!' },
    });
  }
}

function* getPayerGroups() {
  try {
    const response = yield call(StateService.getPayerGroups);

    yield put({
      type: FETCH_PAYER_GROUPS_SUCCESSFUL,
      payload: response.data.data,
    });
  } catch (e) {
    const message = e?.errors?.[0]?.endUserMessage || 'Something went wrong';

    yield put({
      type: FETCH_PAYER_GROUPS_FAILED,
      payload: { message },
    });
  }
}

function* cptFetcher() {
  try {
    const response = yield call(StateService.getCPTs);

    yield put({
      type: FETCH_CPTS_SUCCESSFUL,
      payload: response.data.data,
    });
  } catch (e) {
    const message = e?.errors?.[0]?.endUserMessage || 'Something went wrong';

    yield put({
      type: FETCH_CPTS_FAILED,
      payload: { message },
    });
  }
}

function* icd10sFetcher() {
  try {
    const response = yield call(StateService.getIcd10codes);

    yield put({
      type: FETCH_ICD10_SUCCESSFUL,
      payload: response.data.data,
    });
  } catch (e) {
    const message = e?.errors?.[0]?.endUserMessage || 'Something went wrong';

    yield put({
      type: FETCH_ICD10_FAILED,
      payload: { message },
    });
  }
}

function* addPayerPlanCPTHandler(action) {
  const { bodyRequest, pathParams, callback } = action.payload;

  try {
    const { data } = yield call(StateService.addPayerPlanCPT, bodyRequest, pathParams);
    yield put({ type: ADD_PAYER_PLAN_CPT_SUCCESSFUL, payload: data.data });
    yield callback();
  } catch (e) {
    yield put({
      type: ADD_PAYER_PLAN_CPT_FAILED,
      payload: { message: e.data?.errors?.[0]?.endUserMessage || 'Something went wrong!' },
    });
  }
}

function* updatePayerPlanCPTHandler(action) {
  const { bodyRequest, pathParams, callback } = action.payload;

  try {
    const { data } = yield call(StateService.updatePayerPlanCPT, bodyRequest, pathParams);
    yield put({ type: UPDATE_PAYER_PLAN_CPT_SUCCESSFUL, payload: data.data });
    yield callback();
  } catch (e) {
    yield put({
      type: UPDATE_PAYER_PLAN_CPT_FAILED,
      payload: { message: e.data?.errors?.[0]?.endUserMessage || 'Something went wrong!' },
    });
  }
}

function* stateSaga() {
  yield takeLatest([FETCH_STATES, LOGIN_SUCCESSFUL], stateFetcher);
  yield takeLatest(UPDATE_STATE, updateStateHandler);
  yield takeLatest(FETCH_STATE_DETAIL, stateDetailFetcher);
  yield takeLatest(ADD_REGISTERED_ENTITY, addRegisteredEntityHandler);
  yield takeLatest(UPDATE_REGISTERED_ENTITY, updateRegisteredEntityHandler);
  yield takeLatest(FETCH_PAYERS, payerFetcher);
  yield takeLatest(FETCH_PAYER_DETAIL, payerDetailFetcher);
  yield takeLatest(ADD_PAYER, addPayerHandler);
  yield takeLatest(UPDATE_PAYER, updatePayerHandler);
  yield takeLatest(ADD_PAYER_PLAN, addPayerPlanHandler);
  yield takeLatest(UPDATE_PAYER_PLAN, updatePayerPlanHandler);
  yield takeLatest(ADD_PAYER_PROVIDER, addPayerProviderHandler);
  yield takeLatest(UPDATE_PAYER_PROVIDER, updatePayerProviderHandler);
  yield takeLatest(FETCH_CPTS, cptFetcher);
  yield takeLatest(FETCH_ICD10, icd10sFetcher);
  yield takeLatest(ADD_PAYER_PLAN_CPT, addPayerPlanCPTHandler);
  yield takeLatest(UPDATE_PAYER_PLAN_CPT, updatePayerPlanCPTHandler);
  yield takeLatest(FETCH_PAYER_GROUPS, getPayerGroups);
}

export default stateSaga;
