import { USER_LOGOUT } from '../auth/actions';
import {
  FETCH_APPOINTMENT_REPORT,
  FETCH_APPOINTMENT_REPORT_FAILED,
  FETCH_APPOINTMENT_REPORT_SUCCESSFUL,
  FETCH_DAILY_SCHEDULE,
  FETCH_DAILY_SCHEDULE_FAILED,
  FETCH_DAILY_SCHEDULE_SUCCESSFUL,
  FETCH_LEVEL_OF_ENGAGEMENTS_SUCCESSFUL,
  FETCH_LOE_DETAILS_SUCCESSFUL,
  FETCH_LOE_STATUS_DETAILS,
  FETCH_LOE_STATUS_DETAILS_FAILED,
  FETCH_LOE_STATUS_DETAILS_SUCCESSFUL,
  FETCH_PROVIDER_SCHEDULE,
  FETCH_PROVIDER_SCHEDULE_FAILED,
  FETCH_PROVIDER_SCHEDULE_FAILED_PUBLIC,
  FETCH_PROVIDER_SCHEDULE_PUBLIC,
  FETCH_PROVIDER_SCHEDULE_SUCCESSFUL,
  FETCH_PROVIDER_SCHEDULE_SUCCESSFUL_PUBLIC,
  FETCH_PROVIDER_SERVICES1,
  FETCH_PROVIDER_SERVICES1_FAILED,
  FETCH_PROVIDER_SERVICES1_SUCCESSFUL,
  FETCH_SYSTEM_SERVICES,
  FETCH_SYSTEM_SERVICES_FAILED,
  FETCH_SYSTEM_SERVICES_SUCCESSFUL,
  UPDATE_PROVIDER_SCHEDULE,
  UPDATE_PROVIDER_SCHEDULE_FAILED,
  UPDATE_PROVIDER_SCHEDULE_SUCCESSFUL,
} from './actions';

export const DEFAULT = {
  providerSchedule: {
    isLoading: false,
    isSaving: false,
    errorMsg: '',
    schedule: null,
  },
  dailySchedule: {
    isLoading: false,
    isSaving: false,
    errorMsg: '',
    schedule: null,
  },
  providerServices: {
    isLoading: false,
    errorMsg: '',
    services: null,
  },
  systemServices: {
    isLoading: false,
    errorMsg: '',
    services: [],
  },
  appointmentReport: {
    isLoading: false,
    errorMsg: '',
    appointmentRecord: null,
    currentPage: 0,
    totalPages: 0,
    totalRecords: 0,
    subscriptionAppointments: 0,
    insuranceAppointments: 0,
    cashAppointments: 0,
    totalCash: 0,
    totalCard: 0,
    totalWallet: 0,
  },
  loeGraphDetails: [],
  loeReport: {
    isLoading: false,
    errorMsg: '',
    levelOfEngagementStats: {
      levelOfEngagementStatsCount: [],
      totalUniqueStatuses: 0,
    },
    outReachNeededStats: {
      outreachNeededCount: 0,
      percentageOutreachNeeded: 0,
      totalPatients: 0,
    },
  },
  loeStatusDetails: {
    memberDetails: [],
    outReachNeededStats: {
      outreachNeededCount: 0,
      percentageOutreachNeeded: 0,
      totalPatients: 0,
    },
    isLoading: false,
  },
};

export default function scheduleReducer(state = DEFAULT, action) {
  const { type, payload } = action;

  switch (type) {
    case FETCH_PROVIDER_SCHEDULE: {
      return {
        ...state,
        providerSchedule: {
          ...state.providerSchedule,
          isLoading: true,
          errorMsg: null,
        },
      };
    }
    case FETCH_PROVIDER_SCHEDULE_SUCCESSFUL: {
      return {
        ...state,
        providerSchedule: {
          ...state.providerSchedule,
          isLoading: false,
          schedule: payload.schedule,
        },
      };
    }
    case FETCH_DAILY_SCHEDULE: {
      return {
        ...state,
        dailySchedule: {
          ...state.dailySchedule,
          isLoading: true,
          errorMsg: null,
        },
      };
    }
    case FETCH_DAILY_SCHEDULE_SUCCESSFUL: {
      return {
        ...state,
        dailySchedule: {
          ...state.dailySchedule,
          isLoading: false,
          schedule: payload.schedule,
        },
      };
    }
    case FETCH_PROVIDER_SCHEDULE_PUBLIC: {
      return {
        ...state,
        providerSchedule: {
          ...state.providerSchedule,
          isLoading: true,
          errorMsg: null,
        },
      };
    }
    case FETCH_PROVIDER_SCHEDULE_SUCCESSFUL_PUBLIC: {
      return {
        ...state,
        providerSchedule: {
          ...state.providerSchedule,
          isLoading: false,
          schedule: payload.schedule,
        },
      };
    }
    case USER_LOGOUT: {
      return {
        ...state,
        providerSchedule: {
          ...state.providerSchedule,
          isLoading: false,
          errorMsg: payload?.message || null,
          schedule: null,
        },
        dailySchedule: {
          ...state.dailySchedule,
          isLoading: false,
          errorMsg: payload?.message || null,
          schedule: null,
        },
      };
    }
    case FETCH_PROVIDER_SCHEDULE_FAILED:
    case FETCH_PROVIDER_SCHEDULE_FAILED_PUBLIC: {
      return {
        ...state,
        providerSchedule: {
          ...state.providerSchedule,
          isLoading: false,
          errorMsg: payload?.message || null,
          schedule: null,
        },
      };
    }
    case FETCH_DAILY_SCHEDULE_FAILED: {
      return {
        ...state,
        dailySchedule: {
          ...state.dailySchedule,
          isLoading: false,
          errorMsg: payload?.message || null,
          schedule: null,
        },
      };
    }
    case UPDATE_PROVIDER_SCHEDULE: {
      return {
        ...state,
        providerSchedule: {
          ...state.providerSchedule,
          isSaving: true,
          errorMsg: null,
        },
      };
    }
    case UPDATE_PROVIDER_SCHEDULE_SUCCESSFUL: {
      return {
        ...state,
        providerSchedule: {
          ...state.providerSchedule,
          isSaving: false,
        },
      };
    }
    case UPDATE_PROVIDER_SCHEDULE_FAILED: {
      return {
        ...state,
        providerSchedule: {
          ...state.providerSchedule,
          isSaving: false,
          errorMsg: payload?.message || null,
        },
      };
    }
    case FETCH_PROVIDER_SERVICES1: {
      return {
        ...state,
        providerServices: {
          ...state.providerServices,
          isLoading: true,
          errorMsg: null,
        },
      };
    }
    case FETCH_PROVIDER_SERVICES1_SUCCESSFUL: {
      return {
        ...state,
        providerServices: {
          ...state.providerServices,
          isLoading: false,
          services: payload.services,
        },
      };
    }
    case FETCH_PROVIDER_SERVICES1_FAILED: {
      return {
        ...state,
        providerServices: {
          ...state.providerServices,
          isLoading: false,
          errorMsg: payload?.message || null,
        },
      };
    }
    case FETCH_SYSTEM_SERVICES: {
      return {
        ...state,
        systemServices: {
          ...state.systemServices,
          isLoading: true,
          errorMsg: null,
        },
      };
    }
    case FETCH_SYSTEM_SERVICES_SUCCESSFUL: {
      return {
        ...state,
        systemServices: {
          ...state.systemServices,
          isLoading: false,
          services: payload.services,
        },
      };
    }
    case FETCH_SYSTEM_SERVICES_FAILED: {
      return {
        ...state,
        systemServices: {
          ...state.systemServices,
          isLoading: false,
          errorMsg: payload?.message || null,
          services: null,
        },
      };
    }
    case FETCH_APPOINTMENT_REPORT: {
      return {
        ...state,
        appointmentReport: {
          ...state.appointmentReport,
          isLoading: true,
          errorMsg: null,
        },
      };
    }
    case FETCH_APPOINTMENT_REPORT_SUCCESSFUL: {
      return {
        ...state,
        appointmentReport: {
          isLoading: false,
          errorMsg: null,
          ...payload,
        },
      };
    }
    case FETCH_APPOINTMENT_REPORT_FAILED: {
      return {
        ...state,
        appointmentReport: {
          ...state.appointmentReport,
          isLoading: false,
          errorMsg: payload?.message || null,
        },
      };
    }
    case FETCH_LEVEL_OF_ENGAGEMENTS_SUCCESSFUL: {
      return {
        ...state,
        loeReport: {
          ...state.loeReport,
          levelOfEngagementStats: payload.levelOfEngagementStats,
          outReachNeededStats: payload.outReachNeededStats,
          isLoading: false,
          errorMsg: payload?.message || null,
        },
      };
    }
    case FETCH_LOE_DETAILS_SUCCESSFUL: {
      return {
        ...state,
        loeGraphDetails: payload,
      };
    }
    case FETCH_LOE_STATUS_DETAILS: {
      return {
        ...state,
        loeStatusDetails: {
          ...state.loeStatusDetails,
          isLoading: true,
        },
      };
    }
    case FETCH_LOE_STATUS_DETAILS_FAILED: {
      return {
        ...state,
        loeStatusDetails: {
          ...state.loeStatusDetails,
          isLoading: false,
          memberDetails: [],
          outReachNeededStats: {
            outreachNeededCount: 0,
            percentageOutreachNeeded: 0,
            totalPatients: 0,
          },
        },
      };
    }
    case FETCH_LOE_STATUS_DETAILS_SUCCESSFUL: {
      return {
        ...state,
        loeStatusDetails: {
          ...payload,
          isLoading: false,
        },
      };
    }
    default: {
      return state;
    }
  }
}
