import { makeStyles } from '@mui/styles';
// colors
import { colors } from '../../../colors';

export const useStyles = makeStyles({
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  status: {
    borderRadius: 100,
    minWidth: 'auto',
    fontSize: 14,
    height: 30,
    textTransform: 'capitalize',
  },
  root: ({ type }) => ({
    cursor: 'pointer',
    borderRadius: 8,
    width: '100%',
    boxShadow: `0px 0px 6px ${colors.neutral100}`,
    backgroundColor: colors.white,
    border: `1px solid ${colors[`${type}500`] || colors.neutral100}`,
  }),
  topSection: {
    padding: '32px 32px 24px 32px',
    borderBottom: `1px solid ${colors.neutral100}`,
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
  },
  iconWrapper: ({ type }) => ({
    height: 40,
    width: 40,
    borderRadius: 4,
    border: '1px solid',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center  ',
    backgroundColor: colors[`${type === 'normal' ? 'purple' : type}50`],
    borderColor: colors[`${type === 'normal' ? 'purple' : type}200`],
    '&.RELEASE_OF_INFORMATION': {
      backgroundColor: colors.indigo50,
      borderColor: colors.indigo200,
      '& svg path': {
        fill: colors.indigo500,
      },
    },
    '&.LAB_REQUEST': {
      backgroundColor: colors.green50,
      borderColor: colors.green200,
      '& svg path': {
        fill: colors.green500,
      },
    },
    '&.CONTACT': {
      backgroundColor: colors.rose50,
      borderColor: colors.rose200,
      '& svg path': {
        fill: colors.rose500,
      },
    },
    '&.PHARMACY_ASSISTANCE': {
      backgroundColor: colors.cyan50,
      borderColor: colors.cyan100,
      '& svg path': {
        fill: colors.cyan500,
      },
    },
    '&.REFERRAL_OUT': {
      backgroundColor: colors.orange50,
      borderColor: colors.orange200,
      '& svg path': {
        fill: colors.orange500,
      },
    },
    '&.PRIOR_AUTHORIZATION': {
      backgroundColor: colors.primary50,
      borderColor: colors.primary100,
      '& svg path': {
        fill: colors.primary500,
      },
    },
    '&.GENERAL': {
      backgroundColor: colors.purple50,
      borderColor: colors.purple200,
      '& svg path': {
        fill: colors.purple500,
      },
    },
  }),
  title: {
    lineHeight: '24px',
    fontWeight: 600,
    margin: 0,
    textTransform: 'capitalize',
  },
  subTitle: {
    lineHeight: '20px',
    fontWeight: 600,
    color: colors.neutral500,
    textTransform: 'capitalize',
  },
  description: {
    color: colors.neutral600,
    lineHeight: '24px',
    wordBreak: 'break-word',
  },
  topContentWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 24,
  },
});
