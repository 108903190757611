/* eslint-disable max-len */
/* eslint-disable no-underscore-dangle */
import { colors } from '../../../../../../packages';
import { sentenceCase } from 'sentence-case';
import { toDateString } from '../../../../../../utils/CommonUtils';

export const getLast30DaysData = report =>
  report ? report.aggregations.last_30_days.counts.buckets.map(bucket => bucket.doc_count).slice(0, 30) : [];

export const getPast30DaysData = report =>
  report ? report.aggregations.past_30_days.counts.buckets.map(bucket => bucket.doc_count).slice(0, 30) : [];
export const getRecentCompletionsData = report =>
  report
    ? report.aggregations.recent_completion.by_members.buckets
        .map(bucket => ({
          name:
            bucket.last_assigned_date.hits.hits[0]._source.firstName &&
            bucket.last_assigned_date.hits.hits[0]._source.lastName
              ? `${bucket.last_assigned_date.hits.hits[0]._source.firstName} ${bucket.last_assigned_date.hits.hits[0]._source.lastName}`
              : bucket.last_assigned_date.hits.hits[0]._source.fullName,
          total: bucket.doc_count,
          lastUpdated: toDateString(bucket.last_assigned_date.hits.hits[0]._source.lastModifiedDate),
        }))
        .sort((a, b) => new Date(b.lastUpdated).getTime() - new Date(a.lastUpdated).getTime())
    : [];

export const tableColumns = [
  {
    id: 'name',
    label: 'Name',
    sortable: false,
  },
  {
    id: 'total',
    label: 'Total',
    sortable: false,
  },
  {
    id: 'lastUpdated',
    label: 'Date assigned',
    sortable: false,
  },
  {
    id: 'actions',
    label: '',
    textAlign: 'right',
    sortable: false,
  },
];

const chartColors = [
  colors.success500,
  colors.primary500,
  colors.neutral300,
  colors.orange400,
  colors.secondary500,
  colors.cyan500,
  colors.indigo500,
  colors.purple500,
  colors.rose500,
  colors.destructive500,
];
export const getChartInfo = report =>
  report
    ? {
        title: '',
        subtitle: '',
        chartData: report.aggregations.by_status.buckets.map((item, i) => ({
          name: sentenceCase(item.key || ''),
          color: chartColors[i],
          data: [item.doc_count],
        })),
        donutOptTitle: 'Assignments',
        donutOptTotal: report.aggregations.total_assignments.value,
      }
    : null;

export const getEvaluationReportDetailQuery = name => ({
  size: 0,
  query: {
    term: {
      'evaluation_name.keyword': {
        value: name,
      },
    },
  },
  aggs: {
    total_assignments: {
      cardinality: {
        field: 'mongo_id.keyword',
      },
    },
    by_status: {
      terms: {
        field: 'evaluation_status.keyword',
        size: 10,
      },
    },
    last_30_days: {
      filter: {
        bool: {
          must: [
            {
              exists: {
                field: 'evaluation_id',
              },
            },
            {
              range: {
                lastModifiedDate: {
                  gte: 'now-30d/d',
                  lte: 'now',
                },
              },
            },
          ],
        },
      },
      aggs: {
        counts: {
          date_histogram: {
            field: 'lastModifiedDate',
            calendar_interval: '1d',
          },
        },
      },
    },
    past_30_days: {
      filter: {
        bool: {
          must: [
            {
              exists: {
                field: 'evaluation_id',
              },
            },
            {
              range: {
                lastModifiedDate: {
                  gte: 'now-60d/d',
                  lt: 'now-30d/d',
                },
              },
            },
          ],
        },
      },
      aggs: {
        counts: {
          date_histogram: {
            field: 'lastModifiedDate',
            calendar_interval: '1d',
          },
        },
      },
    },
    recent_completion: {
      filter: {
        term: {
          'evaluation_status.keyword': 'COMPLETED',
        },
      },
      aggs: {
        by_members: {
          terms: {
            field: 'member_name.keyword',
            size: 10000,
          },
          aggs: {
            last_assigned_date: {
              top_hits: {
                _source: ['fullName', 'lastModifiedDate', 'firstName', 'lastName'],
                sort: [{ lastModifiedDate: { order: 'desc' } }],
                size: 1,
              },
            },
          },
        },
      },
    },
  },
});
