import { colors } from '../../../../../../packages';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  drawer: {
    '& .MuiPaper-root': {
      maxWidth: '640px !important',
    },
    '& .drawerBody': {
      padding: 0,
    },
  },
  header: {
    height: 80,
    padding: 24,
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${colors.neutral100}`,
  },
  headerTitle: {
    fontSize: 24,
    lineHeight: '32px',
    margin: 0,
  },
  closeBtn: {
    width: 32,
    height: 32,
    minWidth: 32,
    '& svg': {
      width: 20,
    },
  },
  content: {
    flex: 1,
    height: 'calc(100% - 80px)',
  },
  items: {
    flex: 1,
    overflowY: 'auto',
    height: 'calc(100% - 80px)',
  },
  item: {
    display: 'flex',
    gap: 16,
    padding: 26,
    borderBottom: `1px solid ${colors.neutral100}`,
    fontSize: 14,
    lineHeight: '20px',
    fontWeight: 500,
    '&:hover': {
      '& svg path': {
        fill: colors.primary500,
      },
    },
    '& svg': {
      minWidth: 24,
      width: 24,
    },
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 80,
    padding: 16,
    borderTop: `1px solid ${colors.neutral100}`,
    '& button': {
      height: 48,
    },
  },
});
