import { useEffect, useState } from 'react';
import { Dialog } from '@mui/material';
import { useDispatch } from 'react-redux';
import { ProvideEvaluationHead } from '../../../../../../../hooks/useEvaluationHead';
import { useLocation, useParams } from 'react-router-dom';
import EvaluationReviewHeader from '../../../../sign-off-v2/EvaluationReviewHeader';
import AfterAppointment from '../../../../sign-off-v2/after-appointment/AfterAppointment';
import { ShowConfirmModal } from './ShowConfirmModal';
import { updateAppointmentEvaluationStatus } from '../../../../../../../services/conversation/conversation.service';
import { showSnackbar } from '../../../../../../../redux/modules/snackbar';
import history from '../../../../../../../utils/history';
import { AttemptQuestionModal } from '../../../../sign-off-v2/AttemptQuestionModal';

const EvaluationDrawer = ({ open, onClose, evaluations, UpdateOnCompletion }) => {
  const { state } = useLocation();
  const params = useParams();
  const dispatch = useDispatch();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [selectedEvaluation, setSelectedEvaluation] = useState(evaluations ?? state?.evaluationItem);
  const [disabled, setDisabled] = useState(true);
  const [showConfirm, setShowConfirm] = useState(false);
  const [showAttemptQuestionModal, setShowAttemptQuestionModal] = useState(false);

  const reviewNeeded = () => {
    if (state?.needsReview) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (state?.evaluationItem) setOpenDrawer(true);
  }, [state?.evaluationItem]);

  useEffect(() => {
    if (evaluations || state?.evaluationItem) {
      setSelectedEvaluation(evaluations ?? state?.evaluationItem);
    }
  }, [evaluations, state?.evaluationItem]);

  const disableCompleteButton = value => {
    setDisabled(value);
  };

  const onBackToAppointments = () => {
    if (onClose) {
      onClose();
    } else {
      setOpenDrawer(false);
      history.back();
    }
  };

  const updateEvaluationStatus = async () => {
    const appointmentId = params?.appointmentId;
    const evaluationId = evaluations?.id ?? state?.selectedEvaluationId;
    try {
      const payload = {
        evaluationUpdatedStatus: 'COMPLETED',
        evaluationId,
        remarks: 'Evaluation completed',
      };
      const { data } = await updateAppointmentEvaluationStatus(payload, {
        appointmentId,
      });
      if (data?.message === 'Evaluation Status updated successfully') {
        if (UpdateOnCompletion) UpdateOnCompletion();
        void onBackToAppointments();
      }
    } catch (e) {
      console.warn(e?.data?.errors[0]?.endUserMessage);
      if (e?.data?.errors[0]?.endUserMessage === 'Please attempt any question before marking as complete') {
        setShowAttemptQuestionModal(true);
      } else {
        dispatch(
          showSnackbar({
            snackType: 'error',
            snackMessage: e?.data?.errors[0]?.endUserMessage || 'Something went wrong',
          }),
        );
      }
    }
  };

  const completeEvaluation = () => {
    if (UpdateOnCompletion) UpdateOnCompletion();
    if (disabled) {
      setShowConfirm(true);
    } else {
      void updateEvaluationStatus();
    }
  };

  return (
    <Dialog open={open ?? openDrawer} fullScreen>
      <ShowConfirmModal
        open={showConfirm}
        onClose={() => setShowConfirm(false)}
        onConfirm={() => {
          setShowConfirm(false);
          void updateEvaluationStatus();
        }}
      />
      <AttemptQuestionModal
        open={showAttemptQuestionModal}
        onClose={() => setShowAttemptQuestionModal(false)}
        onContinue={() => {
          setShowAttemptQuestionModal(false);
          onBackToAppointments();
        }}
      />
      <EvaluationReviewHeader
        evaluation={selectedEvaluation}
        appointmentId={params?.appointmentId}
        evaluationId={evaluations?.id ?? state?.selectedEvaluationId}
        onClose={onClose}
        onCloseSignOff={() => setOpenDrawer(false)}
        isSession
        completeEvaluation={completeEvaluation}
        UpdateOnCompletion={UpdateOnCompletion}
      />
      <ProvideEvaluationHead>
        <AfterAppointment
          evaluation={selectedEvaluation}
          showRiskFactors={reviewNeeded()}
          disabled={disabled}
          disableCompleteButton={disableCompleteButton}
          completeEvaluation={completeEvaluation}
          UpdateOnCompletion={UpdateOnCompletion}
          showConfirm={showConfirm}
          showAttemptQuestionModal={showAttemptQuestionModal}
        />
      </ProvideEvaluationHead>
    </Dialog>
  );
};

export default EvaluationDrawer;
