export const AvatarSize = {
  XSMALL: 'xsmall',
  SMALL: 'small',
};

export const AvatarShape = {
  ROUNDED: 'rounded', // default
  SQUARE: 'square',
  CIRCULAR: 'circular',
};

export const AvatarType = {
  IMAGE: 'image', // default
  // PLACEHOLDER = 'placeholder',
  INITIALS: 'initials',
};

export const AvatarColor = {
  IMAGE: 'image', // default
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  INDIGO: 'indigo',
  ORANGE: 'orange',
  LIME: 'lime',
  NEUTRAL: 'neutral',
};
