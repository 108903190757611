/* eslint-disable max-len */
import cx from 'clsx';

// types
// styles
import { useStyles } from './shared/styles';

const FileJpeg = ({ className, onClick, ...rest }) => {
  const classes = useStyles();
  return (
    <svg
      className={cx(classes.root, className)}
      onClick={onClick}
      width="24"
      height="32"
      viewBox="0 0 24 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.0149 0H0.816558C0.365581 0 0 0.374286 0 1.10057V31.4286C0 31.6257 0.365581 32 0.816558 32H23.1834C23.6344 32 24 31.6257 24 31.4286V7.416C24 7.01829 23.9481 6.89029 23.8566 6.796L17.362 0.146857C17.27 0.0531429 17.1449 0 17.0149 0Z"
        fill="#E9E9E0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.3023 0.0859375V6.8568H23.9157L17.3023 0.0859375Z"
        fill="#D9D7CA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.4884 8.2466C9.4884 9.68831 8.34645 10.8575 6.93826 10.8575C5.53008 10.8575 4.38812 9.68831 4.38812 8.2466C4.38812 6.80488 5.53008 5.63574 6.93826 5.63574C8.34645 5.63574 9.4884 6.80488 9.4884 8.2466Z"
        fill="#F3D55B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 22.2856H24V15.9999L18.4186 10.5713L12.5581 17.1427L9.49786 14.0096L0 22.2856Z"
        fill="#26B99A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.1834 32.0004H0.816558C0.365581 32.0004 0 31.6261 0 31.1644V22.2861H24V31.1644C24 31.6261 23.6344 32.0004 23.1834 32.0004Z"
        fill="#13A085"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.33077 24.3711V28.8557C8.33077 29.1265 8.28221 29.3545 8.18565 29.5391C8.0891 29.7242 7.95905 29.8722 7.79663 29.9848C7.63365 30.0968 7.44668 30.1751 7.2357 30.2191C7.02417 30.2631 6.80705 30.2854 6.58324 30.2854C6.47105 30.2854 6.33989 30.274 6.19031 30.2505C6.04017 30.2271 5.885 30.1905 5.72482 30.1408C5.56463 30.0911 5.41058 30.0357 5.26324 29.9728C5.11533 29.9105 4.99086 29.8402 4.88928 29.762L5.27831 29.1294C5.3291 29.1654 5.40165 29.2031 5.49598 29.2425C5.58975 29.2814 5.693 29.318 5.80463 29.3517C5.91682 29.386 6.03403 29.4157 6.1557 29.4414C6.27793 29.4677 6.39235 29.4808 6.49951 29.4808C6.76854 29.4808 6.98398 29.4271 7.14417 29.3202C7.30435 29.214 7.38919 29.0328 7.39979 28.7774V24.3711H8.33077Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.6124 30.2466H9.69653V24.4883H11.3146C11.5535 24.4883 11.7901 24.5277 12.024 24.6054C12.2578 24.6837 12.4677 24.8009 12.6536 24.9574C12.8389 25.1134 12.9896 25.302 13.104 25.5237C13.2184 25.7454 13.2753 25.9934 13.2753 26.27C13.2753 26.5614 13.2268 26.8254 13.1302 27.0626C13.0337 27.2997 12.8986 27.4991 12.7261 27.6603C12.5531 27.822 12.3443 27.9466 12.1004 28.0357C11.856 28.1243 11.5864 28.1683 11.2917 28.1683H10.6124V30.2466ZM10.6124 25.1997V27.4809H11.4519C11.5635 27.4809 11.674 27.4614 11.7834 27.422C11.8928 27.3831 11.9933 27.3197 12.0848 27.2306C12.1763 27.142 12.25 27.0186 12.3064 26.8597C12.3622 26.7009 12.3901 26.5043 12.3901 26.27C12.3901 26.1763 12.3773 26.0677 12.3522 25.9454C12.3265 25.8231 12.2746 25.706 12.1959 25.594C12.1166 25.482 12.0061 25.3883 11.8638 25.3129C11.7215 25.2374 11.5328 25.1997 11.299 25.1997H10.6124Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.4186 27.2774V29.5042C18.3014 29.6551 18.1708 29.7785 18.0256 29.8751C17.8805 29.9717 17.7282 30.0511 17.568 30.1134C17.4078 30.1762 17.242 30.2202 17.0718 30.2465C16.901 30.2728 16.7324 30.2854 16.5644 30.2854C16.2284 30.2854 15.9198 30.2231 15.6374 30.098C15.3549 29.9728 15.1077 29.7882 14.8967 29.5431C14.6857 29.298 14.5205 28.9911 14.4011 28.6214C14.2811 28.2511 14.2219 27.8242 14.2219 27.3402C14.2219 26.8557 14.2811 26.43 14.4011 26.0625C14.5205 25.6957 14.6857 25.39 14.8967 25.1448C15.1077 24.8997 15.3561 24.714 15.6407 24.586C15.9259 24.4585 16.2335 24.3945 16.5644 24.3945C16.8697 24.3945 17.1544 24.4465 17.4189 24.5511C17.6835 24.6557 17.9196 24.8117 18.1289 25.0197L17.4954 25.598C17.3732 25.4465 17.2331 25.3374 17.0757 25.27C16.9177 25.202 16.7553 25.1682 16.5873 25.1682C16.3987 25.1682 16.2195 25.2042 16.0493 25.2774C15.8785 25.3505 15.7261 25.474 15.5916 25.6488C15.4565 25.8231 15.351 26.0482 15.2746 26.3242C15.1987 26.6008 15.1574 26.9391 15.1523 27.3402C15.1574 27.7305 15.197 28.0694 15.2707 28.3557C15.3443 28.642 15.4465 28.8762 15.576 29.0585C15.706 29.2414 15.8534 29.3762 16.0186 29.4654C16.1838 29.554 16.3585 29.598 16.5416 29.598C16.5974 29.598 16.6722 29.594 16.7665 29.586C16.8608 29.5785 16.9546 29.5654 17.0489 29.5471C17.1427 29.5288 17.2331 29.5042 17.3196 29.4728C17.4061 29.4414 17.4697 29.3974 17.5105 29.3402V27.918H16.5566V27.2774H18.4186Z"
        fill="white"
      />
    </svg>
  );
};

export default FileJpeg;
