import { Drawer, drawerType } from '../../../../../../packages/ui/organisms/drawer';
import { btnType, Button, colors } from '../../../../../../packages';
import { UploadFile } from '../../../../../../packages/ui/templates/upload-file';
import { Box } from '@mui/material';

import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import uploadImg from '../../../../../../assets/images/upload.png';
import Typography from '../../../../../../components/v2/Typography';
import { typography } from '../../../../../../components/v2/Typography/index.constant';
import Heading from '../../../../../../components/v2/Heading';
import { HeadingLevel } from '../../../../../../components/v2/Heading/index.types';

import { sectionDescriptions, SectionNames, sectionTitles } from '../../index.constants';
import { useStyles } from '../../../index.styles';
import SubmitDrawer from './SubmitDrawer';
import { putProviderData } from '../../../../../../services/datalab/datalab.service';
import { showSnackbar } from '../../../../../../redux/modules/snackbar';
import { getAuth } from '../../../../../../redux/modules/auth/selectors';
import { requiredKeys, sectionDataType, transformData } from '../../../common/utils.constants';
import { MediaManager } from '../../../../../../services/mediaManager/mediaManager';

const ImportDrawer = ({ open, type, onClose, isTabView = false }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { meta } = useSelector(getAuth);

  const [isParsing, setIsParsing] = useState(false);
  const [submitList, setSubmitList] = useState({ filled: [], missing: [], list: [], title: undefined });
  const [showSubmitDrawer, setShowSubmitDrawer] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [importedData, setImportedData] = useState(undefined);

  const title = useMemo(() => `Add ${sectionTitles[type]}`, [type]);
  const description = useMemo(() => sectionDescriptions[type], [type]);

  const runTimer = () => {
    if (open) {
      const timer = setTimeout(() => {
        onClose();
      }, 15000);

      return () => clearTimeout(timer);
    }
    return null;
  };

  const uploadDocument = async file => {
    const res = await MediaManager.uploadMediaFiles(file, `documents/${type}/`, null);
    if (res.success) {
      const bodyRequest = {
        providerId: meta.userId,
        data: {
          bucket: res?.response?.bucket,
          file: res?.response?.key,
          fileType: type,
          fileName: file?.name,
          fileSize: file?.size,
          fileExtension: file?.type,
        },
      };
      setIsParsing(true);
      try {
        const response = await putProviderData(sectionDataType[type], bodyRequest);
        if (response.status === 200) {
          dispatch(
            showSnackbar({
              snackType: 'success',
              snackMessage: `${sectionTitles[type]} data uploaded`,
            }),
          );
          runTimer();
        }
      } catch (err) {
        const msg = err?.data?.error || 'Something went wrong';
        dispatch(
          showSnackbar({
            snackType: 'error',
            snackMessage: msg,
          }),
        );
        runTimer();
      }
    } else {
      console.log('upload failed');
    }
    return null;
  };

  const onAcceptedFiles = files => {
    const file = files[0];
    if (file.size > 5000000) {
      dispatch(
        showSnackbar({
          snackType: 'error',
          snackMessage: 'Only files upto 5mb are allowed',
        }),
      );
    } else {
      void uploadDocument(file);
    }
  };

  const onManualEntry = () => {
    const defaultData = requiredKeys[type].reduce((acc, key) => {
      acc[key] = '';
      return acc;
    }, {});
    if (type === SectionNames.DEA_CDS_REGISTRATION) {
      defaultData.dataType = '';
    }
    const list = transformData(defaultData, type);
    setSubmitList({
      filled: list?.filled || [],
      missing: list?.missing || [],
      list: list?.list || [],
      title: sectionTitles[type],
    });
    setEditMode(true);
    setShowSubmitDrawer(true);
  };

  const onFormSubmit = async (data, edit) => {
    const dataType = sectionDataType[type];
    let fileData = {};
    if (data.fileData) {
      fileData = data.fileData;
    }
    const requestBody = {
      providerId: meta.userId,
      data: {
        ...data,
        state: data.address ? data.address.state : data.state,
        ...fileData,
      },
    };
    delete requestBody.data.fileData;
    try {
      const response = await putProviderData(dataType, requestBody);
      if (response.status === 200) {
        dispatch(
          showSnackbar({
            snackType: 'success',
            snackMessage: `${sectionTitles[type]} data updated`,
          }),
        );
        closeSubmitModal();
        onClose();
      } else {
        setSubmitList({ filled: [], missing: [], list: [], title: undefined });
      }
    } catch (e) {
      console.warn(e);
      if (e.data) {
        dispatch(
          showSnackbar({
            snackType: 'error',
            snackMessage: e.data.error,
          }),
        );
      } else {
        dispatch(
          showSnackbar({
            snackType: 'error',
            snackMessage: e.message || 'Something went wrong',
          }),
        );
      }
    }
  };

  const closeSubmitModal = () => {
    setShowSubmitDrawer(false);
  };

  return (
    <>
      <Drawer open={open} title={title} onClose={onClose} variant={drawerType.FORM} footer={<div />}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
          <Box>
            <Heading variant={HeadingLevel.SUB}>Upload a PDF to analyze</Heading>
            <Typography {...typography.body.s.medium} color={colors.neutral700} mt={1} mb={4}>
              {description}
            </Typography>
          </Box>
          <UploadFile
            onAcceptedFiles={onAcceptedFiles}
            description="PDF, PNG, JPG file up to 5MB"
            fileType="application/pdf,image/png,image/jpg,image/png,image/jpeg"
            iconAltered
          />
          <Button variant={btnType.TEXT} onClick={onManualEntry}>
            <Typography {...typography.body.s.bold}>Enter information manually</Typography>
          </Button>
        </Box>
        {isParsing && (
          <Box className={classes.waitContainer} sx={{ opacity: 0.9 }}>
            <img src={uploadImg} alt="uploading" />
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Typography {...typography.body.s.medium} sx={{ mt: 2 }}>
                Uploading and analyzing your file.
              </Typography>
              <Typography {...typography.body.s.medium}>It usually takes about 15 seconds.</Typography>
            </Box>
          </Box>
        )}
      </Drawer>
      {showSubmitDrawer && (
        <SubmitDrawer
          missedKeys={submitList.missing || []}
          filledKeys={submitList.filled}
          open={showSubmitDrawer}
          title={sectionTitles[type]}
          subTitle={sectionDescriptions[type]}
          list={submitList.list}
          onClose={closeSubmitModal}
          onEdit={() => {
            setEditMode(true);
          }}
          isTabView
          editMode={editMode}
          onFormSubmit={onFormSubmit}
          fileType={type}
          newRecord
        />
      )}
    </>
  );
};

export default ImportDrawer;
