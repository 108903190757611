/* eslint-disable react/destructuring-assignment */
import { useEffect, useMemo, useState } from 'react';

import {
  btnType,
  Button,
  colors,
  fontWeight,
  IconButton,
  Icons,
  Input,
  inputSize,
  inputType,
  Text,
} from '../../../../../../packages';
import { Box, Stack } from '@mui/material';
import { Drawer, drawerType } from '../../../../../../packages/ui/organisms/drawer';

import RecentAssignmentsTable from '../../../../../../pages/admin/plan-items/components/plan-item-detail/recent-assignments-table';
import {
  getElkChatbotDctReport,
  getElkEvaluationDctReport,
} from '../../../../../../services/reporting/reporting.service';
import { DctType } from '../../../../../../redux/modules/reporting/types';

import {
  getDCTDctDetailQuery,
  getRecentAttemptsData,
  getRecentCompletionData,
  tableColumns,
} from './DCTDetail.constants';
import { useStyles } from './DCTDetail.styles';
import { DCT_TABLES } from '../DataCollectionTemplates.constants';

const DCTDetail = ({ open, onClose, dctDetail, dctType }) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [dctReportDetail, setDctReportDetail] = useState(null);
  const getRecentAssignedData = () => {
    switch (dctDetail?.type) {
      case DCT_TABLES.mostCompletedMembers:
      case DCT_TABLES.leastCompletedMembers:
        return getRecentCompletionData(dctReportDetail, search);
      case DCT_TABLES.mostCompletedAttemps:
      case DCT_TABLES.leastCompletedAttemps:
        return getRecentAttemptsData(dctReportDetail, search);
      default:
        return [];
    }
  };
  const recentAssignedData = useMemo(() => getRecentAssignedData(), [dctReportDetail, search]);

  useEffect(() => {
    if (!dctDetail || !dctDetail.name) {
      return;
    }
    void (async () => {
      setIsLoading(true);
      const resp = await (dctType === DctType.CHATBOT
        ? getElkChatbotDctReport(getDCTDctDetailQuery(dctDetail?.name, dctDetail?.type), null)
        : getElkEvaluationDctReport(getDCTDctDetailQuery(dctDetail?.name, dctDetail?.type), null));
      setDctReportDetail(resp.data);
      setIsLoading(false);
    })();
  }, [dctDetail]);

  const renderMenu = () => {
    return <></>;
    // return (
    //   <Menu
    //     icon="more"
    //     className={classes.menu}
    //     items={[
    //       { label: 'Menu Item 1', onClick: onClickMenu(menuTable, action?.id, 'Menu Item 1') },
    //       { label: 'Menu Item 2', onClick: onClickMenu(menuTable, action?.id, 'Menu Item 2') },
    //       { label: 'Menu Item 3', onClick: onClickMenu(menuTable, action?.id, 'Menu Item 3') },
    //     ]}
    //   />
    // );
  };
  return (
    <Drawer open={open} onClose={onClose} variant={drawerType.NORMAL} className={classes.drawer}>
      {open && (
        <Box className={classes.drawerContent}>
          {isLoading ? (
            <div className={classes.loader}>
              <Icons className="rotate linear infinite" glyph="in-progress" color={colors.primary} />
              Loading...
            </div>
          ) : (
            <>
              <Box className={classes.headerDetail}>
                <Stack direction="column">
                  <Text className={classes.headerTitle} weight={fontWeight.BOLD}>
                    {dctDetail.name}
                  </Text>
                  <Text className={classes.headerSubtitle}>{dctDetail.total} members</Text>
                </Stack>
                <IconButton icon="close" onClick={onClose} className={classes.closeIconBtn} />
              </Box>
              <Box className={classes.content}>
                <Box className={classes.tableWrap}>
                  {/* <Text className={classes.recentText}>Recent assignments</Text> */}
                  <Input
                    variant={inputType.SEARCH}
                    placeholder="Search"
                    value={search}
                    className={classes.inputSearch}
                    onChange={setSearch}
                    size={inputSize.M}
                    fullWidth
                  />
                  <RecentAssignmentsTable
                    className={classes.tableBordered}
                    isShowAll
                    tableColumns={tableColumns}
                    data={recentAssignedData}
                    renderMenuAction={renderMenu}
                  />
                </Box>
              </Box>
              <Box className={classes.drawerFooter}>
                <Button variant={btnType.PRIMARY} onClick={onClose}>
                  Close
                </Button>
              </Box>
            </>
          )}
        </Box>
      )}
    </Drawer>
  );
};

export { DCTDetail };
