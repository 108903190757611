/* eslint-disable max-len */
import { colors } from '../../../../../../../packages/colors';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  title: {
    fontSize: 18,
    lineHeight: '24px',
    margin: 0,
    marginBottom: 4,
  },
  helperText: {
    fontFamily: 'Manrope',
    fontWeight: '500',
    fontSize: 14,
    lineHeight: '20px',
  },
  formLabel: {
    '& .MuiTypography-body1': {
      fontSize: 16,
    },
    alignItems: 'center !important',
  },
  logo: {
    width: 40,
    height: 40,
  },
  selectedByText: {
    fontSize: 14,
    color: colors.neutral600,
    fontWeight: 600,
    marginLeft: '12px',
  },
  textInputChoiceHeading: {
    fontSize: 14,
    fontWeight: 600,
  },
  textInputChoice: {
    fontSize: 16,
    lineHeight: '26px',
  },
  mt20: {
    marginTop: 20,
  },
  markedAsWrong: {
    fontSize: 14,
    color: colors.textError,
    fontWeight: 700,
    marginLeft: '12px',
  },
  markAsWrongBtn: {
    fontSize: 14,
    color: colors.textPrimary,
    fontWeight: 700,
    marginLeft: '12px',
    cursor: 'pointer',
  },
  selectedByBox: {
    marginLeft: '40px',
    alignItems: 'center',
    display: 'flex',
  },
  fullMatch: {
    padding: '4px !important',
    border: '2px solid #A1E6A5',
    borderRadius: '6px',
  },
  notChecked: {
    padding: '4px !important',
    border: '2px solid #fff',
    borderRadius: '6px',
  },
  partialMatch: {
    padding: '4px !important',
    border: '2px solid #FCD34D',
    borderRadius: '6px',
  },
  noMatch: {
    padding: '4px !important',
    border: '2px solid #FCA5A5',
    borderRadius: '6px',
  },
  alfieOnly: {
    padding: '4px !important',
    border: '2px solid #8EC8FD',
    borderRadius: '6px',
  },
  resolved: {
    padding: '4px !important',
    border: '2px solid #A4BCFD',
    borderRadius: '6px',
  },
  subtitle: {
    fontSize: 14,
    lineHeight: '20px',
    margin: 0,
    color: colors.neutral600,
  },
  radioGroup: {
    gap: 16,
    '& .MuiTypography-body1': {
      fontSize: 16,
    },
  },
  amendSelectBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    padding: 16,
    border: `1px solid ${colors.neutral200}`,
    borderRadius: 8,
  },
  selectedBorder: {
    border: `2px solid ${colors.primary500}`,
  },
  avatar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  check: { width: 20, height: 20 },
});
