import { Box } from '@mui/material';
import { AppointmentStatus } from '../../../../packages/constants/CommonConstants';
import cx from 'clsx';
import dayjs from 'dayjs';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { profileActionCreators } from '../../../../redux/modules/profile';
import { scheduleActionCreators } from '../../../../redux/modules/schedule';
import { getAuth } from '../../../../redux/modules/auth/selectors';
import { getProfile, selectGoogleCalendarsEventState } from '../../../../redux/modules/profile/selectors';
import { selectProviderSchedule } from '../../../../redux/modules/schedule/selectors';
import {
  getAppointments,
  getNextAppointment,
  ISearchAppointmentParams,
} from '../../../../services/appointment/appointment.service';
import { getUserTimeZone } from '../../../../utils/dayjs';
import history from '../../../../utils/history';
import apponitmentImg from '../../../../assets/images/appointments.png';
import todosImg from '../../../../assets/images/todos.png';
import Heading from '../../../../components/v2/Heading';
import { HeadingLevel } from '../../../../components/v2/Heading/index.types';
import Typography from '../../../../components/v2/Typography';
import { typography } from '../../../../components/v2/Typography/index.constant';
import Card from '../components/Card';
import ItemCard from '../components/ItemCard';
import Appointments from '../Appointments';
import NoAppointments from '../NoAppointments';
import NextAppointments from '../NextAppointments';
import CompletedAppointments, { CompletedType } from '../CompletedAppointments';
import Todo from '../Todo';
import { useStyles } from '../index.styles';

const RenderAppointments = ({
  currentDate,
  setCurrentDate,
  setCalendarKey,
  tabs,
  setSchedulesDrawerOpen,
  setIsLoading,
  setCurrentTab,
  onGoToChat,
  toggleRescheduleDrawer,
  selectAppointment,
  onSignOff,
  onStartSession,
  isApptCreated,
  setIsApptCreated,
  setSelectedAppointment,
  setViewAppointmentDetail,
}) => {
  const isPast = currentDate.isBefore(dayjs(), 'day');
  const isToday = currentDate.isSame(dayjs(), 'day');

  const classes = useStyles();
  const dispatch = useDispatch();
  const { meta } = useSelector(getAuth);
  const providerSchedule = useSelector(selectProviderSchedule);
  const { events: googleCalendarEvents } = useSelector(selectGoogleCalendarsEventState);
  const { todosStats } = useSelector(getProfile);
  const { activeTodoStats } = todosStats || {};
  const [appointmentState, setAppointmentState] = useState([]);
  const [nextAppointment, setNextAppointment] = useState(null);

  const fetchNextAppointment = async () => {
    const { data } = await getNextAppointment();
    // const sortedAppointment = data.
    setNextAppointment(data.appointment);
  };

  useEffect(() => {
    const statsPayload = {
      assignedBy: '',
      assignedTo: meta.userId,
    };
    dispatch(profileActionCreators.fetchTodoStats(statsPayload));
    if (meta.userId) {
      dispatch(profileActionCreators.fetchSyncedGoogleCalendars(meta.userId));
    }
    void fetchNextAppointment();
  }, []);

  const fetchAppointments = currDate => {
    const queryParams = {
      type: currDate.isAfter(dayjs(), 'day') ? 'PENDING,CURRENT' : 'ALL',
      page: 0,
      size: 1000,
      orderBy: 'desc',
      sortBy: 'startTime',
      startTime: currDate.startOf('day').format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
      endTime: currDate.endOf('day').format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
      searchQuery: '',
      timeZone: providerSchedule?.timezone || getUserTimeZone(),
    };
    setIsLoading(true);
    getAppointments(queryParams)
      .then(res => {
        setAppointmentState(
          res.data.results.map(item => ({
            ...item,
            participantName: item.participantName,
          })),
        );
        setIsLoading(false);
      })
      .catch(err => console.log({ err }));
  };

  const viewDetial = appt => {
    setSelectedAppointment(appt);
    setViewAppointmentDetail(true);
  };

  useEffect(() => {
    if (currentDate) {
      dispatch(
        scheduleActionCreators.fetchProviderSchedule({
          userId: meta.userId,
          queryParams: {
            startTime: currentDate.startOf('day').format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
            endTime: currentDate.endOf('day').format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
          },
        }),
      );
      void fetchAppointments(currentDate);
      // setIsApptCreated();
    }
    if (isApptCreated) {
      dispatch(
        scheduleActionCreators.fetchProviderSchedule({
          userId: meta.userId,
          queryParams: {
            startTime: currentDate.startOf('day').format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
            endTime: currentDate.endOf('day').format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
          },
        }),
      );
      void fetchNextAppointment();
      void fetchAppointments(currentDate);
      setIsApptCreated(false);
    }
  }, [currentDate, isApptCreated]);

  const timezone = useMemo(() => providerSchedule?.timezone || getUserTimeZone(), [providerSchedule]);

  const todayNextAppointment = useMemo(
    () => nextAppointment && dayjs(nextAppointment.startTime).isSame(dayjs(), 'day'),
    [nextAppointment, providerSchedule],
  );

  /* const upcomingAppointments = useMemo(
    () =>
      appointmentState.filter(appt =>
        dayjs(appt.startTime).tz(timezone).isAfter(dayjs().tz(timezone), 'minute'),
      ) || [],
    [appointmentState, timezone],
  );
 */
  /* const pastAppointments = useMemo(
    () =>
      appointmentState.filter(appt =>
        dayjs(appt.startTime).tz(timezone).isBefore(dayjs().tz(timezone), 'day'),
      ) || [],
    [appointmentState, timezone],
  );
  console.log(appointmentState);
  const futureAppointments = useMemo(
    () => appointmentState
        .filter(appt => dayjs(appt.startTime).tz(timezone).isAfter(dayjs().tz(timezone), 'day'))
        .filter(
          item => item.status === AppointmentStatus.BOOKED || item.status === AppointmentStatus.PROPOSED,
        ) || [],
    [appointmentState, timezone],
  ); */

  const blockedSlots = useMemo(() => {
    const scheduledSlots =
      providerSchedule?.blockedSlots?.map(slot => ({
        // ...slot,
        serviceName: 'Blocked',
        memberName: '',
        type: 'confidant',
        status: 'BLOCKED',
        start: slot.start,
        startTime: slot.start,
        end: slot.end,
        endTime: slot.end,
      })) || [];
    const allEvents = googleCalendarEvents
      .map(calendar => {
        return calendar.events;
      })
      .flat()
      .map(event => ({
        // ...event,
        serviceName: 'Blocked',
        memberName: '',
        type: 'external',
        status: 'BLOCKED',
        start: event.utc.start,
        startTime: event.utc.start,
        end: event.utc.end,
        endTime: event.utc.end,
      }))
      .filter(event => dayjs(event.start).isSame(currentDate, 'day'));
    const slots = scheduledSlots.concat(allEvents);
    return slots;
  }, [providerSchedule, googleCalendarEvents]);

  const availableSlots = useMemo(() => {
    return (
      providerSchedule?.availability?.flatMap(schedule =>
        schedule.slots.map(slot => ({
          ...slot,
          serviceName: 'Available',
          memberName: '',
          status: 'AVAILABLE',
          startTime: slot.start,
          endTime: slot.end,
        })),
      ) || []
    );
  }, [providerSchedule]);

  if (isToday) {
    const appoint = appointmentState.filter(
      appt =>
        !(appt.status === AppointmentStatus.FULFILLED && appt.signOffStatus === 'DRAFTED') &&
        !(appt?.appointmentId === nextAppointment?.appointmentId) &&
        appt.status !== 'INACTIVE' &&
        appt.status !== AppointmentStatus.FULFILLED &&
        !(appt.status === AppointmentStatus.FULFILLED && appt.signOffStatus === null) &&
        !(appt.status === AppointmentStatus.FULFILLED && appt.signOffStatus === 'REVIEW') &&
        !(appt.status === AppointmentStatus.FULFILLED && appt.signOffStatus === 'REJECTED') &&
        appt.status !== AppointmentStatus.CANCELLED &&
        appt.status !== AppointmentStatus.NO_SHOW &&
        appt.status !== AppointmentStatus.DECLINED,
    );

    /* // CODE TO FILTER OUT TODAYS UPCOMING APPOINTMENTS, keeping in mind the time.
    const currentTime = dayjs();

    // Filter the appointments to only include upcoming appointments for today after the current time
    const upcomingAppointments = appoint?.filter(item => {
      const appointmentEndTime = dayjs(item.endTime);
      return (
        appointmentEndTime.isSameOrAfter(currentTime, 'minute') &&
        appointmentEndTime.isSame(currentTime, 'day')
      );
    }); */

    const sortedUpcommingAppointments = appoint?.sort((a, b) => {
      return dayjs(a?.startTime).valueOf() - dayjs(b?.startTime).valueOf();
    });

    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
        {appointmentState.length === 0 ? (
          <>
            <Todo />
            <ItemCard
              imgSrc={apponitmentImg}
              description="No appointments scheduled"
              action={{
                children: <Typography {...typography.body.s.bold}>New appointment</Typography>,
                onClick: () => setSchedulesDrawerOpen(true),
              }}
            />
          </>
        ) : (
          <>
            {!!todayNextAppointment && (
              <NextAppointments
                appointment={nextAppointment}
                onReschedule={toggleRescheduleDrawer}
                selectAppointment={selectAppointment}
                onStartSessionClick={onStartSession}
                viewDetail={viewDetial}
              />
            )}
            {sortedUpcommingAppointments.length > 0 && (
              <Appointments
                title="Upcoming today"
                appointments={sortedUpcommingAppointments}
                onGoToChat={onGoToChat}
                onReschedule={toggleRescheduleDrawer}
                selectAppointment={selectAppointment}
                onSignOff={onSignOff}
                onStartSessionClick={onStartSession}
                viewDetail={viewDetial}
              />
            )}
            {sortedUpcommingAppointments.length === 0 && !todayNextAppointment && (
              <>
                <CompletedAppointments variant={CompletedType.APPOINTMENT} />
                {activeTodoStats?.total > 0 && (
                  <ItemCard
                    imgSrc={todosImg}
                    description={`You have ${activeTodoStats?.total} pending to-dos`}
                    action={{
                      children: <Typography {...typography.body.s.bold}>Open to-dos</Typography>,
                      onClick: () => {
                        history.push('/provider/todos');
                      },
                    }}
                  />
                )}
              </>
            )}
          </>
        )}
      </Box>
    );
  }

  if (isPast) {
    const filteredApptState = appointmentState.filter(
      appt =>
        !(appt.status === AppointmentStatus.FULFILLED && appt.signOffStatus === 'DRAFTED') &&
        !(appt.status === AppointmentStatus.FULFILLED && appt.signOffStatus === 'REVIEW') &&
        !(appt.status === AppointmentStatus.FULFILLED && appt.signOffStatus === 'REJECTED') &&
        appt.status !== 'INACTIVE',
    );
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
        <Todo />
        {filteredApptState.length === 0 ? (
          <ItemCard
            imgSrc={apponitmentImg}
            description={`You had no appointments on ${dayjs(currentDate).format('MMMM D')}`}
          />
        ) : (
          <Appointments
            title=""
            appointments={filteredApptState}
            onGoToChat={onGoToChat}
            onReschedule={toggleRescheduleDrawer}
            selectAppointment={selectAppointment}
            onSignOff={onSignOff}
            onStartSessionClick={onStartSession}
            viewDetail={appt => console.log('apptClicked', appt)}
          />
        )}
      </Box>
    );
  }

  const filteredFutureApptState = appointmentState.filter(
    appt => !(appt.status === AppointmentStatus.FULFILLED && appt.signOffStatus === 'DRAFTED'),
  );
  const sortedUpcommingAppointments = filteredFutureApptState?.sort((a, b) => {
    return dayjs(a?.startTime).valueOf() - dayjs(b?.startTime).valueOf();
  });
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
      {appointmentState.length === 0 ? (
        <>
          <NoAppointments
            date={currentDate}
            setSchedulesDrawerOpen={setSchedulesDrawerOpen}
            nextAppointment={nextAppointment}
            setCurrentDate={setCurrentDate}
            setCalendarKey={setCalendarKey}
          />
          <ItemCard
            imgSrc={apponitmentImg}
            description={`You have ${availableSlots?.length} available slots and ${blockedSlots?.length} blocked slots`}
            action={{
              children: <Typography {...typography.body.s.bold}>Open availability</Typography>,
              onClick: () => {
                setCurrentTab(tabs[1].tabKey);
              },
            }}
          />
          {activeTodoStats?.total > 0 && (
            <ItemCard
              imgSrc={todosImg}
              description={`You have ${activeTodoStats?.total} pending to-dos`}
              action={{
                children: <Typography {...typography.body.s.bold}>Open to-dos</Typography>,
                onClick: () => {
                  history.push('/provider/todos');
                },
              }}
            />
          )}
        </>
      ) : (
        <>
          {filteredFutureApptState.length !== 0 ? (
            <Appointments
              title={`Upcoming ${currentDate.format('dddd, MMMM D')}`}
              appointments={sortedUpcommingAppointments}
              onGoToChat={onGoToChat}
              onReschedule={toggleRescheduleDrawer}
              selectAppointment={selectAppointment}
              onSignOff={onSignOff}
              onStartSessionClick={onStartSession}
              viewDetail={appt => console.log('apptClicked', appt)}
            />
          ) : (
            <></>
          )}
          {(availableSlots?.length > 0 || blockedSlots?.length > 0) && (
            <Card
              img={apponitmentImg}
              // <Icons glyph="sign-off-todo" className={classes.img64} />
              action={{
                className: cx(classes.outlineBtn, classes.xsmallBtn),
                children: <Typography {...typography.body.s.bold}>Go to availbility</Typography>,
                onClick: () => setCurrentTab(tabs[1].tabKey),
              }}
              extended={
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
                  <Heading variant={HeadingLevel.H6} letterSpacing="-0.5px">
                    You have {availableSlots?.length} available and {blockedSlots?.length} blocked slots
                  </Heading>
                  {availableSlots?.length > 0 && (
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                      <Typography {...typography.body.m.semibold}>Available slots</Typography>
                      <Box display="flex" gap={1.5} sx={{ flexFlow: 'wrap' }}>
                        {availableSlots.map((slot, index) => (
                          <Box key={index} className={classes.slot}>
                            {dayjs(slot.startTime).tz(timezone).format('hh:mm A')} -{' '}
                            {dayjs(slot.endTime).tz(timezone).format('hh:mm A')}
                          </Box>
                        ))}
                      </Box>
                    </Box>
                  )}
                  {blockedSlots?.length > 0 && (
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                      <Typography {...typography.body.m.semibold}>Blocked slots</Typography>
                      <Box display="flex" gap={1.5} sx={{ flexFlow: 'wrap' }}>
                        {blockedSlots.map((slot, index) => (
                          <Box key={index} className={classes.slot}>
                            {dayjs(slot.startTime).tz(timezone).format('hh:mm A')} -{' '}
                            {dayjs(slot.endTime).tz(timezone).format('hh:mm A')}
                          </Box>
                        ))}
                      </Box>
                    </Box>
                  )}
                </Box>
              }
            />
          )}
        </>
      )}
    </Box>
  );
};

export default RenderAppointments;
