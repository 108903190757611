import { makeStyles } from '@mui/styles';

// colors
import { colors } from '../../../../../../packages';

export const useStyles = makeStyles({
  infoBlock: {
    backgroundColor: colors.white,
    boxShadow:
      '0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
    padding: 16,
    width: '100%',
    borderRadius: 8,
  },
  infoBlockLabel: {
    color: colors.neutral600,
    fontSize: 12,
    lineHeight: '20px',
    margin: 0,
    textTransform: 'uppercase',
    display: 'block',
  },
  infoBlockValue: {
    fontSize: 28,
    lineHeight: '36px',
    margin: 0,
  },
});
