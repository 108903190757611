import { makeStyles } from '@mui/styles';

// colors
import { colors } from '../../../packages';

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: 40,
  },
  table: {
    backgroundColor: colors.white,
  },
  link: {
    textDecoration: 'none',
    cursor: 'pointer',
  },
  name: {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '20px',
  },
  heading: {
    fontFamily: 'Source Serif Pro',
    margin: 0,
  },
  btnGroupIcon: {
    color: colors.neutral900,
    backgroundColor: colors.white,
    padding: '14px 18px',
    transition: '0s',
    height: 48,
    '&:hover': {
      color: colors.white,
      backgroundColor: colors.neutral900,
    },
  },
  btnGroupIconActive: {
    color: colors.white,
    backgroundColor: colors.neutral900,
  },
  badge: {
    borderRadius: 20,
    backgroundColor: colors.neutral50,
    color: colors.neutral700,
    border: 'none',
    whiteSpace: 'nowrap',
    fontSize: 14,
    lineHeight: '20px',
    fontWeight: 600,
    padding: '4px 0px',
    minWidth: 'auto',
  },
  badgeActive: {
    backgroundColor: colors.secondary50,
    color: colors.secondary500,
  },
  menu: {
    float: 'right',
    right: 20,
    '& svg': {
      width: 16,
    },
  },
  menuItemsWrapper: {
    '& ul': {
      padding: 0,
    },
    '& ul li': {
      padding: '10px 16px',
    },
  },
  totalMemberBadge: {
    borderRadius: 99,
    whiteSpace: 'nowrap',
    height: 30,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 12px',
    color: colors.neutral700,
  },
  addProfileBtn: {
    height: 64,
    color: '#FFF',
    whiteSpace: 'nowrap',
    padding: '20px 24px',
    borderRadius: 8,
  },
  drawer: {
    '& .MuiPaper-root': {
      maxWidth: '640px !important',
    },
    '& .drawerBody': {
      minHeight: 'calc(100vh - 80px)',
      flexDirection: 'column',
      display: 'flex',
      padding: '32px',
    },
  },
  whiteColor: {
    color: colors.white,
  },
  badgePadding: {
    padding: '10px',
  },
  searchBar: {
    padding: 0,
    '& .MuiTextField-root': {
      '& .MuiInputBase-root': {
        '& > fieldset': {
          borderBottom: `1px solid ${colors.neutral200}`,
          borderTop: 'unset',
          borderLeft: 'unset',
          borderRight: 'unset',
          borderRadius: 6,
        },
      },
    },
  },
  deleteIcon: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  providerRole: {
    borderRadius: 20,
    height: 18,
    padding: '2px 8px',
    fontSize: 12,
    width: 'fit-content',
    border: 'none',
    marginTop: 2,
    textWrap: 'nowrap',
  },
  dayButton: {
    width: '100%',
    height: '36px',
    fontSize: '12px',
    fontWeight: 'bold',
  },
  sortSelect: {
    backgroundColor: colors.white,
  },
});
