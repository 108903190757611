/* eslint-disable max-len */
import cx from 'clsx';

// types
// styles
import { useStyles } from './shared/styles';

const Analyze = ({ className, color, onClick }) => {
  const classes = useStyles();
  return (
    <svg
      className={cx({
        [classes.root]: true,
        [className || '']: className,
      })}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M4 16H16V4H4V16ZM12 18H8V20H6V18H3C2.73478 18 2.48043 17.8946 2.29289 17.7071C2.10536 17.5196 2 17.2652 2 17V14H0V12H2V8H0V6H2V3C2 2.73478 2.10536 2.48043 2.29289 2.29289C2.48043 2.10536 2.73478 2 3 2H6V0H8V2H12V0H14V2H17C17.2652 2 17.5196 2.10536 17.7071 2.29289C17.8946 2.48043 18 2.73478 18 3V6H20V8H18V12H20V14H18V17C18 17.2652 17.8946 17.5196 17.7071 17.7071C17.5196 17.8946 17.2652 18 17 18H14V20H12V18ZM6 6H14V14H6V6Z"
        fill={color}
      />
    </svg>
  );
};

export default Analyze;
