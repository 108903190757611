import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { Box } from '@mui/material';

// components
import { Button, colors, Icons, Input } from '../../../../../packages';
import { getAuth } from '../../../../../redux/modules/auth/selectors';
import { authActionCreators } from '../../../../../redux/modules/auth';
import { LoginType } from '../../../../../constants/CommonConstants';
import { userAccountRecovery } from '../../../../../services/auth/auth.service';
import Typography from '../../../../../components/v2/Typography';
import { typography } from '../../../../../components/v2/Typography/index.constant';
import { useStyles } from './ForgotPassword.styles';

// import './login.css';

const ForgotPasswordOTP = ({ setScreen, userEmail, setCode }) => {
  const { isLoading, errorMsg, forgotPasswordOTPVerified } = useSelector(getAuth);
  const [counter, setCounter] = useState(60);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [isResendLoading, setIsResendLoading] = useState(false);
  const codeType = 'PASSWORD_RECOVERY';

  useEffect(() => {
    const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    if (counter === 0) {
      setButtonDisable(true);
    }
    return () => clearInterval(timer);
  }, [counter]);

  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      code: '',
      codeType,
      emailOrPhone: userEmail,
      userType: LoginType.PROVIDER,
    },

    onSubmit: values => {
      dispatch(authActionCreators.verifyForgotPasswordOTP(values));

      // used in NewPassword component
      setCode(values.code);
    },
  });
  const classes = useStyles();

  useEffect(() => {
    if (forgotPasswordOTPVerified) {
      setScreen('NewPassword');
    }
  }, [forgotPasswordOTPVerified]);

  const onBlurInput = async e => {
    await formik.setFieldTouched(e.target.name);
  };

  const callResendOtp = () => {
    setIsResendLoading(true);

    userAccountRecovery({ email: userEmail, type: LoginType.PROVIDER })
      .then(() => {
        setButtonDisable(false);
        setCounter(60);
      })
      .catch(e => console.log(e))
      .finally(() => {
        setIsResendLoading(false);
      });
  };

  return (
    <Box display="flex" flexDirection="column" gap={4}>
      {errorMsg && (
        <Box className={classes.errorMsg}>
          <Icons glyph="info-outlined" color={colors.destructive500} />
          <Typography {...typography.body.s.medium}>{errorMsg}</Typography>
        </Box>
      )}
      <form onSubmit={formik.handleSubmit} className={classes.form}>
        <Box display="flex" flexDirection="column" gap={4}>
          <div className={classes.inputContainer}>
            <Typography {...typography.body.normal.medium.semibold}>Verification code</Typography>
            <Input
              className={classes.input}
              placeholder="Enter verification code"
              name="code"
              onChange={formik.handleChange}
              onBlur={onBlurInput}
            />
            {/* <TextError errorMsg={formik.touched.code ? formik.errors.code?.toString() : null} /> */}
          </div>

          <Box display="flex" flexDirection="column" gap={2}>
            <Button
              className={classes.buttonContainer}
              onClick={formik.handleSubmit}
              disabled={formik.values.code.length < 4}
            >
              {isLoading ? (
                <Icons className="rotate linear infinite" glyph="in-progress" color={colors.white} />
              ) : (
                'Verify'
              )}
            </Button>
            <Button className={classes.buttonContainer} onClick={callResendOtp} disabled={!buttonDisable}>
              {isResendLoading ? (
                <Icons className="rotate linear infinite" glyph="in-progress" color={colors.white} />
              ) : (
                'Send again'
              )}
            </Button>
          </Box>
        </Box>
        <Box display="flex" justifyContent="center" color={colors.neutral500} mt={3}>
          <Typography {...typography.body.s.medium}>
            {counter ? `Wait ${counter} second before requesting a new code` : ''}
          </Typography>
        </Box>
      </form>
    </Box>
  );
};

export { ForgotPasswordOTP };
