import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Box, Stack } from '@mui/material';
import {
  Badge,
  badgeStyle,
  badgeType,
  fontWeight,
  Heading,
  headingLevel,
  iconBtnType,
  IconButton,
  useQuery,
} from '../../../packages';
import { BaseLayout } from '../../../layouts/base';

import { showSnackbar } from '../../../redux/modules/snackbar';
import { conversationActionCreators } from '../../../redux/modules/conversation';
import { appointmentActionCreators } from '../../../redux/modules/appointment';
import { profileActionCreators } from '../../../redux/modules/profile';
import {
  selectAutomationRules,
  selectAutomationRulesCount,
} from '../../../redux/modules/conversation/selectors';

import { ContentfulClient } from '../../../config/contentful-config';
import { automationTypes, rightSideNavList } from './Automations.constants';

import Automations from './chatbots';
import AddAutomation from './add-automation';

import { useStyles } from './Automations.styles';

const AutomationList = () => {
  const query = useQuery();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();

  const automations = useSelector(selectAutomationRules);
  const automationCount = useSelector(selectAutomationRulesCount);

  const [showAddNew, setShowAddNew] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [educationList, setEducationList] = useState([]);
  const [editList, setEditList] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);

  const getEducationalContent = async () => {
    const params = {
      content_type: 'educationalContent',
      limit: 50,
    };
    try {
      const response = await ContentfulClient.getEntries(params);
      setEducationList(response.items);
    } catch (error) {
      showSnackbar({
        snackType: 'error',
        snackMessage: error,
      });
    }
  };

  useEffect(() => {
    const queryParams = {
      searchQuery: '',
      type: '',
      method: '',
      pageNumber: 0,
      pageSize: 1000,
    };

    const automationParams = {
      pageNumber: 1,
      pageSize: 10,
      eventType: '',
      category: '',
    };
    const conversationQueryParams = {
      pageNumber: 1,
      pageSize: 10000,
      status: 'ACTIVE',
      reportingView: true,
    };
    dispatch(conversationActionCreators.fetchAutomationCount());
    dispatch(conversationActionCreators.fetchAutomationRules(automationParams));
    dispatch(conversationActionCreators.fetchConversations(conversationQueryParams));
    dispatch(conversationActionCreators.fetchProfileElements(queryParams));
    dispatch(conversationActionCreators.fetchAIJobs());
    dispatch(
      conversationActionCreators.fetchDCTs({
        ...conversationQueryParams,
        pageNumber: 0,
        assignmentCount: false,
        type: '',
      }),
    );
    dispatch(appointmentActionCreators.fetchProviderRoles());
    dispatch(profileActionCreators.fetchGroups());
    dispatch(profileActionCreators.fetchProviders());
    dispatch(conversationActionCreators.fetchRevampTypesList());
    dispatch(conversationActionCreators.fetchTags());
    dispatch(conversationActionCreators.fetchEvaluations());
    dispatch(
      conversationActionCreators.fetchPlanItems({
        pageNumber: 1,
        pageSize: 10000,
      }),
    );

    void (async () => {
      setDataLoading(true);
      await Promise.all([getEducationalContent()]);
      setDataLoading(false);
    })();
  }, []);

  const sideBarList = useMemo(() => {
    return rightSideNavList.reduce((lists, curr) => {
      const type = curr.link.split('=')[1];
      lists.push({ ...curr, badge: automationCount[automationTypes[type]?.countType] });
      return lists;
    }, []);
  }, [automationCount]);

  const title = useMemo(() => {
    const getQuery = query.get('name');
    if (!getQuery) return 'Automations';
    return automationTypes[getQuery]?.title;
  }, [query]);

  const getAutomations = useCallback(params => {
    dispatch(conversationActionCreators.fetchAutomationRules(params));
  }, []);

  const toggleAddNew = () => {
    setShowAddNew(!showAddNew);
    setIsEditMode(false);
  };

  const onDelete = useCallback(id => {
    const getQuery = query.get('name');
    if (id) {
      dispatch(
        conversationActionCreators.deleteAutomationRule({
          id,
          category: getQuery ? automationTypes[getQuery]?.category : '',
        }),
      );
    }
  }, []);

  const onEditItem = useCallback(
    id => () => {
      const editItem = automations?.automationRules?.find(list => list.id === id);
      if (editItem) {
        setEditList(editItem);
        setIsEditMode(true);
        setShowAddNew(!showAddNew);
      }
    },
    [automations],
  );
  return (
    <>
      {showAddNew && (
        <AddAutomation
          isEditMode={isEditMode}
          isOpen={showAddNew}
          onClose={toggleAddNew}
          editList={editList}
          educationList={educationList}
        />
      )}
      <BaseLayout
        rightSideNavList={sideBarList}
        rightSideBarWidth="320px"
        rightSidebarHeadCustom={
          <Box onClick={() => navigate('/admin/automations')} sx={{ cursor: 'pointer' }}>
            <Heading level={headingLevel.S} weight={fontWeight.BOLD}>
              Automations
            </Heading>
          </Box>
        }
      >
        <Box className={classes.root}>
          <Stack direction="row" justifyContent="space-between" spacing={2}>
            <Stack direction="row" alignItems="center" gap={2}>
              <Heading className={classes.heading} level={headingLevel.XL} weight={fontWeight.BOLD}>
                {title}
              </Heading>
              <Badge
                className={classes.totalMemberBadge}
                variant={badgeType.OUTLINED}
                style={badgeStyle.UNRELATED}
              >
                {automations?.totalRecords} total
              </Badge>
            </Stack>

            <IconButton
              className={classes.addProfileBtn}
              icon="plus"
              variant={iconBtnType.PRIMARY}
              onClick={toggleAddNew}
              disabled={dataLoading}
              loading={dataLoading}
            >
              Add automation
            </IconButton>
          </Stack>
          <Automations
            loading={automations.isLoading}
            automations={automations}
            onEditItem={onEditItem}
            onDelete={onDelete}
            getAutomations={getAutomations}
            educations={educationList}
          />
        </Box>
      </BaseLayout>
    </>
  );
};

export { AutomationList };
