// colors
import { colors } from '../../../../../packages/colors';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    paddingTop: 88,
    gap: 40,
  },
  collapsableAccordion: {
    width: '100%',
    '& .collapsableHeader': {
      height: 48,
      gap: 8,
      marginBottom: 40,
      '& span': {
        fontWeight: 600,
        fontSize: 32,
      },
    },
    '& .collapsableIcon': {
      margin: 0,
      '& path': {
        fill: colors.neutral900,
      },
    },
  },
  heading: {
    fontFamily: 'Source Serif Pro',
    margin: 0,
  },
  noPaddingLeft: {
    padding: '32px 32px 32px 0',
  },
  emptyNote: {
    color: colors.neutral300,
    fontSize: 16,
    textAlign: 'center',
  },
  headingWithBtnWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBottom: 4,
  },
  addBtn: {
    height: 64,
    color: '#FFF',
    padding: '20px 24px',
    borderRadius: 8,
    whiteSpace: 'nowrap',
  },
  noteHeaderRightSide: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 16,
  },
  cardsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 40,
    alignItems: 'center',
  },
  badge: {
    width: 'fit-content',
    padding: '4px 8px',
    height: 24,
    borderRadius: 20,
    fontSize: 12,
    lineHeight: '16px',
    marginLeft: 16,
  },
});
