import clsx from 'clsx';
import dayjs from 'dayjs';
import { Box } from '@mui/material';
import { Avatar, avatarType, fontWeight, Text } from '../../../../../../packages';
import { useStyles } from './ViewInput.styles';
import { listMock } from './ViewInput.mock';

const ViewInput = ({ showList }) => {
  const classes = useStyles();

  const renderRow = item => (
    <Box className={classes.itemWrap}>
      <Box className={classes.item}>
        <Box className={classes.userWrap}>
          <Avatar src={item.member.thumbnail} name={item.member.name} size={40} variant={avatarType.CIRCLE} />
          <Box>
            <Text className={classes.name} weight={fontWeight.SEMI_BOLD}>
              {item.member.name}
            </Text>
            <Text className={clsx(classes.name, classes.fullname)} weight={fontWeight.MEDIUM}>
              {item.member.fullname}
            </Text>
          </Box>
        </Box>
        <Box>
          <Text className={classes.name} weight={fontWeight.SEMI_BOLD}>
            {dayjs(item.createdDate).format('MMMM DD, YYYY')}
          </Text>
          <Text className={clsx(classes.name, classes.fullname)} weight={fontWeight.MEDIUM}>
            {dayjs(item.createdDate).format('h:mm a')}
          </Text>
        </Box>
      </Box>
      {showList ? (
        <ul className={classes.listItem}>
          {item.items.map((child, idx) => (
            <li key={idx}>
              <Text className={classes.name} weight={fontWeight.MEDIUM}>
                {child}
              </Text>
            </li>
          ))}
        </ul>
      ) : (
        <Box className={classes.descriptionWrap}>
          <Text className={classes.name} weight={fontWeight.MEDIUM}>
            {item.description}
          </Text>
        </Box>
      )}
    </Box>
  );

  return <Box>{listMock.map(renderRow)}</Box>;
};

export { ViewInput };
