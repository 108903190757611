// colors
import { colors } from '../../../../packages';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  inputsContainer: {
    overflowY: 'auto',
    height: 'calc(100vh - 185px)',
    padding: 24,
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
  },
  container: {
    border: 'solid',
    borderColor: colors.neutral100,
    borderRadius: 8,
    padding: 24,
  },
  subText: {
    color: colors.neutral600,
    fontWeight: 500,
  },
  focused: {
    borderColor: colors.primary300,
  },
  textSize: {
    fontSize: 18,
  },
  /* serviceContainer: {
    height: 'calc(100vh - 238px)',
    overflowY: 'auto',
    transition: 'height 300ms linear',
  }, */
  icon: {
    width: '64px !important',
    height: '64px !important',
    padding: 15,
    borderRadius: 8,
  },
  icon2: {
    width: '64px !important',
    height: '64px !important',
    padding: 15,
    borderRadius: 8,
  },
  scheduleIcon: {
    backgroundColor: colors.primary100,
    color: colors.primary500,
  },
  iAppointmentIcon: {
    backgroundColor: colors.secondary100,
    color: colors.secondary500,
  },
  wrapper: {
    flex: 1,
    flexDirection: 'column',
  },
  loader: {
    height: 'calc(100vh - 158px)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  header: {
    position: 'relative',
    padding: 24,
    borderBottom: `1px solid ${colors.neutral100}`,
  },
  headerNoDrawer: {
    padding: 16,
    '& $headTitle': {
      fontSize: 18,
      lineHeight: '24px',
    },
    '& $headSubtitle': {
      fontSize: 14,
      lineHeight: '20px',
    },
  },
  headWrap: {
    borderBottom: `1px solid ${colors.neutral100}`,
  },
  headTitle: {
    fontSize: 24,
    lineHeight: '32px',
    margin: 0,
  },
  headSubtitle: {
    fontSize: 16,
    lineHeight: '24px',
    color: colors.neutral600,
    display: 'block',
    margin: 0,
  },
  closeBtn: {
    margin: 0,
    minWidth: 'auto',
    position: 'absolute',
    right: 24,
    top: 24,
    '& svg': {
      width: 30,
    },
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 80,
    padding: 16,
    borderTop: `1px solid ${colors.neutral100}`,
  },
  backBtn: {
    width: 40,
    height: 40,
    minWidth: 40,
  },
  content: {
    height: 'calc(100vh - 158px)',
    overflowY: 'auto',
    transition: 'height 300ms linear',
  },
  showFooter: {
    height: 'calc(100vh - 238px)',
  },
  sessionContent: {
    height: 'calc(100vh - 239px)',
    overflowY: 'auto',
    transition: 'height 300ms linear',
  },
  sessionShowFooter: {
    height: 'calc(100vh - 319px)',
  },
  stepWrap: {
    marginTop: 20,
  },
  drawer: {
    '& .drawerBody': {
      padding: 0,
    },
  },
  avatarWrapper: {
    display: 'flex',
    flexDirection: 'row',
    gap: 16,
    '& img': {
      minWidth: 48,
    },
    '& > div': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: 4,
      '& > *': {
        lineHeight: '24px',
      },
    },
  },
  block: {
    marginBottom: 16,
  },
  addRecipientLink: {
    marginTop: '24px',
  },
});
