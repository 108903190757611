import { FC, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import clsx from 'clsx';

import { Drawer, drawerType } from '../../../../../packages/ui/organisms/drawer';
import { Input, inputType, inputSize } from '../../../../../packages/ui/atoms/input';
import { iconBtnType, IconButton } from '../../../../../packages/ui/molecules/icon-button';

import SocialCard from '../../../member-detail/components/SideEffects/components/side-effect-card';

import { useStyles } from '../history-drawer/HistoryDrawer.styles';
import EmptyState from '../empty-state';

const SocialDeterminantDrawer = ({
  open,
  isNoDrawer,
  items = [],
  isOnlyView,
  onClose,
  onClickCard,
  onClickNewReport,
  appointment,
}) => {
  const classes = useStyles();
  const [search, setSearch] = useState('');
  const [list, setList] = useState(items);

  useEffect(() => {
    const filteredList = items.filter(item => item.name?.toLowerCase().includes(search.toLowerCase()));
    setList(filteredList);
  }, [search, items]);

  const renderContent = () => {
    return (
      <Box className={classes.container}>
        <Box className={classes.searchWrap}>
          <Input
            variant={inputType.SEARCH}
            className={classes.searchInput}
            placeholder="Search"
            size={inputSize.M}
            value={search}
            onChange={setSearch}
          />
          <IconButton
            variant={iconBtnType.PRIMARY}
            className={classes.reportNewBtn}
            onClick={onClickNewReport}
          >
            Report new
          </IconButton>
        </Box>
        <Box className={classes.items}>
          {list.length ? (
            list.map((item, idx) => (
              <SocialCard key={idx} item={item} isOnlyView={isOnlyView} onClick={onClickCard} />
            ))
          ) : (
            <EmptyState
              title={`No Social Determinants reported for ${
                appointment.participantName || 'this member'
              } yet.`}
              subtitle={`
              If you've spoken with them about
              their personal situation, make sure
              you save that information here.
              `}
              glyph="dinner"
              glyphOrientation="landscape"
            />
          )}
        </Box>
      </Box>
    );
  };

  if (isNoDrawer) {
    return renderContent();
  }

  return (
    <Drawer
      open={open}
      title="Social determinants"
      subTitle={`${list.length} total`}
      onClose={onClose}
      variant={drawerType.FORM}
      className={clsx(classes.drawer, classes.drawerWithSubtitle)}
      footer={<span />}
    >
      {renderContent()}
    </Drawer>
  );
};

export { SocialDeterminantDrawer };
