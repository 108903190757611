import { FC, memo, useCallback, useEffect, useState } from 'react';
import { Box, useMediaQuery } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'clsx';
import { iconBtnStyle, iconBtnType, IconButton } from '../../../../packages/ui/molecules/icon-button';
import { fontWeight, Heading, headingLevel, Text } from '../../../../packages/ui/atoms/typography';
import { Avatar, avatarType } from '../../../../packages/ui/atoms/avatar';
import { btnType, Button } from '../../../../packages/ui/atoms/button';
import { AppointmentStatus } from '../../../../packages/constants/CommonConstants';
import AppointmentScheduling from '../../appointments/components/appointment-scheduling';
import AppointmentCancel from '../../appointments/components/appointment-cancel';
import dayjs, { getUserTimeZone } from '../../../../utils/dayjs';
import history from '../../../../utils/history';
import { getAppointments } from '../../../../services/appointment/appointment.service';
import { appointmentActionCreators } from '../../../../redux/modules/appointment';
import { profileActionCreators } from '../../../../redux/modules/profile';
import { selectProviderSchedule } from '../../../../redux/modules/schedule/selectors';
import { getProfile } from '../../../../redux/modules/profile/selectors';
import { scheduleActionCreators } from '../../../../redux/modules/schedule';
import { theme } from '../../../../packages/theme';
import { getAuth } from '../../../../redux/modules/auth/selectors';
import { useStyles } from './AppointmentInfo.styles';

const AppointmentInfo = memo(
  ({ isCompleted, onEndSession, appointment, startInPersonSession, showInPersonSession }) => {
    const classes = useStyles();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const { meta } = useSelector(getAuth);
    const [showReschedule, setShowReschedule] = useState(false);
    const [showCancel, setShowCancel] = useState(false);
    const [openMarkAsNoShow, setOpenMarkAsNoShow] = useState(false);
    const [nextAppointments, setNextAppointments] = useState([]);
    const providerSchedule = useSelector(selectProviderSchedule);
    const { demographicDetails, profile } = useSelector(getProfile);

    const dispatch = useDispatch();

    useEffect(() => {
      if (appointment) {
        dispatch(scheduleActionCreators?.fetchProviderSchedule({ userId: profile?.providerId }));
        dispatch(profileActionCreators.fetchDemographicDetails(appointment?.patientId));
        void getCurrentAppointments();
      }
    }, [appointment]);

    const editAppointmentClick = () => {
      setShowReschedule(true);
    };

    const cancelAppointmentClick = () => {
      setShowCancel(true);
    };

    const markNoShowClick = () => {
      setOpenMarkAsNoShow(true);
    };

    const updateAppointment = payload => {
      console.warn(`Ignoring Payload for UpdateAppointment ${payload}`);
      history.push(
        `/provider/${meta?.userId}/day-schedule?date=${dayjs().tz(getUserTimeZone()).format('YYYY-MM-DD')}`,
      );
    };

    /**
     * @Name markAsNoShowClick
     * @description This method is used to mark appointment as no show .
     */
    const markAsNoShowClick = () => {
      dispatch(
        appointmentActionCreators.cancelAppointment({
          appointmentId: appointment.appointmentId,
          appointmentCancelParams: { reason: 'NO_SHOW' },
          callback: errorApi => {
            if (errorApi) {
              console.log(errorApi);
            } else {
              history.push(
                `/provider/${meta?.userId}/day-schedule?date=${dayjs()
                  .tz(getUserTimeZone())
                  .format('YYYY-MM-DD')}`,
              );
            }
          },
        }),
      );
    };

    /**
     * @Name completeByPhoneClick
     * @description This method is used to complete appointment by phone
     */
    const completeByPhoneClick = endedVia => {
      onEndSession(endedVia);
    };

    const startInPersonCall = () => {
      startInPersonSession();
    };

    const onCloseSlider = () => {
      showReschedule && setShowReschedule(false);
      showCancel && setShowCancel(false);
      openMarkAsNoShow && setOpenMarkAsNoShow(false);
    };

    const getCurrentAppointments = useCallback(async () => {
      try {
        const {
          data: { appointments },
        } = await getAppointments({
          type: 'CURRENT',
          timezone: providerSchedule?.timezone || getUserTimeZone(),
          size: 10,
          page: 0,
        });
        const items = appointments.filter(
          item =>
            item.appointmentId !== appointment.appointmentId && item.status === AppointmentStatus.BOOKED,
        );
        setNextAppointments(items.slice(0, 2));
        // eslint-disable-next-line no-empty
      } catch (err) {}
    }, [appointment]);

    const renderInfo = (label, text, icon) => {
      return (
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box>
            <Text className={classes.label}>{label}</Text>
            <Text className={classes.text}>{text || 'N/A'}</Text>
          </Box>
          {icon && (
            <IconButton
              icon={icon}
              className={classes.infoIconBtn}
              style={iconBtnStyle.PRIMARY}
              variant={iconBtnType.SECONDARY}
            />
          )}
        </Box>
      );
    };

    const renderNextItem = item => {
      return (
        <Box display="flex" alignItems="center" gap={1} key={item.appointmentId}>
          <Avatar
            src={item.participantImage}
            size={64}
            variant={avatarType.CIRCLE}
            name={item.participantName}
          />
          <Box display="flex" justifyContent="space-between" flexDirection="column">
            <Text className={classes.nextTitle}>{item.serviceName}</Text>
            <Text
              className={cx(classes.nextTitle, classes.nextSubtitle)}
            >{`${item.patientFirstName} ${item.patientLastName}`}</Text>
            <Text className={classes.nextTime}>
              {dayjs(item.startTime).format('hh:mma')} - {dayjs(item.endTime).format('hh:mma')}
            </Text>
          </Box>
        </Box>
      );
    };

    const renderButton = (label, onClick, variant, className = '') => {
      return (
        <Button
          className={cx(isMobile ? classes.actBtn2 : classes.actBtn, className)}
          onClick={onClick}
          variant={variant}
        >
          {label}
        </Button>
      );
    };

    return (
      <>
        <AppointmentScheduling
          hideCancelBtn
          open={showReschedule}
          onClose={onCloseSlider}
          updateAppointment={updateAppointment}
          appointment={appointment}
        />
        <AppointmentCancel
          open={showCancel}
          onClose={onCloseSlider}
          appointment={appointment}
          updateAppointment={updateAppointment}
        />
        {/* New Drawer Opened on Mark as no show */}
        <AppointmentCancel
          markNoShow={openMarkAsNoShow}
          open={openMarkAsNoShow}
          onClose={onCloseSlider}
          appointment={appointment}
          updateAppointment={updateAppointment}
          handleMarkNoShowClick={markAsNoShowClick}
        />
        {/* EOL */}
        <Box className={classes.boxRight}>
          <Box className={classes.block}>
            <Heading level={headingLevel.M} weight={fontWeight.BOLD} className={classes.topTitle}>
              Basic Information
            </Heading>
            <Box display="flex" flexDirection="column" gap={3}>
              {renderInfo(
                'patient',
                `${appointment?.patientFirstName || ''} ${appointment?.patientLastName || ''}`,
              )}
              {renderInfo(
                'Date of birth',
                demographicDetails?.dateOfBirth
                  ? dayjs(demographicDetails?.dateOfBirth).format('MM/DD/YYYY')
                  : 'N/A',
              )}
              {renderInfo('UUID', demographicDetails?.uuid)}
              {renderInfo('Primary concern', appointment?.primaryConcern)}
              {renderInfo(
                'Phone number',
                demographicDetails?.mobilePhone ? demographicDetails?.mobilePhone : 'N/A',
              )}
              <Box />
            </Box>
            {!isCompleted && (
              <Box display="flex" flexDirection="column" gap={2}>
                {renderButton('Reschedule Appointment', editAppointmentClick, btnType.PRIMARY)}
                {renderButton(
                  'Cancel appointment',
                  cancelAppointmentClick,
                  btnType.SECONDARY,
                  classes.cancelBtn,
                )}
                {renderButton('Mark as no show', markNoShowClick, btnType.OUTLINE, classes.noShowBtn)}
                {renderButton(
                  'Call completed by phone',
                  () => {
                    completeByPhoneClick('PHONE');
                  },
                  btnType.OUTLINE,
                  classes.completedBtn,
                )}
                {/* {renderButton(
                  'Completed in person',
                  () => {
                    completeByPhoneClick('IN_PERSON');
                  },
                  btnType.OUTLINE,
                  classes.completedBtn,
                )} */}
                {showInPersonSession &&
                  renderButton(
                    'Start in person session',
                    () => {
                      startInPersonCall();
                    },
                    btnType.OUTLINE,
                    classes.completedBtn,
                  )}
              </Box>
            )}
          </Box>
          <Box className={classes.block}>
            <Heading level={headingLevel.M} weight={fontWeight.BOLD} className={classes.topTitle}>
              What&apos;s next
            </Heading>
            <Box display="flex" flexDirection="column" gap={3}>
              {nextAppointments.length > 0 ? (
                nextAppointments
                  ?.filter(appt => appt?.status !== AppointmentStatus.CANCELLED)
                  .map(renderNextItem)
              ) : (
                <Text className={classes.nextTitle}>No more appointments today. Nice work!</Text>
              )}
            </Box>
          </Box>
        </Box>
      </>
    );
  },
);

AppointmentInfo.displayName = 'AppointmentInfo';

export { AppointmentInfo };
