import { Box } from '@mui/material';
import { useStyles } from './index.styles';

const BoxFrame = ({ children, ...rest }) => {
  const classes = useStyles();
  return (
    <Box className={classes.root} {...rest}>
      {children}
    </Box>
  );
};

export default BoxFrame;
