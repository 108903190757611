import { FILTER_COLLAPSABLE_SECTION_TYPE } from '../../../packages/ui/organisms/table/filter';

export const tableColumns = [
  { id: 'name', label: 'Name', sortable: true },
  { id: 'relatedElements', label: 'Related elements', sortable: false },
  { id: 'type', label: 'Domain type', sortable: false },
  { id: 'actions', label: '', sortable: false },
];

export const filterOptionsMock = [{ label: 'Type 1', value: 'type_01' }];

export const multiSelectFilterOptionsMock = [
  {
    sectionTitle: 'Domain Type',
    sectionType: FILTER_COLLAPSABLE_SECTION_TYPE.DEFAULT,
    sectionOptions: [
      { label: 'Diagnoses', value: 'Diagnoses' },
      { label: 'Symptoms', value: 'Symptoms' },
      { label: 'Social Determinants', value: 'Social Determinants' },
      { label: 'Substance Use', value: 'Substance Use' },
      { label: 'Medications', value: 'Medications' },
      { label: 'Side Effects', value: 'Side Effects' },
      { label: 'Life Events', value: 'Life Events' },
    ],
  },
];
