import { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import clsx from 'clsx';

import { fontWeight, Heading, headingLevel, TextError } from '../../../../../packages/ui/atoms/typography';
import { Textarea } from '../../../../../packages/ui/atoms/textarea';
import { Select, selectType } from '../../../../../packages/ui/atoms/select';
import { getDomainElements } from '../../../../../services/member/member.service';

import { HistoryLookup } from '../../../../../redux/modules/profile/types';

import SelectUnit from '../select-unit';
import SelectLevel from '../select-level';
import { ReportItem } from '../AddNewReport.types';

// styles
import { useStyles } from './SubstanceForm.styles';

const SubstanceForm = ({ form, error, lookupData, options, substance, onChangeHandler, state }) => {
  const [importanceLevel, setImportanceLevels] = useState([]);
  const classes = useStyles();
  const { touched, errors, ...rest } = form;

  const setTouched = name => async () => {
    await rest.setTouched({ ...touched, [name]: true });
  };

  const onChangeSelectUnit = name => value => {
    // await setFieldValue(name, value);
    onChangeHandler(name, value);
  };

  const renderLabel = (str, className = '') => (
    <Heading level={headingLevel.S} className={clsx(classes.label, className)} weight={fontWeight.BOLD}>
      {str}
    </Heading>
  );

  useEffect(() => {
    if (substance) {
      if (substance?.domainElementId) {
        const tempImportanceLevels = [];
        getDomainElements({ domainTypeId: substance?.domainElementId })
          .then(res => {
            if (res.status === 200) {
              Object.keys(res?.data?.metaData?.metaDataSpec?.importanceLevels).forEach(key => {
                if (res?.data?.metaData?.metaDataSpec?.importanceLevels[key]) {
                  tempImportanceLevels.push({ value: key, label: key });
                }
              });
              setImportanceLevels(tempImportanceLevels);
            }
          })
          .catch(err => {
            console.log({ err });
          });
      } else {
        const selectedDiagnosis = options?.filter(option => option?.groupId === substance?.groupId);
        const selectedRelatedElement = selectedDiagnosis[0].relatedElements?.filter(
          element => element.Id === substance.item.value,
        );

        const tempImportanceLevels = [];
        Object.keys(selectedRelatedElement[0]?.metaData?.metaDataSpec?.importanceLevels).forEach(key => {
          if (selectedRelatedElement[0]?.metaData?.metaDataSpec?.importanceLevels[key]) {
            tempImportanceLevels.push({ value: key, label: key });
          }
        });
        setImportanceLevels(tempImportanceLevels);
      }
    }
  }, [substance]);

  return (
    <>
      <Box className={classes.formContent}>
        {error && (
          <Box display="flex" justifyContent="center">
            <TextError errorMsg={error} />
          </Box>
        )}
        <Box className={classes.section}>
          {renderLabel('Importance level')}
          <SelectLevel
            value={state.status}
            isLifeEvent
            options={importanceLevel}
            onChange={event => onChangeHandler('status', event.target.value)}
          />
        </Box>
        <Box className={classes.section}>
          {renderLabel('Method of use')}
          <Select
            options={lookupData?.methodsOfSubstanceUse.map(item => ({
              label: item.value,
              value: item.name,
            }))}
            value={state.method}
            name="method"
            onChange={event => onChangeHandler('method', event.target.value)}
            variant={selectType.SECONDARY}
            fullWidth
          />
        </Box>
        <Box display="flex" justifyContent="space-between" gap="16px">
          <Box className={classes.section} flex={1}>
            {renderLabel('Dose')}
            <SelectUnit
              value={state.dose.amount}
              unit={state.dose.unit}
              options={lookupData?.unitsOfSubstanceUse.map(item => ({
                label: item.value,
                value: item.name,
              }))}
              onChange={onChangeSelectUnit('dose')}
            />
            <TextError errorMsg={errors.dose?.amount} />
          </Box>
          <Box className={classes.section} flex={1}>
            {renderLabel('Frequency')}
            <Select
              options={lookupData?.currentFrequencyOfSubstanceUse.map(item => ({
                label: item.value,
                value: item.name,
              }))}
              value={state.frequency}
              name="frequency"
              onChange={event => onChangeHandler('frequency', event.target.value)}
              variant={selectType.SECONDARY}
            />
            <TextError errorMsg={errors.frequency} />
          </Box>
          <Box className={classes.section} flex={1}>
            {renderLabel('Duration')}
            <Select
              options={lookupData?.continuousLevelOfSubstanceUse.map(item => ({
                label: item.value,
                value: item.name,
              }))}
              value={state.duration}
              name="duration"
              onChange={event => onChangeHandler('duration', event.target.value)}
              variant={selectType.SECONDARY}
            />
            <TextError errorMsg={errors.duration?.amount} />
          </Box>
        </Box>
        <Box className={classes.section}>
          {renderLabel('Last use')}
          <Select
            options={lookupData?.lastSubstanceUse.map(item => ({ label: item.value, value: item.name }))}
            value={state.lastUse}
            name="lastUse"
            onChange={event => onChangeHandler('lastUse', event.target.value)}
            variant={selectType.SECONDARY}
            fullWidth
          />
        </Box>
        <Box className={classes.section}>
          {renderLabel('Report notes')}
          <Textarea
            value={state.notes}
            name="notes"
            onChange={event => onChangeHandler('notes', event.target.value)}
            onBlur={setTouched('notes')}
            minRows={5}
            placeholder="Enter your notes"
          />
          <TextError errorMsg={touched.notes && state?.notes?.length === 0 ? 'Notes is required' : null} />
        </Box>
      </Box>
    </>
  );
};

export { SubstanceForm };
