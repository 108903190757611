import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Box } from '@mui/material';

import { profileActionCreators } from '../../../../redux/modules/profile';
import { scheduleActionCreators } from '../../../../redux/modules/schedule';
import { stateActionCreators } from '../../../../redux/modules/state';
import { selectProviderServicesState } from '../../../../redux/modules/appointment/selectors';
import { getProfile } from '../../../../redux/modules/profile/selectors';
import { selectProviderSchedule } from '../../../../redux/modules/schedule/selectors';

import EditCredentials from './credentials';
import EditClinicAffiliation from './clinic-affiliation';
import EditEducation from './education';
import Card from './card';
import {
  EducationExperienceSections,
  certificationsMock,
  credentialsMock,
  educationExperienceSections,
} from '../index.constants';

const EducationExperience = () => {
  const dispatch = useDispatch();

  const { providerDetails } = useSelector(getProfile);
  const { services } = useSelector(selectProviderServicesState);
  const providerSchedule = useSelector(selectProviderSchedule);

  const [errorMsg, setErrorMsg] = useState(null);
  const [sections, setSections] = useState([]);
  const [editSection, setEditSection] = useState(null);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    dispatch(stateActionCreators.fetchPayerGroups());
    dispatch(stateActionCreators.fetchCPTs());
  }, []);

  const getContent = id => {
    switch (id) {
      case EducationExperienceSections.CLINIC: {
        return {
          affiliation: {
            avatar: providerDetails?.providerProfile?.affiliationImage,
            title: providerDetails?.providerProfile?.affiliationName?.[0],
            description: providerDetails?.providerProfile?.affiliationPlace?.[0],
            startDate: providerDetails?.providerProfile?.affiliationStartDate,
            endDate: providerDetails?.providerProfile?.affiliationEndDate,
          },
        };
      }
      case EducationExperienceSections.PAST_EMPLOYMENT: {
        return {
          affiliation: {
            avatar: providerDetails?.providerProfile?.employmentImage,
            title: providerDetails?.providerProfile?.employmentName?.[0],
            description: providerDetails?.providerProfile?.employmentPlace?.[0],
            startDate: providerDetails?.providerProfile?.employmentStartDate,
            endDate: providerDetails?.providerProfile?.employmentEndDate,
          },
        };
      }
      case EducationExperienceSections.EDUCATION: {
        return {
          affiliation: {
            avatar: providerDetails?.providerProfile?.educationImage,
            title: providerDetails?.providerProfile?.educationName?.[0],
            description: providerDetails?.providerProfile?.educationDescription,
            startDate: providerDetails?.providerProfile?.educationStartDate,
            endDate: providerDetails?.providerProfile?.educationEndDate,
          },
        };
      }
      case EducationExperienceSections.CREDENTIALS: {
        return {
          badges: providerDetails?.providerProfile?.credentials?.sort((a, b) => (a > b ? 1 : -1)) || [],
        };
      }
      case EducationExperienceSections.CERTIFICATIONS: {
        return {
          badges: providerDetails?.providerProfile?.certifications?.sort((a, b) => (a > b ? 1 : -1)) || [],
        };
      }
      default:
        return { services };
    }
  };

  useEffect(() => {
    const newSections = educationExperienceSections?.map(item => ({
      ...item,
      content: getContent(item.id),
    }));
    setSections(newSections);
  }, [educationExperienceSections, providerDetails]);

  const onEdit = sectionId => {
    setErrorMsg(null);
    setEditSection(sectionId);
  };

  const onCloseEdit = () => {
    setEditSection(null);
  };

  const onUpdateSuccess = () => {
    setIsSaving(false);
    onCloseEdit();
  };

  const onSubmit = payload => {
    try {
      setErrorMsg(null);
      const isActivePayload = payload?.active
        ? JSON.parse(payload?.active)
        : providerDetails?.active || false;

      const data = {
        affiliationImage: payload?.affiliationImage || providerDetails?.providerProfile?.affiliationImage,
        affiliationName: payload?.affiliationName || providerDetails?.providerProfile?.affiliationName,
        affiliationPlace: payload?.affiliationPlace || providerDetails?.providerProfile?.affiliationPlace,
        approach: payload?.approach || providerDetails?.approach,
        certifications: payload?.certifications || providerDetails?.providerProfile?.certifications,
        credentials: payload?.credentials || providerDetails?.providerProfile?.credentials,
        educationDescription:
          payload?.educationDescription || providerDetails?.providerProfile?.educationDescription,
        educationEndDate: payload?.educationEndDate || providerDetails?.providerProfile?.educationEndDate,
        profilePicture: providerDetails?.profileImage,
        educationImage: payload?.educationImage || providerDetails?.providerProfile?.educationImage,
        educationName: payload?.educationName || providerDetails?.providerProfile?.educationName,
        educationPlace: payload?.educationPlace || providerDetails?.providerProfile?.educationPlace,
        educationStartDate:
          payload?.educationStartDate || providerDetails?.providerProfile?.educationStartDate,
        employmentEndDate: payload?.employmentEndDate || providerDetails?.providerProfile?.employmentEndDate,
        employmentImage: payload?.employmentImage || providerDetails?.providerProfile?.employmentImage,
        employmentName: payload?.employmentName || providerDetails?.providerProfile?.employmentName,
        employmentPlace: payload?.employmentPlace || providerDetails?.providerProfile?.employmentPlace,
        employmentStartDate:
          payload?.employmentStartDate || providerDetails?.providerProfile?.employmentStartDate,
        firstName: payload?.firstName || providerDetails?.firstName,
        lastName: payload?.lastName || providerDetails?.lastName,
        fullName: payload?.fullName || providerDetails?.fullName,
        active: payload?.active !== null ? isActivePayload : providerDetails?.active,
        bio: payload?.bio,
        providerRole: payload?.providerRole || providerDetails?.providerProfile?.providerRole,
        twoFAEnabled: payload?.twoFAEnabled !== null ? payload?.twoFAEnabled : providerDetails?.twoFAEnabled,
        speciality: payload?.speciality?.length > 0 ? payload?.speciality : providerDetails?.speciality,
        phoneNumber: payload?.phoneNumber !== null ? payload?.phoneNumber : providerDetails?.phoneNumber,
        signOffRole: payload?.signOffRole || providerDetails?.signOffRole,
        designation: payload?.designation !== null ? payload?.designation : providerDetails?.designation,
      };
      if (payload?.timezone !== providerSchedule?.timezone) {
        dispatch(
          scheduleActionCreators.updateProviderSchedule({
            providerId: providerDetails?.providerId,
            data: {
              timezone: payload?.timezone || providerSchedule?.timezone,
            },
          }),
        );
      }
      dispatch(
        profileActionCreators.updateProviderGeneralInfo({
          id: providerDetails?.providerId,
          data,
          onSuccess: onUpdateSuccess,
        }),
      );
    } catch (err) {
      setErrorMsg(err.data?.errors?.[0]?.endUserMessage || 'Unable to update data');
      setIsSaving(false);
    }
  };

  const editContent = getContent(editSection);

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      {sections.map((section, idx) => (
        <Card {...section} key={idx} onEdit={() => onEdit(section.id)} />
      ))}
      <EditCredentials
        type={editSection}
        title={editSection === EducationExperienceSections.CERTIFICATIONS ? 'Certifications' : 'Credentials'}
        isOpen={[
          EducationExperienceSections.CERTIFICATIONS,
          EducationExperienceSections.CREDENTIALS,
        ].includes(editSection)}
        onClose={onCloseEdit}
        error={errorMsg}
        selectedItems={editContent.badges}
        items={
          editSection === EducationExperienceSections.CERTIFICATIONS ? certificationsMock : credentialsMock
        }
        onUpdate={onSubmit}
        isSaving={isSaving}
      />
      <EditEducation
        isOpen={editSection === EducationExperienceSections.PAST_EMPLOYMENT}
        onClose={onCloseEdit}
        error={errorMsg}
        onUpdate={onSubmit}
        data={editContent.affiliation}
        isSaving={isSaving}
      />
      <EditEducation
        isOpen={editSection === EducationExperienceSections.EDUCATION}
        onClose={onCloseEdit}
        isEducation
        onUpdate={onSubmit}
        error={errorMsg}
        data={editContent.affiliation}
        isSaving={isSaving}
      />
      <EditClinicAffiliation
        isOpen={editSection === EducationExperienceSections.CLINIC}
        onClose={onCloseEdit}
        error={errorMsg}
        onUpdate={onSubmit}
        clinicAffiliation={editContent.affiliation}
        isSaving={isSaving}
      />
    </Box>
  );
};

export default EducationExperience;
