/* eslint-disable max-len */
import cx from 'clsx';

// types
// styles
import { useStyles } from './shared/styles';

const ChevronUp = ({ className, color, onClick }) => {
  const classes = useStyles();
  return (
    <svg
      className={cx({
        [classes.root]: true,
        [className || '']: className,
      })}
      width="24"
      height="24"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M8.95994 0.389447C8.77258 0.201858 8.5184 0.0963469 8.25327 0.0961132H7.7466C7.48205 0.0991859 7.2289 0.204268 7.03994 0.389447L0.199938 7.24278C0.137452 7.30476 0.0878562 7.37849 0.0540104 7.45973C0.0201646 7.54097 0.00273895 7.62811 0.00273895 7.71611C0.00273895 7.80412 0.0201646 7.89126 0.0540104 7.9725C0.0878562 8.05374 0.137452 8.12747 0.199938 8.18945L1.1466 9.13611C1.20744 9.1982 1.28004 9.24752 1.36017 9.28119C1.44031 9.31487 1.52635 9.33221 1.61327 9.33221C1.70019 9.33221 1.78624 9.31487 1.86637 9.28119C1.9465 9.24752 2.01911 9.1982 2.07994 9.13611L7.99994 3.20278L13.9199 9.13611C13.9819 9.1986 14.0556 9.2482 14.1369 9.28204C14.2181 9.31589 14.3053 9.33331 14.3933 9.33331C14.4813 9.33331 14.5684 9.31589 14.6497 9.28204C14.7309 9.2482 14.8046 9.1986 14.8666 9.13611L15.7999 8.18945C15.8624 8.12747 15.912 8.05374 15.9459 7.9725C15.9797 7.89126 15.9971 7.80412 15.9971 7.71611C15.9971 7.62811 15.9797 7.54097 15.9459 7.45973C15.912 7.37849 15.8624 7.30476 15.7999 7.24278L8.95994 0.389447Z"
        fill={color}
      />
    </svg>
  );
};

export default ChevronUp;
