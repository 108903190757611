import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'clsx';
import { Stack } from '@mui/material';
import {
  Badge,
  badgeStyle,
  badgeType,
  colors,
  fontWeight,
  Heading,
  headingLevel,
  iconBtnType,
  IconButton,
  Icons,
  Menu,
  Table,
  Text,
} from '../../../packages';
import { ConfirmationModal } from '../../../packages/ui/templates/confirmation-modal';
import { BaseLayout } from '../../../layouts/base';
import { priorityActionCreators } from '../../../redux/modules/priority';
import { getPriority } from '../../../redux/modules/priority/selectors';
import { tableColumns } from './PriorityTypes.constants';
import { useStyles } from './PriorityTypes.styles';
import AddEditPriorityType from './components/add-edit-type';

const PriorityTypes = () => {
  const [showAddNew, setShowAddNew] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);

  const [value, setValue] = useState({
    search: { searchKey: '' },
    pagination: { currentPage: 1, rowsPerPage: 10 },
    sorter: { direction: 'asc', column: '' },
  });
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    isLoading,
    typesPayload: { totalRecords, domainTypes },
    groupsPayload: { domainGroups },
  } = useSelector(getPriority);

  const toggleAddNew = () => {
    setShowAddNew(!showAddNew);
  };

  const closeConfirmation = () => {
    setSelectedRecord(null);
    setShowConfirmModal(false);
  };

  const handleFetchTypesParamsFormat = ({ search, pagination, sorter }) => {
    const sortBy = sorter.column || '';
    const params = {
      searchQuery: search.searchKey,
      pageNumber: pagination.currentPage,
      pageSize: pagination.rowsPerPage,
      orderBy: sorter.direction,
      sortBy,
    };
    return params;
  };

  const fetchDomainTypes = () => {
    dispatch(priorityActionCreators.fetchPriorityDomainGroups());
    dispatch(priorityActionCreators.fetchPriorityDomainTypes(handleFetchTypesParamsFormat(value)));
  };

  useEffect(() => {
    fetchDomainTypes();
  }, [value]);

  const onDeleteType = () => {
    dispatch(
      priorityActionCreators.deletePriorityDomainType({
        data: selectedRecord?.Id,
        callback: isSucceed => {
          if (isSucceed) {
            fetchDomainTypes();
          }
          closeConfirmation();
        },
      }),
    );
  };

  const renderCheckIcon = val => (
    <Icons
      glyph={val ? 'checkmark' : 'close-circle-outlined'}
      color={val ? colors.success500 : colors.destructive500}
    />
  );

  const renderGroup = typeId => {
    const filteredGroups = domainGroups?.filter(group => group.typeId === typeId) || [];
    if (filteredGroups.length === 0) {
      return (
        <Text className={classes.name} weight={fontWeight.MEDIUM}>
          0 Groups
        </Text>
      );
    }
    return (
      <Text className={classes.name} weight={fontWeight.MEDIUM}>
        {filteredGroups.length
          .toString()
          .concat(' Group')
          .concat(filteredGroups.length > 1 ? 's' : '')}
      </Text>
    );
  };

  const renderElement = totalChildren => {
    if (totalChildren === 0) {
      return (
        <Text className={classes.name} weight={fontWeight.MEDIUM}>
          0 Elements
        </Text>
      );
    }
    return (
      <Text className={classes.name} weight={fontWeight.MEDIUM}>
        {totalChildren
          .toString()
          .concat(' Element')
          .concat(totalChildren > 1 ? 's' : '')}
      </Text>
    );
  };

  const renderColumns = tableColumns.map(column => {
    if (
      column.id === 'requireNotes' ||
      column.id === 'requireRxInfo' ||
      column.id === 'requireRelatedToInfo' ||
      column.id === 'requireInterferenceWithLifeInfo'
    ) {
      return {
        ...column,
        renderCell: renderCheckIcon,
      };
    }
    if (column.id === 'importanceLevels') {
      return {
        ...column,
        renderCell: importanceLevels => (
          <Text className={cx(classes.name, classes.importanceLevels)} weight={fontWeight.MEDIUM}>
            {importanceLevels}
          </Text>
        ),
      };
    }
    if (column.id === 'relatedGroups') {
      return {
        ...column,
        renderCell: renderGroup,
      };
    }
    if (column.id === 'relatedElements') {
      return {
        ...column,
        renderCell: renderElement,
      };
    }
    if (column.id === 'actions') {
      return {
        ...column,
        renderCell: data => (
          <Menu
            icon="more"
            className={classes.menu}
            itemsWrapperClassName={classes.menuItemsWrapper}
            items={[
              {
                label: 'Edit',
                onClick: () => {
                  setSelectedRecord(data);
                  setShowAddNew(true);
                },
              },
              {
                label: 'Delete',
                onClick: () => {
                  setSelectedRecord(data);
                  setShowConfirmModal(true);
                },
              },
            ]}
          />
        ),
      };
    }
    return column;
  });

  return (
    <>
      {showAddNew && (
        <AddEditPriorityType
          isOpen={showAddNew}
          onClose={() => {
            setSelectedRecord(null);
            toggleAddNew();
          }}
          fetchDomainTypes={fetchDomainTypes}
          editData={selectedRecord}
        />
      )}
      <ConfirmationModal
        open={showConfirmModal}
        onClose={closeConfirmation}
        onSave={onDeleteType}
        title="Delete Domain Type"
        message="Are you sure?"
      />
      <BaseLayout>
        <div className={classes.root}>
          <Stack direction="row" justifyContent="space-between" spacing={2}>
            <Stack direction="row" alignItems="center" gap={2}>
              <Heading className={classes.heading} level={headingLevel.XL} weight={fontWeight.BOLD}>
                Data domain types
              </Heading>
              <Badge
                className={classes.totalMemberBadge}
                variant={badgeType.OUTLINED}
                style={badgeStyle.UNRELATED}
              >
                {totalRecords} total
              </Badge>
            </Stack>

            <IconButton
              className={classes.addProfileBtn}
              icon="plus"
              variant={iconBtnType.PRIMARY}
              onClick={toggleAddNew}
            >
              Add type
            </IconButton>
          </Stack>
          <Table
            searchProps={{
              placeholder: 'Search name',
            }}
            gridProps={{
              columns: renderColumns,
              data: domainTypes,
              isLoading,
            }}
            paginationProps={{
              currentRows: domainTypes?.length,
              totalCount: totalRecords,
              showRowsPerPage: true,
            }}
            value={value}
            onChange={setValue}
            className={classes.table}
          />
        </div>
      </BaseLayout>
    </>
  );
};

export { PriorityTypes };
