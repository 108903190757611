import { CbStatus } from '../redux/modules/conversation/types';

export class EvaluationHelper {
  constructor() {
    this.head = null;
    this.tail = null;
  }

  getHead() {
    return this.head;
  }

  setHead(head) {
    this.head = head;
  }

  getStatus(block) {
    if (block && !block.isAttemptable) {
      return CbStatus.NOT_APPLICABLE;
    }
    if (
      block &&
      block.userResponse &&
      block.userResponse.userResponseList &&
      block.userResponse.userResponseList.length > 0
    ) {
      return CbStatus.ANSWERED;
    }
    return CbStatus.NOT_ATTEMPTED;
  }

  isEvaluationCompleted() {
    let block = this.head;
    while (block) {
      if (
        block.required &&
        block.isAttemptable &&
        (!block.userResponse || block.userResponse.userResponseList.length === 0)
      ) {
        return false;
      }
      block = block.next;
    }
    return true;
  }

  addNode(block) {
    const isDct = block?.cbType === 'dct';
    const node = {
      next: null,
      status: this.getStatus(block),
      text: block?.text ?? block?.title,
      subtitle: isDct ? block?.description : block.subtitle,
      cbId: block.cbId,
      cbType: block.cbType,
      required: block.required,
      dctId: block.dctId,
      dctScore: isDct ? block.dctScore : null,
      isAttemptable: block.isAttemptable,
      isDCTScoreable: isDct ? block.isDCTScoreable : null,
      fullPage: isDct ? block.fullPage : false,
      usePreviousResponses: block.usePreviousResponses,
      responderType: block.responderType || 'MEMBER',
      cbProfileElementDetails: block.cbProfileElementDetails || [],
      choices: block.choices || [],
      children: [],
      userResponse: block.userResponse || null,
      helperText: block.helperText,
      evaluationResponses: block.userResponse?.userResponseList || [],
      prompt: block.prompt,
      ratingScale: block.ratingScale || null,
      educationContentSlug: block.educationContentSlug,
      exerciseContent: block.exerciseContent,
    };
    if (!this.head) {
      node.isFirst = true;
      this.head = node;
      this.tail = node;
    } else {
      this.tail.next = node;
      this.tail = this.tail.next;
    }
    return node;
  }

  updateValues(payload, evaluationHead) {
    const { id, value, narrative, blockId } = payload;
    this.setHead(evaluationHead);
    const node = this.getContentBlock(id);

    const updateNodeResponses = (block, blockValue, blockNarrative) => {
      if (block.userResponse) {
        if (block.cbType === 'text-input') {
          if (blockNarrative || blockNarrative === '') {
            block.userResponse.providerNarrative = blockNarrative;
          }
          if (blockValue || blockValue === '') {
            const response = blockValue !== '' ? [blockValue] : [];

            block.userResponse.userResponseList = response;
            block.evaluationResponses = response;
          }
        } else {
          const { userResponseList } = block.userResponse;
          if (blockNarrative || blockNarrative === '') {
            block.userResponse.providerNarrative = blockNarrative;
          } else {
            const valueIndex = userResponseList.indexOf(blockValue);
            if (valueIndex !== -1) {
              userResponseList.splice(valueIndex, 1);
            } else if (block.cbType === 'single-select' || block.cbType === 'rating-scale') {
              block.userResponse.userResponseList = [blockValue];
              block.evaluationResponses = [blockValue];
            } else {
              userResponseList.push(blockValue);
              block.evaluationResponses?.push(blockValue);
            }
          }
        }
      } else if (block.cbType === 'education' || block.cbType === 'exercise') {
        block.userResponse = {
          userResponseList: blockValue,
          providerNarrative: blockNarrative,
          score: 0,
        };
        block.evaluationResponses = blockValue;
      } else {
        block.userResponse = {
          userResponseList: blockValue ? [blockValue] : [],
          providerNarrative: blockNarrative || '',
          score: 0,
        };
        block.evaluationResponses = blockValue ? [blockValue] : [];
      }
    };

    if (node.cbType === 'dct' && node.fullPage) {
      let response = null;
      node.children.forEach(child => {
        if (child.cbId === blockId) {
          updateNodeResponses(child, value, narrative);
          response = {
            cbId: child.cbId,
            providerNarrative: child.userResponse?.providerNarrative || '',
            evaluationResponses: child.userResponse?.userResponseList || [],
          };
        }
      });
      if (response) {
        // Check if response already exists for cb
        const existingResponseIndex = node.evaluationResponses.findIndex(res => res.cbId === blockId);
        if (existingResponseIndex !== -1) {
          // Update the existing response
          node.evaluationResponses[existingResponseIndex] = response;
        }
        // add a new response
        else node.evaluationResponses.push(response);
      }
    } else updateNodeResponses(node, value, narrative);
  }

  transformEvaluation(data) {
    this.head = null;
    this.tail = null;
    data.cbResponseList.forEach(dct => {
      const dctNode = this.addNode(dct);
      if (dct.cbType === 'dct') {
        if (dct.dctContentBlockList.length && dct.dctContentBlockList.length > 0) {
          dct.dctContentBlockList.forEach(cb => {
            if (cb.cbType !== 'dct') {
              const childItem = {
                ...cb,
                dctId: dct.dctId,
                responderType: dct.responderType,
                isAttemptable: !dct.isAttemptable ? false : cb.isAttemptable,
              };
              if (!dct.fullPage) {
                const cbNode = this.addNode(childItem);
                dctNode.children.push(cbNode);
              } else {
                dctNode.children.push(childItem);
                const response = {
                  cbId: cb.cbId,
                  providerNarrative: cb.userResponse?.providerNarrative || '',
                  evaluationResponses: cb.userResponse?.userResponseList || [],
                };

                const hasNarrative = response.providerNarrative.trim() !== '';
                const hasResponse = response.evaluationResponses.length > 0;

                if (hasNarrative || hasResponse) dctNode.evaluationResponses.push(response);
              }
            }
          });
        }
      }
    });
  }

  createLinkedListFrom(list) {
    this.head = null;
    this.tail = null;
    list.forEach(cb => {
      this.addNode(cb);
    });
  }

  getNextApplicableBlock(cbId, onlyUnanswered) {
    let currentBlock = this.getContentBlock(cbId);
    while (currentBlock) {
      if (currentBlock.next && currentBlock.next.isAttemptable) {
        if (onlyUnanswered) {
          if (
            currentBlock.next.status !== CbStatus.ANSWERED &&
            currentBlock.next.cbType !== 'education' &&
            currentBlock.next.cbType !== 'exercise'
          ) {
            return currentBlock.next;
          }
        } else {
          return currentBlock.next;
        }
      }
      currentBlock = currentBlock.next;
    }
    return null;
  }

  getPreviousApplicableBlock(cbId, onlyUnanswered) {
    let currentBlock = this.getPreviousNode(cbId);
    while (currentBlock) {
      if (currentBlock.isAttemptable) {
        if (onlyUnanswered) {
          if (
            currentBlock.status !== CbStatus.ANSWERED &&
            currentBlock.cbType !== 'education' &&
            currentBlock.cbType !== 'exercise'
          ) {
            return currentBlock;
          }
        } else {
          return currentBlock;
        }
      }
      currentBlock = this.getPreviousNode(currentBlock.cbId);
    }
    return null;
  }

  getContentBlock(cbId) {
    let current = this.head;
    while (current) {
      if (current.cbId === cbId) {
        return current;
      }
      current = current.next;
    }
    return null;
  }

  getNextDct(cbId) {
    let current = this.getContentBlock(cbId);
    while (current) {
      current = current.next;
      if (current && current.cbType === 'dct') {
        return current;
      }
    }
    return null;
  }

  getDcts() {
    const sections = [];
    let current = this.head;
    while (current) {
      if (current.cbType === 'dct') {
        sections.push(current);
      }
      current = current.next;
    }
    return sections;
  }

  getQuestions() {
    const sections = [];
    let current = this.head;
    while (current) {
      if (current.cbType === 'dct' || !current.dctId) {
        sections.push(current);
      }
      current = current.next;
    }
    return sections;
  }

  getFilteredQuestions() {
    const sections = [];
    let current = this.head;
    while (current) {
      if (current.cbType !== 'dct') {
        sections.push(current);
      }
      current = current.next;
    }
    return sections;
  }

  getPreviousNode(cbId) {
    let current = this.head;
    while (current) {
      if (current.next && current.next.cbId === cbId) {
        return current;
      }
      current = current.next;
    }
    return null;
  }

  getContentBlocksByDct(dctId) {
    const blocks = [];
    let current = this.head;
    while (current) {
      if (current.dctId === dctId && current.cbType !== 'dct') {
        blocks.push(current);
      }
      current = current.next;
    }
    return blocks;
  }

  skipNonRequiredCB(cbId) {
    const block = this.getContentBlock(cbId);
    if (block) {
      block.status = CbStatus.SKIPPED;
    }
  }
}
