import { baseRequest } from '../../utils/HttpClient';
import { ApiEndpoints } from '../../constants/ApiEndpoints';

export const getAppointments = (bodyRequest, queryParams) => {
  return baseRequest(ApiEndpoints.GET_APPOINTMENTS_V4, bodyRequest, null, queryParams);
};
export const downloadAppointments = (payload, requestBody) => {
  return baseRequest(ApiEndpoints.GET_APPOINTMENTS_V4, requestBody || {}, null, payload, true, true, true);
};
export const getAppointmentCptCodeById = id => {
  return baseRequest(ApiEndpoints.GET_APPOINTMENT_CPT_CODE_BY_ID, null, { id });
};

export const addAppointmentCptCode = bodyRequest => {
  return baseRequest(ApiEndpoints.ADD_APPOINTMENT_CPT_CODE, bodyRequest);
};

export const updateAppointmentCptCode = (bodyRequest, pathParams) => {
  return baseRequest(ApiEndpoints.UPDATE_APPOINTMENT_CPT_CODE, bodyRequest, pathParams);
};

export const fetchAMAState = pathParams => {
  return baseRequest(ApiEndpoints.FETCH_AMA_STATE, null, pathParams, null, true);
};

export const postAMA = bodyRequest => {
  return baseRequest(ApiEndpoints.POST_AMA, bodyRequest, null);
};
