import { useEffect, useState, FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/material';
import clsx from 'clsx';

import { IconButton } from '../../../../../../packages';
import { fontWeight, Text } from '../../../../../../packages';
import { Button, btnType } from '../../../../../../packages';
import { Stepper } from '../../../../../../packages';
import { Icons } from '../../../../../../packages';
import { colors } from '../../../../../../packages';
import { getProfile } from '../../../../../../redux/modules/profile/selectors';
import { getAppointment } from '../../../../../../redux/modules/appointment/selectors';
import { profileActionCreators } from '../../../../../../redux/modules/profile';
import { appointmentActionCreators } from '../../../../../../redux/modules/appointment';
import * as memberService from '../../../../../../services/member/member.service';
import dayjs from '../../../../../../utils/dayjs';

import StepOne from '../../../../../admin/appointments/add-schedule/step-one/StepOne';
import StepThree from '../../../../../admin/appointments/add-schedule/step-three/StepThree';
import {
  StepTitles,
  ScheduleSteps,
  StepsNumber,
} from '../../../../../admin/appointments/add-schedule/AddSchedule.constants';
import { useStyles } from '../../../../../admin/appointments/add-schedule/AddSchedule.styles';
import StepFour from '../../../../../admin/appointments/add-schedule/step-four/StepFour';

const Schedules = ({ isEndedSession = false, appointment, onClose, callback }) => {
  const classes = useStyles();
  const [currentStep, setCurrentStep] = useState(ScheduleSteps.SELECT_MEMBER);
  const [errorMsg, setErrorMsg] = useState(null);
  const {
    payload: { patientsList },
    providers,
    isLoading,
  } = useSelector(getProfile);
  const { isRequesting } = useSelector(getAppointment);
  const dispatch = useDispatch();
  const [memberProfile, setMemberProfile] = useState({});
  const [memberList, setMemberList] = useState(patientsList);
  const [loader, setLoader] = useState(false);
  const [loadMore, setLoadMore] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchKey, setSearchKey] = useState('');
  const [formData, setFormData] = useState({
    member: null,
    provider: null,
    time: null,
    serviceId: null,
  });

  const onCloseClick = () => {
    if (currentStep === ScheduleSteps.SELECT_MEMBER) {
      onClose();
    } else {
      const prevStep =
        currentStep === ScheduleSteps.SELECT_SERVICE
          ? ScheduleSteps.SELECT_PROVIDER
          : ScheduleSteps.SELECT_MEMBER;
      setCurrentStep(prevStep);
    }
  };

  const onChangeFormData = key => payload => {
    errorMsg && setErrorMsg(null);
    setFormData({ ...formData, [key]: payload });
  };
  const fetchMembers = (searchQuery = '') => {
    // setLoader(true);
    dispatch(
      profileActionCreators.fetchPatients({
        searchQuery,
        pageNumber: pageNumber || 1,
        pageSize: 10,
        orderBy: 'asc',
        sortBy: '',
        patientId: appointment?.participantId || '',
      }),
    );
    if (appointment?.participantId && !searchQuery) {
      memberService
        .getAdminProfile({ userId: appointment?.participantId || '' })
        .then(res => {
          setMemberProfile(res.data);
          // setLoader(false);
        })
        .catch(err => {
          // setLoader(false);
          console.log(err);
        });
    }
    // else {
    //   setLoader(false);
    // }
  };
  const fetchProviders = (searchQuery = '') => {
    dispatch(
      profileActionCreators.fetchProviders({
        searchQuery,
        pageNumber: 1,
        pageSize: 1000,
        orderBy: 'asc',
        sortBy: '',
      }),
    );
  };

  useEffect(() => {
    if (appointment?.participantId) {
      setMemberList([
        {
          ...memberProfile,
          id: appointment?.participantId,
          nickName: memberProfile?.firstName || '',
          phone: memberProfile?.phoneNumber || '',
          email: memberProfile?.emailAddress || '',
          photo: memberProfile?.profileImage || '',
          fullName: `${memberProfile?.fullName || ''} ${memberProfile?.lastName || ''}`,
        },
        ...patientsList.filter(item => item.id !== appointment?.participantId),
      ]);
    } else {
      setMemberList(patientsList);
    }
  }, [memberProfile, patientsList]);

  const requestAppointment = payload => {
    dispatch(
      appointmentActionCreators.createAppointment({
        data: payload,
        callback: isSucceed => {
          if (isSucceed) {
            callback && callback();
            onClose();
          }
        },
      }),
    );
  };

  const onSubmitNewSchedule = payload => {
    requestAppointment(payload);
  };

  const onClickContinue = () => {
    if (currentStep === ScheduleSteps.SELECT_MEMBER) {
      if (!formData.member) {
        setErrorMsg('Please select a member!');
        return;
      }
    }
    if (currentStep === ScheduleSteps.SELECT_PROVIDER && !formData.provider) {
      setErrorMsg('Please select a provider!');
      return;
    }
    if (currentStep === ScheduleSteps.SELECT_SERVICE && !formData.serviceId) {
      setErrorMsg('Please select a service!');
      return;
    }
    if (currentStep === ScheduleSteps.SELECT_SERVICE && !formData.time) {
      setErrorMsg('Please select a time slot!');
      return;
    }

    if (currentStep !== ScheduleSteps.SELECT_REVIEW) {
      nextStep(currentStep);
    } else {
      onSubmitNewSchedule(formData);
    }
  };
  const nextStep = value => {
    switch (value) {
      case ScheduleSteps.SELECT_MEMBER:
        return setCurrentStep(ScheduleSteps.SELECT_PROVIDER);
      case ScheduleSteps.SELECT_PROVIDER:
        return setCurrentStep(ScheduleSteps.SELECT_SERVICE);
      default:
        return setCurrentStep(ScheduleSteps.SELECT_REVIEW);
    }
  };

  useEffect(() => {
    // fetchMembers();
    if (appointment?.participantId) {
      setLoader(true);
      memberService
        .getAdminProfile({ userId: appointment?.participantId || '' })
        .then(res => {
          setMemberProfile(res.data);
          setLoader(false);
        })
        .catch(err => {
          setLoader(false);
          console.log(err);
        });
    }
    fetchMembers('');
    fetchProviders();
  }, []);

  useEffect(() => {
    if (appointment) {
      let memberInSession = patientsList.find(item => item.member.id === appointment.participantId);
      const membersList = patientsList.filter(item => item.member.id !== appointment.participantId);

      if (memberInSession) {
        memberInSession.id = appointment.participantId;
      } else {
        memberInSession = {
          dob: '',
          email: appointment.participantEmail,
          firstName: '',
          fullName: appointment.participantName,
          fundsInWallet: 0,
          lastName: '',
          phone: appointment.participantPhoneNumber,
          totalCompletedSessions: 0,
          completed: 0,
          id: appointment.participantId,
          member: {
            photo: appointment.participantImage,
            id: appointment.participantId,
            nickName: appointment.participantName,
            firstName: '',
            lastName: '',
          },
        };
      }
      membersList.unshift(memberInSession);
      // setMembers(membersList);
      setFormData({ ...formData, member: memberInSession });
    }
  }, [patientsList]);

  useEffect(() => {
    setFormData({
      ...formData,
      provider: providers.find(item => item.providerId === appointment?.practitionerId) || providers[0],
    });
  }, [providers]);

  useEffect(() => {
    const fetchPatients = async () => {
      if (pageNumber !== 1 && pageNumber !== 0) {
        setLoadMore(true);
        const response = await memberService.getPatientsList(
          {
            searchQuery: searchKey,
            pageNumber,
            pageSize: 10,
            orderBy: 'asc',
            sortBy: '',
            // patientId: memberId || '',
          },
          null,
        );
        if (response.status === 200) {
          const { data } = response;

          const updatedPatientsList = data.patientsList.map(member => {
            return {
              member: {
                id: member.userId,
                nickName: member.fullName,
                fullName: `${member.firstName || ''} ${member.lastName || ''}`,
                firstName: member.firstName,
                lastName: member.lastName,
                photo: '',
              },
              email: member.email,
              phone: member.phoneNumber,
              activeChat: member.activeChat || false,
              completed: member.totalCompletedSessions,
              fundsInWallet: member.fundsInWallet,
              dob: member.dob ? dayjs(member.dob, 'YYYY-MM-DD').format('MM/DD/YYYY') : null,
              actions: {
                id: member.userId,
              },
            };
          });
          setLoadMore(false);

          setMemberList([...memberList, ...updatedPatientsList]);
        }
      }
    };
    fetchPatients().catch(console.error);
  }, [pageNumber]);

  const renderStep = step => {
    switch (step) {
      case ScheduleSteps.SELECT_MEMBER:
        return (
          <StepOne
            error={errorMsg}
            selectedItem={formData.member}
            fetchMembers={fetchMembers}
            onChange={onChangeFormData('member')}
            options={memberList.map(({ member, ...rest }) => ({ ...rest, ...member }))}
            selectType="member"
            userId={appointment?.participantId}
            setPageNumber={setPageNumber}
            isLoading={loadMore}
            pageNumber={pageNumber}
            setSearchKeyWithPagination={setSearchKey}
          />
        );
      case ScheduleSteps.SELECT_PROVIDER:
        return (
          <StepOne
            error={errorMsg}
            selectedPatient={formData.member}
            selectedItem={formData.provider}
            fetchProviders={fetchProviders}
            setPageNumber={setPageNumber}
            onChange={onChangeFormData('provider')}
            options={
              providers?.filter(
                providr => providr?.active && providr.schedule && providr.schedule.slots?.length > 0,
              ) || []
            }
            selectType="provider"
            userId={appointment?.practitionerId}
            setSearchKeyWithPagination={setSearchKey}
          />
        );
      case ScheduleSteps.SELECT_SERVICE:
        return (
          <StepThree
            error={errorMsg}
            formData={formData}
            selectedDateTime={dayjs().toISOString()}
            onChange={onChangeFormData('time')}
            onChangeService={onChangeFormData('serviceId')}
          />
        );
      default:
        return (
          <StepFour
            error={errorMsg}
            formData={formData}
            selectedDateTime={dayjs().toISOString()}
            onChange={onChangeFormData('time')}
            onChangeService={onChangeFormData('serviceId')}
          />
        );
    }
  };
  const saveText = isRequesting ? 'Saving...' : 'Schedule appointment';
  const showFooter =
    (currentStep === ScheduleSteps.SELECT_MEMBER && !!formData.member) ||
    (currentStep === ScheduleSteps.SELECT_PROVIDER && !!formData.provider) ||
    (currentStep === ScheduleSteps.SELECT_SERVICE && !!formData.time) ||
    currentStep === ScheduleSteps.SELECT_REVIEW;
  return (
    <Box className={classes.wrapper}>
      <Box className={classes.header}>
        <Box>
          <Text weight={fontWeight.BOLD} className={classes.headTitle}>
            Schedule next appointment
          </Text>
          <Text weight={fontWeight.BOLD} className={classes.headSubtitle}>
            {StepTitles[currentStep]}
          </Text>
        </Box>
        <Box className={classes.stepWrap}>
          <Stepper
            steps={['Member', 'Provider', 'Date & time', 'Review']}
            activeStep={StepsNumber[currentStep]}
          />
        </Box>
        <IconButton icon="close" className={classes.closeBtn} onClick={onClose} />
      </Box>
      <Box
        className={clsx(isEndedSession ? classes.content : classes.sessionContent, {
          [classes.showFooter]: showFooter,
          [classes.sessionShowFooter]: !showFooter,
        })}
      >
        {loader && isLoading ? (
          <div className={classes.loader}>
            <Icons className="rotate linear infinite" glyph="in-progress" color={colors.primary} />
            Loading...
          </div>
        ) : (
          renderStep(currentStep)
        )}
      </Box>
      {showFooter && (
        <Box className={classes.footer}>
          <Button variant={btnType.TEXT} onClick={onCloseClick}>
            Cancel
          </Button>
          <Button onClick={onClickContinue} disabled={isRequesting}>
            {currentStep === ScheduleSteps.SELECT_REVIEW ? saveText : 'Continue'}
          </Button>
        </Box>
      )}
    </Box>
  );
};

export { Schedules };
