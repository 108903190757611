import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Grid } from '@mui/material';
import { ProgressBar } from '../../../../../packages/ui/atoms/ProgressBar';
import { Badge, badgeSize, badgeStyle, badgeType } from '../../../../../packages/ui/atoms/badge';
import { fontWeight, Text, textLevel } from '../../../../../packages/ui/atoms/typography';
import { Icons } from '../../../../../packages/icons';
import { colors } from '../../../../../packages/colors';
import { CONTACT_NOTES_FLAGS } from '../../../../../packages/constants/CommonConstants';
import { getSpecialStatus } from '../../../../../packages/ui/templates/note-card';

import { getMemberNotes } from '../../../../../services/member/member.service';
import { NOTES_STATUS_CATEGORIES } from '../../../../admin/notes/NotesList.constants';

import history from '../../../../../utils/history';
import Card from './Card';

import { useStyles } from './index.styles';
import NoteDrawer from '../Notes/NoteDrawer';

const Notes = ({ isAdmin }) => {
  const classes = useStyles();

  const { memberId } = useParams();
  const [notes, setNotes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedNote, setSelectedNote] = useState(null);

  const fetchNotes = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await getMemberNotes({ userId: memberId });
      const listSorted = data.patientContactNotes;
      setNotes(listSorted);
      // eslint-disable-next-line no-empty
    } catch (error) {}
    setIsLoading(false);
  }, [memberId]);

  useEffect(() => {
    void fetchNotes();
  }, [fetchNotes]);

  const labNotes = useMemo(
    () => notes?.filter(note => note?.flag === CONTACT_NOTES_FLAGS.LAB_REQUEST) || [],
    [notes],
  );

  const requestNotes = useMemo(
    () => notes?.filter(note => note?.flag === CONTACT_NOTES_FLAGS.RELEASE_OF_INFORMATION) || [],
    [notes],
  );

  const handleSelectNote = note => {
    setSelectedNote(note);
  };

  const handleDeselectNote = () => {
    setSelectedNote(null);
  };

  const onUpdateNote = note => {
    setSelectedNote(note);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12} lg={6}>
        <Card
          label="Lab results"
          actions={[
            {
              label: 'View all',
              onClick: () =>
                history.push(`/${isAdmin ? 'admin' : 'provider'}/members/${memberId}?name=notes`),
            },
          ]}
        >
          <Box className={classes.flexColumn} sx={{ p: 4, gap: 3, height: 250 }}>
            {isLoading ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Icons className="rotate linear infinite" glyph="in-progress" color={colors.primary} />
              </Box>
            ) : (
              <>
                <Box className={classes.flexColumn} sx={{ gap: 1 }}>
                  <Box sx={{ fontSize: 16, fontWeight: 600, lineHeight: '24px' }}>
                    {labNotes?.filter(labNote => labNote.status === 'COMPLETED').length}/{labNotes.length}{' '}
                    resolved
                  </Box>
                  <ProgressBar
                    value={Math.round(
                      (labNotes?.filter(labNote => labNote.status === 'COMPLETED').length /
                        (labNotes.length || 1)) *
                        100,
                    )}
                    className={classes.labProgressBar}
                  />
                </Box>
                {labNotes?.slice(0, 2)?.map((labNote, index) => (
                  <Grid
                    container
                    alignItems="center"
                    key={index}
                    onClick={() => handleSelectNote(labNote)}
                    sx={{ cursor: 'pointer' }}
                  >
                    <Grid item xs={2}>
                      <Box className={classes.icon}>
                        <Icons glyph="lab-bottle-outlined" color={colors.green500} />
                      </Box>
                    </Grid>
                    <Grid item xs={5}>
                      <Box className={classes.flexColumn} sx={{ gap: 1 }}>
                        <Text weight={fontWeight.SEMI_BOLD} level={textLevel.S}>
                          {labNote.name}
                        </Text>
                        <Text weight={fontWeight.MEDIUM} level={textLevel.S} color={colors.neutral500}>
                          {labNote?.description}
                        </Text>
                      </Box>
                    </Grid>
                    <Grid item xs={5}>
                      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Badge
                          size={badgeSize.SMALL}
                          variant={
                            getSpecialStatus(labNote?.status) === NOTES_STATUS_CATEGORIES.NEEDS_ACTION
                              ? badgeType.OUTLINED
                              : badgeType.FILLED
                          }
                          style={badgeStyle.INACTIVE}
                        >
                          {labNote.status}
                        </Badge>
                      </Box>
                    </Grid>
                  </Grid>
                ))}
              </>
            )}
          </Box>
        </Card>
      </Grid>
      <Grid item xs={12} md={12} lg={6}>
        <Card
          label="Release of information"
          actions={[
            {
              label: 'View all',
              onClick: () =>
                history.push(`/${isAdmin ? 'admin' : 'provider'}/members/${memberId}?name=notes`),
            },
          ]}
        >
          <Box className={classes.flexColumn} sx={{ p: 4, gap: 3, height: 250 }}>
            {isLoading ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Icons className="rotate linear infinite" glyph="in-progress" color={colors.primary} />
              </Box>
            ) : (
              <>
                <Box className={classes.flexColumn} sx={{ gap: 1 }}>
                  <Box sx={{ fontSize: 16, fontWeight: 600, lineHeight: '24px' }}>
                    {requestNotes?.filter(labNote => labNote.status === 'COMPLETED').length}/
                    {requestNotes.length} resolved
                  </Box>
                  <ProgressBar
                    value={Math.round(
                      (requestNotes?.filter(labNote => labNote.status === 'COMPLETED').length /
                        (requestNotes.length || 1)) *
                        100,
                    )}
                    className={classes.labProgressBar}
                  />
                </Box>
                {requestNotes?.slice(0, 2)?.map((requestNote, index) => (
                  <Grid
                    container
                    alignItems="center"
                    key={index}
                    onClick={() => handleSelectNote(requestNote)}
                    sx={{ cursor: 'pointer' }}
                  >
                    <Grid item xs={2}>
                      <Box className={classes.releaseIcon}>
                        <Icons glyph="info-outlined" color={colors.indigo500} />
                      </Box>
                    </Grid>
                    <Grid item xs={5}>
                      <Box className={classes.flexColumn} sx={{ gap: 1 }}>
                        <Text weight={fontWeight.SEMI_BOLD} level={textLevel.S}>
                          {requestNote.name}
                        </Text>
                        <Text weight={fontWeight.MEDIUM} level={textLevel.S} color={colors.neutral500}>
                          {requestNote.description}
                        </Text>
                      </Box>
                    </Grid>
                    <Grid item xs={5}>
                      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Badge size={badgeSize.SMALL} style={badgeStyle.UNRELATED}>
                          {requestNote.status}
                        </Badge>
                      </Box>
                    </Grid>
                  </Grid>
                ))}
              </>
            )}
          </Box>
        </Card>
      </Grid>
      <NoteDrawer selectedNote={selectedNote} onClose={handleDeselectNote} onUpdateNote={onUpdateNote} />
    </Grid>
  );
};

export default Notes;
