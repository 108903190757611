import { makeStyles } from '@mui/styles';

// colors
import { colors } from '../../../../../../colors';

export const useStyles = makeStyles({
  container: {
    display: 'flex',
    padding: '0px 16px',
    alignItems: 'flex-start',
    gap: '32px',
    flex: '1 0 0',
    alignSelf: 'stretch'
  },
  leftSection: {
    display: 'flex',
    flexDirection: 'column',
    gap: '32px',
    flex: 3,
  },
  animatedIcon: {
    width: '80px',
    height: '80px',
  },
  rightSection: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    borderRadius: '8px',
    border: `1px solid ${colors.neutral100}`,
    background: colors.white,
    boxShadow: '0px 10px 20px 0px rgba(0, 0, 0, 0.04), 0px 2px 6px 0px rgba(0, 0, 0, 0.04), 0px 0px 1px 0px rgba(0, 0, 0, 0.04)'
  },
  closeBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    alignSelf: 'stretch',
    borderRadius: '8px',
    border: colors.neutral100,
    background: colors.white,
    boxShadow: '0px 10px 20px 0px rgba(0, 0, 0, 0.04), 0px 2px 6px 0px rgba(0, 0, 0, 0.04), 0px 0px 1px 0px rgba(0, 0, 0, 0.04)',
  },
  header: {
    display: 'flex',
    padding: '24px 16px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '4px',
    alignSelf: 'stretch',
    borderBottom: `1px solid ${colors.neutral100}`,
    flexDirection: 'column',
  },
  sectionTitle: {
    color: colors.neutral900,
    fontFamily: 'Manrope',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '24px',
    margin: 0,
  },
  sectionSubtitle: {
    alignSelf: 'stretch',
    color: colors.neutral600,
    textAlign: 'center',
    fontFamily: 'Manrope',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '20px',
    margin: 0,
  },
  contentBox: {
    display: 'flex',
    padding: '24px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
  },
  aboutText: {
    alignSelf: 'stretch',
    color: colors.neutral900,
    textAlign: 'center',
    fontFamily: 'Manrope',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '16px',
    letterSpacing: '0.12px',
    margin: 0,
    paddingTop: '8px',
    paddingBottom: '8px',
  },
  footer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '4px',
    flex: '1 0 0',
    alignSelf: 'stretch',
    padding: '16px',
    borderTop: `1px solid ${colors.neutral100}`,
  },
  footerTitle: {
    alignSelf: 'stretch',
    color: colors.neutral600,
    textAlign: 'center',
    fontFamily: 'Manrope',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '20px',
    margin: 0,
  },
  textLink: {
    textAlign: 'center',
    fontFamily: 'Manrope',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '20px',
    padding: 0,
    height: 'auto',
  },
  recommendationsBox: {
    display: 'flex',
    padding: '16px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '16px',
    background: colors.neutral50,
    width: '100%',
  },
  recommendationsTitle: {
    alignSelf: 'stretch',
    color: colors.neutral600,
    textAlign: 'center',
    fontFamily: 'Manrope',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '20px',
    margin: 0,
  },
  recommendations: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    alignSelf: 'stretch',
  },
  card: {
    display: 'flex',
    padding: '16px 16px 16px 24px',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '16px',
    flex: '1 0 0',
    alignSelf: 'stretch',
    borderRadius: '8px',
    background: colors.white,
    boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.04), 0px 0px 2px 0px rgba(0, 0, 0, 0.06), 0px 0px 1px 0px rgba(0, 0, 0, 0.04)'
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
  },
  cardTitle: {
    color: colors.neutral900,
    fontFamily: 'Manrope',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '24px',
    margin: 0,
  },
  cardSubtitle: {
    color: colors.neutral600,
    fontFamily: 'Manrope',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '20px',
    margin: 0,
  },
  nextApptSection: {
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'stretch',
    borderRadius: '8px',
    background: colors.white,
    boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.04), 0px 0px 2px 0px rgba(0, 0, 0, 0.06), 0px 0px 1px 0px rgba(0, 0, 0, 0.04)',
    padding: '16px 16px 16px 24px',
    justifyContent: 'space-between',
  },
  nextApptContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '4px'
  },
  nextApptTitle: {
    color: colors.neutral900,
    fontFamily: 'Manrope',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '24px',
    margin: 0,
  },
  nextApptRemainingTime: {
    color: colors.orange600,
    fontWeight: 700
  },
  nextApptSubTitle: {
    color: colors.neutral600,
    fontFamily: 'Manrope',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '20px',
    letterSpacing: '0.14px',
    margin: 0,
  },
  rightHeader: {
    display: 'flex',
    padding: '24px 16px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '8px',
    alignSelf: 'stretch',
    borderBottom: `1px solid ${colors.neutral100}`
  },
  closeSessionIcon: {
    width: '80px',
    height: '80px',
  },
  rightHeaderContentBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
  },
  rightHeaderTitle: {
    color: colors.neutral900,
    textAlign: 'center',
    fontFamily: 'Manrope',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '24px',
    margin: 0,
  },
  rightHeaderSubtitle: {
    color: colors.neutral600,
    textAlign: 'center',
    fontFamily: 'Manrope',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '20px',
    margin: 0,
  },
  memberActionsBox: {
    display: 'flex',
    padding: '16px 24px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '16px',
    alignSelf: 'stretch',
    background: colors.neutral50,
  },
  actionsList: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    alignSelf: 'stretch',
  },
  actionBtn: {
    display: 'flex',
    height: '40px',
    padding: '8px 12px',
    alignItems: 'center',
    gap: '4px',
    alignSelf: 'stretch',
    borderRadius: '4px',
    background: colors.white,
    fontFamily: 'Manrope',
    fontSize: '14px',
    minWidth: '210px !important',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '20px',
    justifyContent: 'space-between',
  },
  profileBtn: {
    border: `1px solid ${colors.primary300}`,
    color: colors.primary500,
    '& svg': {
      '& path': {
        fill: colors.primary500,
      },
    },
    '&:hover': {
      backgroundColor: colors.primary100,
    }
  },
  amaBtn: {
    border: `1px solid ${colors.secondary300}`,
    color: colors.secondary500,
    '& svg': {
      '& path': {
        fill: colors.secondary500,
      },
    },
    '&:hover': {
      backgroundColor: colors.secondary100,
    }
  },
  apptBtn: {
    border: `1px solid ${colors.success300}`,
    color: colors.green500,
    '& svg': {
      '& path': {
        fill: colors.green500,
      },
    },
    '&:hover': {
      backgroundColor: colors.green50,
    }
  },
  chatBtn: {
    border: `1px solid ${colors.orange300}`,
    color: colors.warning500,
    '& svg': {
      '& path': {
        fill: colors.warning500,
      },
    },

    '&:hover': {
      backgroundColor: colors.warning100,
    }
  },
  giggleBox: {
    padding: '16px',
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
  },
  breakText: {
    alignSelf: 'stretch',
    color: colors.neutral900,
    textAlign: 'center',
    fontFamily: 'Manrope',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '20px',
    margin: 0,
  }
});
