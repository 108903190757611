import { useState } from 'react';
import { Box, Stack } from '@mui/material';

import { Drawer, drawerType } from '../../../../packages/ui/organisms/drawer';
import { colors, fontWeight, iconBtnType, IconButton, Input, inputType, Text } from '../../../../packages';

import AssignmentsChart, {
  getRandomData,
} from '../../../../pages/admin/plan-items/components/assignments-chart';
import BreakdownChart from '../../../../pages/admin/plan-items/components/plan-item-detail/breakdown-chart';
import RecentAssignmentsTable from '../../../../pages/admin/plan-items/components/plan-item-detail/recent-assignments-table';
import InfoCard from '../../../../pages/admin/plan-items/components/plan-item-detail/info-card';

// styles
import { useStyles } from './AutomationDetail.styles';
// constants
import { autmationColumns } from './AutomationDetail.constants';

const AutomationDetail = ({ isOpen, onClose, item }) => {
  const classes = useStyles();
  const [showViewAll, setShowViewAll] = useState(false);
  const [searchText, setSearchText] = useState('');

  const onViewAllClick = () => {
    setShowViewAll(true);
  };

  const onCloseViewAll = () => {
    setShowViewAll(false);
  };

  if (showViewAll) {
    return (
      <Drawer open onClose={onClose} variant={drawerType.NORMAL} className={classes.drawer}>
        <Box>
          <Box className={classes.headerDetail}>
            <IconButton icon="chevron-left" onClick={onCloseViewAll} className={classes.backBtnSquare} />
            <Stack direction="column">
              <Text className={classes.recentTitle} weight={fontWeight.BOLD}>
                Automation members
              </Text>
              <Text className={classes.headerSubtitle}>{item.planItemName}</Text>
            </Stack>
          </Box>
          <Box className={classes.searchWrap}>
            <Input
              variant={inputType.SEARCH}
              placeholder="Search"
              className={classes.searchInput}
              value={searchText}
              onChange={setSearchText}
            />
          </Box>
          <RecentAssignmentsTable isShowAll searchText={searchText} tableColumns={autmationColumns} />
        </Box>
      </Drawer>
    );
  }

  return (
    <Drawer open={isOpen} onClose={onClose} variant={drawerType.NORMAL} className={classes.drawer}>
      <Box className={classes.wrapper}>
        <Box className={classes.header}>
          <Stack direction="column">
            <Text className={classes.headerTitle}>Automation details</Text>
            <Text className={classes.headerSubtitle}>{item.name}</Text>
          </Stack>
          <IconButton icon="close" onClick={onClose} className={classes.backBtn} />
        </Box>
        <Box className={classes.content}>
          <Stack direction="row" gap={2} className={classes.infoWrap}>
            <InfoCard title="executions - last 30 days" value={237} />
            <InfoCard title="executions - total" value={514} />
          </Stack>
          <Box className={classes.chartWrap}>
            <AssignmentsChart
              title="Executions"
              className={classes.chart}
              chart={{
                series: [
                  { name: 'Current 30 days', color: colors.primary500, data: getRandomData() },
                  { name: 'Past 30 days', color: colors.neutral600, data: getRandomData() },
                ],
                tooltipConfig: {
                  isShowTotal: false,
                  title: 'Total',
                },
              }}
            />
          </Box>
          <Box
            className={classes.chartWrap}
            display="flex"
            justifyContent="space-between"
            gap={1}
            sx={{ p: 4 }}
          >
            <BreakdownChart
              title="Plan item status breakdown"
              subtitle={item.planItemName}
              className={classes.breakdownChart}
              chartHeight={200}
              chartWidth={500}
              chartData={[
                { name: '15 people completed', color: colors.success500, data: [50] },
                { name: '5 people started', color: colors.primary500, data: [25] },
                { name: '10 people not started', color: colors.neutral300, data: [25] },
              ]}
              donutOpts={{
                total: 30,
                title: 'Members',
                size: '80%',
              }}
            />
          </Box>
          <Box className={classes.assignmentTable}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Text className={classes.recentTitle} weight={fontWeight.SEMI_BOLD}>
                Automation members
              </Text>
              <IconButton onClick={onViewAllClick} className={classes.viewAllBtn}>
                View all (7)
              </IconButton>
            </Stack>
            <RecentAssignmentsTable
              className={classes.tableBordered}
              isShowAll={false}
              tableColumns={autmationColumns}
            />
          </Box>
        </Box>
        <Box className={classes.footer}>
          <IconButton onClick={onClose}>Close</IconButton>
          <IconButton variant={iconBtnType.PRIMARY} className={classes.editBtn}>
            Edit automation
          </IconButton>
        </Box>
      </Box>
    </Drawer>
  );
};

export { AutomationDetail };
