/* eslint-disable max-len */
import dayjs from 'dayjs';
import { DomainPriorities } from '../../../../../redux/modules/profile/types';

export const medicationList = [
  {
    id: '1',
    name: 'Wellbutrin',
    description: '100 mg twice daily',
    assignedBy: 'Eva Bond',
    assignedAt: dayjs().subtract(1, 'd').toISOString(),
    avatar: null,
    priority: {
      color: '',
      name: DomainPriorities.CRITICAL,
    },
    notes:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris aliquam sem eget libero egestas, ut dignissim nunc vehicula. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris aliquam sem eget libero egestas, ut dignissim nunc vehicula.',
    tagMetaData: {
      rxDrugInfo: {
        dose: 5,
        doseUnit: 'MG',
        doseFrequency: 3,
        supply: 60,
        supplyUnit: 'DAYS',
        refillable: true,
      },
      substanceUse: {},
    },
    history: [],
  },
];

export const medicationItems = [
  {
    label: 'Antidepressants',
    groupId: 'Antidepressants',
    items: [
      { label: 'Escitalopram', value: 'Escitalopram' },
      { label: 'Citalopram', value: 'Citalopram' },
      { label: 'Fluvoxamine', value: 'Fluvoxamine' },
      { label: 'Fluoxetine', value: 'Fluoxetine' },
    ],
  },
  {
    label: 'Antipsychotics',
    groupId: 'Antipsychotics',
    items: [
      { label: 'Antipsychotics 1', value: 'Antipsychotics 1' },
      { label: 'Antipsychotics 2', value: 'Antipsychotics 2' },
      { label: 'Antipsychotics 3', value: 'Antipsychotics 3' },
      { label: 'Antipsychotics 4', value: 'Antipsychotics 4' },
    ],
  },
  {
    label: 'Mood Stabilizers',
    groupId: 'Mood Stabilizers',
    items: [
      { label: 'Mood Stabilizers 1', value: 'Mood Stabilizers 1' },
      { label: 'Mood Stabilizers 2', value: 'Mood Stabilizers 2' },
      { label: 'Mood Stabilizers 3', value: 'Mood Stabilizers 3' },
      { label: 'Mood Stabilizers 4', value: 'Mood Stabilizers 4' },
    ],
  },
];

export const stepTitles = {
  0: 'Step 1 - select medications',
  1: 'Step 2 - medication details',
  2: 'Step 3 - review medications',
};
