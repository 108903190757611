export const CardStatus = {
  VERIFIED: 'VERIFIED',
  REVIEW_NEEDED: 'REVIEW_NEEDED',
  DOCUMENT_NEEDED: 'DOCUMENT_NEEDED',
  DOCUMENT_ANALYSIS: 'DOCUMENT_ANALYSIS',
  DISCREPANCY: 'DISCREPANCY',
  EXPIRED: 'EXPIRED',
  EXPIRING_SOON: 'SOON_TO_EXPIRE',
  INCOMPLETE: 'INCOMPLETE',
  MULTIPLE_STATUS: 'MULTIPLE_STATUS',
  FAILED: 'FAILED',
};

export const cardLabels = {
  VERIFIED: 'Verified',
  REVIEW_NEEDED: 'Review needed',
  DOCUMENT_NEEDED: 'Document needed',
  DOCUMENT_ANALYSIS: 'Analyzing document',
  DISCREPANCY: 'Discrepancy',
  EXPIRED: 'Expired',
  SOON_TO_EXPIRE: 'Expiring soon',
  INCOMPLETE: 'Incomplete',
  FAILED: 'Document analysis failed',
};

export const CardType = {
  BOARD_CERTIFICATION: 'BoardCertification',
  LICENSES: 'ProfessionalLicense',
  DEA_CDS_REGISTRATION: 'DeaCdsRegistration',
  DEA: 'DEA',
  CDS: 'CDS',
  EDUCATION: 'Education',
  INSURANCE: 'InsuranceInformation',
};

export const cardTypeLabel = {
  BoardCertification: 'Board certification',
  ProfessionalLicense: 'License',
  DeaCdsRegistration: 'DEA/CDS number',
  DEA: 'DEA number',
  CDS: 'CDS number',
  Education: 'Degree',
  InsuranceInformation: 'Malpractice coverage',
};
