/* eslint-disable max-len */
import cx from 'clsx';

// types
// styles
import { useStyles } from './shared/styles';

const EditFilled = ({ className, color, onClick }) => {
  const classes = useStyles();
  return (
    <svg
      className={cx({
        [classes.root]: true,
        [className || '']: className,
      })}
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M14.81 6.99999C14.9016 7.09346 14.9529 7.21912 14.9529 7.34999C14.9529 7.48087 14.9016 7.60653 14.81 7.69999L6.75 15.79C6.55664 15.9851 6.32517 16.1383 6.07 16.24L1.73 18C1.63912 18.0366 1.53948 18.0457 1.44348 18.0261C1.34748 18.0065 1.25934 17.9592 1.19 17.89C1.08983 17.8261 1.01568 17.7286 0.980868 17.615C0.946051 17.5014 0.952835 17.3791 1 17.27L2.72 12.93C2.82172 12.6748 2.9749 12.4434 3.17 12.25L11.24 4.17999C11.2865 4.13313 11.3418 4.09593 11.4027 4.07055C11.4636 4.04516 11.529 4.03209 11.595 4.03209C11.661 4.03209 11.7264 4.04516 11.7873 4.07055C11.8482 4.09593 11.9035 4.13313 11.95 4.17999L14.81 6.99999ZM18.56 1.09999L17.9 0.439993C17.619 0.158608 17.2377 0.000342938 16.84 -7.16624e-06H16.08C15.8797 -0.00248616 15.681 0.0351699 15.4955 0.110739C15.31 0.186309 15.1415 0.298262 15 0.439993L13.19 2.26999C13.0984 2.36346 13.0471 2.48912 13.0471 2.61999C13.0471 2.75087 13.0984 2.87653 13.19 2.96999L16 5.81999C16.0465 5.86686 16.1018 5.90405 16.1627 5.92944C16.2236 5.95482 16.289 5.96789 16.355 5.96789C16.421 5.96789 16.4864 5.95482 16.5473 5.92944C16.6082 5.90405 16.6635 5.86686 16.71 5.81999L18.56 3.99999C18.7017 3.85847 18.8137 3.68999 18.8893 3.5045C18.9648 3.31901 19.0025 3.12027 19 2.91999V2.15999C18.9997 1.7623 18.8414 1.38103 18.56 1.09999Z"
        fill={color}
      />
    </svg>
  );
};

export default EditFilled;
