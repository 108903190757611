// colors
import { colors } from '../../../../../packages/colors';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  container: {
    flex: 1,
    position: 'relative',
    zIndex: 1,
  },
  messageText: {
    color: colors.neutral900,
    textAlign: 'left',
    lineHeight: '24px',
    wordWrap: 'break-word',
  },
  header: {
    backgroundColor: colors.neutral25,
    borderTop: `1px solid ${colors.neutral100}`,
    borderBottom: `1px solid ${colors.neutral100}`,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '20px 16px',
  },
  headerText: {
    lineHeight: '20px',
  },
  title: {
    fontSize: 18,
    lineHeight: '24px',
    margin: 0,
    marginTop: 4,
    marginBottom: 4,
  },
  closeBtn: {
    width: 40,
    minWidth: 40,
    height: 40,
    '& svg': {
      height: 20,
    },
  },
  groupName: {
    margin: 0,
    fontSize: 14,
  },
  time: {
    color: '#667786',
    paddingLeft: '8px',
  },
  avatar: {
    flex: 1,
  },
});
