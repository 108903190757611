import { makeStyles } from '@mui/styles';

// colors
import { colors } from '../../../../../../packages';

export const useStyles = makeStyles({
  itemWrap: {
    '&:not(:last-child)': {
      borderBottom: `1px solid ${colors.neutral100}`,
    },
  },
  name: {
    color: colors.neutral900,
    fontSize: 14,
    lineHeight: '20px',
    margin: 0,
    display: 'block',
  },
  fullname: {
    color: colors.neutral600,
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '20px 0',
  },
  userWrap: {
    display: 'flex',
    gap: 12,
    alignItems: 'center',
    width: 150,
  },
  listItem: {
    marginBlockStart: 0,
    color: colors.neutral900,
    paddingInlineStart: 14,
    '& li': {
      marginBottom: 4,
    },
  },
  descriptionWrap: {
    paddingBottom: 20,
  },
});
