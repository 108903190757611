import { makeStyles } from '@mui/styles';

// colors
import { colors } from '../../../packages';

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    paddingTop: 88,
    gap: 40,
  },
  header: {
    position: 'absolute',
    top: -48,
    left: -64,
    height: 88,
    width: '100vw',
    borderBottom: `1px solid ${colors.neutral100}`,
    paddingLeft: 32,
    paddingRight: 32,
    display: 'flex',
    alignItems: 'center',
  },
  menu: {
    border: `1px solid ${colors.neutral100}`,
    backgroundColor: colors.white,
    borderRadius: 20,
    padding: '4px 4px 4px 8px',
    minHeight: 'auto',
    height: 28,
    '& svg': {
      marginLeft: 4,
    },
  },
  menuLabel: {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: '20px',
  },
  editBtn: {
    height: 64,
    color: '#FFF',
    whiteSpace: 'nowrap',
    padding: '20px 24px',
    borderRadius: 8,
  },
  heading: {
    fontFamily: 'Source Serif Pro',
    margin: 0,
  },
  subtitle: {
    fontWeight: 400,
    fontSize: 18,
    lineHeight: '28px',
    margin: 0,
    color: colors.neutral600,
  },
  loader: {
    height: 'calc(100vh - 158px)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});
