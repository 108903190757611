import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  Badge,
  badgeStyle,
  badgeType,
  btnType,
  Button,
  colors,
  fontWeight,
  Heading,
  headingLevel,
  Icons,
} from '../../../../../packages';
import { Box, Stack } from '@mui/material';
import { getProfile } from '../../../../../redux/modules/profile/selectors';
import { getPatientEvaluation } from '../../../../../services/reporting/reporting.service';
import EvaluationCard from '../../../member-appointment-notes/components/evaluation-card';
import EmptyState from '../EmptyState';
import { useStyles } from './evaluations.styles';

const Evaluations = () => {
  const classes = useStyles();
  const { memberId } = useParams();
  const [evaluations, setEvaluations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  // const [isCompletionDrawer, setCompletionDrawer] = useState(false);
  // const [isResponseDrawer, setResponseDrawer] = useState(false);
  // const [selectedCompletion, setSelectedCompletion] = useState(null);
  const { selectedPatient } = useSelector(getProfile);

  // const sortByEvaluationName = getCompareFn(x => x.evaluationName);

  useEffect(() => {
    void fetchMemberEvaluations();
  }, []);

  const fetchMemberEvaluations = async () => {
    try {
      const queryParams = {
        pageNumber,
        pageSize: 10,
      };
      const { data } = await getPatientEvaluation(queryParams, { userId: memberId });
      setPageNumber(pageNumber + 1);
      setTotalPages(data?.totalPages);
      setTotalCount(data?.total);
      setEvaluations([...evaluations, ...data?.records]);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const transformStructure = source => {
    return {
      id: source.evaluation._id,
      name: source.evaluation.name,
      status: source.context?.status ?? 'NOT_STARTED',
      description: source.evaluation.description,
      progress: source.evaluationStats.progress,
      evaluationStats: {
        totalCb: source.evaluationStats.totalCb,
        totalRequiredCb: source.evaluationStats.totalRequiredCb,
        totalExercises: source.evaluationStats.totalExercises,
        forProviderCount: source.evaluationStats.forProviderCount,
        unanswered: source.evaluationStats.unanswered,
        riskFactorCount: source.evaluationStats.riskFactorCount,
      },
      evaluationAssignmentMethod: source.appointmentEvaluation.assignmentMethod,
      assignedAt: source.appointmentEvaluation.assignedAt,
      completedAt: source.context?.completedAt ?? null,
      rejectionTemplate: [],
      dctProgress: [],
    };
  };

  return (
    <>
      <Stack direction="row" justifyContent="space-between" spacing={2}>
        <Stack direction="row" alignItems="center" gap={2}>
          <Heading className={classes.heading} level={headingLevel.XL} weight={fontWeight.BOLD}>
            Evaluations
          </Heading>
          <Badge
            className={classes.totalMemberBadge}
            variant={badgeType.OUTLINED}
            style={badgeStyle.UNRELATED}
          >
            {totalCount ?? 0} total
          </Badge>
        </Stack>
      </Stack>
      {loading ? (
        <Box display="flex" alignItems="center" justifyContent="center">
          <Icons className="rotate linear infinite" glyph="in-progress" color={colors.primary} />
        </Box>
      ) : (
        <>
          {evaluations.length > 0 ? (
            evaluations.map((item, index) => (
              <EvaluationCard
                evaluation={transformStructure(item)}
                key={index}
                provider={null}
                expandable={false}
                appointmentIdProp={item?.appointmentId}
              />
            ))
          ) : (
            <EmptyState
              title="No evaluations yet"
              subtitle={`${selectedPatient?.member?.firstName} doesn't have any evaluations yet.`}
            />
          )}
        </>
      )}
      {totalPages !== 0 && pageNumber <= totalPages && (
        <Box className={classes.btnContainer}>
          <Button
            variant={btnType.TEXT}
            className={classes.loadMoreBtn}
            onClick={() => void fetchMemberEvaluations()}
          >
            Load more
          </Button>
        </Box>
      )}
      {/* {isCompletionDrawer && (
        <CompletionDrawer
          open={isCompletionDrawer}
          onClose={() => setCompletionDrawer(false)}
          title={selectedCompletion.evaluationName}
          params={{
            userId: memberId,
            evaluationId: selectedCompletion.evaluationId,
          }}
        />
      )}
      {isResponseDrawer && (
        <ResponseDrawer
          open={isResponseDrawer}
          onClose={() => setResponseDrawer(false)}
          title={selectedCompletion.evaluationName}
          params={{
            contextId: selectedCompletion.contextId,
          }}
        />
      )} */}
    </>
  );
};

export default Evaluations;
