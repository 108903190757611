/* eslint-disable max-len */
import cx from 'clsx';

// types
// styles
import { useStyles } from './shared/styles';

const CameraFilled = ({ className, color, onClick }) => {
  const classes = useStyles();
  return (
    <svg
      className={cx({
        [classes.root]: true,
        [className || '']: className,
      })}
      width="20"
      height="14"
      viewBox="0 0 20 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M19.5 2H19.41C19.1477 2.0011 18.8963 2.10526 18.71 2.29L16 5V2C16 1.46957 15.7893 0.960859 15.4142 0.585786C15.0391 0.210714 14.5304 0 14 0H2C1.46957 0 0.960859 0.210714 0.585786 0.585786C0.210714 0.960859 0 1.46957 0 2V12C0 12.5304 0.210714 13.0391 0.585786 13.4142C0.960859 13.7893 1.46957 14 2 14H14C14.5304 14 15.0391 13.7893 15.4142 13.4142C15.7893 13.0391 16 12.5304 16 12V9L18.71 11.71C18.8963 11.8947 19.1477 11.9989 19.41 12H19.5C19.6326 12 19.7598 11.9473 19.8536 11.8536C19.9473 11.7598 20 11.6326 20 11.5V2.5C20 2.36739 19.9473 2.24021 19.8536 2.14645C19.7598 2.05268 19.6326 2 19.5 2ZM3.5 5.88C3.36739 5.88 3.24021 5.82732 3.14645 5.73355C3.05268 5.63978 3 5.51261 3 5.38V4.62C3 4.48739 3.05268 4.36022 3.14645 4.26645C3.24021 4.17268 3.36739 4.12 3.5 4.12H7.5C7.63261 4.12 7.75979 4.17268 7.85355 4.26645C7.94732 4.36022 8 4.48739 8 4.62V5.38C8 5.51261 7.94732 5.63978 7.85355 5.73355C7.75979 5.82732 7.63261 5.88 7.5 5.88H3.5Z"
        fill={color}
      />
    </svg>
  );
};

export default CameraFilled;
