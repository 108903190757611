import ApexChart from 'react-apexcharts';
import { Box } from '@mui/material';
import clsx from 'clsx';
import { Heading } from '../../../../../../packages';
import { getChartOptions } from './ColumnChart.config';
import { useStyles } from './ColumnChart.styles';

const ColumnChart = ({ title, className, chart }) => {
  const classes = useStyles();

  return (
    <Box className={clsx(classes.card, className)}>
      <Box className={clsx(classes.head, 'chart-head')}>
        <Heading className={classes.title}>{title}</Heading>
      </Box>
      <Box className={classes.chart}>
        <ApexChart options={getChartOptions(chart)} series={chart.series} type="bar" height={330} />
      </Box>
    </Box>
  );
};

export { ColumnChart };
