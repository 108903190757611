/* eslint-disable max-len */
import { profileSectionIds } from '../../../provider/profile/Profile.constants';

export const profileSections = [
  {
    id: profileSectionIds.general,
    title: 'General information',
  },
  {
    id: profileSectionIds.approach,
    title: 'Approach',
  },
  {
    id: profileSectionIds.availableStates,
    title: 'Available states',
  },
  {
    id: profileSectionIds.speciality,
    title: 'Specialties',
  },
  {
    id: profileSectionIds.certifications,
    title: 'Certifications',
  },
  {
    id: profileSectionIds.credentials,
    title: 'Credentials',
  },
  {
    id: profileSectionIds.clinicAffiliation,
    title: 'Clinic affiliation',
  },
  {
    id: profileSectionIds.pastEmployment,
    title: 'Past employment',
  },
  {
    id: profileSectionIds.education,
    title: 'Education',
  },
  {
    id: profileSectionIds.resetPassword,
    title: 'Reset password',
  },
];
