import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';

import { btnType, Button, IconButton } from '../../../../../packages';
import { DctType } from '../../../../../redux/modules/reporting/types';
import { selectReportSaving } from '../../../../../redux/modules/reporting/selectors';
import {
  getConversation,
  selectConversations,
  selectDctState,
  selectEvaluation,
  selectSessionTypes,
} from '../../../../../redux/modules/conversation/selectors';
import { getPriority } from '../../../../../redux/modules/priority/selectors';
import { REPORT_COUNT_QUERY_KEYWORDS } from '../../../report-detail/ReportDetail.constants';
import { getDctsList } from '../../../../../services/conversation/conversation.service';

import DataIncludedSection from './data-included-section';
import {
  appointmentCheckList,
  chatbotsCheckList,
  dataCollectionCheckList,
  dataDomainCheckList,
  demoGraphicCheckList,
  evaluationsCheckList,
  loginsCheckList,
  profileElementsCheckList,
} from './ReportDataIncluded.constants';
import { useStyles } from './ReportDataIncluded.styles';

const ReportDataIncluded = ({ data, onClose, onContinue }) => {
  const { dcts } = useSelector(selectDctState);
  const serviceTypes = useSelector(selectSessionTypes);
  const [evaluationDCTs, setEvaluationDCTs] = useState([]);
  const {
    elementsPayload: { domainElements },
  } = useSelector(getPriority);
  useEffect(() => {
    const queryParams = {
      searchQuery: '',
      type: REPORT_COUNT_QUERY_KEYWORDS.EVALUATION,
      reportingView: true,
      method: '',
      pageNumber: 0,
      pageSize: 1000,
    };
    getDctsList(queryParams)
      .then(res => {
        setEvaluationDCTs(res.data?.dctList);
      })
      .catch(err => {
        console.log('err', err);
      });
  }, []);
  const {
    profileElements: { profileElementList },
  } = useSelector(getConversation);
  const conversations = useSelector(selectConversations);
  const evaluations = useSelector(selectEvaluation);
  const chatbotDctOptions = useMemo(
    () =>
      dcts
        .filter(dct => dct.dctType === DctType.CHATBOT)
        .map(dct => ({
          title: dct.version ? `${dct.name}_v${dct.version}` : dct.name,
          value: dct.dctId,
        })),
    [dcts],
  );

  const evaluationDctOptions = useMemo(
    () =>
      evaluationDCTs
        ?.filter(dct => dct.dctType === DctType.EVALUTATION)
        .map(dct => ({
          title: dct.version ? `${dct.name}_v${dct.version}` : dct.name,
          value: dct.dctId,
        })),
    [evaluationDCTs],
  );
  const serviceTypeOptions = useMemo(
    () => serviceTypes.map(serviceType => ({ title: serviceType.name, value: serviceType.id })),
    [serviceTypes],
  );
  const domainElementOptions = useMemo(
    () => domainElements.map(element => ({ title: element.name, value: element.Id })),
    [domainElements],
  );
  const profileElementOptions = useMemo(
    () =>
      profileElementList.map(element => ({
        title: element.profileElementInfo.key,
        value: element.profileElementInfo.id,
      })),
    [profileElementList],
  );
  const chatbotOptions = useMemo(
    () =>
      conversations.map(item => ({
        title: item.version ? `${item.name}_v${item.version}` : item.name,
        value: item.conversationId,
      })),
    [conversations],
  );
  const evaluationOptions = useMemo(
    () =>
      evaluations.map(item => ({
        title: item.version ? `${item.name}_v${item.version}` : item.name,
        value: item.evaluationId,
      })),
    [evaluations],
  );

  const classes = useStyles();
  const [appointmentsChecked, setAppointmentsChecked] = useState([]);
  const [demoGraphicsChecked, setDemoGraphicsChecked] = useState([]);
  const [chatbotDctsChecked, setChatbotDctsChecked] = useState([]);
  const [evaluationDctsChecked, setEvaluationDctsChecked] = useState([]);
  const [dataDomainChecked, setDataDomainChecked] = useState([]);
  const [profileElementsChecked, setProfileElementsChecked] = useState([]);
  const [chatbotsChecked, setChatbotsChecked] = useState([]);
  const [evaluationsChecked, setEvaluationsChecked] = useState([]);
  const [loginsChecked, setLoginsChecked] = useState([]);

  const [isIncludedAppointments, setIsIncludedAppointments] = useState(false);
  const [isIncludedDemoGraphic, setIsIncludedDemoGraphic] = useState(false);
  const [isIncludedDataDomain, setIsIncludedDataDomain] = useState(false);
  const [isIncludedChatbotDct, setIsIncludedChatbotDct] = useState(false);
  const [isIncludedEvaluationDct, setIsIncludedEvaluationDct] = useState(false);
  const [isIncludedChatbots, setIsIncludedChatbots] = useState(false);
  const [isIncludedLogins, setIsIncludedLogins] = useState(false);
  const [isIncludedProfileElements, setIsIncludedProfileElements] = useState(false);
  const [isIncludedEvaluations, setIsIncludedEvaluations] = useState(false);

  const [appointmentsTags, setAppointmentsTags] = useState([]);
  const [dataDomainTags, setDataDomainTags] = useState([]);
  const [profileElementsTags, setProfileElementsTags] = useState([]);
  const [chatbotsTags, setChatbotsTags] = useState([]);
  const [evaluationsTags, setEvaluationsTags] = useState([]);
  const [chatbotDctTags, setChatbotDctTags] = useState([]);
  const [evaluationDctTags, setEvaluationDctTags] = useState([]);

  const isSaving = useSelector(selectReportSaving);

  useEffect(() => {
    if (!data) {
      return;
    }

    setAppointmentsChecked(
      appointmentCheckList
        .map(item => item.type)
        .filter(type => data.appointmentsSection?.[type]?.isChecked || false),
    );
    setDemoGraphicsChecked(
      demoGraphicCheckList
        .map(item => item.type)
        .filter(type => data.demoGraphicsSection?.[type]?.isChecked || false),
    );
    setChatbotDctsChecked(
      dataCollectionCheckList
        .map(item => item.type)
        .filter(type => data.chatbotDctSection?.[type]?.isChecked || false),
    );
    setEvaluationDctsChecked(
      dataCollectionCheckList
        .map(item => item.type)
        .filter(type => data.evaluationDctSection?.[type]?.isChecked || false),
    );
    setDataDomainChecked(
      dataDomainCheckList
        .map(item => item.type)
        .filter(type => data.dataDomainElementSection?.[type]?.isChecked || false),
    );
    setProfileElementsChecked(
      profileElementsCheckList
        .map(item => item.type)
        .filter(type => data.profileElementsSection?.[type]?.isChecked || false),
    );
    setChatbotsChecked(
      chatbotsCheckList
        .map(item => item.type)
        .filter(type => data.chatbotSection?.[type]?.isChecked || false),
    );
    setEvaluationsChecked(
      evaluationsCheckList
        .map(item => item.type)
        .filter(type => data.evaluationsSection?.[type]?.isChecked || false),
    );
    setLoginsChecked(
      loginsCheckList.map(item => item.type).filter(type => data.loginSection?.[type]?.isChecked || false),
    );

    setIsIncludedAppointments(data.appointmentsSection?.includeInReport || false);
    setIsIncludedDemoGraphic(data.demoGraphicsSection?.includeInReport || false);
    setIsIncludedDataDomain(data.dataDomainElementSection?.includeInReport || false);
    setIsIncludedChatbotDct(data.chatbotDctSection?.includeInReport || false);
    setIsIncludedEvaluationDct(data.evaluationDctSection?.includeInReport || false);
    setIsIncludedChatbots(data.chatbotSection?.includeInReport || false);
    setIsIncludedLogins(data.loginSection?.includeInReport || false);
    setIsIncludedProfileElements(data.profileElementsSection?.includeInReport || false);
    setIsIncludedEvaluations(data.evaluationsSection?.includeInReport || false);

    setAppointmentsTags(() => {
      const ids = data.appointmentsSection?.serviceTypeIds?.serviceTypeIds || [];
      return serviceTypeOptions.filter(option => ids.includes(option.value));
    });
    setDataDomainTags(() => {
      const ids = data.dataDomainElementSection?.dataDomainElementIds?.domainElementIds || [];
      return domainElementOptions.filter(option => ids.includes(option.value));
    });
    setChatbotDctTags(() => {
      const ids = data.chatbotDctSection?.dataCollectionTemplateIds?.dctTemplateIds || [];
      return chatbotDctOptions.filter(option => ids.includes(option.value));
    });
    setEvaluationDctTags(() => {
      const ids = data.evaluationDctSection?.dataCollectionTemplateIds?.dctTemplateIds || [];
      return evaluationDctOptions?.filter(option => ids.includes(option.value));
    });
    setChatbotsTags(() => {
      const ids = data.chatbotSection?.chatbotIds?.conversationIds || [];
      return chatbotOptions.filter(option => ids.includes(option.value));
    });
    // setProfileElementsTags(() => {
    //   const ids = data.profileElementsSection?.profileElementIds?.profileElementIds || [];
    //   return profileElementOptions.filter(option => ids.includes(option.value));
    // });
    setEvaluationsTags(() => {
      const ids = data.evaluationsSection?.evaluationIds?.evaluationIds || [];
      return evaluationOptions.filter(option => ids.includes(option.value));
    });
  }, [data]);

  const onContinueClick = () => {
    const reportData = {
      chatbotDctSection: dataCollectionCheckList.reduce(
        (acc, item) => ({
          ...acc,
          [item.type]: {
            isChecked: chatbotDctsChecked.includes(item.type),
            title: item.title,
            viewType: item.viewType,
          },
        }),
        {
          includeInReport: isIncludedChatbotDct,
          dataCollectionTemplateIds: {
            dctTemplateIds: chatbotDctTags.map(tag => tag.value),
          },
        },
      ),
      evaluationDctSection: dataCollectionCheckList.reduce(
        (acc, item) => ({
          ...acc,
          [item.type]: {
            isChecked: evaluationDctsChecked.includes(item.type),
            title: item.title,
            viewType: item.viewType,
          },
        }),
        {
          includeInReport: isIncludedEvaluationDct,
          dataCollectionTemplateIds: {
            dctTemplateIds: evaluationDctTags.map(tag => tag.value),
          },
        },
      ),
      dataDomainElementSection: dataDomainCheckList.reduce(
        (acc, item) => ({
          ...acc,
          [item.type]: {
            isChecked: dataDomainChecked.includes(item.type),
            title: item.title,
            viewType: item.viewType,
          },
        }),
        {
          includeInReport: isIncludedDataDomain,
          dataDomainElementIds: {
            domainElementIds: dataDomainTags.map(tag => tag.value),
          },
        },
      ),
      appointmentsSection: appointmentCheckList.reduce(
        (acc, item) => ({
          ...acc,
          [item.type]: {
            isChecked: appointmentsChecked.includes(item.type),
            title: item.title,
            viewType: item.viewType,
          },
        }),
        {
          includeInReport: isIncludedAppointments,
          serviceTypeIds: {
            serviceTypeIds: appointmentsTags.map(tag => tag.value),
          },
        },
      ),
      demoGraphicsSection: demoGraphicCheckList.reduce(
        (acc, item) => ({
          ...acc,
          [item.type]: {
            isChecked: demoGraphicsChecked.includes(item.type),
            title: item.title,
            viewType: item.viewType,
          },
        }),
        {
          includeInReport: isIncludedDemoGraphic,
        },
      ),
      chatbotSection: chatbotsCheckList.reduce(
        (acc, item) => ({
          ...acc,
          [item.type]: {
            isChecked: chatbotsChecked.includes(item.type),
            title: item.title,
            viewType: item.viewType,
          },
        }),
        {
          includeInReport: isIncludedChatbots,
          chatbotIds: {
            conversationIds: chatbotsTags.map(tag => tag.value),
          },
        },
      ),
      loginSection: loginsCheckList.reduce(
        (acc, item) => ({
          ...acc,
          [item.type]: {
            isChecked: loginsChecked.includes(item.type),
            title: item.title,
            viewType: item.viewType,
          },
        }),
        {
          includeInReport: isIncludedLogins,
        },
      ),
      profileElementsSection: profileElementsCheckList.reduce(
        (acc, item) => ({
          ...acc,
          [item.type]: {
            isChecked: profileElementsChecked.includes(item.type),
            title: item.title,
            viewType: item.viewType,
          },
        }),
        {
          includeInReport: isIncludedProfileElements,
          profileElementIds: {
            profileElementIds: profileElementsTags.map(tag => tag.value),
          },
        },
      ),
      evaluationsSection: evaluationsCheckList.reduce(
        (acc, item) => ({
          ...acc,
          [item.type]: {
            isChecked: evaluationsChecked.includes(item.type),
            title: item.title,
            viewType: item.viewType,
          },
        }),
        {
          includeInReport: isIncludedEvaluations,
          evaluationIds: {
            evaluationIds: evaluationsTags.map(tag => tag.value),
          },
        },
      ),
    };
    onContinue(reportData);
  };

  return (
    <Box className={classes.container}>
      <Box className={classes.formContent}>
        <DataIncludedSection
          icon="calendar-today-outlined"
          label="Appointments"
          checkList={appointmentCheckList}
          listChecked={appointmentsChecked}
          setListChecked={setAppointmentsChecked}
          isIncluded={isIncludedAppointments}
          setIsIncluded={setIsIncludedAppointments}
          tagLabel="Service types to include:"
          tagOptions={serviceTypeOptions}
          tags={appointmentsTags}
          setTags={setAppointmentsTags}
        />
        <DataIncludedSection
          icon="members-outlined"
          label="Demographics"
          checkList={demoGraphicCheckList}
          listChecked={demoGraphicsChecked}
          setListChecked={setDemoGraphicsChecked}
          isIncluded={isIncludedDemoGraphic}
          setIsIncluded={setIsIncludedDemoGraphic}
        />
        <DataIncludedSection
          icon="collection"
          label="Chatbot DCTs"
          checkList={dataCollectionCheckList}
          listChecked={chatbotDctsChecked}
          setListChecked={setChatbotDctsChecked}
          isIncluded={isIncludedChatbotDct}
          setIsIncluded={setIsIncludedChatbotDct}
          tagLabel="Chatbot dcts to include:"
          tagOptions={chatbotDctOptions}
          tags={chatbotDctTags}
          setTags={setChatbotDctTags}
        />
        <DataIncludedSection
          icon="collection"
          label="Evaluation DCTs"
          checkList={dataCollectionCheckList}
          listChecked={evaluationDctsChecked}
          setListChecked={setEvaluationDctsChecked}
          isIncluded={isIncludedEvaluationDct}
          setIsIncluded={setIsIncludedEvaluationDct}
          tagLabel="Evaluation dcts to include:"
          tagOptions={evaluationDctOptions}
          tags={evaluationDctTags}
          setTags={setEvaluationDctTags}
        />
        <DataIncludedSection
          icon="data-block"
          label="Data domain elements"
          checkList={dataDomainCheckList}
          listChecked={dataDomainChecked}
          setListChecked={setDataDomainChecked}
          isIncluded={isIncludedDataDomain}
          setIsIncluded={setIsIncludedDataDomain}
          tagLabel="Data domain elements to include:"
          tagOptions={domainElementOptions}
          tags={dataDomainTags}
          setTags={setDataDomainTags}
        />
        <DataIncludedSection
          icon="logout"
          label="Logins"
          checkList={loginsCheckList}
          listChecked={loginsChecked}
          setListChecked={setLoginsChecked}
          isIncluded={isIncludedLogins}
          setIsIncluded={setIsIncludedLogins}
        />
        <DataIncludedSection
          icon="profile"
          label="Profile elements"
          checkList={profileElementsCheckList}
          listChecked={profileElementsChecked}
          setListChecked={setProfileElementsChecked}
          isIncluded={isIncludedProfileElements}
          setIsIncluded={setIsIncludedProfileElements}
          tagLabel="Profile elements to include:"
          tagOptions={profileElementOptions}
          tags={profileElementsTags}
          setTags={setProfileElementsTags}
        />
        <DataIncludedSection
          icon="chat-2"
          label="Chatbots"
          checkList={chatbotsCheckList}
          listChecked={chatbotsChecked}
          setListChecked={setChatbotsChecked}
          isIncluded={isIncludedChatbots}
          setIsIncluded={setIsIncludedChatbots}
          tagLabel="Chatbots to include:"
          tagOptions={chatbotOptions}
          tags={chatbotsTags}
          setTags={setChatbotsTags}
        />
        <DataIncludedSection
          icon="data-block"
          label="Evaluations"
          checkList={evaluationsCheckList}
          listChecked={evaluationsChecked}
          setListChecked={setEvaluationsChecked}
          isIncluded={isIncludedEvaluations}
          setIsIncluded={setIsIncludedEvaluations}
          tagLabel="Evaluations to include:"
          tagOptions={evaluationOptions}
          tags={evaluationsTags}
          setTags={setEvaluationsTags}
        />
      </Box>
      <Box className={classes.footer}>
        <IconButton icon="arrow-left" variant={btnType.TEXT} onClick={onClose}>
          Previous step
        </IconButton>
        <Button onClick={onContinueClick}>
          {(isSaving && 'Saving...') || (data && 'Update report') || 'Create report'}
        </Button>
      </Box>
    </Box>
  );
};

export { ReportDataIncluded };
