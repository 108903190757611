import { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { getIn, useFormik } from 'formik';
import { useSelector } from 'react-redux';
import {
  btnType,
  Button,
  fontWeight,
  Heading,
  headingLevel,
  Icons,
  Input,
  inputSize,
  inputType,
  Select,
  selectType,
  Text,
  TextError,
} from '../../../../../packages';
import { selectAIJobs } from '../../../../../redux/modules/conversation/selectors';
import { ActivityActionType } from '../../../../../constants/CommonConstants';
import { actionDelayUnitSelect, actionRepeatUnitSelect } from '../AddAutomation.constants';
import {
  assignedPlanItemsSelect,
  transcriptReadySelections,
  typeSelects,
} from '../../../plan-items/components/add-plan-item/AddPlanItem.constants';
import { AddAutomationActionSchema } from './AutomationAction.schema';
import { useStyles } from './AutomationAction.styles';

const AutomationAction = ({
  onClose,
  onContinue,
  actiondata,
  eventdata,
  isEditMode,
  evaluations,
  planItems,
}) => {
  const DEFAULT_ACTION = {
    reference: '',
    type: '',
    repeat: {
      interval: 'none',
      value: 0,
    },
    delay: {
      interval: 'none',
      value: 0,
    },
    selectedType: '',
  };

  const classes = useStyles();

  const [actionList, setActionList] = useState([]);
  const [actionLabel, setActionLabel] = useState('Reference');
  const [selectedReference, setSelectedReference] = useState(null);
  const aiJobs = useSelector(selectAIJobs);

  const { errors, handleSubmit, handleChange, values, touched, ...rest } = useFormik({
    initialValues: isEditMode
      ? { ...DEFAULT_ACTION, type: actiondata?.action?.type, reference: actiondata?.action?.reference }
      : { ...DEFAULT_ACTION },
    validationSchema: AddAutomationActionSchema,
    onSubmit: data => {
      const { selectedType, ...payload } = data;
      const { delay, repeat } = payload;
      onContinue({
        ...payload,
        delay: {
          interval: delay.interval === 'none' ? 'MIN' : delay.interval,
          value: delay.interval === 'none' ? 0 : delay.value,
        },
        repeat: repeat.interval === 'none' ? null : repeat,
      });
    },
  });

  const getPlanItems = typeValue => {
    return planItems.filter(p => p.type === typeValue);
  };

  const onSelectType = async e => {
    const val = e && e?.target?.value !== '' ? e.target.value : '';
    await rest.setFieldValue('type', val);
    await rest.setFieldValue('reference', '');
    await rest.setFieldValue('selectedType', '');

    setSelectedReference(null);
    setActionLabel(`Select ${val.toLowerCase()}`);
    if (val === 'ASSIGN_PLAN_ITEM') {
      setActionList([]);
      setActionLabel(`Select Plan Items`);
    } else if (val === 'ASSIGN_EVALUATION') {
      setActionList(evaluations);
      setActionLabel(`Select Evaluation`);
    }
  };

  const onSelectScheduledType = async e => {
    const val = e?.target?.value ? e?.target?.value : '';
    await rest.setFieldValue('reference', '');
    setSelectedReference(null);
    await rest.setFieldValue('repeat.interval', 'none');
    await rest.setFieldValue('repeat.value', 0);
    await rest.setFieldValue('delay.interval', 'none');
    await rest.setFieldValue('delay.value', 0);
    await rest.setFieldValue('selectedType', val);

    await rest.setFieldValue('type', 'ASSIGN_PLAN_ITEM');
    if (!e?.target?.value && isEditMode) {
      const selectedPlanItem = planItems?.find(p => p.id === actiondata?.action?.reference);
      await rest.setFieldValue('selectedType', selectedPlanItem?.type);
      await rest.setFieldValue('reference', selectedPlanItem?.id);
      const selectedPlanItems = getPlanItems(selectedPlanItem?.type);
      setSelectedReference({ label: selectedPlanItem?.name, value: selectedPlanItem?.id });
      setActionList(selectedPlanItems?.map(sp => ({ label: sp.name, value: sp.id })));
    } else if (val && values.type !== 'ASSIGN_EVALUATION') {
      const selectedPlanItems = getPlanItems(val);
      setActionList(selectedPlanItems?.map(sp => ({ label: sp.name, value: sp.id })));
    }
  };

  const onSelectReference = async (_, keyValue) => {
    const val =
      keyValue && keyValue?.value !== '' ? keyValue?.value : isEditMode ? actiondata?.action?.reference : '';

    await rest.setFieldValue('reference', val);
    setSelectedReference(keyValue);
  };

  useEffect(() => {
    if (
      isEditMode &&
      actiondata &&
      actiondata?.action &&
      actiondata?.action?.type.trim() !== '' &&
      actiondata?.action?.reference.trim() !== ''
    ) {
      void (async () => {
        if (eventdata?.name !== ActivityActionType.APPOINTMENT_SCHEDULED) {
          await onSelectScheduledType();
        } else {
          await rest.setFieldValue('type', actiondata?.action?.type);
          if (actiondata?.action?.type === 'ASSIGN_PLAN_ITEM') {
            setActionList([]);
            const selectedPlanItem = planItems?.find(p => p.id === actiondata?.action?.reference);
            await rest.setFieldValue('selectedType', selectedPlanItem?.type);
            await rest.setFieldValue('reference', selectedPlanItem?.id);
            const selectedPlanItems = getPlanItems(selectedPlanItem?.type);
            setSelectedReference({ label: selectedPlanItem?.name, value: selectedPlanItem?.id });
            setActionList(selectedPlanItems?.map(sp => ({ label: sp.name, value: sp.id })));
          } else {
            await rest.setFieldValue('type', 'ASSIGN_EVALUATION');
            setActionList(evaluations);
            setSelectedReference(evaluations?.find(ev => ev.value === actiondata?.action?.reference));
          }
        }
        await rest.setFieldValue('repeat.interval', actiondata?.action?.repeat?.interval);
        await rest.setFieldValue('repeat.value', actiondata?.action?.repeat?.value);
        await rest.setFieldValue('delay.interval', actiondata?.action?.delay?.interval);
        await rest.setFieldValue('delay.value', actiondata?.action?.delay?.value);
      })();
    }
  }, []);

  const setTouched = name => async () => {
    await rest.setTouched({ ...touched, [name]: true });
  };

  const renderLabel = label => (
    <Heading level={headingLevel.S} className={classes.label} weight={fontWeight.BOLD}>
      {label}
    </Heading>
  );

  return (
    <Box className={classes.container}>
      <Box className={classes.topWrap}>
        <Icons glyph="pointer" />
        <Text className={classes.title}>Action</Text>
      </Box>
      <form className={classes.form} onSubmit={handleSubmit}>
        <Box className={classes.formContent}>
          {eventdata?.name === ActivityActionType.APPOINTMENT_SCHEDULED && (
            <Box className={classes.section}>
              {renderLabel('Type')}
              <Select
                value={values.type}
                name="type"
                variant={selectType.SECONDARY}
                emptyText="Select action type"
                displayEmpty
                options={assignedPlanItemsSelect}
                onChange={onSelectType}
              />
              <TextError errorMsg={touched.type ? errors.type?.toString() : null} />
            </Box>
          )}
          {eventdata?.name === ActivityActionType.TRANSCRIPT_READY && (
            <Box className={classes.section}>
              {renderLabel('Type')}
              <Select
                value={values.type}
                name="type"
                variant={selectType.SECONDARY}
                emptyText="Select action type"
                displayEmpty
                options={transcriptReadySelections}
                onChange={onSelectType}
              />
              <TextError errorMsg={touched.type ? errors.type?.toString() : null} />
            </Box>
          )}
          {values.type !== 'ASSIGN_EVALUATION' && values.type !== 'RUN_AI_JOB' && (
            <Box className={classes.section}>
              {renderLabel('Select Plan Item Type')}
              <Select
                value={values.selectedType}
                name="selectedType"
                variant={selectType.SECONDARY}
                emptyText="Select type"
                displayEmpty
                options={typeSelects}
                onChange={onSelectScheduledType}
              />
              <TextError errorMsg={touched.selectedType ? errors.selectedType?.toString() : null} />
            </Box>
          )}

          {values.type === 'RUN_AI_JOB' ? (
            <Box className={classes.section}>
              {renderLabel('Select AI Job')}
              <Input
                fullWidth
                name="reference"
                placeholder="Select AI Job"
                size={inputSize.M}
                variant={inputType.AUTOCOMPLETE}
                value={selectedReference || {}}
                options={aiJobs
                  .map(job => {
                    return {
                      value: job.name,
                      label: job.name,
                    };
                  })
                  .concat({
                    value: 'AUTO_EVALUATION',
                    label: 'Auto Evaluation',
                  })}
                getOptionLabel={option => option?.label || ''}
                onBlur={setTouched('reference')}
                onChange={onSelectReference}
              />
              <TextError errorMsg={touched.reference ? errors.reference?.toString() : null} />
            </Box>
          ) : (
            <Box className={classes.section}>
              {renderLabel(actionLabel)}
              <Input
                fullWidth
                name="reference"
                placeholder={actionLabel}
                size={inputSize.M}
                variant={inputType.AUTOCOMPLETE}
                value={selectedReference || {}}
                options={actionList}
                getOptionLabel={option => option?.label || ''}
                onBlur={setTouched('reference')}
                onChange={onSelectReference}
              />
              <TextError errorMsg={touched.reference ? errors.reference?.toString() : null} />
            </Box>
          )}
          {values.reference ? (
            <Box>
              <Box>
                <Box className={classes.section}>
                  {renderLabel('Delay')}
                  <Select
                    value={values?.delay?.interval || ''}
                    name="delay.interval"
                    variant={selectType.SECONDARY}
                    emptyText="Interval"
                    options={actionDelayUnitSelect}
                    onChange={handleChange}
                  />
                  <TextError
                    errorMsg={
                      getIn(touched, 'delay.interval') ? getIn(errors, 'delay.interval')?.toString() : null
                    }
                  />
                </Box>
                {values && values.delay && values.delay.interval && values.delay.interval !== 'none' && (
                  <Box className={classes.section} mt={3}>
                    {renderLabel('Value')}
                    <Input
                      value={values?.delay?.value || 0}
                      name="delay.value"
                      type="number"
                      placeholder="Value"
                      onChange={handleChange}
                      size={inputSize.M}
                      fullWidth
                    />
                    <TextError
                      errorMsg={
                        getIn(touched, 'delay.value') ? getIn(errors, 'delay.value')?.toString() : null
                      }
                    />
                  </Box>
                )}
              </Box>
              <Box>
                <Box className={classes.section} mt={3}>
                  {renderLabel('Repeat')}
                  <Select
                    value={values?.repeat?.interval || ''}
                    name="repeat.interval"
                    variant={selectType.SECONDARY}
                    emptyText="Interval"
                    options={actionRepeatUnitSelect}
                    onChange={handleChange}
                  />
                  <TextError
                    errorMsg={
                      getIn(touched, 'repeat.interval') ? getIn(errors, 'repeat.interval')?.toString() : null
                    }
                  />
                </Box>
                {values && values.repeat && values.repeat.interval && values.repeat.interval !== 'none' && (
                  <Box className={classes.section} mt={3}>
                    {renderLabel('Value')}
                    <Input
                      value={values?.repeat?.value || 0}
                      name="repeat.value"
                      type="number"
                      placeholder="Value"
                      onChange={handleChange}
                      size={inputSize.M}
                      fullWidth
                    />
                    <TextError
                      errorMsg={
                        getIn(touched, 'repeat.value') ? getIn(errors, 'repeat.value')?.toString() : null
                      }
                    />
                  </Box>
                )}
              </Box>
            </Box>
          ) : null}
        </Box>
        <Box className={classes.footer}>
          <Button variant={btnType.TEXT} onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={handleSubmit}>Continue</Button>
        </Box>
      </form>
    </Box>
  );
};

export { AutomationAction };
