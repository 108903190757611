import SendBird from 'sendbird';
import { getSendbirdAppId } from './Credentials';
import { isNull } from './utils';
import * as AuthService from '../../services/auth/auth.service';

let instance = null;

class SendBirdAction {
  constructor() {
    if (instance) {
      return instance;
    }
    const appId = getSendbirdAppId();
    this.sb = new SendBird({
      appId,
    });
    this.previousMessageQuery = null;
    instance = this;
  }

  /**
   * Connect
   */
  async connect(userId, nickname, profileUrl, token) {
    const sb = SendBird.getInstance();

    // Connect using a Promise wrapper
    const connectUser = () => {
      return new Promise((resolve, reject) => {
        void sb.connect(userId, token, (user, error) => {
          if (error) {
            if (error.code === 400302) {
              // Token expired
              console.log('Sendbird Session token expired. Fetching a new token...');
              reject(error); // Reject to handle the error in the outer catch block
            } else {
              reject(error); // Handle other errors
            }
          } else {
            resolve(); // Connection successful
          }
        });
      });
    };

    // Update user info using a Promise wrapper
    const updateUserInfo = () => {
      return new Promise((resolve, reject) => {
        void sb.updateCurrentUserInfo(decodeURIComponent(nickname), profileUrl || '', (_user, _error) => {
          if (_error) {
            reject(_error); // Handle errors in updating user info
          } else {
            console.log('Sendbird user info updated successfully');
            resolve(); // Update successful
          }
        });
      });
    };

    try {
      // Attempt to connect
      await connectUser();

      // Update user info
      await updateUserInfo();

      return sb.currentUser; // Return the connected user
    } catch (error) {
      if (error.code === 400302) {
        // Token expired, handle reconnection
        console.log('Sendbird Session token expired. Fetching a new token...');
        const newToken = await this.refreshSessionToken();
        return this.connect(userId, nickname, profileUrl, newToken); // Recursively reconnect
      }
      throw error; // Propagate the original error
    }
  }

  disconnect() {
    return new Promise((resolve, reject) => {
      this.sb
        .disconnect((response, error) => {
          error ? reject(error) : resolve();
        })
        .then(r => console.log(r))
        .catch(e => console.log(e));
    });
  }

  isCurrentUser(user) {
    return user.userId === this.sb.currentUser.userId;
  }

  /**
   * Channel
   */
  getChannel(channelUrl, isOpenChannel = true) {
    return new Promise((resolve, reject) => {
      if (isOpenChannel) {
        SendBirdAction.getInstance()
          .sb.OpenChannel.getChannel(channelUrl, (openChannel, error) => {
            error ? reject(error) : resolve(openChannel);
          })
          .then(r => console.log(r))
          .catch(e => console.log(e));
      } else {
        SendBirdAction.getInstance()
          .sb.GroupChannel.getChannel(channelUrl, (groupChannel, error) => {
            error ? reject(error) : resolve(groupChannel);
          })
          .catch(e => console.log(e));
      }
    });
  }

  createGroupChannel(channelName, channelUrl, connectionId, currentUserId) {
    return new Promise((resolve, reject) => {
      // set params for the channel
      const sendBirdAction = SendBirdAction.getInstance();
      const params = new sendBirdAction.sb.GroupChannelParams();

      params.addUserIds([connectionId, currentUserId]);
      params.isDistinct = true;
      params.name = channelName;
      params.channelUrl = channelUrl; // In case of a group channel, you can create a new channel by specifying its unique channel URL in a 'GroupChannelParams' object.

      sendBirdAction.sb.GroupChannel.createChannel(params, (groupChannel, error) => {
        if (error) {
          reject(error);
        } else {
          // set channel preference for channel to auto accept invite
          const autoAccept = true;
          sendBirdAction.sb
            // eslint-disable-next-line func-names
            .setChannelInvitationPreference(autoAccept, function (response, _error) {
              if (_error) {
                console.log(_error);
              }
            })
            .then(r => console.log(r))
            .catch(e => console.log(e));
          resolve(groupChannel);
        }
      })
        .then(r => console.log(r))
        .catch(e => console.log(e));
    });
  }

  markAsRead(channel) {
    channel?.markAsRead();
  }

  /**
   * Message
   */
  getMessageList(channel, isInit = false, limit = 50) {
    if (isInit || isNull(this.previousMessageQuery)) {
      this.previousMessageQuery = channel.createPreviousMessageListQuery();
      this.previousMessageQuery.includeParentMessageInfo = true;
      this.previousMessageQuery.includeThreadInfo = true;
    }
    return new Promise((resolve, reject) => {
      if (this.previousMessageQuery.hasMore && !this.previousMessageQuery.isLoading) {
        this.previousMessageQuery.load(limit, true, (messageList, error) => {
          error ? reject(error) : resolve(messageList);
        });
      } else {
        resolve([]);
      }
    });
  }

  async getMemberInChannel(channel) {
    const { GroupChannel } = this.sb;
    try {
      const groupChannel = await GroupChannel.getChannel(channel);
      const { members } = groupChannel;
      return members;
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  sendUserMessage({ channel, message, parentMessageId, mentionedUserIds, data, handler }) {
    const params = new this.sb.UserMessageParams();
    params.parentMessageId = parentMessageId;
    params.message = message;
    params.mentionedUserIds = mentionedUserIds;
    params.data = JSON.stringify(data);
    return channel.sendUserMessage(params, (_message, error) => {
      if (handler) handler(_message, error);
    });
  }

  sendFileMessage({ channel, file, handler }) {
    return channel.sendFileMessage(file, (message, error) => {
      if (handler) handler(message, error);
    });
  }

  async refreshSessionToken() {
    const response = await AuthService.getSendbirdSessionToken();
    const { token } = response.data;
    return token;
  }

  static getInstance() {
    return new SendBirdAction();
  }
}

export { SendBirdAction };
