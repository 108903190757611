import cx from 'clsx';

// mui
import MuiBreadcrumbs from '@mui/material/Breadcrumbs';

// components
import { Link, linkType } from '../../atoms/link';
import { fontWeight, Text, textLevel } from '../../atoms/typography';

// styles
import { useStyles } from './Breadcrumbs.styles';

const Breadcrumbs = ({ className = '', links = [], ...rest }) => {
  const classes = useStyles();

  return (
    <MuiBreadcrumbs
      className={cx({
        [classes.root]: true,
        [className || '']: className,
      })}
      separator={
        <Text className={classes.slash} weight={fontWeight.REGULAR} level={textLevel.XS}>
          /
        </Text>
      }
      {...rest}
    >
      {links.map(({ href, text }, idx) => {
        return href ? (
          <Link key={idx} variant={linkType.SELF} className={classes.link} href={href} underline="hover">
            {text}
          </Link>
        ) : (
          <Text key={idx} className={classes.text} weight={fontWeight.SEMI_BOLD} level={textLevel.XS}>
            {text}
          </Text>
        );
      })}
    </MuiBreadcrumbs>
  );
};

export { Breadcrumbs };
