import { Box } from '@mui/material';
import { colors } from '../../../packages';
import cx from 'clsx';

import { HeadingFont, HeadingLevel, HeadingWeight } from './index.types';
import { useStyles } from './index.styles';

const Heading = ({
  children,
  className,
  variant = HeadingLevel.H1,
  fontFamily = HeadingFont.PRIMARY,
  fontWeight = HeadingWeight.BOLD,
  color = colors.neutral900,
  letterSpacing = 'normal',
}) => {
  const classes = useStyles();
  return (
    <Box
      className={cx({
        [classes.h1]: variant === HeadingLevel.H1,
        [classes.h2]: variant === HeadingLevel.H2,
        [classes.h3]: variant === HeadingLevel.H3,
        [classes.h4]: variant === HeadingLevel.H4,
        [classes.h5]: variant === HeadingLevel.H5,
        [classes.h6]: variant === HeadingLevel.H6,
        [classes.sub]: variant === HeadingLevel.SUB,
        [className]: className,
      })}
      fontFamily={fontFamily}
      fontWeight={fontWeight}
      color={color}
      letterSpacing={letterSpacing}
    >
      {children}
    </Box>
  );
};

export default Heading;
