import { makeStyles } from '@mui/styles';
// colors
import { colors } from '../../../packages';

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 24,
    position: 'relative',
    marginLeft: -64,
    marginRight: -64,
    height: '100%',
    background: '#FFF',
  },
  messageBody: {
    height: 'calc(100vh - 315px)',
    backgroundColor: '#F1F8FF',
    overflow: 'auto',
    padding: '24px 24px 16px',
    display: 'flex',
    flexDirection: 'column',
    overflowX: 'hidden',
    gap: 24,
    '& .no-msg': {
      textAlign: 'center',
      color: colors.neutral400,
    },
  },
  fullMessageBodyHeight: {
    height: '100vh !important',
    justifyContent: 'center',
  },
  replyMessageDisplay: {
    padding: 16,
    backgroundColor: colors.neutral50,
    height: 136,
    position: 'absolute',
    width: '100%',
    // bottom: '10rem',
    bottom: '6.5rem',
  },
  replyMessageDisplayImg: {
    padding: 16,
    backgroundColor: colors.neutral50,
    height: 196,
    position: 'absolute',
    width: '100%',
    bottom: '6.5rem',
  },
  onlinePresenceIndicator: {
    position: 'absolute',
    width: 15,
    height: 15,
    backgroundColor: '#F9911D',
    border: '3px solid white',
    borderRadius: '50%',
    marginLeft: 28,
    marginTop: 27,
  },
  replyOnlinePresenceIndicator: {
    position: 'absolute',
    width: 15,
    height: 15,
    backgroundColor: '#F9911D',
    border: '3px solid white',
    borderRadius: '50%',
    marginLeft: 28,
    marginTop: '27px !important',
  },
  headerOnlinePresenceIndicator: {
    position: 'absolute',
    width: 15,
    height: 15,
    backgroundColor: '#F9911D',
    border: '3px solid white',
    borderRadius: '50%',
    marginLeft: 26,
    marginTop: -14,
  },
  replyMessageDisplayTitle: {
    display: 'flex',
    flexDirection: 'row',
  },
  replyMessageDisplayTitleHeading: {
    width: 100,
  },
  replyMessageDisplayTitleBtn: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  messageText: {
    color: colors.neutral900,
    textAlign: 'left',
    lineHeight: '24px',
    wordWrap: 'break-word',
  },
  reloadBtn: {
    color: colors.white,
  },
  leftChatMessageButtonConatiner: {
    flexDirection: 'column',
    top: 10,
    right: 0,
    display: 'flex',
    alignItems: 'center',
    gap: 8,
  },
  replyButton: {
    height: 24,
    minWidth: '24px !important',
    width: 24,
    padding: 0,
    borderRadius: '50%',
    border: '1px solid ##8EC8FD',
    background: colors.white,
  },
  replyCloseBtn: {
    height: 48,
    minWidth: '48px !important',
    width: 48,
    padding: 0,
    marginTop: -12,
  },
  userChatReplyCard: {
    gap: 16,
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
    width: '100%',
    cursor: 'pointer',
    '& .userChatContentWrapper': {
      maxWidth: '93%',
      gap: 4,
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
    },
    '& .replyMsgContainer': {
      height: 60,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: 'contents',
    },
    '& .userChatMessageWrapper': {
      gap: 4,
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
    },
    '& .nickName': {
      lineHeight: '20px',
      color: colors.neutral900,
    },
    '& .time': {
      lineHeight: '16px',
      color: colors.neutral500,
    },
    '& .userChatNameWrapper': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: 8,
    },
  },
  newUserChatCard: {
    display: 'grid',
    gridTemplateColumns: '4% 70% 4%',
    gridTemplateRows: 'auto auto', // Main message + Reply
    gap: '0px 16px',
    position: 'relative',
    width: '100%',
    cursor: 'pointer',
    '& .userChatRow': { display: 'contents' },
    '& .userChatContentWrapper': {
      gap: 4,
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
    },
    '& .newUserChatMessageWrapper': {
      gap: 4,
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      backgroundColor: '#fff',
      padding: 16,
      border: '1px solid #E5EBF0',
      boxShadow: '0px 4px 8px 0px #0000000A',
      borderRadius: 4,
      width: '100%',
    },
    '& .nickName': {
      lineHeight: '20px',
      color: colors.neutral900,
    },
    '& .time': {
      lineHeight: '16px',
      color: colors.neutral500,
      marginLeft: '0.5rem',
    },
    '& .newUserChatNameWrapper': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  },
  drawerChatCard: { gridTemplateColumns: '4% 86% 4%' },
  highlighted: {
    backgroundColor: colors.neutral100,
  },
  loadMoreContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  avt: {
    minWidth: 40,
  },
  footer: {
    height: 'auto',
    background: colors.neutral25,
    position: 'absolute',
    bottom: -64,
    width: '100%',
    '& .MuiFormControl-root': {
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
    },
  },
  header: {
    background: '#fff',
    alignItems: 'center',
    paddingTop: 15,
    justifyContent: 'space-between',
    position: 'absolute',
    top: -48,
    left: 0,
    height: 72,
    width: '100%',
    paddingLeft: 16,
    paddingRight: 16,
    borderBottom: `1px solid ${colors.neutral100}`,
    '& .headerRightSide': {
      display: 'flex',
      flexDirection: 'column',
      gap: 4,
      alignItems: 'flex-end',
      '& .type': {
        lineHeight: '20px',
        color: colors.neutral900,
      },
      '& .loginDate': {
        lineHeight: '20px',
        color: colors.neutral500,
      },
    },
    '& .headerLeftSide': {
      display: 'flex',
      flexDirection: 'row',
      gap: 12,
      cursor: 'pointer',
      alignItems: 'center',
      paddingTop: 1,
    },
    '& .headerLeftSidePointer': {
      display: 'flex',
      flexDirection: 'row',
      gap: 12,
      cursor: 'pointer',
      alignItems: 'center',
    },
    '& .userNameWrapper': {
      display: 'flex',
      flexDirection: 'column',
      gap: 0,
      '& .nickName': {
        lineHeight: '20px',
        color: colors.neutral900,
      },
      '& .fullName': {
        lineHeight: '20px',
        color: colors.neutral600,
      },
    },
    '& .userNameWrapper2': {
      display: 'flex',
      flexDirection: 'column',
      gap: 0,
      width: '50%',
      '& .nickName': {
        lineHeight: '20px',
        color: colors.neutral900,
      },
      '& .fullName': {
        lineHeight: '20px',
        color: colors.neutral600,
      },
    },
  },
  chatSelectContainer: {
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    alignItems: 'center',
    flex: 1,
    paddingRight: 16,
  },
  chatSelect: {
    '& .MuiSelect-select': {
      marginLeft: 8,
      color: colors.neutral600,
    },
    '& .MuiSelect-icon > path': {
      fill: colors.neutral900,
    },
  },
  rightSideHeader: {
    height: 72,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  tabs: {
    width: '100%',
    height: 72,
    '& > div > div': {
      position: 'relative',
      '&:before': {
        content: "''",
        position: 'absolute',
        width: '100%',
        height: 1,
        backgroundColor: colors.neutral100,
        bottom: 0,
      },
    },
    '& button': {
      textTransform: 'capitalize',
      fontSize: 14,
      height: 72,
      fontWeight: 700,
      color: colors.neutral600,
      '&.Mui-selected': {
        color: colors.neutral900,
      },
    },
    '& .MuiTabs-indicator': {
      height: 1,
      backgroundColor: colors.neutral900,
    },
  },
  providerPhone: {
    width: '45%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  tabBadge: {
    minWidth: 22,
    height: 18,
    fontSize: 12,
    fontWeight: 600,
    lineHeight: '16px',
    padding: 0,
    borderColor: colors.secondary300,
    color: colors.secondary500,
  },
  headerWrap: {
    width: '100%',
    borderBottom: `1px solid ${colors.neutral100}`,
  },
  searchChat: {
    height: 40,
  },
  rightSideBarRoot: {
    display: 'flex',
    width: 400,
    flexDirection: 'column',
    alignItems: 'center',
    flex: 1,
    overflow: 'hidden',
  },
  userChatList: {
    paddingBottom: 24,
    width: '100%',
    flex: 1,
    overflowY: 'auto',
    '& .noRecord': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  userContactCard: {
    padding: '8px 16px 8px 16px',
    gap: 16,
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
    justifyContent: 'space-between',
    width: '100%',
    cursor: 'pointer',
    borderBottom: '1px solid #E5EBF0',
    '&.isActive': {
      backgroundColor: colors.neutral50,
    },
    '& .userChatContentWrapper': {
      gap: 4,
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
    },
    '& .nickName': {
      fontSize: 14,
      lineHeight: '20px',
      color: colors.neutral900,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      flex: 'none',
      maxWidth: ({ isCareTem }) => (isCareTem ? 140 : 160),
    },
    '& .fullName': {
      fontSize: 12,
      lineHeight: '16px',
      color: colors.neutral500,
    },
    '& .lastMessage': {
      lineHeight: '20px',
      color: colors.neutral700,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      maxWidth: 160,
    },
    '& .lastMessageTime': {
      lineHeight: '20px',
      color: colors.neutral700,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
    '& .userChatNameWrapper': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: 3,
      maxWidth: '10rem',
      minWidth: '12rem',
    },
    '& .badge': {
      height: 18,
      border: `1px solid ${colors.orange300}`,
      color: colors.orange600,
      background: colors.white,
      fontSize: 12,
      fontWeight: 600,
      lineHeight: '16px',
      // minWidth: '90px',
      textWrap: 'nowrap',
    },
  },
  unreadMessageBadge: {
    background: colors.secondary500,
    borderColor: colors.secondary500,
    position: 'absolute',
    color: colors.white,
    right: 6,
    fontSize: 12,
    width: '1.125rem',
    height: '1.125rem',
    borderRadius: '50%',
    padding: 0,
  },
  fileThumbnail: {
    height: 200,
    width: 180,
  },
  emptyState: {
    gap: '0px',
  },
  latest: {
    position: 'absolute',
    bottom: '6rem',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  latestBtn: {
    height: 32,
    borderRadius: 30,
    color: colors.white,
    '& svg': {
      transform: 'rotate(-90deg)',
      width: 20,
    },
  },
  chatContainer: {
    display: 'grid !important',
    gridTemplateColumns: '3% 10% 45% 40%',
  },
  loeContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: 2,
    marginLeft: 12,
  },

  active: {
    background: '#2DA834',
    color: 'white',
    fontSize: '13px',
    height: 24,
    padding: '2px 10px',
    textAlign: 'center',
  },
  activeOutlined: {
    borderColor: colors.success400,
    fontSize: '13px',
    color: '#268C2B',
    padding: '2px 10px',
    textAlign: 'center',
  },
  headContainer: {
    display: 'flex',
    gap: 4,
    marginLeft: 'auto',
  },
  colLabel: {
    color: colors.neutral500,
  },
  subStatus: {
    marginTop: 1,
    height: 24,
  },
  divider: {
    borderLeft: '1px solid #D4DDE5',
    paddingRight: 20,
    height: 40,
    marginTop: -1,
  },
  divPadding: {
    // marginRight: 20,
  },
  colLabel2: {
    paddingTop: 1,
    gap: '4px !important',
    lineHeight: 16,
    paddingRight: 24,
  },
  colLabel3: {
    paddingTop: 1,
    gap: '4px !important',
    lineHeight: 16,
    // paddingRight: 24,
  },
  engaged: {
    background: colors.success500,
  },
  outreachNeeded: {
    background: colors.warning600,
  },
  notEngaged: {
    background: colors.destructive500,
  },
  todoDates: {
    fontWeight: '700',
  },
  link: {
    fontFamily: 'Manrope',
    fontWeight: 600,
    fontSize: 14,
    lineHeight: '19px',
    color: colors.primary600,
    transition: '0.4s',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
      opacity: 0.8,
    },
  },
  drawer: {
    '& .MuiPaper-root': {
      maxWidth: '560px !important',
    },
    '& .drawerBody': {
      minHeight: 'calc(100vh - 80px)',
      flexDirection: 'column',
      display: 'flex',
      padding: 0,
    },
  },
  chatBtn: {
    minWidth: 40,
    width: 40,
    height: 40,
    border: `1px solid ${colors.primary300}`,
  },
  chatBtnDisabled: {
    minWidth: 40,
    width: 40,
    height: 40,
  },
  availableBadge: {
    height: 28,
    background: colors.neutral50,
    borderRadius: 6,
    paddingTop: 4,
    paddingBottom: 4,
    paddingLeft: 12,
    paddingRight: 12,
  },
  availableBadgeFitWidth: {
    height: 28,
    background: colors.neutral50,
    borderRadius: 6,
    paddingTop: 4,
    paddingBottom: 4,
    paddingLeft: 12,
    paddingRight: 12,
    minWidth: 'fit-content',
    width: 'fit-content',
  },
  toggleWrapper: {
    display: 'flex',
    felxDirection: 'row',
    color: colors.primary600,
  },
  chevronIcon: {
    width: 24,
    minWidth: 24,
    height: 24,
    objectFit: 'contain',
    transition: '0.4s',
    marginLeft: '12px',
    marginRight: '12px',
    transform: ({ collapsed }) => `rotate(${collapsed ? '180deg' : 0})`,
  },
  toggleText: {
    curser: 'pointer',
  },
  badgeWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  badgeWrapperAlignStart: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    minWidth: 90,
    paddingTop: 2,
    justifyContent: 'flex-end',
  },
  profileWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    gap: 4,
  },
  profileWrapperCenterAligned: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 4,
  },
  careNavigatorBadge: {
    border: `1px solid ${colors.neutral200}`,
    borderRadius: 100,
    color: colors.neutral700,
    padding: '0px 8px',
  },
  iconBtn: {
    '& button': {
      minWidth: 40,
      height: 40,
      padding: 0,
      border: `1px solid ${colors.primary300}`,
    },
  },
  careTeambar: {
    backgroundColor: '#FFF',
    padding: 16,
    borderBottom: `1px solid ${colors.neutral100}`,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  joinCallbar: {
    padding: 6,
    paddingLeft: 16,
    background: colors.primary500,
    display: 'flex',
    gap: 32,
    borderRadius: 6,
    alignItems: 'center',
  },
  providerBadge: {
    height: 18,
    minHeight: 'fit-content',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 100,
    background: colors.indigo50,
    fontSize: 12,
    fontWeight: 600,
    lineHeight: '16px',
    color: colors.indigo600,
    paddingLeft: 8,
    paddingRight: 8,
    marginLeft: 8,
  },
  badge: {
    height: 22,
    borderRadius: 100,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: 160,
    minWidth: 'fit-content',
  },
  provider: {
    color: colors.indigo600,
    background: colors.indigo50,
  },
  supervising: {
    color: colors.green600,
    background: colors.green50,
  },
  care: {
    color: colors.orange600,
    background: colors.orange50,
  },
  coach: {
    color: colors.warning600,
    background: colors.warning50,
  },
  member: {
    color: colors.rose600,
    background: colors.rose50,
  },
  designation: {
    color: colors.destructive600,
    background: colors.destructive50,
  },
  loadMoreBtn: {
    '& svg': {
      width: 16,
      height: 16,
    },
  },
  loadMoreBtnLoading: {
    // display: 'flex',
    // animation: `$ckw 3000ms`,
    // animationDuration: '5.5s',
    // animationIterationCount: 'infinite',
    // transformOrigin: '50% 50%',
  },
  // '@keyframes ckw': {
  //   '0%': {
  //     transform: 'rotate(0deg)',
  //   },
  //   '100%': {
  //     transform: 'rotate(360deg)',
  //   },
  // },
  loadMoreBtnWrapper: {
    display: 'flex',
    justifyContent: 'center',
    color: colors.primary500,
    fontWeight: 500,
  },
  loadMoreBtnTextWrapper: {
    display: 'flex',
    justifyContent: 'center',
    cursor: 'pointer',
    color: colors.primary500,
    fontWeight: 700,
    fontSize: 14,
    marginLeft: '-15px',
  },
  loadMoreBtnTextWrapperDisabled: {
    display: 'flex',
    justifyContent: 'center',
    color: colors.textPrimary,
    fontWeight: 700,
    fontSize: 14,
    marginLeft: '-15px',
  },
  label: {
    fontSize: 14,
    lineHeight: '20px',
    margin: 0,
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    position: 'relative',
  },
  content: {
    display: 'flex',
    paddingLeft: 26,
    paddingRight: 26,
    paddingTop: 26,
    flexDirection: 'column',
  },
  defaultCareNavigatorInput: {
    height: '48px',
  },
  headerIcons: {
    width: 16,
    height: 16,
  },
  headerDesignationText: {
    fontWeight: 700,
    fontSize: 12,
    lineHeight: '16px',
  },
  headerNameText: {
    fontWeight: 600,
    fontSize: 14,
    lineHeight: '20px',
  },
  secondaryHeaderIcon: {
    width: 16,
    height: 16,
  },
  headerBtnUser: {
    border: '1px solid #0374DD !important',
  },
  headerBtnCalendar: {
    border: '1px solid #DD0374 !important',
    '&:hover': {
      backgroundColor: colors.secondary50,
    },
  },
  headerBtnCalendarChecked: {
    border: '1px solid #16B364 !important',
    '&:hover': {
      backgroundColor: colors.green50,
    },
  },
  headerBtnPhone: {
    border: '1px solid #7A5AF8 !important',
    '&:hover': {
      backgroundColor: colors.purple50,
    },
  },
  headerBtnCamera: {
    border: '1px solid #06AED4 !important',
    '&:hover': {
      backgroundColor: colors.cyan50,
    },
  },
  headerBtnMultipleUsers: {
    border: '1px solid #EF6820 !important',
    '&:hover': {
      backgroundColor: colors.orange50,
    },
  },
  sideCardWrapper: {
    display: 'flex',
    padding: 10,
    gap: 10,
    flexDirection: 'column',
    width: '100%',
  },
  sideCardText: {
    maxWidth: 345,
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  sideCardHeader: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  sideCardFooter: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  sideCardMessage: {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '20px',
    color: '#4C5965',
    maxWidth: 345,
    wordBreak: 'break-all',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  sideCardTime: {
    fontWeight: 500,
    fontSize: 12,
    lineHeight: '16px',
    color: '#667786',
  },
  sideCardRead: {
    fontWeight: 700,
    fontSize: 12,
    lineHeight: '16px',
  },
  newReplyMessageWrapper: {
    gridColumn: 'span 3', // Reply box spans across all columns
    margin: '0 3.5rem',
    borderRadius: 4,
    boxShadow: '0px 4px 8px 0px #0000000A',
    '& .newUserChatNameWrapper': {
      flexDirection: 'row',
      gap: 8,
      justifyContent: 'flex-start',
    },
    '& .nickName': {
      lineHeight: '20px',
      color: colors.primary500,
    },
  },
  drawerReplyMessageWrapper: { margin: '0 2.5rem' },
  leftAvt: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  menuIcons: {
    width: 16,
    height: 16,
  },
  fileImage: {
    objectFit: 'cover',
    width: '350px',
    maxHeight: '200px',
  },
});
