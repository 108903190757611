export const getContentBlockHistory = state => state.reporting;

export const selectReport = state => state.reporting.report;

export const selectReportList = state => state.reporting.report?.reportList ?? [];

export const selectReportSaving = state => state.reporting.report?.isSaving;

export const selectClaimsList = state => state.reporting.claims;

export const selectClaim = state => state.reporting.claim;

export const selectInvoiceList = state => state.reporting.invoices;

export const selectReportDetailTotalMembers = state => state.reporting.reportDetailTotalMembers;

export const selectProviderFee = state => state.reporting.providerFee;

export const selectInvoice = state => state.reporting.invoice;
