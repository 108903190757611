// colors
import { colors } from '../../../packages';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    paddingTop: 88,
    gap: 40,
  },
  header: {
    position: 'absolute',
    top: -48,
    left: -64,
    height: 88,
    width: '100vw',
    borderBottom: `1px solid ${colors.neutral100}`,
    paddingLeft: 32,
    paddingRight: 32,
    display: 'flex',
    alignItems: 'center',
  },
  heading: {
    fontFamily: 'Source Serif Pro',
    margin: 0,
  },
  table: {
    borderRadius: '8px',
    backgroundColor: 'white',
    '& .MuiTableCell-body': {
      padding: '24px 16px 24px 24px !important',
    },
    '& .MuiTableCell-head:nth-child(1)': {
      borderTopLeftRadius: '8px',
    },
    '& .MuiTableCell-head:nth-child(2)': {
      borderTopRightRadius: '8px',
    },
  },
  menu: {
    float: 'right',
    color: colors.neutral400,
    right: 20,
    '& svg': {
      width: 9,
    },
  },
  menuItemsWrapper: {
    '& ul': {
      padding: 0,
    },
    '& ul li': {
      padding: '10px 16px',
      '&:last-child': {
        color: colors.destructive500,
        '& svg path': {
          fill: colors.destructive500,
        },
      },
    },
    '& .menu-item-custom': {
      borderBottom: `1px solid ${colors.neutral100}`,
    },
  },
  icon: {
    marginRight: 12,
    width: 16,
  },
  addReportBtn: {
    fontSize: 14,
    fontWeight: 'bold',
    lineHeight: '20px',
    color: colors.primary500,
    border: `1px solid ${colors.primary300}`,
    height: 40,
    minWidth: 139,
    width: 'auto',
    background: '#FFF',
    padding: '8px 16px 8px 16px',
    borderRadius: '4px',
  },
  addReportBtnDisabled: {
    opacity: 0.7,
    border: 'none !important',
  },
});
