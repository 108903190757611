import { Box, Stack } from '@mui/material';
import dayjs from 'dayjs';
import clsx from 'clsx';

import { Drawer, drawerType } from '../../../../packages/ui/organisms/drawer';
import { boxType, DashboardBox } from '../../../../packages/ui/templates/dashboard-box';
import { Badge, badgeStyle, badgeType, fontWeight, Heading, Text } from '../../../../packages';
import { ProfileInfo } from '../../../../packages/ui/templates/profile-info';
import { useStyles } from './PaymentDetailDrawer.styles';

const PaymentDetailDrawer = ({ open, onClose, appointment }) => {
  const classes = useStyles();

  const renderLabel = label => <Text className={classes.infoBlockLabel}>{label}</Text>;
  const renderAdditionalLabel = label => <Text className={classes.infoBlockAdditionalLabel}>{label}</Text>;

  return (
    <Drawer
      open={open}
      onClose={onClose}
      title="Payment details"
      variant={drawerType.FORM}
      footer={<Box />}
      className={classes.drawer}
    >
      {appointment && (
        <Box>
          <Box className={classes.top}>
            <Stack direction="row" alignItems="center" gap={1.5}>
              <Heading className={classes.amount}>${appointment.amountPaid}</Heading>
              <Stack direction="column" justifyContent="space-between" flex={1}>
                <Badge
                  variant={badgeType.OUTLINED}
                  style={appointment?.amountPaid > 0 ? badgeStyle.RESOLVED : badgeStyle.CRITICAL}
                  className={clsx(classes.status, { [classes.unpaidStatus]: appointment?.amountPaid <= 0 })}
                >
                  {appointment?.amountPaid > 0 ? 'Paid' : 'UnPaid'}
                </Badge>
                <Text className={classes.time}>
                  {dayjs(appointment.appointmentTime).format('MM/DD/YYYY - h:mm a')}
                </Text>
              </Stack>
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              gap={2}
              sx={{ marginTop: 4 }}
            >
              {[
                { title: 'our cost', value: `$${appointment?.serviceCost}` },
                { title: 'recommended', value: `$${appointment?.recommendedCost}` },
                { title: 'market rate', value: `$${appointment?.marketRate}` },
              ].map(item => (
                <DashboardBox
                  key={item.title}
                  className={classes.item}
                  label={item.title}
                  value={item.value}
                  type={boxType.TOTAL}
                />
              ))}
            </Stack>
          </Box>
          <Box className={classes.infoWrap}>
            <Box className={classes.infoBlock}>
              {renderLabel('Paid by member')}
              <ProfileInfo
                type="member"
                photo={appointment.patientProfileImage}
                nickName={
                  appointment.patientFirstName && appointment.patientLastName
                    ? `${appointment.patientFirstName || ''} ${appointment.patientLastName || ''}`
                    : appointment?.patientNickName ?? 'No Name'
                }
                fullName={appointment?.patientUuid}
                memberId={appointment.memberId}
              />
            </Box>
            <Box className={classes.infoBlock}>
              {renderLabel('Provider')}
              <ProfileInfo
                type="provider"
                photo={appointment.providerProfileImage}
                role={appointment.providerRole}
                fullName={appointment.providerFullName}
                memberId={appointment.providerId}
              />
            </Box>
            <Box className={classes.infoBlock}>
              {renderLabel('Session type')}
              <Box className={classes.member}>
                <Text className={classes.id} weight={fontWeight.MEDIUM}>
                  {appointment.serviceName}
                </Text>
              </Box>
            </Box>
          </Box>
          <Box className={classes.infoWrap}>
            <Box className={classes.infoBlock}>
              {renderAdditionalLabel('Payment method')}
              <Box className={classes.member}>
                <Text className={classes.id} weight={fontWeight.MEDIUM}>
                  {appointment.payeeType}
                </Text>
              </Box>
              {renderAdditionalLabel('State')}
              <Box className={classes.member}>
                <Text className={classes.id} weight={fontWeight.MEDIUM}>
                  {appointment.patientState}
                </Text>
              </Box>
              {appointment.cptCode?.length > 0 && renderAdditionalLabel('CPT code')}
              <Box className={classes.member}>
                <Text className={classes.id} weight={fontWeight.MEDIUM}>
                  {appointment.cptCode}
                </Text>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </Drawer>
  );
};

export { PaymentDetailDrawer };
