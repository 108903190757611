import { ApiEndpoints } from '../../constants/ApiEndpoints';
import { DCT_STATUS } from '../../constants/CommonConstants';
import { baseRequest } from '../../utils/HttpClient';

export const getChatbots = (pathParams, queryParams) => {
  return baseRequest(ApiEndpoints.CHATBOT_DETAILS, null, pathParams, queryParams);
};

export const getChatbotContacts = conversationContextId => {
  return baseRequest(ApiEndpoints.GET_CHATBOT_CONTACTS, null, { conversationContextId });
};

export const getChatbotAttemptHistory = requestBody => {
  return baseRequest(ApiEndpoints.GET_CHATBOT_ATTEMPT_HISTORY, requestBody);
};

export const getChannelUrl = pathParams => {
  if (!pathParams?.connectionId) {
    console.error('No Connection Id');
  }
  return baseRequest(ApiEndpoints.GET_CHANNEL_URL, null, pathParams);
};

export const getAppointmentEvaluationProgress = pathParams => {
  if (!pathParams?.appointmentId) {
    console.error('No Appointment Id');
  }
  return baseRequest(ApiEndpoints.GET_APPOINTMENT_EVALUATION_PROGRESS, null, pathParams, null);
};

export const provideAIFeedback = (request, appointmentId) => {
  return baseRequest(ApiEndpoints.PROVIDE_AI_EVALUATION_FEEDBACK, request, { appointmentId }, null);
};

export const getEvaluationContext = pathParams => {
  if (!pathParams?.appointmentId || !pathParams?.evaluationId) {
    console.error('No Appointment OR Evaluation Id');
  }
  return baseRequest(ApiEndpoints.GET_EVALUATION_CONTEXT, null, pathParams, null);
};
export const updateSessionTranscript = (appointmentId, requestBody) => {
  return baseRequest(ApiEndpoints.UPDATE_SESSION_TRANSCRIPT, requestBody, { appointmentId }, null);
};

export const updateEvaluationContext = (payload, pathParams) => {
  if (!pathParams?.appointmentId || !pathParams?.evaluationId) {
    console.error('No Appointment OR Evaluation Id');
  }
  return baseRequest(ApiEndpoints.UPDATE_EVALUATION_CONTEXT, payload, pathParams, null);
};

export const updateContextForFullPageDCT = payload => {
  return baseRequest(ApiEndpoints.UPDATE_EVALUATION_CONTEXT_FOR_FULL_PAGE_DCT, payload, null, null);
};

export const updateAppointmentEvaluationStatus = (payload, pathParams) => {
  if (!pathParams?.appointmentId) {
    console.error('No Appointment Id');
  }
  return baseRequest(ApiEndpoints.UPDATE_APPOINTMENT_EVALUATION_STATUS, payload, pathParams, null);
};
export const updateAppointmentAddMultipleEvaluations = (appointmentEvaluation, pathParams) => {
  if (!pathParams?.appointmentId) {
    console.error('No Appointment Id');
  }
  return baseRequest(
    ApiEndpoints.UPDATE_APPOINTMENT_ADD_MULTIPLE_EVALUATIONS,
    appointmentEvaluation,
    pathParams,
    null,
  );
};
export const getDctsList = params => {
  const {
    searchQuery,
    pageNumber,
    pageSize,
    orderBy,
    sortBy,
    status,
    type,
    assignmentCount = true,
    reportingView = false,
    isScorable,
    isAiGenerated,
  } = params;
  const queryParams = {
    searchQuery: searchQuery ?? '',
    pageNumber: pageNumber ?? 0,
    pageSize: pageSize ?? 10000,
    orderBy: orderBy ?? '',
    sortBy: sortBy ?? [],
    status: status ?? DCT_STATUS.ACTIVE,
    type: type ?? '',
    assignmentCount,
    reportingView,
    isScorable: isScorable ?? '',
    isAiGenerated: !!isAiGenerated,
  };
  return baseRequest(ApiEndpoints.GET_DCT_LIST, null, null, queryParams, true);
};

export const addDct = dct => {
  return baseRequest(ApiEndpoints.ADD_DCT, dct, null, null, true);
};

export const addAutomationRule = automationRuleRequest => {
  return baseRequest(ApiEndpoints.ADD_AUTOMATION_RULE, automationRuleRequest, null, null, true);
};

export const updateAutomationRule = (automationRuleRequest, automationRuleId) => {
  return baseRequest(
    ApiEndpoints.UPDATE_AUTOMATION_RULE,
    automationRuleRequest,
    { automationRuleId },
    null,
    true,
  );
};

export const getDCTDetails = (dctId, version) => {
  return baseRequest(ApiEndpoints.GET_DCT_DETAIL, null, { dctId }, { version }, true);
};

export const updateDCTScoring = (scoring, dctId) => {
  return baseRequest(ApiEndpoints.UPDATE_DCT_SCORING, scoring, { dctId }, null, true);
};

export const addEvaluationDct = dct => {
  return baseRequest(ApiEndpoints.ADD_EVALUATION_DCT, dct, null, null, true);
};

export const getContentblocksByDct = dctId => {
  return baseRequest(ApiEndpoints.GET_CONTENT_BLOCKS_BY_DCT, null, { dctId }, null, true);
};

export const updateDCT = (dctId, request) => {
  return baseRequest(ApiEndpoints.UPDATE_DCT, request, { dctId }, null, true);
};
export const updateEvaluationDCT = (dctId, request) => {
  return baseRequest(ApiEndpoints.UPDATE_EVALUATION_DCT, request, { dctId }, null, true);
};

export const getDctAssignmentReport = (dctId, period, version) => {
  return baseRequest(ApiEndpoints.GET_DCT_ASSIGNMENT_REPORT, null, { dctId }, { period, version }, true);
};

export const getDctAssignmentDetail = (dctId, version) => {
  return baseRequest(ApiEndpoints.GET_DCT_ASSIGNMENT_DETAIL, null, { dctId }, { version }, true);
};
/// ////

export const getEvaluationAssignmentDetail = (evaluationId, version) => {
  return baseRequest(ApiEndpoints.GET_EVALUATION_DETAIL, null, { evaluationId }, { version }, true);
};

export const getEvaluationAssignmentReport = (evaluationId, period, version) => {
  return baseRequest(
    ApiEndpoints.GET_EVALUATION_ASSIGNMENT_DETAIL_GRAPH,
    null,
    { evaluationId },
    { period, version },
    true,
  );
};

export const getEvaluationDCTAssignmentDetail = (dctId, version) => {
  return baseRequest(ApiEndpoints.GET_EVALUATION_DCT_ASSIGNMENT_DETAIL, null, { dctId }, { version }, true);
};

export const getEvaluationDCTAssignmentReport = (dctId, period, version) => {
  return baseRequest(
    ApiEndpoints.GET_EVALUATION_DCT_ASSIGNMENT_REPORT,
    null,
    { dctId },
    { period, version },
    true,
  );
};
/// //////////

export const upgradeDctVersion = dctId => {
  return baseRequest(ApiEndpoints.UPGRADE_DCT_VERSION, null, { dctId }, null, true);
};

export const publishDCT = dctId => {
  return baseRequest(ApiEndpoints.PUBLISH_DCT_DRAFT, null, { dctId }, null, true);
};

export const getDCTRipplesCount = dctId => {
  return baseRequest(ApiEndpoints.CHECK_DCT_DRAFT_RIPPLES, null, { dctId }, null, true);
};

export const getSessionTypes = () => {
  return baseRequest(ApiEndpoints.GET_SESSION_TYPES, null);
};

export const createSessionType = data => {
  return baseRequest(ApiEndpoints.CREATE_SESSION_TYPE, data);
};

export const updateSessionType = data => {
  const payload = {
    name: data.name,
    description: data.description,
  };
  return baseRequest(ApiEndpoints.UPDATE_SESSION_TYPE, payload, { name: data.id });
};

export const deleteSessionType = name => {
  return baseRequest(ApiEndpoints.DELETE_SESSION_TYPE, null, { name });
};

export const getPlanItems = queryParams => {
  return baseRequest(ApiEndpoints.GET_PLAN_ITEMS, null, null, queryParams);
};

export const addPlanItem = data => {
  return baseRequest(ApiEndpoints.ADD_NEW_PLAN_ITEM, data, null, null, true);
};

export const updatePlanItem = data => {
  return baseRequest(ApiEndpoints.UPDATE_PLAN_ITEM, data, null, { revampPlanItemId: data.id });
};

export const deletePlanItem = id => {
  return baseRequest(ApiEndpoints.DELETE_PLAN_ITEM, null, null, { planItemId: id });
};

export const getAssignedPlanItems = queryParams => {
  return baseRequest(ApiEndpoints.GET_ASSIGNED_PLAN_ITEMS, null, null, queryParams);
};

export const addAssignedPlanItems = data => {
  return baseRequest(ApiEndpoints.ADD_ASSIGNED_PLAN_ITEMS, data, null, null, true);
};

export const getConversations = queryParams => {
  return baseRequest(ApiEndpoints.GET_CONVERSATIONS_DETAIL, null, null, queryParams);
};

export const getAutomationRules = queryParams => {
  return baseRequest(ApiEndpoints.GET_AUTOMATION_RULES_DETAIL, null, null, queryParams);
};

export const deleteAutomationRule = automationRuleId => {
  return baseRequest(ApiEndpoints.DELETE_AUTOMATION_RULE, null, { automationRuleId });
};

export const getRevampTypes = () => {
  return baseRequest(ApiEndpoints.GET_REVAMP_TYPES, null, null);
};

export const getEvaluationList = ({
  searchQuery = '',
  pageNumber = 0,
  pageSize = 10000,
  orderBy = '',
  sortBy = [],
  status = '',
  reportingView = false,
  isAiGenerated = false,
}) => {
  const queryParams = {
    searchQuery,
    pageNumber,
    pageSize,
    orderBy,
    sortBy,
    status,
    reportingView,
    isAiGenerated,
  };
  return baseRequest(ApiEndpoints.GET_EVALUATIONS, null, null, queryParams);
};

export const getDctComments = pathParams => {
  return baseRequest(ApiEndpoints.GET_CONTENT_BLOCK_COMMENTS, null, pathParams, null, true);
};

export const addDctComment = (payload, pathParams) => {
  return baseRequest(ApiEndpoints.ADD_CONTENT_BLOCK_COMMENT, payload, pathParams, null, true);
};

export const addEvaluation = payload => {
  return baseRequest(ApiEndpoints.ADD_NEW_EVALUATION, payload, null, null, true);
};

export const updateEvaluation = (payload, pathParams) => {
  return baseRequest(ApiEndpoints.UPDATE_EVALUATION, payload, pathParams, null, true);
};

export const getConversationsList = ({
  searchQuery = '',
  pageNumber = 0,
  pageSize = 10,
  orderBy = '',
  sortBy = [],
  status = '',
}) => {
  const queryParams = { searchQuery, pageNumber, pageSize, orderBy, sortBy, status };

  return baseRequest(ApiEndpoints.GET_CONVERSATIONS_DETAIL, null, null, queryParams, true);
};

export const addConversation = payload => {
  return baseRequest(ApiEndpoints.ADD_NEW_CONVERSATION, payload, null, null, true);
};
export const updateConversation = (payload, pathParams) => {
  return baseRequest(ApiEndpoints.UPDATE_CONVERSATION, payload, pathParams, null, true);
};

export const getConversationAssignmentDetail = (pathParams, version) => {
  return baseRequest(ApiEndpoints.GET_CONVERSATION_ASSIGNMENT_DETAIL, null, pathParams, { version }, true);
};

export const getConversationAssignmentReport = (period, pathParams, version) => {
  const queryParams = { period, version };

  return baseRequest(ApiEndpoints.GET_CONVERSATION_ASSIGNMENT_REPORT, null, pathParams, queryParams, true);
};
export const getTagsList = ({
  searchQuery = '',
  pageNumber = 0,
  pageSize = 10,
  orderBy = '',
  sortBy = [],
  status = '',
}) => {
  const queryParams = { searchQuery, pageNumber, pageSize, orderBy, sortBy, status };
  return baseRequest(ApiEndpoints.GET_TAGS_LIST, null, null, queryParams, true);
};

export const addTag = payload => {
  return baseRequest(ApiEndpoints.ADD_NEW_TAG, payload, null, null, true);
};
export const updateTag = (payload, pathParams) => {
  return baseRequest(ApiEndpoints.UPDATE_TAG, payload, pathParams, null, true);
};

export const getTagReport = pathParams => {
  return baseRequest(ApiEndpoints.GET_TAG_REPORT, null, pathParams, null, true);
};
export const getTagAssignmentDetails = pathParams => {
  return baseRequest(ApiEndpoints.GET_TAG_ASSIGNMENT_DETAILS, null, pathParams, null, true);
};

export const getTagDetail = pathParams => {
  return baseRequest(ApiEndpoints.GET_TAG_DETAIL, null, pathParams, null, true);
};
export const getContentBlocksByConversation = conversationId => {
  return baseRequest(ApiEndpoints.GET_CONTENT_BLOCKS_BY_CONVERSATION, null, { conversationId }, null, true);
};

export const addContentBlock = request => {
  return baseRequest(ApiEndpoints.ADD_NEW_CONTENT_BLOCK, request, null, null, true);
};

export const saveContentBlock = (cbId, contentBlock) => {
  return baseRequest(ApiEndpoints.SAVE_CONTENT_BLOCK, contentBlock, { cbId }, null, true);
};

export const deleteContentBlock = cbId => {
  return baseRequest(ApiEndpoints.DELETE_CONTENT_BLOCK, null, { cbId }, null, true);
};

export const addContentBlockInLibrary = payload => {
  return baseRequest(ApiEndpoints.ADD_CONTENT_BLOCK, payload, null, null, true);
};

export const integrateContentBlock = payload => {
  return baseRequest(ApiEndpoints.USE_CONTENT_BLOCK, payload, null, null, true);
};

export const getContentBlockInLibrary = queryParams => {
  return baseRequest(ApiEndpoints.FETCH_LIBRARY_CONTENT_BLOCKS, null, null, queryParams, true);
};

export const updateContentBlockInLibrary = (data, contentBlockId) => {
  return baseRequest(ApiEndpoints.UPDATE_LIBRARY_CONTENT_BLOCKS, data, { contentBlockId }, null, true);
};

export const deleteContentBlockInLibrary = contentBlockId => {
  return baseRequest(ApiEndpoints.DELETE_LIBRARY_CONTENT_BLOCKS, null, { contentBlockId }, null, true);
};

export const deleteEvaluation = (appointmentId, evaluationId) => {
  return baseRequest(ApiEndpoints.DELETE_EVALUATION, { evaluationId }, { appointmentId }, null, true);
};

export const getProfileElementsList = (searchQuery, type, method, pageNumber, pageSize, orderBy, sortBy) => {
  const queryParams = {
    searchQuery: searchQuery || '',
    type: type || '',
    method: method || '',
    pageNumber: pageNumber || 0,
    pageSize: pageSize || 10000,
    orderBy: orderBy || '',
    sortBy: sortBy || [],
  };
  return baseRequest(ApiEndpoints.GET_PROFILE_ELEMENTS, null, null, queryParams, true);
};
export const getAllChoiceTemplates = () => {
  return baseRequest(ApiEndpoints.GET_ALL_CHOICE_TEMPLATES, null, null, null, true);
};

export const saveChoiceTemplate = request => {
  return baseRequest(ApiEndpoints.SAVE_CHOICE_TEMPLATE, request, null, null, true);
};

export const getContentBlocksByConsversationId = conversationId => {
  return baseRequest(ApiEndpoints.GET_CONTENT_BLOCKS_BY_CONVERSATION, null, { conversationId }, null, true);
};

export const addNewContentBlock = request => {
  return baseRequest(ApiEndpoints.ADD_NEW_CONTENT_BLOCK, request, null, null, true);
};
export const upgradeConversationVersion = conversationId => {
  return baseRequest(ApiEndpoints.UPGRADE_CONVERSATION_VERSION, null, { conversationId }, null, true);
};
export const publishConversation = conversationId => {
  return baseRequest(ApiEndpoints.PUBLISH_CONVERSATION_DRAFT, null, { conversationId }, null, true);
};
export const getConversationRippleCounts = conversationId => {
  return baseRequest(ApiEndpoints.CHECK_CONVERSATION_DRAFT_RIPPLES, null, { conversationId }, null, true);
};
// Get Associated tag/ data
export const getAssociatedTagData = pathParams => {
  return baseRequest(ApiEndpoints.GET_DOMAIN_ELEMENT, null, null, pathParams, true);
};

export const getProfileElements = payload => {
  return baseRequest(ApiEndpoints.GET_PROFILE_ELEMENTS, null, null, payload);
};

export const addProfileElement = payload => {
  return baseRequest(ApiEndpoints.ADD_NEW_PROFILE_ELEMENT, payload, null, null, true);
};

export const createMemberConnection = requestBody => {
  return baseRequest(ApiEndpoints.CREATE_MEMBER_CONNECTION, requestBody, null, null, true);
};

export const deleteMemberConnection = param => {
  return baseRequest(ApiEndpoints.DELETE_MEMBER_CONNECTION, null, param, null, true);
};

export const updateProfileElement = (payload, profileElementId) => {
  return baseRequest(ApiEndpoints.UPDATE_PROFILE_ELEMENT, payload, null, { profileElementId }, true);
};

export const getPercentileDistributionOfProfileElementsByValues = profileElementId => {
  return baseRequest(
    ApiEndpoints.GET_PERCENTILE_DISTRIBUTION_PROFILE_ELEMENTS_BY_VALUES,
    null,
    { profileElementId },
    null,
    true,
  );
};

export const getProfileElementDetailByMonth = profileElementId => {
  return baseRequest(
    ApiEndpoints.GET_PROFILE_ELEMENT_DETAIL_BY_MONTH,
    null,
    { profileElementId },
    null,
    true,
  );
};

export const getProfileElementDataUsage = profileElementId => {
  return baseRequest(ApiEndpoints.GET_PROFILE_ELEMENT_DATA_USAGE, null, { profileElementId }, null, true);
};

export const getIcd10Codes = () => {
  return baseRequest(ApiEndpoints.GET_ICD10_CODES, null, null, null, true);
};
export const getCPTCodes = () => {
  return baseRequest(ApiEndpoints.GET_CPTS, null, null, null, true);
};

export const getAutomationCount = () => {
  return baseRequest(ApiEndpoints.GET_AUTOMATION_COUNT, null);
};

export const reRunAIAnalysis = (appointmentId, evaluationId, mode, cbId) => {
  return baseRequest(
    ApiEndpoints.RE_PROCESS_ALFIE_VALIDATION,
    { mode, cbId },
    { appointmentId, evaluationId },
    null,
    true,
  );
};

export const notifyForAiCompletion = data => {
  return baseRequest(ApiEndpoints.NOTIFY_AI_COMPLETION, data);
};

export const fetchDctPreviousResponses = queryParams => {
  return baseRequest(ApiEndpoints.FETCH_DCT_PREVIOUS_RESPONSES, null, null, queryParams, true);
};

export const applyPreviousDCTAnswers = requestBody => {
  return baseRequest(ApiEndpoints.USE_DCT_PREVIOUS_RESPONSES, requestBody, null, null, true);
};

export const getSignedUrl = requestBody => {
  return baseRequest(ApiEndpoints.AWS_SIGNEDURL, requestBody, null, null, true);
};

export const fetchMessagesFromOldApp = queryParams => {
  return baseRequest(ApiEndpoints.FETCH_OLD_MESSAGES, null, null, queryParams, true);
};

export const fetchChatRecommendations = (pathParams, queryParams) => {
  return baseRequest(ApiEndpoints.FETCH_CHAT_RECOMMENDATIONS, null, pathParams, queryParams, true);
};
