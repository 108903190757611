import { colors } from '../../../../../packages/colors';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  toggleWrap: {
    '& > *': {
      padding: 4,
      '& > div': {
        padding: 0,
        paddingRight: 16,
      },
    },
  },
  headerService: {
    fontWeight: 800,
    fontSize: 12,
    lineHeight: '20px',
    textTransform: 'uppercase',
    color: colors.neutral500,
  },
  titleDuration: {
    fontWeight: 700,
    fontSize: 16,
    lineHeight: '24px',
    color: colors.neutral900,
  },
  descriptionAffiliation: {
    fontWeight: 500,
    fontSize: 16,
    lineHeight: '24px',
    color: colors.neutral600,
  },
  wrapperServiceDefault: {
    height: 24,
    padding: '2px 8px',
    border: `1px solid ${colors.secondary500}`,
    borderRadius: 100,
    whiteSpace: 'nowrap',
    fontWeight: 600,
    fontSize: 14,
    lineHeight: '20px',
    color: colors.secondary500,
    marginTop: 8,
  },
  wrapperIconStar: {
    width: 14,
    height: 14,
    marginRight: 4,
  },
  editBtn: {
    fontSize: 14,
    lineHeight: '20px',
    fontWeight: 700,
    marginTop: 12,
    '& svg': {
      width: 14,
      marginLeft: 12,
    },
  },
  dot: {
    width: 6,
    height: 6,
    borderRadius: '50%',
    marginRight: 6,
    display: 'inline-block',
    backgroundColor: colors.neutral600,
  },
  dotActive: {
    backgroundColor: colors.green600,
  },
  status: {
    height: 22,
    width: 86,
    padding: 0,
    borderRadius: 20,
    fontSize: 12,
    fontWeight: 600,
  },
});
