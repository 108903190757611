import { useEffect, useState } from 'react';
import clsx from 'clsx';
import { Stack } from '@mui/material';
import {
  Badge,
  badgeStyle,
  badgeType,
  fontWeight,
  Heading,
  headingLevel,
  iconBtnType,
  IconButton,
  Menu,
  Text,
} from '../../../packages';
import { Filter, Table } from '../../../packages/ui/organisms/table';
import { BaseLayout } from '../../../layouts/base';
import { addTag, getTagsList, updateTag } from '../../../services/conversation/conversation.service';
import history from '../../../utils/history';
import AddTag from './components/add-tag';
import { filterOptionsMock, tagColumns } from './TagsList.mock';
import { useStyles } from './TagsList.styles';
import { TAGS } from './Tags.constants';

const TagsList = () => {
  const [showAddTag, setShowAddTag] = useState(false);
  const [currentTag, setCurrentTag] = useState(null);
  const [tagsList, setTagsList] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [, setIsEmpty] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [value, setValue] = useState({
    search: {
      searchKey: '',
    },
    pagination: {
      currentPage: 1,
      rowsPerPage: 10,
    },
    sorter: {
      direction: 'asc',
      column: '',
    },
  });
  const classes = useStyles();

  const toggleAddTag = () => {
    setShowAddTag(!showAddTag);
  };

  /**
   * @function onEditClick
   * @params tagId
   * @description This method is used to handle Edit menu option click.
   */
  const onEditClick = tagId => () => {
    setCurrentTag(tagsList?.find(tag => tag?.tagId === tagId));
    setIsEditMode(true);
    setShowAddTag(true);
  };

  /**
   * @function onViewClick
   * @params tagId
   * @description This method is used to handle View menu option click.
   */
  const onViewClick = tagId => () => {
    setCurrentTag(tagsList?.find(tag => tag?.tagId === tagId));
    history.push(`/admin/data-management-tags/${tagId}`);
  };

  /**
   * @Name renderColumns
   * @description This method is used to render columns in the screen
   */
  const renderColumns = tagColumns.map(column => {
    if (column.id === 'name') {
      return {
        ...column,
        renderCell: name => (
          <Text className={classes.name} weight={fontWeight.MEDIUM}>
            {name}
          </Text>
        ),
      };
    }
    if (column.id === 'type') {
      return {
        ...column,
        renderCell: type => (
          <Text className={classes.name} weight={fontWeight.MEDIUM}>
            {type}
          </Text>
        ),
      };
    }
    if (column.id === 'assignmentMethod') {
      return {
        ...column,
        renderCell: assignmentMethod => (
          <Text className={classes.name} weight={fontWeight.MEDIUM}>
            {TAGS.TAGS_ASSIGNMENT_METHOD[assignmentMethod]}
          </Text>
        ),
      };
    }
    if (column.id === 'associationCount') {
      return {
        ...column,
        renderCell: val => `${val} ${val > 1 ? ' members' : ' member'}`,
      };
    }
    if (column.id === 'priority') {
      return {
        ...column,
        renderCell: val => (
          <Badge
            style={badgeStyle[val?.toUpperCase()]}
            className={clsx(classes.badge, val)}
            variant={badgeType.FILLED}
          >
            {TAGS.TAGS_PRIORITY_LEVELS[val]}
          </Badge>
        ),
      };
    }
    if (column.id === 'actions') {
      return {
        ...column,

        renderCell: ({ id: tagId }) => (
          <Menu
            icon="more"
            className={classes.menu}
            itemsWrapperClassName={classes.menuItemsWrapper}
            items={[
              { label: 'Edit', onClick: onEditClick(tagId) },
              { label: 'View', onClick: onViewClick(tagId) },
            ]}
          />
        ),
      };
    }
    return column;
  });

  /**
   * @Name formatListAccordingToColumns
   * @description This method is used to format list according to UI
   */
  const formatListAccordingToColumns = listToBeFormatted =>
    listToBeFormatted?.map(tag => ({
      ...tag,
      priority: tag?.importance,
      actions: {
        id: tag?.tagId,
      },
    }));

  /**
   * @Name getTags
   * @description This method is used get tags list from BE
   */
  const getTags = () => {
    setIsLoading(true);
    getTagsList({})
      .then(tagListResponse => {
        if (tagListResponse?.status !== 200) {
          throw new Error('Whoops ! Something went wrong . Please try later');
          setIsLoading(false);
        } else {
          const responseList = tagListResponse?.data?.tagList || [];
          setTagsList(formatListAccordingToColumns(responseList));
          setDataSource(formatListAccordingToColumns(responseList));
          setIsEmpty(!responseList || responseList?.length < 1);
          setIsLoading(false);
        }
      })
      .catch(error => {
        console.log('Whoops ! Something went wrong . Please try later', error);
        setIsLoading(false);
      });
  };

  /**
   * @function useEffect
   * @description This method is used to call getTags method first time
   */
  useEffect(() => {
    getTags();
  }, []);

  /**
   * @function useEffect
   * @description This method is used to filter records based on multiple filters.
   */
  useEffect(() => {
    const { search, sorter, pagination } = value;
    const currentPage = pagination?.currentPage || 1;
    const rowsPerPage = pagination?.rowsPerPage || 6;
    const listFiltered = tagsList
      .filter(({ name }) => {
        if (search?.searchKey) {
          return name.toLowerCase().includes(search.searchKey.toLowerCase());
        }
        return true;
      })

      .sort((a, b) => {
        const sortColumn = sorter?.column;
        const compare = a[sortColumn]?.toString()?.localeCompare(b[sortColumn]?.toString());
        return sorter?.direction === 'desc' ? compare * -1 : compare;
      })
      .slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage);

    const fliteredList = formatListAccordingToColumns(listFiltered);
    setDataSource(fliteredList);
  }, [value]);

  /**
   * @Name updateTagItem
   * @description This method is used to update tag Item
   */

  const updateTagItem = tagUpdateRequest => {
    if (tagUpdateRequest) {
      updateTag(tagUpdateRequest, { tagId: currentTag?.tagId })
        .then(res => {
          if (res?.status === 200) {
            getTags();
          } else {
            throw new Error(res?.data?.errors[0]?.endUserMessage);
          }
        })
        .catch(error => {
          console.log('Whoops ! Something went wrong . Please try later', error);
        });
    } else {
      throw new Error('Whoops ! Something went wrong . Please try later');
    }
  };

  /**
   * @Name addNewTagItem
   * @description This method is used to add new tag
   */

  const addNewTagItem = tagRequest => {
    if (tagRequest) {
      addTag(tagRequest)
        .then(res => {
          if (res?.status === 200) {
            getTags();
          } else {
            throw new Error(res?.data?.errors[0]?.endUserMessage);
          }
        })
        .catch(error => {
          console.log('Whoops ! Something went wrong . Please try later', error);
        });
    } else {
      throw new Error('Whoops ! Something went wrong . Please try later');
    }
  };

  /**
   * @Name saveTag
   * @description This method is used handle Add/edit tag functionality
   */
  const saveTag = tagRequest => {
    if (isEditMode) {
      updateTagItem(tagRequest);
    } else {
      addNewTagItem(tagRequest);
    }
  };

  return (
    <>
      {showAddTag && (
        <AddTag
          tag={currentTag}
          isOpen={showAddTag}
          onClose={toggleAddTag}
          isEditMode={isEditMode}
          onSubmit={saveTag}
        />
      )}

      <BaseLayout>
        <div className={classes.root}>
          <Stack direction="row" justifyContent="space-between" spacing={2}>
            <Stack direction="row" alignItems="center" gap={2}>
              <Heading className={classes.heading} level={headingLevel.XL} weight={fontWeight.BOLD}>
                Tags
              </Heading>
              <Badge
                className={classes.totalMemberBadge}
                variant={badgeType.OUTLINED}
                style={badgeStyle.UNRELATED}
              >
                {dataSource?.length} total
              </Badge>
            </Stack>

            <IconButton
              className={classes.addBtn}
              icon="plus"
              variant={iconBtnType.PRIMARY}
              onClick={toggleAddTag}
            >
              Add tag
            </IconButton>
          </Stack>
          <Table
            searchProps={{
              placeholder: 'Search',
              filterProps: {
                variant: Filter.tableFilterType.MULTIPLE,
                options: filterOptionsMock,
                allOptionLabel: 'All profile',
              },
            }}
            gridProps={{
              columns: renderColumns,
              data: dataSource,
              isLoading,
            }}
            paginationProps={{
              currentRows: dataSource?.length,
              totalCount: dataSource?.length,
            }}
            value={value}
            onChange={setValue}
            className={classes.table}
          />
        </div>
      </BaseLayout>
    </>
  );
};

export { TagsList };
