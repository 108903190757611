import { Box } from '@mui/material';
import { Table } from '../../../../../packages/ui/organisms/table';
import { Badge, badgeStyle, badgeType } from '../../../../../packages/ui/atoms/badge';

import ProfileInfo from '../../../../../components/profile-info';
import React from 'react';
import { toSentenceCase } from '../../../../../utils/CommonUtils';
import { useStyles } from './index.styles';
import CardHeader from '../card-header';

const TableCard = ({ columns, data, action, title, loading, hideNotes, onRowClick }) => {
  const classes = useStyles();

  const renderColumns = columns.map(column => {
    if ((title === 'Active medications' || title === 'Active diagnosis') && column.id === 'name') {
      return {
        ...column,
        renderCell: name => {
          return <Box width={title === 'Active medications' ? '128px' : '222px'}>{name}</Box>;
        },
      };
    }
    if ((title === 'Active medications' || title === 'Active diagnosis') && column.id === 'provider') {
      return {
        ...column,
        renderCell: ({ fullName, role, profileImage }) => (
          <Box width="222px">
            <ProfileInfo fullName={fullName} userRole={role} src={profileImage} hideNotes={hideNotes} />
          </Box>
        ),
      };
    }
    if ((title === 'Medications' || title === 'Diagnoses') && column.id === 'name' && !hideNotes) {
      return {
        ...column,
        renderCell: name => {
          return <Box width="100px">{name}</Box>;
        },
      };
    }
    if (
      ((title === 'Medications' && !hideNotes) || title === 'Active medications') &&
      column.id === 'dosage'
    ) {
      return {
        ...column,
        renderCell: dosage => {
          return <Box width="65px">{dosage}</Box>;
        },
      };
    }
    if (column.id === 'provider') {
      return {
        ...column,
        renderCell: ({ fullName, role, profileImage }) => (
          <ProfileInfo fullName={fullName} userRole={role} src={profileImage} hideNotes={hideNotes} />
        ),
      };
    }
    if (column.id === 'status') {
      return {
        ...column,
        renderCell: status => {
          return (
            <Badge variant={badgeType.OUTLINED} style={badgeStyle[status]} className={classes.status}>
              {toSentenceCase(status)}
            </Badge>
          );
        },
      };
    }
    if (column.id === 'duration') {
      return {
        ...column,
        renderCell: duration => {
          return <Box width="62px">{duration}</Box>;
        },
      };
    }
    return column;
  });

  return (
    <Box className={classes.root}>
      <CardHeader title={title} action={action} />
      <Table
        className={classes.table}
        gridProps={{
          columns: renderColumns,
          isLoading: loading,
          onRowClick,
          data,
        }}
      />
    </Box>
  );
};

export default TableCard;
