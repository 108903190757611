import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Filter, Table } from '../../../../../packages/ui/organisms/table';
import { Menu } from '../../../../../packages';
import { ProfileInfo } from '../../../../../packages/ui/templates/profile-info';

import { profileActionCreators } from '../../../../../redux/modules/profile';
import { getProfile } from '../../../../../redux/modules/profile/selectors';
import history from '../../../../../utils/history';

import ProviderDetail from './provider-detail';
import { filterOptionsMock, providerColumns } from './Providers.constants';
import { useStyles } from './Providers.styles';

const ProviderList = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { providers } = useSelector(getProfile);
  const [providerList, setProviderList] = useState([]);
  const [selectedProvider, setSelectedProvider] = useState();
  const [tableParams, setTableParams] = useState({
    search: { searchKey: '' },
    pagination: { currentPage: 1, rowsPerPage: 10 },
    sorter: { direction: 'asc', column: '' },
  });

  const onRowClick = item => {
    setSelectedProvider(item);
  };

  const onViewProfile = pId => () => {
    history.push(`/admin/providers/${pId}?name=general-information`);
  };

  const onViewChat = pId => () => {
    history.push(`/admin/chats?userId=${pId}&type=providers`);
  };

  useEffect(() => {
    const formatProviders = providers.map(item => ({
      ...item,
      provider: {
        firstName: item.firstName,
        lastName: item.lastName,
        profileImage: item.profileImage,
        designation: item.designation,
      },
      npiNumber: 123456789,
      payers: '3 payers',
      members: '20 members',
      actions: {
        appointmentId: 1,
        disabled: false,
      },
    }));
    setProviderList(formatProviders);
  }, [providers]);

  useEffect(() => {
    dispatch(profileActionCreators.fetchProviders());
  }, [tableParams]);

  const renderColumns = useCallback(() => {
    return providerColumns.map(column => {
      if (column.id === 'provider') {
        return {
          ...column,
          renderCell: ({ firstName, lastName, profileImage, designation }) => (
            <ProfileInfo
              type="provider"
              photo={profileImage}
              role={designation}
              fullName={`${firstName || ''} ${lastName || ''}`}
            />
          ),
        };
      }
      if (column.id === 'actions') {
        return {
          ...column,
          renderCell: ({ id }) => (
            <Menu
              icon="more"
              className={classes.menu}
              itemsWrapperClassName={classes.menuItemsWrapper}
              items={[
                { label: 'View Profile', onClick: onViewProfile(id) },
                { label: 'Go to chat', onClick: onViewChat(id) },
              ]}
            />
          ),
        };
      }
      return column;
    });
  }, []);

  return (
    <>
      <ProviderDetail
        open={!!selectedProvider}
        onClose={() => setSelectedProvider(null)}
        provider={selectedProvider}
      />
      <Table
        searchProps={{
          placeholder: 'Search',
          filterProps: {
            variant: Filter.tableFilterType.MULTIPLE,
            options: filterOptionsMock,
            allOptionLabel: 'All provider',
          },
        }}
        gridProps={{
          columns: renderColumns(),
          data: providerList,
          isLoading: false,
          onRowClick,
        }}
        paginationProps={{
          currentRows: tableParams.pagination.rowsPerPage,
          totalCount: providerList.length,
          showRowsPerPage: true,
        }}
        value={tableParams}
        onChange={setTableParams}
        className={classes.table}
      />
    </>
  );
};

export default ProviderList;
