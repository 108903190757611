import { colors } from '../../../../../../packages/colors';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  header: {
    display: 'flex',
    gap: 24,
    alignItems: 'center',
    height: 80,
    padding: '20px 16px',
    borderBottom: `1px solid ${colors.neutral100}`,
  },
  headerTitle: {
    display: 'block',
    fontSize: 24,
    lineHeight: '32px',
    fontWeight: 700,
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 80,
    padding: 16,
    borderTop: `1px solid ${colors.neutral100}`,
  },
  backBtnSquare: {
    width: 40,
    height: 40,
    minWidth: 40,
    borderRadius: 4,
    border: `1px solid ${colors.primary100}`,
    '& svg': {
      width: 14,
      height: 14,
    },
  },
  // backBtn: {
  //   width: 40,
  //   height: 40,
  //   minWidth: 40,
  //   minHeight: 40,
  //   padding: '18px 12px',
  //   borderColor: colors.primary300,
  //   '& svg': {
  //     color: colors.primary500,
  //     width: 18,
  //     height: 15,
  //   },
  // },
  form: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
    height: 'calc(100vh - 170px)',
    overflowY: 'auto',
  },
  form2: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
    height: 'calc(100vh - 80px)',
    overflowY: 'auto',
  },
  formContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: 24,
    padding: 40,
    overflowY: 'auto',
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    position: 'relative',
  },
  label: {
    fontSize: 14,
    lineHeight: '20px',
    margin: 0,
  },
});
