import { Box } from '@mui/material';
import { useStyles } from './MemberDisconnected.styles';
import InternetDisconnected from './../../../../../../../assets/images/internet-disconnected.png';
import { Icons } from '../../../../../../icons';
import { btnType, Button } from '../../../../../atoms/button';
import clsx from 'clsx';
import { MEMBER_ACTIONS } from '../../../sessions.constants';

const MemberDisconnected = ({ firstName, phoneNumber, duration, serviceDuration, onMemberActionClick }) => {
  const classes = useStyles();
  const remainingDuration = serviceDuration - duration / 60;
  return (
    <Box className={classes.container}>
      <Box className={classes.contentBox}>
        <img src={InternetDisconnected} className={classes.disconnectedIcon} alt={'Member Disconnected'} />
        <p className={classes.disconnectedText}>{firstName} got disconnected from the session.</p>
        <Box className={classes.actionsContainer}>
          <Box className={classes.actionCard}>
            <Box className={classes.actionDetailsBox}>
              <p className={classes.cardTitle}>Call the member directly</p>
              <p className={classes.cardDescription}>{phoneNumber}</p>
            </Box>
            <Button variant={btnType.OUTLINE} className={clsx([classes.actionBtn, classes.callBtn])}>
              <Icons glyph="phone-outlined" />
            </Button>
          </Box>
          <Box className={classes.actionCard}>
            <Box className={classes.actionDetailsBox}>
              <p className={classes.cardTitle}>Chat with the care team</p>
              <p
                className={clsx(classes.cardDescription, {
                  [classes.danger]: Math.round(remainingDuration) <= 5,
                })}
              >
                {Math.round(remainingDuration)} min{remainingDuration > 1 ? 's' : ''} left in session
              </p>
            </Box>
            <Button
              variant={btnType.OUTLINE}
              className={clsx([classes.actionBtn, classes.chatBtn])}
              onClick={() => onMemberActionClick(MEMBER_ACTIONS.CHAT)}
            >
              <Icons glyph="message" />
            </Button>
          </Box>
          <Box className={classes.cardFooter}>
            <p className={classes.desc}>Sit tight, we’ll connect you to them when they’re back online.</p>
            <p className={classes.desc}>You can keep filling out your documentation</p>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export { MemberDisconnected };
