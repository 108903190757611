/* eslint-disable max-len */
// types

// styles
import { useStyles } from './shared/styles';

const CircleCashOnly = ({ className, color, onClick, width = 16, height = 16 }) => {
  const classes = useStyles();
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="9" cy="9" r="8" fill="#EBFDE8" stroke="#70E377" />
    </svg>
  );
};

export default CircleCashOnly;
