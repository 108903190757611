/* eslint-disable max-len */
import cx from 'clsx';

// types
// styles
import { useStyles } from './shared/styles';

const DataManagementOutlined = ({ className, color, onClick }) => {
  const classes = useStyles();
  return (
    <svg
      className={cx({
        [classes.root]: true,
        [className || '']: className,
      })}
      width="17"
      height="20"
      viewBox="0 0 17 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M8.90001 15.1L12.4333 11.5667C12.9023 11.0983 13.1661 10.4629 13.1667 9.80004V2.50004C13.1667 2.05801 12.9911 1.63409 12.6785 1.32153C12.366 1.00897 11.942 0.833374 11.5 0.833374H2.33334C1.89131 0.833374 1.46739 1.00897 1.15483 1.32153C0.842266 1.63409 0.666672 2.05801 0.666672 2.50004V14.1667C0.666672 14.6087 0.842266 15.0327 1.15483 15.3452C1.46739 15.6578 1.89131 15.8334 2.33334 15.8334H7.13334C7.79616 15.8328 8.43161 15.569 8.90001 15.1ZM6.5 10V14.1667H2.33334V2.50004H11.5V9.16671H7.33334C7.11232 9.16671 6.90036 9.2545 6.74408 9.41078C6.5878 9.56706 6.5 9.77903 6.5 10ZM14.8333 4.16671V15.8334C14.8333 16.2754 14.6577 16.6993 14.3452 17.0119C14.0326 17.3244 13.6087 17.5 13.1667 17.5H4.00001C4.00001 17.9421 4.1756 18.366 4.48816 18.6786C4.80072 18.9911 5.22464 19.1667 5.66667 19.1667H13.1667C14.0507 19.1667 14.8986 18.8155 15.5237 18.1904C16.1488 17.5653 16.5 16.7174 16.5 15.8334V5.83337C16.5 5.39135 16.3244 4.96742 16.0118 4.65486C15.6993 4.3423 15.2754 4.16671 14.8333 4.16671Z"
        fill={color}
      />
    </svg>
  );
};

export default DataManagementOutlined;
