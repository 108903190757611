import { createSelector } from 'reselect';
import { appointmentDetailsAdapter } from './reducer';

export const getAppointment = state => state.appointment;

export const selectMasterScheduleState = createSelector(
  getAppointment,
  appointmentState => appointmentState.masterSchedule,
);

export const selectMasterScheduleItems = createSelector(
  selectMasterScheduleState,
  masterScheduleState => masterScheduleState.masterScheduleItems,
);

export const selectMasterScheduleCapacity = createSelector(
  selectMasterScheduleState,
  masterScheduleState => masterScheduleState.capacity,
);

export const selectMasterScheduleItemsLoading = createSelector(
  selectMasterScheduleState,
  masterScheduleState => masterScheduleState.isLoading,
);

export const selectProviderRolesState = createSelector(
  getAppointment,
  appointmentState => appointmentState.providerRoles,
);

export const selectProviderRoles = createSelector(
  selectProviderRolesState,
  providerRolesState => providerRolesState.roles,
);

export const selectProviderRolesLoading = createSelector(
  selectProviderRolesState,
  providerRolesState => providerRolesState.isLoading,
);

export const selectProviderServicesState = createSelector(
  getAppointment,
  appointmentState => appointmentState.providerServices,
);

export const selectAppointmentDetailsState = createSelector(
  getAppointment,
  appointmentState => appointmentState.appointmentDetails,
);

export const selectAppointmentDetailsStateLoading = state => state.appointment.appointmentDetails.isLoading;

const { selectById } = appointmentDetailsAdapter.getSelectors();

export const selectAppointmentDetailsById = appointmentId =>
  createSelector(selectAppointmentDetailsState, appointmentDetailsState =>
    selectById(appointmentDetailsState, appointmentId),
  );

export const selectAppointmentById = appointmentId =>
  createSelector(getAppointment, appointment =>
    appointment.appointments.find(a => a.appointmentId === appointmentId),
  );

export const selectAppointmentCounts = createSelector(
  getAppointment,
  appointmentState => appointmentState.allAppointmentCounts,
);
