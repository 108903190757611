import React, { FC } from 'react';
import clsx from 'clsx';
import { Drawer, drawerType } from '../../../../../packages/ui/organisms/drawer';
import { Box } from '@mui/system';
import { IconButton } from '../../../../../packages/ui/molecules/icon-button';
import { Text } from '../../../../../packages/ui/atoms/typography';
import { Collapsable } from '../../../../../packages/ui/atoms/collapsable';
import { getAuth } from '../../../../../redux/modules/auth/selectors';
import { useSelector } from 'react-redux';
import { useStyles } from './index.styles';
import { EvaluationExercise } from '../../../sessions/evaluations/evaluation-section-detail/components/evaluation-exercise/EvaluationExercise';

const PreviewExercisesDrawer = ({ open, onClose, exercises, evaluationName, selectedExerciseCbId }) => {
  const classes = useStyles();
  const { isAdmin } = useSelector(getAuth);

  return (
    <Drawer open={open} onClose={onClose} variant={drawerType.NORMAL} className={classes.drawer}>
      <Box className={classes.wrapper}>
        <Box className={classes.header}>
          <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
            <IconButton icon="chevron-left" onClick={onClose} className={classes.backBtn} />
            <Box display="flex" flexDirection="column">
              <Text className={classes.title}>Preview Exercises</Text>
              <Text className={classes.previewEvaluationName}>{evaluationName}</Text>
            </Box>
          </Box>
        </Box>
        <Box className={classes.tabContent}>
          <Box display="flex" flexDirection="column" gap={1}>
            {exercises.map((exercise, index) => (
              <Collapsable
                key={index}
                className={clsx(classes.exerciseName, {
                  [classes.collapsable]: !isAdmin,
                })}
                label={exercise.text}
                open={exercise.cbId === selectedExerciseCbId}
              >
                <Box display="flex" flexDirection="column" gap={3} pb={2}>
                  {exercise.subtitle && exercise.subtitle.length > 0 && (
                    <Box className={classes.exerciseDesc}>{exercise.subtitle}</Box>
                  )}
                  <Box className={classes.exerciseContentBox}>
                    <EvaluationExercise selectedSection={exercise.exerciseContent} />
                  </Box>
                </Box>
              </Collapsable>
            ))}
          </Box>
        </Box>
      </Box>
    </Drawer>
  );
};

export { PreviewExercisesDrawer };
