import { makeStyles } from '@mui/styles';

// colors
import { colors } from '../../../../../packages';

export const useStyles = makeStyles({
  card: {
    display: 'flex',
    backgroundColor: 'white',
    padding: '24px 32px 32px 32px',
    marginTop: '40px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    // height: '404px',
    alignSelf: 'stretch',
    borderRadius: '8px',
    boxShadow:
      '0px 0px 1px 0px rgba(0, 0, 0, 0.04), 0px 2px 6px 0px rgba(0, 0, 0, 0.04), 0px 10px 20px 0px rgba(0, 0, 0, 0.04)',
    '& .apexcharts-svg': {
      height: '215px !important',
      width: '900px !important',
    },
    '& .apexcharts-canvas': {
      width: '900px !important',
    },
    '& foreignObject': {
      height: '215px !important',
      width: '900px !important',
    },
    '& .apexcharts-legend': {
      display: 'flex',
      flexFlow: 'column wrap',
    },
    '& .MuiLinearProgress-root, .MuiLinearProgress-bar': {
      borderRadius: '0px !important',
    },
  },
  heading: {
    padding: '0 !important',
    marginBottom: '32 !important',
    marginTop: '0 !important',
    fontSize: '18px',
    fontWeight: '600px',
  },
  heading2: {
    display: 'block',
    marginTop: '80px !important',
    padding: '0 !important',
    marginBottom: '16px !important',
    fontSize: '18px',
  },
  progressBar: {
    padding: '0 !important',
    margin: '0 !important',
    '& .MuiLinearProgress-bar': {
      backgroundColor: colors.neutral900,
      backgroundImage: 'linear-gradient(90deg, #0D0F11 99.5%, white 10px)',
    },
  },
});
