import clsx from 'clsx';
import { Box } from '@mui/material';
import { Collapsable, IiconPosition } from '../../../../../packages/ui/atoms/collapsable';
import { ICBResponse, IDctContentBlock } from '../../../../../redux/modules/conversation/types';
import { QuestionItem } from './QuestionItem';
import { useStyles } from './index.styles';

const EvaluationItem = ({
  id,
  dct,
  open,
  evaluationStatus,
  memberId,
  contextId,
  onPreviewExercise,
  selectedTab,
  showBottomBorder,
  waitingForAI,
  evaluationId,
  appointmentId,
  contentIdentifier,
}) => {
  const classes = useStyles();
  const questions = dct?.dctContentBlockList?.filter(cb => cb.cbType !== 'dct');
  return (
    <Collapsable
      id={id}
      className={clsx(classes.collapsable, showBottomBorder && classes.questionBorderBottom)}
      iconPosition={IiconPosition.LEFT}
      label={<Box sx={{ fontWeight: 600, fontSize: 16, lineHeight: '24px' }}>{dct.text}</Box>}
      open={open}
    >
      {questions?.map((contentBlock, index) => (
        <QuestionItem
          id={contentBlock.cbId}
          contentBlock={{ ...contentBlock, responderType: dct.responderType }}
          key={index}
          contained
          onPreviewExercise={onPreviewExercise}
          evaluationStatus={evaluationStatus}
          memberId={memberId}
          contextId={contextId}
          showBottomBorder={index !== questions.length - 1}
          selectedTab={selectedTab}
          waitingForAI={waitingForAI}
          evaluationId={evaluationId}
          appointmentId={appointmentId}
          contentIdentifier={contentIdentifier}
        />
      ))}
    </Collapsable>
  );
};

export default EvaluationItem;
