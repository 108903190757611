// colors
import { colors } from '../../../../packages/colors';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  modal: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalContent: {
    position: 'relative',
    width: 480,
  },
  closeIconWrap: {
    border: `8px solid ${colors.white}`,
    borderRadius: '50%',
    width: 72,
    height: 72,
    position: 'absolute',
    top: -36,
    left: 36,
    backgroundColor: colors.secondary100,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  closeIcon: {
    width: 33,
    height: 33,
    '& path': {
      fill: colors.secondary500,
    },
  },
  inner: {
    display: 'flex',
    flexDirection: 'column',
    gap: '40px',
    background: colors.white,
    boxShadow:
      '0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
    borderRadius: 8,
    '&:hover $cardLink': {
      display: 'flex',
    },
    padding: '56px 40px 40px',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
  closeBtn: {
    position: 'absolute',
    top: 25,
    right: 25,
    cursor: 'pointer',
  },
  title: {
    alignSelf: 'stretch',
    color: colors.neutral900,
    fontFamily: '"Source Serif Pro"',
    fontSize: '32px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '40px',
    letterSpacing: '-0.64px',
    margin: 0,
  },
  label: {
    fontSize: 16,
    lineHeight: '24px',
    fontWeight: 500,
    color: colors.neutral600,
    display: 'block',
  },
  btn: {
    height: 64,
    borderRadius: 8,
    border: `1px solid ${colors.neutral300}`,
    width: '100%',
    whiteSpace: 'nowrap',
  },
  cancelBtn: {
    backgroundColor: colors.white,
    color: colors.neutral900,
    '&:hover': {
      backgroundColor: colors.neutral25,
    },
  },
  okBtn: {
    backgroundColor: colors.destructive500,
    color: colors.white,
    border: 'none',
    '&:hover': {
      backgroundColor: colors.destructive600,
      color: colors.white,
    },
  },
});
