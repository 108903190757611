import dayjs from 'dayjs';
import { tz } from 'moment-timezone';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
// dayjs.tz.setDefault('America/New_York');

export const getUserTimeZone = () => {
  return tz.guess();
};

export const getTimeDifference = (date1, date2) => {
  const differenceInTime = Math.abs(date2.getTime() - date1.getTime());
  const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));
  const differenceInHours = Math.ceil(differenceInTime / (1000 * 3600));
  return differenceInDays > 1
    ? `${differenceInDays} Days ago`
    : differenceInHours
    ? `${differenceInHours}h ago`
    : '-';
};

// This function returns the current local datetime in UTC
// but without the GMT string to restrict the Date object to convert it back to local datetime
export const getLocalDateAsUTC = () => {
  const localDate = new Date();
  const localUTCString = localDate.toUTCString().replace('GMT', '');
  return new Date(localUTCString);
};

export default dayjs;
