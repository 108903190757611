/* eslint-disable max-len */
import cx from 'clsx';

// types
// styles
import { useStyles } from './shared/styles';

const Rotate = ({ className, color, onClick }) => {
  const classes = useStyles();
  return (
    <svg
      className={cx(classes.root, className)}
      onClick={onClick}
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.55234 2.69401C5.06447 1.38373 6.99899 0.663721 8.99984 0.666512C13.6023 0.666512 17.3332 4.39735 17.3332 8.99984C17.3332 10.7798 16.7748 12.4298 15.8248 13.7832L13.1665 8.99984H15.6665C15.6666 7.69286 15.2825 6.41468 14.5621 5.32422C13.8416 4.23377 12.8164 3.37914 11.6142 2.86662C10.4119 2.35409 9.08543 2.20626 7.7998 2.44153C6.51417 2.67679 5.32605 3.28476 4.38317 4.18985L3.55234 2.69401ZM14.4473 15.3057C12.9352 16.616 11.0007 17.336 8.99984 17.3332C4.39734 17.3332 0.666504 13.6023 0.666504 8.99984C0.666504 7.21984 1.22484 5.56984 2.17484 4.21651L4.83317 8.99984H2.33317C2.33306 10.3068 2.71713 11.585 3.43762 12.6755C4.1581 13.7659 5.18323 14.6205 6.38552 15.1331C7.58782 15.6456 8.91424 15.7934 10.1999 15.5582C11.4855 15.3229 12.6736 14.7149 13.6165 13.8098L14.4473 15.3057Z"
        fill={color}
      />
    </svg>
  );
};

export default Rotate;
