import { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import clsx from 'clsx';

import { Icons } from '../../../icons';
import { useStyles } from './DateSelect.styles';

const MuiDatePicker = ({
  value,
  onChange,
  inputClassName = '',
  placeholder = 'mmm dd, yyyy',
  inputFormat = 'MMM dd, yyyy',
  noValidate = false,
  isDOB = false,
  isDisableFuture = false,
  ...rest
}) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date(value));

  const handleChange = newValue => {
    setSelectedDate(newValue);
    onChange(newValue);
  };

  const togglePicker = () => {
    if (!rest.disabled) {
      setIsOpen(!isOpen);
    }
  };

  useEffect(() => {
    if (value) {
      setSelectedDate(new Date(value));
    }
  }, [value]);

  useEffect(() => {
    const currentDate = new Date();
    const selectedDateValue = new Date(value);
    if (selectedDateValue > currentDate && isDOB) {
      setSelectedDate(new Date());
    }
  }, [value]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DesktopDatePicker
        {...rest}
        value={selectedDate}
        onChange={handleChange}
        open={isOpen}
        onClose={togglePicker}
        disableFuture={isDisableFuture}
        inputFormat={inputFormat}
        renderInput={params => {
          return (
            <TextField
              {...params}
              className={clsx(classes.input, inputClassName)}
              onClick={togglePicker}
              InputProps={{
                ...params.InputProps,
                endAdornment: <Icons glyph="event-outlined" onClick={togglePicker} />,
              }}
              error={!noValidate && !selectedDate}
              inputProps={{
                ...params.inputProps,
                // disabled: true,
                disableFuture: true,
                placeholder,
              }}
            />
          );
        }}
      />
    </LocalizationProvider>
  );
};

export { MuiDatePicker };
