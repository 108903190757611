import { Box } from '@mui/material';
import { useStyles } from './OpenSession.styles';
import { btnType, Button } from '../../../../../atoms/button';
import { Input, inputType } from '../../../../../atoms/input';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  selectAssignedEvaluationState,
  selectEvaluation,
} from '../../../../../../../redux/modules/conversation/selectors';
import { getTimeForEval } from '../../../../../../../utils/CommonUtils';

const OpenSession = ({ scratchpad, addScratchpadNotes, onAddEvaluation }) => {
  const classes = useStyles();
  const { evaluations } = useSelector(selectAssignedEvaluationState);
  const allEvaluations = useSelector(selectEvaluation);
  const filteredEvaluations = allEvaluations.filter(
    evaluation => !evaluations.some(e => e.id === evaluation.evaluationId),
  );
  const [recommendationsVisible, setRecommendationsVisible] = useState(0);
  const recommendations = filteredEvaluations.slice(0, recommendationsVisible);

  const assignEvaluation = evaluationId => {
    onAddEvaluation([evaluationId]);
  };

  return (
    <Box className={classes.container}>
      <Box className={classes.openBox}>
        <Box className={classes.header}>
          <p className={classes.sectionTitle}>Open session</p>
          <p className={classes.sectionSubtitle}>It’s your session now, enjoy it!</p>
        </Box>
        <Box className={classes.contentBox}>
          <Input
            fullWidth
            variant={inputType.DEFAULT}
            placeholder="Add any notes here"
            multiline
            minRows={3}
            maxRows={3}
            value={scratchpad}
            onChange={event => addScratchpadNotes(event.target.value)}
            onKeyDown={event => {
              if (event.key === ' ') {
                event.stopPropagation();
              }
            }}
          />
          <p className={classes.aboutText}>
            Write down anything you can’t say out loud & we’ll add it to your final notes.
          </p>
        </Box>
        {recommendationsVisible > 0 && (
          <Box className={classes.recommendationsBox}>
            <p className={classes.recommendationsTitle}>Not required, but recommended by us:</p>
            <Box className={classes.recommendations}>
              {recommendations.map(evaluation => {
                const stats = evaluation.evaluationStats;
                return (
                  <Box className={classes.card} key={evaluation.evaluationId}>
                    <Box className={classes.cardContent}>
                      <p className={classes.cardTitle}>{evaluation.name}</p>
                      {stats && (
                        <p className={classes.cardSubtitle}>
                          {stats?.totalCb} Questions • {stats?.totalRequiredCb} Required •{' '}
                          {getTimeForEval(stats?.totalCb)}
                        </p>
                      )}
                    </Box>
                    <Button
                      variant={btnType.OUTLINE}
                      className={classes.useBtn}
                      onClick={() => assignEvaluation(evaluation.evaluationId)}
                    >
                      Use
                    </Button>
                  </Box>
                );
              })}
            </Box>
          </Box>
        )}
        <Box className={classes.footer}>
          <p className={classes.footerTitle}>Out of ideas? We can help</p>
          <Button
            variant={btnType.TEXT}
            className={classes.textLink}
            onClick={() => {
              setRecommendationsVisible(c => c + 3);
            }}
          >
            See {recommendationsVisible > 0 ? 'more' : ''} recommendations
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
export { OpenSession };
