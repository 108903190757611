export const tagColumns = [
  { id: 'name', label: 'Name', sortable: false },
  { id: 'type', label: 'Tag Type', sortable: false },
  { id: 'assignmentMethod', label: 'Assignment Method', sortable: false },
  { id: 'associationCount', label: 'Connected To', sortable: false },
  { id: 'priority', label: 'Tag Priority', sortable: false },
  { id: 'actions', label: 'Actions', sortable: false },
];

export const filterOptionsMock = [{ label: 'Type 1', value: 'type_01' }];
