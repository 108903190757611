import cx from 'clsx';

// mui
import MuiTabs from '@mui/material/Tabs';
import MuiTab from '@mui/material/Tab';

// types
import { tabStyle } from './Tabs.types';

// styles
import { useStyles } from './Tabs.styles';

const Tabs = ({ className = '', value = '', style = tabStyle.PRIMARY, options = [], onChange, ...rest }) => {
  const classes = useStyles({ style });

  const onChangeTab = (__, tabKey) => {
    onChange(tabKey);
  };

  const handleKeyDown = event => {
    if (event.key === 'ArrowLeft' || event.key === 'ArrowRight') {
      event.preventDefault();
    }
  };

  return (
    <MuiTabs
      className={cx({
        [classes.root]: true,
        [className || '']: className,
      })}
      value={value}
      onChange={onChangeTab}
      onKeyDown={handleKeyDown}
      {...rest}
    >
      {options.map(({ tabKey, ...opts }, idx) => (
        <MuiTab
          disableFocusRipple
          className={classes.tab}
          key={idx}
          value={tabKey}
          onKeyDown={handleKeyDown}
          {...opts}
        />
      ))}
    </MuiTabs>
  );
};

const TabPanel = ({ className = '', value = '', tabKey = '', children, ...rest }) => {
  const classes = useStyles();

  if (value === tabKey) {
    return (
      <div
        className={cx({
          [classes.tabPanel]: true,
          [className || '']: className,
        })}
        {...rest}
      >
        {children}
      </div>
    );
  }
  return null;
};

export { Tabs, TabPanel };
