import Box from '@mui/material/Box';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Icons } from '../../../../packages/icons';
import { colors } from '../../../../packages/colors';
import { IconButton, iconBtnPosition, iconBtnType } from '../../../../packages/ui/molecules/icon-button';
import { RadioGroup, radioGroupType } from '../../../../packages/ui/molecules/radio-group';
import { Badge, badgeType } from '../../../../packages/ui/atoms/badge';
import { Avatar, avatarType } from '../../../../packages/ui/atoms/avatar';
import {
  AppointmentStatusBagde,
  AppointmentStatusText,
} from '../../../../packages/constants/CommonConstants';
import { Tooltip } from '../../../../packages/ui/atoms/tooltip';
import UpdateVisitFrequency from '../../member-detail/components/CareTeam/edit';
import dayjs from '../../../../utils/dayjs';

import { getProfile } from '../../../../redux/modules/profile/selectors';
import { profileActionCreators } from '../../../../redux/modules/profile';
import { getNextAppointment } from '../../../../services/appointment/appointment.service';

import UpdateLevelOfEngagement from './UpdateLevelOfEngagement';

import { radioOptions } from './SessionSignOffV2.constant';
import { useStyles } from './SessionSignOffV2.styles';

const Appointments = ({
  onBack,
  onNext,
  appointment,
  preFill,
  setSchedulesDrawerOpen,
  template,
  scheduledSuccess,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { profile, demographicDetails, isLoading } = useSelector(getProfile);

  const [selectedOption, setSelectedOption] = useState(preFill?.selectedOption || 'no');
  const [nextAppointment, setNextAppointment] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [isActiveMember, setIsActiveMember] = useState(preFill?.isActiveMember || 'no');
  const [isWeeklyFrequency, setIsWeeklyFrequency] = useState(preFill?.isWeeklyFrequency || 'no');

  const [openVisitFrequency, setOpenVisitFrequency] = useState(false);
  const [openLevelOfEngagement, setOpenLevelOfEngagement] = useState(false);
  const [isUpdatedFrequency, setIsUpdatedFrequency] = useState(false);
  const [isUpdatedLevel, setIsUpdatedLevel] = useState(false);

  const fetchNextAppointment = async () => {
    setLoading(true);
    try {
      await getNextAppointment(appointment?.participantId, true).then(({ data }) => {
        setNextAppointment(data.appointment || undefined);
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (appointment?.participantId) {
      dispatch(profileActionCreators.fetchDemographicDetails(appointment?.participantId));
      setNextAppointment(undefined);
      void fetchNextAppointment();
    }
  }, [appointment?.participantId]);

  useEffect(() => {
    if (nextAppointment) {
      setSelectedOption('yes');
    }
  }, [nextAppointment]);

  const isDisabled = useMemo(() => {
    return !nextAppointment && selectedOption === 'no';
  }, [nextAppointment, selectedOption]);

  useEffect(() => {
    const handleKeyPress = event => {
      if (event.key === 'Enter' && !isDisabled) {
        onNextClick();
      }
    };
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [isDisabled]);

  useEffect(() => {
    if (template === 'yes' && !preFill?.selectedOption) {
      setSelectedOption('yes');
    }
  }, [template]);

  useEffect(() => {
    void fetchNextAppointment();
  }, [scheduledSuccess]);

  const frequencyDataObj = useMemo(() => {
    return {
      userId: profile.providerId,
      name: profile.fullName,
      visitFrequency: demographicDetails?.visitFrequency,
    };
  }, [demographicDetails?.visitFrequency, profile.providerId, profile.fullName]);

  const onNextClick = () => {
    onNext({
      appointment: {
        icon: 'calendar-today-outlined',
        title: 'Next appointment',
        description: 'scheduled',
        number: nextAppointment ? 1 : 'Not',
        selectedOption,
        isActiveMember,
        isWeeklyFrequency,
      },
    });
  };

  const onSelect = e => {
    setSelectedOption(e.target.value);
  };

  const onAdd = () => {
    setSchedulesDrawerOpen(true);
  };

  if (loading || isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" flex={1}>
        <Icons className="rotate linear infinite" glyph="in-progress" color={colors.primary} />
      </Box>
    );
  }

  return (
    <>
      <Box className={classes.wrapper}>
        <Box display="flex" flexDirection="column" gap={10} sx={{ height: 'calc(100vh - 257px)' }}>
          <Box className={classes.contentWrapper}>
            <Box className={classes.head}>
              <Box className={classes.contentHeader}>
                <Icons glyph="calendar-today-outlined" color={colors.neutral700} />
                <Box className={classes.contentHeaderTitle}>Next appointment with me</Box>
              </Box>
              <IconButton icon="plus" onClick={onAdd}>
                Add new
              </IconButton>
            </Box>
            <Box className={classes.content}>
              {nextAppointment ? (
                <Box className={classes.appointmentCard}>
                  <Box className={classes.appointmentCardHeader}>
                    <Box display="flex" justifyContent="space-between">
                      <Box className="title">{nextAppointment?.serviceName}</Box>
                      <Badge
                        variant={badgeType.OUTLINED}
                        style={AppointmentStatusBagde[nextAppointment.status]}
                        size={28}
                        className={classes.badge}
                      >
                        {AppointmentStatusText[nextAppointment.status]}
                      </Badge>
                    </Box>
                    <Box className="subTitle">{nextAppointment?.serviceDuration} minutes session</Box>
                  </Box>
                  <Box className={classes.appointmentDetails}>
                    <Box display="flex" gap={1.5} alignItems="center">
                      <Avatar
                        src={nextAppointment?.participantImage || nextAppointment.providerImage}
                        name={
                          nextAppointment.providerName ||
                          `${nextAppointment.patientFirstName || ''} ${nextAppointment.patientLastName || ''}`
                        }
                        size={40}
                        variant={avatarType.CIRCLE}
                      />
                      <Box className={classes.avatar}>
                        <Box className="name">
                          {nextAppointment.providerName ||
                            `${nextAppointment.patientFirstName || ''} ${
                              nextAppointment.patientLastName || ''
                            }`}
                        </Box>
                      </Box>
                    </Box>
                    <Box className="wrapper">
                      <Icons glyph="calendar-today-outlined" color={colors.neutral600} />
                      <Box className="text">{dayjs(nextAppointment.startTime).format('MMMM DD, YYYY')}</Box>
                    </Box>
                    <Box className="wrapper">
                      <Icons glyph="clock" color={colors.neutral600} />
                      <Box className="text">
                        {dayjs(nextAppointment.startTime).format('hh:mmA')} -{' '}
                        {dayjs(nextAppointment.endTime).format('hh:mmA')}
                      </Box>
                    </Box>
                  </Box>
                </Box>
              ) : (
                <Box className={classes.empty}>
                  <Box className="title">No scheduled appointment</Box>
                  <Box className="content">
                    It&apos;s best practice to make sure you have another appointment scheduled with the
                    Member before you end the session with them. If you&apos;re not going to have another
                    appointment with them, you should change their Level of Engagement and Visit Frequency.
                  </Box>
                  <IconButton icon="plus" variant={iconBtnType.OUTLINE} onClick={onAdd}>
                    New appointment
                  </IconButton>
                </Box>
              )}
            </Box>
            {!nextAppointment && (
              <Box mb={3}>
                <Box className={classes.question}>
                  Do you want to continue without scheduling an appointment?
                </Box>
                <RadioGroup
                  name="radio-buttons-group"
                  options={radioOptions}
                  value={selectedOption}
                  onChange={onSelect}
                  variant={radioGroupType.HORIZONTAL}
                />
              </Box>
            )}
            <Box className={classes.question} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              {isUpdatedLevel
                ? `Level of Engagement updated to ${demographicDetails?.levelOfEngagementStatus}`
                : demographicDetails?.levelOfEngagementStatus
                ? `Is the level of engagement for ${appointment?.participantName} ${demographicDetails?.levelOfEngagementStatus}?`
                : `Is the level of engagement for ${appointment?.participantName} still active?`}
              <Tooltip
                title="We use the member’s Level of Engagement to help the operations team manage outreach to each
                member. If a member is being discharged for any reason, please change their Level of
                Engagement by selecting No and clicking the update button."
                placement="right-start"
              >
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Icons glyph="eye-outlined" />
                </Box>
              </Tooltip>
            </Box>
            {!isUpdatedLevel && (
              <RadioGroup
                name="radio-buttons-group"
                options={radioOptions}
                value={isActiveMember}
                onChange={e => {
                  setIsActiveMember(e.target.value);
                }}
                variant={radioGroupType.HORIZONTAL}
              />
            )}
            {isActiveMember !== 'yes' && (
              <Box mt={3}>
                <IconButton
                  icon="plus"
                  iconPosition={iconBtnPosition.LEFT}
                  variant={iconBtnType.TEXT}
                  onClick={() => setOpenLevelOfEngagement(true)}
                >
                  Update Level of Engagement
                </IconButton>
              </Box>
            )}
            <Box className={classes.question} sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 4 }}>
              {isUpdatedFrequency
                ? `Visit Frequency updated to ${demographicDetails?.visitFrequency}`
                : `Is your visit frequency for you and ${appointment?.participantName} still ${demographicDetails?.visitFrequency}?`}
              <Tooltip
                title="A member’s Visit Frequency is another key data point for the operations team to help manage
                outreach. If the frequency you’re meeting with a member changes, please update their Visit
                Frequency here."
                placement="right-start"
              >
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Icons glyph="eye-outlined" />
                </Box>
              </Tooltip>
            </Box>
            {!isUpdatedFrequency && (
              <RadioGroup
                name="radio-buttons-group"
                options={radioOptions}
                value={isWeeklyFrequency}
                onChange={e => {
                  setIsWeeklyFrequency(e.target.value);
                }}
                variant={radioGroupType.HORIZONTAL}
              />
            )}
            {isWeeklyFrequency !== 'yes' && (
              <Box mt={3} mb={3}>
                <IconButton
                  icon="plus"
                  iconPosition={iconBtnPosition.LEFT}
                  variant={iconBtnType.TEXT}
                  onClick={() => setOpenVisitFrequency(true)}
                >
                  Update Visit Frequency
                </IconButton>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      <Box className={classes.footer}>
        <IconButton
          icon="chevron-left"
          iconPosition={iconBtnPosition.LEFT}
          className={classes.previousBtn}
          onClick={onBack}
          variant={iconBtnType.TEXT}
        >
          Previous
        </IconButton>
        <IconButton
          icon="chevron-right"
          iconPosition={iconBtnPosition.RIGHT}
          className={classes.nextBtn}
          onClick={onNextClick}
          disabled={isDisabled}
        >
          Next
        </IconButton>
      </Box>
      <UpdateVisitFrequency
        openDrawer={openVisitFrequency}
        onClose={() => setOpenVisitFrequency(false)}
        data={frequencyDataObj}
        currentUserId={appointment?.participantId}
        onUpdated={() => setIsUpdatedFrequency(true)}
      />
      <UpdateLevelOfEngagement
        openDrawer={openLevelOfEngagement}
        onClose={() => setOpenLevelOfEngagement(false)}
        memberId={appointment?.participantId}
        onUpdated={() => setIsUpdatedLevel(true)}
      />
    </>
  );
};

export default Appointments;
