import React from 'react';
import clsx from 'clsx';
import { Box } from '@mui/material';
import dayjs from 'dayjs';

import { Avatar, avatarType, Badge, badgeStyle, badgeType, Text } from '../../../../../../packages';

import { DomainPriorities } from '../../../../../../redux/modules/profile/types';
import { useStyles } from './MedicationCard.styles';

const MedicationBottomCard = ({ medication, showBadge }) => {
  const classes = useStyles();
  const {
    // tagMetaData: { rxDrugInfo },
    ...rest
  } = medication;

  const priority = rest.priority || rest.importanceLevel;

  // const renderBlock = (label: string, value: string) => {
  //   return (
  //     <Box>
  //       <Text className={classes.label}>{label}</Text>
  //       <Text className={clsx(classes.label, classes.value)}>{value}</Text>
  //     </Box>
  //   );
  // };

  const renderContent = () => {
    return (
      <Box flex={1}>
        {/* <Box display="flex" justifyContent="space-between" alignItems="center">
          {renderBlock('Dose', `${rxDrugInfo.dose}${rxDrugInfo.doseUnit.toLowerCase()}`)}
          {renderBlock('Frequency', getFrequencyTime(rxDrugInfo.doseFrequency, rxDrugInfo.supplyUnit))}
          {renderBlock('Supply', `${rxDrugInfo.supply} ${rxDrugInfo.supplyUnit.toLowerCase()}`)}
          {renderBlock('Refillable', rxDrugInfo.refillable ? `${rxDrugInfo.doseFrequency} times` : 'No')}
        </Box> */}
        {medication.notes && <Text className={clsx(classes.time, classes.notes)}>{medication.notes}</Text>}
      </Box>
    );
  };

  return (
    <Box className={classes.borderTop}>
      <Box className={clsx(classes.rowCenter, classes.flexStart)}>
        <Box display="flex" gap="12px" alignItems="center" flex={showBadge ? 1 : 0.3}>
          <Avatar
            src={medication.avatar}
            size={40}
            variant={avatarType.CIRCLE}
            name={medication.assignedBy}
          />
          <Box display="flex" gap="4px" flexDirection="column" justifyContent="flex-start">
            <Text className={classes.username}>By {medication.assignedBy}</Text>
            <Text className={classes.time}>{dayjs(medication.assignedAt).format('MMMM DD, YYYY')}</Text>
          </Box>
        </Box>
        {showBadge ? (
          <Badge
            variant={badgeType.FILLED}
            className={clsx(classes.badge, {
              [classes.badgeMisreported]: priority.name === DomainPriorities.MISREPORTED,
            })}
            style={badgeStyle[priority.name] || badgeStyle.UNRELATED}
          >
            {priority.name}
          </Badge>
        ) : (
          <Box
            display="flex"
            alignItems="center"
            className={clsx(classes.interference, {
              [classes.interferenceResolved]: medication.status === 'Resolved',
            })}
            flex="0.7"
          >
            {renderContent()}
          </Box>
        )}
      </Box>
      {showBadge && <Box className={classes.bottomBox}>{renderContent()}</Box>}
    </Box>
  );
};

export { MedicationBottomCard };
