// import { makeStyles } from '@mui/styles';

// // colors
// import { colors } from '../../../colors';

// // types
// import { fontWeight, headingLevel, textLevel } from './Typography.types';

// interface StyleProps {
//   weight?: fontWeight;
//   level?: headingLevel | textLevel;
//   size?: number | string;
// }

// const headingFontSize = {
//   [headingLevel.XL]: 40,
//   [headingLevel.L]: 34,
//   [headingLevel.M]: 24,
//   [headingLevel.S]: 18,
// };

// const textFontSize = {
//   [textLevel.XL]: 32,
//   [textLevel.L]: 22,
//   [textLevel.M]: 16,
//   [textLevel.S]: 14,
//   [textLevel.XS]: 12,
// };

// export const useStyles = makeStyles({
//   typography: {
//     color: colors.neutral900,
//     fontFamily: 'Manrope',
//     fontWeight: ({ weight }: StyleProps) => weight,
//     lineHeight: 1.5,
//   },
//   heading: {
//     fontSize: ({ size, level }: StyleProps) => size || headingFontSize[level as headingLevel],
//   },
//   text: {
//     fontSize: ({ size, level }: StyleProps) => size || textFontSize[level as textLevel],
//   },
//   error: {
//     color: colors.destructive500,
//     fontSize: 12,
//     lineHeight: '16px',
//   },
// });

import { makeStyles } from '@mui/styles';

// colors
import { colors } from '../../../colors';

// Heading Font Sizes
const headingFontSize = {
  XL: 40,
  L: 34,
  M: 24,
  S: 18,
};

// Text Font Sizes
const textFontSize = {
  XL: 32,
  L: 22,
  M: 16,
  S: 14,
  XS: 12,
};

export const useStyles = makeStyles({
  typography: {
    color: colors.neutral900,
    fontFamily: 'Manrope',
    fontWeight: props => props.weight,
    lineHeight: 1.5,
  },
  heading: {
    fontSize: props => props.size || headingFontSize[props.level],
  },
  text: {
    fontSize: props => props.size || textFontSize[props.level],
  },
  error: {
    color: colors.destructive500,
    fontSize: 12,
    lineHeight: '16px',
  },
});
