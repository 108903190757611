import { Box } from '@mui/material';

import { useStyles } from './TopbarView.styles';

const TopbarView = ({ publisher, screen, hidden }) => {
  const classes = useStyles();
  return (
    <Box position="relative" visibility={hidden ? 'hidden' : 'visible'}>
      <Box className={classes.container}>
        <Box display="flex" gap="30px">
          <div className={classes.thumbnail}>{publisher}</div>
          {screen && <div className={classes.thumbnail}>{screen}</div>}
        </Box>
      </Box>
    </Box>
  );
};

export { TopbarView };
