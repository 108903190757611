import { Box } from '@mui/material';

import { fontWeight, Text } from '../../atoms/typography';
import { FormControlLabel } from '../../atoms/form-control-label';
import { Checkbox } from '../../atoms/checkbox';

import { useStyles } from './MyCheckbox.styles';

const MyCheckbox = ({ label, sublabel, isChecked, onChange }) => {
  const classes = useStyles();

  return (
    <Box>
      <FormControlLabel
        control={<Checkbox />}
        checked={isChecked}
        onChange={onChange}
        className={classes.checkbox}
        label={label}
      />
      <Text className={classes.checkboxSubText} weight={fontWeight.MEDIUM}>
        {sublabel}
      </Text>
    </Box>
  );
};

export { MyCheckbox };
