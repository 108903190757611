/* eslint-disable react/no-did-update-set-state */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unused-state */
import { Component } from 'react';
import once from 'lodash/once';
import { isNil, omitBy } from 'lodash/fp';
import uuid from 'uuid';
import { SessionContext } from './OTSession';

export default class OTPublisher extends Component {
  constructor(props, context) {
    super(props);
    this.state = {
      publisher: null,
      lastStreamId: '',
      session: props.session || context.session || null,
    };
  }

  componentDidMount() {
    this.createPublisher();
  }

  componentDidUpdate(prevProps, prevState) {
    const getDefault = (value, defaultValue) => (value === undefined ? defaultValue : value);

    const shouldUpdate = (key, defaultValue) => {
      const previous = getDefault(prevProps.properties[key], defaultValue);
      const current = getDefault(this.props.properties[key], defaultValue);
      return previous !== current;
    };

    const updatePublisherProperty = (key, defaultValue) => {
      if (shouldUpdate(key, defaultValue)) {
        const value = getDefault(this.props.properties[key], defaultValue);
        this.state.publisher[key](value);
      }
    };

    if (shouldUpdate('videoSource', undefined)) {
      this.destroyPublisher();
      this.createPublisher();
      return;
    }

    updatePublisherProperty('publishAudio', true);
    updatePublisherProperty('publishVideo', true);

    if (this.context.session !== prevState.session) {
      this.destroyPublisher(prevState.session);
      this.createPublisher();
      this.setState({ session: this.context.session });
    }
  }

  componentWillUnmount() {
    if (this.context.session) {
      this.context.session.off('sessionConnected', this.sessionConnectedHandler);
    }

    this.destroyPublisher();
  }

  getPublisher() {
    return this.state.publisher;
  }

  publisherId;

  node;

  static contextType = SessionContext;

  destroyPublisher(session = this.context.session) {
    delete this.publisherId;

    if (this.state.publisher) {
      this.state.publisher.off('streamCreated', this.streamCreatedHandler);

      if (this.props.eventHandlers && typeof this.props.eventHandlers === 'object') {
        this.state.publisher.once('destroyed', () => {
          this.state.publisher.off(this.props.eventHandlers);
        });
      }

      if (session) {
        session.unpublish(this.state.publisher);
      }
      this.state.publisher.destroy();
    }
  }

  publishToSession(publisher) {
    const { publisherId } = this;

    this.context.session.publish(publisher, err => {
      if (publisherId !== this.publisherId) {
        // Either this publisher has been recreated or the
        // component unmounted so don't invoke any callbacks
        return;
      }
      if (err) {
        this.errorHandler(err);
      } else if (typeof this.props.onPublish === 'function') {
        this.props.onPublish();
      }
    });
  }

  errorHandler(err) {
    throw new Error('Method not implemented.');
  }

  createPublisher() {
    if (!this.context.session) {
      this.setState({ publisher: null, lastStreamId: '' });
      return;
    }

    const properties = this.props.properties || {};
    let container;

    if (properties.insertDefaultUI !== false) {
      container = document.createElement('div');
      container.setAttribute('class', 'OTPublisherContainer');
      this.node.appendChild(container);
    }

    this.publisherId = uuid();
    const { publisherId } = this;

    this.errorHandler = once(err => {
      if (publisherId !== this.publisherId) {
        // Either this publisher has been recreated or the
        // component unmounted so don't invoke any callbacks
        return;
      }
      if (typeof this.props.onError === 'function') {
        this.props.onError(err);
      }
    });

    const publisher = OT.initPublisher(container, properties, err => {
      if (publisherId !== this.publisherId) {
        // Either this publisher has been recreated or the
        // component unmounted so don't invoke any callbacks
        return;
      }
      if (err) {
        this.errorHandler(err);
      } else if (typeof this.props.onInit === 'function') {
        this.props.onInit();
      }
    });
    publisher.on('streamCreated', this.streamCreatedHandler);

    if (this.props.eventHandlers && typeof this.props.eventHandlers === 'object') {
      const handles = omitBy(isNil)(this.props.eventHandlers);
      publisher.on(handles);
    }

    if (this.context.session.connection) {
      this.publishToSession(publisher);
    } else {
      this.context.session.once('sessionConnected', this.sessionConnectedHandler);
    }

    this.setState({ publisher, lastStreamId: '' });
  }

  sessionConnectedHandler = () => {
    this.publishToSession(this.state.publisher);
  };

  streamCreatedHandler = event => {
    this.setState({ lastStreamId: event.stream.id });
  };

  render() {
    const { className, style } = this.props;
    return (
      <div
        className={className}
        style={style}
        ref={node => {
          this.node = node;
        }}
      />
    );
  }
}
