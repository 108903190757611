import { useEffect, useMemo } from 'react';

// hooks
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import Box from '@mui/material/Box';

// components
import { Breadcrumbs, colors, Icons } from '../../../packages';

import { getProfile } from '../../../redux/modules/profile/selectors';
import { getAuth } from '../../../redux/modules/auth/selectors';
import { appointmentActionCreators } from '../../../redux/modules/appointment';
import { stateActionCreators } from '../../../redux/modules/state';
import {
  selectAppointmentDetailsById,
  selectAppointmentDetailsStateLoading,
} from '../../../redux/modules/appointment/selectors';
import { BaseLayout } from '../../../layouts/base';

import SelectedComponent from './MemberAppointmentNotes.selected';
import RightSidebar from '../profile/components/right-sidebar';

// styles
import { useStyles } from './MemberAppointmentNotes.styles';
import { priorityList } from '../sessions/priority-list/PriorityList.constants';

const MemberAppointmentNotes = () => {
  const classes = useStyles();
  const { selectedAppointment } = useSelector(getProfile);
  const { isAdmin } = useSelector(getAuth);
  const location = useLocation();
  const userRole = location.pathname.split('/')[1];
  const { memberId, appointmentId } = useParams();
  const appointmentDetails = useSelector(selectAppointmentDetailsById(appointmentId));
  const appointmentDetailsLoading = useSelector(selectAppointmentDetailsStateLoading);
  const { domainTypes } = useSelector(getProfile);
  const dispatch = useDispatch();
  const serviceName = appointmentDetails ? appointmentDetails?.serviceName : selectedAppointment?.serviceName;
  const member = useMemo(
    () => appointmentDetails?.participants.filter(participant => !participant.isPractitioner)?.[0],
    [appointmentDetails],
  );

  useEffect(() => {
    if (memberId && member?.participantId && memberId !== member?.participantId) {
      console.error('Oop! Something went wrong');
    }
  }, [memberId, member]);

  useEffect(() => {
    dispatch(stateActionCreators.fetchICD10());
  }, []);

  useEffect(() => {
    if (appointmentId) {
      dispatch(appointmentActionCreators.fetchAppointmentDetails(appointmentId));
    }
  }, [appointmentId]);

  const rightSidebarNavItems = useMemo(
    () =>
      [
        {
          id: 'general',
          value: 'General information',
        },
        {
          id: 'cpts',
          value: 'CPT Codes',
        },
        {
          id: 'evaluations',
          value: 'Evaluations',
          children:
            appointmentDetails?.evaluationProgress.map(evaluation => ({
              id: evaluation.id,
              value: evaluation.name,
            })) ?? [],
        },
        {
          id: 'priorities',
          value: 'Priorities',
          children: [
            {
              id: 'Symptomatic Factors',
              value: 'Symptomatic Factors',
            },
            {
              id: 'Diagnoses',
              value: 'Diagnoses',
            },
            {
              id: 'Environmental Factors',
              value: 'Environmental Factors',
            },
            {
              id: 'Medications',
              value: 'Medications',
            },
          ],
        },
        isAdmin && {
          id: 'Case notes',
          value: 'Case Notes',
        },
        { id: 'signOff', value: 'Sign off' },
      ].filter(Boolean),
    [appointmentDetails, domainTypes, priorityList],
  );

  return (
    <BaseLayout
      customRightSideBar={<RightSidebar title="Appointment details" navItems={rightSidebarNavItems} />}
      rightSideBarWidth="320px"
    >
      <div className={classes.root}>
        <div className={classes.breadcrumbs}>
          <Breadcrumbs
            links={[
              { href: `/${userRole}/members`, text: 'Members' },
              {
                href: `/${userRole}/members/${member?.participantId}?name=dashboard`,
                text: member?.name,
              },
              { text: serviceName },
            ]}
          />
        </div>
        {appointmentDetailsLoading && (
          <Box display="flex" justifyContent="center" sx={{ paddingY: 5 }}>
            <Icons className="rotate linear infinite" glyph="in-progress" color={colors.primary} />
          </Box>
        )}
        <SelectedComponent />
      </div>
    </BaseLayout>
  );
};

export { MemberAppointmentNotes };
