import { TOGGLE_SIDEBAR, OPEN_DRAWER, CLOSE_DRAWER } from './actions';

export const DEFAULT = {
  showSidebar: true,
  activeDrawer: null,
};

export default function commonReducer(state = DEFAULT, action) {
  const { type, payload } = action;

  switch (type) {
    case TOGGLE_SIDEBAR: {
      return {
        showSidebar: !state.showSidebar,
      };
    }
    case OPEN_DRAWER: {
      return {
        ...state,
        activeDrawer: payload,
      };
    }
    case CLOSE_DRAWER: {
      return {
        ...state,
        activeDrawer: null,
      };
    }
    default: {
      return state;
    }
  }
}
