import { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';

import { fontWeight, Text, TextError } from '../../../../../packages';
import { appointmentActionCreators } from '../../../../../redux/modules/appointment';
import dayjs from '../../../../../utils/dayjs';
import { getCostRange, getDurationUnit } from '../../../../../utils/CommonUtils';
import { useStyles } from './StepThree.styles';

const StepThreeNew = ({
  formData,
  // onChange,
  error,
  // selectedDateTime,
  onChangeService,
  onChangeSelectedService,
  serviceId,
  services,
  isNewProviderSelected,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  // const { services } = useSelector(selectProviderServicesState);
  const [selectedServiceId, setSelectedServiceId] = useState(serviceId || '');
  const state = formData?.member?.state;

  // const providerSchedule = useSelector(selectProviderSchedule);

  useEffect(() => {
    if (formData && formData?.provider?.id) {
      dispatch(appointmentActionCreators.fetchProviderServices(formData?.provider?.id));
    }
  }, [formData]);

  useEffect(() => {
    if (serviceId) {
      onChangeService(serviceId);
    }
  }, [serviceId]);

  return (
    <Box className={classes.serviceContainer}>
      {error && (
        <Box display="flex" justifyContent="center" flex={1} padding={2}>
          <TextError errorMsg={error} />
        </Box>
      )}
      {services?.length !== 0 ? (
        <>
          {services.map((service, index) => (
            <Box
              key={index}
              className={clsx(classes.serviceListItem, {
                [classes.activeService]: isNewProviderSelected
                  ? service.serviceId === selectedServiceId
                  : service.id === selectedServiceId,
              })}
              onClick={() => {
                setSelectedServiceId(service.id || service.serviceId);
                onChangeSelectedService(service);
                onChangeService(service.id || service.serviceId);
                let commaSeparatedParticipantIds;
                if (isNewProviderSelected) {
                  const serviceIds = service.providerServices.map(
                    providerService => providerService.providerId,
                  );
                  commaSeparatedParticipantIds = serviceIds.join(',');
                }
                dispatch(
                  appointmentActionCreators.fetchMasterSchedule2({
                    participantIds: isNewProviderSelected
                      ? commaSeparatedParticipantIds
                      : formData?.provider?.id,
                    memberId: formData?.member?.id,
                    serviceId: isNewProviderSelected ? '' : service.id || service.serviceId,
                    viewProviderDetails: isNewProviderSelected,
                    viewInsurances: true,
                    timezone: 'America/New_York',
                    startDate: dayjs().startOf('month').subtract(2, 'day').format('DD-MM-YYYY'),
                    endDate: dayjs().endOf('month').format('DD-MM-YYYY'),
                    state,
                    duration: service.duration,
                  }),
                );
              }}
            >
              <Box className={classes.serviceNameContainer}>
                <Text weight={fontWeight.SEMI_BOLD} className={classes.serviceName}>
                  {service.name}
                </Text>
                <Text weight={fontWeight.MEDIUM} className={classes.serviceDuration}>
                  {getDurationUnit(service.duration)}
                </Text>
              </Box>
              <Box className={classes.serviceCostContainer}>
                {isNewProviderSelected ? `${getCostRange(service)}` : `$${service.cost}`}
              </Box>
            </Box>
          ))}
        </>
      ) : (
        <Text className={clsx(classes.day, classes.textCenter)}>No service available</Text>
      )}
    </Box>
  );
};

export default StepThreeNew;
