// import { makeStyles } from '@mui/styles';

// // colors
// import { colors } from '../../../colors';

// // types
// import { statusType } from './Status.types';

// interface StyleProps {
//   variant?: statusType;
// }

// export const useStyles = makeStyles({
//   root: {
//     display: 'flex',
//     alignItems: 'center',
//     fontFamily: 'Manrope',
//     fontSize: 14,
//     fontWeight: 600,
//     letterSpacing: '0.01em',
//     color: ({ variant }: StyleProps) =>
//       variant === statusType.ACTIVE ? colors.success : colors.textLowContrast,
//   },
//   dot: {
//     width: 12,
//     height: 12,
//     marginRight: 8,
//     borderRadius: '50%',
//     backgroundColor: ({ variant }: StyleProps) =>
//       variant === statusType.ACTIVE ? colors.success : colors.neutral600,
//   },
// });

import { makeStyles } from '@mui/styles';

// colors
import { colors } from '../../../colors';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Manrope',
    fontSize: 14,
    fontWeight: 600,
    letterSpacing: '0.01em',
    color: ({ variant }) => (variant === 'ACTIVE' ? colors.success : colors.textLowContrast),
  },
  dot: {
    width: 12,
    height: 12,
    marginRight: 8,
    borderRadius: '50%',
    backgroundColor: ({ variant }) => (variant === 'ACTIVE' ? colors.success : colors.neutral600),
  },
});

export { useStyles };
