import { Box } from '@mui/material';

import {
  Badge,
  badgeType,
  colors,
  fontWeight,
  Heading,
  headingLevel,
  Icons,
  Text,
} from '../../../../../packages';
import { ProgressBar } from '../../../../../packages/ui/atoms/ProgressBar';

import {
  EVALUATION_STATUS,
  EVALUATION_STATUS_TYPES,
  evaluationStatusStyle,
} from '../../../sessions/evaluations-v2/Evaluations.constants';
import clsx from 'clsx';

import { useStyles } from './CheckListCard.styles';

const CheckListCard = ({ title, subtitle, status, progress, icon, onClick }) => {
  const classes = useStyles();

  return (
    <Box className={classes.card}>
      <Box className={classes.cardContent} onClick={onClick}>
        <Box className={classes.iconWrapper}>
          <Icons color={colors.primary500} glyph={icon} />
        </Box>
        <Box
          className={status === EVALUATION_STATUS_TYPES.IN_PROGRESS ? classes.cardText : classes.cardText2}
        >
          <Heading level={headingLevel.M} weight={fontWeight.SEMI_BOLD} className={classes.title}>
            {title}
          </Heading>
          <Text className={classes.subtitle}>{subtitle}</Text>
        </Box>
        {status && (
          <Box className={classes.statusBadge}>
            {status === EVALUATION_STATUS_TYPES.IN_PROGRESS ? (
              <>
                <ProgressBar value={progress} className={classes.progress} />
                <Badge
                  className={classes.badge}
                  variant={badgeType.OUTLINED}
                  style={evaluationStatusStyle[status]}
                >
                  {progress}%
                </Badge>
              </>
            ) : (
              <Badge
                icon={status === 'COMPLETED' || status === 'INCOMPLETE' ? 'check-circle' : null}
                className={clsx({
                  [classes.badgeCompleted]: status === 'COMPLETED',
                  [classes.badgeIncomplete]: status === 'INCOMPLETE',
                  [classes.badge]: status !== 'COMPLETED' && status !== 'INCOMPLETE',
                })}
                variant={
                  status === 'COMPLETED' || status === 'INCOMPLETE' ? badgeType.ICON : badgeType.OUTLINED
                }
                style={evaluationStatusStyle[status]}
              >
                {EVALUATION_STATUS[status]}
              </Badge>
            )}
          </Box>
        )}
      </Box>

      {/*   <Menu
        icon="more"
        className={classes.menu}
        // itemsWrapperClassName={classes.menuItemsWrapper}
        // items={items}
      /> */}
    </Box>
  );
};

export { CheckListCard };
