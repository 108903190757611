import { Box } from '@mui/material';

import clsx from 'clsx';
import { fontWeight, Text, textLevel } from '../../../../../packages';
import { ProfileInfo } from '../../../../../packages/ui/templates/profile-info';
import { useStyles2 } from './StepFour.styles';
import { getFullImagePath } from '../../../../../utils/CommonUtils';
import dayjs from '../../../../../utils/dayjs';

const StepFour = ({ formData }) => {
  const classes = useStyles2();

  const renderLabel = label => (
    <Text className={clsx(classes.text18, classes.label)} weight={fontWeight.BOLD}>
      {label}
    </Text>
  );

  return (
    <Box className={classes.reviewWrap}>
      <Box
        className={classes.reviewSection}
        display="flex"
        justifyContent="space-around"
        borderBottom="solid 1px #EDF1F5"
        alignItems="center"
      >
        <Box className={classes.title}> {renderLabel('Member')}</Box>
        <Box className={classes.valueSection}>
          <ProfileInfo
            type="member"
            photo={getFullImagePath(formData.member?.photo)}
            nickName={`${formData.member?.firstName || ''} ${formData.member?.lastName || ''}`}
            fullName={formData.member?.uuid}
            role={formData.member?.designation}
          />
        </Box>
      </Box>
      <Box
        className={classes.reviewSection}
        display="flex"
        justifyContent="space-around"
        borderBottom="solid 1px #EDF1F5"
        alignItems="center"
      >
        <Box className={classes.title}> {renderLabel('Provider')}</Box>
        <Box className={classes.valueSection}>
          <ProfileInfo
            type="provider"
            photo={getFullImagePath(formData.provider?.profileImage)}
            fullName={formData.provider?.fullName}
            role={formData.provider?.designation}
          />
        </Box>
      </Box>
      <Box className={classes.reviewSection} display="flex" justifyContent="space-around" alignItems="center">
        <Box className={classes.title}> {renderLabel('Date')}</Box>
        <Box className={classes.valueSection} gap="16px">
          <Text className={classes.text16} level={textLevel.M} weight={fontWeight.MEDIUM}>
            {dayjs(formData.time?.start).format('MMMM D, YYYY')},
            {dayjs(formData.time?.start).format('hh:mm A')} - {dayjs(formData.time?.end).format('hh:mm A')}
          </Text>
        </Box>
      </Box>
    </Box>
  );
};

export default StepFour;
