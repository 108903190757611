/* eslint-disable max-len */
import cx from 'clsx';

// types
// styles
import { useStyles } from './shared/styles';

const EyeOutlined = ({ className, color, onClick }) => {
  const classes = useStyles();
  return (
    <svg
      className={cx({
        [classes.root]: true,
        [className || '']: className,
      })}
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M9.99996 2.5C14.4933 2.5 18.2316 5.73333 19.0158 10C18.2325 14.2667 14.4933 17.5 9.99996 17.5C5.50663 17.5 1.7683 14.2667 0.984131 10C1.76746 5.73333 5.50663 2.5 9.99996 2.5ZM9.99996 15.8333C11.6995 15.833 13.3486 15.2557 14.6773 14.196C16.0061 13.1363 16.9357 11.6569 17.3141 10C16.9343 8.34442 16.0041 6.86667 14.6755 5.80835C13.3469 4.75004 11.6986 4.17377 9.99996 4.17377C8.30138 4.17377 6.65304 4.75004 5.32444 5.80835C3.99585 6.86667 3.0656 8.34442 2.6858 10C3.06421 11.6569 3.99386 13.1363 5.32258 14.196C6.65131 15.2557 8.30041 15.833 9.99996 15.8333ZM9.99996 13.75C9.0054 13.75 8.05158 13.3549 7.34831 12.6516C6.64505 11.9484 6.24996 10.9946 6.24996 10C6.24996 9.00544 6.64505 8.05161 7.34831 7.34835C8.05158 6.64509 9.0054 6.25 9.99996 6.25C10.9945 6.25 11.9484 6.64509 12.6516 7.34835C13.3549 8.05161 13.75 9.00544 13.75 10C13.75 10.9946 13.3549 11.9484 12.6516 12.6516C11.9484 13.3549 10.9945 13.75 9.99996 13.75ZM9.99996 12.0833C10.5525 12.0833 11.0824 11.8638 11.4731 11.4731C11.8638 11.0824 12.0833 10.5525 12.0833 10C12.0833 9.44747 11.8638 8.91756 11.4731 8.52686C11.0824 8.13616 10.5525 7.91667 9.99996 7.91667C9.44743 7.91667 8.91753 8.13616 8.52683 8.52686C8.13612 8.91756 7.91663 9.44747 7.91663 10C7.91663 10.5525 8.13612 11.0824 8.52683 11.4731C8.91753 11.8638 9.44743 12.0833 9.99996 12.0833Z"
        fill={color}
      />
    </svg>
  );
};

export default EyeOutlined;
