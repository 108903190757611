import { Fragment } from 'react';
import { Box } from '@mui/material';
import cx from 'clsx';

// components
import {
  fontWeight,
  Heading,
  headingLevel,
  Text,
  textLevel,
} from '../../../../../packages/ui/atoms/typography';
import { Badge, badgeType, badgeStyle } from '../../../../../packages/ui/atoms/badge';
import { Link } from '../../../../../packages/ui/atoms/link';
import { ProgressBar } from '../../../../../packages/ui/atoms/ProgressBar';
import { btnType, btnSize, Button } from '../../../../../packages/ui/atoms/button';

// icons
import { Icons } from '../../../../../packages/icons';

// colors
import { colors } from '../../../../../packages/colors';

import dayjs from '../../../../../utils/dayjs';

// styles
import { useStyles } from '../../MemberDetail.styles';

const ChatbotCard = ({ chatbot, onClickPrevCompletions, onClickChatbot }) => {
  const classes = useStyles({});

  const {
    chatbotName,
    chatbotVersion,
    percentage,
    dcts,
    completedAt,
    assignedAt,
    lastModified,
    latestContextId,
  } = chatbot;

  const renderNonScorableDCT = dct => {
    return (
      <>
        {dct.completed ? (
          <Icons glyph="checkmark" color={colors.success500} />
        ) : (
          <Icons glyph="clear-circle" color={colors.neutral600} />
        )}
        <Text level={textLevel.S} weight={fontWeight.MEDIUM}>
          {dct.dctName}
        </Text>
      </>
    );
  };

  const renderScorableDCT = dct => {
    return (
      <>
        <Badge className={classes.badge} style={badgeStyle.SUCCESS} variant={badgeType.FILLED}>
          Score: {dct.finalScore}
        </Badge>
        <Text level={textLevel.S} weight={fontWeight.MEDIUM}>
          {dct.dctName}
        </Text>
      </>
    );
  };

  const renderDCTs = dctList => {
    return (
      <>
        {dctList.map((dct, index) => (
          <div key={index}>{dct.scorable ? renderScorableDCT(dct) : renderNonScorableDCT(dct)}</div>
        ))}
      </>
    );
  };

  const getStatus = () => {
    if (percentage === 0) {
      return 'Not started';
    }
    if (percentage > 0 && percentage < 100) {
      return 'In progress';
    }
    return 'Completed';
  };

  return (
    <Fragment>
      <div className={classes.chatBotCard}>
        <div className={classes.chatBotCardHeaderWrapper}>
          <div className={classes.chatBotCardHeader}>
            <div className={classes.chatBotCardHeaderContent}>
              <Heading
                className={classes.chatBotCardHeaderContentTitle}
                weight={fontWeight.SEMI_BOLD}
                level={headingLevel.M}
              >
                {chatbotName}{' '}
                {chatbotVersion && (
                  <Text
                    className={classes.chatBotCardHeaderContentSubTitle}
                    level={textLevel.S}
                    weight={fontWeight.MEDIUM}
                  >
                    v{chatbotVersion}
                  </Text>
                )}
              </Heading>
            </div>
            {assignedAt && (
              <Text
                className={classes.chatBotCardHeaderContentSubTitle}
                level={textLevel.M}
                weight={fontWeight.MEDIUM}
              >
                {`Assigned at :  ${dayjs(assignedAt).format('MMMM DD, YYYY hh:mm a')}`}
              </Text>
            )}
            {percentage > 99 && completedAt ? (
              <Text
                className={classes.chatBotCardHeaderContentSubTitle}
                level={textLevel.M}
                weight={fontWeight.MEDIUM}
              >
                {`Completed at :  ${dayjs(completedAt).format('MMMM DD, YYYY hh:mm a')}`}
              </Text>
            ) : (
              lastModified && (
                <Text
                  className={classes.chatBotCardHeaderContentSubTitle}
                  level={textLevel.M}
                  weight={fontWeight.MEDIUM}
                >
                  {`Last Modified :  ${dayjs(lastModified).format('MMMM DD, YYYY hh:mm a')}`}
                </Text>
              )
            )}
          </div>
          <Badge
            className={classes.badgeOutlined}
            style={percentage > 99 ? badgeStyle.SUCCESS : badgeStyle.CRITICAL}
            variant={badgeType.OUTLINED}
          >
            {getStatus()}
          </Badge>

          <Box className={classes.actions}>
            <Link
              className={cx({ [classes.careTeamValueWrapper]: true, [classes.link]: true })}
              onClick={e => {
                e.preventDefault();
                onClickPrevCompletions();
              }}
            >
              Previous completions
            </Link>
            {!!latestContextId && (
              <Button variant={btnType.SECONDARY} size={btnSize.SMALL} onClick={onClickChatbot}>
                View chatbot
              </Button>
            )}
          </Box>
        </div>
        {percentage < 100 && (
          <div className={classes.progressWrapper}>
            <Text className={classes.chatBotProgressText} level={textLevel.XS} weight={fontWeight.MEDIUM}>
              {percentage}% completed
            </Text>
            <ProgressBar value={percentage} />
          </div>
        )}
        <div className={classes.progressDetailWrapper}>{renderDCTs(dcts)}</div>
      </div>
    </Fragment>
  );
};

export default ChatbotCard;
