/* eslint-disable max-len */
import cx from 'clsx';

// types
// styles
import { useStyles } from './shared/styles';

const MessageUnreadOutlined = ({ className, color, onClick }) => {
  const classes = useStyles();
  return (
    <svg
      className={cx({
        [classes.root]: true,
        [className || '']: className,
      })}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M4.30301 12.6667L1.33301 15V2.66667C1.33301 2.48986 1.40325 2.32029 1.52827 2.19526C1.65329 2.07024 1.82286 2 1.99967 2H13.9997C14.1765 2 14.3461 2.07024 14.4711 2.19526C14.5961 2.32029 14.6663 2.48986 14.6663 2.66667V12C14.6663 12.1768 14.5961 12.3464 14.4711 12.4714C14.3461 12.5964 14.1765 12.6667 13.9997 12.6667H4.30301ZM3.84167 11.3333H13.333V3.33333H2.66634V12.2567L3.84167 11.3333ZM5.33301 6.66667H10.6663V8H5.33301V6.66667Z"
        fill={color}
      />
    </svg>
  );
};

export default MessageUnreadOutlined;
