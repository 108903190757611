import clsx from 'clsx';
import { Box, Modal } from '@mui/material';

import { Heading, Text } from '../../../packages/ui/atoms/typography';
import { Icons } from '../../../packages/icons';
import { btnType, Button } from '../../../packages/ui/atoms/button';
import { colors } from '../../../packages/colors';

import { useStyles } from '../schedule/renderTabContents/common.styles';

const ConfirmModal = ({
  icon,
  open,
  title,
  content,
  lbtnLabel,
  rbtnLabel,
  onClose,
  isLoading,
  onCancel,
  onSubmit,
}) => {
  const classes = useStyles();

  return (
    <Modal open={open} onClose={onClose}>
      <Box className={classes.modal}>
        <Box className={classes.modalContent}>
          {icon && (
            <Box className={classes.editIconWrap}>
              <Icons glyph={icon} className={classes.editIcon} />
            </Box>
          )}
          <Box className={classes.content}>
            <Box>
              <Heading className={classes.title}>{title}</Heading>
              <Text className={classes.subtitle}>{content}</Text>
              <Icons glyph="close" onClick={onClose} className={classes.closeBtn} color={colors.neutral400} />
            </Box>
            <Box display="flex" justifyContent="space-between" alignItems="center" gap="16px">
              <Button
                className={clsx(classes.modalBtn, classes.cancelBtn)}
                disabled={isLoading}
                onClick={onCancel || onClose}
                variant={btnType.OUTLINE}
              >
                {lbtnLabel ?? 'Cancel'}
              </Button>
              <Button
                className={clsx(classes.modalBtn, classes.okBtn)}
                disabled={isLoading}
                onClick={onSubmit}
              >
                {rbtnLabel ?? 'Ok'}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default ConfirmModal;
