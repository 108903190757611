import React, { useCallback, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { colors, Icons } from '../../../packages';
import { showSnackbar } from '../../../redux/modules/snackbar';
import { profileActionCreators } from '../../../redux/modules/profile';
import { getAppointment } from '../../../redux/modules/appointment/selectors';
import history from '../../../utils/history';
import { patchAppointment } from '../../../services/appointment/appointment.service';
import { getAppointmentEvaluationProgress } from '../../../services/conversation/conversation.service';
import { OPENTOK_APIKEY } from '../../../constants/CommonConstants';
import { getCareTeamMember } from '../../../services/member/member.service';
import { getUserTimeZone } from '../../../utils/dayjs';
import { appointmentActionCreators } from '../../../redux/modules/appointment';
import { getProfile } from '../../../redux/modules/profile/selectors';
import { selectProviderSchedule } from '../../../redux/modules/schedule/selectors';
import { getAuth } from '../../../redux/modules/auth/selectors';
import dayjs from 'dayjs';
import AppointmentInfo from './appointment-info';
import WaitingHeader from './waiting-header';
import VideoCallScreen from './video-call-screen';
import EndSession from './end-session';
import WaitingRoomTopBar from './end-session-topbar';
import FeedbackAppointment from '../appointments/components/feedback-appointment';
import { EVALUATION_STATUS } from './evaluations-v2/Evaluations.constants';
import { useStyles } from './Sessions.styles';
import { PatchAction } from '../../../redux/modules/appointment/types';
import { ProvideEvaluationHead } from '../../../hooks/useEvaluationHead';

const Sessions = () => {
  const classes = useStyles();
  const { appointmentId } = useParams();
  const dispatch = useDispatch();
  const { profile, demographicDetails } = useSelector(getProfile);
  const providerSchedule = useSelector(selectProviderSchedule);
  const { isAdmin, meta } = useSelector(getAuth);
  const { appointments: appointmentsState = [] } = useSelector(getAppointment);

  const [appointment, setAppointment] = useState(null);
  const [isEndedSession, setIsEndedSession] = useState(false);
  const [showEndSessionConfirm, setShowEndSessionConfirm] = useState(false);
  const [telesessionToken, setTelesessionToken] = useState('');
  const [telesessionId, setTelesessionId] = useState('');
  const [isWaiting, setIsWaiting] = useState(true);
  const [isInitializing, setIsInitializing] = useState(true);
  const [showFeedbackAppointment, setShowFeedbackAppointment] = useState(false);
  const [careTeamList, setCareTeamList] = useState([]);
  const [session, setSession] = useState(null);
  const [callEndedReason, setCallEndedReason] = useState(null);
  const [inPersonSession, setInPersonSession] = useState(false);

  useEffect(() => {
    if (!appointment) {
      const item = appointmentsState?.find(app => app.appointmentId === appointmentId);
      if (item) {
        joinAppointmentRequest(appointmentId);

        dispatch(profileActionCreators.fetchDemographicDetails(item.participantId));

        const newItem = {
          ...item,
          participantGenderIdentity: demographicDetails?.genderIdentity,
        };

        setAppointment(newItem);
        dispatch(profileActionCreators.fetchDomainTypes(item.participantId));
        getCareTeamList(item);
      } else if (!item && !isAdmin) {
        fetchAppointments2(profile?.providerId, providerSchedule?.timezone);
      }
    }
  }, [appointmentsState, appointment, appointmentId]);

  /**
   * @Name useEffect
   * @description This method is used to get Evaluation List with progress detail of each evaluation
   */
  useEffect(() => {
    if (!appointmentId) {
      dispatch(
        showSnackbar({
          snackType: 'error',
          snackMessage: 'Please provide appointment Id',
        }),
      );
    }
  }, []);

  /**
   * @Name onEndSession
   * @description This method is used to end tele session
   */
  const onEndSession = (endedVia = null) => {
    session.signal(
      {
        type: 'session-ended',
        data: 'Call Disconnected',
      },
      () => {
        patchAppointment({ action: PatchAction.COMPLETE_APPOINTMENT }, { appointmentId })
          .then(response => {
            if (response.status === 200) {
              setShowEndSessionConfirm(false);
              setIsEndedSession(true);
              // setSelectedSession(FilterSessionsMock[0].type);
              setShowFeedbackAppointment(true);
              setIsWaiting(false);
              patchAppointment(
                {
                  action: PatchAction.PROVIDER_SIGN_OFF,
                  skipProviderSignOff: true,
                },
                {
                  appointmentId: appointment.appointmentId,
                },
              )
                .then(() => console.log('Moved to pending'))
                .catch(() => console.log('Whoops ! something went wrong when moving to pending'));
            }
          })
          .catch(err => {
            dispatch(
              showSnackbar({
                snackType: 'error',
                snackMessage: 'Whoops ! something went wrong please try again',
              }),
            );
            setShowEndSessionConfirm(false);
            console.log('Whoops ! something went wrong please try again', err);
          });
      },
    );
  };

  /**
   * @Name toggleEndSessionConfirm
   * @description This method is used to show end session popup and navigate to next screen
   */
  const toggleEndSessionConfirm = (endedVia = null) => {
    if (!isEndedSession) {
      setShowEndSessionConfirm(s => !s);
      setCallEndedReason(endedVia);
    }
  };

  const startInPersonCall = () => {
    setIsWaiting(false);
    setInPersonSession(true);
    void patchAppointment({ action: PatchAction.START_IN_PERSON }, { appointmentId });
  };

  const onCloseFeedbackAppointment = (isSubmitted = false) => {
    setShowFeedbackAppointment(false);
  };

  /**
   * @Name joinAppointmentRequest
   * @description This method is used to get session details like session token etc
   */
  const joinAppointmentRequest = useCallback(id => {
    patchAppointment({ action: PatchAction.ARRIVE_FOR_APPOINTMENT }, { appointmentId: id })
      .then(({ data }) => {
        setTelesessionToken(data?.telesessionToken);
        setTelesessionId(data?.telesessionId);
        setSession(OT.initSession(OPENTOK_APIKEY, data?.telesessionId));
        setIsInitializing(false);
      })
      .catch(err => {
        dispatch(
          showSnackbar({
            snackType: 'error',
            snackMessage: err.data?.errors?.[0]?.endUserMessage || 'Can not join appointment',
          }),
        );
        history.push(
          `/provider/${meta?.userId}/day-schedule?date=${dayjs().tz(getUserTimeZone()).format('YYYY-MM-DD')}`,
        );
      });
  }, []);

  /**
   * @Name getCareTeamList
   * @description This method is used to get care team list for the patient
   */
  const getCareTeamList = appt => {
    if (!appt?.participantId) {
      dispatch(
        showSnackbar({
          snackType: 'error',
          snackMessage: 'Please provide Member Id',
        }),
      );
    } else {
      getCareTeamMember({ userId: appt?.participantId }, { careNavigator: true })
        .then(careTeamListResponse => {
          if (careTeamListResponse?.status !== 200) {
            dispatch(
              showSnackbar({
                snackType: 'error',
                snackMessage: 'Whoops ! Something went wrong . Please try later',
              }),
            );
          } else {
            setCareTeamList(careTeamListResponse?.data || []);
          }
        })
        .catch(error => {
          console.log('Whoops ! Something went wrong . Please try later', error);
        });
    }
  };

  const fetchAppointments2 = (providerId, timeZone) => {
    const filters = [{ searchField: 'status', searchQuery: 'BOOKED' }];
    const queryParams = {
      orderBy: 'desc',
      pageNumber: 0,
      size: 300,
      sortBy: 'startTime',
      statuses: 'BOOKED',
      type: 'CURRENT',
      searchQuery: '',
    };
    const bodyRequest = {
      providerId,
      refDate: dayjs().format('DD-MM-YYYY'),
      timezone: timeZone || getUserTimeZone(),
      type: 'current',
      textSearch: '',
      size: 300,
      filters,
    };
    dispatch(
      appointmentActionCreators.fetchAppointments({
        bodyRequest,
        queryParams: { ...queryParams },
      }),
    );
  };

  const resetSessionStates = () => {
    setIsEndedSession(false);
    setIsWaiting(true);
    setIsInitializing(true);
    setTelesessionToken(null);
    setTelesessionId(null);
    setSession(null);
  };

  const handleRestartSession = () => {
    patchAppointment(
      { action: PatchAction.RESTART_APPOINTMENT },
      { appointmentId: appointment.appointmentId },
    )
      .then(response => {
        if (response.status === 200) {
          resetSessionStates();
          joinAppointmentRequest(appointment?.appointmentId);
        }
      })
      .catch(err => {
        dispatch(
          showSnackbar({
            snackType: 'error',
            snackMessage: err?.data?.errors[0].endUserMessage,
          }),
        );
        console.log('Whoops ! something went wrong please try again', err);
      });
  };

  return (
    <>
      <EndSession
        onClose={toggleEndSessionConfirm}
        onEndSession={onEndSession}
        open={showEndSessionConfirm}
        endedReason={callEndedReason}
      />
      <FeedbackAppointment
        appointmentId={appointmentId}
        open={showFeedbackAppointment}
        onClose={onCloseFeedbackAppointment}
      />
      <Box className={classes.container}>
        {isWaiting && !isEndedSession && <WaitingRoomTopBar appointment={appointment} />}
        <Box className={classes.mainContent}>
          {isInitializing || !appointment ? (
            <Box display="flex" justifyContent="space-between" className={classes.waitingScreen}>
              <Box className={classes.boxLeft}>
                <WaitingHeader isCompleted={isEndedSession} appointment={appointment} />
                <Box display="flex" justifyContent="center" sx={{ paddingY: 5 }}>
                  <Icons className="rotate linear infinite" glyph="in-progress" color={colors.primary} />
                </Box>
              </Box>
              <AppointmentInfo
                isCompleted={isEndedSession}
                onEndSession={onEndSession}
                appointment={appointment}
                startInPersonSession={startInPersonCall}
                showInPersonSession={false}
              />
            </Box>
          ) : (
            <ProvideEvaluationHead>
              <VideoCallScreen
                isWaiting={isWaiting}
                setIsWaiting={setIsWaiting}
                onEndSession={toggleEndSessionConfirm}
                endSession={onEndSession}
                isEndedSession={isEndedSession}
                appointment={appointment}
                telesessionToken={telesessionToken}
                telesessionId={telesessionId}
                careTeamList={careTeamList}
                startInPersonSession={startInPersonCall}
                isInPersonSessionCall={inPersonSession}
                onRestartSessionClick={handleRestartSession}
              />
            </ProvideEvaluationHead>
          )}
        </Box>
      </Box>
    </>
  );
};

export { Sessions };
