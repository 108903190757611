/* eslint-disable max-len */
import cx from 'clsx';

// types
// styles
import { useStyles } from './shared/styles';

const RatingHalfOutlined = ({ className, color, onClick }) => {
  const classes = useStyles();
  return (
    <svg
      className={cx({
        [classes.root]: true,
        [className || '']: className,
      })}
      width="22"
      height="19"
      viewBox="0 0 22 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M21 7.31999L20.92 7.06999C20.8914 6.97354 20.8339 6.88822 20.7552 6.8256C20.6765 6.76299 20.5804 6.72611 20.48 6.71999L14.09 6.20999L11.6 0.319988C11.5647 0.224396 11.5004 0.1422 11.4162 0.0848443C11.3319 0.0274882 11.2319 -0.00217362 11.13 -1.17625e-05H10.87C10.767 -0.00136368 10.6661 0.0284818 10.5804 0.0855986C10.4947 0.142715 10.4283 0.224428 10.39 0.319988L7.93999 6.20999L1.54999 6.71999C1.44959 6.72611 1.35352 6.76299 1.2748 6.8256C1.19608 6.88822 1.13854 6.97354 1.10999 7.06999L0.999991 7.31999C0.966774 7.41454 0.963345 7.51699 0.990166 7.61355C1.01699 7.71011 1.07277 7.79612 1.14999 7.85999L5.99999 12L4.55999 18.22C4.53614 18.3172 4.54144 18.4193 4.57523 18.5136C4.60901 18.6078 4.66979 18.6901 4.74999 18.75L4.99999 18.9C5.08544 18.967 5.19144 19.0023 5.29999 19C5.39156 19.0019 5.48178 18.9776 5.55999 18.93L11 15.6L16.47 18.93C16.5482 18.9776 16.6384 19.0019 16.73 19C16.8385 19.0023 16.9445 18.967 17.03 18.9L17.25 18.75C17.3302 18.6901 17.391 18.6078 17.4248 18.5136C17.4585 18.4193 17.4638 18.3172 17.44 18.22L16 12L20.86 7.84999C20.9335 7.78566 20.9861 7.70083 21.011 7.6064C21.036 7.51196 21.0321 7.41222 21 7.31999ZM14 11.38L15.12 16.06L11 13.55V3.40999L12.86 7.86999L17.67 8.24999L14 11.38Z"
        fill={color}
      />
    </svg>
  );
};

export default RatingHalfOutlined;
