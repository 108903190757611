import dayjs from 'dayjs';
import { shortNumber } from '../../../../../utils/CommonUtils';

export const getChartOptions = config => {
  const seriesColors = config.series.map(({ color }) => color);
  return {
    chart: {
      stacked: true,
      toolbar: {
        show: false,
      },
    },
    title: {
      text: '',
    },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: 24,
      },
    },
    colors: seriesColors,
    stroke: {
      width: 1,
      colors: ['#fff'],
    },
    xaxis: {
      categories: config.categories?.map(item => (item.length > 20 ? `${item.slice(0, 20)}...` : item)),
      labels: {
        formatter: val => {
          if (config.isDatetime) {
            return dayjs(val).format('MMM D');
          }
          return val;
        },
        style: {
          fontFamily: 'Manrope',
          fontWeight: 600,
        },
      },
    },
    yaxis: {
      title: {
        text: undefined,
      },
    },
    tooltip: {
      style: {
        fontFamily: 'Manrope',
      },
      custom: ({ series, dataPointIndex, w }) => {
        const title = config.isDatetime
          ? dayjs(w.globals.labels[dataPointIndex]).format('MMM DD, YYYY')
          : config.categories[dataPointIndex];
        const total = series.reduce((tt, item) => +tt + +item[dataPointIndex], 0);
        const half = Math.ceil(series.length / 2);

        const firstHalf = series.slice(0, half);
        const secondHalf = series.slice(half, series.length);
        return `
          <div class="chart-tooltip">
            <div class="date">${config.tooltipTile || title}</div>
            <div class="appointments">${total} total</div>
            <div class="row">
            <div class="col">
            ${firstHalf.reduce(
              (s, item, i) =>
                `${s}<div class="row">
                <div class="row-left">
                  <div class="dot dot-${i}" style="background-color: ${seriesColors[i]}"></div>
                  <div class="title">${config.series[i].name}</div>
                </div>
              <div class="value">${item[dataPointIndex]}</div>
            </div>`,
              '',
            )}
            </div>
            <div class="col">
            ${secondHalf.reduce(
              (s, item, i) =>
                `${s}<div class="row">
                <div class="row-left">
                  <div class="dot dot-${half + parseInt(i, 10)}" style="background-color: ${
                  seriesColors[half + parseInt(i, 10)]
                }"></div>
                  <div class="title">${config.series[half + parseInt(i, 10)].name}</div>
                </div>
              <div class="value">${item[dataPointIndex]}</div>
            </div>`,
              '',
            )}
            </div>
            </div>
            </div>
        </div>
        `;
      },
      y: {
        formatter: shortNumber,
      },
    },
    fill: {
      opacity: 1,
    },
    legend: {
      show: true,
      horizontalAlign: 'left',
    },
  };
};
