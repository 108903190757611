import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Grid } from '@mui/material';
import useEvaluationHead from '../../../../../hooks/useEvaluationHead';
import { conversationActionCreators } from '../../../../../redux/modules/conversation';
import { selectEvaluationContext } from '../../../../../redux/modules/conversation/selectors';
import { useStyles } from '../SessionSignOffV2.styles';
import {
  updateContextForFullPageDCT,
  updateEvaluationContext,
} from '../../../../../services/conversation/conversation.service';
import { EvaluationHelper } from '../../../../../utils/EvaluationHelper';
import { LeaveUnansweredModal } from '../../evaluations-v2/evaluation-modals/LeaveUnansweredModal';
import EvaluationReview from '../../evaluations/evaluation-section-detail/components/evaluation-review';
import { getAppointmentById } from '../../../../../services/appointment/appointment.service';
import EvaluationDetail from '../../evaluations-v2/evaluation-left-section/evaluation-detail';
import { EvaluationRightSection } from '../../evaluations-v2/evaluation-right-section';

const list = new EvaluationHelper();

const AfterAppointment = ({
  evaluation,
  showRiskFactors = true,
  disableCompleteButton,
  completeEvaluation,
  showConfirm,
  showAttemptQuestionModal,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const evaluationContext = useSelector(selectEvaluationContext);
  const { appointmentId } = useParams();
  const [currentEvaluation, setCurrentEvaluation] = useState(evaluation);
  const [currentBlock, setCurrentBlock] = useState(null);
  const [nextInProgress, setNextInProgress] = useState(false);
  const [selectedEvaluationItem, setSelectedEvaluationItem] = useState(evaluation); // useState<IEvaluationItem | null>(null);
  const [showEvaluationReview, setShowEvaluationReview] = useState(false);
  const [, setShowRejectedEvaluation] = useState(false);
  const [, setShowCompletedEvaluation] = useState(false);
  const [showLeaveUnansweredModal, setShowLeaveUnansweredModal] = useState(false);
  const [showLeaveUnansweredDCTModal, setShowLeaveUnansweredDCTModal] = useState(false);
  const [dctRequiredQuestions, setDctRequiredQuestions] = useState([]);
  const [tabType, setTabType] = useState('unanswered');
  const [isHotkeysModalOpen, setIsHotkeysModalOpen] = useState(false);
  const tabWithRiskFactors = [
    { tabKey: 'unanswered', label: 'Unanswered', count: 0 },
    { tabKey: 'risk-factors', label: 'Risk factors', count: 0 },
    { tabKey: 'all', label: 'All questions', count: 0 },
  ];
  const tabWithOutRiskFactors = [
    { tabKey: 'unanswered', label: 'Unanswered', count: 0 },
    { tabKey: 'all', label: 'All questions', count: 0 },
  ];
  const [tabs, setTabs] = useState(showRiskFactors ? tabWithRiskFactors : tabWithOutRiskFactors);
  const { head, processEvaluationData, createFilteredList, updateValues } = useEvaluationHead();
  const [unanswered, setUnanswered] = useState([]);
  const [riskFactors, setRiskFactors] = useState([]);
  const [forProviders, setForProviders] = useState([]);
  const [skippedQuestions, setSkippedQuestions] = useState([]);
  const [appointmentDetails, setAppointmentDetails] = useState(null);
  const [textInputInFocus, setTextInputInFocus] = useState(false);
  const [leftTrigger, setLeftTrigger] = useState(false);
  const [rightTrigger, setRightTrigger] = useState(false);
  const [isRead, setIsRead] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [leftSectionExpanded, setLeftSectionExpanded] = useState(true);
  const [rightSectionExpanded, setRightSectionExpanded] = useState(false);
  const [focusedChildIndex, setFocusedChildIndex] = useState(0);

  useEffect(() => {
    if (evaluation.status === 'REJECTED') {
      setShowRejectedEvaluation(true);
      evaluationSelected(evaluation);
    } else if (evaluation.status === 'COMPLETED') {
      setShowCompletedEvaluation(true);
      evaluationSelected(evaluation);
    } else {
      setShowCompletedEvaluation(false);
      setShowEvaluationReview(false);
      evaluationSelected(evaluation);
    }
  }, []);

  useEffect(() => {
    void getAppointmentById({ appointmentId }).then(response => {
      const appointmentById = response?.data;
      if (appointmentById) {
        setAppointmentDetails(appointmentById);
      }
    });
  }, []);

  useEffect(() => {
    if (currentEvaluation) {
      dispatch(
        conversationActionCreators.fetchEvaluationContext({
          appointmentId,
          evaluationId: currentEvaluation.id,
        }),
      );
    }
    // Cleanup function
    return () => {
      dispatch(
        conversationActionCreators.evaluationContextUpdated({
          evaluationContext: null,
        }),
      );
    };
  }, [currentEvaluation.id, skippedQuestions]);

  const evaluationSelected = e => {
    setCurrentBlock(null);
    setCurrentEvaluation(e);
    setSelectedEvaluationItem(e);
  };

  const setNextBlock = (block, context, forceNext = false) => {
    if (block) {
      if (nextInProgress || forceNext) {
        const next = list.getNextApplicableBlock(block.cbId);
        if (next) {
          setCurrentBlock(next);
          setSelectedIndex(-1);
          setFocusedChildIndex(0);
        } else {
          setShowEvaluationReview(true);
        }
        setNextInProgress(false);
      } else {
        setCurrentBlock(list.getContentBlock(block.cbId));
      }
    } else {
      setCurrentBlock(context);
      setSelectedIndex(-1);
      setFocusedChildIndex(0);
    }
  };

  const setPreviousBlock = block => {
    if (block) {
      const prev = list.getPreviousApplicableBlock(block.cbId);
      if (prev) {
        setShowEvaluationReview(false);
        setCurrentBlock(prev);
        setSelectedIndex(-1);
        setFocusedChildIndex(0);
      }
    }
  };

  const getQuestionSectionsByType = evaluationDtoList => {
    const questionSections = [];
    const dctIds = [];
    evaluationDtoList?.forEach(evaluationDto => {
      if (evaluationDto?.cbType) {
        if (evaluationDto?.cbType === 'dct') {
          if (dctIds?.includes(evaluationDto.dctId)) {
            questionSections.forEach(section => {
              if (evaluationDto?.dctId === section?.dctId) {
                section.items = evaluationDto?.dctContentBlockList?.filter(item => item.cbType !== 'dct');
              }
            });
          } else {
            dctIds.push(evaluationDto?.dctId);
            questionSections.push({
              title: evaluationDto?.text,
              isDct: true,
              score: 0,
              items: evaluationDto?.dctContentBlockList?.filter(item => item.cbType !== 'dct'),
              dctId: evaluationDto?.dctId,
              dctScore: evaluationDto?.dctScore,
              isDCTScoreable: evaluationDto?.isDCTScoreable,
              usePreviousResponses: evaluationDto?.usePreviousResponses,
              fullPage: evaluationDto?.fullPage,
              isAttemptable: evaluationDto?.isAttemptable,
              displayLogics: evaluationDto?.displayLogics,
              cbId: evaluationDto?.cbId,
              responderType: evaluationDto?.responderType,
              description: evaluationDto?.description,
              prompt: evaluationDto?.prompt,
            });
          }
        } else if (evaluationDto?.cbType !== 'dct') {
          questionSections.push({
            title: evaluationDto?.text,
            type: 'CB',
            score: 0,
            isAttemptable: evaluationDto?.isAttemptable,
            displayLogics: evaluationDto?.displayLogics,
            cbId: evaluationDto?.cbId,
            items: [
              {
                ...evaluationDto,
                description: '',
                type: evaluationDto?.cbType,
                isDone: evaluationDto?.isDone || false,
              },
            ],
          });
        }
      }
    });

    return questionSections;
  };

  const openHotkeysModal = e => {
    e.preventDefault();
    e.target.blur();
    setIsHotkeysModalOpen(true);
  };

  const closeHotkeysModal = () => {
    setIsHotkeysModalOpen(false);
  };

  const handleKeyboardShortcuts = e => {
    if (
      showLeaveUnansweredModal ||
      showLeaveUnansweredDCTModal ||
      showConfirm ||
      showAttemptQuestionModal ||
      textInputInFocus ||
      isHotkeysModalOpen
    ) {
      return;
    }
    if (e.key === ' ' && !e.shiftKey) {
      e.preventDefault();
      if (showEvaluationReview) {
        completeEvaluation();
      } else if ((currentBlock.cbType === 'exercise' || currentBlock.cbType === 'education') && isRead) {
        updateValues({ id: currentBlock.cbId, narrative: '', value: ['true'] });
        nextQuestion();
      } else {
        nextQuestion();
      }
    }

    if (e.key === ' ' && e.shiftKey) {
      setPreviousBlock(currentBlock);
    }

    if (e.key === 'Backspace' && e.shiftKey) {
      setLeftSectionExpanded(!leftSectionExpanded);
    }

    if (e.key === 'Enter' && e.shiftKey) {
      setRightSectionExpanded(!rightSectionExpanded);
    }

    if (e.key === 'ArrowUp') {
      setRightSectionExpanded(true);

      if (currentBlock.cbType === 'dct' && currentBlock.fullPage) {
        if (selectedIndex > 0) {
          setSelectedIndex(selectedIndex - 1);
        } else if (focusedChildIndex > 0) {
          // Move focus to the previous child block
          setFocusedChildIndex(focusedChildIndex - 1);
          const prevChild = currentBlock.children.filter(
            block => block.cbType !== 'education' && block.cbType !== 'exercise',
          )[focusedChildIndex - 1];
          setSelectedIndex(prevChild.choices?.length - 1);
        }
        return;
      }

      if (selectedIndex > 0) setSelectedIndex(selectedIndex - 1);
    }

    if (e.key === 'ArrowDown') {
      setRightSectionExpanded(true);

      if (currentBlock.cbType === 'dct' && currentBlock.fullPage) {
        const childBlocks = currentBlock.children.filter(
          block => block.cbType !== 'education' && block.cbType !== 'exercise',
        );

        const child = childBlocks[focusedChildIndex];

        if (selectedIndex < child.choices?.length - 1) {
          setSelectedIndex(selectedIndex + 1);
        } else if (focusedChildIndex < childBlocks.length - 1) {
          // Move focus to the next child block
          setFocusedChildIndex(focusedChildIndex + 1);
          setSelectedIndex(0); // Reset index for new child
        }
        return;
      }

      if (selectedIndex < currentBlock.choices.length - 1) setSelectedIndex(selectedIndex + 1);
    }

    if (e.key === 'ArrowRight') {
      if (selectedIndex > -1 || (currentBlock && currentBlock.cbType === 'rating-scale')) {
        setRightTrigger(prev => !prev);
      }
    }

    if (e.key === 'ArrowLeft') {
      if (selectedIndex > -1 || (currentBlock && currentBlock.cbType === 'rating-scale')) {
        setLeftTrigger(prev => !prev);
      }
    }

    if (e.key === 'K' && e.shiftKey) {
      openHotkeysModal(e);
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyboardShortcuts);
    return () => {
      window.removeEventListener('keydown', handleKeyboardShortcuts);
    };
  }, [
    showLeaveUnansweredModal,
    showLeaveUnansweredDCTModal,
    isHotkeysModalOpen,
    showConfirm,
    showAttemptQuestionModal,
    currentBlock,
    leftSectionExpanded,
    rightSectionExpanded,
    tabType,
    textInputInFocus,
    selectedIndex,
    focusedChildIndex,
    leftTrigger,
    rightTrigger,
  ]);

  useEffect(() => {
    if (evaluationContext && evaluationContext?.evaluationId === evaluation?.id) {
      let unansweredList = unanswered;
      let riskFactorsList = riskFactors;
      let forProvidersList = forProviders;
      const allQuestions = evaluationContext.cbResponseList.flatMap(question => {
        if (question.cbType === 'dct') {
          const cbList = [];
          question.dctContentBlockList.forEach(cb => {
            if (cb.cbType !== 'dct') {
              cbList.push({
                ...cb,
                dctId: question.dctId,
                responderType: question.responderType,
                isAttemptable: !question.isAttemptable ? false : cb.isAttemptable,
              });
            }
          });
          return cbList;
        }
        return question;
      });
      const allUnanswered = allQuestions.every(
        question => question.userResponse === null || question.userResponse?.userResponseList.length === 0,
      );
      const requiredQuestions = allQuestions.filter(
        question =>
          question.cbType !== 'exercise' &&
          question.cbType !== 'education' &&
          (question.userResponse === null || question.userResponse?.userResponseList.length === 0) &&
          question.cbType !== 'dct' &&
          question.required &&
          question.isAttemptable,
      );
      if (requiredQuestions.length > 0) {
        const allRequiredSkipped = requiredQuestions.every(question =>
          skippedQuestions.find(cb => cb.cbId === question.cbId),
        );
        disableCompleteButton(!allRequiredSkipped && !allUnanswered);
      } else {
        disableCompleteButton(false);
      }

      // Setting For providers tab and list when Waiting for AI
      if (evaluationContext?.aiCompletionStatus === 'IN_PROGRESS') {
        if (forProviders.length === 0) {
          forProvidersList = allQuestions.filter(question => question?.responderType === 'PROVIDER');
        } else {
          forProvidersList = forProviders.map(question => {
            return allQuestions.find(qc => qc?.cbId === question?.cbId);
          });
        }
        setForProviders(forProvidersList);
      } else {
        if (unanswered.length === 0) {
          unansweredList = allQuestions.filter(
            question =>
              question.cbType !== 'exercise' &&
              question.cbType !== 'education' &&
              question.isAttemptable &&
              (question.userResponse === null || question.userResponse?.userResponseList.length === 0) &&
              question.cbType !== 'dct',
          );
        } else {
          const unansweredCbIds = unanswered.map(question => question.cbId);

          // Map unanswered to corresponding allQuestions items (change find krna)
          unansweredList = unansweredCbIds
            .map(cbId => allQuestions.find(question => question.cbId === cbId))
            .filter(question => question && question.isAttemptable);

          // Add newly attemptable questions (item find krna)
          const newlyAttemptableQuestions = allQuestions.filter(
            question =>
              question.isAttemptable &&
              (question.userResponse === null || question.userResponse?.userResponseList.length === 0) &&
              question.cbType !== 'exercise' &&
              question.cbType !== 'education' &&
              question.cbType !== 'dct' &&
              !unansweredCbIds.includes(question.cbId),
          );
          newlyAttemptableQuestions.forEach(question => {
            // Find the index of the question in the allQuestions array
            const indexInAllQuestions = allQuestions.findIndex(q => q.cbId === question.cbId);

            // Determine the number of answered questions before it in the allQuestions array
            const answeredBeforeCount = allQuestions
              .slice(0, indexInAllQuestions)
              .filter(
                q =>
                  ((q.userResponse !== null && q.userResponse.userResponseList.length > 0) ||
                    !q.isAttemptable ||
                    question.cbType === 'exercise' ||
                    question.cbType === 'education' ||
                    question.cbType === 'dct') &&
                  !unansweredCbIds.includes(q.cbId),
              ).length;
            // Adjust the original position in the unansweredList
            const adjustedIndexInUnanswered = indexInAllQuestions - answeredBeforeCount;

            // Insert the question back into the unansweredList at the adjusted position
            if (adjustedIndexInUnanswered !== -1 && adjustedIndexInUnanswered <= unansweredList.length) {
              unansweredList.splice(adjustedIndexInUnanswered, 0, question);
            }
          });
        }
        setUnanswered(unansweredList);
      }

      if (riskFactors.length === 0) {
        riskFactorsList = allQuestions.filter(question => {
          if (question.userResponse?.answeredBy === 'AI') {
            const riskFactorChoices = question?.choices?.filter(choice => choice.riskFactor);
            return riskFactorChoices?.some(choice => {
              return !!question.userResponse.userResponseList.includes(choice?.choice);
            });
          }
          return false;
        });
      } else {
        riskFactorsList = riskFactors.map(question => {
          return allQuestions.find(qc => qc?.cbId === question?.cbId);
        });
      }
      setRiskFactors(riskFactorsList);

      const tabsArray = [];
      if (unansweredList.length > 0) {
        tabsArray.push({ tabKey: 'unanswered', label: 'Unanswered', count: unansweredList.length });
      }
      if (riskFactorsList.length > 0) {
        tabsArray.push({ tabKey: 'risk-factors', label: 'Risk factors', count: riskFactorsList.length });
      }
      if (forProvidersList.length > 0) {
        tabsArray.push({ tabKey: 'for-providers', label: 'For Providers', count: forProvidersList.length });
      }
      tabsArray.push({
        tabKey: 'all',
        label: 'All questions',
        count: currentEvaluation?.evaluationStats?.totalCb,
      });
      setTabs(tabsArray);
      if (tabsArray.find(tab => tab.tabKey === tabType)) {
        if (tabType === 'for-providers') {
          createFilteredList(forProvidersList);
        } else if (tabType === 'unanswered') {
          createFilteredList(unansweredList);
        } else if (tabType === 'risk-factors') {
          createFilteredList(riskFactorsList);
        } else {
          processEvaluationData(evaluationContext);
        }
      } else {
        setTabType(tabsArray[0].tabKey);
      }
      if (evaluationContext.evaluationId === selectedEvaluationItem?.id) {
        const cbResponse = getQuestionSectionsByType(evaluationContext.cbResponseList);
        selectedEvaluationItem.sections = cbResponse;
        setSelectedEvaluationItem(selectedEvaluationItem);
      }
    }
  }, [evaluationContext]);

  useEffect(() => {
    if (evaluationContext && evaluationContext?.evaluationId === evaluation?.id) {
      setCurrentBlock(null);
      if (tabType === 'for-providers') {
        createFilteredList(forProviders);
      } else if (tabType === 'unanswered') {
        createFilteredList(unanswered);
      } else if (tabType === 'risk-factors') {
        createFilteredList(riskFactors);
      } else {
        processEvaluationData(evaluationContext);
      }
    }
  }, [tabType]);

  useEffect(() => {
    if (head) {
      setNextBlock(currentBlock, head);
    }
  }, [head]);

  const handleLeaveUnansweredDCT = () => {
    // Append the skippedQuestions without duplicates
    setSkippedQuestions(prevSkipped => {
      const newSkipped = [...prevSkipped];

      dctRequiredQuestions.forEach(child => {
        // Check if the child is already in the skippedQuestions
        const alreadySkipped = newSkipped.some(skipped => skipped.cbId === child.cbId);

        if (!alreadySkipped) {
          newSkipped.push(child);
        }
      });

      return newSkipped;
    });
    setShowLeaveUnansweredDCTModal(false);
    nextQuestion(true);
  };

  const submitAnswer = async () => {
    if (currentBlock && currentBlock.userResponse) {
      const { userResponse } = currentBlock;
      let evaluationResponses = userResponse?.userResponseList;

      if (evaluationResponses && evaluationResponses.length === 1 && evaluationResponses[0] === '')
        evaluationResponses = [];

      const payload = [
        {
          contentBlockId: currentBlock.cbId,
          dctId: currentBlock.dctId,
          evaluationResponses,
          providerNarrative: userResponse?.providerNarrative || '',
        },
      ];
      try {
        const { data } = await updateEvaluationContext(payload, {
          appointmentId,
          evaluationId: currentEvaluation.id,
        });
        if (data.errors) {
          console.warn(data.errors);
        } else {
          dispatch(
            conversationActionCreators.evaluationContextUpdated({
              evaluationContext: data,
              linkedEvaluationNodes: list.getHead(),
            }),
          );
        }
      } catch (e) {
        console.warn(e);
      }
    }
  };

  const submitFullPageDCT = async () => {
    if (currentBlock && currentBlock?.evaluationResponses) {
      const { evaluationResponses } = currentBlock;

      const payload = {
        appointmentId,
        dctId: currentBlock.dctId,
        evaluationId: currentEvaluation.id,
        evaluationResponses,
      };
      try {
        const { data } = await updateContextForFullPageDCT(payload);
        if (data.errors) {
          console.warn(data.errors);
        } else {
          dispatch(
            conversationActionCreators.evaluationContextUpdated({
              evaluationContext: data,
              linkedEvaluationNodes: list.getHead(),
            }),
          );
        }
      } catch (e) {
        console.warn(e);
      }
    }
  };

  const respondAndGoNext = (fullPage = false) => {
    setNextInProgress(true);
    if (fullPage) void submitFullPageDCT();
    else void submitAnswer();
  };

  const nextQuestion = (leaveUnanswered = false) => {
    const isRequiredAndUnanswered = block => {
      const skipTypes = ['exercise', 'education'];
      return (
        block.required &&
        (!block.userResponse || block.userResponse?.userResponseList.length === 0) &&
        !skipTypes.includes(block.cbType)
      );
    };

    if (!currentBlock) return;

    if (currentBlock.cbType !== 'dct') {
      if (isRequiredAndUnanswered(currentBlock) && !leaveUnanswered) setShowLeaveUnansweredModal(true);
      else if (currentBlock.userResponse) respondAndGoNext();
      else setNextBlock(currentBlock, head, true);
      return;
    }

    if (currentBlock.cbType === 'dct' && currentBlock.fullPage) {
      const unansweredRequiredChildren = currentBlock.children.filter(isRequiredAndUnanswered);

      if (unansweredRequiredChildren.length > 0 && !leaveUnanswered) {
        setShowLeaveUnansweredDCTModal(true);
        setDctRequiredQuestions(unansweredRequiredChildren);
      } else if (currentBlock.evaluationResponses.length > 0) respondAndGoNext(true);
      else setNextBlock(currentBlock, head, true);
      return;
    }

    if (currentBlock.next) setCurrentBlock(currentBlock.next);
    else setShowEvaluationReview(true);
  };

  const changeTab = tab => setTabType(tab);

  return (
    <>
      {showLeaveUnansweredModal && (
        <LeaveUnansweredModal
          open={showLeaveUnansweredModal}
          onClose={() => setShowLeaveUnansweredModal(false)}
          onConfirm={() => {
            setSkippedQuestions([...skippedQuestions, currentBlock]);
            setShowLeaveUnansweredModal(false);
            nextQuestion(true);
          }}
        />
      )}
      {showLeaveUnansweredDCTModal && (
        <LeaveUnansweredModal
          dct
          open={showLeaveUnansweredDCTModal}
          onClose={() => setShowLeaveUnansweredDCTModal(false)}
          onConfirm={handleLeaveUnansweredDCT}
        />
      )}
      <Grid container height="calc(100vh - 80px)">
        <Grid
          item
          xs={12}
          sm={6}
          className={classes.outerCol}
          height="calc(100vh - 79px)"
          display="flex"
          flexDirection="column"
        >
          <EvaluationDetail
            tabs={tabs}
            tabType={tabType}
            setTabType={changeTab}
            listHelper={list}
            currentBlock={currentBlock}
            currentEvaluation={currentEvaluation}
            closeHotkeysModal={closeHotkeysModal}
            openHotkeysModal={e => openHotkeysModal(e)}
            isHotkeysModalOpen={isHotkeysModalOpen}
            setCurrentBlock={block => {
              setSelectedIndex(-1);
              setFocusedChildIndex(0);
              setCurrentBlock(block);
              setShowEvaluationReview(false);
            }}
            appointmentId={appointmentId}
            evaluationContext={evaluationContext}
            isSignOffFlow
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          {showEvaluationReview ? (
            <EvaluationReview
              onClickComplete={completeEvaluation}
              onClickPrevious={() => {
                setShowEvaluationReview(false);
              }}
              selectedEvaluation={selectedEvaluationItem}
            />
          ) : (
            currentBlock && (
              <EvaluationRightSection
                setInputFocused={setTextInputInFocus}
                selectedIndex={selectedIndex}
                childIndex={focusedChildIndex}
                rightTrigger={rightTrigger}
                leftTrigger={leftTrigger}
                currentBlock={currentBlock}
                isDisablePrevious={currentBlock?.isFirst}
                appointment={appointmentDetails}
                listHelper={list}
                currentEvaluation={currentEvaluation}
                setShowEvaluationReview={setShowEvaluationReview}
                setCurrentBlock={block => setCurrentBlock(block)}
                setIsRead={v => setIsRead(v)}
                isRead={isRead}
                onClickNext={() => {
                  nextQuestion();
                }}
                onClickPrevious={() => {
                  setPreviousBlock(currentBlock);
                }}
                skipNonRequiredCB={() => {
                  console.log('Skip clicked');
                }}
                isSignOffFlow
              />
            )
          )}
        </Grid>
      </Grid>
    </>
  );
};
export default AfterAppointment;
