import { makeStyles } from '@mui/styles';

// colors
import { colors } from '../../../../colors';

export const useStyles = makeStyles({
  wrapper: props => ({
    display: 'flex',
    flexDirection: 'column',
    width: props.expanded ? '360px': '72px',
    borderRight: `1px solid ${colors.neutral200}`,
    transition: 'width 0.25s',
  }),
  arrowBtn: {
    minWidth: 'auto',
    '& svg': {
      width: 16,
      height: 16,
      '& path': {
        fill: colors.primary500,
      },
    },
  },
  arrowBtnRotate: {
    transform: 'rotate(-180deg)',
  },
  collapseArea: props => ({
    padding: `16px ${props.expanded ? '16px' : '24px'}`,
    width: '100%',
    display: 'flex',
    justifyContent: props.expanded ? 'flex-end' : 'center',
  }),
  collapseButton: {
    width: '24px !important',
    height: '40px !important',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  stepsContainer: {
    flex: 1,
    overflowY: 'auto',
  },
  mainSection: ({ expanded }) => ({
    padding: '8px 24px',
    display: 'flex',
    flexDirection: 'row',
    gap: '12px',
    cursor: 'pointer',
    justifyContent: expanded ? 'flex-start' : 'center',
  }),
  subsection: ({ expanded }) => ({
    padding: expanded ? '8px 24px 8px 60px' : '8px 24px',
    textAlign: expanded ? 'left' : 'center',
    margin: 0,
    color: colors.neutral700,
    fontFamily: 'Manrope',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '20px',
    cursor: 'pointer',
  }),
  dctName: ({ expanded }) => ({
    padding: expanded ? '8px 24px 8px 68px' : '8px 24px',
    textAlign: expanded ? 'left' : 'center',
    margin: 0,
    color: colors.neutral700,
    fontFamily: 'Manrope',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '20px',
    cursor: 'pointer',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  }),
  subsectionSelected: {
    backgroundColor: colors.primary50,
    fontWeight: '600 !important',
    color: `${colors.primary600} !important`,
  },
  sectionCount: {
    color: colors.neutral500,
    textAlign: 'center',
    fontSize: '14px',
    borderRadius: '50%',
    border: `2px solid ${colors.neutral500}`,
    fontWeight: 700,
    lineHeight: '20px',
    height: '24px',
    width: '24px',
    minWidth: '24px',
    minHeight: '24px',
    display: 'inline-block',
  },
  sectionSelected: {
    border: `2px solid ${colors.primary500}`,
    color: colors.primary500,
  },
  sectionCompleted: {
    border: `2px solid ${colors.success500}`,
    color: colors.success500,
  },
  sectionTitle: ({ expanded }) => ({
    color: colors.neutral900,
    fontFamily: 'Manrope',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '24px',
  }),
  memberActionsWrapper: props => ({
    padding: `24px 16px`,
    display: 'flex',
    flexDirection: props.expanded ? 'row' : 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '16px',
  }),
  actionBtn: {
    padding: 0,
    borderRadius: '4px',
    minWidth: '40px !important',
    width: '40px !important',
    height: '40px !important',
    '& svg': {
      width: '16px',
      height: '16px',
    }
  },
  profileBtn: {
    border: `1px solid ${colors.primary500}`,
    color: colors.primary500,
    '& svg': {
      '& path': {
        fill: colors.primary500,
      },
    },
    '&:hover': {
      backgroundColor: colors.primary100,
    }
  },
  amaBtn: {
    border: `1px solid ${colors.secondary500}`,
    color: colors.secondary500,
    '& svg': {
      '& path': {
        fill: colors.secondary500,
      },
    },
    '&:hover': {
      backgroundColor: colors.secondary100,
    }
  },
  apptBtn: {
    border: `1px solid ${colors.green500}`,
    color: colors.green500,
    '& svg': {
      '& path': {
        fill: colors.green500,
      },
    },
    '&:hover': {
      backgroundColor: colors.green50,
    }
  },
  chatBtn: {
    border: `1px solid ${colors.orange500}`,
    color: colors.warning500,
    '& svg': {
      '& path': {
        fill: colors.warning500,
      },
    },

    '&:hover': {
      backgroundColor: colors.warning100,
    }
  },
  evaluationContainer: {
    display: 'flex',
    flexDirection: 'column',
  }
});
