import { FC, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'clsx';

// components
import {
  fontWeight,
  Heading,
  headingLevel,
  Text,
  textLevel,
} from '../../../../../packages/ui/atoms/typography';
import { Drawer, drawerPosition, drawerType } from '../../../../../packages/ui/organisms/drawer';
import { Link } from '../../../../../packages/ui/atoms/link';
import { colors } from '../../../../../packages/colors';
import { Input, inputSize } from '../../../../../packages/ui/atoms/input';
import { Icons } from '../../../../../packages/icons';

import { getProfile } from '../../../../../redux/modules/profile/selectors';
import { getAuth } from '../../../../../redux/modules/auth/selectors';
import { IPatient } from '../../../../../redux/modules/profile/types';
import { profileActionCreators } from '../../../../../redux/modules/profile/actions';
import AssignmentsChart from '../../../../admin/plan-items/components/assignments-chart';

import { getRandomData } from '../../../../../utils/CommonUtils';
import { useStyles } from '../../MemberDetail.styles';

const Wallet = ({ memberId }) => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const classes = useStyles({});
  const { selectedPatient = {}, walletBalance } = useSelector(getProfile);
  const { isAdmin } = useSelector(getAuth);
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    memberId: '',
    amount: '',
  });

  useEffect(() => {
    dispatch(profileActionCreators.setWalletBalance(selectedPatient.fundsInWallet));
    setFormData({
      memberId,
      amount: '',
    });
    dispatch(profileActionCreators.getWalletBalance(memberId));
  }, [selectedPatient]);

  const onChangeNumberField = field => e => {
    if (e.target.value && !Number(e.target.value)) {
      return;
    }

    if (e.target) {
      setFormData(old => ({ ...old, [field]: e.target?.value }));
    }
  };

  const handleOpenDrawer = e => {
    e.preventDefault();
    setOpenDrawer(true);
  };
  const handleCloseDrawer = () => setOpenDrawer(false);

  const handleOnSubmit = () => {
    dispatch(
      profileActionCreators.updateWalletBalance({
        memberId: formData.memberId,
        amount: formData.amount,
      }),
    );

    setFormData({
      memberId,
      amount: '',
    });
    handleCloseDrawer();
  };

  const textDecorationLine = isAdmin ? undefined : 'line-through';

  return (
    <div className={classes.card}>
      <div className={classes.cardTopSection}>
        <Heading level={headingLevel.S} weight={fontWeight.BOLD}>
          Wallet
        </Heading>
        <Link onClick={handleOpenDrawer}>Add funds</Link>
      </div>
      <div>
        <div className={classes.item}>
          <Text className={classes.textDark} level={textLevel.M} weight={fontWeight.MEDIUM}>
            ${walletBalance} available
          </Text>
          <Text className={classes.textLight} level={textLevel.S} weight={fontWeight.SEMI_BOLD}>
            <span style={{ textDecorationLine, display: 'none' }}>$ added total</span>
          </Text>
        </div>
      </div>
      <Drawer
        variant={drawerType.FORM}
        position={drawerPosition.RIGHT}
        open={openDrawer}
        onClose={handleCloseDrawer}
        title="Add funds"
        submitBtnTitle="Add funds"
        onSubmit={handleOnSubmit}
      >
        <div className={classes.fundsDetailWrapper}>
          <div className={classes.fundDetail} style={{ flexDirection: 'column', gap: 12 }}>
            <Heading className="fundValue big-val" level={headingLevel.M} weight={fontWeight.MEDIUM}>
              ${walletBalance}
            </Heading>
            <Text className="fundTitle big-title" level={textLevel.XS} weight={fontWeight.EXTRA_BOLD}>
              current balance
            </Text>
          </div>
          <div className={classes.fundDetailItem} style={{ display: 'none' }}>
            <div className={classes.fundDetail}>
              <Heading className="fundValue added" level={headingLevel.M} weight={fontWeight.MEDIUM}>
                <span style={{ textDecorationLine }}>$50</span>
              </Heading>
              <Text className="fundTitle" level={textLevel.XS} weight={fontWeight.EXTRA_BOLD}>
                Added total
              </Text>
            </div>
            <div className={classes.fundDetail}>
              <Heading className="fundValue spent" level={headingLevel.M} weight={fontWeight.MEDIUM}>
                <span style={{ textDecorationLine }}>$800</span>
              </Heading>
              <Text className="fundTitle" level={textLevel.XS} weight={fontWeight.EXTRA_BOLD}>
                Spent total
              </Text>
            </div>
            <div className={classes.fundDetail}>
              <Heading className="fundValue contributed" level={headingLevel.M} weight={fontWeight.MEDIUM}>
                <span style={{ textDecorationLine }}>$100</span>
              </Heading>
              <Text className="fundTitle" level={textLevel.XS} weight={fontWeight.EXTRA_BOLD}>
                Contributed total
              </Text>
            </div>
          </div>
        </div>
        <div className={classes.chartWrap} style={{ display: 'none' }}>
          <AssignmentsChart
            showHeader={false}
            className="fund-chart"
            chart={{
              series: [
                { name: 'Added', color: colors.success500, data: getRandomData(5) },
                { name: 'Spent', color: colors.secondary500, data: getRandomData(5) },
                { name: 'Contributed', color: colors.primary500, data: getRandomData(5) },
              ],
              tooltipConfig: {
                isShowTotal: false,
                title: 'Funds',
                syntax: '$',
              },
            }}
          />
        </div>
        <div
          className={cx({
            [classes.inputsContainer]: true,
            [classes.newFundWrapper]: true,
          })}
        >
          <div className={classes.inputWrapper}>
            <label htmlFor="new_fund">
              <Text level={textLevel.S} weight={fontWeight.BOLD}>
                How much would you like to add?
              </Text>
            </label>
            <Input
              startAdornmentClassName={classes.dollarSignAdornment}
              endAdornment={<Icons glyph="question-outlined" color={colors.primary500} />}
              startAdornment={
                <Text
                  className={classes.dollarSignAdornmentText}
                  level={textLevel.S}
                  weight={fontWeight.SEMI_BOLD}
                >
                  $
                </Text>
              }
              id="new_fund"
              size={inputSize.M}
              placeholder="Enter amount"
              value={formData.amount || ''}
              onChange={onChangeNumberField('amount')}
            />
          </div>
          <div className={classes.inputWrapper} style={{ display: 'none' }}>
            <label htmlFor="reason__of__adding">
              <Text level={textLevel.S} weight={fontWeight.BOLD}>
                <span style={{ textDecorationLine }}> Reason of adding </span>
              </Text>
            </label>
            <Input disabled id="reason__of__adding" size={inputSize.M} placeholder="Enter the reason" />
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default Wallet;
