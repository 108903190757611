/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
import clsx from 'clsx';
import { Box, Modal } from '@mui/material';

import { Heading, Text } from '../../atoms/typography';
import { Icons } from '../../../icons';
import { Button } from '../../atoms/button';
import { colors } from '../../../colors';

import { useStyles } from './ConfirmUsePreviousModal.styles';

const ConfirmUsePreviousModal = ({ open, onClose, previousResponseFlags, onConfirm }) => {
  const classes = useStyles();

  const getSubtext = () => {
    const { allNormal, allRisky } = previousResponseFlags;
    if (allRisky) {
      return "All previous responses are flagged as risky. Are you sure you want to use all previous responses?";
    }
    if (!allNormal && !allRisky) {
      return "Some previous responses are flagged as risky. Are you sure you want to use all previous responses?";
    }
    return null;
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box className={classes.modal}>
        <Box className={classes.modalContent}>
          <Box className={classes.closeIconWrap}>
            <Icons glyph="info" className={classes.closeIcon} />
          </Box>
          <Box className={classes.inner}>
            <Box className={classes.content}>
              <Box>
                <Heading className={classes.title}>
                  All previous responses are not in normal range!
                </Heading>
                <Icons glyph="close" onClick={onClose} className={classes.closeBtn} color={colors.neutral400} />
              </Box>
              <Box>
                <Text className={classes.label}>{getSubtext()}</Text>
              </Box>
            </Box>
            <Box display="flex" justifyContent="space-between" alignItems="center" gap="16px">
              <Button className={clsx(classes.btn, classes.cancelBtn)} onClick={onClose}>
                Do not use
              </Button>
              <Button onClick={onConfirm} className={clsx(classes.btn, classes.okBtn)}>
                Confirm
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export { ConfirmUsePreviousModal };
