import React, { ReactNode } from 'react';
import { Box } from '@mui/material';
import clsx from 'clsx';

import { fontWeight, Heading, Text } from '../../../../../packages/ui/atoms/typography';
import { btnType, Button } from '../../../../../packages/ui/atoms/button';
import { Icons } from '../../../../../packages/icons';

import { useStyles } from './EmptyState.styles';

const EmptyState = ({
  title,
  subtitle,
  btnClick,
  btnLabel,
  btnVariant = btnType.SECONDARY,
  glyph,
  glyphOrientation = 'square',
  isEmptyWithLottie = false,
  lottieSize = 128,
}) => {
  const classes = useStyles({ lottieSize });
  const isLandscape = glyphOrientation === 'landscape';
  const isPortrait = glyphOrientation === 'portrait';
  const isSquare = glyphOrientation === 'square';
  return (
    <Box className={classes.card}>
      {glyph && (
        <Icons
          glyph={glyph}
          className={clsx({
            [classes.alifie_landscape]: isLandscape,
            [classes.alfie_portrait]: isPortrait,
            [classes.alfie]: isSquare,
            [classes.lottieSize]: isEmptyWithLottie,
          })}
        />
      )}
      <Heading className={classes.title} weight={fontWeight.BOLD}>
        {title}
      </Heading>
      <Text className={classes.subtitle} weight={fontWeight.MEDIUM}>
        {subtitle}
      </Text>
      {btnLabel && (
        <Button onClick={btnClick} variant={btnVariant} className={classes.btn}>
          {btnLabel}
        </Button>
      )}
    </Box>
  );
};

export { EmptyState };
