import axios from 'axios';
import { ApiEndpoints } from '../../constants/ApiEndpoints';
import { baseRequest } from '../../utils/HttpClient';
import getConfig from '../../config';

export const createCalendar = (bodyRequest, queryParams) => {
  return baseRequest(ApiEndpoints.ADD_GOOGLE_CALENDER_SETTINGS, bodyRequest, null, queryParams);
};

export const getGoogleCalendarEvents = bodyRequest => {
  return baseRequest(ApiEndpoints.GET_ALL_GOOGLE_CALENDAR_EVENTS, bodyRequest, null, null);
};
export const deleteGoogleCalendarEvents = bodyRequest => {
  return baseRequest(ApiEndpoints.DELETE_CALENDAR_EVENTS, bodyRequest, null, null);
};

export const getCalendars = queryParams => {
  return baseRequest(ApiEndpoints.GET_GOOGLE_CALENDER_SETTINGS, null, null, queryParams);
};

export const updateCalendar = (bodyRequest, queryParams) => {
  return baseRequest(ApiEndpoints.UPDATE_GOOGLE_CALENDER_SETTINGS, bodyRequest, null, queryParams);
};

export const deleteCalendar = queryParams => {
  return baseRequest(ApiEndpoints.DELETE_GOOGLE_CALENDER_SETTINGS, null, null, queryParams);
};

export const alllowCalendarAccess = bodyRequest => {
  return baseRequest(ApiEndpoints.ALLOW_ACCESS_GOOGLE_CALENDAR, bodyRequest, null, null);
};
export const syncConfidantToGoogle = bodyRequest => {
  return baseRequest(ApiEndpoints.SYNC_CONFIDANT_TO_GOOGLE_CALENDAR, bodyRequest, null, null);
};

export const syncGoogleToConfidant = bodyRequest => {
  return baseRequest(ApiEndpoints.SYNC_GOOGLE_TO_CONFIDANT, bodyRequest, null, null);
};

export const getAllCalendarsFromAccessToken = requestBody => {
  return baseRequest(ApiEndpoints.GET_ALL_CALENDARS_WITH_ACCESS_TOKEN, requestBody, null, null);
};

export const sendSupportEmail = bodyRequest => {
  return baseRequest(ApiEndpoints.SEND_SUPPORT_EMAIL, bodyRequest, null, null);
};

export const addProvider = bodyRequest => {
  return baseRequest(ApiEndpoints.ADD_PROVIDER_NEW, bodyRequest, null, null, true);
};

export const getTopProviders = queryParams => {
  return baseRequest(ApiEndpoints.GET_TOP_PROVIDERS, null, null, queryParams);
};

export const createToDo = bodyRequest => {
  return baseRequest(ApiEndpoints.CREATE_TODO, bodyRequest, null, null);
};

export const updateTodo = bodyRequest => {
  return baseRequest(ApiEndpoints.UPDATE_TODO, bodyRequest, null, null);
};

export const shareAlfieValidation = bodyRequest => {
  return baseRequest(ApiEndpoints.SHARE_ALFIE_VALIDATION, bodyRequest, null, null);
};

export const listTodos = payload => {
  return baseRequest(ApiEndpoints.LIST_TODOS, null, null, payload);
};
export const completeTodos = bodyRequest => {
  return baseRequest(ApiEndpoints.COMPLETE_TODOS, bodyRequest, null, null);
};

export const addMemberOrMembersInBulk = payload => {
  return axios.post(`${getConfig.api.baseUrl}/profile/member`, payload, {
    headers: {
      Authorization: localStorage.getItem('authorizationToken'),
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const getElkProfileElementAssignmentChatBotList = body => {
  return baseRequest(ApiEndpoints.GET_ELK_PROFILE_ELEMENT_ASSIGNMENT_CHATBOT, body, null, null, false, false);
};

export const getElkProfileElementAssignmentEvaluationList = body => {
  return baseRequest(
    ApiEndpoints.GET_ELK_PROFILE_ELEMENT_ASSIGNMENT_EVALUATION,
    body,
    null,
    null,
    false,
    false,
  );
};

export const getElkProfileElementDctConvoList = body => {
  return baseRequest(ApiEndpoints.GET_ELK_PROFILE_ELEMENT_DCT_CONVO, body, null, null, false, false);
};

export const getElkProfileElementDctEvaluationList = body => {
  return baseRequest(ApiEndpoints.GET_ELK_PROFILE_ELEMENT_DCT_EVALUATION, body, null, null, false, false);
};

export const getElkProfileElementPriorityDataDomainList = body => {
  return baseRequest(
    ApiEndpoints.GET_ELK_PROFILE_ELEMENT_PRIORITY_DATA_DOMAIN,
    body,
    null,
    null,
    false,
    false,
  );
};

export const getTodosStats = payload => {
  return baseRequest(ApiEndpoints.TODO_STATS, null, payload, null);
};

export const getUsersSummary = requestBody => {
  return baseRequest(ApiEndpoints.USERS_SUMMARY, requestBody);
};

export const addUpdateDefaultCareNavigator = requestBody => {
  return baseRequest(ApiEndpoints.ADD_UPDATE_DEFAUTL_CARE_NAVIGATOR, requestBody);
};

export const getDefaultCareNavigator = queryParams => {
  return baseRequest(ApiEndpoints.GET_DEFAUTL_CARE_NAVIGATOR, null, null, queryParams);
};

export const getCareNavigatorAliases = queryParams => {
  return baseRequest(ApiEndpoints.GET_CARE_NAVIGATOR_ALIAS_ACCOUNTS, null, null, queryParams);
};

export const deleteCareTeamConnection = (param, queryParams) => {
  return baseRequest(ApiEndpoints.DELETE_MEMBER_CONNECTION, null, param, queryParams, true);
};
