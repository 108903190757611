/* eslint-disable no-nested-ternary */
import cx from 'clsx';
import React from 'react';
import dayjs from 'dayjs';

import {
  AppointmentStatus,
  AppointmentStatusBagde,
  AppointmentStatusDone,
  AppointmentStatusPending,
  AppointmentStatusText,
  SignOffStatus,
} from '../../../constants/CommonConstants';
import { btnType, Button } from '../../atoms/button';
import { Rating } from '../../atoms/rating';
import { iconBtnPosition, IconButton } from '../../molecules/icon-button';
import { Badge, badgeStyle, badgeType } from '../../atoms/badge';
import { fontWeight, Heading, headingLevel, Text, textLevel } from '../../atoms/typography';
import { NoteCardBottom } from '../note-card/NoteCardBottom';

// styles
import { useStyles } from './AppointmentCard.styles';
// types

const AppointmentCard = ({
  className = '',
  userType = 'member',
  appointment,
  isNextAppointment = false,
  onViewClick = undefined,
  onGotoSignOff = undefined,
  onStartSessionClick = undefined,
  onProfileClick = undefined,
  timezone = undefined,
  isAdmin = undefined,
  appointmentSection = '',
  showProvider = false,
  isRecurring = false,
}) => {
  const classes = useStyles();
  const isAdmin1 = isAdmin !== undefined ? isAdmin : false;
  const handleViewClick = () => {
    if (appointment.signOffStatus === SignOffStatus.DRAFTED) {
      onGotoSignOff(appointment);
    } else {
      onViewClick(appointment);
    }
  };

  const handleStartSessionClick = () => {
    onStartSessionClick(appointment);
  };

  // check if the appointment is today
  const appointmentDate = dayjs(appointment.startTime);
  const currentDate = new Date();
  const appointmentEndDate = new Date(appointment?.endTime);
  const isToday = appointmentDate.isSame(dayjs(), 'day');
  const isPast = appointmentDate.isBefore(dayjs());
  const isPending = AppointmentStatusPending.includes(appointment.status);
  const isDone = AppointmentStatusDone.includes(appointment.status);
  const isBookToday = isToday && !isPending && !isDone;
  const todayText = isToday ? 'Today' : 'Scheduled';
  const isWaitingSignOff =
    appointment.requireSupervisorSignOff &&
    !!appointment.signOffStatus &&
    appointment.signOffStatus !== SignOffStatus.APPROVED;
  let finalStatus =
    // eslint-disable-next-line no-nested-ternary
    !isDone && appointment.status !== AppointmentStatus.BOOKED && isWaitingSignOff
      ? appointment.signOffStatus === SignOffStatus.REVIEW
        ? 'WAITING_REVIEW'
        : 'SIGN_OFF'
      : appointment.status;

  if (appointment.signOffStatus === SignOffStatus.DRAFTED) {
    finalStatus = 'SIGN_OFF';
  }
  const { status, signOffStatus, patientStatus, practitionerStatus, statusChangingAuthority } =
    appointment || {};
  let statusText = AppointmentStatusText[status];
  if (status === AppointmentStatus.FULFILLED && signOffStatus === SignOffStatus.DRAFTED) {
    statusText = 'Waiting for Sign off';
  }
  if (status === AppointmentStatus.PROPOSED || status === AppointmentStatus.PENDING) {
    if (patientStatus === AppointmentStatus.NEEDS_ACTION) {
      statusText = 'Waiting on Member';
    } else if (practitionerStatus === AppointmentStatus.NEEDS_ACTION) {
      statusText = 'Waiting on Provider';
    }
  } else if (status === AppointmentStatus.CANCELLED) {
    if (patientStatus === AppointmentStatus.DECLINED) {
      statusText = 'Cancelled - M';
    } else if (practitionerStatus === AppointmentStatus.DECLINED) {
      statusText = 'Cancelled - P';
    }
  }
  if (status === AppointmentStatus.NO_SHOW) {
    if (statusChangingAuthority === 'PATIENT') {
      statusText = 'No Show - M';
    } else if (statusChangingAuthority === 'PRACTITIONER') {
      statusText = 'No Show - P';
    }
  }
  if (status === AppointmentStatus.BOOKED) {
    statusText = isToday ? 'Today' : 'Scheduled';
  }

  return (
    <div className={cx(classes.root, className)}>
      <div className={classes.cardTop}>
        <div className={classes.topLeft}>
          <div className={classes.titleWrap}>
            <Heading className={classes.title} level={headingLevel.S} weight={fontWeight.BOLD}>
              {appointment?.serviceName}
            </Heading>
            {isPast || isDone || isPending ? (
              <Badge
                style={
                  appointment.signOffStatus === 'REVIEW' &&
                  appointment.status === 'FULFILLED' &&
                  appointmentSection !== 'past'
                    ? AppointmentStatusBagde.SIGN_OFF
                    : AppointmentStatusBagde[finalStatus]
                }
                variant={badgeType.FILLED}
                className={classes.badge}
              >
                {appointment.signOffStatus === 'REVIEW' &&
                appointment.status === 'FULFILLED' &&
                appointmentSection === 'pending' ? (
                  'Waiting for Supervisor Sign Off'
                ) : statusText?.toLowerCase()?.includes('cancel') ? (
                  <span className={classes.cancelledStatusText}>{statusText}</span>
                ) : (
                  statusText
                )}
              </Badge>
            ) : (
              <Badge
                style={isToday || isNextAppointment ? badgeStyle.CRITICAL : badgeStyle.LOW}
                variant={badgeType.FILLED}
                className={cx(classes.badge, { [classes.badgeToday]: isBookToday })}
              >
                {isNextAppointment ? 'Next appointment' : todayText}
              </Badge>
            )}
          </div>

          <Text className={classes.subTitle} level={textLevel.S}>
            {appointment?.serviceDuration} minutes session
          </Text>
          {appointment.feedback?.rating && (
            <Rating value={appointment.feedback.rating} className={classes.rating} />
          )}
        </div>
        <div className={classes.topRight}>
          {onViewClick && (
            <IconButton
              iconPosition={iconBtnPosition.RIGHT}
              variant={btnType.TEXT}
              className={classes.btn}
              icon="arrow-right"
              onClick={handleViewClick}
            >
              {!isDone && isWaitingSignOff && isAdmin1
                ? 'Reschedule Appointment'
                : appointment.signOffStatus === SignOffStatus.DRAFTED && isAdmin1
                ? 'Sign off on Note'
                : appointment.status === AppointmentStatus.FULFILLED && isAdmin1
                ? 'View appointment'
                : 'View details'}
            </IconButton>
          )}
          {(appointment.status === AppointmentStatus.NEEDS_ACTION ||
            appointment.status === AppointmentStatus.BOOKED) &&
            !isWaitingSignOff &&
            !isAdmin1 &&
            onStartSessionClick &&
            currentDate <= appointmentEndDate && (
              <Button variant={btnType.PRIMARY} className={classes.btn} onClick={handleStartSessionClick}>
                {appointment.status === AppointmentStatus.NEEDS_ACTION ? 'Accept' : 'Start session'}
              </Button>
            )}
        </div>
      </div>
      <NoteCardBottom
        isAppointment
        onProfileClick={onProfileClick}
        timezone={timezone}
        isRecurring={isRecurring}
        type={appointment.providerRole ? 'provider' : userType}
        note={{
          createdBy: {
            designation: (showProvider && appointment?.practitionerDesignation) || appointment?.providerRole,
            nickName: `${appointment?.practitionerFirstName || ''} ${
              appointment?.practitionerLastName || ''
            }`,
            fullName: appointment.patientUuid,
            profileImage: appointment?.practitionerImage || appointment?.providerImage,
            providerId: showProvider
              ? appointment?.practitionerId || appointment?.providerId
              : appointment?.patientId,
          },
          createdAt: appointmentDate.toISOString(),
          timeSpent: appointment?.serviceDuration,
        }}
      />
    </div>
  );
};

export { AppointmentCard };
