// colors
import { colors } from '../../../../../packages/colors';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  card: {
    padding: '16px 24px',
    borderRadius: 8,
    backgroundColor: colors.white,
    boxShadow:
      '0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04)',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
  },
  cardContent: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    cursor: 'pointer',
    width: '-webkit-fill-available',
    gap: '2%',
  },
  iconWrapper: {
    height: 48,
    width: 48,
    borderRadius: 4,
    border: '1px solid',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center  ',
    backgroundColor: colors.primary50,
    borderColor: colors.primary100,
  },
  statusBadge: {
    display: 'flex',
    gap: '24px',
    justifyContent: 'flex-end',
    alignItems: 'center',
    position: 'absolute',
    right: '8%',
  },
  title: {
    fontSize: 16,
    lineHeight: '24px',
    margin: 0,
  },
  subtitle: {
    fontSize: 14,
    lineHeight: '20px',
    color: colors.neutral600,
  },
  menu: {
    float: 'right',
    right: 20,
    '& svg': {
      width: 16,
    },
  },
  menuItemsWrapper: {
    '& ul': {
      padding: 0,
    },
    '& ul li': {
      padding: '10px 16px',
    },
  },
  // menu: {
  //   '& svg': {
  //     width: 14,
  //     marginLeft: 0,
  //   },
  // },
  // menuActive: {},
  // menuItemsWrapper: {},
  badge: {
    borderRadius: 20,
    height: 24,
    lineHeight: '20px',
    width: 'max-content',
  },
  badgeCompleted: {
    borderRadius: 20,
    height: 24,
    lineHeight: '20px',
    width: 'max-content',
    color: colors.white,
    backgroundColor: colors.success500,
    border: `1px solid ${colors.success500}`,
  },
  badgeIncomplete: {
    borderRadius: 20,
    height: 24,
    lineHeight: '20px',
    width: 'max-content',
    color: colors.white,
    backgroundColor: colors.primary500,
    border: `1px solid ${colors.primary500}`,
  },
  progress: {
    height: 8,
    width: 174,
  },
  cardText: {
    maxWidth: '65%',
  },
  cardText2: {
    maxWidth: '80%',
  },
});
