import { FC } from 'react';
import { Box } from '@mui/material';

import { Heading, Text } from '../../../../../packages/ui/atoms/typography';

import cx from 'clsx';
import { Icons } from '../../../../../packages/icons';

import { useStyles } from './KeyboardShortcutsModal.styles';

const KeyboardShortcutItem = ({ text, buttonTexts, shortcutType }) => {
  const classes = useStyles();
  return (
    <Box className={classes.shortcutItem}>
      <Box>
        <Text className={classes.itemText}>{text}</Text>
      </Box>
      <Box>
        <Box className={classes.shortcutButtonsBox}>
          {buttonTexts.map((item, index) => (
            <Box key={index} className={classes.shortcutButtonsBox}>
              <Box className={classes.shortcutButton}>
                {item.type === 'text' ? (
                  <Heading className={classes.itemButtonText}>{item.name}</Heading>
                ) : (
                  <Icons className={classes.shortcutButtonIcon} glyph={item.name} />
                )}
              </Box>
              {buttonTexts.length > 1 && index < buttonTexts.length - 1 && (
                <Box>
                  <Text
                    className={cx({
                      [classes.generalBtnComboText]: shortcutType === 'GENERAL',
                      [classes.navigationBtnComboText]: shortcutType === 'NAVIGATION',
                    })}
                  >
                    {shortcutType === 'GENERAL' ? '+' : 'or'}
                  </Text>
                </Box>
              )}
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};
export { KeyboardShortcutItem };
