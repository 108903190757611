import { Box, Stack } from '@mui/material';
import { Badge, badgeStyle, badgeType, colors, fontWeight, Text } from '../../../../../../packages';

// styles
import { useStyles } from './ReportCard.styles';
import CardTop from '../card-top';

const ReportCard = ({ title, subtitle, badge, onViewClick }) => {
  const classes = useStyles();
  const list = [
    { color: colors.success500, label: 'Highest number', value: 50, changedValue: '+5% from 1st completion' },
    {
      color: colors.destructive500,
      label: 'Lowest number',
      value: 7,
      changedValue: '+5% from 1st completion',
    },
    {
      color: colors.primary500,
      label: 'Average  number',
      value: 23,
      changedValue: '+5% from 1st completion',
    },
  ];

  return (
    <Box className={classes.card}>
      <CardTop title={title} subtitle={subtitle} badge={badge} onClick={onViewClick} />
      <Box className={classes.content}>
        {list.map(({ color, label, value, changedValue }) => (
          <Box className={classes.item} key={label}>
            <Stack gap={2} alignItems="center" direction="row">
              <Stack gap={1} direction="row" alignItems="center">
                <Box className={classes.dot} sx={{ backgroundColor: color }} />
                <Text weight={fontWeight.MEDIUM} className={classes.value}>
                  {value}
                </Text>
              </Stack>
              <Badge variant={badgeType.OUTLINED} style={badgeStyle.UNRELATED} className={classes.badge}>
                {label}
              </Badge>
            </Stack>
            <Text weight={fontWeight.MEDIUM} className={classes.value}>
              {changedValue}
            </Text>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export { ReportCard };
