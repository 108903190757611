import React from 'react';
import { colors, Tooltip } from '../../../../packages';
import { useStyles } from './MultiColorProgressBar.styles';

const MultiColorProgressBar = ({ readings, totalValue }) => {
  const classes = useStyles();
  const tValue = readings?.reduce((accumulator, reading) => {
    return parseInt(accumulator, 10) + parseInt(reading?.value, 10);
  }, 0);
  const newReadings =
    totalValue - tValue > 0
      ? [
          ...readings,
          {
            name: '---',
            value: totalValue - tValue,
            color: colors.neutral25,
          },
        ]
      : readings;
  const bars =
    newReadings &&
    newReadings.length &&
    newReadings.map(function (item, i) {
      if (item.value > 0) {
        return (
          <Tooltip key={`index${i}`} title={`${item.value} ${item.name}`} placement="top-start">
            <div
              className={classes.bar}
              style={{ backgroundColor: item.color, width: `${(item.value / tValue) * 100}%` }}
              key={i}
            />
          </Tooltip>
        );
      }
      return null;
    }, this);
  return (
    <div className={classes.multicolorBar}>
      {/* <Tooltip id={`tip`} /> */}
      <div className={classes.bars}>{bars}</div>
    </div>
  );
};

export default MultiColorProgressBar;
