import AddFileOutlined from './AddFileOutlined';
import AddImageOutlined from './AddImageOutlined';
import UploadFileOutlined from './UploadFileOutlined';
import UploadFile from './UploadFile';
import AddUserOutlined from './AddUserOutlined';
import AlertFilled from './AlertFilled';
import AlertOutlined from './AlertOutlined';
import Appointment from './Appointment';
import ArrowLeft from './ArrowLeft';
import ArrowRight from './ArrowRight';
import ArrowDownFilled from './ArrowDownFilled';
import ArrowRightFilled from './ArrowRightFilled';
import ArrowUpFilled from './ArrowUpFilled';
import Attach from './Attach';
import Automation from './Automation';
import BellFilled from './BellFilled';
import BookClosedOutlined from './BookClosedOutlined';
import BookOpenedOutlined from './BookOpenedOutlined';
import BookSideFilledOutlined from './BookSideFilledOutlined';
import BookmarkedFilled from './BookmarkedFilled';
import BookmarkedOutlined from './BookmarkedOutlined';
import CalendarCheckedOutlined from './CalendarCheckedOutlined';
import CalendarPrimaryOutlined from './CalendarPrimaryOutlined';
import CalendarPrimaryOutlinedSquare from './CalendarPrimaryOutlinedSquare';
import CalendarSecondaryOutlined from './CalendarSecondaryOutlined';
import CalendarTodayOutlined from './CalendarTodayOutlined';
import CameraFilled from './CameraFilled';
import CaretDown from './CaretDown';
import CaretLeft from './CaretLeft';
import CaretRight from './CaretRight';
import CaretUp from './CaretUp';
import ChartFilled from './ChartFilled';
import ChatCheckedOutlined from './ChatCheckedOutlined';
import Chat from './Chat';
import ChatFilled from './ChatFilled';
import ChatOutlined from './ChatOutlined';
import CheckboxBlankOutlined from './CheckboxBlankOutlined';
import CheckboxBlankOutlinedStroke from './CheckboxBlankOutlinedStroke';
import CheckboxMultiple from './CheckboxMultiple';
import CheckboxOutlined from './CheckboxOutlined';
import CheckCircle from './CheckCircle';
import CheckMark from './CheckMark';
import ChevronDown from './ChevronDown';
import ChevronLeft from './ChevronLeft';
import ChevronRight from './ChevronRight';
import ChevronUp from './ChevronUp';
import CircleLegend from './CircleLegend';
import ClearCircle from './ClearCircle';
import Close from './Close';
import CloseCircleOutlined from './CloseCircleOutlined';
import Connect from './Connect';
import ConversationsFilled from './ConversationsFilled';
import ConversationsOutlined from './ConversationsOutlined';
import Dashboard from './Dashboard';
import DataManagementFilled from './DataManagementFilled';
import DataManagementOutlined from './DataManagementOutlined';
import DeleteOutlined from './DeleteOutlined';
import Diagnosis from './Diagnosis';
import Disconnect from './Disconnect';
import DocumentFilled from './DocumentFilled';
import Drag from './Drag';
import Duplicate from './Duplicate';
import EditFilled from './EditFilled';
import Email from './Email';
import EmailPar from './EmailPar';
import EmailOutlined from './EmailOutlined';
import EndCallFilled from './EndCallFilled';
import EventOutlined from './EventOutlined';
import EyeOutlined from './EyeOutlined';
import FilterFilled from './FilterFilled';
import FlagOutlined from './FlagOutlined';
import GoalOutlined from './GoalOutlined';
import GridOutlined from './GridOutlined';
import GroupOutlined from './GroupOutlined';
import HeartOutlined from './HeartOutlined';
import HistoryOutlined from './HistoryOutlined';
import InfoOutlined from './InfoOutlined';
import InProgress from './InProgress';
import Interference from './Interference';
import LabBottleOutlined from './LabBottleOutlined';
import LifeEventOutlined from './LifeEventOutlined';
import Login from './Login';
import LongArrowRight from './LongArrowRight';
import MedicationOutlined from './MedicationOutlined';
import Menu from './Menu';
import MicOff from './MicOff';
import Minus from './Minus';
import More from './More';
import MoreVertical from './MoreVertical';
import PasswordLock from './PasswordLock';
import Pause from './Pause';
import Phone from './Phone';
import PhoneOutlined from './PhoneOutlined';
import PillOutlined from './PillOutlined';
import PlayFilled from './PlayFilled';
import PlayOutlined from './PlayOutlined';
import Plus from './Plus';
import PlusCircleFilled from './PlusCircleFilled';
import PlusCircleOutlined from './PlusCircleOutlined';
import QuestionOutlined from './QuestionOutlined';
import RatingFilled from './RatingFilled';
import RatingHalfOutlined from './RatingHalfOutlined';
import RatingOutlined from './RatingOutlined';
import RemoveUserOutlined from './RemoveUserOutlined';
import ReportOutlined from './ReportOutlined';
import RewardOutlined from './RewardOutlined';
import SearchOutlined from './SearchOutlined';
import SendFilled from './SendFilled';
import ScreenCheckedOutlined from './ScreenCheckedOutlined';
import ScreenSharedOutlined from './ScreenSharedOutlined';
import Support from './Support';
import Symptom from './Symptom';
import Transcript from './Transcript';
import User from './User';
import UserReportOutlined from './UserReportOutlined';
import XCircleOutlined from './XCircleOutlined';
import MembersOutlined from './MembersOutlined';
import MembersFilled from './MembersFilled';
import GoalCircle from './GoalCircle';
import Download from './Download';
import FileJpeg from './FileJpeg';
import FilePdf from './FilePdf';
import ListItem from './List';
import Clock from './Clock';
import EditFilledUnderline from './EditFilledUnderline';
import Union from './Union';
import MinusOutlined from './MinusOutlined';
import SurveyLine from './SurveyLine';
import FileListLine from './FileListLine';
import FileWarning from './FileWarning';
import VideoChat from './VideoChat';
import Pin from './Pin';
import Unpin from './Upin';
import HorizontalLine from './HorizontalLine';
// story icons
import StoryIconLogo from './StoryIconLogo';
import ChatSquare from './ChatSquare';
import Pencil from './Pencil';
import Quotes from './Quotes';
import Comment from './Comment';
import DeleteOutlined2 from './DeleteOutlined2';
import Image from './Image';
import Rotate from './Rotate';
import Medication2 from './Medication2';
import Diagnoses from './Diagnoses';
import Chat2 from './Chat2';
import Stack from './Stack';
import UserStar from './UserStar';
import AlfieOutlined from './Alfie';
import ClockRotate from './ClockRotate';
import ChartOutlined from './ChartOutlined';
import FullScreen from './FullScreen';
import MicOn from './MicOn';
import ShareScreen from './ShareScreen';
import PauseOutlined from './PauseOutlined';
import Star2 from './Star2';
import Speed from './Speed';
import Level from './Level';
import Schedule from './Schedule';
import DataBlock from './DataBlock';
import Collections from './Collections';
import Export from './Export';
import SquareArrowUpRight from './SquareArrowUpRight';
import CopyOutlined from './CopyOutlined';
import DollarFilled from './DollarFilled';
import List2 from './List2';
import AddOrderOutlined from './AddOrderOutlined';
import Routing from './Routing';
import UpCircle from './UpCircle';
import DragArrows from './DragArrows';
import ArrowLeftRight from './ArrowLeftRight';
import Profile from './Profile';
import AOutlined from './AOutline';
import SaveCard from './SaveCard';
import Pointer from './Pointer';
import Filter from './Filter';
import Logout from './Logout';
import Print from './Print';
import DollarOutlined from './DollarOutlined';
import EditOutlined from './EditOutlined';
import MessageOutlined from './MessageOutlined';
import CameraOff from './CameraOff';
import Earth from './Earth';
import InputMethod from './InputMethod';
import Help from './Help';
import Evaluation from './Evaluation';
import Pharmacy from './Pharmacy';
import Procedure from './Procedure';
import Recurring from './Recurring';

// import lottieIcons
import LottieGad7 from '../lotties/lottieIcons/LottieGad7';
import LottieCareTeam from '../lotties/lottieIcons/LottieCareTeam';
import LottieCaseNotes from '../lotties/lottieIcons/LottieCaseNotes';
import LottieChatbot from '../lotties/lottieIcons/LottieChatbot';
import LottieCognitiveIssues from '../lotties/lottieIcons/LottieCognitiveIssues';
import LottieCurrentAndPastAppt from '../lotties/lottieIcons/LottieCurrentAndPastAppt';
import LottiePhq9 from '../lotties/lottieIcons/LottiePhq9';
import LottieEnvironmentalFactors from '../lotties/lottieIcons/LottieEnvironmentalFactors';
import LottieHospital from '../lotties/lottieIcons/LottieHospital';
import LottieInsurance from '../lotties/lottieIcons/LottieInsurance';
import LottieMedications from '../lotties/lottieIcons/LottieMedications';
import LottieSymptomaticFactors from '../lotties/lottieIcons/LottieSymptomaticFactors';
import LottieDiagnoses from '../lotties/lottieIcons/LottieDiagnoses';
import LottieDriverLicense from '../lotties/lottieIcons/LottieDriverLicense';
import Specialists from '../lotties/lottieIcons/Specialists';
import Value from '../lotties/lottieIcons/Value';
import FaceTalkingInterested from '../lotties/lottieIcons/FaceTalkingInterested';
import Dinner from '../lotties/lottieIcons/Dinner';
import Celebrate from '../lotties/lottieIcons/Celebrate';
import ConfettiForCompletedAppointments from '../lotties/lottieIcons/ConfettiForCompletedAppointments';
import FireworksForCompletedNotes from '../lotties/lottieIcons/FireworksForCompletedNotes';
import AlfieWithHeartBubble from '../lotties/lottieIcons/AlfieWithHeartBubble';
import AvatarIconSearch from '../lotties/lottieIcons/AvatarIconSearch';
import Dog08Calendar from '../lotties/lottieIcons/Dog08Calendar';
import Dog11Library from '../lotties/lottieIcons/Dog11Library';
import Dog12WithPhoneAndProvider from '../lotties/lottieIcons/Dog12WithPhoneAndProvider';
import Dog14Treat from '../lotties/lottieIcons/Dog14Treat';
import FaceTalkingAmazed from '../lotties/lottieIcons/FaceTalkingAmazed';
import FaceTalkingBored from '../lotties/lottieIcons/FaceTalkingBored';
import FaceTalkingCelebrating from '../lotties/lottieIcons/FaceTalkingCelebrating';
import FaceTalkingConfused from '../lotties/lottieIcons/FaceTalkingConfused';
import FaceTalkingElated from '../lotties/lottieIcons/FaceTalkingElated';
import FaceTalkingExcited from '../lotties/lottieIcons/FaceTalkingExcited';
import FaceTalkingSad from '../lotties/lottieIcons/FaceTalkingSad';
import Providers from '../lotties/lottieIcons/Providers';
import Wine from '../lotties/lottieIcons/Wine';
import CircleOutlinedArrowDown from './CircleOutlinedArrowDown';
import CircleOutlined from './CircleOutlined';
import CurrencySquare from './CurrencySquare';
import Analyze from './Analyze';
import TrainBot from './TrainBot';
import EditNote from './EditNote';
import Lock from './Lock';
import SwitchAlternateOutlined from './SwitchAlternateOutlined';
import SwitchAlternateWhite from './SwitchAlternateWhite';
import SwitchAlternate from './SwitchAlternate';
import Unlock from './Unlock';
import UnlockWhite from './UnlockWhite';
import PharmacyOutlined from './PharmacyOutlined';
import ShieldOutlined from './ShieldCheckOutlined';
import ProfileArrowLeftOutlined from './ProfileArrowLeftOutlined';
import InstantApointment from './InstantApointment';
import FileListLineFoldOutlined from './FileListLineFoldOutlined';
import GoogleIcon from './GoogleIcon';
import ReplyThread from './ReplyThread';
import OutlinedBell from './OutlinedBell';
import Forward from './Forward';
import NotesIcon from './NotesIcon';
import Mentions from './Mentions';
import Todo from './Todo';
import TodoList from './TodoList';
import AddMention from './AddMention';
import ChatIcon from './ChatIcon';
import CopyLink from './CopyLink';
import KeyboardIcon from './KeyboardIcon';
import arrowUpIcon from './ArrowUpIcon';
import arrowDownIcon from './ArrowDownIcon';
import BlackCircleFilled from './BlackCircleFilled';
import GreyCircleOutlined from './GreyCircleOutlined';
import FlagFilled from './FlagFilled';
import InfoFilled from './InfoFilled';
import CheckedCircleOutlined from './CheckedCircleOutlined';
import SpecificDate from './SpecificDate';
// import InstantAppointmentOutlined from './InstantAppointmentoutlined';
import IAppointmentOutlined from './IAppointmentOutlined';
import CircleCashOnly from './CircleCashOnly';
import CircleInsuranceOnly from './CircleInsuranceOnly';
import Heart from './Heart';
import Intelligent from './Intelligent';
import Determinant from './Determinant';
import Wand from './Wand';
import Quote from './Quote';
import RecordIcon from './RecordIcon';
import EchoIcon from './EconIcon';
import ChatMentionsTodo from '../lotties/lottieIcons/ChatMentionsTodo';
import GeneralNotesTodo from '../lotties/lottieIcons/GeneralNotesTodo';
import SignOffTodo from '../lotties/lottieIcons/SignOffTodo';
import WandOutlined from './WandOutlined';
import DoubleArrow from './DoubleArrow';
import SwapIcon from './SwapIcon';
import Restart from './Restart';
import Server from '../lotties/lottieIcons/Server';
import ServerConnection from '../lotties/lottieIcons/ServerConnection';
import EmptyDegrees from '../lotties/lottieIcons/EmptyDegrees';
import EmptyLicenses from '../lotties/lottieIcons/EmptyLicenses';
import EmptyBoardCertifications from '../lotties/lottieIcons/EmptyBoardCertifications';
import EmptyDeaCds from '../lotties/lottieIcons/EmptyDeaCds';
import EmptyMalpracticeCoverage from '../lotties/lottieIcons/EmptyMalpracticeCoverage';
import LoveCareLottie from '../lotties/lottieIcons/LoveCare';
import MultipleUsers from './MultipleUsers';
import CameraOutlined from './CameraOutlined';
import MessageUnreadOutlined from './MessageUnreadOutlined';
import FilterOutlined from './FilterOutlined';
import Star from './Star';
import Member from './Member';
import Message from './Message';
import Keyboard from './Keyboard';
import Gear from './Gear';
import LottieMagicWand from '../lotties/lottieIcons/MagicWand';

const glyphs = {
  'profile-arrow-left-outlined': ProfileArrowLeftOutlined,
  'shield-outlined': ShieldOutlined,
  'pharmacy-outlined': PharmacyOutlined,
  appointment: Appointment,
  'a-outlined': AOutlined,
  'arrow-left-right': ArrowLeftRight,
  profile: Profile,
  'add-file-outlined': AddFileOutlined,
  'add-order-outlined': AddOrderOutlined,
  'add-image-outlined': AddImageOutlined,
  'upload-file': UploadFile,
  'upload-file-outlined': UploadFileOutlined,
  'add-user-outlined': AddUserOutlined,
  'alert-filled': AlertFilled,
  'alert-outlined': AlertOutlined,
  'arrow-down-filled': ArrowDownFilled,
  'arrow-left': ArrowLeft,
  'arrow-right': ArrowRight,
  'arrow-right-filled': ArrowRightFilled,
  'arrow-up-filled': ArrowUpFilled,
  'goal-circle': GoalCircle,
  'love-care': LoveCareLottie,
  download: Download,
  'save-outlined': SaveCard,
  list: ListItem,
  list2: List2,
  clock: Clock,
  pointer: Pointer,
  filter: Filter,
  'filter-outlined': FilterOutlined,
  'copy-outlined': CopyOutlined,
  'clock-rotate': ClockRotate,
  'camera-outlined': CameraOutlined,
  'file-jpeg': FileJpeg,
  'file-pdf': FilePdf,
  'dollar-filled': DollarFilled,
  'dollar-outlined': DollarOutlined,
  print: Print,
  attach: Attach,
  export: Export,
  rotate: Rotate,
  automation: Automation,
  'bell-filled': BellFilled,
  'book-closed-outlined': BookClosedOutlined,
  'book-opened-outlined': BookOpenedOutlined,
  'book-side-filled-outlined': BookSideFilledOutlined,
  'bookmarked-filled': BookmarkedFilled,
  'bookmarked-outlined': BookmarkedOutlined,
  'calendar-checked-outlined': CalendarCheckedOutlined,
  'calendar-primary-outlined': CalendarPrimaryOutlined,
  'calendar-primary-outlined-square': CalendarPrimaryOutlinedSquare,
  'calendar-secondary-outlined': CalendarSecondaryOutlined,
  'calendar-today-outlined': CalendarTodayOutlined,
  'camera-filled': CameraFilled,
  'camera-off': CameraOff,
  'caret-down': CaretDown,
  'caret-left': CaretLeft,
  'caret-right': CaretRight,
  'caret-up': CaretUp,
  'chart-filled': ChartFilled,
  member: Member,
  chat: Chat,
  quote: Quotes,
  comment: Comment,
  'chat-square': ChatSquare,
  'chat-checked-outlined': ChatCheckedOutlined,
  'chat-filled': ChatFilled,
  'chat-outlined': ChatOutlined,
  'message-outlined': MessageOutlined,
  'message': Message,
  'checkbox-multiple': CheckboxMultiple,
  'checkbox-outlined': CheckboxBlankOutlined,
  'checkbox-stroke-outlined': CheckboxBlankOutlinedStroke,
  'checkbox-blank-outlined': CheckboxOutlined,
  'check-circle': CheckCircle,
  checkmark: CheckMark,
  'chevron-down': ChevronDown,
  'chevron-left': ChevronLeft,
  'chevron-right': ChevronRight,
  'chevron-up': ChevronUp,
  'circle-legend': CircleLegend,
  'clear-circle': ClearCircle,
  close: Close,
  'dollar-square': CurrencySquare,
  union: Union,
  image: Image,
  logout: Logout,
  pencil: Pencil,
  'alfie-outlined': AlfieOutlined,
  'close-circle-outlined': CloseCircleOutlined,
  connect: Connect,
  'checked-circle-outlined': CheckedCircleOutlined,
  'conversations-filled': ConversationsFilled,
  'conversations-outlined': ConversationsOutlined,
  dashboard: Dashboard,
  'data-management-filled': DataManagementFilled,
  'data-management-outlined': DataManagementOutlined,
  'delete-outlined': DeleteOutlined,
  'delete-outlined-2': DeleteOutlined2,
  diagnosis: Diagnosis,
  disconnect: Disconnect,
  'document-filled': DocumentFilled,
  'chart-outlined': ChartOutlined,
  drag: Drag,
  'drag-arrows': DragArrows,
  duplicate: Duplicate,
  'edit-filled': EditFilled,
  'edit-outlined': EditOutlined,
  'edit-underline': EditFilledUnderline,
  'specific-date': SpecificDate,
  'instant-appointment-outlined': IAppointmentOutlined,
  // 'instant-appointment-outlined': InstantAppointmentOutlined,
  email: Email,
  'email-par': EmailPar,
  'circle-cash-only': CircleCashOnly,
  'circle-insurance-only': CircleInsuranceOnly,
  'email-outlined': EmailOutlined,
  'empty-board-certifications': EmptyBoardCertifications,
  'empty-dea-cds': EmptyDeaCds,
  'empty-degrees': EmptyDegrees,
  'empty-malpractice-coverage': EmptyMalpracticeCoverage,
  'empty-licenses': EmptyLicenses,
  'end-call-filled': EndCallFilled,
  'event-outlined': EventOutlined,
  'eye-outlined': EyeOutlined,
  'filter-filled': FilterFilled,
  'flag-outlined': FlagOutlined,
  'goal-outlined': GoalOutlined,
  'grid-outlined': GridOutlined,
  'group-outlined': GroupOutlined,
  'heart-outlined': HeartOutlined,
  'history-outlined': HistoryOutlined,
  info: InfoFilled,
  'info-outlined': InfoOutlined,
  'in-progress': InProgress,
  InstantAppointment: InstantApointment,
  interference: Interference,
  keyboard: Keyboard,
  gear: Gear,
  'lab-bottle-outlined': LabBottleOutlined,
  'life-event-outlined': LifeEventOutlined,
  login: Login,
  'long-arrow-right': LongArrowRight,
  'medication-outlined': MedicationOutlined,
  'medication-2': Medication2,
  'chat-2': Chat2,
  'full-screen': FullScreen,
  stack: Stack,
  'user-star': UserStar,
  star2: Star2,
  speed: Speed,
  schedule: Schedule,
  level: Level,
  'data-block': DataBlock,
  collection: Collections,
  diagnoses: Diagnoses,
  'members-filled': MembersFilled,
  'members-outlined': MembersOutlined,
  menu: Menu,
  'mic-off': MicOff,
  'mic-on': MicOn,
  minus: Minus,
  'message-unread-outlined': MessageUnreadOutlined,
  'minus-outlined': MinusOutlined,
  more: More,
  'more-vertical': MoreVertical,
  'multiple-users': MultipleUsers,
  'password-lock': PasswordLock,
  pause: Pause,
  'pause-outlined': PauseOutlined,
  phone: Phone,
  'phone-outlined': PhoneOutlined,
  'pill-outlined': PillOutlined,
  'play-filled': PlayFilled,
  'play-outlined': PlayOutlined,
  plus: Plus,
  'plus-circle-filled': PlusCircleFilled,
  'plus-circle-outlined': PlusCircleOutlined,
  'question-outlined': QuestionOutlined,
  'rating-filled': RatingFilled,
  'rating-half-outlined': RatingHalfOutlined,
  'rating-outlined': RatingOutlined,
  'remove-user-outlined': RemoveUserOutlined,
  'report-outlined': ReportOutlined,
  'reward-outlined': RewardOutlined,
  'search-outlined': SearchOutlined,
  'send-filled': SendFilled,
  'screen-checked-outlined': ScreenCheckedOutlined,
  'screen-shared-outlined': ScreenSharedOutlined,
  'swap-icon': SwapIcon,
  support: Support,
  symptom: Symptom,
  'square-arrow-up-right': SquareArrowUpRight,
  'share-screen': ShareScreen,
  routing: Routing,
  transcript: Transcript,
  user: User,
  'up-circle': UpCircle,
  'user-report-outlined': UserReportOutlined,
  'x-circle-outlined': XCircleOutlined,
  // story icons
  'story-icon-logo': StoryIconLogo,
  'survey-line': SurveyLine,
  'file-list-line': FileListLine,
  'file-warning': FileWarning,
  'file-list-line-fold-outlined': FileListLineFoldOutlined,
  // lottie icons
  'lottie-gad7': LottieGad7,
  'lottie-care-team': LottieCareTeam,
  'lottie-case-notes': LottieCaseNotes,
  'lottie-chatbot': LottieChatbot,
  'lottie-cognitive-issues': LottieCognitiveIssues,
  'lottie-current-and-past': LottieCurrentAndPastAppt,
  'lottie-diagnoses': LottieDiagnoses,
  'lottie-driver-license': LottieDriverLicense,
  'lottie-phq9': LottiePhq9,
  'lottie-environmental-factors': LottieEnvironmentalFactors,
  'lottie-hospital': LottieHospital,
  'lottie-insurance': LottieInsurance,
  'lottie-medications': LottieMedications,
  'lottie-symptomatic-factors': LottieSymptomaticFactors,
  'specialists-provider': Specialists,
  'value-admin': Value,
  'face-talking-interested': FaceTalkingInterested,
  dinner: Dinner,
  celebrate: Celebrate,
  'confetti-for-completed-appointments': ConfettiForCompletedAppointments,
  'fireworks-for-complete-notes': FireworksForCompletedNotes,
  'alfie-with-heart-bubble': AlfieWithHeartBubble,
  'avatar-icon-search': AvatarIconSearch,
  'dog-08-calendar': Dog08Calendar,
  'dog-11-library': Dog11Library,
  'dog-12-with-phone-and-provider': Dog12WithPhoneAndProvider,
  'dog-14-treat': Dog14Treat,
  'face-talking-amazed': FaceTalkingAmazed,
  'face-talking-bored': FaceTalkingBored,
  'face-talking-celebrating': FaceTalkingCelebrating,
  'face-talking-confused': FaceTalkingConfused,
  'face-talking-elevated': FaceTalkingElated,
  'face-talking-excited': FaceTalkingExcited,
  'face-talking-sad': FaceTalkingSad,
  provider: Providers,
  wine: Wine,
  'video-chat': VideoChat,
  pin: Pin,
  unpin: Unpin,
  earth: Earth,
  'horizontal-line': HorizontalLine,
  'circle-outlined-arrow-down': CircleOutlinedArrowDown,
  'circle-outlined': CircleOutlined,
  'input-method': InputMethod,
  analyze: Analyze,
  training: TrainBot,
  'edit-note': EditNote,
  lock: Lock,
  unlock: Unlock,
  'unlock-white': UnlockWhite,
  'switch-alternate-outline': SwitchAlternateOutlined,
  'switch-alternate-white': SwitchAlternateWhite,
  'switch-alternate': SwitchAlternate,
  'google-icon': GoogleIcon,
  'reply-thread': ReplyThread,
  'outlined-bell': OutlinedBell,
  help: Help,
  evaluation: Evaluation,
  pharmacy: Pharmacy,
  procedure: Procedure,
  forward: Forward,
  'mentions-icon': Mentions,
  'notes-icon': NotesIcon,
  todo: Todo,
  'todo-list': TodoList,
  addMention: AddMention,
  'chat-icon': ChatIcon,
  'copy-link': CopyLink,
  'black-circle-filled': BlackCircleFilled,
  'grey-circle-outlined': GreyCircleOutlined,
  'keyboard-icon': KeyboardIcon,
  'arrow-up': arrowUpIcon,
  'arrow-down': arrowDownIcon,
  'flag-filled': FlagFilled,
  heart: Heart,
  intelligent: Intelligent,
  determinant: Determinant,
  wand: Wand,
  'wand-outlined': WandOutlined,
  'ai-quote': Quote,
  record: RecordIcon,
  echo: EchoIcon,
  'chat-mentions-todo': ChatMentionsTodo,
  'general-notes-todo': GeneralNotesTodo,
  'sign-off-todo': SignOffTodo,
  'double-arrow-icon': DoubleArrow,
  restart: Restart,
  recurring: Recurring,
  server: Server,
  'server-connection': ServerConnection,
  star: Star,
  'magic-wand': LottieMagicWand,
};
export { glyphs };
