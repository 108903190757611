/* eslint-disable no-nested-ternary */
import { useEffect, useState } from 'react';
import cx from 'clsx';
import { Box, Collapse } from '@mui/material';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';

import {
  Badge,
  badgeStyle,
  badgeType,
  Button,
  iconBtnStyle,
  iconBtnType,
  IconButton,
  Text,
} from '../../../../../packages';
import { useDomainType } from '../../../../../hooks/useDomainType';
import { DomainTypesEnum } from '../../../../../constants/CommonConstants';
import { reportItemProgress } from '../AddNewReport.constants';

import MedicationForm from '../medication-form';
// styles
import { useStyles } from './ReportFormCollapse.styles';
import { showSnackbar } from '../../../../../redux/modules/snackbar';
import { MedicationEditSchema } from '../index.schema';

const defaultValues = {
  status: '',
  notes: '',
  dosage: '',
  frequency: '',
  symptoms: [],
  provider: 'Confidant Provider',
  confidantProvider: '',
  providerName: '',
  additionalNotes: '',
};

const ReportFormCollapseMedications = ({
  open = false,
  medication,
  onChange,
  lookupData,
  onRemoveClick,
  onOpenCollapse,
  memberId,
  options,
}) => {
  const dispatch = useDispatch();
  const [, symptoms] = useDomainType({
    type: DomainTypesEnum.SYMPTOMS,
    patientId: memberId,
  });

  const symtomsList = symptoms?.relatedElements?.map(item => item.name) || [];
  const [collapsed, setCollapsed] = useState(open || false);
  const [state, setState] = useState(defaultValues);
  const classes = useStyles();

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
    onOpenCollapse && onOpenCollapse(!collapsed);
  };

  const form = useFormik({
    initialValues: { ...defaultValues },
    validationSchema: MedicationEditSchema,
    onSubmit: payload => {
      console.log({ payload });
    },
  });

  const buttonStatus = () => {
    const requiredFields = ['dosage', 'frequency', 'notes', 'status', 'provider'];
    if (state.provider !== 'External Provider') {
      requiredFields.push('confidantProvider');
    }
    return requiredFields.every(field => state[field]?.length !== 0);
  };

  const showError = () => {
    let errorMessage;
    if (state.status.length === 0) {
      errorMessage = 'Importance level';
    } else if (state.dosage.length === 0) {
      errorMessage = 'Dosage';
    } else if (state.frequency.length === 0) {
      errorMessage = 'Frequency';
    } else if (state.provider.length === 0) {
      errorMessage = 'Provider';
    } else if (state.confidantProvider.length === 0 && state.provider !== 'External Provider') {
      errorMessage = 'Confidant Provider';
    } else if (state.notes.length === 0) {
      errorMessage = 'Report Notes';
    }
    dispatch(
      showSnackbar({
        snackType: 'error',
        snackMessage: `${errorMessage} is required`,
      }),
    );
  };

  const handleSubmit = () => {
    const buttonEnabled = buttonStatus();

    if (buttonEnabled) {
      const { ...remaining } = form.values;
      onChange({
        ...remaining,
        tutorial: {
          dosage: state.dosage,
          frequency: state.frequency,
          confidantProviderId: state.confidantProvider,
          confidantProviderName: state.providerName,
          symptomsBeingTreated: state.symptoms,
          prescribingProvider: state.provider,
          providerName: state.providerName,
        },
        ...state,
      });
    } else {
      showError();
    }
  };

  useEffect(() => {
    if (medication) {
      const { /* tutorial, */ ...remaining } = medication;
      const newObj = {
        ...remaining,
        // ...tutorial,
      };
      Object.keys(newObj).forEach(async key => {
        await form.setFieldValue(key, newObj[key]);
      });
      if (medication.progress) {
        setCollapsed(medication.progress === reportItemProgress.InProgress);
      }
    }
  }, [medication]);

  useEffect(() => {
    setCollapsed(open || false);
  }, [open]);

  const onChangeHandler = (key, value) => {
    setState({
      ...state,
      [key]: value,
    });
  };

  return (
    <Box className={classes.root}>
      <Box className={cx(classes.header, { [classes.headerBorder]: collapsed })} onClick={toggleCollapsed}>
        <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
          <Text className={classes.headTitle}>{medication.item.label}</Text>
          <Badge
            variant={badgeType.OUTLINED}
            className={classes.badge}
            style={
              medication.progress === reportItemProgress.Completed
                ? badgeStyle.SUCCESS
                : medication.progress === reportItemProgress.Incomplete
                ? badgeStyle.MISREPORTED
                : badgeStyle.CRITICAL
            }
          >
            {medication.progress}
          </Badge>
        </Box>
      </Box>
      <Collapse in={collapsed}>
        <form className={classes.form} onSubmit={handleSubmit}>
          <MedicationForm
            form={form}
            medication={medication}
            lookupData={lookupData}
            symptomsList={symtomsList}
            options={options}
            onChangeHandler={onChangeHandler}
            state={state}
          />
          <Box className={classes.footer}>
            <IconButton
              icon="delete-outlined-2"
              className={classes.removeBtn}
              variant={iconBtnType.TEXT}
              style={iconBtnStyle.SECONDARY}
              onClick={onRemoveClick}
            >
              Remove this medication
            </IconButton>
            <Button onClick={handleSubmit}>Done</Button>
          </Box>
        </form>
      </Collapse>
    </Box>
  );
};

export default ReportFormCollapseMedications;
