export const btnType = {
  PRIMARY: 'PRIMARY',
  SECONDARY: 'SECONDARY',
  TEXT: 'TEXT',
  OUTLINE: 'OUTLINE',
};

export const btnSize = {
  DEFAULT: 'DEFAULT',
  LARGE: 'LARGE',
  SMALL: 'SMALL',
  XSMALL: 'XSMALL',
  XXSMALL: 'XXSMALL',
};
