import { Box, Modal } from '@mui/material';
import cx from 'clsx';

// components
import { Heading, Text } from '../../atoms/typography';
import { Button } from '../../atoms/button';
import { Icons } from '../../../icons';
import { colors } from '../../../colors';
import { useStyles } from './Modal.styles';

const ConfirmationModal = ({ open, onClose, onSave, title, message, noLabel = '', yesLabel = '' }) => {
  const classes = useStyles();
  return (
    <Modal open={open} onClose={onClose}>
      <Box className={classes.modal}>
        <Box className={classes.modalContent}>
          <Box className={classes.content}>
            <Box>
              <Heading className={classes.title}>{title}</Heading>
              <Icons glyph="close" onClick={onClose} className={classes.closeBtn} color={colors.neutral400} />
            </Box>
            <Box>
              <Text className={classes.label}>{message}</Text>
            </Box>
            <Box display="flex" justifyContent="space-between" alignItems="center" gap="16px">
              <Button onClick={onClose} className={cx(classes.btn, classes.cancelBtn)}>
                {noLabel !== '' ? noLabel : 'No'}
              </Button>
              <Button className={cx(classes.btn, classes.okBtn)} onClick={onSave}>
                {yesLabel !== '' ? yesLabel : 'Yes'}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export { ConfirmationModal };
