import { makeStyles } from '@mui/styles';

// colors
import { colors } from '../../../../../../colors';

export const useStyles = makeStyles({
  radioGroupRoot: ({ currentIndex, choices }) => {
    const choicesChilds = choices.reduce((acc, cur, index) => {
      return {
        ...acc,
        [`&:nth-child(${index + 1})`]: {
          backgroundColor: index === currentIndex ? colors.neutral50 : colors.white,
        },
      };
    }, {});
    return {
      display: 'flex',
      '& .MuiFormGroup-root': {
        gap: '8px',
        flex: 1,
        '& .MuiFormControlLabel-root': {
          display: 'flex',
          flexDirection: 'row-reverse',
          padding: '16px 16px 16px 24px',
          alignItems: 'center',
          ...choicesChilds,
          gap: '16px',
          flex: 1,
          borderRadius: '8px',
          background: colors.white,
          boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.04), 0px 0px 2px 0px rgba(0, 0, 0, 0.06), 0px 0px 1px 0px rgba(0, 0, 0, 0.04)',
          '& .MuiFormControlLabel-label': {
            flex: 1,
          },
        },
      },
    };
  },
  radioGroupRootFP: ({ currentIndex, choices }) => {
    const choicesChilds = choices.reduce((acc, cur, index) => {
      return {
        ...acc,
        [`&:nth-child(${index + 1})`]: {
          backgroundColor: index === currentIndex ? colors.neutral50 : colors.white,
        },
      };
    }, {});
    return {
      display: 'flex',
      '& .MuiFormGroup-root': {
        gap: '8px',
        flex: 1,
        '& .MuiFormControlLabel-root': {
          display: 'flex',
          flexDirection: 'row-reverse',
          padding: '8px 8px 8px 0px',
          alignItems: 'center',
          ...choicesChilds,
          gap: '16px',
          flex: 1,
          background: colors.white,
          '& .MuiFormControlLabel-label': {
            flex: 1,
          },
        },
      },
    };
  },
});
