import Box from '@mui/material/Box';
import React, { Fragment, useState } from 'react';
import dayjs from 'dayjs';

import { Badge, badgeType } from '../../../../../packages/ui/atoms/badge';
import {
  fontWeight,
  Heading,
  headingLevel,
  Text,
  textLevel,
} from '../../../../../packages/ui/atoms/typography';

import { getFullImagePath } from '../../../../../utils/CommonUtils';

// styles
import { useStyles } from '../../MemberDetail.styles';

const GroupCard = ({ name, joinedAt, attendanceCount, joinedMembersCount, groupImage }) => {
  const [, setOpenDrawer] = useState(false);
  const classes = useStyles({});

  const handleOpenDrawer = e => {
    e.preventDefault();
    setOpenDrawer(true);
  };

  return (
    <Fragment>
      <div onClick={handleOpenDrawer} className={classes.groupCard}>
        <img src={groupImage ? getFullImagePath(groupImage) : '/images/group_card_img.png'} alt="img" />
        <div>
          <div className="groupCardHeader">
            <Heading className="groupCardTitle" level={headingLevel.M} weight={fontWeight.BOLD}>
              {name}
            </Heading>
            <Badge variant={badgeType.OUTLINED} className="groupCardBadge">
              {attendanceCount || 0} attended
            </Badge>
          </div>
          <Box display="flex" alignItems="center">
            <Text className="groupCardJoinDate" level={textLevel.M} weight={fontWeight.MEDIUM}>
              Joined {dayjs(joinedAt).format('MM/DD/YYYY')}
            </Text>
            <Box marginLeft={2}>
              <Text className="groupCardJoinDate" level={textLevel.M} weight={fontWeight.MEDIUM}>
                {joinedMembersCount || 0} members joined
              </Text>
            </Box>
          </Box>
        </div>
      </div>

      {/* <Drawer
        variant={drawerType.FORM}
        position={drawerPosition.RIGHT}
        open={openDrawer}
        onClose={handleCloseDrawer}
        submitBtnTitle="update"
        className={classes.chatBotDrawer}
        footer={
          <div className={classes.chatBotDrawerFooter}>
            <Button onClick={handleCloseDrawer} className={classes.drawerCancelBtn} variant={btnType.TEXT}>
              Close
            </Button>
            <Text className={classes.neutral700} level={textLevel.S} weight={fontWeight.MEDIUM}>
              12/24 sessions attended
            </Text>
          </div>
        }
        header={
          <div className={classes.groupDrawerHeader}>
            <div className="headerImgWrapper">
              <img src="/images/group_card_img.png" alt="img" />
              <div>
                <Heading className={classes.groupHeadTitle} level={headingLevel.M} weight={fontWeight.BOLD}>
                  Mindfullness 101
                </Heading>
                <Text className={classes.groupHeadSubTitle} weight={fontWeight.MEDIUM} level={textLevel.M}>
                  Joined on February 20, 2021
                </Text>
              </div>
            </div>
            <Icons onClick={handleCloseDrawer} className={classes.groupCardCloseIcon} glyph="close" />
          </div>
        }
      >
        <div className={classes.progressWrapper}>
          <Text className={classes.chatBotProgressText} level={textLevel.XS} weight={fontWeight.MEDIUM}>
            6 of 8 sessions attended
          </Text>
          <ProgressBar value={75} />
        </div>
        <div className={classes.groupsWrapper}>
          {contributions && (
            <>
              <Heading className="heading" weight={fontWeight.MEDIUM} level={headingLevel.S}>
                Contribution
              </Heading>
              <div className="contributionsWrapper">
                {contributions.map((value, index) => (
                  <div className="contribution" key={index}>
                    <Text className="title" weight={fontWeight.MEDIUM} level={textLevel.M}>
                      {value.title}
                    </Text>
                    <Text className="date" weight={fontWeight.MEDIUM} level={textLevel.S}>
                      {value.date}
                    </Text>
                  </div>
                ))}
              </div>
            </>
          )}
          {sessions && (
            <>
              <Heading
                className={cx({ heading: true, [classes.pt40]: true })}
                weight={fontWeight.MEDIUM}
                level={headingLevel.S}
              >
                Group sessions history
              </Heading>
              <div className="contributionsWrapper sessionsWrapper">
                {sessions.map((value, index) => (
                  <div className="contribution sessionCard" key={index}>
                    <Text className="title" weight={fontWeight.MEDIUM} level={textLevel.M}>
                      {value.date}
                    </Text>
                    <div className={cx({ statusWrapper: true, statusTrue: value?.attended })}>
                      <Text className="date" weight={fontWeight.MEDIUM} level={textLevel.S}>
                        {value?.attended ? 'Attended' : 'Missed'}
                      </Text>
                      <Icons glyph={value?.attended ? 'checkmark' : 'clear-circle'} />
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
      </Drawer> */}
    </Fragment>
  );
};

export default GroupCard;
