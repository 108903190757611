import { makeStyles } from '@mui/styles';
import { colors } from '../../../../../../packages/colors';
import { theme } from '../../../../../../packages/theme';

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    background: colors.white,
    borderRadius: 12,
    boxShadow: '0px 0px 1px 0px #0000000A, 0px 0px 2px 0px #0000000F, 0px 4px 8px 0px #0000000A',
  },
  reviewCard: {
    border: `2px solid ${colors.warning200}`,
  },
  documentCard: {
    border: `2px solid ${colors.indigo200}`,
  },
  discrepancyCard: {
    border: `2px solid ${colors.secondary200}`,
  },
  expiredCard: {
    border: `2px solid ${colors.destructive200}`,
  },
  expiringSoonCard: {
    border: `2px solid ${colors.orange200}`,
  },
  incompleteCard: {
    border: `2px solid ${colors.destructive400}`,
  },
  primaryBtn: {
    color: colors.white,
    textTransform: 'capitalize',
    fontWeight: 700,
    fontSize: 14,
    lineHeight: '20px',
  },
  outlineBtn: {
    color: colors.primary600,
    borderColor: colors.primary300,
    fontWeight: 700,
    fontSize: 14,
    lineHeight: '20px',
  },
  previewBtn: {
    padding: 0,
    margin: 0,
    height: 20,
    minWidth: 54,
    justifyContent: 'flex-start',
  },
  detailView: {
    padding: 24,
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down(1178)]: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gridRowGap: 16,
    },
  },
  badge: {
    borderRadius: 100,
    fontWeight: 600,
    fontSize: 12,
    lineHeight: '16px',
    color: colors.white,
    textTransform: 'capitalize',
  },
  verifiedBadge: {
    backgroundColor: colors.success500,
  },
  reviewBadge: {
    backgroundColor: colors.warning600,
  },
  documentBadge: {
    backgroundColor: colors.indigo500,
  },
  descrepancyBadge: {
    backgroundColor: colors.secondary500,
  },
  expiredBadge: {
    backgroundColor: colors.destructive500,
  },
  expiringSoonBadge: {
    backgroundColor: colors.orange500,
  },
  incompleteBadge: {
    backgroundColor: colors.destructive500,
  },
});
