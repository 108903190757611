/* eslint-disable max-len */
import cx from 'clsx';

// types
// styles
import { useStyles } from './shared/styles';

const Earth = ({ className, color, onClick }) => {
  const classes = useStyles();
  return (
    <svg
      className={cx(classes.root, className)}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M4.235 4.453C2.96004 5.77629 2.1795 7.49815 2.02457 9.32917C1.86964 11.1602 2.34977 12.9887 3.38426 14.5074C4.41874 16.0261 5.94451 17.1424 7.70511 17.6686C9.46571 18.1948 11.3538 18.0989 13.052 17.397C13.167 16.647 12.915 15.927 12.812 15.675C12.582 15.115 11.824 14.158 10.559 12.831C10.221 12.476 10.243 12.203 10.364 11.394L10.377 11.303C10.459 10.749 10.597 10.421 12.462 10.125C13.41 9.975 13.659 10.353 14.004 10.878L14.12 11.05C14.448 11.53 14.691 11.64 15.058 11.806C15.223 11.881 15.428 11.976 15.703 12.131C16.355 12.504 16.355 12.925 16.355 13.847V13.952C16.355 14.343 16.317 14.687 16.257 14.986C17.0244 14.0229 17.5591 12.8957 17.8196 11.6922C18.0801 10.4886 18.0593 9.24122 17.7588 8.04703C17.4584 6.85284 16.8863 5.7441 16.0873 4.80714C15.2883 3.87017 14.2838 3.13026 13.152 2.645C12.599 3.018 11.84 3.547 11.575 3.91C11.44 4.095 11.248 5.042 10.625 5.12C10.463 5.14 10.244 5.126 10.012 5.111C9.39 5.071 8.54 5.016 8.268 5.755C8.095 6.223 8.065 7.495 8.624 8.155C8.714 8.26 8.731 8.455 8.67 8.674C8.59 8.961 8.429 9.136 8.378 9.172C8.282 9.116 8.09 8.893 7.959 8.742C7.646 8.377 7.254 7.922 6.748 7.782C6.564 7.731 6.362 7.689 6.165 7.647C5.616 7.532 4.995 7.401 4.85 7.093C4.744 6.867 4.745 6.556 4.745 6.228C4.745 5.811 4.745 5.34 4.541 4.883C4.47008 4.71991 4.36585 4.57344 4.235 4.453V4.453ZM10 20C4.477 20 0 15.523 0 10C0 4.477 4.477 0 10 0C15.523 0 20 4.477 20 10C20 15.523 15.523 20 10 20Z"
        fill={color}
      />
    </svg>
  );
};

export default Earth;
