import { CONVERSATION_TYPES } from '../../../constants/CommonConstants';

export const rightSideNavList = [
  { text: 'Assignments', link: '?name=assignments', query: { type: 'name', value: 'assignments' } },
  { text: 'Details', link: '?name=details', query: { type: 'name', value: 'details' } },
];

export const completionMock = [
  {
    title: '1st completion',
    value: 250,
  },
  {
    title: '2nd',
    value: 100,
    changedValue: 100,
  },
  {
    title: '3rd',
    value: 75,
    changedValue: 50,
  },
  {
    title: '4th',
    value: 10,
    changedValue: -80,
  },
];

export const completionTabs = [
  { label: '1st completion', tabKey: '1st' },
  { label: '2nd', tabKey: '2nd' },
  { label: '3rd', tabKey: '3rd' },
];

export const path2type = {
  'collection-conversations': { text: 'Conversations', type: CONVERSATION_TYPES.CONVERSATION },
  'collection-templates': { text: 'Data collection templates', type: CONVERSATION_TYPES.DCT },
  'collection-evaluations': { text: 'Evaluations', type: CONVERSATION_TYPES.EVALUATION },
  'collection-evaluation-dcts': { text: 'Evaluation DCTs', type: CONVERSATION_TYPES.EVALUATION_DCT },
};
