import { createAction } from 'redux-actions';

export const TOGGLE_SIDEBAR = 'app/toggleSidebar';
export const OPEN_DRAWER = 'app/openDrawer';
export const CLOSE_DRAWER = 'app/closeDrawer';

export const commonActionCreators = {
  toggleSidebar: createAction(TOGGLE_SIDEBAR),
  openDrawer: createAction(OPEN_DRAWER),
  closeDrawer: createAction(CLOSE_DRAWER),
};
