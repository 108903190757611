import { makeStyles } from '@mui/styles';

// colors
import { colors } from '../../../packages/colors';
import { theme } from '../../../packages/theme';

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: 64,
  },
  body: { display: 'flex', flexDirection: 'column', gap: 40 },
  rflex: { display: 'flex', alignItems: 'center' },
  rflex2: { display: 'flex', alignItems: 'center', paddingRight: 2 },
  cflex: { display: 'flex', flexDirection: 'column' },
  sbflex: { display: 'flex', justifyContent: 'space-between', alignItems: 'center' },
  sbflex2: { display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingRight: 2 },
  upperActionIcon: {
    width: 40,
    height: 40,
    minWidth: 40,
    padding: 0,
    borderColor: colors.neutral300,
    '& svg': {
      color: colors.neutral500,
      width: 7,
    },
  },
  calendarBtn: {
    width: 40,
    height: 40,
    minWidth: 40,
    padding: 0,
    borderColor: colors.neutral300,
    '& svg': {
      color: colors.neutral500,
      width: 20,
    },
  },
  menuBtn: {
    width: 24,
    height: 24,
    minWidth: 24,
    padding: 0,
    borderColor: colors.neutral300,
    '& svg': {
      color: colors.neutral500,
      width: 20,
    },
  },
  menuText: {
    fontFamily: 'Manrope',
    fontWeight: 700,
    fontSize: 14,
    lineHeight: '20px',
    color: '#0365C1',
  },
  menuItem: {
    display: 'flex',
    gap: 8,
    paddingLeft: 16,
    paddingRight: 16,
  },
  todayBtn: {
    height: 40,
    minWidth: 40,
    padding: 0,
    borderColor: colors.neutral300,
    paddingLeft: 16,
    paddingRight: 16,
  },
  iconBtn: {
    gap: 8,
  },
  primaryBtn: {
    color: colors.white,
    padding: '12px 20px',
  },
  secondaryBtn: {
    width: 40,
    height: 40,
    minWidth: 40,
  },
  outlineBtn: {
    color: colors.primary600,
    borderColor: colors.primary300,
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  outlineBtn2: {
    color: colors.white,
    borderColor: colors.primary300,
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  blockBtn: {
    color: colors.neutral800,
    borderColor: colors.neutral300,
  },
  tableIcon: {
    width: 16,
    '& path': { fill: colors.neutral600 },
  },
  tableActionIcon: {
    width: 32,
    minWidth: 32,
    height: 32,
    '& svg': {
      width: 8,
      stroke: 'aliceblue',
      '& path': { fill: colors.neutral400 },
    },
  },
  tableBox: {
    display: 'flex',
    flexDirection: 'column',
    background: colors.white,
    border: `1px solid ${colors.neutral100}`,
    borderRadius: 8,
    boxShadow: '0px 0px 1px 0px #0000000A, 0px 0px 2px 0px #0000000F, 0px 4px 8px 0px #0000000A',
  },
  borderTop: { borderTop: `1px solid ${colors.neutral100}` },
  badge: {
    borderRadius: 100,
    height: 18,
    fontSize: 12,
    lineHeight: '16px',
    fontWeight: 600,
  },
  cancelledStatusText: {
    whiteSpace: 'nowrap',
  },
  apptCard: {
    background: colors.white,
    boxShadow: '0px 0px 1px 0px #0000000A, 0px 0px 2px 0px #0000000F',
    borderRadius: 8,
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    minHeight: '94px',
    gap: '24px',
    alignItems: 'center',
    padding: '24px',
  },
  apptCardContainer: {
    background: colors.white,
    boxShadow: '0px 0px 1px 0px #0000000A, 0px 0px 2px 0px #0000000F, 0px 4px 8px 0px #0000000A',
    borderRadius: 8,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  evaluation: {
    padding: 16,
    display: 'flex',
    justifyContent: 'space-between',
    borderTop: `1px solid ${colors.neutral200}`,
    cursor: 'pointer',
  },
  text12: {
    fontWeight: 600,
    fontSize: 12,
    lineHeight: '16px',
  },
  text14: {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '20px',
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    gap: 24,
    '& .title': {
      fontWeight: 600,
      fontSize: 22,
      lineHeight: '28px',
      [theme.breakpoints.down('md')]: {
        fontSize: 18,
        lineHeight: '24px',
      },
    },
  },
  availability: {
    display: 'flex',
    gap: 24,
    padding: 24,
    background: colors.white,
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  availabilityContent: {
    flex: 1,
  },
  label1: {
    display: 'flex',
    alignItems: 'center',
    gap: 12,
    fontSize: 16,
    fontWeight: 600,
    lineHeight: '24px',
    [theme.breakpoints.down('md')]: {
      fontSize: 14,
      lineHeight: '20px',
    },
  },
  label2: {
    fontSize: 16,
    fontWeight: 700,
    lineHeight: '24px',
    [theme.breakpoints.down('md')]: {
      fontSize: 14,
      lineHeight: '20px',
    },
  },
  label3: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: '24px',
    color: colors.neutral600,
    textAlign: 'center',
  },
  label4: {
    fontSize: 16,
    fontWeight: 600,
    lineHeight: '24px',
    color: colors.neutral900,
    textAlign: 'center',
  },
  serviceName: { fontSize: 16, fontWeight: 500, lineHeight: '24px', color: colors.neutral900 },
  durationLabel: { fontSize: 14, fontWeight: 500, lineHeight: '20px', color: colors.neutral600 },
  updateAvailability: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 24,
    gap: 16,
    width: 328,
    height: 348,
    background: colors.neutral50,
    borderRadius: 8,
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  tabContainer: {
    borderTop: `1px solid ${colors.neutral100}`,
    borderBottom: `1px solid ${colors.neutral100}`,
    paddingLeft: 32,
    paddingRight: 32,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: colors.white,
  },
  tabs: {
    borderBottom: `1px solid ${colors.neutral100}`,
    height: '72px',
    '& button': {
      color: colors.neutral600,
      fontWeight: 700,
      fontSize: 14,
      lineHeight: '20px',
      height: '72px',
      minWidth: '99px',
      textTransform: 'none',
      padding: 0,
      marginRight: 32,
      '&.Mui-selected': {
        color: `${colors.neutral900} !important`,
      },
    },
    '& .MuiTabs-indicator': {
      backgroundColor: colors.neutral900,
    },
  },
  content: {
    padding: 64,
    flex: 1,
    overflowY: 'auto',
  },
  xsmallBtn: {
    height: 48,
  },
  primaryBorder: {
    borderColor: colors.primary300,
  },
  link: {
    fontFamily: 'Manrope',
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '24px',
    color: colors.primary600,
    transition: '0.4s',
    textDecoration: 'none',
    cursor: 'pointer',
    '&:hover': {
      // textDecoration: 'underline',
      opacity: 0.8,
    },
  },
  img64: {
    width: 64,
    height: 64,
  },
  img40: {
    width: 40,
    height: 40,
  },
  rating: {
    height: 20,
    '& path': {
      fill: colors.warning500,
    },
  },
  slot: {
    height: 28,
    border: `1px solid ${colors.neutral300}`,
    borderRadius: 4,
    paddingTop: 4,
    paddingBottom: 4,
    paddingLeft: 8,
    paddingRight: 8,
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '20px',
    color: colors.neutral700,
    display: 'flex',
    alignItems: 'center',
    textWrap: 'nowrap',
  },
  dropdownMenu: {
    paddingTop: 8,
    paddingBottom: 8,
  },
  dropdown: {
    borderRadius: 12,
  },
});

export const durationWrapper = {
  display: 'flex',
  alignItems: 'center',
  gap: '4px',
};
