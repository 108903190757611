import cx from 'clsx';

// mui
import MuiFormControlLabel from '@mui/material/FormControlLabel';

// styles
import { useStyles } from './FormControlLabel.styles';

// types

const FormControlLabel = ({ className = '', control, label = null, ...rest }) => {
  const classes = useStyles();

  return (
    <MuiFormControlLabel
      className={cx({
        [classes.root]: true,
        [className || '']: className,
      })}
      control={control}
      label={label}
      {...rest}
    />
  );
};

export { FormControlLabel };
