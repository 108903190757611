import dayjs from 'dayjs';
import { CONTENT_BLOCK_TYPES, frequencyConstants } from '../constants/CommonConstants';
import {
  noAnswerOptions,
  notApplicableOptions,
} from '../pages/admin/conversation/components/conversation-flow/flow-edting/FlowEditing.constant';

export const convertByteToString = byte => {
  if (byte < 1024) {
    return `${byte}B`;
  }
  if (byte < 1024 * 1024) {
    return `${(byte / 1024).toFixed(2)} KB`;
  }
  return `${(byte / 1024 / 1024).toFixed(2)} MB`;
};

const FrequencyTypes = {
  DAYS: 'day',
  WEEKS: 'week',
  MONTHS: 'month',
};

export const getFrequencyTime = (num, unit) => {
  return `${frequencyConstants[num] || `${num} times`} a ${FrequencyTypes[unit]}`;
};

const collator = Intl.Collator();

export const getCompareFn =
  (f, sortOrder = 'asc') =>
  (a, b) => {
    const x = sortOrder === 'asc' ? f(a) : f(b);
    const y = sortOrder === 'asc' ? f(b) : f(a);
    return collator.compare(x, y);
  };

export const formatDateAndStatus = (dueDate, isAddTwoDays = false) => {
  if (!dueDate) {
    return 'No due date';
  }

  let parsedDate;

  if (isAddTwoDays) {
    parsedDate = dayjs(dueDate).add(2, 'day');
  } else {
    parsedDate = dayjs(dueDate);
  }

  if (!parsedDate.isValid()) {
    // Try parsing the date with 'MM-DD-YYYY' format
    return dayjs(dueDate, 'MM-DD-YYYY').locale('en').format('MMM D, YYYY');
  }

  const now = dayjs();
  const dueDay = parsedDate;
  if (now.isSame(dueDay, 'day')) {
    return 'Due today';
  }
  if (now.add(1, 'day').isSame(dueDay, 'day')) {
    return 'Due tomorrow';
  }
  if (now.isAfter(dueDay)) {
    return 'Past due';
  }

  return `Due ${parsedDate.locale('en').format('MM/DD/YYYY')}`;
};

export const getProfileFullName = item => {
  let profileFullName = item.practitionerFullName;
  if (!profileFullName) {
    if (item.practitionerFirstName || item.practitionerLastName) {
      profileFullName = `${item.practitionerFirstName} ${item.practitionerLastName}`;
    } else {
      profileFullName = item.assignedByUserName;
    }
  }
  return profileFullName;
};

export const getDesignation = item => {
  let designation = item.practitionerDesignation || item.assignedByUserDesignation || '';
  designation = designation?.toLowerCase()?.replace(/(?:^|\s|_)\S/g, match => match?.toUpperCase());
  designation = designation?.replace(/_/g, ' ');
  return designation;
};

export const stringToColor = (string = '') => {
  let hash = 0;
  let i;

  for (i = 0; i < string.length; i += 1) {
    // eslint-disable-next-line no-bitwise
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    // eslint-disable-next-line no-bitwise
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }

  return color;
};

export const stringAvatar = (name = '') => {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: name?.split(' ')?.length > 0 && `${name?.split(' ')?.[0]?.[0]}${name?.split(' ')?.[1]?.[0]}`,
  };
};

export const initializeAIConfig = contentBlock => {
  if (!contentBlock.aiDocumentationConfig) {
    contentBlock.aiDocumentationConfig = {};
  }
  const { aiDocumentationConfig } = contentBlock;
  if (!aiDocumentationConfig.noAnswerResponse) {
    aiDocumentationConfig.noAnswerResponse = noAnswerOptions[0].value;
  }
  if (!aiDocumentationConfig.notApplicableResponse) {
    aiDocumentationConfig.notApplicableResponse = notApplicableOptions[0].value;
  }
  if (!aiDocumentationConfig.responseLength) {
    if (
      contentBlock.basicInfo.type === CONTENT_BLOCK_TYPES.SINGLE_SELECT ||
      contentBlock.basicInfo.type === CONTENT_BLOCK_TYPES.MULTI_SELECT
    ) {
      aiDocumentationConfig.responseLength = 'select-choice';
    } else {
      aiDocumentationConfig.responseLength = 'short-with-description';
    }
  }
};
