import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import cx from 'clsx';
import { Box } from '@mui/material';
import { colors } from '../../../colors';

// icons
import { Icons } from '../../../icons';

// components
import { IconLink, iconLinkStyle, iconLinkType } from '../../molecules/icon-link';
import { Link } from '../../atoms/link';
import { Menu } from '../../molecules/menu';
import { Collapsable, IiconPosition } from '../../atoms/collapsable';
import { Avatar, avatarType } from '../../atoms/avatar';
import { fontWeight, Text, textLevel } from '../../atoms/typography';
import { Badge, badgeStyle, badgeType } from '../../atoms/badge';
import { btnType, Button } from '../../atoms/button';
import { TextField, textFieldType } from '../../atoms/text-field';
// hooks
import { useQuery } from '../../../hooks';

// styles
import { useStyles } from './SideNavigation.styles';

// types
import { sideNavigationType } from './SideNavigation.types';

const SideNavigation = ({
  className = '',
  navItems = [],
  bottomNavItems = [],
  textFields = [],
  moreInfoTextFields = [],
  dropdownItems = [],
  avatar,
  name = '',
  role = '',
  connectionId = '',
  setOpenDrawer = null,
  setOpenInstantAppointmentDrawer = null,
  handleSidebarToggle,
  sideBarIsActive = true,
  variant = sideNavigationType.LEFT,
  rightSidebarHeadCustom = null,
  custom = null,
  handleGoBack = null,
  handleChatBtn = () => {},
  primaryRoutes = [],
  ...rest
}) => {
  const { pathname } = useLocation();
  const queryHook = useQuery();
  const classes = useStyles({ sideBarIsActive });
  const [isPrimaryPage, setIsPrimaryPage] = useState(true);
  const [collapseLink, setCollapseLink] = useState('');
  const [showAppointmentBadge, setShowAppointmentBadge] = useState(false);
  const [collapsed, setCollapsed] = useState(false);

  const isActive = useCallback(
    ({ link, query }) => {
      if (query) {
        return queryHook.get(query?.type) === query.value;
      }

      if (link === '/') return link === pathname;

      if (link?.includes('/day-schedule')) {
        const splitLink = link?.split('?');
        return splitLink[0] === pathname;
      }

      return pathname.startsWith(link);
    },
    [pathname, queryHook],
  );

  const onClickCollapsable = link => e => {
    e.preventDefault();
    if (!sideBarIsActive && variant === sideNavigationType.LEFT) {
      handleSidebarToggle();
      // save the link to expand exactly the collapse component when clicking on the link
      setCollapseLink(link);
    }
  };

  useEffect(() => {
    if (pathname?.includes('/day-schedule')) {
      setIsPrimaryPage(true);
    } else {
      setIsPrimaryPage(primaryRoutes.includes(pathname));
    }
  }, [pathname]);

  // reset collapse link when left side bar is collapsed
  useEffect(() => {
    if (!sideBarIsActive && variant === sideNavigationType.LEFT) {
      setCollapseLink('');
    }
  }, [sideBarIsActive]);

  useEffect(() => {
    const appointmentObj = navItems.find(item => item.text === 'Appointments');
    appointmentObj?.children?.forEach(item => {
      const hasAppointments = (item.text === 'Current' || item.text === 'Pending') && Number(item.badge) > 0;
      return hasAppointments ? setShowAppointmentBadge(hasAppointments) : '';
    });
  }, [navItems]);

  return (
    <div
      className={cx({
        [classes.root]: true,
        [classes.activeBar]: sideBarIsActive,
        [classes.inactiveBar]: !sideBarIsActive && variant === sideNavigationType.LEFT,
        [className || '']: className,
      })}
      {...rest}
    >
      {custom || (
        <>
          <div className={classes.topPanel}>
            {variant === sideNavigationType.RIGHT && (
              <div
                className={cx({
                  [classes.userCard]: true,
                  [classes.borderBottom]: true,
                  [classes.rightSideUserCard]: true,
                })}
              >
                {rightSidebarHeadCustom || (
                  <div className={classes.userWrapper}>
                    <Avatar size={40} variant={avatarType.CIRCLE} src={avatar} name={name} />
                    {sideBarIsActive && (
                      <div>
                        <Text
                          className={classes.userNameText}
                          weight={fontWeight.SEMI_BOLD}
                          level={textLevel.S}
                        >
                          {name}
                        </Text>
                        <Text className={classes.userInfoText} weight={fontWeight.MEDIUM} level={textLevel.S}>
                          {role}
                        </Text>
                      </div>
                    )}
                  </div>
                )}
                {connectionId !== null && (
                  <Button
                    className={classes.btn}
                    onClick={() => {
                      handleChatBtn();
                    }}
                  >
                    <Icons glyph="chat-square" color="#0365C1" />
                  </Button>
                )}
              </div>
            )}

            {variant === sideNavigationType.LEFT && (
              <div className={classes.logoAndTogglerWrapper}>
                {(sideBarIsActive || isPrimaryPage) && (
                  <Icons className={classes.logo} glyph="story-icon-logo" onClick={handleSidebarToggle} />
                )}
                {sideBarIsActive ? (
                  <>
                    <Button
                      variant={btnType.TEXT}
                      className={classes.sideBarToggler}
                      onClick={handleSidebarToggle}
                    >
                      <Icons className={classes.togglerIcon} glyph="caret-left" color={colors.neutral700} />
                    </Button>
                  </>
                ) : (
                  <>
                    {!isPrimaryPage && (
                      <Button
                        variant={btnType.PRIMARY}
                        className={classes.sideBarActiveBtn}
                        onClick={handleGoBack}
                      >
                        <Icons className={classes.togglerIcon} glyph="arrow-left" color="currentColor" />
                      </Button>
                    )}
                  </>
                )}
              </div>
            )}
            {textFields?.length > 0 && (
              <div
                className={cx({
                  [classes.textFieldsWrapper]: true,
                  [classes.borderBottom]: true,
                })}
              >
                {textFields.map((values, index) => (
                  <TextField
                    key={index}
                    variant={textFieldType.S}
                    setOpenDrawer={setOpenDrawer}
                    setOpenInstantAppointmentDrawer={setOpenInstantAppointmentDrawer}
                    {...values}
                  />
                ))}
                {moreInfoTextFields?.length > 0 && (
                  <Collapsable
                    open={collapsed}
                    label={`${collapsed ? 'Hide' : 'Show'} contact info`}
                    iconPosition={IiconPosition.LEFT}
                    className={cx({
                      [classes.moreInfo]: true,
                      [classes.moreInfoCollapsed]: collapsed,
                    })}
                    // headerHidden={collapsed}
                    onChange={open => setCollapsed(open)}
                    iconColor="#0365C1"
                  >
                    <Box paddingLeft="18px" display="flex" gap="16px" flexDirection="column">
                      {moreInfoTextFields.map((values, index) => (
                        <Box key={index}>
                          <TextField
                            variant={textFieldType.S}
                            setOpenDrawer={setOpenDrawer}
                            setOpenInstantAppointmentDrawer={setOpenInstantAppointmentDrawer}
                            {...values}
                          />
                        </Box>
                      ))}
                    </Box>
                  </Collapsable>
                )}
              </div>
            )}
            {navItems?.length > 0 && (
              <div
                className={cx({
                  [classes.navItems]: true,
                  [classes.rightSideNavItems]: variant === sideNavigationType.RIGHT,
                })}
              >
                {navItems.map(
                  (
                    {
                      icon,
                      activeIcon,
                      text,
                      link,
                      children,
                      classIcon,
                      query,
                      badge,
                      section,
                      status,
                      rightIcon,
                      rightIconClass,
                      rightIconColor,
                    },
                    idx,
                  ) => {
                    const isChildActive = children && children?.some(item => isActive({ link: item.link, query: item.query }));
                    const active =
                      (isActive({ link, query }) && !isChildActive) || (!sideBarIsActive && isChildActive);
                    return children ? (
                      <Collapsable
                        key={idx}
                        active={active}
                        iconHidden={!sideBarIsActive}
                        open={
                          // this is cheat to collapse the menu when sidebar is collapsed
                          // eslint-disable-next-line no-nested-ternary
                          variant === sideNavigationType.LEFT
                            ? sideBarIsActive
                              ? isChildActive || collapseLink === link || ''
                              : false
                            : true
                        }
                        label={
                          <IconLink
                            className={cx({
                              [classes.navItem]: !active,
                              [classes.navActiveItem]: active,
                              [classes.navNoIconItem]: !icon,
                              [classIcon]: !!classIcon,
                            })}
                            variant={iconLinkType.SELF}
                            style={iconLinkStyle.PRIMARY}
                            href={link}
                            icon={active ? activeIcon : icon}
                            onClick={onClickCollapsable(link)}
                          >
                            {sideBarIsActive && (
                              <span
                                className={cx({
                                  [classes.navText]: true,
                                  [classes.navActiveText]: active,
                                })}
                              >
                                {text}
                                <span
                                  className={cx({
                                    [classes.dot]: showAppointmentBadge && text === 'Appointments',
                                  })}
                                />
                              </span>
                            )}
                          </IconLink>
                        }
                        iconPosition={IiconPosition.RIGHT}
                      >
                        <div className={classes.subNavItems}>
                          {children.map(subItem => {
                            const activeSubNav = isActive({ link: subItem.link, query: subItem?.query });
                            return (
                              <Link
                                className={cx({
                                  [classes.navItem]: !activeSubNav,
                                  [classes.navActiveItem]: activeSubNav,
                                  [classes.navNoIconItem]: true,
                                  [classes.disableNavItem]: subItem.disabled,
                                })}
                                key={subItem.text}
                                href={
                                  subItem?.query
                                    ? `${pathname}${subItem.link ?? ''}?${subItem.query.type}=${
                                        subItem.query.value
                                      }${subItem.section ? `#${subItem.section}` : ''}`
                                    : subItem.link
                                }
                                onClick={() => {
                                  if (subItem.section) {
                                    const s = document.querySelector(`section[id="${subItem.section}"]`);
                                    if (s) {
                                      s.scrollIntoView({ behavior: 'smooth', block: 'start' });
                                    }
                                  }
                                }}
                              >
                                <span
                                  className={cx({
                                    [classes.navText]: true,
                                    [classes.navActiveText]: activeSubNav,
                                    [classes.subNavText]: true,
                                  })}
                                >
                                  {subItem.text}
                                </span>
                                {Number(subItem.badge) >= 0 && Number(subItem.badge) <= 999 && (
                                  <Badge
                                    className={cx({
                                      [classes.childBadgeAppoint]: variant === sideNavigationType.LEFT,
                                      [classes.badgeActiveAppoint]:
                                        activeSubNav && variant === sideNavigationType.LEFT,
                                      [classes.childBadge]:
                                        variant === sideNavigationType.RIGHT && Number(subItem.badge) > 0,
                                      [classes.childBadgeZero]: Number(subItem.badge) === 0,
                                      [classes.badgeActive]:
                                        activeSubNav &&
                                        variant === sideNavigationType.RIGHT &&
                                        Number(subItem.badge) > 0,
                                    })}
                                  >
                                    {subItem.badge}
                                  </Badge>
                                )}
                                {Number(subItem.badge) > 999 && (
                                  <Badge
                                    className={cx({
                                      [classes.childBadgeAppoint]: variant === sideNavigationType.LEFT,
                                      [classes.badgeActiveAppoint]:
                                        activeSubNav && variant === sideNavigationType.LEFT,
                                      [classes.childBadge]: variant === sideNavigationType.RIGHT,
                                      [classes.badgeActive]:
                                        activeSubNav && variant === sideNavigationType.RIGHT,
                                    })}
                                  >
                                    999+
                                  </Badge>
                                )}
                                {subItem?.rightIcon && (
                                  <Icons
                                    glyph={subItem?.rightIcon}
                                    className={subItem?.rightIconClass}
                                    color={subItem?.rightIconColor}
                                  />
                                )}
                              </Link>
                            );
                          })}
                        </div>
                      </Collapsable>
                    ) : (
                      <div key={idx} className={active ? classes.navActiveItem : classes.navItem}>
                        <IconLink
                          variant={iconLinkType.SELF}
                          style={iconLinkStyle.PRIMARY}
                          href={
                            query
                              ? `${pathname}?${query.type}=${query.value}${section ? `#${section}` : ''}`
                              : link
                          }
                          icon={active ? activeIcon : icon}
                          className={cx({
                            [classes.linkBadge]: true,
                            [classIcon]: !!classIcon,
                          })}
                          onClick={() => {
                            if (section) {
                              const s = document.querySelector(`section[id="${section}"]`);
                              if (s) {
                                s.scrollIntoView({ behavior: 'smooth', block: 'start' });
                              }
                            }
                          }}
                        >
                          <div className={classes.linkBadge}>
                            {sideBarIsActive && (
                              <span
                                className={cx({
                                  [classes.navText]: true,
                                  [classes.navActiveText]: active,
                                })}
                              >
                                {text}
                                <span
                                  className={cx({
                                    [classes.chatConnectedDot]: status === 'CONNECTED' && text === 'Chats',
                                    [classes.chatConnectingDot]: status === 'CONNECTING' && text === 'Chats',
                                    [classes.chatDisconnectedDot]:
                                      status === 'DISCONNECTED' && text === 'Chats',
                                  })}
                                />
                              </span>
                            )}
                            {badge > 0 && sideBarIsActive && (
                              <Badge
                                className={cx(classes.childBadge2, {
                                  [classes.childBadgeLeft]: variant === sideNavigationType.LEFT,
                                  [classes.childBadge]: variant === sideNavigationType.RIGHT,
                                })}
                              >
                                {badge}
                              </Badge>
                            )}
                            {rightIcon && (
                              <Icons glyph={rightIcon} className={rightIconClass} color={rightIconColor} />
                            )}
                          </div>
                        </IconLink>
                      </div>
                    );
                  },
                )}
              </div>
            )}
          </div>
          <div className={classes.bottomPanel}>
            {bottomNavItems?.length > 0 && (
              <div
                className={cx({
                  [classes.navItems]: true,
                  [classes.rightSideNavItems]: variant === sideNavigationType.RIGHT,
                })}
              >
                {bottomNavItems.map(({ icon, activeIcon, text, link, badge }, idx) => {
                  const active = isActive({ link });
                  return (
                    <div key={idx} className={active ? classes.navActiveItem : classes.navItem}>
                      <IconLink
                        variant={iconLinkType.SELF}
                        style={iconLinkStyle.PRIMARY}
                        href={link}
                        icon={active ? activeIcon : icon}
                        className={classes.bottomNavItem}
                      >
                        {sideBarIsActive && (
                          <div className={classes.bottomNavItemWrapper}>
                            <span
                              className={cx({
                                [classes.navText]: true,
                                [classes.navActiveText]: active,
                              })}
                            >
                              {text}
                            </span>
                            {badge && (
                              <Badge
                                variant={badgeType.FILLED}
                                style={badgeStyle.LOW}
                                className={classes.badge}
                              >
                                {badge} new
                              </Badge>
                            )}
                          </div>
                        )}
                        {!sideBarIsActive && badge && <span className={classes.badgeText}>{badge}</span>}
                      </IconLink>
                    </div>
                  );
                })}
              </div>
            )}
            {variant === sideNavigationType.LEFT && (
              <div
                className={cx({
                  [classes.userProfileCard]: true,
                  [classes.borderTop]: true,
                })}
              >
                <Menu items={dropdownItems} className={classes.menu}>
                  <div className={classes.userWrapper}>
                    <Avatar size={40} variant={avatarType.CIRCLE} src={avatar} name={name} />
                    {sideBarIsActive && (
                      <div>
                        <Text
                          className={classes.userNameText}
                          weight={fontWeight.SEMI_BOLD}
                          level={textLevel.S}
                        >
                          {name}
                        </Text>
                        <Text className={classes.userInfoText} weight={fontWeight.MEDIUM} level={textLevel.S}>
                          {role}
                        </Text>
                      </div>
                    )}
                  </div>
                  {sideBarIsActive && dropdownItems && (
                    <div className={classes.menuArrowWrapper}>
                      <Icons glyph="chevron-down" />
                    </div>
                  )}
                </Menu>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export { SideNavigation };
