/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
import clsx from 'clsx';
import { Box, Modal } from '@mui/material';

import { Button, colors, Heading, Icons, Text } from '../../../../packages';

import { useStyles } from './DeleteReport.styles';

const DeleteReport = ({ open, onClose, onDeleteReport, selectedReport = null }) => {
  const classes = useStyles();
  return (
    <Modal open={open} onClose={onClose}>
      <Box className={classes.modal}>
        <Box className={classes.modalContent}>
          <Box className={classes.closeIconWrap}>
            <Icons glyph="clear-circle" className={classes.closeIcon} />
          </Box>
          <Box className={classes.content}>
            <Box>
              <Heading className={classes.title}>Delete this report?</Heading>
              <Icons glyph="close" onClick={onClose} className={classes.closeBtn} color={colors.neutral400} />
            </Box>
            {selectedReport && (
              <Box>
                <Text className={classes.label}>{selectedReport?.reportName}</Text>
              </Box>
            )}
            <Box display="flex" justifyContent="space-between" alignItems="center" gap="16px">
              <Button className={clsx(classes.btn, classes.cancelBtn)} onClick={onClose}>
                Cancel
              </Button>
              <Button onClick={onDeleteReport} className={clsx(classes.btn, classes.okBtn)}>
                Delete Report
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export { DeleteReport };
