// import { makeStyles } from '@mui/styles';

// // colors
// import { colors } from '../../../colors';

// export const useStyles = makeStyles({
//   root: {
//     background: colors.neutral300,
//     borderRadius: 28,
//     height: 40,
//     '& > span': {
//       fontFamily: 'Manrope',
//       fontWeight: 600,
//       fontSize: 14,
//       lineHeight: 1,
//       color: colors.neutral900,
//       paddingLeft: 16,
//       paddingRight: 10,
//     },
//     '& > .MuiChip-deleteIcon': {
//       color: colors.neutral500,
//       width: 18,
//       height: 18,
//       margin: '0 10px 0 0',
//       transition: '0.4s',
//       '&:hover': {
//         opacity: 0.8,
//       },
//     },
//   },
// });

import { makeStyles } from '@mui/styles';

// colors
import { colors } from '../../../colors';

export const useStyles = makeStyles({
  root: {
    background: colors.neutral300,
    borderRadius: 28,
    height: 40,
    '& > span': {
      fontFamily: 'Manrope',
      fontWeight: 600,
      fontSize: 14,
      lineHeight: 1,
      color: colors.neutral900,
      paddingLeft: 16,
      paddingRight: 10,
    },
    '& > .MuiChip-deleteIcon': {
      color: colors.neutral500,
      width: 18,
      height: 18,
      margin: '0 10px 0 0',
      transition: '0.4s',
      '&:hover': {
        opacity: 0.8,
      },
    },
  },
});
