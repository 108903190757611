import { FC, useEffect, useState } from 'react';

// components
import { Grid } from '@mui/material';
import {
  fontWeight,
  Heading,
  headingLevel,
  Text,
  textLevel,
} from '../../../../../packages/ui/atoms/typography';
import { TextField } from '../../../../../packages/ui/atoms/text-field';
import { Drawer, drawerPosition, drawerType } from '../../../../../packages/ui/organisms/drawer';
import { Link } from '../../../../../packages/ui/atoms/link';
import { Input, inputSize, inputType } from '../../../../../packages/ui/atoms/input';
import { Tooltip } from '../../../../../packages/ui/atoms/tooltip';

import { useDispatch, useSelector } from 'react-redux';
import { getProfile } from '../../../../../redux/modules/profile/selectors';
import { profileActionCreators } from '../../../../../redux/modules/profile';
import { IDemographicDetails } from '../../../../../redux/modules/profile/types';

// styles
import { useStyles } from '../../MemberDetail.styles';

const EmergencyContact = ({ memberId }) => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const { demographicDetails = {} } = useSelector(getProfile);
  const classes = useStyles({});
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    emergencyContactName: '',
    emergencyContactNumber: '',
  });

  useEffect(() => {
    setFormData({
      emergencyContactName: demographicDetails.emergencyContact || '',
      emergencyContactNumber: demographicDetails.emergencyPhone || '',
    });
  }, [demographicDetails]);

  const handleOpenDrawer = e => {
    e.preventDefault();
    setOpenDrawer(true);
  };
  const handleCloseDrawer = () => setOpenDrawer(false);

  const renderInfo = (label, text) => {
    return (
      <Grid item md={1} xs={3}>
        <div className={classes.fieldContainer}>
          <Text className={classes.textLight} level={textLevel.S} weight={fontWeight.SEMI_BOLD}>
            {label}
          </Text>
          <Text className={classes.fieldValue} level={textLevel.M} weight={fontWeight.MEDIUM}>
            {text?.length > 15 ? (
              <Tooltip title={text} placement="top-start">
                <span className={classes.fieldValue2}>{text}</span>
              </Tooltip>
            ) : (
              <span>{text}</span>
            )}
          </Text>
        </div>
      </Grid>
    );
  };
  const onChangeField = field => e => {
    const value = e.target?.value;
    setFormData(old => ({
      ...old,
      [field]: value,
    }));
  };

  const onChangePhone = value => {
    if (value !== undefined) {
      setFormData(old => ({
        ...old,
        emergencyContactNumber: value,
      }));
    }
  };

  const handleOnSubmit = () => {
    dispatch(
      profileActionCreators.updateEmergencyInfo({
        emergencyInfo: {
          emergencyContact: formData.emergencyContactName || '',
          emergencyPhone: formData.emergencyContactNumber || '',
        },
        memberId,
      }),
    );
    handleCloseDrawer();
  };

  const formatEmergencyContactNumber = () => {
    if (demographicDetails.emergencyPhone) {
      const last10Digits = demographicDetails.emergencyPhone.slice(-10);
      // Format the number as (XXX) XXX-XXXX
      const formattedNumber = `(${last10Digits.slice(0, 3)}) ${last10Digits.slice(3, 6)}-${last10Digits.slice(
        6,
      )}`;

      return formattedNumber;
    }
    return demographicDetails.emergencyPhone;
  };

  return (
    <div className={classes.card}>
      <div className={classes.cardTopSection}>
        <Heading level={headingLevel.S} weight={fontWeight.BOLD}>
          Emergency contact
        </Heading>
        <Link onClick={handleOpenDrawer}>Edit emergency contact</Link>
      </div>
      <Grid className={classes.cardContentSection} container columns={3} rowSpacing="32px">
        {renderInfo('Emergency contact name', demographicDetails.emergencyContact || 'N/A')}
        {renderInfo('Emergency contact phone', formatEmergencyContactNumber() || 'N/A')}
      </Grid>
      <Drawer
        variant={drawerType.FORM}
        position={drawerPosition.RIGHT}
        open={openDrawer}
        onClose={handleCloseDrawer}
        title="Edit emergency contact"
        submitBtnTitle="update"
        onSubmit={handleOnSubmit}
      >
        <div className={classes.inputsContainer}>
          <div className={classes.inputWrapper}>
            <label htmlFor="emergencyContactName">
              <Text level={textLevel.S} weight={fontWeight.BOLD}>
                Emergency contact name
              </Text>
            </label>
            <Input
              value={formData.emergencyContactName}
              id="emergencyContactName"
              size={inputSize.M}
              placeholder="Enter your emergency contact name"
              onChange={onChangeField('emergencyContactName')}
            />
          </div>
          <div className={classes.inputWrapper}>
            <label htmlFor="emergencyContactNumber">
              <Text level={textLevel.S} weight={fontWeight.BOLD}>
                Emergency contact phone
              </Text>
            </label>
            <Input
              value={formData.emergencyContactNumber || ''}
              name="emergencyContactNumber"
              placeholder="Enter phone number"
              onChange={onChangePhone}
              size={inputSize.M}
              variant={inputType.PHONE}
              fullWidth
            />
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default EmergencyContact;
