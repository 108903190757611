import cx from 'clsx';

// components
import * as Filter from './filter';
import * as Search from './search';
import * as Grid from './grid';
import * as Pagination from './pagination';

// styles
import { useStyles } from './Table.styles';

const { TableSearch } = Search;
const { TablePagination } = Pagination;
const { TableGrid } = Grid;

const Table = ({
  className = '',
  searchProps = undefined,
  paginationProps = undefined,
  gridProps = undefined,
  value = undefined,
  onChange = null,
  onClickExport = null,
  ...rest
}) => {
  const classes = useStyles();

  const onChangeSearch = searchParams => {
    const newValue = {
      ...value,
      pagination: {
        ...value.pagination,
        currentPage: 1,
      },
      search: searchParams,
    };
    onChange(newValue);
  };

  const onChangeSorter = sorterParams => {
    const newValue = { ...value, sorter: sorterParams };
    onChange(newValue);
  };

  const onChangePagination = paginationParams => {
    const newValue = { ...value, pagination: paginationParams };
    onChange(newValue);
  };

  return (
    <div
      className={cx({
        [classes.root]: true,
        [className || '']: className,
      })}
      {...rest}
    >
      {/* Search */}
      {searchProps && (
        <TableSearch
          className={classes.search}
          value={value?.search}
          onChange={onChangeSearch}
          onClickExport={onClickExport}
          {...searchProps}
        />
      )}

      {/* Grid */}
      <TableGrid className={classes.grid} sorter={value?.sorter} onChange={onChangeSorter} {...gridProps} />

      {/* Pagination */}
      {paginationProps && (
        <TablePagination
          className={classes.pagination}
          value={value?.pagination}
          noOfRows={gridProps?.data?.length}
          onChange={onChangePagination}
          {...paginationProps}
        />
      )}
    </div>
  );
};

export { Table, Filter, Search as TableSearch, Pagination as TablePagination, Grid as TableGrid };
