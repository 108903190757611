import dayjs from 'dayjs';
import { GLOBAL_DATE_FORMAT } from '../../../../constants/CommonConstants';

export const automationDetailElkQuery = automationId => {
  return {
    size: 0,
    query: {
      bool: {
        must: [
          {
            term: {
              'ruleId.keyword': automationId,
            },
          },
        ],
      },
    },
    aggs: {
      last_30_days_total_assignments: {
        filter: {
          range: {
            createdAt: {
              gte: 'now-30d/d',
            },
          },
        },
      },
      total_assignments: {
        value_count: {
          field: 'createdAt',
        },
      },
      assignments_per_day_7_days: {
        filter: {
          range: {
            createdAt: {
              gte: 'now-7d/d',
              lte: 'now/d',
            },
          },
        },
        aggs: {
          count: {
            date_histogram: {
              field: 'createdAt',
              calendar_interval: '1d',
            },
          },
        },
      },
      assignments_per_day_last_30_days: {
        filter: {
          range: {
            createdAt: {
              gte: 'now-30d/d',
              lte: 'now/d',
            },
          },
        },
        aggs: {
          count: {
            date_histogram: {
              field: 'createdAt',
              calendar_interval: '1d',
            },
          },
        },
      },
      assignments_per_day_last_3_months: {
        filter: {
          range: {
            createdAt: {
              gte: 'now-90d/d',
              lte: 'now/d',
            },
          },
        },
        aggs: {
          count: {
            date_histogram: {
              field: 'createdAt',
              calendar_interval: '1d',
            },
          },
        },
      },
      assignments_per_day_last_6_months: {
        filter: {
          range: {
            createdAt: {
              gte: 'now-180d/d',
              lte: 'now/d',
            },
          },
        },
        aggs: {
          count: {
            date_histogram: {
              field: 'createdAt',
              calendar_interval: '1d',
            },
          },
        },
      },
      assignments_per_day_last_12_months: {
        filter: {
          range: {
            createdAt: {
              gte: 'now-365d/d',
              lte: 'now/d',
            },
          },
        },
        aggs: {
          count: {
            date_histogram: {
              field: 'createdAt',
              calendar_interval: '1d',
            },
          },
        },
      },
      breakdown_by_progress: {
        terms: {
          field: 'status.keyword',
          size: 10,
        },
        aggs: {
          member_id: {
            terms: {
              field: 'patientId.keyword',
              size: 10,
            },
          },
        },
      },
    },
  };
};

export const automationRecentAssignmentElkQuery = (
  automationId,
  searchKeyword,
  pageNumber = 0,
  pageSize = 10,
) => {
  if (!automationId) {
    return null;
  }
  return {
    from: (pageNumber - 1) * pageSize,
    size: pageSize,
    query: {
      bool: {
        must: [
          {
            term: {
              'ruleId.keyword': automationId,
            },
          },
        ],
        should: [
          {
            regexp: {
              'member_fullname.keyword': {
                value: `.*${searchKeyword}.*`,
                case_insensitive: true,
              },
            },
          },
          {
            regexp: {
              'member_first_name.keyword': {
                value: `.*${searchKeyword}.*`,
                case_insensitive: true,
              },
            },
          },
          {
            regexp: {
              'member_last_name.keyword': {
                value: `.*${searchKeyword}.*`,
                case_insensitive: true,
              },
            },
          },
        ],
        minimum_should_match: 1,
      },
    },
  };
};

export const getFormatedRecentAssignedData = recentAssignedData => {
  if (recentAssignedData?.hits?.length <= 0) {
    return null;
  }
  /* eslint no-underscore-dangle: "warn" */
  // return recentAssignedData?.hits?.map(hit => hit._source);
  return recentAssignedData?.hits?.map(hit => ({
    member: {
      name: hit?._source?.member_fullname || '', // eslint-disable-line no-underscore-dangle
      fullname: `${hit?._source?.member_first_name || ''} ${hit?._source?.member_last_name || ''}`, // eslint-disable-line no-underscore-dangle
      patientId: hit?._source?.patientId, // eslint-disable-line no-underscore-dangle
      // thumbnail: 'https://i.pravatar.cc/300',
    },
    status: hit?._source?.status?.toLowerCase(), // eslint-disable-line no-underscore-dangle
    date: dayjs(hit?._source?.assignedAt).format(GLOBAL_DATE_FORMAT), // eslint-disable-line no-underscore-dangle
  }));
};

export const getFormatedAutomationDetail = automationDetail => {
  if (!automationDetail) {
    return null;
  }
  return {
    assignmentLast30Days: automationDetail?.aggregations?.last_30_days_total_assignments?.doc_count || 0,
    totalAssignments: automationDetail?.aggregations?.total_assignments?.value || 0,
    assignments: {
      last7Days: automationDetail?.aggregations?.assignments_per_day_7_days?.count?.buckets?.map(bucket => ({
        date: bucket.key_as_string,
        count: bucket.doc_count,
      })),
      current30Days: automationDetail?.aggregations?.assignments_per_day_last_30_days?.count?.buckets?.map(
        bucket => ({
          date: bucket.key_as_string,
          count: bucket.doc_count,
        }),
      ),
      last3Months: automationDetail?.aggregations?.assignments_per_day_last_3_months?.count?.buckets?.map(
        bucket => ({
          date: bucket.key_as_string,
          count: bucket.doc_count,
        }),
      ),
      last6Months: automationDetail?.aggregations?.assignments_per_day_last_6_months?.count?.buckets?.map(
        bucket => ({
          date: bucket.key_as_string,
          count: bucket.doc_count,
        }),
      ),
      last12Months: automationDetail?.aggregations?.assignments_per_day_last_12_months?.count?.buckets?.map(
        bucket => ({
          date: bucket.key_as_string,
          count: bucket.doc_count,
        }),
      ),
    },
    breakdownByProgress: automationDetail?.aggregations?.breakdown_by_progress?.buckets?.map(bucket => ({
      key: bucket.key,
      count: bucket.doc_count,
      members: bucket.member_id?.buckets?.map(bkt => ({ key: bkt.key, count: bkt.doc_count })),
    })),
    totalRecords: automationDetail?.hits?.total?.value,
  };
};

export const automationRecentAssignemtColumns = [
  { id: 'member', label: 'Member', sortable: false },
  { id: 'status', label: 'Plan item status', sortable: false, className: 'color-secondary-500' },
  { id: 'date', label: 'Date', sortable: false },
];

// export const filterOptionsMock = [{ label: 'Type 1', value: 'type_01' }];
