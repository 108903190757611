import { makeStyles } from '@mui/styles';

// colors
import { colors } from '../../../../packages';

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  loader: {
    display: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    gap: 2,
    alignItems: 'center',
  },
  heading: {
    fontFamily: 'Source Serif Pro',
    margin: 0,
  },
  totalBadge: {
    borderRadius: 99,
    whiteSpace: 'nowrap',
    height: 30,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 12px',
    color: colors.neutral700,
  },
  addBtn: {
    color: '#fff',
    fontSize: 16,
    whiteSpace: 'nowrap',
    '& svg': {
      width: 20,
      marginRight: 4,
      '& path': {
        fill: '#fff',
      },
    },
  },
  arrowIconBtn: {
    '& svg': {
      width: 16,
      height: 16,
      '& path': {
        fill: colors.neutral400,
      },
    },
  },
  roleBadge: {
    borderRadius: 20,
    whiteSpace: 'nowrap',
    height: 18,
    padding: '2px 8px',
    fontSize: 12,
    width: 'fit-content',
    border: 'none',
    marginTop: 2,
    '&.Provider': {
      backgroundColor: colors.indigo50,
      color: colors.indigo600,
    },
    '&.Coach': {
      backgroundColor: colors.warning50,
      color: colors.warning600,
    },
    '&.Prescriber': {
      backgroundColor: colors.cyan50,
      color: colors.cyan600,
    },
    '&.Matchmaker': {
      backgroundColor: '#FEF6EE',
      color: '#E04F16',
    },
    '&.Socialworker': {
      backgroundColor: colors.purple50,
      color: colors.purple600,
    },
  },
  status: {
    borderRadius: 100,
    minWidth: 'auto',
    fontSize: 14,
  },
  appointmentCardWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 24,
  },
  appointmentList: {
    backgroundColor: colors.white,
  },
  memberColumn: {
    textDecoration: 'none',
    cursor: 'pointer',
  },
  member: {
    display: 'flex',
    alignItems: 'center',
    gap: 12,
  },
  info: {
    display: 'flex',
    flexDirection: 'column',
    gap: 0,
  },
  id: {
    fontFamily: 'Manrope',
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '20px',
    color: colors.neutral900,
  },
  name: {
    fontFamily: 'Manrope',
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '20px',
    color: colors.neutral600,
  },
  timeWrap: {
    fontSize: 14,
    lineHeight: '20px',
    height: 44,
  },
  date: {
    color: colors.neutral900,
    fontWeight: 600,
    whiteSpace: 'nowrap',
  },
  time: {
    whiteSpace: 'nowrap',
    color: colors.neutral600,
    fontWeight: 500,
  },
  menu: {
    float: 'right',
    right: 20,
    '& svg': {
      width: 16,
    },
  },
  menuItemsWrapper: {
    '& ul': {
      padding: 0,
    },
    '& ul li': {
      padding: '10px 16px',
      '&:last-child': {
        color: colors.destructive600,
        '& svg path': {
          fill: colors.destructive600,
        },
      },
    },
  },
  cancelledStatusText: {
    whiteSpace: 'nowrap',
  },
});
