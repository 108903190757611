import cx from 'clsx';
import MuiTooltip from '@mui/material/Tooltip';
import { useStyles } from './Tooltip.styles';

const Tooltip = ({ className = '', title, children, ...rest }) => {
  const classes = useStyles();

  return (
    <MuiTooltip
      classes={{
        tooltip: cx({
          [classes.root]: true,
          [className || '']: className,
        }),
        arrow: classes.arrow,
      }}
      title={title}
      arrow
      {...rest}
    >
      {children}
    </MuiTooltip>
  );
};

export { Tooltip };
