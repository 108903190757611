export const headingLevel = {
  XL: 'XL',
  L: 'L',
  M: 'M',
  S: 'S',
};

export const textLevel = {
  XL: 'XL',
  L: 'L',
  M: 'M',
  S: 'S',
  XS: 'XS',
};

export const fontWeight = {
  LIGHT: '300',
  REGULAR: '400',
  MEDIUM: '500',
  SEMI_BOLD: '600',
  BOLD: 'bold',
  EXTRA_BOLD: '800',
};
