// hooks/useMessageThread.js
import { useState } from 'react';

const MESSAGE_THREAD_INITIAL_STATE = {
  messageId: 0,
  message: '',
  nickName: '',
  colorCode: '',
  userId: '',
  name: '',
  avatar: '',
  thumbnail: '',
  createdDate: '',
  fileMeta: {
    type: '',
    url: '',
  },
};

export const useMessageThread = () => {
  const [messageThread, setMessageThread] = useState(MESSAGE_THREAD_INITIAL_STATE);

  const resetMessageThread = () => {
    setMessageThread(MESSAGE_THREAD_INITIAL_STATE);
  };

  return { messageThread, setMessageThread, resetMessageThread };
};
