import { Box, Stack } from '@mui/material';

import { fontWeight, iconBtnType, IconButton, Icons, Text } from '../../../../../packages';

import AutomationEventItem from '../automation-event-item';
// styles
import { useStyles } from './AutomationEvent.styles';

const AutomationEvent = ({ icon, title, events }) => {
  const classes = useStyles();

  const onEditClick = event => {
    console.log('onEditClick', event);
  };

  const onDeleteClick = event => {
    console.log('onDeleteClick', event);
  };

  const onAddFilterClick = () => {
    console.log('onAddFilterClick');
  };

  return (
    <Box className={classes.wrapper}>
      <Stack direction="row" gap={1.5} alignItems="center" sx={{ marginBottom: 3 }}>
        <Icons glyph={icon} className={classes.icon} />
        <Text className={classes.title} weight={fontWeight.BOLD}>
          {title}
        </Text>
      </Stack>
      <Box className={classes.content}>
        {title === 'Filters' && events.length === 0 ? (
          <Box className={classes.event}>
            <Stack direction="row" alignItems="center" justifyContent="space-between" flex={1}>
              <Text className={classes.nofilter} weight={fontWeight.SEMI_BOLD}>
                No filters added yet
              </Text>
              <IconButton
                icon="plus"
                variant={iconBtnType.PRIMARY}
                onClick={onAddFilterClick}
                className={classes.addBtn}
              >
                Add filter
              </IconButton>
            </Stack>
          </Box>
        ) : (
          events.map((event, index) => (
            <AutomationEventItem key={index} event={event} onEdit={onEditClick} onDelete={onDeleteClick} />
          ))
        )}
      </Box>
    </Box>
  );
};

export { AutomationEvent };
