import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import { path2type } from '../../../conversation/ConversationDetail.mock';
import {
  getDctAssignmentDetail,
  getEvaluationDCTAssignmentDetail,
} from '../../../../../services/conversation/conversation.service';
import ReportChartCard from '../../../../../pages/admin/conversation/components/report-chart-card-conversation';
import { CONVERSATION_TYPES } from '../../../../../constants/CommonConstants';
import AssignmentsCard from '../assignments-chart-card';
import ConversationTableCard from '../conversation-table-card';
import InfoCard from '../info-card';

const AssignmentDetail = ({ version, dct = null }) => {
  const { templateId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [dctAssignment, setDctAssignment] = useState();
  const location = useLocation();
  const path = location.pathname.split('/')[2];

  const fetchDCTAssignmentDetail = () => {
    setIsLoading(true);
    if (path2type[path].type === CONVERSATION_TYPES.EVALUATION_DCT) {
      getEvaluationDCTAssignmentDetail(templateId, version).then(res => {
        setDctAssignment(res.data);
        setIsLoading(false);
      });
      return;
    }
    getDctAssignmentDetail(templateId, version).then(res => {
      setDctAssignment(res.data);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    void fetchDCTAssignmentDetail();
  }, []);

  return (
    <Box display="flex" flexDirection="column" gap={4} sx={{ marginTop: 7 }}>
      {!isLoading && (
        <ReportChartCard
          completed={dct?.completed ?? dctAssignment?.completed}
          inProgress={dct?.inProgress ?? dctAssignment?.inProgress}
          notStarted={dct?.notStarted ?? dctAssignment?.notStarted}
        />
      )}
      <AssignmentsCard version={version} />
      <ConversationTableCard dctAssignment={dctAssignment} disableExport isLoading={isLoading} />
      <InfoCard
        title="DCT appears in conversations"
        data={(dct?.appearedInConversations ?? dctAssignment?.appearedInConversations) || []}
        version={version}
      />
    </Box>
  );
};

export { AssignmentDetail };
