import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { getUserTimeZone } from '../../../utils/dayjs';
import history from '../../../utils/history';
import { Box, ButtonGroup } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import debounce from 'debounce';

// components
import {
  Checkbox,
  colors,
  fontWeight,
  Heading,
  headingLevel,
  IconButton,
  btnType,
  Icons,
  Input,
  inputSize,
  inputType,
  Select,
  selectType,
  Text,
  textLevel,
} from '../../../packages';
import { ProfileInfo } from '../../../packages/ui/templates/profile-info';
import { Drawer } from '../../../packages/ui/organisms/drawer';
import linkIcon from '../../../assets/images/link.png';

import { BaseLayout } from '../../../layouts/base';
import { appointmentActionCreators } from '../../../redux/modules/appointment';
import {
  getAppointment,
  selectProviderRoles,
  selectProviderRolesLoading,
} from '../../../redux/modules/appointment/selectors';
import { getAuth } from '../../../redux/modules/auth/selectors';
import { selectProviderSchedule } from '../../../redux/modules/schedule/selectors';
import { getProfile } from '../../../redux/modules/profile/selectors';
import { typography } from '../../../components/v2/Typography/index.constant';
import Typography from '../../../components/v2/Typography';

import { MasterScheduleItemV2 } from './components/master-schedule-item-v2/MasterScheduleItemV2';
import Calendar from './components/calendar';
import AddSchedule from '../appointments/add-schedule';
import AvailableProviders from './components/AvailableProviders';

// mock
import {
  daysList,
  daysOfWeek,
  durationOptions,
  paymentMethodOptions,
  sortOptions,
  timesOfDay,
} from './MasterSchedule.mock';

// styles
import { useStyles } from './MasterSchedule.styles';
import { getServiceTypes } from '../../../services/member/member.service';
import { getStatesSimple } from '../../../services/state/state.service';
import { stateActionCreators } from '../../../redux/modules/state';
import { stateSelector } from '../../../redux/modules/state/selectors';
import { searchSchedule } from '../../../services/appointment/appointment.service';
import { CreateSchedulingLink } from './components/create-scheduling-link/CreateSchedulingLink';

const MasterSchedule = () => {
  const { profile } = useSelector(getProfile);
  const { isAdmin } = useSelector(getAuth);
  const { payers, isLoading: payersLoading } = useSelector(stateSelector);
  const { allProviderServices } = useSelector(getAppointment);
  const roles = useSelector(selectProviderRoles);
  const rolesLoading = useSelector(selectProviderRolesLoading);
  const providerSchedule = useSelector(selectProviderSchedule);

  const classes = useStyles();
  const dispatch = useDispatch();

  const [currentProvider, setCurrentProvider] = useState({});
  const [showAddSchedule, setShowAddSchedule] = useState(false);
  const [showCreateLinkDrawer, setShowCreateLinkDrawer] = useState(false);
  const [view, setView] = useState('list');
  const [serviceTypeOptions, setServiceTypeOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [masterScheduleItems, setMasterScheduleItems] = useState([]);
  const [capacity, setCapacity] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [filters, setFilters] = useState({
    startDate: null,
    endDate: null,
    duration: 60,
    endTime: '2359',
    month: dayjs().format('M'),
    sortOrder: 'NEXT_AVAILABILITY',
    acceptingNewPatients: true,
    serviceTypes: '',
    state: '',
    insurance: '',
    serviceId: '',
    providerRoles: '',
    paymentMethod: 'ALL',
    providerId: [],
    selectedDays: daysOfWeek,
    startTime: '0000',
    timeSpan: durationOptions[0].value,
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    year: dayjs().format('YYYY'),
    viewCapacity: true,
  });
  const [open, setOpen] = useState(false);
  const [drawerItems, setDrawerItems] = useState({
    date: '',
    items: [],
  });
  const [searchKey, setSearchKey] = useState('');
  const isListView = view === 'list';
  const isCalendarView = view === 'calendar';

  useEffect(() => {
    dispatch(appointmentActionCreators.fetchProviderRoles());
    dispatch(appointmentActionCreators.fetchAllProviderServices({ initial: false }));
    void fetchServiceTypes();
    void getAllStates();
  }, []);

  const fetchSchedule = async () => {
    setIsLoading(true);
    const startTimeValue = `${filters.startTime.slice(0, 2)}:${filters.startTime.slice(2, 4)}`;
    const endTimeValue = `${filters.endTime.slice(0, 2)}:${filters.endTime.slice(2, 4)}`;
    const paramquery = {
      participantIds: filters?.providerId.toString(),
      daysOfWeek: filters.selectedDays.toString(),
      duration: filters.duration,
      startDate:
        view === 'calendar'
          ? dayjs(filters?.startDate).format('DD-MM-YYYY') || dayjs().format('DD-MM-YYYY')
          : dayjs().format('DD-MM-YYYY'),
      endDate:
        view === 'calendar'
          ? dayjs(filters?.endDate).format('DD-MM-YYYY') || dayjs().add(30, 'day').format('DD-MM-YYYY')
          : dayjs().add(7, 'day').format('DD-MM-YYYY'),
      startTime: startTimeValue,
      endTime: endTimeValue,
      timezone: filters.timeZone,
      year: Number(filters.year),
      sortOrder: filters.sortOrder.toString(),
      acceptingNewPatients: filters.acceptingNewPatients,
      serviceTypes: filters.serviceTypes.toString(),
      state: filters.state.toString(),
      payerId: filters.insurance.toString(),
      serviceId: filters.serviceId.toString(),
      providerRoles: filters.providerRoles.toString(),
      paymentMethod: filters.paymentMethod.toString(),
      month: Number(filters.month),
      viewProviderDetails: true,
      viewInsurances: true,
      viewProviderSessions: true,
      viewCapacity: view === 'list',
      size: 100,
    };
    try {
      const { data } = await searchSchedule(paramquery);
      setMasterScheduleItems(data?.results);
      setCapacity({
        providersCapacityPerDay: data.providersCapacityPerDay,
        totalProvidersCapacity: data.totalProvidersCapacity,
      });
      setIsLoading(false);
    } catch (e) {
      console.error(e);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    void fetchSchedule();
  }, [filters]);

  const uniqueRoles = useMemo(
    () => Array.from(new Set(roles.map(({ designation }) => designation.toLocaleLowerCase()))),
    [roles],
  );

  const fetchServiceTypes = useCallback(async () => {
    try {
      const { data } = await getServiceTypes();
      setServiceTypeOptions(
        data.map(item => ({
          value: item?.name,
          label: item?.name,
        })),
      );
    } catch (error) {
      console.log(error);
    }
  }, []);

  const getAllStates = useCallback(async () => {
    try {
      const response = await getStatesSimple();
      const activeStates = response?.data?.data?.filter(state => state.isActive);
      setStateOptions(activeStates);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const onChangeSearchKey = debounce(newValue => {
    setSearchKey(newValue);
  }, 500);

  useEffect(() => {
    if (filters?.state) {
      const selectedStateId = stateOptions.find(state => state.name === filters?.state)?._id;
      dispatch(stateActionCreators.fetchPayers(selectedStateId));
    }
  }, [filters?.state]);

  const getMasterSchedules = schedules => {
    if (!schedules || schedules.length === 0) return [];
    return schedules.map(schedule => {
      const reduced = schedule?.slots?.reduce((prev, slot) => {
        const dayString = dayjs(slot?.start).format('YYYY-MM-DD');
        if (prev[dayString]) {
          prev[dayString].push(slot);
        } else {
          prev[dayString] = [slot];
        }
        return prev;
      }, {});

      return { ...schedule, slots: reduced };
    });
  };

  const transformedSchedules = useMemo(() => {
    if (searchKey === '') {
      return getMasterSchedules(masterScheduleItems);
    }
    const filteredValue = masterScheduleItems.filter(v => {
      return v.providerDetailsDto.name.toLowerCase().includes(searchKey.toLowerCase());
    });

    if (filteredValue.length === 0) {
      return getMasterSchedules(masterScheduleItems);
    }

    return getMasterSchedules(filteredValue);
  }, [searchKey, masterScheduleItems]);

  const masterSchedules = useMemo(() => {
    if (searchKey === '') {
      return masterScheduleItems;
    }
    const filteredValue = masterScheduleItems.filter(v => {
      return v.providerDetailsDto.name.toLowerCase().includes(searchKey.toLowerCase());
    });

    if (filteredValue.length === 0) {
      return masterScheduleItems;
    }

    return filteredValue;
  }, [searchKey, masterScheduleItems]);

  const onFilterChange = debounce((key, newValue) => {
    if (key === 'selectedDays') {
      if (filters[key].indexOf(newValue) !== -1) {
        setFilters({
          ...filters,
          [key]: filters[key].filter(v => v !== newValue),
        });
      } else {
        setFilters({
          ...filters,
          [key]: [...filters[key], newValue],
        });
      }
    } else if (key === 'timesOfDay') {
      const { startTime, endTime } = newValue;
      setFilters({
        ...filters,
        startTime,
        endTime,
      });
    } else if (key === 'state') {
      setFilters({
        ...filters,
        state: newValue,
        insurance: '',
      });
    } else if (key === 'providerId') {
      setFilters({
        ...filters,
        providerId: [...filters.providerId, newValue],
      });
    } else if (key === 'serviceId') {
      setFilters({
        ...filters,
        serviceId: newValue,
        duration: allProviderServices?.find(s => s.serviceId === newValue)?.duration || 60,
      });
    } else {
      setFilters({
        ...filters,
        [key]: newValue,
      });
    }
  }, 500);

  const isSelected = (key, newValue) => {
    if (key === 'selectedDays') {
      return Boolean(filters[key].find(v => v === newValue));
    }
    if (key === 'timesOfDay') {
      const { startTime, endTime } = newValue;
      return filters.startTime === startTime && filters.endTime === endTime;
    }
    return filters[key] === newValue;
  };

  const onRemoveProvider = id => {
    const newList = filters.providerId.filter(v => v !== id);
    setFilters({
      ...filters,
      providerId: newList,
    });
  };

  const convertTime = time => {
    const hours = Math.floor(time);
    const mins = Math.floor((time - hours) * 60);
    if (time && time !== 0) {
      if (hours >= 1000) {
        return `${Math.round(hours / 100) / 10}k hours`;
      }
      if (mins > 0) {
        return `${hours} hours ${mins} mins`;
      }
      return `${hours} hours`;
    }
    return '0 hours';
  };

  const onDateClick = (date, items) => {
    setOpen(true);
    /*     const filteredItem = items?.filter((item, index) => {
      const itemIndex = items?.findIndex(i => {
        return i?.providerName === item?.providerName;
      });
      return index === itemIndex;
    }); */
    setDrawerItems({
      date,
      items,
    });
  };
  const fetchAppointments2 = (providerId, timeZone) => {
    const filters2 = [{ searchField: 'status', searchQuery: 'BOOKED' }];
    const queryParams = {
      orderBy: 'desc',
      pageNumber: 0,
      size: 180,
      sortBy: 'startTime',
      statuses: 'BOOKED',
      type: 'CURRENT',
      searchQuery: '',
    };
    const bodyRequest = {
      providerId,
      refDate: dayjs().format('DD-MM-YYYY'),
      timezone: timeZone || getUserTimeZone(),
      type: 'current',
      textSearch: '',
      size: 180,
      filters: filters2,
    };
    dispatch(
      appointmentActionCreators.fetchAppointments({
        bodyRequest,
        queryParams: { ...queryParams },
      }),
    );
  };

  const onSubmitNewSchedule = payload => {
    dispatch(
      appointmentActionCreators.createAppointment({
        data: { ...payload, timezone: providerSchedule?.timezone },
        callback: (isSucceed, isInstantSession, appointmentId) => {
          if (isSucceed) {
            setShowAddSchedule(false);
            if (!isAdmin) {
              fetchAppointments2(profile.providerId, providerSchedule.timezone);
            }
            if (isInstantSession) {
              history.push(`/provider/appointments/current/${appointmentId}/session`);
            }
          }
        },
      }),
    );
  };

  return (
    <>
      <BaseLayout
        rightSideBarWidth="320px"
        customRightSideBar={
          <Box>
            <Input
              variant={inputType.SEARCH}
              size={inputSize.M}
              fullWidth
              placeholder="Search"
              className={classes.searchBar}
              onChange={value => {
                onChangeSearchKey(value);
              }}
            />
            <Box padding="32px">
              <Box display="flex" gap="32px" flexDirection="column">
                <Box display="flex" alignItems="center" gap={1.5} mb={2}>
                  <Checkbox
                    checked={filters?.acceptingNewPatients}
                    onChange={() => onFilterChange('acceptingNewPatients', !filters?.acceptingNewPatients)}
                  />
                  <Text level={textLevel.M}>Accepting new patients</Text>
                </Box>
                <Box sx={styles.filterContainer}>
                  <Text level={textLevel.S} weight={fontWeight.BOLD}>
                    Days of the week
                  </Text>
                  <Box sx={styles.daysGrouped}>
                    <Box sx={styles.availabilityCardItemGroup}>
                      {daysList.slice(0, 3).map((day, index) => (
                        <Box
                          key={index}
                          sx={
                            isSelected('selectedDays', day.value)
                              ? styles.daysButton
                              : styles.daysButtonDisabled
                          }
                          onClick={() => onFilterChange('selectedDays', day.value)}
                        >
                          <Text
                            level={textLevel.XS}
                            weight={fontWeight.BOLD}
                            color={isSelected('selectedDays', day.value) ? colors.white : colors.primary}
                          >
                            {day.label}
                          </Text>
                        </Box>
                      ))}
                    </Box>
                    <Box sx={styles.availabilityCardItemGroup}>
                      {daysList.slice(3, 6).map((day, index) => (
                        <Box
                          key={index}
                          sx={
                            isSelected('selectedDays', day.value)
                              ? styles.daysButton
                              : styles.daysButtonDisabled
                          }
                          onClick={() => onFilterChange('selectedDays', day.value)}
                        >
                          <Text
                            level={textLevel.XS}
                            weight={fontWeight.BOLD}
                            color={isSelected('selectedDays', day.value) ? colors.white : colors.primary}
                          >
                            {day.label}
                          </Text>
                        </Box>
                      ))}
                    </Box>
                    <Box
                      sx={
                        isSelected('selectedDays', 'SATURDAY') ? styles.daysButton : styles.daysButtonDisabled
                      }
                      onClick={() => onFilterChange('selectedDays', 'SATURDAY')}
                    >
                      <Text
                        level={textLevel.XS}
                        weight={fontWeight.BOLD}
                        color={isSelected('selectedDays', 'SATURDAY') ? colors.white : colors.primary}
                      >
                        Sat
                      </Text>
                    </Box>
                  </Box>
                </Box>
                <Box sx={styles.filterContainer}>
                  <Text level={textLevel.S} weight={fontWeight.BOLD}>
                    Times of the day
                  </Text>
                  <Box>
                    {timesOfDay.map(({ label, startTime, endTime }, index) => (
                      <Box key={index} display="flex" alignItems="center" gap={1.5} mb={2}>
                        <Checkbox
                          checked={isSelected('timesOfDay', { startTime, endTime })}
                          onChange={() => onFilterChange('timesOfDay', { startTime, endTime })}
                        />
                        <Text level={textLevel.M}>{label}</Text>
                      </Box>
                    ))}
                  </Box>
                </Box>
                <Box sx={styles.filterContainer}>
                  <Text level={textLevel.S} weight={fontWeight.BOLD}>
                    Services
                  </Text>
                  <Select
                    variant={selectType.SECONDARY}
                    emptyText="All services"
                    displayEmpty
                    value={filters.serviceTypes}
                    onChange={e => onFilterChange('serviceTypes', e?.target?.value)}
                    options={serviceTypeOptions}
                  />
                </Box>
                <Box sx={styles.filterContainer}>
                  <Text level={textLevel.S} weight={fontWeight.BOLD}>
                    State
                  </Text>
                  <Select
                    variant={selectType.SECONDARY}
                    emptyText="All States"
                    displayEmpty
                    value={filters?.state}
                    onChange={e => onFilterChange('state', e?.target?.value)}
                    options={stateOptions.map(state => {
                      return { label: state?.name, value: state?.name };
                    })}
                  />
                </Box>
                {payersLoading ? (
                  <>
                    <Icons className="rotate linear infinite" glyph="in-progress" color={colors.primary} />
                    Loading...
                  </>
                ) : (
                  <Box sx={styles.filterContainer}>
                    <Text level={textLevel.S} weight={fontWeight.BOLD}>
                      Insurance
                    </Text>
                    <Select
                      variant={selectType.SECONDARY}
                      emptyText="All Insurances"
                      displayEmpty
                      value={filters.insurance}
                      onChange={e => onFilterChange('insurance', e?.target?.value)}
                      options={payers
                        ?.filter(payer => payer.isActive)
                        .map(payer => {
                          return { label: payer?.name, value: payer?._id };
                        })}
                    />
                  </Box>
                )}
                <Box sx={styles.filterContainer}>
                  <Text level={textLevel.S} weight={fontWeight.BOLD}>
                    Appointment type
                  </Text>
                  <Select
                    variant={selectType.SECONDARY}
                    emptyText="All appointment types"
                    displayEmpty
                    value={filters.serviceId}
                    onChange={e => onFilterChange('serviceId', e?.target?.value)}
                    options={allProviderServices
                      ?.sort((a, b) => a.name.localeCompare(b.name))
                      .map(service => {
                        return { label: service?.name, value: service?.serviceId };
                      })}
                  />
                </Box>
                <Box sx={styles.filterContainer}>
                  <Text level={textLevel.S} weight={fontWeight.BOLD}>
                    Provider type
                  </Text>
                  <Select
                    variant={selectType.SECONDARY}
                    emptyText="All providers"
                    displayEmpty
                    value={filters.providerRoles}
                    onChange={e => onFilterChange('providerRoles', e?.target?.value)}
                    options={uniqueRoles.map(role => ({
                      label: `${role[0]?.toUpperCase()}${role?.slice(1)}`,
                      value: role,
                    }))}
                  />
                </Box>
                <Box sx={styles.filterContainer}>
                  <Text level={textLevel.S} weight={fontWeight.BOLD}>
                    Payment method
                  </Text>
                  <Box>
                    {paymentMethodOptions.map((method, index) => (
                      <Box key={index} display="flex" alignItems="center" gap={1.5} mb={2}>
                        <Checkbox
                          checked={isSelected('paymentMethod', method.value)}
                          onChange={() => onFilterChange('paymentMethod', method.value)}
                        />
                        <Text level={textLevel.M}>{method.label}</Text>
                      </Box>
                    ))}
                  </Box>
                </Box>
                <Box sx={styles.filterContainer}>
                  <Text level={textLevel.S} weight={fontWeight.BOLD}>
                    Specific providers
                  </Text>
                  <Box>
                    {filters.providerId?.map((id, index) => {
                      const data = roles.find(role => role.userId === id);
                      return (
                        <Box key={index} sx={styles.addedProviders}>
                          <ProfileInfo
                            type="provider"
                            photo={data?.profilePicture}
                            fullName={data?.name}
                            role={data?.designation}
                            isProvider
                          />
                          <Icons
                            glyph="delete-outlined-2"
                            color={colors.destructive600}
                            onClick={() => onRemoveProvider(id)}
                            className={classes.deleteIcon}
                          />
                        </Box>
                      );
                    })}
                    <Select
                      value=""
                      options={roles
                        .filter(item => item.active && !filters.providerId.includes(item.userId))
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map(role => ({
                          label: role?.name,
                          value: role?.userId,
                        }))}
                      IconComponent={props => <AddIcon style={{ color: '#005ebe' }} {...props} />}
                      emptyText="Add specific providers"
                      variant={selectType.PRIMARY}
                      displayEmpty
                      sx={{
                        '& .MuiSelect-icon': {
                          right: 'unset',
                          left: '8px',
                        },
                      }}
                      adjustText
                      truncate={false}
                      onChange={e => onFilterChange('providerId', e?.target?.value)}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        }
      >
        <div className={classes.root}>
          <Box display="flex" justifyContent="space-between">
            <Heading className={classes.heading} level={headingLevel.XL} weight={fontWeight.BOLD}>
              Master schedule
            </Heading>
            <Box sx={styles.availabilityCardItems}>
              {isListView && (
                <Select
                  variant={selectType.SECONDARY}
                  value={filters?.sortOrder}
                  onChange={e => onFilterChange('sortOrder', e?.target?.value)}
                  className={classes.sortSelect}
                  options={sortOptions.map(option => {
                    return { label: option?.label, value: option?.value };
                  })}
                />
              )}
              <ButtonGroup variant="outlined" aria-label="outlined button group">
                <IconButton
                  className={clsx(classes.btnGroupIcon, { [classes.btnGroupIconActive]: isListView })}
                  icon="list"
                  onClick={() => setView('list')}
                />
                <IconButton
                  className={clsx(classes.btnGroupIcon, { [classes.btnGroupIconActive]: isCalendarView })}
                  icon="calendar-primary-outlined"
                  onClick={() => setView('calendar')}
                />
              </ButtonGroup>
            </Box>
          </Box>
          <Box display="flex" flexDirection="column" gap="8px">
            {(rolesLoading || isLoading) && isListView ? (
              <Box
                sx={{
                  position: rolesLoading || isLoading ? 'relative' : 'absolute',
                  top: rolesLoading || isLoading ? -125 : 0,
                  left: rolesLoading || isLoading ? 0 : 70,
                  width: '100%',
                  height: '100vh',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: rolesLoading || isLoading ? '' : colors.white,
                  opacity: 0.8,
                  zIndex: 1000,
                }}
              >
                <Icons className="rotate linear infinite" glyph="in-progress" color={colors.primary} />
              </Box>
            ) : (
              <Box sx={styles.container}>
                {isListView && (
                  <Box sx={styles.cardGroup}>
                    <Box sx={[styles.card, styles.padding]}>
                      <Box sx={styles.cardContainer}>
                        <Typography {...typography.overline.small} color={colors.neutral600}>
                          TOTAL CAPACITY
                        </Typography>
                        <Typography {...typography.heading.h4.bold} color={colors.neutral900}>
                          {convertTime(capacity?.totalProvidersCapacity)}
                        </Typography>
                      </Box>
                    </Box>
                    <Box sx={[styles.card, styles.padding]}>
                      <Box sx={styles.cardContainer}>
                        <Typography {...typography.overline.small} color={colors.neutral600}>
                          CAPACITY TODAY
                        </Typography>
                        <Typography {...typography.heading.h4.bold} color={colors.neutral900}>
                          {convertTime(capacity?.providersCapacityPerDay)}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                )}
                {isListView && (
                  <Box sx={[styles.card, styles.padding]}>
                    <Box sx={styles.schedulingLinkCard}>
                      <Box sx={styles.schedulingLinkGroup}>
                        <img src={linkIcon} alt="link" style={styles.schedulingImage} />
                        <Typography {...typography.body.l.semibold} color={colors.neutral900}>
                          Create scheduling link
                        </Typography>
                      </Box>
                      <IconButton
                        icon="plus"
                        variant={btnType.PRIMARY}
                        className={classes.whiteColor}
                        onClick={() => setShowCreateLinkDrawer(true)}
                      >
                        Create link
                      </IconButton>
                    </Box>
                  </Box>
                )}
                {isListView &&
                  transformedSchedules
                    ?.filter(item => Object.keys(item.slots).length > 0)
                    ?.map((data, index) => (
                      <MasterScheduleItemV2
                        key={index}
                        data={data}
                        onClickSchedule={() => {
                          setShowAddSchedule(true);
                          const foundProvider = roles.find(role => role.name === data.providerName);
                          setCurrentProvider(foundProvider);
                        }}
                      />
                    ))}
              </Box>
            )}
            {isCalendarView && (
              <Calendar
                data={masterSchedules}
                onDateClick={onDateClick}
                filters={filters}
                setFilters={value => setFilters(value)}
                isLoading={isLoading}
              />
            )}
          </Box>
        </div>
      </BaseLayout>
      <Drawer open={open} onClose={() => setOpen(false)} footer={<div />} className={classes.drawer}>
        <Box display="flex" flexDirection="column" gap="16px">
          <AvailableProviders data={drawerItems} onClose={() => setOpen(false)} />
        </Box>
      </Drawer>
      <AddSchedule
        isOpen={showAddSchedule}
        onClose={() => setShowAddSchedule(false)}
        onSubmit={onSubmitNewSchedule}
        providerId={currentProvider?.userId}
      />
      <CreateSchedulingLink
        isOpen={showCreateLinkDrawer}
        onClose={() => setShowCreateLinkDrawer(false)}
        stateOptions={stateOptions}
      />
    </>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
  },
  card: {
    width: '100%',
    backgroundColor: '#FFF',
    borderRadius: '8px',
    boxShadow:
      '0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04)',
  },
  padding: {
    padding: '16px',
  },
  cardGroup: {
    display: 'flex',
    gap: 3,
  },
  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 1,
  },
  schedulingLinkCard: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '16px',
  },
  schedulingLinkGroup: {
    display: 'flex',
    alignItems: 'center',
    gap: 2,
  },
  schedulingImage: {
    height: '40px',
    width: '40px',
  },
  availabilityCardTop: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '24px',
    borderBottom: `1px solid ${colors.neutral100}`,
  },
  availabilityCardItems: {
    display: 'flex',
    gap: '24px',
  },
  availabilityCardItemGroup: {
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
  },
  weekDaysGrid: {
    display: 'flex',
  },
  gridItem: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '16px',
    padding: '16px',
    borderRight: `1px solid ${colors.neutral100}`,
  },
  gridItemLast: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '16px',
    padding: '16px',
    borderRight: 'none',
  },
  weekDay: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  daysGrouped: {
    display: 'flex',
    flexDirection: 'column',
    gap: 1,
    width: '100%',
  },
  daysButton: {
    display: 'flex',
    justifyContent: 'center',
    flex: 1,
    borderRadius: '4px',
    padding: '8px 16px',
    backgroundColor: colors.primary,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  daysButtonDisabled: {
    display: 'flex',
    justifyContent: 'center',
    flex: 1,
    borderRadius: '4px',
    padding: '8px 16px',
    border: `1px solid ${colors.primary300}`,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  filterContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
  },
  addedProviders: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '16px',
  },
};

export { MasterSchedule };
