import { Box } from '@mui/material';
import { btnSize, btnType } from '../../../../packages/ui/atoms/button';

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Header from '../../../../components/v2/Header';
import Typography from '../../../../components/v2/Typography';
import { typography } from '../../../../components/v2/Typography/index.constant';

import SubmitDrawer from '../caqh-information/components/drawers/SubmitDrawer';
import ImportDrawer from '../caqh-information/components/drawers/ImportDrawer';

import { useStyles } from './index.styles';
import PriorityEmpty from '../../member-detail/components/PriorityEmpty';
import { SectionNames } from '../caqh-information/index.constants';
import { getAuth } from '../../../../redux/modules/auth/selectors';
import { getCaqhData, transformData } from '../common/utils.constants';
import { CardStatus, CardType, ProfileSectionCard } from '../common/card/profile-section-card';
import { InfoCard } from '../common/card/info-card';
import { putProviderData } from '../../../../services/datalab/datalab.service';
import { showSnackbar } from '../../../../redux/modules/snackbar';

const BoardCertifications = ({ refresh }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { meta } = useSelector(getAuth);
  const [showNewUpload, setShowNewUpload] = useState(false);
  const [showSubmitModal, setShowSubmitModal] = useState(false);
  const [submitList, setSubmitList] = useState({ filled: [], missing: [], list: [], title: undefined });
  const [certifications, setCertifications] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);

  const fetchCaqhData = async () => {
    const caqhData = await getCaqhData({ providerId: meta?.userId, type: 'board-certification' });
    if (caqhData) {
      setCertifications(caqhData);
    }
  };

  useEffect(() => {
    void fetchCaqhData();
  }, []);

  const onApprove = async id => {
    const type = 'board-certification';
    const requestBody = {
      providerId: meta.userId,
      data: {
        id,
        documentStatus: 'APPROVED',
      },
    };
    try {
      const response = await putProviderData(type, requestBody);
      if (response.status === 200) {
        dispatch(
          showSnackbar({
            snackType: 'success',
            snackMessage: 'Board certificate approved',
          }),
        );
        void fetchCaqhData();
      }
    } catch (err) {
      const msg = err.response?.data?.errors?.[0]?.endUserMessage || 'Something went wrong';
      console.log(msg);
    }
  };

  const onUpdate = id => {
    const data = certifications.find(i => i._id === id);
    const list = transformData(data?.mainData, CardType.BOARD_CERTIFICATION);
    setShowSubmitModal(true);
    setSubmitList({
      filled: list?.filled || [],
      missing: list?.missing || [],
      list: list?.list || [],
      title: 'Board Certifications',
    });
    setSelectedRecord(data);
    setEditMode(true);
    setShowSubmitModal(true);
  };

  const onFormSubmit = async (data, edit) => {
    const type = 'board-certification';
    let fileData = {};
    if (data.fileData) {
      fileData = data.fileData;
    }
    const requestBody = {
      providerId: meta.userId,
      data: {
        id: selectedRecord._id,
        specialty: data.specialty,
        certificateNumber: data.certificateNumber,
        boardName: data.boardName,
        initialCertificateDate: data.initialCertificateDate,
        expiryDate: data.expiryDate,
        ...fileData,
      },
    };
    try {
      const { data: response } = await putProviderData(type, requestBody);
      if (response.success) {
        dispatch(
          showSnackbar({
            snackType: 'success',
            snackMessage: 'Board certificate data updated',
          }),
        );
      } else {
        setSubmitList({ filled: [], missing: [], list: [], title: undefined });
        void fetchCaqhData();
      }
    } catch (e) {
      console.warn(e);
      if (e.data) {
        dispatch(
          showSnackbar({
            snackType: 'error',
            snackMessage: e.data.error,
          }),
        );
      } else {
        dispatch(
          showSnackbar({
            snackType: 'error',
            snackMessage: e.message || 'Something went wrong',
          }),
        );
      }
    }
    onCloseModal();
    void fetchCaqhData();
  };

  const resetInformation = () => {
    setSubmitList({ filled: [], missing: [], list: [], title: undefined });
  };

  const onCloseModal = () => {
    setSelectedRecord(null);
    setShowNewUpload(false);
    setShowSubmitModal(false);
    refresh();
    void fetchCaqhData();
    resetInformation();
  };

  return (
    <>
      <Header
        label="Board certifications"
        actions={[
          {
            children: <Typography {...typography.body.normal.medium.bold}>Add new</Typography>,
            icon: 'plus',
            variant: btnType.PRIMARY,
            size: btnSize.SMALL,
            onClick: () => setShowNewUpload(true),
            className: classes.primaryBtn,
          },
        ]}
      />
      <InfoCard records={certifications} />
      {certifications?.length > 0 ? (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
          {certifications?.map((certification, index) => {
            const cardData =
              certification?.documentStatus === CardStatus.DISCREPANCY
                ? certification?.filesData
                : certification?.mainData;
            const discrepancies = [];
            if (certification?.documentStatus === CardStatus.DISCREPANCY) {
              certification?.issues?.includes('boardName') && discrepancies.push('name');
              certification?.issues?.includes('specialty') && discrepancies.push('specialty');
              certification?.issues?.includes('certificateNumber') && discrepancies.push('number');
              certification?.issues?.includes('initialCertificateDate') && discrepancies.push('issueDate');
              certification?.issues?.includes('expiryDate') && discrepancies.push('expiryDate');
            }
            return (
              <ProfileSectionCard
                key={index}
                data={certification}
                status={certification?.documentStatus || 'INCOMPLETE'}
                name={cardData?.boardName || ''}
                specialties={[cardData?.specialty || '']}
                number={cardData?.certificateNumber || ''}
                issueDate={cardData?.initialCertificateDate || ''}
                expiryDate={cardData?.expiryDate || ''}
                file={certification?.files?.[0] || 'Missing'}
                type={CardType.BOARD_CERTIFICATION}
                onUpdate={() => onUpdate(certification?._id)}
                onApprove={() => onApprove(certification?._id)}
                discrepancies={discrepancies}
              />
            );
          })}
        </Box>
      ) : (
        <PriorityEmpty
          title="Board certifications will appear here once added."
          description=""
          glyph="empty-board-certifications"
          glyphOrientation="portrait"
          isEmptyWithLottie
          lottieSize={128}
        />
      )}
      {showNewUpload && (
        <ImportDrawer open={showNewUpload} onClose={onCloseModal} type={SectionNames.BOARD_CERTIFICATION} />
      )}
      {showSubmitModal && (
        <SubmitDrawer
          missedKeys={submitList.missing || []}
          filledKeys={submitList.filled}
          open={showSubmitModal}
          title="Board Certification"
          subTitle="Board Certification description"
          list={submitList.list}
          onClose={onCloseModal}
          onEdit={() => {
            setEditMode(true);
          }}
          isTabView
          editMode={editMode}
          onFormSubmit={onFormSubmit}
          selectedRecord={selectedRecord}
          fileType={SectionNames.BOARD_CERTIFICATION}
        />
      )}
    </>
  );
};

export default BoardCertifications;
