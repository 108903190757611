import { GeneralInfo } from './components';

const SelectedComponent = ({ selectedScreen }) => {
  switch (selectedScreen) {
    case 'general':
      return <GeneralInfo status="completed" />;
    case 'previous-hospitalization':
      return <GeneralInfo status="incompleted" />;
    case 'feeling-nervous':
      return <GeneralInfo status="rejected" />;
    default:
      return <div>In Progress...</div>;
  }
};

export default SelectedComponent;
