/* eslint-disable max-len */
import cx from 'clsx';
// types
// styles
import { useStyles } from './shared/styles';

const DoubleArrow = ({ className, color, onClick, ...rest }) => {
  const classes = useStyles();
  return (
    <svg
      className={cx({
        [classes.root]: true,
        [className || '']: className,
      })}
      onClick={onClick}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M3.99996 2.66797H14C14.1768 2.66797 14.3463 2.73821 14.4714 2.86323C14.5964 2.98826 14.6666 3.15782 14.6666 3.33464V8.0013H13.3333V4.0013H3.99996V6.0013L0.666626 3.33464L3.99996 0.667969V2.66797ZM12 13.3346H1.99996C1.82315 13.3346 1.65358 13.2644 1.52855 13.1394C1.40353 13.0143 1.33329 12.8448 1.33329 12.668V8.0013H2.66663V12.0013H12V10.0013L15.3333 12.668L12 15.3346V13.3346Z"
        fill={color}
      />
    </svg>
  );
};

export default DoubleArrow;
