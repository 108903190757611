import { useEffect, useState } from 'react';
import { Box, Divider } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  fontWeight,
  Heading,
  Text,
  textLevel,
  headingLevel,
} from '../../../../../packages/ui/atoms/typography';
import { IconButton } from '../../../../../packages/ui/molecules/icon-button';
import { Avatar, avatarType } from '../../../../../packages/ui/atoms/avatar';
import { colors } from '../../../../../packages/colors';
import { Input, inputType } from '../../../../../packages/ui/atoms/input';
import { Icons } from '../../../../../packages/icons';

import { profileActionCreators } from '../../../../../redux/modules/profile';
import { getProfile } from '../../../../../redux/modules/profile/selectors';
import { useStyles } from './Members.styles';

function MemberItem({ nickName, name, image, onSelect }) {
  const classes = useStyles();
  return (
    <Box
      sx={{ '&:hover': { backgroundColor: colors.neutral100 }, cursor: 'pointer' }}
      display="flex"
      paddingX="16px"
      paddingY="24px"
      alignItems="center"
      onClick={onSelect}
    >
      <Avatar name={name} variant={avatarType.CIRCLE} size={40} src={image} />
      <Box paddingLeft="16px" flex="1" display="flex" justifyContent="space-between">
        <Box display="flex" flexDirection="column" gap="5px">
          <Heading weight={fontWeight.BOLD} level={headingLevel.S} className={classes.name}>
            {nickName}
          </Heading>
          <Text level={textLevel.S}>{name}</Text>
        </Box>
        <IconButton icon="arrow-right" onClick={onSelect} />
      </Box>
    </Box>
  );
}

const Members = ({ onClose, onSelect }) => {
  const {
    payload: { patientsList },
    isLoading,
  } = useSelector(getProfile);
  const dispatch = useDispatch();
  const classes = useStyles();
  const [search, setSearch] = useState('');

  const fetchMembers = (searchQuery = '') => {
    dispatch(
      profileActionCreators.fetchPatients({
        searchQuery,
        pageNumber: 1,
        pageSize: 10,
        orderBy: 'asc',
        sortBy: '',
      }),
    );
  };

  useEffect(() => {
    fetchMembers(search);
  }, [search]);

  const onSelectHandler = participant => {
    if (onSelect) {
      onSelect(participant);
    }
  };

  return (
    <Box className={classes.container}>
      <Box className={classes.header}>
        <Box className={classes.headerText}>
          <Heading weight={fontWeight.BOLD} className={classes.title}>
            Members
          </Heading>
        </Box>
        <IconButton icon="close" onClick={onClose} className={classes.closeBtn} />
      </Box>
      <Box paddingX="16px" display="flex" paddingY="24px" flexDirection="column">
        <Input
          value={search}
          placeholder="Search"
          variant={inputType.SEARCH}
          onChange={value => setSearch(value)}
        />
      </Box>
      <Divider />
      <Box display="flex" flexDirection="column">
        {isLoading && (
          <Box display="flex" justifyContent="center" paddingY="10px">
            <Icons className="rotate linear infinite" glyph="in-progress" color={colors.primary} />
          </Box>
        )}
        {patientsList.map((patient, index) => (
          <Box key={patient.member.id}>
            <MemberItem
              nickName={patient.member.nickName}
              name={patient.member.fullName}
              image={patient.member.photo}
              onSelect={() =>
                onSelectHandler({
                  participantId: patient.member.id,
                  participantName: patient.member.fullName,
                  participantImage: patient.member.photo,
                })
              }
            />
            {index !== patientsList.length - 1 && <Divider />}
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export { Members };
