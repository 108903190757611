import { Box, Divider } from '@mui/material';
import clsx from 'clsx';
import { fontWeight, Text, textLevel } from '../../../../../packages/ui/atoms/typography';
import { Avatar, avatarType } from '../../../../../packages/ui/atoms/avatar';
import { Badge, badgeType } from '../../../../../packages/ui/atoms/badge';
// styles
import { useStyles } from './ProviderCard.styles';

const ProviderCard = ({ title, subtitle, provider, label }) => {
  const classes = useStyles();

  return (
    <Box className={classes.card}>
      <Box className={classes.cardTop} display="flex" justifyContent="space-between" alignItems="center">
        <Text className={classes.cardTopTitle} weight={fontWeight.MEDIUM} level={textLevel.S}>
          {label}
        </Text>
      </Box>
      <Divider />
      <Box className={classes.cardBody}>
        {provider ? (
          <Box display="flex" alignItems="center" gap="12px">
            <Avatar variant={avatarType.CIRCLE} size={40} src={provider.thumbnail} name={provider.name} />
            <Box>
              <Text className={classes.username} level={textLevel.S}>
                {provider.name}
              </Text>
              {provider.role ? (
                <Badge className={classes.badge} variant={badgeType.FILLED}>
                  {provider.role}
                </Badge>
              ) : (
                <Text className={clsx(classes.username, classes.usernameFull)} level={textLevel.S}>
                  {provider.fullname}
                </Text>
              )}
            </Box>
          </Box>
        ) : (
          <Box>
            <Text className={classes.title} level={textLevel.S}>
              {title}
            </Text>
            <Box />
            <Text className={classes.subtitle} level={textLevel.S}>
              {subtitle}
            </Text>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export { ProviderCard };
