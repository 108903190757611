// colors
import { colors } from '../../../packages/colors';
import { theme } from '../../../packages/theme';
import { makeStyles } from '@mui/styles';

export const getColumnsForStatsChart = (statCount = 4) => {
  let columnCount = Math.min(statCount, 5);
  if (statCount > 5) {
    columnCount = Math.ceil(statCount / 2);
  }
  const columnWidth = Math.max(Math.ceil(850 / columnCount), 150);

  return `repeat(${columnCount}, ${columnWidth}px)`;
};
export const useStyles = makeStyles({
  modal: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  loader: {
    display: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  preLineText: {
    whiteSpace: 'pre-line',
    wordWrap: 'break-word',
  },
  modalContent: {
    position: 'relative',
    width: 'fit-content',
  },
  content: {
    background: colors.white,
    boxShadow:
      '0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
    borderRadius: 8,
    '&:hover $cardLink': {
      display: 'flex',
    },
    padding: '56px 40px 40px',
  },
  tableToPrint: {
    backgroundColor: colors.white,
    borderCollapse: 'collapse',
    borderRadius: 8,
  },
  tableToPrint_th: { backgroundColor: colors.primary, color: colors.white, wordWrap: 'normal' },
  tableToPrint_td: { padding: '5px', border: '1px solid #000', wordWrap: 'normal' },

  countLabel: {
    margin: '32px 16px 16px 32px',
    fontSize: 14,
    lineHeight: '20px',
    color: colors.neutral600,
    fontWeight: 600,
    display: 'flex',
    alignItems: 'center',
    gap: 8,
  },
  count: {
    fontSize: 24,
    lineHeight: '32px',
    color: colors.neutral900,
    fontWeight: 700,
  },
  topSection: {
    backgroundColor: colors.white,
    marginBottom: '32px',
    paddingBottom: '32px',
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    paddingTop: 88,
    gap: 40,
  },
  drawer: {
    '& .drawerBody': {
      padding: 0,
    },
  },
  noteBottomCard: {
    borderBottom: `1px solid ${colors.neutral100}`,
  },
  noteTexts: {
    padding: 32,
  },
  noteTarget: {
    lineHeight: '24px',
    fontWeight: 600,
    marginBottom: 16,
  },
  header: {
    position: 'absolute',
    top: -48,
    left: -64,
    height: 88,
    width: '100vw',
    borderBottom: `1px solid ${colors.neutral100}`,
    paddingLeft: 32,
    paddingRight: 32,
    display: 'flex',
    alignItems: 'center',
  },
  heading: {
    fontFamily: 'Source Serif Pro',
    margin: 0,
  },
  cardsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
  },
  card: {
    backgroundColor: '#FFF',
    borderRadius: 8,
    boxShadow:
      '0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04)',
  },
  chartWrap: {
    '& .fund-chart': {
      margin: '0px -40px',
      width: 'calc(100% + 80px)',
      boxShadow: 'none',
      borderRadius: 0,
      borderTop: `1px solid ${colors.neutral100}`,
      '& .apexcharts-legend': {
        display: 'none',
      },
    },
  },
  cardTopSection: {
    height: 72,
    width: '100%',
    paddingLeft: 24,
    paddingRight: 24,
    borderBottom: '1px solid',
    color: colors.neutral100,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  cardContentSection: {
    padding: 32,
  },
  noPaddingLeft: {
    padding: '32px 32px 32px 0',
  },
  calendarWrap: {
    '& .fc-scroller': {
      overflow: 'hidden !important',
    },
  },
  wrapDisableLabel: {
    '& div > span:first-child': {
      textDecorationLine: 'line-through',
    },
  },
  labelDisable: {
    textDecorationLine: 'line-through',
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 88,
    paddingLeft: 32,
    paddingRight: 32,
    borderBottom: '1px solid',
    color: colors.neutral100,
    '&:last-child': { borderBottom: 'none' },
  },
  textDark: {
    lineHeight: '24px',
    color: colors.neutral900,
  },
  textLight: {
    lineHeight: '19px',
    color: colors.neutral500,
  },
  inputWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    '& > label': {
      lineHeight: '20px',
      height: 20,
    },
    '& .label': {
      lineHeight: '20px',
      height: 20,
    },
  },
  emptyNote: {
    color: colors.neutral300,
    fontSize: 16,
    textAlign: 'center',
  },
  activeStatus: {
    height: 28,
    width: 100,
    padding: 0,
    borderRadius: 20,
    fontSize: 14,
    fontWeight: 600,
  },
  dot: {
    width: 6,
    height: 6,
    borderRadius: '50%',
    marginRight: 8,
    display: 'inline-block',
    backgroundColor: colors.neutral600,
  },
  dotActive: {
    backgroundColor: colors.green600,
  },
  inputsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 24,
  },
  horizontalInputsContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: 16,
    '& > *': {
      flex: 1,
    },
  },
  fundsDetailWrapper: {
    margin: '0 auto',
    paddingBottom: 32,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 60,
    justifyContent: 'center',
    '& > *': {
      flex: '50%',
      width: 142,
    },
  },
  fundDetail: {
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    gap: 32,
    padding: '16px 0',
    '&:nth-child(2)': {
      borderTop: `1px solid ${colors.neutral100}`,
      borderBottom: `1px solid ${colors.neutral100}`,
    },
    '& > .fundValue': {
      fontSize: 14,
      lineHeight: '20px',
      fontWeight: 700,
      margin: 0,
      minWidth: 50,
      textAlign: 'left',
      '&.big-val': {
        fontSize: 28,
        lineHeight: '36px',
        color: colors.neutral700,
        textAlign: 'center',
      },
      '&.added': {
        color: colors.success500,
      },
      '&.spent': {
        color: colors.secondary500,
      },
      '&.contributed': {
        color: colors.primary500,
      },
    },
    '& > .fundTitle': {
      fontSize: 14,
      lineHeight: '20px',
      fontWeight: 500,
      color: colors.neutral700,
      '&.big-title': {
        fontSize: 12,
        fontWeight: 800,
        color: colors.neutral500,
        textTransform: 'uppercase',
      },
    },
  },
  fundDetailItem: {},
  newFundWrapper: {
    margin: '0 -40px',
    padding: '0 40px 40px',
  },
  dollarSignAdornment: {
    position: 'relative',
    '&::before': {
      content: '""',
      display: 'block',
      backgroundColor: colors.neutral25,
      height: 48,
      width: 40,
      position: 'absolute',
      right: 0,
      borderRight: `1px solid ${colors.neutral200}`,
    },
  },
  dollarSignAdornmentText: {
    paddingLeft: 4,
    paddingRight: 16,
    position: 'relative',
  },
  addBtn: {
    height: 64,
    color: '#FFF',
    padding: '20px 24px',
    borderRadius: 8,
    whiteSpace: 'nowrap',
  },
  addScheduleBtn: {
    height: 48,
    color: '#fff',
    fontSize: 15,
    '& svg': {
      width: 20,
    },
  },
  subHeading: {
    fontFamily: 'Source Serif Pro',
    fontSize: 32,
    lineHeight: '40px',
    margin: 0,
  },
  careTeamCardContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 24,
  },
  recommendSlider: {
    '& .drawerBody': {
      padding: 0,
    },
    '& .select-provider': {
      height: 'calc(100vh - 160px)',
      overflow: 'hidden',
      '& .list-item': {
        height: 'calc(100% - 102px)',
        overflowY: 'auto',
        borderTop: `1px solid ${colors.neutral100}`,
        padding: 0,
      },
    },
    '& .drawer-footer button:first-child': {
      opacity: 0,
    },
  },
  noData: {
    fontSize: 18,
    lineHeight: '24px',
    color: colors.neutral600,
    margin: 0,
  },
  insuranceItemHead: {
    fontSize: 18,
    lineHeight: '24px',
    color: colors.neutral900,
    margin: 0,
  },
  gotoChat2: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  badge: {
    border: 0,
    height: 24,
    paddingLeft: 10,
    paddingRight: 10,
    borderRadius: 50,
    whiteSpace: 'nowrap',
  },
  statusBadge: {
    paddingRight: 10,
  },
  badgeOutlined: {
    height: 28,
    paddingLeft: 10,
    paddingRight: 10,
    borderRadius: 50,
    width: 'max-content',
  },
  careTeamCardsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
  },
  link: {
    fontSize: 14,
    lineHeight: '20px',
    fontWeight: 700,
    color: colors.primary600,
  },
  careTeamValueWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 12,
    '& svg': {
      minWidth: 20,
    },
  },
  chatBotCard: {
    background: '#FFF',
    filter:
      // eslint-disable-next-line
      'drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.04)) drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.06)) drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.04))',
    borderRadius: 8,
    '&:hover $actions': {
      display: 'flex',
    },
  },
  actions: {
    display: 'none',
    gap: 24,
  },
  chatBotCardHeader: {
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
    padding: 24,
  },
  chatBotCardHeaderCompletions: {
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
    padding: '18px 24px',
  },
  chatBotCardHeaderContent: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 16,
  },
  chatBotCardHeaderContentTitle: {
    lineHeight: '32px',
    color: colors.neutral900,
    margin: 0,
    width: '490px',
  },
  chatBotCardHeaderContentSubTitle: {
    color: colors.neutral600,
    lineHeight: '24px',
    margin: 0,
  },
  chatBotProgressText: {
    color: colors.neutral600,
    lineHeight: '16px',
    whiteSpace: 'nowrap',
  },
  chatBotAnswerWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '16px 0',
    marginBottom: 16,
    borderBottom: `1px solid ${colors.neutral100}`,
  },
  chatBotPrevHistory: {
    maxWidth: 370,
  },
  progressWrapper: {
    borderTop: `1px solid ${colors.neutral100}`,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: 24,
    gap: 24,
  },
  progressDetailWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      flex: '33%',
      padding: '24px 16px',
      border: `1px solid ${colors.neutral100}`,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      gap: 12,
      '& > svg': {
        height: 24,
        width: 24,
      },
    },
  },
  careTeamValue: {
    color: colors.neutral600,
    whiteSpace: 'nowrap',
  },
  chatbotCardsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
    minHeight: 'calc(100vh - 290px)',
  },
  chatBotCardHeaderWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingRight: 24,
    alignItems: 'baseline',
  },
  chatBotDrawer: {
    '& .MuiDrawer-paper': {
      maxWidth: 800,
      '& .drawerBody': {
        padding: 0,
      },
    },
  },
  chatBotDrawer2: {
    '& .MuiDrawer-paper': {
      maxWidth: 800,
      backgroundColor: colors.neutral50,
      '& .drawerHeader': {
        backgroundColor: colors.white,
      },
      '& .drawerBody': {
        padding: 0,
      },
    },
  },
  activitySelect: {
    width: 240,
    backgroundColor: colors.white,
  },
  activityCards: {
    display: 'flex',
    flexDirection: 'column',
    gap: 48,
    minHeight: 'calc(100vh - 290px)',
  },
  activityCard: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'relative',
    '&::before': {
      content: '""',
      display: 'block',
      height: 48,
      width: 4,
      backgroundColor: colors.neutral100,
      position: 'absolute',
      top: '100%',
      left: 26,
    },
  },
  activityCardLast: {
    '&::before': {
      display: 'none !important',
    },
  },
  activityIconWrapper: {
    height: 56,
    width: 56,
    minWidth: 56,
    borderRadius: '50%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'white',
    boxShadow:
      '0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04)',
    '& svg': {
      height: 24,
      width: 24,
    },
  },
  activityCardLeftSide: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 24,
  },
  activityCardTitle: {
    marginTop: 0,
    marginBottom: 4,
    lineHeight: '24px',
    color: colors.neutral900,
  },
  activityCardSubTitle: {
    margin: 0,
    lineHeight: '24px',
    color: colors.neutral600,
  },
  activityCardDate: {
    margin: 0,
    lineHeight: '20px',
    color: colors.neutral500,
  },

  drawerCollapsableAccordion: {
    '& .collapsableHeader': {
      justifyContent: 'flex-start',
      height: 48,
      gap: 8,
    },
    '& .collapsableIcon': {
      margin: 0,
      '& path': {
        fill: colors.neutral900,
      },
    },
  },
  collapsableWrapper: {
    paddingLeft: 12,
    paddingRight: 12,
    position: 'relative',
    '& .collapsableInfoCards': {
      padding: '24px 28px',
      display: 'flex',
      flexDirection: 'column',
      gap: 16,
    },
    '& .collapsableCardScoreText': {
      lineHeight: '48px',
      color: colors.neutral500,
      position: 'absolute',
      right: 12,
      top: 0,
    },
    '& .collapsableInfoCardWrapper': {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: colors.white,
      padding: 24,
      border: '1px solid #e5ebf0',
      borderRadius: 8,
    },
    '& .collapsableInfoCardTitle': {
      color: colors.neutral900,
      lineHeight: '24px',
    },
    '& .collapsableInfoCardSubTitle': {
      color: colors.neutral700,
      lineHeight: '24px',
    },
  },
  collapsableCardsWrapper: {
    padding: 24,
  },
  chatBotDrawerFooter: {
    borderTop: `1px solid ${colors.neutral100}`,
    padding: '0 24px',
    height: 72,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  drawerCancelBtn: {
    padding: 0,
    color: colors.primary600,
    fontSize: 14,
    fontWeight: 700,
    minWidth: 48,
    height: 48,
  },
  neutral700: { color: colors.neutral700 },
  groupCards: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
    minHeight: 'calc(100vh - 290px)',
  },
  groupCard: {
    padding: 24,
    gap: 24,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    background: '#FFFFFF',
    boxShadow: 'inset 0px -1px 0px #EDF1F5',
    borderRadius: 8,
    cursor: 'pointer',
    '& > img': {
      height: 100,
      width: 100,
      borderRadius: 6,
    },
    '& .groupCardJoinDate': {
      lineHeight: '24px',
      display: 'inline-block',
      color: colors.neutral600,
    },
    '& .groupCardTitle': {
      margin: 0,
      lineHeight: '32px',
      color: colors.neutral900,
    },
    '& .groupCardHeader': {
      display: 'flex',
      alignItems: 'center',
      gap: 8,
    },
    '& .groupCardBadge': {
      height: 28,
      borderRadius: 99,
      padding: '0 12px',
      fontWeight: 600,
      color: colors.neutral700,
      borderColor: colors.neutral200,
      textWrap: 'nowrap',
    },
  },
  groupsWrapper: {
    padding: '44px 40px 0 40px',
    borderTop: `1px solid ${colors.neutral100}`,
    '& .heading': {
      lineHeight: '24px',
      fontWeight: 700,
      color: colors.neutral900,
      margin: 0,
    },
    '& .contributionsWrapper': {
      paddingTop: 16,
      gap: 16,
      display: 'flex',
      flexDirection: 'column',
      '& .sessionCard': {
        height: 72,
        borderBottom: `1px solid ${colors.neutral100}`,
        '&:last-child': {
          borderBottom: '0px',
        },
      },
      '& .contribution': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',

        '& .statusWrapper': {
          display: 'flex',
          flexDirection: 'row',
          gap: 8,
          alignItems: 'center',
          '& *': {
            color: colors.destructive600,
            fill: colors.destructive600,
          },
          '&.statusTrue *': {
            color: colors.success500,
            fill: colors.success500,
          },
        },
        '& .title': {
          lineHeight: '24px',
          color: colors.neutral700,
        },
        '& .date': {
          lineHeight: '20px',
          color: colors.neutral500,
        },
      },
    },
    '& .sessionsWrapper': { paddingTop: 8 },
  },
  pt40: {
    paddingTop: 40,
  },
  groupCardCloseIcon: {
    width: 24,
    height: 24,
    color: colors.primary500,
    cursor: 'pointer',
    marginTop: 10,
  },
  groupDrawerHeader: {
    height: 108,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    borderBottom: `1px solid ${colors.neutral100}`,
    padding: '18px 20px 26px 24px',
    '& .headerImgWrapper': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: 16,
      '& img': {
        height: 64,
        width: 64,
        borderRadius: 6,
      },
    },
  },
  groupHeadSubTitle: {
    marginTop: 4,
    lineHeight: '24px',
    color: colors.neutral600,
    display: 'inline-block',
  },
  groupHeadTitle: {
    margin: 0,
    lineHeight: '32px',
    color: colors.neutral900,
  },
  noteHeaderRightSide: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 16,
  },
  noteCardWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 24,
    alignItems: 'center',
  },
  noteHeaderLeftSide: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 16,
  },
  totalBadge: {
    borderRadius: 100,
    height: 28,
  },
  table: {},
  diagnosesCard: {
    '& .border-top': {
      borderTop: `1px solid ${colors.neutral100}`,
    },
  },
  loadingWrap: {
    '& svg': {
      marginRight: 12,
    },
  },
  loadMoreBtn: {
    fontSize: 14,
  },
  completionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    gap: 8,
    padding: 40,
    backgroundColor: colors.neutral50,
  },
  heatmapChart: {
    height: 73,
    padding: '24px 0 24px 24px',
    backgroundColor: colors.white,
    boxShadow:
      '0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04)',
    borderRadius: 8,
    '& div': {
      minHeight: 'unset !important',
    },
    '& .apexcharts-svg': {
      transform: 'translate(0, 0) !important',
    },
    '& .apexcharts-inner.apexcharts-graphical': {
      transform: 'translate(0, 0) !important',
    },
  },
  noPadding: {
    padding: 0,
    height: 'unset',
    fontSize: 14,
    lineHeight: '20px',
  },
  insuranceItemHeadSub: {
    width: 200,
    marginRight: 40,
    color: colors.neutral900,
  },
  insuranceItemLabel: {
    color: colors.neutral700,
  },
  noImage: {
    width: '233px',
    height: '141px',
    border: `1px solid ${colors.neutral100}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  startAdorment: {
    marginRight: '28px !important',
    '&::after': {
      content: '"" !important',
      position: 'absolute',
      backgroundColor: colors.neutral25,
      borderRight: `1px solid ${colors.neutral100}`,
      width: 40,
      height: 52,
      left: -16,
      top: -16,
      bottom: -32,
      zIndex: '-1 !important',
    },
  },
  startAdorment2: {
    marginRight: '28px !important',
    '&::after': {
      content: '"" !important',
      position: 'absolute',
      backgroundColor: colors.neutral25,
      borderRight: `1px solid ${colors.neutral100}`,
      width: 55,
      height: 52,
      left: -16,
      top: -16,
      bottom: -32,
      zIndex: '-1 !important',
    },
  },
  section: {
    padding: 10,
    gap: 12,
    display: 'flex',
    width: 242,
    height: 60,
  },
  sectionAutoSize: {
    padding: 10,
    gap: 12,
    display: 'flex',
    width: 'auto',
    height: 'auto',
  },
  appointFreqText: {
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
    marginTop: -2,
    // paddingTop: 2,
  },
  colLabel: {
    color: colors.neutral500,
    lineHeight: '20px',
  },
  colLabel2: {
    lineHeight: '20px',
  },
  iconSize: {
    fontSize: 40,
    borderRadius: 4,
    color: 'white',
    padding: 7,
  },
  iconSize2: {
    fontSize: 40,
    borderRadius: 4,
    color: '#667786',
    padding: 9,
  },
  daily: {
    background: colors.success50,
    borderRadius: 6,
  },
  dailyIcon: {
    background: colors.success500,
  },
  weekly: {
    background: colors.indigo50,
    borderRadius: 6,
  },
  weeklyIcon: {
    background: colors.indigo500,
  },
  biweekly: {
    background: colors.cyan50,
    borderRadius: 6,
  },
  biweeklyIcon: {
    background: colors.cyan500,
  },
  monthly: {
    background: colors.warning50,
    borderRadius: 6,
  },
  monthlyIcon: {
    background: colors.warning500,
  },
  asNeeded: {
    background: colors.secondary50,
    borderRadius: 6,
  },
  asNeededIcon: {
    background: colors.secondary500,
  },
  noLongerInCare: {
    background: colors.destructive50,
    borderRadius: 6,
  },
  noLongerInCareIcon: {
    background: colors.destructive500,
  },
  scheduleBtn: {
    paddingLeft: 0,
    height: 20,
    textTransform: 'none',
  },
  careTeamBtn: {
    height: 40,
    minWidth: '40px !important',
    width: 40,
    padding: 0,
  },
  levelOfEngagementSelect: {
    display: 'grid',
    gridTemplateColumns: '49% 49%',
  },
  frequency: {
    display: 'flex',
    gap: 6,
  },
  documentToPrint: {
    padding: 20,
    overflowY: 'scroll',
    maxHeight: 600,
    maxWidth: 700,
  },
  appointmentBox: {
    paddingLeft: 20,
    pageBreakInside: 'avoid',
  },
  sectionBoxTop: {
    paddingLeft: 20,
    pageBreakInside: 'avoid',
  },
  sectionBox: {
    paddingLeft: 20,
    paddingTop: '10%',
    pageBreakInside: 'avoid',
  },
  sectionBoxPageBreakBefore: {
    paddingLeft: 20,
    pageBreakInside: 'avoid',
    pageBreakBefore: 'always',
  },
  sectionBoxPageBreakAfter: {
    paddingLeft: 20,
    pageBreakAfter: 'always',
  },
  paddingTop10: {
    paddingTop: '10%',
  },
  evaluationBox: {
    paddingLeft: 20,
    pageBreakInside: 'auto',
  },
  dctBox: {
    paddingLeft: 20,
    pageBreakInside: 'avoid',
  },
  qnaBox: {
    paddingLeft: 20,
    pageBreakInside: 'auto',
  },
  documentTitle: { color: colors.dark },
  appointmentTitle: { color: colors.dark },
  evaluationTitle: { color: colors.dark },
  dctTitle: {
    color: colors.neutral600,
  },
  question: {
    color: colors.dark,
  },
  answer: {
    color: colors.primary,
  },
  cptList: {
    marginTop: '-20px',
  },
  codes: {
    display: 'flex',
    flexDirection: 'row',
    gap: 32,
  },
  coverPage: {
    pageBreakAfter: 'always',
    display: 'flex',
    justifyContent: 'center',
  },

  title: {
    color: colors.primary,
    fontWeight: 700,
    textDecoration: 'none',
    verticalAlign: 'baseline',
    fontSize: '12pt',
    textAlign: 'center',
    fontFamily: 'Manrope',
    fontStyle: 'normal',
  },
  sectionTitleLeft: {
    color: colors.dark,
    fontWeight: 700,
    textDecoration: 'none',
    verticalAlign: 'baseline',
    fontSize: '12pt',
    textAlign: 'left',
    fontFamily: 'Manrope',
    fontStyle: 'normal',
  },
  sectionTitleCenter: {
    color: colors.dark,
    fontWeight: 700,
    textDecoration: 'none',
    verticalAlign: 'baseline',
    fontSize: '12pt',
    textAlign: 'center',
    fontFamily: 'Manrope',
    fontStyle: 'normal',
  },
  sectionSubTitleCenter: {
    color: colors.primary,
    fontWeight: 700,
    textDecoration: 'none',
    verticalAlign: 'baseline',
    fontSize: '12pt',
    textAlign: 'center',
    fontFamily: 'Manrope',
    fontStyle: 'normal',
  },
  sectionSubTitleLeft: {
    color: colors.primary,
    fontWeight: 700,
    textDecoration: 'none',
    verticalAlign: 'baseline',
    fontSize: '12pt',
    textAlign: 'left',
    fontFamily: 'Manrope',
    fontStyle: 'normal',
  },
  exportDate: {
    dispaly: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'end',
  },
  textRight: {
    textAlign: 'right',
  },
  c2: {
    textDecoration: 'none',
    verticalAlign: 'baseline',
    textAlign: 'center',
    fontStyle: 'normal',
    color: colors.primary,
    fontWeight: 700,
    fontSize: '13pt',
  },
  c5: {
    color: colors.primary,
    fontWeight: 700,
    textDecoration: 'none',
    verticalAlign: 'baseline',
    fontSize: '11pt',
    textAlign: 'center',
    fontStyle: 'normal',
  },
  c4: {
    color: colors.primary,
    fontWeight: 700,
    textDecoration: 'none',
    verticalAlign: 'baseline',
    fontSize: '9pt',
    textAlign: 'center',
    fontStyle: 'normal',
  },
  c1: {
    color: colors.dark,
    fontWeight: 400,
    textDecoration: 'none',
    verticalAlign: 'baseline',
    fontSize: '11pt',
    textAlign: 'center',
    fontStyle: 'normal',
  },
  p: {
    margin: 0,
    color: colors.dark,
    fontSize: '11pt',
  },
  bottomBox: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '50%',
  },
  middleBox: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '40%',
    gap: '8px',
  },
  topBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
  bottomInnerBox: {
    display: 'flex',
    flexDirection: 'column',
  },
  coverDownlad: {
    display: 'flex',
    flexDirection: 'column',
    padding: 96,
    pageBreakInside: 'avoid',
    justifyContent: 'center',
    pageBreakAfter: 'always',
    width: '100%',
    marginLeft: '50px',
  },
  imageBox: {
    overflow: 'hidden',
    display: 'inline-block',
    marginBottom: '-20px',
    border: `0px solid ${colors.dark}`,
    transform: 'rotate(0rad) translateZ(0px)',
    WebkitTransition: 'rotate(0rad) translateZ(0px)',
    width: '321.5px',
    height: '84.5px',
  },
  imageTag: {
    width: '321.5px',
    height: '84.5px',
    marginLeft: '0px',
    marginTop: '0px',
    transform: 'rotate(0rad) translateZ(0px)',
    webkitTransform: 'rotate(0rad) translateZ(0px)',
  },
  searchHeader: {
    width: '100%',
    backgroundColor: colors.white,
    borderRadius: 8,
    boxShadow: '0px 0px 6px #EDF1F5',
    padding: '0 12px',
  },
  insuranceHeaderButtons: {
    display: 'flex',
    gap: 8,
  },
  searchBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: 32,
    padding: 24,
  },
  searchInput: {
    height: 48,
  },
  factorStatusBadge: {
    borderRadius: 4,
  },
  diagnoseStatusBadge: {
    height: 24,
    borderRadius: 100,
  },
  headBtn: {
    borderColor: colors.primary300,
    color: colors.primary500,
    borderRadius: 6,
  },
  primaryBtn: {
    color: colors.white,
    borderRadius: 6,
  },
  iconBtn: {
    gap: 8,
  },
  headingWithBtnWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBottom: 4,
  },
  chart: {
    position: 'relative',
    height: 'auto',
    paddingBottom: 20,
    '& .legend-badge-value': {
      display: 'inline-block',
      marginLeft: '8px',
      fontSize: 12,
      height: 18,
      lineHeight: '16px',
      fontFamily: 'Manrope',
      fontWeight: '600',
      color: colors.neutral700,
      borderRadius: '20px',
      border: `1px solid ${colors.neutral200}`,
      padding: '1px 8px 0',
    },
    '& .apexcharts-legend.apx-legend-position-right': {
      overflow: 'hidden !important',
      // textOverflow: 'ellipsis',
      // whiteSpace: 'nowrap',
    },
    '& .apexcharts-legend-marker': {
      marginRight: 16,
    },
    '& .apexcharts-legend-series': {
      [theme.breakpoints.up(1670)]: {
        margin: '0 0 16px !important',
      },
      [theme.breakpoints.down(1670)]: {
        margin: '0 !important',
      },
    },
    '& .legend-wrap': {
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      gap: 32,
      marginLeft: 16,
      '&-left': {
        width: 200,
      },
    },
    '& .chartWrap-tooltip': {
      padding: 16,
      backgroundColor: colors.white,
      width: 'fit-content',
      '& .date': {
        fontSize: '14px !important',
        lineHeight: '20px',
        color: colors.neutral600,
      },
      '& .level': {
        color: colors.neutral900,
        fontWeight: 700,
      },
      '& .row': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: 8,
        whiteSpace: 'wrap',
        marginTop: 12,
        '& .value': {
          borderRadius: '50%',
          width: 22,
          height: 22,
          border: `1px solid ${colors.neutral200}`,
          fontSize: 12,
          lineHeight: '16px',
          fontWeight: 700,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: colors.neutral700,
          textAlign: 'center',
        },
        '& .row-left': {
          display: 'flex',
          alignItems: 'center',
        },
        '& .dot': {
          width: 12,
          height: 12,
          borderRadius: '50%',
          marginRight: 8,
          '&.dot-0': {
            backgroundColor: colors.primary500,
          },
          '&.dot-1': {
            backgroundColor: colors.secondary500,
          },
          '&.dot-2': {
            backgroundColor: colors.neutral600,
          },
        },
      },
    },
    '& .apexcharts-canvas svg': {
      width: 'calc(100vw - 550px)',
      '& foreignObject': {
        width: '100%',
      },
    },
    '& .apexcharts-legend.apexcharts-align-center.apx-legend-position-right': {
      width: '900px !important',
      display: 'grid',
      left: '164px !important',
      gridTemplateColumns: 'repeat(4, 213px)',
      height: '148px !important',
      [theme.breakpoints.down(1755)]: {
        gridTemplateColumns: 'repeat(3, 213px)',
      },
      [theme.breakpoints.down(1530)]: {
        gridTemplateColumns: 'repeat(3, 180px)',
      },
      [theme.breakpoints.down(1430)]: {
        gridTemplateColumns: 'repeat(2, 200px)',
      },
      [theme.breakpoints.down(1330)]: {
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'auto !important',
      },
    },
  },
  increasedWidthDrawer: {
    '& .MuiPaper-root': {
      maxWidth: '800px !important',
      overflowY: 'hidden',
    },
    '& .drawerBody': {
      backgroundColor: colors.neutral50,
      padding: 0,
      minHeight: 'calc(100vh - 80px)',
      overflowY: 'hidden',
    },
    '& $items': {
      height: 'calc(100vh - 113px - 80px)',
    },
  },
  factorDrawer: {
    '& .MuiPaper-root': {
      maxWidth: '640px !important',
    },
    '& .drawerBody': {
      padding: 0,
    },
  },
  collapseHeader: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    background: ({ active }) => (active ? colors.neutral50 : 'transparent'),
    '& .collapsableIcon': {
      margin: 0,
      '& path': {
        fill: colors.primary500,
      },
    },
  },
  chevronIcon: {
    width: 24,
    minWidth: 24,
    height: 24,
    objectFit: 'contain',
    transition: '0.4s',
    marginLeft: '12px',
    marginRight: '12px',
    transform: ({ collapsed }) => `rotate(${collapsed ? '180deg' : 0})`,
  },
  icon: {
    height: 32,
    width: 32,
  },
});
