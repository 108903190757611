import { theme } from '../../../../../packages/theme';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  bodyBox: {
    height: '72px',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      alignItems: 'flex-start',
      paddingTop: 16,
      height: '64px',
    },
  },
  parentBody: {
    display: 'flex',
    alignItems: 'center',
  },
  childBody: {
    cursor: 'pointer',
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingRight: '32px',
  },
  bodyDivider: {
    borderColor: '#EDF1F5',
  },
  checkbox: {
    marginLeft: '24px',
    marginRight: '24px',
  },
  profileInfo: {
    height: '40px',
    textOverflow: 'ellipsis',
  },
  checkboxChecked: {
    opacity: 0.4,
    [theme.breakpoints.down('md')]: {
      opacity: 1,
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    gap: 12,
  },
  profileContainer: {
    width: 300,
  },
  typeContainer: {
    fontFamily: 'Manrope',
    fontSize: 14,
    fontWeight: 500,
    display: 'flex',
    alignItems: 'center',
  },
  badge: {
    height: 24,
    borderRadius: 100,
    paddingTop: 2,
    paddingBottom: 2,
    paddingLeft: 10,
    paddingRight: 10,
  },
  profileBox: {
    maxWidth: '81%',
    '& > :nth-child(3)': {
      maxWidth: 'unset !important',
    },
  },
  badgeMobile: {
    height: 18,
    borderRadius: 100,
    paddingLeft: 10,
    paddingRight: 10,
    width: 'fit-content',
  },
  todoItemProfileContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '46%',
    gap: '12px',
  },
  todoItemMemberProfile: {
    display: 'flex',
    flexDirection: 'row',
    width: '48%',
    '& .sb-avatar__image': {
      maxWidth: 'unset !important',
    },
  },
  todoItemProviderProfile: {
    display: 'flex',
    flexDirection: 'row',
    width: '48%',
    '& .sb-avatar__image': {
      maxWidth: 'unset !important',
    },
  },
  todoItemDueDateContainer: {
    display: 'flex',
    width: '136px',
    justifyContent: 'end',
  },
  todoTextContainer: {
    display: 'flex',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingLeft: '12px',
    gap: '4px',
    width: '54%',
  },
  textOverflow: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: '32rem',
  },
});
