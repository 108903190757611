import { FC, useState } from 'react';
import { Box } from '@mui/material';
import clsx from 'clsx';
import { Icons } from '../../../../../packages/icons';
import { Toggle } from '../../../../../packages/ui/atoms/toggle';
import { Badge, badgeStyle, badgeType } from '../../../../../packages/ui/atoms/badge';
import { IconButton, iconBtnPosition } from '../../../../../packages/ui/molecules/icon-button';
import { colors } from '../../../../../packages/colors';
import { convertMinutesToHours } from '../../../../../utils/CommonUtils';

import { IService } from '../../../../../redux/modules/appointment/types';
import { useStyles } from './ServiceItem.styles';

const ServiceItem = ({ service, isEditable = false, onEditService, toggleService }) => {
  const classes = useStyles();
  const [isChecked, setIsChecked] = useState(service.serviceAvailable);

  const displayValue = `${convertMinutesToHours(service.duration)}, Recommended Cost = $${
    service.recommendedCost
  }`;

  const onChangeToggle = () => {
    toggleService && toggleService(service);
    setIsChecked(!isChecked);
  };

  const onClickEditing = () => {
    onEditService && onEditService(service);
  };

  return (
    <Box display="flex" justifyContent="space-between" alignItems="flex-start" width="100%">
      <Box display="flex" gap="2px" alignItems="flex-start" width="100%">
        {isEditable && (
          <Box className={classes.toggleWrap}>
            <Toggle checked={isChecked} onChange={onChangeToggle} />
          </Box>
        )}
        <Box display="flex" justifyContent="space-between" width="100%">
          <Box display="flex" flexDirection="column" gap="2px">
            <Box className={classes.headerService}>{service.serviceTypes?.[0]}</Box>
            <Box className={classes.titleDuration}>{service.name}</Box>
            <Box className={classes.descriptionAffiliation}>{displayValue}</Box>
          </Box>
          {!isEditable && (
            <Box display="flex" justifyContent="center" alignItems="center">
              <Badge
                variant={badgeType.OUTLINED}
                style={service.serviceAvailable ? badgeStyle.RESOLVED : badgeStyle.UNRELATED}
                className={classes.status}
              >
                <span className={clsx(classes.dot, { [classes.dotActive]: service.serviceAvailable })} />{' '}
                {service.serviceAvailable ? 'Active' : 'Inactive'}
              </Badge>
            </Box>
          )}
        </Box>
      </Box>
      {service.isDefault ? (
        <Box display="flex" alignItems="center" className={classes.wrapperServiceDefault}>
          <Icons className={classes.wrapperIconStar} color={colors.secondary500} glyph="rating-filled" />
          Confidant default
        </Box>
      ) : (
        <>
          {isEditable && (
            <IconButton
              icon="edit-underline"
              onClick={onClickEditing}
              iconPosition={iconBtnPosition.RIGHT}
              className={classes.editBtn}
            >
              Edit
            </IconButton>
          )}
        </>
      )}
    </Box>
  );
};

export { ServiceItem };
