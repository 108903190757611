import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { createLogger } from 'redux-logger';
import { compact } from 'lodash';
import { persistStore } from 'redux-persist';

import rootReducer from './reducers';
import sagas from './sagas';

export default function initializeStore() {
  const sagaMiddleware = createSagaMiddleware();

  const middlewares = compact([
    sagaMiddleware,
    createLogger({
      collapsed: (getState, action, logEntry) => !logEntry.error,
    }),
  ]);

  // eslint-disable-next-line no-underscore-dangle
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const store = createStore(rootReducer, {}, composeEnhancers(applyMiddleware(...middlewares)));

  sagaMiddleware.run(sagas, store);

  const persistor = persistStore(store, null, () => {
    store.getState();
  });

  return { store, persistor };
}
