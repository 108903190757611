import { FC, useEffect, useState } from 'react';

import AppointmentCard from './AppointmentCard';
import AssignedEvaluationDrawer from './AssignedEvaluationDrawer';
import EvaluationDetail from './evaluation-detail';
import { getAppointmentEvaluationProgress } from '../../../../services/conversation/conversation.service';

const AppointmentWithEvals = ({
  appointment,
  assignedEvaluations,
  fetchEvaluationsOnLoad,
  isPast,
  hideNotes,
  title = '',
  viewApptDetail,
  showViewDetails = false,
}) => {
  const [selectedEvaluation, setSelectedEvaluation] = useState(null);
  const [evaluations, setEvaluations] = useState(assignedEvaluations || []);
  const [showAssignedEvaluations, setShowAssignedEvaluations] = useState(false);
  const { appointmentId } = appointment;

  useEffect(() => {
    if (fetchEvaluationsOnLoad) {
      getAppointmentEvaluationProgress({ appointmentId })
        .then(evaluationProgressDetailList => {
          if (evaluationProgressDetailList?.status !== 200) {
            throw new Error('Whoops ! Something went wrong . Please try later');
          } else {
            const responseList = evaluationProgressDetailList?.data || [];
            setEvaluations(responseList);
          }
        })
        .catch(error => {
          console.log('Whoops ! Something went wrong . Please try later', error);
        });
    }
  }, [fetchEvaluationsOnLoad]);

  const onSelectAssignedEvaluation = evaluation => {
    setSelectedEvaluation(evaluation);
  };

  const onSeeAll = () => {
    setShowAssignedEvaluations(true);
  };

  return (
    <>
      <AppointmentCard
        onSelectAssignedEvaluation={onSelectAssignedEvaluation}
        evaluations={evaluations}
        onSeeAll={onSeeAll}
        isPast={isPast}
        appointment={appointment}
        isWaiting={hideNotes}
        title={title}
        viewApptDetail={viewApptDetail}
        showViewDetails={showViewDetails}
      />
      {showAssignedEvaluations && (
        <AssignedEvaluationDrawer
          onEvaluationSelected={onSelectAssignedEvaluation}
          evaluations={evaluations}
          open={showAssignedEvaluations}
          past={isPast}
          onClose={() => setShowAssignedEvaluations(false)}
        />
      )}
      {selectedEvaluation && (
        <EvaluationDetail
          isOpen={!!selectedEvaluation}
          onClose={() => setSelectedEvaluation(null)}
          evaluation={selectedEvaluation}
          memberId={appointment?.patientId}
          appointmentId={appointmentId}
          isView={false}
        />
      )}
    </>
  );
};

export default AppointmentWithEvals;
