import { useEffect, useMemo, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Box, Stack } from '@mui/material';
import dayjs from 'dayjs';
import {
  Breadcrumbs,
  colors,
  fontWeight,
  Heading,
  headingLevel,
  iconBtnType,
  IconButton,
  Icons,
  Menu,
  Text,
  textLevel,
  useQuery,
} from '../../../packages';
import { BaseLayout } from '../../../layouts/base';
import {
  getDCTDetails,
  getEvaluationDCTAssignmentDetail,
  saveContentBlock,
} from '../../../services/conversation/conversation.service';
import { showSnackbar } from '../../../redux/modules/snackbar';
import { CONVERSATION_TYPES } from '../../../constants/CommonConstants';
import ConversationFlow from '../conversation/components/conversation-flow';
import SelectedComponent from './DctDetail.selected';
import { rightSideNavList } from './DctDetail.constants';
import { path2type } from '../conversation/ConversationDetail.mock';

import { useStyles } from './DctDetail.styles';

const DctDetail = () => {
  const classes = useStyles();
  const query = useQuery();
  const { templateId, version } = useParams();
  const isDraft = query.get('isDraft');
  const [selectedScreen, setSelectedScreen] = useState('assignments');
  const [isLoading, setIsLoading] = useState(false);
  const [dct, setDct] = useState();
  const [selectedVersion, setSelectedVersion] = useState(parseInt(version ?? '1', 10));
  const [showFlow, setShowFlow] = useState(false);
  const [versions] = useState(version && version !== undefined ? [version] : [1]);

  const location = useLocation();
  const state = location.state;
  const path = location.pathname.split('/')[2];

  const versionOptions = useMemo(() => {
    return versions.map(item => ({
      label: `v${item}`,
      value: item,
      onClick: () => setSelectedVersion(Number(item)),
    }));
  }, [versions]);

  const fetchDctDetails = () => {
    setIsLoading(true);
    if (path2type[path].type === CONVERSATION_TYPES.EVALUATION_DCT) {
      getEvaluationDCTAssignmentDetail(templateId, selectedVersion)
        .then(res => {
          setDct({
            id: templateId,
            ...res.data,
            dct: { ...res.data.dct, status: isDraft === 'true' ? 'DRAFT' : 'ACTIVE' },
          });

          if (state?.openFlow) {
            setShowFlow(true);
          }
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
      return;
    }
    getDCTDetails(templateId, selectedVersion)
      .then(res => {
        setDct({ id: templateId, ...res.data });

        if (state?.openFlow) {
          setShowFlow(true);
        }
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (state?.openFlow) {
      setShowFlow(false);
    }

    void fetchDctDetails();
  }, [templateId, selectedVersion]);

  useEffect(() => {
    const getQuery = query.get('name');
    if (!getQuery) {
      query.set('name', 'assignments');
      window.location.search = query.toString();
    } else {
      setSelectedScreen(getQuery);
    }
  }, [query]);

  const onSaveContentBlock = async (contentBlockId, updatedContentBlock) => {
    // content block save API call
    const response = await saveContentBlock(contentBlockId, updatedContentBlock);
    if (response.status !== 200) {
      showSnackbar({ snackType: 'error', snackMessage: response.data.errors[0].endUserMessage });
    } else {
      showSnackbar({ snackType: 'success', snackMessage: 'Content block saved' });
    }
  };

  return (
    <>
      {showFlow ? (
        <ConversationFlow
          dct={dct}
          conversationType={path2type[path].type}
          onSave={onSaveContentBlock}
          onClose={() => setShowFlow(false)}
        />
      ) : (
        <BaseLayout
          rightSideNavList={rightSideNavList}
          rightSideBarWidth="320px"
          rightSidebarHeadCustom={
            <Heading level={headingLevel.S} weight={fontWeight.BOLD}>
              DCT
            </Heading>
          }
          propType="EVALUTIONS"
        >
          <Box className={classes.root}>
            <Box className={classes.header}>
              <Breadcrumbs
                links={[
                  { href: `/admin/${path}`, text: path2type[path].text },
                  { text: dct?.dctName ?? dct?.dct?.name },
                ]}
              />
            </Box>
            <Box>
              <Stack direction="row" justifyContent="space-between" spacing={2}>
                <Stack direction="column" gap={1}>
                  <Stack direction="row" gap={2} alignItems="center">
                    <Heading className={classes.heading} level={headingLevel.XL} weight={fontWeight.BOLD}>
                      {dct?.dctName ?? dct?.dct?.name}
                    </Heading>
                    <Menu
                      icon="chevron-down"
                      items={versionOptions}
                      itemsWrapperClassName={classes.menuLabel}
                      className={classes.menu}
                    >
                      <Text className={classes.menuLabel} level={textLevel.S}>
                        v{selectedVersion}
                      </Text>
                    </Menu>
                  </Stack>
                  <Text className={classes.subtitle}>
                    Updated on {dayjs(dct?.dct?.lastUpdated).format('MM/DD/YYYY')}
                  </Text>
                </Stack>
                <IconButton
                  className={classes.editBtn}
                  variant={iconBtnType.PRIMARY}
                  onClick={() => setShowFlow(true)}
                >
                  Open Flow
                </IconButton>
              </Stack>
              {!isLoading ? (
                <SelectedComponent selectedScreen={selectedScreen} version={selectedVersion} dct={dct} />
              ) : (
                <div className={classes.loader}>
                  <Icons className="rotate linear infinite" glyph="in-progress" color={colors.primary} />
                  Loading...
                </div>
              )}
            </Box>
          </Box>
        </BaseLayout>
      )}
    </>
  );
};

export { DctDetail };
