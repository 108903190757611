import { useMemo } from 'react';
import { Box } from '@mui/material';
import clsx from 'clsx';
import { fontWeight, iconBtnType, IconButton, Icons, Text } from '../../../../../../../packages';
import { Drawer, drawerType } from '../../../../../../../packages/ui/organisms/drawer';
import { SymptomChart } from '../../../Symptoms/symptom-detail/symptom-chart';
import { SideEffectBottomCard } from '../side-effect-card/SideEffectBottomCard';
import { useStyles } from './SideEffectDetail.styles';

const SideEffectDetail = ({ open, onClose, item, isNoDrawer, onClickNewReport, priorityType }) => {
  const classes = useStyles();

  const fullHistory = useMemo(() => {
    if (item) {
      const { history, priority, ...rest } = item;
      const current = { ...rest, priority, importanceLevel: priority };
      return history ? [current, ...history] : [current];
    }
    return [];
  }, [item]);

  // const [selectedTime, setSelectedTime] = useState(AppointmentsFilterDates[2]);

  // const times = AppointmentsFilterDates.map(time => ({
  //   ...time,
  //   onClick: () => setSelectedTime(time),
  // }));

  const renderContent = () => (
    <Box className={classes.container}>
      <Box className={clsx(classes.header, { [classes.headerNoDrawer]: isNoDrawer })}>
        <Box>
          <Text weight={fontWeight.BOLD} className={classes.headTitle}>
            All Reports - {item.name}
          </Text>
          <Text weight={fontWeight.BOLD} className={classes.headSubtitle}>
            {item.description}
          </Text>
        </Box>
        <IconButton icon="close" className={classes.closeBtn} onClick={onClose} />
      </Box>
      <Box className={clsx(classes.content, { [classes.contentNoDrawer]: isNoDrawer })}>
        {!isNoDrawer && (
          <Box className={classes.contentFilter}>
            <Text weight={fontWeight.BOLD} className={classes.contentTitle}>
              Importance level <Icons glyph="question-outlined" className={classes.question} />
            </Text>
            {/* <Menu icon="chevron-down" items={times} itemsWrapperClassName={classes.menuLabel}>
              <Text className={classes.menuLabel} level={textLevel.S}>
                {selectedTime.label}
              </Text>
            </Menu> */}
          </Box>
        )}
        <Box className={classes.contentChart}>
          <SymptomChart name={priorityType} items={fullHistory} />
        </Box>
        <Box>
          {fullHistory?.map((report, index) => (
            <SideEffectBottomCard key={index} item={report} showBadge />
          ))}
        </Box>
      </Box>
      <Box className={classes.footer}>
        <IconButton variant={iconBtnType.TEXT} onClick={onClose}>
          Close
        </IconButton>
        <IconButton
          // icon="plus"
          onClick={onClickNewReport}
          className={classes.newReportBtn}
          variant={iconBtnType.PRIMARY}
        >
          Update report
        </IconButton>
      </Box>
    </Box>
  );

  if (isNoDrawer) {
    return <>{renderContent()}</>;
  }

  return (
    <Drawer open={open} onClose={onClose} variant={drawerType.NORMAL} className={classes.drawer}>
      {item && renderContent()}
    </Drawer>
  );
};

export { SideEffectDetail };
