import cx from 'clsx';

// mui
import MuiRadioGroup from '@mui/material/RadioGroup';
import { Box, Typography } from '@mui/material';
// components
import { Radio } from '../../atoms/radio';
import { FormControlLabel } from '../../atoms/form-control-label';

// styles
import { useStyles } from './RadioGroup.styles';

// types
import { radioGroupType } from './RadioGroup.types';

const RadioGroup = ({
  variant = radioGroupType.VERTICAL,
  defaultValue = undefined,
  className = '',
  showSubLabel = false,
  options = [],
  ...rest
}) => {
  const classes = useStyles();

  return (
    <MuiRadioGroup
      className={cx({
        [classes.root]: true,
        [classes.horizontal]: variant === radioGroupType.HORIZONTAL,
        [className || '']: className,
      })}
      {...rest}
      defaultValue={defaultValue || options[0]?.value}
    >
      {!showSubLabel
        ? options.map(({ label, value }, idx) => (
            <FormControlLabel key={idx} value={value} label={label} control={<Radio />} />
          ))
        : options.map(({ label, value, subLabel }, idx) => (
            <Box key={idx}>
              <FormControlLabel value={value} label={label} control={<Radio />} />
              {subLabel && <Typography className={classes.subLabel}>{subLabel}</Typography>}
            </Box>
          ))}
    </MuiRadioGroup>
  );
};

export { RadioGroup };
