import { useState } from 'react';
import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import { fontWeight, Text, textLevel } from '../../../../../../packages/ui/atoms/typography';
import { UploadFile } from '../../../../../../packages/ui/templates/upload-file';
import UploadFileProgress from '../upload-flie-progress';
import { useStyles } from '../../../MemberDetail.styles';

function getFileExtension(filename) {
  // get file extension
  if (!filename) return '';
  const extension = filename.substring(filename.lastIndexOf('.') + 1, filename.length);
  return extension;
}

const IdCardUpload = ({ label, onUpload, type = 'front' }) => {
  const classes = useStyles({});
  const [imageFile, setImageFile] = useState(null);
  const [, setFileUpdated] = useState(false);
  const { memberId } = useParams();

  const onAcceptedFiles = files => {
    const file = files[0];
    const name = `${memberId}Photo${type}.${getFileExtension(file.name)}`;
    const newFile = new File([file], name, { type: file.type });
    setImageFile(newFile);
    setFileUpdated(true);
  };

  return (
    <Box>
      <Box className={classes.inputsContainer}>
        <Box className={classes.inputWrapper}>
          <Text className="label" level={textLevel.S} weight={fontWeight.BOLD}>
            <span>{label}</span>
          </Text>
        </Box>
      </Box>
      <Box marginY={2}>
        {imageFile ? (
          <UploadFileProgress
            file={imageFile}
            fileName={imageFile?.name || 'logo.png'}
            fileSize={imageFile?.size || 1024}
            onUploaded={val => {
              setImageFile(val);
              onUpload(val, type);
            }}
            onRemove={() => {
              setImageFile(null);
            }}
          />
        ) : (
          <UploadFile
            onAcceptedFiles={onAcceptedFiles}
            description="PNG, JPG, GIF up to 5MB"
            fileType="image/*"
          />
        )}
      </Box>
    </Box>
  );
};

export default IdCardUpload;
