import makeStyles from '@mui/styles/makeStyles';
import { LogoOrientation } from './Logo.types';

export const useStyles = makeStyles(() => ({
  root: {
    display: 'inline-flex',
    flexDirection: ({ orientation }) => (orientation === LogoOrientation.HORIZONTAL ? 'row' : 'column'),
    alignItems: 'center',
    cursor: 'pointer',
    gap: 8,

    '& .logomark': {
      lineHeight: 0,
    },
  },
  xxsmall: {
    '& .logomark svg': {
      width: 32,
      height: 32,
    },
    '& .logotype svg': {
      width: 86,
      height: 24,
    },
  },
  xsmall: {
    gap: ({ orientation }) => (orientation === LogoOrientation.HORIZONTAL ? 12 : 4),
    '& .logomark svg': {
      width: 40,
      height: 40,
    },
    '& .logotype svg': {
      width: 'auto',
      height: 29.05,
    },
  },
  small: {
    gap: ({ orientation }) => (orientation === LogoOrientation.HORIZONTAL ? 12 : 8),
    '& .logomark svg': {
      width: 48,
      height: 48,
    },
    '& .logotype svg': {
      width: 'auto',
      height: 34.86,
    },
  },
  medium: {
    gap: ({ orientation }) => (orientation === LogoOrientation.HORIZONTAL ? 14 : 8),
    '& .logomark svg': {
      width: 56,
      height: 56,
    },
    '& .logotype svg': {
      width: 'auto',
      height: 40.67,
    },
  },
  large: {
    gap: ({ orientation }) => (orientation === LogoOrientation.HORIZONTAL ? 16 : 12),
    '& .logomark svg': {
      width: 64,
      height: 64,
    },
    '& .logotype svg': {
      width: 172,
      height: 48,
    },
  },
  xlarge: {
    gap: ({ orientation }) => (orientation === LogoOrientation.HORIZONTAL ? 20 : 18),
    '& .logomark svg': {
      width: 80,
      height: 80,
    },
    '& .logotype svg': {
      width: 'auto',
      height: 58.11,
    },
  },
  xxlarge: {
    gap: 24,
  },
}));
