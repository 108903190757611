import { useState } from 'react';
import cx from 'clsx';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { Box, Grid } from '@mui/material';
import MuiInputAdornment from '@mui/material/InputAdornment';

// components
import { btnType, Button, colors, IconButton, Icons, Input } from '../../../packages';
import { userAccountRecovery } from '../../../services/auth/auth.service';
import { getAuth } from '../../../redux/modules/auth/selectors';
import { LoginType } from '../../../constants/CommonConstants';
import Heading from '../../../components/v2/Heading';
import { HeadingFont, HeadingLevel } from '../../../components/v2/Heading/index.types';
import Typography from '../../../components/v2/Typography';
import { typography } from '../../../components/v2/Typography/index.constant';
import VSlider from '../../../components/v2/VSlider';
import { ForgotPasswordOTP } from './components/ForgotPasswordOTP/ForgotPasswordOTP';
import { NewPassword } from './components/NewPassword/NewPassword';
import { ForgotPasswordSchema } from './ForgotPasswordSchema';
// styles
import { useStyles } from './ForgotPassword.styles';

// import './login.css';

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [screen, setScreen] = useState('forgotPassword');
  const [code, setCode] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { isLoading } = useSelector(getAuth);

  const formik = useFormik({
    initialValues: {
      type: LoginType.PROVIDER,
      email: '',
      password: '',
    },
    validationSchema: ForgotPasswordSchema,
    onSubmit: () => {
      setIsSubmitting(true);

      if (screen === 'forgotPassword') {
        userAccountRecovery({
          email: formik.values.email,
          type: LoginType.PROVIDER,
        })
          .then(() => {
            setIsSubmitting(false);
            setScreen('OTP');
          })
          .catch(err => {
            console.log('err', err);
            setIsSubmitting(false);
            typeof err === 'string'
              ? formik.setErrors({ email: 'Email Not Found' })
              : formik.setErrors({ email: ` Email ${err.statusText}` });
          });
      } else if (screen === 'OTP') setScreen('NewPassword');
    },
  });
  const classes = useStyles();

  const onBlurInput = async e => {
    await formik.setFieldTouched(e.target.name);
  };

  return (
    <div className={cx({ [classes.root]: true })}>
      <Grid container>
        <Grid item xs={12} sm={6}>
          <Box width="100%" height="100%" display="flex" justifyContent="center" alignItems="center">
            <Box width={448} display="flex" alignItems="center">
              <Box className={classes.flex} gap={7} flex={1}>
                <Box display="flex" flexDirection="column" gap={1}>
                  <Box className={classes.between}>
                    <Heading
                      variant={HeadingLevel.H2}
                      fontFamily={HeadingFont.SECONDARY}
                      letterSpacing="-0.7px"
                    >
                      {screen === 'OTP'
                        ? 'Verify that its you'
                        : screen === 'NewPassword'
                        ? 'New Password'
                        : screen === 'forgotPassword'
                        ? 'Reset Password'
                        : null}
                    </Heading>
                    {screen === 'forgotPassword' && (
                      <IconButton
                        icon="arrow-left"
                        variant={btnType.OUTLINE}
                        className={classes.outlineBtn}
                        onClick={() => navigate('/login')}
                      >
                        Back to login
                      </IconButton>
                    )}
                  </Box>
                  {screen === 'OTP' && (
                    <Typography {...typography.body.m.regular}>
                      {`We sent a verification code to your provided email ${formik.values.email}`}.
                    </Typography>
                  )}
                </Box>
                <Box display="flex" flexDirection="column">
                  {screen === 'OTP' && (
                    <ForgotPasswordOTP
                      setScreen={setScreen}
                      setCode={setCode}
                      userEmail={formik.values.email}
                    />
                  )}
                  {screen === 'NewPassword' && <NewPassword code={code} email={formik.values.email} />}
                  {screen === 'forgotPassword' && (
                    <Box display="flex" flexDirection="column" gap={2}>
                      <form onSubmit={formik.handleSubmit} className={classes.form}>
                        <div className={classes.inputContainer}>
                          <Typography {...typography.body.normal.medium.semibold}>Email</Typography>
                          <Input
                            className={classes.input}
                            placeholder="Enter your email"
                            name="email"
                            onChange={formik.handleChange}
                            onBlur={onBlurInput}
                            InputProps={{
                              startAdornment: (
                                <MuiInputAdornment position="start">
                                  <Icons
                                    glyph="email-outlined"
                                    color={formik.errors.email ? colors.destructive600 : colors.neutral500}
                                  />
                                </MuiInputAdornment>
                              ),
                            }}
                          />
                          {formik.touched.email ? (
                            <Typography
                              {...typography.body.normal.small.medium}
                              color={colors.destructive600}
                            >
                              {formik.errors.email?.toString()}
                            </Typography>
                          ) : null}
                        </div>

                        <div className={classes.inputContainer}>
                          <Button
                            className={classes.buttonContainer}
                            onClick={formik.handleSubmit}
                            disabled={isSubmitting || !formik.values.email}
                          >
                            {isLoading ? (
                              <Icons
                                className="rotate linear infinite"
                                glyph="in-progress"
                                color={colors.white}
                              />
                            ) : (
                              'Submit email'
                            )}
                          </Button>
                        </div>
                      </form>
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box className={classes.rightContainer} sx={{ px: { xs: 5, sm: 5, md: 10 } }}>
            <Box className={classes.startBackground} />
            <VSlider />
            <Box className={classes.endBackground} />
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export { ForgotPassword };
