import { Box } from '@mui/material';
import { colors, IconButton } from '../../../../packages';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import { getUserTimeZone } from '../../../../utils/dayjs';
import { useSelector } from 'react-redux';
import { selectProviderSchedule } from '../../../../redux/modules/schedule/selectors';

import BoxFrame from '../../../../components/v2/BoxFrame';
import Typography from '../../../../components/v2/Typography';
import { typography } from '../../../../components/v2/Typography/index.constant';

import { useStyles } from '../index.styles';

const SlotCard = ({ slot, actions, isBlocked }) => {
  const classes = useStyles();
  const providerSchedule = useSelector(selectProviderSchedule);
  const timeDiffInMinutes = dayjs(slot?.end).diff(dayjs(slot?.start), 'minute');
  const timezone = useMemo(() => providerSchedule?.timezone || getUserTimeZone(), [providerSchedule]);

  let timeString;
  if (timeDiffInMinutes >= 60) {
    const hours = Math.floor(timeDiffInMinutes / 60);
    const minutes = timeDiffInMinutes % 60;
    timeString = `${hours} hr${hours > 1 ? 's' : ''}`;
    if (minutes > 0) {
      timeString += ` ${minutes} min${minutes > 1 ? 's' : ''}`;
    }
  } else {
    timeString = `${timeDiffInMinutes} min${timeDiffInMinutes > 1 ? 's' : ''}`;
  }

  return (
    <BoxFrame>
      <Box
        display="flex"
        justifyContent="space-between"
        sx={{
          py: 1.875,
          px: 2,
          flexDirection: {
            md: 'row',
            xs: 'column',
          },
          gap: {
            md: 0,
            xs: 3,
          },
        }}
      >
        <Box
          className={classes.rflex}
          gap={3}
          alignItems="center"
          sx={{
            justifyContent: {
              xs: 'space-between',
              md: 'normal',
            },
          }}
        >
          {isBlocked ? (
            <>
              <Typography {...typography.body.m.bold}>
                {`${dayjs(slot?.start).tz(timezone).format('h:mm A')} - ${dayjs(slot?.end)
                  .tz(timezone)
                  .format('h:mm A')}`}
              </Typography>
              <Typography {...typography.body.m.medium} color={colors.neutral700}>
                Blocked by {slot?.type === 'confidant' ? 'you' : 'google calendar'}
              </Typography>
            </>
          ) : (
            <>
              <Typography {...typography.body.m.bold}>
                {dayjs(slot?.start).tz(timezone).format('h:mm A')}
              </Typography>
              <Typography {...typography.body.m.medium} color={colors.neutral700}>
                for {timeString}
              </Typography>
            </>
          )}
        </Box>
        <Box
          className={classes.rflex}
          gap={2}
          sx={{
            flexDirection: {
              md: 'row',
              xs: 'column',
            },
            '& button': {
              width: { md: 'auto', xs: '100%' },
            },
          }}
        >
          {(slot?.type === 'confidant' || !isBlocked) &&
            actions.map((action, index) => (
              <IconButton key={index} {...action}>
                {action.children}
              </IconButton>
            ))}
        </Box>
      </Box>
    </BoxFrame>
  );
};

export default SlotCard;
