import cx from 'clsx';

// mui
import MuiTextarea from '@mui/material/TextareaAutosize';

// types
// styles
import { useStyles } from './Textarea.styles';

const Textarea = ({ className = '', placeholder = '', ...rest }) => {
  const classes = useStyles();

  return (
    <MuiTextarea
      className={cx({
        [classes.root]: true,
        [className || '']: className,
      })}
      placeholder={placeholder}
      {...rest}
    />
  );
};

export { Textarea };
