/* eslint-disable import/no-cycle */
import { createSelector } from 'reselect';
import { DCT_STATUS } from '../../../constants/CommonConstants';
import { assignedPlanItemAdapter, associatedTagsAdapter } from './reducer';

export const getConversation = state => state.conversation;

export const getMessages = createSelector(getConversation, conversationState => conversationState.messages);
export const getIsLoadingMessages = createSelector(
  getConversation,
  conversationState => conversationState.isLoading,
);

const selectSessionTypeState = createSelector(
  getConversation,
  conversationState => conversationState.sessionType,
);

export const selectSessionTypeLoading = createSelector(
  selectSessionTypeState,
  sessionTypeState => sessionTypeState.isLoading,
);

export const selectSessionTypeError = createSelector(
  selectSessionTypeState,
  sessionTypeState => sessionTypeState.errorMsg,
);

export const selectSessionTypes = createSelector(
  selectSessionTypeState,
  sessionTypeState => sessionTypeState.sessionTypes,
);

export const selectPlanItemState = createSelector(
  getConversation,
  conversationState => conversationState.planItem,
);

export const selectPlanItems = createSelector(selectPlanItemState, planItemState => planItemState.planItems);

export const selectPlanItemsLoading = createSelector(
  selectPlanItemState,
  planItemState => planItemState.isLoading,
);

export const selectPlanItemSaving = createSelector(
  selectPlanItemState,
  planItemState => planItemState.isSaving,
);

export const selectPlanItemById = planId =>
  createSelector(selectPlanItems, planItems => planItems.find(item => item.id === planId));

export const selectAssignedPlanItemsById = memberId =>
  createSelector(
    getConversation,
    conversation =>
      assignedPlanItemAdapter.getSelectors().selectById(conversation.assignedPlanItems, memberId)?.items,
  );

export const selectAssignedPlanItemsLoading = createSelector(
  getConversation,
  conversation => conversation.assignedPlanItems.isLoading,
);

export const selectAssignedPlanItemsSaving = createSelector(
  getConversation,
  conversation => conversation.assignedPlanItems.isSaving,
);

export const selectConversationListState = createSelector(
  getConversation,
  conversationState => conversationState.conversation,
);

export const selectConversations = createSelector(
  selectConversationListState,
  conversationListState => conversationListState.conversations,
);
export const selectConversationState = createSelector(
  selectConversationListState,
  conversationListState => conversationListState,
);
export const selectRevampTypesListState = createSelector(
  getConversation,
  conversationState => conversationState.revampType,
);

export const selectRevampTypes = createSelector(
  selectRevampTypesListState,
  revampTypesListState => revampTypesListState.revampTypes,
);

export const selectEvaluationContext = createSelector(
  getConversation,
  conversationState => conversationState.evaluationContext.evaluationContext,
);

export const inPersonEvaluationContext = createSelector(
  getConversation,
  conversationState => conversationState.evaluationContext.evaluationHead,
);

export const selectEvaluationListState = createSelector(
  getConversation,
  conversationState => conversationState.evaluation,
);

export const selectEvaluation = createSelector(
  selectEvaluationListState,
  evaluationState => evaluationState.evaluations,
);

export const selectEvaluationToggleStatus = createSelector(
  getConversation,
  conversationState => conversationState.evaluationContext.toggleStatus,
);

export const selectAssociatedTagById = typeId =>
  createSelector(getConversation, conversation =>
    associatedTagsAdapter.getSelectors().selectById(conversation.associatedTags, typeId),
  );

export const selectProfileElementList = createSelector(
  getConversation,
  conversationState => conversationState.profileElements.profileElementList,
);

export const selectDctState = createSelector(getConversation, conversationState => conversationState.dct);

export const libraryContentBlocks = createSelector(
  getConversation,
  conversationState => conversationState.libraryContentBlocks,
);

export const selectActiveDcts = createSelector(selectDctState, dctState =>
  dctState.dcts.filter(dct => dct.status === DCT_STATUS.ACTIVE),
);

export const selectDraftDcts = createSelector(selectDctState, dctState =>
  dctState.dcts.filter(dct => dct.status === DCT_STATUS.DRAFT),
);

export const selectTags = createSelector(getConversation, conversationState => conversationState.tags.tags);

export const selectAutomationRules = createSelector(
  getConversation,
  conversationState => conversationState.automationRules,
);

export const selectAutomations = createSelector(
  selectAutomationRules,
  automationState => automationState.automationRules,
);

export const selectAutomationById = automationId =>
  createSelector(selectAutomations, automations => automations.find(item => item.id === automationId));

export const selectAIJobs = createSelector(getConversation, conversationState => conversationState.aiJobs);

export const selectAutomationRulesCount = createSelector(
  getConversation,
  conversationState => conversationState.automationRulesCount.counts,
);

export const selectAssignedEvaluationState = createSelector(
  getConversation,
  conversationState => conversationState.assignedEvaluations,
);

export const selectAssignedEvaluations = createSelector(
  selectAssignedEvaluationState,
  evaluationState => evaluationState.evaluations,
);
