/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-underscore-dangle */
import { useEffect, useMemo, useState } from 'react';
import { useDownloadExcel } from 'table-to-excel-react';

import { Box } from '@mui/material';

import { toDateString } from '../../../../../utils/CommonUtils';
import { getElkDataDomainElementReport } from '../../../../../services/reporting/reporting.service';
import HeadCard from '../head-card';
import StackChart from '../stack-chart';

import { assignedColumns, getChartSeries, getMedicationQuery } from './Medications.constants';
import { ViewAllDrawer } from '../data-collection-templates/ViewAllDrawer';
import { ViewAllDrawer as ViewAllMembersDrawer } from '../appointments/ViewAllDrawer';
import { useStyles } from '../symptoms/Symptoms.styles';
import { DDE_REPORT_TYPES, getMemberListdata, MemberListColumns } from '../../ReportDetail.constants';
import RenderTable from '../render-table';
import { DemographicsDetail } from '../demographics/demographics-detail/DemographicsDetail';

const Medications = ({ report, showNewReport, componentRef }) => {
  const [ddeReport, setDdeReport] = useState(null);
  const [showViewAllMembersType, setShowViewAllMembersType] = useState(null);
  const [showViewAllType, setShowViewAllType] = useState(null);
  const [selectedItem, setSelectedItemDetail] = useState(null);
  const { onDownload } = useDownloadExcel({
    fileName: 'medications_report',
    table: 'table_to_xls',
    sheet: 'sheet 1',
  });
  const onRowClick = item => {
    setSelectedItemDetail({ ...item, type: DDE_REPORT_TYPES.DEFAULT });
  };
  const classes = useStyles();
  const chartSeries = useMemo(() => getChartSeries(ddeReport), [ddeReport]);
  const chartCategories = useMemo(
    () => ddeReport?.aggregations.top_ten_by_members.buckets.map(bucket => bucket.key) ?? [],
    [ddeReport],
  );
  const mostAssginedData = useMemo(
    () =>
      ddeReport?.aggregations.most_assigned.buckets.map(bucket => ({
        name: bucket.key,
        members: bucket.uniqueMembers.value,
        lastUpdated: toDateString(bucket.last_assigned_date.hits.hits[0]._source.assignedAt),
      })) ?? [],
    [ddeReport],
  );
  const mostImportantData = useMemo(
    () =>
      ddeReport?.aggregations.most_count_by_importance.buckets.map(bucket => ({
        name: bucket.key,
        members: bucket.doc_count,
        lastUpdated: toDateString(bucket.last_modified_date.hits.hits[0]._source.lastModified),
      })) ?? [],
    [ddeReport],
  );
  const mostChangedData = useMemo(
    () =>
      ddeReport?.aggregations.most_changed_from_start?.buckets.map(bucket => ({
        name: bucket.key,
        members: bucket.doc_count,
        lastUpdated: toDateString(bucket.last_modified_date.hits.hits[0]._source.lastModified),
      })) ?? [],
    [ddeReport],
  );
  const memberListData = useMemo(() => {
    return getMemberListdata(ddeReport);
  }, [ddeReport]);
  const dataDomainElementSection = useMemo(() => report?.data?.dataDomainElementSection, [report]);
  const onCloseViewDetail = () => {
    setSelectedItemDetail(null);
  };
  useEffect(() => {
    !showNewReport &&
      void (async () => {
        const resp = await getElkDataDomainElementReport(getMedicationQuery(report), null);
        setDdeReport(resp.data);
      })();
  }, [showNewReport]);

  const onCloseViewAll = () => {
    setShowViewAllType(null);
  };

  const onClickAll = type => () => {
    setShowViewAllType(type || showViewAllType);
  };

  // eslint-disable-next-line react/display-name
  const renderMenu = () => () => {
    return <></>;
    // return (
    //   <Menu
    //     icon="more"
    //     className={classes.menu}
    //     items={[
    //       { label: 'Menu Item 1', onClick: onClickMenu(menuTable, action?.id, 'Menu Item 1') },
    //       { label: 'Menu Item 2', onClick: onClickMenu(menuTable, action?.id, 'Menu Item 2') },
    //       { label: 'Menu Item 3', onClick: onClickMenu(menuTable, action?.id, 'Menu Item 3') },
    //     ]}
    //   />
    // );
  };

  const onCloseViewAllMembers = () => {
    setShowViewAllMembersType(null);
  };

  const onClickAllMembers = type => () => {
    setShowViewAllMembersType(type || 'memberList');
  };

  const getContentsInViewAllMembers = () => {
    if (showViewAllMembersType === 'memberList') {
      return {
        title: 'Member list',
        subtitle: `${memberListData.length} total`,
        tableColumns: MemberListColumns,
        data: memberListData.slice().reverse(),
        renderMenuAction: renderMenu(),
      };
    }
    return null;
  };

  const getContentsInViewAll = () => {
    if (showViewAllType === 'mostAssigned') {
      return {
        title: 'Most assigned medications',
        subtitle: `${mostAssginedData.length} total`,
        tableColumns: assignedColumns,
        data: mostAssginedData,
        renderMenuAction: renderMenu(),
        onRowClick,
      };
    }
    if (showViewAllType === 'leastAssigned') {
      return {
        title: 'Least assigned medications',
        subtitle: `${mostAssginedData.length} total`,
        tableColumns: assignedColumns,
        data: mostAssginedData.slice().reverse(),
        renderMenuAction: renderMenu(),
        onRowClick,
      };
    }
    if (showViewAllType === 'mostImportantByCount') {
      return {
        title: 'Most important by count',
        subtitle: `${mostImportantData.length} total`,
        tableColumns: assignedColumns,
        data: mostImportantData,
        renderMenuAction: renderMenu(),
        onRowClick,
      };
    }
    if (showViewAllType === 'mostChangedFromStart') {
      return {
        title: 'Most changed from start',
        subtitle: `${mostChangedData.length} total`,
        tableColumns: assignedColumns,
        data: mostChangedData,
        renderMenuAction: renderMenu(),
        onRowClick,
      };
    }

    return null;
  };

  const renderTable = (
    title,
    data,
    columns,
    onClickViewAll,
    renderMenuAction,
    className = '',
    disableOnRowClick = false,
  ) => (
    <RenderTable
      title={title}
      data={data}
      columns={columns}
      onClickViewAll={onClickViewAll}
      renderMenuAction={renderMenuAction}
      className={className}
      onRowClick={disableOnRowClick ? null : onRowClick}
    />
  );

  const renderAllTables = () => {
    return (
      <div id="table_to_xls" hidden>
        {[
          {
            title: 'Most Assigned Medications',
            tableColumns: null,
            data: null,
          },
          {
            tableColumns: assignedColumns,
            data: mostAssginedData,
          },
          {
            title: 'Member List',
            tableColumns: null,
            data: null,
          },
          {
            tableColumns: MemberListColumns,
            data: memberListData,
          },
        ].map((item, index) => {
          return (
            <table key={`${index}_t`}>
              <thead key={`${index}_th`}>
                <tr key={`${index}_trh`}>
                  {item?.title && <th>{item.title}</th>}
                  {item?.tableColumns?.find(col => col.id === 'member') &&
                    ['Member_fullName', 'Member_Name', 'Member_Id'].map(colItem => (
                      <th key={`${colItem}_thr`}>{colItem}</th>
                    ))}
                  {item?.tableColumns?.find(col => col.id === 'provider') &&
                    ['Provider_Name', 'provider_Role'].map(colItem => (
                      <th key={`${colItem}_thr`}>{colItem}</th>
                    ))}
                  {item?.tableColumns
                    ?.filter(col => !(col.id === 'member' || col.id === 'provider'))
                    .map((column, indx) => {
                      return <th key={`${indx}_thr`}>{column.label}</th>;
                    })}
                </tr>
              </thead>
              <tbody>
                {item?.data?.map((row, ind) => {
                  return (
                    <tr key={`${ind}_tr`}>
                      {item.tableColumns.find(col => col.id === 'member') &&
                        ['fullname', 'name', 'memberId'].map(colItem => (
                          <td key={`${colItem}_thr`}>{row.member[colItem]}</td>
                        ))}
                      {item.tableColumns.find(col => col.id === 'provider') &&
                        ['name', 'role'].map(colItem => (
                          <td key={`${colItem}_thr`}>{row.provider[colItem]}</td>
                        ))}
                      {item.tableColumns
                        .filter(col => !(col.id === 'member' || col.id === 'provider'))
                        .map((column, i) => {
                          return <td key={`${i}_tir`}>{row[column.id]}</td>;
                        })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          );
        })}
      </div>
    );
  };

  return (
    <>
      {renderAllTables()}
      <DemographicsDetail
        open={!!selectedItem}
        onClose={onCloseViewDetail}
        demographicsDetail={selectedItem}
      />
      <ViewAllMembersDrawer
        isOpen={!!showViewAllMembersType}
        onClose={onCloseViewAllMembers}
        {...getContentsInViewAllMembers()}
      />
      <ViewAllDrawer isOpen={!!showViewAllType} onClose={onCloseViewAll} {...getContentsInViewAll()} />
      <Box className={classes.card}>
        <HeadCard title="Medications" componentRef={componentRef} onDownloadClick={onDownload} />
        <Box className={classes.card}>
          {dataDomainElementSection.top10tByMembers?.isChecked && (
            <Box className={classes.chartWrap}>
              <StackChart
                title="Top 10 medications by member"
                className={classes.chart}
                chart={{
                  series: chartSeries,
                  isDatetime: false,
                  categories: chartCategories,
                }}
              />
            </Box>
          )}
          <Box display="flex" justifyContent="space-between" gap={6}>
            {dataDomainElementSection.mostAssigned?.isChecked &&
              renderTable(
                'Most assigned medications',
                mostAssginedData,
                assignedColumns,
                onClickAll('mostAssigned'),
                renderMenu(),
                !dataDomainElementSection.leastAssigned?.isChecked ? classes.halfTable : '',
              )}
            {dataDomainElementSection.leastAssigned?.isChecked &&
              renderTable(
                'Least assigned medications',
                mostAssginedData.slice().reverse(),
                assignedColumns,
                onClickAll('leastAssigned'),
                renderMenu(),
                !dataDomainElementSection.mostAssigned?.isChecked ? classes.halfTable : '',
              )}
          </Box>
          <Box display="flex" justifyContent="space-around" className={classes.fullTable2}>
            {renderTable(
              'Member list',
              memberListData.slice().reverse(),
              MemberListColumns,
              onClickAllMembers('memberList'),
              '',
              classes.fullTable,
              true,
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export { Medications };
