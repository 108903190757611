// colors
import { colors } from '../../../../../packages/colors';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  card: {
    background: colors.white,
    boxShadow:
      '0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
    borderRadius: 8,
    '&:hover $cardLink': {
      display: 'flex',
    },
  },
  title: {
    fontSize: 16,
    color: colors.neutral900,
    margin: 0,
    marginBottom: 4,
  },
  divider: {
    borderColor: colors.neutral100,
  },
  cardItem: {
    padding: '20px 24px',
    '& .Supervising': {
      backgroundColor: colors.green50,
      color: colors.green600,
    },
  },
  text700: {
    color: colors.neutral700,
    fontSize: 14,
  },
  badge: {
    border: 0,
    height: 24,
    paddingLeft: 10,
    paddingRight: 10,
    borderRadius: 50,
    whiteSpace: 'nowrap',
    fontSize: 14,
    paddingBottom: 2,
    fontWeight: 600,
  },
  signoffBtn: {
    backgroundColor: colors.primary500,
    color: colors.white,
    height: 64,
    padding: '20px 22px',
    borderRadius: 8,
    '& svg': {
      marginRight: 9,
    },
    '&:hover': {
      backgroundColor: colors.primary600,
    },
  },
  success700: {
    color: colors.success700,
  },
  rejectBtn: {
    backgroundColor: colors.destructive500,
    color: colors.white,
    height: '64px',
    marginRight: '10px',
    border: 'none',
    '&:hover': {
      backgroundColor: colors.destructive600,
      color: colors.white,
    },
  },
});
