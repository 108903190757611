import { useEffect, useState } from 'react';
import cx from 'clsx';
import { Collapse, Box } from '@mui/material';

import { Text } from '../../../../../packages/ui/atoms/typography';
import { Icons } from '../../../../../packages/icons';
import { colors } from '../../../../../packages/colors';
import { FormControlLabel } from '../../../../../packages/ui/atoms/form-control-label';
import { Checkbox } from '../../../../../packages/ui/atoms/checkbox';

// styles
import { useStyles } from './ReportItemsCollapse.styles';

const ReportCollapseItems = ({
  className = '',
  label = '',
  open = false,
  items,
  selectedItems,
  onChange,
}) => {
  const [collapsed, setCollapsed] = useState(open || false);
  const [selectedCount, setSelectedCount] = useState(0);
  const classes = useStyles({ collapsed });

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  useEffect(() => {
    setCollapsed(open || false);
  }, [open]);

  useEffect(() => {
    if (selectedItems) {
      const count = items.reduce(
        (counted, item) => (selectedItems.includes(item.value) ? counted + 1 : counted),
        0,
      );
      setSelectedCount(count);
    }
  }, [selectedItems]);

  return (
    <Box className={cx(classes.root, className)}>
      <Box className={cx(classes.header, 'collapsableHeader')} onClick={toggleCollapsed}>
        <Box display="flex" alignItems="center">
          <Icons
            className={cx(classes.chevronIcon, 'collapsableIcon')}
            glyph="chevron-down"
            color={colors.neutral400}
          />
          <Text className={classes.label}>{label}</Text>
        </Box>
        <Text className={classes.label2}>{`${selectedCount} of ${items.length} selected`}</Text>
      </Box>
      <Collapse in={collapsed}>
        <Box className={classes.selects}>
          {items.map(item => (
            <FormControlLabel
              control={<Checkbox />}
              key={item.value}
              label={item.label}
              checked={selectedItems.includes(item.value)}
              onChange={() => {
                const newList = selectedItems.includes(item.value)
                  ? selectedItems.filter(selectedItem => selectedItem !== item.value)
                  : [...selectedItems, item.value];
                onChange(newList);
              }}
              className={classes.selectItem}
            />
          ))}
        </Box>
      </Collapse>
    </Box>
  );
};

export { ReportCollapseItems };
