import clsx from 'clsx';
import { Box, Modal } from '@mui/material';

import { Button, colors, Heading, Icons, Text } from '../../../../packages';

import { useStyles } from './AIReminderModal.styles';

const AIReminderModal = ({ open, onClose }) => {
  const classes = useStyles();

  return (
    <Modal open={open} onClose={onClose}>
      <Box className={classes.modal}>
        <Box className={classes.modalContent}>
          <Box className={classes.editIconWrap}>
            <Icons glyph="pencil" className={classes.editIcon} />
          </Box>
          <Box className={classes.content}>
            <Box>
              <Heading className={classes.title}>Confidant AI Reminder</Heading>
              <Text className={classes.subtitle}>
                All data processed by this system is de-identified and strictly complies with the Health
                Insurance Portability and Accountability Act (HIPAA). We use robust encryption and data
                handling practices to ensure that protected information within our system remains confidential
                and secure.
              </Text>
              <Icons glyph="close" onClick={onClose} className={classes.closeBtn} color={colors.neutral400} />
            </Box>
            <Box display="flex" justifyContent="space-between" alignItems="center" gap="16px">
              <Button className={clsx(classes.btn, classes.cancelBtn)} onClick={onClose}>
                Acknowledged
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export { AIReminderModal };
