// colors
import { colors } from '../../../../../packages/colors';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  heading: {
    fontFamily: 'Source Serif Pro',
    margin: 0,
    // marginBottom: '40px',
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  status: {
    borderRadius: 100,
    minWidth: 'auto',
    fontSize: 14,
  },
  menu: {
    float: 'right',
    color: colors.neutral400,
    right: 20,
    '& svg': {
      width: 9,
    },
  },
  noteWrapper: { padding: 24, '& > div': { width: '100%', height: 56 } },
  verticalEllipsis: {
    fontWeight: 500,
    fontSize: 16,
    color: colors.neutral900,
    height: 75,
    display: '-webkit-box',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 3,
  },
  cardLabel: {
    fontWeight: 800,
    fontSize: 12,
    lineHeight: '20px',
    color: colors.neutral600,
    letterSpacing: '1px',
    textTransform: 'uppercase',
  },
  cardDescription: {
    fontWeight: 700,
    fontSize: 22,
    lineHeight: '28px',
    color: colors.neutral900,
  },
  cardSubLabel: {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '20px',
    color: colors.neutral600,
  },
  labProgressBar: {
    '& .MuiLinearProgress-bar': {
      backgroundColor: colors.success500,
    },
  },
  icon: {
    width: 40,
    height: 40,
    borderRadius: 4,
    border: `1px solid ${colors.green200}`,
    background: colors.green50,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  releaseIcon: {
    width: 40,
    height: 40,
    borderRadius: 4,
    border: `1px solid ${colors.indigo200}`,
    background: colors.indigo50,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  tableHead: { padding: 16, background: colors.neutral25, boxShadow: '0px -1px 0px 0px #EDF1F5 inset' },
  spaceBetweenRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 32,
  },
  headingWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: 40,
  },
  magicLink: {
    marginBottom: '4px',
  },
});
