import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Stack } from '@mui/material';

import {
  Avatar,
  avatarType,
  Badge,
  badgeStyle,
  badgeType,
  btnType,
  fontWeight,
  Heading,
  headingLevel,
  IconButton,
  Link,
  Menu,
  Table,
} from '../../../../packages';

import { profileActionCreators } from '../../../../redux/modules/profile';
import { selectConnections } from '../../../../redux/modules/profile/selectors';
import { showSnackbar } from '../../../../redux/modules/snackbar';
import { suggestConnection } from '../../../../services/member/member.service';
import history from '../../../../utils/history';

import { connectionColumns } from './Connections.constants';
import { useStyles } from './Connections.styles';
import AddConnection from './add-connection';

const Connections = () => {
  const classes = useStyles();
  const { providerId } = useParams();
  const [isSaving, setIsSaving] = useState(false);
  const [value, setValue] = useState({
    search: { searchKey: '' },
    pagination: { currentPage: 1, rowsPerPage: 10 },
    sorter: { direction: 'asc', column: '' },
  });
  const [showAddNew, setShowAddNew] = useState(false);
  const { isLoading, connections, totalRecords } = useSelector(selectConnections);
  const dispatch = useDispatch();

  const tableData = useMemo(() => {
    return connections.map(item => ({
      ...item,
      member: {
        photo: item.profilePicture,
        id: item.connectionId,
        name: item.name,
        firstName: item.firstName,
        lastName: item.lastName,
      },
      email: item.email,
      phone: item.phone,
      actions: { id: item.connectionId },
    }));
  }, [connections]);

  const handleFetchConnectionsParamsFormat = ({ search, pagination }) => {
    const params = {
      searchQuery: search.searchKey,
      pageNumber: pagination.currentPage - 1,
      pageSize: pagination.rowsPerPage,
      status: 'active',
      type: 'members',
      userId: providerId,
    };

    return params;
  };

  const fetchConnections = () => {
    dispatch(profileActionCreators.fetchConnectionsByStatus(handleFetchConnectionsParamsFormat(value)));
  };

  useEffect(() => {
    if (providerId) {
      fetchConnections();
    }
  }, [providerId]);

  useEffect(() => {
    fetchConnections();
  }, [value]);

  const onViewProfile = connectionId => () => {
    history.push(`/admin/members/${connectionId}?name=general-information`);
  };

  const renderColumns = connectionColumns.map(column => {
    if (column.id === 'member') {
      return {
        ...column,
        renderCell: ({ photo, id, name, firstName, lastName }) => (
          <Link className={classes.memberColumn} to={`/admin/members/${id}?name=general-information`}>
            <div className={classes.member}>
              <Avatar size={40} variant={avatarType.CIRCLE} src={photo} name={name} />
              <div className={classes.info}>
                <span className={classes.name}>{name}</span>
                <span className={classes.id}>{`${firstName || ''} ${lastName || ''}`}</span>
              </div>
            </div>
          </Link>
        ),
      };
    }
    if (column.id === 'actions') {
      return {
        ...column,
        renderCell: ({ id: connectionId }) => (
          <Menu
            icon="more"
            className={classes.menu}
            itemsWrapperClassName={classes.menuItemsWrapper}
            items={[{ label: 'View Profile', onClick: onViewProfile(connectionId) }]}
          />
        ),
      };
    }
    return column;
  });

  const toggleAddNew = () => {
    setShowAddNew(!showAddNew);
  };

  const onAddSession = useCallback(() => {
    toggleAddNew();
  }, [toggleAddNew]);

  const connectMember = useCallback(
    async ({ userId }) => {
      try {
        setIsSaving(true);
        await suggestConnection({
          suggestedFirstUser: userId,
          suggestedSecondUser: providerId,
        });
        toggleAddNew();
        fetchConnections();
      } catch (err) {
        const msg = err.data?.errors?.[0]?.endUserMessage || 'Whoops ! something went wrong please try again';
        dispatch(
          showSnackbar({
            snackType: 'error',
            snackMessage: msg,
          }),
        );
      }
      setIsSaving(false);
    },
    [providerId, showAddNew],
  );

  return (
    <>
      <AddConnection
        isOpen={showAddNew}
        isSaving={isSaving}
        onClose={toggleAddNew}
        onAddConnection={connectMember}
      />
      <Box className={classes.root}>
        <Stack direction="row" justifyContent="space-between" spacing={2} sx={{ marginBottom: 5 }}>
          <Stack direction="row" alignItems="center" gap={2} sx={{ height: 48 }}>
            <Heading className={classes.heading} level={headingLevel.XL} weight={fontWeight.BOLD}>
              Connections
            </Heading>
            <Badge className={classes.totalBadge} variant={badgeType.OUTLINED} style={badgeStyle.UNRELATED}>
              {totalRecords} total
            </Badge>
          </Stack>
          <IconButton icon="plus" variant={btnType.PRIMARY} className={classes.addBtn} onClick={onAddSession}>
            Add new
          </IconButton>
        </Stack>
        <Table
          searchProps={{
            placeholder: 'Search connections by name, phone, or email',
          }}
          gridProps={{
            columns: renderColumns,
            data: tableData,
            isLoading,
          }}
          paginationProps={{
            currentRows: tableData.length,
            totalCount: totalRecords,
          }}
          value={value}
          onChange={setValue}
          className={classes.table}
        />
      </Box>
    </>
  );
};

export { Connections };
