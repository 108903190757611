import { useState } from 'react';
import { Box, List, ListItem, ListItemText } from '@mui/material';

import { btnType, colors, IconButton } from '../../../../../packages';
import { Drawer } from '../../../../../packages/ui/organisms/drawer';
import { Tabs } from '../../../../../packages/ui/atoms/tabs';

import Typography from '../../../../../components/v2/Typography';
import { typography } from '../../../../../components/v2/Typography/index.constant';
import helpIcon from '../../../../../assets/images/help-icon.png';
import { helperTabs } from './QuestionsDrawer.constants';

import { useStyles } from './QuestionsDrawer.styles';

const QuestionsDrawer = () => {
  const classes = useStyles();
  const [showHelper, setShowHelper] = useState(false);
  const [currentTab, setCurrentTab] = useState(helperTabs[0].tabKey);

  const renderTab1 = () => {
    return (
      <Box className={classes.tab1Container}>
        <Box>
          <Typography className={classes.heading2}>
            General availability & Google calender integration
          </Typography>
          <Typography className={classes.text} mt={1} color={colors.neutral700}>
            Setting up (and changing) web app general availability and integration of Google calendar(s).
            <br />
            <Box>
              &quot;How do I change my availability for the future?&quot;
              <br />
              &quot;How do I add more hours to my schedule?&quot;
            </Box>
          </Typography>
        </Box>
        <Box className={classes.videoContainer}>
          <iframe
            width="520"
            height="293"
            src="https://www.loom.com/embed/dc271b914e344cca9941a753a42f2a1d?sid=fe853ce9-1aca-4829-a2d1-0c0342e0cce5"
            title="youtube render"
            className={classes.video}
          />
        </Box>
        <List sx={{ listStyle: 'decimal' }}>
          <ListItem sx={{ display: 'list-item' }}>
            <ListItemText
              primary={
                <Typography className={classes.text} color={colors.neutral700}>
                  <b>Access Profile Settings:</b> Click on your profile icon, usually located in the bottom
                  left corner, and select &quot;Account&quot;.
                </Typography>
              }
            />
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            <ListItemText
              primary={
                <Typography className={classes.text} color={colors.neutral700}>
                  <b>Navigate to General Availability:</b> In the sidebar, find and click on &quot;General
                  Availability&quot;.
                </Typography>
              }
            />
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            <ListItemText
              primary={
                <Typography className={classes.text} color={colors.neutral700}>
                  <b>Edit Availability:</b> Press the blue &apos;Edit&apos; button. Ensure your time zone is
                  correct. Toggle on the days you want to be available.
                </Typography>
              }
            />
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            <ListItemText
              primary={
                <Typography className={classes.text} color={colors.neutral700}>
                  <b>Add Time Slots:</b> For each available day, add time slots. You can add multiple slots
                  per day.
                </Typography>
              }
            />
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            <ListItemText
              primary={
                <Typography className={classes.text} color={colors.neutral700}>
                  <b>Remove Unwanted Time Slots:</b> Click the negative icon next to any time slot you wish to
                  remove.
                </Typography>
              }
            />
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            <ListItemText
              primary={
                <Typography className={classes.text} color={colors.neutral700}>
                  <b>Update Availability:</b> After reviewing, click &quot;Update Availability&quot; to save
                  changes.
                </Typography>
              }
            />
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            <ListItemText
              primary={
                <Typography className={classes.text} color={colors.neutral700}>
                  <b>Review Updated Availability:</b> Double-check that the availability reflects your
                  intended times.
                </Typography>
              }
            />
          </ListItem>
        </List>
      </Box>
    );
  };

  return (
    <>
      <Box display="flex" justifyContent="center" alignItems="center">
        <Box
          display="flex"
          alignItems="center"
          gap={2}
          p={1}
          sx={{
            background: colors.white,
            borderRadius: 999,
            width: 254,
            cursor: 'pointer',
            boxShadow: '0px 0px 1px 0px #0000000A, 0px 0px 2px 0px #0000000F, 0px 4px 8px 0px #0000000A',
          }}
          onClick={() => setShowHelper(true)}
        >
          <img src={helpIcon} alt="help icon" />
          <Typography {...typography.body.m.semibold}>Have any questions?</Typography>
        </Box>
      </Box>
      <Drawer open={showHelper} onClose={() => setShowHelper(false)} className={classes.drawer}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          borderBottom={`1px solid ${colors.neutral100}`}
        >
          <Tabs options={helperTabs} value={currentTab} onChange={setCurrentTab} className={classes.tabs} />
          <Box sx={{ '& button svg path': { fill: colors.neutral400 } }}>
            <IconButton variant={btnType.TEXT} icon="close" onClick={() => setShowHelper(false)} />
          </Box>
        </Box>
        <Box p={4} display="flex" flexDirection="column" gap={4}>
          {currentTab === 'information' && renderTab1()}
        </Box>
      </Drawer>
    </>
  );
};

export { QuestionsDrawer };
