import { Box, ButtonGroup, Menu, MenuItem } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { btnType, Button, colors, iconBtnPosition, IconButton, Icons } from '../../../packages';
import { TabPanel, Tabs } from '../../../packages/ui/atoms/tabs';

import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';

import history from '../../../utils/history';
import Header from '../../../components/v2/Header';
import Heading from '../../../components/v2/Heading';
import { HeadingLevel } from '../../../components/v2/Heading/index.types';
import Typography from '../../../components/v2/Typography';
import { typography } from '../../../components/v2/Typography/index.constant';
import { BaseLayout } from '../../../layouts/base';

import AddSchedule from '../../../pages/admin/appointments/add-schedule';
import { appointmentActionCreators } from '../../../redux/modules/appointment';
import { scheduleActionCreators } from '../../../redux/modules/schedule';
import { showSnackbar } from '../../../redux/modules/snackbar';
import { selectEvaluation } from '../../../redux/modules/conversation/selectors';
import { selectDailySchedule, selectProviderSchedule } from '../../../redux/modules/schedule/selectors';
import { getAuth } from '../../../redux/modules/auth/selectors';
import { updateProviderSchedule } from '../../../services/schedule/schedule.service';
import { getUserTimeZone } from '../../../utils/dayjs';
import { useLocation } from 'react-router-dom';

import AppointmentScheduling from '../appointments/components/appointment-scheduling';
import AppointmentDetail from '../appointments/components/appointment-detail';
import ConfirmModal from '../day-availability/ConfirmModal';
import { DayAvailabilityDrawer } from '../day-availability/DayAvailabilityDrawer';
import GeneralAvailability from '../profile/components/general-availability';
import RenderAppointments from './renderTabContents/RenderAppointments';
import RenderCalendar from './renderTabContents/RenderCalendar';
import RenderAvailability from './renderTabContents/RenderAvailability';

import { useStyles } from './index.styles';
import GenerateLinkDrawer from './components/schedule-link/GenerateLinkDrawer';

const tabs = [
  { label: 'Appointments', tabKey: 'appointments' },
  { label: 'Availability', tabKey: 'availability' },
  { label: 'Calendar', tabKey: 'calendar' },
];

const ENV = process.env.REACT_APP_ENV || process.env.NODE_ENV || 'staging';

const HOST_URL =
  ENV === 'prod'
    ? `https://member.confidanthealth.com/public/`
    : ENV === 'qa' || ENV === 'development'
    ? `https://qa.member.confidanthealth.com/public/`
    : `https://sit1.member.confidanthealth.com/public/`;

const Schedule = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();

  const { meta } = useSelector(getAuth);
  const defaultScheduleLink = `${HOST_URL}book-appointment?selectedProvider=${meta.userId}`;
  const providerSchedule = useSelector(selectProviderSchedule);
  const dailySchedule = useSelector(selectDailySchedule);
  const evaluations = useSelector(selectEvaluation);

  const [currentTab, setCurrentTab] = useState(tabs[0].tabKey);
  const [currentDate, setCurrentDate] = useState(dayjs());
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [schedulesDrawerOpen, setSchedulesDrawerOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [, setIsLoading] = useState(false);
  const [showReschedule, setShowReschedule] = useState(false);
  const [viewAppointmentDetail, setViewAppointmentDetail] = useState(false);
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [isActionLoading, setIsActionLoading] = useState(false);
  const [openGeneralAvailability, setOpenGeneralAvailability] = useState(false);
  const [openBlockedWholeDay, setOpenBlockedWholeDay] = useState(false);
  const [openUnblockWholeDay, setOpenUnblockWholeDay] = useState(false);
  const [showDayAvailability, setShowDayAvailability] = useState(false);
  const [showScheduleLinkDrawer, setShowScheduleLinkDrawer] = useState(false);
  const [isAppointmentCreated, setIsAppointmentCreated] = useState(false);
  const [calendarKey, setCalendarKey] = useState(0);
  const [schedulingAnchorEl, setSchedulingAnchorEl] = useState(null);
  const openSchedulingLink = Boolean(schedulingAnchorEl);
  const searchParams = new URLSearchParams(location.search);
  const dateParam = searchParams.get('date');

  const handleClick = event => {
    setSchedulingAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setSchedulingAnchorEl(null);
  };

  const headerLabel = useMemo(
    () =>
      currentDate.isSame(dayjs(), 'day')
        ? `Today, ${currentDate.format('MMMM D')}`
        : currentDate.format('dddd, MMMM D'),
    [currentDate],
  );

  const currentWeekDay = useMemo(() => dayjs(currentDate).format('dddd'), [currentDate]);

  const gotoDay = type => {
    if (type === 'prev') {
      setCurrentDate(currentDate.add(-1, 'day'));
      setCalendarKey(prevKey => prevKey + 1);
    }
    if (type === 'next') {
      setCurrentDate(currentDate.add(1, 'day'));
      setCalendarKey(prevKey => prevKey + 1);
    }
  };

  const onSubmitNewSchedule = payload => {
    dispatch(
      appointmentActionCreators.createAppointment({
        data: { ...payload, timezone: providerSchedule?.timezone },
        callback: (isSucceed, isInstantSession, appointmentId) => {
          if (isSucceed) {
            setSchedulesDrawerOpen(false);
            setIsAppointmentCreated(true);
            const endOfDay = currentDate.endOf('day');
            const startOfDay = currentDate.startOf('day');
            dispatch(
              scheduleActionCreators.fetchDailySchedule({
                userId: meta.userId,
                queryParams: {
                  startTime: startOfDay.format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
                  endTime: endOfDay.format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
                },
              }),
            );
            if (isInstantSession) {
              history.push(`/provider/appointments/current/${appointmentId}/session`);
            }
          }
        },
      }),
    );
  };

  const toggleRescheduleDrawer = () => {
    setShowReschedule(!showReschedule);
  };

  const onCloseDrawer = () => {
    setSelectedAppointment(null);
    setViewAppointmentDetail(false);
  };

  const selectAppointment = appointment => {
    setSelectedAppointment(appointment);
  };

  const onStartSession = appointment => {
    history.push(`/provider/appointments/current/${appointment?.appointmentId}/session`);
  };

  const onGoToChat = appointment => {
    history.push(`/provider/chats?userId=${appointment?.patientId}&type=care_team`);
  };

  const onSignOff = appointment => {
    history.push(`/provider/appointments/past/${appointment.appointmentId}/session-signoff/v2`);
  };

  const onBlockWholeDay = async () => {
    setIsActionLoading(true);
    try {
      const startOfDay = currentDate.startOf('day');
      await updateProviderSchedule(meta?.userId, {
        availability: [
          {
            startTimeOfDay: startOfDay.format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
            slots: [],
            active: true,
            removed: false,
          },
        ],
        timezone: providerSchedule?.timezone || getUserTimeZone(),
      });
      const endOfDay = currentDate.endOf('day');
      dispatch(
        scheduleActionCreators.fetchProviderSchedule({
          userId: meta.userId,
        }),
      );
      dispatch(
        scheduleActionCreators.fetchDailySchedule({
          userId: meta.userId,
          queryParams: {
            startTime: startOfDay.format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
            endTime: endOfDay.format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
          },
        }),
      );
    } catch (e) {
      const message = e.data?.errors?.[0]?.endUserMessage || 'Something went wrong!';
      dispatch(showSnackbar({ snackType: 'error', snackMessage: message }));
    } finally {
      setIsActionLoading(false);
      setOpenBlockedWholeDay(false);
    }
  };

  const onUnblockWholeDay = async () => {
    setIsActionLoading(true);
    try {
      const endOfDay = currentDate.endOf('day');
      const startOfDay = currentDate.startOf('day');
      await updateProviderSchedule(meta?.userId, {
        availability: [
          {
            startTimeOfDay: startOfDay.format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
            slots: [
              {
                start: startOfDay.format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
                end: endOfDay.format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
              },
            ],
            active: true,
            removed: false,
          },
        ],
        timezone: providerSchedule?.timezone || getUserTimeZone(),
      });
      dispatch(
        scheduleActionCreators.fetchDailySchedule({
          userId: meta.userId,
          queryParams: {
            startTime: startOfDay.format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
            endTime: endOfDay.format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
          },
        }),
      );
    } catch (e) {
      const message = e.data?.errors?.[0]?.endUserMessage || 'Something went wrong!';
      dispatch(showSnackbar({ snackType: 'error', snackMessage: message }));
    } finally {
      setIsActionLoading(false);
      setOpenUnblockWholeDay(false);
    }
  };

  useEffect(() => {
    // const dateParam = searchParams.get('date');
    if (dateParam && location.pathname.includes('day-availability')) {
      setCurrentTab(tabs[1].tabKey);
      setCurrentDate(dayjs(dateParam));
    }
  }, []);
  useEffect(() => {
    // const dateParam = searchParams.get('date');
    if (dateParam && location.pathname.includes('/day-schedule')) {
      setCurrentTab(tabs[0].tabKey);
      setCurrentDate(dayjs(dateParam));
    }
  }, [location.pathname]);

  useEffect(() => {
    searchParams.set('date', dayjs(currentDate).format('YYYY-MM-DD'));
    history.push({ search: searchParams.toString() });
  }, [currentDate]);

  return (
    <BaseLayout noPadding>
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            p: 4,
            background: colors.white,
          }}
        >
          <Header
            upper={
              <Box sx={{ display: 'flex', gap: 2 }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    // minDate={dayjs('2018-01-01')}
                    open={openDatePicker}
                    onClose={() => setOpenDatePicker(false)}
                    value={currentDate}
                    onChange={newValue => {
                      setCurrentDate(dayjs(newValue));
                      setCalendarKey(prevKey => prevKey + 1);
                    }}
                    PopperProps={{
                      placement: 'bottom-start',
                      anchorEl,
                    }}
                    renderInput={() => (
                      <IconButton
                        icon="calendar-primary-outlined"
                        className={classes.calendarBtn}
                        variant={btnType.OUTLINE}
                        onClick={event => {
                          setOpenDatePicker(true);
                          setAnchorEl(event.currentTarget);
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
                <Button
                  variant={btnType.OUTLINE}
                  className={classes.todayBtn}
                  onClick={() => {
                    setCurrentDate(dayjs());
                    setCalendarKey(prevKey => prevKey + 1);
                  }}
                >
                  <Typography {...typography.body.s.bold}>Today</Typography>
                </Button>
                <ButtonGroup
                  variant="outlined"
                  aria-label="outlined button group"
                  sx={{
                    display: {
                      md: 'inherit',
                      xs: 'flex',
                    },
                    '& button': {
                      flex: {
                        md: 'none',
                        xs: 0.5,
                      },
                    },
                  }}
                >
                  <IconButton
                    icon="chevron-left"
                    className={classes.upperActionIcon}
                    variant={btnType.OUTLINE}
                    onClick={() => gotoDay('prev')}
                  />
                  <IconButton
                    icon="chevron-right"
                    className={classes.upperActionIcon}
                    variant={btnType.OUTLINE}
                    onClick={() => gotoDay('next')}
                  />
                </ButtonGroup>
              </Box>
            }
            middle={<Heading variant={HeadingLevel.H5}>{headerLabel}</Heading>}
            actions={[
              {
                children: <Typography {...typography.body.m.bold}>New appointment</Typography>,
                variant: btnType.PRIMARY,
                className: classes.primaryBtn,
                onClick: () => setSchedulesDrawerOpen(true),
              },
            ]}
          />
        </Box>
        <Box className={classes.tabContainer}>
          <Tabs options={tabs} value={currentTab} onChange={setCurrentTab} className={classes.tabs} />
          <Box
            sx={{
              '& button': {
                gap: 1,
                color: colors.neutral900,
                '& svg': { width: 20, marginTop: schedulingAnchorEl ? '15px' : '2px' },
              },
            }}
          >
            <IconButton
              variant={btnType.TEXT}
              icon={schedulingAnchorEl ? 'chevron-up' : 'chevron-down'}
              iconPosition={iconBtnPosition.RIGHT}
              onClick={handleClick}
            >
              <Typography {...typography.body.normal.small.semibold} color={colors.neutral600}>
                Scheduling link
              </Typography>
            </IconButton>
          </Box>
          <Menu
            id="scheduling-link-menu"
            aria-labelledby="scheduling-link-button"
            anchorEl={schedulingAnchorEl}
            open={openSchedulingLink}
            onClose={handleClose}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            classes={{ list: classes.dropdownMenu, paper: classes.dropdown }}
          >
            <MenuItem
              className={classes.menuItem}
              onClick={async () => {
                await navigator.clipboard.writeText(defaultScheduleLink);
                dispatch(
                  showSnackbar({
                    snackType: 'success',
                    snackMessage: 'Scheduling link copied successfully',
                    vertical: 'top',
                    horizontal: 'center',
                  }),
                );
                handleClose();
              }}
            >
              <Icons glyph="copy-link" className={classes.menuBtn} />
              <span className={classes.menuText}>Copy link</span>
            </MenuItem>
            <MenuItem
              className={classes.menuItem}
              onClick={() => {
                setShowScheduleLinkDrawer(true);
                handleClose();
              }}
            >
              <Icons glyph="plus" className={classes.menuBtn} />
              <span className={classes.menuText}>Generate new</span>
            </MenuItem>
            {/* <ScheduleLink
              isSchedule={false}
              onOpen={() => {
                handleClose();
                setShowScheduleLinkDrawer(true);
              }}
              // onClose={() => {setShowScheduleLinkDrawer(false)}}
            /> */}
          </Menu>
        </Box>
        {currentTab === tabs[2].tabKey ? (
          <Box sx={{ flex: 1, overflowY: 'auto' }}>
            <TabPanel value={currentTab} tabKey={tabs[2].tabKey}>
              <RenderCalendar
                currentDate={currentDate}
                calendarKey={calendarKey}
                setSchedulesDrawerOpen={setSchedulesDrawerOpen}
                setCurrentDate={setCurrentDate}
                setSelectedAppointment={setSelectedAppointment}
                setShowDayAvailability={setShowDayAvailability}
                setOpenBlockedWholeDay={setOpenBlockedWholeDay}
                setViewAppointmentDetail={setViewAppointmentDetail}
              />
            </TabPanel>
          </Box>
        ) : (
          <Box className={classes.content}>
            <TabPanel value={currentTab} tabKey={tabs[0].tabKey}>
              <RenderAppointments
                currentDate={currentDate}
                setCurrentDate={setCurrentDate}
                setCalendarKey={setCalendarKey}
                tabs={tabs}
                setSchedulesDrawerOpen={setSchedulesDrawerOpen}
                setCurrentTab={setCurrentTab}
                onGoToChat={onGoToChat}
                onSignOff={onSignOff}
                onStartSession={onStartSession}
                toggleRescheduleDrawer={toggleRescheduleDrawer}
                selectAppointment={selectAppointment}
                setViewAppointmentDetail={setViewAppointmentDetail}
                setSelectedAppointment={setSelectedAppointment}
                setIsLoading={setIsLoading}
                isApptCreated={isAppointmentCreated}
                setIsApptCreated={isCreated => setIsAppointmentCreated(isCreated)}
              />
            </TabPanel>
            <TabPanel value={currentTab} tabKey={tabs[1].tabKey}>
              <RenderAvailability
                currentDate={currentDate}
                setOpenGeneralAvailability={setOpenGeneralAvailability}
                setOpenBlockedWholeDay={setOpenBlockedWholeDay}
                setShowDayAvailability={setShowDayAvailability}
                setSchedulesDrawerOpen={setSchedulesDrawerOpen}
                setOpenUnblockWholeDay={setOpenUnblockWholeDay}
                currentWeekDay={currentWeekDay}
              />
            </TabPanel>
          </Box>
        )}
      </Box>
      <AppointmentScheduling
        open={showReschedule}
        onClose={toggleRescheduleDrawer}
        appointment={selectedAppointment}
        updateAppointment={setSelectedAppointment}
        setIsApptCreated={isCreated => setIsAppointmentCreated(isCreated)}
      />
      {schedulesDrawerOpen && (
        <AddSchedule
          selectedDateTime={currentDate.format('DD-MM-YYYY')}
          isOpen={schedulesDrawerOpen}
          onClose={() => setSchedulesDrawerOpen(false)}
          onSubmit={onSubmitNewSchedule}
          onClickReschedule={toggleRescheduleDrawer}
        />
      )}
      <AppointmentDetail
        open={viewAppointmentDetail}
        onClose={onCloseDrawer}
        onRescheduleClick={toggleRescheduleDrawer}
        onAcceptAppointment={onStartSession}
        appointment={selectedAppointment}
        evaluations={evaluations}
      />
      {openBlockedWholeDay && (
        <ConfirmModal
          open={openBlockedWholeDay}
          icon="close-circle-outlined"
          title="Block the whole day?"
          content="It will make the whole day unavailable for booking appointments by members and matchmakers."
          lbtnLabel="Do not block"
          rbtnLabel="Block"
          isLoading={isActionLoading}
          onClose={() => setOpenBlockedWholeDay(false)}
          onSubmit={onBlockWholeDay}
        />
      )}
      {openUnblockWholeDay && (
        <ConfirmModal
          open={openUnblockWholeDay}
          icon="close-circle-outlined"
          title="Unblock the whole day?"
          content="It will make the whole day available for booking appointments by members and matchmakers."
          lbtnLabel="Do not unblock"
          rbtnLabel="Unblock"
          isLoading={isActionLoading}
          onClose={() => setOpenUnblockWholeDay(false)}
          onSubmit={onUnblockWholeDay}
        />
      )}
      {showDayAvailability && (
        <DayAvailabilityDrawer
          currentDate={currentDate}
          providerId={meta?.userId}
          isOpen={showDayAvailability}
          onClose={() => {
            setShowDayAvailability(false);
          }}
          schedules={dailySchedule?.availability}
          // fetchProviderDaySchedule={fetchProviderDaySchedule}
        />
      )}
      {openGeneralAvailability && (
        <GeneralAvailability
          title="Update general availability"
          isCalendar
          isOpen={openGeneralAvailability}
          providerId={meta.userId}
          onClose={() => {
            setOpenGeneralAvailability(false);
          }}
          // currentWeekDay={currentWeekDay}
        />
      )}
      {showScheduleLinkDrawer && (
        <GenerateLinkDrawer
          open={showScheduleLinkDrawer}
          onClose={() => setShowScheduleLinkDrawer(false)}
          providerId={meta?.userId}
          hostURL={HOST_URL}
        />
      )}
    </BaseLayout>
  );
};

export default Schedule;
