import { FC, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { Badge, badgeSize, badgeStyle, badgeType } from '../../../../../packages/ui/atoms/badge';
import { colors } from '../../../../../packages/colors';
import { TabPanel, Tabs } from '../../../../../packages/ui/atoms/tabs';
import { Icons } from '../../../../../packages/icons';
import { Collapsable, IiconPosition } from '../../../../../packages/ui/atoms/collapsable';
import { btnSize, btnType, Button } from '../../../../../packages/ui/atoms/button';
import clsx from 'clsx';
import { IDctContentBlock } from '../../../../../redux/modules/conversation/types';
import { useStyles } from './index.styles';
import Typography from '../../../../../components/v2/Typography';
import { typography } from '../../../../../components/v2/Typography/index.constant';
import { PreviousResponses } from './PreviousResponses';
import { Comments } from './Comments';

export const QuestionItem = ({
  id,
  contentBlock,
  contained,
  open,
  evaluationStatus,
  showBottomBorder,
  memberId,
  contextId,
  onPreviewExercise,
  // selectedTab,
  waitingForAI,
  appointmentId,
  evaluationId,
  contentIdentifier,
}) => {
  const classes = useStyles();
  const [tabType, setTabType] = useState('answer');
  const [collapseOpen, setCollapseOpen] = useState(open);
  const [tabs, setTabs] = useState([
    { tabKey: 'answer', label: 'Answer' },
    {
      tabKey: 'comments',
      label: 'Comments',
      count: contentBlock.userResponse?.commentsCount,
    },
    { tabKey: 'previous_answers', label: 'Previous answers' },
  ]);

  useEffect(() => {
    if (contentIdentifier === id) {
      setCollapseOpen(true);
      setTabType('comments');
    }
  }, []);

  const updateCommentCount = newCount => {
    setTabs(prevTabs => prevTabs.map(tab => (tab.tabKey === 'comments' ? { ...tab, count: newCount } : tab)));
  };
  const renderTab = tab => {
    return {
      tabKey: tab.tabKey,
      label: tab.label || tab.tabKey,
      icon: tab?.count ? (
        <Badge className={classes.countBadge} size={badgeSize.XSMALL}>
          {tab?.count || 0}
        </Badge>
      ) : (
        <></>
      ),
      iconPosition: 'end',
    };
  };

  const renderContentBody = () => {
    if (contentBlock.cbType === 'exercise' || contentBlock.cbType === 'education') {
      return null;
    }
    const values = contentBlock.userResponse?.userResponseList || ['Unanswered'];
    const options =
      contentBlock.cbType === 'single-select' || contentBlock.cbType === 'multi-select'
        ? contentBlock.choices
        : values.map(value => ({ choice: value }));

    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
        {/* <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}> */}
        {/*  <Heading variant={HeadingLevel.H6} fontWeight={HeadingWeight.SEMI_BOLD}> */}
        {/*    {contentBlock.text} */}
        {/*  </Heading> */}
        {/*  {contentBlock.subtitle?.length > 0 && ( */}
        {/*    <Typography {...typography.body.m.medium} color={colors.neutral600}> */}
        {/*      {contentBlock.subtitle} */}
        {/*    </Typography> */}
        {/*  )} */}
        {/* </Box> */}
        <Box display="flex" flexDirection="column" gap={1}>
          {options?.map((option, index) => {
            const isMatch = values.includes(option.choice);

            return (
              <Box key={index} display="flex" gap={1} alignItems="center">
                <Box
                  sx={{
                    height: 24,
                    width: 24,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  {isMatch &&
                  contentBlock.cbType !== 'text-input' &&
                  contentBlock.cbType !== 'rating-scale' ? (
                    <Icons glyph="checkmark" color={colors.neutral700} />
                  ) : (
                    <Box
                      sx={{
                        height: 8,
                        width: 8,
                        borderRadius: '50%',
                        background: colors.neutral400,
                      }}
                    />
                  )}
                </Box>
                <Typography
                  {...typography.body.m.regular}
                  color={isMatch ? colors.neutral900 : colors.neutral500}
                >
                  {option.choice}
                </Typography>
                {option?.riskFactor && (
                  <Box
                    className={classes.badge}
                    sx={{ border: `1px solid ${colors.neutral200}`, borderRadius: 25, px: 1 }}
                  >
                    <Typography {...typography.body.xs.semibold}>Risk Factor</Typography>
                  </Box>
                )}
              </Box>
            );
          })}
        </Box>
        {contentBlock.userResponse?.providerNarrative?.length > 0 && (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            <Typography {...typography.body.m.semibold}>Narrative</Typography>
            <Typography {...typography.body.m.medium} color={colors.neutral700}>
              {contentBlock.userResponse.providerNarrative}
            </Typography>
          </Box>
        )}
      </Box>
    );
  };

  const renderContent = () => {
    if (
      evaluationStatus === 'NOT_STARTED' ||
      contentBlock.cbType === 'exercise' ||
      contentBlock.cbType === 'education'
    ) {
      return null;
    }
    return (
      <Box className={classes.content}>
        <Box className={classes.contentHeader}>
          <Tabs
            value={tabType}
            onChange={setTabType}
            options={tabs.map(renderTab)}
            className={classes.tabs}
          />
        </Box>
        <Box className={classes.contentBody}>
          <TabPanel value={tabType} tabKey={tabs[0].tabKey}>
            {renderContentBody()}
          </TabPanel>
          <TabPanel value={tabType} tabKey={tabs[1].tabKey}>
            <Comments
              cbId={contentBlock.cbId}
              contextId={contextId}
              memberId={memberId}
              appointmentId={appointmentId}
              evaluationId={evaluationId}
              onCommentCount={updateCommentCount}
            />
          </TabPanel>
          <TabPanel value={tabType} tabKey={tabs[2].tabKey}>
            <PreviousResponses cbId={contentBlock.cbId} memberId={memberId} contextId={contextId} />
          </TabPanel>
        </Box>
      </Box>
    );
  };

  const hasNoResponse =
    !contentBlock.userResponse ||
    !contentBlock.userResponse.userResponseList ||
    contentBlock.userResponse.userResponseList.length === 0 ||
    contentBlock.userResponse.userResponseList.every(
      res => res.trim().toLowerCase() === 'not applicable' || res.trim().toLowerCase() === 'not discussed',
    );
  const responses =
    contentBlock.userResponse?.userResponseList.filter(
      res => res.trim().toLowerCase() !== 'not applicable' && res.trim().toLowerCase() !== 'not discussed',
    ) || [];
  const showSubtitle =
    evaluationStatus !== 'NOT_STARTED' &&
    contentBlock.cbType !== 'exercise' &&
    contentBlock.cbType !== 'education';

  const hasRiskFactor =
    evaluationStatus === 'COMPLETED' &&
    contentBlock.userResponse?.userResponseList?.length !== 0 &&
    contentBlock.choices?.length !== 0 &&
    contentBlock.userResponse?.userResponseList?.some(userResponseItem =>
      contentBlock.choices?.some(choice => choice.choice === userResponseItem && choice.riskFactor),
    );

  const commentCount = tabs.find(tab => tab.tabKey === 'comments').count ?? 0;

  return (
    <Collapsable
      id={id}
      className={clsx({
        [classes.collapsable]: true,
        [classes.questionInDct]: contained && showSubtitle /* && !hasNoResponse */,
        [classes.questionBorderBottom]: showBottomBorder,
        [classes.noPointer]: evaluationStatus === 'NOT_STARTED' /* || hasNoResponse  */ || !showSubtitle,
      })}
      iconHidden={evaluationStatus === 'NOT_STARTED' /*  || hasNoResponse */ || !showSubtitle}
      iconPosition={IiconPosition.LEFT}
      label=""
      labelExtend={
        <Box id={id} className={classes.question}>
          <Box sx={{ display: 'flex', flexDirection: 'column', marginRight: '1rem' }}>
            <Box className={classes.questionName}>{contentBlock.text}</Box>
            {showSubtitle && (
              <Box className={classes.questionType}>
                {hasNoResponse ? 'Unanswered' : responses.join(', ')}
              </Box>
            )}
          </Box>
          <Box display="flex" flexDirection="row" gap={2} alignItems="center">
            {commentCount > 0 && (
              <Badge className={classes.badge} variant={badgeType.OUTLINED} style={badgeStyle.PRIMARY}>
                {commentCount} Comment
                {commentCount > 1 && 's'}
              </Badge>
            )}
            {contentBlock.required && (
              <Badge className={classes.badge} variant={badgeType.OUTLINED} style={badgeStyle.REQUIRED}>
                Required
              </Badge>
            )}
            {hasRiskFactor && (
              <Badge className={classes.badge} variant={badgeType.OUTLINED} style={badgeStyle.CRITICAL}>
                Risk factor
              </Badge>
            )}
            {contentBlock.responderType === 'PROVIDER' && (
              <Badge className={classes.badge} variant={badgeType.OUTLINED} style={badgeStyle.PROVIDER}>
                For provider
              </Badge>
            )}
            {contentBlock.cbType === 'exercise' && (
              <Button
                variant={btnType.TEXT}
                size={btnSize.XXSMALL}
                className={classes.previewButton}
                onClick={() => {
                  onPreviewExercise(contentBlock.cbId);
                }}
              >
                Preview exercise
              </Button>
            )}
            {waitingForAI && (
              <Badge className={classes.badge} variant={badgeType.OUTLINED} style={badgeStyle.MISREPORTED}>
                Waiting for AI
              </Badge>
            )}
          </Box>
        </Box>
      }
      open={collapseOpen}
    >
      {renderContent()}
    </Collapsable>
  );
};
