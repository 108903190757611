// colors
import { colors } from '../../../packages/colors';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  container: {
    width: '100%',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
  },
  container2: {
    width: '100%',
    // height: '100vh',
    display: 'flex',
    flexDirection: 'column',
  },
  mainContent: {
    backgroundColor: colors.neutral50,
    flex: 1,
    overflowY: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100vh - 80px)',
  },
  waitingScreen: {
    padding: '64px',
    flex: 1,
    width: '100%',
    gap: 64,
  },
  priorityList: {
    position: 'relative',
    height: 'calc(100vh - 80px)',
    overflowY: 'auto',
    width: '100%',
    padding: '56px 264px',
    '@media (max-width: 1440px)': {
      padding: '32px',
    },
  },
  checklistWrap: {
    height: '100%',
  },
  checklist: {
    padding: 0,
    height: '100%',
    overflow: 'unset',
  },
  boxLeft: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
});
