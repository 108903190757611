import axios from 'axios';

import { ApiEndpoints } from '../constants/ApiEndpoints';
import getConfig from '../config';

const LOGIN_PATH = '/login';

function authHeader() {
  return localStorage.getItem('authorizationToken');
}

function isAuthenticationEndpoint(endpoint) {
  return (
    endpoint === ApiEndpoints.LOGIN.path ||
    endpoint === ApiEndpoints.VERIFY_OTP.path ||
    endpoint === ApiEndpoints.USER_ACCOUNT_RECOVERY.path ||
    endpoint === ApiEndpoints.UPDATE_PASSWORD.path ||
    endpoint === ApiEndpoints.VERIFY_CONFIRMATION_CODE.path ||
    endpoint === ApiEndpoints.GET_CONNECTIONS.path ||
    endpoint === ApiEndpoints.GET_PROVIDER_SCHEDULE.path
  );
}

export function setAuthToken(token) {
  try {
    localStorage.setItem('authorizationToken', `Bearer ${String(token)}`);
  } catch (e) {
    console.log(e);
  }
}

export function deleteAuthToken() {
  try {
    localStorage.removeItem('authorizationToken');
    localStorage.removeItem('meta');
  } catch (e) {
    console.log(e);
    return false;
  }
  return true;
}

function checkTokenExpiry(response, endpoint) {
  if (response && response.errorCode && response.errorCode === 'UNAUTHORIZED') {
    if (!this.isAuthenticationEndpoint(endpoint)) {
      deleteAuthToken();
      window.location.replace(LOGIN_PATH);
    }
  }
}

function generateQueryParams(queryParams) {
  const esc = encodeURIComponent;
  return `?${String(
    Object.keys(queryParams)
      .map(k => `${esc(k)}=${esc(queryParams[k])}`)
      .join('&'),
  )}`;
}

function formatEndpoint(endpoint, pathParams, queryParams) {
  // generating fully qualified path for request
  let formattedEndpoint = endpoint.path;
  if (pathParams) {
    // Replacing url path params with actual provided values.
    Object.keys(pathParams).forEach(key => {
      formattedEndpoint = formattedEndpoint.replace(`{${String(key)}}`, pathParams[key]);
    });
  }

  if (formattedEndpoint.includes('{')) {
    // Some path params aren't replaced with actual values.
    throw new Error(
      'Path parameters are required and their keys must' +
        'match with the placeholder keys defined in endpoint declaration',
    );
  }
  if (queryParams) {
    // Appending Query params with url path
    formattedEndpoint += generateQueryParams(queryParams);
  }

  return formattedEndpoint;
}

export const signOff = () => {
  deleteAuthToken();
};

function methodType(method) {
  switch (method.toLowerCase()) {
    case 'get':
      return 'GET';
    case 'delete':
      return 'DELETE';
    case 'head':
      return 'HEAD';
    case 'options':
      return 'OPTIONS';
    case 'post':
      return 'POST';
    case 'put':
      return 'PUT';
    case 'patch':
      return 'PATCH';
    case 'purge':
      return 'PURGE';
    case 'link':
      return 'LINK';
    case 'unlink':
      return 'UNLINK';
    default:
      console.log('Invalid Type returning get');
      return 'GET';
  }
}

const servicePortMapping = {
  'report-service-node': 3000,
  profile: 8084,
  conversation: 8082,
  v2: 8090, // v2/scheduling
  scheduling: 8085,
  auth: 8083,
  billing: 8087,
  audit: 8081,
};

function getPortForService(service) {
  const serviceKey = Object.keys(servicePortMapping).find(key => service.includes(key));
  return servicePortMapping[serviceKey] || 9191;
}

axios.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    return Promise.reject(error.response);
  },
);

export function baseRequest(
  endpoint,
  requestBody,
  pathParams,
  queryParams,
  requireAuth = true,
  isBase = true,
  isBlob = false,
  isMultiPart = false,
  formDataKey = null,
  stringifyMultipart = true,
) {
  let elkBody = {};
  let headers;
  let formData;
  let endpointPath =
    pathParams || queryParams ? formatEndpoint(endpoint, pathParams, queryParams) : endpoint.path;
  if (isMultiPart) {
    formData = requestBody;
    // if (stringifyMultipart) {
    //   formData.append(formDataKey, JSON.stringify(requestBody[formDataKey]));
    // } else {
    //   formData.append(formDataKey, requestBody[formDataKey]);
    // }
    // eslint-disable-next-line no-param-reassign
    requestBody = formData;
    headers = requireAuth
      ? { 'Content-Type': 'multipart/form-data', Authorization: authHeader() }
      : { 'Content-Type': 'multipart/form-data' };
  } else {
    headers = requireAuth
      ? { 'Content-Type': 'application/json', Authorization: authHeader() }
      : { 'Content-Type': 'application/json' };
  }
  let elkBaseUrl;
  let elkPrefix = '';

  let baseUrl = getConfig.api.baseUrl;

  // for local environment
  if (baseUrl.includes('localhost')) {
    const serviceName = endpoint.path.split('/');
    const port = getPortForService(endpoint.path.charAt(0) === '/' ? serviceName[1] : serviceName[0]);
    baseUrl = baseUrl.replace('[port]', port);
    if (endpointPath.includes('report-service-node')) {
      if (endpointPath.charAt(0) === '/') {
        endpointPath = endpointPath.replace('/report-service-node/', '/');
      } else {
        endpointPath = endpointPath.replace('report-service-node/', '/');
      }
    }
  }

  if (!isBase) {
    // elkBaseUrl = getConfig.api.elkUrl.split('/').slice(0, -1).join('/');
    // elkPrefix = getConfig.api.elkUrl.split('/').pop();
    // converting the call to proxy server
    elkBaseUrl = getConfig.api.elkUrl.split('/').slice(0, -1).join('/');
    elkPrefix = getConfig.api.elkUrl.split('/').pop();
    const finalUrl = `${elkBaseUrl}/${elkPrefix}${endpointPath}`;
    elkBody = {
      url: finalUrl,
      data: { ...requestBody },
    };

    elkBaseUrl = baseUrl;
    elkPrefix = '/report-service-node/elastic-proxy';

    endpointPath = '';

    // const { username, password } = getConfig.api;
    // if (username && password) {
    //   headers.Authorization = `Basic ${btoa(`${username}:${password}`)}`;
    // }
  }

  const conf = isBlob
    ? {
        method: methodType(endpoint.method),
        baseURL: isBase ? baseUrl : elkBaseUrl,
        headers,
        responseType: 'blob',
        url: `${elkPrefix}${endpointPath}`,
        data: isBase ? requestBody : elkBody,
      }
    : {
        method: methodType(endpoint.method),
        baseURL: isBase ? baseUrl : elkBaseUrl,
        headers,
        url: `${elkPrefix}${endpointPath}`,
        data: isBase ? requestBody : elkBody,
      };

  return axios
    .request(conf)
    .then(response => {
      if (response && response.status === 200 && response.headers && response.data) {
        if (response.data.accessToken) {
          console.log('authorization found');
          setAuthToken(response.data.accessToken);
        }
      }
      return response;
    })
    .catch(error => {
      if (error?.status === 401) {
        if (!isAuthenticationEndpoint(endpoint.path)) {
          localStorage.removeItem('authorizationToken');
          window.location.replace(LOGIN_PATH);
        } else {
          return Promise.reject(error.data?.errors?.[0]?.endUserMessage || 'Unauthorized');
        }
      }

      if (error?.response) {
        // The client was given an error response (5xx, 4xx)
        if (error.response.status && error.response.status === 404) {
          throw new Error(
            `404 error occured. Reason:  ${String(error.response.data.error)} ENDPOINT : ${String(
              error.response.config.url,
            )}`,
          );
        } else if (error.response.status && error.response.status === 401) {
          if (!isAuthenticationEndpoint(endpoint.path)) {
            console.log('Routing to Login');
            window.location.replace(LOGIN_PATH);
          }
        } else if (error.response.status && error.response.status !== 200) {
          throw new Error(`An unexpected error occurred. Reason:  ${String(error.response.data.message)}`);
        }
        checkTokenExpiry(error.response, endpoint);
      }
      return Promise.reject(error);
    });
}
