// import dayjs from 'dayjs';
// import { IAppointment } from '../appointment-card';
// import { AppointmentStatus } from '../../../constants/CommonConstants';

// export const CurrentAppointmentsMock: IAppointment[] | any = [
//   {
//     appointmentId: '1',
//     serviceName: '15 min session with nurse practitioner',
//     serviceDuration: 30,
//     participantName: 'Eva Bond',
//     status: AppointmentStatus.BOOKED,
//     participantImage: 'https://i.pravatar.cc/300',
//     startTime: dayjs().add(1, 'h').toISOString(),
//   },
//   {
//     appointmentId: '2',
//     serviceName: 'Meds for alcohol use',
//     serviceDuration: 30,
//     participantName: 'Wade Warren',
//     status: AppointmentStatus.BOOKED,
//     participantImage: 'https://i.pravatar.cc/300',
//     startTime: dayjs().add(1, 'd').toISOString(),
//   },
//   {
//     appointmentId: '3',
//     serviceName: 'Medication evaluation',
//     serviceDuration: 30,
//     participantName: 'Eduard Franz',
//     role: 'Prescriber',
//     status: AppointmentStatus.BOOKED,
//     participantImage: 'https://i.pravatar.cc/300',
//     startTime: dayjs().add(48, 'h').toISOString(),
//   },
//   {
//     appointmentId: '4',
//     serviceName: 'Medication evaluation 2',
//     serviceDuration: 30,
//     participantName: 'Eduard Franz',
//     status: AppointmentStatus.BOOKED,
//     participantImage: 'https://i.pravatar.cc/300',
//     startTime: dayjs().add(49, 'h').toISOString(),
//   },
//   {
//     appointmentId: '5',
//     serviceName: 'Medication evaluation 3',
//     serviceDuration: 30,
//     participantName: 'Eduard Franz',
//     status: AppointmentStatus.BOOKED,
//     participantImage: 'https://i.pravatar.cc/300',
//     startTime: dayjs().add(50, 'h').toISOString(),
//   },
// ];

// export const PastAppointmentsMock: IAppointment[] | any = [
//   {
//     appointmentId: '1',
//     serviceName: '15 min session with nurse practitioner',
//     serviceDuration: 30,
//     participantName: 'Eva Bond',
//     status: 'completed',
//     feedback: {
//       rating: 4,
//     },
//     participantImage: 'https://i.pravatar.cc/300',
//     startTime: dayjs().subtract(1, 'd').toISOString(),
//   },
//   {
//     appointmentId: '2',
//     serviceName: 'Meds for alcohol use',
//     serviceDuration: 30,
//     participantName: 'John Smith',
//     status: 'noShow',
//     participantImage: 'https://i.pravatar.cc/300',
//     startTime: dayjs().subtract(2, 'd').toISOString(),
//   },
//   {
//     appointmentId: '3',
//     serviceName: 'Medication evaluation',
//     serviceDuration: 30,
//     participantName: 'Eduard Franz',
//     status: 'canceled',
//     participantImage: 'https://i.pravatar.cc/300',
//     startTime: dayjs().add(2, 'd').toISOString(),
//   },
// ];

// export const PendingAppointmentsMock: IAppointment[] | any = [
//   {
//     appointmentId: '1',
//     serviceName: 'Meds for opioid use evaluation',
//     serviceDuration: 30,
//     participantName: 'Eva Bond',
//     status: 'requested',
//     participantImage: 'https://i.pravatar.cc/300',
//     startTime: dayjs().toISOString(),
//   },
//   {
//     appointmentId: '2',
//     serviceName: 'Meds for opioid use evaluation',
//     serviceDuration: 30,
//     participantName: 'Eva Bond',

//     status: 'waiting',
//     participantImage: 'https://i.pravatar.cc/300',
//     startTime: dayjs().add(26, 'h').toISOString(),
//   },
// ];

// export const ProviderScheduleMock = {
//   timezone: 'America/Vancouver',
//   planningHorizon: [
//     {
//       day: 'SUNDAY',
//       active: true,
//       availability: [{ start: 2000, end: 2400 }],
//     },
//     {
//       day: 'MONDAY',
//       active: false,
//       availability: [{ start: 2000, end: 2400 }],
//     },
//     {
//       day: 'TUESDAY',
//       active: true,
//       availability: [{ start: 0, end: 500 }],
//     },
//     {
//       day: 'WEDNESDAY',
//       active: true,
//       availability: [{ start: 2000, end: 2400 }],
//     },
//     {
//       day: 'THURSDAY',
//       active: true,
//       availability: [{ start: 0, end: 500 }],
//     },

//     {
//       day: 'FRIDAY',
//       active: true,
//       availability: [{ start: 0, end: 500 }],
//     },
//     {
//       day: 'SATURDAY',
//       active: true,
//       availability: [{ start: 0, end: 500 }],
//     },
//   ],
//   blockingHorizon: [],
//   nextAvailableSlot: {
//     availableSlot: [
//       { end: 1130, start: 1115 },
//       { end: 1300, start: 1315 },
//     ],
//     nextAvailableDay: '10-10-2022',
//   },
// };

import dayjs from 'dayjs';
import { AppointmentStatus } from '../../../constants/CommonConstants';

export const CurrentAppointmentsMock = [
  {
    appointmentId: '1',
    serviceName: '15 min session with nurse practitioner',
    serviceDuration: 30,
    participantName: 'Eva Bond',
    status: AppointmentStatus.BOOKED,
    participantImage: 'https://i.pravatar.cc/300',
    startTime: dayjs().add(1, 'h').toISOString(),
  },
  {
    appointmentId: '2',
    serviceName: 'Meds for alcohol use',
    serviceDuration: 30,
    participantName: 'Wade Warren',
    status: AppointmentStatus.BOOKED,
    participantImage: 'https://i.pravatar.cc/300',
    startTime: dayjs().add(1, 'd').toISOString(),
  },
  {
    appointmentId: '3',
    serviceName: 'Medication evaluation',
    serviceDuration: 30,
    participantName: 'Eduard Franz',
    role: 'Prescriber',
    status: AppointmentStatus.BOOKED,
    participantImage: 'https://i.pravatar.cc/300',
    startTime: dayjs().add(48, 'h').toISOString(),
  },
  {
    appointmentId: '4',
    serviceName: 'Medication evaluation 2',
    serviceDuration: 30,
    participantName: 'Eduard Franz',
    status: AppointmentStatus.BOOKED,
    participantImage: 'https://i.pravatar.cc/300',
    startTime: dayjs().add(49, 'h').toISOString(),
  },
  {
    appointmentId: '5',
    serviceName: 'Medication evaluation 3',
    serviceDuration: 30,
    participantName: 'Eduard Franz',
    status: AppointmentStatus.BOOKED,
    participantImage: 'https://i.pravatar.cc/300',
    startTime: dayjs().add(50, 'h').toISOString(),
  },
];

export const PastAppointmentsMock = [
  {
    appointmentId: '1',
    serviceName: '15 min session with nurse practitioner',
    serviceDuration: 30,
    participantName: 'Eva Bond',
    status: 'completed',
    feedback: {
      rating: 4,
    },
    participantImage: 'https://i.pravatar.cc/300',
    startTime: dayjs().subtract(1, 'd').toISOString(),
  },
  {
    appointmentId: '2',
    serviceName: 'Meds for alcohol use',
    serviceDuration: 30,
    participantName: 'John Smith',
    status: 'noShow',
    participantImage: 'https://i.pravatar.cc/300',
    startTime: dayjs().subtract(2, 'd').toISOString(),
  },
  {
    appointmentId: '3',
    serviceName: 'Medication evaluation',
    serviceDuration: 30,
    participantName: 'Eduard Franz',
    status: 'canceled',
    participantImage: 'https://i.pravatar.cc/300',
    startTime: dayjs().add(2, 'd').toISOString(),
  },
];

export const PendingAppointmentsMock = [
  {
    appointmentId: '1',
    serviceName: 'Meds for opioid use evaluation',
    serviceDuration: 30,
    participantName: 'Eva Bond',
    status: 'requested',
    participantImage: 'https://i.pravatar.cc/300',
    startTime: dayjs().toISOString(),
  },
  {
    appointmentId: '2',
    serviceName: 'Meds for opioid use evaluation',
    serviceDuration: 30,
    participantName: 'Eva Bond',
    status: 'waiting',
    participantImage: 'https://i.pravatar.cc/300',
    startTime: dayjs().add(26, 'h').toISOString(),
  },
];

export const ProviderScheduleMock = {
  timezone: 'America/Vancouver',
  planningHorizon: [
    {
      day: 'SUNDAY',
      active: true,
      availability: [{ start: 2000, end: 2400 }],
    },
    {
      day: 'MONDAY',
      active: false,
      availability: [{ start: 2000, end: 2400 }],
    },
    {
      day: 'TUESDAY',
      active: true,
      availability: [{ start: 0, end: 500 }],
    },
    {
      day: 'WEDNESDAY',
      active: true,
      availability: [{ start: 2000, end: 2400 }],
    },
    {
      day: 'THURSDAY',
      active: true,
      availability: [{ start: 0, end: 500 }],
    },

    {
      day: 'FRIDAY',
      active: true,
      availability: [{ start: 0, end: 500 }],
    },
    {
      day: 'SATURDAY',
      active: true,
      availability: [{ start: 0, end: 500 }],
    },
  ],
  blockingHorizon: [],
  nextAvailableSlot: {
    availableSlot: [
      { end: 1130, start: 1115 },
      { end: 1300, start: 1315 },
    ],
    nextAvailableDay: '10-10-2022',
  },
};
