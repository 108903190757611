/* eslint-disable jsx-a11y/aria-role */
import { Box, Grid } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Button, colors, fontWeight, Heading, headingLevel, Text, textLevel } from '../../../../packages';
import { Fragment } from 'react';
import dayjs from 'dayjs';
import ProfileInfo from '../../../../components/profile-info';

const AvailableProviders = ({ data, onClose }) => {
  return (
    <Fragment>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderBottom: '1px solid #E5EBF0',
          p: 3,
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Heading level={headingLevel.M} weight={fontWeight.BOLD}>
            {dayjs(data?.date).format('dddd, MMMM D, YYYY')}
          </Heading>
          <Text level={textLevel.M} color={colors.neutral600}>
            {data?.items?.length} providers available
          </Text>
        </Box>
        <CloseIcon fontSize="medium" onClick={onClose} style={{ color: colors.primary500 }} />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          backgroundColor: colors.neutral50,
          borderRadius: 2,
          p: 4,
          mt: -2,
        }}
      >
        {data?.items?.map((item, index) => {
          const slotsList = item?.slots?.filter(
            d => dayjs(d?.start).format('YYYY-MM-DD') === dayjs(item?.firstAvailable).format('YYYY-MM-DD'),
          );

          return (
            <Box
              key={index}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: colors.white,
                border: '1px solid #E5EBF0',
                borderRadius: 2,
              }}
            >
              <Box
                sx={{
                  px: 3,
                  py: 2,
                  borderBottom: '1px solid #E5EBF0',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <ProfileInfo fullName={item.provider.name} userRole="provider" />
                <Button>Schedule</Button>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, p: 3 }}>
                <Grid container columnSpacing={2}>
                  {slotsList?.map((slot, ii) => (
                    <Grid item xs={6} key={ii}>
                      <Box
                        sx={{
                          border: '1px solid #D4DDE5',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          borderRadius: 3,
                          py: 1,
                          mb: 1.5,
                        }}
                      >
                        <Text level={textLevel.S} weight={fontWeight.MEDIUM}>
                          {dayjs(slot?.start).format('H:mm A')} - {dayjs(slot?.end).format('H:mm A')}
                        </Text>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
                {item?.provider?.insuranceDetails?.length > 0 &&
                  item?.provider?.insuranceDetails?.map((insurance, insuranceIndex) => {
                    const names = insurance?.insurances?.map(ins => ins?.name) || [];
                    const joinedNames = names?.join(', ');
                    return (
                      <Grid container key={insuranceIndex} columnSpacing={2}>
                        <Grid item xs={6}>
                          <Text
                            level={textLevel.S}
                            weight={fontWeight.BOLD}
                          >{`Insurances accepted in ${insurance?.state}`}</Text>
                        </Grid>
                        <Grid item xs={6}>
                          <Text level={textLevel.S} weight={fontWeight.MEDIUM} color={colors.neutral700}>
                            {joinedNames}
                          </Text>
                        </Grid>
                      </Grid>
                    );
                  })}
                {item?.provider?.specialties && (
                  <Grid container columnSpacing={2}>
                    <Grid item xs={6}>
                      <Text level={textLevel.S} weight={fontWeight.BOLD}>
                        Specialties
                      </Text>
                    </Grid>
                    <Grid item xs={6}>
                      <Text level={textLevel.S} weight={fontWeight.MEDIUM} color={colors.neutral700}>
                        {item?.provider?.specialties}
                      </Text>
                    </Grid>
                  </Grid>
                )}
                {item?.provider?.approach && (
                  <Grid container columnSpacing={2}>
                    <Grid item xs={12}>
                      <Text level={textLevel.S} weight={fontWeight.MEDIUM} color={colors.neutral700}>
                        {item?.provider?.approach}
                      </Text>
                    </Grid>
                  </Grid>
                )}
              </Box>
            </Box>
          );
        })}
      </Box>
    </Fragment>
  );
};

export default AvailableProviders;
