import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import CheckPDMP from '../../../components/v2/CheckPDMP';
import IdentificationCard from '../../../components/v2/IdentificationCard';
import AppointmentWithEvals from './appointments/AppointmentWithEvals';
import Diagnoses from './diagnoses';
import Medications from './medications';
import SymptomaticFactors from './symptomatic-factors';
import EnviromentalFactors from './enviromental-factors';
import { getPreviousCompletedAppointment } from '../../../services/appointment/appointment.service';
import { PriorityDetailDrawer } from './PriorityDetailDrawer';
import RenderEvaluations from './evaluations';
import { PrioritiesCard } from '../sessions/priority-list/PriorityList.constants';
import RecentCaseNotes from './case-notes';
import { getMemberNotes } from '../../../services/member/member.service';
import { CheckIDs } from '../../../packages/ui/templates/sessions/section-detail/components/check-ids';

const Priorities = ({
  hideNotes,
  currentAppointment,
  isInPersonSessionCall = false,
  demographicDetails,
  isSession = false,
  handleMemberActionClick,
}) => {
  const [lastAppointment, setLastAppointment] = useState(null);
  const [viewAllType, setViewAllType] = useState(null);
  const [diagnosesReset, setDiagnosesReset] = useState(false);
  const [medicationsReset, setMedicationsReset] = useState(false);
  const [symptomsReset, setSymptomsReset] = useState(false);
  const [envFactorsReset, setEnvFactorsReset] = useState(false);
  const [selectedPriority, setSelectedPriority] = useState(null);
  const [notes, setNotes] = useState(null);
  const [caseNotesLoader, setCaseNotesLoader] = useState(false);

  const onViewAll = type => {
    setSelectedPriority(null);
    setViewAllType(type);
  };

  const onCloseViewAllDrawer = () => {
    setViewAllType(null);
  };

  const onElementsUpdated = type => {
    switch (type) {
      case PrioritiesCard.Diagnoses: {
        setDiagnosesReset(v => !v);
        break;
      }
      case PrioritiesCard.Medications: {
        setMedicationsReset(v => !v);
        break;
      }
      case PrioritiesCard.SymptomaticFactors: {
        setSymptomsReset(v => !v);
        break;
      }
      case PrioritiesCard.EnvironmentalFactors: {
        setEnvFactorsReset(v => !v);
        break;
      }
      default: {
        console.log('Unknown type updated');
      }
    }
  };

  useEffect(() => {
    if (currentAppointment) {
      getPreviousCompletedAppointment(currentAppointment.patientId, currentAppointment.startTime)
        .then(({ data }) => {
          setLastAppointment(data.appointment);
        })
        .catch(e => {
          console.error(e);
        });

      setCaseNotesLoader(true);
      fetchNotesCall();
    }
  }, [currentAppointment]);

  const fetchNotesCall = () => {
    getMemberNotes({ userId: currentAppointment?.participantId }, { pageNumber: 0, pageSize: 3 })
      .then(res => {
        if (res.data?.patientContactNotes) {
          setNotes(res.data);
        }
        setCaseNotesLoader(false);
      })
      .catch(error => {
        setCaseNotesLoader(false);
        console.log({ error });
      });
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
      {!isSession ? (
        <>
          {/* <RenderNotes memberId={currentAppointment.participantId} hideIcon /> */}
          {currentAppointment && (
            <AppointmentWithEvals
              appointment={currentAppointment}
              fetchEvaluationsOnLoad
              hideNotes={hideNotes}
            />
          )}
          <RecentCaseNotes
            notes={notes?.patientContactNotes}
            total={notes?.totalRecords}
            memberId={currentAppointment?.participantId}
            fetchNotes={fetchNotesCall}
            caseNotesLoader={caseNotesLoader}
          />
          {/* ICH-3260 */}
          {demographicDetails && (
            <CheckIDs
              promptsVisible
              idCardFront={demographicDetails.idCardFront}
              idCardBack={demographicDetails.idCardBack}
            />
          )}
          <RenderEvaluations isWaiting={hideNotes} memberId={currentAppointment.participantId} />
          {/* {hideNotes && <RenderKPIs memberId={currentAppointment?.patientId} />} */}
          {/* <RenderProfile /> */}
          <SymptomaticFactors
            memberId={currentAppointment.patientId}
            onViewAll={onViewAll}
            onClick={(item, type) => {
              setSelectedPriority(item);
              setViewAllType(type);
            }}
            reset={symptomsReset}
          />
          {lastAppointment && (
            <AppointmentWithEvals
              appointment={lastAppointment}
              fetchEvaluationsOnLoad
              isPast
              hideNotes={hideNotes}
            />
          )}
          <Diagnoses
            memberId={currentAppointment.patientId}
            hideNotes={hideNotes}
            onViewAll={onViewAll}
            onClick={(item, type) => {
              setSelectedPriority(item);
              setViewAllType(type);
            }}
            reset={diagnosesReset}
          />
          <Medications
            memberId={currentAppointment.patientId}
            hideNotes={hideNotes}
            onViewAll={onViewAll}
            onClick={(item, type) => {
              setSelectedPriority(item);
              setViewAllType(type);
            }}
            reset={medicationsReset}
          />
          <EnviromentalFactors
            memberId={currentAppointment.patientId}
            onViewAll={onViewAll}
            onClick={(item, type) => {
              setSelectedPriority(item);
              setViewAllType(type);
            }}
            reset={envFactorsReset}
          />
        </>
      ) : (
        <>
          <Diagnoses
            memberId={currentAppointment.patientId}
            hideNotes={hideNotes}
            onViewAll={onViewAll}
            onClick={(item, type) => {
              setSelectedPriority(item);
              setViewAllType(type);
            }}
            reset={diagnosesReset}
          />
          <RenderEvaluations isWaiting={hideNotes} memberId={currentAppointment.participantId} />
          <Medications
            memberId={currentAppointment.patientId}
            hideNotes={hideNotes}
            onViewAll={onViewAll}
            onClick={(item, type) => {
              setSelectedPriority(item);
              setViewAllType(type);
            }}
            reset={medicationsReset}
          />
          <SymptomaticFactors
            memberId={currentAppointment.patientId}
            onViewAll={onViewAll}
            onClick={(item, type) => {
              setSelectedPriority(item);
              setViewAllType(type);
            }}
            reset={symptomsReset}
          />
          <EnviromentalFactors
            memberId={currentAppointment.patientId}
            onViewAll={onViewAll}
            onClick={(item, type) => {
              setSelectedPriority(item);
              setViewAllType(type);
            }}
            reset={envFactorsReset}
          />
          <RecentCaseNotes
            notes={notes?.patientContactNotes}
            total={notes?.totalRecords}
            memberId={currentAppointment?.participantId}
            fetchNotes={fetchNotesCall}
            caseNotesLoader={caseNotesLoader}
          />
          {currentAppointment && (
            <AppointmentWithEvals
              appointment={currentAppointment}
              fetchEvaluationsOnLoad
              hideNotes={hideNotes}
            />
          )}
          {demographicDetails && (
            <CheckIDs
              promptsVisible
              idCardFront={demographicDetails.idCardFront}
              idCardBack={demographicDetails.idCardBack}
            />
          )}
          {lastAppointment && (
            <AppointmentWithEvals
              appointment={lastAppointment}
              fetchEvaluationsOnLoad
              isPast
              hideNotes={hideNotes}
            />
          )}
        </>
      )}
      <IdentificationCard
        isSession
        isInPersonSessionCall={isInPersonSessionCall}
        memberId={currentAppointment.patientId}
        handleMemberActionClick={handleMemberActionClick}
      />
      {(currentAppointment?.patientState === 'Connecticut' ||
        currentAppointment?.patientState === 'Texas' ||
        currentAppointment?.patientState === 'Florida' ||
        currentAppointment?.patientState === 'Virginia' ||
        currentAppointment?.patientState === 'New Hampshire') && (
        <CheckPDMP patientState={currentAppointment.patientState} />
      )}
      {!!viewAllType && (
        <PriorityDetailDrawer
          memberId={currentAppointment.patientId}
          appointment={currentAppointment}
          selectedItem={selectedPriority}
          type={viewAllType}
          onClose={onCloseViewAllDrawer}
          open={!!viewAllType}
          onElementsUpdated={onElementsUpdated}
        />
      )}
    </Box>
  );
};

export default Priorities;
