// colors
import { colors } from '../../../../../packages';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  label: {
    display: 'block',
    marginBottom: 8,
  },
  drawer: {
    '& .drawerBody': {
      minHeight: 'calc(100vh - 50px)',
      flexDirection: 'column',
      display: 'flex',
    },
  },
  badge: {
    borderRadius: 100,
    height: 28,
  },
  title: {
    fontFamily: 'Manrope',
    fontSize: '22px',
    fontWeight: '500',
    lineHeight: '28px',
    textAlign: 'left',
  },
  text14: {
    fontSize: 14,
    lineHeight: '20px',
  },
  text16: {
    fontSize: 16,
    lineHeight: '24px',
  },
  duration: {
    color: colors.neutral600,
    display: 'block',
    marginTop: 4,
  },
  info: {
    color: colors.neutral700,
  },
  fullname: {
    color: colors.neutral500,
    display: 'block',
  },
  icon: {},
  boxInfo: {
    display: 'flex',
    alignItems: 'center',
    gap: 10,
    marginBottom: 10,
  },
  chatBtn: {
    '& svg': {
      width: 16,
      marginLeft: 10,
    },
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    gap: 10,
    flexDirection: 'column',
  },
  btn: {
    width: '100%',
    color: colors.white,
  },
  btnCancel: {
    border: `1px solid ${colors.destructive300}`,
    color: colors.destructive600,
  },
  btnReschedule: {
    border: `1px solid ${colors.primary300}`,
    color: colors.primary600,
  },
  btnEvaluation: {
    border: `1px solid ${colors.primary300}`,
    color: colors.primary600,
    minWidth: 40,
    minHeight: 40,
  },
  evaluationFooter: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 80,
    padding: 16,
    borderTop: `1px solid ${colors.neutral100}`,
  },
  wrapper: {
    justifyItems: 'space-between',
    flexDirection: 'column',
  },
  backBtn: {
    width: 40,
    height: 40,
    minWidth: 40,
    borderRadius: 8,
    border: `1px solid ${colors.primary300}`,
    '& svg': {
      width: 8,
    },
  },
  header: {
    display: 'flex',
    gap: 24,
    alignItems: 'center',
    height: 80,
    padding: '20px 16px',
    borderBottom: `1px solid ${colors.neutral100}`,
  },
  headerTitle: {
    fontSize: 24,
    lineHeight: '32px',
    fontWeight: 700,
  },
  searchWrap: {
    padding: 24,
    maxHeight: 'calc(100% - 330px)',
    overflowY: 'scroll',
  },
  searchInput: {
    height: 48,
    width: '100%',
    backgroundColor: colors.white,
  },
  evaluations: {
    '& > div': {
      borderBottom: `1px solid ${colors.neutral100}`,
      '&:last-child': {
        borderBottom: 'none',
      },
    },
  },
  evaluation: {
    flexDirection: 'column',
    display: 'flex',
    padding: '38px 30px',
    borderBottom: `1px solid ${colors.neutral100}`,
  },
  infoLabel: {
    display: 'block',
    marginBottom: 8,
    fontSize: 18,
    fontWeight: 600,
    lineHeight: '24px',
    color: colors.neutral900,
  },
  infoValue: {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: '20px',
    color: colors.neutral700,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: '350px',
  },
  footer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginTop: 40,
    marginBottom: 40,
  },
  footerBtn: { height: 48 },
  infoIcon: {
    width: 12,
  },
  btnOpenChat: {
    marginLeft: 0,
    marginRight: 'auto',
    padding: 0,
    height: 20,
    fontWeight: 700,
    fontSize: 14,
  },
  modal: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalContent: {
    position: 'relative',
    width: 480,
  },
  editIconWrap: {
    border: `8px solid ${colors.white}`,
    borderRadius: '50%',
    width: 72,
    height: 72,
    position: 'absolute',
    top: -36,
    left: 36,
    backgroundColor: colors.secondary100,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  editIcon: {
    width: 33,
    height: 33,
    '& path': {
      fill: colors.secondary500,
    },
  },
  content: {
    background: colors.white,
    boxShadow:
      '0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
    borderRadius: 8,
    '&:hover $cardLink': {
      display: 'flex',
    },
    padding: '56px 40px 40px',
  },
  closeBtn: {
    position: 'absolute',
    top: 25,
    right: 25,
    cursor: 'pointer',
  },
  modalTitle: {
    fontSize: 32,
    color: colors.neutral900,
    fontWeight: 700,
    margin: 0,
    marginBottom: 8,
    fontFamily: 'Source Serif Pro',
  },
  subtitle: {
    fontSize: 16,
    lineHeight: '24px',
    fontWeight: 500,
  },
  modalBtn: {
    marginTop: 40,
    width: '100%',
    padding: 0,
  },
  cancelBtn: {
    backgroundColor: colors.white,
    color: colors.neutral800,
  },
  okBtn: {
    backgroundColor: colors.destructive600,
    color: colors.white,
    border: 'none',
  },
  cancelledStatusText: {
    whiteSpace: 'nowrap',
  },
  userType: {
    color: colors.neutral600,
    display: 'block',
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  flexAlignCenter: {
    display: 'flex',
    alignItems: 'center',
  },
  flexBetween: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

export const sxStyles = {
  durationWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
  },
  recurringIcon: {
    display: 'flex',
    marginTop: '5px',
    alignItems: 'center',
  },
};
