import { useState } from 'react';
import cx from 'clsx';

// mui
import MuiSelect from '@mui/material/Select';
import MuiMenuItem from '@mui/material/MenuItem';
// icons
import { Box } from '@mui/material';
import { Icons } from '../../../icons';

// colors
import { colors } from '../../../colors';

// types
import { selectSize, selectType } from './Select.types';

// styles
import { useStyles } from './Select.styles';
import { Avatar, avatarType } from '../avatar';
import { Badge } from '../badge';

const Select = ({
  className = '',
  itemClassName = '',
  variant = selectType.PRIMARY,
  size = selectSize.S,
  disabled = false,
  options = [],
  displayEmpty = false,
  emptyText = '',
  truncate = false,
  truncateAt = null,
  adjustText = false,
  id = null,
  ...rest
}) => {
  const classes = useStyles({ variant });
  const [open, setOpen] = useState(false);
  const truncateEnd = truncateAt || 20;
  const cn = {
    root: cx({
      [classes.root]: true,
      [classes.primary]: variant === selectType.PRIMARY,
      [classes.secondary]: variant === selectType.SECONDARY || variant === selectType.AVATAR,
      [classes.rootSmall]: size === selectSize.S,
      [classes.rootMedium]: size === selectSize.M,
      [classes.rootLarge]: size === selectSize.L,
      [classes.selectDropwdown]: adjustText,
      [className || '']: className,
    }),
    item: cx({
      [classes.item]: true,
      [itemClassName || '']: itemClassName,
    }),
  };

  return (
    <MuiSelect
      className={cn.root}
      defaultValue={options[0]?.value}
      displayEmpty={displayEmpty}
      IconComponent={iconProps => (
        <Icons
          className={cx({
            [classes.arrowDropdownIcon]: true,
            [iconProps?.className]: true,
          })}
          glyph="chevron-down"
          color={variant === selectType.PRIMARY ? colors.primary : colors.neutral900}
        />
      )}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      disabled={disabled}
      {...rest}
    >
      {displayEmpty && emptyText && <MuiMenuItem value="">{emptyText}</MuiMenuItem>}
      {options.map(({ label, value, avatar, count }, idx) => (
        <MuiMenuItem key={idx} className={cn.item} value={value} id={`${id}-option-${label}`}>
          {variant === selectType.AVATAR && (
            <Box className={classes.avatarContainer}>
              <Avatar src={avatar} name={label} variant={avatarType.CIRCLE} size={32} />
            </Box>
          )}
          <div className={classes.labelCountWrapper}>
            {truncate
              ? `${label.substring(0, truncateEnd)}${label.length > truncateEnd ? '...' : ''}`
              : label}
            {count ? (
              <Badge className={classes.unreadMessageBadge}>{count > 99 ? '99+' : count}</Badge>
            ) : (
              <></>
            )}
          </div>
        </MuiMenuItem>
      ))}
    </MuiSelect>
  );
};

export { Select };
