/* eslint-disable react/no-did-update-set-state */
/* eslint-disable react/no-access-state-in-setstate */

/* eslint-disable react/destructuring-assignment */
import { Component } from 'react';
import uuid from 'uuid';

import OTSubscriberContext from './OTSubscriberContext';

export default class OTSubscriber extends Component {
  constructor(props, context) {
    super(props);

    this.state = {
      subscriber: null,
      stream: props.stream || context.stream || null,
      session: props.session || context.session || null,
      currentRetryAttempt: 0,
    };
    this.maxRetryAttempts = props.maxRetryAttempts || 5;
    this.retryAttemptTimeout = props.retryAttemptTimeout || 1000;
  }

  componentDidMount() {
    this.createSubscriber();
  }

  componentDidUpdate(prevProps, prevState) {
    const cast = (value, Type, defaultValue) => (value === undefined ? defaultValue : Type(value));

    const updateSubscriberProperty = key => {
      const previous = cast(prevProps.properties[key], Boolean, true);
      const current = cast(this.props.properties[key], Boolean, true);
      if (previous !== current) {
        this.state.subscriber[key](current);
      }
    };

    updateSubscriberProperty('subscribeToAudio');
    updateSubscriberProperty('subscribeToVideo');

    if (prevState.session !== this.context.session || prevState.stream !== this.context.stream) {
      this.destroySubscriber(prevState.session);
      this.createSubscriber();
      this.setState({
        stream: this.context.stream,
        session: this.context.session,
      });
    }
  }

  componentWillUnmount() {
    this.destroySubscriber();
  }

  handleRetrySubscriber() {
    setTimeout(() => {
      this.setState(state => ({
        currentRetryAttempt: state.currentRetryAttempt + 1,
        subscriber: null,
      }));
      this.createSubscriber();
    }, this.retryAttemptTimeout);
  }

  getSubscriber() {
    return this.state.subscriber;
  }

  maxRetryAttempts;

  retryAttemptTimeout;

  node;

  subscriberId;

  static contextType = OTSubscriberContext;

  createSubscriber() {
    if (!this.context.session || !this.context.stream) {
      this.setState({ subscriber: null });
      return;
    }
    const properties = this.props.properties || {};
    let container;
    if (properties.insertDefaultUI !== false) {
      container = document.createElement('div');
      container.setAttribute('class', 'OTSubscriberContainer');
      this.node.appendChild(container);
    }

    this.subscriberId = uuid();
    const { subscriberId } = this;

    const subscriber = this.context.session.subscribe(
      this.context.stream,
      container,
      this.props.properties,
      err => {
        if (subscriberId !== this.subscriberId) {
          // Either this subscriber has been recreated or the
          // component unmounted so don't invoke any callbacks
          return;
        }
        if (err && this.props.retry && this.state.currentRetryAttempt < this.maxRetryAttempts - 1) {
          // Error during subscribe function
          this.handleRetrySubscriber();
          // If there is a retry action, do we want to execute the onError props function?
          // return;
        }
        if (err && typeof this.props.onError === 'function') {
          this.props.onError(err);
        } else if (!err && typeof this.props.onSubscribe === 'function') {
          this.props.onSubscribe();
        }
      },
    );

    if (this.props.eventHandlers && typeof this.props.eventHandlers === 'object') {
      subscriber.on(this.props.eventHandlers);
    }

    this.setState({ subscriber });
  }

  destroySubscriber(session = this.props.session) {
    delete this.subscriberId;

    if (this.state.subscriber) {
      if (this.props.eventHandlers && typeof this.props.eventHandlers === 'object') {
        this.state.subscriber.once('destroyed', () => {
          this.state.subscriber.off(this.props.eventHandlers);
        });
      }

      if (session) {
        session.unsubscribe(this.state.subscriber);
      }
    }
  }

  render() {
    const { className, style } = this.props;
    return (
      <div
        className={className}
        style={style}
        ref={node => {
          this.node = node;
        }}
      />
    );
  }
}
