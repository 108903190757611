import Box from '@mui/material/Box';

import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  btnSize,
  btnType,
  Button,
  iconBtnPosition,
  iconBtnType,
  IconButton,
  RadioGroup,
  radioGroupType,
  Select,
  selectType,
} from '../../../../packages';
import { Drawer, drawerType } from '../../../../packages/ui/organisms/drawer';

import { stateActionCreators } from '../../../../redux/modules/state';
import { reportServiceActionCreators } from '../../../../redux/modules/report-service';
import { getReportService } from '../../../../redux/modules/report-service/selectors';
import { getCPTs } from '../../../../services/state/state.service';

import { useDomainType } from '../../../../hooks/useDomainType';
import { DomainTypesEnum } from '../../../../constants/CommonConstants';

import { AddProcedureCodes } from '../video-call-screen/components/add-procedure-codes';

import { radioOptions } from './SessionSignOffV2.constant';
import { useStyles } from './SessionSignOffV2.styles';

const ProcedureCodes = ({ appointment, onBack, onNext, preFill, template }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { appointmentCptCode } = useSelector(getReportService);

  const [isLoadingDiagnoses, diagnoses, domainElementsList] = useDomainType({
    type: DomainTypesEnum.DIAGNOSES,
    patientId: appointment?.participantId,
  });

  const [selectedOption, setSelectedOption] = useState(preFill?.selectedOption ?? 'no');
  const [openProcedureDrawer, setOpenProcedureDrawer] = useState(false);
  const [diagnosisList, setDiagnosisList] = useState([]);
  const [cpts, setCpts] = useState([]);
  const primaryCpt = appointment?.servicePrimaryCptCodes?.[0] || null;
  const [primaryCptDef, setPrimaryCptDef] = useState(null);
  const [selectedDiagnosis, setSelectedDiagnosis] = useState('');

  const getProcedureCodeOptionLists = async () => {
    const cptCodesList = await getCPTs();
    setCpts(cptCodesList?.data?.data);
    dispatch(stateActionCreators.fetchICD10());
  };

  useEffect(() => {
    if (cpts.length > 0 && primaryCpt) {
      const primary = cpts.find(cpt => cpt.code === primaryCpt);
      if (primary) {
        setPrimaryCptDef(primary);
      }
    }
  }, [cpts]);
  useEffect(() => {
    dispatch(reportServiceActionCreators.fetchAppointmentCptCodeById(appointment?.appointmentId));
    getProcedureCodeOptionLists()
      .then()
      .catch(err => {
        console.log('option list fetch failed', err);
      });
  }, []);

  useEffect(() => {
    let tempDiagnosisList = [];
    if (domainElementsList) {
      domainElementsList.forEach(item => {
        if (item.relatedElements?.length) {
          const filteredDiagnises = item.relatedElements?.filter(
            element => element.metaData?.metaDataValues?.icd10Codes,
          );
          tempDiagnosisList = [...filteredDiagnises, ...tempDiagnosisList];
        }
      });
    }
    setDiagnosisList(tempDiagnosisList);
  }, [diagnoses, domainElementsList]);

  const isDisabled = useMemo(() => {
    return (
      (!appointmentCptCode ||
        (appointmentCptCode && Object.keys(appointmentCptCode).length === 0) ||
        appointmentCptCode?.cptList?.length === 0) &&
      selectedOption === 'no'
    );
  }, [appointmentCptCode, selectedOption]);

  useEffect(() => {
    const handleKeyPress = event => {
      if (event.key === 'Enter' && !isDisabled) {
        onNextClick();
      }
    };
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [isDisabled]);

  useEffect(() => {
    if (template === 'yes' && !preFill?.selectedOption) {
      setSelectedOption('yes');
    }
  }, [template]);

  const onNextClick = () => {
    onNext({
      codes: {
        icon: 'procedure',
        title: 'Procedure codes',
        description: 'added',
        number: appointmentCptCode?.cptList?.length ?? 0,
        selectedOption,
      },
    });
  };

  const onSelect = e => {
    setSelectedOption(e.target.value);
  };

  const onAdd = () => {
    setOpenProcedureDrawer(true);
  };

  const diagnosisOptions = useMemo(
    () =>
      diagnoses?.relatedElements?.map(element => ({
        label: element?.name,
        value: { name: element?.name, icd10: element.icd10Codes?.[0] },
      })),
    [diagnoses?.relatedElements],
  );

  const onSavePrimary = () => {
    const payload = {
      cptList: [
        {
          cptCode: primaryCptDef.code,
          diagnosis: selectedDiagnosis.name,
          icd10Code: selectedDiagnosis.icd10,
          notes: '',
        },
      ],
    };
    dispatch(
      reportServiceActionCreators.addAppointmentCptCode({
        bodyRequest: { appointmentId: appointment.appointmentId, ...payload },
        callback: () => {
          dispatch(reportServiceActionCreators.fetchAppointmentCptCodeById(appointment.appointmentId));
        },
      }),
    );
  };

  const renderLabel = label => (
    <Box
      sx={{
        color: '#0D0F11',
        fontFamily: 'Manrope',
        fontSize: '14px',
        fontWeight: 700,
        lineHeight: '20px',
      }}
    >
      {label}
    </Box>
  );
  const renderPrimaryCode = cpt => {
    const selectedCpt = cpts.find(c => c.code === cpt.cptCode);
    return (
      <Box sx={styles.assignedCodeContainer}>
        <Box sx={styles.titleContainer}>
          <Box sx={styles.headerContent}>
            <Box sx={styles.cptCodeText}>{selectedCpt?.displayName}</Box>
            <Box sx={styles.diagnosisText}>{cpt?.diagnosis}</Box>
          </Box>
          <Button variant={btnType.OUTLINE} size={btnSize.XXSMALL} sx={styles.editBtn} onClick={onAdd}>
            Edit
          </Button>
        </Box>
      </Box>
    );
  };
  return (
    <>
      <Box className={classes.wrapper}>
        <Box sx={styles.contentWrapper}>
          <Box sx={styles.mainHeading}>Eligible procedure codes</Box>
          <Box sx={styles.section}>
            <Box sx={styles.subHeading}>Primary codes</Box>
            <Box sx={styles.flexColumn}>
              {appointmentCptCode?.cptList?.length > 0 ? (
                renderPrimaryCode(appointmentCptCode?.cptList[0])
              ) : primaryCptDef ? (
                <Box sx={styles.initialPrimaryBox}>
                  <Box sx={styles.cptSection}>
                    <Box sx={styles.cptCodeText}>{primaryCptDef?.displayName}</Box>
                    <Button
                      variant={btnType.PRIMARY}
                      disabled={selectedDiagnosis === ''}
                      size={btnSize.XXSMALL}
                      sx={styles.saveBtn}
                      onClick={onSavePrimary}
                    >
                      Save
                    </Button>
                  </Box>
                  <Box sx={styles.diagnosisSection}>
                    {renderLabel('Diagnosis')}
                    <Select
                      sx={styles.diagnosisSelect}
                      value={selectedDiagnosis}
                      variant={selectType.SECONDARY}
                      options={diagnosisOptions}
                      emptyText="Select diagnosis"
                      displayEmpty
                      onChange={e => {
                        const diagnoseId = e?.target?.value;
                        setSelectedDiagnosis(diagnoseId);
                      }}
                    />
                  </Box>
                </Box>
              ) : (
                <Box className={classes.empty}>
                  <Box className="title">Procedure Code Needed</Box>
                  <Box className="content">
                    Procedure codes are required for billing purposes. Select a procedure code(s) that reflect
                    the type and duration of this session.
                  </Box>
                  <IconButton icon="plus" variant={iconBtnType.OUTLINE} onClick={onAdd}>
                    New procedure code
                  </IconButton>
                </Box>
              )}
            </Box>
          </Box>
          {!appointmentCptCode ||
          (appointmentCptCode && Object.keys(appointmentCptCode).length === 0) ||
          appointmentCptCode?.cptList?.length === 0 ? (
            <Box>
              <Box className={classes.question}>Do you want to continue without adding procedure codes?</Box>
              <RadioGroup
                name="radio-buttons-group"
                options={radioOptions}
                value={selectedOption}
                onChange={onSelect}
                variant={radioGroupType.HORIZONTAL}
              />
            </Box>
          ) : (
            <Box sx={styles.section}>
              <Box sx={styles.subHeading}>Additional Codes</Box>
              <Box sx={styles.flexColumn}>
                {appointmentCptCode?.cptList?.length > 1 ? (
                  appointmentCptCode?.cptList?.slice(1).map((cpt, index) => {
                    const selectedCpt = cpts.find(c => c.code === cpt.cptCode);
                    return (
                      <Box sx={styles.assignedCodeContainer} key={index}>
                        <Box sx={styles.titleContainer}>
                          <Box sx={styles.headerContent}>
                            <Box sx={styles.cptCodeText}>{selectedCpt?.displayName}</Box>
                            <Box sx={styles.diagnosisText}>{cpt?.diagnosis}</Box>
                          </Box>
                          <Button
                            variant={btnType.OUTLINE}
                            size={btnSize.XXSMALL}
                            sx={styles.editBtn}
                            onClick={onAdd}
                          >
                            Edit
                          </Button>
                        </Box>
                      </Box>
                    );
                  })
                ) : (
                  <Box sx={styles.noAdditionalText}>No additional codes yet</Box>
                )}
              </Box>
              <IconButton icon="plus" onClick={onAdd} className={classes.addProcedureCodeBtn}>
                Add procedure code
              </IconButton>
            </Box>
          )}
          <Drawer
            open={openProcedureDrawer}
            onClose={() => setOpenProcedureDrawer(false)}
            variant={drawerType.NORMAL}
          >
            <AddProcedureCodes
              appointment={appointment}
              appointmentCptCode={appointmentCptCode}
              domainElements={diagnoses?.relatedElements}
              diagnosisList={diagnosisList}
              cpts={cpts}
              onClose={() => setOpenProcedureDrawer(false)}
              isLoadingCPT={isLoadingDiagnoses}
            />
          </Drawer>
        </Box>
      </Box>
      <Box className={classes.footer}>
        <IconButton
          icon="chevron-left"
          iconPosition={iconBtnPosition.LEFT}
          className={classes.previousBtn}
          onClick={onBack}
          variant={iconBtnType.TEXT}
        >
          Previous
        </IconButton>
        <IconButton
          icon="chevron-right"
          iconPosition={iconBtnPosition.RIGHT}
          className={classes.nextBtn}
          onClick={onNextClick}
          disabled={isDisabled}
        >
          Next
        </IconButton>
      </Box>
    </>
  );
};
const styles = {
  contentWrapper: {
    width: 800,
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'stretch',
    gap: 5,
  },
  mainHeading: {
    color: '#0D0F11',
    fontFamily: 'Manrope',
    fontSize: '28px',
    fontWeight: 700,
    lineHeight: '36px',
    letterSpacing: '-0.56px',
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
  },
  subHeading: {
    color: '#0D0F11',
    fontFamily: 'Manrope',
    fontSize: '22px',
    fontWeight: 600,
    lineHeight: '28px',
    letterSpacing: '-0.22px',
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
  initialPrimaryBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    alignSelf: 'stretch',
    borderRadius: '8px',
    border: '1px solid #E5EBF0',
    boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.04)',
  },
  cptSection: {
    display: 'flex',
    padding: '24px',
    justifyContent: 'space-between',
    alignItems: 'center',
    alignSelf: 'stretch',
    borderBottom: '1px solid #E5EBF0',
  },
  cptCodeText: {
    color: '#0D0F11',
    fontFamily: 'Manrope',
    fontSize: '18px',
    fontWeight: 600,
    lineHeight: '24px',
  },
  saveBtn: {
    padding: '8px 16px !important',
    height: '40px !important',
    fontSize: '14px !important',
  },
  editBtn: {
    padding: '8px 16px !important',
    height: '40px !important',
    fontSize: '14px !important',
    fontWeight: '700 !important',
    color: '#0365C1 !important',
    lineHeight: '20px !important',
    border: '1px solid #8EC8FD !important',
  },
  diagnosisSection: {
    display: 'flex',
    padding: '24px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    alignSelf: 'stretch',
    gap: 2,
  },
  diagnosisSelect: {
    alignSelf: 'stretch',
    '& .MuiInputBase-input': {
      marginLeft: '0px !important',
    },
  },
  assignedCodeContainer: {
    display: 'flex',
    padding: '24px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '8px',
    alignSelf: 'stretch',
    borderRadius: '8px',
    border: '1px solid #E5EBF0',
    boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.04)',
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    alignSelf: 'stretch',
  },
  headerContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItem: 'flex-start',
  },
  diagnosisText: {
    fontFamily: 'Manrope',
    color: '#4C5965',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '20px',
  },
  noAdditionalText: {
    color: '#333C43',
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '24px',
  },
};

export default ProcedureCodes;
