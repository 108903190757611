export const tableColumns = [
  { id: 'member', label: 'Member', sortable: false },
  { id: 'assignMethod', label: 'Assignment Method', sortable: false },
  { id: 'previousValue', label: 'Previous Value', sortable: false },
  { id: 'currentValue', label: 'Current Value', sortable: false },
  { id: 'date', label: 'Date', sortable: false },
];

export const getProfileElementAssignmentEvaluationListQuery = params => {
  return {
    size: params.size,
    from: params.from,
    _source: ['evaluation_id', 'member_id', 'fullName', 'firstName', 'lastName', 'type'],
    query: {
      bool: {
        must: [
          {
            nested: {
              path: 'dataElementDetails',
              query: {
                bool: {
                  must: [
                    {
                      term: {
                        'dataElementDetails.dataElementKey.keyword': {
                          value: params.profileKey,
                        },
                      },
                    },
                  ],
                },
              },
              inner_hits: {},
            },
          },
        ],
        should: [
          {
            regexp: {
              'fullName.keyword': {
                value: `.*${params.searchKey}.*`,
                case_insensitive: true,
              },
            },
          },
          {
            regexp: {
              'firstName.keyword': {
                value: `.*${params.searchKey}.*`,
                case_insensitive: true,
              },
            },
          },
          {
            regexp: {
              'lastName.keyword': {
                value: `.*${params.searchKey}.*`,
                case_insensitive: true,
              },
            },
          },
        ],
        minimum_should_match: 1,
      },
    },
    aggs: {
      total_assingment: {
        cardinality: {
          field: 'member_id.keyword',
        },
      },
    },
  };
};

export const getProfileElementAssignmentChatBotListQuery = params => {
  return {
    size: params.size,
    from: params.from,
    _source: ['evaluation_id', 'member_id', 'fullName', 'firstName', 'lastName', 'type'],
    query: {
      bool: {
        must: [
          {
            nested: {
              path: 'dataElementDetails',
              query: {
                bool: {
                  must: [
                    {
                      term: {
                        'dataElementDetails.dataElementKey.keyword': {
                          value: params.profileKey,
                        },
                      },
                    },
                  ],
                },
              },
              inner_hits: {},
            },
          },
        ],
        should: [
          {
            regexp: {
              'fullName.keyword': {
                value: `.*${params.searchKey}.*`,
                case_insensitive: true,
              },
            },
          },
          {
            regexp: {
              'firstName.keyword': {
                value: `.*${params.searchKey}.*`,
                case_insensitive: true,
              },
            },
          },
          {
            regexp: {
              'lastName.keyword': {
                value: `.*${params.searchKey}.*`,
                case_insensitive: true,
              },
            },
          },
        ],
        minimum_should_match: 1,
      },
    },
    aggs: {
      total_assingment: {
        cardinality: {
          field: 'member_id.keyword',
        },
      },
    },
  };
};

export const mapChatBotResponseToTableObject = data => {
  const hits = { ...data.hits };
  if (hits.hits.length > 0) {
    const sortedArray = hits.hits.map(hit => {
      /* eslint no-underscore-dangle: ["error", { "allow": ["_source"] }] */
      const { member_id: memberId, fullName, type, firstName, lastName } = hit._source;
      const dataElements = hit.inner_hits.dataElementDetails.hits.hits[0]._source.dataElement.values;

      // Sort the values array based on timestamp
      const sortedValues = dataElements.sort((a, b) => {
        const timestampA = new Date(a.timestamp).getTime();
        const timestampB = new Date(b.timestamp).getTime();
        return timestampA - timestampB;
      });
      const previous = sortedValues[0]?.value;
      const current = sortedValues[1]?.value;
      const date = sortedValues[0]?.timestamp;

      return {
        member: {
          memberId,
          fullName,
          firstName: firstName || '',
          lastName: lastName || '',
        },
        assignMethod: {
          type,
        },
        previousValue: {
          previous,
        },
        currentValue: {
          current,
        },
        date,
      };
    });
    return sortedArray;
  }
  return [];
};
