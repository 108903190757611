import { ApiEndpoints } from '../../constants/ApiEndpoints';
import { baseRequest } from '../../utils/HttpClient';

export const createNewAppointment = payload => {
  return baseRequest(ApiEndpoints.CREATE_APPOINTMENT, payload);
};

export const createNewRecurringAppointment = payload => {
  return baseRequest(ApiEndpoints.CREATE_RECURRING_APPOINTMENT, payload);
};

export const validateRecurringAppointment = payload => {
  return baseRequest(ApiEndpoints.VALIDATE_RECURRING_APPOINTMENT, payload);
};

export const getNextAppointment = (participantId = null, includePending = false, startFrom = null) => {
  let query = null;
  if (participantId) {
    query = {
      participantId,
      includePending,
    };
    if (startFrom) {
      query.startFrom = startFrom;
    }
  } else if (startFrom) {
    query = {
      startFrom,
      includePending,
    };
  }
  return baseRequest(ApiEndpoints.FETCH_NEXT_APPOINTMENT, null, null, query);
};

export const getPreviousCompletedAppointment = (memberId, olderThan) => {
  return baseRequest(ApiEndpoints.FETCH_PREVIOUS_COMPLETED_APPOINTMENT, null, null, { memberId, olderThan });
};
export const allAppointmentsCounts = payload => {
  return baseRequest(ApiEndpoints.ALL_APPOINNTMENT_COUNTS, payload || {});
};
export const getAppointments = queryParams => {
  return baseRequest(ApiEndpoints.GET_APPOINTMENTS, null, null, queryParams);
};
export const getAppointmentCounts = queryParams => {
  return baseRequest(ApiEndpoints.GET_APPOINTMENT_COUNTS, null, null, queryParams);
};
export const exportAppointments = queryParams => {
  return baseRequest(ApiEndpoints.EXPORT_APPOINTMENTS, null, null, queryParams);
};

export const getPastAppointments = (pathParams, queryParams) => {
  return baseRequest(ApiEndpoints.GET_APPOINTMENTS_PAST, null, pathParams, queryParams);
};

export const getPastAppointmentDetails = pathParams => {
  return baseRequest(ApiEndpoints.GET_PAST_APPOINTMENT_DETAILS, null, pathParams);
};

export const getProviderServices = queryParams => {
  return baseRequest(ApiEndpoints.GET_PROVIDER_SERVICES_BY_ID, null, null, queryParams);
};

export const searchSchedule = queryParams => {
  return baseRequest(ApiEndpoints.SEARCH_SCHEDULE, null, null, queryParams);
};

export const patchAppointment = (bodyRequest, pathParams) => {
  return baseRequest(ApiEndpoints.PATCH_APPOINTMENT, bodyRequest, pathParams);
};

export const cancelRecurringAppointment = (bodyRequest, pathParams) => {
  return baseRequest(ApiEndpoints.CANCEL_RECURRING_APPOINTMENT, bodyRequest, pathParams);
};

export const getAppointmentById = pathParams => {
  return baseRequest(ApiEndpoints.GET_APPOINTMENT_BY_ID, null, pathParams);
};

export const listProviders = () => {
  return baseRequest(ApiEndpoints.LIST_APPOINTMENT_ELIGIBLE_PROVIDERS, null);
};

export const startOrJoinCall = (bodyRequest, queryParams) => {
  return baseRequest(ApiEndpoints.START_OR_JOIN_CALL, null, bodyRequest, queryParams);
};

export const leaveCall = bodyRequest => {
  return baseRequest(ApiEndpoints.LEAVE_CALL, null, bodyRequest);
};

export const getAppointmentCptCodes = appointmentId => {
  return baseRequest(ApiEndpoints.GET_APPOINTMENT_CPT_CODE, null, { appointmentId });
};

export const appointmentsDownload = (memberId, startDate, endDate) => {
  return baseRequest(ApiEndpoints.APPPINTMENT_DOWNLOAD, null, { memberId }, { startDate, endDate });
};

export const addNoteAppointment = (bodyRequest, pathParams) => {
  return baseRequest(ApiEndpoints.ADD_NOTE_APPOINTMENT, bodyRequest, pathParams);
};

export const fetchMasterScheduleProviders = pathParams => {
  return baseRequest(ApiEndpoints.MARK_REJECT_PROVIDER_FEE, null, pathParams);
};

export const getAllProviderServices = queryParams => {
  return baseRequest(ApiEndpoints.GET_ALL_UNIQUE_SERVICES, undefined, undefined, queryParams);
};

export const getAvailableSlots = bodyRequest => {
  return baseRequest(ApiEndpoints.SEARCH_SCHEDULE, undefined, undefined, bodyRequest);
};
