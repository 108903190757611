// colors
import { colors } from '../../../../../../packages/colors';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  card: {
    borderRadius: 8,
    backgroundColor: colors.white,
    width: '100%',
    '&:hover $topRight': {
      display: 'flex',
    },
  },
  rowCenter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  flexStart: {
    alignItems: 'flex-start',
  },
  topLeft: {},
  topLeftHead: {
    display: 'flex',
    gap: 12,
    alignItems: 'center',
  },
  borderTop: {
    borderBottom: `1px solid ${colors.neutral100}`,
    '&:last-child': {
      borderBottom: 'none',
    },
    padding: 24,
  },
  topRight: {
    display: 'none',
    gap: 20,
  },
  badge: {
    borderRadius: 20,
  },
  badgeMisreported: {
    backgroundColor: colors.secondary50,
    color: colors.secondary500,
  },
  title: {
    fontSize: 24,
    lineHeight: '32px',
    fontWeight: 700,
    cursor: 'pointer',
  },
  title2: {
    fontSize: 16,
    lineHeight: '24px',
    fontWeight: 500,
    color: colors.neutral600,
  },
  btn: {
    fontSize: 14,
    '& svg': {
      marginRight: 8,
      width: 16,
    },
  },
  username: {
    fontSize: 14,
    lineHeight: '20px',
    fontWeight: 600,
    margin: 0,
  },
  time: {
    fontSize: 14,
    lineHeight: '20px',
    fontWeight: 500,
    margin: 0,
    color: colors.neutral600,
  },
  interference: {
    '& svg path': {
      fill: colors.neutral500,
    },
  },
  interferenceResolved: {
    '& svg path': {
      fill: `${colors.secondary500} !important`,
    },
    '& $time': {
      color: `${colors.secondary500} !important`,
    },
  },
  bottomBox: {
    marginTop: 16,
    marginBottom: 12,
  },
});
