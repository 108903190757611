/* eslint-disable no-nested-ternary */
import { useEffect, useState } from 'react';
import cx from 'clsx';
import { Box, Collapse } from '@mui/material';
import { useFormik } from 'formik';

import {
  Badge,
  badgeStyle,
  badgeType,
  Button,
  iconBtnStyle,
  iconBtnType,
  IconButton,
  Text,
} from '../../../../../../../packages';
import { useDomainType } from '../../../../../../../hooks/useDomainType';
import { DomainTypesEnum } from '../../../../../../../constants/CommonConstants';

// styles
import { useStyles } from './ReportFormCollapse.styles';
import { MedicationEditSchema } from '../../medication-editing/MedicationEditing.schema';
import MedicationForm from '../../medication-form';
import { reportItemProgress } from '../../../../MemberDetail.constants';

const defaultValues = {
  status: '',
  notes: '',
  dosage: '',
  frequency: '',
  symptoms: [],
  provider: 'Confidant Provider',
  confidantProvider: '',
  providerName: '',
  additionalNotes: '',
  // dose: {
  //   amount: 1,
  //   unit: 'MG',
  // },
  // frequency: {
  //   amount: 1,
  //   unit: 'DAYS',
  // },
  // duration: {
  //   amount: 1,
  //   unit: 'DAYS',
  // },
  // refillable: true,
  // refillTimes: {
  //   amount: 1,
  //   unit: 'TIMES',
  // },
  // isInterference: false,
};

const ReportFormCollapse = ({
  open = false,
  medication,
  onChange,
  lookupData,
  onRemoveClick,
  onOpenCollapse,
  memberId,
  options,
}) => {
  const [, symptoms] = useDomainType({
    type: DomainTypesEnum.SYMPTOMS,
    patientId: memberId,
  });

  const symtomsList = symptoms?.relatedElements?.map(item => item.name) || [];
  const [collapsed, setCollapsed] = useState(open || false);
  const classes = useStyles();

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
    onOpenCollapse && onOpenCollapse(!collapsed);
  };

  const form = useFormik({
    initialValues: { ...defaultValues },
    validationSchema: MedicationEditSchema,
    onSubmit: payload => {
      const { /* dose, frequency, duration, refillTimes, refillable, */ ...remaining } = payload;

      onChange({
        ...remaining,
        tutorial: {
          dosage: remaining.dosage,
          frequency: remaining.frequency,
          confidantProviderId: remaining.confidantProvider,
          confidantProviderName: remaining.providerName,
          symptomsBeingTreated: remaining.symptoms,
          prescribingProvider: remaining.provider,
          providerName: remaining.providerName,
          // dose,
          // frequency,
          // duration,
          // refillTimes,
          // refillable,
        },

        // metaData: {
        //   notes: payload?.additionalNotes,
        //   selectedImportance: payload?.status,
        //   rxDrugInfo: {
        //     confidantProviderId: payload?.confidantProvider,
        //     confidantProviderName: payload?.confidantProviderName,
        //     dosage: payload?.dosage,
        //     dose: 0,
        //     doseFrequency: 0,
        //     doseUnit: '',
        //     frequency: payload?.frequency,
        //     prescribingProvider: payload?.provider === 'Confidant Provider' ? 'Internal' : 'External',
        //     providerName: payload?.providerName,
        //     refillable: true,
        //     supply: 0,
        //     supplyUnit: '',
        //     symptomsBeingTreated: payload?.symptoms,
        //   },
      });
    },
  });

  useEffect(() => {
    if (medication) {
      const { /* tutorial, */ ...remaining } = medication;
      const newObj = {
        ...remaining,
        // ...tutorial,
      };
      Object.keys(newObj).forEach(async key => {
        await form.setFieldValue(key, newObj[key]);
      });
      if (medication.progress) {
        setCollapsed(medication.progress === reportItemProgress.InProgress);
      }
    }
  }, [medication]);

  useEffect(() => {
    setCollapsed(open || false);
  }, [open]);

  return (
    <Box className={classes.root}>
      <Box className={cx(classes.header, { [classes.headerBorder]: collapsed })} onClick={toggleCollapsed}>
        <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
          <Text className={classes.headTitle}>{medication.item.label}</Text>
          <Badge
            variant={badgeType.OUTLINED}
            className={classes.badge}
            style={
              medication.progress === reportItemProgress.Completed
                ? badgeStyle.SUCCESS
                : medication.progress === reportItemProgress.Incomplete
                ? badgeStyle.MISREPORTED
                : badgeStyle.CRITICAL
            }
          >
            {medication.progress}
          </Badge>
        </Box>
      </Box>
      <Collapse in={collapsed}>
        <form className={classes.form} onSubmit={form.handleSubmit}>
          <MedicationForm
            form={form}
            medication={medication}
            lookupData={lookupData}
            symptomsList={symtomsList}
            options={options}
          />
          <Box className={classes.footer}>
            <IconButton
              icon="delete-outlined-2"
              className={classes.removeBtn}
              variant={iconBtnType.TEXT}
              style={iconBtnStyle.SECONDARY}
              onClick={onRemoveClick}
            >
              Remove this medication
            </IconButton>
            <Button onClick={form.handleSubmit}>Done</Button>
          </Box>
        </form>
      </Collapse>
    </Box>
  );
};

export { ReportFormCollapse };
