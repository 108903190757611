import { makeStyles } from '@mui/styles';

// colors
import { colors } from '../../../../packages';

export const useStyles = makeStyles({
  table: {
    backgroundColor: colors.white,
  },
  link: {
    textDecoration: 'none',
    cursor: 'pointer',
  },
  name: {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '20px',
  },
  menu: {
    float: 'right',
    right: 20,
    '& svg': {
      width: 16,
    },
  },
  menuItemsWrapper: {
    '& ul': {
      padding: 0,
    },
    '& ul li': {
      padding: '10px 16px',
      '&:last-child': {
        color: colors.destructive500,
        '& svg path': {
          fill: colors.destructive500,
        },
      },
    },
    '& .menu-item-custom': {
      borderBottom: `1px solid ${colors.neutral100}`,
    },
  },
  icon: {
    marginRight: 12,
    width: 16,
  },
  toggleWrap: {},
});
