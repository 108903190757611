import { makeStyles } from '@mui/styles';

// colors
import { colors } from '../../../../../../colors';

export const useStyles = makeStyles({
  container: {
    backgroundColor: colors.destructive50,
    flexGrow: 1,
    height: '100vh',
  },
  contentBox: {
    paddingTop: '226px',
    paddingLeft: '40px',
    paddingRight: '40px',
    paddingBottom: '32px',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    alignItems: 'center',
  },
  disconnectedIcon: {
    width: '80px',
    height: '80px',
  },
  disconnectedText: {
    color: colors.neutral900,
    textAlign: 'center',
    fontFamily: 'Manrope',
    fontSize: '32px',
    fontStyle: 'normal',
    fontWeight: 300,
    lineHeight: '130%',
    margin: 0,
  },
  actionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    alignSelf: 'stretch',
    borderRadius: '8px',
    border: `1px solid ${colors.neutral100}`,
    background: colors.white,
    boxShadow: '0px 10px 20px 0px rgba(0, 0, 0, 0.04), 0px 2px 6px 0px rgba(0, 0, 0, 0.04), 0px 0px 1px 0px rgba(0, 0, 0, 0.04)'
  },
  actionCard: {
    display: 'flex',
    padding: '16px 24px',
    alignItems: 'center',
    gap: '24px',
    alignSelf: 'stretch',
    borderBottom: `1px solid ${colors.neutral100}`
  },
  cardTitle: {
    alignSelf: 'stretch',
    color: colors.neutral900,
    fontFamily: 'Manrope',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '24px',
    margin: 0,
  },
  cardDescription: {
    alignSelf: 'stretch',
    color: colors.neutral600,
    fontFamily: 'Manrope',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '20px',
    margin: 0,
  },
  actionDetailsBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    gap: '4px',
    flex: '1 0 0'
  },
  cardFooter: {
    display: 'flex',
    padding: '16px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '8px',
    alignSelf: 'stretch',
    borderBottom: `1px solid ${colors.neutral100}`
  },
  actionBtn: {
    padding: 0,
    borderRadius: '4px',
    minWidth: '40px !important',
    width: '40px !important',
    height: '40px !important',
    '& svg': {
      width: '16px',
      height: '16px',
    }
  },
  callBtn: {
    border: `1px solid ${colors.indigo500}`,
    color: colors.indigo500,
    '& svg': {
      '& path': {
        fill: colors.indigo500,
      },
    },
    '&:hover': {
      backgroundColor: colors.indigo100,
    }
  },
  chatBtn: {
    border: `1px solid ${colors.orange500}`,
    color: colors.warning500,
    '& svg': {
      '& path': {
        fill: colors.warning500,
      },
    },

    '&:hover': {
      backgroundColor: colors.warning100,
    }
  },
  desc: {
    alignSelf: 'stretch',
    color: colors.neutral900,
    textAlign: 'center',
    fontFamily: 'Manrope',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '20px',
    margin: 0,
  },
  danger: {
    color: colors.destructive600,
  }
});
