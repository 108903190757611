export const CONTACT_NOTES_TYPES = {
  GENERAL: [
    'Low or Moderate Risk for Suicidal Ideation as per protocol',
    'Low or Moderate Risk for Homicidal Ideation as per protocol',
    'History of Drug-Seeking Behavior',
    'History of Eating Disorder',
    'History of Violence',
    'Significant history of no-shows for appointments',
    'Active self-harm behaviors (with no suicidal ideation)',
    'Pregnancy (for services other than OUD)',
    'Child Protection Involvement (current or historical)',
    'Criminal Justice System Involvement (current or historical)',
    'Secondary Chronic Pain',
    'Significant Psychiatric History',
    'Safety concerns in the home (Domestic Violence)',
    'Undergoing active Suboxone Induction',
    'History of Non-Disclosure of Medications Prescribed',
    'Comorbid medical, mental health, and/or SUD/AUD diagnoses',
    'Allergy',
    'Other',
  ],
  CONTACT: ['Phone Call', 'Email'],
  CAUTION: [
    'Low or Moderate Risk for Suicidal Ideation as per protocol',
    'Low or Moderate Risk for Homicidal Ideation as per protocol',
    'History of Drug-Seeking Behavior',
    'History of Eating Disorder',
    'History of Violence',
    'Significant history of no-shows for appointments',
    'Active self-harm behaviors (with no suicidal ideation)',
    'Pregnancy (for services other than OUD)',
    'Child Protection Involvement (current or historical)',
    'Criminal Justice System Involvement (current or historical)',
    'Secondary Chronic Pain',
    'Significant Psychiatric History',
    'Safety concerns in the home (Domestic Violence)',
    'Undergoing active Suboxone Induction',
    'History of Non-Disclosure of Medications Prescribed',
    'Comorbid medical, mental health, and/or SUD/AUD diagnoses',
    'Allergy',
    'Other',
  ],
  PROHIBITIVE: [
    'High Risk for Suicidal Ideation as per protocol',
    'High Risk for Homicidal Ideation as per protocol',
    'Current drug seeking behavior',
    'Current diagnosis of acute Eating Disorder',
    'Active self-harm behaviors with suicidal ideation',
    'Threatened Provider during session',
    'Current diversion concerns',
    'Active Withdrawal with physical symptoms as listed in exclusionary criteria',
    'Pregnancy (for OUD)',
    'Taking Medications not as Prescribed',
    'Chronic Pain Primary (for OUD)',
    'Active Non-Disclosure of Medications Prescribed',
    'Requires Higher Level of Care',
    'Other',
  ],
  LAB_REQUEST: [
    'CBC with Differential',
    'Complete Metabolic Panel',
    'Hepatic Panel',
    'General Hepatitis Panel',
    'HIV Panel',
    'Lithium Levels',
    'Depakote Levels',
    'Tegretol Levels',
    'Other',
  ],
  RELEASE_OF_INFORMATION: ['Name', 'Phone', 'Email', 'Fax', 'Address'],
};
