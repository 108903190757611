import { Box, Stack } from '@mui/material';
import { colors, fontWeight, IconButton, Icons, Text } from '../../../../../packages';
import { useStyles } from './AutomationEventItem.styles';

const AutomationEventItem = ({ event, onEdit, onDelete }) => {
  const classes = useStyles();

  const onEditClick = () => {
    onEdit(event);
  };

  const onDeleteClick = () => {
    onDelete(event);
  };

  const renderInfo = (label, value) => (
    <Box>
      <Text className={classes.infoLabel} weight={fontWeight.EXTRA_BOLD}>
        {label}
      </Text>
      <Text className={classes.infoValue} weight={fontWeight.SEMI_BOLD}>
        {value}
      </Text>
    </Box>
  );

  return (
    <Box className={classes.event}>
      <Stack direction="row" gap={4} alignItems="center">
        {renderInfo(event.startLabel, event.startValue)}
        <Icons glyph="arrow-right" color={colors.neutral500} />
        {renderInfo(event.endLabel, event.endValue)}
      </Stack>
      <Stack direction="row" gap={1.5} alignItems="center">
        <IconButton onClick={onEditClick} className={classes.editBtn}>
          Edit
        </IconButton>
        {event.isDeleteable && (
          <IconButton onClick={onDeleteClick} className={classes.deleteBtn}>
            Delete
          </IconButton>
        )}
      </Stack>
    </Box>
  );
};

export { AutomationEventItem };
