import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { sentenceCase } from 'sentence-case';
import {
  Badge,
  badgeStyle,
  badgeType,
  btnType,
  Button,
  CheckboxGroup,
  fontWeight,
  IconButton,
  Input,
  inputType,
  Text,
} from '../../../../../packages';
import { Drawer, drawerType } from '../../../../../packages/ui/organisms/drawer';
import { AppointmentStatus, AppointmentStatusBagde } from '../../../../../packages/constants/CommonConstants';
import { getTimeZoneText } from '../../../../../constants/CommonConstants';
import { useStyles } from './AppointmentDetail.styles';

const AddEvaluation = ({
  isOpen,
  onClose,
  onAddEvaluation,
  appointment,
  evaluations,
  assignedEvaluations,
  onRemoveEvaluation,
}) => {
  const classes = useStyles();
  const [searchText, setSearchText] = useState('');
  const [options, setOptions] = useState(
    evaluations?.map(ev => ({ label: ev.name, value: ev.evaluationId })),
  );
  const [checkedValues, setCheckedValues] = useState(
    evaluations
      ?.filter(ev => assignedEvaluations?.some(ae => ev.evaluationId === ae.id))
      ?.map(ev => ev.evaluationId),
  );

  useEffect(() => {
    if (searchText.trim() === '') {
      setOptions(evaluations?.map(ev => ({ label: ev.name, value: ev.evaluationId })));
    } else {
      setOptions(
        evaluations
          ?.filter(ev => ev?.name?.toLowerCase()?.includes(searchText?.toLowerCase()))
          ?.map(ev => ({ label: ev.name, value: ev.evaluationId })),
      );
    }
  }, [searchText]);

  const onChangeCheck = val => {
    // Determine which evaluations were unchecked
    const uncheckedEvaluations = checkedValues.filter(id => !val.includes(id));
    // Remove the unchecked evaluations
    uncheckedEvaluations.forEach(evaluationId => {
      onRemoveEvaluation(evaluationId);
    });
    setCheckedValues([...val]);
  };

  return (
    <Drawer open={isOpen} onClose={onClose} variant={drawerType.NORMAL} className={classes.drawer}>
      <Box className={classes.wrapper}>
        <Box className={classes.header}>
          <IconButton icon="chevron-left" onClick={onClose} className={classes.backBtn} />
          <Text className={classes.headerTitle}>Add evaluation</Text>
        </Box>
      </Box>
      <Box className={classes.evaluation}>
        <Box display="flex" justifyContent="space-between">
          <Box>
            <Text weight={fontWeight.BOLD} className={classes.title}>
              {appointment.serviceName}
            </Text>
            <Text weight={fontWeight.MEDIUM} className={clsx(classes.text14, classes.duration)}>
              {appointment.serviceDuration} minutes session
            </Text>
          </Box>
          <Badge
            variant={badgeType.FILLED}
            style={AppointmentStatusBagde[appointment.status] || badgeStyle.UNRELATED}
            className={classes.badge}
          >
            {appointment.status === AppointmentStatus.BOOKED
              ? 'Scheduled'
              : sentenceCase(appointment.status || '')}
          </Badge>
        </Box>
        <Box sx={{ marginTop: 2 }}>
          <Text weight={fontWeight.SEMI_BOLD} className={clsx(classes.text14, classes.info)}>
            {dayjs(appointment.startTime).format('MMMM DD, YYYY - hh:mma')}
            &nbsp;-&nbsp;
            {dayjs(appointment.endTime).format('hh:mma')}&nbsp;-&nbsp;
            {getTimeZoneText(appointment.providerTimezone)}
          </Text>
        </Box>
      </Box>
      <Box className={classes.searchWrap}>
        <Input
          variant={inputType.SEARCH}
          placeholder="Search"
          className={classes.searchInput}
          value={searchText}
          onChange={setSearchText}
        />
        <Box mt={3.5}>
          <CheckboxGroup options={options} onChange={onChangeCheck} value={checkedValues} />
        </Box>
      </Box>
      <Box className={classes.evaluationFooter}>
        <Button variant={btnType.TEXT} onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={() => onAddEvaluation(checkedValues)} disabled={checkedValues?.length === 0}>
          Add selected
        </Button>
      </Box>
    </Drawer>
  );
};

export default AddEvaluation;
