// import cx from 'clsx';
// import dayjs from 'dayjs';
// import customParseFormat from 'dayjs/plugin/customParseFormat';
// import { FC } from 'react';
// import { getTimeZoneText } from '../../../constants/CommonConstants';
// // colors
// import { colors } from '../../../colors';
// // icons
// import { Icons } from '../../../icons';
// import { ProfileInfo } from '../profile-info';
// import { Text, textLevel } from '../../atoms/typography';
// // types
// import { INoteCardProps } from './NoteCard.types';
// // styles
// import { useStyles } from './NoteCardBottom.styles';

// dayjs.extend(customParseFormat);

// const NoteCardBottom: FC<INoteCardProps> = ({
//   className = '',
//   timezone,
//   note,
//   isAppointment,
//   type = 'member',
//   onProfileClick,
//   isProvider,
//   isRecurring,
// }) => {
//   const classes = useStyles();

//   return (
//     <div className={cx(classes.bottomSection, className)}>
//       <div className={cx(classes.bottomSectionInner, className)}>
//         <div
//           className={cx(classes.footerUserInfo, { [classes.clickable]: !!onProfileClick })}
//           onClick={onProfileClick}
//         >
//           <ProfileInfo
//             type={type}
//             photo={note.createdBy?.profileImage}
//             nickName={note?.createdBy?.nickName || 'N/A'}
//             fullName={note.createdBy?.fullName || note?.createdBy?.name || 'N/A'}
//             role={note.createdBy?.designation || ''}
//           />
//         </div>
//         {note.patientId && (
//           <div
//             className={cx(classes.footerUserInfo, { [classes.clickable]: !!onProfileClick })}
//             onClick={onProfileClick}
//           >
//             <ProfileInfo
//               type="member"
//               photo={note?.memberProfileImage}
//               nickName={`${note.memberFirstName || ''} ${note.memberLastName || ''}`}
//               fullName={note?.memberUuid}
//               memberId={note?.patientId}
//               isProvider={isProvider}
//             />
//           </div>
//         )}
//         {note?.createdAt && (
//           <div className={classes.bottomContentWrapper}>
//             <Icons className={classes.bottomIcon} glyph="event-outlined" color={colors.neutral600} />
//             <Text className={classes.bottomText} level={textLevel.S}>
//               {isAppointment
//                 ? dayjs
//                     .utc(note.createdAt)
//                     ?.tz(timezone || dayjs.tz.guess())
//                     ?.format('MM/DD/YYYY - hh:mm A')
//                 : dayjs.utc(note.createdAt)?.tz(dayjs.tz.guess())?.format('MM/DD/YYYY - hh:mm A')}
//               {isAppointment && timezone && ` (${getTimeZoneText(timezone)})`}
//             </Text>
//             {isRecurring && <Icons glyph="recurring" color={colors.secondary500} />}
//           </div>
//         )}
//         {note?.releaseInformationTo?.nameOfReleaseTarget && (
//           <div className={classes.bottomContentWrapper}>
//             <Icons className={classes.bottomIcon} glyph="goal-circle" color={colors.neutral600} />
//             <Text className={classes.bottomText} level={textLevel.S}>
//               Target of release - {note?.releaseInformationTo?.nameOfReleaseTarget || 'N/A'}
//             </Text>
//           </div>
//         )}
//         {!isAppointment && (
//           <div className={classes.bottomContentWrapper}>
//             <Icons
//               className={classes.bottomIcon}
//               glyph="attach"
//               color={note?.attachments?.length === 0 ? colors.destructive600 : colors.neutral600}
//             />
//             <Text
//               className={cx(classes.bottomText, note?.attachments?.length === 0 && classes.alertText)}
//               level={textLevel.S}
//             >
//               {note?.attachments?.length || 'No'}{' '}
//               {note?.attachments?.length > 1 ? 'attachments' : 'attachment'}
//             </Text>
//           </div>
//         )}
//         {note?.standingOrder && note?.lab && (
//           <div className={classes.bottomContentWrapper}>
//             <Icons className={classes.bottomIcon} glyph="double-arrow-icon" color={colors.neutral600} />
//             <Text className={classes.bottomText} level={textLevel.S}>
//               {note?.lab || 'N/A'} Diagnostics - {note?.standingOrder?.frequency || 'N/A'}
//             </Text>
//           </div>
//         )}
//       </div>
//       {/* {note?.timeSpent && (
//           <div className={classes.bottomContentWrapper}>
//             <Icons className={classes.bottomIcon} glyph="clock" color={colors.neutral600} />
//             <Text className={classes.bottomText} level={textLevel.S}>
//               {isAppointment ? `${dayjs(note?.createdAt).format('hh:mm a')}` : `${note.timeSpent} minutes`}
//             </Text>
//           </div>
//         )} */}
//     </div>
//   );
// };

// export { NoteCardBottom };

import cx from 'clsx';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { getTimeZoneText } from '../../../constants/CommonConstants';
// colors
import { colors } from '../../../colors';
// icons
import { Icons } from '../../../icons';
import { ProfileInfo } from '../profile-info';
import { Text, textLevel } from '../../atoms/typography';
// styles
import { useStyles } from './NoteCardBottom.styles';

dayjs.extend(customParseFormat);

const NoteCardBottom = ({
  className = '',
  timezone = undefined,
  note,
  isAppointment = false,
  type = 'member',
  onProfileClick = null,
  isProvider = false,
  isRecurring = false,
}) => {
  const classes = useStyles();

  return (
    <div className={cx(classes.bottomSection, className)}>
      <div className={cx(classes.bottomSectionInner, className)}>
        <div
          className={cx(classes.footerUserInfo, { [classes.clickable]: !!onProfileClick })}
          onClick={onProfileClick}
        >
          <ProfileInfo
            type={type}
            photo={note.createdBy?.profileImage}
            nickName={note?.createdBy?.nickName || 'N/A'}
            fullName={note.createdBy?.fullName || note?.createdBy?.name || 'N/A'}
            role={note.createdBy?.designation || ''}
          />
        </div>
        {note.patientId && (
          <div
            className={cx(classes.footerUserInfo, { [classes.clickable]: !!onProfileClick })}
            onClick={onProfileClick}
          >
            <ProfileInfo
              type="member"
              photo={note?.memberProfileImage}
              nickName={`${note.memberFirstName || ''} ${note.memberLastName || ''}`}
              fullName={note?.memberUuid}
              memberId={note?.patientId}
              isProvider={isProvider}
            />
          </div>
        )}
        {note?.createdAt && (
          <div className={classes.bottomContentWrapper}>
            <Icons className={classes.bottomIcon} glyph="event-outlined" color={colors.neutral600} />
            <Text className={classes.bottomText} level={textLevel.S}>
              {isAppointment
                ? dayjs
                    .utc(note.createdAt)
                    ?.tz(timezone || dayjs.tz.guess())
                    ?.format('MM/DD/YYYY - hh:mm A')
                : dayjs.utc(note.createdAt)?.tz(dayjs.tz.guess())?.format('MM/DD/YYYY - hh:mm A')}
              {isAppointment && timezone && ` (${getTimeZoneText(timezone)})`}
            </Text>
            {isRecurring && <Icons glyph="recurring" color={colors.secondary500} />}
          </div>
        )}
        {note?.releaseInformationTo?.nameOfReleaseTarget && (
          <div className={classes.bottomContentWrapper}>
            <Icons className={classes.bottomIcon} glyph="goal-circle" color={colors.neutral600} />
            <Text className={classes.bottomText} level={textLevel.S}>
              Target of release - {note?.releaseInformationTo?.nameOfReleaseTarget || 'N/A'}
            </Text>
          </div>
        )}
        {!isAppointment && (
          <div className={classes.bottomContentWrapper}>
            <Icons
              className={classes.bottomIcon}
              glyph="attach"
              color={note?.attachments?.length === 0 ? colors.destructive600 : colors.neutral600}
            />
            <Text
              className={cx(classes.bottomText, note?.attachments?.length === 0 && classes.alertText)}
              level={textLevel.S}
            >
              {note?.attachments?.length || 'No'}{' '}
              {note?.attachments?.length > 1 ? 'attachments' : 'attachment'}
            </Text>
          </div>
        )}
        {note?.standingOrder && note?.lab && (
          <div className={classes.bottomContentWrapper}>
            <Icons className={classes.bottomIcon} glyph="double-arrow-icon" color={colors.neutral600} />
            <Text className={classes.bottomText} level={textLevel.S}>
              {note?.lab || 'N/A'} Diagnostics - {note?.standingOrder?.frequency || 'N/A'}
            </Text>
          </div>
        )}
      </div>
    </div>
  );
};

export { NoteCardBottom };
