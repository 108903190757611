// colors
import { colors } from '../../../../../packages/colors';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  card: {
    backgroundColor: colors.white,
    borderRadius: 8,
    flex: 1,
    border: `1px solid ${colors.neutral100}`,
    '&:hover $btnLinkWrap': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  cardTop: {
    padding: 24,
    height: 64,
  },
  cardTopTitle: {
    fontSize: 18,
    lineHeight: '24px',
    fontWeight: 600,
  },
  btnLinkWrap: {
    backgroundColor: colors.primary50,
    borderRadius: 4,
    width: 40,
    height: 40,
    cursor: 'pointer',
    display: 'none',
  },
  btnLink: {
    fontSize: 14,
    lineHeight: '20px',
    color: colors.primary500,
    fontWeight: 700,
    width: 18,
  },
  cardBody: {
    padding: 24,
  },
  username: {
    fontWeight: 600,
    lineHeight: '24px',
    fontSize: 14,
    display: 'block',
  },
  usernameFull: {
    color: colors.neutral600,
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '20px',
  },
  badge: {
    marginTop: 4,
    borderRadius: 99,
    height: 22,
    fontSize: 12,
    fontWeight: 600,
    lineHeight: '20px',
    padding: '0 8px',
    color: colors.indigo600,
    backgroundColor: colors.indigo50,
    borderColor: colors.indigo50,
  },
  title: {
    fontSize: 16,
    lineHeight: '24px',
    fontWeight: 600,
    color: colors.neutral900,
  },
  subtitle: {
    fontSize: 14,
    lineHeight: '20px',
    fontWeight: 500,
    color: colors.neutral700,
  },
});
