import { Box } from '@mui/material';
import MuiInputAdornment from '@mui/material/InputAdornment';
import { btnSize, btnType, Button, colors, Icons, Input } from '../../../../packages';

import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import cx from 'clsx';

import BoxFrame from '../../../../components/v2/BoxFrame';
import Header from '../../../../components/v2/Header';
import Typography from '../../../../components/v2/Typography';
import { typography } from '../../../../components/v2/Typography/index.constant';
import Heading from '../../../../components/v2/Heading';
import { HeadingLevel, HeadingWeight } from '../../../../components/v2/Heading/index.types';

import lockImg from '../../../../assets/images/lock.png';
import { insertIfObj } from '../../../../utils/InsertIf';
import { useDispatch, useSelector } from 'react-redux';

import { useStyles } from './index.styles';
import { validationSchema } from './index.schema';
import { getAuth } from '../../../../redux/modules/auth/selectors';
import { fetchCaqhCredentials, postCaqhCredentials } from '../../../../services/datalab/datalab.service';
import { showSnackbar } from '../../../../redux/modules/snackbar';

const defaultValues = {
  caqh: '',
  password: '',
};

const CAQHAccess = ({ navItemStatus, setNavItemStatus, refresh }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(true);
  const [showCAQH, setShowCAQH] = useState(true);
  const [isEditable, setIsEditable] = useState(false);
  const [loader, setLoader] = useState(false);
  const { meta } = useSelector(getAuth);

  const { errors, values, handleChange, handleSubmit, touched, setFieldTouched, setFieldValue } = useFormik({
    initialValues: { ...defaultValues },
    enableReinitialize: true,
    validationSchema,
    onSubmit: async payload => {
      if (isEditable) {
        setIsEditable(false);
        await setFieldValue('password', '');
      } else {
        setLoader(true);
        try {
          const bodyRequest = {
            caqhUsername: payload.caqh,
            caqhPassword: payload.password,
            userAccountId: meta?.userId,
          };
          const response = await postCaqhCredentials(bodyRequest);
          if (response.status === 200) {
            dispatch(
              showSnackbar({
                snackType: 'success',
                snackMessage: 'CAQH Credentials successfully validated',
              }),
            );
            setIsEditable(true);
            setLoader(false);
            refresh();
          } else {
            dispatch(
              showSnackbar({
                snackType: 'error',
                snackMessage: 'Error posting CAQH Credentials',
              }),
            );
            setLoader(false);
          }
        } catch (error) {
          dispatch(
            showSnackbar({
              snackType: 'error',
              snackMessage: error?.data?.message || 'Something went wrong',
            }),
          );
          console.error('API call error:', error);
          setLoader(false);
        }
      }
    },
  });

  const onBlurInput = async e => {
    await setFieldTouched(e.target.name);
  };

  const getCaqhCredentials = async () => {
    try {
      const response = await fetchCaqhCredentials({ userAccountId: meta?.userId });
      if (response?.status === 200 && response.data?.data?.caqhUsername) {
        const caqhUsername = response.data?.data?.caqhUsername;
        const dummyPassword = '*********';
        await setFieldValue('caqh', caqhUsername);
        await setFieldValue('password', dummyPassword);
        setIsEditable(true);
        const state = navItemStatus;
        state.find(i => i.key === 'caqh-access').status = 'success';
        setNavItemStatus([...state]);
      }
    } catch (err) {
      const msg = err.response?.data?.errors?.[0]?.endUserMessage || 'Something went wrong';
      console.log(msg);
      const state = navItemStatus;
      state.find(i => i.key === 'caqh-access').status = 'disabled';
      setNavItemStatus([...state]);
    }
  };

  useEffect(() => {
    void getCaqhCredentials();
  }, []);

  return (
    <>
      <Header label="CAQH Access" />
      <BoxFrame className={classes.card} sx={{ background: 'white' }}>
        <Box p={3}>
          <Box className={classes.information}>
            <img alt="lock" src={lockImg} className={classes.lockImg} />
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
              <Heading
                variant={HeadingLevel.SUB}
                fontWeight={HeadingWeight.SEMI_BOLD}
                color={colors.success700}
              >
                Your information is secure
              </Heading>
              <Typography {...typography.body.m.medium} color={colors.neutral700}>
                We&apos;ll need to access your CAQH profile, so we can help update it with information related
                to your participation in the Confidant Network. If we have any issues with accessing your
                profile, we&apos;ll reach out to you.
              </Typography>
            </Box>
          </Box>
          <form onSubmit={handleSubmit}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                mt: 10,
                mb: 13,
                flexDirection: 'column',
                gap: 3,
              }}
            >
              <Box className={classes.section}>
                <Typography {...typography.body.normal.small.bold}>Login</Typography>
                <Input
                  className={cx(classes.input, {
                    [classes.error]: !values.caqh && !!touched.caqh && !!errors.caqh,
                  })}
                  name="caqh"
                  placeholder="Enter your CAQH login"
                  type={showCAQH ? 'password' : 'text'}
                  onChange={handleChange}
                  onBlur={onBlurInput}
                  value={values.caqh || ''}
                  InputProps={{
                    ...insertIfObj(!!values.caqh && showCAQH, {
                      endAdornment: (
                        <MuiInputAdornment position="end">
                          <Icons
                            glyph="eye-outlined"
                            className={classes.eye}
                            color={colors.neutral500}
                            onClick={() => setShowCAQH(!showCAQH)}
                          />
                        </MuiInputAdornment>
                      ),
                    }),
                    ...insertIfObj(!values.caqh && !!touched.caqh && !!errors.caqh, {
                      endAdornment: (
                        <MuiInputAdornment position="end">
                          <Icons
                            glyph="info"
                            className={classes.info}
                            color={colors.neutral500}
                            onClick={() => setShowCAQH(!showCAQH)}
                          />
                        </MuiInputAdornment>
                      ),
                    }),
                    ...insertIfObj(!!values.caqh && !showCAQH, {
                      endAdornment: (
                        <MuiInputAdornment position="end">
                          <Icons
                            glyph="password-lock"
                            className={classes.eye}
                            color={colors.neutral500}
                            onClick={() => setShowCAQH(!showCAQH)}
                          />
                        </MuiInputAdornment>
                      ),
                    }),
                    readOnly: isEditable,
                  }}
                />
              </Box>
              <Box className={classes.section}>
                <Typography {...typography.body.normal.small.bold}>Password</Typography>
                <Input
                  className={cx(classes.input, {
                    [classes.error]: !values.password && !!touched.password && !!errors.password,
                  })}
                  name="password"
                  placeholder="Enter your CAQH password"
                  type={showPassword ? 'password' : 'text'}
                  onChange={handleChange}
                  onBlur={onBlurInput}
                  value={values.password || ''}
                  InputProps={
                    !isEditable
                      ? {
                          ...insertIfObj(!!values.password && showPassword, {
                            endAdornment: (
                              <MuiInputAdornment position="end">
                                <Icons
                                  glyph="eye-outlined"
                                  className={classes.eye}
                                  color={colors.neutral500}
                                  onClick={() => setShowPassword(!showPassword)}
                                />
                              </MuiInputAdornment>
                            ),
                          }),
                          ...insertIfObj(!!values.password && !showPassword, {
                            endAdornment: (
                              <MuiInputAdornment position="end">
                                <Icons
                                  glyph="password-lock"
                                  className={classes.eye}
                                  color={colors.neutral500}
                                  onClick={() => setShowPassword(!showPassword)}
                                />
                              </MuiInputAdornment>
                            ),
                          }),
                          ...insertIfObj(!values.password && !!touched.password && !!errors.password, {
                            endAdornment: (
                              <MuiInputAdornment position="end">
                                <Icons
                                  glyph="info"
                                  className={classes.info}
                                  color={colors.neutral500}
                                  onClick={() => setShowCAQH(!showCAQH)}
                                />
                              </MuiInputAdornment>
                            ),
                          }),
                        }
                      : {
                          readOnly: isEditable,
                        }
                  }
                />
              </Box>
              <Box className={classes.btn}>
                {isEditable ? (
                  <Button
                    size={btnSize.SMALL}
                    onClick={handleSubmit}
                    variant={btnType.OUTLINE}
                    className={classes.outlineBtn}
                    disabled={loader}
                  >
                    {loader ? (
                      <Icons className="rotate linear infinite" glyph="in-progress" color={colors.primary} />
                    ) : (
                      'Edit'
                    )}
                  </Button>
                ) : (
                  <Button
                    size={btnSize.SMALL}
                    disabled={!values.password || !values.caqh || loader}
                    onClick={handleSubmit}
                  >
                    {loader ? (
                      <Icons className="rotate linear infinite" glyph="in-progress" color={colors.primary} />
                    ) : (
                      'Save'
                    )}
                  </Button>
                )}
              </Box>
            </Box>
          </form>
        </Box>
      </BoxFrame>
    </>
  );
};

export default CAQHAccess;
