/* eslint-disable max-len */
import cx from 'clsx';

// types
// styles
import { useStyles } from './shared/styles';

const ConversationsFilled = ({ className, color, onClick }) => {
  const classes = useStyles();
  return (
    <svg
      className={cx({
        [classes.root]: true,
        [className || '']: className,
      })}
      width="20"
      height="19"
      viewBox="0 0 20 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M12.4999 12.3333C13.384 12.3333 14.2318 11.9821 14.8569 11.357C15.4821 10.7319 15.8333 9.88402 15.8333 8.99996V3.99996C15.8333 3.1159 15.4821 2.26806 14.8569 1.64294C14.2318 1.01782 13.384 0.666626 12.4999 0.666626H4.16659C3.28253 0.666626 2.43468 1.01782 1.80956 1.64294C1.18444 2.26806 0.833252 3.1159 0.833252 3.99996V14.4166C0.833252 14.5271 0.877151 14.6331 0.955291 14.7113C1.03343 14.7894 1.13941 14.8333 1.24992 14.8333C1.30442 14.833 1.3583 14.8218 1.40839 14.8003C1.45848 14.7788 1.50377 14.7475 1.54159 14.7083L3.20825 13.0416C3.4452 12.8109 3.72557 12.6295 4.03311 12.5079C4.34066 12.3863 4.66927 12.327 4.99992 12.3333H12.4999ZM17.4999 3.99996V10.6666C17.4999 11.5507 17.1487 12.3985 16.5236 13.0236C15.8985 13.6488 15.0506 14 14.1666 14H4.16659C4.16659 14.442 4.34218 14.8659 4.65474 15.1785C4.9673 15.491 5.39122 15.6666 5.83325 15.6666H14.9999C15.3295 15.6658 15.656 15.7302 15.9606 15.856C16.2653 15.9819 16.542 16.1668 16.7749 16.4L18.4416 18.0666C18.4794 18.1059 18.5247 18.1371 18.5748 18.1586C18.6249 18.1801 18.6788 18.1913 18.7333 18.1916C18.8438 18.1916 18.9497 18.1477 19.0279 18.0696C19.106 17.9914 19.1499 17.8855 19.1499 17.775V5.66663C19.1499 5.22747 18.9766 4.80605 18.6677 4.49397C18.3587 4.18188 17.9391 4.00435 17.4999 3.99996Z"
        fill={color}
      />
    </svg>
  );
};

export default ConversationsFilled;
