import { useCallback, useEffect, useState } from 'react';

// hooks
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Breadcrumbs, useQuery } from '../../../packages';
import { getProfile, selectMemberInsurance } from '../../../redux/modules/profile/selectors';
import { profileActionCreators } from '../../../redux/modules/profile';
import * as MemberService from '../../../services/member/member.service';

// components
import { BaseLayout } from '../../../layouts/base';

// styles
import { useStyles } from '../../provider/member-detail/MemberDetail.styles';

import SelectedComponent from './MemberDetail.selected';
import { rightSideNavList } from './MemberDetail.constants';
import { getPaymentMethodIndicators } from '../../../utils/CommonUtils';

const MemberDetail = () => {
  const [selectedScreen, setSelectedScreen] = useState('');
  const { insurance } = useSelector(selectMemberInsurance);
  const [openDrawer, setOpenDrawer] = useState(false);
  const classes = useStyles({});
  const {
    payload: { patientsList },
    selectedPatient,
    demographicDetails,
    domainTypes = [],
  } = useSelector(getProfile);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const query = useQuery();
  const { memberId } = useParams();
  const textFields = useCallback(() => {
    return [
      {
        label: 'Reward',
        text: selectedPatient?.reward || '-',
      },
      {
        label: 'Level of Engagement##', // ## added to differentiate b/w data
        text:
          Object.keys(demographicDetails).length === 0
            ? '-'
            : `${demographicDetails?.levelOfEngagementStatus}|${demographicDetails?.levelOfEngagementSubStatus}`,
      },
      /* {
        label: 'Date of Birth',
        text: selectedPatient?.dob ? dayjs(selectedPatient?.dob).format(GLOBAL_DATE_FORMAT) : '-',
      }, */
      {
        label: 'Insurance',
        text: insurance?.currentInsurance?.name || '-',
      },
    ];
  }, [selectedPatient, demographicDetails, insurance]);

  const rightSidebar = useCallback(() => {
    return rightSideNavList.map(item => {
      if (item.text === 'Priorities') {
        const newChild = item.children.map(child => {
          if (child.text === 'History') {
            return child;
          }
          if (child.text.toLowerCase() === 'environmental factors') {
            const environmentalFactors = ['social determinants', 'life events'];
            let badgeCount = 0;
            domainTypes.forEach(domainType => {
              if (environmentalFactors.includes(domainType.typeName.toLowerCase())) {
                badgeCount += domainType.relatedElements?.length ?? 0;
              }
            });
            return {
              ...child,
              badge: badgeCount || 0,
            };
          }
          if (child.text.toLowerCase() === 'symptomatic factors') {
            const symptomaticFactors = ['symptoms', 'substance use', 'side effects'];
            let badgeCount = 0;
            domainTypes.forEach(domainType => {
              if (symptomaticFactors.includes(domainType.typeName.toLowerCase())) {
                badgeCount += domainType.relatedElements?.length ?? 0;
              }
            });
            return {
              ...child,
              badge: badgeCount || 0,
            };
          }
          const domainType = domainTypes.find(
            ({ typeName }) => typeName.toLocaleLowerCase() === child.text.toLocaleLowerCase(),
          );
          return {
            ...child,
            badge: domainType?.relatedElements?.length || 0,
          };
        });
        return {
          ...item,
          children: newChild,
        };
      }
      if (item.text === 'General') {
        const newChild = item.children.map(child => {
          if (child.query?.value === 'insurance-information') {
            const indicators = getPaymentMethodIndicators(insurance, demographicDetails);
            return {
              text: 'Insurance information',
              query: { type: 'name', value: 'insurance-information' },
              rightIcon: indicators.glyph,
              rightIconColor: indicators.color,
            };
          }
          return child;
        });
        return {
          ...item,
          children: newChild,
        };
      }
      return item;
    });
  }, [domainTypes]);

  useEffect(() => {
    if (memberId) {
      const member = patientsList.find(item => item.member.id === memberId);
      if (member) {
        dispatch(profileActionCreators.selectedPatient(member));
        dispatch(profileActionCreators.fetchDemographicDetails(memberId));
        dispatch(profileActionCreators.fetchMemberInsurance({ memberId }));
      } else {
        MemberService.getAdminProfile({ userId: memberId })
          .then(res => {
            const mmber = {
              member: {
                id: memberId,
                firstName: res?.data?.firstName,
                lastName: res?.data?.lastName,
                fullName: res?.data?.fullName,
                nickName: res?.data?.fullName,
                photo: res?.data?.profileImage,
                uuid: res?.data?.uuid,
              },
              email: res?.data?.emailAddress,
              dob: res?.data?.dob,
              phone: res?.data?.phoneNumber,
              completed: res?.data?.activityCount,
              fundsInWallet: res?.data?.fundsInWallet,
              state: res?.data?.state,
              ...res?.data,
            };
            dispatch(profileActionCreators.selectedPatient(mmber));
            dispatch(profileActionCreators.fetchDemographicDetails(memberId));
            dispatch(profileActionCreators.fetchMemberInsurance({ memberId }));
            if (!res?.data?.fullName || !res?.data?.firstName) {
              navigate('/admin/members');
            }
          })
          .catch(err => {
            console.log('err', err);
            navigate('/admin/members');
          });
      }
    }
  }, [memberId, patientsList]);

  useEffect(() => {
    const getQuery = query.get('name');
    setSelectedScreen(getQuery || 'general-information');
  }, [query]);

  return (
    <BaseLayout
      rightSideNavList={rightSidebar()}
      textFields={textFields()}
      rightSideBarWidth="320px"
      setOpenDrawer={setOpenDrawer}
    >
      <div className={classes.root}>
        <div className={classes.header}>
          <Breadcrumbs
            links={[
              { href: '/admin/members', text: 'Members' },
              {
                text:
                  demographicDetails?.firstName || demographicDetails?.lastName
                    ? `${demographicDetails?.firstName || ''} ${demographicDetails.lastName || ''}`
                    : demographicDetails?.nickName
                    ? demographicDetails?.nickName
                    : '',
              },
            ]}
          />
        </div>
        <SelectedComponent
          selectedScreen={selectedScreen}
          openDrawer={openDrawer}
          setOpenDrawer={setOpenDrawer}
        />
      </div>
    </BaseLayout>
  );
};

export { MemberDetail };
