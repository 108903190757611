import { Box } from '@mui/material';

import HistoryDrawer from '../drawers/history-drawer';
import PastAppointmentsDrawer from '../drawers/past-appointments-drawer';
import GroupsDrawer from '../drawers/groups-drawer';
import ChatbotsDrawer from '../drawers/chatbots-drawer';
import SocialDeterminantDrawer from '../drawers/social-determinant-drawer';
import LifeEventDrawer from '../drawers/life-event-drawer';
import SymptomDrawer from '../drawers/symptom-drawer';
import DiagnosesDrawer from '../drawers/diagonses-drawer';
import MedicationDrawer from '../drawers/medication-drawer';
import SideEffectDrawer from '../drawers/side-effect-drawer';
import SubstanceUseDrawer from '../drawers/substance-use-drawer';
import NotesDrawer from '../drawers/notes-drawer';
import PlanItemsDrawer from '../drawers/plan-items-drawer';
import { PrioritiesCard } from '../priority-list/PriorityList.constants';
import SymptomaticFactorDrawer from '../drawers/symptomatic-factor-drawer';
import EnvironmentalFactorDrawer from '../drawers/environmental-factor-drawer';

const CurrentView = ({ view, isSession = true, open = true, ...rest }) => {
  const renderView = () => {
    switch (view) {
      case PrioritiesCard.History:
        return <HistoryDrawer open={open} {...rest} />;
      case PrioritiesCard.PastAppointment:
        return <PastAppointmentsDrawer open={open} {...rest} />;
      case PrioritiesCard.Chatbots:
        return <ChatbotsDrawer open={open} {...rest} />;
      case PrioritiesCard.Groups:
        return <GroupsDrawer open={open} {...rest} />;
      case PrioritiesCard.SocialDeterminants:
        return <SocialDeterminantDrawer open={open} {...rest} />;
      case PrioritiesCard.LifeEvents:
        return <LifeEventDrawer open={open} {...rest} />;
      case PrioritiesCard.Symptoms:
        return <SymptomDrawer open={open} {...rest} />;
      case PrioritiesCard.Diagnoses:
        return <DiagnosesDrawer isSession={isSession} open={open} {...rest} />;
      case PrioritiesCard.Medications:
        return <MedicationDrawer isSession={isSession} open={open} {...rest} />;
      case PrioritiesCard.SideEffects:
        return <SideEffectDrawer open={open} {...rest} />;
      case PrioritiesCard.SymptomaticFactors:
        return <SymptomaticFactorDrawer open={open} {...rest} />;
      case PrioritiesCard.EnvironmentalFactors:
        return <EnvironmentalFactorDrawer open={open} {...rest} />;
      case PrioritiesCard.SubstanceUse:
        return <SubstanceUseDrawer open={open} {...rest} />;
      case PrioritiesCard.Notes:
        return <NotesDrawer open={open} {...rest} />;
      case PrioritiesCard.PlanItems:
        return <PlanItemsDrawer open={open} {...rest} />;
      default:
        return null;
    }
  };
  return <Box>{renderView()}</Box>;
};

export { CurrentView };
