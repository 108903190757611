import { colors } from '../../../../../../packages/colors';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  card: {
    position: 'relative',
    backgroundColor: colors.white,
    borderRadius: 8,
    width: '100%',
    boxShadow:
      '0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
  },
  head: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '30px 24px',
    borderBottom: `1px solid ${colors.neutral100}`,
  },
  headRight: {
    display: 'flex',
    alignItems: 'center',
    gap: 12,
  },
  title: {
    color: colors.neutral900,
    fontSize: 18,
    fontWeight: 600,
    lineHeight: '24px',
    margin: 0,
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      marginLeft: 8,
      '& path': {
        fill: colors.neutral400,
      },
    },
  },
  menu: {
    padding: 0,
  },
  menuLabel: {
    color: colors.neutral700,
    fontSize: 14,
    lineHeight: '20px',
    fontWeight: 600,
    '& ul li': {
      color: colors.neutral700,
    },
  },
  chart: {
    padding: '16px 16px 24px',
    '& .apexcharts-legend': {
      gap: 20,
    },
    '& .apexcharts-legend-marker': {
      borderRadius: '50% !important',
      top: '1px !important',
      marginRight: '8px !important',
    },
    '& .apexcharts-legend-text': {
      fontSize: '14px !important',
    },
    '& .chart-tooltip': {
      padding: 16,
      width: 232,
      borderRadius: '8px !important',
      '& .date': {
        fontSize: '14px !important',
        lineHeight: '20px',
        color: colors.neutral600,
      },
      '& .level': {
        color: colors.neutral900,
        fontWeight: 700,
      },
      '& .row': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: 12,
        '& .value': {
          borderRadius: 22,
          minWidth: 22,
          padding: 4,
          height: 22,
          border: `1px solid ${colors.neutral200}`,
          fontSize: 12,
          lineHeight: '16px',
          fontWeight: 700,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: colors.neutral700,
          textAlign: 'center',
        },
        '& .row-left': {
          display: 'flex',
          alignItems: 'center',
        },
        '& .dot': {
          width: 12,
          height: 12,
          borderRadius: '50%',
          marginRight: 8,
          '&.dot-0': {
            backgroundColor: colors.primary500,
          },
          '&.dot-1': {
            backgroundColor: colors.secondary500,
          },
          '&.dot-2': {
            backgroundColor: colors.neutral600,
          },
        },
      },
    },
  },
  chartLegend: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 16,
  },
  circle: {
    width: 12,
    height: 12,
    borderRadius: '50%',
    backgroundColor: colors.primary500,
    marginRight: 8,
  },
  legendText: {
    fontSize: 14,
    lineHeight: '20px',
    fontWeight: 500,
    color: colors.neutral600,
    margin: 0,
  },
});
