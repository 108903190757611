// colors
import { colors } from '../../../../../packages';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  reviewWrap: {
    padding: '0px 45px',
  },
  reviewSection: {
    padding: '25px 0px',
  },
  section: {
    position: 'relative',
    padding: '16px 24px',
  },
  timeSelect: {
    paddingLeft: 26,
  },
  title: {
    fontSize: 18,
    lineHeight: '24px',
    margin: 0,
    width: '25%',
  },
  valueSection: {
    width: '75%',
  },
  valueSection2: {
    width: '75%',
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
  },
  valueSection3: {
    width: '75%',
    display: 'flex',
    alignItems: 'center',
    gap: 12,
  },
  text18: {
    fontSize: 18,
    lineHeight: '24px',
  },
  text16: {
    fontSize: 16,
    lineHeight: '24px',
    fontWeight: 600,
  },
  text16_2: {
    fontSize: 16,
    color: colors.neutral600,
    lineHeight: '24px',
    fontWeight: 500,
  },
  text14: {
    fontSize: 14,
    lineHeight: '20px',
    paddingBottom: 16,
  },
  select: {
    width: '100%',
  },
  label: {
    display: 'block',
  },
  text16_3: {
    fontSize: 16,
    color: colors.neutral500,
    lineHeight: '24px',
    fontWeight: 600,
  },
});
