import cx from 'clsx';
// mui
import { Drawer as MuiDrawer } from '@mui/material';

// components
import { fontWeight, Heading, headingLevel, Text, textLevel } from '../../atoms/typography';
import { btnType, Button } from '../../atoms/button';
import { Icons } from '../../../icons';

// types
import { drawerPosition, drawerType, drawerWidth } from './Drawer.types';

// styles
import { useStyles } from './Drawer.styles';

const Drawer = ({
  children,
  className = '',
  paperClassName = '',
  position = drawerPosition.RIGHT,
  variant = drawerType.NORMAL,
  widthVariant = drawerWidth.NORMAL,
  open,
  subtitleComponent = null,
  disableSubmit = false,
  onClose,
  title = 'Form',
  titleIconComponent = null,
  subTitle = null,
  submitBtnTitle = 'Confirm',
  onSubmit = null,
  narrowHeader = false,
  footer = null,
  header = null,
  titleWidth = '100%',
  stickyHeader = false,
}) => {
  const classes = useStyles();
  return (
    <MuiDrawer
      anchor={position}
      open={open}
      onClose={onClose}
      className={cx({
        [classes.root]: true,
        [classes.formRoot]: variant === drawerType.FORM,
        [classes.vertical]:
          (position === drawerPosition.LEFT || position === drawerPosition.RIGHT) &&
          widthVariant === drawerWidth.NORMAL,
        [classes.verticalNarrow]:
          (position === drawerPosition.LEFT || position === drawerPosition.RIGHT) &&
          widthVariant === drawerWidth.NARROW,
        [classes.horizontal]: position === drawerPosition.TOP || position === drawerPosition.BOTTOM,
        [className]: true,
      })}
      classes={{ paper: paperClassName }}
    >
      {variant === drawerType.FORM ? (
        <>
          <div>
            {header || (
              <div
                className={cx({
                  [classes.drawerHeader]: true,
                  [classes.bigDrawerHeader]: subTitle,
                  [classes.biggerDrawerHeader]: subtitleComponent,
                  [classes.stickyHeader]:stickyHeader
                })}
              >
                <div
                  className={cx({
                    [classes.topContentWrapperfortitle]:
                      !narrowHeader && !subtitleComponent && !subTitle && !titleIconComponent,
                    [classes.topContentWrapper]:
                      !narrowHeader &&
                      subtitleComponent &&
                      subtitleComponent &&
                      subTitle &&
                      titleIconComponent,
                    [classes.topContentWrapper1]: narrowHeader,
                  })}
                >
                  <div className={classes.row}>
                    {titleIconComponent && <div>{titleIconComponent}</div>}
                    <div>
                      <Heading className={classes.headTitle} level={headingLevel.S} weight={fontWeight.BOLD}>
                        <div className={classes.row}>
                          <span
                            className={titleWidth === '100%' ? '' : classes.textElipsis}
                            style={{ width: titleWidth }}
                          >
                            {title}
                          </span>
                        </div>
                      </Heading>
                      {subTitle && (
                        <Text className={classes.headSubTitle} weight={fontWeight.MEDIUM} level={textLevel.M}>
                          {subTitle}
                        </Text>
                      )}
                    </div>
                  </div>
                  {subtitleComponent && <div className={classes.subtitleComponent}>{subtitleComponent}</div>}
                </div>

                <Icons onClick={onClose} className={classes.closeIcon} glyph="close" />
              </div>
            )}
            <div
              className={cx({
                [classes.drawerBody]: true,
                drawerBody: true,
              })}
            >
              {children}
            </div>
          </div>
          {footer || (
            <div className={cx(classes.drawerFooter, 'drawer-footer')}>
              <Button onClick={onClose} className={classes.drawerCancelBtn} variant={btnType.TEXT}>
                Cancel
              </Button>
              <Button
                className={classes.drawerUpdateBtn}
                variant={btnType.PRIMARY}
                onClick={onSubmit}
                disabled={disableSubmit}
              >
                {submitBtnTitle}
              </Button>
            </div>
          )}
        </>
      ) : (
        children
      )}
    </MuiDrawer>
  );
};

export { Drawer };
