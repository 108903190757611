// import { makeStyles } from '@mui/styles';

// // colors
// import { colors } from '../../../colors';
// import { fontWeight, textLevel } from '../typography';

// interface StyleProps {
//   collapsed?: boolean;
//   active?: boolean;
// }

// export const useStyles = makeStyles({
//   root: {
//     display: 'flex',
//     flexDirection: 'column',
//     gap: 0,
//   },
//   infoIcon: {
//     height: '15px',
//     width: '15px',
//   },
//   tooltip: {
//     cursor: 'pointer',
//   },
//   header: {
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'space-between',
//     cursor: 'pointer',
//     flex: 1,
//     background: ({ active }) => (active ? colors.neutral50 : 'transparent'),
//   },
//   chevronIcon: {
//     width: 24,
//     minWidth: 24,
//     height: 24,
//     objectFit: 'contain',
//     transition: '0.4s',
//     marginLeft: '12px',
//     marginRight: '12px',
//     transform: ({ collapsed }: StyleProps) => `rotate(${collapsed ? '180deg' : 0})`,
//   },
//   label: {
//     fontFamily: 'Manrope',
//     fontWeight: 'bold',
//     fontSize: 14,
//     lineHeight: '20px',
//     color: colors.neutral900,
//   },
//   labelIcon: {
//     display: 'flex',
//     justifyContent: 'flex-start',
//     alignItems: 'center',
//     gap: 4,
//   },
//   flexRow: {
//     display: 'flex',
//     flexDirection: 'row',
//   },
//   flex1: {
//     flex: 1,
//   },
//   subLabel: {
//     color: colors.neutral600,
//     fontWeight: fontWeight.MEDIUM,
//     fontFamily: 'Manrope',
//     fontSize: 14,
//     lineHeight: '20px',
//   },
//   dctEditBtn: {
//     gap: 4,
//     minWidth: 24,
//     '& svg': { width: 20 },
//   },
//   dctDeleteBtn: {
//     minWidth: 24,
//     '& svg': { width: 20, '& path': { color: colors.destructive500 } },
//   },
//   btnDiv: {
//     display: 'flex',
//     gap: 16,
//   },
//   container: {
//     display: 'flex',
//     justifyContent: 'space-between',
//   },
// });

import { makeStyles } from '@mui/styles';

// colors
import { colors } from '../../../colors';
import { fontWeight } from '../typography';

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: 0,
  },
  infoIcon: {
    height: '15px',
    width: '15px',
  },
  tooltip: {
    cursor: 'pointer',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
    flex: 1,
    background: ({ active }) => (active ? colors.neutral50 : 'transparent'),
  },
  chevronIcon: {
    width: 24,
    minWidth: 24,
    height: 24,
    objectFit: 'contain',
    transition: '0.4s',
    marginLeft: '12px',
    marginRight: '12px',
    transform: ({ collapsed }) => `rotate(${collapsed ? '180deg' : 0})`,
  },
  label: {
    fontFamily: 'Manrope',
    fontWeight: 'bold',
    fontSize: 14,
    lineHeight: '20px',
    color: colors.neutral900,
  },
  labelIcon: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: 4,
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  flex1: {
    flex: 1,
  },
  subLabel: {
    color: colors.neutral600,
    fontWeight: fontWeight.MEDIUM,
    fontFamily: 'Manrope',
    fontSize: 14,
    lineHeight: '20px',
  },
  dctEditBtn: {
    gap: 4,
    minWidth: 24,
    '& svg': { width: 20 },
  },
  dctDeleteBtn: {
    minWidth: 24,
    '& svg': { width: 20, '& path': { color: colors.destructive500 } },
  },
  btnDiv: {
    display: 'flex',
    gap: 16,
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});
