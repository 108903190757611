export const invoices = [
  {
    amountPaid: 100,
    status: 'pending',
    rating: 3.5,
    provider: {
      name: 'Natali',
      fullname: 'Natali Craig',
      profileImage: 'https://i.pravatar.cc/300',
      role: 'Provider',
    },
  },
  {
    amountPaid: 50,
    status: 'paid',
    rating: 5,
    provider: {
      name: 'Zahir',
      fullname: 'Zahir Mays',
      profileImage: 'https://i.pravatar.cc/300',
      role: 'Supervisor',
    },
  },
  {
    amountPaid: 75,
    status: 'hold',
    rating: 2,
    provider: {
      name: 'john_doe',
      fullname: 'John Doe',
      profileImage: 'https://i.pravatar.cc/300',
      role: 'Coach',
    },
  },
  {
    amountPaid: 100,
    status: 'paid',
    rating: 5,
    provider: {
      name: 'Alisa',
      fullname: 'Alisa Hester',
      profileImage: 'https://i.pravatar.cc/300',
      role: 'Designation',
    },
  },
  {
    amountPaid: 100,
    status: 'paid',
    rating: 5,
    provider: {
      name: 'Eliah',
      fullname: 'Eliah Johnson',
      profileImage: 'https://i.pravatar.cc/300',
      role: 'matchmaker',
    },
  },
];

export const viewGroups = [
  { type: '', label: 'ALL' },
  { type: 'pending', label: 'Pending' },
  { type: 'hold', label: 'On hold' },
  { type: 'paid', label: 'Paid' },
];

export const statusMapping = {
  pending: 'Pending',
  hold: 'On hold',
  paid: 'Paid',
};
