import { Box } from '@mui/material';
import { btnSize, btnType } from '../../../packages/ui/atoms/button';
import { AppointmentStatus, SignOffStatus } from '../../../packages/constants/CommonConstants';

import dayjs, { getUserTimeZone } from '../../../utils/dayjs';
import Heading from '../../../components/v2/Heading';
import { HeadingLevel, HeadingWeight } from '../../../components/v2/Heading/index.types';
import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import { selectProviderSchedule } from '../../../redux/modules/schedule/selectors';
import AppointmentCard from './components/AppointmentCard';

import { useStyles } from './index.styles';
import history from '../../../utils/history';

const Appointments = ({
  title = '',
  appointments,
  onGoToChat,
  onReschedule,
  selectAppointment,
  onSignOff,
  onStartSessionClick,
  viewDetail,
}) => {
  const classes = useStyles();
  const providerSchedule = useSelector(selectProviderSchedule);
  const timezone = useMemo(() => providerSchedule?.timezone || getUserTimeZone(), [providerSchedule]);
  return (
    <Box className={classes.cflex} gap={4}>
      {title && (
        <Heading variant={HeadingLevel.SUB} fontWeight={HeadingWeight.SEMI_BOLD}>
          {title}
        </Heading>
      )}
      <Box className={classes.cflex} gap={2}>
        {appointments?.map(item => {
          const actions = [];
          if (item.status === AppointmentStatus.PROPOSED || item.status === AppointmentStatus.PENDING) {
            if (
              item.patientStatus === AppointmentStatus.NEEDS_ACTION &&
              title === 'Upcoming today' &&
              !item.instantAppointment
            ) {
              actions.push({
                icon: 'calendar-today-outlined',
                variant: btnType.OUTLINE,
                size: btnSize.SMALL,
                className: classes.outlineBtn,
                children: 'Reschedule',
                onClick: () => {
                  selectAppointment(item);
                  onReschedule();
                },
              });
            }
            if (item.patientStatus === AppointmentStatus.NEEDS_ACTION) {
              actions.push({
                icon: 'chat',
                variant: btnType.OUTLINE,
                size: btnSize.SMALL,
                className: classes.outlineBtn,
                children: 'Go to chat',
                onClick: () => onGoToChat(item),
              });
            }
            if (item.practitionerStatus === AppointmentStatus.NEEDS_ACTION) {
              actions.push({
                icon: 'checkmark',
                variant: btnType.PRIMARY,
                size: btnSize.SMALL,
                className: classes.primaryBtn,
                children: 'Accept',
              });
            }
          }
          if (
            item.status === AppointmentStatus.BOOKED &&
            title === 'Upcoming today' &&
            !item?.instantAppointment
          ) {
            actions.push({
              icon: 'calendar-today-outlined',
              variant: btnType.OUTLINE,
              size: btnSize.SMALL,
              className: classes.outlineBtn,
              children: 'Reschedule',
              onClick: () => {
                selectAppointment(item);
                onReschedule();
              },
            });
          }
          if (item.status === AppointmentStatus.BOOKED && title === 'Upcoming today') {
            actions.push({
              // icon: 'calendar-today-outlined',
              variant: btnType.PRIMARY,
              size: btnSize.SMALL,
              className: classes.outlineBtn2,
              children: 'Start session',
              onClick: () => {
                onStartSessionClick(item);
                // onReschedule();
              },
            });
          }
          if (
            item.status === AppointmentStatus.BOOKED &&
            title !== 'Upcoming today' &&
            !item?.instantAppointment
          ) {
            actions.push({
              icon: 'calendar-today-outlined',
              variant: btnType.OUTLINE,
              size: btnSize.SMALL,
              className: classes.outlineBtn,
              children: 'Reschedule',
              onClick: () => {
                selectAppointment(item);
                onReschedule();
              },
            });
          }
          if (item.status === AppointmentStatus.FULFILLED) {
            if (item.signOffStatus === SignOffStatus.DRAFTED) {
              actions.push({
                variant: btnType.PRIMARY,
                size: btnSize.SMALL,
                className: classes.primaryBtn,
                children: 'Sign off',
                onClick: () => onSignOff(item),
              });
            } else {
              actions.push({
                variant: btnType.OUTLINE,
                size: btnSize.SMALL,
                className: classes.outlineBtn,
                children: 'View Details',
                onClick: () =>
                  history.push(`/provider/members/${item.patientId}/appointments/${item.appointmentId}`),
              });
            }
          }
          if (
            item.status === AppointmentStatus.CANCELLED &&
            title.trim() !== '' &&
            item?.instantAppointment
          ) {
            actions.push({
              icon: 'calendar-today-outlined',
              variant: btnType.OUTLINE,
              size: btnSize.SMALL,
              className: classes.outlineBtn,
              children: 'Reschedule',
              onClick: () => {
                selectAppointment(item);
                onReschedule();
              },
            });
          }
          return (
            <AppointmentCard
              key={item.appointmentId}
              start={dayjs(item.startTime).tz(timezone).format('MM/DD/YYYY')}
              end={`${dayjs(item.startTime).tz(timezone).format('h:mm a')} - ${dayjs(item.endTime)
                .tz(timezone)
                .format('h:mm a')}`}
              appointment={item}
              actions={actions}
              viewDetail={viewDetail}
            />
          );
        })}
      </Box>
    </Box>
  );
};

export default Appointments;
