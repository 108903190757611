/* eslint-disable react/destructuring-assignment */
import { useEffect, useMemo, useState } from 'react';

import {
  btnType,
  Button,
  colors,
  fontWeight,
  IconButton,
  Icons,
  Input,
  inputSize,
  inputType,
  Text,
} from '../../../../../../packages';
import { Box, Stack } from '@mui/material';
import { Drawer, drawerType } from '../../../../../../packages/ui/organisms/drawer';

import { getElkProfileElementReport2 } from '../../../../../../services/reporting/reporting.service';
import BreakdownChart from '../../../../../../pages/admin/plan-items/components/plan-item-detail/breakdown-chart';
import RecentAssignmentsTable from '../../../../../../pages/admin/plan-items/components/plan-item-detail/recent-assignments-table';

import AssignmentsChart from '../../assignments-chart';
import {
  getChartInfo,
  getLast30DaysData,
  getPast30DaysData,
  getProfileElementDetailQuery,
  getRecentAssignedData,
  tableColumns,
} from './ProfileElementDetail.constants';
import { useStyles } from './ProfileElementDetail.styles';

const ProfileElementDetail = ({ open, onClose, profileElement }) => {
  const [isLoading, setIsLoading] = useState(false);
  const classes = useStyles();
  const [search, setSearch] = useState('');
  const [tableParams, setTableParams] = useState({
    search: { searchKey: '' },
    pagination: { currentPage: 1, rowsPerPage: 20 },
    sorter: { direction: 'asc', column: '' },
  });
  const [profileElementReportDetail, setProfileElementReportDetail] = useState(null);
  const chartInfo = useMemo(() => getChartInfo(profileElementReportDetail), [profileElementReportDetail]);
  const last30DaysData = useMemo(
    () => getLast30DaysData(profileElementReportDetail),
    [profileElementReportDetail],
  );
  const past30DaysData = useMemo(
    () => getPast30DaysData(profileElementReportDetail),
    [profileElementReportDetail],
  );
  const recentAssignedData = useMemo(
    () => getRecentAssignedData(profileElementReportDetail, search),
    [profileElementReportDetail, search, tableParams.pagination],
  );
  useEffect(() => {
    if (!profileElement || !profileElement.name) {
      return;
    }
    void (async () => {
      setIsLoading(true);
      const resp = await getElkProfileElementReport2(getProfileElementDetailQuery(profileElement.name), null);
      setProfileElementReportDetail(resp.data);
      setIsLoading(false);
    })();
  }, [profileElement]);

  const renderMenu = () => {
    return <></>;
    // return (
    //   <Menu
    //     icon="more"
    //     className={classes.menu}
    //     items={[
    //       { label: 'Menu Item 1', onClick: onClickMenu(menuTable, action?.id, 'Menu Item 1') },
    //       { label: 'Menu Item 2', onClick: onClickMenu(menuTable, action?.id, 'Menu Item 2') },
    //       { label: 'Menu Item 3', onClick: onClickMenu(menuTable, action?.id, 'Menu Item 3') },
    //     ]}
    //   />
    // );
  };

  return (
    <Drawer open={open} onClose={onClose} variant={drawerType.NORMAL} className={classes.drawer}>
      {open && (
        <Box className={classes.drawerContent}>
          {isLoading ? (
            <div className={classes.loader}>
              <Icons className="rotate linear infinite" glyph="in-progress" color={colors.primary} />
              Loading...
            </div>
          ) : (
            <>
              <Box className={classes.headerDetail}>
                <Stack direction="column">
                  <Text className={classes.headerTitle} weight={fontWeight.BOLD}>
                    {profileElement.name}
                  </Text>
                  <Text className={classes.headerSubtitle}>{profileElement.total} assignments</Text>
                </Stack>
                <IconButton icon="close" onClick={onClose} className={classes.closeIconBtn} />
              </Box>
              <Box className={classes.content}>
                <Box className={classes.donut}>
                  {chartInfo && (
                    <BreakdownChart
                      className={classes.breakdownChart}
                      title={chartInfo.title}
                      subtitle={chartInfo.subtitle}
                      chartData={chartInfo.chartData}
                      chartHeight={250}
                      chartWidth={700}
                      donutOpts={{
                        total: chartInfo.donutOptTotal,
                        title: chartInfo.donutOptTitle,
                        size: '80%',
                      }}
                    />
                  )}
                </Box>

                <Box className={classes.chartWrap}>
                  <AssignmentsChart
                    title="Last 30 days"
                    className={classes.chart}
                    chart={{
                      series: [
                        { name: 'Current 30 days', color: colors.primary500, data: last30DaysData },
                        { name: 'Past 30 days', color: colors.neutral600, data: past30DaysData },
                      ],
                      tooltipConfig: {
                        isShowTotal: false,
                        title: 'Total',
                      },
                    }}
                  />
                </Box>
                <Box className={classes.tableWrap}>
                  <Text className={classes.recentText}>Recent assignments</Text>
                  <Input
                    variant={inputType.SEARCH}
                    placeholder="Search"
                    value={search}
                    className={classes.inputSearch}
                    onChange={setSearch}
                    size={inputSize.M}
                    fullWidth
                  />
                  <RecentAssignmentsTable
                    className={classes.tableBordered}
                    isShowAll
                    tableColumns={tableColumns}
                    data={recentAssignedData.slice(
                      (tableParams.pagination.currentPage - 1) * tableParams.pagination.rowsPerPage,
                      (tableParams.pagination.currentPage - 1) * Number(tableParams.pagination.rowsPerPage) +
                        Number(tableParams.pagination.rowsPerPage),
                    )}
                    renderMenuAction={renderMenu}
                    paginationProps={{
                      totalCount: recentAssignedData.length,
                      showRowsPerPage: false,
                    }}
                    value={tableParams}
                    onChange={setTableParams}
                  />
                </Box>
              </Box>
              <Box className={classes.drawerFooter}>
                <Button variant={btnType.PRIMARY} onClick={onClose}>
                  Close
                </Button>
              </Box>
            </>
          )}
        </Box>
      )}
    </Drawer>
  );
};

export { ProfileElementDetail };
