import clsx from 'clsx';
import dayjs from 'dayjs';
import { Box } from '@mui/material';
import { Avatar, avatarType, fontWeight, Text } from '../../../../../../packages';
import { useStyles } from './SingleSelect.styles';
import { listMock } from './SingleSelect.mock';

const SingleSelect = ({ showNumber }) => {
  const classes = useStyles();

  const renderRow = item => (
    <Box className={classes.item}>
      <Box className={classes.userWrap}>
        <Avatar src={item.member.thumbnail} name={item.member.name} size={40} variant={avatarType.CIRCLE} />
        <Box>
          <Text className={classes.name} weight={fontWeight.SEMI_BOLD}>
            {item.member.name}
          </Text>
          <Text className={clsx(classes.name, classes.fullname)} weight={fontWeight.MEDIUM}>
            {item.member.fullname}
          </Text>
        </Box>
      </Box>
      <Box sx={{ width: 100, textAlign: 'left' }}>
        <Text className={classes.name} weight={fontWeight.MEDIUM}>
          {showNumber ? item.numberic : item.status}
        </Text>
      </Box>
      <Box>
        <Text className={classes.name} weight={fontWeight.SEMI_BOLD}>
          {dayjs(item.createdDate).format('MMMM DD, YYYY')}
        </Text>
        <Text className={clsx(classes.name, classes.fullname)} weight={fontWeight.MEDIUM}>
          {dayjs(item.createdDate).format('h:mm a')}
        </Text>
      </Box>
    </Box>
  );

  return <Box>{listMock.map(renderRow)}</Box>;
};

export { SingleSelect };
