// colors
import { colors } from '../../../../../packages';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  drawer: {
    '& .drawerBody': {
      padding: 0,
      minHeight: 'calc(100vh - 80px)',
      flexDirection: 'column',
      display: 'flex',
    },
  },
  boxTop: {
    borderBottom: `1px solid ${colors.neutral100}`,
    padding: 24,
  },
  title: {
    fontSize: 18,
    lineHeight: '24px',
    margin: 0,
  },
  text14: {
    fontSize: 14,
    lineHeight: '20px',
  },
  selectWrap: {
    padding: 24,
  },
  select: {
    width: '100%',
  },
  reason: {
    display: 'block',
    marginBottom: 8,
  },
  duration: {
    color: colors.neutral600,
    display: 'block',
    marginTop: 4,
  },
  info: {
    color: colors.neutral700,
  },
  fullname: {
    color: colors.neutral500,
    display: 'block',
  },
  boxInfo: {
    display: 'flex',
    alignItems: 'center',
    gap: 10,
    marginBottom: 4,
    '&:last-child': {
      marginBottom: 0,
    },
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: 16,
    borderTop: `1px solid ${colors.neutral100}`,
    gap: 10,
  },
  btn: {
    color: colors.white,
  },
  cancelBtn: {
    color: colors.white,
    backgroundColor: colors.destructive500,
    '&:hover': {
      backgroundColor: '#F87171',
    },
  },
});
