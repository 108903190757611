import { makeStyles } from '@mui/styles';

// colors
import { colors } from '../../../packages';

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: 40,
  },
  table: {
    backgroundColor: colors.white,
  },
  link: {
    textDecoration: 'none',
    cursor: 'pointer',
  },
  name: {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '20px',
  },
  importanceLevels: {
    display: 'inline-block',
    width: '144px',
  },
  heading: {
    fontFamily: 'Source Serif Pro',
    margin: 0,
  },
  menu: {
    float: 'right',
    right: 24,
    '& svg': {
      width: 16,
    },
    '& svg path': {
      fill: colors.neutral400,
    },
  },
  menuItemsWrapper: {
    '& ul': {
      padding: 0,
    },
    '& ul li': {
      padding: '10px 16px',
    },
  },
  totalMemberBadge: {
    borderRadius: 99,
    whiteSpace: 'nowrap',
    height: 30,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 12px',
    color: colors.neutral700,
  },
  addProfileBtn: {
    height: 64,
    color: '#FFF',
    whiteSpace: 'nowrap',
    padding: '16px 24px',
    borderRadius: 8,
  },
});
