/* eslint-disable max-len */
import cx from 'clsx';

// types
// styles
import { useStyles } from './shared/styles';

const ShareScreen = ({ className, color, onClick, ...rest }) => {
  const classes = useStyles();
  return (
    <svg
      className={cx(classes.root, className)}
      onClick={onClick}
      width="24"
      height="16"
      viewBox="0 0 24 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M23.5 14H22V1C22 0.734784 21.8946 0.48043 21.7071 0.292893C21.5196 0.105357 21.2652 0 21 0H3C2.73478 0 2.48043 0.105357 2.29289 0.292893C2.10536 0.48043 2 0.734784 2 1V14H0.5C0.367392 14 0.240215 14.0527 0.146447 14.1464C0.0526784 14.2402 0 14.3674 0 14.5L0 15C0 15.2652 0.105357 15.5196 0.292893 15.7071C0.48043 15.8946 0.734784 16 1 16H23C23.2652 16 23.5196 15.8946 23.7071 15.7071C23.8946 15.5196 24 15.2652 24 15V14.5C24 14.3674 23.9473 14.2402 23.8536 14.1464C23.7598 14.0527 23.6326 14 23.5 14ZM17.85 7.35L14.06 11.14C14.0135 11.1869 13.9582 11.2241 13.8973 11.2494C13.8364 11.2748 13.771 11.2879 13.705 11.2879C13.639 11.2879 13.5736 11.2748 13.5127 11.2494C13.4518 11.2241 13.3965 11.1869 13.35 11.14L13.15 10.94C13.1017 10.8935 13.0634 10.8375 13.0376 10.7756C13.0118 10.7136 12.999 10.6471 13 10.58V8.82C10 8.82 7 10.1 6.65 11.74C6.63803 11.8133 6.60016 11.8798 6.54326 11.9275C6.48637 11.9753 6.41425 12.001 6.34 12H6.28C6.24202 12.0012 6.20426 11.9937 6.1696 11.9781C6.13495 11.9625 6.1043 11.9392 6.08 11.91C6.05412 11.8836 6.03373 11.8523 6.02 11.818C6.00627 11.7837 5.99947 11.747 6 11.71C6.10796 9.91579 6.88868 8.22831 8.18626 6.98449C9.48385 5.74066 11.2028 5.032 13 5V3.42C12.999 3.35292 13.0118 3.28636 13.0376 3.22443C13.0634 3.16251 13.1017 3.10654 13.15 3.06L13.35 2.86C13.3965 2.81314 13.4518 2.77594 13.5127 2.75055C13.5736 2.72517 13.639 2.7121 13.705 2.7121C13.771 2.7121 13.8364 2.72517 13.8973 2.75055C13.9582 2.77594 14.0135 2.81314 14.06 2.86L17.85 6.65C17.8978 6.69489 17.936 6.74911 17.962 6.80931C17.9881 6.8695 18.0015 6.9344 18.0015 7C18.0015 7.0656 17.9881 7.1305 17.962 7.19069C17.936 7.25089 17.8978 7.30511 17.85 7.35Z"
        fill={color}
      />
    </svg>
  );
};

export default ShareScreen;
