import { Box } from '@mui/material';
import { btnType } from '../../../../packages/ui/atoms/button';
import { colors } from '../../../../packages/colors';
import { Avatar, avatarType } from '../../../../packages/ui/atoms/avatar';
import { Badge, badgeSize } from '../../../../packages/ui/atoms/badge';
import dayjs from 'dayjs';

import { IService } from '../../../../redux/modules/appointment/types';
import BoxFrame from '../../../../components/v2/BoxFrame';
import Header from '../../../../components/v2/Header';
import { HeadingFont, HeadingLevel, HeadingWeight } from '../../../../components/v2/Heading/index.types';
import Typography from '../../../../components/v2/Typography';
import { typography } from '../../../../components/v2/Typography/index.constant';
import ServiceItem from '../services/service-item';

import { useStyles } from '../index.styles';

const Card = ({ title, onEdit, content }) => {
  const classes = useStyles();
  const { badges, affiliation, services } = content;

  return (
    <BoxFrame>
      <Box py={2.5} px={3} sx={{ '& button': { minWidth: 27 } }}>
        <Header
          label={title}
          fontFamily={HeadingFont.PRIMARY}
          fontVariant={HeadingLevel.SUB}
          fontWeight={HeadingWeight.SEMI_BOLD}
          actions={
            onEdit && [
              {
                children: <Typography {...typography.body.s.bold}>Edit</Typography>,
                variant: btnType.TEXT,
                onClick: onEdit,
              },
            ]
          }
        />
      </Box>
      <Box p={3} borderTop={`1px solid ${colors.neutral100}`}>
        {affiliation && (
          <Box display="flex" justifyContent="space-between" alignItems="center">
            {affiliation.title ? (
              <>
                <Box display="flex" gap="24px" alignItems="center">
                  <Avatar size={64} variant={avatarType.SQUARE} src={affiliation.avatar} />
                  <Box>
                    <Typography {...typography.body.m.bold}>{affiliation.title}</Typography>
                    <Typography
                      {...typography.body.m.medium}
                      sx={{
                        maxWidth: 200,
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {affiliation.description}
                    </Typography>
                  </Box>
                </Box>
                <Box display="flex" flexDirection="column" alignItems="flex-end">
                  <Typography {...typography.body.s.medium}>
                    {dayjs(affiliation.startDate).format('MMMM DD, YYYY -')}
                  </Typography>
                  <Typography {...typography.body.s.medium}>
                    {dayjs(affiliation.endDate).format('MMMM DD, YYYY')}
                  </Typography>
                </Box>
              </>
            ) : (
              <Typography {...typography.body.m.medium}>N/A</Typography>
            )}
          </Box>
        )}
        {badges && (
          <>
            {badges?.length > 0 ? (
              <Box display="flex" flexWrap="wrap" gap="5px">
                {badges.map((item, index) => (
                  <Badge size={badgeSize.SMALL} className={classes.stateBadge} key={index}>
                    <Typography {...typography.body.s.semibold} color={colors.neutral700}>
                      {item}
                    </Typography>
                  </Badge>
                ))}
              </Box>
            ) : (
              <Typography {...typography.body.m.medium}>N/A</Typography>
            )}
          </>
        )}
        {services && (
          <>
            {services.length > 0 ? (
              <Box display="flex" flexDirection="column" gap="32px">
                {services.map(item => (
                  <ServiceItem service={item} key={item.id} />
                ))}
              </Box>
            ) : (
              <Typography {...typography.body.m.medium}>N/A</Typography>
            )}
          </>
        )}
      </Box>
    </BoxFrame>
  );
};

export default Card;
