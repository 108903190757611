import { FC } from 'react';
import { Drawer, drawerType } from '../../../../../packages/ui/organisms/drawer';
import { Box } from '@mui/material';
import { Notes } from '../../../member-detail/components';

import { useStyles } from './NotesDrawer.style';

const NotesDrawer = ({ open, isNoDrawer, onClose, appointment, refetch }) => {
  const classes = useStyles();

  const renderContent = () => {
    return (
      <Box marginX="40px" marginY="10px" display="flex" flexDirection="column" gap="20px">
        <Notes memberID={appointment?.participantId} refetch={refetch} />
      </Box>
    );
  };

  if (isNoDrawer) {
    return renderContent();
  }

  return (
    <Drawer
      open={open}
      title="Notes"
      onClose={onClose}
      variant={drawerType.FORM}
      className={classes.drawer}
      footer={<div />}
    >
      {renderContent()}
    </Drawer>
  );
};

export { NotesDrawer };
