import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import clsx from 'clsx';
import debounce from 'debounce';

import {
  Avatar,
  avatarType,
  Badge,
  badgeType,
  colors,
  fontWeight,
  Icons,
  Input,
  inputType,
  Text,
  textLevel,
} from '../../../../../packages';
import { searchSchedule } from '../../../../../services/appointment/appointment.service';
import { stateCodes } from '../../../member-list/MemberList.constants';
import { useStyles } from '../create-scheduling-link/CreateSchedulingLink.styles';

const SelectMember = ({ selectedItem, setSelectedItem, formData }) => {
  const classes = useStyles();
  const [searchKey, setSearchKey] = useState('');
  const [providerList, setProviderList] = useState([]);
  const [filteredProviders, setFilteredProviders] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (searchKey) {
      const filteredList = providerList.filter(item =>
        item?.name.toLowerCase().includes(searchKey.toLowerCase()),
      );
      setFilteredProviders(filteredList);
    } else {
      setFilteredProviders(providerList);
    }
  }, [searchKey, providerList]);

  useEffect(() => {
    void fetchProviders();
  }, []);

  const onChangeSearchKey = debounce(newValue => {
    setSearchKey(newValue);
  }, 500);

  const fetchProviders = async () => {
    setIsLoading(true);
    const paramquery = {
      sortOrder: 'A_TO_Z',
      state: formData?.state ? [formData?.state] : [],
      payerId: formData?.insurance,
      serviceId: formData?.service,
      viewProviderDetails: true,
      viewInsurances: true,
      acceptingNewPatients: !formData?.member,
      size: 100,
    };
    try {
      const { data } = await searchSchedule(paramquery);
      const newProviderList = data?.results?.map(item => {
        return {
          id: item?.actor,
          name: item?.providerDetailsDto?.name,
          photo: item?.providerDetailsDto?.providerImage,
          designation: item?.providerDetailsDto?.designation,
          states: item?.providerDetailsDto?.states,
          acceptingNewPatients: item?.providerDetailsDto?.acceptingNewPatients,
        };
      });
      setProviderList(newProviderList);
      setIsLoading(false);
    } catch (e) {
      console.error(e);
      setIsLoading(false);
    }
  };

  const renderStates = states => {
    const abbreviatedStates = [];
    states.forEach(state => {
      if (state.includes(' ')) {
        const stateKey = Object.keys(stateCodes).find(
          key => key.toLowerCase().trim() === state.toLowerCase().trim(),
        );
        abbreviatedStates.push(stateCodes[stateKey]);
      } else {
        abbreviatedStates.push(stateCodes[state]);
      }
    });
    return abbreviatedStates.join(', ');
  };

  const renderProvider = item => {
    const { id, name, photo, designation, states, acceptingNewPatients } = item;
    return (
      <Box
        className={clsx(classes.userinfo, { [classes.selected]: selectedItem?.id === id })}
        key={id}
        onClick={() => setSelectedItem(item)}
        display="flex"
        gap="4px"
      >
        <Box sx={styles.profile}>
          <Box sx={styles.providerInfo}>
            <Avatar size={40} variant={avatarType.CIRCLE} src={photo} name={name} />
            <Box sx={styles.info}>
              <Text level={textLevel.S} weight={fontWeight.SEMI_BOLD} className={classes.valueText}>
                {name}
              </Text>
              <Badge
                className={clsx(classes.providerRole, designation?.split(' ').join(''))}
                variant={badgeType.FILLED}
              >
                {designation}
              </Badge>
            </Box>
          </Box>
        </Box>
        <Box sx={styles.listItemGroup}>
          <Box sx={styles.listItemContainer}>
            {states?.length > 0 ? (
              <Text level={textLevel.S} weight={fontWeight.MEDIUM}>
                {renderStates(states)}
              </Text>
            ) : (
              <Text level={textLevel.S} weight={fontWeight.BOLD} color={colors.destructive600}>
                Missing
              </Text>
            )}
          </Box>
          <Box sx={styles.listItemContainer}>
            {acceptingNewPatients ? (
              <Icons glyph="check-circle" color={colors.success} />
            ) : (
              <Icons glyph="x-circle-outlined" color={colors.destructive600} />
            )}
            <Text level={textLevel.S} weight={fontWeight.MEDIUM}>
              Accept new patients
            </Text>
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <Box>
      <Box padding="24px 24px 0px 24px">
        <Text level={textLevel.L} weight={fontWeight.BOLD}>
          Select provider
        </Text>
      </Box>
      <Box sx={styles.searchBox}>
        <Input
          className={classes.searchInput}
          variant={inputType.SEARCH}
          placeholder="Search"
          value={searchKey}
          onChange={value => {
            onChangeSearchKey(value);
          }}
        />
      </Box>
      <Box sx={{ padding: 3 }}>
        {isLoading ? (
          <Box sx={styles.loadingWrap}>
            <Icons className="rotate linear infinite" glyph="in-progress" color={colors.primary} />
            Loading...
          </Box>
        ) : filteredProviders?.length > 0 ? (
          filteredProviders?.map(renderProvider)
        ) : (
          <Text weight={fontWeight.MEDIUM} className={clsx(classes.nickName, classes.emptyList)}>
            There is no item
          </Text>
        )}
      </Box>
    </Box>
  );
};

const styles = {
  searchBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: 24,
    padding: '24px 24px 0px 24px',
  },
  loadingWrap: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&svg': {
      marginRight: 12,
    },
  },
  listItemGroup: {
    display: 'flex',
    alignItems: 'center',
    gap: '32px',
  },
  listItemContainer: {
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
    justifyContent: 'flex-end',
    minWidth: '162px',
  },
  profile: {
    '& #fullName': {
      display: 'inline-block',
      width: 140,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  providerInfo: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  },
  info: {
    display: 'flex',
    flexDirection: 'column',
    gap: 0,
  },
};

export default SelectMember;
