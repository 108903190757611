import { Box, Divider, Typography } from '@mui/material';
import { FC } from 'react';
import cx from 'clsx';

import { colors } from '../../../../../../packages/colors';
import { btnSize, btnType, Button } from '../../../../../../packages/ui/atoms/button';
import { Badge, badgeSize } from '../../../../../../packages/ui/atoms/badge';

import { CardStatus, CardType, cardLabels, cardTypeLabel } from './ProfileSectionCard.constant';

import { useStyles } from './ProfileSectionCard.styles';

const ProfileSectionCard = ({ data, onUpdate, onApprove, ...rest }) => {
  const classes = useStyles();

  const fileAvailable =
    rest?.status !== CardStatus.DOCUMENT_NEEDED &&
    rest?.status !== CardStatus.REVIEW_NEEDED &&
    rest?.file &&
    rest?.file !== 'Missing';

  const renderItem = (label, element) => (
    <Box display="flex" flexDirection="column" gap={rest?.[element] ? 0.5 : 1}>
      <Typography
        color={colors.neutral600}
        sx={{ fontWeight: 600, fontSize: 14, lineHeight: '20px', fontFamily: 'Manrope' }}
      >
        {label}
      </Typography>
      {rest?.[element] ? (
        element === 'file' ? (
          <Button
            variant={btnType.TEXT}
            className={classes.previewBtn}
            onClick={() => (fileAvailable ? window.open(rest?.file?.signedUrl, '_blank') : null)}
          >
            <Typography
              color={fileAvailable ? colors.primary600 : colors.destructive500}
              sx={{ fontWeight: 700, fontSize: 14, lineHeight: '20px', fontFamily: 'Manrope' }}
            >
              {fileAvailable ? 'Preview' : 'Missing'}
            </Typography>
          </Button>
        ) : element === 'states' ? (
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: 14,
              lineHeight: '20px',
              fontFamily: 'Manrope',
              color: rest?.discrepancies?.includes(element) ? colors.secondary500 : colors.neutral900,
              maxWidth: '240px',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {rest?.[element]?.length === 1 && rest?.[element]?.[0] !== '' ? (
              rest?.[element]?.[0]
            ) : rest?.[element]?.length > 1 ? (
              'Multistate'
            ) : (
              <Box sx={{ width: 120, height: 16, background: colors.destructive50, mt: 0.5 }} />
            )}
          </Typography>
        ) : (
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: 14,
              lineHeight: '20px',
              fontFamily: 'Manrope',
              color: rest?.discrepancies?.includes(element) ? colors.secondary500 : colors.neutral900,
              maxWidth: '240px',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {rest?.[element]}
          </Typography>
        )
      ) : (
        <Box sx={{ width: 120, height: 16, background: colors.destructive50 }} />
      )}
    </Box>
  );

  return (
    <div
      className={cx({
        [classes.root]: true,
        [classes.reviewCard]: rest?.status === CardStatus.REVIEW_NEEDED,
        [classes.documentCard]:
          rest?.status === CardStatus.DOCUMENT_NEEDED || rest?.status === CardStatus.DOCUMENT_ANALYSIS,
        [classes.discrepancyCard]: rest?.status === CardStatus.DISCREPANCY,
        [classes.expiredCard]: rest?.status === CardStatus.EXPIRED,
        [classes.expiringSoonCard]: rest?.status === CardStatus.EXPIRING_SOON,
        [classes.incompleteCard]:
          rest?.status === CardStatus.INCOMPLETE || rest?.status === CardStatus.FAILED,
      })}
    >
      <Box sx={{ mx: 3, mt: 3, mb: 2 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
              <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                <Typography
                  color={colors.neutral600}
                  sx={{ fontWeight: 600, fontSize: 14, lineHeight: '20px', fontFamily: 'Manrope' }}
                >
                  {cardTypeLabel[rest?.type]}
                </Typography>
                <Badge
                  size={badgeSize.XSMALL}
                  className={cx({
                    [classes.badge]: true,
                    [classes.verifiedBadge]: rest?.status === CardStatus.VERIFIED,
                    [classes.reviewBadge]: rest?.status === CardStatus.REVIEW_NEEDED,
                    [classes.documentBadge]:
                      rest?.status === CardStatus.DOCUMENT_NEEDED ||
                      rest?.status === CardStatus.DOCUMENT_ANALYSIS,
                    [classes.descrepancyBadge]: rest?.status === CardStatus.DISCREPANCY,
                    [classes.expiredBadge]: rest?.status === CardStatus.EXPIRED,
                    [classes.expiringSoonBadge]: rest?.status === CardStatus.EXPIRING_SOON,
                    [classes.incompleteBadge]:
                      rest?.status === CardStatus.INCOMPLETE || rest?.status === CardStatus.FAILED,
                  })}
                >
                  {cardLabels[rest?.status]}
                </Badge>
              </Box>
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: 18,
                  lineHeight: '28px',
                  fontFamily: 'Manrope',
                  wordBreak: 'break-word',
                  color: rest?.discrepancies?.includes('name') ? colors.secondary500 : colors.neutral900,
                }}
              >
                {rest?.name}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', gap: 2, minWidth: '200px', justifyContent: 'flex-end' }}>
            {rest?.status === CardStatus.INCOMPLETE ? (
              <Button
                variant={btnType.PRIMARY}
                size={btnSize.XSMALL}
                onClick={() => onUpdate(rest?.id)}
                className={classes.primaryBtn}
              >
                Add information
              </Button>
            ) : rest?.status === CardStatus.DOCUMENT_NEEDED || rest?.status === CardStatus.FAILED ? (
              <Button
                variant={btnType.OUTLINE}
                size={btnSize.XSMALL}
                onClick={() => onUpdate(rest?.id)}
                className={classes.outlineBtn}
              >
                Upload document
              </Button>
            ) : rest?.status !== CardStatus.DOCUMENT_ANALYSIS ? (
              <Button
                variant={btnType.OUTLINE}
                size={btnSize.XSMALL}
                onClick={() => onUpdate(rest?.id)}
                className={classes.outlineBtn}
              >
                Edit
              </Button>
            ) : null}
            {rest?.status === CardStatus.REVIEW_NEEDED && (
              <Button
                variant={btnType.PRIMARY}
                size={btnSize.XSMALL}
                onClick={() => onApprove(rest?.id)}
                className={classes.primaryBtn}
              >
                Approve
              </Button>
            )}
          </Box>
        </Box>
      </Box>
      <Divider sx={{ borderColor: colors.neutral100 }} />
      {rest?.type === CardType.BOARD_CERTIFICATION && (
        <>
          <Box sx={{ p: 3 }}>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: 14,
                lineHeight: '20px',
                fontFamily: 'Manrope',
                color: colors.neutral600,
              }}
            >
              Specialties
            </Typography>
            {rest?.specialties?.length > 0 ? (
              <Box display="flex" gap={2} mt={0.5}>
                {rest?.specialties.map((specialty, index) => (
                  <Typography
                    key={index}
                    sx={{
                      fontWeight: 500,
                      fontSize: 14,
                      lineHeight: '20px',
                      fontFamily: 'Manrope',
                      color: rest?.discrepancies?.includes('specialty')
                        ? colors.secondary500
                        : colors.neutral900,
                    }}
                  >
                    {specialty}
                  </Typography>
                ))}
              </Box>
            ) : (
              <Box sx={{ width: 120, height: 16, background: colors.destructive50, mt: 1 }} />
            )}
          </Box>
          <Divider sx={{ borderColor: colors.neutral100 }} />
        </>
      )}
      <Box>
        {rest?.status === CardStatus.DISCREPANCY && (
          <Box sx={{ ml: 3, mt: 2 }}>
            <Typography
              sx={{
                fontWeight: 800,
                fontSize: 12,
                lineHeight: '20px',
                letterSpacing: '1px',
                fontFamily: 'Manrope',
                color: colors.neutral500,
              }}
            >
              EXISTING INFORMATION
            </Typography>
          </Box>
        )}
        <Box className={classes.detailView}>
          {rest?.type !== CardType.EDUCATION && renderItem('Number', 'number')}
          {rest?.states && renderItem('State', 'states')}
          {rest?.type === CardType.EDUCATION && renderItem('Major', 'major')}
          {rest?.type === CardType.EDUCATION
            ? renderItem('Start date', 'issueDate')
            : renderItem('Issue date', 'issueDate')}
          {rest?.type === CardType.EDUCATION
            ? renderItem('End date', 'expiryDate')
            : renderItem('Expiry date', 'expiryDate')}
          {renderItem('File', 'file')}
        </Box>
      </Box>
      {(rest?.status === CardStatus.DISCREPANCY ||
        (rest?.status === CardStatus.FAILED && data?.issues?.length > 0)) && (
        <>
          <Divider sx={{ borderColor: colors.neutral100 }} />
          <Box sx={{ p: 3 }}>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: 14,
                lineHeight: '14px',
                fontFamily: 'Manrope',
                color: colors.neutral900,
              }}
            >
              {rest?.status === CardStatus.DISCREPANCY
                ? 'There’s an information discrepancy with the CAQH database. Update information to fix this issue.'
                : data?.issues[0]}
            </Typography>
          </Box>
        </>
      )}
    </div>
  );
};

export { ProfileSectionCard };
