// colors
import { colors } from '../../../../../packages';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  twoColumnsWrapper: {
    margin: 32,
  },
  noTextDecoration: {
    textDecoration: 'none',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '100%',
  },
  topSection: {
    backgroundColor: colors.white,
    marginBottom: '32px',
    paddingBottom: '32px',
  },
  statusBadge: {
    margin: '0 0 24px 64px',
  },
  iconWrapper: ({ status }) => ({
    height: 40,
    width: 40,
    borderRadius: 4,
    border: '1px solid',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center  ',
    backgroundColor: colors[`${status === 'normal' ? 'purple' : status}50`],
    borderColor: colors[`${status === 'normal' ? 'purple' : status}200`],
    '&.RELEASE_OF_INFORMATION': {
      backgroundColor: colors.indigo50,
      borderColor: colors.indigo200,
      '& svg path': {
        fill: colors.indigo500,
      },
    },
    '&.LAB_REQUEST': {
      backgroundColor: colors.green50,
      borderColor: colors.green200,
      '& svg path': {
        fill: colors.green500,
      },
    },
    '&.CONTACT': {
      backgroundColor: colors.rose50,
      borderColor: colors.rose200,
      '& svg path': {
        fill: colors.rose500,
      },
    },
    '&.GENERAL': {
      backgroundColor: colors.purple50,
      borderColor: colors.purple200,
      '& svg path': {
        fill: colors.purple500,
      },
    },
    '&.CAUTION': {
      backgroundColor: colors.warning50,
      borderColor: colors.warning200,
      '& svg path': {
        fill: colors.warning500,
      },
    },
    '&.PROHIBITIVE': {
      backgroundColor: colors.destructive50,
      borderColor: colors.destructive200,
      '& svg path': {
        fill: colors.destructive500,
      },
    },
    '&.PHARMACY_ASSISTANCE': {
      backgroundColor: colors.cyan50,
      borderColor: colors.cyan100,
      '& svg path': {
        fill: colors.cyan500,
      },
    },
    '&.REFERRAL_OUT': {
      backgroundColor: colors.orange50,
      borderColor: colors.orange200,
      '& svg path': {
        fill: colors.orange500,
      },
    },
    '&.PRIOR_AUTHORIZATION': {
      backgroundColor: colors.primary50,
      borderColor: colors.primary100,
      '& svg path': {
        fill: colors.primary500,
      },
    },
  }),
  status: {
    borderRadius: 100,
    minWidth: 'auto',
    fontSize: 14,
    height: 30,
  },
  drawer: {
    '& .MuiPaper-root': {
      maxWidth: '720px !important',
    },
    '& .drawerBody': {
      padding: 0,
    },
  },
  noteBottomCard: {
    borderBottom: `1px solid ${colors.neutral100}`,
  },
  noteTexts: {
    padding: 32,
  },
  noteTarget: {
    lineHeight: '24px',
    fontWeight: 600,
    margin: 0,
    marginBottom: 16,
  },
  downloadWrap: {
    borderTop: `1px solid ${colors.neutral100}`,
  },
  verticalBlock: {
    display: 'flex',
    flexDirection: 'column',
    gap: 24,
  },
  verticalBlockTopMargin: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 24,
    gap: 24,
  },
  valueLabelWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    gap: 24,
  },
  description: {
    wordBreak: 'break-word',
  },
});
