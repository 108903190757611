import { FC } from 'react';
import { Box } from '@mui/system';
import { Modal } from '@mui/material';
import { fontWeight, Heading, Text, textLevel } from '../../../../../packages/ui/atoms/typography';
import { Icons } from '../../../../../packages/icons';
import { colors } from '../../../../../packages/colors';
import { btnType, Button } from '../../../../../packages/ui/atoms/button';
import { useStyles } from '../evaluation-left-section/Evaluations.styles';

const LeaveUnansweredModal = ({ open, onClose, onConfirm, dct }) => {
  const classes = useStyles();
  return (
    <Modal open={open} onClose={onClose}>
      <Box className={classes.modal}>
        <Box className={classes.modalContent}>
          <Box className={classes.content}>
            <Box>
              <Heading className={classes.modalTitle}>
                {dct ? 'Answers are required.' : 'Answer is required.'}
                <br />
                Leave {dct ? 'them' : 'it'} unanswered?
              </Heading>
              <Text level={textLevel.M} weight={fontWeight.MEDIUM} color={colors.neutral600}>
                Answers are typically required because they have significant clinical importance.
              </Text>
              <Icons glyph="close" onClick={onClose} className={classes.closeBtn} color={colors.neutral400} />
            </Box>
            <Box className={classes.modalButtons}>
              <Button onClick={onClose} className={classes.modalCloseButton}>
                Cancel
              </Button>
              <Button variant={btnType.PRIMARY} onClick={onConfirm} className={classes.modalButton}>
                Leave unanswered
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
export { LeaveUnansweredModal };
