import React, { FC, useState } from 'react';
import cx from 'clsx';
import { ProfileInfo } from '../../../../../packages/ui/templates/profile-info';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Collapse from '@mui/material/Collapse';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Badge, badgeSize, badgeStyle, badgeType } from '../../../../../packages/ui/atoms/badge';
import { colors } from '../../../../../packages/colors';
import { Icons } from '../../../../../packages/icons';
import { Table } from '../../../../../packages/ui/organisms/table';
import { tableFilterType } from '../../../../../packages/ui/organisms/table/filter';
import { Menu } from '../../../../../packages/ui/molecules/menu';
import { theme } from '../../../../../packages/theme';
import dayjs from 'dayjs';
import BoxFrame from '../../../../../components/v2/BoxFrame';
import Heading from '../../../../../components/v2/Heading';
import TodoItem from '../TodoItem/TodoItem';
import TodoItemAppt from '../TodoItem/TodoItemAppt';
import Typography from '../../../../../components/v2/Typography';
import { typography } from '../../../../../components/v2/Typography/index.constant';
import { HeadingLevel, HeadingWeight } from '../../../../../components/v2/Heading/index.types';
import { TodoVariant } from './TodoList.constants';
import { useStyles } from './TodoList.styles';
import history from '../../../../../utils/history';
import { defaultAppointmentFilterOptions } from '../../../appointments/AppointmentList.constants';

const TodoList = ({
  open,
  heading,
  count,
  items,
  onItemClicked,
  onItemChecked,
  onItemUnchecked,
  setValue,
  value,
  preventEmptyView,
  isLoading,
  emptyTodosText,
  emptyTodosHeading,
  emptyTodosIcon,
  isCompleted,
  todoType,
  variant = TodoVariant.PRIMARY,
}) => {
  const [collapsed, setCollapsed] = useState(open || false);
  const classes = useStyles({ collapsed });
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };
  const columns = [
    { id: 'text', label: variant === TodoVariant.PRIMARY ? 'Text' : 'Appointment type', sortable: false },
    { id: 'createdDate', label: 'Date', sortable: false },
    { id: 'provider', label: variant === TodoVariant.PRIMARY ? 'Provider' : 'Member', sortable: false },
    { id: 'status', label: 'Status', sortable: false },
    { id: 'actions', label: '', sortable: false },
  ];

  const renderColumns = columns.map(column => {
    if (column.id === 'text') {
      return {
        ...column,
        renderCell: text => (
          <Box sx={{ maxWidth: '300px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
            <Typography {...typography.body.normal.small.medium}>{text}</Typography>
          </Box>
        ),
      };
    }
    if (column.id === 'provider') {
      return {
        ...column,
        renderCell: provider => {
          return (
            <>
              {variant === TodoVariant.PRIMARY ? (
                <ProfileInfo
                  type="provider"
                  photo={provider?.photo}
                  fullName={provider?.name}
                  role={provider?.designation}
                />
              ) : (
                <ProfileInfo
                  type="member"
                  photo={provider?.photo}
                  fullName={provider?.patientUuid}
                  nickName={provider?.name}
                  profilePhotoName={provider?.name}
                  memberId={provider?.profileId}
                  isProvider
                  showProfilePhotoName
                />
              )}
            </>
          );
        },
      };
    }
    if (column.id === 'status') {
      return {
        ...column,
        renderCell: () => (
          <Badge style={badgeStyle.SUCCESS} variant={badgeType.OUTLINED} size={badgeSize.SMALL}>
            {variant === TodoVariant.PRIMARY ? 'Marked as completed' : 'Completed'}
          </Badge>
        ),
      };
    }
    if (column.id === 'createdDate') {
      return {
        ...column,
        renderCell: createdDate => {
          const date = dayjs(createdDate, 'MM/DD/YYYY - h:mm A').format('MM/DD/YYYY');
          const time = dayjs(createdDate, 'MM/DD/YYYY - h:mm A').format('h:mm A');
          return (
            <>
              {variant === TodoVariant.PRIMARY ? (
                <Typography {...typography.body.normal.small.semibold}>{createdDate}</Typography>
              ) : (
                <Box className={classes.date}>
                  <Typography {...typography.body.normal.small.semibold}>{date}</Typography>
                  <Typography {...typography.body.normal.small.medium} color={colors.neutral600}>
                    {time}
                  </Typography>
                </Box>
              )}
            </>
          );
        },
      };
    }
    if (column.id === 'actions') {
      return {
        ...column,
        renderCell: actions => (
          <>
            {variant === TodoVariant.PRIMARY ? (
              <Menu
                icon="more"
                className={classes.menu}
                itemsWrapperClassName={classes.menuItemsWrapper}
                disabled={false}
                items={[
                  {
                    label: 'View details',
                    onClick: () => {
                      onItemClicked(actions.itemId);
                    },
                  },
                  {
                    label: 'Mark as incomplete',
                    onClick: () => {
                      onItemUnchecked(actions.itemId);
                    },
                  },
                ]}
              />
            ) : (
              <Menu
                icon="more"
                className={classes.menu}
                itemsWrapperClassName={classes.menuItemsWrapper}
                disabled={false}
                items={[
                  {
                    label: 'View details',
                    onClick: () => {
                      history.push(
                        `/provider/members/${actions?.participantId}/appointments/${actions?.appointmentId}`,
                      );
                    },
                  },
                ]}
              />
            )}
          </>
        ),
      };
    }
    return column;
  });

  const data = items.map(item => ({
    text: item.text,
    provider: {
      name: item.assignedByFullName,
      photo: item.assignedByPhoto,
      designation: item.designation,
      profileId: item.profileId,
      patientUuid: item.patientUuid,
    },
    createdDate: item.date,
    actions: {
      appointmentId: item.appointmentId,
      participantId: item.participantId,
      itemId: item.id,
    },
  }));
  return (
    <BoxFrame>
      <Box className={cx(classes.header, 'collapsableHeader')} onClick={toggleCollapsed}>
        <Box display="flex" alignItems="center" my={3} ml={1.25} mr={3}>
          <Icons
            className={cx(classes.chevronIcon, 'collapsableIcon')}
            glyph="chevron-down"
            color={colors.neutral400}
          />
          <Heading variant={HeadingLevel.SUB} fontWeight={HeadingWeight.SEMI_BOLD}>
            {heading}
          </Heading>
        </Box>
        {!isTablet && (
          <Box display="flex" gap={2}>
            <Badge
              style={badgeStyle.INACTIVE}
              variant={badgeType.OUTLINED}
              size={badgeSize.SMALL}
              className={classes.badge}
            >
              {count} total
            </Badge>
          </Box>
        )}
      </Box>
      <Divider />
      <Collapse in={collapsed}>
        {(items && items.length > 0) || preventEmptyView ? (
          <Box className={classes.mainParentBody}>
            {variant === TodoVariant.PRIMARY ? (
              <>
                {!isCompleted &&
                  items.map((todoItem, index) => (
                    <React.Fragment key={todoItem.id}>
                      <TodoItem
                        id={todoItem.id}
                        text={todoItem.text}
                        dueDate={todoItem.dueDate}
                        assignedByPhoto={todoItem.assignedByPhoto}
                        assignedByFullName={todoItem.assignedByFullName}
                        assignedByProfilePhotoText={todoItem.assignedByProfilePhotoText}
                        sourcePhoto={todoItem.sourcePhoto}
                        sourceFullName={todoItem.sourceFullName}
                        patientUuid={todoItem.patientUuid}
                        sourceSubtitle={todoItem.sourceSubtitle}
                        todoType={todoType}
                        designation={todoItem.designation}
                        subtext={todoItem.subtext}
                        onItemChecked={onItemChecked}
                        onItemClicked={onItemClicked}
                        onItemUnchecked={onItemUnchecked}
                        checked={todoItem.checked}
                      />
                      {index < items.length - 1 && !isTablet && <Divider className={classes.bodyDivider} />}
                    </React.Fragment>
                  ))}
                {isCompleted && (
                  <>
                    {isTablet ? (
                      items.map((todoItem, index) => (
                        <React.Fragment key={todoItem.id}>
                          <TodoItem
                            id={todoItem.id}
                            text={todoItem.text}
                            dueDate="Marked as complete"
                            assignedByPhoto={todoItem.assignedByPhoto}
                            assignedByProfilePhotoText={todoItem.assignedByProfilePhotoText}
                            onItemChecked={onItemChecked}
                            onItemClicked={onItemClicked}
                            onItemUnchecked={onItemUnchecked}
                            checked={todoItem.checked}
                          />
                          {index < items.length - 1 && !isTablet && (
                            <Divider className={classes.bodyDivider} />
                          )}
                        </React.Fragment>
                      ))
                    ) : (
                      <Table
                        // searchProps={{
                        //   placeholder: 'Search',
                        //   filterProps: {
                        //     variant: Filter.tableFilterType.SINGLE,
                        //   },
                        // }}
                        gridProps={{
                          columns: renderColumns,
                          data,
                          isLoading,
                          // onRowClick: onClickNote,
                        }}
                        paginationProps={{
                          currentRows: 10,
                          totalCount: count,
                          showRowsPerPage: false,
                        }}
                        value={value}
                        onChange={setValue}
                        className={classes.table}
                      />
                    )}
                  </>
                )}
              </>
            ) : (
              <>
                {!isCompleted &&
                  items.map((todoItem, index) => (
                    <React.Fragment key={todoItem.appointmentId}>
                      <TodoItemAppt
                        id={todoItem.appointmentId}
                        text={todoItem.text}
                        date={todoItem.date}
                        dueDate={todoItem.dueDate}
                        sourcePhoto={todoItem.assignedByPhoto}
                        sourceProfilePhotoText={todoItem.assignedByProfilePhotoText}
                        sourceFullName={todoItem.assignedByFullName}
                        patientUuid={todoItem.patientUuid}
                        onItemChecked={onItemChecked}
                        onItemClicked={onItemClicked}
                        onItemUnchecked={onItemUnchecked}
                        checked={todoItem.checked}
                        waitingForAI={todoItem.waitingForAI}
                      />
                      {index < items.length - 1 && <Divider className={classes.bodyDivider} />}
                    </React.Fragment>
                  ))}
                {isCompleted && (
                  <>
                    {isTablet ? (
                      items.map((todoItem, index) => (
                        <React.Fragment key={todoItem.id}>
                          <TodoItemAppt
                            id={todoItem.appointmentId}
                            text={todoItem.text}
                            date={todoItem.date}
                            dueDate="Completed"
                            sourcePhoto={todoItem.assignedByPhoto}
                            sourceProfilePhotoText={todoItem.assignedByProfilePhotoText}
                            sourceFullName={todoItem.assignedByFullName}
                            patientUuid={todoItem.patientUuid}
                            onItemChecked={onItemChecked}
                            onItemClicked={onItemClicked}
                            onItemUnchecked={onItemUnchecked}
                            checked={todoItem.checked}
                          />
                          {index < items.length - 1 && !isTablet && (
                            <Divider className={classes.bodyDivider} />
                          )}
                        </React.Fragment>
                      ))
                    ) : (
                      <Table
                        searchProps={{
                          placeholder: 'Search',
                          filterProps: {
                            variant: tableFilterType.MULTIPLE,
                            multiSelectOptions: defaultAppointmentFilterOptions,
                          },
                        }}
                        gridProps={{
                          columns: renderColumns,
                          data,
                          isLoading,
                          // onRowClick: onClickNote,
                        }}
                        paginationProps={{
                          currentRows: 10,
                          totalCount: count,
                          showRowsPerPage: false,
                        }}
                        value={value}
                        onChange={setValue}
                        className={classes.table}
                      />
                    )}
                  </>
                )}
              </>
            )}
          </Box>
        ) : (
          <Box className={classes.emptyTodos}>
            <Icons className={classes.icons} glyph={emptyTodosIcon} />
            <Box className={classes.textBox}>
              <Heading variant={HeadingLevel.SUB} fontWeight={HeadingWeight.BOLD}>
                {emptyTodosHeading}
              </Heading>
              <Typography {...typography.body.m.semibold} color={colors.neutral600}>
                {emptyTodosText}
              </Typography>
            </Box>
          </Box>
        )}
      </Collapse>
    </BoxFrame>
  );
};
export default TodoList;
