/* eslint-disable max-len */
import cx from 'clsx';

// types
// styles
import { useStyles } from './shared/styles';

const CaretRight = ({ className, color, onClick }) => {
  const classes = useStyles();
  return (
    <svg
      className={cx({
        [classes.root]: true,
        [className || '']: className,
      })}
      width="6"
      height="10"
      viewBox="0 0 6 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M1.05999 9.14C1.01351 9.18686 0.958208 9.22406 0.897279 9.24944C0.836349 9.27483 0.770996 9.2879 0.70499 9.2879C0.638984 9.2879 0.573632 9.27483 0.512702 9.24944C0.451772 9.22406 0.396472 9.18686 0.349991 9.14L0.14999 8.94C0.101676 8.89345 0.0634331 8.83749 0.0376311 8.77556C0.0118291 8.71364 -0.000982226 8.64707 -9.51882e-06 8.58V1.42C-0.000982226 1.35292 0.0118291 1.28635 0.0376311 1.22443C0.0634331 1.16251 0.101676 1.10654 0.14999 1.06L0.349991 0.859996C0.396472 0.813132 0.451772 0.775935 0.512702 0.75055C0.573632 0.725166 0.638984 0.712097 0.70499 0.712097C0.770996 0.712097 0.836349 0.725166 0.897279 0.75055C0.958208 0.775935 1.01351 0.813132 1.05999 0.859996L4.84999 4.65C4.89782 4.69489 4.93594 4.7491 4.962 4.8093C4.98806 4.8695 5.00151 4.9344 5.00151 5C5.00151 5.06559 4.98806 5.13049 4.962 5.19069C4.93594 5.25089 4.89782 5.30511 4.84999 5.35L1.05999 9.14Z"
        fill={color}
      />
    </svg>
  );
};

export default CaretRight;
