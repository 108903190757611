import Box from '@mui/material/Box';

import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import cx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';

import { Icons } from '../../../../packages/icons';
import { colors } from '../../../../packages/colors';
import { IconButton, iconBtnPosition, iconBtnType } from '../../../../packages/ui/molecules/icon-button';
import { RadioGroup, radioGroupType } from '../../../../packages/ui/molecules/radio-group';
import { Badge, badgeType } from '../../../../packages/ui/atoms/badge';
import { ProgressBar } from '../../../../packages/ui/atoms/ProgressBar';

import { getAppointmentEvaluationProgress } from '../../../../services/conversation/conversation.service';
import { conversationActionCreators } from '../../../../redux/modules/conversation';
import history from '../../../../utils/history';
import EvaluationDetail from '../../priorities/appointments/evaluation-detail';
import { Toggle } from '../../../../packages/ui/atoms/toggle';
import * as ConversationService from '../../../../services/conversation/conversation.service';
import { selectEvaluation } from '../../../../redux/modules/conversation/selectors';
import { EVALUATION_STATUS, evaluationStatusStyle, radioOptions } from './SessionSignOffV2.constant';
import { useStyles } from './SessionSignOffV2.styles';
import { ProvideEvaluationHead } from '../../../../hooks/useEvaluationHead';
import AfterAppointment from './after-appointment/AfterAppointment';
import { SkipReviewModal } from './SkipReviewModal';
import AddEvaluation from '../../../admin/appointments/appointment-detail-drawer/AddEvaluation';

const Evaluations = ({
  onNext,
  preFill,
  onSetTemplate,
  // startEvaluationReview,
  inEvaluationReview,
  memberId,
  diagnoses,
  isNextBtnClicked,
  setIsNextBtnClicked,
}) => {
  const classes = useStyles();
  const { appointmentId } = useParams();
  const dispatch = useDispatch();
  const allEvaluations = useSelector(selectEvaluation);

  const [isAdding, setIsAdding] = useState(preFill?.isAdding || 'no');
  const [isCompleting, setIsCompleting] = useState(preFill?.isCompleting || 'no');
  const [loading, setLoading] = useState(false);
  const [evaluations, setEvaluations] = useState([]);
  const [selectedEvaluation, setSelectedEvaluation] = useState(null);
  const [showSkipReviewModal, setShowSkipReviewModal] = useState(false);
  const [openAddEvaluation, setOpenAddEvaluation] = useState(false);
  const [isChecked, setIsChecked] = useState([]);
  const [hasRun, setHasRun] = useState(false);

  const getEvaluationProgressList = () => {
    setLoading(true);
    getAppointmentEvaluationProgress({ appointmentId })
      .then(evaluationProgressDetailList => {
        if (evaluationProgressDetailList?.status !== 200) {
          throw new Error('Whoops ! Something went wrong . Please try later');
        } else {
          const responseList = evaluationProgressDetailList?.data || [];
          setEvaluations(responseList);
          const isNotificationEnabled = responseList.filter(item => item.notifyForAICompletion);
          if (isNotificationEnabled.length !== 0) {
            const enabledNotification = isNotificationEnabled.map(item => item.id);
            setIsChecked(enabledNotification);
          }
        }
      })
      .catch(error => {
        console.log('Whoops ! Something went wrong . Please try later', error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    dispatch(conversationActionCreators.fetchEvaluations());
    getEvaluationProgressList();
  }, []);

  const isDisabled = useMemo(() => {
    return evaluations.length === 0
      ? isAdding === 'no'
      : evaluations.some(x => x.status === 'NOT_STARTED' || x.status === 'IN_PROGRESS')
      ? isCompleting === 'no'
      : false;
  }, [evaluations, isAdding, isCompleting]);

  useEffect(() => {
    const handleKeyPress = event => {
      if (event.key === 'Enter' && !isDisabled) {
        setIsNextBtnClicked(true);
      }
    };
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [isDisabled]);

  useEffect(() => {
    if (isNextBtnClicked && diagnoses && !hasRun) {
      setTimeout(() => {
        onNextClick();
        setIsNextBtnClicked(false);
      }, 3000);
      setHasRun(true);
    }
  }, [hasRun, isNextBtnClicked, diagnoses?.relatedElements]);

  const onSelectAdding = e => {
    if (e.target.value === 'yes') {
      onSetTemplate('yes');
    } else {
      onSetTemplate('no');
    }
    setIsAdding(e.target.value);
  };

  const onSelectCompleting = e => {
    setIsCompleting(e.target.value);
  };

  const onNextClick = () => {
    const aiCompletionExists = evaluations.some(
      e => e.status === 'NEEDS_REVIEW' || e.aiCompletionStatus === 'IN_PROGRESS',
    );
    if (aiCompletionExists) {
      setShowSkipReviewModal(true);
    } else {
      const completedEvals = evaluations?.filter(item => item.status === 'COMPLETED');
      onNext({
        evaluations: {
          icon: 'evaluation',
          title: 'Evaluations',
          description: 'completed',
          number: completedEvals.length,
          isAdding,
          isCompleting,
        },
      });
    }
  };

  const notifyAiCompletion = async (id, notifyForAICompletionFlag) => {
    try {
      const { data } = await ConversationService.getEvaluationContext({ appointmentId, evaluationId: id });
      if (data?.contextId) {
        const response = await ConversationService.notifyForAiCompletion({
          contextId: data?.contextId,
          notifyForAICompletionFlag,
        });
        if (response.status === 200) getEvaluationProgressList();
      }
    } catch (e) {
      console.warn(e);
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" flex={1}>
        <Icons className="rotate linear infinite" glyph="in-progress" color={colors.primary} />
      </Box>
    );
  }

  if (inEvaluationReview) {
    return (
      <ProvideEvaluationHead>
        <AfterAppointment evaluation={selectedEvaluation} />
      </ProvideEvaluationHead>
    );
  }

  const onAddEvaluation = selectedValues => {
    dispatch(
      conversationActionCreators.addAssignedEvaluation({
        evaluationIds: selectedValues,
        appointmentId,
      }),
    );
    setOpenAddEvaluation(false);
    setTimeout(getEvaluationProgressList, 2000);
  };

  return (
    <>
      <SkipReviewModal
        open={showSkipReviewModal}
        onClose={() => setShowSkipReviewModal(false)}
        onContinue={() => {
          setShowSkipReviewModal(false);
          onNext({
            evaluations: {
              icon: 'evaluation',
              title: 'Evaluations',
              description: 'completed',
              number: evaluations.length,
              isAdding,
              isCompleting,
            },
          });
        }}
      />
      <Box className={classes.wrapper}>
        <Box className={classes.contentWrapper}>
          <Box className={classes.contentHeader} justifyContent="space-between">
            <Box display="flex" alignItems="center" gap="16px">
              <Icons glyph="evaluation" color={colors.neutral700} />
              <Box className={classes.contentHeaderTitle}>Review evaluations</Box>
            </Box>
            <Box>
              <IconButton
                icon="plus"
                /* onClick={() =>
                  history.push(`/provider/appointments/current/${appointmentId}/session-signoff`, {
                    sessionType: 'evaluation',
                  })
                } */
                onClick={() => setOpenAddEvaluation(true)}
              >
                Add new
              </IconButton>
            </Box>
          </Box>
          <Box className={classes.content}>
            {evaluations?.length > 0 ? (
              evaluations.map((evaluation, index) => {
                const aiInProgress = evaluation?.aiCompletionStatus === 'IN_PROGRESS';
                const needsReview = evaluation?.status === 'NEEDS_REVIEW';
                const inProgress = evaluation?.status === 'IN_PROGRESS';
                return (
                  <Box className={classes.evaluation} key={index}>
                    <Box
                      width="100%"
                      display="flex"
                      flexDirection="column"
                      gap={aiInProgress ? '16px' : '0px'}
                    >
                      <Box
                        width="100%"
                        display="flex"
                        justifyContent="space-between"
                        onClick={() => {
                          if (evaluation?.status !== 'REJECTED') {
                            // if (aiInProgress) {
                            //   setSelectedEvaluation(evaluation);
                            // } else {
                            history.push(
                              `/provider/appointments/current/${appointmentId}/session-signoff/v2/${evaluation.id}`,
                              {
                                sessionType: 'evaluation',
                                evaluationItem: evaluation,
                                selectedEvaluationId: evaluation.id,
                                needsReview,
                              },
                            );
                            // }
                          }
                        }}
                      >
                        <Box className={classes.evaluationContent}>
                          <Box className="title">{evaluation?.name}</Box>
                          <Box className="subTitle">{evaluation?.description}</Box>
                        </Box>
                        <Box display="flex" gap={3} alignItems="center">
                          {evaluation?.progress > 0 &&
                            evaluation?.status === 'IN_PROGRESS' &&
                            !aiInProgress && (
                              <ProgressBar value={evaluation?.progress} className={classes.progressBar} />
                            )}
                          <Badge
                            variant={badgeType.OUTLINED}
                            style={
                              aiInProgress
                                ? evaluationStatusStyle.AI_IN_PROGRESS
                                : needsReview
                                ? evaluationStatusStyle.NEEDS_REVIEW
                                : evaluationStatusStyle[evaluation?.status]
                            }
                            size={28}
                            className={cx(classes.badge, { [classes.badgeColor]: inProgress })}
                          >
                            <Box
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                              flex={1}
                              gap="4px"
                            >
                              {/* {aiInProgress && (
                                <Icons
                                  className="rotate linear infinite"
                                  glyph="in-progress"
                                  color={colors.primary500}
                                />
                              )} */}
                              {aiInProgress
                                ? 'Waiting for AI'
                                : needsReview
                                ? EVALUATION_STATUS.NEEDS_REVIEW
                                : inProgress
                                ? `${evaluation?.progress}%`
                                : EVALUATION_STATUS[evaluation?.status]}
                            </Box>
                          </Badge>
                        </Box>
                      </Box>
                      {aiInProgress && (
                        <Box>
                          <Box>
                            <Toggle
                              checked={isChecked.includes(evaluation.id)}
                              onChange={() => {
                                if (!isChecked.includes(evaluation.id)) {
                                  setIsChecked([...isChecked, evaluation.id]);
                                  void notifyAiCompletion(evaluation?.id, true);
                                } else {
                                  const newArray = isChecked.filter(value => value !== evaluation.id);
                                  setIsChecked([...newArray]);
                                  void notifyAiCompletion(evaluation?.id, false);
                                }
                              }}
                            >
                              <span className={classes.toggle}>Notify me when AI review is completed</span>
                            </Toggle>
                          </Box>
                        </Box>
                      )}
                    </Box>
                  </Box>
                );
              })
            ) : (
              <Box className={classes.empty}>
                <Box className="title">Evaluation Needed</Box>
                <Box className="content">
                  Evaluations ensure a comprehensive record of clinical interventions. Complete the
                  appropriate evaluations to effectively document the content of this session.
                </Box>
                <IconButton
                  icon="plus"
                  variant={iconBtnType.OUTLINE}
                  /* onClick={() =>
                    history.push(`/provider/appointments/current/${appointmentId}/session-signoff`, {
                      sessionType: 'evaluation',
                    })
                  } */
                  onClick={() => setOpenAddEvaluation(true)}
                >
                  New evaluation
                </IconButton>
              </Box>
            )}
          </Box>
          {evaluations.length === 0 ? (
            <>
              <Box className={classes.question}>Do you want to continue without adding evaluations?</Box>
              <RadioGroup
                name="radio-buttons-group"
                options={radioOptions}
                value={isAdding}
                onChange={onSelectAdding}
                variant={radioGroupType.HORIZONTAL}
              />
            </>
          ) : evaluations.some(x => x.status === 'NOT_STARTED' || x.status === 'IN_PROGRESS') ? (
            <>
              <Box className={classes.question}>Do you want to continue without completing evaluations?</Box>
              <RadioGroup
                name="radio-buttons-group"
                options={radioOptions}
                value={isCompleting}
                onChange={onSelectCompleting}
                variant={radioGroupType.HORIZONTAL}
              />
            </>
          ) : (
            <></>
          )}
        </Box>
      </Box>
      <Box className={cx(classes.footer, classes.single)}>
        <IconButton
          icon="chevron-right"
          iconPosition={iconBtnPosition.RIGHT}
          className={classes.nextBtn}
          onClick={() => {
            setIsNextBtnClicked(true);
          }}
          disabled={isDisabled || isNextBtnClicked}
        >
          {isNextBtnClicked ? 'Please wait...' : 'Next'}
        </IconButton>
      </Box>
      {selectedEvaluation && (
        <EvaluationDetail
          fullScreen
          memberId={memberId}
          isOpen={!!selectedEvaluation}
          onClose={() => setSelectedEvaluation(null)}
          evaluation={selectedEvaluation}
          appointmentId={appointmentId}
          isView={false}
        />
      )}
      {openAddEvaluation && (
        <AddEvaluation
          isOpen={openAddEvaluation}
          onClose={() => setOpenAddEvaluation(false)}
          evaluations={allEvaluations}
          assignedEvaluations={evaluations}
          onAddEvaluation={onAddEvaluation}
        />
      )}
    </>
  );
};

export default Evaluations;
