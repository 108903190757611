import { FILTER_COLLAPSABLE_SECTION_TYPE } from '../../../packages/ui/organisms/table/filter';

export const tableColumns = [
  { id: 'name', label: 'Name', sortable: true },
  { id: 'planToken', label: 'Plan Token', sortable: true },
  { id: 'type', label: 'Type', sortable: true },
  { id: 'navigatesTo', label: 'Navigates to', sortable: false },
  { id: 'progressState', label: 'Progress state', sortable: false },
  { id: 'completedState', label: 'Completed state', sortable: false },
  { id: 'removedByMember', label: 'Removed by member', sortable: false },
  { id: 'completeXTimes', label: 'Complete X times', sortable: false },
  { id: 'actions', label: '', sortable: false },
];

export const filterOptionsMock = [{ label: 'Type 1', value: 'type_01' }];

export const planItemFilterOptionsMock = [
  {
    sectionTitle: 'PlanType',
    sectionType: FILTER_COLLAPSABLE_SECTION_TYPE.DEFAULT,
    sectionOptions: [
      { label: 'Education', value: 'Education' },
      { label: 'Topic', value: 'Topic' },
      { label: 'Activity', value: 'Activity' },
      { label: 'Group', value: 'Group' },
      { label: 'Conversation', value: 'Conversation' },
      { label: 'Provider', value: 'Provider' },
      { label: 'Provider Type', value: 'Provider Type' },
      { label: 'Service', value: 'Service' },
    ],
  },
];
