/* eslint-disable max-len */
// types

// styles
import { useStyles } from './shared/styles';

const CircleInsuranceOnly = ({ className, color, onClick }) => {
  const classes = useStyles();
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="8" cy="8" r="7.5" fill="#E8F4FF" stroke="#95CCFF" />
    </svg>
  );
};

export default CircleInsuranceOnly;
