export const Sections = {
  WELCOME: 'welcome',
  EVALUATIONS: 'evaluations',
  OPEN_SESSION: 'open-session',
  CLOSE_SESSION: 'close-session',
};

export const subsections = {
  IMPORTANT_INFORMATION: 'important-information',
  DOUBLE_CHECK_IDS: 'double-check-ids',
};

export const subsectionsWithoutId = {
  IMPORTANT_INFORMATION: 'important-information',
  DOUBLE_CHECK_IDS: 'double-check-ids',
};

export const MEMBER_ACTIONS = {
  PROFILE: 'profile',
  AMA: 'ama',
  SCHEDULE: 'schedule',
  CHAT: 'chat',
};
