/* eslint-disable max-len */
import cx from 'clsx';

// types
// styles
import { useStyles } from './shared/styles';

const UnlockWhite = ({ className, color, onClick }) => {
  const classes = useStyles();
  return (
    <svg
      className={cx({
        [classes.root]: true,
        [className || '']: className,
      })}
      width="16"
      height="18"
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M3.83333 7.33333H14.6667C14.8877 7.33333 15.0996 7.42112 15.2559 7.5774C15.4122 7.73369 15.5 7.94565 15.5 8.16666V16.5C15.5 16.721 15.4122 16.933 15.2559 17.0892C15.0996 17.2455 14.8877 17.3333 14.6667 17.3333H1.33333C1.11232 17.3333 0.900358 17.2455 0.744078 17.0892C0.587797 16.933 0.5 16.721 0.5 16.5V8.16666C0.5 7.94565 0.587797 7.73369 0.744078 7.5774C0.900358 7.42112 1.11232 7.33333 1.33333 7.33333H2.16667V6.49999C2.16619 5.18558 2.60965 3.90953 3.42516 2.87869C4.24067 1.84785 5.38042 1.12264 6.65967 0.820629C7.93891 0.518614 9.28267 0.657493 10.4731 1.21475C11.6635 1.77201 12.6309 2.71499 13.2183 3.89083L11.7275 4.63583C11.3079 3.79584 10.6169 3.1222 9.76652 2.72412C8.91613 2.32603 7.95622 2.22685 7.0424 2.44264C6.12858 2.65843 5.31444 3.17655 4.73196 3.913C4.14949 4.64945 3.83283 5.56104 3.83333 6.49999V7.33333ZM6.33333 11.5V13.1667H9.66667V11.5H6.33333Z"
        fill="white"
      />
    </svg>
  );
};

export default UnlockWhite;
