import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import ButtonGroup from '@mui/material/ButtonGroup';
import Stack from '@mui/material/Stack';
import clsx from 'clsx';
import { Heading, headingLevel, fontWeight } from '../../../../../packages/ui/atoms/typography';
import { badgeType, Badge, badgeStyle } from '../../../../../packages/ui/atoms/badge';
import { Button } from '../../../../../packages/ui/atoms/button';
import { iconBtnType, IconButton } from '../../../../../packages/ui/molecules/icon-button';
import { Icons } from '../../../../../packages/icons';
import { colors } from '../../../../../packages/colors';
import { reportingActionCreators } from '../../../../../redux/modules/reporting';
import { selectInvoiceList } from '../../../../../redux/modules/reporting/selectors';
import { viewGroups } from './invoices.mock';
import { useStyles } from './index.styles';
import InvoiceItem from './InvoiceItem';

const Invoices = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [viewBy, setViewBy] = useState(viewGroups[0].type);

  const { isLoading, invoicesList = [], totalRecords } = useSelector(selectInvoiceList);

  useEffect(() => {
    dispatch(
      reportingActionCreators.fetchInvoices({
        pageSize: 100,
        pageNumber: 1,
        sortBy: 'createdAt',
        sortOrder: 'desc',
      }),
    );
  }, []);

  const onChangeView = type => () => {
    setViewBy(type);
  };

  return (
    <>
      <Stack direction="row" justifyContent="space-between" spacing={2}>
        <Stack direction="row" alignItems="center" gap={2}>
          <Heading className={classes.heading} level={headingLevel.XL} weight={fontWeight.BOLD}>
            Invoices
          </Heading>
          <Badge
            className={classes.totalMemberBadge}
            variant={badgeType.OUTLINED}
            style={badgeStyle.UNRELATED}
          >
            {totalRecords} total
          </Badge>
        </Stack>
        <Stack direction="row" alignItems="center" gap={2}>
          <ButtonGroup variant="outlined" aria-label="outlined button group">
            {viewGroups.map(({ type, label }) => (
              <Button
                key={type}
                className={clsx(classes.btnGroupIcon, {
                  [classes.btnGroupIconActive]: type === viewBy,
                })}
                onClick={onChangeView(type)}
              >
                {label}
              </Button>
            ))}
          </ButtonGroup>
          <IconButton
            className={classes.addInvoicesBtn}
            icon="plus"
            variant={iconBtnType.PRIMARY}
            onClick={() => console.log('New Invoices')}
            loading={isLoading}
          >
            New Invoices
          </IconButton>
        </Stack>
      </Stack>
      <Box>
        {isLoading ? (
          <Box display="flex" justifyContent="center" sx={{ paddingY: 5 }}>
            <Icons className="rotate linear infinite" glyph="in-progress" color={colors.primary} />
          </Box>
        ) : (
          invoicesList.map((invoice, index) => <InvoiceItem key={index} invoice={invoice} />)
        )}
      </Box>
    </>
  );
};

export default Invoices;
