import {
  AUTHENTICATED_LINK_NAVIGATION,
  FORGOT_PASSWORD_OTP_VERIFICATION_FAILED,
  FORGOT_PASSWORD_OTP_VERIFICATION_SUCCESSFUL,
  LOGIN_FAILED,
  LOGIN_SUCCESSFUL,
  PERFORM_LOGIN,
  REQUIRE_OTP,
  USER_LOGOUT,
  VERIFY_FORGOT_PASSWORD_OTP,
  VERIFY_OTP,
} from './actions';

const token = localStorage.getItem('authorizationToken');
const meta = localStorage.getItem('meta');
const metaData = JSON.parse(meta);

const DEFAULT = {
  isAuthenticated: !!token,
  isLoading: false,
  isAdmin: metaData?.authority === 'ADMIN',
  requiresOTP: false,
  forgotPasswordOTPVerified: false,
  errorMsg: '',
  linkToNavigate: '',
  meta: metaData || {
    userId: '',
    nickName: '',
    authority: '',
  },
};

export default function authReducer(state = DEFAULT, action) {
  const { type, payload } = action;

  switch (type) {
    case PERFORM_LOGIN: {
      return {
        ...state,
        isLoading: true,
        errorMsg: '',
      };
    }
    case LOGIN_FAILED: {
      return {
        ...state,
        isLoading: false,
        isAuthenticated: false,
        errorMsg: payload?.message,
      };
    }
    case LOGIN_SUCCESSFUL: {
      return {
        ...state,
        isAuthenticated: true,
        isAdmin: payload.isAdmin,
        isLoading: false,
        errorMsg: '',
        meta: payload,
      };
    }
    case USER_LOGOUT: {
      return {
        ...DEFAULT,
        isAuthenticated: false,
        isAdmin: false,
      };
    }
    case REQUIRE_OTP: {
      return {
        ...DEFAULT,
        isAuthenticated: false,
        requiresOTP: true,
      };
    }
    case VERIFY_OTP: {
      return {
        ...state,
        isLoading: true,
        errorMsg: '',
        isAuthenticated: false,
        requiresOTP: true,
      };
    }
    case VERIFY_FORGOT_PASSWORD_OTP: {
      return {
        ...state,
        isLoading: true,
        errorMsg: '',
        isAuthenticated: false,
      };
    }

    case FORGOT_PASSWORD_OTP_VERIFICATION_SUCCESSFUL: {
      return {
        ...state,
        isLoading: false,
        errorMsg: '',
        isAuthenticated: false,
        forgotPasswordOTPVerified: true,
      };
    }

    case FORGOT_PASSWORD_OTP_VERIFICATION_FAILED: {
      return {
        ...state,
        isLoading: false,
        errorMsg: 'Invalid Code',
        isAuthenticated: false,
        forgotPasswordOTPVerified: false,
      };
    }
    case AUTHENTICATED_LINK_NAVIGATION: {
      return {
        ...state,
        linkToNavigate: payload,
      };
    }
    default: {
      return state;
    }
  }
}
