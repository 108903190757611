export const conversationColumns = [
  { id: 'name', label: 'Name', sortable: true },
  { id: 'version', label: 'Version', sortable: false },
  // { id: 'assignableBy', label: 'Assignable by', sortable: false },
  { id: 'dctCount', label: "DCT's", sortable: false },
  { id: 'lastUpdated', label: 'Last updated', sortable: false },
  { id: 'assignedTo', label: 'Assignments', sortable: false },
  // { id: 'progress', label: 'Progress', sortable: false },
  { id: 'id', label: 'Actions', sortable: false },
];

export const filterOptionsMock = [{ label: 'Type 1', value: 'type_01' }];

export const viewGroups = [
  { type: 'ACTIVE', label: 'Active' },
  { type: 'DRAFT', label: 'Drafts' },
];
