import { badgeStyle } from '../../../../../../packages';
import dayjs from 'dayjs';

export const assignmentColumns = [
  { id: 'member', label: 'Member', sortable: false },
  { id: 'assignBy', label: 'Assigned by', sortable: false },
  { id: 'status', label: 'Progress status', sortable: false },
  { id: 'assignedDate', label: 'Date', sortable: false },
];

export const badgeStatusText = {
  completed: 'Completed',
  in_progress: 'In progress',
  not_started: 'Not started',
};

export const badgeStatusStyle = {
  completed: badgeStyle.SUCCESS,
  in_progress: badgeStyle.PRIMARY,
  not_started: badgeStyle.UNRELATED,
};

export const assignmentListMock = [
  {
    id: '1',
    member: {
      name: 'john_doe',
      fullname: 'John Doe',
      thumbnail: 'https://i.pravatar.cc/300',
    },
    assignBy: {
      type: 'provider',
      name: 'Eva Bond',
      role: 'Provider',
      thumbnail: 'https://i.pravatar.cc/300',
    },
    status: 'completed',
    assignedDate: dayjs().subtract(1, 'd').toISOString(),
  },
  {
    id: '2',
    member: {
      name: 'Eliah',
      fullname: 'Eliah Johnson',
      thumbnail: 'https://i.pravatar.cc/300',
    },
    assignBy: {
      type: 'user',
      thumbnail: 'https://i.pravatar.cc/300',
    },
    status: 'completed',
    assignedDate: dayjs().subtract(2, 'd').toISOString(),
  },
  {
    id: '3',
    member: {
      name: 'Alisa',
      fullname: 'Alisa Hester',
      thumbnail: 'https://i.pravatar.cc/300',
    },
    assignBy: {
      type: 'system',
    },
    status: 'in_progress',
    assignedDate: dayjs().subtract(1, 'd').toISOString(),
  },
  {
    id: '4',
    member: {
      name: 'Lana',
      fullname: 'Lana Stevens',
      thumbnail: 'https://i.pravatar.cc/300',
    },
    assignBy: {
      type: 'provider',
      name: 'Eva Bond',
      role: 'Provider',
      thumbnail: 'https://i.pravatar.cc/300',
    },
    status: 'in_progress',
    assignedDate: dayjs().subtract(1, 'd').toISOString(),
  },
  {
    id: '5',
    member: {
      name: 'Natali',
      fullname: 'Natali Craig',
      thumbnail: 'https://i.pravatar.cc/300',
    },
    assignBy: {
      type: 'provider',
      name: 'Phoenix Baker',
      role: 'Provider',
      thumbnail: 'https://i.pravatar.cc/300',
    },
    status: 'not_started',
    assignedDate: dayjs().subtract(1, 'd').toISOString(),
  },
  {
    id: '6',
    member: {
      name: 'Zahir',
      fullname: 'Zahir Mays',
      thumbnail: 'https://i.pravatar.cc/300',
    },
    assignBy: {
      type: 'provider',
      name: 'Ryan Foxx',
      role: 'Coach',
      thumbnail: 'https://i.pravatar.cc/300',
    },
    status: 'not_started',
    assignedDate: dayjs().subtract(1, 'd').toISOString(),
  },
  {
    id: '7',
    member: {
      name: 'Lucky Loki',
      fullname: 'Loki Bright',
      thumbnail: 'https://i.pravatar.cc/300',
    },
    assignBy: {
      name: 'Eva Bond',
      role: 'Provider',
      thumbnail: 'https://i.pravatar.cc/300',
    },
    status: 'not_started',
    assignedDate: dayjs().subtract(1, 'd').toISOString(),
  },
];
