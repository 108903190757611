import { FC, useEffect, useState } from 'react';
import { Box } from '@mui/material';

import { Drawer, drawerType } from '../../../../../packages/ui/organisms/drawer';
import { Input, inputSize, inputType } from '../../../../../packages/ui/atoms/input';
import { AppointmentCard } from '../../../../../packages/ui/templates/appointment-card';

import { useStyles } from './PastAppointmentsDrawer.styles';
import EmptyState from '../empty-state';

const PastAppointmentsDrawer = ({ open, onClose, isNoDrawer, items = [] }) => {
  const classes = useStyles();
  const [search, setSearch] = useState('');
  const [appointmentsFiltered, setAppointmentsFiltered] = useState(items);

  useEffect(() => {
    const filteredList = items.filter(item => item.serviceName?.toLowerCase().includes(search.toLowerCase()));
    setAppointmentsFiltered(filteredList);
  }, [search, items.length]);

  const renderContent = () => {
    return (
      <Box className={classes.container}>
        <Box className={classes.searchWrap}>
          <Input
            variant={inputType.SEARCH}
            className={classes.searchInput}
            placeholder="Search past appointments"
            size={inputSize.M}
            value={search}
            onChange={setSearch}
          />
        </Box>
        <Box className={classes.appointments}>
          {appointmentsFiltered.length > 0 ? (
            appointmentsFiltered.map(item => (
              <AppointmentCard appointment={item} key={item.appointmentId} userType="provider" />
            ))
          ) : (
            <EmptyState
              title={`You don't have any Past Appointments yet.`}
              subtitle="Seems like a good time to schedule one : )"
              glyph="dog-12-with-phone-and-provider"
              glyphOrientation="landscape"
            />
          )}
        </Box>
      </Box>
    );
  };

  if (isNoDrawer) {
    return renderContent();
  }

  return (
    <Drawer
      open={open}
      title="Past appointments"
      subTitle={`${appointmentsFiltered.length} total`}
      onClose={onClose}
      variant={drawerType.FORM}
      className={classes.drawer}
      footer={<div />}
    >
      {renderContent()}
    </Drawer>
  );
};

export { PastAppointmentsDrawer };
