// colors
import { colors } from '../../../../packages';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  drawer: {
    '& .MuiPaper-root': {
      maxWidth: '640px !important',
    },
    '& .drawerBody': {
      height: 'calc(88vh - 70px)',
      overflowY: 'auto',
      flexDirection: 'column',
      display: 'flex',
      padding: 0,
    },
  },
  top: {
    backgroundColor: colors.neutral50,
    padding: 32,
  },
  amount: {
    fontSize: 96,
    lineHeight: '104px',
    fontWeight: 600,
    fontFamily: 'Source Serif Pro',
    margin: 0,
  },
  time: {
    fontSize: 16,
    lineHeight: '24px',
    color: colors.neutral600,
    margin: 0,
  },
  appointmentTime: {
    fontSize: 16,
    lineHeight: '20px',
    color: colors.neutral600,
    margin: 0,
  },
  item: {
    flex: 1,
  },
  status: {
    borderRadius: 100,
    minWidth: 'auto',
    fontSize: 14,
    height: 24,
    color: colors.success600,
    marginBottom: 10,
  },
  unpaidStatus: {
    color: colors.destructive500,
  },
  submittedStatus: {
    color: colors.primary600,
  },
  member: {
    display: 'flex',
    alignItems: 'center',
    gap: 12,
    minHeight: 40,
  },
  info: {
    display: 'flex',
    flexDirection: 'column',
    gap: 0,
  },
  id: {
    fontFamily: 'Manrope',
    fontSize: 14,
    lineHeight: '20px',
    color: colors.neutral900,
  },
  name: {
    fontFamily: 'Manrope',
    fontSize: 14,
    lineHeight: '20px',
    color: colors.neutral600,
  },
  infoWrap: {
    display: 'flex',
    alignItems: 'center',
    padding: 32,
    justifyContent: 'space-between',
    borderTop: `1px solid ${colors.neutral100}`,
  },
  infoBlock: {
    flex: 1,
  },
  infoBlockLabel: {
    fontWeight: 800,
    fontSize: 12,
    lineHeight: '20px',
    textTransform: 'uppercase',
    color: colors.neutral600,
    margin: 0,
    marginBottom: 8,
    display: 'block',
  },
  infoBlockBold: {
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '24px',
    color: colors.neutral900,
    margin: 0,
    marginBottom: 8,
    display: 'block',
  },
  roleBadge: {
    borderRadius: 20,
    whiteSpace: 'nowrap',
    height: 18,
    padding: '2px 8px',
    fontSize: 12,
    width: 'fit-content',
    border: 'none',
    marginTop: 2,
    '&.Provider': {
      backgroundColor: colors.indigo50,
      color: colors.indigo600,
    },
    '&.Coach': {
      backgroundColor: colors.warning50,
      color: colors.warning600,
    },
    '&.Prescriber': {
      backgroundColor: colors.cyan50,
      color: colors.cyan600,
    },
    '&.Matchmaker': {
      backgroundColor: '#FEF6EE',
      color: '#E04F16',
    },
    '&.Socialworker': {
      backgroundColor: colors.purple50,
      color: colors.purple600,
    },
  },
  fontWeight400: {
    fontWeight: 400,
  },
  fontWeight500: {
    fontWeight: 500,
  },
  nobottom: {
    marginBottom: 0,
  },
  row: {
    display: 'flex',
    flexDirection: 'column',
    height: 'fit-content',
    border: `1px solid ${colors.neutral100}`,
    borderRadius: 8,
    background: colors.neutral25,
    padding: 24,
  },
  footer: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 80,
    padding: 16,
    borderTop: `1px solid ${colors.neutral100}`,
  },
  btnGroup: {
    display: 'flex',
    gap: 12,
  },
  btn: {
    background: colors.destructive500,
    color: colors.white,
  },
});
