import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles({
  scheduled: {
    // border: theme.border.interactiveSubtle.s,
    // color: theme.color.text.primaryColor,
  },
  isValidDate: {
    border: 'solid 1px #D4DDE5',
    background: 'white',
    color: '#4C5965',
  },
  isAvailableSlot: {
    border: 'solid 1px #D4DDE5',
    color: '#268C2B',
    background: 'white',
  },
  cashSlot: {
    border: 'solid 1px #70E377',
    color: '#0D753B',
    background: '#EBFDE8',
  },
  insuranceSlot: {
    border: 'solid 1px #95CCFF',
    color: '#005DB4',
    background: '#E8F4FF',
  },
  staticCalendar: {
    width: '100%',
  },
  today: {
    // background: theme.color.surface.secondaryColor,
    // color: theme.color.text.secondaryColor,
    // ...theme.typography.label.smallRegular,
    border: 'none !important',
  },
});
