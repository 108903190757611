import React from 'react';
import clsx from 'clsx';
import { Box } from '@mui/material';

import { Badge, badgeStyle, badgeType, iconBtnType, IconButton, Text } from '../../../../../../packages';

import { DomainPriorities } from '../../../../../../redux/modules/profile/types';
import { SymptomBottomCard } from './SymptomBottomCard';
import { useStyles } from './SymptomCard.styles';

const SymptomCard = ({ symptom, onClick, isOnlyView }) => {
  const classes = useStyles();

  const onClickTitle = () => {
    onClick && onClick(symptom, false);
  };

  const onClickEdit = () => {
    onClick && onClick(symptom, true);
  };

  const priority = symptom.priority || symptom.importanceLevel;

  return (
    <Box className={classes.card}>
      <Box className={classes.rowCenter}>
        <Box className={classes.topLeft}>
          <Box className={classes.topLeftHead}>
            <Text className={classes.title} onClick={onClickTitle}>
              {symptom.name}
            </Text>
            <Badge
              variant={badgeType.FILLED}
              className={clsx(classes.badge, {
                [classes.badgeMisreported]: priority?.name === DomainPriorities.MISREPORTED,
              })}
              style={badgeStyle[priority?.name]}
            >
              {priority?.name}
            </Badge>
          </Box>
          <Box>
            <Text className={classes.title2}>{symptom.description}</Text>
          </Box>
        </Box>
        <Box className={classes.topRight}>
          {/* <IconButton
            icon="clock-rotate"
            variant={iconBtnType.TEXT}
            className={classes.btn}
            onClick={onClickTitle}
          >
            See All Reports ({(symptom.history ?? []).length + 1})
          </IconButton> */}
          {!isOnlyView && (
            <IconButton variant={iconBtnType.SECONDARY} className={classes.btn} onClick={onClickEdit}>
              Update
            </IconButton>
          )}
        </Box>
      </Box>
      <SymptomBottomCard symptom={symptom} />
    </Box>
  );
};

export { SymptomCard };
