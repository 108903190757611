import { Box, Stack } from '@mui/material';

import { Drawer, drawerType } from '../../../../packages/ui/organisms/drawer';
import { iconBtnType, IconButton, Text } from '../../../../packages';

import AutomationEvent from './automation-event';
import { useStyles } from './DuplicateAutomation.styles';
import { listEvent } from './DuplicateAutomation.constants';

const DuplicateAutomation = ({ isOpen, onClose, item }) => {
  const classes = useStyles();

  return (
    <Drawer open={isOpen} onClose={onClose} variant={drawerType.NORMAL} className={classes.drawer}>
      <Box className={classes.wrapper}>
        <Box className={classes.header}>
          <Stack direction="column">
            <Text className={classes.headerTitle}>Automation details</Text>
            <Text className={classes.headerSubtitle}>{item.name}</Text>
          </Stack>
          <IconButton icon="close" onClick={onClose} className={classes.backBtn} />
        </Box>
        <Box className={classes.content}>
          {listEvent.map((event, index) => (
            <AutomationEvent key={index} {...event} />
          ))}
        </Box>
        <Box className={classes.footer}>
          <IconButton onClick={onClose}>Close</IconButton>
          <IconButton variant={iconBtnType.PRIMARY} className={classes.duplicateBtn}>
            Duplicate automation
          </IconButton>
        </Box>
      </Box>
    </Drawer>
  );
};

export { DuplicateAutomation };
