/* eslint-disable max-len */
import cx from 'clsx';

// types
// styles
import { useStyles } from './shared/styles';

const Determinant = ({ className, color, onClick }) => {
  const classes = useStyles();
  return (
    <svg
      className={cx({
        [classes.root]: true,
        [className || '']: className,
      })}
      onClick={onClick}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.3329 2.66663V4.37063L23.9995 6.59463L28.8422 4.98129L29.6862 7.50929L25.6435 8.85729L29.7715 20.2066C28.3155 21.7226 26.2662 22.6666 23.9995 22.6666C21.7315 22.6666 19.6835 21.7226 18.2275 20.2066L22.3529 8.85729L17.3329 7.18263V25.3333H22.6662V28H9.33287V25.3333H14.6662V7.18263L9.64221 8.85729L13.7702 20.2066C12.3155 21.7226 10.2662 22.6666 7.99954 22.6666C5.73154 22.6666 3.68354 21.7226 2.22754 20.2066L6.35287 8.85729L2.31287 7.51063L3.15687 4.97996L7.99954 6.59329L14.6662 4.37063V2.66663H17.3329ZM23.9995 12.1373L21.3889 19.3186C22.1715 19.76 23.0649 20 23.9995 20C24.9329 20 25.8262 19.76 26.6102 19.32L23.9995 12.136V12.1373ZM7.99954 12.1373L5.38887 19.3186C6.17154 19.76 7.06487 20 7.99954 20C8.93287 20 9.82621 19.76 10.6102 19.32L7.99954 12.136V12.1373Z"
        fill={color}
      />
    </svg>
  );
};

export default Determinant;
