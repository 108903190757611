export const LogoSize = {
  XXSMALL: 'xxsmall',
  XSMALL: 'xsmall',
  SMALL: 'small',
  MEDIUM: 'medium', // default
  LARGE: 'large',
  XLARGE: 'xlarge',
  XXLARGE: 'xxlarge',
};

export const LogoOrientation = {
  HORIZONTAL: 'horizontal',
  VERTICAL: 'vertical',
};
