import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import {
  Badge,
  badgeStyle,
  badgeType,
  colors,
  fontWeight,
  Heading,
  headingLevel,
  Text,
  textLevel,
} from '../../../../../packages';
import { NoteCard } from '../../../../../packages/ui/templates/note-card';
import { typography } from '../../../../../components/v2/Typography/index.constant';
import Typography from '../../../../../components/v2/Typography';
import { NoteIcons } from '../../../../../constants/CommonConstants';
import { profileActionCreators } from '../../../../../redux/modules/profile';
import { appointmentActionCreators } from '../../../../../redux/modules/appointment';
import { getProfile } from '../../../../../redux/modules/profile/selectors';
import { selectAppointmentDetailsById } from '../../../../../redux/modules/appointment/selectors';
import { getAppointmentCptCodeById } from '../../../../../services/report-service/reportService.service';
import { getMemberNotes } from '../../../../../services/member/member.service';
import { selectCpts } from '../../../../../redux/modules/state/selectors';
// card component
import {
  AppointmentStatus,
  AppointmentStatusBagde,
  AppointmentStatusText,
  SignOffStatus,
} from '../../../../../packages/constants/CommonConstants';
import { getAuth } from '../../../../../redux/modules/auth/selectors';
import { getUserTimeZone } from '../../../../../utils/dayjs';
import { selectProviderSchedule } from '../../../../../redux/modules/schedule/selectors';
import AppointmentCard from '../../../priorities/appointments/AppointmentCard';
import EvaluationCard from '../evaluation-card';
import SignOff from '../signoff';
import { useStyles } from '../../MemberAppointmentNotes.styles';
import CompleteNote from '../complete-note';
import {
  getAppointmentById,
  getNextAppointment,
} from '../../../../../services/appointment/appointment.service';
import { showSnackbar } from '../../../../../redux/modules/snackbar';
import SymptomaticFactors from '../../../priorities/symptomatic-factors';
import Diagnoses from '../../../priorities/diagnoses';
import EnviromentalFactors from '../../../priorities/enviromental-factors';
import Medications from '../../../priorities/medications';
import { PriorityDetailDrawer } from '../../../priorities/PriorityDetailDrawer';
import { PrioritiesCard } from '../../../sessions/priority-list/PriorityList.constants';
import { stateActionCreators } from '../../../../../redux/modules/state';
import Feedback from '../feedback';

const GeneralInfo = () => {
  const classes = useStyles();
  const { memberId, appointmentId } = useParams();
  const { profile, selectedAppointment } = useSelector(getProfile);
  const { isAdmin } = useSelector(getAuth);
  const providerSchedule = useSelector(selectProviderSchedule);
  const appointmentDetails = useSelector(selectAppointmentDetailsById(appointmentId));
  const [showCompleteNote, setShowCompleteNote] = useState(false);
  const [cpts, setCpts] = useState([]);
  const [caseNotes, setCaseNotes] = useState([]);
  const [appointment, setAppointment] = useState(null);
  const [viewAllType, setViewAllType] = useState(null);
  const [selectedPriority, setSelectedPriority] = useState(null);
  const [diagnosesReset, setDiagnosesReset] = useState(false);
  const [medicationsReset, setMedicationsReset] = useState(false);
  const [symptomsReset, setSymptomsReset] = useState(false);
  const [envFactorsReset, setEnvFactorsReset] = useState(false);
  const [nextAppointment, setNextAppointment] = useState(null);
  const cptList = useSelector(selectCpts);
  const serviceName = appointmentDetails ? appointmentDetails?.serviceName : selectedAppointment?.serviceName;
  const startTime = appointmentDetails ? appointmentDetails?.startTime : selectedAppointment?.startTime;
  const serviceDuration = appointmentDetails
    ? appointmentDetails?.duration
    : selectedAppointment?.serviceDuration;
  const dispatch = useDispatch();
  const signOffStatuses = SignOffStatus;
  const appointmentStatus = {
    status: appointmentDetails?.status,
    time: appointmentDetails?.startTime,
    signOffStatus: appointmentDetails?.signOffStatus,
  };

  const onViewAll = type => {
    setSelectedPriority(null);
    setViewAllType(type);
  };

  useEffect(() => {
    if (appointmentId && memberId) {
      dispatch(appointmentActionCreators.fetchAppointmentDetails(appointmentId));
      dispatch(profileActionCreators.fetchDomainTypes(memberId));
      dispatch(appointmentActionCreators.fetchAppointmentById({ appointmentId2: appointmentId }));
      dispatch(stateActionCreators.fetchCPTs());
      if (isAdmin) {
        void fetchNotes();
        void fetchNextAppointment();
      }

      getAppointmentCptCodeById(appointmentId)
        .then(cptCodesList => {
          setCpts(
            cptCodesList?.data?.data?.cptList?.map(item => {
              const foundCPT = cptList?.find(cptItem => cptItem.code === item.cptCode);
              if (foundCPT) {
                return foundCPT;
              }
              return null;
            }),
          );
        })
        .catch(err => console.log({ err }));
    }
  }, []);

  useEffect(() => {
    if (appointmentId) void fetchAppointmentById();
  }, [appointmentId]);

  const fetchAppointmentById = async () => {
    try {
      const { data } = await getAppointmentById({ appointmentId });
      setAppointment(data);
    } catch (err) {
      showSnackbar({
        snackType: 'error',
        snackMessage:
          err?.data?.errors?.[0]?.endUserMessage || 'Whoops ! something went wrong please try again',
      });
    }
  };

  const fetchNextAppointment = async () => {
    const { data } = await getNextAppointment(memberId, false);
    setNextAppointment(data?.appointment ?? null);
  };

  const fetchNotes = useCallback(async () => {
    try {
      const { data } = await getMemberNotes({ userId: memberId });
      const listSorted = data.patientContactNotes.map(item => ({ ...item, patientId: '' }));
      setCaseNotes(listSorted);
      // eslint-disable-next-line no-empty
    } catch (error) {
      console.error(error);
    }
  }, [memberId]);

  const evaluationProgress = appointmentDetails?.evaluationProgress ?? [];
  const participants = appointmentDetails?.participants ?? [];
  const practitionerParticipant = participants.find(participant => participant.isPractitioner);
  const provider = {
    id: practitionerParticipant?.participantId,
    name: practitionerParticipant?.name,
    profilePicture: practitionerParticipant?.profilePicture,
    role: practitionerParticipant?.designation,
  };

  const toggleCompleteNote = () => {
    setShowCompleteNote(!showCompleteNote);
  };

  const onElementsUpdated = type => {
    switch (type) {
      case PrioritiesCard.Diagnoses: {
        setDiagnosesReset(v => !v);
        break;
      }
      case PrioritiesCard.Medications: {
        setMedicationsReset(v => !v);
        break;
      }
      case PrioritiesCard.SymptomaticFactors: {
        setSymptomsReset(v => !v);
        break;
      }
      case PrioritiesCard.EnvironmentalFactors: {
        setEnvFactorsReset(v => !v);
        break;
      }
      default: {
        console.log('Unknown type updated');
      }
    }
  };

  const onCloseViewAllDrawer = () => {
    setViewAllType(null);
    setSelectedPriority(null);
  };

  const renderHeadTitle = title => {
    return (
      <Heading
        className={clsx(classes.heading, classes.heading2)}
        level={headingLevel.M}
        weight={fontWeight.BOLD}
      >
        {title}
      </Heading>
    );
  };
  // const Col = ({ label, value }) => (
  //   <Box display="flex" flexDirection="column" minWidth={185}>
  //     <Text className={classes.colLabel} level={textLevel.S} weight={fontWeight.SEMI_BOLD}>
  //       {label}
  //     </Text>
  //     {label && (
  //       <Text className={classes.marginTop8} level={textLevel.M} weight={fontWeight.MEDIUM}>
  //         {value || 'N/A'}
  //       </Text>
  //     )}
  //   </Box>
  // );

  const Status = ({ status, time, signOffStatus }) => {
    const isToday = dayjs(time).isSame(dayjs(), 'day');
    let statusText = AppointmentStatusText[status];
    let waitingForSignOff = false;

    if (status === AppointmentStatus.FULFILLED && signOffStatus === signOffStatuses.DRAFTED) {
      statusText = 'Waiting for Sign off';
      waitingForSignOff = true;
    }
    if (status === AppointmentStatus.PROPOSED || status === 'PENDING') {
      statusText = 'Pending';
    }
    if (status === AppointmentStatus.NO_SHOW) {
      statusText = 'No Show';
    }
    if (status === AppointmentStatus.BOOKED) {
      statusText = isToday ? 'Today' : 'Scheduled';
    }
    return appointmentDetails?.status ? (
      <Badge
        variant={badgeType.FILLED}
        style={
          waitingForSignOff
            ? AppointmentStatusBagde.SIGN_OFF
            : isToday
            ? badgeStyle.MISREPORTED
            : AppointmentStatusBagde[status] || badgeStyle.UNRELATED
        }
        className={classes.status}
      >
        {statusText}
      </Badge>
    ) : (
      <></>
    );
  };

  return (
    <>
      <Box id="general">
        <CompleteNote open={showCompleteNote} onClose={toggleCompleteNote} />
        <Box className={classes.topHeading}>
          <Box className={classes.headWrap} display="flex" justifyContent="space-between">
            <Box className={classes.headLeft} display="flex" justifyContent="flex-start" alignItems="center">
              <Heading className={classes.heading} level={headingLevel.XL} weight={fontWeight.BOLD}>
                {serviceName}
              </Heading>
              <Status {...appointmentStatus} />
            </Box>
          </Box>
          {appointmentDetails?.startTime && (
            <Text className={classes.timeText} level={textLevel.S}>
              <>
                {isAdmin ? (
                  <>
                    {dayjs(startTime).format('MMMM DD, YYYY, h:mma - ')}
                    {dayjs(startTime).add(Number(serviceDuration), 'm').format('h:mma')}
                  </>
                ) : (
                  <>
                    {dayjs(startTime)
                      .tz(providerSchedule?.timezone || getUserTimeZone())
                      .format('MMMM DD, YYYY, h:mma - ')}
                    {dayjs(startTime)
                      .add(Number(serviceDuration), 'm')
                      .tz(providerSchedule?.timezone || getUserTimeZone())
                      .format('h:mma')}
                  </>
                )}
              </>
            </Text>
          )}
        </Box>
        <Box className={classes.feedbackCards}>
          {participants.map((feedback, i) => (
            <Feedback {...feedback} key={i} />
          ))}
        </Box>
      </Box>
      <Box id="cpts" className={classes.cards}>
        {renderHeadTitle('CPT Codes')}
        <Box className={clsx(classes.card, classes.p0)}>
          {cpts?.length > 0 ? (
            <Box display="flex" flexWrap="wrap" flex={1} justifyContent="space-between">
              {cpts?.map((item, index) => (
                <Box
                  key={`${index}-${item?.code}`}
                  display="flex"
                  gap="12px"
                  alignItems="center"
                  padding="24px"
                  boxShadow="1px 0px 0px 0px #EDF1F5 inset"
                >
                  <Text className={classes.cptCode}>{item?.code}</Text>
                  {(item?.minTime || item?.maxTime) && (
                    <Badge
                      variant={badgeType.OUTLINED}
                      style={badgeStyle.INACTIVE}
                      className={classes.badge2}
                    >
                      {item?.minTime || item?.maxTime} minutes
                    </Badge>
                  )}
                </Box>
              ))}
            </Box>
          ) : (
            <Box padding="24px" className={classes.noPointer}>
              <Typography {...typography.body.m.medium} color={colors.neutral900}>
                No CPT Codes Added
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
      <Box id="evaluations" className={classes.cards}>
        {renderHeadTitle('Evaluations')}
        {evaluationProgress?.length > 0 ? (
          <Box gap="16px" display="flex" flexDirection="column">
            {evaluationProgress.map((item, idx) => (
              <EvaluationCard
                evaluation={item}
                key={idx}
                provider={provider}
                expandable={
                  (profile?.signOffRole === 'SUPERVISOR' && profile?.providerId !== provider?.id) || isAdmin
                }
              />
            ))}
          </Box>
        ) : (
          <Box className={clsx(classes.card, classes.noPointer, classes.p0)}>
            <Box padding="24px">
              <Typography {...typography.body.m.medium} color={colors.neutral900}>
                No Evaluations Added
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
      <Box id="priorities" className={classes.cards}>
        {renderHeadTitle('Priorities')}
        <Box id="Symptomatic Factors">
          <SymptomaticFactors
            memberId={memberId}
            onViewAll={onViewAll}
            onClick={(item, type) => {
              setSelectedPriority(item);
              setViewAllType(type);
            }}
            reset={symptomsReset}
          />
        </Box>
        <Box id="Diagnoses">
          <Diagnoses
            memberId={memberId}
            hideNotes
            onViewAll={onViewAll}
            onClick={(item, type) => {
              setSelectedPriority(item);
              setViewAllType(type);
            }}
            reset={diagnosesReset}
          />
        </Box>
        <Box id="Environmental Factors">
          <EnviromentalFactors
            memberId={memberId}
            onViewAll={onViewAll}
            onClick={(item, type) => {
              setSelectedPriority(item);
              setViewAllType(type);
            }}
            reset={envFactorsReset}
          />
        </Box>
        <Box id="Medications">
          <Medications
            // title="Active medications"
            memberId={memberId}
            hideNotes
            onViewAll={onViewAll}
            onClick={(item, type) => {
              setSelectedPriority(item);
              setViewAllType(type);
            }}
            reset={medicationsReset}
          />
        </Box>
        {!!viewAllType && (
          <PriorityDetailDrawer
            memberId={memberId}
            selectedItem={selectedPriority}
            type={viewAllType}
            onClose={onCloseViewAllDrawer}
            open={!!viewAllType}
            onElementsUpdated={onElementsUpdated}
          />
        )}
      </Box>
      {isAdmin && (
        <Box id="Case notes" className={classes.cards}>
          {renderHeadTitle('Case notes')}
          {caseNotes?.length > 0 ? (
            <Box gap="16px" display="flex" flexDirection="column">
              {caseNotes.map((note, idx) => (
                <NoteCard key={idx} note={note} icon={NoteIcons[note.flag]} className={classes.noPointer} />
              ))}
            </Box>
          ) : (
            <Box className={clsx(classes.card, classes.noPointer, classes.p0)}>
              <Box padding="24px">
                <Typography {...typography.body.m.medium} color={colors.neutral900}>
                  No Case Notes Added
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
      )}
      {isAdmin && nextAppointment && (
        <Box id="Next Appointment" className={classes.cards}>
          {renderHeadTitle('Next Appointment')}
          <AppointmentCard hideCardHeader isWaiting isPast={false} appointment={nextAppointment} />
        </Box>
      )}
      {appointmentDetails?.status === AppointmentStatus.FULFILLED && (
        <Box id="signOff" className={classes.cards}>
          {renderHeadTitle('Sign off')}
          <SignOff
            appointmentDetails={appointment}
            supervisor={profile.signOffRole === 'SUPERVISOR' && profile}
            signOffDates={{
              providerSignoffDate: appointmentDetails?.signOffNotes?.signOffAt ?? null,
              supervisorSignoffDate: appointmentDetails?.signOffNotes?.supervisorSignOffAt ?? null,
            }}
          />
        </Box>
      )}
    </>
  );
};

export default GeneralInfo;
