import { useEffect, useState } from 'react';
import ApexChart from 'react-apexcharts';
import { Box } from '@mui/material';

import { Heading, Menu, Text, textLevel } from '../../../../../packages';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { LoeAssignmentsFilterDates } from '../../../../../constants/CommonConstants';
import { chartOptions } from './AssignmentsCard.utils';
import { useStyles } from './AssignmentsCard.styles';
import { getGraphDetails } from '../../../../../redux/modules/schedule/selectors';

const AssignmentsCard = ({ updateGraph }) => {
  const classes = useStyles();
  const [selectedTime, setSelectedTime] = useState(LoeAssignmentsFilterDates[1]);
  const [chartSeries, setChartSeries] = useState([]);
  const [dateSeries, setDateSeries] = useState([]);
  const loeGraphDetails = useSelector(getGraphDetails);

  const times = LoeAssignmentsFilterDates.map(time => ({
    ...time,
    onClick: () => {
      setSelectedTime(time);
      updateGraph(time.value);
    },
  }));

  const customTooltip = ({ series, dataPointIndex, seriesIndex }) => {
    const time = dayjs(dateSeries[dataPointIndex]).format('MMM DD, YYYY');
    const num = +series[seriesIndex][dataPointIndex];
    return `
      <div class="chart-tooltip">
        <div class="date">${time}</div>
        <div class="level">${num} ${num > 1 ? 'assignments' : 'assignment'} </div>
      </div>
    `;
  };

  useEffect(() => {
    if (loeGraphDetails) {
      const tempSeriesCounts = loeGraphDetails.map(item => item.count);
      setChartSeries(tempSeriesCounts);
      const tempSeriesDates = loeGraphDetails.map(item => item.date);
      setDateSeries(tempSeriesDates);
    }
  }, [loeGraphDetails]);

  return (
    <Box>
      <Box className={classes.head}>
        <Heading className={classes.title}>Assignments chart</Heading>
        <Box className={classes.headRight}>
          <Menu
            icon="chevron-down"
            items={times}
            itemsWrapperClassName={classes.menuLabel}
            className={classes.menu}
          >
            <Text className={classes.menuLabel} level={textLevel.S}>
              {selectedTime.label}
            </Text>
          </Menu>
        </Box>
      </Box>
      <Box className={classes.chart}>
        <ApexChart
          options={{
            ...chartOptions, // Spread the existing chartOptions
            xaxis: {
              ...chartOptions.xaxis, // Spread the existing xaxis options
              categories: dateSeries, // Update the categories property
            },
            tooltip: {
              ...chartOptions.tooltip,
              custom: customTooltip, // Assign the custom function to the tooltip
            },
            yaxis: {
              ...chartOptions.yaxis,
              max: Math.max(...chartSeries) + 1,
              min: 0,
            },
          }}
          series={[{ data: chartSeries }]}
          type="line"
          height={330}
        />
      </Box>
    </Box>
  );
};

export { AssignmentsCard };
