import { colors } from '../../../../../packages/colors';
import { Badge, badgeStyle, badgeType } from '../../../../../packages/ui/atoms/badge';
import { btnType } from '../../../../../packages/ui/atoms/button';
import { Rating } from '../../../../../packages/ui/atoms/rating';
import { fontWeight, Heading, headingLevel, Text } from '../../../../../packages/ui/atoms/typography';
import { IconButton } from '../../../../../packages/ui/molecules/icon-button';
import { ProfileInfo } from '../../../../../packages/ui/templates/profile-info';
import { Box } from '@mui/material';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { IInvoiceRecord } from '../../../../../redux/modules/reporting/types';
import { useStyles } from './index.styles';
import { statusMapping } from './invoices.mock';

const InvoiceItem = ({ invoice }) => {
  const classes = useStyles();
  return (
    <Box mb={2}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        padding={3}
        sx={{
          background: colors.white,
          borderTopLeftRadius: 8,
          borderTopRightRadius: 8,
        }}
      >
        <Box display="flex" alignItems="center" gap={2}>
          <Heading level={headingLevel.M} weight={fontWeight.BOLD}>
            ${invoice.ammountPaid}
          </Heading>
          <Badge
            variant={badgeType.OUTLINED}
            style={invoice.status === 'paid' ? badgeStyle.RESOLVED : badgeStyle.HIGH}
            className={clsx(
              classes.status,
              { [classes.holdStatus]: invoice.status === 'hold' },
              { [classes.pendingStatus]: invoice.status === 'pending' },
            )}
          >
            {statusMapping[invoice.status]}
          </Badge>
        </Box>
        <Box display="flex" alignItems="center" gap={3}>
          <Text className={classes.subTitle}>
            Member will be charged on {dayjs(invoice.dueDate).format('MMMM, D')}
          </Text>
          <IconButton
            className={classes.actionBtn}
            icon="more-vertical"
            variant={btnType.OUTLINE}
            onClick={() => console.log()}
          />
        </Box>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        sx={{ borderTop: `1px solid ${colors.neutral100}`, px: 3, py: 2, background: colors.white }}
      >
        <Box display="flex" flexDirection="column">
          <Text className={classes.evaluationName}>Meds for opioid use evaluation</Text>
          <Box display="flex" alignItems="center" gap={1.5}>
            <Text className={classes.subTitle}>Completed on December 5 at 10:30 am</Text>
            <Box sx={{ background: colors.neutral200, width: 8, height: 8, borderRadius: '50%' }} />
            <Text className={classes.subTitle}>15 minute session</Text>
            <Box sx={{ background: colors.neutral200, width: 8, height: 8, borderRadius: '50%' }} />
            <Rating value={5} readOnly={false} className={classes.rating} showValue={false} name="rating" />
          </Box>
        </Box>
        <Box>
          <ProfileInfo
            type="provider"
            photo={invoice.provider.profileImage}
            role={invoice.provider.firstName}
            fullName={invoice.provider.fullName}
            isProvider
          />
        </Box>
      </Box>
    </Box>
  );
};

export default InvoiceItem;
