import React, { Fragment } from 'react';
import { Box } from '@mui/material';
import { fontWeight, Heading, headingLevel } from '../../../../packages';

import ScoredCard from './scored-card';
import { scores } from './ScoredOutcomes.constants';
import { useStyles } from './ScoredOutcomes.styles';

const ScoredOutcomes = () => {
  const classes = useStyles();

  return (
    <Fragment>
      <Heading className={classes.heading} level={headingLevel.XL} weight={fontWeight.BOLD}>
        Scored Outcomes
      </Heading>
      <Box className={classes.content}>
        {scores.map(item => (
          <ScoredCard item={item} key={item.name} />
        ))}
      </Box>
    </Fragment>
  );
};

export { ScoredOutcomes };
