import { useEffect, useMemo, useState } from 'react';
import { Badge, badgeSize, badgeStyle, badgeType } from '../../../packages/ui/atoms/badge';
import { fontWeight, Heading, headingLevel, Text, textLevel } from '../../../packages/ui/atoms/typography';
import { iconBtnType, IconButton } from '../../../packages/ui/molecules/icon-button';
import { Table } from '../../../packages/ui/organisms/table';
import { colors } from '../../../packages/colors';
import { Menu } from '../../../packages/ui/molecules/menu';
import { Icons } from '../../../packages/icons';
import { Box, Stack } from '@mui/material';

import { BaseLayout } from '../../../layouts/base';
import { insertIfObj } from '../../../utils/InsertIf';
import * as memberService from '../../../services/member/member.service';
import dayjs from 'dayjs';
import { GLOBAL_DATE_FORMAT } from '../../../constants/CommonConstants';
import { diffDays } from '../../../utils/CommonUtils';
import { ConfirmationModal } from '../../../packages/ui/templates/confirmation-modal';
import { useDispatch, useSelector } from 'react-redux';
import { showSnackbar } from '../../../redux/modules/snackbar';
import { useNavigate } from 'react-router-dom';
import { profileActionCreators } from '../../../redux/modules/profile';
import { getProfile } from '../../../redux/modules/profile/selectors';

import { useStyles } from './index.styles';
import AddReferralDrawer from './components/add-referral-drawer/AddReferralDrawer';

const tableColumns = [
  { id: 'name', label: 'Name', sortable: false },
  { id: 'patientCount', label: 'Total patients', sortable: false },
  { id: 'activePatientCount', label: 'Active patients', sortable: false },
  { id: 'firstReferral', label: 'First referral', sortable: false },
  { id: 'latestReferral', label: 'Last referral', sortable: false },
  { id: 'createdAt', label: 'Date added', sortable: false },
  { id: 'actions', label: '', sortable: false },
];

const ReferralPartners = () => {
  const classes = useStyles();
  const [referralPartners, setReferralPartners] = useState([]);
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [selectedReferralPartner, setSelectedReferralPartner] = useState(null);
  // const [totalRecords, setTotalRecords] = useState(0);
  const profile = useSelector(getProfile);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [tableParams, setTableParams] = useState({
    search: { searchKey: '' },
    pagination: { currentPage: 0, rowsPerPage: 10 },
  });
  const onAddNew = () => {
    setSelectedReferralPartner(null);
    toggleDrawer();
  };

  const toggleDrawer = () => {
    setIsOpenDrawer(!isOpenDrawer);
  };

  const toggleConfirmModal = () => {
    setShowConfirmDelete(!showConfirmDelete);
  };
  const getFormatQueryParams = () => {
    return {
      pageNumber: tableParams.pagination.currentPage,
      pageSize: tableParams.pagination.rowsPerPage,
      search: tableParams.search.searchKey,
    };
  };
  const fetchReferralPartnersCall = () => {
    dispatch(profileActionCreators.fetchReferralPartners(getFormatQueryParams()));
  };

  useEffect(() => {
    setReferralPartners(
      profile?.referralPartners?.partners?.map(rPartners => {
        return {
          ...rPartners,
          id: rPartners.refPartnerId,
          patientCount: rPartners?.patientCount || 0,
          activePatientCount: rPartners?.activePatientCount || 0,
          firstReferral: rPartners?.firstReferral || { assignedAt: '' },
          latestReferral: rPartners?.latestReferral || { assignedAt: '' },
          createdAt: rPartners?.createdAt || '',
          actions: rPartners,
        };
      }) || [],
    );
  }, [profile?.referralPartners?.partners]);
  useEffect(() => {
    fetchReferralPartnersCall();
  }, [tableParams]);

  const onDeleteReferralPartner = () => {
    toggleConfirmModal();
  };
  const onSubmitReferral = payload => {
    if (!payload?.name) {
      dispatch(showSnackbar({ snackType: 'error', snackMessage: 'Partner name is required!' }));
      return;
    }
    setIsSaving(true);
    if (selectedReferralPartner) {
      memberService
        .updateReferralPartner(payload, {
          referralPartnerId: payload.refPartnerId || selectedReferralPartner.refPartnerId,
        })
        .then(() => {
          dispatch(
            showSnackbar({ snackType: 'success', snackMessage: 'Referral partner updated successfully' }),
          );
          fetchReferralPartnersCall();
          setIsSaving(false);
          toggleDrawer();
        })
        .catch(() => {
          dispatch(showSnackbar({ snackType: 'error', snackMessage: 'Unable to update referral partner' }));
          setIsSaving(false);
        });
    } else {
      memberService
        .createReferralPartner(payload)
        .then(() => {
          dispatch(
            showSnackbar({ snackType: 'success', snackMessage: 'Referral partner added successfully' }),
          );
          fetchReferralPartnersCall();
          setIsSaving(false);
          toggleDrawer();
        })
        .catch(() => {
          dispatch(showSnackbar({ snackType: 'error', snackMessage: 'Unable to add referral partner' }));
          setIsSaving(false);
        });
    }
  };
  const getDaysText = days => {
    if (days > 1) return `${days} days ago`;
    if (days <= 0) return 'Today';
    return `${days} day ago`;
  };
  const columns = useMemo(
    () =>
      tableColumns.map(column => ({
        ...column,
        renderCell: name => (
          <Text level={textLevel.S} weight={fontWeight.MEDIUM} color={colors.neutral900}>
            {name}
          </Text>
        ),
        ...insertIfObj(column.id === 'patientCount', {
          renderCell: patientCount => (
            <Badge variant={badgeType.OUTLINED} size={badgeSize.SMALL} style={badgeStyle.UNRELATED}>
              {patientCount} patients
            </Badge>
          ),
        }),
        ...insertIfObj(column.id === 'activePatientCount', {
          renderCell: activePatientCount => (
            <Badge variant={badgeType.OUTLINED} size={badgeSize.SMALL} style={badgeStyle.ACTIVE}>
              {activePatientCount} active
            </Badge>
          ),
        }),
        ...insertIfObj(column.id === 'firstReferral', {
          renderCell: ({ assignedAt }) => (
            <Text level={textLevel.S} weight={fontWeight.MEDIUM} color={colors.neutral900}>
              {assignedAt
                ? getDaysText(
                    diffDays(
                      dayjs(assignedAt).format(GLOBAL_DATE_FORMAT),
                      dayjs().format(GLOBAL_DATE_FORMAT),
                    ),
                  )
                : '0'}{' '}
            </Text>
          ),
        }),
        ...insertIfObj(column.id === 'latestReferral', {
          renderCell: ({ assignedAt }) => (
            <Text level={textLevel.S} weight={fontWeight.MEDIUM} color={colors.neutral900}>
              {assignedAt
                ? getDaysText(
                    diffDays(
                      dayjs(assignedAt).format(GLOBAL_DATE_FORMAT),
                      dayjs().format(GLOBAL_DATE_FORMAT),
                    ),
                  )
                : '0'}
            </Text>
          ),
        }),
        ...insertIfObj(column.id === 'createdAt', {
          renderCell: createdAt => (
            <Text level={textLevel.S} weight={fontWeight.MEDIUM} color={colors.neutral900}>
              {createdAt ? dayjs(createdAt).format(GLOBAL_DATE_FORMAT) : '-'}
            </Text>
          ),
        }),
        ...insertIfObj(column.id === 'actions', {
          renderCell: referralPartner => (
            <Menu
              icon="more"
              className={classes.menu}
              itemsWrapperClassName={classes.menuItemsWrapper}
              items={[
                {
                  icon: <Icons glyph="edit-underline" className={classes.icon} />,
                  label: 'Edit partner',
                  onClick: () => {
                    setSelectedReferralPartner(referralPartner);
                    toggleDrawer();
                  },
                },
                // {
                //   icon: <Icons glyph="copy-outlined" className={classes.icon} />,
                //   label: 'Delete partner',
                //   onClick: () => {
                //     setSelectedReferralPartner(referralPartner);
                //     toggleConfirmModal();
                //   },
                // },
              ]}
            />
          ),
        }),
      })),
    [tableColumns, referralPartners],
  );

  return (
    <BaseLayout>
      <AddReferralDrawer
        isOpen={isOpenDrawer}
        onClose={toggleDrawer}
        onSubmit={onSubmitReferral}
        isSaving={isSaving}
        referralPartner={selectedReferralPartner}
      />
      <ConfirmationModal
        open={showConfirmDelete}
        onClose={toggleConfirmModal}
        onSave={onDeleteReferralPartner}
        title="Delete referral partner"
        message={`Are you sure? Delete ${selectedReferralPartner?.name}`}
      />
      <Stack direction="row" display="flex" justifyContent="space-between" spacing={2}>
        <Stack direction="row" alignItems="center" gap={2}>
          <Heading className={classes.heading} level={headingLevel.XL} weight={fontWeight.BOLD}>
            Referral partners
          </Heading>
          <Badge variant={badgeType.OUTLINED} style={badgeStyle.UNRELATED} className={classes.badge}>
            {profile?.referralPartners?.totalRecords} total
          </Badge>
        </Stack>
        <IconButton className={classes.addBtn} icon="plus" variant={iconBtnType.PRIMARY} onClick={onAddNew}>
          Add referral partner
        </IconButton>
      </Stack>
      <Box sx={{ pt: 6 }}>
        <Table
          className={classes.table}
          searchProps={{
            placeholder: 'Search referral partners by name',
          }}
          paginationProps={{
            currentRows: referralPartners.length,
            totalCount: profile?.referralPartners?.totalRecords,
          }}
          gridProps={{
            columns,
            onRowClick: item =>
              navigate(`/admin/referral-partners/${item.refPartnerId}`, {
                state: { partnerName: item.name },
              }),
            data: referralPartners,
            isLoading: profile?.referralPartners?.isLoading,
          }}
          value={tableParams}
          onChange={setTableParams}
        />
      </Box>
    </BaseLayout>
  );
};

export default ReferralPartners;
