import { ApiEndpoints } from '../../constants/ApiEndpoints';
import { baseRequest, signOff } from '../../utils/HttpClient';

export const login = payload => {
  return baseRequest(ApiEndpoints.LOGIN, payload);
};

export const verifyOtp = payload => {
  return baseRequest(ApiEndpoints.VERIFY_AUTH, payload);
};

export const userAccountRecovery = userAccountRecoveryParams => {
  return baseRequest(ApiEndpoints.USER_ACCOUNT_RECOVERY, userAccountRecoveryParams);
};

export const verifyConfirmationCode = verifyConfirmationCodeParams => {
  return baseRequest(ApiEndpoints.VERIFY_CONFIRMATION_CODE, verifyConfirmationCodeParams);
};

export const updatePassword = updatePasswordParams => {
  const res = baseRequest(ApiEndpoints.UPDATE_PASSWORD, updatePasswordParams);

  return res;
};

export const resendVerificationCode = resendVerificationCodeParams => {
  return baseRequest(ApiEndpoints.RESEND_VERIFICATION_CODE, resendVerificationCodeParams);
};

export const resendOtp = payload => {
  return baseRequest(ApiEndpoints.RESEND_OTP, payload);
};

export const logout = () => {
  return signOff();
};

export const registerPlayerId = pathParams => {
  return baseRequest(ApiEndpoints.REGISTER_PLAYERID, null, pathParams);
};

export const getSendbirdSessionToken = () => {
  return baseRequest(ApiEndpoints.GET_SENDBIRD_TOKEN);
};

export const sendMagicLink = pathParams => {
  return baseRequest(ApiEndpoints.SEND_MAGICLINK, null, pathParams);
};
