import { FC } from 'react';
import { Box } from '@mui/material';

import { Toggle, positionType } from '../../../../../../packages/ui/atoms/toggle';
import { IconButton } from '../../../../../../packages/ui/molecules/icon-button';

import { useStyles } from './TimeHeading.styles';

const TimeHeading = ({ label, checked, onChange, onAddTimeSlot }) => {
  const classes = useStyles();
  return (
    <Box display="flex" justifyContent="space-between" alignItems="center" className={classes.heading}>
      {onChange ? (
        <Toggle checked={checked} onChange={onChange} labelPosition={positionType.RIGHT}>
          {label}
        </Toggle>
      ) : (
        <Box sx={{ fontSize: 16, fontWeight: 600, lineHeight: '24px' }}>{label}</Box>
      )}
      {((onChange && checked) || !onChange) && (
        <IconButton icon="plus" onClick={onAddTimeSlot} className={classes.btn}>
          Add time slot
        </IconButton>
      )}
    </Box>
  );
};

export { TimeHeading };
