import { useState } from 'react';
import cx from 'clsx';

// mui
import MuiMenu from '@mui/material/Menu';
import MuiMenuItem from '@mui/material/MenuItem';

// icons
import { Box } from '@mui/material';
import { Icons } from '../../../icons';

// colors
import { colors } from '../../../colors';

// components
import { Link, linkType } from '../../atoms/link';

// styles
import { useStyles } from './Menu.styles';

const Menu = ({
  className = '',
  classNameActive = '',
  itemsWrapperClassName = '',
  onToggle = null,
  items = [],
  children = null,
  animated = true,
  icon = undefined,
  onMenuClick = undefined,
  ...rest
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const classes = useStyles({ open, animated });

  const onOpen = e => {
    setAnchorEl(e.currentTarget);
    if (onToggle) {
      onToggle();
    }
  };

  const onClose = () => {
    setAnchorEl(null);
    if (onToggle) {
      onToggle();
    }
  };

  return (
    <Box onClick={onMenuClick}>
      {' '}
      {/* Wrap everything in Box and call onMenuClick */}
      <MuiMenu
        className={cx(classes.root, itemsWrapperClassName)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        onClose={onClose}
        {...rest}
      >
        {items?.map(({ label, onClick, icon: itemIcon, customeElement }, idx) =>
          customeElement ? (
            <MuiMenuItem key={idx} className="menu-item-custom">
              {customeElement}
            </MuiMenuItem>
          ) : (
            <MuiMenuItem
              key={idx}
              className={cx(classes.menuItem, { [classes.menuItemDisabled]: rest.disabled })}
              onClick={() => {
                onClose();
                if (!rest.disabled) {
                  onClick?.();
                }
              }}
            >
              {itemIcon}
              {label}
            </MuiMenuItem>
          ),
        )}
      </MuiMenu>
      <Link
        className={cx(classes.link, className, { [classNameActive]: open })}
        variant={linkType.CALLBACK}
        onClick={onOpen}
      >
        {children}
        {icon && (
          <Icons
            className={classes.arrow}
            glyph={icon}
            color={open ? colors.neutral900 : colors.neutral500}
          />
        )}
      </Link>
    </Box>
  );
};

export { Menu };
