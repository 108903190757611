import { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useStyles } from './SessionNavigator.styles';
import { Box, Collapse } from '@mui/material';
import { IconButton } from '../../../molecules/icon-button';
import { btnType, Button } from '../../../atoms/button';
import { Icons } from '../../../../icons';
import { useSelector } from 'react-redux';
import { selectAssignedEvaluationState } from '../../../../../redux/modules/conversation/selectors';
import { MEMBER_ACTIONS, Sections, subsections } from '../sessions.constants';

const SessionNavigator = ({
  isFirstAppointment,
  selectedSection,
  setSelectedSection,
  sessionEnded,
  selectedEvaluationId,
  setSelectedEvaluationId,
  currentBlock,
  setCurrentBlock,
  currentEvaluation,
  setCurrentEvaluation,
  evaluationsCompleted,
  listHelper,
  onMemberActionClick,
}) => {
  const [expanded, setExpanded] = useState(true);
  const classes = useStyles({ expanded });
  const { evaluations } = useSelector(selectAssignedEvaluationState);

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    if (sessionEnded) {
      setExpanded(true);
    }
  }, [sessionEnded]);

  const cleanupSelections = () => {
    listHelper.setHead(null);
    setSelectedEvaluationId(null);
    setCurrentEvaluation(null);
    setCurrentBlock(null);
  };

  return (
    <Box className={classes.wrapper}>
      {/* Session navigator content */}
      <Box className={classes.collapseArea}>
        <Box className={classes.collapseButton} onClick={toggleExpand}>
          <IconButton
            icon='chevron-right'
            onClick={toggleExpand}
            className={clsx(classes.arrowBtn, { [classes.arrowBtnRotate]: expanded })}
          />
        </Box>
      </Box>
      <Box className={classes.stepsContainer}>
        {/* Steps container */}
        <Box
          className={classes.mainSection}
          onClick={() => {
            cleanupSelections();
            setSelectedSection(Sections.WELCOME);
            setSelectedEvaluationId(subsections.IMPORTANT_INFORMATION);
          }}>
          <span
            className={clsx(classes.sectionCount, {
              [classes.sectionSelected]: selectedSection === Sections.WELCOME,
              [classes.sectionCompleted]: selectedSection !== Sections.WELCOME,
            })}
          >
            1
          </span>
          {expanded && <span className={classes.sectionTitle}>Welcome to the session</span>}
        </Box>
        <Collapse in={selectedSection === Sections.WELCOME}>
          <p
            className={clsx(classes.subsection, {
              [classes.subsectionSelected]: selectedEvaluationId === subsections.IMPORTANT_INFORMATION,
            })}
            onClick={() => {
              cleanupSelections();
              setSelectedSection(Sections.WELCOME);
              setSelectedEvaluationId(subsections.IMPORTANT_INFORMATION);
            }}
          >
            {expanded ? 'Important Information' : '•'}
          </p>
          {isFirstAppointment && (
            <p
              className={clsx(classes.subsection, {
                [classes.subsectionSelected]: selectedEvaluationId === subsections.DOUBLE_CHECK_IDS,
              })}
              onClick={() => {
                cleanupSelections();
                setSelectedSection(Sections.WELCOME);
                setSelectedEvaluationId(subsections.DOUBLE_CHECK_IDS);
              }}
            >
              {expanded ? 'Double Check IDs' : '•'}
            </p>
          )}
        </Collapse>
        <Box
          className={classes.mainSection}
          onClick={() => {
            cleanupSelections();
            setSelectedSection(Sections.EVALUATIONS);
          }}
        >
          <span
            className={clsx(classes.sectionCount, {
              [classes.sectionSelected]: selectedSection === Sections.EVALUATIONS,
              [classes.sectionCompleted]: selectedSection === Sections.OPEN_SESSION || selectedSection === Sections.CLOSE_SESSION || evaluationsCompleted,
            })}
          >
            2
          </span>
          {expanded && <span className={classes.sectionTitle}>Collect Important Data</span>}
        </Box>
        {evaluations?.filter(ev => ev.status !== 'REJECTED').length > 0 && (
          <Collapse in={selectedSection === Sections.EVALUATIONS}>
            {evaluations.filter(ev => ev.status !== 'REJECTED').map(evaluation => {
              return (
                <Box className={classes.evaluationContainer} key={evaluation.id}>
                  <p
                    className={clsx(classes.subsection, {
                      [classes.subsectionSelected]: currentEvaluation && currentEvaluation.id === evaluation.id,
                    })}
                    onClick={() => {
                      cleanupSelections();
                      setSelectedSection(Sections.EVALUATIONS);
                      setCurrentEvaluation(evaluation);
                    }}
                  >
                    {expanded ? evaluation.name : '•'}
                  </p>
                  <Collapse in={currentEvaluation && currentEvaluation.id === evaluation.id}>
                    {listHelper.getDcts().map(dct => {
                      return (
                        <p className={clsx(classes.dctName, {
                          [classes.subsectionSelected]: currentBlock && currentBlock.dctId === dct.dctId,
                        })} key={dct.dctId} onClick={() => {
                          setCurrentBlock(dct);
                        }}>
                          • {expanded && dct.text}
                        </p>
                      );
                    })}
                  </Collapse>
                </Box>
              );
            })}
          </Collapse>
        )}
        <Box
          className={classes.mainSection}
          onClick={() => {
            cleanupSelections();
            setSelectedSection(Sections.OPEN_SESSION);
          }}
        >
          <span
            className={clsx(classes.sectionCount, {
              [classes.sectionSelected]: selectedSection === Sections.OPEN_SESSION,
              [classes.sectionCompleted]: selectedSection === Sections.CLOSE_SESSION,
            })}
          >
            3
          </span>
          {expanded && <span className={classes.sectionTitle}>Open Session</span>}
        </Box>
        {sessionEnded && (
          <Box className={classes.mainSection}>
            <span
              className={clsx(classes.sectionCount, {
                [classes.sectionSelected]: selectedSection === Sections.CLOSE_SESSION,
              })}
            >
              4
            </span>
            {expanded && <span className={classes.sectionTitle}>Close the Session</span>}
          </Box>
        )}
      </Box>
      <Box className={classes.memberActionsWrapper}>
        {/* Member Actions */}
        <Button
          variant={btnType.OUTLINE}
          className={clsx([classes.actionBtn, classes.profileBtn])}
          onClick={() => onMemberActionClick(MEMBER_ACTIONS.PROFILE)}
        >
          <Icons glyph='member' />
        </Button>
        <Button
          variant={btnType.OUTLINE}
          className={clsx([classes.actionBtn, classes.amaBtn])}
          onClick={() => onMemberActionClick(MEMBER_ACTIONS.AMA)}
        >
          <Icons glyph='question-outlined' />
        </Button>
        <Button
          variant={btnType.OUTLINE}
          className={clsx([classes.actionBtn, classes.apptBtn])}
          onClick={() => onMemberActionClick(MEMBER_ACTIONS.SCHEDULE)}
        >
          <Icons glyph='calendar-checked-outlined' />
        </Button>
        <Button
          variant={btnType.OUTLINE}
          className={clsx([classes.actionBtn, classes.chatBtn])}
          onClick={() => onMemberActionClick(MEMBER_ACTIONS.CHAT)}
        >
          <Icons glyph='message' />
        </Button>
      </Box>
    </Box>
  );
};
export { SessionNavigator };
