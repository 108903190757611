import { ApiEndpoints } from '../../constants/ApiEndpoints';
import { baseRequest } from '../../utils/HttpClient';

export const getNotifications = queryParams => {
  return baseRequest(ApiEndpoints.GET_NOTIFICATIONS, null, null, queryParams);
};

export const getAdminNotifications = () => {
  return baseRequest(ApiEndpoints.GET_ADMIN_NOTIFICATIONS, null, null, null);
};

export const removeAdminNotification = notificationId => {
  return baseRequest(ApiEndpoints.REMOVE_ADMIN_NOTIFICATION, null, { notificationId }, null);
};
