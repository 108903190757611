import { Box, Skeleton } from '@mui/material';
import { useStyles } from './Loader.styles';

export const LoaderEnums = {
  CHAT_HEADER: 'CHAT_HEADER',
  CHAT_MESSAGE: 'CHAT_MESSAGE',
  CHAT_USER_CARD: 'CHAT_USER_CARD',
  CHAT_INPUT: 'CHAT_INPUT',
  SLOTS: 'SLOTS',
  APPOINTMENT: 'APPOINTMENT',
  PROVIDE_SEARCH_CARD: 'PROVIDE_SEARCH_CARD',
  SEARCH: 'SEARCH',
  NEXT_APPOINTMENT: 'NEXT_APPOINTMENT',
};

const ContentLoader = ({ numItems = 1, type }) => {
  const classes = useStyles();

  const renderUser = () => (
    <Box className={classes.userCard}>
      <Skeleton variant="circular" width={50} height={50} />
      <Box display="flex" flexDirection="column" gap={1} flex={1}>
        <Skeleton variant="rectangular" width="100%" height={13} style={{ marginTop: 8 }} />
        <Skeleton variant="rectangular" width="40%" height={10} />​
      </Box>
    </Box>
  );

  const messageItem = (style = {}) => (
    <Box className={classes.userCard} width="50%" style={style}>
      <Skeleton variant="circular" width={50} height={50} />
      <Box display="flex" flexDirection="column" gap={1} flex={1}>
        <Box display="flex" alignItems="center" gap={1} flex={1}>
          <Skeleton variant="rectangular" width="20px" height={10} />
          <Skeleton variant="rectangular" width="40px" height={10} />​
        </Box>
        <Skeleton variant="rectangular" width="100%" height={10} />​
      </Box>
    </Box>
  );

  const renderLoaderItem = (val, index) => {
    const key = `content-loader-${index}`;
    switch (val) {
      case LoaderEnums.CHAT_HEADER: {
        return (
          <Box key={key} className={classes.chatHeader}>
            <Box className={classes.userCard}>
              <Skeleton variant="circular" width={50} height={50} />
              <Box display="flex" flexDirection="column" flex={1} gap={1}>
                <Skeleton variant="rectangular" width="20%" height={10} />
                <Skeleton variant="rectangular" width="20%" height={10} />​
              </Box>
            </Box>
            <Box display="flex" flexDirection="column" flex={1} alignItems="flex-end" gap={1}>
              <Skeleton variant="rectangular" width="10%" height={10} />
              <Skeleton variant="rectangular" width="20%" height={10} />​
            </Box>
          </Box>
        );
      }
      case LoaderEnums.CHAT_MESSAGE: {
        return <Box key={key}>{messageItem()}</Box>;
      }
      case LoaderEnums.CHAT_USER_CARD: {
        return (
          <Box className={classes.userCard} key={key}>
            <Skeleton variant="circular" width={50} height={50} />
            <Box display="flex" flexDirection="column" gap={1} flex={1}>
              <Box display="flex" alignItems="center" gap={1} flex={1}>
                <Skeleton variant="rectangular" width="20%" height={10} />
                <Skeleton variant="rectangular" width="30%" height={10} />​
              </Box>
              <Skeleton variant="rectangular" width="100%" height={10} />​
            </Box>
          </Box>
        );
      }
      case LoaderEnums.CHAT_INPUT: {
        return (
          <Box display="flex" flexDirection="column" gap={1} flex={1} key={key}>
            <Skeleton variant="rectangular" width="100%" height={10} />
            <Box display="flex" alignItems="center" gap={1} flex={1}>
              <Skeleton variant="rectangular" width="20px" height={20} className={classes.square} />
              <Skeleton variant="rectangular" width="40px" height={10} />
              <Skeleton variant="rectangular" width="80px" height={10} />​
            </Box>
          </Box>
        );
      }
      case LoaderEnums.SEARCH: {
        return (
          <Box display="flex" alignItems="center" gap={1} flex={1} key={key}>
            <Skeleton variant="rectangular" width="20px" height={20} className={classes.square} />
            <Skeleton variant="rectangular" width="40px" height={10} />
            <Skeleton variant="rectangular" width="80px" height={10} />​
          </Box>
        );
      }
      case LoaderEnums.APPOINTMENT: {
        return (
          <Box className={classes.contentLoaderCard} key={key}>
            <Box display="flex" justifyContent="space-between">
              <Skeleton variant="rectangular" width="20%" height={10} />
              <Skeleton variant="rectangular" width="20%" height={10} />
            </Box>
            <Skeleton variant="rectangular" width="100%" height={20} />
            {renderUser()}
          </Box>
        );
      }
      case LoaderEnums.SLOTS: {
        return (
          <Box key={key} display="flex" justifyContent="space-between" flex={1}>
            <Skeleton variant="rectangular" width="30%" height={18} />
            <Skeleton variant="rectangular" width="20%" height={18} />
            <Skeleton variant="rectangular" width="30%" height={18} />
          </Box>
        );
      }
      case LoaderEnums.PROVIDE_SEARCH_CARD:
        return (
          <Box key={key} display="flex" flexDirection="column" gap={2}>
            {renderUser()}
            <Skeleton variant="rectangular" width="100%" height={32} />
            <Skeleton variant="rectangular" width="100%" height={32} />
          </Box>
        );
      case LoaderEnums.NEXT_APPOINTMENT:
        return (
          <Box gap={2} display="flex" flexDirection="column" key={key}>
            <Box display="flex" gap={4} flex={1}>
              <Box display="flex" gap={1} flex={1}>
                <Skeleton variant="rectangular" width="20%" height={32} />
                <Skeleton variant="rectangular" width="50%" height={32} />
              </Box>
              <Skeleton variant="rectangular" width="20%" height={32} />
            </Box>
            <Skeleton variant="rectangular" width="100%" height={1} />
            <Box display="flex" gap={4}>
              {renderUser()}
              <Skeleton variant="rectangular" width={50} height={32} style={{ marginTop: 8 }} />
            </Box>
          </Box>
        );
      default:
        // LoaderEnums.CHAT_LOADER
        return (
          <Box gap={4} display="flex" flexDirection="column" key={key}>
            <Box display="flex" gap={1} justifyContent="space-between">
              <Skeleton variant="rectangular" width="20%" height={32} />
              <Skeleton variant="rectangular" width="20%" height={32} />
            </Box>
            <Box display="flex" gap={1} flexDirection="column" justifyContent="space-between">
              <Skeleton variant="rectangular" width="100%" height={24} />
              <Skeleton variant="rectangular" width="90%" height={24} />
              <Skeleton variant="rectangular" width="100%" height={12} />
              <Skeleton variant="rectangular" width="100%" height={12} />
              <Skeleton variant="rectangular" width="50%" height={12} />
            </Box>
            <Box display="flex" gap={1} flexDirection="column" justifyContent="space-between">
              <Skeleton variant="rectangular" width="100%" height={24} />
              <Skeleton variant="rectangular" width="100%" height={24} />
              <Skeleton variant="rectangular" width="100%" height={24} />
            </Box>
            <Skeleton variant="rectangular" width="100%" height={24} />
          </Box>
        );
    }
  };

  const renderContentLoader = () => {
    const loaderViews = [];
    for (let i = 0; i < numItems; i++) {
      loaderViews.push(renderLoaderItem(type, i));
    }
    return loaderViews;
  };

  return (
    <Box display="flex" flexDirection="column" gap={2} className={classes.container}>
      {renderContentLoader()}
    </Box>
  );
};

export { ContentLoader };
