import React, { useMemo } from 'react';
import dayjs from 'dayjs';
import { ProfileInfo } from '../../../../packages/ui/templates/profile-info';
import { Box } from '@mui/material';
import {
  btnType,
  Button,
  colors,
  fontWeight,
  Heading,
  headingLevel,
  iconBtnPosition,
  IconButton,
  Icons,
  Text,
  TextField,
} from '../../../../packages';
import history from '../../../../utils/history';
import { useStyles } from './AppointmentEvaluationTopBar.styles';

const AppointmentEvaluationTopBar = ({ appointment, sessionDuration, analysisInProgress, onReprocess }) => {
  const classes = useStyles();

  const member = useMemo(
    () => appointment?.participants.filter(participant => !participant.isPractitioner)?.[0],
    [appointment],
  );

  const provider = useMemo(
    () => appointment?.participants.filter(participant => participant.isPractitioner)?.[0],
    [appointment],
  );

  const renderInfo = (label, text, className = '') => {
    return (
      <Box className={className}>
        <TextField label={label} text={text || '-'} />
      </Box>
    );
  };

  const getFriendlyDuration = () => {
    if (!sessionDuration) {
      return '-';
    }
    const minutes = Math.floor(sessionDuration / 60);
    const seconds = Math.ceil(sessionDuration % 60);
    return seconds > 0 ? `${minutes} min ${seconds} sec` : `${minutes} min`;
  };

  return (
    <Box className={classes.container}>
      <Box display="flex" justifyContent="space-between" alignItems="center" flex="1">
        <Box className={classes.leftPane}>
          <Button variant={btnType.OUTLINE} className={classes.btnBack} onClick={history.back}>
            <Icons color={colors.primary500} glyph="arrow-left" />
          </Button>
          {/* <Avatar src={member?.profilePicture} name={member?.name} size={48} variant={avatarType.CIRCLE} /> */}
          <Box className={classes.info}>
            <Heading level={headingLevel.M} weight={fontWeight.SEMI_BOLD} className={classes.title}>
              {appointment.serviceName}
            </Heading>
            <Text weight={fontWeight.MEDIUM} className={classes.subtitle}>
              {dayjs(appointment.startTime).format('MMMM D, YYYY')} •{' '}
              {dayjs(appointment.startTime).format('hh:mm A')} -{' '}
              {dayjs(appointment.endTime).format('hh:mm A')}
            </Text>
          </Box>
        </Box>
        <Box className={classes.rightPane} gap={3}>
          <ProfileInfo
            type="member"
            photo={member?.profilePicture}
            nickName={`${member?.firstName || ''} ${member?.lastName || ''}`}
            fullName={member?.uuid}
            memberId={member?.participantId}
          />
          <Box className={classes.divider} />
          {/* eslint-disable jsx-a11y/aria-role */}
          <ProfileInfo
            type="provider"
            photo={provider?.profilePicture}
            role="Provider"
            fullName={provider?.name}
          />
          <Box className={classes.divider} />
          {renderInfo('Date of Birth', dayjs(member?.dateOfBirth).format('MM-DD-YYYY'))}
          <Box className={classes.divider} />
          {renderInfo('Session Duration', getFriendlyDuration())}
          <Box className={classes.divider} />
          <IconButton
            disabled={analysisInProgress}
            onClick={onReprocess}
            variant={btnType.TEXT}
            icon="analyze"
            iconPosition={iconBtnPosition.RIGHT}
          >
            {analysisInProgress ? `AI Analysis in Progress \u00A0\u00A0` : `Rerun AI Analysis \u00A0\u00A0`}
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

export { AppointmentEvaluationTopBar };
