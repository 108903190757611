import {
  btnSize,
  btnType,
  Button,
  Checkbox,
  Collapsable,
  colors,
  IconButton,
  Icons,
  IiconPosition,
  Input,
  inputSize,
  RadioGroup,
  radioGroupType,
  Select,
  selectType,
  Text,
  TextError,
} from '../../../../../../packages';
import { TabPanel, Tabs } from '../../../../../../packages/ui/atoms/tabs';
import { UploadFile } from '../../../../../../packages/ui/templates/upload-file';
import { Box, Drawer } from '@mui/material';

import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';

import Heading from '../../../../../../components/v2/Heading';
import cx from 'clsx';
import { HeadingLevel } from '../../../../../../components/v2/Heading/index.types';
import Typography from '../../../../../../components/v2/Typography';
import { typography } from '../../../../../../components/v2/Typography/index.constant';

import dayjs from 'dayjs';
import CreateConnectionModal from '../../../../../../layouts/base/CreateConnectionModal';
import { deleteCredentialingDocument } from '../../../../../../services/datalab/datalab.service';
import { getAuth } from '../../../../../../redux/modules/auth/selectors';
import { useStyles } from '../../../index.styles';
import DatePickerInput from '../../../../../admin/claim-detail/components/DatePicker';
import FormListItems from '../../../common/forms/form-list-item';
import {
  allowedFutureDateKeys,
  defaultToYesBooleanKeys,
  renderDatePicker,
  setToPresentDateKeys,
  TAB_VIEW,
} from '../../index.constants';
import { isFieldVisible, removeIndex, shouldRenderKey, unFlattenData } from '../../../common/common.utils';
import { CardStatus } from '../../../common/card/profile-section-card';
import UploadFileProgress from '../../../../member-detail/components/insurance-information/upload-flie-progress';
import { showSnackbar } from '../../../../../../redux/modules/snackbar';
import { convertByteToString } from '../../../../../../utils';
import { transformData } from '../../../common/utils.constants';

const SubmitDrawer = ({
  open,
  title,
  subTitle,
  isTabView,
  onClose,
  missedKeys,
  filledKeys,
  editMode,
  list,
  onEdit,
  onAddArrayItem,
  onFormSubmit,
  loading,
  selectedRecord,
  fileType,
  newRecord = false,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { meta } = useSelector(getAuth);
  const [currentTab, setCurrentTab] = useState(TAB_VIEW.INFORMATION);
  const [checkboxStates, setCheckboxStates] = useState({});
  const [record, setRecord] = useState(selectedRecord);
  const [fileData, setFileData] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [disableButton, setDisableButton] = useState(true);
  const [filesDataList, setFilesDataList] = useState({ filled: [], missing: [], list: [], title: undefined });
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    setRecord(selectedRecord);
    if (
      selectedRecord?.documentStatus === CardStatus.FAILED ||
      selectedRecord?.documentStatus === CardStatus.DOCUMENT_NEEDED
    ) {
      setCurrentTab(TAB_VIEW.FILE);
    }
  }, [selectedRecord]);

  const tabs = [
    { tabKey: TAB_VIEW.INFORMATION, label: 'Information' },
    { tabKey: TAB_VIEW.FILE, label: 'Files' },
  ];

  const renderMissedKeys =
    record?.documentStatus === CardStatus.DISCREPANCY ? filesDataList.missing : missedKeys;
  const renderFilledKeys =
    record?.documentStatus === CardStatus.DISCREPANCY ? filesDataList.filled : filledKeys;

  const initialValues = useMemo(() => {
    let missed = [];
    let filled = [];
    if (missedKeys) {
      missed = renderMissedKeys;
    }
    if (filledKeys) {
      filled = renderFilledKeys;
    }
    return filled
      .concat(missed)
      .filter(item => !item.section)
      .reduce((acc, curr) => {
        if (curr.key) {
          if (curr.badge) {
            acc[curr.key] =
              defaultToYesBooleanKeys.includes(removeIndex(curr.key)) || curr.badge.label === true;
          } else if (renderDatePicker?.some(dateKey => dateKey === removeIndex(curr.key))) {
            acc[curr.key] = curr.value === '' ? dayjs().format('MM/DD/YYYY') : curr.value;
          } else if (curr.key === 'dataType') {
            acc[curr.key] = curr.value === '' ? 'DEA' : curr.value;
          } else {
            acc[curr.key] = curr.value !== undefined && curr.value !== null ? curr.value : '';
          }
        }
        return acc;
      }, {});
  }, [renderMissedKeys, renderFilledKeys]);

  const {
    errors: formErrors,
    values,
    handleChange,
    handleSubmit,
    setValues,
    setFieldValue,
    touched,
  } = useFormik({
    // activeLocation-practiceName
    initialValues,
    enableReinitialize: record?.documentStatus === CardStatus.DISCREPANCY,
    onSubmit: () => {
      const formValues = JSON.parse(JSON.stringify(values));
      Object.keys(formValues)?.forEach(key => {
        const visible = isFieldVisible(key, values);
        if (!visible) {
          formValues[key] = key?.toLowerCase()?.includes('has') ? false : '';
        }
      });
      const structuredData = unFlattenData(formValues);
      if (imageFile) {
        const dataWithFile = {
          ...structuredData,
          fileData: {
            bucket: fileData.bucket,
            file: fileData.file,
            fileType: fileData.type,
            fileName: fileData?.fileName,
            fileSize: fileData?.fileSize,
            fileExtension: fileData?.fileExtension,
          },
        };
        onFormSubmit(dataWithFile, filledKeys?.length > 0);
      } else {
        onFormSubmit(structuredData, filledKeys?.length > 0);
      }
    },
  });

  useEffect(() => {
    if (record) {
      const filesData = transformData(record?.filesData, fileType);
      setFilesDataList({
        filled: filesData?.filled || [],
        missing: filesData?.missing || [],
        list: filesData?.list || [],
        title: '',
      });
    }
  }, [record]);

  useEffect(() => {
    const isAnyValueNotEmpty = Object.keys(values)
      ?.filter(key => shouldRenderKey(removeIndex(key)) && typeof values[key] !== 'boolean')
      .some(key => values[key] !== '');
    setDisableButton(!isAnyValueNotEmpty);
  }, [values]);

  const onUploadResponse = (response, item) => {
    if (response.success) {
      const bodyRequest = {
        recordId: record?._id,
        bucket: response?.response?.bucket,
        file: response?.response?.key,
        type: fileType,
        fileName: item?.name,
        fileSize: item?.size,
        fileExtension: item?.type,
      };
      setFileData(bodyRequest);
    } else {
      console.log('upload failed');
    }
  };

  const onAcceptedFiles = files => {
    const file = files[0];
    if (file.size > 5000000) {
      dispatch(
        showSnackbar({
          snackType: 'error',
          snackMessage: 'Only files upto 5mb are allowed',
        }),
      );
    } else {
      setImageFile(file);
    }
  };

  const onMainAction = e => {
    if (editMode) {
      handleSubmit(e);
    } else {
      onEdit();
    }
  };

  const onChangeRadioButtons = e => {
    const { name, value } = e.target;
    const booleanValue = value === 'true' ? true : value === 'false' ? false : value;
    void setFieldValue(name, booleanValue);
  };

  const formatAndFillDate = date => {
    let parsedDate = dayjs(
      date,
      ['MM/DD/YYYY', 'MM/YYYY', 'YYYY', 'M/D/YYYY', 'MM/D/YYYY', 'M/DD/YYYY'],
      true,
    );
    const currentDate = dayjs();

    if (!parsedDate?.isValid()) {
      parsedDate = currentDate;
    } else {
      if (!parsedDate.year()) parsedDate = parsedDate.year(currentDate.year());
      if (!parsedDate.month()) parsedDate = parsedDate.month(0); // January is month 0 in dayjs
      if (!parsedDate.date()) parsedDate = parsedDate.date(1);
    }

    return parsedDate;
  };

  const handleCheckboxChange = key => event => {
    const isChecked = event.target.checked;
    setCheckboxStates(prevState => ({
      ...prevState,
      [key]: isChecked,
    }));
    if (isChecked) {
      const currentDate = dayjs().format('MM/DD/YYYY');
      void setFieldValue(key, currentDate);
    }
  };

  const deleteCard = async () => {
    try {
      const body = {
        type: fileType,
        id: record?._id,
        providerId: meta.userId,
      };
      const { data } = await deleteCredentialingDocument(body);
      if (data?.success) {
        dispatch(
          showSnackbar({
            snackType: 'success',
            snackMessage: `${title} removed successfully.`,
          }),
        );
        setIsModalOpen(false);
        onClose();
      }
    } catch (error) {
      dispatch(
        showSnackbar({
          snackType: 'error',
          snackMessage: `unable to remove ${title?.toLowerCase()}.`,
        }),
      );
    }
  };

  useEffect(() => {
    if (editMode && list?.length > 0) {
      list.forEach(item => {
        setToPresentDateKeys.includes(item.key?.replace(/-(\d+)-/g, '.')) &&
          setCheckboxStates(prevState => ({
            ...prevState,
            [item.key]: item.value === 'PRESENT' || item.value === dayjs().format('MM/DD/YYYY'),
          }));
      });
    }
  }, [editMode, list]);

  const renderDangerZone = () => (
    <Box className={classes.formBlock} alignItems="flex-start">
      <Text className={classes.title}>Danger zone</Text>
      <IconButton
        icon="delete-outlined-2"
        className={classes.removeContentBtn}
        onClick={() => setIsModalOpen(true)}
      >
        Remove {title?.toLowerCase()}
      </IconButton>
    </Box>
  );

  const renderFields = (item, index) => {
    if (!shouldRenderKey(item.key) || !isFieldVisible(item.key, values)) {
      return null;
    }
    const { section, label, value, key, badge, type } = item;
    const isDiscrepant =
      record?.issues?.includes(key?.replaceAll('-', '.')) &&
      record?.documentStatus === CardStatus.DISCREPANCY;
    if (section) {
      return (
        <Typography
          key={index}
          {...typography.overline.small}
          color={colors.neutral500}
          sx={{ textTransform: 'uppercase' }}
        >
          {label}
        </Typography>
      );
    }
    if (type === 'add') {
      return (
        <Box
          key={index}
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
          }}
        >
          <IconButton
            variant={btnType.TEXT}
            icon="plus"
            onClick={() => {
              const sectionKey = key.replaceAll('-add', '');
              const nums = Object.keys(values)
                .filter(k => k.includes(`${sectionKey}-`))
                .map(k => {
                  const segments = k.split('-');
                  const numbers = segments.filter(s => !Number.isNaN(Number(s)));
                  const num = numbers[numbers?.length - 1];
                  return Number(num);
                })
                .filter(Boolean);
              let max = Math.max(...nums);
              if (max === Infinity || max === -Infinity) {
                max = 0;
              }
              onAddArrayItem(sectionKey, max, index, setValues);
            }}
          >
            {label}
          </IconButton>
        </Box>
      );
    }
    if (key && !Array.isArray(value)) {
      const isSpecialDateKey = setToPresentDateKeys.includes(removeIndex(key));
      return (
        <Box key={index}>
          <Typography {...typography.body.normal.small.bold} mb={1}>
            {label}
          </Typography>
          {renderDatePicker?.some(dateKey => dateKey === removeIndex(key)) ||
          key?.toLowerCase()?.includes('date') ? (
            <Box
              className={cx({
                [classes.dateInput]: true,
                [classes.discrepantDateInput]: isDiscrepant,
              })}
              sx={{ display: 'flex', alignItems: 'center' }}
            >
              <DatePickerInput
                date={formatAndFillDate(values[key])}
                disableFuture={!allowedFutureDateKeys?.some(allowedKey => allowedKey === key)}
                onChange={date => {
                  const formattedDate = date?.isValid()
                    ? date.format('MM/DD/YYYY')
                    : dayjs().format('MM/DD/YYYY');
                  void setFieldValue(key, formattedDate);
                }}
                disabled={checkboxStates[key]}
              />
              {isSpecialDateKey && (
                <Typography
                  {...typography.body.normal.small.bold}
                  sx={{ display: 'flex', alignItems: 'center', paddingLeft: '8px' }}
                >
                  <Checkbox
                    checked={checkboxStates[key]}
                    onChange={handleCheckboxChange(key)}
                    className={classes.checkbox}
                  />
                  Present
                </Typography>
              )}
            </Box>
          ) : badge ? (
            <RadioGroup
              variant={radioGroupType.HORIZONTAL}
              options={[
                { label: 'Yes', value: true },
                { label: 'No', value: false },
              ]}
              onChange={onChangeRadioButtons}
              name={key}
              value={values[key] === true ? true : values[key] === false ? false : ''}
            />
          ) : key === 'dataType' && newRecord ? (
            <Select
              value={values[key]}
              name={key}
              variant={selectType.SECONDARY}
              emptyText=""
              displayEmpty
              options={[
                { label: 'DEA', value: 'DEA' },
                { label: 'CDS', value: 'CDS' },
              ]}
              onChange={handleChange}
              sx={{ width: '100%' }}
            />
          ) : (
            <Input
              fullWidth
              name={key}
              value={values[key] || ''}
              onChange={handleChange}
              endAdornment={
                isDiscrepant ? <Icons glyph="alert-filled" color={colors.destructive600} /> : null
              }
              className={cx({
                [classes.inputDiscrepant]: isDiscrepant,
              })}
              size={inputSize.M}
            />
          )}
          {isDiscrepant && (
            <Typography {...typography.body.s.medium} sx={{ mt: 1 }}>
              Information discrepancy with the CAQH database. Update {label}.
            </Typography>
          )}
          <TextError errorMsg={touched[key] ? formErrors[key]?.toString() : null} />
        </Box>
      );
    }
    return null;
  };

  const renderForm = () => {
    return (
      <form onSubmit={onMainAction}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
          {/* {filledKeys?.length === 0 && missedKeys?.length > 0 ? ( */}
          {/*  <Box className={classes.uploadForm}> */}
          {/*    {missedKeys.map((item, index) => renderFields(item, index))} */}
          {/*  </Box> */}
          {/* ) : (  */}
          <>
            {renderMissedKeys.filter(item => shouldRenderKey(item.key) && isFieldVisible(item.key, values))
              ?.length > 0 && (
              <Collapsable
                open
                className={classes.collapsable}
                iconColor={colors.neutral700}
                iconPosition={IiconPosition.RIGHT}
                labelExtend={
                  <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                    <Icons glyph="info-outlined" color={colors.destructive500} />
                    <Typography {...typography.body.m.semibold}>Missing information</Typography>
                  </Box>
                }
              >
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2.5, p: 5 }}>
                  {renderMissedKeys.map((item, index) => renderFields(item, index))}
                </Box>
              </Collapsable>
            )}
            {renderFilledKeys?.filter(item => shouldRenderKey(item.key) && isFieldVisible(item.key, values))
              ?.length > 0 && (
              <Collapsable
                open
                className={classes.collapsable}
                iconColor={colors.neutral700}
                iconPosition={IiconPosition.RIGHT}
                labelExtend={
                  <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                    <Icons glyph="checked-circle-outlined" color={colors.success500} />
                    <Typography {...typography.body.m.semibold}>Filled information</Typography>
                  </Box>
                }
              >
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 5, p: 5 }}>
                  {renderFilledKeys
                    .filter(item => shouldRenderKey(item.key))
                    .map((item, index) => renderFields(item, index))}
                </Box>
              </Collapsable>
            )}
            {record?.dataSource === 'USER' && renderDangerZone()}
          </>
          {/*  )} */}
        </Box>
      </form>
    );
  };

  const renderTabViews = () => (
    <>
      <Tabs
        options={record?.documentStatus !== CardStatus.REVIEW_NEEDED ? tabs : [tabs[0]]}
        value={currentTab}
        onChange={setCurrentTab}
        className={classes.drawerTabs}
      />
      <TabPanel value={currentTab} tabKey={tabs[0].tabKey}>
        <FormListItems list={[...filledKeys, ...missedKeys]} filesDataList={filesDataList} record={record} />
      </TabPanel>
      {record?.documentStatus !== CardStatus.REVIEW_NEEDED && (
        <TabPanel value={currentTab} tabKey={tabs[1].tabKey}>
          <Box sx={{ p: 3 }}>
            <Typography {...typography.body.m.semibold} color={colors.neutral900} sx={{ mb: 3 }}>
              Files
            </Typography>
            <Box className={classes.filesContainer}>
              {record?.files?.map((file, index) => (
                <Box
                  key={index}
                  className={classes.fileItem}
                  sx={{
                    borderBottom: index === record?.files?.length - 1 ? '' : `1px solid ${colors.neutral100}`,
                  }}
                >
                  <Box className={classes.fileTitleContainer}>
                    <Icons
                      className={classes.fileThumbnailIcon}
                      glyph={file?.fileExtension === 'application/pdf' ? 'file-pdf' : 'file-jpeg'}
                    />
                    <Box className={classes.fileTitle}>
                      <Typography {...typography.body.normal.small.medium}>{file?.fileName}</Typography>
                      <Typography {...typography.body.s.medium} color={colors.neutral500}>
                        {convertByteToString(file?.fileSize)}
                      </Typography>
                    </Box>
                  </Box>
                  <Box className={classes.fileButtons}>
                    <IconButton
                      variant={btnType.TEXT}
                      icon="eye-outlined"
                      className={classes.fileViewButton}
                      onClick={() => window.open(file?.signedUrl, '_blank')}
                    >
                      View
                    </IconButton>
                    <IconButton
                      variant={btnType.TEXT}
                      icon="download"
                      className={classes.fileDownloadButton}
                      onClick={() => window.open(file?.signedUrl, '_blank')}
                    >
                      Download
                    </IconButton>
                  </Box>
                </Box>
              ))}
            </Box>
            {imageFile ? (
              <UploadFileProgress
                file={imageFile}
                fileName={imageFile?.name || 'logo.png'}
                fileSize={imageFile?.size || 1024}
                s3Folder={`documents/${fileType}/`}
                onUploaded={() => console.log('File uploaded')}
                onUploadResponse={onUploadResponse}
                onRemove={() => {
                  setImageFile(null);
                }}
              />
            ) : (
              <UploadFile
                onAcceptedFiles={onAcceptedFiles}
                description="PDF, PNG, JPG file up to 5MB"
                fileType="application/pdf,image/png,image/jpg,image/png,image/jpeg"
                iconAltered
              />
            )}
          </Box>
        </TabPanel>
      )}
    </>
  );

  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', flex: 1 }}>
          <Box className={classes.leftContainer}>
            <Box sx={{ p: 3 }}>
              <Box className={classes.header}>
                <Heading variant={HeadingLevel.H5}>{title}</Heading>
                {!editMode && <Icons onClick={onClose} className={classes.closeIcon} glyph="close" />}
              </Box>
              {subTitle && editMode && <Typography {...typography.body.m.medium}>{subTitle}</Typography>}
            </Box>
            {isTabView ? (
              renderTabViews()
            ) : (
              <FormListItems list={list} filesDataList={filesDataList} record={record} />
            )}
          </Box>
          {editMode && <Box className={classes.rightContainer}>{renderForm()}</Box>}
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            p: 2,
            borderTop: `1px solid ${colors.neutral100}`,
          }}
        >
          <Button variant={btnType.TEXT} size={btnSize.SMALL} onClick={onClose}>
            {editMode ? 'Cancel' : 'Close'}
          </Button>
          <Button
            size={btnSize.SMALL}
            disabled={loading || (editMode && disableButton)}
            onClick={onMainAction}
          >
            {loading ? 'Saving' : !editMode ? 'Edit' : filledKeys?.length === 0 ? 'Save' : 'Update'}
          </Button>
        </Box>
      </Box>
      <CreateConnectionModal
        icon="x-circle-outlined"
        open={isModalOpen}
        title={`Remove ${title?.toLowerCase()}?`}
        content={`Do you want to remove ${title?.toLowerCase()}?`}
        lbtnLabel="Cancel"
        rbtnLabel="Confirm"
        onClose={() => setIsModalOpen(false)}
        onCancel={() => setIsModalOpen(false)}
        onSubmit={deleteCard}
        isLoading={false}
        isDelete
      />
    </Drawer>
  );
};

export default SubmitDrawer;
