// colors
import { colors } from '../../../../../packages';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  btnGroupIcon: {
    color: colors.neutral900,
    backgroundColor: colors.white,
    padding: '14px 18px',
    transition: '0s',
    height: 48,
    '&:hover': {
      color: colors.white,
      backgroundColor: colors.neutral900,
    },
  },
  btnGroupIconActive: {
    color: colors.white,
    backgroundColor: colors.neutral900,
  },
});
