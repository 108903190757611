// colors
import { colors } from '../../../../../packages/colors';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  modal: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalContent: {
    position: 'relative',
    width: 480,
  },
  editIcon: {
    width: '40px',
    height: '40px',
    flexShrink: '0px',
  },
  content: {
    background: colors.white,
    boxShadow:
      '0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
    borderRadius: 8,
    '&:hover $cardLink': {
      display: 'flex',
    },
    padding: '32px 32px 16px 32px',
  },
  closeBtn: {
    width: '24px',
    height: '24px',
    position: 'absolute',
    top: 25,
    right: 25,
    cursor: 'pointer',
  },
  title: {
    fontSize: '24px',
    color: colors.neutral900,
    fontWeight: 700,
    margin: 0,
    marginBottom: 16,
    fontFamily: 'Manrope',
  },
  subtitle: {
    fontSize: 12,
    lineHeight: '20px',
    fontWeight: 800,
    letterSpacing: '1px',
    color: colors.neutral700,
    margin: '0px !important',
    paddingTop: '8px',
  },
  subtitle2: {
    fontSize: 12,
    marginTop: '24px !important',
    lineHeight: '20px',
    fontWeight: 800,
    letterSpacing: '1px',
    color: colors.neutral700,
    marginBottom: '0px !important',
  },
  label: {
    fontSize: 16,
    fontWeight: 600,
    color: colors.neutral900,
    display: 'block',
    marginTop: 24,
    marginBottom: 8,
  },
  input: {
    height: 56,
  },
  btn: {
    height: 64,
    borderRadius: 8,
    border: `1px solid ${colors.neutral300}`,
    width: '100%',
    marginTop: 24,
  },
  okBtn: {
    backgroundColor: colors.primary500,
    color: colors.white,
    border: 'none',
  },
  loading: {
    marginRight: 8,
  },
  itemText: {
    fontSize: '16px',
    fontWeight: 500,
    color: colors.neutral500,
    lineHeight: '24px',
  },
  itemButtonText: {
    fontSize: '14px',
    fontWeight: 600,
    color: colors.neutral900,
    lineHeight: '20px',
  },
  shortcutItem: {
    display: 'flex',
    padding: '16px 0px',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  shortcutButton: {
    display: 'flex',
    height: '24px',
    padding: '8px 12px',
    alignItems: 'center',
    border: '1px',
    borderStyle: 'solid',
    borderRadius: '6px',
    borderColor: colors.neutral300,
    boxShadow: '0px 2px 0px 0px #BEC9D3',
  },
  shortcutButtonsBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  generalBtnComboText: {
    fontSize: '18px',
    fontWeight: 500,
    fontStyle: 'normal',
    color: colors.neutral900,
    lineHeight: '28px',
    padding: '0px 8px',
  },
  navigationBtnComboText: {
    fontSize: '14px',
    fontWeight: 600,
    fontStyle: 'normal',
    color: colors.neutral900,
    lineHeight: '20px',
    padding: '0px 8px',
  },
  shortcutButtonIcon: {
    width: '16px',
    height: '16px',
    alignItems: 'center',
    '& path': {
      fill: colors.neutral800,
    },
  },
});
