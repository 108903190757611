import { Box } from '@mui/material';
import { fontWeight, Text } from '../../../../../packages';
import { useStyles } from './InfoCard.styles';

const InfoCard = ({ title, value }) => {
  const classes = useStyles();

  return (
    <Box className={classes.infoBlock}>
      <Text className={classes.infoBlockLabel} weight={fontWeight.EXTRA_BOLD}>
        {title}
      </Text>
      <Text className={classes.infoBlockValue} weight={fontWeight.BOLD}>
        {value}
      </Text>
    </Box>
  );
};

export { InfoCard };
