import { Box } from '@mui/material';
import { useStyles } from './Welcome.styles';
import { Icons } from '../../../../../../icons';
import LoveCare from '../../../../../../icons/lotties/lottieIcons/LoveCare';

const Welcome = ({ promptsVisible }) => {
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      <Box className={classes.welcomeBox}>
        <Box className={classes.welcomeFrame}>
          <LoveCare className={classes.animatedIcon} />
          <p className={classes.sectionTitle}>
            Welcome them with a warm greeting
          </p>
          <p className={classes.sectionSubtitle}>
            This is your time to shine!
          </p>
        </Box>
        {promptsVisible && (
          <Box className={classes.askBox}>
            <Box className={classes.askContent}>
              <Box className={classes.firstLineLayout}>
                <p className={classes.firstLine}>
                  You may want to <span style={{fontWeight: 700}}>ask</span> things like:
                </p>
                <Icons glyph={"question-outlined"} className={classes.askIcon}/>
              </Box>
              <p className={classes.instruction}>
                • How have you been since our last session?
              </p>
              <p className={classes.instruction}>
                • Is there anything you’d like to share before we get started?
              </p>
            </Box>
          </Box>
        )}
        {promptsVisible && (
          <Box className={classes.sayBox}>
            <Box className={classes.askContent}>
              <Box className={classes.firstLineLayout}>
                <p className={classes.firstLine}>
                  You may want to <span style={{fontWeight: 700}}>say</span> things like:
                </p>
                <Icons glyph={"chat-outlined"} className={classes.sayIcon}/>
              </Box>
              <p className={classes.instruction}>
                • We’ll be reviewing our treatment plan and checking in on how things are going.
              </p>
              <p className={classes.instruction}>
                • I’ll ask you some questions to make sure we’re on track.
              </p>
              <p className={classes.instruction}>
                • We can use this time however is most beneficial for you.
              </p>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
}
export { Welcome };
