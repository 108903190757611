import { useState } from 'react';
import cx from 'clsx';
// components
import { Input, inputSize } from '../../atoms/input';
// styles
import { useStyles } from './MessageInputField.styles';

const MessageInputField = ({ className = '', onSubmit, placeholder = 'Message', value, ...rest }) => {
  const classes = useStyles();
  const [isFocus, setIsFocus] = useState(false);

  const onToggleFocus = () => setIsFocus(!isFocus);

  const onPressEnter = event => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      onSubmit();
    }
  };

  return (
    <Input
      multiline
      onKeyPress={onPressEnter}
      className={cx(classes.isChat, {
        [classes.root]: true,
        [className]: true,
        [classes.focus]: isFocus,
      })}
      onFocus={onToggleFocus}
      onBlur={onToggleFocus}
      size={inputSize.M}
      placeholder={placeholder}
      value={value}
      {...rest}
    />
  );
};

export { MessageInputField };
