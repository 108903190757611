import { useEffect, useState } from 'react';
import clsx from 'clsx';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import {
  btnSize,
  btnType,
  Button,
  colors,
  fontWeight,
  Heading,
  headingLevel,
  Input,
  inputSize,
  Text,
  textLevel,
} from '../../../../packages';
import { Drawer, drawerPosition, drawerType } from '../../../../packages/ui/organisms/drawer';
import { DomainTypesEnum } from '../../../../constants/CommonConstants';
import * as MemberService from '../../../../services/member/member.service';
import { useStyles } from '../ClaimDetail.styles';
import ProcedureCodeFields from './ProcedureCodeFields';

const defaultData = {
  totalClaim: 0,
  totalCost: 0,
  totalProviderFee: 0,
  patientResponsibility: 0,
  cptList: [],
};

const SubscriberDrawer = ({ data, open, onClose, onSubmit }) => {
  const classes = useStyles();
  const [formData, setFormData] = useState({ ...data });
  const [errors, setErrors] = useState(defaultData);
  const [cptListErrors, setCptListErrors] = useState([]);

  const labels = {
    totalClaim: 'Total claim',
    patientResponsibility: 'Patient responsibility',
  };

  const onChangeField = field => e => {
    if (errors[field]) {
      setErrors(s => ({ ...s, [field]: '' }));
    }
    setFormData(old => ({
      ...old,
      [field]: e.target?.value,
    }));
  };

  const onEditInfo = () => {
    const cptErrs = JSON.parse(JSON.stringify(cptListErrors));
    formData.cptList.forEach((item, index) => {
      cptErrs.push({
        cptCode: 0,
        relatedDiagnosis: 0,
        icd10Code: 0,
        providerFee: 0,
      });
      if (item.cptCode) cptErrs[index].cptCode = 0;
      else cptErrs[index].cptCode = 1;
      item.relatedDiagnosis ? (cptErrs[index].relatedDiagnosis = 0) : (cptErrs[index].relatedDiagnosis = 1);
      item.icd10Code ? (cptErrs[index].icd10Code = 0) : (cptErrs[index].icd10Code = 1);
      item.providerFee ? (cptErrs[index].providerFee = 0) : (cptErrs[index].providerFee = 1);
    });
    setCptListErrors(cptErrs);
    if (!cptErrs.find(item => item.cptCode || item.relatedDiagnosis || item.icd10Code || item.providerFee))
      onSubmit(formData, 'claim');
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      variant={drawerType.FORM}
      position={drawerPosition.RIGHT}
      title="Edit Claim"
      submitBtnTitle="Update"
      onSubmit={onEditInfo}
    >
      {Object.keys(labels)?.map(d => (
        <>
          <Box className={classes.inputWrapper} key={d}>
            <label htmlFor={d}>
              <Text level={textLevel.S} weight={fontWeight.BOLD}>
                {labels[d]}
              </Text>
            </label>
            <Input
              name={d}
              value={formData[d] || ''}
              onChange={onChangeField(d)}
              size={inputSize.M}
              placeholder={`Enter your ${labels[d]}`}
            />
          </Box>
        </>
      ))}
      <ProcedureCodeFields values={formData} cptListErrors={cptListErrors} />
    </Drawer>
  );
};

const Claim = ({ onSubmit, data = defaultData, patientId = null }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [diagnoses, setDiagnoses] = useState([]);
  useEffect(() => {
    if (patientId)
      MemberService.getDomainTypes({ patientId })
        .then(res => {
          setDiagnoses(
            res.data?.types
              ?.filter(
                d => d.typeName?.toLocaleLowerCase() === DomainTypesEnum.DIAGNOSES.toLocaleLowerCase(),
              )?.[0]
              ?.relatedElements?.map(di => {
                return {
                  name: di?.name,
                  id: di?.id,
                };
              }),
          );
        })
        .catch(err => {
          console.log('err', err);
        });
  }, []);
  const Col = ({ label, value }) => (
    <Box display="flex" flexDirection="column" minWidth={185}>
      <Text className={classes.colLabel} level={textLevel.S} weight={fontWeight.SEMI_BOLD}>
        {label}
      </Text>
      <Text className={classes.marginTop8} level={textLevel.M} weight={fontWeight.MEDIUM}>
        {value || 'N/A'}
      </Text>
    </Box>
  );

  return (
    <Box
      sx={{
        background: colors.white,
        paddingX: 3,
        paddingBottom: 4,
        marginBottom: 2,
        boxShadow:
          '0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04)',
        borderRadius: 2,
      }}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Heading level={headingLevel.S} weight={fontWeight.SEMI_BOLD}>
          Claim
        </Heading>
        <Button
          className={classes.btn}
          variant={btnType.TEXT}
          onClick={() => setOpen(true)}
          size={btnSize.SMALL}
        >
          Edit info
        </Button>
      </Box>
      <Divider sx={{ marginX: -3 }} />
      <Box display="flex" flexDirection="column">
        <Box display="flex" mt={5}>
          <Box display="flex" flexDirection="column" minWidth={185}>
            <Text className={classes.colLabel} level={textLevel.S} weight={fontWeight.SEMI_BOLD}>
              Total claim
            </Text>
            <Heading
              className={clsx(classes.marginTop8, classes.headingFont)}
              level={headingLevel.XL}
              weight={fontWeight.REGULAR}
            >
              {data?.totalClaim ? `$${data.totalClaim}` : 'N/A'}
            </Heading>
          </Box>
          <Box display="flex" flexDirection="column" minWidth={185}>
            <Text className={classes.colLabel} level={textLevel.S} weight={fontWeight.SEMI_BOLD}>
              Patient responsibility
            </Text>
            <Heading
              className={clsx(classes.marginTop8, classes.headingFont)}
              level={headingLevel.XL}
              weight={fontWeight.REGULAR}
            >
              {data?.patientResponsibility ? `$${data.patientResponsibility}` : 'N/A'}
            </Heading>
          </Box>
        </Box>

        {data?.cptList?.map((c, index) => (
          <Box
            key={`${index}-${c._id}`}
            display="flex"
            mx={-3}
            py={2}
            px={3}
            sx={{ borderTop: `1px solid ${colors.neutral100}` }}
          >
            <Col label="CPT code" value={c?.cptCode} />
            <Col label="Fee" value={c?.cost ? `$${c?.cost}` : ''} />
            <Col label="Related diagnosis" value={c?.relatedDiagnosis} />
          </Box>
        ))}
      </Box>
      {open && (
        <SubscriberDrawer data={data} open={open} onClose={() => setOpen(false)} onSubmit={onSubmit} />
      )}
    </Box>
  );
};

export default Claim;
