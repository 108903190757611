import { makeStyles } from '@mui/styles';
import { theme } from '../../packages';

export const useStyles = makeStyles({
  root: {
    backgroundColor: theme.palette.grey['50'],
    minHeight: '100vh',
    overflowX: 'hidden',
  },
  content: ({ rightSideBarWidth, noPadding }) => ({
    paddingTop: noPadding ? 0 : 48,
    paddingBottom: noPadding ? 0 : 64,
    paddingRight: noPadding ? 0 : 64,
    width: `calc(100% - ${rightSideBarWidth || '0px'})`,
    height: '100vh',
    overflowY: 'auto',
    overflowX: 'hidden',
  }),
  contentNoVerticalPadding: ({ rightSideBarWidth }) => ({
    paddingTop: 0,
    paddingBottom: 0,
    paddingRight: 64,
    width: `calc(100% - ${rightSideBarWidth || '0px'})`,
  }),
  content2: ({ rightSideBarWidth }) => ({
    paddingTop: 48,
    // paddingBottom: 64,
    paddingRight: 64,
    width: `calc(100% - ${rightSideBarWidth || '0px'})`,
  }),
  contentCollapse: ({ noPadding }) => ({
    paddingLeft: noPadding ? 82 : 136,
  }),
  contentHoldUp: ({ noPadding }) => ({
    paddingLeft: noPadding ? 279 : 344,
  }),
  sideNavigation: {
    position: 'fixed',
    zIndex: 999,
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '0px',
    },
  },
  rightSideNavigation: {
    zIndex: 999,
  },
  rightSideNavWrapper: ({ rightSideBarWidth, isChatNav }) => ({
    height: '100vh',
    width: rightSideBarWidth,
    '& > div': isChatNav
      ? {}
      : {
          overflowY: 'auto',
        },
  }),
  rightSideBar: ({ rightSideBarWidth }) => ({
    position: 'fixed',
    right: 0,
    top: 0,
    height: '100vh',
    width: rightSideBarWidth,
  }),
  isMobileView: {
    paddingLeft: 24,
    paddingRight: '24px !important',
    paddingTop: '24px !important',
  },
});
