import { Box } from '@mui/material';
import dayjs from 'dayjs';
import { Heading, Text, headingLevel, fontWeight } from '../../../../packages/ui/atoms/typography';
import { Avatar, avatarType } from '../../../../packages/ui/atoms/avatar';
import { useStyles } from './WaitingHeader.styles';

const WaitingHeader = ({ isCompleted, appointment }) => {
  const classes = useStyles();

  return (
    <Box className={classes.headWrap}>
      <Avatar
        src={appointment?.participantImage}
        name={appointment?.participantName}
        size={96}
        variant={avatarType.CIRCLE}
      />
      <Box>
        <Heading level={headingLevel.M} weight={fontWeight.BOLD} className={classes.title}>
          {isCompleted
            ? `${appointment?.serviceDuration} minutes session with ${appointment?.participantName}`
            : `We'll connect you to ${appointment?.participantName} when
              ${appointment?.participantGenderIdentity === 'Female' ? 'she' : 'he'}
                 arrives.`}
        </Heading>
        <Text className={classes.subtitle} weight={fontWeight.MEDIUM}>
          {isCompleted && appointment?.analytics?.completedAt
            ? `Completed on ${dayjs(appointment?.analytics?.completedAt).format('MMMM DD, h:mm a')}`
            : 'Here is what you can do in the meantime.'}
        </Text>
      </Box>
    </Box>
  );
};

export { WaitingHeader };
