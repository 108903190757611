/* eslint-disable max-len */
import cx from 'clsx';

// types
// styles
import { useStyles } from './shared/styles';

const Help = ({ className, color = '#0374DD', onClick }) => {
  const classes = useStyles();
  return (
    <svg
      className={cx({
        [classes.root]: true,
        [className || '']: className,
      })}
      onClick={onClick}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 0C15.523 0 20 4.477 20 10C20 15.523 15.523 20 10 20C4.477 20 0 15.523 0 10C0 4.477 4.477 0 10 0ZM10 15C9.37442 15.0007 8.7543 14.8836 8.172 14.655L5.936 16.892C7.16665 17.6194 8.57043 18.0022 10 18C11.4296 18.0022 12.8333 17.6194 14.064 16.892L11.828 14.655C11.2457 14.8836 10.6256 15.0007 10 15ZM2 10C2 11.484 2.404 12.873 3.108 14.064L5.345 11.828C5.11636 11.2457 4.99933 10.6256 5 10C5 9.355 5.122 8.739 5.345 8.172L3.108 5.936C2.38057 7.16665 1.99784 8.57043 2 10V10ZM16.892 5.936L14.655 8.172C14.878 8.739 15 9.355 15 10C15 10.645 14.878 11.261 14.655 11.828L16.892 14.064C17.6194 12.8333 18.0022 11.4296 18 10C18.0022 8.57043 17.6194 7.16665 16.892 5.936V5.936ZM10 7C9.20435 7 8.44129 7.31607 7.87868 7.87868C7.31607 8.44129 7 9.20435 7 10C7 10.7956 7.31607 11.5587 7.87868 12.1213C8.44129 12.6839 9.20435 13 10 13C10.7956 13 11.5587 12.6839 12.1213 12.1213C12.6839 11.5587 13 10.7956 13 10C13 9.20435 12.6839 8.44129 12.1213 7.87868C11.5587 7.31607 10.7956 7 10 7ZM10 2C8.57043 1.99784 7.16665 2.38057 5.936 3.108L8.172 5.345C8.7543 5.11636 9.37442 4.99933 10 5C10.645 5 11.261 5.122 11.828 5.345L14.064 3.108C12.8333 2.38057 11.4296 1.99784 10 2V2Z"
        fill={color}
      />
    </svg>
  );
};

export default Help;
