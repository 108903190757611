import { Box } from '@mui/material';

import { Heading, IconButton, Text } from '../../../../../packages';

import { useLocation, useNavigate } from 'react-router-dom';
import { CONVERSATION_TYPES } from '../../../../../constants/CommonConstants';
import { path2type } from '../../../conversation/ConversationDetail.mock';
import { useStyles } from './InfoCard.styles';

const InfoCard = ({ title, data }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname.split('/')[2];

  const goToConversationReport = (conversationId, conversationVersion) => {
    navigate(
      `/admin/${
        path2type[path].type === CONVERSATION_TYPES.EVALUATION_DCT
          ? 'collection-evaluations'
          : 'collection-conversations'
      }/${conversationId}/${conversationVersion}`,
    );
  };

  return (
    <Box className={classes.card}>
      <Box className={classes.head}>
        <Heading className={classes.title}>{title}</Heading>
      </Box>
      {data.map((item, idx) => (
        <Box
          className={classes.data}
          key={idx}
          onClick={() => goToConversationReport(item.conversationId, item.version)}
        >
          <Box sx={{ width: 288 }}>
            <Text className={classes.text}>{item.conversationName}</Text>
          </Box>
          <IconButton icon="chevron-right" className={classes.icon} />
        </Box>
      ))}
    </Box>
  );
};

export { InfoCard };
