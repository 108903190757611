import { colors } from '../../../../../packages';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  drawer: {
    '& .MuiPaper-root': {
      maxWidth: '560px !important',
    },
    '& .drawerBody': {
      padding: 0,
    },
  },
  wrapper: {
    flex: 1,
    flexDirection: 'column',
  },
  header: {
    position: 'relative',
    padding: 24,
    borderBottom: `1px solid ${colors.neutral100}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  headTitle: {
    fontSize: 24,
    lineHeight: '32px',
    margin: 0,
  },
  content: {
    height: 'calc(100vh - 82px)',
    overflowY: 'auto',
    transition: 'height 300ms linear',
  },
  showFooter: {
    height: 'calc(100vh - 162px)',
  },
  checkIcon: {
    textAlign: 'center',
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 80,
    padding: 16,
    borderTop: `1px solid ${colors.neutral100}`,
    '& button': {
      height: 48,
    },
  },
  closeBtn: {
    minWidth: 'auto',
    '& svg': {
      width: 24,
    },
  },
  member: {
    display: 'flex',
    alignItems: 'center',
    gap: 12,
  },
  info: {
    display: 'flex',
    flexDirection: 'column',
    gap: 0,
  },
  table: {
    backgroundColor: colors.white,
  },
  id: {
    fontFamily: 'Manrope',
    fontWeight: 600,
    fontSize: 14,
    lineHeight: '20px',
    color: colors.neutral900,
  },
  name: {
    fontFamily: 'Manrope',
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '20px',
    color: colors.neutral600,
  },
});
