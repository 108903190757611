import { FC, useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { FormControlLabel } from '../../../../../../../packages/ui/atoms/form-control-label';
import { Checkbox } from '../../../../../../../packages/ui/atoms/checkbox';
import { useStyles } from './EvaluationNervous.styles';

const EvaluationNervous = ({ selectedSection, multiSelectChange, multiSelect, forceRender, onChange }) => {
  const classes = useStyles();
  const [selectedBoxes, setSelectedBoxes] = useState(
    selectedSection?.userResponse?.userResponseList.length > 0
      ? selectedSection?.userResponse?.userResponseList
      : [],
  );

  const onChangeCheckboxes = item => () => {
    const isChecked = selectedBoxes?.includes(item);

    if (isChecked) {
      setSelectedBoxes(selectedBoxes.filter(box => box !== item));
      selectedSection.evaluationResponses = selectedBoxes?.filter(box => box !== item);
    } else {
      setSelectedBoxes([...selectedBoxes, item]);
      selectedSection.evaluationResponses = [...selectedBoxes, item];
    }
    if (onChange) {
      onChange(item);
    }
    forceRender();
  };

  useEffect(() => {
    if (selectedSection.evaluationResponses.length === 0) {
      setSelectedBoxes([]);
      if (selectedSection?.userResponse?.userResponseList.length > 0) {
        selectedSection.evaluationResponses = selectedSection.userResponse.userResponseList;
        setSelectedBoxes([...selectedSection.userResponse.userResponseList]);
      }
    } else {
      setSelectedBoxes([...selectedSection.evaluationResponses]);
    }
  }, [selectedSection.evaluationResponses, multiSelectChange, multiSelect, selectedSection.userResponse]);
  return (
    <>
      <Box display="flex" flexDirection="column" gap="16px">
        {selectedSection?.choices?.map(item => (
          <FormControlLabel
            key={item?.choice}
            control={<Checkbox />}
            checked={selectedBoxes?.includes(item?.choice)}
            onChange={onChangeCheckboxes(item?.choice)}
            label={item?.choice}
            className={classes.formLabel}
          />
        ))}
      </Box>
    </>
  );
};

export { EvaluationNervous };
