import React from 'react';
import { Box, Stack } from '@mui/material';
import { sentenceCase } from 'sentence-case';

import { Heading, Text } from '../../../../../../packages/ui/atoms/typography';
import { Badge, badgeType, badgeStyle } from '../../../../../../packages/ui/atoms/badge';

import { IAssignedPlanItem } from '../../../../../../redux/modules/conversation/types';
import { useStyles } from './PlanItemCard.styles';

const PlanItemCard = ({ item }) => {
  const classes = useStyles();

  return (
    <Box className={classes.card}>
      <Box height={96} className={classes.dlex}>
        <Stack direction="column" gap={1.5}>
          <Heading className={classes.title}>{item.planItem.name}</Heading>
          <Text className={classes.typeText}>{sentenceCase(item.planItem.type || '')}</Text>
        </Stack>
        <Badge variant={badgeType.OUTLINED} style={badgeStyle.CRITICAL} className={classes.badgeResult}>
          +{item.planItem.planToken} token{item.planItem.planToken > 1 ? 's' : ''}
        </Badge>
      </Box>
    </Box>
  );
};

export { PlanItemCard };
