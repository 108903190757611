export const tabSelectOptions = chatCounts => [
  { label: 'All chats', value: 'all', count: Object.values(chatCounts).reduce((a, b) => a + b, 0) },
  { label: 'Providers', value: 'providers', count: chatCounts.providers },
  { label: 'Care team', value: 'care_team', count: chatCounts.careTeamMembers },
  { label: 'Groups', value: 'groups', count: chatCounts.groups },
  { label: 'Care navigator', value: 'care_navigator', count: chatCounts.careNavigator },
];

export const tabSelectOptionsAdmin = chatCounts => [
  { label: 'All chats', value: 'all', count: Object.values(chatCounts).reduce((a, b) => a + b, 0) },
  { label: 'Members', value: 'members', count: chatCounts.members },
];

export const tabSelectOptionsCareNavigator = chatCounts => [
  { label: 'All chats', value: 'all', count: Object.values(chatCounts).reduce((a, b) => a + b, 0) },
  { label: 'Members', value: 'members', count: chatCounts.members },
  { label: 'Providers', value: 'providers', count: chatCounts.providers },
  { label: 'Care team', value: 'care_team', count: chatCounts.careTeamMembers },
];
