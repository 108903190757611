import { FC } from 'react';
import clsx from 'clsx';
import ButtonGroup from '@mui/material/ButtonGroup';
import { IconButton } from '../../../../../packages';

import { useStyles } from './CalendarGroupButton.styles';

const CalendarGroupButton = ({ showCalendar, toggleCalendar }) => {
  const classes = useStyles();

  const onShowCalendar = () => {
    toggleCalendar(true);
  };

  const onShowList = () => {
    toggleCalendar(false);
  };

  return (
    <ButtonGroup variant="outlined" aria-label="outlined button group">
      <IconButton
        icon="list"
        className={clsx(classes.btnGroupIcon, { [classes.btnGroupIconActive]: !showCalendar })}
        onClick={onShowList}
      />
      <IconButton
        icon="calendar-primary-outlined"
        className={clsx(classes.btnGroupIcon, { [classes.btnGroupIconActive]: showCalendar })}
        onClick={onShowCalendar}
      />
    </ButtonGroup>
  );
};

export default CalendarGroupButton;
