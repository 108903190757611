/* eslint-disable no-nested-ternary */
import { FC, useEffect, useState } from 'react';
import cx from 'clsx';
import { Collapse, Box } from '@mui/material';
import { useFormik } from 'formik';

import { Text } from '../../../../../../../packages/ui/atoms/typography';
import { Button } from '../../../../../../../packages/ui/atoms/button';
import {
  iconBtnStyle,
  iconBtnType,
  IconButton,
} from '../../../../../../../packages/ui/molecules/icon-button';
import { Badge, badgeStyle, badgeType } from '../../../../../../../packages/ui/atoms/badge';

// styles
import { useStyles } from './ReportFormCollapse.styles';
import { SubstanceUseEditSchema } from '../../substance-use-editing/SubstanceUseEditing.schema';
import { substanceDefaultValues } from '../../SubstanceUse.constants';
import SubstanceForm from '../../substance-form';
import { reportItemProgress } from '../../../../MemberDetail.constants';

const ReportFormCollapse = ({
  open = false,
  item,
  lookupData,
  onChange,
  onRemoveClick,
  onOpenCollapse,
  options,
}) => {
  const [collapsed, setCollapsed] = useState(open || false);
  const classes = useStyles();

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
    onOpenCollapse && onOpenCollapse(!collapsed);
  };

  const form = useFormik({
    initialValues: { ...substanceDefaultValues },
    validationSchema: SubstanceUseEditSchema,
    onSubmit: payload => {
      const { dose, frequency, duration, refillTimes, method, lastUse, refillable, ...remaining } = payload;

      onChange({
        ...remaining,
        tutorial: {
          method,
          lastUse,
          dose,
          frequency,
          duration,
          refillTimes,
          refillable,
        },
      });
    },
  });

  useEffect(() => {
    if (item) {
      const { tutorial, ...remaining } = item;
      const newObj = {
        ...remaining,
        ...tutorial,
      };
      Object.keys(newObj).forEach(async key => {
        await form.setFieldValue(key, newObj[key]);
      });
      if (item.progress) {
        setCollapsed(item.progress === reportItemProgress.InProgress);
      }
    }
  }, [item]);

  useEffect(() => {
    setCollapsed(open || false);
  }, [open]);

  return (
    <Box className={classes.root}>
      <Box className={cx(classes.header, { [classes.headerBorder]: collapsed })} onClick={toggleCollapsed}>
        <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
          <Text className={classes.headTitle}>{item.item.label}</Text>
          <Badge
            variant={badgeType.OUTLINED}
            className={classes.badge}
            style={
              item.progress === reportItemProgress.Completed
                ? badgeStyle.SUCCESS
                : item.progress === reportItemProgress.Incomplete
                ? badgeStyle.MISREPORTED
                : badgeStyle.CRITICAL
            }
          >
            {item.progress}
          </Badge>
        </Box>
      </Box>
      <Collapse in={collapsed}>
        <form className={classes.form} onSubmit={form.handleSubmit}>
          <SubstanceForm form={form} options={options} substance={item} lookupData={lookupData} />
          <Box className={classes.footer}>
            <IconButton
              icon="delete-outlined-2"
              className={classes.removeBtn}
              variant={iconBtnType.TEXT}
              style={iconBtnStyle.SECONDARY}
              onClick={onRemoveClick}
            >
              Remove this item
            </IconButton>
            <Button onClick={form.handleSubmit}>Done</Button>
          </Box>
        </form>
      </Collapse>
    </Box>
  );
};

export { ReportFormCollapse };
