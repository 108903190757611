import { Box } from '@mui/material';

import clsx from 'clsx';
import { colors, fontWeight, Text } from '../../../../../packages';
import { useStyles } from './StepFive.styles';
import SlotComponent from '../SlotsView';

const StepFiveNew = ({ onClickReschedule, recurringSlots }) => {
  const classes = useStyles();
  const slotsExceptFirst = recurringSlots.slice(1, recurringSlots.length);
  const renderLabel = label => (
    <Text className={clsx(classes.text18, classes.label)} weight={fontWeight.BOLD}>
      {label}
    </Text>
  );
  const renderSubLabel = label => (
    <Text
      className={clsx(classes.text12, classes.label)}
      weight={fontWeight.EXTRA_BOLD}
      color={colors.neutral500}
    >
      {label}
    </Text>
  );

  return (
    <Box className={classes.reviewWrap}>
      <Box className={classes.reviewSection}>
        {renderLabel('Check availability')}
        {renderSubLabel('SELECTED DAY AND TIME')}
        <SlotComponent slot={recurringSlots?.[0]} onClickReschedule={onClickReschedule} />
        {recurringSlots?.length > 1 && (
          <>
            {renderSubLabel(
              `NEXT ${recurringSlots?.length - 1} UPCOMING DATE${recurringSlots?.length - 1 > 1 ? 'S' : ''}`,
            )}
            {slotsExceptFirst?.map((item, index) => (
              <SlotComponent key={`${index}-slot`} slot={item} onClickReschedule={onClickReschedule} />
            ))}
          </>
        )}
      </Box>
    </Box>
  );
};

export default StepFiveNew;
