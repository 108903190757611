/* eslint-disable max-len */
import cx from 'clsx';

// types
// styles
import { useStyles } from './shared/styles';

const Unpin = ({ className, color, onClick, ...rest }) => {
  const classes = useStyles();
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
      className={cx({
        [classes.root]: true,
        [className || '']: className,
      })}
      {...rest}
    >
      <g clipPath="url(#clip0_1455_90)">
        <path
          d="M4.146 0.146001C4.19252 0.099599 4.24773 0.0628209 4.30847 0.0377692C4.36921 0.0127175 4.4343 -0.00011655 4.5 7.97493e-07H11.5C11.6326 7.97493e-07 11.7598 0.0526792 11.8536 0.146447C11.9473 0.240216 12 0.367393 12 0.500001C12 1.18 11.658 1.674 11.354 1.979C11.228 2.104 11.104 2.203 11 2.277V6.708L11.078 6.756C11.281 6.883 11.554 7.07 11.829 7.311C12.36 7.775 13 8.527 13 9.5C13 9.63261 12.9473 9.75979 12.8536 9.85355C12.7598 9.94732 12.6326 10 12.5 10H8.5V14.5C8.5 14.776 8.276 16 8 16C7.724 16 7.5 14.776 7.5 14.5V10H3.5C3.36739 10 3.24021 9.94732 3.14645 9.85355C3.05268 9.75979 3 9.63261 3 9.5C3 8.527 3.64 7.775 4.17 7.311C4.4287 7.08637 4.70641 6.88461 5 6.708V2.277C4.8739 2.18771 4.75548 2.08803 4.646 1.979C4.342 1.674 4 1.179 4 0.500001C3.99988 0.434296 4.01272 0.369212 4.03777 0.30847C4.06282 0.247728 4.0996 0.19252 4.146 0.146001V0.146001ZM5.726 1.554L5.724 1.553L5.726 1.554ZM5.724 1.553L5.726 1.554C5.80842 1.59577 5.87766 1.65955 5.92603 1.73828C5.97439 1.81701 6 1.9076 6 2V7C6 7.09278 5.97418 7.18373 5.92544 7.26267C5.87669 7.34161 5.80695 7.40544 5.724 7.447H5.722L5.71 7.454L5.656 7.484C5.36117 7.64846 5.08405 7.84282 4.829 8.064C4.511 8.342 4.244 8.66 4.104 9H11.896C11.756 8.66 11.489 8.342 11.171 8.064C10.9004 7.82917 10.605 7.62462 10.29 7.454L10.278 7.448H10.276C10.1929 7.40636 10.1231 7.34238 10.0743 7.26324C10.0255 7.18411 9.99982 7.09295 10 7V2C9.99962 1.90331 10.0273 1.80858 10.0796 1.7273C10.132 1.64601 10.2068 1.58164 10.295 1.542C10.4229 1.4667 10.5408 1.37565 10.646 1.271C10.726 1.191 10.801 1.101 10.86 1H5.14C5.2 1.1 5.273 1.191 5.354 1.271C5.46447 1.38078 5.58886 1.47559 5.724 1.553Z"
          fill={color}
        />
        <line x1="2.42315" y1="-0.266357" x2="13.0774" y2="16.6595" stroke={color} />
      </g>
      <defs>
        <clipPath id="clip0_1455_90">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Unpin;
