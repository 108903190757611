import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Box, Grid } from '@mui/material';
import {
  Avatar,
  avatarType,
  Badge,
  badgeSize,
  badgeType,
  colors,
  fontWeight,
  Icons,
  Text,
  textLevel,
} from '../../../../../packages';
import dayjs from 'dayjs';

import { profileActionCreators } from '../../../../../redux/modules/profile';
import { getAuth } from '../../../../../redux/modules/auth/selectors';
import { getProfile } from '../../../../../redux/modules/profile/selectors';
import { createNewAssociatedTag } from '../../../../../services/member/member.service';
import { useDomainType } from '../../../../../hooks/useDomainType';
import { DomainTypesEnum } from '../../../../../constants/CommonConstants';
import history from '../../../../../utils/history';

import Card from './Card';
import AddNewReport from '../AddNewReport';
import DiagnosisDetail from '../Diagnoses/diagnoses-detail';
import DiagnosisEditing from '../Diagnoses/diagnoses-editing';
import { stepTitles } from '../Diagnoses/Diagnoses.constants';

import { useStyles } from './index.styles';

const Diagnoses = ({ isAdmin }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { memberId } = useParams();
  const { meta } = useSelector(getAuth);
  const { profile, selectedPatient } = useSelector(getProfile);

  const [isLoading, domainData, domainOptions] = useDomainType({
    type: DomainTypesEnum.DIAGNOSES,
  });

  const [showAddDrawer, setShowAddDrawer] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [errorApi, setError] = useState(null);

  const onSelectedItem = (item, isEdit = false) => {
    setSelectedItem(item);
    setIsEditing(isEdit);
  };

  const onCloseDrawer = () => {
    setSelectedItem(null);
    setIsEditing(false);
    setShowAddDrawer(false);
  };

  const onClickNewReport = () => {
    setShowAddDrawer(true);
  };

  const onFullReport = item => {
    console.log('onFullReport', item);
  };

  const onUpdateItem = async data => {
    const { status: importanceLevel, notes, selectedIct10code } = data;
    setIsSaving(true);
    setError(null);
    try {
      await createNewAssociatedTag({
        patientId: memberId,
        tagItems: [
          {
            assignedBy: profile.fullName || meta.nickName,
            domainElementId: selectedItem.domainElementId,
            selectedIct10code,
            metaData: {
              notes,
              selectedImportance: importanceLevel,
            },
          },
        ],
      });
      setIsSaving(false);
      dispatch(profileActionCreators.fetchDomainTypes(memberId));
      onCloseDrawer();
    } catch (error) {
      setError(error.data.message || error.data.errors[0]?.endUserMessage);
      setIsSaving(false);
    }
  };

  const onSubmitNewReport = payload => {
    const data = {
      patientId: memberId,
      tagItems: payload.map(item => ({
        assignedBy: profile.fullName || meta.nickName,
        domainElementId: item.item.value,
        selectedIct10code: item.icd10,
        metaData: {
          interferenceInLife: item.isInterference,
          notes: item.notes,
          selectedImportance: item.status,
        },
      })),
    };
    dispatch(
      profileActionCreators.createNewAssociatedTag({
        data,
        callback: errorMsg => {
          if (!errorMsg) {
            onCloseDrawer();
          }
        },
      }),
    );
  };

  return (
    <>
      <Card
        label="Diagnoses"
        actions={[
          {
            label: 'View all',
            onClick: () =>
              history.push(`/${isAdmin ? 'admin' : 'provider'}/members/${memberId}?name=diagnoses`),
          },
        ]}
      >
        {isLoading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', p: 3 }}>
            <Icons className="rotate linear infinite" glyph="in-progress" color={colors.primary} />
            Loading...
          </Box>
        ) : domainData?.relatedElements?.length > 0 ? (
          <>
            <Grid container alignItems="center">
              <Grid item xs={3}>
                <Box className={classes.tableHead}>
                  <Text weight={fontWeight.BOLD} level={textLevel.XS} color={colors.neutral700}>
                    Diagnosis name
                  </Text>
                </Box>
              </Grid>
              <Grid item xs={3}>
                <Box className={classes.tableHead}>
                  <Text weight={fontWeight.BOLD} level={textLevel.XS} color={colors.neutral700}>
                    Diagnosing provider
                  </Text>
                </Box>
              </Grid>
              <Grid item xs={2}>
                <Box className={classes.tableHead}>
                  <Text weight={fontWeight.BOLD} level={textLevel.XS} color={colors.neutral700}>
                    ICD-10 Code
                  </Text>
                </Box>
              </Grid>
              <Grid item xs={2}>
                <Box className={classes.tableHead}>
                  <Text weight={fontWeight.BOLD} level={textLevel.XS} color={colors.neutral700}>
                    Date added
                  </Text>
                </Box>
              </Grid>
              <Grid item xs={2}>
                <Box className={classes.tableHead}>
                  <Text weight={fontWeight.BOLD} level={textLevel.XS} color={colors.neutral700}>
                    Status
                  </Text>
                </Box>
              </Grid>
            </Grid>
            {domainData.relatedElements.slice(0, 3).map((item, idx) => (
              <Grid
                container
                key={idx}
                alignItems="center"
                sx={{ borderBottom: `1px solid ${colors.neutral100}`, cursor: 'pointer' }}
                onClick={() => onSelectedItem(item)}
              >
                <Grid item xs={3}>
                  <Box sx={{ p: 2 }}>
                    <Text weight={fontWeight.BOLD} level={textLevel.S}>
                      {item.name}
                    </Text>
                  </Box>
                </Grid>
                <Grid item xs={3}>
                  <Box sx={{ p: 2, display: 'flex', gap: 1.5, alignItems: 'center' }}>
                    <Avatar src={item.avatar} size={40} variant={avatarType.CIRCLE} name={item.assignedBy} />
                    <Box display="flex" gap="4px" flexDirection="column" justifyContent="flex-start">
                      <Text weight={fontWeight.BOLD} level={textLevel.S}>
                        {item.assignedBy}
                      </Text>
                      <Text weight={fontWeight.MEDIUM} level={textLevel.S}>
                        {dayjs(item.assignedAt).format('MMMM DD, YYYY')}
                      </Text>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={2}>
                  <Box sx={{ p: 2 }}>
                    <Text weight={fontWeight.BOLD} level={textLevel.S}>
                      {item.selectedIct10code}
                    </Text>
                  </Box>
                </Grid>
                <Grid item xs={2}>
                  <Box sx={{ p: 2 }}>
                    <Text weight={fontWeight.BOLD} level={textLevel.S}>
                      {dayjs(item.assignedAt).format('MM/DD/YYYY')}
                    </Text>
                  </Box>
                </Grid>
                <Grid item xs={2}>
                  <Box sx={{ p: 2 }}>
                    <Badge size={badgeSize.SMALL} style={item.priority?.name} variant={badgeType.OUTLINED}>
                      {item.priority?.name}
                    </Badge>
                  </Box>
                </Grid>
              </Grid>
            ))}
          </>
        ) : (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', p: 3 }}>
            <Text weight={fontWeight.BOLD} level={textLevel.S} color={colors.neutral700}>
              No diagnoses reported{' '}
              {`${selectedPatient?.member?.nickName ? `for ${selectedPatient?.member?.nickName}` : ''}`} yet.
            </Text>
          </Box>
        )}
      </Card>
      <AddNewReport
        isOpen={showAddDrawer}
        onClose={onCloseDrawer}
        title="Report new diagnoses"
        stepTitles={stepTitles}
        // showInterference
        searchLabel="Select one or more diagnoses"
        options={domainOptions}
        onSubmit={onSubmitNewReport}
        isLifeEvent
      />
      <DiagnosisDetail
        open={!!selectedItem && !isEditing}
        onClose={onCloseDrawer}
        onClickNewReport={onClickNewReport}
        diagnoses={selectedItem}
        onClickFullReport={onFullReport}
      />
      <DiagnosisEditing
        title="Update Diagnosis"
        isOpen={!!selectedItem && isEditing}
        onClose={onCloseDrawer}
        item={selectedItem}
        isSaving={isSaving}
        error={errorApi}
        onSubmit={onUpdateItem}
        options={domainOptions}
      />
    </>
  );
};

export default Diagnoses;
