import { Box } from '@mui/material';

import { fontWeight, Heading, iconBtnPosition, IconButton, Text, textLevel } from '../../../../../packages';

import { useStyles } from './AssignmentMethodCard.styles';

const AssignmentMethodCard = ({ assignmentsMethod }) => {
  const classes = useStyles();

  /**
   * @Name renderSection
   * @description This method is used render section like label & Value
   */
  const renderSection = (label, value) => (
    <Box className={classes.section}>
      <Text className={classes.sectionLabel} level={textLevel.S} weight={fontWeight.EXTRA_BOLD}>
        {label}
      </Text>
      <Text className={classes.sectionValue} level={textLevel.S} weight={fontWeight.BOLD}>
        {value}
      </Text>
    </Box>
  );

  return (
    <Box className={classes.card}>
      <Box className={classes.head}>
        <Heading className={classes.title}>Assignments method</Heading>
      </Box>
      <Box className={classes.content}>
        {assignmentsMethod?.map((assignmentMethod, index) => (
          <Box key={index} display="flex" flex={0.6} justifyContent="space-between">
            {renderSection('Profile element', assignmentMethod?.key)}
            {renderSection('Rule', assignmentMethod?.rule)}
            {renderSection('Value', assignmentMethod?.value)}
          </Box>
        ))}

        <IconButton
          iconPosition={iconBtnPosition.RIGHT}
          icon="square-arrow-up-right"
          className={classes.viewBtn}
        >
          View profile element
        </IconButton>
      </Box>
    </Box>
  );
};

export { AssignmentMethodCard };
