import { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import history from '../../../utils/history';
import { Breadcrumbs, colors, useQuery } from '../../../packages';
import { getProfile, selectMemberInsurance } from '../../../redux/modules/profile/selectors';
import { profileActionCreators } from '../../../redux/modules/profile';
import { BaseLayout } from '../../../layouts/base';
import AddSchedule from '../../admin/appointments/add-schedule';
import { appointmentActionCreators } from '../../../redux/modules/appointment';
import { selectProviderSchedule } from '../../../redux/modules/schedule/selectors';
import { getAuth } from '../../../redux/modules/auth/selectors';
import { priorityActionCreators } from '../../../redux/modules/priority';
import { getUserTimeZone } from '../../../utils/dayjs';
import SelectedComponent from './MemberDetail.selected';
import { rightSideNavList } from './MemberDetail.constants';
import { useStyles } from './MemberDetail.styles';
import UpdateVisitFrequency from './components/CareTeam/edit';
import { getNextAppointment } from '../../../services/appointment/appointment.service';
import { getPaymentMethodIndicators } from '../../../utils/CommonUtils';

const MemberDetail = memo(() => {
  const [selectedScreen, setSelectedScreen] = useState('');
  const { isAdmin } = useSelector(getAuth);
  const { insurance } = useSelector(selectMemberInsurance);
  const [openInstantAppointmentDrawer, setOpenInstantAppointmentDrawer] = useState(false);
  const [nextAppointment, setNextAppointment] = useState(null);
  const [openDrawer, setOpenDrawer] = useState(false);
  const providerSchedule = useSelector(selectProviderSchedule);
  const classes = useStyles({});
  const { profile, domainTypes = [], demographicDetails } = useSelector(getProfile);
  const dispatch = useDispatch();
  const query = useQuery();
  const { memberId } = useParams();
  const [frequencyDataObj, setFrequencyDataObj] = useState({
    userId: profile.providerId,
    name: profile.fullName,
    visitFrequency: demographicDetails?.visitFrequency /* item.visitFrequency */,
  });
  const [, setInstantAppointmentObj] = useState({
    provider: {
      id: profile?.providerId,
    },
    member: {
      id: memberId,
    },
    time: {
      start: dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
      end: dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
    },
  });
  useEffect(() => {
    dispatch(profileActionCreators.fetchDemographicDetails(memberId));
    dispatch(profileActionCreators.fetchMemberInsurance({ memberId }));
    dispatch(priorityActionCreators.fetchPriorityDomainTypes());
    setNextAppointment(null);
    void getNextAppointment(memberId).then(({ data }) => {
      setNextAppointment(data.appointment || null);
    });
    setInstantAppointmentObj({
      provider: {
        id: profile?.providerId,
      },
      member: {
        id: memberId,
      },
      time: {
        start: dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
        end: dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
      },
    });
  }, []);

  useEffect(() => {
    const getQuery = query.get('name');
    setSelectedScreen(getQuery || 'general-information');
  }, [query]);

  const toggleAppointments = screen => {
    history.push(`/provider/members/${memberId}?name=${screen}`);
  };

  useEffect(() => {
    setFrequencyDataObj({
      userId: profile.providerId,
      name: profile.fullName,
      visitFrequency: demographicDetails?.visitFrequency,
    });
    dispatch(
      profileActionCreators.selectedPatient({
        member: {
          id: memberId,
          firstName: demographicDetails.firstName,
          lastName: demographicDetails.lastName,
          fullName: demographicDetails.fullName,
          nickName: demographicDetails.nickName,
          photo: demographicDetails?.profilePicture,
          uuid: demographicDetails.uuid,
        },
        email: demographicDetails.email,
        dob: demographicDetails.dateOfBirth,
        phone: demographicDetails.mobilePhone,
        fundsInWallet: 0,
        engagement: {
          Status: demographicDetails.levelOfEngagementStatus,
          SubStatus: demographicDetails.levelOfEngagementStatus,
        },
      }),
    );
  }, [demographicDetails]);

  const fetchAppointments2 = (providerId, timeZone) => {
    const filters = [{ searchField: 'status', searchQuery: 'BOOKED' }];
    const queryParams = {
      orderBy: 'desc',
      pageNumber: 0,
      size: 180,
      sortBy: 'startTime',
      statuses: 'BOOKED',
      type: 'CURRENT',
      searchQuery: '',
    };
    const bodyRequest = {
      providerId,
      refDate: dayjs().format('DD-MM-YYYY'),
      timezone: timeZone || getUserTimeZone(),
      type: 'current',
      textSearch: '',
      size: 180,
      filters,
    };
    dispatch(
      appointmentActionCreators.fetchAppointments({
        bodyRequest,
        queryParams: { ...queryParams },
      }),
    );
  };

  const onSubmitNewSchedule = payload => {
    dispatch(
      appointmentActionCreators.createAppointment({
        data: { ...payload, timezone: providerSchedule?.timezone },
        callback: (isSucceed, isInstantSession, appointmentId) => {
          if (isSucceed) {
            setOpenInstantAppointmentDrawer(false);
            if (!isAdmin) {
              fetchAppointments2(profile.providerId, providerSchedule.timezone);
            }
            if (isInstantSession) {
              history.push(`/provider/appointments/current/${appointmentId}/session`);
            }
          }
        },
      }),
    );
  };

  const textFields = useCallback(() => {
    const nextAppointmentText = nextAppointment
      ? dayjs(nextAppointment.startTime).format('MM/DD hh:mma')
      : 'Not Scheduled';
    return [
      {
        label: 'Next appointment##',
        text: nextAppointmentText,
      },
      {
        label: 'Visit Frequency##',
        text: demographicDetails?.visitFrequency === null ? '-' : demographicDetails?.visitFrequency,
      },
      {
        label: 'Insurance',
        text: insurance?.currentInsurance?.name || '-',
      },
    ];
  }, [demographicDetails, nextAppointment, insurance]);

  const moreInfoTextFields = useCallback(() => {
    return [
      {
        label: 'Level of Engagement##', // ## added to differentiate b/w data
        text:
          Object.keys(demographicDetails).length === 0
            ? '-'
            : `${demographicDetails?.levelOfEngagementStatus}|${demographicDetails?.levelOfEngagementSubStatus}`,
      },
      {
        label: 'Phone number',
        text: demographicDetails?.mobilePhone
          ? `(${demographicDetails?.mobilePhone?.slice(1, 4)})
          ${demographicDetails?.mobilePhone?.slice(4, 7)}-${demographicDetails?.mobilePhone?.slice(7, 11)}`
          : '-',
      },
      {
        label: 'Email',
        text: demographicDetails?.email || '-',
      },
    ];
  }, [demographicDetails]);

  const rightSidebar = useCallback(() => {
    return rightSideNavList.map(item => {
      // if (item.text === 'Priorities') {
      //   const newChild = item.children.map(child => {
      //     if (child.text === 'History') {
      //       return child;
      //     }
      //     if (child.text.toLowerCase() === 'environmental factors') {
      //       const environmentalFactors = ['social determinants', 'life events'];
      //       let badgeCount = 0;
      //       domainTypes.forEach(domainType => {
      //         if (environmentalFactors.includes(domainType.typeName.toLowerCase())) {
      //           badgeCount += domainType.relatedElements?.length ?? 0;
      //         }
      //       });
      //       return {
      //         ...child,
      //         badge: badgeCount || 0,
      //       };
      //     }
      //     if (child.text.toLowerCase() === 'symptomatic factors') {
      //       const symptomaticFactors = ['symptoms', 'substance use', 'side effects'];
      //       let badgeCount = 0;
      //       domainTypes.forEach(domainType => {
      //         if (symptomaticFactors.includes(domainType.typeName.toLowerCase())) {
      //           badgeCount += domainType.relatedElements?.length ?? 0;
      //         }
      //       });
      //       return {
      //         ...child,
      //         badge: badgeCount || 0,
      //       };
      //     }
      //     const domainType = domainTypes.find(
      //       ({ typeName }) => typeName.toLocaleLowerCase() === child.text.toLocaleLowerCase(),
      //     );
      //     return {
      //       ...child,
      //       badge: domainType?.relatedElements?.length || 0,
      //     };
      //   });
      //   return {
      //     ...item,
      //     children: newChild,
      //   };
      // }
      if (item.text === 'Insurance') {
        const indicators = getPaymentMethodIndicators(insurance, demographicDetails);
        return {
          text: 'Insurance',
          query: { type: 'name', value: 'insurance-information' },
          rightIcon: indicators.glyph,
          rightIconColor: indicators.color,
        };
      }
      if (item.text === 'Appointments') {
        const icon = demographicDetails?.bookingRestricted ? 'info-outlined' : 'checked-circle-outlined';
        const iconColor = demographicDetails?.bookingRestricted ? colors.destructive500 : colors.success500;
        return {
          ...item,
          rightIcon: icon,
          rightIconColor: iconColor,
        };
      }
      if (item.text.toLowerCase() === 'environmental factors') {
        const environmentalFactors = ['social determinants', 'life events'];
        let badgeCount = 0;
        domainTypes.forEach(domainType => {
          if (environmentalFactors.includes(domainType.typeName.toLowerCase())) {
            badgeCount += domainType.relatedElements?.length ?? 0;
          }
        });
        return {
          ...item,
          badge: badgeCount || 0,
        };
      }
      if (item.text.toLowerCase() === 'symptomatic factors') {
        const symptomaticFactors = ['symptoms', 'substance use', 'side effects'];
        let badgeCount = 0;
        domainTypes.forEach(domainType => {
          if (symptomaticFactors.includes(domainType.typeName.toLowerCase())) {
            badgeCount += domainType.relatedElements?.length ?? 0;
          }
        });
        return {
          ...item,
          badge: badgeCount || 0,
        };
      }
      const domainType = domainTypes.find(
        ({ typeName }) => typeName.toLocaleLowerCase() === item.text.toLocaleLowerCase(),
      );
      return {
        ...item,
        badge: domainType?.relatedElements?.length || 0,
      };
      return item;
    });
  }, [domainTypes]);

  useEffect(() => {
    if (openDrawer) {
      setOpenInstantAppointmentDrawer(false);
    }
  }, [openDrawer]);

  return (
    <>
      <BaseLayout
        rightSideNavList={rightSidebar()}
        textFields={textFields()}
        moreInfoTextFields={moreInfoTextFields()}
        rightSideBarWidth="320px"
        setOpenDrawer={setOpenDrawer}
        setOpenInstantAppointmentDrawer={setOpenInstantAppointmentDrawer}
      >
        <div className={classes.root}>
          <div className={classes.header}>
            <Breadcrumbs
              links={[
                { href: '/provider/members', text: 'Members' },
                {
                  text:
                    demographicDetails?.firstName || demographicDetails?.lastName
                      ? `${demographicDetails?.firstName || ''} ${demographicDetails.lastName || ''}`
                      : demographicDetails?.nickName
                      ? demographicDetails?.nickName
                      : '',
                },
              ]}
            />
          </div>
          <SelectedComponent selectedScreen={selectedScreen} toggle={toggleAppointments} />
        </div>
      </BaseLayout>
      {openInstantAppointmentDrawer && (
        <AddSchedule
          isOpen={openInstantAppointmentDrawer}
          onClose={() => setOpenInstantAppointmentDrawer(false)}
          onSubmit={onSubmitNewSchedule}
          memberId={memberId}
          // providerId={chatType === 'providers' ?  selectedContact?.id : null}
        />
      )}
      {openDrawer && (
        <UpdateVisitFrequency
          openDrawer={openDrawer}
          onClose={() => setOpenDrawer(false)}
          data={frequencyDataObj}
          currentUserId={memberId}
        />
      )}
    </>
  );
});

MemberDetail.displayName = 'MemberDetail';

export { MemberDetail };
