import { Box } from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Button, colors, fontWeight, Icons, Text, textLevel } from '../../../../packages';
import AddPriorities from '../add-priorities/AddPriorities';
import PriorityView from '../priorities-view/PrioritiesView';
import { PrioritiesCardLabel } from '../AlfieAnalysisPlayground.contstant';

import { useStyles } from '../AlfieAnalysisPlayground.styles';

const AlfieAnalysisPriorityDetail = ({
  type,
  selectedElement,
  openAdd,
  setOpenAdd,
  inferredPriorities,
  onSelectElement,
}) => {
  const classes = useStyles();
  const filteredPriorities = inferredPriorities.filter(p => p.type === PrioritiesCardLabel[type]);

  const onAdd = () => setOpenAdd(true);
  const onClose = () => setOpenAdd(false);

  const addPriorities = () => {
    return (
      <AddPriorities
        onClose={onClose}
        selectedElement={selectedElement}
        filteredPriorities={filteredPriorities}
      />
    );
  };

  return (
    <>
      {!openAdd ? (
        <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
          <Box
            className={classes.column1_1_1}
            sx={{
              borderBottom: 'none !important',
              boxShadow: 'none !important',
            }}
          >
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
              <Text
                level={selectedElement ? textLevel.S : textLevel.M}
                weight={selectedElement ? fontWeight.SEMI_BOLD : fontWeight.BOLD}
                className={selectedElement && classes.subtitle}
              >
                {PrioritiesCardLabel[type]}
              </Text>

              <Text
                level={selectedElement ? textLevel.M : textLevel.S}
                weight={selectedElement ? fontWeight.BOLD : fontWeight.SEMI_BOLD}
                className={!selectedElement && classes.subtitle}
              >
                {selectedElement
                  ? selectedElement.name
                  : `${filteredPriorities.length} found in conversation`}
              </Text>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: selectedElement ? 'space-between' : 'flex-start',
              px: 3,
              flexGrow: 1,
            }}
          >
            {selectedElement ? (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '16px',
                  alignItems: 'flex-start',
                  marginTop: '24px',
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: '16px' }}>
                  <Box
                    sx={{
                      display: 'flex',
                      padding: '12px',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      gap: '8px',
                      borderRadius: '8px',
                      backgroundColor: colors.secondary50,
                    }}
                  >
                    <Icons glyph="wand" color={colors.secondary500} />
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', flex: 1 }}>
                    <Text level={textLevel.M} weight={fontWeight.SEMI_BOLD} className={classes.aiHeading}>
                      AI rationale
                    </Text>
                    <Text level={textLevel.M} className={classes.aiValue}>
                      {selectedElement.reason}
                    </Text>
                  </Box>
                </Box>
                {selectedElement.quote?.length > 0 && (
                  <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: '16px' }}>
                    <Box
                      sx={{
                        display: 'flex',
                        padding: '12px',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        gap: '8px',
                        borderRadius: '8px',
                        backgroundColor: colors.primary50,
                      }}
                    >
                      <Icons glyph="ai-quote" color={colors.primary500} />
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', flex: 1 }}>
                      <Text level={textLevel.M} weight={fontWeight.SEMI_BOLD} className={classes.aiHeading}>
                        Quote
                      </Text>
                      <Text level={textLevel.M} className={classes.aiValue}>
                        {selectedElement.quote}
                      </Text>
                    </Box>
                  </Box>
                )}
                {selectedElement.confidence && selectedElement.confidence > 0 && (
                  <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: '16px' }}>
                    <Box
                      sx={{
                        display: 'flex',
                        padding: '12px',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        gap: '8px',
                        borderRadius: '8px',
                        backgroundColor: colors.secondary50,
                      }}
                    >
                      <Icons glyph="wand" color={colors.secondary500} />
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', flex: 1 }}>
                      <Text level={textLevel.M} weight={fontWeight.SEMI_BOLD} className={classes.aiHeading}>
                        AI confidence
                      </Text>
                      <Text level={textLevel.M} className={classes.aiValue}>
                        {selectedElement.confidence}
                      </Text>
                    </Box>
                  </Box>
                )}
              </Box>
            ) : (
              <Text level={textLevel.M} className={classes.mLabel}>
                Select a priority to view details
              </Text>
            )}
            {selectedElement ? (
              <Box
                sx={{
                  borderTop: '1px solid #EDF1F5',
                  mx: -3,
                  px: 3,
                  py: 2,
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <KeyboardArrowLeftIcon />
                <Button onClick={onAdd}>
                  Add this {PrioritiesCardLabel[type]?.toLowerCase().slice(0, -1)}
                </Button>
                <KeyboardArrowRightIcon />
              </Box>
            ) : (
              <PriorityView
                filteredPriorities={filteredPriorities}
                type={type}
                onSelectElement={onSelectElement}
              />
            )}
          </Box>
        </Box>
      ) : (
        addPriorities()
      )}
    </>
  );
};

export default AlfieAnalysisPriorityDetail;
