import { colors } from '../../../../../packages/colors';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  selectedQuestion: {
    backgroundColor: colors.primary100,
  },
  dctItem: {
    fontSize: '16px',
    fontWeight: '600',
    lineHeight: '24px',
    color: colors.neutral900,
    marginLeft: '8px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  collapseHeaderBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '8px 24px 8px 16px',
    cursor: 'pointer',
    justifyContent: 'space-between',
  },
  noPointer: {
    cursor: 'default !important',
  },
  invertIcon: {
    minWidth: '24px',
    minHeight: '24px',
    transformOrigin: 'center',
    transform: 'rotate(180deg)',
  },
  baseIconClass: {
    minWidth: '24px',
    minHeight: '24px',
  },
  collapseItemBox: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    padding: '8px 24px 8px 16px',
  },
  wh24: {
    maxWidth: '24px',
    maxHeight: '24px',
    '&.MuiCheckbox-root': {
      padding: '0px !important',
    },
  },
  nonSelectedQuestion: {
    backgroundColor: colors.white,
  },
  onlyCheckbox: {
    '& .MuiCheckbox-root': {
      marginBottom: '16px',
    },
  },
  marginLeftQuestion: {
    marginLeft: '10px',
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '20px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  marginClass: {
    marginLeft: '20px',
  },
  marginRightCheckbox: {
    marginRight: '16px',
    marginLeft: '8px',
  },
  evalIcon: {
    height: '20px',
    width: '20px',
    flexShrink: 0,
  },
  dctCheckIcon: {
    stroke: '#2DA834',
    width: '24px',
  },
  minusIcon: {
    height: '16px',
    width: '16px',
    flexShrink: 0,
  },
  requiredBadge: {
    display: 'flex',
    padding: '2px 8px',
    borderRadius: '100px',
    border: `1px solid ${colors.warning300}`,
    alignItems: 'center',
    justifyContent: 'center',
  },
  collapseTitle: {
    display: 'flex',
    alignItems: 'center',
    width: '90%',
  },
  badge: {
    fontSize: 12,
    height: 18,
    lineHeight: '16px',
    '& path': {
      fill: colors.secondary500,
    },
  },
  badge2: {
    fontSize: 12,
    height: 18,
    lineHeight: '16px',
    width: 'max-content',
    '& path': {
      fill: colors.secondary500,
    },
  },
  ml2: {
    marginLeft: '1.5rem',
  },
});
