export const cptColumns = [
  { id: 'code', label: 'Code', sortable: false },
  { id: 'description', label: 'Description', sortable: false },
  { id: 'feeType', label: 'Fee Type', sortable: false },
  { id: 'fee', label: 'Fee', sortable: false },
  { id: 'requiredTimeLimits', label: 'Require Time', sortable: false },
  { id: 'requiredCPTCodes', label: 'Require Code', sortable: false },
  { id: 'requiredDiagnosis', label: 'Require Diagnosis', sortable: false },
  { id: 'actions', label: '', sortable: false },
];

export const providerColumns = [
  { id: 'provider', label: 'Provider', sortable: false },
  { id: 'npi', label: 'NPI', sortable: false },
  { id: 'isBillingProvider', label: 'Billing provider', sortable: false },
  // { id: 'actions', label: '', sortable: false },
];

export const FeeTypeLabels = {
  SINGLE: 'Single Fee',
  TIME: 'Time based',
};

export const FeeTypes = {
  SINGLE: 'SINGLE',
  TIME: 'TIME',
};

export const feeScheduleTypeOptions = [
  { label: 'Single Fee', value: FeeTypes.SINGLE },
  { label: 'Time based', value: FeeTypes.TIME },
];
