import { FILTER_COLLAPSABLE_SECTION_TYPE } from '../../../packages/ui/organisms/table/filter';

export const memberColumns = [
  { id: 'member', label: 'Member', sortable: true },
  { id: 'lastAppointment', label: 'Last Appointment', sortable: false },
  { id: 'nextAppointment', label: 'Next Appointment', sortable: false },
  { id: 'email', label: 'Email', sortable: false },
  { id: 'phone', label: 'Phone', sortable: false },
  { id: 'dob', label: 'Date of birth', sortable: false },
  { id: 'fundsInWallet', label: 'Funds', sortable: false },
  { id: 'completed', label: 'Sessions', sortable: true },
  { id: 'state', label: 'State', sortable: false },
  { id: 'engagement', label: 'Engagement', sortable: false },
  { id: 'status', label: 'Status', sortable: false },
  { id: 'actions', label: '', sortable: false },
];

export const filterOptionsMock = [
  // { label: 'Active in chat', value: 'active_chat' },
  // { label: 'Inactive in chat', value: 'inactive_chat' },
  { label: 'At Least 2 Sessions', value: '2_sessions' },
  { label: 'No Sessions', value: 'no_sessions' },
  { label: 'Texas', value: 'Texas' },
  { label: 'Florida', value: 'Florida' },
  { label: 'Connecticut', value: 'Connecticut' },
  { label: 'Virginia', value: 'Virginia' },
  { label: 'New Hampshire', value: 'New Hampshire' },
  { label: 'New Member', value: 'New member' },
  { label: 'Referral', value: 'Referral' },
  { label: 'Active', value: 'Active' },
  { label: 'Referral Active', value: 'Referral Active' },
  { label: 'Successful Discharge', value: 'Successful Discharge' },
  { label: 'Self Discharge', value: 'Self Discharge' },
  { label: 'Discharged to HLOC', value: 'Discharged to HLOC' },
  { label: 'Lost to Care', value: 'Lost to Care' },
  { label: 'Not Admitted to Care', value: 'Not admitted to care' },
  { label: 'DIY', value: 'Diy' },
  { label: 'Ghost', value: 'Ghost' },
  { label: 'Engaged', value: 'Engaged' },
  { label: 'Not engaged', value: 'Not engaged' },
  { label: 'Outreach needed', value: 'Outreach needed' },
  // { label: 'Discharged', value: 'discharged' },
  // { label: 'Discharge HLOC', value: 'Discharge HLOC' },
  // {
  //   label: 'Discharged Asked to be Removed',
  //   value: 'Discharged Asked to be Removed',
  // },
  // { label: 'Discharged - No Logins', value: 'Discharged - No Logins' },
  // { label: 'Discharged - Unreachable', value: 'Discharged - Unreachable' },
  // { label: 'DIY', value: 'DIY' },
  // { label: 'Counseling DIY', value: 'Counseling DIY' },
  // { label: 'Coaching DIY', value: 'Coaching DIY' },
  // { label: 'Clinical DIY', value: 'Clinical DIY' },
  // // { label: 'PMPM', value: 'pmpm' },
  // { label: 'Phase 1', value: 'Phase 1' },
  // { label: 'Phase 2', value: 'Phase 2' },
];

export const multiSelectFilterOptionsMock = [
  {
    sectionTitle: 'State',
    sectionType: FILTER_COLLAPSABLE_SECTION_TYPE.DEFAULT,
    sectionOptions: [
      { label: 'Texas', value: 'Texas' },
      { label: 'Florida', value: 'Florida' },
      { label: 'Connecticut', value: 'Connecticut' },
      { label: 'Virginia', value: 'Virginia' },
      { label: 'New Hampshire', value: 'New Hampshire' },
    ],
  },
  {
    sectionTitle: 'Sessions Attended',
    sectionType: FILTER_COLLAPSABLE_SECTION_TYPE.RADIO,
    sectionOptions: [
      { label: 'At Least 2 Sessions', value: '2 Sessions' },
      { label: 'No Sessions', value: 'No Sessions' },
    ],
  },

  {
    sectionTitle: 'VBC',
    sectionType: FILTER_COLLAPSABLE_SECTION_TYPE.DEFAULT,
    sectionOptions: [{ label: 'VBC flag', value: 'VBC flag' }],
  },
  {
    sectionTitle: 'Level of Engagement',
    sectionType: FILTER_COLLAPSABLE_SECTION_TYPE.DEFAULT,
    sectionOptions: [
      { label: 'New Member', value: 'New member' },
      { label: 'Referral', value: 'Referral' },
      { label: 'Active', value: 'Active' },
      { label: 'Referral Active', value: 'Referral Active' },
      { label: 'Successful Discharge', value: 'Successful Discharge' },
      { label: 'Self Discharge', value: 'Self Discharge' },
      { label: 'Discharged to HLOC', value: 'Discharged to HLOC' },
      { label: 'Lost to Care', value: 'Lost to Care' },
      { label: 'Not Admitted to Care', value: 'Not admitted to care' },
      { label: 'DIY', value: 'Diy' },
      { label: 'Ghost', value: 'Ghost' },
      { label: 'Engaged', value: 'Engaged' },
      { label: 'Not engaged', value: 'Not engaged' },
      { label: 'Invite Pending', value: 'Invite pending' },
      { label: 'Invited', value: 'Invited' },
    ],
  },
  {
    sectionTitle: 'Insurance',
    sectionType: FILTER_COLLAPSABLE_SECTION_TYPE.RADIO,
    sectionOptions: [
      { label: 'Insurance', value: 'Insurance' },
      { label: 'Validated insurance', value: 'Validated insurance' },
      { label: 'Non validated insurance', value: 'Non validated insurance' },
      {
        label: 'Non validated insurance and appointments',
        value: 'Non validated insurance and appointments',
      },
      { label: 'Validated insurance and appointment', value: 'Validated insurance and appointment' },
    ],
  },
  {
    sectionTitle: 'Outreach',
    sectionType: FILTER_COLLAPSABLE_SECTION_TYPE.DEFAULT,
    sectionOptions: [{ label: 'Outreach needed', value: 'Outreach needed' }],
  },
];

export const stateCodes = {
  Alabama: 'AL',
  Alaska: 'AK',
  Arizona: 'AZ',
  Arkansas: 'AR',
  California: 'CA',
  Colorado: 'CO',
  Connecticut: 'CT',
  Delaware: 'DE',
  'District Of Columbia': 'DC',
  Florida: 'FL',
  'Federated States Of Micronesia': 'FM',
  Georgia: 'GA',
  Hawaii: 'HI',
  Idaho: 'ID',
  Illinois: 'IL',
  Indiana: 'IN',
  Iowa: 'IA',
  Kansas: 'KS',
  Kentucky: 'KY',
  Louisiana: 'LA',
  Maine: 'ME',
  Maryland: 'MD',
  Massachusetts: 'MA',
  Michigan: 'MI',
  Minnesota: 'MN',
  Mississippi: 'MS',
  Missouri: 'MO',
  Montana: 'MT',
  Nebraska: 'NE',
  Nevada: 'NV',
  'New Hampshire': 'NH',
  'New Jersey': 'NJ',
  'New Mexico': 'NM',
  'New York': 'NY',
  'North Carolina': 'NC',
  'North Dakota': 'ND',
  Ohio: 'OH',
  Oklahoma: 'OK',
  Oregon: 'OR',
  Pennsylvania: 'PA',
  'Rhode Island': 'RI',
  'South Carolina': 'SC',
  'South Dakota': 'SD',
  Tennessee: 'TN',
  Texas: 'TX',
  Utah: 'UT',
  Vermont: 'VT',
  Virginia: 'VA',
  Washington: 'WA',
  'West Virginia': 'WV',
  Wisconsin: 'WI',
  Wyoming: 'WY',
  null: '—',
};

export const INSURANCE_FILTERS_MAPPING = {
  Insurance: 'INSURANCE',
  'Validated insurance': 'VALIDATED_INSURANCE',
  'Non validated insurance': 'NON_VALIDATED_INSURANCE',
  'Non validated insurance and appointments': 'NON_VALIDATED_INSURANCE_AND_APPOINTMENTS',
  'Validated insurance and appointment': 'VALIDATED_INSURANCE_AND_APPOINTMENT',
};
