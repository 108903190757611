import cx from 'clsx';
import { useCallback } from 'react';
import { AvatarGroup, Box } from '@mui/material';

// components
import { Avatar, avatarType } from '../../../../../packages/ui/atoms/avatar';
import { fontWeight, Text, textLevel } from '../../../../../packages/ui/atoms/typography';
import { Icons } from '../../../../../packages/icons';
import { Badge, badgeType, badgeStyle } from '../../../../../packages/ui/atoms/badge';
import { colors } from '../../../../../packages/colors';

import { NotificationType } from '../../../../../redux/modules/notification/types';

import { formatTimeMessage } from '../../../../../utils/CommonUtils';
import { useStyles } from './NotificationItem.styles';

const NotificationItem = ({ item, onClick }) => {
  const classes = useStyles();
  const { activityType, timestamp, metaData } = item;
  const isGroupMessage = activityType === NotificationType.GROUP_MESSAGE;

  const getContent = useCallback(() => {
    switch (activityType) {
      case NotificationType.READ_EDUCATION:
        return {
          icon: <Icons glyph="book-closed-outlined" color={colors.indigo500} />,
          text: `${metaData.memberName} read ${metaData.educationName || '{educationName}'}`,
        };
      case NotificationType.ASKED_QUESTION:
        return {
          icon: <Icons glyph="survey-line" color={colors.primary500} />,
          text: `${metaData.memberName} has asked a question`,
        };
      case NotificationType.SCHEDULED_APPOINTMENT:
        return {
          icon: <Icons glyph="calendar-primary-outlined" color={colors.primary500} />,
          text: `${metaData.memberName} has scheduled an ${metaData.appointmentType || ''} appointment with ${
            metaData.providerName
          }`,
        };
      case NotificationType.COMPLETED_APPOINTMENT:
        return {
          icon: <Icons glyph="calendar-checked-outlined" color={colors.green500} />,
          text: `${metaData.memberName} has completed an ${metaData.appointmentType || ''} appointment with ${
            metaData.providerName
          }`,
        };
      case NotificationType.COMPLETED_CONVERSATION:
        return {
          icon: <Icons glyph="screen-checked-outlined" color={colors.green500} />,
          text: `${metaData.memberName} has completed the ${
            metaData.conversationName || 'Conversation Name'
          } conversation`,
        };
      case NotificationType.POSTED_PROVIDER_REVIEW:
        return {
          icon: <Icons glyph="rating-outlined" color={colors.orange500} />,
          text: `${metaData.memberName} has rated ${metaData.providerName} ${metaData.rating || 1} for ${
            metaData.appointmentType || ''
          } appointment`,
        };
      case NotificationType.NEW_MESSAGE:
        return {
          icon: <Icons glyph="message-outlined" color={colors.purple500} />,
          text: `You received a new message from ${metaData.memberName}`,
        };
      case NotificationType.GROUP_MESSAGE:
        return {
          icon: <Icons glyph="conversations-outlined" color={colors.cyan500} />,
          text: `You got ${metaData.amountMessage || '{amountMessage}'} in a group chat`,
        };
      default:
        return {
          icon: <Icons glyph="question-outlined" color={colors.primary500} />,
          text: 'Unknown',
        };
    }
  }, [activityType]);

  const { icon, text } = getContent();

  return (
    <Box
      className={cx(classes.notification, {
        [classes.notificationRead]: metaData.isUnRead,
      })}
      onClick={onClick}
    >
      <Box className={classes.leftSide}>
        {icon}
        <Text className={classes.text} level={textLevel.S} weight={fontWeight.MEDIUM}>
          {text}
        </Text>
      </Box>
      <Box className={classes.rightSide}>
        {isGroupMessage ? (
          <AvatarGroup max={4} className={classes.avtGroup}>
            {['A', 'B', 'C'].map((thumb, index) => (
              <Avatar
                variant={avatarType.CIRCLE}
                size={40}
                src={thumb}
                name={thumb}
                key={index}
                className={classes.avtItem}
                style={{ zIndex: index }}
              />
            ))}
          </AvatarGroup>
        ) : (
          <>
            {metaData.isUnRead && (
              <Badge variant={badgeType.FILLED} style={badgeStyle.MISREPORTED} className={classes.badge}>
                Unread
              </Badge>
            )}
          </>
        )}
        <Text className={classes.time} level={textLevel.S}>
          {formatTimeMessage(timestamp)}
        </Text>
      </Box>
    </Box>
  );
};

export { NotificationItem };
