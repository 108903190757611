// import { makeStyles } from '@mui/styles';

// import { NoteStyle } from '../../../constants/CommonConstants';

// // colors
// import { colors } from '../../../colors';

// interface PropsT {
//   status: NoteStyle;
// }

// export const useStyles = makeStyles({
//   root: ({ status }: PropsT) => ({
//     cursor: 'pointer',
//     borderRadius: 8,
//     boxShadow: `0px 0px 6px ${colors.neutral100}`,
//     backgroundColor: colors.white,
//     border: `1px solid ${colors[`${status}500`] || colors.neutral100}`,
//     padding: 24,
//   }),
//   topContentWrapper: {
//     display: 'flex',
//     alignItems: 'center',
//     gap: 24,
//   },
//   iconWrapper: ({ status }: PropsT) => ({
//     height: 40,
//     width: 40,
//     borderRadius: 4,
//     border: '1px solid',
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'center  ',
//     backgroundColor: colors[`${status === 'normal' ? 'purple' : status}50`],
//     borderColor: colors[`${status === 'normal' ? 'purple' : status}200`],
//     '&.RELEASE_OF_INFORMATION': {
//       backgroundColor: colors.indigo50,
//       borderColor: colors.indigo200,
//       '& svg path': {
//         fill: colors.indigo500,
//       },
//     },
//     '&.LAB_REQUEST': {
//       backgroundColor: colors.green50,
//       borderColor: colors.green200,
//       '& svg path': {
//         fill: colors.green500,
//       },
//     },
//     '&.CONTACT': {
//       backgroundColor: colors.rose50,
//       borderColor: colors.rose200,
//       '& svg path': {
//         fill: colors.rose500,
//       },
//     },
//     '&.GENERAL': {
//       backgroundColor: colors.purple50,
//       borderColor: colors.purple200,
//       '& svg path': {
//         fill: colors.purple500,
//       },
//     },
//     '&.CAUTION': {
//       backgroundColor: colors.warning50,
//       borderColor: colors.warning200,
//       '& svg path': {
//         fill: colors.warning500,
//       },
//     },
//     '&.PROHIBITIVE': {
//       backgroundColor: colors.destructive50,
//       borderColor: colors.destructive200,
//       '& svg path': {
//         fill: colors.destructive500,
//       },
//     },
//     '&.PHARMACY_ASSISTANCE': {
//       backgroundColor: colors.cyan50,
//       borderColor: colors.cyan100,
//       '& svg path': {
//         fill: colors.cyan500,
//       },
//     },
//     '&.REFERRAL_OUT': {
//       backgroundColor: colors.orange50,
//       borderColor: colors.orange200,
//       '& svg path': {
//         fill: colors.orange500,
//       },
//     },
//     '&.PRIOR_AUTHORIZATION': {
//       backgroundColor: colors.primary50,
//       borderColor: colors.primary100,
//       '& svg path': {
//         fill: colors.primary500,
//       },
//     },
//   }),
//   title: {
//     lineHeight: '24px',
//     fontWeight: 600,
//     margin: 0,
//   },
//   subTitle: {
//     lineHeight: '20px',
//     fontWeight: 600,
//     fontSize: 16,
//     color: colors.neutral500,
//   },
//   actionsWrapper: {
//     display: 'flex',
//     justifyContent: 'flex-end',
//   },
//   actionsInnerWrapper: {
//     display: 'flex',
//     gap: 3,
//   },
//   cardWithActionsWrapper: {
//     display: 'flex',
//     justifyContent: 'space-between',
//     gap: 24,
//   },
//   iconButtonWrapper: {
//     fontSize: '14px',
//     fontWeight: 700,
//     display: 'flex',
//     gap: 16,
//     lineHeight: '20px',
//   },
//   container: {
//     display: 'flex',
//     flexDirection: 'column',
//     gap: 8,
//   },
// });

import { makeStyles } from '@mui/styles';
// colors
import { colors } from '../../../colors';

export const useStyles = makeStyles({
  root: ({ status }) => ({
    cursor: 'pointer',
    borderRadius: 8,
    boxShadow: `0px 0px 6px ${colors.neutral100}`,
    backgroundColor: colors.white,
    border: `1px solid ${colors[`${status}500`] || colors.neutral100}`,
    padding: 24,
  }),
  topContentWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 24,
  },
  iconWrapper: ({ status }) => ({
    height: 40,
    width: 40,
    borderRadius: 4,
    border: '1px solid',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center  ',
    backgroundColor: colors[`${status === 'normal' ? 'purple' : status}50`],
    borderColor: colors[`${status === 'normal' ? 'purple' : status}200`],
    '&.RELEASE_OF_INFORMATION': {
      backgroundColor: colors.indigo50,
      borderColor: colors.indigo200,
      '& svg path': {
        fill: colors.indigo500,
      },
    },
    '&.LAB_REQUEST': {
      backgroundColor: colors.green50,
      borderColor: colors.green200,
      '& svg path': {
        fill: colors.green500,
      },
    },
    '&.CONTACT': {
      backgroundColor: colors.rose50,
      borderColor: colors.rose200,
      '& svg path': {
        fill: colors.rose500,
      },
    },
    '&.GENERAL': {
      backgroundColor: colors.purple50,
      borderColor: colors.purple200,
      '& svg path': {
        fill: colors.purple500,
      },
    },
    '&.CAUTION': {
      backgroundColor: colors.warning50,
      borderColor: colors.warning200,
      '& svg path': {
        fill: colors.warning500,
      },
    },
    '&.PROHIBITIVE': {
      backgroundColor: colors.destructive50,
      borderColor: colors.destructive200,
      '& svg path': {
        fill: colors.destructive500,
      },
    },
    '&.PHARMACY_ASSISTANCE': {
      backgroundColor: colors.cyan50,
      borderColor: colors.cyan100,
      '& svg path': {
        fill: colors.cyan500,
      },
    },
    '&.REFERRAL_OUT': {
      backgroundColor: colors.orange50,
      borderColor: colors.orange200,
      '& svg path': {
        fill: colors.orange500,
      },
    },
    '&.PRIOR_AUTHORIZATION': {
      backgroundColor: colors.primary50,
      borderColor: colors.primary100,
      '& svg path': {
        fill: colors.primary500,
      },
    },
  }),
  title: {
    lineHeight: '24px',
    fontWeight: 600,
    margin: 0,
  },
  subTitle: {
    lineHeight: '20px',
    fontWeight: 600,
    fontSize: 16,
    color: colors.neutral500,
    wordBreak: 'break-word',
  },
  actionsWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  actionsInnerWrapper: {
    display: 'flex',
    gap: 3,
  },
  cardWithActionsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: 24,
  },
  iconButtonWrapper: {
    fontSize: '14px',
    fontWeight: 700,
    display: 'flex',
    gap: 16,
    lineHeight: '20px',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
  },
});
