// components
import { Box, Stack } from '@mui/material';
import { Badge, badgeType, fontWeight, iconBtnPosition, IconButton, Text } from '../../../../../../packages';

// styles
import { useStyles } from './FlowCard.styles';
import FlowItem from '../flow-item';

const FlowCard = ({ item, onEditClick, isEditable }) => {
  const classes = useStyles();

  const onEdit = () => {
    onEditClick(item);
  };

  return (
    <Box id={item.id} className={classes.root}>
      <Box className={classes.top}>
        <Stack direction="row" gap={1}>
          <Stack direction="column" gap={0.5}>
            {/* <Text className={classes.title} weight={fontWeight.BOLD}>
              {item.variableName}
            </Text> */}
            <Text className={classes.title} weight={fontWeight.REGULAR}>
              {item.message}
            </Text>
          </Stack>
          {item.badge && (
            <Badge variant={badgeType.OUTLINED} className={classes.badge}>
              {item.badge}
            </Badge>
          )}
        </Stack>

        <IconButton
          className={classes.editBtn}
          icon="edit-underline"
          iconPosition={iconBtnPosition.RIGHT}
          onClick={onEdit}
          disabled={!isEditable}
        >
          Edit
        </IconButton>
      </Box>
      <Box className={classes.content}>
        {/* {item.message && (
          <Box sx={{ paddingBottom: 3 }}>
            <Text className={classes.description} weight={fontWeight.REGULAR}>
              {item.type}
            </Text>
          </Box>
        )} */}
        <Box display="flex" flexDirection="column" gap={1}>
          {item.items.map((data, idx) =>
            typeof data === 'string' ? (
              <Box className={classes.textWrap} key={idx}>
                <Box className={classes.dot} />
                <Text key={idx} className={classes.description} weight={fontWeight.REGULAR}>
                  {data}
                </Text>
              </Box>
            ) : (
              <FlowItem item={data} key={idx} />
            ),
          )}
        </Box>
      </Box>
    </Box>
  );
};

export { FlowCard };
