import { makeStyles } from '@mui/styles';

// colors
import { colors } from '../../../../../../packages';

export const useStyles = makeStyles({
  breakdownChart: {
    left: 0,
  },
  donutCharts: {
    display: 'flex',
    padding: 32,
    gap: 32,
    flex: 1,
    paddingTop: 0,
    '&:first-child': {
      paddingTop: 24,
    },
  },
  donut: {
    border: `1px solid ${colors.neutral100}`,
    borderRadius: 8,
    padding: 24,
    flex: 1,
    width: '100%',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 10,
    marginTop: -20,
  },
});
