import { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import clsx from 'clsx';
import dayjs from 'dayjs';

import { fontWeight, Heading, iconBtnPosition, IconButton, Menu, Text } from '../../../../../packages';
import { Filter, Table } from '../../../../../packages/ui/organisms/table';

import { assignmentColumns, filterOptionsMock } from './AssignmentTableCard.mock';
import { useStyles } from './AssignmentTableCard.styles';

const AssignmentTableCard = ({ tagAsssignment }) => {
  const classes = useStyles();
  const [assignTagsList] = useState(tagAsssignment?.assignedTo || []);
  const [dataSource, setDataSource] = useState(tagAsssignment?.assignedTo || []);
  const [value, setValue] = useState({
    search: {
      searchKey: '',
    },
    pagination: {
      currentPage: 1,
      rowsPerPage: 10,
    },
    sorter: {
      direction: 'asc',
      column: '',
    },
  });

  /**
   * @Name renderColumns
   * @description This method is used to render columns
   */
  const renderColumns = assignmentColumns.map(column => {
    if (column.id === 'patientName') {
      return {
        ...column,
        renderCell: patientName => (
          <Box display="flex" gap={1.5} alignItems="center">
            {/* <Avatar src={thumbnail} name={name} size={40} variant={avatarType.CIRCLE} /> */}
            <Box>
              <Text className={classes.name} weight={fontWeight.SEMI_BOLD}>
                {patientName}
              </Text>
              <Text className={clsx(classes.name, classes.fullname)} weight={fontWeight.MEDIUM}>
                {patientName}
              </Text>
            </Box>
          </Box>
        ),
      };
    }
    if (column.id === 'date') {
      return {
        ...column,
        renderCell: date => dayjs(date).format('MM/DD/YYYY'),
      };
    }
    if (column.id === 'actions') {
      return {
        ...column,
        renderCell: () => (
          <Menu
            icon="more"
            className={classes.menu}
            itemsWrapperClassName={classes.menuItemsWrapper}
            items={[{ label: 'Menu Item' }, { label: 'Menu Item' }, { label: 'Menu Item' }]}
          />
        ),
      };
    }
    return column;
  });

  /**
   * @Name formatList
   * @description This method is used formate list according to columns req
   */
  const formatList = listToBeUpdated =>
    listToBeUpdated?.map(patientAssignment => ({
      ...patientAssignment,
      actions: { assignId: patientAssignment.patientId },
    }));

  /**
   * @function useEffect
   * @description This method is used to filter records based on multiple filters.
   */
  useEffect(() => {
    const { search, sorter, pagination } = value;
    const currentPage = pagination?.currentPage || 1;
    const rowsPerPage = pagination?.rowsPerPage || 10;
    const listFiltered = assignTagsList
      .filter(({ patientName }) => {
        if (search?.searchKey) {
          return (
            patientName?.toLowerCase().includes(search?.searchKey) ||
            patientName?.toLowerCase().includes(search?.searchKey)
          );
        }
        return true;
      })

      .sort((a, b) => {
        const sortColumn = sorter?.column;
        const compare = a[sortColumn]?.toString()?.localeCompare(b[sortColumn]?.toString());
        return sorter?.direction === 'desc' ? compare * -1 : compare;
      })
      .slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage);

    const fliteredList = formatList(listFiltered);
    setDataSource(fliteredList);
  }, [value]);

  return (
    <Box className={classes.card}>
      <Box className={classes.head}>
        <Heading className={classes.title}>Tag assignments</Heading>
        <IconButton icon="export" iconPosition={iconBtnPosition.RIGHT} className={classes.exportBtn}>
          Export
        </IconButton>
      </Box>
      <Box>
        <Table
          searchProps={{
            placeholder: 'Search',
            filterProps: {
              variant: Filter.tableFilterType.MULTIPLE,
              options: filterOptionsMock,
              allOptionLabel: 'All profile',
            },
          }}
          gridProps={{
            columns: renderColumns,
            data: dataSource,
            isLoading: false,
          }}
          paginationProps={{
            currentRows: dataSource?.length,
            totalCount: dataSource?.length,
          }}
          value={value}
          onChange={setValue}
          className={classes.table}
        />
      </Box>
    </Box>
  );
};

export { AssignmentTableCard };
