import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import {
  btnSize,
  btnType,
  Button,
  colors,
  fontWeight,
  Heading,
  headingLevel,
  Input,
  inputSize,
  inputType,
  Text,
  textLevel,
} from '../../../../packages';
import { Drawer, drawerPosition, drawerType } from '../../../../packages/ui/organisms/drawer';
import { useStyles } from '../ClaimDetail.styles';

const defaultData = { name: '', taxId: '' };

const labels = {
  name: 'Payer',
  taxId: 'Tax ID',
};

const PayerDrawer = ({ data, open, onClose, onSubmit, payers }) => {
  const classes = useStyles();
  const [formData, setFormData] = useState({ ...data });
  const [selectedPayer, setSelectedPayer] = useState(null);

  useEffect(() => {
    const selectPayer = payers.find(p => p?.name === data?.name);

    if (selectPayer) {
      setSelectedPayer({ label: selectPayer._id, value: selectPayer.name });
      setFormData({ ...formData, name: selectPayer?._id });
    }
  }, [data?.name]);

  // const onChangeField = (field) => (e) => {
  //   if (errors[field]) {
  //     setErrors(s => ({ ...s, [field]: null }));
  //   }
  //   setFormData(old => ({
  //     ...old,
  //     [field]: e.target?.value,
  //   }));
  // };

  const onChangeName = (_, keyValue) => {
    const val = keyValue && keyValue?.value !== '' ? keyValue?.value : '';
    setSelectedPayer(keyValue);
    setFormData({ ...formData, name: val });
  };

  const onChangePayer = () => {
    onSubmit({ ...formData, name: selectedPayer?.value || '' }, 'payer');
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      variant={drawerType.FORM}
      position={drawerPosition.RIGHT}
      title="Edit Payer"
      submitBtnTitle="Update"
      onSubmit={onChangePayer}
    >
      <Box className={classes.inputWrapper}>
        <label htmlFor="name">
          <Text level={textLevel.S} weight={fontWeight.BOLD}>
            {labels.name}
          </Text>
        </label>
        <Input
          fullWidth
          name="name"
          placeholder="Select payer"
          size={inputSize.M}
          variant={inputType.AUTOCOMPLETE}
          value={selectedPayer}
          options={payers?.map(p => ({ label: p.name, value: p?._id })) || []}
          getOptionLabel={option => option?.label || ''}
          onChange={onChangeName}
        />
      </Box>

      {/* {Object.keys(data)
        .filter(d => d === 'taxId')
        .map((d: keyof IClaimPayer, index) => (
          <Box className={classes.inputWrapper} key={`${d}-${index}`}>
            <label htmlFor={d}>
              <Text level={textLevel.S} weight={fontWeight.BOLD}>
                {labels[d]}
              </Text>
            </label>
            <Input
              name={d}
              value={formData[d] || ''}
              onChange={onChangeField(d)}
              size={inputSize.M}
              placeholder={`Enter your ${labels[d]}`}
            />
            {errors[d] && <TextError errorMsg={errors[d]} />}
          </Box>
        ))} */}
    </Drawer>
  );
};

const Payer = ({ onSubmit, data = defaultData, payers }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const Col = ({ label, value }) => (
    <Box display="flex" flexDirection="column" minWidth={185}>
      <Text className={classes.colLabel} level={textLevel.S} weight={fontWeight.SEMI_BOLD}>
        {label}
      </Text>
      <Text className={classes.marginTop8} level={textLevel.M} weight={fontWeight.MEDIUM}>
        {value || 'N/A'}
      </Text>
    </Box>
  );

  return (
    <Box
      sx={{
        background: colors.white,
        paddingX: 3,
        paddingBottom: 4,
        marginBottom: 2,
        boxShadow:
          '0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04)',
        borderRadius: 2,
      }}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Heading level={headingLevel.S} weight={fontWeight.SEMI_BOLD}>
          Payer
        </Heading>
        <Button
          className={classes.btn}
          variant={btnType.TEXT}
          onClick={() => setOpen(true)}
          size={btnSize.SMALL}
        >
          Change payer
        </Button>
      </Box>
      <Divider sx={{ marginX: -3 }} />

      <Box display="flex" mt={4}>
        <Col label="Payer" value={data?.name} />
        <Col label="State name" value={data?.stateName || 'N/A'} />
      </Box>
      {open && (
        <PayerDrawer
          data={data}
          open={open}
          onClose={() => setOpen(false)}
          onSubmit={onSubmit}
          payers={payers}
        />
      )}
    </Box>
  );
};

export default Payer;
