import { useState } from 'react';
import {
  fontWeight,
  Heading,
  headingLevel,
  IconButton,
  Input,
  inputSize,
  inputType,
  TextError,
} from '../../../../packages';
import { Box } from '@mui/material';

import { useStyles } from '../ClaimDetail.styles';

const ProcedureCodeFields = ({ values, cptListErrors }) => {
  const [currentCPTList, setCurrentCPTList] = useState(values?.cptList || []);
  const classes = useStyles();
  const renderLabel = label => (
    <Heading level={headingLevel.S} className={classes.label} weight={fontWeight.BOLD}>
      {label}
    </Heading>
  );

  const handleChange = (field, index, value) => {
    const newValue = JSON.parse(JSON.stringify(currentCPTList));
    newValue[index][field] = value;
    values.cptList[index][field] = value;
    setCurrentCPTList(newValue);
  };
  return (
    <Box className={classes.formContent}>
      {currentCPTList?.map((_, index) => (
        <Box
          key={`cpt-list-${index}`}
          display="flex"
          flexDirection="column"
          gap={3}
          marginBottom={currentCPTList?.length > 1 ? 12 : 0}
        >
          <Box className={classes.section}>
            {renderLabel(`${index + 1} - Procedure code`)}

            <Box display="flex" gap={2} width="100%">
              <Input
                fullWidth
                name={`cptList.${index}.cptCode`}
                placeholder="Select item"
                size={inputSize.M}
                variant={inputType.DEFAULT}
                value={currentCPTList[index].cptCode}
                onChange={e => handleChange('cptCode', index, e.target.value)}
                disabled
              />
              {currentCPTList?.length > 0 && (
                <IconButton
                  icon="delete-outlined-2"
                  className={classes.removeIconBtn}
                  onClick={() => {
                    const newCPTList = JSON.parse(JSON.stringify(currentCPTList));
                    newCPTList.splice(index, 1);
                    values.cptList = newCPTList;
                    setCurrentCPTList(newCPTList);
                  }}
                />
              )}
            </Box>
            <TextError
              errorMsg={
                cptListErrors?.length > index && cptListErrors[index]?.cptCode !== 0
                  ? 'CPT code is required!'
                  : null
              }
            />
          </Box>
          <Box className={classes.section}>
            {renderLabel('Related Diagnosis')}
            <Input
              fullWidth
              name={
                currentCPTList[index].relatedDiagnosis
                  ? `cptList.${index}.relatedDiagnosis`
                  : `cptList.${index}.diagnosis`
              }
              size={inputSize.M}
              variant={inputType.DEFAULT}
              value={currentCPTList[index].relatedDiagnosis ?? currentCPTList[index].diagnosis}
              onChange={e => {
                if (currentCPTList[index].relatedDiagnosis)
                  handleChange('relatedDiagnosis', index, e.target.value);
                else handleChange('diagnosis', index, e.target.value);
              }}
              disabled
            />
            <TextError
              errorMsg={
                cptListErrors?.length > index && cptListErrors[index]?.relatedDiagnosis !== 0
                  ? 'Diagnosis is required!'
                  : null
              }
            />
          </Box>
          <Box className={classes.section}>
            {renderLabel('Specific ICD-10 Code')}
            <Input
              fullWidth
              name={`cptList.${index}.icd10Code`}
              size={inputSize.M}
              variant={inputType.DEFAULT}
              value={currentCPTList[index].icd10Code}
              onChange={e => handleChange('icd10Code', index, e.target.value)}
              disabled
            />
            <TextError
              errorMsg={
                cptListErrors?.length > index && cptListErrors[index]?.icd10Code !== 0
                  ? 'ICD10 code is required!'
                  : null
              }
            />
          </Box>
          <Box className={classes.section}>
            {renderLabel('Provider fee')}
            <Input
              fullWidth
              name={`cptList.${index}.providerFee`}
              placeholder=""
              size={inputSize.M}
              variant={inputType.DEFAULT}
              value={currentCPTList[index]?.providerFee}
              onChange={e => handleChange('providerFee', index, e.target.value)}
              disabled
            />
            <TextError
              errorMsg={
                cptListErrors?.length > index && cptListErrors[index]?.providerFee !== 0
                  ? 'Provider fee is required!'
                  : null
              }
            />
          </Box>
          <Box className={classes.section}>
            {renderLabel('Notes')}
            <Input
              fullWidth
              name={`cptList.${index}.notes`}
              placeholder=""
              size={inputSize.M}
              variant={inputType.DEFAULT}
              value={currentCPTList[index]?.notes}
              onChange={e => handleChange('notes', index, e.target.value)}
              disabled
            />
          </Box>
        </Box>
      ))}
      {/* <IconButton
        icon="plus"
        onClick={() => {
          const newCPTList = [
            ...currentCPTList,
            { cptCode: '', relatedDiagnosis: '', icd10Code: '', notes: '', providerFee: '' },
          ];
          console.log('newCPTList', newCPTList);
          values.cptList = newCPTList;
          setCurrentCPTList(newCPTList);
        }}
        className={classes.addBtn}
      >
        Add another
      </IconButton> */}
    </Box>
  );
};

export default ProcedureCodeFields;
