import React, { FC, useEffect, useState } from 'react';
import { Box, Collapse } from '@mui/material';
import cx from 'clsx';
import CircleIcon from '@mui/icons-material/Circle';
import { Icons } from '../../../../../packages/icons';
import { fontWeight, Text } from '../../../../../packages/ui/atoms/typography';
import { colors } from '../../../../../packages/colors';

import { useStyles } from './EvaluationSectionCollapse.styles';
import { IEvaluationContext } from '../../../../../redux/modules/conversation/types';

const EvaluationSectionCollapse = ({
  section,
  evaluationCompleted,
  selectedSection,
  onSelectSection,
  evaluation,
  index,
  setMultiSelect,
  onSelectMultiSelect,
  skippedCBTs,
  alfieValidation,
  evaluationContext,
  externallyCollapsed,
  sessionId,
}) => {
  const [collapsed, setCollapsed] = useState(externallyCollapsed || false);
  const [isCompleted, setIsCompleted] = useState(false);
  const [hasDoneItem, setHasDoneItem] = useState(false);
  const classes = useStyles({ collapsed });

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  useEffect(() => {
    setCollapsed(externallyCollapsed);
  }, [externallyCollapsed]);

  useEffect(() => {
    if (index === 0) {
      if (section?.isDct && section?.isAttemptable) {
        onSelectSection(section);
      } else if (section?.items[0]?.isAttemptable) {
        onSelectSection(section?.items[0]);
      }
    }
  }, []);

  useEffect(() => {
    const completed =
      evaluationCompleted ??
      section.items.every(
        item => item.isDone || (item.userResponse && item.userResponse?.userResponseList.length > 0),
      );
    const hasDoneItems = section.items.every(
      item => item.isDone || (item.userResponse && item.userResponse?.userResponseList.length > 0),
    );
    setIsCompleted(completed);
    setHasDoneItem(hasDoneItems);
  }, [section]);

  // +++++++++++++++++ // Duplicate code
  // useEffect(() => {
  //   const completed =
  //     evaluationCompleted ??
  //     section.items.every(
  //       item => item.isDone || (item.userResponse && item.userResponse.userResponseList.length > 0),
  //     );
  //   const hasDoneItems = section.items.every(
  //     item => item.isDone || (item.userResponse && item.userResponse.userResponseList.length > 0),
  //   );
  //   setIsCompleted(completed);
  //   setHasDoneItem(hasDoneItems);
  // }, [section]);

  useEffect(() => {
    if (evaluation?.sections[index]?.items) {
      const isOpen =
        (alfieValidation && collapsed) ||
        evaluation?.sections[index].items
          ?.map(sectionVal => {
            return sectionVal?.cbId;
          })
          .includes(selectedSection?.cbId);
      setCollapsed(isOpen);
    }
  }, [selectedSection]);

  const checkColor = item => {
    if (item?.isDone || (item.userResponse && item.userResponse?.userResponseList.length > 0)) {
      return colors.success500;
    }
    if (item?.cbId === selectedSection?.cbId) {
      return colors.primary600;
    }
    if (skippedCBTs.includes(item?.cbId)) {
      return colors.destructive600;
    }
    return colors.neutral400;
  };

  const checkSkip = item => {
    if (skippedCBTs.includes(item?.cbId) || alfieValidation) {
      return 'horizontal-line';
    }
    return 'circle-outlined';
  };

  const active = collapsed || section?.cbId === selectedSection?.cbId;
  const isSelectedValidationQuestion =
    (alfieValidation && selectedSection?.cbId === section.cbId) ||
    section?.items.some(item => item.cbId === selectedSection?.cbId);
  return (
    <Box
      className={cx(classes.root, {
        [classes.noPb]: alfieValidation,
      })}
    >
      {section.type ? (
        <>
          <Box className={classes.selects}>
            {section?.items?.map(item => (
              <Box
                key={item?.cbId}
                className={cx(
                  // { [classes.selectItem]: item.isAttemptable },
                  // { [classes.selectItemNotSelectable]: !item.isAttemptable },
                  [classes.selectItem],
                  {
                    [classes.selectItemActive]: item?.cbId === selectedSection?.cbId,
                  },
                )}
                onClick={() => {
                  onSelectSection(item);
                  onSelectMultiSelect(!setMultiSelect);
                }}
              >
                <Text className={classes.label}>{item?.text}</Text>
                {!alfieValidation && (
                  <>
                    {item?.isDone || (item.userResponse && item.userResponse?.userResponseList.length > 0) ? (
                      <Icons
                        className={classes.evalIcon}
                        glyph="checkmark"
                        color={
                          item?.isDone ||
                          (item.userResponse && item.userResponse?.userResponseList.length > 0)
                            ? colors.success500
                            : colors.neutral400
                        }
                      />
                    ) : (
                      <Icons className={classes.evalIcon} glyph={checkSkip(item)} color={checkColor(item)} />
                    )}
                  </>
                )}
              </Box>
            ))}
          </Box>
        </>
      ) : (
        <>
          <Box
            className={cx(classes.header, 'collapsableHeader', {
              [classes.selectedHeader]: alfieValidation ? isSelectedValidationQuestion : active,
              [classes.headerLeftBorder]: isSelectedValidationQuestion,
            })}
            onClick={() => {
              toggleCollapsed();
              onSelectSection(section);
            }}
          >
            <Box display="flex" alignItems="center" gap={1.5}>
              <Icons
                className={cx(classes.chevronIcon, 'collapsableIcon')}
                glyph="chevron-down"
                color={colors.neutral400}
              />
              <Text className={classes.title} weight={fontWeight.SEMI_BOLD}>
                {section?.title}
              </Text>
            </Box>
            {!alfieValidation && (
              <>
                {active ? (
                  <Icons
                    className={classes.checkCircleIcon}
                    glyph={isCompleted || hasDoneItem ? 'check-circle' : 'circle-outlined-arrow-down'}
                    // eslint-disable-next-line no-nested-ternary
                    color={
                      isCompleted ? colors.success500 : hasDoneItem ? colors.success500 : colors.primary500
                    }
                  />
                ) : (
                  <Icons
                    className={classes.checkCircleIcon}
                    glyph={isCompleted || hasDoneItem ? 'check-circle' : 'circle-outlined-arrow-down'}
                    // eslint-disable-next-line no-nested-ternary
                    color={isCompleted ? colors.success500 : hasDoneItem ? colors.success500 : '#333C43'}
                  />
                )}
              </>
            )}
          </Box>
          {alfieValidation && (
            <Box
              className={cx(classes.cList, {
                [classes.selectedHeader]: isSelectedValidationQuestion,
                [classes.headerLeftBorder]: isSelectedValidationQuestion,
              })}
            >
              {['full-match', 'resolved', 'partial-match', 'no-match'].map((status, idx) => {
                return (
                  <Box key={idx} display="flex" alignItems="center" gap={1}>
                    <CircleIcon className={status} />
                    <Box className={classes.count}>
                      <Box className={`${status}-text`}>
                        {section?.items.filter(item => item.matchStatus === status).length}
                      </Box>
                    </Box>
                  </Box>
                );
              })}
            </Box>
          )}
          <Collapse in={collapsed}>
            <Box className={classes.selects}>
              {section?.items?.map(item => (
                <Box
                  key={item?.cbId}
                  className={cx([classes.selectItem], {
                    [classes.selectItemActive]: item?.cbId === selectedSection?.cbId,
                  })}
                  onClick={() => {
                    onSelectSection(item);
                    onSelectMultiSelect(!setMultiSelect);
                  }}
                >
                  <Text
                    className={cx(classes.label, {
                      [classes.activeColor]: item?.cbId === selectedSection?.cbId,
                    })}
                  >
                    {item?.text}
                  </Text>
                  {item?.isDone || (item.userResponse && item.userResponse?.userResponseList.length > 0) ? (
                    <Icons
                      className={cx([classes.evalIcon], {
                        [classes.fullMatchIcon]: alfieValidation && item?.matchStatus === 'full-match',
                        [classes.partialMatchIcon]: alfieValidation && item?.matchStatus === 'partial-match',
                        [classes.noMatchIcon]: alfieValidation && item?.matchStatus === 'no-match',
                        [classes.resolvedIcon]: alfieValidation && item?.matchStatus === 'resolved',
                      })}
                      glyph="checkmark"
                      color={
                        item?.isDone || (item.userResponse && item.userResponse?.userResponseList.length > 0)
                          ? colors.success500
                          : colors.neutral400
                      }
                    />
                  ) : (
                    <Icons
                      className={cx([classes.evalIcon], {
                        [classes.fullMatchIcon]: alfieValidation && item?.matchStatus === 'full-match',
                        [classes.partialMatchIcon]: alfieValidation && item?.matchStatus === 'partial-match',
                        [classes.noMatchIcon]: alfieValidation && item?.matchStatus === 'no-match',
                        [classes.resolvedIcon]: alfieValidation && item?.matchStatus === 'resolved',
                      })}
                      glyph={checkSkip(item)}
                      color={checkColor(item)}
                    />
                  )}
                </Box>
              ))}
            </Box>
          </Collapse>
        </>
      )}
    </Box>
  );
};

export { EvaluationSectionCollapse };
