// colors
import { colors } from '../../../../packages';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  container: {
    padding: 16,
    display: 'flex',
    alignItems: 'center',
  },
  btnBack: {
    border: `1px solid ${colors.primary300}`,
    color: colors.primary300,
    width: 48,
    height: 48,
    padding: 0,
    minWidth: 0,
    marginRight: 16,
  },
  speakerAdjustmentBox: {
    paddingLeft: '26px',
    paddingRight: '16px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: '16px',
    paddingBottom: '16px',
    borderBottom: `1px solid ${colors.neutral100}`,
    width: '100%',
    boxShadow:
      '0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
    position: 'absolute',
    zIndex: 1000,
    backgroundColor: 'white',
  },
  colSubtitlesScroll: {
    flexGrow: 1,
    height: 'calc(100vh - 162px)',
    // overflowY: 'scroll',
    paddingTop: '32px',
    paddingBottom: '32px',
  },
  invertAllBtn: {
    padding: '0px !important',
    height: '40px !important',
  },
  invertAllIcon: {
    width: '20px !important',
    height: '20px !important',
  },
  invertSingleIcon: {
    width: '16.67px !important',
    height: '16.67px !important',
  },
  invertAllText: {
    fontSize: '14px',
    lineHeight: '20px',
    paddingLeft: '10px',
    color: colors.textPrimary,
  },
  speakerActionBtn: {
    padding: '10px 16px !important',
    height: '40px !important',
    fontSize: '14px',
  },
  info: {
    marginTop: 'auto',
    marginBottom: 'auto',
    marginLeft: 16,
  },
  title: {
    margin: 0,
    fontSize: '14px',
    lineHeight: '20px',
  },
  subtitle: {
    fontSize: '14px',
    color: colors.textPrimary,
  },
  speakerSwitchedBadge: {
    backgroundColor: 'white',
    padding: '0px 8px 0px 8px',
    fontSize: '12px',
    fontWeight: '600',
    lineHeight: '16px',
    height: '18px !important',
  },
  badgeProvider: {
    color: colors.purple600,
    borderColor: colors.purple300,
  },
  badgeMember: {
    color: colors.textSecondary,
    borderColor: colors.secondary300,
  },
  subtitle3: {
    fontSize: '14px',
    lineHeight: '20px',
    color: '#637888',
    fontWeight: 500,
    margin: 0,
  },
  subtitle2: {
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '24px',
    color: '#111C24',
  },
  subtitleBox: {
    marginTop: '4px',
  },
  memberSubtitle: {
    color: '#DD0374',
  },
  btnLink: {
    fontSize: 14,
    lineHeight: '20px',
    color: colors.primary500,
    fontWeight: 700,
    width: 25,
  },
  subTitleContainer: {
    padding: '12px 20px 0px 40px',
  },
  editModeSubtitle: {
    padding: '12px 20px 12px 40px',
  },
  limitedPaddingBox: {
    padding: '0px 20px 0px 40px',
  },
  subtitleTextBox: {
    '&:hover': {
      backgroundColor: '#F5F8FB',
      '& .MuiButton-root': {
        display: 'inline-flex !important',
      },
    },
  },
  emptyAvatarBox: {
    width: 40,
    height: 10,
  },
  textHighlighted: {
    backgroundColor: '#FEF3C7',
  },
  invertSingleButton: {
    backgroundColor: 'white',
    padding: '13.67px !important',
    display: 'none',
    minWidth: '44px',
    borderColor: colors.neutral200,
    borderRadius: '6px',
    boxShadow: `0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04)`,
  },
  menu: {
    width: 40,
    height: 40,
    borderRadius: 8,
    border: '1px solid transparent',
    '& svg path': {
      fill: colors.primary500,
    },
    '& svg': {
      width: 16,
      marginLeft: 7,
    },
  },
  menuActive: {
    border: `1px solid ${colors.neutral300}`,
    '& svg path': {
      fill: colors.neutral900,
    },
  },
  menuIcon: {
    marginRight: 10,
  },
  btnOpenChat: {
    marginRight: 0,
    marginLeft: 'auto',
  },
});
