/* eslint-disable jsx-a11y/aria-role */
import React from 'react';
import dayjs from 'dayjs';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import {
  Avatar,
  avatarType,
  Badge,
  badgeSize,
  badgeStyle,
  badgeType,
  colors,
  fontWeight,
  Icons,
  Link,
  Menu,
  Text,
  textLevel,
} from '../../../../../packages';
import { useStyles } from '../../MasterSchedule.styles';
import { stateCodes } from '../../../member-list/MemberList.constants';
import history from '../../../../../utils/history';
import { SUPPORTED_AUTHORITIES } from '../../../../../constants/CommonConstants';
import { getAuth } from '../../../../../redux/modules/auth/selectors';

const MasterScheduleItemV2 = ({ data, onClickSchedule }) => {
  const classes = useStyles();
  const { meta } = useSelector(getAuth);
  const { providerDetailsDto, slots, actor } = data;

  const firstAvailableDate =
    Object.keys(slots).length > 0 ? slots[Object.keys(slots)[0]]?.[0]?.start : '9999-01-01';

  const weekDays = firstAvailableDate?.includes('9999')
    ? []
    : Array.from({ length: 7 }, (_, index) => dayjs().add(index, 'day').format('YYYY-MM-DD'));

  const renderStates = states => {
    const abbreviatedStates = [];
    states.forEach(state => {
      if (state.includes(' ')) {
        const stateKey = Object.keys(stateCodes).find(
          key => key.toLowerCase().trim() === state.toLowerCase().trim(),
        );
        abbreviatedStates.push(stateCodes[stateKey]);
      } else {
        abbreviatedStates.push(stateCodes[state]);
      }
    });
    return abbreviatedStates.join(', ');
  };

  return (
    <>
      <Box sx={styles.card}>
        <Box sx={styles.availabilityCardTop}>
          <Link to={`/admin/providers/${actor}?name=general-information`}>
            <Box sx={styles.providerInfo}>
              <Avatar
                size={40}
                variant={avatarType.CIRCLE}
                src={providerDetailsDto?.providerImage}
                name={providerDetailsDto?.name}
              />
              <Box sx={styles.info}>
                {providerDetailsDto?.name}
                <Badge
                  className={clsx(classes.providerRole, providerDetailsDto?.designation?.split(' ').join(''))}
                  variant={badgeType.FILLED}
                >
                  {providerDetailsDto?.designation}
                </Badge>
              </Box>
            </Box>
          </Link>
          <Box sx={styles.availabilityCardItems}>
            <Box sx={styles.availabilityCardItemGroup}>
              <Icons glyph="alert-filled" color={colors.primary} />
              <span>{`${providerDetailsDto?.session ?? 0} sessions`}</span>
            </Box>
            <Box sx={styles.availabilityCardItemGroup}>
              <Icons glyph="alert-filled" color={colors.primary} />
              {providerDetailsDto?.states?.length > 0 ? (
                <span>{renderStates(providerDetailsDto?.states)}</span>
              ) : (
                <Text level={textLevel.S} weight={fontWeight.BOLD} color={colors.destructive600}>
                  Missing
                </Text>
              )}
            </Box>
            <Box sx={styles.availabilityCardItemGroup}>
              {providerDetailsDto?.acceptingNewPatients ? (
                <Icons glyph="check-circle" color={colors.success} />
              ) : (
                <Icons glyph="x-circle-outlined" color={colors.destructive600} />
              )}
              <span>Accept new patients</span>
            </Box>
            <Menu
              icon="more"
              items={[
                {
                  label: 'Go to profile',
                  onClick: () => history.push(`/admin/providers/${actor}?name=general-information`),
                },
                ...(meta.authority !== SUPPORTED_AUTHORITIES.ADMIN &&
                meta.authority !== SUPPORTED_AUTHORITIES.BILLING_SUPPORT
                  ? [
                      {
                        label: 'Go to chat',
                        onClick: () => history.push(`/admin/chats?userId=${actor}&type=providers`),
                      },
                    ]
                  : []),
                {
                  label: 'Schedule appointment',
                  onClick: () => onClickSchedule(),
                },
              ]}
            />
          </Box>
        </Box>
        <Box sx={styles.weekDaysGrid}>
          {weekDays.map((value, index) => (
            <Box key={index} sx={index === weekDays.length - 1 ? styles.gridItemLast : styles.gridItem}>
              <Box sx={styles.weekDay}>
                <Text level={textLevel.S} weight={fontWeight.SEMI_BOLD} color={colors.neutral900}>
                  {dayjs(value).isToday() ? 'Today' : dayjs(value).format('ddd')}
                </Text>
                <Text level={textLevel.S} weight={fontWeight.MEDIUM} color={colors.neutral600}>
                  {dayjs(value).format('M/D')}
                </Text>
              </Box>
              <Badge
                variant={badgeType.OUTLINED}
                size={badgeSize.XSMALL}
                style={
                  slots[dayjs(value).format('YYYY-MM-DD')]?.length > 0
                    ? badgeStyle.PRIMARY
                    : badgeStyle.INACTIVE
                }
                className={classes.badgePadding}
              >
                {slots[dayjs(value).format('YYYY-MM-DD')]?.length > 0
                  ? `${slots[dayjs(value).format('YYYY-MM-DD')]?.length} slots`
                  : 'No slots'}
              </Badge>
            </Box>
          ))}
        </Box>
      </Box>
    </>
  );
};

const styles = {
  card: {
    width: '100%',
    backgroundColor: '#FFF',
    borderRadius: '8px',
    boxShadow:
      '0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04)',
  },
  availabilityCardTop: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '24px',
    borderBottom: `1px solid ${colors.neutral100}`,
  },
  availabilityCardItems: {
    display: 'flex',
    gap: '24px',
  },
  availabilityCardItemGroup: {
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
  },
  weekDaysGrid: {
    display: 'flex',
  },
  weekDay: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  gridItem: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '16px',
    padding: '16px',
    borderRight: `1px solid ${colors.neutral100}`,
  },
  gridItemLast: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '16px',
    padding: '16px',
    borderRight: 'none',
  },
  providerInfo: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  },
  info: {
    display: 'flex',
    flexDirection: 'column',
    gap: 0,
  },
};

export { MasterScheduleItemV2 };
