/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
import { FC } from 'react';
import clsx from 'clsx';
import { Box, Modal } from '@mui/material';

import { Heading, Text } from '../../../../packages/ui/atoms/typography';
import { Icons } from '../../../../packages/icons';
import { Button } from '../../../../packages/ui/atoms/button';
import { colors } from '../../../../packages/colors';

import { useStyles } from './EndSession.styles';

const EndSession = ({ open, onClose, onEndSession, endedReason }) => {
  const classes = useStyles();

  const getTitle = () => {
    if (endedReason) {
      if (endedReason === 'PHONE') {
        return 'Start Documentation for Phone Call';
      }
      if (endedReason === 'IN_PERSON') {
        return 'Start Documentation for In Person';
      }
    }
    return 'End this session?';
  };

  const getSubtext = () => {
    if (endedReason) {
      if (endedReason === 'PHONE') {
        return 'By selecting this option, you will exit the waiting room and begin the documentation for this appointment with the member. Please note that once the documentation process starts, you cannot return to the waiting room to start a telehealth session.';
      }
      if (endedReason === 'IN_PERSON') {
        return 'By selecting this option, you will exit the waiting room and begin the documentation for this appointment with the member. Please note that once the documentation process starts, you cannot return to the waiting room to start a telehealth session.';
      }
    }
    return "This will end your current session with the Member. Next you'll need to sign off on the appointment notes to close out this appointment.";
  };

  const getResumeButtonText = () => {
    if (endedReason) {
      if (endedReason === 'PHONE') {
        return 'Continue Waiting';
      }
      if (endedReason === 'IN_PERSON') {
        return 'Continue Waiting';
      }
    }
    return 'Continue session';
  };

  const getEndButtonText = () => {
    if (endedReason) {
      if (endedReason === 'PHONE') {
        return 'Go to Documentation';
      }
      if (endedReason === 'IN_PERSON') {
        return 'Go to Documentation';
      }
    }
    return 'End Session';
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box className={classes.modal}>
        <Box className={classes.modalContent}>
          <Box className={classes.closeIconWrap}>
            <Icons glyph="clear-circle" className={classes.closeIcon} />
          </Box>
          <Box className={classes.content}>
            <Box>
              <Heading className={classes.title}>{getTitle()}</Heading>
              <Icons glyph="close" onClick={onClose} className={classes.closeBtn} color={colors.neutral400} />
            </Box>
            <Box>
              <Text className={classes.label}>{getSubtext()}</Text>
            </Box>
            <Box display="flex" justifyContent="space-between" alignItems="center" gap="16px">
              <Button className={clsx(classes.btn, classes.cancelBtn)} onClick={onClose}>
                {getResumeButtonText()}
              </Button>
              <Button onClick={onEndSession} className={clsx(classes.btn, classes.okBtn)}>
                {getEndButtonText()}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export { EndSession };
