import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FactorType } from '../../provider-v2/member-detail/add-factor-drawer/FactorItem';

import UpdatePriorityCard from '../sessions/video-call-screen/components/update-priority-card';
import ViewPriorityCard from '../sessions/video-call-screen/components/view-priority-card';
import CreateNewReport from '../sessions/video-call-screen/components/create-new-report';
import DrawerView from '../sessions/current-view';
import { getProfile } from '../../../redux/modules/profile/selectors';
import { getAssignedPrioritiesV2 } from '../../../services/priority/priority.service';
import { getDomainGroup } from '../../../services/member/member.service';
import { DomainTypesEnum } from '../../../constants/CommonConstants';
import { PrioritiesCard, priorityListCard } from '../sessions/priority-list/PriorityList.constants';
import { mapToLegacy } from '../../../utils/CommonUtils';

export const PriorityDetailDrawer = ({
  memberId,
  type,
  appointment,
  isSession = true,
  selectedItem,
  ...rest
}) => {
  const [showNewReport, setShowNewReport] = useState(false);
  const [selectedCard, setSelectedCard] = useState(selectedItem);
  const [isEditCard, setIsEditCard] = useState(false);
  const [loading, setLoading] = useState(false);
  const [innerElementType, setInnerElementType] = useState(null);
  const { domainTypes, lookupData } = useSelector(getProfile);
  const [allPriorityRecords, setAllPriorityRecords] = useState([]);
  const [domainOptions, setDomainOptions] = useState([]);
  const [isLoadingOptions, setIsLoadingOptions] = useState(false);
  const typeName =
    type === 'diagnoses'
      ? DomainTypesEnum.DIAGNOSES
      : type === 'medications'
      ? DomainTypesEnum.MEDICATIONS
      : type === 'cognitive-issues'
      ? DomainTypesEnum.COGNITIVE_ISSUES
      : type;

  const onCloseCard = isSubmitted => {
    if (isSubmitted && typeof isSubmitted === 'boolean') {
      if (rest.onElementsUpdated) {
        rest.onElementsUpdated(type);
      }
      if (!selectedItem) {
        fetchAssignedPriorities();
      }
    }
    if (selectedItem) {
      rest.onClose();
    } else {
      setSelectedCard(null);
      setIsEditCard(false);
      setInnerElementType(null);
    }
  };

  const onClickCard = (card, isEdit = false) => {
    setIsEditCard(isEdit);
    setSelectedCard(card);
  };

  const onClickNewReport = () => {
    setShowNewReport(true);
    setInnerElementType(null);
  };

  const onClickUpdateReport = () => {
    setIsEditCard(true);
  };
  const onCloseAddNewReport = submitted => {
    if (submitted && rest.onElementsUpdated) {
      rest.onElementsUpdated(type);
    }
    setShowNewReport(false);
  };

  const refetchNotes = useCallback(() => {
    // void fetchNotes(memberId);
  }, [memberId]);

  const fetchAssignedPriorities = () => {
    setAllPriorityRecords([]);
    setLoading(true);
    getAssignedPrioritiesV2({
      patientId: memberId,
      dataDomainType: type,
      pageNumber: 0,
      pageSize: 500,
    })
      .then(({ data }) => {
        setAllPriorityRecords(data.records.map(record => mapToLegacy(record, lookupData)));
        setLoading(false);
      })
      .catch(e => {
        console.error(e);
        setLoading(false);
        setAllPriorityRecords([]);
      });
  };

  const fetchDomainForm = typeId => {
    getDomainGroup({ domainTypeId: typeId })
      .then(({ data }) => {
        const opts = data.map(item => ({
          label: item.name,
          groupId: item.Id,
          items: item.relatedElements.map(element => ({ label: element.name, value: element.Id })),
          relatedElements: item.relatedElements,
        }));
        setDomainOptions(opts);
        setIsLoadingOptions(false);
      })
      .catch(() => {
        setDomainOptions([]);
        setIsLoadingOptions(false);
      });
  };

  useEffect(() => {
    if (type && rest.open) {
      fetchAssignedPriorities();
      const domain = domainTypes.find(item => item.typeName === typeName);
      if (domain) {
        setIsLoadingOptions(true);
        fetchDomainForm(domain.typeId);
      }
    }
  }, [type, rest.open, domainTypes]);

  useEffect(() => {
    if (selectedCard) {
      const mappedCard = priorityListCard.find(
        listCard => listCard.title.toLowerCase() === selectedCard?.domainTypeName?.toLowerCase(),
      );
      if (mappedCard) {
        setInnerElementType(mappedCard.type);
      }
    }
  }, [selectedCard]);

  useEffect(() => {
    if (innerElementType) {
      let innerTypeName;
      switch (innerElementType) {
        case PrioritiesCard.SocialDeterminants:
        case FactorType.SOCIAL_DETERMINANT:
          innerTypeName = DomainTypesEnum.SOCIALS_DETERMINANTS;
          break;
        case PrioritiesCard.LifeEvents:
        case FactorType.LIFE_EVENT:
          innerTypeName = DomainTypesEnum.LIFE_EVENTS;
          break;
        case PrioritiesCard.Symptoms:
        case FactorType.SYMPTOM:
          innerTypeName = DomainTypesEnum.SYMPTOMS;
          break;
        case PrioritiesCard.SubstanceUse:
        case FactorType.SUBSTANCE_USE:
          innerTypeName = DomainTypesEnum.SUBSTANCE_USE;
          break;
        case PrioritiesCard.SideEffects:
        case FactorType.SIDE_EFFECT:
          innerTypeName = DomainTypesEnum.SIDE_EFFECTS;
          break;
        default:
          innerTypeName = null;
          break;
      }
      if (innerTypeName) {
        const innerDomain = domainTypes.find(item => item.typeName === innerTypeName);
        if (innerDomain) {
          fetchDomainForm(innerDomain.typeId);
        }
      }
    }
  }, [domainTypes, innerElementType]);

  return (
    <>
      {selectedCard && (
        <>
          {isEditCard ? (
            <UpdatePriorityCard
              card={selectedCard?.element ?? selectedCard}
              onCloseCard={onCloseCard}
              patientId={memberId}
              sectionType={innerElementType || type}
              lookupData={lookupData}
            />
          ) : (
            <ViewPriorityCard
              card={selectedCard}
              onClickNewReport={onClickUpdateReport}
              onCloseCard={onCloseCard}
              lookupData={lookupData}
              sectionType={innerElementType || type}
            />
          )}
        </>
      )}
      {showNewReport && (
        <CreateNewReport
          sectionType={innerElementType || type}
          onCloseCard={onCloseAddNewReport}
          memberId={memberId}
          lookupData={lookupData}
          domainOptions={domainOptions}
          onSelectInnerElementType={setInnerElementType}
          isLoadingOptions={isLoadingOptions}
        />
      )}
      {!selectedCard && (
        <DrawerView
          {...rest}
          isSession={isSession}
          loading={loading}
          view={type}
          appointment={appointment}
          items={allPriorityRecords}
          onClickCard={onClickCard}
          onClickNewReport={onClickNewReport}
          refetch={refetchNotes}
        />
      )}
    </>
  );
};
