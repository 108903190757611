import { useEffect, useState } from 'react';
import { Box } from '@mui/material';

import { Drawer, drawerType } from '../../../../../packages/ui/organisms/drawer';
import { Input, inputSize, inputType } from '../../../../../packages';

import GroupCard from '../../../member-detail/components/Groups/GroupCard';

import { useStyles } from '../past-appointments-drawer/PastAppointmentsDrawer.styles';

import EmptyState from '../empty-state';

const GroupsDrawer = ({ open, onClose, isNoDrawer, items = [], appointment }) => {
  const classes = useStyles();
  const [search, setSearch] = useState('');
  const [listFiltered, setListFiltered] = useState(items);

  useEffect(() => {
    const filteredList = items.filter(item => item.name?.toLowerCase().includes(search.toLowerCase()));
    setListFiltered(filteredList);
  }, [search, items]);

  const renderContent = () => {
    return (
      <Box className={classes.container}>
        <Box className={classes.searchWrap}>
          <Input
            variant={inputType.SEARCH}
            className={classes.searchInput}
            placeholder="Search groups"
            size={inputSize.M}
            value={search}
            onChange={setSearch}
          />
        </Box>
        <Box className={classes.appointments}>
          {listFiltered.length > 0 ? (
            listFiltered.map((value, index) => <GroupCard key={index} {...value} />)
          ) : (
            <EmptyState
              title={`${appointment.participantName || 'This member'} hasn't joined any groups yet.`}
              subtitle="Maybe you should talk with them about it..."
              glyph="dinner"
              glyphOrientation="landscape"
            />
          )}
        </Box>
      </Box>
    );
  };

  if (isNoDrawer) {
    return renderContent();
  }

  return (
    <Drawer
      open={open}
      title="Groups"
      subTitle={`${listFiltered.length} total`}
      onClose={onClose}
      variant={drawerType.FORM}
      className={classes.drawer}
      footer={<div />}
    >
      {renderContent()}
    </Drawer>
  );
};

export { GroupsDrawer };
