import cx from 'clsx';
// mui
import MuiButton from '@mui/material/Button';
// types
import { btnSize, btnType } from './Button.types';
// styles
import { useStyles } from './Button.styles';

const Button = ({
  className = '',
  variant = btnType.PRIMARY,
  size = btnSize.DEFAULT,
  onClick = null,
  onKeyPress = null,
  children,
  loading = false,
  ...rest
}) => {
  const classes = useStyles({ variant });

  return (
      <MuiButton
        className={cx({
          [classes.root]: true,
          [classes.primary]: variant === btnType.PRIMARY,
          [classes.secondary]: variant === btnType.SECONDARY,
          [classes.text]: variant === btnType.TEXT,
          [classes.outline]: variant === btnType.OUTLINE,
          [classes.small]: size === btnSize.SMALL,
          [classes.xsmall]: size === btnSize.XSMALL,
          [classes.xxsmall]: size === btnSize.XXSMALL,
          [className || '']: className,
        })}
        onKeyPress={onKeyPress}
        variant={variant === btnType.TEXT ? 'text' : 'contained'}
        onClick={onClick}
        {...rest}
      >
        {loading ? <>Loading...</> : children}
      </MuiButton>
  );
};

export { Button };
