/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
import clsx from 'clsx';
import { Box, Modal } from '@mui/material';

import { Heading, Text } from '../../atoms/typography';
import { Icons } from '../../../icons';
import { Button } from '../../atoms/button';
import { colors } from '../../../colors';

import { useStyles } from './EndSession.styles';

const EndSession = ({ open, onClose, onEndSession, endedReason }) => {
  const classes = useStyles();

  const getTitle = () => {
    if (endedReason) {
      if (endedReason === 'PHONE') {
        return 'Start Documentation for Phone Call';
      }
      if (endedReason === 'IN_PERSON') {
        return 'Start Documentation for In Person';
      }
    }
    return 'Are you sure you want to end the session?';
  };

  const getSubtext = () => {
    if (endedReason) {
      if (endedReason === 'PHONE') {
        return 'By selecting this option, you will exit the waiting room and begin the documentation for this appointment with the member. Please note that once the documentation process starts, you cannot return to the waiting room to start a telehealth session.';
      }
      if (endedReason === 'IN_PERSON') {
        return 'By selecting this option, you will exit the waiting room and begin the documentation for this appointment with the member. Please note that once the documentation process starts, you cannot return to the waiting room to start a telehealth session.';
      }
    }
    return "The video chat with the patient will be closed, but you’ll still be able to complete your note.";
  };

  const getResumeButtonText = () => {
    if (endedReason) {
      if (endedReason === 'PHONE') {
        return 'Continue Waiting';
      }
      if (endedReason === 'IN_PERSON') {
        return 'Continue Waiting';
      }
    }
    return 'Continue session';
  };

  const getEndButtonText = () => {
    if (endedReason) {
      if (endedReason === 'PHONE') {
        return 'Go to Documentation';
      }
      if (endedReason === 'IN_PERSON') {
        return 'Go to Documentation';
      }
    }
    return 'End session';
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box className={classes.modal}>
        <Box className={classes.modalContent}>
          <Box className={classes.closeIconWrap}>
            <Icons glyph="clear-circle" className={classes.closeIcon} />
          </Box>
          <Box className={classes.inner}>
            <Box className={classes.content}>
              <Box>
                <Heading className={classes.title}>{getTitle()}</Heading>
                <Icons glyph="close" onClick={onClose} className={classes.closeBtn} color={colors.neutral400} />
              </Box>
              <Box>
                <Text className={classes.label}>{getSubtext()}</Text>
              </Box>
            </Box>
            <Box display="flex" justifyContent="space-between" alignItems="center" gap="16px">
              <Button className={clsx(classes.btn, classes.cancelBtn)} onClick={onClose}>
                {getResumeButtonText()}
              </Button>
              <Button onClick={onEndSession} className={clsx(classes.btn, classes.okBtn)}>
                {getEndButtonText()}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export { EndSession };
