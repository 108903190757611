/* eslint-disable max-len */
import cx from 'clsx';

// types
// styles
import { useStyles } from './shared/styles';

const KeyboardIcon = ({ className, color, onClick }) => {
  const classes = useStyles();
  return (
    <svg
      className={cx({
        [classes.root]: true,
        [className || '']: className,
      })}
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M4.99999 5H35C35.442 5 35.8659 5.17559 36.1785 5.48816C36.4911 5.80072 36.6667 6.22464 36.6667 6.66667V33.3333C36.6667 33.7754 36.4911 34.1993 36.1785 34.5118C35.8659 34.8244 35.442 35 35 35H4.99999C4.55797 35 4.13404 34.8244 3.82148 34.5118C3.50892 34.1993 3.33333 33.7754 3.33333 33.3333V6.66667C3.33333 6.22464 3.50892 5.80072 3.82148 5.48816C4.13404 5.17559 4.55797 5 4.99999 5ZM8.33333 11.6667V15H11.6667V11.6667H8.33333ZM8.33333 18.3333V21.6667H11.6667V18.3333H8.33333ZM8.33333 25V28.3333H31.6667V25H8.33333ZM15 18.3333V21.6667H18.3333V18.3333H15ZM15 11.6667V15H18.3333V11.6667H15ZM21.6667 11.6667V15H25V11.6667H21.6667ZM28.3333 11.6667V15H31.6667V11.6667H28.3333ZM21.6667 18.3333V21.6667H25V18.3333H21.6667ZM28.3333 18.3333V21.6667H31.6667V18.3333H28.3333Z"
        fill={color}
      />
    </svg>
  );
};

export default KeyboardIcon;
