import { Box } from '@mui/material';
import { IconButton } from '../../../packages';
import Heading from '../Heading';

import { HeadingFont, HeadingLevel, HeadingWeight } from '../Heading/index.types';
import { useStyles } from './index.styles';

const Header = ({
  label,
  upperActions,
  actions,
  badge = <></>,
  upper = <></>,
  middle = <></>,
  extended = <></>,
  fontFamily = HeadingFont.SECONDARY,
  fontVariant = HeadingLevel.H1,
  fontWeight = HeadingWeight.BOLD,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {upper}
      {label && (
        <Box display="flex" gap={3} alignItems="center">
          <Heading fontFamily={fontFamily} variant={fontVariant} fontWeight={fontWeight}>
            {label}
          </Heading>
          {badge}
          {upperActions && (
            <Box display="flex" gap={1.5} alignItems="center">
              {upperActions.map((action, index) => (
                <IconButton key={index} {...action}>
                  {action.children}
                </IconButton>
              ))}
            </Box>
          )}
        </Box>
      )}
      {middle}
      {actions && (
        <Box display="flex" gap={3} alignItems="center">
          {actions.map((action, index) => (
            <IconButton key={index} {...action}>
              {action.children}
            </IconButton>
          ))}
        </Box>
      )}
      {extended}
    </div>
  );
};

export default Header;
