import { makeStyles } from '@mui/styles';

// colors
import { colors } from '../../../../../../colors';

export const useStyles = makeStyles({
  container: {
    padding: '0px 16px 32px 16px',
    gap: '32px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    alignSelf: 'stretch',
  },
  animatedIcon: {
    width: '80px',
    height: '80px',
  },
  evaluationsBox: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: colors.white,
    borderRadius: '8px',
    border: `1px solid ${colors.neutral100}`,
    background: colors.white,
    boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.04), 0px 0px 2px 0px rgba(0, 0, 0, 0.06), 0px 0px 1px 0px rgba(0, 0, 0, 0.04)',
    alignSelf: 'stretch',
    '& >:last-child': {
      borderBottomLeftRadius: '8px',
      borderBottomRightRadius: '8px',
    }
  },
  headerFrame: {
    display: 'flex',
    padding: '24px 16px',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '8px',
    alignSelf: 'stretch',
    borderBottom: `1px solid ${colors.neutral100}`,
  },
  noEvaluationsTitle: {
    alignSelf: 'stretch',
    color: colors.neutral900,
    textAlign: 'center',
    fontFamily: 'Source Serif Pro',
    fontSize: '36px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '44px',
    letterSpacing: '-0.72px',
    margin: 0,
  },
  sectionTitle: {
    color: colors.neutral900,
    textAlign: 'center',
    fontFamily: 'Manrope',
    fontSize: '22px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '28px',
    letterSpacing: '-0.22px',
    margin: 0,
  },
  sectionSubtitle: {
    color: colors.neutral900,
    textAlign: 'center',
    fontFamily: 'Manrope',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '24px',
    margin: 0,
  },
  recommendationsBox: {
    display: 'flex',
    padding: '16px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '16px',
    background: colors.neutral50,
    width: '100%',
  },
  choicesContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    alignSelf: 'stretch',
  },
  choiceText: {
    alignSelf: 'stretch',
    color: colors.neutral900,
    fontFeatureSettings: '\'salt\' on',
    fontFamily: 'Manrope',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '24px',
    margin: 0,
    flex: 1,
  },
  choiceCard: {
    display: 'flex',
    padding: '16px 16px 16px 24px',
    alignItems: 'center',
    gap: '16px',
    flex: 1,
    borderRadius: '8px',
    background: colors.white,
    boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.04), 0px 0px 2px 0px rgba(0, 0, 0, 0.06), 0px 0px 1px 0px rgba(0, 0, 0, 0.04)',
  },
  recommendationsTitle: {
    alignSelf: 'stretch',
    color: colors.neutral600,
    textAlign: 'center',
    fontFamily: 'Manrope',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '20px',
    margin: 0,
  },
  prevResponse: {
    alignSelf: 'stretch',
    color: colors.neutral600,
    fontFamily: 'Manrope',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '20px',
    margin: 0,
  },
  cbTypeDesc: {
    alignSelf: 'stretch',
    color: colors.neutral600,
    textAlign: 'center',
    fontFamily: 'Manrope',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '20px',
    margin: 0,
  },
  recommendations: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    alignSelf: 'stretch',
  },
  card: {
    display: 'flex',
    padding: '16px 16px 16px 24px',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '16px',
    flex: '1 0 0',
    alignSelf: 'stretch',
    borderRadius: '8px',
    background: colors.white,
    boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.04), 0px 0px 2px 0px rgba(0, 0, 0, 0.06), 0px 0px 1px 0px rgba(0, 0, 0, 0.04)',
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
  },
  cardTitle: {
    color: colors.neutral900,
    fontFamily: 'Manrope',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '24px',
    margin: 0,
  },
  cardSubtitle: {
    color: colors.neutral600,
    fontFamily: 'Manrope',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '20px',
    margin: 0,
  },
  safeResponse: {
    color: colors.success600,
  },
  allSafeResponses: {
    color: colors.success500,
  },
  riskyResponse: {
    color: colors.destructive600,
  },
  allRiskyResponses: {
    color: colors.destructive500,
  },
  nonSafeNonRiskyResponse: {
    color: colors.warning600,
  },
  allNonSafeNonRiskyResponses: {
    color: colors.warning600,
  },
  useBtn: {
    display: 'flex',
    height: '40px',
    padding: '8px 16px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '8px',
    borderRadius: '4px',
    border: `1px solid ${colors.neutral300}`,
    background: colors.white,
  },
  moreIcon: {
    display: 'flex',
    width: '40px',
    minWidth: '40px',
    height: '40px',
    padding: '8px 12px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '8px',
    borderRadius: '4px',
    background: '#FFF',
  },
  footer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '4px',
    flex: '1 0 0',
    padding: '16px',
    borderTop: `1px solid ${colors.neutral100}`,
  },
  footerTitle: {
    alignSelf: 'stretch',
    color: colors.neutral600,
    textAlign: 'center',
    fontFamily: 'Manrope',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '20px',
    margin: 0,
  },
  prevTitle: {
    alignSelf: 'stretch',
    color: colors.neutral600,
    fontFamily: 'Manrope',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '20px',
    margin: 0,
  },
  textLink: {
    textAlign: 'center',
    fontFamily: 'Manrope',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '20px',
    padding: 0,
    height: 'auto',
  },
  skipText: {
    color: colors.neutral900,
    fontFamily: 'Manrope',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '24px',
    margin: 0,
  },
  titleBox: {
    display: 'flex',
    padding: '24px',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '4px',
    alignSelf: 'stretch',
    borderBottom: `1px solid ${colors.neutral100}`,
  },
  titleBoxWithIcon: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '4px',
    alignSelf: 'stretch',
  },
  evalHeader: {
    display: 'flex',
    padding: '24px',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '8px',
    alignSelf: 'stretch',
    borderBottom: `1px solid ${colors.neutral100}`,
  },
  questionTitle: {
    color: colors.neutral900,
    fontFamily: 'Manrope',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 600,
    textAlign: 'center',
    lineHeight: '24px',
    margin: 0,
  },
  fullPageQuestionTitle: {
    color: colors.neutral900,
    fontFamily: 'Manrope',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '28px',
    margin: 0,
  },
  questionSubtitle: {
    color: colors.neutral600,
    fontFeatureSettings: '\'salt\' on',
    fontFamily: 'Manrope',
    fontSize: '14px',
    textAlign: 'center',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '20px',
    margin: 0,
  },
  fullPageQuestionSubtitle: {
    color: colors.neutral600,
    fontFeatureSettings: '\'salt\' on',
    fontFamily: 'Manrope',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '20px',
    margin: 0,
  },
  promptContainer: {
    display: 'flex',
    padding: '24px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '8px',
    alignSelf: 'stretch',
  },
  sayBox: {
    display: 'flex',
    padding: '24px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '12px',
    alignSelf: 'stretch',
    borderRadius: '8px',
    background: colors.primary50,
  },
  promptBox: {
    display: 'flex',
    padding: '24px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '12px',
    alignSelf: 'stretch',
    borderRadius: '8px',
    background: colors.green50,
  },
  askContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '8px',
    alignSelf: 'stretch',
  },
  firstLineLayout: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '8px',
    alignSelf: 'stretch',
  },
  firstLine: {
    color: colors.neutral900,
    fontFamily: 'Manrope',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '24px',
    flex: 1,
    margin: 0,
  },
  askIcon: {
    color: colors.success,
    height: '24px',
    width: '24px',
    '& path': {
      fill: colors.success,
    },
  },
  sayIcon: {
    color: colors.primary,
    height: '24px',
    width: '24px',
    // '& path': {
    //   fill: colors.primary,
    // },
  },
  instruction: {
    color: colors.neutral900,
    fontFamily: 'Manrope',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '20px',
    margin: 0,
    display: 'list-item',
    boxSizing: 'border-box',
    marginLeft: '14px',
  },
  aiBox: {
    display: 'flex',
    padding: '24px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '8px',
    alignSelf: 'stretch',
    borderTop: `1px solid ${colors.neutral100}`,
  },
  manualBox: {
    display: 'flex',
    padding: '16px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '4px',
    borderTop: `1px solid ${colors.neutral100}`,
  },
  magicText: {
    color: colors.neutral900,
    textAlign: 'center',
    fontFeatureSettings: '\'salt\' on',
    fontFamily: 'Manrope',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '20px',
    margin: 0,
  },
  inputBox: {
    display: 'flex',
    padding: '16px 24px',
    flexDirection: 'column',
    alignItems: 'center',
    alignSelf: 'stretch',
    gap: '8px',
    flex: 1,
    borderRadius: '8px',
    background: colors.white,
    boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.04), 0px 0px 2px 0px rgba(0, 0, 0, 0.06), 0px 0px 1px 0px rgba(0, 0, 0, 0.04)',
  },
  helperText: {
    alignSelf: 'stretch',
    color: colors.neutral600,
    fontFeatureSettings: '\'salt\' on',
    fontFamily: 'Manrope',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '20px',
    margin: 0,
  },
  fullPageQuestionPrompt: {
    alignSelf: 'stretch',
    color: colors.neutral900,
    fontFeatureSettings: '\'salt\' on',
    fontFamily: 'Manrope',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '24px',
    margin: 0,
  },
  multiSelectBox: {
    display: 'flex',
    padding: '16px 16px 16px 24px',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '16px',
    flex: '1 0 0',
    borderRadius: '8px',
    background: colors.white,
    boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.04), 0px 0px 2px 0px rgba(0, 0, 0, 0.06), 0px 0px 1px 0px rgba(0, 0, 0, 0.04)',
    cursor: 'pointer',
  },
  multiSelectBoxFP: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '16px',
    flex: '1 0 0',
    background: colors.white,
    cursor: 'pointer',
  },
  multiSelectLabel: {
    color: colors.neutral900,
    fontFeatureSettings: '\'salt\' on',
    fontFamily: 'Manrope',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '24px',
    flex: 1,
  },
  checkbox: {
    padding: '8px',
  },
  adjustedRightSectionCheckIcon: {
    marginRight: '0px',
    height: '24px',
    width: '24px',
  },
  focusedChoice: {
    backgroundColor: colors.neutral50,
  },

  menu: {
    float: 'right',
    padding: '8px 12px',
    '& svg': {
      width: 16,
    },
  },
  menuItemsWrapper: {
    '& ul': {
      padding: 0,
    },
    '& ul li': {
      padding: '10px 16px',
      '&:last-child': {
        color: colors.destructive500,
        '& svg path': {
          fill: colors.destructive500,
        },
      },
    },
    '& .menu-item-custom': {
      borderBottom: `1px solid ${colors.neutral100}`,
    },
  },
  icon: {
    marginRight: 8,
  },
  dctQuestionBox: {
    display: 'flex',
    padding: '16px 16px 16px 24px',
    alignItems: 'center',
    alignSelf: 'stretch',
    gap: '16px',
    flex: '1 0 0',
    borderRadius: '8px',
    background: colors.white,
    boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.04), 0px 0px 2px 0px rgba(0, 0, 0, 0.06), 0px 0px 1px 0px rgba(0, 0, 0, 0.04)'
  },
  questionAnswer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    gap: '4px',
  },
  notSelectionBox: {
    display: 'flex',
    padding: '16px',
    alignItems: 'center',
    flexDirection: 'column',
    gap: '4px',
    borderTop: `1px solid ${colors.neutral100}`
  },
  useAllBox: {
    display: 'flex',
    padding: '16px 24px',
    alignItems: 'center',
    gap: '24px',
    borderTop: `1px solid ${colors.neutral100}`
  },
  useAllBoxFP: {
    display: 'flex',
    alignItems: 'center',
    gap: '24px',
  },
  prevResponseDeets: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '4px',
    flex: '1 0 0',
    alignSelf: 'stretch'
  },
  fullPageQuestionBox: {
    display: 'flex',
    padding: '16px 16px 16px 24px',
    flexDirection: 'column',
    alignSelf: 'stretch',
    gap: '16px',
    flex: '1 0 0',
    borderRadius: '8px',
    background: colors.white,
    boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.04), 0px 0px 2px 0px rgba(0, 0, 0, 0.06), 0px 0px 1px 0px rgba(0, 0, 0, 0.04)'
  },
  fullPagePromptIcon: {
    width: '24px !important',
    height: '24px !important',
    color: colors.success500,
    '& path': {
      fill: colors.success500,
    },
  },
  vBoxGap8: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
  hBoxGap4: {
    display: 'flex',
    flexDirection: 'row',
    gap: '4px',
  }
});
