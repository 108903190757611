/* eslint-disable max-len */
import cx from 'clsx';

// types
// styles
import { useStyles } from './shared/styles';

const PillOutlined = ({ className, color, onClick }) => {
  const classes = useStyles();
  return (
    <svg
      className={cx({
        [classes.root]: true,
        [className || '']: className,
      })}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M21.7915 2.20794L21.7916 2.2081C22.8933 3.30941 23.4991 4.7721 23.4991 6.33014C23.4991 7.88813 22.8933 9.35087 21.7916 10.453C21.7916 10.453 21.7915 10.453 21.7915 10.453L16.1221 16.1228L16.1221 16.1228L10.4526 21.7919L10.4525 21.792C9.35081 22.8941 7.88847 23.5 6.33014 23.5C4.77177 23.5 3.3091 22.8941 2.20779 21.7921L2.20763 21.7919C1.10595 20.6906 0.5 19.2279 0.5 17.6699C0.5 16.1119 1.10592 14.6492 2.20769 13.547C2.20771 13.547 2.20772 13.547 2.20773 13.547L7.87717 7.87718L7.87719 7.87716L13.5465 2.20805L13.5465 2.20802C14.6485 1.10591 16.1106 0.5 17.6691 0.5C19.2275 0.5 20.6902 1.10595 21.7915 2.20794ZM9.96619 21.3056L9.96621 21.3056L15.039 16.2334L15.3925 15.8799L15.039 15.5263L8.47393 8.96049L8.12037 8.60689L7.7668 8.96049L2.69404 14.0336L2.69398 14.0337C1.72375 15.0043 1.18805 16.2978 1.18805 17.67C1.18805 19.0423 1.72342 20.3347 2.69402 21.3055C3.66464 22.2764 4.95728 22.8121 6.33014 22.8121C7.70249 22.8121 8.99545 22.2762 9.96619 21.3056ZM21.3052 9.96658L21.3053 9.96648C22.2755 8.99565 22.811 7.70214 22.811 6.32996C22.811 4.95769 22.2757 3.66526 21.3051 2.69446C20.3345 1.72364 19.0418 1.18787 17.6691 1.18787C16.2966 1.18787 15.0036 1.72383 14.033 2.69442L8.9603 7.7668L8.60674 8.12033L8.96027 8.4739L15.5253 15.0397L15.8789 15.3933L16.2325 15.0397L21.3052 9.96658Z"
        fill={color}
        stroke={color}
      />
      <path
        d="M19.9263 5.69128C19.6571 5.69128 19.3925 5.56274 19.229 5.32452C18.834 4.74756 18.1826 4.40387 17.4857 4.40387C16.9219 4.40387 16.3923 4.62305 15.9933 5.02203C15.6636 5.35162 15.1295 5.35162 14.7999 5.02203C14.4703 4.69226 14.4703 4.15814 14.7999 3.82855C15.5175 3.1106 16.4711 2.71582 17.4857 2.71582C18.7397 2.71582 19.9122 3.33398 20.6219 4.37018C20.8852 4.75488 20.7872 5.28003 20.4023 5.54388C20.2568 5.64349 20.0907 5.69128 19.9263 5.69128Z"
        fill={color}
      />
    </svg>
  );
};

export default PillOutlined;
