// import { makeStyles } from '@mui/styles';

// // colors
// import { colors } from '../../../../colors';

// interface StyleProps {
//   open?: boolean;
//   selected?: boolean;
// }

// const filterBtnColor = (open: boolean, selected: boolean) => {
//   if (selected) return colors.textSecondary;
//   return open ? colors.textHighContrast : colors.primary600;
// };

// export const useStyles = makeStyles({
//   root: {
//     '& > .MuiPaper-root': {
//       marginTop: 8,
//       borderRadius: 6,
//       boxShadow: '0px 10px 20px rgb(0 0 0 / 10%)',
//       '& > .MuiList-root': {
//         padding: 16,
//       },
//     },
//   },
//   options: {
//     display: 'flex',
//     flexDirection: 'column',
//     gap: 16,
//     '& .MuiFormControlLabel-label': {
//       fontSize: 14,
//       fontWeight: 600,
//     },
//   },
//   optionItem: {
//     padding: 0,
//     fontFamily: 'Manrope',
//     fontWeight: 600,
//     fontSize: 14,
//     textAlign: 'left',
//     letterSpacing: '0.01em',
//     color: colors.textPrimary,
//   },
//   allOptionItem: {
//     color: colors.textHighContrast,
//   },
//   filterBtn: ({ open, selected }: StyleProps) => ({
//     fontSize: 16,
//     fontWeight: 'bold',
//     color: filterBtnColor(open, selected),
//     border: `1px solid ${colors.primary300}`,
//     height: 48,
//     minWidth: 144,
//     width: 'auto',
//     background: '#FFF',
//     padding: '12px 20px',
//     borderRadius: 6,
//     letterSpacing: 0,
//   }),
//   filterBtnDisabled: {
//     opacity: 0.7,
//     border: 'none !important',
//   },
//   appliedCount: {
//     '& > div': {
//       width: 24,
//       minWidth: 24,
//       height: 24,
//       fontSize: 12,
//       fontWeight: 'bold',
//     },
//     '& > p': {
//       marginLeft: 8,
//       fontWeight: 'bold',
//       whiteSpace: 'nowrap',
//     },
//   },
//   collapsableSectionsWrapper: {
//     padding: '24px',
//   },
//   sectionWrapper: {
//     padding: '12px 0px',
//   },
//   multiselectOptions: {
//     paddingTop: '8px',
//   },
//   input: {
//     width: '100%',
//   },
//   dropdownWrapper: {
//     display: 'flex',
//     flexDirection: 'column',
//   },
//   profileWrapper: {
//     display: 'flex',
//     flexDirection: 'row',
//     alignItems: 'center',
//     justifyContent: 'space-between',
//   },
//   deleteText: {
//     color: colors.bgCritical,
//     cursor: 'pointer',
//   },
//   wrapper: {
//     flex: 1,
//     justifyItems: 'space-between',
//     flexDirection: 'column',
//   },
//   header: {
//     display: 'flex',
//     justifyContent: 'space-between',
//     alignItems: 'center',
//     height: 80,
//     padding: '20px 16px 20px 24px',
//     borderBottom: `1px solid ${colors.neutral100}`,
//   },
//   headerTitle: {
//     fontSize: 18,
//     lineHeight: '24px',
//     fontWeight: 700,
//   },
//   footer: {
//     display: 'flex',
//     justifyContent: 'space-between',
//     alignItems: 'center',
//     height: 80,
//     padding: 16,
//     borderTop: `1px solid ${colors.neutral100}`,
//   },
//   backBtn: {
//     width: 40,
//     height: 40,
//     minWidth: 40,
//   },
//   badge: {
//     height: 'auto !important',
//     fontSize: 12,
//     padding: '0 8px',
//   },
//   nickName: {
//     fontSize: 14,
//     color: colors.neutral900,
//     lineHeight: '20px',
//     overflow: 'hidden',
//     wordBreak: 'break-word',
//     maxWidth: '148px',
//     textOverflow: 'ellipsis',
//     whiteSpace: 'nowrap',
//     display: 'block',
//   },
// });

import { makeStyles } from '@mui/styles';

// colors
import { colors } from '../../../../colors';

const filterBtnColor = (open, selected) => {
  if (selected) return colors.textSecondary;
  return open ? colors.textHighContrast : colors.primary600;
};

export const useStyles = makeStyles({
  root: {
    '& > .MuiPaper-root': {
      marginTop: 8,
      borderRadius: 6,
      boxShadow: '0px 10px 20px rgb(0 0 0 / 10%)',
      '& > .MuiList-root': {
        padding: 16,
      },
    },
  },
  options: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
    '& .MuiFormControlLabel-label': {
      fontSize: 14,
      fontWeight: 600,
    },
  },
  optionItem: {
    padding: 0,
    fontFamily: 'Manrope',
    fontWeight: 600,
    fontSize: 14,
    textAlign: 'left',
    letterSpacing: '0.01em',
    color: colors.textPrimary,
  },
  allOptionItem: {
    color: colors.textHighContrast,
  },
  filterBtn: ({ open = false, selected = false }) => ({
    fontSize: 16,
    fontWeight: 'bold',
    color: filterBtnColor(open, selected),
    border: `1px solid ${colors.primary300}`,
    height: 48,
    minWidth: 144,
    width: 'auto',
    background: '#FFF',
    padding: '12px 20px',
    borderRadius: 6,
    letterSpacing: 0,
  }),
  filterBtnDisabled: {
    opacity: 0.7,
    border: 'none !important',
  },
  appliedCount: {
    '& > div': {
      width: 24,
      minWidth: 24,
      height: 24,
      fontSize: 12,
      fontWeight: 'bold',
    },
    '& > p': {
      marginLeft: 8,
      fontWeight: 'bold',
      whiteSpace: 'nowrap',
    },
  },
  collapsableSectionsWrapper: {
    padding: '24px',
  },
  sectionWrapper: {
    padding: '12px 0px',
  },
  multiselectOptions: {
    paddingTop: '8px',
  },
  input: {
    width: '100%',
  },
  dropdownWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  profileWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  deleteText: {
    color: colors.bgCritical,
    cursor: 'pointer',
  },
  wrapper: {
    flex: 1,
    justifyItems: 'space-between',
    flexDirection: 'column',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 80,
    padding: '20px 16px 20px 24px',
    borderBottom: `1px solid ${colors.neutral100}`,
  },
  headerTitle: {
    fontSize: 18,
    lineHeight: '24px',
    fontWeight: 700,
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 80,
    padding: 16,
    borderTop: `1px solid ${colors.neutral100}`,
  },
  backBtn: {
    width: 40,
    height: 40,
    minWidth: 40,
  },
  badge: {
    height: 'auto !important',
    fontSize: 12,
    padding: '0 8px',
  },
  nickName: {
    fontSize: 14,
    color: colors.neutral900,
    lineHeight: '20px',
    overflow: 'hidden',
    wordBreak: 'break-word',
    maxWidth: '148px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    display: 'block',
  },
});
