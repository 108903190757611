import { makeStyles } from '@mui/styles';

// colors
import { colors } from '../../../../colors';

export const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  container: {
    padding: '16px',
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  rightActions: ({ isEndedSession }) => ({
    gap: isEndedSession ? '16px' : '24px',
    display: 'flex',
    alignItems: 'center',
  }),
  endSessionBtn: {
    height: '48px !important',
    padding: '12px 20px !important',
    color: colors.destructive600,
    backgroundColor: colors.destructive50,
    fontSize: '16px !important',
    lineHeight: '24px !important',
    borderRadius: '6px !important',
    boxShadow: `0px 0px 1px ${colors.neutral100}`,
    '&:hover': {
      backgroundColor: colors.destructive600,
      color: colors.white,
    },
  },
  signoffBtn: {
    height: '44px',
    padding: '12px 20px',
    fontSize: '14px',
    color: colors.success600,
    fontWeight: 700,
    borderRadius: '4px',
    lineHeight: '20px',
    '& svg path': {
      fill: colors.success600,
    },
    border: `1px solid ${colors.green100}`,
    '&:hover': {
      backgroundColor: colors.green50,
    }
  },
  leaveBtn: {
    height: '44px',
    padding: '12px 20px',
    fontSize: '14px',
    color: colors.primary600,
    fontWeight: 700,
    borderRadius: '4px',
    lineHeight: '20px',
    '& svg path': {
      fill: colors.success600,
    },
    border: `1px solid ${colors.primary300}`,
    '&:hover': {
      backgroundColor: colors.primary50,
    }
  },
  time: {
    fontSize: 14,
    lineHeight: '20px',
    color: colors.neutral600,
    margin: 0,
    fontWeight: 600,
  },
  timeRed: {
    fontSize: 16,
    lineHeight: '24px',
    color: colors.textError,
    margin: 0,
    fontWeight: 1000,
  },
  progressBar: {
    borderTop: `1px solid ${colors.neutral100}`,
    borderBottom: `1px solid ${colors.neutral100}`,
    height: '5px !important',
    backgroundColor: colors.white,
    borderRadius: '0px !important',
    '& .MuiLinearProgress-bar': {
      borderRadius: '0px !important',
      backgroundColor: colors.primary500,
    },
  }
});
