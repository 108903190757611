/* eslint-disable func-names */
/* eslint-disable object-shorthand */
import React, { createRef, useCallback, useEffect, useRef, useState } from 'react';
import { Box, ButtonGroup, Paper } from '@mui/material';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import cx from 'clsx';
import dayjs from 'dayjs';
import { CalendarView } from '../../../../../packages/constants/CommonConstants';
import $ from 'jquery';
import { Button, colors, Icons } from '../../../../../packages';
import {
  calendarToolbar,
  calendarToolbarMultipleView,
} from '../../../../../packages/ui/templates/Calendar/Calendar.config';

import { useStyles } from './Calendar.styles';

const Calendar = ({
  className,
  onDateClick,
  isMultipleView = false,
  data = [],
  filters,
  setFilters,
  isLoading,
  ...props
}) => {
  const classes = useStyles();
  const [currentView, setCurrentView] = useState(CalendarView.Month);
  const calendarRef = createRef();
  const lastNavDateRef = useRef(null);

  const jqueryRenderTimeSlot = () => {
    $(document).on(
      {
        mouseenter: function () {
          const cellWidth = $('th.fc-col-header-cell').width();
          const cellHeight = $(this).height();
          const columnCount = $('thead table.fc-col-header th.fc-col-header-cell').children().length;

          if (!$(this).html()) {
            for (let i = 0; i < columnCount; i++) {
              $(this).append(
                `<td
                  class="temp-cell"
                  style="height: ${cellHeight - 1}px; width: ${+cellWidth + 3}px"
                />`,
              );
            }
          }
        },

        mouseleave: function () {
          $(this).children('.temp-cell').remove();
        },
      },
      'td.fc-timegrid-slot.fc-timegrid-slot-lane',
    );
  };

  useEffect(() => {
    if (currentView === CalendarView.Week) {
      jqueryRenderTimeSlot();
    }
  }, [currentView]);

  const renderDayCell = ({ date, dayNumberText }) => {
    const isFirstMonthDay = new Date(date).getDate() === 1;
    return (
      <div className={classes.dayCellText}>
        {isFirstMonthDay ? dayjs(date).format('MMM D') : dayNumberText}
      </div>
    );
  };

  const renderDayHeader = ({ date }) => {
    const day = dayjs(date);
    return (
      <div className={classes.dayHeadText}>
        <div className={classes.dayHeadDay}>{day.format('DD')}</div>
        <div className={classes.dayHeadMonth}>{day.format('MMMM')}</div>
      </div>
    );
  };

  const onChangeView = view => {
    calendarRef.current?.getApi().changeView(view);
    setCurrentView(view);
  };

  const renderBtn = (label, view) => {
    return (
      <Button
        className={cx(classes.btn, { [classes.btnActive]: currentView === view })}
        onClick={() => onChangeView(view)}
      >
        {label}
      </Button>
    );
  };

  const onClickEvent = (date, items) => {
    if (onDateClick) {
      onDateClick(date, items);
    }
  };

  const renderEventContent = event => {
    const {
      event: {
        start,
        extendedProps: { items },
      },
    } = event;
    return (
      <div onClick={() => onClickEvent(start, items)} className={cx(classes.event)}>
        <div className={classes.eventContent}>
          <div className={classes.eventTitle}>{items.length} providers available</div>
        </div>
      </div>
    );
  };

  function generateEvents({ providerDetailsDto, slots }) {
    const sortedDates = slots.sort(function (a, b) {
      return b.start - a.start;
    });
    const data1 = slots.map(({ start }) => {
      return {
        provider: providerDetailsDto,
        firstAvailable: new Date(sortedDates.length !== 0 ? start : '01-01-9999'),
        date: dayjs(sortedDates.length !== 0 ? start : '01-01-9999').format('DD-MM-YYYY'),
        slots,
      };
    });

    const sortedDates1 = data1.sort(function (a, b) {
      return b.start - a.start;
    });

    const filteredItem = sortedDates1?.filter((item, index) => {
      const itemIndex = sortedDates1?.findIndex(i => {
        return (
          i?.provider?.id === item?.provider?.id &&
          dayjs(i?.firstAvailable).format('YYYY-MM-DD') === dayjs(item?.firstAvailable).format('YYYY-MM-DD')
        );
      });
      return index === itemIndex;
    });
    return filteredItem;
  }

  const events = Object.values(
    data
      .reduce((prev, curr) => {
        return [...prev, ...generateEvents(curr)];
      }, [])
      .reduce((prev, curr) => {
        const { date, provider, firstAvailable, slots } = curr;
        if (!prev[date]) {
          const [day, month, year] = date.split('-');
          return {
            ...prev,
            [date]: {
              start: new Date(year, month - 1, day, 6),
              end: new Date(year, month - 1, day, 6),
              items: [{ provider, firstAvailable, slots }],
            },
          };
        }
        return {
          ...prev,
          [date]: {
            ...prev[date],
            items: [...prev[date].items, { provider, firstAvailable, slots }],
          },
        };
      }, {}),
  );

  const handleNavClick = useCallback(dateInfo => {
    const newDate = dateInfo.view.currentStart;

    if (!lastNavDateRef.current || lastNavDateRef.current.getTime() !== newDate.getTime()) {
      lastNavDateRef.current = newDate;
      setFilters({ ...filters, startDate: dayjs(dateInfo.startStr), endDate: dayjs(dateInfo.endStr) });
    }
  }, []);

  return (
    <>
      {isLoading && (
        <Box
          sx={{
            position: 'relative',
            top: -125,
            left: 0,
            width: '100%',
            height: '100vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            opacity: 0.8,
            zIndex: 1000,
          }}
        >
          <Icons className="rotate linear infinite" glyph="in-progress" color={colors.primary} />
        </Box>
      )}
      <Paper className={cx(classes.calendarView, className, { [classes.hideCalendar]: isLoading })}>
        {isMultipleView && (
          <ButtonGroup className={classes.btnGroup}>
            {renderBtn('Month', CalendarView.Month)}
            {renderBtn('Week', CalendarView.Week)}
            {renderBtn('Day', CalendarView.Day)}
          </ButtonGroup>
        )}
        <FullCalendar
          dayHeaderClassNames={classes.dayHead}
          initialView={currentView}
          ref={calendarRef}
          dayHeaders={currentView !== CalendarView.Day}
          plugins={[interactionPlugin, dayGridPlugin, timeGridPlugin]}
          dayCellContent={renderDayCell}
          dayHeaderContent={currentView === CalendarView.Week ? renderDayHeader : undefined}
          allDaySlot={false}
          businessHours={false}
          events={events}
          dayCellClassNames={`${classes.dayCell} day-unavailable`}
          slotDuration="01:00:00"
          headerToolbar={isMultipleView ? calendarToolbarMultipleView : calendarToolbar}
          eventContent={renderEventContent}
          datesSet={dateInfo => {
            handleNavClick(dateInfo);
          }}
          {...props}
        />
        ;
      </Paper>
    </>
  );
};

export { Calendar };
