import clsx from 'clsx';
import { Box, Stack } from '@mui/material';
import { Badge, badgeStyle, badgeType, fontWeight, IconButton, Text } from '../../../../../../packages';

// styles
import { useStyles } from './CardTop.styles';

const CardTop = ({ title, subtitle, badge, className, onClick }) => {
  const classes = useStyles();

  return (
    <Box className={clsx(classes.cardTop, className)}>
      <Box>
        <Stack gap={1} alignItems="center" direction="row">
          <Text weight={fontWeight.BOLD} className={classes.cardTopTitle}>
            {title}
          </Text>
          <Badge className={classes.badge} variant={badgeType.OUTLINED} style={badgeStyle.UNRELATED}>
            {badge}
          </Badge>
        </Stack>
        <Text weight={fontWeight.MEDIUM} className={classes.cardTopSubtitle}>
          {subtitle}
        </Text>
      </Box>
      <IconButton onClick={onClick} className={classes.viewResponseBtn}>
        View responses by members
      </IconButton>
    </Box>
  );
};

export { CardTop };
