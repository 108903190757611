// import { makeStyles } from '@mui/styles';

// import { colors } from '../../../colors';

// // types
// import { pillType } from './Pill.types';

// interface StyleProps {
//   variant?: pillType;
// }

// // variant === pillType.NOT_DONE ? colors.textLowContrast : colors.white,

// const circleTextColor = {
//   [pillType.CURRENT]: colors.secondary,
//   [pillType.SUCCESS]: colors.white,
//   [pillType.DONE]: colors.white,
//   [pillType.NOT_DONE]: colors.textLowContrast,
//   [pillType.NEUTRAL]: colors.dark,
//   [pillType.WARNING]: colors.white,
//   [pillType.DANGER]: colors.white,
// };

// const borderColor = {
//   [pillType.CURRENT]: colors.secondary,
//   [pillType.SUCCESS]: colors.textSuccess,
//   [pillType.DONE]: colors.secondary,
//   [pillType.NOT_DONE]: colors.neutral100,
//   [pillType.NEUTRAL]: colors.neutral100,
//   [pillType.WARNING]: colors.white,
//   [pillType.DANGER]: colors.white,
// };

// const circleSize = {
//   [pillType.CURRENT]: 32,
//   [pillType.SUCCESS]: 32,
//   [pillType.DONE]: 32,
//   [pillType.NOT_DONE]: 32,
//   [pillType.NEUTRAL]: 24,
//   [pillType.WARNING]: 24,
//   [pillType.DANGER]: 24,
// };

// const circleFontSize = {
//   [pillType.CURRENT]: 16,
//   [pillType.SUCCESS]: 16,
//   [pillType.DONE]: 16,
//   [pillType.NOT_DONE]: 16,
//   [pillType.NEUTRAL]: 14,
//   [pillType.WARNING]: 14,
//   [pillType.DANGER]: 14,
// };

// const bgColor = {
//   [pillType.CURRENT]: colors.white,
//   [pillType.SUCCESS]: colors.textSuccess,
//   [pillType.DONE]: colors.secondary,
//   [pillType.NOT_DONE]: colors.neutral100,
//   [pillType.NEUTRAL]: colors.neutral100,
//   [pillType.WARNING]: colors.warning500,
//   [pillType.DANGER]: colors.orange500,
// };

// const labelColor = {
//   [pillType.CURRENT]: colors.secondary,
//   [pillType.SUCCESS]: colors.textSuccess,
//   [pillType.DONE]: colors.secondary,
//   [pillType.NOT_DONE]: colors.textLowContrast,
//   [pillType.NEUTRAL]: colors.textLowContrast,
//   [pillType.WARNING]: colors.warning500,
//   [pillType.DANGER]: colors.orange500,
// };

// export const useStyles = makeStyles({
//   root: {
//     display: 'flex',
//     alignItems: 'center',
//   },
//   circle: {
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'center',
//     width: ({ variant }: StyleProps) => circleSize[variant],
//     height: ({ variant }: StyleProps) => circleSize[variant],
//     borderRadius: '100%',
//     fontFamily: 'Manrope',
//     fontWeight: 'bold',
//     fontSize: ({ variant }: StyleProps) => circleFontSize[variant],
//     background: ({ variant }: StyleProps) => bgColor[variant],
//     color: ({ variant }: StyleProps) => circleTextColor[variant],
//     border: ({ variant }: StyleProps) => `2px solid ${borderColor[variant]}`,
//   },
//   circleCurrent: {
//     position: 'relative',
//     '&:after': {
//       zIndex: -1,
//       width: 40,
//       position: 'absolute',
//       top: -4,
//       left: -4,
//       height: 40,
//       borderRadius: '100%',
//       content: '""',
//       backgroundColor: colors.secondary100,
//     },
//   },
//   label: {
//     margin: '0 0 0 12px',
//     fontWeight: 700,
//     fontFamily: 'Manrope',
//     fontSize: 14,
//     lineHeight: '19px',
//     color: ({ variant }: StyleProps) => labelColor[variant],
//   },
// });

import { makeStyles } from '@mui/styles';

import { colors } from '../../../colors';

// Style configurations
const circleTextColor = {
  CURRENT: colors.secondary,
  SUCCESS: colors.white,
  DONE: colors.white,
  NOT_DONE: colors.textLowContrast,
  NEUTRAL: colors.dark,
  WARNING: colors.white,
  DANGER: colors.white,
};

const borderColor = {
  CURRENT: colors.secondary,
  SUCCESS: colors.textSuccess,
  DONE: colors.secondary,
  NOT_DONE: colors.neutral100,
  NEUTRAL: colors.neutral100,
  WARNING: colors.white,
  DANGER: colors.white,
};

const circleSize = {
  CURRENT: 32,
  SUCCESS: 32,
  DONE: 32,
  NOT_DONE: 32,
  NEUTRAL: 24,
  WARNING: 24,
  DANGER: 24,
};

const circleFontSize = {
  CURRENT: 16,
  SUCCESS: 16,
  DONE: 16,
  NOT_DONE: 16,
  NEUTRAL: 14,
  WARNING: 14,
  DANGER: 14,
};

const bgColor = {
  CURRENT: colors.white,
  SUCCESS: colors.textSuccess,
  DONE: colors.secondary,
  NOT_DONE: colors.neutral100,
  NEUTRAL: colors.neutral100,
  WARNING: colors.warning500,
  DANGER: colors.orange500,
};

const labelColor = {
  CURRENT: colors.secondary,
  SUCCESS: colors.textSuccess,
  DONE: colors.secondary,
  NOT_DONE: colors.textLowContrast,
  NEUTRAL: colors.textLowContrast,
  WARNING: colors.warning500,
  DANGER: colors.orange500,
};

// Styles definition
export const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  circle: props => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: circleSize[props.variant] || 32,
    height: circleSize[props.variant] || 32,
    borderRadius: '100%',
    fontFamily: 'Manrope',
    fontWeight: 'bold',
    fontSize: circleFontSize[props.variant] || 16,
    background: bgColor[props.variant] || colors.neutral100,
    color: circleTextColor[props.variant] || colors.textLowContrast,
    border: `2px solid ${borderColor[props.variant] || colors.neutral100}`,
  }),
  circleCurrent: {
    position: 'relative',
    '&:after': {
      zIndex: -1,
      width: 40,
      position: 'absolute',
      top: -4,
      left: -4,
      height: 40,
      borderRadius: '100%',
      content: '""',
      backgroundColor: colors.secondary100,
    },
  },
  label: props => ({
    margin: '0 0 0 12px',
    fontWeight: 700,
    fontFamily: 'Manrope',
    fontSize: 14,
    lineHeight: '19px',
    color: labelColor[props.variant] || colors.textLowContrast,
  }),
});
