import { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import cx from 'clsx';
import { useParams } from 'react-router-dom';
import { OTPublisher, OTSession, OTStreams, OTSubscriber } from '../../../../packages/opentok-react';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton, useQuery } from '../../../../packages';
import Notes from '../../sessions/video-call-screen/components/notes';
import Schedules from '../../sessions/video-call-screen/components/schedules';
import { startOrJoinCall } from '../../../../services/appointment/appointment.service';
import { OPENTOK_APIKEY } from '../../../../constants/CommonConstants';
import history from '../../../../utils/history';
import { getMessages } from '../../../../redux/modules/conversation/selectors';
import { sortingDesToAsc } from '../../../../utils/CommonUtils';
import PriorityList from '../../sessions/video-call-screen/components/priority-list';
import { showSnackbar } from '../../../../redux/modules/snackbar';
import { useStyles } from './VideoChats.styles';
import TopHeader from './top-header';
import TopBar from './top-bar';
import { tabsLeft } from './top-header/TopHeader';
import Chats from './chats';
import Members from './members';

function SubscriberVideo({ children, width, height, onPin, thumbNail }) {
  const [pinned, setPinned] = useState(false);
  const classes = useStyles();

  const onPinHandler = () => {
    setPinned(pin => !pin);
    onPin(!pinned);
  };

  const innerWidth = thumbNail ? '10%' : width;
  const innerHeight = thumbNail ? 'calc(25% - 22.5px)' : height;

  return (
    <Box
      sx={{
        borderRadius: '8px',
        overflow: 'hidden',
        transition: 'all ease-in-out 0.4s',
        position: 'relative',
        '& > div': {
          width: '100%',
          height: '100%',
          '& > div': {
            width: '100% !important',
            height: '100% !important',
          },
        },
        width: pinned ? 'calc(90% - 30px)' : innerWidth,
        height: pinned ? '100%' : innerHeight,
        order: pinned ? -1 : 0,
        '&:hover': {
          '& > .pin': {
            display: 'block',
          },
        },
      }}
    >
      {children}
      <IconButton
        icon={pinned ? 'unpin' : 'pin'}
        disabled={thumbNail && !pinned}
        onClick={onPinHandler}
        className={`pin ${classes.pin}`}
      />
    </Box>
  );
}

const VideoChats = () => {
  const classes = useStyles();
  const [showShareScreenSelection, setShowShareScreenSelection] = useState(false);
  const [selectedSession, setSelectedSession] = useState(tabsLeft[0].type);
  const [notes, setNotes] = useState('');
  const { channelUrl } = useParams();
  const params = useQuery();
  const [call, setCall] = useState({});
  const [session, setSession] = useState(null);
  const [publishVideo, setPublishVideo] = useState(true);
  const [publishAudio, setPublishAudio] = useState(true);
  const [numberOfParticipants, setNumberOfSubscribers] = useState(0);
  const messages = useSelector(getMessages);
  const sortedMessage = messages.sort(sortingDesToAsc);
  const [fullScreen, setFullScreen] = useState(false);
  const [pinned, setPinned] = useState(false);
  const [participant, setParticipant] = useState({});
  const dispatch = useDispatch();

  const isDirectCall = Boolean(params.get('isDirectCall'));
  const onShareScreenClick = () => {
    setShowShareScreenSelection(true);
  };

  const getColWidth = () => {
    if (numberOfParticipants === 1) {
      return '100%';
    }
    if (numberOfParticipants <= 4) {
      return 'calc(50% - 15px)';
    }
    if (numberOfParticipants <= 6) {
      return `calc(${100 / 3}% - 20px)`;
    }
    if (numberOfParticipants <= 8) {
      return `calc(25% - 22.5px)`;
    }
    return `calc(${100 / 6}% - 30px)`;
  };

  const getAvatarHeight = () => {
    if (numberOfParticipants <= 2) {
      return '100%';
    }
    if (numberOfParticipants <= 12) {
      return 'calc(50% - 15px)';
    }
    return `calc(${100 / 3}% - 20px)`;
  };

  const topHeaderTabChange = type => {
    setSelectedSession(type);
  };

  const onCloseSession = () => {
    setSelectedSession(tabsLeft[0].type);
  };

  const onStartOrJoinCall = async () => {
    try {
      const res = await startOrJoinCall(
        {
          channelUrl,
        },
        { isDirectCall },
      );
      setCall(res.data);
      setSession(OT.initSession(OPENTOK_APIKEY, res?.data?.sessionId));
    } catch (error) {
      dispatch(
        showSnackbar({
          snackType: 'error',
          snackMessage:
            error?.errors?.[0]?.endUserMessage ?? 'Unable to initiate call, please try again later',
        }),
      );
      history.back();
    }
  };

  /**
   * @Name sessionEventHandlers
   * @description Session event handlers details
   */
  const sessionEventHandlers = {
    streamCreated: event => {
      console.log('Stream created event', event);
    },
    signal: event => {
      if (event.type === 'session-ended' || event.type === 'signal:session-ended') {
        console.log('Signal event', event);
      }
    },
    streamDestroyed: event => {
      console.log('Stream destroyed!', event);
    },
    connectionDestroyed: event => {
      setNumberOfSubscribers(n => (n ? n - 1 : 0));
      console.log('Connection destroyed!', event);
    },
    sessionConnected: event => {
      console.log(`sessionConnected:`, event);
    },
    sessionDisconnected: event => {
      console.log(`sessionDisconnected:`, event);
    },
  };

  /**
   * @Name subscriberEventHandlers
   * @description Subscriber event handler details
   */
  const subscriberEventHandlers = {
    error: error => {
      console.log(`There was an error with the subscriber: ${error}`);
    },
    connected: event => {
      console.log(`Subscriber connected to the stream`, event);
      setNumberOfSubscribers(n => n + 1);
    },
    disconnected: event => {
      setNumberOfSubscribers(n => (n ? n - 1 : 0));
      console.log(`Subscriber disconnected from the stream`, event);
    },
    destroyed: event => {
      setNumberOfSubscribers(n => (n ? n - 1 : 0));
      console.log(`Subscriber destroyed`, event);
    },
  };

  const onStopSharingClick = () => {
    setShowShareScreenSelection(false);
  };

  /**
   * @Name publisherEventHandlers
   * @description Publisher event handlers details
   */
  const publisherEventHandlers = {
    error: error => {
      console.log(`There was an error with the publisher: ${error}`);
    },
    streamCreated: event => {
      console.log('Publisher stream created', event);
    },
    streamDestroyed: event => {
      console.log(`Publisher stream destroyed because:`, event);
    },
    mediaStopped: () => {
      onStopSharingClick();
    },
  };

  const endSession = () => {
    session.disconnect();
    history.back();
  };

  useEffect(() => {
    if (channelUrl) {
      void onStartOrJoinCall();
    }
  }, [channelUrl]);

  const toggleFullScreen = () => {
    setFullScreen(on => !on);
  };

  return (
    <Box className={classes.mainContainer}>
      {!fullScreen && (
        <TopHeader
          selectedSession={selectedSession}
          onChange={topHeaderTabChange}
          numberOfParticipants={numberOfParticipants + 1}
          onEndSession={endSession}
        />
      )}
      {!call.sessionId ? (
        <div className={classes.video} />
      ) : (
        <OTSession
          apiKey={OPENTOK_APIKEY}
          sessionId={call.sessionId}
          token={call.sessionToken}
          eventHandlers={sessionEventHandlers}
          options={{}}
        >
          <Box height={fullScreen ? '100vh !important' : 'calc(100vh - 80px)'} className={classes.content}>
            <Box className={classes.container}>
              <Box className={cx(classes.rightPanel)} />
              <Box className={classes.videoContainer}>
                <TopBar
                  onShareScreenClick={onShareScreenClick}
                  toggleMic={setPublishAudio}
                  toggleCamera={setPublishVideo}
                  onEndSession={endSession}
                  toggleFullScreen={toggleFullScreen}
                  onStopSharingClick={onStopSharingClick}
                  publisher={
                    <OTPublisher
                      properties={{ publishVideo, publishAudio, width: 200, height: 114 }}
                      eventHandlers={publisherEventHandlers}
                    />
                  }
                  screen={
                    showShareScreenSelection && (
                      <OTPublisher
                        properties={{ videoSource: 'screen', width: 200, height: 114 }}
                        eventHandlers={publisherEventHandlers}
                      />
                    )
                  }
                />
                <Box className={cx(classes.video)}>
                  <Box paddingTop="160px" height="100%">
                    <Box
                      className={classes.subscriberVideo}
                      sx={{
                        '& > div': {
                          display: 'flex',
                          gap: '30px',
                          width: '100%',
                          height: '100%',
                          flexWrap: 'wrap',
                          justifyContent: 'center',
                          flexDirection: pinned ? 'column' : 'row',
                          overflow: 'hidden',
                        },
                      }}
                    >
                      <OTStreams>
                        <SubscriberVideo
                          width={getColWidth()}
                          height={getAvatarHeight()}
                          onPin={setPinned}
                          thumbNail={pinned}
                        >
                          <OTSubscriber
                            eventHandlers={subscriberEventHandlers}
                            properties={{
                              fitMode: 'contain',
                            }}
                          />
                        </SubscriberVideo>
                      </OTStreams>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box
                className={cx(classes.rightPanel, {
                  [classes.chatPanel]:
                    selectedSession === tabsLeft[1].type ||
                    (selectedSession === tabsLeft[4].type && !participant?.participantId),
                  [classes.notePanel]: selectedSession === tabsLeft[2].type,
                  [classes.schedulePanel]: selectedSession === tabsLeft[3].type,
                  [classes.priorityPanel]: selectedSession === tabsLeft[4].type && participant?.participantId,
                })}
              >
                {selectedSession === tabsLeft[2].type && (
                  <Notes onClose={onCloseSession} notes={notes} setNotes={setNotes} />
                )}
                {selectedSession === tabsLeft[3].type && <Schedules onClose={onCloseSession} />}
                {selectedSession === tabsLeft[1].type && (
                  <Chats onClose={onCloseSession} messages={sortedMessage} channelUrl={channelUrl} />
                )}
                {selectedSession === tabsLeft[4].type && !participant?.participantId && (
                  <Members onSelect={setParticipant} onClose={onCloseSession} />
                )}
                {selectedSession === tabsLeft[4].type && participant?.participantId && (
                  <PriorityList appointment={participant} hasBack onClose={() => setParticipant({})} />
                )}
              </Box>
            </Box>
          </Box>
        </OTSession>
      )}
    </Box>
  );
};

export { VideoChats };
