import { makeStyles } from '@mui/styles';

// colors
import { colors } from '../../../packages';

export const useStyles = makeStyles({
  heading: {
    fontFamily: 'Source Serif Pro',
    margin: 0,
  },

  totalMemberBadge: {
    borderRadius: 99,
    whiteSpace: 'nowrap',
    height: 30,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 12px',
    color: colors.neutral700,
  },

  tableBox: {
    marginTop: '32px',
  },
  table: {
    borderRadius: '8px',
    backgroundColor: 'white',
    '& .MuiTableCell-body': {
      padding: '24px 16px 24px 24px !important',
    },
    '& .MuiTableCell-head:nth-child(1)': {
      borderTopLeftRadius: '8px',
    },
    '& .MuiTableCell-head:nth-child(2)': {
      borderTopRightRadius: '8px',
    },
  },
});
