import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';

import { Heading, headingLevel, Text, textLevel } from '../../../../../../packages/ui/atoms/typography';
import { iconBtnPosition, IconButton } from '../../../../../../packages/ui/molecules/icon-button';
import { Icons } from '../../../../../../packages/icons';
import { saveAs } from 'file-saver';

// styles
import { btnType } from '../../../../../../packages/ui/atoms/button';
import { useStyles } from './AttachmentCard.styles';

const AttachmentCard = ({ file, onRemove, className = '' }) => {
  const classes = useStyles();
  const isImage = file?.attachmentType?.includes('image');
  const audioRef = useRef(null);
  const isAudio = file?.attachmentType === '.mp3' || file?.attachmentType.split('/')[0] === 'audio';
  const [isPlaying, setIsPlaying] = useState(false);

  const downloadJSONFromURL = url => {
    window.open(url, '_blank');
  };

  const downloadImage = e => {
    saveAs(e, file.attachmentName);
  };
  const togglePlay = () => {
    if (audioRef.current.paused) {
      audioRef.current.play();
      setIsPlaying(true);
    } else {
      audioRef.current.pause();
      setIsPlaying(false);
    }
  };
  useEffect(() => {
    if (audioRef) {
      if (audioRef?.current) {
        audioRef?.current?.addEventListener('ended', () => {
          setIsPlaying(false);
        });
      }
    }
  });

  return (
    <div className={clsx(classes.download, className)}>
      <div className={classes.downloadPart}>
        <Icons glyph={isImage ? 'file-jpeg' : 'file-pdf'} className={classes.icon} />
        <div className={classes.texts}>
          <Heading className={classes.fileName} level={headingLevel.S}>
            {file.attachmentName}
          </Heading>
          <Text level={textLevel.M}>{file.attachmentSize}</Text>
        </div>
      </div>
      {onRemove ? (
        <IconButton icon="close" variant={btnType.TEXT} className={classes.removeBtn} onClick={onRemove} />
      ) : (
        <div className={classes.buttonWrap}>
          {(file.attachmentType !== '.json' || file.attachmentName !== 'Session Transcript') &&
            file.attachmentName !== 'Session Audio' && (
              <IconButton
                icon="download"
                variant={btnType.TEXT}
                className={classes.downloadBtn}
                onClick={() => {
                  if (file.attachmentType === '.json') {
                    downloadJSONFromURL(file.attachmentUrl);
                  } else {
                    downloadImage(file?.attachmentUrl);
                  }
                }}
              >
                Download
              </IconButton>
            )}
          <audio ref={audioRef} src={file?.attachmentUrl} />
          <IconButton
            icon="chevron-right"
            iconPosition={iconBtnPosition.RIGHT}
            variant={btnType.OUTLINE}
            className={classes.downloadBtn}
            onClick={() => {
              if (isAudio) {
                // window.open(file?.attachmentUrl, '_blank');
                togglePlay();
              } else {
                window.open(file?.attachmentUrl);
              }
            }}
          >
            {!isAudio ? 'View' : isPlaying ? 'Pause' : 'Play'}
          </IconButton>
        </div>
      )}
    </div>
  );
};

export { AttachmentCard };
