import { memo, useCallback } from 'react';
import clsx from 'clsx';
import { Box, Button as MuiButton } from '@mui/material';
import {
  Avatar,
  avatarType,
  Badge,
  badgeType,
  colors,
  fontWeight,
  Heading,
  headingLevel,
  Icons,
  Text,
} from '../../../../packages';
import { useStyles } from './ApointmentEvaluationSubtitles.styles';

const MemoizedAvatar = memo(Avatar);
export const TranscriptPart = memo(function TranscriptPart({
  editMode,
  analysisInProgress,
  idx,
  showMeta,
  imageSrc,
  name,
  timestamp,
  switched,
  isProviderMessage,
  userType,
  shouldHighlight,
  speaker,
  text,
  providerSpeaker,
  onChangeTranscriptSpeaker,
  parts,
}) {
  const classes = useStyles();

  const normalizeTime = useCallback(time => {
    const minutes = Math.floor(Number(time) / 60);
    const seconds = Number((Number(time) % 60).toFixed(0));
    return `${minutes.toFixed(0)}:${seconds >= 10 ? seconds.toFixed(0) : `0${seconds.toFixed(0)}`}`;
  }, []);

  const invertTranscriptSpeaker = useCallback(
    (spk, index) => {
      const isProvider = spk === providerSpeaker;
      onChangeTranscriptSpeaker(index, isProvider);
    },
    [providerSpeaker, parts],
  );

  return (
    <Box
      display="flex"
      alignItems="center"
      key={idx}
      gap={1.5}
      className={clsx(classes.subTitleContainer, {
        [classes.subtitleTextBox]: editMode && !analysisInProgress,
        [classes.limitedPaddingBox]: !showMeta,
        [classes.editModeSubtitle]: editMode && !analysisInProgress,
      })}
    >
      {showMeta ? (
        <Box alignSelf="flex-start">
          <MemoizedAvatar src={imageSrc} name={name} size={40} variant={avatarType.CIRCLE} />
        </Box>
      ) : (
        <Box alignSelf="flex-start" className={classes.emptyAvatarBox} />
      )}
      <Box display="flex" flex={1}>
        <Box display="flex" flexDirection="column" justifyContent="center">
          {showMeta && (
            <Box display="flex" alignItems="center" gap={1}>
              <Heading level={headingLevel.S} weight={fontWeight.SEMI_BOLD} className={classes.title}>
                {name}
              </Heading>
              <Text weight={fontWeight.SEMI_BOLD} className={classes.subtitle}>
                {normalizeTime(timestamp)}
              </Text>
              {switched && (
                <Badge
                  size={28}
                  variant={badgeType.OUTLINED}
                  className={clsx(classes.speakerSwitchedBadge, {
                    [classes.badgeProvider]: isProviderMessage,
                    [classes.badgeMember]: !isProviderMessage,
                  })}
                >
                  {isProviderMessage ? 'Recognized as a provider message' : 'Recognized as a member message'}
                </Badge>
              )}
            </Box>
          )}
          {showMeta && (
            <Heading level={headingLevel.S} weight={fontWeight.REGULAR} className={classes.subtitle3}>
              {userType}
            </Heading>
          )}
          <Box
            id={`time_${timestamp}`}
            className={classes.subtitleBox}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Text
              weight={fontWeight.REGULAR}
              className={clsx(classes.subtitle2, {
                [classes.textHighlighted]: shouldHighlight,
                [classes.memberSubtitle]: speaker !== providerSpeaker,
              })}
            >
              {text}
            </Text>
          </Box>
        </Box>
      </Box>
      <Box>
        {editMode && !analysisInProgress && (
          <MuiButton
            variant="outlined"
            className={classes.invertSingleButton}
            onClick={() => invertTranscriptSpeaker(speaker, idx)}
            title="Invert Speaker Selection"
          >
            <Icons color={colors.primary} glyph="switch-alternate" className={classes.invertSingleIcon} />
          </MuiButton>
        )}
      </Box>
    </Box>
  );
});
