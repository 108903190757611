/* eslint-disable react/destructuring-assignment */
import { useEffect, useMemo, useState } from 'react';

import {
  btnType,
  Button,
  colors,
  fontWeight,
  IconButton,
  Input,
  inputSize,
  inputType,
  Text,
} from '../../../../../../packages';
import { Box, Stack } from '@mui/material';
import { Drawer, drawerType } from '../../../../../../packages/ui/organisms/drawer';

import { getElkEvaluationReport } from '../../../../../../services/reporting/reporting.service';
import BreakdownChart from '../../../../../../pages/admin/plan-items/components/plan-item-detail/breakdown-chart';
import DctTable from '../../data-collection-templates/dct-table';
import AssignmentsChart from '../../assignments-chart';

import {
  getChartInfo,
  getEvaluationReportDetailQuery,
  getLast30DaysData,
  getPast30DaysData,
  getRecentCompletionsData,
  tableColumns,
} from './EvaluationDetail.constants';
import { useStyles } from './EvaluationDetail.styles';

const EvaluationDetail = ({ open, onClose, item }) => {
  const classes = useStyles();
  const [search, setSearch] = useState('');
  const [evaluationReportDetail, setEvaluationReportDetail] = useState(null);
  const chartInfo = useMemo(() => getChartInfo(evaluationReportDetail), [evaluationReportDetail]);
  const last30DaysData = useMemo(() => getLast30DaysData(evaluationReportDetail), [evaluationReportDetail]);
  const past30DaysData = useMemo(() => getPast30DaysData(evaluationReportDetail), [evaluationReportDetail]);
  const recentCompletionsData = useMemo(
    () => getRecentCompletionsData(evaluationReportDetail),
    [evaluationReportDetail],
  );

  useEffect(() => {
    if (!item || !item.name) {
      return;
    }
    void (async () => {
      const resp = await getElkEvaluationReport(getEvaluationReportDetailQuery(item.name), null);
      setEvaluationReportDetail(resp.data);
    })();
  }, [item]);

  const renderMenu = () => {
    return <></>;
    // return (
    //   <Menu
    //     icon="more"
    //     className={classes.menu}
    //     items={[
    //       { label: 'Menu Item 1', onClick: onClickMenu(menuTable, action?.id, 'Menu Item 1') },
    //       { label: 'Menu Item 2', onClick: onClickMenu(menuTable, action?.id, 'Menu Item 2') },
    //       { label: 'Menu Item 3', onClick: onClickMenu(menuTable, action?.id, 'Menu Item 3') },
    //     ]}
    //   />
    // );
  };

  const formatLegend = (name, opts) => {
    return `${opts.w.globals.series[opts.seriesIndex]} ${name}`;
  };

  return (
    <Drawer open={open} onClose={onClose} variant={drawerType.NORMAL} className={classes.drawer}>
      {open && (
        <Box className={classes.drawerContent}>
          <Box className={classes.headerDetail}>
            <Stack direction="column">
              <Text className={classes.headerTitle} weight={fontWeight.BOLD}>
                {item.name}
              </Text>
              <Text className={classes.headerSubtitle}>{item.total} assignments</Text>
            </Stack>
            <IconButton icon="close" onClick={onClose} className={classes.closeIconBtn} />
          </Box>
          <Box className={classes.content}>
            <Box className={classes.donut}>
              {chartInfo && (
                <BreakdownChart
                  className={classes.breakdownChart}
                  title={chartInfo.title}
                  subtitle={chartInfo.subtitle}
                  chartData={chartInfo.chartData}
                  chartHeight={200}
                  chartWidth={640}
                  donutOpts={{
                    total: chartInfo.donutOptTotal,
                    title: chartInfo.donutOptTitle,
                    size: '80%',
                    legend: { formatter: formatLegend },
                  }}
                />
              )}
            </Box>

            <Box className={classes.chartWrap}>
              <AssignmentsChart
                title="Last 30 days"
                className={classes.chart}
                chart={{
                  series: [
                    { name: 'Current 30 days', color: colors.primary500, data: last30DaysData },
                    { name: 'Past 30 days', color: colors.neutral600, data: past30DaysData },
                  ],
                  tooltipConfig: {
                    isShowTotal: false,
                    title: 'Total',
                  },
                }}
              />
            </Box>
            <Box className={classes.tableWrap}>
              <Text className={classes.recentText}>Recent completions</Text>
              <Input
                variant={inputType.SEARCH}
                placeholder="Search"
                value={search}
                className={classes.inputSearch}
                onChange={setSearch}
                size={inputSize.M}
                fullWidth
              />
              <DctTable
                className={classes.tableBordered}
                isShowAll
                searchText={search}
                tableColumns={tableColumns}
                data={recentCompletionsData}
                renderMenuAction={renderMenu}
              />
            </Box>
          </Box>
          <Box className={classes.drawerFooter}>
            <Button variant={btnType.PRIMARY} onClick={onClose}>
              Close
            </Button>
          </Box>
        </Box>
      )}
    </Drawer>
  );
};

export { EvaluationDetail };
