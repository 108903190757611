import cx from 'clsx';

// types
import { pillType } from './Pill.types';

// styles
import { useStyles } from './Pill.styles';

const Pill = ({ className = '', variant = pillType.NOT_DONE, label = '', children }) => {
  const classes = useStyles({ variant });

  return (
    <div
      className={cx(classes.root, className, {
        [classes.circleCurrent]: variant === pillType.CURRENT,
      })}
    >
      <div className={classes.circle} id="stepCircle">
        {children}
      </div>
      {label && <p className={classes.label}>{label}</p>}
    </div>
  );
};

export { Pill };
