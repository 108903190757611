import { useCallback } from 'react';
import clsx from 'clsx';

import { Badge, badgeType, Icons, Table } from '../../../../../../packages';

import { useStyles } from './DctTable.styles';

const DctTable = ({
  className,
  isShowAll,
  searchText = '',
  data,
  onRowClick,
  tableColumns,
  renderMenuAction,
}) => {
  const classes = useStyles();

  const renderColumns = tableColumns.map(column => {
    if (column.id === 'change') {
      return {
        ...column,
        renderCell: val => {
          const isUp = val > 0;
          if (val === 0) {
            return (
              <Badge variant={badgeType.FILLED} className={classes.badgeNoPercentage}>
                {val}%
              </Badge>
            );
          }
          return (
            <Badge variant={badgeType.FILLED} className={clsx(classes.badge, { [classes.badgeUp]: isUp })}>
              <>
                <Icons glyph={isUp ? 'arrow-right' : 'arrow-left'} />
                {val}%
              </>
            </Badge>
          );
        },
      };
    }
    if (column.id === 'actions' && renderMenuAction) {
      return {
        ...column,
        renderCell: renderMenuAction,
      };
    }
    return column;
  });

  const formatList = useCallback(list => list.map(item => ({ ...item, actions: { id: item.id } })), []);

  const listFormatted = formatList(data);
  const list = isShowAll
    ? listFormatted.filter(
        item => item.name?.toLowerCase().includes(searchText.toLowerCase()) || item.name.includes(searchText),
      )
    : listFormatted.slice(0, 5);

  return (
    <Table
      className={clsx(classes.table, className)}
      gridProps={{
        columns: renderColumns,
        onRowClick,
        data: list,
        isLoading: false,
      }}
    />
  );
};

export { DctTable };
