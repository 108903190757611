import { FILTER_COLLAPSABLE_SECTION_TYPE } from '../../../packages/ui/organisms/table/filter';
import { getProviderFilterOptions } from '../provider-fee/ProviderFee.constants';

export const tableColumns = [
  { id: 'appointmentTime', label: 'Date & time', sortable: false },
  { id: 'provider', label: 'Provider', sortable: false },
  { id: 'member', label: 'Member', sortable: false },
  { id: 'serviceName', label: 'Service', sortable: false },
  { id: 'amount', label: 'Amount', sortable: false },
  { id: 'payeeType', label: 'Payment method', sortable: false },
  // { id: 'insuranceProvider', label: 'Insurance provider', sortable: false },
  { id: 'patientState', label: 'State', sortable: false },
  // { id: 'cptCodes', label: 'CPT Codes', sortable: false },
  // { id: 'actions', label: '', sortable: false },
];
export const stateNames = ['Texas', 'Florida', 'Virginia', 'Connecticut'];
export const stateFilterOptions = stateNames.map(state => ({
  label: state,
  value: state,
  filterBy: 'state',
}));

export const payeeFilterOptions = [
  { label: 'Insurance', value: 'Insurance' },
  { label: 'Recurring Subscription', value: 'RecurringSubscription' },
  { label: 'Wallet/Stripe', value: 'Wallet/Stripe' },
  ...stateFilterOptions,
];

export const payeeTypes = {
  INSURANCE: 'Insurance',
  APPOINTMENT_CHARGES: 'Card payment',
  RECURRING_SUBSCRIPTION: 'Subscription',
  Wallet: 'Wallet',
  WALLET: 'Wallet',
  'Appointment Payment': 'Appointment Payment',
  HYBRID: 'Card/Wallet',
};

// const getProviderFilterOptions = (providers[]) => {
//   return {
//     sectionTitle: DROPDOWN_FILTER_COLLAPSABLE_TYPE.PROVIDERS,
//     sectionType: FILTER_COLLAPSABLE_SECTION_TYPE.DROPDOWN,
//     sectionOptions: providers.map(provider => {
//       return {
//         label: provider.fullName,
//         value: {
//           name: provider.fullName,
//           id: provider.providerId,
//           designation: provider.designation,
//           image: provider.profile,
//         },
//       };
//     }),
//   };
// };

export const paymentMultiSelectFilterOptions = providers => {
  return [
    {
      sectionTitle: 'PaymentMethod',
      sectionType: FILTER_COLLAPSABLE_SECTION_TYPE.DEFAULT,
      sectionOptions: [
        // TODO: cash payment is not yet accepted on backend side, enable it once it is implemented on backend.
        // { label: 'Cash Payment', value: 'Cash Payment' },
        { label: 'Credit Card', value: 'Credit Card' },
        { label: 'Wallet', value: 'Wallet' },
      ],
    },
    {
      sectionTitle: 'State',
      sectionType: FILTER_COLLAPSABLE_SECTION_TYPE.DEFAULT,
      sectionOptions: [
        { label: 'Texas', value: 'Texas' },
        { label: 'Florida', value: 'Florida' },
        { label: 'Connecticut', value: 'Connecticut' },
        { label: 'Virginia', value: 'Virginia' },
        { label: 'New Hampshire', value: 'New Hampshire' },
      ],
    },
    {
      sectionTitle: 'Role',
      sectionType: FILTER_COLLAPSABLE_SECTION_TYPE.DEFAULT,
      sectionOptions: [
        { label: 'Nurse Practitioner', value: 'Nurse Practitioner' },
        { label: 'Therapist', value: 'Therapist' },
        { label: 'Counselor', value: 'Counselor' },
        { label: 'Coach', value: 'Coach' },
        { label: 'Care Manager', value: 'Care Manager' },
        { label: 'Physician', value: 'Physician' },
      ],
    },
    getProviderFilterOptions(providers),
  ];
};

export const providerFeeFilterOptions = [...stateFilterOptions];
