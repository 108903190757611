/* eslint-disable max-len */
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  Badge,
  badgeType,
  Button,
  fontWeight,
  Heading,
  headingLevel,
  useQuery,
} from '../../../../../packages';
import clsx from 'clsx';
import { Box } from '@mui/material';
import dayjs from 'dayjs';
import history from '../../../../../utils/history';
import { getAuth } from '../../../../../redux/modules/auth/selectors';
import { selectAppointmentDetailsById } from '../../../../../redux/modules/appointment/selectors';
import { selectEvaluationContext } from '../../../../../redux/modules/conversation/selectors';
import { profileActionCreators } from '../../../../../redux/modules/profile';
import EvaluationDetails from '../../../priorities/appointments/evaluation-detail/EvaluationDetails';
// card component
import ProviderCard from '../provider-card';
// styles
import LLMAccessConfirmModal from '../llm-access-confirm-modal';
import { useStyles } from '../../MemberAppointmentEvaluation.styles';
import { EVALUATION_STATUS, SUPPORTED_AUTHORITIES } from '../../../../../constants/CommonConstants';

const GeneralInfo = ({ status }) => {
  const dispatch = useDispatch();
  const { memberId, appointmentId } = useParams();
  const evaluationContext = useSelector(selectEvaluationContext);
  const { isAdmin, meta } = useSelector(getAuth);
  const appointmentDetails = useSelector(selectAppointmentDetailsById(appointmentId));
  const query = useQuery();
  const classes = useStyles();

  const [accessModal, setAccessModal] = useState(false);
  const [aiType, setAiType] = useState('validation');
  const [data, setData] = useState(null);

  const provider = useMemo(
    () => appointmentDetails?.participants.filter(participant => participant.isPractitioner)?.[0],
    [appointmentDetails],
  );

  const member = useMemo(
    () => appointmentDetails?.participants.filter(participant => !participant.isPractitioner)?.[0],
    [appointmentDetails],
  );

  useEffect(() => {
    const evaluationId = query.get('evaluationId');
    if (appointmentDetails) {
      setData(appointmentDetails.evaluationProgress.find(evaluation => evaluation.id === evaluationId));
    }
  }, [query, appointmentDetails]);

  // const badgeStatusText = {
  //   completed: 'Completed',
  //   incompleted: 'Incomplete',
  //   rejected: 'Rejected',
  // };

  // const renderHeadTitle = (title: string, score?: number) => {
  //   return (
  //     <Box display="flex" gap="16px" alignItems="center" className={classes.titleSection}>
  //       <Heading
  //         className={clsx(classes.heading, classes.heading2)}
  //         level={headingLevel.M}
  //         weight={fontWeight.BOLD}
  //       >
  //         {title}
  //       </Heading>
  //       {score && (
  //         <Badge
  //           variant={badgeType.OUTLINED}
  //           className={clsx(classes.badge, classes.scoreBadge)}
  //           style={badgeStyle.HIGH}
  //         >
  //           Score: {score}
  //         </Badge>
  //       )}
  //     </Box>
  //   );
  // };

  const providerCards = [
    {
      label: 'Related appointment',
      title: appointmentDetails?.serviceName,
      subtitle: dayjs(appointmentDetails?.startTime).format('MMMM DD, YYYY, h:mma'),
    },
    {
      label: 'Provider',
      provider: {
        chatId: '1',
        name: provider?.name,
        role: 'Provider',
        thumbnail: provider?.profilePicture,
      },
    },
    {
      label: 'Patient',
      provider: {
        chatId: '2',
        name: member?.name,
        fullname: `${member?.firstName || ''} ${member?.lastName || ''}`.trim() || member?.name || 'Member',
        thumbnail: member?.profilePicture,
      },
    },
  ];

  const renderButtons = meta.authority === SUPPORTED_AUTHORITIES.ADMIN;

  return (
    <>
      <LLMAccessConfirmModal
        open={accessModal}
        isLoading={false}
        onClose={() => {
          setAccessModal(false);
        }}
        onSubmit={() => {
          setAccessModal(false);
          if (aiType === 'analysis') {
            history.push(
              `/admin/alfie/analysis/${memberId}/${appointmentId}/${evaluationContext.evaluationId}`,
            );
          } else {
            history.push(
              `/admin/alfie/validation/${memberId}/${appointmentId}/${evaluationContext.evaluationId}`,
            );
          }
        }}
      />
      <Box>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box display="flex" justifyContent="flex-start" alignItems="center">
            <Heading className={classes.heading} level={headingLevel.XL} weight={fontWeight.BOLD}>
              {evaluationContext?.name}
            </Heading>
            <Badge
              variant={badgeType.FILLED}
              className={clsx(classes.headBadge, `${classes[`${data?.status?.toLowerCase()}Badge`]}`)}
            >
              {EVALUATION_STATUS[data?.status]}
            </Badge>
          </Box>
          {evaluationContext?.alfieEvaluations && isAdmin && renderButtons && (
            <Box display="flex" gap={3}>
              {evaluationContext?.alfieEvaluations.evaluations && (
                <Button
                  onClick={() => {
                    const queryParams = {
                      searchQuery: '',
                      pageNumber: 1,
                      pageSize: 10000,
                      orderBy: '',
                      sortBy: '',
                    };
                    setAiType('validation');
                    setAccessModal(true);
                    dispatch(profileActionCreators.fetchAdminValidationTodos());
                    dispatch(profileActionCreators.fetchProviders(queryParams));
                  }}
                >
                  Validation
                </Button>
              )}
              {evaluationContext?.alfieEvaluations.inferredPriorities && (
                <Button
                  onClick={() => {
                    setAiType('analysis');
                    setAccessModal(true);
                  }}
                >
                  Analysis
                </Button>
              )}
            </Box>
          )}
        </Box>
      </Box>
      <Box display="flex" flexDirection="column" gap="56px">
        <Box className={classes.providerCards}>
          {providerCards?.map((item, idx) => (
            <ProviderCard {...item} key={idx} />
          ))}
        </Box>
        <EvaluationDetails
          fullScreen
          className={classes.evaluationDetailBody}
          evaluation={data}
          appointmentId={appointmentId}
          memberId={memberId}
        />
      </Box>
    </>
  );
};

export default GeneralInfo;
