import { Box } from '@mui/material';
import dayjs from 'dayjs';
import { tz } from 'moment-timezone';
import clsx from 'clsx';
import { getAuth } from '../../../../../redux/modules/auth/selectors';
import { useSelector } from 'react-redux';
import {
  Badge,
  badgeType,
  colors,
  fontWeight,
  iconBtnPosition,
  IconButton,
  Icons,
  Text,
} from '../../../../../packages';
import { Drawer, drawerType } from '../../../../../packages/ui/organisms/drawer';
import { ProfileInfo } from '../../../../../packages/ui/templates/profile-info';
import {
  AppointmentStatusBagde,
  AppointmentStatusText,
} from '../../../../../packages/constants/CommonConstants';
import { getTimeZoneText } from '../../../../../constants/CommonConstants';

import { useStyles } from './AppointmentsDrawer.styles';

const AppointmentsDrawer = ({ open, onClose, appointments, onSelectAppointment }) => {
  const classes = useStyles();
  const { isAdmin } = useSelector(getAuth);

  const onClickAppointment = item => () => onSelectAppointment(item);

  const renderInfo = (icon, value) => (
    <Box className={classes.boxInfo}>
      <Icons color={colors.neutral600} glyph={icon} />
      <Text weight={fontWeight.SEMI_BOLD} className={clsx(classes.text14, classes.info)}>
        {value}
      </Text>
    </Box>
  );

  const renderAppointment = appointment => (
    <Box className={classes.appointment}>
      <Box display="flex" justifyContent="space-between" sx={{ marginBottom: 4 }}>
        <Box>
          <Text weight={fontWeight.BOLD} className={classes.title}>
            {appointment.serviceName}
          </Text>
          <Text weight={fontWeight.MEDIUM} className={clsx(classes.text14, classes.duration)}>
            {appointment.serviceDuration} minutes session
          </Text>
        </Box>
        <Badge
          variant={badgeType.FILLED}
          style={AppointmentStatusBagde[appointment.status]}
          className={classes.badge}
        >
          {AppointmentStatusText[appointment.status] || 'Scheduled'}
        </Badge>
      </Box>

      <Box display="flex" alignItems="center" justifyContent="space-between">
        <ProfileInfo
          type={appointment.providerRole ? 'provider' : 'member'}
          photo={appointment.participantImage}
          nickName={appointment.participantName}
          fullName={
            appointment.providerName ||
            `${appointment.patientFirstName || ''} ${appointment.patientLastName || ''}`
          }
          role={appointment.providerRole}
          memberId={appointment?.providerRole ? appointment?.provider?.userAccountId : appointment?.patientId}
          isProvider={!isAdmin}
        />
        <Box display="flex" flexDirection="column" gap={0.5}>
          {renderInfo('event-outlined', dayjs(appointment.startTime).format('MMMM DD, YYYY'))}
          {renderInfo(
            'clock',
            `${dayjs(appointment.startTime).format('hh:mma')} ${
              appointment.providerTimezone !== null
                ? getTimeZoneText(appointment.providerTimezone)
                : tz.guess()
            }`,
          )}
        </Box>
        <IconButton
          icon="arrow-right"
          iconPosition={iconBtnPosition.RIGHT}
          className={classes.viewBtn}
          onClick={onClickAppointment(appointment)}
        >
          View details
        </IconButton>
      </Box>
    </Box>
  );

  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={dayjs(appointments[0]?.startTime).format('dddd, MMMM DD, YYYY')}
      subTitle={`${appointments.length} Sessions`}
      variant={drawerType.FORM}
      footer={<div />}
      className={classes.drawer}
    >
      <Box>{appointments.map(renderAppointment)}</Box>
    </Drawer>
  );
};

export { AppointmentsDrawer };
