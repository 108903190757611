import React, { Fragment, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fontWeight, Heading, headingLevel } from '../../../../../packages/ui/atoms/typography';
import { getProfile } from '../../../../../redux/modules/profile/selectors';
import { IUserGroup } from '../../../../../redux/modules/profile/types';
import { profileActionCreators } from '../../../../../redux/modules/profile/actions';

// styles
import { useStyles } from '../../MemberDetail.styles';
import GroupCard from './GroupCard';
import EmptyState from '../EmptyState';

const Groups = () => {
  const classes = useStyles({});

  const { userGroups = [], demographicDetails } = useSelector(getProfile);
  const dispatch = useDispatch();
  const { memberId } = useParams();

  useEffect(() => {
    if (memberId) {
      dispatch(profileActionCreators.fetchUserGroups(memberId));

      // {
      //   title: 'Healthier habits',
      //   joiningDate: 'Joined 5/3/2022',
      //   attend: '8/10 attended',
      //   sessions: [
      //     { date: 'March 15, 2021', attended: true },
      //     { date: 'March 10, 2021', attended: true },
      //     { date: 'February 24, 2021', attended: false },
      //     { date: 'February 15, 2021', attended: true },
      //     { date: 'February 15, 2021', attended: true },
      //     { date: 'February 15, 2021', attended: false },
      //   ],
      //   contributions: [{ title: '$50 contributed to the group', date: 'September 12, 2021' }],
      // },
    }
  }, []);

  return (
    <Fragment>
      <Heading className={classes.heading} level={headingLevel.XL} weight={fontWeight.BOLD}>
        Groups
      </Heading>
      <div className={classes.groupCards}>
        {userGroups.length > 0 ? (
          userGroups.map((value, index) => <GroupCard key={index} {...value} />)
        ) : (
          <EmptyState
            title={`${demographicDetails?.nickName || ''} hasn't joined any groups yet.`}
            subtitle="Maybe you should talk with them about it..."
            glyph="dinner"
            glyphOrientation="landscape"
          />
        )}
      </div>
    </Fragment>
  );
};

export default Groups;
