export const DctType = {
  CHATBOT: 'DEFAULT',
  EVALUTATION: 'EVALUATION',
};

export const ReportFilterRule = {
  EQUALS: 'equals',
  NOT_EQUALS: 'not-equals',
  GREATER_THAN: 'greater-than',
  LESS_THAN: 'less-than',
  CONTAINS: 'contains',
  NOT_CONTAIN: 'not-contain',
  BEFORE_DATE: 'before-date',
  AFTER_DATE: 'after-date',
};

export const ReportViewType = {
  PIE_CHART: 'PIE_CHART',
  TABLE: 'TABLE',
  BAR_GRAPH: 'BAR_GRAPH',
  COUNT: 'COUNT',
  LINE_CHART: 'LINE_CHART',
};

export const IClaimStatus = {
  CREATED: 'CREATED',
  INFO_REQUESTED: 'INFO_REQUESTED',
  REJECTED: 'REJECTED',
  PAID: 'PAID',
  COMPLETED: 'COMPLETED',
};
