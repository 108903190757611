import React from 'react';
import { Box } from '@mui/material';
import { fontWeight, Heading, headingLevel, Icons, Text, textLevel } from '../../../../../packages';
import Divider from '@mui/material/Divider';
import { useStyles } from './LoeAssignmentsDrawer.styles';

export const DrawerHeader = ({ title, membersCount, onClose }) => {
  const classes = useStyles();

  return (
    <Box>
      <Box className={classes.container}>
        <Box>
          <Heading level={headingLevel.M} weight={fontWeight.BOLD} className={classes.heading}>
            {title}
          </Heading>
          <Text level={textLevel.M} weight={fontWeight.MEDIUM} className={classes.memberCount}>
            {membersCount}
          </Text>
        </Box>
        <Icons glyph="close" className={classes.closeIcon} onClick={onClose} />
      </Box>
      <Divider className={classes.divider} />
    </Box>
  );
};
