/* eslint-disable max-len */
import cx from 'clsx';

// types
// styles
import { useStyles } from './shared/styles';

const TrainBot = ({ className, color, onClick }) => {
  const classes = useStyles();
  return (
    <svg
      className={cx({
        [classes.root]: true,
        [className || '']: className,
      })}
      width="18"
      height="21"
      viewBox="0 0 18 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M10 3.055C14.5 3.552 18 7.367 18 12V21H0V12C0 7.367 3.5 3.552 8 3.055V0H10V3.055ZM16 19V12C16 10.1435 15.2625 8.36301 13.9497 7.05025C12.637 5.7375 10.8565 5 9 5C7.14348 5 5.36301 5.7375 4.05025 7.05025C2.7375 8.36301 2 10.1435 2 12V19H16ZM9 17C7.67392 17 6.40215 16.4732 5.46447 15.5355C4.52678 14.5979 4 13.3261 4 12C4 10.6739 4.52678 9.40215 5.46447 8.46447C6.40215 7.52678 7.67392 7 9 7C10.3261 7 11.5979 7.52678 12.5355 8.46447C13.4732 9.40215 14 10.6739 14 12C14 13.3261 13.4732 14.5979 12.5355 15.5355C11.5979 16.4732 10.3261 17 9 17ZM9 15C9.79565 15 10.5587 14.6839 11.1213 14.1213C11.6839 13.5587 12 12.7956 12 12C12 11.2044 11.6839 10.4413 11.1213 9.87868C10.5587 9.31607 9.79565 9 9 9C8.20435 9 7.44129 9.31607 6.87868 9.87868C6.31607 10.4413 6 11.2044 6 12C6 12.7956 6.31607 13.5587 6.87868 14.1213C7.44129 14.6839 8.20435 15 9 15ZM9 13C8.73478 13 8.48043 12.8946 8.29289 12.7071C8.10536 12.5196 8 12.2652 8 12C8 11.7348 8.10536 11.4804 8.29289 11.2929C8.48043 11.1054 8.73478 11 9 11C9.26522 11 9.51957 11.1054 9.70711 11.2929C9.89464 11.4804 10 11.7348 10 12C10 12.2652 9.89464 12.5196 9.70711 12.7071C9.51957 12.8946 9.26522 13 9 13Z"
        fill={color}
      />
    </svg>
  );
};

export default TrainBot;
