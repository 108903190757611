import dayjs from 'dayjs';
import { DomainPriorities } from '../../../../redux/modules/profile/types';

export const stepTitles = {
  0: 'Step 1 - select symptoms',
  1: 'Step 2 - symptom details',
  2: 'Step 3 - review symptoms',
};

export const symptomItems = [
  {
    label: 'Feelings',
    groupId: 'Feelings',
    items: [
      { label: 'Feeling Agitated', value: 'Feeling Agitated' },
      { label: 'Feeling Anxious', value: 'Feeling Anxious' },
      { label: 'Feeling Aggressive', value: 'Feeling Aggressive' },
      { label: 'Feeling Angry', value: 'Feeling Angry' },
    ],
  },
  {
    label: 'Mental',
    groupId: 'Mental',
    items: [
      { label: 'Mental Agitated', value: 'Mental Agitated' },
      { label: 'Mental Anxious', value: 'Mental Anxious' },
      { label: 'Mental Aggressive', value: 'Mental Aggressive' },
      { label: 'Mental Angry', value: 'Mental Angry' },
    ],
  },
  {
    label: 'Sleep',
    groupId: 'Sleep',
    items: [
      { label: 'Sleep Agitated', value: 'Sleep Agitated' },
      { label: 'Sleep Anxious', value: 'Sleep Anxious' },
      { label: 'Sleep Aggressive', value: 'Sleep Aggressive' },
      { label: 'Sleep Angry', value: 'Sleep Angry' },
    ],
  },
  {
    label: 'Energy',
    groupId: 'Energy',
    items: [
      { label: 'Energy Agitated', value: 'Energy Agitated' },
      { label: 'Energy Anxious', value: 'Energy Anxious' },
      { label: 'Energy Aggressive', value: 'Energy Aggressive' },
      { label: 'Energy Angry', value: 'Energy Angry' },
    ],
  },
  {
    label: 'Substance use',
    groupId: 'Substance use',
    items: [
      { label: 'Substance Agitated', value: 'Substance Agitated' },
      { label: 'Substance Anxious', value: 'Substance Anxious' },
      { label: 'Substance Aggressive', value: 'Substance Aggressive' },
      { label: 'Substance Angry', value: 'Substance Angry' },
    ],
  },
  {
    label: 'Physical',
    groupId: 'Physical',
    items: [
      { label: 'Physical Agitated', value: 'Physical Agitated' },
      { label: 'Physical Anxious', value: 'Physical Anxious' },
      { label: 'Physical Aggressive', value: 'Physical Aggressive' },
      { label: 'Physical Angry', value: 'Physical Angry' },
    ],
  },
  {
    label: 'Sexual',
    groupId: 'Sexual',
    items: [
      { label: 'Sexual Agitated', value: 'Sexual Agitated' },
      { label: 'Sexual Anxious', value: 'Sexual Anxious' },
      { label: 'Sexual Aggressive', value: 'Sexual Aggressive' },
      { label: 'Sexual Angry', value: 'Sexual Angry' },
    ],
  },
];

export const substanceUseList = [
  {
    id: '1',
    name: 'Heroin',
    description: 'Injecting once a month for 3 months.',
    assignedBy: 'Eva Bond',
    assignedAt: dayjs().subtract(1, 'd').toISOString(),
    avatar: null,
    priority: { name: DomainPriorities.HIGH, color: '' },
    history: [],
    notes:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris aliquam sem eget libero egestas, ut dignissim nunc vehicula. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris aliquam sem eget libero egestas, ut dignissim nunc vehicula.',
    tagMetaData: {
      rxDrugInfo: {
        method: 'Injecting',
        dose: 5,
        doseUnit: 'MG',
        doseFrequency: 3,
        supply: 60,
        supplyUnit: 'DAYS',
        refillable: true,
        lastUse: 'Last week',
      },
      substanceUse: {
        currentFrequencyOfUse: 'FEW_TIMES_A_YEAR',
        howLongUsingThisLevel: 'YEARS',
        lastUse: 'LAST_WEEK',
        methodOfUse: 'SMOKE',
        unitOfUse: 'GRAMS',
      },
    },
  },
];

export const medicationItems = [
  {
    label: 'Category name',
    groupId: 'Category name',
    items: [
      { label: 'Benzodiazepine', value: 'Benzodiazepine' },
      { label: 'Alcohol', value: 'Alcohol' },
      { label: 'Fluvoxamine', value: 'Fluvoxamine' },
      { label: 'Cannabis', value: 'Cannabis' },
    ],
  },
  {
    label: 'Category name 1',
    groupId: 'Category name 1',
    items: [
      { label: 'Category name 1 - 1', value: 'Category name 1 - 1' },
      { label: 'Category name 1 - 2', value: 'Category name 1 - 2' },
      { label: 'Category name 1 - 3', value: 'Category name 1 - 3' },
      { label: 'Category name 1 - 4', value: 'Category name 1 - 4' },
    ],
  },
  {
    label: 'Category name 2',
    groupId: 'Category name 2',
    items: [
      { label: 'Category name 2 - 1', value: 'Category name 2 - 1' },
      { label: 'Category name 2 - 2', value: 'Category name 2 - 2' },
      { label: 'Category name 2 - 3', value: 'Category name 2 - 3' },
      { label: 'Category name 2 - 4', value: 'Category name 2 - 4' },
    ],
  },
];

export const stepTitlesSubstance = {
  0: 'Step 1 - select substances',
  1: 'Step 2 - substance details',
  2: 'Step 3 - review substances',
};

export const substanceDefaultValues = {
  status: '',
  notes: '',
  method: 'DRINKING',
  lastUse: 'TODAY',
  dose: {
    amount: 1,
    unit: 'MG',
  },
  frequency: 'MULTIPLE_TIMES_DAILY',
  duration: 'DAYS',
  refillable: true,
  refillTimes: {
    amount: 1,
    unit: 'TIMES',
  },
  isInterference: false,
};

export const stepTitlesSideEffect = {
  0: 'Step 1 - select side effect',
  1: 'Step 2 - side effect details',
  2: 'Step 3 - review side effects',
};

export const stepTitlesDiagnoses = {
  0: 'Step 1 - select diagnoses',
  1: 'Step 2 - diagnosis details',
  2: 'Step 3 - review diagnoses',
};

export const stepTitlesMedications = {
  0: 'Step 1 - select medications',
  1: 'Step 2 - medication details',
  2: 'Step 3 - review medications',
};

export const reportItemProgress = {
  Completed: 'Completed',
  InProgress: 'In progress',
  Incomplete: 'Incomplete',
};
