import { makeStyles } from '@mui/styles';

// colors
import { colors } from '../../../packages';

export const useStyles = makeStyles({
  root: {
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    gap: 32,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  heading: {
    fontFamily: 'Source Serif Pro',
    margin: 0,
  },
  btnGroupIcon: {
    color: colors.neutral700,
    backgroundColor: colors.white,
    padding: '14px 18px',
    transition: '0s',
    fontSize: 14,
    height: 48,
    border: `1px solid ${colors.neutral200}`,
    '&:hover': {
      color: colors.white,
      backgroundColor: colors.neutral900,
      border: `1px solid ${colors.neutral900}`,
    },
  },
  btnGroupIconActive: {
    color: colors.white,
    border: `1px solid ${colors.neutral900}`,
    backgroundColor: colors.neutral900,
  },
  iconButton: {
    color: colors.white,
    padding: 16,
    whiteSpace: 'nowrap',
  },
  loadMoreBtn: {
    fontSize: 14,
    borderRadius: 6,
    '&:hover': {
      backgroundColor: colors.primary300,
      color: colors.primary500,
    },
  },
  totalBadge: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 28,
    whiteSpace: 'nowrap',
    color: colors.neutral700,
    borderRadius: 100,
  },
  searchInput: {
    height: 64,
    width: '100%',
    marginBottom: 24,
    backgroundColor: colors.white,
  },
});
