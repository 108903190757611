import { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { scheduleActionCreators } from '../../../../redux/modules/schedule';

import ProfileCard from '../../../../pages/provider/profile/components/profile-card';
import EditGeneralAvailability from '../../../../pages/provider/profile/components/general-availability';

import { selectProviderSchedule } from '../../../../redux/modules/schedule/selectors';
import { ProfileDurationMock } from './Availability.mock';

const Availability = () => {
  const [showEdit, setShowEdit] = useState(false);
  const { providerId } = useParams();
  const providerSchedule = useSelector(selectProviderSchedule);
  const filteredSchedule = providerSchedule?.availability.filter(item => item.active === true);
  const dispatch = useDispatch();

  useEffect(() => {
    if (providerId) {
      dispatch(scheduleActionCreators.fetchProviderSchedule({ userId: providerId }));
    }
  }, [providerId]);

  // const toggleEditSlider = () => {
  //   setShowEdit(s => !s);
  // };

  const props = {
    id: ProfileDurationMock.id,
    title: ProfileDurationMock.title,
    content: {
      workingDays: filteredSchedule?.reduce((prev, item) => {
        return {
          ...prev,
          [item.day.toLowerCase()]: prev[item.day.toLowerCase()]
            ? [...prev[item.day.toLowerCase()], ...item.slots]
            : item.slots,
        };
      }, {}),
    },
  };

  return (
    <>
      <Box display="flex" flexDirection="column" gap="16px">
        <ProfileCard
          {...props}
          onEdit={() => {
            setShowEdit(true);
          }}
        />
      </Box>

      <EditGeneralAvailability isOpen={showEdit} onClose={() => setShowEdit(false)} providerId={providerId} />
    </>
  );
};

export { Availability };
