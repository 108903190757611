import cx from 'clsx';
import Heading from '../../../components/v2/Heading';
import { HeadingLevel } from '../../../components/v2/Heading/index.types';
import Typography from '../../../components/v2/Typography';
import { typography } from '../../../components/v2/Typography/index.constant';
import { colors, Icons } from '../../../packages';
import dayjs from '../../../utils/dayjs';
import { Box, Link } from '@mui/material';
import Card from './components/Card';
import { useStyles } from './index.styles';

const NoAppointments = ({
  date,
  setSchedulesDrawerOpen,
  nextAppointment,
  setCurrentDate,
  setCalendarKey,
}) => {
  const classes = useStyles();
  return (
    <Card
      img={<Icons glyph="sign-off-todo" className={classes.img64} />}
      action={{
        className: cx(classes.outlineBtn, classes.xsmallBtn),
        children: <Typography {...typography.body.s.bold}>New appointment</Typography>,
        onClick: () => setSchedulesDrawerOpen(true),
      }}
      extended={
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          <Heading variant={HeadingLevel.H6} letterSpacing="-0.5px">
            No appointments scheduled for {dayjs(date).format('MMM D')}, yet.
          </Heading>
          {nextAppointment && (
            <Box>
              <Typography {...typography.body.m.medium} color={colors.neutral600}>
                Your next appointment is on{' '}
                <Link
                  onClick={e => {
                    e.preventDefault();
                    setCurrentDate(dayjs(nextAppointment.startTime));
                  }}
                  className={classes.link}
                  // style={{ cursor: 'pointer' }}
                >
                  <Typography color={colors.primary} component="span">
                    {dayjs(nextAppointment.startTime).format('MM/DD/YYYY')}
                  </Typography>
                </Link>
              </Typography>
            </Box>
          )}
        </Box>
      }
    />
  );
};

export default NoAppointments;
