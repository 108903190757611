import { makeStyles } from '@mui/styles';

// colors
import { colors } from '../../../packages';

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  heading: {
    fontFamily: 'Source Serif Pro',
    margin: 0,
  },
  totalBadge: {
    borderRadius: 99,
    whiteSpace: 'nowrap',
    height: 30,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 12px',
    color: colors.neutral700,
  },
  addBtn: {
    color: '#fff',
    fontSize: 16,
    whiteSpace: 'nowrap',
    height: 64,
    padding: '20px 24px',
    '& svg': {
      width: 24,
      '& path': {
        fill: '#fff',
      },
    },
  },
  table: {
    backgroundColor: '#fff',
    borderRadius: 4,
    overflow: 'hidden',
  },
  stBadge: {
    minWidth: 'auto',
    fontSize: 14,
    height: 28,
    lineHeight: '20px',
    width: 30,
    fontWeight: 600,
  },
  menu: {
    float: 'right',
    right: 20,
    '& svg': {
      width: 16,
    },
  },
  menuItemsWrapper: {
    '& ul': {
      padding: 0,
    },
    '& ul li': {
      padding: '10px 16px',
    },
  },
});
