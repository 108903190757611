// components
import { Box } from '@mui/material';
import ReactApexChart from 'react-apexcharts';
import { colors, fontWeight, Heading, headingLevel } from '../../../../../packages';
import { ProgressBar } from '../../../../../packages/ui/atoms/ProgressBar';
import { useSelector } from 'react-redux';

import { selectLoeReport } from '../../../../../redux/modules/schedule/selectors';
import { getChartOpts } from './DonutChart.utils';
// styles
import { useStyles } from './DonutChart.styles';

const DonutChart = ({ sorted }) => {
  const classes = useStyles();
  const { levelOfEngagementStats, outReachNeededStats } = useSelector(selectLoeReport);

  const colorMap = {
    'No Status': colors.neutral500,
    'New member': colors.primary,
    Referral: colors.indigo500,
    Active: colors.success,
    'Referral Active': colors.success400,
    'Successful Discharge': '#087443',
    'Self Discharge': colors.cyan500,
    'Discharged to HLOC': '#FD6F8E',
    'Lost to Care': colors.destructive500,
    'Not admitted to care': colors.orange500,
    DIY: colors.purple500,
    Ghost: colors.bgCritical,
    'Signup incomplete': colors.warning600,
    Invited: colors.secondary300,
    'Invite pending': colors.neutral900,
  };

  const legendSeries = sorted.map(item => {
    return {
      name: item.levelOfEngagementStatus,
      color: colorMap[item.levelOfEngagementStatus],
      data: [item.memberCount],
    };
  });

  const series = sorted.map(item => {
    return item.memberCount;
  });

  return (
    <Box className={classes.card}>
      <Heading class={classes.heading} level={headingLevel.S} weight={fontWeight.REGULAR}>
        By assignments
      </Heading>
      <ReactApexChart
        width={650}
        options={getChartOpts(
          legendSeries,
          {
            total: levelOfEngagementStats.totalUniqueStatuses,
            title: 'Levels total',
            size: '85%',
          },
          true,
        )}
        series={series}
        type="donut"
        height="662px"
      />
      <Heading class={classes.heading2} level={headingLevel.S} weight={fontWeight.REGULAR}>
        Outreach needed
      </Heading>
      <ProgressBar
        value={outReachNeededStats.percentageOutreachNeeded}
        variant="determinate"
        className={classes.progressBar}
      />
    </Box>
  );
};

export { DonutChart };
