/* eslint-disable max-len */
import cx from 'clsx';

// types
// styles
import { useStyles } from './shared/styles';

const QuestionOutlined = ({ className, color, onClick }) => {
  const classes = useStyles();
  return (
    <svg
      className={cx({
        [classes.root]: true,
        [className || '']: className,
      })}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M9.99984 18.3334C5.39734 18.3334 1.6665 14.6026 1.6665 10.0001C1.6665 5.39758 5.39734 1.66675 9.99984 1.66675C14.6023 1.66675 18.3332 5.39758 18.3332 10.0001C18.3332 14.6026 14.6023 18.3334 9.99984 18.3334ZM9.99984 16.6667C11.7679 16.6667 13.4636 15.9644 14.7139 14.7141C15.9641 13.4639 16.6665 11.7682 16.6665 10.0001C16.6665 8.23197 15.9641 6.53628 14.7139 5.28604C13.4636 4.03579 11.7679 3.33341 9.99984 3.33341C8.23173 3.33341 6.53604 4.03579 5.28579 5.28604C4.03555 6.53628 3.33317 8.23197 3.33317 10.0001C3.33317 11.7682 4.03555 13.4639 5.28579 14.7141C6.53604 15.9644 8.23173 16.6667 9.99984 16.6667ZM9.1665 12.5001H10.8332V14.1667H9.1665V12.5001ZM10.8332 11.1292V11.6667H9.1665V10.4167C9.1665 10.1957 9.2543 9.98377 9.41058 9.82749C9.56686 9.67121 9.77882 9.58342 9.99984 9.58342C10.2366 9.5834 10.4684 9.51616 10.6684 9.38952C10.8685 9.26288 11.0284 9.08205 11.1296 8.86807C11.2309 8.65408 11.2693 8.41574 11.2404 8.18078C11.2115 7.94582 11.1165 7.7239 10.9664 7.54084C10.8163 7.35778 10.6173 7.22111 10.3925 7.14672C10.1678 7.07234 9.92653 7.0633 9.69686 7.12067C9.46718 7.17803 9.25852 7.29944 9.09514 7.47076C8.93177 7.64208 8.8204 7.85628 8.774 8.08842L7.139 7.76091C7.24036 7.25432 7.47443 6.78372 7.81727 6.39724C8.16011 6.01075 8.59944 5.72223 9.09033 5.56118C9.58122 5.40013 10.1061 5.37232 10.6112 5.48059C11.1164 5.58887 11.5838 5.82934 11.9655 6.17744C12.3473 6.52553 12.6298 6.96875 12.7841 7.4618C12.9384 7.95485 12.9591 8.48004 12.8439 8.98367C12.7287 9.48731 12.4819 9.95133 12.1286 10.3283C11.7753 10.7053 11.3283 10.9817 10.8332 11.1292Z"
        fill={color}
      />
    </svg>
  );
};

export default QuestionOutlined;
