import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { getProfile } from '../../redux/modules/profile/selectors';
import { Box } from '@mui/material';
import { fontWeight, IconButton, Text } from '../../packages';
import { Drawer, drawerType } from '../../packages/ui/organisms/drawer';
import AskMeAnythingComponent from '../../packages/ui/organisms/ask-me-anything';
import { fetchAMAState, postAMA } from '../../services/report-service/reportService.service';
import { useStyles } from './AskMeAnything.styles';

const AskMeAnything = ({ isOpen = false, onClose = null, memberId, useDrawer = false }) => {
  const classes = useStyles();
  const { profile } = useSelector(getProfile);
  const TIME_LIMIT = 30000;
  const [question, setQuestion] = useState(null);
  const [response, setResponse] = useState(null);
  const [isError, setIsError] = useState(false);
  const [isLoadingResponse, setIsLoadingResponse] = useState(false);

  const getAskedQuestionState = async (identifier, startTime = Date.now()) => {
    try {
      const pathParams = { providerId: profile?.providerId, memberId, identifier };
      const stateResponse = await fetchAMAState(pathParams);
      if (stateResponse.data.state?.status === 'COMPLETED') {
        setIsLoadingResponse(false);
        setResponse(stateResponse.data.state?.response);
      } else {
        const elapsedTime = Date.now() - startTime;

        if (elapsedTime >= TIME_LIMIT) {
          setIsLoadingResponse(false);
          setResponse('This question is a little too complicated for me right now.');
        } else {
          setTimeout(() => {
            void getAskedQuestionState(identifier, startTime);
          }, 4000);
        }
      }
    } catch (e) {
      console.warn(e);
      setIsLoadingResponse(false);
      setIsError(true);
    }
  };

  const handleSubmitQuestion = async () => {
    try {
      setIsLoadingResponse(true);
      const identifier = Date.now();

      const bodyRequest = {
        memberId,
        providerId: profile?.providerId,
        question,
        identifier,
      };
      const invokeResponse = await postAMA(bodyRequest);
      if (invokeResponse.data?.success) {
        await getAskedQuestionState(identifier);
      }
    } catch (e) {
      console.warn(e);
    }
  };

  const renderAMA = () => (
    <AskMeAnythingComponent
      memberId={memberId}
      question={question}
      response={response}
      setQuestion={setQuestion}
      onSubmit={handleSubmitQuestion}
      isLoading={isLoadingResponse}
      isError={isError}
    />
  );

  return (
    <>
      {useDrawer ? (
        <Drawer open={isOpen} onClose={onClose} variant={drawerType.NORMAL} className={classes.drawer}>
          <Box className={classes.wrapper}>
            <Box className={classes.header}>
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Text weight={fontWeight.BOLD} className={classes.headTitle}>
                  Ask us anything
                </Text>
                <IconButton icon="close" className={classes.closeBtn} onClick={onClose} />
              </Box>
            </Box>
            <Box className={classes.content}>{renderAMA()}</Box>
          </Box>
        </Drawer>
      ) : (
        renderAMA()
      )}
    </>
  );
};

export { AskMeAnything };
