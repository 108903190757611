import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';

import { btnType, Button, fontWeight, Heading, headingLevel } from '../../../../../packages';

import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { auditActionCreators } from '../../../../../redux/modules/audit';
import { getAudit } from '../../../../../redux/modules/audit/selectors';
import { getProfile } from '../../../../../redux/modules/profile/selectors';

import { ActivityActionType } from '../../../../../constants/CommonConstants';

import ActivityCard from './ActivityCard';
import EmptyState from '../EmptyState';
import { useStyles } from '../../MemberDetail.styles';

const Activity = () => {
  const classes = useStyles({});
  const [pageSize, setPageSize] = useState(10);
  const { activity = {}, isLoading } = useSelector(getAudit);
  const { demographicDetails } = useSelector(getProfile);
  const dispatch = useDispatch();
  const { memberId } = useParams();
  const [lastActivities, setLastActivities] = useState([]);

  const formatSubtitle = (description, replaceValue) => {
    return description.replace('%s', String(replaceValue));
  };

  const formatSpecialTitleOrSubtitle = (subject, action, object) => {
    if (object) {
      return `${subject} ${action} with ${object}`;
    }
    return `${subject} ${action}`;
  };

  const onLoadMoreClick = () => {
    setPageSize(s => s + 10);
  };

  const formatActivity = (recentActivity, title, icon, color, replaceValue, subTitle) => {
    return {
      title, // changed it to removed bookmarked
      subTitle: subTitle ?? formatSubtitle(recentActivity.activityDescription, replaceValue),
      date: recentActivity.activityTime
        ? dayjs(recentActivity.activityTime, 'YYYY-MM-DDhh:mm:ss').format('MMMM DD, YYYY [at] h:mm a')
        : '',
      icon,
      color,
    };
  };

  const parseActivitiesList = activities => {
    setLastActivities(
      activities.map(item => {
        switch (item.activityActionType) {
          case ActivityActionType.CONTENT_BOOKMARKED:
            return formatActivity(
              item,
              'Content bookmarked',
              'bookmarked-outlined',
              'primary',
              item.referenceText ?? '',
            );
          case ActivityActionType.CONTENT_REMOVED_BOOKMARKED:
            return formatActivity(item, 'Content bookmark removed', 'book-opened-outlined', 'secondary');
          case ActivityActionType.CONTENT_MARKED_AS_COMPLETE:
            return formatActivity(
              item,
              'Content marked as complete',
              'book-side-filled-outlined',
              'success',
              item.referenceText ?? '',
            );
          case ActivityActionType.CONVERSATION_ASSIGNED:
            return formatActivity(item, 'Conversation assigned', 'screen-shared-outlined', 'primary');
          case ActivityActionType.CONVERSATION_COMPLETED:
            return formatActivity(
              item,
              formatSpecialTitleOrSubtitle(item.referenceText ?? 'Conversation', 'completed'),
              'screen-checked-outlined',
              'success',
              item.referenceText,
            );
          case ActivityActionType.DCT_STARTED:
            return formatActivity(
              item,
              formatSpecialTitleOrSubtitle(item.referenceText ?? 'DCT', 'started'),
              'survey-line',
              'primary',
            );
          case ActivityActionType.DCT_COMPLETED:
            return formatActivity(
              item,
              formatSpecialTitleOrSubtitle(item.referenceText ?? 'DCT', 'completed'),
              'survey-line',
              'success',
              item.referenceText,
            );
          case ActivityActionType.APPOINTMENT_SCHEDULED:
            return formatActivity(
              item,
              formatSpecialTitleOrSubtitle(item.referenceText ?? 'Appointment', 'scheduled'),
              'appointment',
              'primary',
              item.activityDetail.name,
              formatSpecialTitleOrSubtitle(item.referenceText, 'scheduled', item.activityDetail.name),
            );
          case ActivityActionType.APPOINTMENT_COMPLETED:
            return formatActivity(
              item,
              formatSpecialTitleOrSubtitle(item.referenceText, 'completed'),
              'calendar-checked-outlined',
              'success',
              item.activityDetail.name,
              formatSpecialTitleOrSubtitle(item.referenceText, 'completed', item.activityDetail.name),
            );
          case ActivityActionType.APPOINTMENT_MARK_FOR_SIGNOFF:
            return formatActivity(item, 'Appointment mark for sign off', 'appointment', 'secondary');
          case ActivityActionType.CONNECTED:
            return formatActivity(item, 'Connected with a provider', 'connect', 'primary');
          case ActivityActionType.DISCONNECTED:
            return formatActivity(item, 'Disconnected from provider', 'disconnect', 'destructive');
          case ActivityActionType.JOINED_GROUP:
            return formatActivity(
              item,
              'Joined a group',
              'add-user-outlined',
              'primary',
              item.referenceText ?? 'a group',
            );
          case ActivityActionType.LEFT_GROUP:
            return formatActivity(item, 'Left a group', 'remove-user-outlined', 'destructive');
          case ActivityActionType.JOINED_GROUP_SESSION:
            return formatActivity(item, 'Joined a group session', 'members-outlined', 'primary');
          default:
            return formatActivity(
              item,
              'Login',
              'file-list-line',
              'primary',
              new Date(item.activityTime).toLocaleString(),
            );
        }
      }),
    );
  };

  useEffect(() => {
    if (memberId) {
      dispatch(auditActionCreators.fetchUserActivity({ userId: memberId, pageSize }));
    }
  }, [pageSize]);

  useEffect(() => {
    if (activity.recentActivities) {
      parseActivitiesList(activity.recentActivities);
    }
  }, [activity]);

  return (
    <>
      <div className={classes.headingWithBtnWrapper}>
        <Heading className={classes.heading} level={headingLevel.XL} weight={fontWeight.BOLD}>
          Activity
        </Heading>
        {/* <Select
          className={classes.activitySelect}
          variant={selectType.SECONDARY}
          options={[{ value: 1, label: 'Show all' }]}
        /> */}
      </div>
      <div className={classes.activityCards}>
        {lastActivities.length > 0 ? (
          <>
            {lastActivities.map((value, index) => (
              <ActivityCard key={index} {...value} isLastItem={index === lastActivities.length - 1} />
            ))}
            {lastActivities.length >= pageSize && (
              <Button onClick={onLoadMoreClick} disabled={isLoading} variant={btnType.TEXT}>
                Load more
              </Button>
            )}
          </>
        ) : (
          <EmptyState
            title="No activity yet"
            subtitle={`${demographicDetails.nickName} doesn’t have activity yet.`}
          />
        )}
      </div>
    </>
  );
};

export default Activity;
