import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Stack } from '@mui/material';
import clsx from 'clsx';
import { Filter, Table } from '../../../packages/ui/organisms/table';
import {
  Badge,
  badgeStyle,
  badgeType,
  colors,
  fontWeight,
  Heading,
  headingLevel,
  Icons,
  Menu,
  Text,
} from '../../../packages';
import { ProfileInfo } from '../../../packages/ui/templates/profile-info';
import { scheduleActionCreators } from '../../../redux/modules/schedule';
import { selectAppointmentReport } from '../../../redux/modules/schedule/selectors';
import dayjs from '../../../utils/dayjs';
import { BaseLayout } from '../../../layouts/base';
import { DROPDOWN_FILTER_COLLAPSABLE_TYPE } from '../../../packages/ui/organisms/table/filter';
import { profileActionCreators } from '../../../redux/modules/profile';
import { saveAs } from 'file-saver';

import PaymentDetailDrawer from './payment-detail-drawer';

import {
  payeeFilterOptions,
  payeeTypes,
  paymentMultiSelectFilterOptions,
  tableColumns,
} from './Payments.constants';
import { useStyles } from './Payments.styles';
import { getAppointmentReport } from '../../../services/schedule/schedule.service';
import { showSnackbar } from '../../../redux/modules/snackbar';

const PaymentList = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [filterChangeLoading, setFilterChangeLoading] = useState(false);
  const [resetMultiSelectFilter, setResetMultiSelectFilter] = useState(false);
  const [filterOptionsMock, setFilterOptionsMock] = useState([]);
  const [tableParams, setTableParams] = useState({
    search: {
      searchKey: '',
      filter: [],
      dateFilter: {
        startDate: '',
        endDate: '',
      },
    },
    pagination: { currentPage: 1, rowsPerPage: 10 },
    sorter: { direction: 'desc', column: '' },
  });
  const [selectedItem, setSelectedItem] = useState(null);
  const [appointmentList, setAppointmentList] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState([]);
  const providersList = useSelector(state => state.profile.providers2);
  const { isLoading, appointmentRecord, totalRecords, totalCard, totalWallet, totalCash } =
    useSelector(selectAppointmentReport);

  useEffect(() => {
    if (providersList?.length <= 0) dispatch(profileActionCreators.fetchAllProviders());
  }, []);

  const onRowClick = data => {
    setSelectedItem(data);
  };

  const onChangeFilter = filter => {
    setSelectedFilter(filter);
  };

  const populateData = () => {
    const formatAppointments = (appointmentRecord || []).map(appointment => ({
      ...appointment,
      member: {
        nickName: appointment?.patientNickName,
        fullName:
          appointment?.patientFirstName && appointment?.patientLastName
            ? `${appointment?.patientFirstName || ''} ${appointment?.patientLastName || ''}`
            : appointment?.patientNickName ?? 'No Name',
        photo: appointment?.patientProfileImage,
        id: appointment?.memberId,
        uuid: appointment?.patientUuid,
      },
      provider: {
        fullName: appointment?.providerFullName,
        photo: appointment?.providerProfileImage,
        role: appointment?.providerRole,
        id: appointment?.providerId,
      },
      amount: {
        amountPaid: appointment?.amountPaid,
        increased: false,
        changed: true,
      },
      payeeType: payeeTypes[appointment?.payeeType],
      insuranceProvider: appointment?.insuranceProvider || 'n/a',
      actions: { ...appointment },
    }));

    setAppointmentList(formatAppointments);
  };

  const getMultiSelectFilterForRole = multiselectFilters => {
    const roles = [];

    if (multiselectFilters?.Role) {
      multiselectFilters.Role.forEach(filter => {
        switch (filter) {
          case 'Nurse Practitioner':
            roles.push('Nurse Practitioner');
            break;
          case 'Therapist':
            roles.push('Therapist');
            break;
          case 'Counselor':
            roles.push('Counselor');
            break;
          case 'Coach':
            roles.push('Coach');
            break;
          case 'Care Manager':
            roles.push('Care Manager');
            break;
          case 'Physician':
            roles.push('Physician');
            break;

          default:
            break;
        }
      });
    }
    return roles;
  };
  const getMultiSelectFilterForPaymentMethod = multiselectFilters => {
    const paymentMethods = [];

    if (multiselectFilters?.PaymentMethod) {
      multiselectFilters.PaymentMethod.forEach(filter => {
        switch (filter) {
          case 'Cash Payment':
            paymentMethods.push('Cash Payment');
            break;
          case 'Credit Card':
            paymentMethods.push('card_payment');
            break;
          case 'Wallet':
            paymentMethods.push('wallet');
            break;
          default:
            break;
        }
      });
    }

    return paymentMethods;
  };

  const getMultiSelectFilterForState = multiselectFilters => {
    const states = [];
    if (multiselectFilters?.State) {
      multiselectFilters.State.forEach(filter => {
        switch (filter) {
          case 'Texas':
            states.push('Texas');
            break;
          case 'Florida':
            states.push('Florida');
            break;
          case 'Connecticut':
            states.push('Connecticut');
            break;
          case 'Virginia':
            states.push('Virginia');
            break;
          case 'New Hampshire':
            states.push('New Hampshire');
            break;
          default:
            break;
        }
      });
    }
    return states;
  };

  const getParticipants = multiselectFilters => {
    if (multiselectFilters?.[DROPDOWN_FILTER_COLLAPSABLE_TYPE.PROVIDERS]?.length > 0) {
      return multiselectFilters[DROPDOWN_FILTER_COLLAPSABLE_TYPE.PROVIDERS]?.map(provider => {
        return provider.id;
      });
    }
    return [];
  };

  const downloadData = async payload => {
    getAppointmentReport(payload)
      .then(res => {
        if (res.status === 200) {
          const blob = new Blob([res.data], {
            type: 'application/vnd.ms-excel',
          });

          saveAs(blob, 'Cash Payments list.xlsx');
        }
      })
      .catch(err => {
        console.warn(err);
        dispatch(
          showSnackbar({
            snackType: 'error',
            snackMessage: err.data?.errors?.[0]?.endUserMessage || 'Failed to Export data.',
          }),
        );
      });
  };
  const fetchPaymentsCall = (isDownload = false) => {
    const currentFilters = tableParams?.search?.multiSelectFilter;
    const paymentMethod = getMultiSelectFilterForPaymentMethod(currentFilters);
    const states = getMultiSelectFilterForState(currentFilters);
    const roles = getMultiSelectFilterForRole(currentFilters);
    const participants = getParticipants(currentFilters);

    const payload = tableParams?.search?.dateFilter?.startDate
      ? {
          download: isDownload,
          // sortBy: params.sorter.column,
          nameFilter: tableParams?.search?.searchKey,
          orderBy: tableParams?.sorter?.direction || 'desc',
          pageNumber: tableParams?.pagination?.currentPage - 1,
          pageSize: isDownload ? totalRecords || 10000 : tableParams?.pagination?.rowsPerPage,
          payee: paymentMethod,
          stateFilter: states,
          roles,
          providerIds: participants,
          startDate: tableParams?.search?.dateFilter?.startDate
            ? dayjs(tableParams?.search?.dateFilter?.startDate)?.toISOString()
            : dayjs()?.subtract(6, 'months')?.toISOString(),
          endDate: tableParams?.search?.dateFilter?.endDate
            ? dayjs(tableParams?.search?.dateFilter?.endDate)?.toISOString()
            : dayjs()?.toISOString(),
        }
      : {
          download: isDownload,
          // sortBy: params.sorter.column,
          nameFilter: tableParams?.search?.searchKey,
          orderBy: tableParams?.sorter?.direction || 'desc',
          pageNumber: tableParams?.pagination?.currentPage - 1,
          pageSize: isDownload ? totalRecords || 2000 : tableParams?.pagination?.rowsPerPage,
          payee: paymentMethod,
          stateFilter: states,
          providerIds: participants,
          roles,
        };

    if (isDownload) {
      void downloadData(payload);
    } else {
      dispatch(scheduleActionCreators.fetchAppointmentReport(payload));
    }
  };
  useEffect(() => {
    fetchPaymentsCall();
  }, [selectedFilter, tableParams]);

  useEffect(() => {
    populateData();
  }, [appointmentRecord]);

  const renderStatusTotalNew = (status, value, isAmount, badgeValue) => (
    <Box
      padding={2}
      sx={{
        background: colors.white,
        flex: '1 1 0',
        boxShadow:
          '0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
        borderRadius: '8px',
      }}
    >
      <Box display="flex" justifyContent="space-between">
        <Text className={classes.label}>{status.toUpperCase()}</Text>
        <Badge
          className={clsx(
            classes.statusLabel,
            { [classes.unpaidStatus]: badgeValue !== 'Wallet' },
            { [classes.submittedStatus]: badgeValue === 'Cash' },
          )}
          variant={badgeType.OUTLINED}
          style={
            badgeValue === 'Cash'
              ? badgeStyle.PRIMARY
              : badgeValue === 'Wallet'
              ? badgeStyle.RESOLVED
              : badgeStyle.HIGH
          }
        >
          {badgeValue}
        </Badge>
      </Box>
      <Text className={classes.statusFee}>
        {isAmount && '$'}
        {Number.isInteger(value) ? value : value.toFixed(2)}
      </Text>
    </Box>
  );
  const renderColumns = useCallback(() => {
    return tableColumns.map(column => {
      if (column.id === 'appointmentTime') {
        return {
          ...column,
          renderCell: appointmentTime => (
            <div className={classes.timeWrap}>
              <div className={classes.date}>{dayjs(appointmentTime).format('MM/DD/YYYY')}</div>
              <div className={classes.time}>{dayjs(appointmentTime).format('h:mm a')}</div>
            </div>
          ),
        };
      }
      if (column.id === 'member') {
        return {
          ...column,
          renderCell: ({ fullName, photo, id, uuid }) => (
            <ProfileInfo type="member" photo={photo} nickName={fullName} fullName={uuid} memberId={id} />
          ),
        };
      }
      if (column.id === 'provider') {
        return {
          ...column,
          renderCell: ({ photo, fullName, role, id }) => {
            return (
              <ProfileInfo type="provider" photo={photo} role={role} fullName={fullName} memberId={id} />
            );
          },
        };
      }
      if (column.id === 'amount') {
        return {
          ...column,
          renderCell: ({ amountPaid }) => <span>${amountPaid}</span>,
        };
      }
      if (column.id === 'status') {
        return {
          ...column,
          renderCell: val => {
            return (
              <Badge
                variant={badgeType.FILLED}
                style={val === 'Paid' ? badgeStyle.GREEN : badgeStyle.HIGH}
                className={clsx(classes.status, { [classes.unpaidStatus]: val !== 'Paid' })}
              >
                {val}
              </Badge>
            );
          },
        };
      }
      if (column.id === 'actions') {
        return {
          ...column,
          renderCell: () => {
            return (
              <Menu
                icon="more"
                className={classes.menu}
                itemsWrapperClassName={classes.menuItemsWrapper}
                items={[{ label: 'Menu Item' }, { label: 'Menu Item' }, { label: 'Menu Item' }]}
              />
            );
          },
        };
      }
      return column;
    });
  }, []);

  const onCloseDrawer = () => {
    setSelectedItem(null);
  };
  const displayData = appointmentList || [];

  useEffect(() => {
    setFilterOptionsMock(paymentMultiSelectFilterOptions(providersList));
    setResetMultiSelectFilter(true);
  }, [providersList]);

  useEffect(() => {
    setResetMultiSelectFilter(false);
  }, [tableParams]);

  useEffect(() => {
    setFilterChangeLoading(true);
    setTimeout(() => {
      setFilterChangeLoading(false);
    }, 10);
  }, [filterOptionsMock]);

  return (
    <BaseLayout>
      <PaymentDetailDrawer open={!!selectedItem} onClose={onCloseDrawer} appointment={selectedItem} />
      <Box className={classes.root}>
        <Stack direction="row" justifyContent="space-between" spacing={2} sx={{ marginBottom: 5 }}>
          <Stack direction="row" alignItems="center" gap={2} sx={{ height: 48 }}>
            <Heading className={classes.heading} level={headingLevel.XL} weight={fontWeight.BOLD}>
              Cash Payments
            </Heading>
            <Badge className={classes.totalBadge} variant={badgeType.OUTLINED} style={badgeStyle.UNRELATED}>
              {totalRecords || 0} total
            </Badge>
          </Stack>
        </Stack>
        <Box display="flex" flexDirection="column" mb={4} gap={0}>
          <Box display="flex" mb={2} gap={2}>
            {renderStatusTotalNew('Total Cash Payments', totalCash || 0, true, 'Cash')}
            {renderStatusTotalNew('Wallet Payments', totalWallet || 0, true, 'Wallet')}
            {renderStatusTotalNew('Credit Card Payments', totalCard || 0, true, 'Card')}
          </Box>
          {/* <Box display="flex" mb={2} gap={2}>
            {renderStatusTotalNew('Insurance Appointments', insuranceAppointments, false, 'insurance')}
            {renderStatusTotalNew(
              'Subscription Appointments',
              subscriptionAppointments,
              false,
              'subscription',
            )}
          </Box> */}
        </Box>
        <div className={classes.appointmentList}>
          {filterChangeLoading ? (
            <div className={classes.loader}>
              <Icons className="rotate linear infinite" glyph="in-progress" color={colors.primary} />
              Loading...
            </div>
          ) : (
            <Table
              searchProps={{
                placeholder: 'Search member and provider',
                filterProps: {
                  variant: Filter.tableFilterType.MULTIPLE,
                  options: payeeFilterOptions,
                  multiSelectOptions: filterOptionsMock,
                  onChange: onChangeFilter,
                  dateFilter: {
                    startDate: tableParams?.search?.dateFilter?.startDate,
                    endDate: tableParams?.search?.dateFilter?.endDate,
                  },
                },
                resetMultiSelectFilter,
                exportProps: {
                  btnTitle: 'Export',
                  isLoadingExport: false,
                },
              }}
              onClickExport={() => fetchPaymentsCall(true)}
              gridProps={{
                columns: renderColumns(),
                data: displayData,
                isLoading,
                onRowClick,
              }}
              paginationProps={{
                currentRows: displayData?.length || 0,
                totalCount: totalRecords || 0,
                showRowsPerPage: true,
              }}
              value={tableParams}
              onChange={setTableParams}
            />
          )}
        </div>
      </Box>
    </BaseLayout>
  );
};

export { PaymentList };
