import { AppointmentStatus } from '../../../../packages/constants/CommonConstants';
import { FILTER_COLLAPSABLE_SECTION_TYPE } from '../../../../packages/ui/organisms/table/filter';

export const AppointmentTitles = {
  pending: 'Pending appointments',
  past: 'Past appointments',
  current: 'Current appointments',
};

export const appointmentColumns = [
  { id: 'provider', label: 'Provider', sortable: true },
  { id: 'member', label: 'Member', sortable: true },
  { id: 'serviceName', label: 'Session type', sortable: true },
  { id: 'appointmentStatus', label: 'Status', sortable: true },
  { id: 'patientState', label: 'State', sortable: true },
  { id: 'appointmentTime', label: 'Date & time', sortable: true },
  // { id: 'actions', label: '', sortable: false },
];

export const appointmentFilterStatusOptions = {
  current: [
    { label: 'Texas', value: 'Texas', filterBy: 'state' },
    { label: 'Florida', value: 'Florida', filterBy: 'state' },
    { label: 'Virginia', value: 'Virginia', filterBy: 'state' },
    { label: 'Connecticut', value: 'Connecticut', filterBy: 'state' },
    { label: 'Cash Pay', value: 'APPOINTMENT_CHARGES', filterBy: 'paymentMethod' },
    { label: 'Insurance', value: 'INSURANCE', filterBy: 'paymentMethod' },
    { label: 'Subscription', value: 'Subscription', filterBy: 'paymentMethod' },
  ],
  past: [
    { label: 'No Show', value: AppointmentStatus.NO_SHOW, filterBy: 'status' },
    { label: 'Cancelled', value: AppointmentStatus.CANCELLED, filterBy: 'status' },
    { label: 'Completed', value: AppointmentStatus.FULFILLED, filterBy: 'status' },
    { label: 'Texas', value: 'Texas', filterBy: 'state' },
    { label: 'Florida', value: 'Florida', filterBy: 'state' },
    { label: 'Virginia', value: 'Virginia', filterBy: 'state' },
    { label: 'Connecticut', value: 'Connecticut', filterBy: 'state' },
    { label: 'Cash Pay', value: 'APPOINTMENT_CHARGES', filterBy: 'paymentMethod' },
    { label: 'Insurance', value: 'INSURANCE', filterBy: 'paymentMethod' },
    { label: 'Subscription', value: 'Subscription', filterBy: 'paymentMethod' },
  ],
  pending: [
    { label: 'Waiting for Member', value: AppointmentStatus.PROPOSED, filterBy: 'status' },
    { label: 'Waiting for Provider', value: AppointmentStatus.NEEDS_ACTION, filterBy: 'status' },
    { label: 'Texas', value: 'Texas', filterBy: 'state' },
    { label: 'Florida', value: 'Florida', filterBy: 'state' },
    { label: 'Virginia', value: 'Virginia', filterBy: 'state' },
    { label: 'Connecticut', value: 'Connecticut', filterBy: 'state' },
    { label: 'Cash Pay', value: 'APPOINTMENT_CHARGES', filterBy: 'paymentMethod' },
    { label: 'Insurance', value: 'INSURANCE', filterBy: 'paymentMethod' },
    { label: 'Subscription', value: 'Subscription', filterBy: 'paymentMethod' },
  ],
};

export const appointmentSorterMap = {
  provider: 'practitionerName',
  member: 'participantName',
  serviceName: 'sessionType',
  appointmentStatus: 'status',
  appointmentTime: 'startTime',
  patientState: 'patientState',
};

export const pendingAppointmentsFilterOption = [
  {
    sectionTitle: 'State',
    sectionType: FILTER_COLLAPSABLE_SECTION_TYPE.DEFAULT,
    sectionOptions: [
      { label: 'Texas', value: 'Texas' },
      { label: 'Florida', value: 'Florida' },
      { label: 'Connecticut', value: 'Connecticut' },
      { label: 'Virginia', value: 'Virginia' },
      { label: 'New Hampshire', value: 'New Hampshire' },
    ],
  },
];

export const currentAppointmentsFilterOption = [
  {
    sectionTitle: 'Status',
    sectionType: FILTER_COLLAPSABLE_SECTION_TYPE.RADIO,
    sectionOptions: [
      { label: 'Today', value: 'Today' },
      { label: 'Scheduled', value: 'Scheduled' },
    ],
  },
  ...pendingAppointmentsFilterOption,
];

export const pastAppointmentsFilterOption = [
  {
    sectionTitle: 'Status',
    sectionType: FILTER_COLLAPSABLE_SECTION_TYPE.DEFAULT,
    sectionOptions: [
      { label: 'Cancelled', value: AppointmentStatus.CANCELLED },
      { label: 'Completed', value: AppointmentStatus.FULFILLED },
      { label: 'No Show', value: 'NO SHOW' },
    ],
  },
  ...pendingAppointmentsFilterOption,
];
