import { FILTER_COLLAPSABLE_SECTION_TYPE } from '../../../packages/ui/organisms/table/filter';
import { getProviderFilterOptions } from '../provider-fee/ProviderFee.constants';

export const tableColumns = [
  { id: 'appointmentStartTime', label: 'Date & time', sortable: false },
  { id: 'renderingProvider', label: 'Provider', sortable: false },
  { id: 'patient', label: 'Member', sortable: false },
  { id: 'candidStatus', label: 'Candid claim status', sortable: false },
  { id: 'status', label: 'Status', sortable: false },
  { id: 'paidAt', label: 'Paid at', sortable: false },
  { id: 'claim', label: 'Claim', sortable: false },
  { id: 'payer', label: 'Insurance', sortable: false },
  { id: 'stateName', label: 'State', sortable: false },
  { id: 'cptCodes', label: 'CPT Codes', sortable: false },
  { id: 'actions', label: '', sortable: false },
];

export const CLAIM_STATUSES = [
  { label: 'Created', value: 'CREATED' },
  { label: 'Info Requested', value: 'INFO_REQUESTED' },
  { label: 'Rejected', value: 'REJECTED' },
  { label: 'Completed', value: 'COMPLETED' },

  { label: 'Paid', value: 'PAID' },
  { label: 'Denied', value: 'DENIED' },
  { label: 'Partially Paid', value: 'PARTIALLY_PAID' },
  { label: 'Overpaid', value: 'OVERPAID' },
  { label: 'Underpaid', value: 'UNDERPAID' },
  { label: 'Appealed', value: 'APPEALED' },
  { label: 'Submitted', value: 'SUBMITTED' },

  { label: 'Unbilled - Duplicate', value: 'UNBILLED_DUPLICATE' },
  { label: 'Unbilled - Policy Inactive', value: 'UNBILLED_POLICY_INACTIVE' },
  { label: 'Unbilled - Unidentified', value: 'UNBILLED_UNIDENTIFIED' },
  { label: 'Unbilled - DOS Missing', value: 'UNBILLED_DOS_MISSING' },
  { label: 'Unbilled - Address Missing', value: 'UNBILLED_ADDRESS_MISSING' },
  { label: 'Unbilled - DOB Error', value: 'UNBILLED_DOB_ERROR' },
  { label: 'Unbilled - Name Error', value: 'UNBILLED_NAME_ERROR' },
  { label: 'Unbilled - DX Error', value: 'UNBILLED_DX_ERROR' },
  { label: 'Unbilled - No billed amount', value: 'UNBILLED_NO_BILLED_AMOUNT' },
];

export const multiSelectFilterOptionsMock = providers => {
  return [
    {
      sectionTitle: 'Status',
      sectionType: FILTER_COLLAPSABLE_SECTION_TYPE.DEFAULT,
      sectionOptions: [
        { label: 'Created', value: 'Created' },
        { label: 'Paid', value: 'Paid' },
        { label: 'Denied', value: 'Denied' },
        { label: 'Info Requested', value: 'Info Requested' },
        { label: 'Rejected', value: 'Rejected' },
        { label: 'Completed', value: 'Completed' },
        { label: 'Partially Paid', value: 'Partially Paid' },
        { label: 'Overpaid', value: 'Overpaid' },
        { label: 'Underpaid', value: 'Underpaid' },
        { label: 'Appealed', value: 'Appealed' },
        { label: 'Submitted', value: 'Submitted' },
        { label: 'Unbilled', value: 'Unbilled' },
      ],
    },
    {
      sectionTitle: 'State',
      sectionType: FILTER_COLLAPSABLE_SECTION_TYPE.DEFAULT,
      sectionOptions: [
        { label: 'Texas', value: 'Texas' },
        { label: 'Florida', value: 'Florida' },
        { label: 'Connecticut', value: 'Connecticut' },
        { label: 'Virginia', value: 'Virginia' },
        { label: 'New Hampshire', value: 'New Hampshire' },
      ],
    },
    {
      sectionTitle: 'Role',
      sectionType: FILTER_COLLAPSABLE_SECTION_TYPE.DEFAULT,
      sectionOptions: [
        { label: 'Nurse Practitioner', value: 'Nurse Practitioner' },
        { label: 'Therapist', value: 'Therapist' },
        { label: 'Counselor', value: 'Counselor' },
        { label: 'Coach', value: 'Coach' },
        { label: 'Care Manager', value: 'Care Manager' },
        { label: 'Physician', value: 'Physician' },
      ],
    },
    {
      sectionTitle: 'Candid',
      sectionType: FILTER_COLLAPSABLE_SECTION_TYPE.RADIO,
      sectionOptions: [
        { label: 'Candid Success', value: 'success' },
        { label: 'Candid Failure', value: 'failure' },
      ],
    },
    {
      sectionTitle: 'VBC',
      sectionType: FILTER_COLLAPSABLE_SECTION_TYPE.DEFAULT,
      sectionOptions: [{ label: 'VBC flag', value: 'VBC flag' }],
    },
    {
      sectionTitle: 'Date filter preference',
      sectionType: FILTER_COLLAPSABLE_SECTION_TYPE.TOGGLE,
      sectionOptions: [{ label: 'Filter by paid at date', value: false }],
    },
    getProviderFilterOptions(providers),
  ];
};

export const CandidStatus = {
  CANDID_FAILURE: 'CANDID_FAILURE',
  CANDID_SUCCESS: 'CANDID_SUCCESS',
};

export const CANDID_CLAIM_FILTERS = {
  ALL: null,
  PAID: 'PAID',
  SUBMITTED: 'SUBMITTED',
  DENIED: 'DENIED',
  PENDING: 'PENDING',
  NO_CHECK_NUMBER: 'NO_CHECK_NUMBER',
  FUNDS_NOT_RECEIVED: 'FUNDS_NOT_RECEIVED',
};
