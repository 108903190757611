import dayjs from 'dayjs';
import { CONNECTIONS_TYPES } from '../../../constants/CommonConstants';

import { USER_LOGOUT } from '../auth/actions';
import {
  ADD_MEMBER_INSURANCE,
  ADD_MEMBER_INSURANCE_FAILED,
  ADD_MEMBER_INSURANCE_SUCCESSFUL,
  ALL_CONNECTIONS_FETCHED,
  CARE_NAVIGATOR_CONNECTIONS_FETCH_FAILED,
  CARE_NAVIGATOR_CONNECTIONS_FETCHED,
  CARETEAM_CONNECTIONS_FETCH_FAILED,
  CARETEAM_CONNECTIONS_FETCHED,
  CHATS_CLEAR,
  CLEAR_CARE_TEAM_LIST,
  CONNECTIONS_FETCHED,
  FETCH_ADMIN_PROFILE,
  FETCH_ADMIN_VALIDATION_TODO_SUCCESSFUL,
  FETCH_ALL_GOOGLE_CALENDAR_EVENTS,
  FETCH_ALL_GOOGLE_CALENDAR_EVENTS_FAILED,
  FETCH_ALL_GOOGLE_CALENDAR_EVENTS_SUCCESSFUL,
  FETCH_ALL_PROVIDERS_SUCCESSFUL,
  FETCH_DEMOGRAPHIC_DETAILS,
  FETCH_DEMOGRAPHIC_DETAILS_FAILED,
  FETCH_DEMOGRAPHIC_DETAILS_SUCCESSFUL,
  FETCH_DEMOGRAPHIC_METADATA,
  FETCH_DEMOGRAPHIC_METADATA_FAILED,
  FETCH_DEMOGRAPHIC_METADATA_SUCCESSFUL,
  FETCH_DOMAIN_TYPES,
  FETCH_DOMAIN_TYPES_FAILED,
  FETCH_DOMAIN_TYPES_SUCCESSFUL,
  FETCH_EVALUATION_TODO,
  FETCH_EVALUATION_TODO_SUCCESSFUL,
  FETCH_GROUPS,
  FETCH_GROUPS_FAILED,
  FETCH_GROUPS_SUCCESSFUL,
  FETCH_LOOKUP_DATA,
  FETCH_LOOKUP_DATA_SUCCESSFUL,
  FETCH_MEMBER_INSURANCE,
  FETCH_MEMBER_INSURANCE_FAILED,
  FETCH_MEMBER_INSURANCE_SUCCESSFUL,
  FETCH_MORE_CONNECTIONS,
  FETCH_NOTES_TODO,
  FETCH_NOTES_TODO_SUCCESSFUL,
  FETCH_PATIENTS,
  FETCH_PATIENTS_FAILED,
  FETCH_PATIENTS_SUCCESSFUL,
  FETCH_PROFILE_FAILED,
  FETCH_PROFILE_SUCCESSFUL,
  FETCH_PROVIDER_DETAILS_FAILED,
  FETCH_PROVIDER_DETAILS_SUCCESSFUL,
  FETCH_PROVIDER_PROFILE,
  FETCH_PROVIDERS_SUCCESSFUL,
  FETCH_REFERRAL_PARTNERS,
  FETCH_REFERRAL_PARTNERS_FAILED,
  FETCH_REFERRAL_PARTNERS_SUCCESSFUL,
  FETCH_SYNCED_GOOGLE_CALENDAR,
  FETCH_SYNCED_GOOGLE_CALENDAR_FAILED,
  FETCH_SYNCED_GOOGLE_CALENDAR_SUCCESSFUL,
  FETCH_TODO,
  FETCH_TODO_STATS_FAILED,
  FETCH_TODO_STATS_SUCCESSFUL,
  FETCH_TODO_SUCCESSFUL,
  FETCH_USER_GROUPS,
  FETCH_USER_GROUPS_FAILED,
  FETCH_USER_GROUPS_SUCCESSFUL,
  FETCH_VALIDATION_TODO,
  FETCH_VALIDATION_TODO_SUCCESSFUL,
  GET_CHAT_CONNECTION_BY_ID_SUCCESSFUL,
  GET_CONNECTIONS,
  GET_CONNECTIONS_BY_STATUS,
  GET_CONNECTIONS_BY_STATUS_FAILED,
  GET_CONNECTIONS_BY_STATUS_SUCCESSFUL,
  GET_CONNECTIONS_FAILED,
  GET_WALLET_BALANCE_SUCCESSFUL,
  INVITE_MEMBER_FAILED,
  INVITE_MEMBER_SUCCESSFUL,
  MEMBER_CONNECTIONS_FETCH_FAILED,
  MEMBER_CONNECTIONS_FETCHED,
  PROVIDER_CONNECTIONS_FETCH_FAILED,
  PROVIDER_CONNECTIONS_FETCHED,
  PUT_CARE_TEAM_LIST,
  PUT_CONTACT_DETAILS_SUCCESS,
  PUT_DEMOGRAPHIC_DETAILS,
  PUT_DEMOGRAPHIC_DETAILS2,
  PUT_DEMOGRAPHIC_DETAILS_FAILED,
  PUT_DEMOGRAPHIC_DETAILS_SUCCESSFUL,
  PUT_EMERGENCY_DETAILS_SUCCESS,
  PUT_MEMBER_INSURANCE,
  PUT_MEMBER_INSURANCE_FAILED,
  PUT_MEMBER_INSURANCE_SUCCESSFUL,
  SEARCH_CHATS,
  SEARCH_CHATS_CLEAR,
  SEARCH_CHATS_FAILED,
  SEARCH_CHATS_SUCCESS,
  SELECTED_APPOINTMENT_NOTE,
  SELECTED_PATIENT,
  SET_WALLET_BALANCE,
  TODO_COMPLETED,
  TODO_COMPLETED_SUCCESSFUL,
  UPDATE_ACTIVE_CONNECTION_LAST_MESSAGE,
  UPDATE_CHAT_CONNECTIONS,
  UPDATE_PROVIDER_GENERAL,
  UPDATE_WALLET_BALANCE,
  UPDATE_WALLET_BALANCE_FAILED,
  UPDATE_WALLET_BALANCE_SUCCESSFUL,
} from './actions';
import {
  CHAT_ADD_MESSAGE,
  CHAT_MARK_AS_READ,
  CHAT_MEDIA_SENT,
  CHAT_MESSAGE_RECEIVED,
  FETCH_CHATS,
} from '../conversation/actions';
import { arrayUniqueByKey } from '../../../utils/CommonUtils';

const getOptimisedConnections = connections => {
  return connections.map(item => {
    switch (item.type) {
      case CONNECTIONS_TYPES.PRACTITIONER:
        return {
          connectionId: item.connectionId,
          name: item.name,
          email: item.email,
          phone: item.phone,
          profilePicture: item.profilePicture,
          lastModified: item.lastModified,
          type: item.type,
          lastMessage: item.lastMessage,
          lastMessageTimestamp: item.lastMessageTimestamp,
          lastMessageTimestampUnix: item.lastMessageTimestampUnix,
          lastMessageUnread: item.lastMessageUnread,
          channelUrl: item.channelUrl,
          designation: item.designation,
          active: item.active,
          inActiveChat: item.inActiveChat,
          firstName: item.firstName,
          lastName: item.lastName,
          dob: item.dob,
          nextAppointment: item.nextAppointment,
          nickname: item.nickname,
          userId: item.userId,
          colorCode: item.colorCode,
        };
      case CONNECTIONS_TYPES.CHAT_GROUP:
        return {};
      default:
        return {
          connectionId: item.connectionId,
          name: item.name,
          email: item.email,
          phone: item.phone,
          profilePicture: item.profilePicture,
          lastModified: item.lastModified,
          type: item.type,
          lastMessage: item.lastMessage,
          lastMessageTimestamp: item.lastMessageTimestamp,
          lastMessageTimestampUnix: item.lastMessageTimestampUnix,
          lastMessageUnread: item.lastMessageUnread,
          channelUrl: item.channelUrl,
          designation: item.designation,
          active: item.active,
          inActiveChat: item.inActiveChat,
          firstName: item.firstName,
          lastName: item.lastName,
          dob: item.dob,
          uuid: item.uuid,
          nextAppointment: item.nextAppointment,
          colorCode: item.colorCode,
        };
    }
  });
};
export const DEFAULT = {
  isLoading: false,
  groupsLoading: false,
  careTeamList2: [],
  todoCount: 0,
  evaluationTodoCount: 0,
  notesTodoCount: 0,
  validationTodoCount: 0,
  validationTodos: [],
  adminValidationTodos: [],
  todosStats: {
    activeTodoStats: {
      chatCount: 0,
      evaluationsCount: 0,
      notesCount: 0,
      alfieValidationCount: 0,
      withoutDueDateCount: 0,
      withDueDateCount: 0,
      pastDueDateCount: 0,
      total: 0,
    },
    completedTodoStats: {
      chatCount: 0,
      evaluationsCount: 0,
      notesCount: 0,
      alfieValidationCount: 0,
      withoutDueDateCount: 0,
      withDueDateCount: 0,
      pastDueDateCount: 0,
      total: 0,
    },
  },
  todos: [],
  evaluationTodos: [],
  notesTodos: [],
  errorMsg: '',
  payload: {
    currentPage: 1,
    totalPages: 0,
    totalRecords: 0,
    patientsList: [],
  },
  selectedPatient: {},
  providerDetails: {},
  profile: {},
  demographicDetails: {},
  userGroups: [],
  walletBalance: 0,
  selectedAppointment: {},
  providers: [],
  providers2: [],
  activeConnections: [],
  chats: {
    members: {
      isLoading: false,
      connections: [],
      hasMore: false,
      currentPage: 0,
      loadingMore: false,
    },
    providers: {
      isLoading: false,
      connections: [],
      hasMore: false,
      currentPage: 0,
      loadingMore: false,
    },
    careTeam: {
      isLoading: false,
      connections: [],
      hasMore: false,
      currentPage: 0,
      loadingMore: false,
    },
    careNavigator: {
      isLoading: false,
      connections: [],
      hasMore: false,
      currentPage: 0,
      loadingMore: false,
    },
    all: {
      isLoading: false,
      connections: [],
      hasMore: false,
      currentPage: 0,
      loadingMore: false,
    },
    searchResults: {
      isLoading: false,
      connections: [],
    },
  },
  pastConnections: [],
  connectionsFetchedFor: '',
  domainTypes: [],
  lookupData: {},
  connections: {
    isLoading: false,
    errorMsg: '',
    connections: [],
    currentPage: 1,
    totalPages: 0,
    totalRecords: 0,
  },
  group: {
    isLoading: false,
    errorMsg: '',
    groups: [],
  },
  demographicMetadata: {
    gender: [],
    genderIdentities: [],
    genderPronouns: [],
    levelOfEngagements: [],
    primaryInterests: [],
    referralSources: [],
    isLoading: false,
    errorMsg: '',
  },
  memberInsurance: {
    insurance: null,
    isLoading: false,
    isSaving: false,
    errorMsg: '',
  },
  syncedCalendars: {
    calendars: [],
    isLoading: false,
    errorMsg: '',
  },
  googleCalendarEvents: {
    events: [],
    isLoading: false,
    errorMsg: '',
  },
};

export const sortConnections = (roaster = []) => {
  return roaster.sort((a, b) => {
    if (!a.lastMessage) {
      return 1;
    }
    return dayjs(b.lastModified).unix() - dayjs(a.lastModified).unix();
  });
};

export default function patientsReducer(state = DEFAULT, action) {
  const { type, payload } = action;

  switch (type) {
    case UPDATE_ACTIVE_CONNECTION_LAST_MESSAGE: {
      return {
        ...state,
        activeConnections: state.activeConnections.map(item =>
          item.channelUrl === payload.channelUrl
            ? { ...item, lastMessage: payload.message.message.text }
            : item,
        ),
      };
    }
    case SEARCH_CHATS: {
      return {
        ...state,
        chats: {
          ...state.chats,
          searchResults: {
            ...state.chats.searchResults,
            isLoading: true,
            connections: [],
          },
        },
      };
    }
    case SEARCH_CHATS_CLEAR: {
      return {
        ...state,
        chats: {
          ...state.chats,
          searchResults: {
            ...state.chats.searchResults,
            isLoading: false,
            connections: [],
          },
        },
      };
    }
    case SEARCH_CHATS_SUCCESS: {
      return {
        ...state,
        chats: {
          ...state.chats,
          searchResults: {
            ...state.chats.searchResults,
            isLoading: false,
            connections: payload.connections,
          },
        },
      };
    }
    case SEARCH_CHATS_FAILED: {
      return {
        ...state,
        chats: {
          ...state.chats,
          searchResults: {
            ...state.chats.searchResults,
            isLoading: false,
            connections: [],
          },
        },
      };
    }
    case FETCH_PROVIDER_PROFILE:
    case FETCH_ADMIN_PROFILE: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case FETCH_PROFILE_FAILED: {
      return {
        ...state,
        isLoading: false,
        profile: {},
      };
    }
    case FETCH_PROFILE_SUCCESSFUL: {
      return {
        ...state,
        isLoading: false,
        profile: payload,
      };
    }
    case FETCH_PROVIDERS_SUCCESSFUL: {
      return {
        ...state,
        providers: payload.providers,
        totalProviders: payload.totalProviders,
      };
    }
    case FETCH_ALL_PROVIDERS_SUCCESSFUL: {
      return {
        ...state,
        providers2: payload.providers,
      };
    }
    case FETCH_TODO_STATS_SUCCESSFUL: {
      return {
        ...state,
        todosStats: payload,
      };
    }
    case FETCH_TODO_STATS_FAILED: {
      return {
        ...state,
        todosStats: DEFAULT.todosStats,
      };
    }
    case FETCH_TODO_SUCCESSFUL: {
      return {
        ...state,
        todoCount: payload.total,
        todos: payload.results,
      };
    }
    case FETCH_TODO: {
      if (payload.refresh) {
        return {
          ...state,
          todoCount: 0,
          todos: [],
        };
      }
      return state;
    }
    case FETCH_EVALUATION_TODO_SUCCESSFUL: {
      return {
        ...state,
        evaluationTodoCount: payload.total,
        evaluationTodos: payload.results,
      };
    }
    case FETCH_EVALUATION_TODO: {
      if (payload.refresh) {
        return {
          ...state,
          evaluationTodoCount: 0,
          evaluationTodos: [],
        };
      }
      return state;
    }
    case FETCH_NOTES_TODO: {
      if (payload.refresh) {
        return {
          ...state,
          notesTodoCount: 0,
          notesTodos: [],
        };
      }
      return state;
    }
    case FETCH_NOTES_TODO_SUCCESSFUL: {
      return {
        ...state,
        notesTodoCount: payload.total,
        notesTodos: payload.results,
      };
    }
    case FETCH_ADMIN_VALIDATION_TODO_SUCCESSFUL: {
      return {
        ...state,
        adminValidationTodos: payload.results,
      };
    }
    case FETCH_VALIDATION_TODO: {
      if (payload.refresh) {
        return {
          ...state,
          validationTodoCount: 0,
          validationTodos: [],
        };
      }
      return state;
    }
    case FETCH_VALIDATION_TODO_SUCCESSFUL: {
      return {
        ...state,
        validationTodoCount: payload.total,
        validationTodos: payload.results,
      };
    }
    case TODO_COMPLETED: {
      return {
        ...state,
        todos: state.todos.map(item => {
          if (item.id === payload.data.id) {
            item.status = payload.data.status;
          }
          return item;
        }),
        notesTodos: state.notesTodos.map(item => {
          if (item.id === payload.data.id) {
            item.status = payload.data.status;
          }
          return item;
        }),
      };
    }
    case TODO_COMPLETED_SUCCESSFUL: {
      return {
        ...state,
        todos: state.todos.map(item => {
          if (item.id === payload.data.id) {
            item.status = payload.data.status;
          }
          return item;
        }),
        notesTodos: state.notesTodos.map(item => {
          if (item.id === payload.data.id) {
            item.status = payload.data.status;
          }
          return item;
        }),
      };
    }
    case FETCH_PATIENTS: {
      return {
        ...state,
        isLoading: true,
        errorMsg: '',
      };
    }

    case FETCH_PATIENTS_FAILED: {
      return {
        ...state,
        isLoading: false,
        errorMsg: payload?.message,
        payload: {
          currentPage: 1,
          totalPages: 0,
          totalRecords: 0,
          patientsList: [],
        },
        selectedPatient: {},
      };
    }

    case FETCH_PATIENTS_SUCCESSFUL: {
      return {
        ...state,
        isLoading: false,
        errorMsg: '',
        payload: {
          currentPage: payload.currentPage,
          totalPages: payload.totalPages,
          totalRecords: payload.totalRecords,
          patientsList: payload.patientsList,
        },
      };
    }
    case FETCH_DEMOGRAPHIC_DETAILS: {
      return {
        ...state,
        isLoading: true,
        errorMsg: '',
        demographicDetails: {},
      };
    }
    case FETCH_DEMOGRAPHIC_DETAILS_FAILED: {
      return {
        ...state,
        isLoading: false,
        errorMsg: payload?.message,
        demographicDetails: {},
      };
    }
    case FETCH_DEMOGRAPHIC_DETAILS_SUCCESSFUL: {
      return {
        ...state,
        isLoading: false,
        errorMsg: '',
        demographicDetails: payload,
      };
    }
    case FETCH_DOMAIN_TYPES: {
      return {
        ...state,
        isLoading: true,
        domainTypes: [],
      };
    }
    case FETCH_DOMAIN_TYPES_SUCCESSFUL: {
      return {
        ...state,
        isLoading: false,
        domainTypes: payload,
      };
    }
    case FETCH_DOMAIN_TYPES_FAILED: {
      return {
        ...state,
        isLoading: false,
        domainTypes: [],
      };
    }
    case FETCH_LOOKUP_DATA: {
      return {
        ...state,
        lookupData: {},
      };
    }
    case FETCH_LOOKUP_DATA_SUCCESSFUL: {
      return {
        ...state,
        lookupData: payload,
      };
    }
    case PUT_DEMOGRAPHIC_DETAILS: {
      return {
        ...state,
        isLoading: true,
        errorMsg: '',
      };
    }
    case PUT_DEMOGRAPHIC_DETAILS_FAILED: {
      return {
        ...state,
        isLoading: false,
        errorMsg: payload?.message,
      };
    }
    case PUT_DEMOGRAPHIC_DETAILS_SUCCESSFUL: {
      return {
        ...state,
        isLoading: false,
        errorMsg: '',
        demographicDetails: payload,
      };
    }
    case PUT_CONTACT_DETAILS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        errorMsg: '',
        demographicDetails: {
          ...state.demographicDetails,
          email: payload.contactInfo.email,
          address1: payload.contactInfo.address1,
          address2: payload.contactInfo.address2,
          city: payload.contactInfo.city,
          mobilePhone: payload.contactInfo.phone,
          state: payload.contactInfo.state,
          zip: payload.contactInfo.zip,
        },
      };
    }
    case PUT_EMERGENCY_DETAILS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        errorMsg: '',
        demographicDetails: {
          ...state.demographicDetails,
          emergencyContact: payload.emergencyContact || '',
          emergencyPhone: payload.emergencyPhone || '',
        },
      };
    }

    case UPDATE_WALLET_BALANCE: {
      return {
        ...state,
        isLoading: true,
        errorMsg: '',
      };
    }
    case UPDATE_WALLET_BALANCE_FAILED: {
      return {
        ...state,
        isLoading: false,
        errorMsg: payload?.message,
      };
    }
    case UPDATE_WALLET_BALANCE_SUCCESSFUL: {
      return {
        ...state,
        isLoading: false,
        errorMsg: '',
        walletBalance: payload.updatedBalance,
      };
    }
    case GET_WALLET_BALANCE_SUCCESSFUL: {
      return {
        ...state,
        isLoading: false,
        errorMsg: '',
        walletBalance: payload.balance,
      };
    }
    case UPDATE_PROVIDER_GENERAL: {
      return {
        ...state,
        isLoading: false,
        errorMsg: '',
        providerDetails: {
          ...state.providerDetails,
          ...payload.data,
        },
      };
    }

    case SELECTED_PATIENT: {
      return {
        ...state,
        selectedPatient: payload,
      };
    }
    case FETCH_PROVIDER_DETAILS_SUCCESSFUL: {
      return {
        ...state,
        providerDetails: payload,
      };
    }
    case FETCH_PROVIDER_DETAILS_FAILED: {
      return {
        ...state,
        isLoading: false,
        errorMsg: payload?.message,
        providerDetails: {},
      };
    }
    case SET_WALLET_BALANCE: {
      return {
        ...state,
        walletBalance: payload,
      };
    }

    case FETCH_USER_GROUPS: {
      return {
        ...state,
        errorMsg: null,
        groupsLoading: true,
      };
    }
    case FETCH_USER_GROUPS_FAILED: {
      return {
        ...state,
        groupsLoading: false,
        errorMsg: payload.message,
        userGroups: [],
      };
    }
    case FETCH_USER_GROUPS_SUCCESSFUL: {
      return {
        ...state,
        groupsLoading: false,
        userGroups: payload,
      };
    }

    case GET_CONNECTIONS: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case UPDATE_CHAT_CONNECTIONS: {
      const { type: connectionType, updatedConnection } = payload;
      const chatType =
        connectionType === 'PATIENT'
          ? 'members'
          : connectionType === 'PRACTITIONER' || connectionType === 'MATCH_MAKER'
          ? 'providers'
          : connectionType === 'CARE_TEAM'
          ? 'care_team'
          : 'groups';
      let memberConnections = state.chats.members.connections;
      let providerConnections = state.chats.providers.connections;
      let careTeamConnections = state.chats.careTeam.connections;
      if (chatType === 'members') {
        memberConnections = [...memberConnections, updatedConnection];
      } else if (chatType === 'providers') {
        providerConnections = [...providerConnections, updatedConnection];
      } else if (chatType === 'care_team') {
        careTeamConnections = [...careTeamConnections, updatedConnection];
      }
      return {
        ...state,
        chats: {
          ...state.chats,
          members: {
            ...state.chats.members,
            connections: memberConnections,
          },
          providers: {
            ...state.chats.providers,
            connections: providerConnections,
          },
          careTeam: {
            ...state.chats.careTeam,
            connections: careTeamConnections,
          },
        },
      };
    }
    case FETCH_CHATS: {
      return {
        ...state,
        userGroups: [],
        chats: {
          ...state.chats,
          members: {
            ...state.chats.members,
            connections: [],
            isLoading: true,
          },
          providers: {
            ...state.chats.providers,
            connections: [],
            isLoading: true,
          },
          careTeam: {
            ...state.chats.careTeam,
            connections: [],
            isLoading: true,
          },
          careNavigator: {
            ...state.chats.careNavigator,
            connections: [],
            isLoading: true,
          },
          searchResults: {
            isLoading: false,
            connections: [],
          },
        },
      };
    }
    case GET_CONNECTIONS_FAILED: {
      return {
        ...state,
        isLoading: false,
        errorMsg: payload.errorMsg,
      };
    }
    case MEMBER_CONNECTIONS_FETCH_FAILED: {
      return {
        ...state,
        chats: {
          ...state.chats,
          members: {
            ...state.chats.members,
            isLoading: false,
          },
        },
      };
    }
    case PROVIDER_CONNECTIONS_FETCH_FAILED: {
      return {
        ...state,
        chats: {
          ...state.chats,
          providers: {
            ...state.chats.providers,
            isLoading: false,
          },
        },
      };
    }
    case CARETEAM_CONNECTIONS_FETCH_FAILED: {
      return {
        ...state,
        chats: {
          ...state.chats,
          careTeam: {
            ...state.chats.careTeam,
            isLoading: false,
          },
        },
      };
    }
    case CARE_NAVIGATOR_CONNECTIONS_FETCH_FAILED: {
      return {
        ...state,
        chats: {
          ...state.chats,
          careTeam: {
            ...state.chats.careTeam,
            isLoading: false,
          },
        },
      };
    }
    case MEMBER_CONNECTIONS_FETCHED: {
      return {
        ...state,
        chats: {
          ...state.chats,
          members: {
            ...state.chats.members,
            isLoading: (payload.hasMoreRecords && payload.page < 2) || false,
            connections:
              payload.page === 0
                ? getOptimisedConnections(payload.connections)
                : [...state.chats.members.connections, ...getOptimisedConnections(payload.connections)],
            hasMore: payload.hasMoreRecords,
            currentPage: payload.page,
            loadingMore: false,
          },
        },
      };
    }
    case GET_CHAT_CONNECTION_BY_ID_SUCCESSFUL: {
      return {
        ...state,
        chats: {
          ...state.chats,
          members: {
            ...state.chats.members,
            connections:
              payload.type === 'PATIENT'
                ? arrayUniqueByKey([...state.chats.members.connections, payload], 'connectionId')
                : state.chats.members.connections,
          },
          providers: {
            ...state.chats.providers,
            connections:
              payload.type === 'PRACTITIONER'
                ? arrayUniqueByKey([...state.chats.providers.connections, payload], 'connectionId')
                : state.chats.providers.connections,
          },
          careTeam: {
            ...state.chats.careTeam,
            connections:
              payload.type === 'CARE_TEAM'
                ? arrayUniqueByKey([...state.chats.careTeam.connections, payload], 'connectionId')
                : state.chats.careTeam.connections,
          },
        },
      };
    }
    case CHAT_MESSAGE_RECEIVED: {
      const { channelUrl, message } = payload;
      return {
        ...state,
        chats: {
          ...state.chats,
          members: {
            ...state.chats.members,
            connections: state.chats.members.connections.map(connection => {
              if (connection.channelUrl === channelUrl) {
                return {
                  ...connection,
                  lastMessage: message.message?.text,
                  lastMessageTimestampUnix: message.createdAt,
                };
              }
              return connection;
            }),
          },
          providers: {
            ...state.chats.providers,
            connections: state.chats.providers.connections.map(connection => {
              if (connection.channelUrl === channelUrl) {
                return {
                  ...connection,
                  lastMessage: message.message?.text,
                  lastMessageTimestampUnix: message.createdAt,
                };
              }
              return connection;
            }),
          },
          careTeam: {
            ...state.chats.careTeam,
            connections: state.chats.careTeam.connections.map(connection => {
              if (connection.channelUrl === channelUrl) {
                return {
                  ...connection,
                  lastMessage: message.message?.text,
                  lastMessageTimestampUnix: message.createdAt,
                };
              }
              return connection;
            }),
          },
        },
      };
    }
    case FETCH_MORE_CONNECTIONS: {
      const { type: connectionType } = payload;
      let memberLoadingMore = state.chats.members.loadingMore;
      let providerLoadingMore = state.chats.providers.loadingMore;
      let careTeamLoadingMore = state.chats.careTeam.loadingMore;
      if (connectionType === 'members') {
        memberLoadingMore = true;
      } else if (connectionType === 'providers') {
        providerLoadingMore = true;
      } else if (connectionType === 'care_team') {
        careTeamLoadingMore = true;
      }
      return {
        ...state,
        chats: {
          ...state.chats,
          members: {
            ...state.chats.members,
            loadingMore: memberLoadingMore,
          },
          providers: {
            ...state.chats.providers,
            loadingMore: providerLoadingMore,
          },
          careTeam: {
            ...state.chats.careTeam,
            loadingMore: careTeamLoadingMore,
          },
        },
      };
    }
    case PROVIDER_CONNECTIONS_FETCHED: {
      return {
        ...state,
        chats: {
          ...state.chats,
          providers: {
            ...state.chats.providers,
            isLoading: (payload.hasMoreRecords && payload.page < 2) || false,
            connections:
              payload.page === 0
                ? getOptimisedConnections(payload.connections)
                : [...state.chats.providers.connections, ...getOptimisedConnections(payload.connections)],
            hasMore: payload.hasMoreRecords,
            currentPage: payload.page,
            loadingMore: false,
          },
        },
      };
    }
    case CARETEAM_CONNECTIONS_FETCHED: {
      return {
        ...state,
        chats: {
          ...state.chats,
          careTeam: {
            ...state.chats.careTeam,
            isLoading: (payload.hasMoreRecords && payload.page < 2) || false,
            connections:
              payload.page === 0
                ? getOptimisedConnections(payload.connections)
                : [...state.chats.careTeam.connections, ...getOptimisedConnections(payload.connections)],
            hasMore: payload.hasMoreRecords,
            currentPage: payload.page,
            loadingMore: false,
          },
        },
      };
    }
    case ALL_CONNECTIONS_FETCHED: {
      return {
        ...state,
        chats: {
          ...state.chats,
          all: {
            ...state.chats.all,
            isLoading: (payload.hasMoreRecords && payload.page < 2) || false,
            connections:
              payload.page === 0
                ? getOptimisedConnections(payload.connections)
                : [...state.chats.all.connections, ...getOptimisedConnections(payload.connections)],
            hasMore: payload.hasMoreRecords,
            currentPage: payload.page,
            loadingMore: false,
          },
        },
      };
    }
    case CARE_NAVIGATOR_CONNECTIONS_FETCHED: {
      return {
        ...state,
        chats: {
          ...state.chats,
          careNavigator: {
            ...state.chats.careNavigator,
            isLoading: (payload.hasMoreRecords && payload.page < 2) || false,
            connections:
              payload.page === 0
                ? getOptimisedConnections(payload.connections)
                : [...state.chats.careNavigator.connections, ...getOptimisedConnections(payload.connections)],
            hasMore: payload.hasMoreRecords,
            currentPage: payload.page,
            loadingMore: false,
          },
        },
      };
    }
    case CONNECTIONS_FETCHED: {
      const { activeConnections, pastConnections } = state;
      let newActive = payload.activeConnections;
      let newPast = payload.pastConnections;
      if (state.connectionsFetchedFor !== null) {
        activeConnections.forEach(connection => {
          newActive = payload.activeConnections.map(activeConnection => {
            if (connection.connectionId === activeConnection.connectionId) {
              activeConnection.messages = connection.messages;
            }
            return activeConnection;
          });
        });
        pastConnections.forEach(connection => {
          newPast = payload.pastConnections.map(pastConnection => {
            if (connection.connectionId === pastConnection.connectionId) {
              pastConnection.messages = connection.messages;
            }
            return pastConnection;
          });
        });
      }
      return {
        ...state,
        isLoading: false,
        activeConnections: sortConnections(newActive),
        pastConnections: sortConnections(newPast),
        connectionsFetchedFor: payload.userId,
      };
    }
    case GET_CONNECTIONS_BY_STATUS: {
      return {
        ...state,
        connections: {
          ...state.connections,
          isLoading: true,
        },
      };
    }
    case GET_CONNECTIONS_BY_STATUS_FAILED: {
      return {
        ...state,
        connections: {
          ...state.connections,
          isLoading: false,
          errorMsg: payload.errorMsg,
        },
      };
    }
    case GET_CONNECTIONS_BY_STATUS_SUCCESSFUL: {
      return {
        ...state,
        connections: {
          ...state.connections,
          isLoading: false,
          ...action.payload,
        },
      };
    }
    case CHAT_ADD_MESSAGE: {
      const newList = state.activeConnections.map(item => {
        if (item.channelUrl && item.channelUrl === payload.channelUrl) {
          return {
            ...item,
            lastMessage: payload.message.message.text || '',
            lastMessageTimestampUnix: dayjs().unix(),
            lastModified: payload.message.createdAt,
          };
        }
        return item;
      });

      return {
        ...state,
        activeConnections: sortConnections(newList),
        chats: {
          ...state.chats,
          members: {
            ...state.chats.members,
            connections: state.chats.members.connections.map(connection => {
              if (connection.channelUrl === payload.channelUrl) {
                return {
                  ...connection,
                  lastMessage: payload.message.message.text,
                  lastMessageTimestampUnix: dayjs().valueOf(),
                };
              }
              return connection;
            }),
          },
          providers: {
            ...state.chats.providers,
            connections: state.chats.providers.connections.map(connection => {
              if (connection.channelUrl === payload.channelUrl) {
                return {
                  ...connection,
                  lastMessage: payload.message.message.text,
                  lastMessageTimestampUnix: dayjs().valueOf(),
                };
              }
              return connection;
            }),
          },
          careTeam: {
            ...state.chats.careTeam,
            connections: state.chats.careTeam.connections.map(connection => {
              if (connection.channelUrl === payload.channelUrl) {
                return {
                  ...connection,
                  lastMessage: payload.message.message.text,
                  lastMessageTimestampUnix: dayjs().valueOf(),
                };
              }
              return connection;
            }),
          },
          careNavigator: {
            ...state.chats.careNavigator,
            connections: state.chats.careNavigator.connections.map(connection => {
              if (connection.channelUrl === payload.channelUrl) {
                return {
                  ...connection,
                  lastMessage: payload.message.message.text,
                  lastMessageTimestampUnix: dayjs().valueOf(),
                };
              }
              return connection;
            }),
          },
          searchResults: {
            ...state.chats.searchResults,
            connections: state.chats.searchResults.connections.map(connection => {
              if (connection.channelUrl === payload.channelUrl) {
                return {
                  ...connection,
                  lastMessage: payload.message.message.text,
                  lastMessageTimestampUnix: dayjs().valueOf(),
                };
              }
              return connection;
            }),
          },
        },
        userGroups: state.userGroups.map(connection => {
          if (connection.channelUrl === payload.channelUrl) {
            return {
              ...connection,
              lastMessage: payload.message.message.text,
              lastMessageTimestampUnix: dayjs().valueOf(),
            };
          }
          return connection;
        }),
      };
    }
    case CHAT_MEDIA_SENT: {
      return {
        ...state,
        chats: {
          ...state.chats,
          members: {
            ...state.chats.members,
            connections: state.chats.members.connections.map(connection => {
              if (connection.channelUrl === payload.channelUrl) {
                return {
                  ...connection,
                  lastMessage: 'Attachment',
                  lastMessageTimestampUnix: dayjs().valueOf(),
                };
              }
              return connection;
            }),
          },
          providers: {
            ...state.chats.providers,
            connections: state.chats.providers.connections.map(connection => {
              if (connection.channelUrl === payload.channelUrl) {
                return {
                  ...connection,
                  lastMessage: 'Attachment',
                  lastMessageTimestampUnix: dayjs().valueOf(),
                };
              }
              return connection;
            }),
          },
          careTeam: {
            ...state.chats.careTeam,
            connections: state.chats.careTeam.connections.map(connection => {
              if (connection.channelUrl === payload.channelUrl) {
                return {
                  ...connection,
                  lastMessage: 'Attachment',
                  lastMessageTimestampUnix: dayjs().valueOf(),
                };
              }
              return connection;
            }),
          },
        },
      };
    }

    case SELECTED_APPOINTMENT_NOTE: {
      return {
        ...state,
        selectedAppointment: payload,
      };
    }
    case CHAT_MARK_AS_READ: {
      const { activeConnections } = state;
      // const { channelUrl } = payload;
      // activeConnections = activeConnections.map(connection => {
      //   if (connection.channelUrl === channelUrl) {
      //     return {
      //       ...connection,
      //       // lastMessageUnread: false,
      //     };
      //   }
      //   return connection;
      // });
      return {
        ...state,
        activeConnections: sortConnections(activeConnections),
      };
    }
    case FETCH_GROUPS: {
      return {
        ...state,
        group: {
          ...state.group,
          isLoading: true,
          errorMsg: null,
        },
      };
    }
    case FETCH_GROUPS_SUCCESSFUL: {
      return {
        ...state,
        group: {
          ...state.group,
          isLoading: false,
          errorMsg: null,
          groups: payload.groups,
        },
      };
    }
    case FETCH_GROUPS_FAILED: {
      return {
        ...state,
        group: {
          ...state.group,
          isLoading: false,
          errorMsg: payload?.message || null,
          groups: [],
        },
      };
    }
    case FETCH_DEMOGRAPHIC_METADATA: {
      return {
        ...state,
        demographicMetadata: {
          ...state.demographicMetadata,
          isLoading: true,
          errorMsg: null,
        },
      };
    }
    case FETCH_DEMOGRAPHIC_METADATA_SUCCESSFUL: {
      return {
        ...state,
        demographicMetadata: {
          ...state.demographicMetadata,
          isLoading: false,
          errorMsg: null,
          ...payload.demographicMetadata,
        },
      };
    }
    case FETCH_DEMOGRAPHIC_METADATA_FAILED: {
      return {
        ...state,
        demographicMetadata: {
          ...state.demographicMetadata,
          isLoading: false,
          errorMsg: payload?.message || null,
        },
      };
    }
    case FETCH_MEMBER_INSURANCE: {
      return {
        ...state,
        memberInsurance: {
          ...state?.memberInsurance,
          insurance: null,
          isLoading: true,
          errorMsg: null,
        },
      };
    }
    case FETCH_MEMBER_INSURANCE_SUCCESSFUL: {
      return {
        ...state,
        memberInsurance: {
          ...state?.memberInsurance,
          isLoading: false,
          errorMsg: null,
          insurance: payload.data,
        },
      };
    }
    case FETCH_MEMBER_INSURANCE_FAILED: {
      return {
        ...state,
        memberInsurance: {
          ...state.memberInsurance,
          insurance: null,
          isLoading: false,
          errorMsg: payload?.message || null,
        },
      };
    }
    case PUT_MEMBER_INSURANCE: {
      return {
        ...state,
        memberInsurance: {
          ...state?.memberInsurance,
          isLoading: true,
          errorMsg: null,
        },
      };
    }
    case PUT_MEMBER_INSURANCE_SUCCESSFUL: {
      return {
        ...state,
        memberInsurance: {
          ...state?.memberInsurance,
          isLoading: false,
          errorMsg: null,
          insurance: payload.data,
        },
      };
    }
    case PUT_MEMBER_INSURANCE_FAILED: {
      return {
        ...state,
        memberInsurance: {
          ...state?.memberInsurance,
          isLoading: false,
          errorMsg: payload?.message || null,
        },
      };
    }
    case ADD_MEMBER_INSURANCE: {
      return {
        ...state,
        memberInsurance: {
          ...state?.memberInsurance,
          isLoading: true,
          errorMsg: null,
        },
      };
    }
    case ADD_MEMBER_INSURANCE_SUCCESSFUL: {
      return {
        ...state,
        memberInsurance: {
          ...state?.memberInsurance,
          isLoading: false,
          errorMsg: null,
          insurance: payload?.data,
        },
      };
    }
    case ADD_MEMBER_INSURANCE_FAILED: {
      return {
        ...state,
        memberInsurance: {
          ...state?.memberInsurance,
          insurance: null,
          isLoading: false,
          errorMsg: payload?.message || null,
        },
      };
    }
    case USER_LOGOUT: {
      return {
        ...DEFAULT,
      };
    }
    case PUT_CARE_TEAM_LIST: {
      return {
        ...state,
        careTeamList2: action.payload,
      };
    }
    case CLEAR_CARE_TEAM_LIST: {
      return {
        ...state,
        careTeamList2: [],
      };
    }
    case PUT_DEMOGRAPHIC_DETAILS2: {
      const { levelOfEngagementStatus, levelOfEngagementSubStatus, visitFrequency } = action.payload;
      return {
        ...state,
        demographicDetails: {
          ...state.demographicDetails,
          levelOfEngagementStatus,
          levelOfEngagementSubStatus,
          visitFrequency,
        },
      };
    }
    case INVITE_MEMBER_SUCCESSFUL: {
      return {
        ...state,
        payload: {
          ...state.payload,
          patientsList: state.payload.patientsList.map(member => {
            if (member.member.id === payload.memberId) {
              member.engagement.Status = 'Invited';
              member.actions.loeStatus = member.engagement.Status;
            }
            return member;
          }),
        },
      };
    }
    case INVITE_MEMBER_FAILED: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case FETCH_SYNCED_GOOGLE_CALENDAR: {
      return {
        ...state,
        syncedCalendars: {
          ...state.syncedCalendars,
          isLoading: true,
        },
      };
    }
    case FETCH_SYNCED_GOOGLE_CALENDAR_SUCCESSFUL: {
      return {
        ...state,
        syncedCalendars: {
          ...state.syncedCalendars,
          isLoading: false,
          calendars: payload,
        },
      };
    }
    case FETCH_SYNCED_GOOGLE_CALENDAR_FAILED: {
      return {
        ...state,
        syncedCalendars: {
          ...state.syncedCalendars,
          isLoading: false,
          errorMsg: payload?.message,
        },
      };
    }
    case FETCH_ALL_GOOGLE_CALENDAR_EVENTS: {
      return {
        ...state,
        googleCalendarEvents: {
          ...state.googleCalendarEvents,
          isLoading: true,
        },
      };
    }
    case FETCH_ALL_GOOGLE_CALENDAR_EVENTS_SUCCESSFUL: {
      return {
        ...state,
        googleCalendarEvents: {
          ...state.googleCalendarEvents,
          isLoading: false,
          events: payload,
        },
      };
    }
    case FETCH_ALL_GOOGLE_CALENDAR_EVENTS_FAILED: {
      return {
        ...state,
        googleCalendarEvents: {
          ...state.googleCalendarEvents,
          isLoading: false,
          errorMsg: payload?.message,
        },
      };
    }

    case FETCH_REFERRAL_PARTNERS: {
      return {
        ...state,
        referralPartners: {
          isLoading: true,
        },
      };
    }
    case FETCH_REFERRAL_PARTNERS_SUCCESSFUL: {
      return {
        ...state,
        referralPartners: {
          partners: payload.referralPartnerList,
          totalRecords: payload.totalRecords,
          isLoading: false,
        },
      };
    }
    case FETCH_REFERRAL_PARTNERS_FAILED: {
      return {
        ...state,
        referralPartners: {
          partners: [],
          totalRecords: 0,
          isLoading: false,
          errorMsg: payload?.message,
        },
      };
    }
    case CHATS_CLEAR: {
      return {
        ...state,
        groupsLoading: false,
        userGroups: [],
        chats: {
          ...state.chats,
          members: {
            ...state.chats.members,
            connections: [],
          },
          providers: {
            ...state.chats.providers,
            connections: [],
          },
          careTeam: {
            ...state.chats.careTeam,
            connections: [],
          },
          careNavigator: {
            ...state.chats.careNavigator,
            connections: [],
          },
          searchResults: {
            ...state.chats.searchResults,
            isLoading: false,
            connections: [],
          },
        },
      };
    }
    default: {
      return state;
    }
  }
}
