import cx from 'clsx';
// colors
import { Box } from '@mui/material';
import { colors } from '../../../colors';
// icons
import { Icons } from '../../../icons';
import { Heading, headingLevel, Text, textLevel } from '../../atoms/typography';
import { NoteStyle } from '../../../constants/CommonConstants';
// styles
import { useStyles } from './NoteCategoryCard.styles';
// types
import { iconBtnPosition, iconBtnType, IconButton } from '../../molecules/icon-button';

const NoteCategoryCard = ({
  className = '',
  flag,
  icon,
  hideIcon = false,
  title,
  subTitle,
  onClick,
  actions = [],
}) => {
  const status = NoteStyle[flag] || NoteStyle.GENERAL;
  const classes = useStyles({ status });

  return (
    <div className={cx(classes.root, className)} onClick={onClick}>
      <div className={classes.topContentWrapper}>
        {!hideIcon && (
          <Box className={cx(classes.iconWrapper, flag)}>
            <Icons color={colors[`${status}500`]} glyph={icon} />
          </Box>
        )}
        <Box sx={{ flex: 1, flexDirection: 'row' }} className={classes.container}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2 }}>
            <Heading className={classes.title} level={headingLevel.S}>
              {title}
            </Heading>
            <Box className={classes.actionsWrapper}>
              {actions.length > 0 && (
                <Box sx={{ display: 'flex', gap: 3 }}>
                  {actions.map((action, index) => (
                    <Box key={index} sx={{ svg: { marginLeft: 1 }, '& button': { fontSize: 14 } }}>
                      <IconButton
                        onClick={action.onClick}
                        icon={action.icon || 'arrow-right'}
                        iconPosition={iconBtnPosition.RIGHT}
                        variant={iconBtnType.TEXT}
                      >
                        {action.label}
                      </IconButton>
                    </Box>
                  ))}
                </Box>
              )}
            </Box>
          </Box>
          <Box>
            <Text className={classes.subTitle} level={textLevel.S}>
              {subTitle}
            </Text>
          </Box>
        </Box>
      </div>
    </div>
  );
};

export { NoteCategoryCard };
