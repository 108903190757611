import { colors, Icons } from '../../../../packages';
import { Drawer, drawerType } from '../../../../packages/ui/organisms/drawer';
import { Box } from '@mui/material';
import Typography from '../../../../components/v2/Typography';
import { typography } from '../../../../components/v2/Typography/index.constant';
import dayjs from 'dayjs';

const RecurringDrawer = ({ open, onClose, startTime, viewDetail }) => {
  return (
    <Drawer
      open={open}
      title="Edit recurring appointment"
      onClose={onClose}
      variant={drawerType.FORM}
      footer={<div />}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
        <Typography {...typography.body.m.medium} color={colors.neutral600}>
          Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat
          duis enim.
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              cursor: 'pointer',
              border: `1px solid ${colors.neutral200}`,
              borderRadius: 2,
              p: 3,
            }}
            onClick={viewDetail}
          >
            <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
              <Icons glyph="calendar-today-outlined" color={colors.primary500} />
              <Typography {...typography.body.m.semibold}>This appointment only</Typography>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
              <Typography {...typography.body.s.medium}>{dayjs(startTime).format('MMM DD, YYYY')}</Typography>
              <Typography
                {...typography.body.s.medium}
                color={colors.neutral600}
                display="flex"
                justifyContent="flex-end"
              >
                {dayjs(startTime).format('h:mm a')}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              cursor: 'pointer',
              border: `1px solid ${colors.neutral200}`,
              borderRadius: 2,
              p: 3,
            }}
            onClick={() => console.log('==≠')} // open scheduler slider function
          >
            <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
              <Icons glyph="calendar-today-outlined" color={colors.secondary500} />
              <Typography {...typography.body.m.semibold}>All future appointments</Typography>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
              <Typography {...typography.body.s.medium}>Every {dayjs(startTime).format('dddd')}</Typography>
              <Typography
                {...typography.body.s.medium}
                color={colors.neutral600}
                display="flex"
                justifyContent="flex-end"
              >
                {dayjs(startTime).format('h:mm a')}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Drawer>
  );
};

export default RecurringDrawer;
