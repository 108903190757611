/* eslint-disable max-len */
import cx from 'clsx';

// types
// styles
import { useStyles } from './shared/styles';

const Star2 = ({ className, color, onClick }) => {
  const classes = useStyles();
  return (
    <svg
      className={cx(classes.root, className)}
      onClick={onClick}
      width="16"
      height="21"
      viewBox="0 0 16 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 4.99999C10.1217 4.99999 12.1566 5.84285 13.6569 7.34314C15.1571 8.84343 16 10.8783 16 13C16 15.1217 15.1571 17.1566 13.6569 18.6568C12.1566 20.1571 10.1217 21 8 21C5.87827 21 3.84344 20.1571 2.34315 18.6568C0.842855 17.1566 0 15.1217 0 13C0 10.8783 0.842855 8.84343 2.34315 7.34314C3.84344 5.84285 5.87827 4.99999 8 4.99999ZM8 6.99999C6.4087 6.99999 4.88258 7.63213 3.75736 8.75735C2.63214 9.88257 2 11.4087 2 13C2 14.5913 2.63214 16.1174 3.75736 17.2426C4.88258 18.3679 6.4087 19 8 19C9.5913 19 11.1174 18.3679 12.2426 17.2426C13.3679 16.1174 14 14.5913 14 13C14 11.4087 13.3679 9.88257 12.2426 8.75735C11.1174 7.63213 9.5913 6.99999 8 6.99999ZM8 8.49999L9.323 11.18L12.28 11.61L10.14 13.695L10.645 16.641L8 15.25L5.355 16.64L5.86 13.695L3.72 11.609L6.677 11.179L8 8.49999ZM14 -7.03335e-06V2.99999L12.637 4.13799C11.5059 3.54458 10.2711 3.17482 9 3.04899V-7.03335e-06H14ZM7 -0.00100708V3.04899C5.72935 3.17464 4.49482 3.54405 3.364 4.13699L2 2.99999V-7.03335e-06L7 -0.00100708Z"
        fill={color}
      />
    </svg>
  );
};

export default Star2;
