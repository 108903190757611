import { makeStyles } from '@mui/styles';

import { colors } from '../../../../../packages/colors';

export const useStyles = makeStyles({
  cardProfileRoot: {
    background: colors.white,
    boxShadow: `inset 0px -1px 0px ${colors.neutral100}`,
    borderRadius: 8,
    marginBottom: 16,
  },
  dividerCard: {
    backgroundColor: colors.neutral100,
  },
});
