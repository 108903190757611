import { useState } from 'react';
import { Box, Stack } from '@mui/material';
import { Drawer, drawerType } from '../../../../../packages/ui/organisms/drawer';
import { TabPanel, Tabs } from '../../../../../packages/ui/atoms/tabs';
import { fontWeight, Heading, Text } from '../../../../../packages';
import { Filter } from '../../../../../packages/ui/organisms/table';
import { TableSearch } from '../../../../../packages/ui/organisms/table/search';

import { completionTabs } from '../../../conversation/ConversationDetail.mock';
import ReportCard from './report-card';
import CardTop from './card-top';

import { useStyles } from './CompletionDetail.styles';
import SingleSelect from './single-select';
import ViewInput from './view-input';

const CompletionDetail = () => {
  const classes = useStyles();
  const [currentTab, setCurrentTab] = useState(completionTabs[0].tabKey);
  const [showViewDetails, setShowViewDetails] = useState(null);

  const onViewDetailClick = type => () => {
    setShowViewDetails(type);
  };

  const onCloseViewDetail = () => {
    setShowViewDetails(null);
  };

  const renderContent = () => (
    <Box display="flex" flexDirection="column" gap={4}>
      <Box className={classes.searchWrap}>
        <TableSearch
          placeholder="Search"
          filterProps={{
            variant: Filter.tableFilterType.MULTIPLE,
            options: [],
            allOptionLabel: 'All',
          }}
        />
      </Box>
      {/* <ReportChartCard
        title="How are you feeling today?"
        subtitle="Single Select"
        badge="30 Completions"
        total={30}
        chartData={[
          { name: 'Excellent', data: [50], changedValue: '+5% from 1st completion' },
          { name: 'Fine', data: [20], changedValue: '+5% from 1st completion' },
          { name: 'Terrible', data: [30], changedValue: '+5% from 1st completion' },
        ]}
        onViewClick={onViewDetailClick('singleSelect')}
      />
      <ReportChartCard
        title="Content Block Question"
        subtitle="Rating scale"
        badge="20 Completions"
        total={20}
        chartData={[
          { name: 'Choice 1', data: [50], changedValue: '+5% from 1st completion' },
          { name: 'Choice 2', data: [25], changedValue: '+5% from 1st completion' },
          { name: 'Choice 3', data: [25], changedValue: '+5% from 1st completion' },
        ]}
        onViewClick={onViewDetailClick('ratingScale')}
      /> */}
      <ReportCard
        title="Content Block Question"
        subtitle="Numeric input"
        badge="20 Completions"
        onViewClick={onViewDetailClick('numberic')}
      />
      <CardTop
        className={classes.card}
        title="Content Block Question"
        subtitle="Text input"
        badge="20 Completions"
        onClick={onViewDetailClick('textInput')}
      />
      <CardTop
        className={classes.card}
        title="Content Block Question"
        subtitle="Text area"
        badge="20 Completions"
        onClick={onViewDetailClick('textArea')}
      />
    </Box>
  );

  const renderDrawerContent = () => {
    switch (showViewDetails) {
      case 'singleSelect':
      case 'ratingScale':
      case 'numberic':
        return <SingleSelect showNumber={['ratingScale', 'numberic'].includes(showViewDetails)} />;
      default:
        return <ViewInput showList={showViewDetails === 'textInput'} />;
    }
  };

  return (
    <>
      <Drawer
        open={!!showViewDetails}
        onClose={onCloseViewDetail}
        variant={drawerType.FORM}
        footer={<div />}
        title="Responses by members"
        subTitle="30 completions"
      >
        <Box>
          <Text weight={fontWeight.SEMI_BOLD} className={classes.drawerTitle}>
            How are you feeling today?
          </Text>
          {renderDrawerContent()}
        </Box>
      </Drawer>
      <Box display="flex" flexDirection="column" gap={4} sx={{ marginTop: 6 }}>
        <Stack direction="column" gap={1}>
          <Heading className={classes.heading} weight={fontWeight.BOLD}>
            Responses by completion
          </Heading>
          <Text className={classes.subtitle}>500 completions total</Text>
        </Stack>
        <Box display="flex" justifyContent="space-between" gap={2}>
          {/* {completionMock.map((item, index) => (
            <InfoCard key={index} {...item} isBordered={index === 0} />
          ))} */}
        </Box>
        <Tabs options={completionTabs} value={currentTab} onChange={setCurrentTab} className={classes.tabs} />
        <TabPanel value={currentTab} tabKey={completionTabs[0].tabKey}>
          {renderContent()}
        </TabPanel>
        <TabPanel value={currentTab} tabKey={completionTabs[1].tabKey}>
          {renderContent()}
        </TabPanel>
        <TabPanel value={currentTab} tabKey={completionTabs[2].tabKey}>
          {renderContent()}
        </TabPanel>
      </Box>
    </>
  );
};

export { CompletionDetail };
