import dayjs from 'dayjs';

export const assignmentColumns = [
  { id: 'patientName', label: 'Assigned to', sortable: false },
  { id: 'assignBy', label: 'Assigned by', sortable: false },
  { id: 'date', label: 'Assigned on', sortable: false },
  { id: 'actions', label: '', sortable: false },
];

export const filterOptionsMock = [{ label: 'Type 1', value: 'type_01' }];

export const assignmentListMock = [
  {
    id: '1',
    assignTo: {
      name: 'john_doe',
      fullname: 'John Doe',
      thumbnail: 'https://i.pravatar.cc/300',
    },
    assignBy: {
      type: 'provider',
      name: 'Eva Bond',
      role: 'Provider',
      thumbnail: 'https://i.pravatar.cc/300',
    },
    assignedDate: dayjs().subtract(1, 'd').toISOString(),
  },
  {
    id: '2',
    assignTo: {
      name: 'Eliah',
      fullname: 'Eliah Johnson',
      thumbnail: 'https://i.pravatar.cc/300',
    },
    assignBy: {
      type: 'user',
      thumbnail: 'https://i.pravatar.cc/300',
    },
    assignedDate: dayjs().subtract(2, 'd').toISOString(),
  },
  {
    id: '3',
    assignTo: {
      name: 'Alisa',
      fullname: 'Alisa Hester',
      thumbnail: 'https://i.pravatar.cc/300',
    },
    assignBy: {
      type: 'system',
    },
    assignedDate: dayjs().subtract(1, 'd').toISOString(),
  },
  {
    id: '4',
    assignTo: {
      name: 'Lana',
      fullname: 'Lana Stevens',
      thumbnail: 'https://i.pravatar.cc/300',
    },
    assignBy: {
      type: 'provider',
      name: 'Eva Bond',
      role: 'Provider',
      thumbnail: 'https://i.pravatar.cc/300',
    },
    assignedDate: dayjs().subtract(1, 'd').toISOString(),
  },
  {
    id: '5',
    assignTo: {
      name: 'Natali',
      fullname: 'Natali Craig',
      thumbnail: 'https://i.pravatar.cc/300',
    },
    assignBy: {
      type: 'provider',
      name: 'Phoenix Baker',
      role: 'Provider',
      thumbnail: 'https://i.pravatar.cc/300',
    },
    assignedDate: dayjs().subtract(1, 'd').toISOString(),
  },
  {
    id: '6',
    assignTo: {
      name: 'Zahir',
      fullname: 'Zahir Mays',
      thumbnail: 'https://i.pravatar.cc/300',
    },
    assignBy: {
      type: 'provider',
      name: 'Ryan Foxx',
      role: 'Coach',
      thumbnail: 'https://i.pravatar.cc/300',
    },
    assignedDate: dayjs().subtract(1, 'd').toISOString(),
  },
  {
    id: '7',
    assignTo: {
      name: 'Lucky Loki',
      fullname: 'Loki Bright',
      thumbnail: 'https://i.pravatar.cc/300',
    },
    assignBy: {
      name: 'Eva Bond',
      role: 'Provider',
      thumbnail: 'https://i.pravatar.cc/300',
    },
    assignedDate: dayjs().subtract(1, 'd').toISOString(),
  },
];
