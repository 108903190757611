import { Box, Grid, useMediaQuery } from '@mui/material';
import { IconButton, iconBtnPosition } from '../../../packages/ui/molecules/icon-button';
import { colors } from '../../../packages/colors';
import { ProfileInfo } from '../../../packages/ui/templates/profile-info';
import { Badge, badgeSize, badgeType } from '../../../packages/ui/atoms/badge';
import history from '../../../utils/history';

import { useEffect, useState } from 'react';
import Heading from '../../../components/v2/Heading';
import Typography from '../../../components/v2/Typography';
import { typography } from '../../../components/v2/Typography/index.constant';
import { HeadingLevel, HeadingWeight } from '../../../components/v2/Heading/index.types';
import { getAuth } from '../../../redux/modules/auth/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { profileActionCreators } from '../../../redux/modules/profile';
import { getProviderFeeList } from '../../../services/provider-fee/providerFee.service';
import { getDateLabels } from '../../../utils/CommonUtils';
import { showSnackbar } from '../../../redux/modules/snackbar';
import PaymentCard from './components/Card';
import AllPaymentsDrawer from './components/AllPaymentsDrawer';

import { useStyles } from './index.styles';

const Payments = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [openPaymentsDrawer, setOpenPaymentsDrawer] = useState(false);
  const { meta } = useSelector(getAuth);
  const [paymentList, setPaymentList] = useState({});
  const isMd = useMediaQuery(theme => theme.breakpoints.down('md'));

  const fetchProviderPayments = async () => {
    try {
      const queryParams = {
        page: 1,
        limit: 5,
      };
      const body = {
        searchQuery: '',
        startDate: null,
        endDate: null,
        statuses: [],
        states: [],
        roles: [],
        providerIds: [meta.userId],
      };
      const { data } = await getProviderFeeList(queryParams, body);
      setPaymentList(data);
    } catch (e) {
      const message = e?.data?.message || 'Something went wrong';
      dispatch(
        showSnackbar({
          snackType: 'error',
          snackMessage: message,
        }),
      );
      console.warn(e);
    }
  };

  const gotoMemberDetail = async payment => {
    const queryParams = {
      searchQuery: payment?.member?.firstName || payment?.member?.nickName,
      pageNumber: 1,
      pageSize: 50,
      orderBy: '',
      sortBy: '',
    };
    await dispatch(profileActionCreators.fetchPatients(queryParams));
    setTimeout(function () {
      history.push(`/provider/members/${payment?.memberId}?name=dashboard`);
    }, 1500);
  };

  useEffect(() => {
    void fetchProviderPayments();
  }, []);

  return (
    <>
      <Box className={classes.scorebox}>
        <Box className={classes.sbflex} px={3} py={2.5}>
          <Heading variant={HeadingLevel.SUB} fontWeight={HeadingWeight.SEMI_BOLD}>
            My payments
          </Heading>
          {paymentList?.metadata?.count > 0 && (
            <Box sx={{ '& button': { gap: 1 } }}>
              <IconButton
                icon="arrow-right"
                iconPosition={iconBtnPosition.RIGHT}
                onClick={() => setOpenPaymentsDrawer(true)}
              >
                <Typography {...typography.body.s.bold}>View all</Typography>
              </IconButton>
            </Box>
          )}
        </Box>
        <Box className={classes.cardContainer}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <PaymentCard
                label="Total amount earned"
                count={`$${paymentList?.metadata?.totalFee ? paymentList?.metadata?.totalFee : '0'}`}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <PaymentCard label="Total Appointments" count={paymentList?.metadata?.count} />
            </Grid>
          </Grid>
        </Box>
        <Grid container>
          {paymentList?.data?.map((payment, index) => (
            <Grid
              item
              container
              display="flex"
              alignItems="center"
              borderBottom={`1px solid ${colors.neutral100}`}
              key={index}
            >
              <Grid
                item
                xs={12}
                md={3}
                padding={2}
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  void gotoMemberDetail(payment);
                }}
              >
                <ProfileInfo
                  type="member"
                  photo={payment?.member?.profileImage}
                  nickName={
                    payment?.member?.firstName && payment?.member?.lastName
                      ? `${payment?.member?.firstName || ''} ${payment?.member?.lastName || ''}`
                      : payment?.member?.Nickname || 'No Name'
                  }
                  fullName={payment.member.uuid}
                />
              </Grid>
              <Grid item xs={12} md={1} padding={2}>
                <Typography {...typography.body.m.bold}>${payment?.amount}</Typography>
              </Grid>
              <Grid item xs={12} md={3} padding={2}>
                <Typography {...typography.body.normal.small.medium}>{payment?.sessionType}</Typography>
              </Grid>
              <Grid item xs={12} md={2} padding={2} className={isMd ? '' : classes.paymentBadgeContainer}>
                <Badge
                  variant={badgeType.FILLED}
                  size={badgeSize.XSMALL}
                  className={
                    payment.status === 'Paid'
                      ? classes.badgeStyle1
                      : payment.status === 'Submitted' || payment.status === 'Created'
                      ? classes.badgeStyle2
                      : classes.badgeStyle3
                  }
                >
                  <Typography {...typography.body.normal.xsmall.semibold}>
                    {payment.status === 'Paid'
                      ? 'Paid'
                      : payment.status === 'Submitted' || payment.status === 'Created'
                      ? 'Pending'
                      : 'Rejected'}
                  </Typography>
                </Badge>
              </Grid>
              <Grid item xs={12} md={3} padding={2}>
                <Typography {...typography.body.normal.small.medium} color={colors.neutral600}>
                  {getDateLabels(payment?.timeStamp)}
                </Typography>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Box>
      <AllPaymentsDrawer open={openPaymentsDrawer} onClose={() => setOpenPaymentsDrawer(false)} />
    </>
  );
};

export default Payments;
