// import { makeStyles } from '@mui/styles';

// // colors
// import { colors } from '../../../colors';

// export const useStyles = makeStyles({
//   root: {
//     padding: 0,
//     '&:hover': {
//       '& svg > rect': {
//         stroke: colors.primary,
//       },
//     },
//     '&.Mui-checked': {
//       '& svg > rect': {
//         fill: colors.bgTertiary,
//       },
//     },
//     '&.Mui-disabled': {
//       opacity: 0.5,
//     },
//   },
//   icon: {
//     width: 24,
//     height: 24,
//   },
// });

import { makeStyles } from '@mui/styles';

// colors
import { colors } from '../../../colors';

export const useStyles = makeStyles({
  root: {
    padding: 0,
    '&:hover': {
      '& svg > rect': {
        stroke: colors.primary,
      },
    },
    '&.Mui-checked': {
      '& svg > rect': {
        fill: colors.bgTertiary,
      },
    },
    '&.Mui-disabled': {
      opacity: 0.5,
    },
  },
  icon: {
    width: 24,
    height: 24,
  },
});
