import { FC, useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';
import { Badge, badgeSize, badgeStyle, badgeType } from '../../../../packages/ui/atoms/badge';
import { iconBtnPosition, IconButton } from '../../../../packages/ui/molecules/icon-button';
import { colors } from '../../../../packages/colors';
import BoxFrame from '../../../../components/v2/BoxFrame';
import Header from '../../../../components/v2/Header';
import Typography from '../../../../components/v2/Typography';
import { HeadingFont, HeadingLevel, HeadingWeight } from '../../../../components/v2/Heading/index.types';
import { typography } from '../../../../components/v2/Typography/index.constant';
import { Icons } from '../../../../packages/icons';
import { useSelector } from 'react-redux';
import { mapToLegacy, toSentenceCase } from '../../../../utils/CommonUtils';
import { getProfile } from '../../../../redux/modules/profile/selectors';
import { getAssignedPrioritiesV2 } from '../../../../services/priority/priority.service';
import { PrioritiesCard } from '../../sessions/priority-list/PriorityList.constants';
import { useStyles } from '../index.styles';

const EnviromentalFactors = ({ memberId, onViewAll, reset, onClick }) => {
  const typeName = PrioritiesCard.EnvironmentalFactors;
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [records, setRecords] = useState([]);
  const [total, setTotal] = useState(0);
  const { lookupData } = useSelector(getProfile);

  useEffect(() => {
    setLoading(true);
    getAssignedPrioritiesV2({
      patientId: memberId,
      dataDomainType: typeName,
      pageNumber: 0,
      pageSize: 500,
      importanceLevels: 'CRITICAL,HIGH',
    })
      .then(({ data }) => {
        setRecords(data.records.map(record => mapToLegacy(record, lookupData)));
        setLoading(false);
      })
      .catch(e => {
        console.error(e);
        setLoading(false);
        setRecords([]);
      });
    getAssignedPrioritiesV2({
      patientId: memberId,
      dataDomainType: typeName,
      pageNumber: 0,
      pageSize: 1,
    })
      .then(({ data }) => {
        setTotal(data.totalRecords);
      })
      .catch(e => {
        console.error(e);
      });
  }, [memberId, reset]);
  return (
    <BoxFrame>
      <Grid container>
        <Grid item xs={12} md={9}>
          <Box py={2.5} px={3}>
            <Header
              label="Important environmental factors"
              fontFamily={HeadingFont.PRIMARY}
              fontVariant={HeadingLevel.SUB}
              fontWeight={HeadingWeight.SEMI_BOLD}
              badge={
                total > 0 && (
                  <Badge variant={badgeType.OUTLINED} style={badgeStyle.INACTIVE} size={badgeSize.XSMALL}>
                    {total} total
                  </Badge>
                )
              }
            />
          </Box>
        </Grid>
        {onViewAll && (
          <Grid item xs={12} md={3} display="flex" justifyContent="flex-end" alignItems="center">
            <Box sx={{ '& button': { gap: 1 } }} pr={2}>
              <IconButton
                icon="arrow-right"
                iconPosition={iconBtnPosition.RIGHT}
                onClick={() => onViewAll(typeName)}
              >
                <Typography {...typography.body.s.bold}>View all</Typography>
              </IconButton>
            </Box>
          </Grid>
        )}
        {loading ? (
          <Grid item container sx={{ borderTop: `1px solid ${colors.neutral100}` }}>
            <Grid item xs={12} md={12}>
              <Box display="flex" justifyContent="center" sx={{ paddingY: 5 }}>
                Loading...{' '}
                <Icons className="rotate linear infinite" glyph="in-progress" color={colors.primary} />
              </Box>
            </Grid>
          </Grid>
        ) : records.length === 0 ? (
          <Grid item container sx={{ borderTop: `1px solid ${colors.neutral100}` }}>
            <Grid item xs={12} md={12} style={{ padding: '40px' }}>
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  flex: 1,
                  gap: '16px',
                }}
              >
                <Icons glyph="lottie-environmental-factors" className={classes.lottieSize} />
                <Typography {...typography.body.m.medium} color={colors.neutral900}>
                  No social determinant or life event data with critical or high importance.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        ) : (
          records.map((item, index) => (
            <Grid
              item
              container
              key={index}
              sx={{
                borderTop: `1px solid ${colors.neutral100}`,
                cursor: 'pointer',
              }}
              onClick={() => {
                onClick(item, typeName);
              }}
            >
              <Grid item xs={12} md={9}>
                <Box display="flex" gap={2} py={2.5} px={3}>
                  <Typography {...typography.body.m.medium}>{item.title}</Typography>
                  <Box sx={{ '&>div': { borderRadius: 1 } }}>
                    <Badge
                      variant={badgeType.OUTLINED}
                      style={badgeStyle[item.status]}
                      size={badgeSize.SMALL}
                    >
                      {toSentenceCase(item?.status)}
                    </Badge>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} md={3} display="flex" justifyContent="flex-end" alignItems="center">
                <Box pr={2}>
                  <Typography {...typography.body.s.medium}>{item.date}</Typography>
                </Box>
              </Grid>
            </Grid>
          ))
        )}
      </Grid>
    </BoxFrame>
  );
};

export default EnviromentalFactors;
