/* eslint-disable max-len */
// types
// styles
import { useStyles } from './shared/styles';
import cx from 'clsx';

const Member = ({ className, color, onClick }) => {
  const classes = useStyles();
  return (

    <svg
      className={cx({
        [classes.root]: true,
        [className || '']: className,
      })}
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      onClick={onClick}
    >
      <path
        d='M2.6665 14.666C2.6665 13.2515 3.22841 11.895 4.2286 10.8948C5.2288 9.89459 6.58535 9.33268 7.99984 9.33268C9.41432 9.33268 10.7709 9.89459 11.7711 10.8948C12.7713 11.895 13.3332 13.2515 13.3332 14.666H11.9998C11.9998 13.6052 11.5784 12.5877 10.8283 11.8376C10.0781 11.0874 9.0607 10.666 7.99984 10.666C6.93897 10.666 5.92156 11.0874 5.17141 11.8376C4.42126 12.5877 3.99984 13.6052 3.99984 14.666H2.6665ZM7.99984 8.66602C5.78984 8.66602 3.99984 6.87602 3.99984 4.66602C3.99984 2.45602 5.78984 0.666016 7.99984 0.666016C10.2098 0.666016 11.9998 2.45602 11.9998 4.66602C11.9998 6.87602 10.2098 8.66602 7.99984 8.66602ZM7.99984 7.33268C9.47317 7.33268 10.6665 6.13935 10.6665 4.66602C10.6665 3.19268 9.47317 1.99935 7.99984 1.99935C6.5265 1.99935 5.33317 3.19268 5.33317 4.66602C5.33317 6.13935 6.5265 7.33268 7.99984 7.33268Z'
        fill={color}
      />
    </svg>
  );
};

export default Member;
