import { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import cx from 'clsx';
import { Drawer, drawerType } from '../../../../packages/ui/organisms/drawer';
import ToastMessage from '../../../../packages/ui/templates/toast-message';
import { TOAST_TYPES } from '../../../../constants/CommonConstants';
import AddSchedule from '../../../../pages/admin/appointments/add-schedule';
import { appointmentActionCreators } from '../../../../redux/modules/appointment';
import { useDispatch, useSelector } from 'react-redux';
import { selectProviderSchedule } from '../../../../redux/modules/schedule/selectors';
import Priorities from '../../priorities';
import Chats from './components/chat-drawer';
import { useStyles } from './VideoCallScreen.styles';
import WaitingHeader from '../waiting-header';
import AppointmentInfo from '../appointment-info';
import AskMeAnything from '../../../../components/ask-me-anything';
import { UpdatedSessionExperience } from './UpdatedSessionExperience';
import { MEMBER_ACTIONS } from '../../../../packages/ui/templates/sessions/sessions.constants';
import { getActiveDrawer } from '../../../../redux/modules/common/selectors';
import { commonActionCreators } from '../../../../redux/modules/common';
import { getNextAppointment } from '../../../../services/appointment/appointment.service';

const VideoCallScreen = ({
  isWaiting,
  setIsWaiting,
  appointment,
  isEndedSession,
  onEndSession,
  endSession,
  telesessionToken,
  telesessionId,
  careTeamList,
  isInPersonSessionCall,
  startInPersonSession,
  onRestartSessionClick,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const providerSchedule = useSelector(selectProviderSchedule);
  const activeDrawer = useSelector(getActiveDrawer);
  const [nextAppointment, setNextAppointment] = useState(null);
  const [nextGeneralAppointment, setNextGeneralAppointment] = useState(null);
  const [toastProperties, setToastProperties] = useState({
    message: '',
    type: TOAST_TYPES.SUCCESS,
    status: false,
  });

  const handleCloseMemberActionsDrawer = () => {
    dispatch(commonActionCreators.closeDrawer());
  };

  const fetchNextAppointment = async () => {
    try {
      await getNextAppointment(appointment.participantId, false, appointment.endTime).then(({ data }) => {
        setNextAppointment(data?.appointment || undefined);
      });
      await getNextAppointment(null, false, appointment.endTime).then(({ data }) => {
        setNextGeneralAppointment(data?.appointment || undefined);
      });
    } catch (error) {
      console.log(error);
    }
  };

  const onSubmitNewSchedule = payload => {
    dispatch(
      appointmentActionCreators.createAppointment({
        data: { ...payload, timezone: providerSchedule?.timezone },
        callback: (isSucceed /* , isInstantSession: boolean, appointmentId: string */) => {
          if (isSucceed) {
            fetchNextAppointment();
            handleCloseMemberActionsDrawer();
          }
        },
      }),
    );
  };

  const handleMemberActionClick = type => {
    dispatch(commonActionCreators.openDrawer(type));
  };

  useEffect(() => {
    if (appointment) {
      void fetchNextAppointment();
    }
  }, [appointment]);

  const renderDefaultScreen = () => (
    <Box className={classes.mainContent}>
      <Box display="flex" justifyContent="space-between" className={classes.waitingScreen}>
        <Box className={classes.boxLeft}>
          <WaitingHeader appointment={appointment} isCompleted={isEndedSession} />
          <Priorities
            hideNotes
            currentAppointment={appointment}
            handleMemberActionClick={handleMemberActionClick}
          />
        </Box>
        <AppointmentInfo
          appointment={appointment}
          onEndSession={onEndSession}
          isCompleted={isEndedSession}
          startInPersonSession={startInPersonSession}
          showInPersonSession
        />
      </Box>
    </Box>
  );

  return (
    <>
      {isWaiting && renderDefaultScreen()}
      <Box className={cx(isWaiting ? classes.hiddenContainer : classes.vcsContainer)}>
        <UpdatedSessionExperience
          appointment={appointment}
          telesessionId={telesessionId}
          telesessionToken={telesessionToken}
          isInPersonSessionCall={isInPersonSessionCall}
          endSession={endSession}
          isWaiting={isWaiting}
          setIsWaiting={setIsWaiting}
          setToastProperties={setToastProperties}
          onRestartSessionClick={onRestartSessionClick}
          nextAppointment={nextAppointment}
          nextGeneralAppointment={nextGeneralAppointment}
          handleMemberActionClick={handleMemberActionClick}
        />
      </Box>
      {activeDrawer === MEMBER_ACTIONS.CHAT && (
        <Drawer
          open={activeDrawer === MEMBER_ACTIONS.CHAT}
          onClose={handleCloseMemberActionsDrawer}
          variant={drawerType.NORMAL}
          className={classes.chatDrawer}
        >
          <Chats
            careTeamList={careTeamList}
            appointment={appointment}
            onClose={handleCloseMemberActionsDrawer}
          />
        </Drawer>
      )}
      {activeDrawer === MEMBER_ACTIONS.SCHEDULE && (
        <AddSchedule
          isOpen={activeDrawer === MEMBER_ACTIONS.SCHEDULE}
          onClose={handleCloseMemberActionsDrawer}
          onSubmit={onSubmitNewSchedule}
          memberId={appointment?.participantId}
          appointment={appointment}
          isVideoSession={false}
        />
      )}
      {activeDrawer === MEMBER_ACTIONS.AMA && (
        <AskMeAnything
          useDrawer
          isOpen={activeDrawer === MEMBER_ACTIONS.AMA}
          onClose={handleCloseMemberActionsDrawer}
          memberId={appointment?.participantId}
        />
      )}
      {activeDrawer === MEMBER_ACTIONS.PROFILE && (
        <Drawer
          stickyHeader
          title="Member Profile"
          open={activeDrawer === MEMBER_ACTIONS.PROFILE}
          onClose={handleCloseMemberActionsDrawer}
          variant={drawerType.FORM}
          className={classes.memberProfileDrawer}
          footer
        >
          <Priorities
            hideNotes
            currentAppointment={appointment}
            isInPersonSessionCall={isInPersonSessionCall}
            isSession
            handleMemberActionClick={handleMemberActionClick}
          />
        </Drawer>
      )}
      <ToastMessage
        show={toastProperties.status}
        snackType={toastProperties.type}
        snackMessage={toastProperties.message}
        clearSnackbarAction={() => {
          setToastProperties({ message: '', type: toastProperties.type, status: false });
        }}
      />
    </>
  );
};

export { VideoCallScreen };
