import { colors } from '../../../../../packages';
import dayjs from 'dayjs';
import { shortNumber } from '../../../../../utils/CommonUtils';

export const generateChartOptions = chartDays => ({
  chart: {
    stacked: true,
    toolbar: {
      show: false,
    },
  },
  title: {
    text: '',
  },
  plotOptions: {
    bar: {
      horizontal: false,
    },
  },
  colors: [
    colors.green600,
    colors.neutral500,
    colors.destructive500,
    colors.cyan500,
    colors.indigo500,
    colors.orange500,
  ],
  stroke: {
    width: 1,
    colors: ['#fff'],
  },
  xaxis: {
    categories: chartDays,
    labels: {
      formatter: val => {
        return dayjs(val).format('MMM D');
      },
      style: {
        fontFamily: 'Manrope',
        fontWeight: 600,
      },
    },
  },
  yaxis: {
    title: {
      text: undefined,
    },
  },
  tooltip: {
    style: {
      fontFamily: 'Manrope',
    },
    custom: ({ series, dataPointIndex, w }) => {
      const time = dayjs(w.globals.labels[dataPointIndex]).format('MMM DD, YYYY');
      const total =
        Number(series[0][dataPointIndex]) +
        Number(series[1][dataPointIndex]) +
        Number(series[2][dataPointIndex]);

      return `
        <div class="chart-tooltip">
          <div class="date">${time}</div>
          <div class="appointments">${total} appointments</div>
          <div class="row">
            <div class="row-left">
              <div class="dot dot-completed"></div>
              <div class="title">Completed</div>
            </div>
            <div class="value">${series[0][dataPointIndex] || 0}</div>
          </div>
          <div class="row">
            <div class="row-left">
              <div class="dot dot-canceled"></div>
              <div class="title">Canceled</div>
            </div>
            <div class="value">${series[1][dataPointIndex] || 0}</div>
          </div>
          <div class="row">
            <div class="row-left">
              <div class="dot dot-noshow"></div>
              <div class="title">No shows</div>
            </div>
            <div class="value">${series[2][dataPointIndex] || 0}</div>
          </div>
          <div class="row">
            <div class="row-left">
              <div class="dot dot-booked"></div>
              <div class="title">Booked</div>
            </div>
            <div class="value">${series[3][dataPointIndex] || 0}</div>
          </div>
          <div class="row">
            <div class="row-left">
              <div class="dot dot-inactive"></div>
              <div class="title">Inactive</div>
            </div>
            <div class="value">${series[4][dataPointIndex] || 0}</div>
          </div>
          <div class="row">
            <div class="row-left">
              <div class="dot dot-proposed"></div>
              <div class="title">Proposed</div>
            </div>
            <div class="value">${series[5][dataPointIndex] || 0}</div>
          </div>
        </div>
      `;
    },
    y: {
      formatter: shortNumber,
    },
  },
  fill: {
    opacity: 1,
  },
  legend: {
    show: true,
    horizontalAlign: 'left',
  },
});
