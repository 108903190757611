import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';

// components
import { Grid } from '@mui/material';
import {
  fontWeight,
  Heading,
  headingLevel,
  Input,
  inputSize,
  inputType,
  Link,
  RadioGroup,
  radioGroupType,
  Select,
  selectType,
  Text,
  TextError,
  textLevel,
  Tooltip,
} from '../../../../../packages';
import { Drawer, drawerPosition, drawerType } from '../../../../../packages/ui/organisms/drawer';

import { getProfile } from '../../../../../redux/modules/profile/selectors';
import { getAuth } from '../../../../../redux/modules/auth/selectors';
import { profileActionCreators } from '../../../../../redux/modules/profile';
import { GLOBAL_DATE_FORMAT } from '../../../../../constants/CommonConstants';
import DatePickerInput from '../../../../admin/claim-detail/components/DatePicker';

// styles
import { useStyles } from '../../MemberDetail.styles';

const PersonalInformation = ({ memberId }) => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [formValid, setFormValid] = useState(true);
  const { selectedPatient = {}, demographicDetails = {}, demographicMetadata = {} } = useSelector(getProfile);
  const { isAdmin } = useSelector(getAuth);
  const [statusList] = useState([
    { label: 'Active', value: 'Active' },
    { label: 'Discharged to HLOC', value: 'Discharged to HLOC' },
    { label: 'DIY', value: 'DIY' },
    { label: 'Ghost', value: 'Ghost' },
    { label: 'Lost to Care', value: 'Lost to Care' },
    { label: 'New Member', value: 'New member' },
    { label: 'Not Admitted to Care', value: 'Not admitted to care' },
    { label: 'Referral', value: 'Referral' },
    { label: 'Referral Active', value: 'Referral Active' },
    { label: 'Self Discharge', value: 'Self Discharge' },
    { label: 'Successful Discharge', value: 'Successful Discharge' },
  ]);
  const classes = useStyles({});
  const [errors, setErrors] = useState({ nickName: null, dateOfBirth: null });
  const profile = useSelector(getProfile);
  const [invalidDate, setInvalidDate] = useState(false);
  const [formData, setFormData] = useState({
    firstName: '', // api connected
    lastName: '', // api connected
    nickName: '',
    dateOfBirth: '',
    genderIdentity: '',
    genderPronoun: '',
    gender: '',
    levelOfEngagementStatus: '',
    levelOfEngagementSubStatus: '',
    primaryInterests: [],
    isActiveChatGroup: false,
    referralSources: [],
    referralPartners: [],
    eprescribeEntry: false,
  });

  const dispatch = useDispatch();

  const getFormatQueryParams = () => {
    return {
      pageNumber: 0,
      pageSize: 10000,
      search: '',
    };
  };
  const referralPartnerOptions = useMemo(
    () =>
      profile?.referralPartners?.partners?.map(rPartners => {
        return {
          refPartnerName: rPartners.name,
          refPartnerId: rPartners.refPartnerId,
        };
      }) || [],
    [profile?.referralPartners?.partners],
  );

  const fetchReferralPartnersCall = () => {
    dispatch(profileActionCreators.fetchReferralPartners(getFormatQueryParams()));
  };

  useEffect(() => {
    dispatch(profileActionCreators.fetchDemographicMetadata());
    fetchReferralPartnersCall();
  }, []);
  useEffect(() => {
    setFormData({
      firstName: demographicDetails.firstName || '',
      lastName: demographicDetails.lastName || '',
      nickName: demographicDetails.nickName || '',
      dateOfBirth:
        demographicDetails.dateOfBirth && demographicDetails.dateOfBirth !== ''
          ? dayjs(demographicDetails.dateOfBirth).toISOString()
          : '',
      genderIdentity: demographicDetails.genderIdentity || 'Male',
      gender: demographicDetails.sex || 'Male',
      genderPronoun: demographicDetails.genderPronoun || '',
      levelOfEngagementStatus: demographicDetails?.levelOfEngagementStatus || '',
      levelOfEngagementSubStatus: '',
      isActiveChatGroup: demographicDetails.isActiveChat || false,
      primaryInterests: demographicDetails.onboardingGoals?.map(goal => ({ title: goal, value: goal })) || [],
      referralSources: demographicDetails.referralSources?.map(goal => ({ title: goal, value: goal })) || [],
      referralPartners:
        demographicDetails.referralPartners?.map(item => {
          if (item.value) {
            return item.value;
          }
          return item;
        }) || [],
      eprescribeEntry: demographicDetails.eprescribeEntry || false,
    });
  }, [selectedPatient, demographicDetails]);
  const handleOpenDrawer = e => {
    e.preventDefault();
    setOpenDrawer(true);
  };
  const handleCloseDrawer = () => setOpenDrawer(false);

  useEffect(() => {
    if (Object.keys(selectedPatient)?.length !== 0 && Object.keys(demographicDetails)?.length !== 0) {
      selectedPatient.member.firstName = demographicDetails.firstName;
      selectedPatient.member.lastName = demographicDetails.lastName;
      selectedPatient.member.nickName = demographicDetails.nickName;
      selectedPatient.dob = demographicDetails.dateOfBirth;
      dispatch(profileActionCreators.selectedPatient(selectedPatient));
    }
  }, [demographicDetails]);

  const onChangeField = field => e => {
    if (errors[field]) {
      setErrors(s => ({ ...s, [field]: null }));
    }
    setFormData(old => ({
      ...old,
      [field]: !isAdmin && field === 'isActiveChatGroup' ? !old.isActiveChatGroup : e.target?.value,
    }));
  };

  useEffect(() => {
    if (formData) {
      if (
        formData.firstName.trim()?.length === 0 &&
        formData.lastName.trim()?.length === 0 &&
        formData.nickName.trim()?.length === 0
      ) {
        setErrors({
          ...errors,
          nickName: 'Either First, Last, or Preferred name is required',
        });
        setFormValid(false);
      } else {
        setFormValid(!invalidDate);
        setErrors({
          ...errors,
          nickName: null,
        });
      }
    }
  }, [formData, invalidDate]);
  const onChangeDateOfBirth = date => {
    if (date?.isValid()) {
      if (date?.isBefore(dayjs())) {
        setFormData({ ...formData, dateOfBirth: date.toISOString() });
        setFormValid(true);
        setErrors({ ...errors, dateOfBirth: null });
        setInvalidDate(false);
      } else {
        setErrors({ ...errors, dateOfBirth: 'Invalid date of birth' });
        setFormValid(false);
        setInvalidDate(true);
      }
    } else {
      setFormData({ ...formData, dateOfBirth: '' });
      setFormValid(true);
      setErrors({ ...errors, dateOfBirth: null });
      setInvalidDate(false);
    }
  };
  const onChangeLevelOfEngagementStatus = e => {
    setFormData(old => ({
      ...old,
      levelOfEngagementStatus: e.target.value,
    }));
  };

  const onChangePrimaryInterests = (e, values, reason) => {
    if (reason === 'removeOption') {
      setFormData(old => ({
        ...old,
        primaryInterests: values,
      }));
      return;
    }
    const value = e.target.innerText;
    const isExisted = formData.primaryInterests.some(item => item.value === value);
    if (!isExisted) {
      setFormData(old => ({
        ...old,
        primaryInterests: [...old.primaryInterests, { title: value, value }],
      }));
    }
  };

  const onChangeReferralSources = (e, values, reason) => {
    if (reason === 'removeOption') {
      const removedReferralSource = formData.referralSources.find(
        item => !values.some(option => option.value === item.value),
      );
      const filteredReferralPartners = formData.referralPartners.filter(
        item => item.refPartnerName !== removedReferralSource.value,
      );
      setFormData(old => ({
        ...old,
        referralSources: values,
        referralPartners: filteredReferralPartners,
      }));
      return;
    }
    const value = e.target.innerText;
    const isExisted = formData.referralSources.some(item => item.value === value);
    if (!isExisted) {
      setFormData(old => ({
        ...old,
        referralSources: [...old.referralSources, { title: value, value }],
      }));
    }
    onChangeReferralPartner(value);
  };
  const handleOnSubmit = () => {
    if (
      formData.firstName.trim()?.length === 0 &&
      formData.lastName.trim()?.length === 0 &&
      formData.nickName.trim()?.length === 0
    ) {
      setErrors({
        ...errors,
        nickName: 'Either First, Last, or Preferred name is required',
      });
      setFormValid(false);
      return;
    }
    dispatch(
      profileActionCreators.updateDemographicDetails({
        demographicDetails: {
          ...formData,
          fullName: formData.nickName,
          dateOfBirth: formData.dateOfBirth !== '' ? dayjs(formData.dateOfBirth).format('YYYY-MM-DD') : '',
          gender: formData.genderIdentity,
          primaryInterests: formData.primaryInterests.map(({ value }) => value),
          referralSources:
            formData.referralSources?.length !== 0 ? formData.referralSources.map(({ value }) => value) : [],
          referralPartners:
            formData.referralPartners?.length !== 0
              ? formData.referralPartners?.map(({ refPartnerId }) => refPartnerId)
              : [],
          mobilePhone: demographicDetails?.mobilePhone,
        },
        memberId,
      }),
    );
    handleCloseDrawer();
  };
  const onChangeReferralPartner = value => {
    const matchingReferralSource = referralPartnerOptions.find(item => item.refPartnerName === value);
    if (matchingReferralSource) {
      const isExisted = formData.referralPartners.some(
        item => item.refPartnerName === matchingReferralSource.refPartnerName,
      );
      if (!isExisted) {
        setFormData(old => ({
          ...old,
          referralPartners: [...formData.referralPartners, matchingReferralSource], // { title: value, value }],
        }));
      }
      // setSelectedReferralPartners(value);}
    }
  };

  const renderInfo = (label, text) => {
    return (
      <Grid item sm={1} xs={3} className={classes.preLineText}>
        <div className={classes.fieldContainer}>
          <Text className={classes.textLight} level={textLevel.S} weight={fontWeight.SEMI_BOLD}>
            {label}
          </Text>
          <Text className={classes.fieldValue} level={textLevel.M} weight={fontWeight.MEDIUM}>
            {text?.length > 15 ? (
              <Tooltip title={text} placement="top-start">
                <span className={classes.fieldValue2}>{text}</span>
              </Tooltip>
            ) : (
              <span>{text}</span>
            )}
          </Text>
        </div>
      </Grid>
    );
  };

  return (
    <div className={classes.card}>
      <div className={classes.cardTopSection}>
        <Heading level={headingLevel.S} weight={fontWeight.BOLD}>
          Personal information
        </Heading>
        <Link onClick={handleOpenDrawer}>Edit personal information</Link>
      </div>
      <Grid className={classes.cardContentSection} container columns={4} rowSpacing="32px" spacing={2}>
        {renderInfo('UID', demographicDetails.uuid)}
        {renderInfo(
          'Date of birth',
          demographicDetails.dateOfBirth
            ? `${dayjs(demographicDetails.dateOfBirth, 'YYYY-MM-DD').format(
                GLOBAL_DATE_FORMAT,
              )} (${dayjs().diff(demographicDetails.dateOfBirth, 'y')} y.o)`
            : '-',
        )}
        {renderInfo('Preferred Name', demographicDetails.nickName)}
        <Grid item sm={1} xs={3} className={classes.preLineText} />
        {renderInfo('Sex', demographicDetails.sex ? `${demographicDetails.sex}` : '')}
        {renderInfo(
          'Pronouns',
          demographicDetails.genderPronoun ? `(${demographicDetails.genderPronoun})` : 'N/A',
        )}
        <Grid item sm={1} xs={3} className={classes.preLineText} />
        <Grid item sm={1} xs={3} className={classes.preLineText} />
        {renderInfo('Added to treat', demographicDetails.eprescribeEntry ? 'Yes' : 'No')}
        {renderInfo(
          'Primary interests',
          demographicDetails.onboardingGoals?.length > 0
            ? demographicDetails.onboardingGoals?.join(', ')
            : 'None',
        )}
        {renderInfo(
          'Referral source',
          demographicDetails.referralSources?.length > 0
            ? demographicDetails.referralSources?.join(', ')
            : 'None',
        )}
        {renderInfo(
          'Level of engagement',
          Object.keys(demographicDetails)?.length === 0
            ? '-'
            : `${
                demographicDetails?.levelOfEngagementStatus !== null
                  ? demographicDetails?.levelOfEngagementStatus
                  : '-'
              },${
                demographicDetails?.levelOfEngagementSubStatus !== null
                  ? demographicDetails?.levelOfEngagementSubStatus
                  : '-'
              }`,
        )}
      </Grid>

      <Drawer
        variant={drawerType.FORM}
        position={drawerPosition.RIGHT}
        open={openDrawer}
        onClose={handleCloseDrawer}
        title="Edit personal information"
        submitBtnTitle="update"
        disableSubmit={!formValid}
        onSubmit={handleOnSubmit}
      >
        <div className={classes.inputsContainer}>
          <div className={classes.inputWrapper}>
            <label htmlFor="first__name">
              <Text level={textLevel.S} weight={fontWeight.BOLD}>
                First name
              </Text>
            </label>
            <Input
              name="firstName"
              value={formData.firstName || ''}
              onChange={onChangeField('firstName')}
              size={inputSize.M}
              placeholder="Enter your first name"
            />
          </div>
          <div className={classes.inputWrapper}>
            <label htmlFor="last__name">
              <Text level={textLevel.S} weight={fontWeight.BOLD}>
                Last name
              </Text>
            </label>
            <Input
              value={formData.lastName || ''}
              name="lastName"
              size={inputSize.M}
              onChange={onChangeField('lastName')}
              placeholder="Enter your last name"
            />
          </div>
          <div className={classes.inputWrapper}>
            <label htmlFor="first__name">
              <Text level={textLevel.S} weight={fontWeight.BOLD}>
                Preferred name
              </Text>
            </label>
            <Input
              name="fullName"
              value={formData.nickName || ''}
              onChange={onChangeField('nickName')}
              size={inputSize.M}
              placeholder="Enter your preferred name"
            />
            <TextError errorMsg={errors.nickName} />
          </div>
          <div className={classes.inputWrapper}>
            <label htmlFor="date__of__birth">
              <Text level={textLevel.S} weight={fontWeight.BOLD}>
                <span>Date of birth</span>
              </Text>
            </label>
            <DatePickerInput date={dayjs(formData.dateOfBirth)} onChange={onChangeDateOfBirth} />
            <TextError errorMsg={errors.dateOfBirth} />
          </div>
          <div className={classes.inputWrapper}>
            <Text className="label" level={textLevel.S} weight={fontWeight.BOLD}>
              Sex
            </Text>
            <RadioGroup
              value={formData.genderIdentity}
              variant={radioGroupType.HORIZONTAL}
              onChange={onChangeField('genderIdentity')}
              options={demographicMetadata.gender.map(gender => ({ label: gender, value: gender }))}
            />
          </div>
          <div className={classes.inputWrapper}>
            <Text className="label" level={textLevel.S} weight={fontWeight.BOLD}>
              <span>Pronouns</span>
            </Text>
            <Select
              variant={selectType.SECONDARY}
              value={formData.genderPronoun}
              onChange={onChangeField('genderPronoun')}
              options={demographicMetadata.genderPronouns.map(pronoun => ({
                label: pronoun,
                value: pronoun,
              }))}
            />
          </div>
          <div className={classes.inputWrapper}>
            <Text className="label" level={textLevel.S} weight={fontWeight.BOLD}>
              <span>Level of Engagement</span>
            </Text>
            <Select
              variant={selectType.SECONDARY}
              options={statusList}
              onChange={onChangeLevelOfEngagementStatus}
              value={formData.levelOfEngagementStatus}
            />
            {/* {(formData.levelOfEngagementStatus === 'Diy' ||
              formData.levelOfEngagementStatus === 'Active') && (
              <Select
                variant={selectType.SECONDARY}
                options={formData.levelOfEngagementStatus === 'Diy' ? subStatusDiyList : subStatusActiveList}
                onChange={onChangeLevelOfEngagementSubStatus}
                value={formData.levelOfEngagementSubStatus}
              />
            )} */}
          </div>
          <div className={classes.inputWrapper}>
            <label htmlFor="multi__reward">
              <Text level={textLevel.S} weight={fontWeight.BOLD}>
                <span>Primary interests</span>
              </Text>
            </label>
            <Input
              variant={inputType.TAGS}
              name="onboardingGoals"
              onChange={onChangePrimaryInterests}
              size={inputSize.M}
              value={formData.primaryInterests}
              options={demographicMetadata.primaryInterests.sort().map(interest => ({
                title: interest,
                value: interest,
              }))}
            />
          </div>
          <div className={classes.inputWrapper}>
            <label htmlFor="date__of__birth">
              <Text level={textLevel.S} weight={fontWeight.BOLD}>
                <span>Referral Sources</span>
              </Text>
            </label>
            <Input
              value={formData.referralSources}
              // placeholder="Select R"
              name="referralSources"
              isOptionEqualToValue={(option, value) => option.value === value.value}
              // className={classes.valuesInput}
              options={demographicMetadata.referralSources.sort().map(source => ({
                title: source,
                value: source,
              }))}
              onChange={onChangeReferralSources}
              variant={inputType.TAGS}
              size={inputSize.M}
              // onBlur={setTouched('serviceTypes')}
              // fullWidth
            />
          </div>
          <div className={classes.inputWrapper}>
            <Text className="label" level={textLevel.S} weight={fontWeight.BOLD}>
              Add to treat
            </Text>
            <RadioGroup
              value={formData.eprescribeEntry ? 'Yes' : 'No'}
              variant={radioGroupType.HORIZONTAL}
              onChange={e => setFormData({ ...formData, eprescribeEntry: e.target.value === 'Yes' })}
              options={[
                { label: 'Yes', value: 'Yes' },
                { label: 'No', value: 'No' },
              ]}
            />
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default PersonalInformation;
