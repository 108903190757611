import * as React from 'react';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

export default function DatePickerInput({ date, onChange, disabled, disableFuture = true }) {
  const [value, setValue] = React.useState(!date || date?.toString() === 'Invalid Date' ? null : date);
  React.useEffect(() => {
    if (date || date?.toString() !== 'Invalid Date') {
      setValue(date);
    }
  }, [date]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        value={value}
        disabled={disabled}
        onChange={newValue => {
          setValue(newValue);
          onChange(newValue);
        }}
        disableFuture={disableFuture}
        renderInput={params => (
          <TextField
            {...params}
            error={params?.error}
            InputProps={{
              ...params.InputProps,
              readOnly: true,
              onKeyDown: e => {
                e.preventDefault();
              },
            }}
          />
        )}
      />
    </LocalizationProvider>
  );
}
