/* eslint-disable max-len */
import { colors } from '../../../../../../../packages/colors';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  container: {
    flex: 1,
    position: 'relative',
    zIndex: 1,
    backgroundColor: colors.white,
    boxShadow:
      '0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
  },
  helperText: {
    fontFamily: 'Manrope',
    fontWeight: '500',
    fontSize: 14,
    lineHeight: '20px',
  },
  header: {
    height: 80,
    padding: '16px 16px 16px 24px',
    backgroundColor: colors.neutral25,
    display: 'flex',
    alignItems: 'center',
    boxShadow: 'inset 0px -1px 0px #EDF1F5',
  },
  body: {
    height: 'calc(100vh - 250px)',
    padding: 32,
    backgroundColor: colors.white,
    overflowY: 'auto',
  },
  backBtn: {
    width: 40,
    height: 40,
    minWidth: 40,
    borderRadius: 6,
    border: `1px solid ${colors.primary300}`,
    marginRight: 16,
    '& svg': {
      width: 10,
    },
  },
  title: {
    fontSize: 18,
    lineHeight: '24px',
    margin: 0,
    marginBottom: 4,
  },
  subtitle: {
    fontSize: 14,
    lineHeight: '20px',
    margin: 0,
    color: colors.neutral600,
  },
  info: {
    borderRadius: 6,
    backgroundColor: colors.primary50,
    padding: '19px 18px',
    marginTop: 32,
    display: 'flex',
    gap: 18,
    cursor: 'pointer',
  },
  infoTitle: {
    fontSize: 16,
    lineHeight: '24px',
    color: colors.primary600,
    margin: 0,
  },
  titleDetail: {
    color: colors.neutral900,
  },
  infoSubtitle: {
    fontSize: 14,
    lineHeight: '20px',
    color: colors.neutral500,
    margin: 0,
  },
  text: {
    display: 'block',
    fontSize: 14,
    color: colors.neutral700,
    fontWeight: 500,
    margin: 0,
    lineHeight: '20px',
  },
});
