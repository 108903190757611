import React from 'react';
import cx from 'clsx';
import { fontWeight, Heading, headingLevel, Text, textLevel } from '../../../../../packages';
import { Drawer, drawerType } from '../../../../../packages/ui/organisms/drawer';
import { NoteCardBottom } from '../../../../../packages/ui/templates/note-card';
import { Badge, badgeStyle, badgeType } from '../../../../../packages';
import { colors } from '../../../../../packages';
import { Icons } from '../../../../../packages';
import { CONTACT_NOTES_FLAGS, NoteStyle } from '../../../../../packages/constants/CommonConstants';
import { NOTES_STATUS_CATEGORIES } from '../../../../admin/notes/NotesList.constants';
import { AttachmentCard } from './AttachmentCard';
import { useStyles } from './NoteDrawer.styles';

import { getSpecialStatus } from './AddNoteDrawer/AddNoteForm';

const NoteDrawer = ({ selectedNote, onClose, onUpdateNote }) => {
  const classes = useStyles({ status: selectedNote?.type, marginLeft: selectedNote?.name?.length <= 7 });

  const onClickUpdate = () => {
    onUpdateNote(selectedNote);
  };
  const getIcon = type => {
    const status = NoteStyle[type] || NoteStyle.GENERAL;
    switch (type) {
      case CONTACT_NOTES_FLAGS.PROHIBITIVE:
        return (
          <div className={cx(classes.iconWrapper, type)}>
            <Icons color={colors[`${status}500`]} glyph="close-circle-outlined" />
          </div>
        );
      case CONTACT_NOTES_FLAGS.CAUTION:
        return (
          <div className={cx(classes.iconWrapper, type)}>
            <Icons color={colors[`${status}500`]} glyph="flag-outlined" />
          </div>
        );
      case CONTACT_NOTES_FLAGS.RELEASE_OF_INFORMATION:
        return (
          <div className={cx(classes.iconWrapper, type)}>
            <Icons color={colors[`${status}500`]} glyph="info-outlined" />
          </div>
        );
      case CONTACT_NOTES_FLAGS.LAB_REQUEST:
        return (
          <div className={cx(classes.iconWrapper, type)}>
            <Icons color={colors[`${status}500`]} glyph="lab-bottle-outlined" />
          </div>
        );
      case CONTACT_NOTES_FLAGS.CONTACT:
        return (
          <div className={cx(classes.iconWrapper, type)}>
            <Icons color={colors[`${status}500`]} glyph="user-report-outlined" />
          </div>
        );
      case CONTACT_NOTES_FLAGS.REFERRAL_OUT:
        return (
          <div className={cx(classes.iconWrapper, type)}>
            <Icons color={colors.orange500} glyph="profile-arrow-left-outlined" />
          </div>
        );
      case CONTACT_NOTES_FLAGS.PHARMACY_ASSISTANCE:
        return (
          <div className={cx(classes.iconWrapper, type)}>
            <Icons color={colors.cyan500} glyph="pharmacy-outlined" />
          </div>
        );
      case CONTACT_NOTES_FLAGS.PRIOR_AUTHORIZATION:
        return (
          <div className={cx(classes.iconWrapper, type)}>
            <Icons color={colors.primary500} glyph="shield-outlined" />
          </div>
        );
      default:
        return (
          <div className={cx(classes.iconWrapper, type)}>
            <Icons color={colors[`${status}500`]} glyph="report-outlined" />
          </div>
        );
    }
  };
  const isReleaseNote = type => {
    return type && type === CONTACT_NOTES_FLAGS.RELEASE_OF_INFORMATION;
  };
  const renderLabelValue = (label, value, isLink = false) => {
    return (
      <div className={classes.valueLabelWrapper}>
        <Text level={textLevel.M} weight={fontWeight.BOLD}>
          {label}
        </Text>
        <Text level={textLevel.M}>
          {isLink ? (
            <a
              className={classes.noTextDecoration}
              href={value.includes('http') ? value : `https://${value}`}
              target="_blank"
              rel="noreferrer"
            >
              {value}
            </a>
          ) : (
            value
          )}
        </Text>
      </div>
    );
  };
  return (
    <Drawer
      title={selectedNote?.name}
      titleIconComponent={getIcon(selectedNote?.type)}
      subTitle={selectedNote?.type}
      open={!!selectedNote}
      onClose={onClose}
      variant={drawerType.FORM}
      submitBtnTitle="Edit"
      onSubmit={onClickUpdate}
      className={classes.drawer}
      titleWidth="550px"
      subtitleComponent={
        selectedNote?.status ? (
          <div className={classes.statusBadge}>
            <Badge
              variant={
                getSpecialStatus(selectedNote?.status) === NOTES_STATUS_CATEGORIES.NEEDS_ACTION
                  ? badgeType.OUTLINED
                  : badgeType.FILLED
              }
              style={badgeStyle.DARK}
              className={classes.status}
            >
              {selectedNote?.status}
            </Badge>
          </div>
        ) : (
          ''
        )
      }
    >
      {selectedNote && (
        <React.Fragment>
          <NoteCardBottom
            type="provider"
            note={{ ...selectedNote, showTarget: false }}
            className={classes.noteBottomCard}
          />
          <div className={classes.noteTexts}>
            {selectedNote.target && (
              <Heading className={classes.noteTarget} level={headingLevel.S}>
                {selectedNote.target}
              </Heading>
            )}
            <div className={classes.verticalBlock}>
              {isReleaseNote(selectedNote?.type) &&
                selectedNote.typeOfInformationForRelease &&
                renderLabelValue('Type of information to release', selectedNote.typeOfInformationForRelease)}
              {isReleaseNote(selectedNote?.type) &&
                selectedNote.additionalInformationForRelease &&
                renderLabelValue(
                  'Additional information to release',
                  selectedNote.additionalInformationForRelease,
                )}
              {isReleaseNote(selectedNote?.type) &&
                selectedNote.rationaleReasonForRelease &&
                renderLabelValue('Rational/reason to release', selectedNote.rationaleReasonForRelease)}
              {selectedNote.description && (
                <Text
                  sx={{ lineHeight: '1.25rem !important' }}
                  level={textLevel.M}
                  className={classes.description}
                >
                  {selectedNote.description}
                </Text>
              )}
            </div>
            <div className={classes.verticalBlockTopMargin}>
              {isReleaseNote(selectedNote?.type) &&
                selectedNote.signableDocumentLink &&
                renderLabelValue('Signable Document Link', selectedNote.signableDocumentLink, true)}
            </div>
          </div>
          <div className={classes.downloadWrap}>
            {selectedNote.attachments?.map((attachment, index) => (
              <AttachmentCard key={index} file={attachment} />
            ))}
          </div>
          <div className={classes.twoColumnsWrapper}>
            <div className={classes.row}>
              {isReleaseNote(selectedNote?.type) && selectedNote?.releaseInformationTo && (
                <div className={classes.column}>
                  <Text level={textLevel.L} weight={fontWeight.BOLD}>
                    Release information to:
                  </Text>
                  {selectedNote?.releaseInformationTo?.nameOfReleaseTarget
                    ? renderLabelValue(
                        'Name of target',
                        selectedNote?.releaseInformationTo?.nameOfReleaseTarget,
                      )
                    : renderLabelValue('Name of target', '-')}
                  {selectedNote?.releaseInformationTo?.relationship
                    ? renderLabelValue('Relationship', selectedNote?.releaseInformationTo?.relationship)
                    : renderLabelValue('Relationship', '-')}
                  {selectedNote?.releaseInformationTo?.address1
                    ? renderLabelValue('Address 1', selectedNote?.releaseInformationTo?.address1)
                    : renderLabelValue('Address 1', '-')}
                  {selectedNote?.releaseInformationTo?.address2
                    ? renderLabelValue('Address 2', selectedNote?.releaseInformationTo?.address2)
                    : renderLabelValue('Address 2', '-')}
                  {selectedNote?.releaseInformationTo?.state
                    ? renderLabelValue('State', selectedNote?.releaseInformationTo?.state)
                    : renderLabelValue('State', '-')}
                  {selectedNote?.releaseInformationTo?.city
                    ? renderLabelValue('City', selectedNote?.releaseInformationTo?.city)
                    : renderLabelValue('City', '-')}
                  {selectedNote?.releaseInformationTo?.zip
                    ? renderLabelValue('Zip', selectedNote?.releaseInformationTo?.zip)
                    : renderLabelValue('Zip', '-')}
                  {selectedNote?.releaseInformationTo?.phone
                    ? renderLabelValue('Phone', selectedNote?.releaseInformationTo?.phone)
                    : renderLabelValue('Phone', '-')}
                </div>
              )}
              {isReleaseNote(selectedNote?.type) && selectedNote?.obtainInformationFrom && (
                <div className={classes.column}>
                  <Text level={textLevel.L} weight={fontWeight.BOLD}>
                    Obtain information from:
                  </Text>
                  {selectedNote?.obtainInformationFrom?.nameOfReleaseTarget
                    ? renderLabelValue(
                        'Name of target',
                        selectedNote?.obtainInformationFrom?.nameOfReleaseTarget,
                      )
                    : renderLabelValue('Name of target', '-')}
                  {selectedNote?.obtainInformationFrom?.relationship
                    ? renderLabelValue('Relationship', selectedNote?.obtainInformationFrom?.relationship)
                    : renderLabelValue('Relationship', '-')}
                  {selectedNote?.obtainInformationFrom?.address1
                    ? renderLabelValue('Address 1', selectedNote?.obtainInformationFrom?.address1)
                    : renderLabelValue('Address 1', '-')}
                  {selectedNote?.obtainInformationFrom?.address2
                    ? renderLabelValue('Address 2', selectedNote?.obtainInformationFrom?.address2)
                    : renderLabelValue('Address 2', '-')}
                  {selectedNote?.obtainInformationFrom?.state
                    ? renderLabelValue('State', selectedNote?.obtainInformationFrom?.state)
                    : renderLabelValue('State', '-')}
                  {selectedNote?.obtainInformationFrom?.city
                    ? renderLabelValue('City', selectedNote?.obtainInformationFrom?.city)
                    : renderLabelValue('City', '-')}
                  {selectedNote?.obtainInformationFrom?.zip
                    ? renderLabelValue('Zip', selectedNote?.obtainInformationFrom?.zip)
                    : renderLabelValue('Zip', '-')}
                  {selectedNote?.obtainInformationFrom?.phone
                    ? renderLabelValue('Phone', selectedNote?.obtainInformationFrom?.phone)
                    : renderLabelValue('Phone', '-')}
                </div>
              )}
            </div>
          </div>
        </React.Fragment>
      )}
    </Drawer>
  );
};

export default NoteDrawer;
