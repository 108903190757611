import { useState } from 'react';
import ApexChart from 'react-apexcharts';
import { Box } from '@mui/material';
import clsx from 'clsx';

import { Heading, Menu, Text, textLevel } from '../../../../../packages';

import { AppointmentsFilterDates } from '../../../../../constants/CommonConstants';
import { getChartOptions } from './AssignmentsChart.config';
import { useStyles } from './AssignmentsChart.styles';

const AssignmentsChart = ({ title, className, chart, showHeader = true }) => {
  const classes = useStyles();
  const { series, tooltipConfig, showLegend = true, ...rest } = chart;
  const [selectedTime, setSelectedTime] = useState(AppointmentsFilterDates[2]);

  const times = AppointmentsFilterDates.map(time => ({
    ...time,
    onClick: () => setSelectedTime(time),
  }));

  return (
    <Box className={clsx(classes.card, className)}>
      {showHeader && (
        <Box className={clsx(classes.head, 'chart-head')}>
          <Heading className={classes.title}>{title}</Heading>
          <Box className={classes.headRight}>
            <Menu
              icon="chevron-down"
              items={times}
              itemsWrapperClassName={classes.menuLabel}
              className={classes.menu}
            >
              <Text className={classes.menuLabel} level={textLevel.S}>
                {selectedTime.label}
              </Text>
            </Menu>
            {/* <Menu
              icon="chevron-down"
              items={statuses}
              itemsWrapperClassName={classes.menuLabel}
              className={classes.menu}
            >
              <Text className={classes.menuLabel} level={textLevel.S}>
                {selectedStatus.label}
              </Text>
            </Menu> */}
          </Box>
        </Box>
      )}
      <Box className={classes.chart}>
        <ApexChart
          options={getChartOptions(series, tooltipConfig, rest)}
          series={series}
          type="line"
          height={330}
        />
        {showLegend && series.length === 1 && (
          <Box className={classes.chartLegend}>
            <Box className={classes.circle} />
            <Text className={classes.legendText}>{series[0].name}</Text>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export { AssignmentsChart };
