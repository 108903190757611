import { makeStyles } from '@mui/styles';

// colors
import { colors } from '../../../../../packages';

export const useStyles = makeStyles({
  card: {
    backgroundColor: colors.white,
    boxShadow:
      '0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
    width: '100%',
    borderRadius: 8,
    overflow: 'hidden',
  },
  donutCharts: {
    display: 'flex',
    padding: 32,
    gap: 32,
    flex: 1,
    paddingTop: 0,
    // display: 'flex',
    // padding: 32,
    // gap: 32,
    // flex: 1,
  },
  donut: {
    border: `1px solid ${colors.neutral100}`,
    borderRadius: 8,
    padding: 24,
    flex: 1,
    width: '100%',
    // border: `1px solid ${colors.neutral100}`,
    // borderRadius: 8,
    // padding: 15,
    // flex: 1,
    // width: '80%',
    // '& $breakdownChart .apexcharts-inner.apexcharts-graphical': {
    //   transform: 'translate(-10px, 15px) !important',
    // },
  },
  donut1: {
    border: `1px solid ${colors.neutral100}`,
    borderRadius: 8,
    padding: 24,
    flex: 1,
    width: '100%',
    // border: `1px solid ${colors.neutral100}`,
    // borderRadius: 8,
    // padding: 15,
    // flex: 1,
    // '& $breakdownChart .apexcharts-inner.apexcharts-graphical': {
    //   transform: 'translate(-6px, 15px) !important',
    // },
  },
  breakdownChart: {
    left: 0,
    // height: '100%',
    // width: '100%',
  },
  chartWrap: {
    padding: '0 24px 0',
    '& > div': {
      '& div:last-child': {
        padding: 0,
      },
    },
  },
  chart: {
    padding: 0,
    borderRadius: 0,
    border: 'none !important',
    boxShadow: 'none !important',
    '& .chart-head': {
      padding: '30px 12px',
      borderBottom: 'none',
      position: 'relative',
      '&:before': {
        content: "''",
        position: 'absolute',
        bottom: 0,
        width: 'calc(100% - 24px)',
        height: 1,
        backgroundColor: colors.neutral100,
      },
    },
  },
  tableWrap: {
    '&:first-child': {
      padding: '8px 0 32px 32px',
    },
    '&:last-child': {
      padding: '8px 32px 32px 0',
    },
  },
  fullTable: {
    '&:last-child': {
      padding: '0 32px 32px 32px',
    },
  },
  tableBordered: {
    border: `1px solid ${colors.neutral100}`,
    borderBottom: 'none',
    borderRadius: 8,
    overflow: 'hidden',
    marginTop: 24,
    '& td.color-secondary-500': {
      color: colors.secondary500,
    },
  },
  recentTitle: {
    fontSize: 18,
    lineHeight: '24px',
    margin: 0,
  },
  viewAllBtn: {
    fontSize: 14,
    lineHeight: '20px',
    fontWeight: 700,
  },
});
