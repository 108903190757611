import cx from 'clsx';
import Box from '@mui/material/Box';
import { Badge, badgeStyle, badgeType } from '../../atoms/badge';
import { fontWeight, Heading, headingLevel, Text } from '../../atoms/typography';
import { Rating } from '../../atoms/rating';
import { Icons } from '../../../icons';
import { Avatar, avatarType } from '../../atoms/avatar';
// styles
import { useStyles } from './DashboardBox.styles';
// types
import { boxType } from './DashboardBox.types';

const boxBadge = {
  connections: badgeStyle.RESOLVED,
  rating: badgeStyle.UNRELATED,
  CANCELLED: badgeStyle.UNRELATED,
  FULFILLED: badgeStyle.RESOLVED,
  NO_SHOW: badgeStyle.CRITICAL,
};

const DashboardBox = ({
  className = '',
  label = undefined,
  value = undefined,
  type = '',
  badge = 0,
  isInvoice = false,
  isIncrease = false,
  isDashBoardTopSection = false,
  totalAppointments = 0,
  showIncrement = false,
  profileImage = '',
  profileName = undefined,
}) => {
  const classes = useStyles();
  const increaseBadge = isIncrease ? badgeStyle.RESOLVED : badgeStyle.CRITICAL;
  const bStyle = isDashBoardTopSection ? badgeStyle.PRIMARY : increaseBadge;
  const invoiceBoxStyle = isInvoice ? classes.invoiceValue : classes.value;
  return (
    <div className={cx(classes.root, className)}>
      <div className={classes.header}>
        <Text className={classes.label}>{label?.replaceAll('_', ' ')}</Text>
        {showIncrement && type !== boxType.TOTAL && isIncrease !== null && (
          <Badge variant={badgeType.OUTLINED} style={bStyle} className={classes.badge}>
            <>
              {type === boxType.CONNECTIONS && !isIncrease ? (
                <span className={classes.dot} />
              ) : (
                <>
                  {isIncrease != null && !isDashBoardTopSection && (
                    <>
                      <Icons
                        glyph="arrow-left"
                        className={cx(isIncrease ? classes.increment : classes.incrementNegative)}
                      />
                    </>
                  )}
                </>
              )}
              {`${badge} ${isDashBoardTopSection ? `of ${totalAppointments}` : ''}`}
            </>
          </Badge>
        )}
      </div>
      <Heading level={headingLevel.L} weight={fontWeight.BOLD} className={invoiceBoxStyle}>
        {value}{' '}
        {type === boxType.RATING && <Rating value={+value} className={classes.rating} showValue={false} />}
        {profileName && (
          <>
            <Box className={classes.avatar}>
              <Avatar size={28} variant={avatarType.CIRCLE} src={profileImage} name={profileName} />
            </Box>
            <Text className={classes.profileName}>{profileName}</Text>
          </>
        )}
      </Heading>
    </div>
  );
};

export { DashboardBox };
