import { Box, Collapse, List, ListItemButton, ListItemText } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
  colors,
  fontWeight,
  iconBtnStyle,
  iconBtnType,
  IconButton,
  Text,
  textLevel,
} from '../../../../packages';
import { useState } from 'react';
import { useStyles } from '../AlfieAnalysisPlayground.styles';

const AlfieAnalysisCollapse = props => {
  const {
    cardInfo: { title, icon, type },
    cardDetails,
    selectedType,
    selectedElement,
    setSelectedElement,
    setSelectedType,
  } = props;

  const classes = useStyles();

  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setSelectedElement(null);
    if (selectedType !== type) setSelectedType(type);
    setOpen(!open);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        paddingX: 3,
        paddingY: 2,
        paddingBottom: 0,
        background: selectedType === type && !selectedElement ? colors.primary50 : colors.white,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
        onClick={handleClick}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, cursor: 'pointer', pb: 2 }}>
          <IconButton
            icon={icon}
            className={classes.infoIconBtn}
            style={iconBtnStyle.PRIMARY}
            variant={iconBtnType.SECONDARY}
          />
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
            <Text level={textLevel.M} weight={fontWeight.SEMI_BOLD}>
              {title}
            </Text>
            <Text level={textLevel.S} weight={fontWeight.MEDIUM} className={classes.subtitle}>
              {cardDetails.length} found in conversation
            </Text>
          </Box>
        </Box>
        {open ? (
          <KeyboardArrowUpIcon sx={{ color: colors.neutral600 }} />
        ) : (
          <KeyboardArrowDownIcon sx={{ color: colors.neutral600 }} />
        )}
      </Box>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding sx={{ marginX: -3 }}>
          {cardDetails?.map((detail, index) => (
            <ListItemButton
              key={index}
              sx={{
                paddingX: 3,
                paddingY: 2,
                background: detail.name === selectedElement?.name ? colors.primary50 : colors.white,
              }}
              onClick={() => {
                if (selectedType !== type) setSelectedType(type);
                setSelectedElement(detail);
              }}
            >
              <ListItemText primary={detail.name} sx={{ '& span': { fontSize: 16, fontWeight: 500 } }} />
            </ListItemButton>
          ))}
        </List>
      </Collapse>
    </Box>
  );
};

export default AlfieAnalysisCollapse;
