import { makeStyles } from '@mui/styles';

// colors
import { colors } from '../../../../../../packages';

export const useStyles = makeStyles({
  card: {
    position: 'relative',
    backgroundColor: colors.white,
    borderRadius: 8,
    width: '100%',
    boxShadow:
      '0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
  },
  content: {
    position: 'relative',
    padding: '32px 56px',
    borderTop: `1px solid ${colors.neutral100}`,
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBottom: 16,
    paddingTop: 16,
    '&:not(:last-child)': {
      borderBottom: `1px solid ${colors.neutral100}`,
    },
  },
  dot: {
    width: 8,
    height: 8,
    borderRadius: '50%',
  },
  value: {
    fontSize: 16,
    lineHeight: '24px',
    margin: 0,
  },
  badge: {
    width: 'fit-content',
    padding: '4px 8px',
    height: 24,
    borderRadius: 20,
    fontSize: 12,
    lineHeight: '16px',
  },
});
