/* eslint-disable max-len */
import cx from 'clsx';

// types
// styles
import { useStyles } from './shared/styles';

const Lock = ({ className, color, onClick }) => {
  const classes = useStyles();
  return (
    <svg
      className={cx({
        [classes.root]: true,
        [className || '']: className,
      })}
      width="16"
      height="18"
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M13.8333 7.33342H14.6667C14.8877 7.33342 15.0996 7.42121 15.2559 7.57749C15.4122 7.73377 15.5 7.94573 15.5 8.16675V16.5001C15.5 16.7211 15.4122 16.9331 15.2559 17.0893C15.0996 17.2456 14.8877 17.3334 14.6667 17.3334H1.33333C1.11232 17.3334 0.900358 17.2456 0.744078 17.0893C0.587797 16.9331 0.5 16.7211 0.5 16.5001V8.16675C0.5 7.94573 0.587797 7.73377 0.744078 7.57749C0.900358 7.42121 1.11232 7.33342 1.33333 7.33342H2.16667V6.50008C2.16667 5.73404 2.31755 4.97549 2.6107 4.26776C2.90386 3.56003 3.33354 2.91697 3.87521 2.37529C4.41689 1.83362 5.05995 1.40394 5.76768 1.11078C6.47541 0.817632 7.23396 0.666748 8 0.666748C8.76604 0.666748 9.52459 0.817632 10.2323 1.11078C10.9401 1.40394 11.5831 1.83362 12.1248 2.37529C12.6665 2.91697 13.0961 3.56003 13.3893 4.26776C13.6825 4.97549 13.8333 5.73404 13.8333 6.50008V7.33342ZM2.16667 9.00008V15.6667H13.8333V9.00008H2.16667ZM7.16667 10.6667H8.83333V14.0001H7.16667V10.6667ZM12.1667 7.33342V6.50008C12.1667 5.39501 11.7277 4.3352 10.9463 3.5538C10.1649 2.7724 9.10507 2.33341 8 2.33341C6.89493 2.33341 5.83512 2.7724 5.05372 3.5538C4.27232 4.3352 3.83333 5.39501 3.83333 6.50008V7.33342H12.1667Z"
        fill={color}
      />
    </svg>
  );
};

export default Lock;
