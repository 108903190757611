import cx from 'clsx';

// mui
import MuiChip from '@mui/material/Chip';

// types
// styles
import { useStyles } from './Chip.styles';

const Chip = ({ className = '', onDelete, children = null, ...rest }) => {
  const classes = useStyles();

  return (
    <MuiChip
      className={cx({
        [classes.root]: true,
        [className || '']: className,
      })}
      label={children}
      onDelete={onDelete}
      {...rest}
    />
  );
};

export { Chip };
