import { Box } from '@mui/material';
import { btnType } from '../../../../packages/ui/atoms/button';
import { Filter, Table } from '../../../../packages/ui/organisms/table';
import { colors } from '../../../../packages/colors';
import { IconButton } from '../../../../packages/ui/molecules/icon-button';

import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getAuth } from '../../../../redux/modules/auth/selectors';
import { getProfile } from '../../../../redux/modules/profile/selectors';
import { profileActionCreators } from '../../../../redux/modules/profile';
import { DomainTypesEnum } from '../../../../constants/CommonConstants';

import Header from '../../../../components/v2/Header';
import Typography from '../../../../components/v2/Typography';
import { typography } from '../../../../components/v2/Typography/index.constant';
import { getAssignedPrioritiesV2 } from '../../../../services/priority/priority.service';
import { PrioritiesCard } from '../../../provider/sessions/priority-list/PriorityList.constants';
import { PriorityDetailDrawer } from '../../../provider/priorities/PriorityDetailDrawer';
import PriorityEmpty from '../../../provider/member-detail/components/PriorityEmpty';
import { getDomainGroup } from '../../../../services/member/member.service';

import ChartBoard from '../ChartBoard';
import AddNewReport from '../add-new-report';
import { stepTitlesDiagnoses } from '../add-new-report/AddNewReport.constants';
import { FactorType } from '../add-factor-drawer/FactorItem';

import { useStyles } from './index.styles';
import {
  DOMAIN_ELEMENT_VARIENT,
  formatDomainData,
  getFilters,
  getMultiSelectFilterOptions,
} from '../member-detail.constents';
import { ProfileInfoRole } from '../../../../components/v2/ProfileInfo';

const columns = [
  { id: 'factor', label: 'Diagnosis', sortable: false },
  { id: 'currentLevel', label: 'Current level', sortable: false },
  { id: 'firstLevel', label: 'First level', sortable: false },
  { id: 'context', label: 'Context', sortable: false },
  { id: 'actions', label: '', sortable: false },
];

const Diagnoses = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { memberId } = useParams();
  const { meta, isAdmin } = useSelector(getAuth);
  const { profile } = useSelector(getProfile);
  const typeName = PrioritiesCard.Diagnoses;
  const [domainData, setDomainData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [domainOptions, setDomainOptions] = useState([]);
  const [optionsLoading, setOptionsLoading] = useState(false);

  const [value, setValue] = useState({
    search: {
      searchKey: '',
    },
    pagination: {
      currentPage: 1,
      rowsPerPage: 10,
    },
    sorter: {
      direction: 'asc',
      column: '',
    },
  });

  const [selectedItem, setSelectedItem] = useState({});
  const [selectedType, setSelectedType] = useState(null);
  const [reset, setReset] = useState(false);
  const { lookupData, domainTypes } = useSelector(getProfile);

  useEffect(() => {
    setIsLoading(true);
    getAssignedPrioritiesV2({
      patientId: memberId,
      dataDomainType: typeName,
      pageNumber: value.pagination.currentPage - 1,
      pageSize: value.pagination.rowsPerPage,
      importanceLevels: getFilters(value)?.importanceLevels,
      searchQuery: value.search.searchKey ?? '',
    })
      .then(({ data }) => {
        setDomainData(data);
        setIsLoading(false);
      })
      .catch(e => {
        console.error(e);
        setIsLoading(false);
      });
  }, [value, reset]);

  const fetchDomainForm = typeId => {
    getDomainGroup({ domainTypeId: typeId })
      .then(({ data }) => {
        const opts = data.map(item => ({
          label: item.name,
          groupId: item.Id,
          items: item.relatedElements.map(element => ({ label: element.name, value: element.Id })),
          relatedElements: item.relatedElements,
        }));
        setDomainOptions(opts);
        setOptionsLoading(false);
      })
      .catch(() => {
        setDomainOptions([]);
        setOptionsLoading(false);
      });
  };

  useEffect(() => {
    const domain = domainTypes.find(item => item.typeName === DomainTypesEnum.DIAGNOSES);
    if (domain) {
      setOptionsLoading(true);
      fetchDomainForm(domain.typeId);
    }
  }, [selectedType, domainTypes]);

  const domainTypeData = useMemo(() => {
    if (domainData?.records?.length > 0) {
      return formatDomainData(domainData, lookupData);
    }
    return { domainElementData: [], domainElementStats: [] };
  }, [domainData, value]);

  const elementsStats = useMemo(() => {
    if (domainData?.records?.length > 0) {
      return formatDomainData(domainData, lookupData)?.domainElementStats;
    }
    return [];
  }, [domainData]);

  const renderColumns = columns.map(column => {
    if (column.id === 'factor') {
      return {
        ...column,
        renderCell: ({ name, count }) => (
          <Box display="flex" flexDirection="column" paddingLeft={2} gap={0.5}>
            <Typography {...typography.body.normal.small.semibold}>{name}</Typography>
            <Typography {...typography.body.normal.small.medium} color={colors.neutral600}>
              {count} updates
            </Typography>
          </Box>
        ),
      };
    }

    if (column.id === 'currentLevel' || column.id === 'firstLevel') {
      return {
        ...column,
        renderCell: ({ level, color, date }) => (
          <Box display="flex" flexDirection="column" paddingLeft={2} gap={0.5}>
            <Typography
              {...typography.body.normal.small.semibold}
              color={
                level === 'High'
                  ? colors.orange600
                  : level === 'Medium'
                  ? colors.warning600
                  : level === 'Critical'
                  ? colors.destructive600
                  : color ?? colors.cyan600
              }
            >
              {level}
            </Typography>
            <Typography {...typography.body.normal.small.medium} color={colors.neutral600}>
              {date}
            </Typography>
          </Box>
        ),
      };
    }

    if (column.id === 'context') {
      return {
        ...column,
        renderCell: context => (
          <Box paddingLeft={2}>
            <Typography {...typography.body.normal.small.medium}>{context}</Typography>
          </Box>
        ),
      };
    }

    if (column.id === 'actions') {
      return {
        ...column,
        renderCell: item => (
          <IconButton
            icon="chevron-right"
            className={classes.actions}
            onClick={() => setSelectedItem(item)}
          />
        ),
      };
    }

    return column;
  });

  const onCloseDrawer = () => {
    setSelectedType(null);
    setReset(!reset);
  };

  const onSubmitNewReport = payload => {
    const data = {
      patientId: memberId,
      tagItems: payload.map(item => ({
        assignedBy: profile.fullName || meta.nickName,
        domainElementId: item.item.value,
        selectedIct10code: item.icd10,
        metaData: {
          interferenceInLife: item.isInterference,
          notes: item.notes,
          selectedImportance: item.status,
        },
      })),
    };
    dispatch(
      profileActionCreators.createNewAssociatedTag({
        data,
        callback: errorMsg => {
          if (!errorMsg) {
            onCloseDrawer();
          }
        },
      }),
    );
  };

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
        <Header
          label="Diagnoses"
          actions={
            (profile.designation === ProfileInfoRole.NURSE_PRACTITIONER ||
              profile.designation === ProfileInfoRole.THERAPIST ||
              profile.designation === ProfileInfoRole.PHYSICIAN ||
              isAdmin) && [
              {
                children: <Typography {...typography.body.normal.medium.bold}>New diagnosis</Typography>,
                variant: btnType.PRIMARY,
                className: classes.primaryBtn,
                icon: 'plus',
                onClick: () => setSelectedType(FactorType.DIAGONOSES),
              },
            ]
          }
        />
        <Box display="flex" flexDirection="column" gap={3}>
          {elementsStats?.length > 0 && <ChartBoard legendSeries={elementsStats} total={12} />}
          <Table
            searchProps={{
              placeholder: 'Search',
              filterProps: {
                variant: Filter.tableFilterType.MULTIPLE,
                multiSelectOptions: getMultiSelectFilterOptions(DOMAIN_ELEMENT_VARIENT.DIAGNOSES),
              },
            }}
            gridProps={{
              columns: renderColumns,
              data: domainTypeData?.domainElementData,
              onRowClick: item => {
                setSelectedItem(item);
              },
              isLoading,
              emptyState: (
                <PriorityEmpty
                  title="No diagnosis has been made yet."
                  description=""
                  glyph="lottie-diagnoses"
                  glyphOrientation="portrait"
                  isEmptyWithLottie
                  lottieSize={128}
                />
              ),
            }}
            paginationProps={{
              currentRows: 10,
              totalCount: domainData.totalRecords,
              noOfRows: 10,
              showRowsPerPage: false,
            }}
            value={value}
            onChange={setValue}
            className={classes.table}
          />
        </Box>
      </Box>

      <AddNewReport
        isOpen={selectedType === FactorType.DIAGONOSES}
        onClose={onCloseDrawer}
        title="Report new diagnoses"
        stepTitles={stepTitlesDiagnoses}
        searchLabel="Select one or more diagnoses"
        options={domainOptions}
        onSubmit={onSubmitNewReport}
        isLifeEvent
        type={DomainTypesEnum.DIAGNOSES}
        optionsLoading={optionsLoading}
      />
      {selectedItem?.taxonomyName && (
        <PriorityDetailDrawer
          memberId={memberId}
          selectedItem={selectedItem}
          type={DomainTypesEnum.DIAGNOSES}
          onClose={() => {
            setSelectedItem(null);
            setSelectedType(null);
          }}
          open={selectedItem?.taxonomyName}
          onElementsUpdated={() => setReset(!reset)}
        />
      )}
    </>
  );
};

export default Diagnoses;
