import cx from 'clsx';
import MuiRating from '@mui/material/Rating';
import { useStyles } from './Rating.styles';

const Rating = ({ className = '', showValue = true, value, ...rest }) => {
  const classes = useStyles();

  return (
    <div
      className={cx({
        [classes.root]: true,
        [className || '']: className,
      })}
    >
      <MuiRating className={classes.rating} defaultValue={value} precision={0.5} readOnly {...rest} />
      {showValue && <span className={classes.value}>{value.toFixed(1)}</span>}
    </div>
  );
};

export { Rating };
