// import { makeStyles } from '@mui/styles';

// // colors
// import { colors } from '../../../colors';

// export const useStyles = makeStyles({
//   root: {
//     height: 56,
//     padding: '0 32px',
//     fontSize: 16,
//     fontFamily: 'Manrope',
//     fontWeight: 'bold',
//     lineHeight: '22px',
//     textTransform: 'none',
//     boxShadow: 'none',
//     borderRadius: 6,
//     transition: '0.4s',
//     '&:hover': {
//       opacity: 0.8,
//       boxShadow: 'none',
//     },
//   },
//   primary: {
//     color: colors.white,
//     backgroundColor: colors.primary,
//     '&:hover': {
//       backgroundColor: colors.primary,
//     },
//   },
//   secondary: {
//     color: colors.textPrimary,
//     backgroundColor: colors.bgTertiary,
//     '&:hover': {
//       backgroundColor: colors.bgTertiary,
//     },
//   },
//   text: {
//     color: colors.textPrimary,
//   },
//   outline: {
//     color: colors.neutral900,
//     backgroundColor: colors.white,
//     border: '1px solid #BEC9D3',
//     '&:hover': {
//       backgroundColor: colors.bgTertiary,
//     },
//   },
//   small: {
//     height: 48,
//     padding: '0 20px',
//   },
//   xsmall: {
//     height: 40,
//     padding: '0 20px',
//   },
//   xxsmall: {
//     height: 24,
//     padding: '0 20px',
//   },
// });

import { makeStyles } from '@mui/styles';

// colors
import { colors } from '../../../colors';

export const useStyles = makeStyles({
  root: {
    height: 56,
    padding: '0 32px',
    fontSize: 16,
    fontFamily: 'Manrope',
    fontWeight: 'bold',
    lineHeight: '22px',
    textTransform: 'none',
    boxShadow: 'none',
    borderRadius: 6,
    transition: '0.4s',
    '&:hover': {
      opacity: 0.8,
      boxShadow: 'none',
    },
    '&.Mui-disabled': {
      opacity: 0.4,
    },
  },
  primary: {
    color: colors.white,
    backgroundColor: colors.primary,
    '&:hover': {
      backgroundColor: colors.primary,
    },
  },
  secondary: {
    color: colors.textPrimary,
    backgroundColor: colors.bgTertiary,
    '&:hover': {
      backgroundColor: colors.bgTertiary,
    },
  },
  text: {
    color: colors.textPrimary,
  },
  outline: {
    color: colors.neutral900,
    backgroundColor: colors.white,
    border: '1px solid #BEC9D3',
    '&:hover': {
      backgroundColor: colors.bgTertiary,
    },
  },
  small: {
    height: 48,
    padding: '0 20px',
  },
  xsmall: {
    height: 40,
    padding: '0 20px',
  },
  xxsmall: {
    height: 24,
    padding: '0 20px',
  },
});
