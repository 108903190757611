import { Box } from '@mui/material';
import { colors } from '../../../../packages';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'clsx';
import dayjs from 'dayjs';
import nightImg from '../../../../assets/images/night.png';
import availableCheckImg from '../../../../assets/images/available-check.png';
import notAvailable from '../../../../assets/images/notAvailable.png';
import Typography from '../../../../components/v2/Typography';
import { typography } from '../../../../components/v2/Typography/index.constant';
import Heading from '../../../../components/v2/Heading';
import { HeadingLevel } from '../../../../components/v2/Heading/index.types';
import { profileActionCreators } from '../../../../redux/modules/profile';
import { scheduleActionCreators } from '../../../../redux/modules/schedule';
import { getAuth } from '../../../../redux/modules/auth/selectors';
import { selectDailySchedule, selectProviderSchedule } from '../../../../redux/modules/schedule/selectors';
import { selectGoogleCalendarsEventState } from '../../../../redux/modules/profile/selectors';
import { getUserTimeZone } from '../../../../utils/dayjs';
import Card from '../components/Card';
import AvailableSlots from '../AvailableSlots';
import BlockedSlots from '../BlockedSlots';

import { useStyles } from '../index.styles';

const RenderAvailability = ({
  currentDate,
  setOpenGeneralAvailability,
  setOpenBlockedWholeDay,
  setShowDayAvailability,
  setSchedulesDrawerOpen,
  setOpenUnblockWholeDay,
  currentWeekDay,
}) => {
  const classes = useStyles();
  const isPast = currentDate.isBefore(dayjs(), 'day');

  const dispatch = useDispatch();
  const { meta } = useSelector(getAuth);
  const providerSchedule = useSelector(selectProviderSchedule);
  const dailySchedule = useSelector(selectDailySchedule);
  const { events: googleCalendarEvents } = useSelector(selectGoogleCalendarsEventState);

  useEffect(() => {
    if (meta.userId) {
      dispatch(profileActionCreators.fetchSyncedGoogleCalendars(meta.userId));
    }
  }, []);

  useEffect(() => {
    if (currentDate) {
      dispatch(
        scheduleActionCreators.fetchProviderSchedule({
          userId: meta.userId,
        }),
      );
      const endOfDay = currentDate.endOf('day');
      const startOfDay = currentDate.startOf('day');
      dispatch(
        scheduleActionCreators.fetchDailySchedule({
          userId: meta.userId,
          queryParams: {
            startTime: startOfDay.format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
            endTime: endOfDay.format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
          },
        }),
      );
    }
  }, [currentDate]);

  const timezone = useMemo(() => dailySchedule?.timezone || getUserTimeZone(), [dailySchedule]);

  const workingDays = useMemo(() => {
    const filteredSchedule = providerSchedule?.availability.filter(item => item.active === true);
    return filteredSchedule?.reduce((prev, item) => {
      const convertedSlots = item.slots.map(v => {
        return {
          start: dayjs(v.start).tz(timezone).format('hh:mm A'),
          end: dayjs(v.end).tz(timezone).format('hh:mm A'),
        };
      });
      return {
        ...prev,
        [item.day.toLowerCase()]: prev[item.day.toLowerCase()]
          ? [...prev[item.day.toLowerCase()], ...convertedSlots]
          : convertedSlots,
      };
    }, {});
  }, [providerSchedule, timezone]);

  const availableSlots = useMemo(() => {
    return (
      dailySchedule?.availability?.flatMap(schedule =>
        schedule.slots.map(slot => ({
          ...slot,
          day: schedule.day,
          serviceName: 'Available',
          memberName: '',
          status: 'AVAILABLE',
          startTime: slot.start,
          endTime: slot.end,
          startText: dayjs(slot.start).tz(timezone).format('hh:mm A'),
          endText: dayjs(slot.end).tz(timezone).format('hh:mm A'),
        })),
      ) || []
    );
  }, [dailySchedule]);

  const blockedSlots = useMemo(() => {
    const scheduledSlots =
      dailySchedule?.blockedSlots?.map(slot => ({
        // ...slot,
        // day: schedule-link.day,
        serviceName: 'Blocked',
        memberName: '',
        type: 'confidant',
        status: 'BLOCKED',
        start: slot.start,
        startTime: slot.start,
        end: slot.end,
        endTime: slot.end,
      })) || [];
    const allEvents = googleCalendarEvents
      .map(calendar => {
        return calendar.events;
      })
      .flat()
      .map(event => ({
        // ...event,
        serviceName: 'Blocked',
        memberName: '',
        type: 'external',
        status: 'BLOCKED',
        start: event.utc.start,
        startTime: event.utc.start,
        end: event.utc.end,
        endTime: event.utc.end,
      }))
      .filter(event => dayjs(event.start).isSame(currentDate, 'day'));
    return scheduledSlots.concat(allEvents);
  }, [dailySchedule, googleCalendarEvents]);

  const hasGeneralAvailability = workingDays?.[currentWeekDay.toLowerCase()]?.length > 0;
  const hasSpecificAvailability = availableSlots?.length > 0;
  const renderAvailabilityDescription = () => {
    let mainText;
    if (isPast) {
      mainText = 'This day has ended';
    } else if (hasGeneralAvailability) {
      mainText = `On ${currentWeekDay}s you are generally available`;
    } else if (hasSpecificAvailability) {
      mainText = `You are not generally available on ${currentWeekDay}s`;
    } else {
      mainText = 'Not available';
    }
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: isPast ? 4 : hasGeneralAvailability || hasSpecificAvailability ? 3 : 1,
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          <Heading variant={HeadingLevel.H6} letterSpacing="-0.5px">
            {mainText}
          </Heading>
          {isPast && (
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography {...typography.body.m.semibold} color={colors.neutral600}>
                To update your availability for all future
              </Typography>
              <Typography {...typography.body.m.medium} color={colors.neutral600}>
                <Typography component="span" {...typography.body.m.semibold} color={colors.neutral900}>
                  {currentWeekDay}s,
                </Typography>
                go to the General availability page.
              </Typography>
            </Box>
          )}
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          {isPast && workingDays?.[currentWeekDay.toLowerCase()] && (
            <Typography {...typography.body.m.semibold}>Available slots on {currentWeekDay}s</Typography>
          )}
          {hasGeneralAvailability && !hasSpecificAvailability && (
            <>
              <Typography {...typography.body.m.medium} color={colors.neutral600}>
                But you are not available this{' '}
                <Typography component="span" {...typography.body.m.semibold} color={colors.neutral900}>
                  {currentWeekDay}
                </Typography>
              </Typography>
              <Typography {...typography.body.m.semibold}>
                Available slots on future {currentWeekDay}s
              </Typography>
              <Box sx={{ display: 'flex', gap: 1.5 }}>
                {workingDays?.[currentWeekDay.toLowerCase()]?.map((workday, index) => (
                  <Box key={index} className={classes.slot}>
                    {workday.start} - {workday.end}
                  </Box>
                ))}
              </Box>
            </>
          )}
          {hasGeneralAvailability && hasSpecificAvailability && (
            <Box sx={{ display: 'flex', gap: 1.5 }}>
              {workingDays?.[currentWeekDay.toLowerCase()]?.map((workday, index) => (
                <Box key={index} className={classes.slot}>
                  {workday.start} - {workday.end}
                </Box>
              ))}
            </Box>
          )}
          {!hasGeneralAvailability && hasSpecificAvailability && (
            <>
              <Typography {...typography.body.m.medium} color={colors.neutral600}>
                But you are available this{' '}
                <Typography component="span" {...typography.body.m.semibold} color={colors.neutral900}>
                  {currentWeekDay}
                </Typography>
              </Typography>
              <Typography {...typography.body.m.semibold}>
                Available slots on this {currentWeekDay}
              </Typography>
              <Box sx={{ display: 'flex', gap: 1.5, flexWrap: 'wrap' }}>
                {availableSlots?.map((workday, index) => (
                  <Box key={index} className={classes.slot}>
                    {workday.startText} - {workday.endText}
                  </Box>
                ))}
              </Box>
            </>
          )}
          {!hasGeneralAvailability && !hasSpecificAvailability && (
            <Typography {...typography.body.m.medium} color={colors.neutral600}>
              You are not generally available on{' '}
              <Typography component="span" {...typography.body.m.semibold} color={colors.neutral900}>
                {currentWeekDay}s
              </Typography>
            </Typography>
          )}
        </Box>
      </Box>
    );
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
      <Card
        img={
          isPast
            ? nightImg
            : hasGeneralAvailability || hasSpecificAvailability
            ? availableCheckImg
            : notAvailable
        }
        action={{
          className: cx(classes.outlineBtn, classes.xsmallBtn),
          children: <Typography {...typography.body.s.bold}>Update general availability</Typography>,
          onClick: () => setOpenGeneralAvailability(true),
        }}
        extended={renderAvailabilityDescription()}
      />
      {!isPast && (
        <>
          {availableSlots.length > 0 && (
            <AvailableSlots
              slots={availableSlots}
              providerSchedule={providerSchedule}
              currentDate={currentDate}
              setOpenBlockedWholeDay={setOpenBlockedWholeDay}
              setShowDayAvailability={setShowDayAvailability}
              setSchedulesDrawerOpen={setSchedulesDrawerOpen}
            />
          )}
          {blockedSlots.length > 0 && (
            <BlockedSlots
              slots={blockedSlots}
              providerSchedule={providerSchedule}
              currentDate={currentDate}
              setOpenUnblockWholeDay={setOpenUnblockWholeDay}
            />
          )}
        </>
      )}
    </Box>
  );
};

export default RenderAvailability;
