import { Box, Grid } from '@mui/material';
import { colors } from '../../../../../packages/colors';
import { Icons } from '../../../../../packages/icons';

import Heading from '../../../../../components/v2/Heading';
import { HeadingLevel, HeadingWeight } from '../../../../../components/v2/Heading/index.types';
import Typography from '../../../../../components/v2/Typography';
import { typography } from '../../../../../components/v2/Typography/index.constant';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import {
  getKPIOutcome,
  getProfileElementAssignmentIn30Days,
} from '../../../../../services/reporting/reporting.service';

import { useStyles } from './index.styles';

const AverageFrame = ({ avgScores, memberId, setData, isDataAvailable }) => {
  const classes = useStyles();
  const allDataAvailable =
    isDataAvailable.phq9 === false &&
    isDataAvailable.gad7 === false &&
    isDataAvailable.hospitalization === false;

  const boxFlexDirection = allDataAvailable ? 'column' : 'row';
  const boxGap = allDataAvailable ? '0px' : '12px';
  const [avgScore, setAvgScores] = useState({
    count: 0,
    point: 0,
    initialScore: 0,
    date: dayjs().format('MM/DD/YYYY'),
  });
  const [isCurrentScore, setIsCurrentScore] = useState(true);
  const [hospitalization, setHospitalization] = useState({
    count: 0,
    date: dayjs().format('MM/DD/YYYY'),
  });
  const [outcomeRange, setOutcomeRange] = useState({
    vNegativeMin: 0,
    vNegativeMax: 0,
    negativeMin: 0,
    negativeMax: 0,
    neutralMin: 0,
    neutralMax: 0,
    positiveMin: 0,
    positiveMax: 0,
  });

  const fetchAverageScore = async () => {
    try {
      const queryParams = {
        userIds: memberId,
        pageNumber: 0,
        pageSize: 1,
        orderBy: 'asc',
        sortBy: 'createdAt',
      };
      if (avgScores?.type === 'phq9' || avgScores?.type === 'gad7') {
        const { data } = await getKPIOutcome(
          avgScores.type === 'phq9' ? 'PHQ-9 Score' : 'GAD-7 Score',
          queryParams,
        );
        const dataOutCome = data?.metaData?.outComeRange;
        setOutcomeRange({
          vNegativeMin: dataOutCome?.veryNegativeRange?.minScore,
          vNegativeMax: dataOutCome?.veryNegativeRange?.maxScore,
          negativeMin: dataOutCome?.negativeRange?.minScore,
          negativeMax: dataOutCome?.negativeRange?.maxScore,
          neutralMin: dataOutCome?.neutralRange?.minScore,
          neutralMax: dataOutCome?.neutralRange?.maxScore,
          positiveMin: dataOutCome?.positiveRange?.minScore,
          positiveMax: dataOutCome?.positiveRange?.maxScore,
        });
        if (data?.data[0]?.totalCompletions < 2 && data?.data[0]?.entryDifferenceInDays < 30) {
          setIsCurrentScore(false);
        } else {
          setIsCurrentScore(true);
        }
        setAvgScores({
          count: data?.data[0]?.totalCompletions ?? 0,
          point: data?.data[0]?.lastAttemptDetails.value ?? 0,
          initialScore: data?.data[0]?.firstAttemptDetails.value ?? 0,
          date: dayjs(data?.data[0]?.lastAttemptDetails.timestamp).format('MM/DD/YYYY'),
        });
        setData(data?.data[0]?.totalCompletions ?? 0);
      } else {
        const { data } = await getProfileElementAssignmentIn30Days({
          userAccountId: memberId,
          profileElement: 'Hospitalized overnight in last 30 days',
        });
        setData(!!data?.data?.latest30DaysAttempt);
        if (data?.data?.latest30DaysAttempt) {
          setHospitalization({
            count: data.data?.latest30DaysAttempt?.value ?? 0,
            date: dayjs(data.data.latest30DaysAttempt.timestamp).format('MM/DD/YYYY'),
          });
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    void fetchAverageScore();
  }, []);

  const getColors = count => {
    if (count >= outcomeRange.vNegativeMin && count <= outcomeRange.vNegativeMax) {
      return { borderColor: colors.destructive600, color: colors.destructive600 };
    }
    if (count >= outcomeRange.negativeMin && count <= outcomeRange.negativeMax) {
      return { borderColor: colors.warning600, color: colors.warning600 };
    }
    if (count >= outcomeRange.neutralMin && count <= outcomeRange.neutralMax) {
      return { borderColor: colors.neutral400, color: colors.neutral400 };
    }
    if (count >= outcomeRange.positiveMin && count <= outcomeRange.positiveMax) {
      return { borderColor: colors.success600, color: colors.success600 };
    }
    return { borderColor: colors.neutral400, color: colors.neutral400 };
  };

  const count = avgScores.type === 'hospitalizations' ? hospitalization.count : avgScore.count;
  const isBetter = avgScore?.initialScore - avgScore?.point > 0;
  return (
    <Grid container className={classes.scorebox}>
      <Box className={classes.sbflex}>
        <Box pl={2} py={2}>
          <Heading variant={HeadingLevel.SUB} fontWeight={HeadingWeight.SEMI_BOLD}>
            {avgScores?.title}
          </Heading>
        </Box>
        {/* {avgScore?.count !== 0 && (
          <Button variant={btnType.TEXT} onClick={onClick}>
            <Typography {...typography.body.s.bold}>View all ({avgScore?.count})</Typography>
          </Button>
        )} */}
      </Box>
      <Box className={classes.borderTop} p={2} display="flex" gap={1.5} alignItems="center">
        {count > 0 && (
          <Box
            className={classes.circle}
            sx={{
              background:
                avgScores.type === 'hospitalizations' ? colors.neutral700 : getColors(avgScore?.point).color,
            }}
          >
            <Typography {...typography.body.m.bold}>
              {Number(
                avgScores.type === 'hospitalizations' ? hospitalization.count : avgScore?.point,
              ).toFixed(0)}
            </Typography>
          </Box>
        )}
        {avgScores.type !== 'hospitalizations' ? (
          <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
            {count > 0 ? (
              <>
                {isCurrentScore ? (
                  <>
                    <Typography {...typography.body.s.semibold}>
                      <span style={{ color: isBetter ? '#1E7023' : '#DC2626' }}>
                        {Math.abs(avgScore?.point - avgScore?.initialScore).toFixed(0)} points{' '}
                        {isBetter ? 'better' : 'worse'}{' '}
                      </span>
                      vs initial
                    </Typography>
                    <Typography {...typography.body.s.semibold} color={colors.neutral600}>
                      initial score is{' '}
                      <Box component="span" color={colors.destructive500}>
                        {Number(avgScore?.initialScore).toFixed(0)}
                      </Box>
                    </Typography>
                  </>
                ) : (
                  <>
                    <Typography {...typography.body.s.semibold}>Current score</Typography>
                    <Typography {...typography.body.s.medium} color={colors.neutral600}>
                      as of {avgScore.date}
                    </Typography>
                  </>
                )}
              </>
            ) : (
              <Box
                style={{
                  display: 'flex',
                  flexDirection: boxFlexDirection,
                  alignItems: 'center',
                  flex: 1,
                  gap: boxGap,
                }}
              >
                <Icons
                  glyph={avgScores?.type === 'phq9' ? 'lottie-phq9' : 'lottie-gad7'}
                  className={allDataAvailable ? classes.width64 : classes.width52}
                />
                <Typography {...typography.body.m.medium} color={colors.neutral900}>
                  No {avgScores?.title} completions yet
                </Typography>
              </Box>
            )}
          </Box>
        ) : (
          <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
            {Number(count) > 0 ? (
              <>
                <Typography {...typography.body.s.semibold}>hospitalizations</Typography>
                {hospitalization?.count !== 0 && (
                  <Typography {...typography.body.s.medium} color={colors.neutral600}>
                    as of {hospitalization.date}
                  </Typography>
                )}
              </>
            ) : (
              <Box
                style={{
                  display: 'flex',
                  flexDirection: boxFlexDirection,
                  alignItems: 'center',
                  flex: 1,
                  gap: boxGap,
                }}
              >
                <Icons
                  glyph="lottie-hospital"
                  className={allDataAvailable ? classes.width64 : classes.width52}
                />
                <Typography {...typography.body.m.medium} color={colors.neutral900}>
                  No hospitalization data yet
                </Typography>
              </Box>
            )}
          </Box>
        )}
      </Box>
    </Grid>
  );
};

export default AverageFrame;
