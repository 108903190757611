/* eslint-disable no-underscore-dangle */
/* eslint-disable max-len */
import { toDateString } from '../../../../../../utils/CommonUtils';
import { DEMOGRAPHICS_TABLES } from '../Demographics.constants';

export const tableColumns = [
  {
    id: 'member',
    label: 'Memeber',
    sortable: false,
  },
  {
    id: 'lastUpdated',
    label: 'Date assigned',
    sortable: false,
  },
  {
    id: 'actions',
    label: '',
    sortable: false,
  },
];
const getFieldsFromBucket3 = bucket => {
  const name =
    bucket.fields.firstName?.[0] && bucket.fields.lastName?.[0]
      ? `${bucket.fields.firstName[0]} ${bucket.fields.lastName[0]}`
      : bucket.fields.fullName?.[0];
  const lastModifiedDate = bucket.fields.lastModifiedDate?.[0] || '';
  const memberId = bucket.fields.memberId?.[0] || bucket.fields.userAccountId?.[0] || '';
  return { name, lastModifiedDate, memberId };
};
const getFormatedData = (buckets, searchKeyword) => {
  if (searchKeyword) {
    return buckets
      .map(bucket => ({
        name: getFieldsFromBucket3(bucket).name,
        member: {
          name: getFieldsFromBucket3(bucket).name,
          memberId: getFieldsFromBucket3(bucket).memberId,
        },
        lastUpdated: toDateString(getFieldsFromBucket3(bucket).lastModifiedDate),
      }))
      .filter(item => item.name?.toLowerCase().includes(searchKeyword.toLowerCase()));
  }
  return buckets.map(bucket => ({
    name: getFieldsFromBucket3(bucket).name,
    member: {
      name: getFieldsFromBucket3(bucket).name,
      memberId: getFieldsFromBucket3(bucket).memberId,
    },
    lastUpdated: toDateString(getFieldsFromBucket3(bucket).lastModifiedDate),
  }));
};
export const getDemographicsRecentAssignedData = (profileElementReport, searchKeyword) =>
  profileElementReport
    ? searchKeyword
      ? getFormatedData(profileElementReport.hits.hits, searchKeyword)
      : getFormatedData(profileElementReport.hits.hits, null)
    : [];
export const getDDERecentAssignedData = (profileElementReport, searchKeyword) =>
  profileElementReport
    ? searchKeyword
      ? getFormatedData(profileElementReport.hits.hits, searchKeyword)
      : getFormatedData(profileElementReport.hits.hits, null)
    : [];

export const getDemographicsDetailQuery = (name, type) => {
  switch (type) {
    case DEMOGRAPHICS_TABLES.cities:
      return {
        size: 10000,
        query: {
          term: {
            'city.keyword': {
              value: name,
            },
          },
        },
        _source: ['userAccountId', 'firstName', 'lastName', 'fullName', 'lastModified'],
        sort: {
          lastModified: {
            order: 'desc',
          },
        },
        fields: ['userAccountId', 'firstName', 'lastName', 'fullName', 'lastModified'],
        script_fields: {
          lastModifiedDate: {
            script: {
              source: "params['_source']['lastModified']",
              lang: 'painless',
            },
          },
        },
      };

    case DEMOGRAPHICS_TABLES.states:
      return {
        size: 10000,
        query: {
          term: {
            'state.keyword': {
              value: name,
            },
          },
        },
        _source: ['userAccountId', 'firstName', 'lastName', 'fullName', 'lastModified'],
        sort: {
          lastModified: {
            order: 'desc',
          },
        },
        fields: ['userAccountId', 'firstName', 'lastName', 'fullName', 'lastModified'],
        script_fields: {
          lastModifiedDate: {
            script: {
              source: "params['_source']['lastModified']",
              lang: 'painless',
            },
          },
        },
      };

    default:
      return {
        size: 10000,
        query: {
          bool: {
            filter: [
              {
                term: {
                  'dde_name.keyword': name,
                },
              },
            ],
          },
        },
        _source: [
          'first_name',
          'last_name',
          'member_name',
          'dde_name',
          'initial_importance',
          'dde_importance',
          'initial_assignedAt',
          'assignedAt',
          'lastModified',
          'member_id',
        ],
        sort: {
          assignedAt: {
            order: 'desc',
          },
        },
        fields: [
          'first_name',
          'last_name',
          'member_name',
          'dde_name',
          'initial_importance',
          'dde_importance',
          'initial_assignedAt',
          'assignedAt',
          'lastModified',
          'member_id',
        ],
        script_fields: {
          memberId: {
            script: {
              source: "params['_source']['member_id']",
              lang: 'painless',
            },
          },
          firstName: {
            script: {
              source: "params['_source']['first_name']",
              lang: 'painless',
            },
          },
          fullName: {
            script: {
              source: "params['_source']['member_name']",
              lang: 'painless',
            },
          },
          lastName: {
            script: {
              source: "params['_source']['last_name']",
              lang: 'painless',
            },
          },
          lastModifiedDate: {
            script: {
              source: "params['_source']['lastModified']",
              lang: 'painless',
            },
          },
        },
      };
  }
};
