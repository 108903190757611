import { colors } from '../../../../packages';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  drawer: {
    '& .drawerBody': {
      padding: 0,
    },
  },
  wrapper: {
    flex: 1,
    justifyItems: 'space-between',
    flexDirection: 'column',
  },
  header: {
    display: 'flex',
    gap: 24,
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 80,
    padding: '20px 16px',
    borderBottom: `1px solid ${colors.neutral100}`,
  },
  headerTitle: {
    fontSize: 24,
    lineHeight: '32px',
    fontWeight: 700,
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 80,
    padding: 16,
    borderTop: `1px solid ${colors.neutral100}`,
  },
  closeBtn: {
    minWidth: 'auto',
    '& svg': {
      width: 24,
    },
  },
  form: {
    flex: 1,
  },
  formContent: {
    flex: 1,
    display: 'flex',
    minHeight: 'calc(100vh - 160px)',
    flexDirection: 'column',
    gap: 24,
    padding: 40,
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    position: 'relative',
  },
  removeBtn: {
    color: colors.destructive600,
    fontSize: 14,
    fontWeight: 700,
    lineHeight: '20px',
    '& svg': {
      marginRight: 8,
    },
  },
  label: {
    fontSize: 14,
    lineHeight: '20px',
    margin: 0,
  },
  input: {
    height: 48,
  },
});
