/* eslint-disable max-len */
import cx from 'clsx';

// types
// styles
import { useStyles } from './shared/styles';

const Transcript = ({ className, color, onClick }) => {
  const classes = useStyles();
  return (
    <svg
      className={cx({
        [classes.root]: true,
        [className || '']: className,
      })}
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M18 0H2C1.46957 0 0.960859 0.210714 0.585786 0.585786C0.210714 0.960859 0 1.46957 0 2V14C0 14.5304 0.210714 15.0391 0.585786 15.4142C0.960859 15.7893 1.46957 16 2 16H7.09C7.35234 16.0011 7.60374 16.1053 7.79 16.29L9.28 17.78C9.42052 17.9207 9.61116 17.9998 9.81 18H10.19C10.3888 17.9998 10.5795 17.9207 10.72 17.78L12.21 16.29C12.3963 16.1053 12.6477 16.0011 12.91 16H18C18.5304 16 19.0391 15.7893 19.4142 15.4142C19.7893 15.0391 20 14.5304 20 14V2C20 1.46957 19.7893 0.960859 19.4142 0.585786C19.0391 0.210714 18.5304 0 18 0ZM18 14H12.91C12.1146 14.0007 11.3521 14.3172 10.79 14.88L10 15.67L9.21 14.88C8.64793 14.3172 7.88538 14.0007 7.09 14H2V2H18V14ZM4.5 11H11.5C11.6326 11 11.7598 10.9473 11.8536 10.8536C11.9473 10.7598 12 10.6326 12 10.5V9.5C12 9.36739 11.9473 9.24021 11.8536 9.14645C11.7598 9.05268 11.6326 9 11.5 9H4.5C4.36739 9 4.24021 9.05268 4.14645 9.14645C4.05268 9.24021 4 9.36739 4 9.5V10.5C4 10.6326 4.05268 10.7598 4.14645 10.8536C4.24021 10.9473 4.36739 11 4.5 11ZM4.5 7H15.5C15.6326 7 15.7598 6.94732 15.8536 6.85355C15.9473 6.75979 16 6.63261 16 6.5V5.5C16 5.36739 15.9473 5.24021 15.8536 5.14645C15.7598 5.05268 15.6326 5 15.5 5H4.5C4.36739 5 4.24021 5.05268 4.14645 5.14645C4.05268 5.24021 4 5.36739 4 5.5V6.5C4 6.63261 4.05268 6.75979 4.14645 6.85355C4.24021 6.94732 4.36739 7 4.5 7Z"
        fill={color}
      />
    </svg>
  );
};

export default Transcript;
