import { makeStyles } from '@mui/styles';

// colors
import { colors } from '../../../../../packages';

export const useStyles = makeStyles({
  card: {
    backgroundColor: colors.white,
    boxShadow:
      '0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
    width: '100%',
    borderRadius: 8,
    overflow: 'hidden',
  },
  boxes: {
    display: 'grid',
    padding: 32,
    gridTemplateColumns: '1fr 1fr 1fr',
    gridRowGap: 16,
    gridColumnGap: 16,
    borderBottom: `1px solid ${colors.neutral100}`,
    '& > div': {
      width: '100%',
    },
    '@media (max-width: 1024px)': {
      gridTemplateColumns: '1fr 1fr',
    },
    '@media (max-width: 768px)': {
      gridTemplateColumns: '1fr',
    },
  },
  chartWrap: {
    '& > div > div': {
      paddingBottom: 0,
      borderBottom: 'none',
    },
  },
  chart: {
    padding: 0,
    borderRadius: 0,
    border: 'none',
    boxShadow: 'none',
  },
  tableWrap: {
    '&:first-child': {
      padding: '32px 0 32px 32px',
    },
    '&:last-child': {
      padding: '32px 32px 32px 0',
    },
  },
  halfTable: {
    padding: '32px !important',
  },
  fullTable: {
    '&:last-child': {
      padding: '0 32px 32px 32px',
    },
  },
  tableBordered: {
    border: `1px solid ${colors.neutral100}`,
    borderBottom: 'none',
    borderRadius: 8,
    overflow: 'hidden',
    marginTop: 24,
    '& td.color-secondary-500': {
      color: colors.secondary500,
    },
  },
  recentTitle: {
    fontSize: 18,
    lineHeight: '24px',
    margin: 0,
  },
  viewAllBtn: {
    fontSize: 14,
    lineHeight: '20px',
    fontWeight: 700,
  },
  menu: {
    '& svg': {
      width: 14,
    },
  },
  drawerContent: {
    flex: 1,
  },
  drawer: {
    '& .MuiPaper-root': {
      maxWidth: '720px !important',
    },
    '& .drawerBody': {
      padding: 0,
    },
  },
  headerDetail: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 16,
    borderBottom: `1px solid ${colors.neutral100}`,
  },
  headerTitle: {
    fontSize: 18,
    lineHeight: '24px',
    margin: 0,
  },
  headerSubtitle: {
    fontSize: 14,
    lineHeight: '24px',
    fontWeight: 500,
    margin: 0,
    color: colors.neutral600,
  },
  closeIconBtn: {
    width: 40,
    height: 40,
    minWidth: 40,
  },
  drawerTable: {
    flex: 1,
    overflowY: 'auto',
    height: 'calc(100vh - 160px)',
    '& td.color-secondary-500': {
      color: colors.secondary500,
    },
  },
  drawerFooter: {
    display: 'flex',
    height: 80,
    borderTop: `1px solid ${colors.neutral100}`,
    justifyContent: 'flex-end',
    padding: 16,
    '& button': {
      height: 48,
    },
  },
});
