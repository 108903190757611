export const PLAN_ITEMS = {
  PLAN_ITEM_TYPE: {
    EDUCATION: 'EDUCATION',
    TOPIC: 'TOPIC',
    ACTIVITY: 'ACTIVITY',
    GROUP: 'GROUP',
    CONVERSATION: 'CONVERSATION',
    PROVIDER: 'PROVIDER',
    PROVIDER_TYPE: 'PROVIDER TYPE',
    SERVICE: 'SERVICE',
  },
  PLAN_ITEM_IN_PROGRESS_STATE: {
    EDUCATION: {
      VIEW_SELECTED_EDUCATION: 'View Selected Education',
    },
    TOPIC: {
      VIEW_SELECTED_TOPIC: 'View Selected TOPIC',
    },
    ACTIVITY: {
      SCHEDULE_SELECTED_ACTIVITY: 'Schedule Selected Activity',
    },
    GROUP: {
      JOIN_SELECTED_GROUP: 'Join Selected Group',
    },
    CONVERSATION: {
      START_SELECTED_CONVERSATION: 'Start Selected Conversation',
    },
    PROVIDER: {
      REQUEST_APPOINTMENT_FROM_SELECTED_PROVIDER_TYPE: 'Request Appointment from Selected Provider',
    },
    PROVIDER_TYPE: {
      REQUEST_APPOINTMENT_FROM_SELECTED_PROVIDER_TYPE: 'Request Appointment from Selected Provider Type',
    },
    SERVICE: {
      REQUEST_APPOINTMENT_FROM_SELECTED_SERVICE_TYPE: 'Request Appointment for Selected Service Type',
    },
  },
  PLAN_ITEM_COMPLETED_STATE: {
    EDUCATION: {
      VIEW_SELECTED_EDUCATION: 'View Selected Education',
      COMPLETE_SELECTED_EDUCATION: 'Complete Selected Education',
    },
    TOPIC: {
      VIEW_EACH_EDUCATION_WITHIN_A_SELECTED_TOPIC: 'View each Education within a Selected Topic',
    },
    ACTIVITY: {
      CHECKIN_ON_SELECTED_ACTIVITY: 'Check-in on Selected Activity',
    },
    GROUP: {
      ATTEND_SESSION_FOR_SELECTED_GROUP: 'Attend a Session for a Selected Group',
    },
    CONVERSATION: {
      COMPLETE_SELECTED_CONVERSATION: 'Complete Selected Conversation',
    },
    PROVIDER: {
      COMPLETE_APPOINTMENT_FROM_SELECTED_PROVIDER_TYPE: 'Complete Appointment from Selected Provider',
    },
    PROVIDER_TYPE: {
      COMPLETE_APPOINTMENT_FROM_SELECTED_PROVIDER_TYPE: 'Complete Appointment from Selected Provider Type',
    },
    SERVICE: {
      COMPLETE_APPOINTMENT_FROM_SELECTED_SERVICE_TYPE: 'Complete Appointment for Selected Service Type',
    },
  },
  REMOVED_FROM_PLAN: {
    COMPLETED_X_TIMES: 'Completed X times ',
    REMOVED_BY_MEMBER: 'Removed by Member',
  },
};

export const REVAMP_TYPES = {
  Reward: 'Reward',
  Values: 'Values',
  Activities: 'Activities',
  Mind_And_Body: 'Mind & Body',
  Plan: 'Plan',
};

export const typeSelects = Object.keys(PLAN_ITEMS.PLAN_ITEM_TYPE).map(key => ({
  value: key,
  label: PLAN_ITEMS.PLAN_ITEM_TYPE[key],
}));

export const getNavigatesToSelects = selectedType =>
  Object.keys(PLAN_ITEMS.PLAN_ITEM_TYPE)
    .filter(type => type === selectedType)
    .map(key => ({
      value: key,
      label: `Navigates to ${PLAN_ITEMS.PLAN_ITEM_TYPE[key]} Screen`,
    }));

export const getProgressStateSelects = type =>
  Object.keys(PLAN_ITEMS.PLAN_ITEM_IN_PROGRESS_STATE[type]).map(key => ({
    value: key,
    label: PLAN_ITEMS.PLAN_ITEM_IN_PROGRESS_STATE[type][key],
  }));

export const getCompleteStateSelects = type =>
  Object.keys(PLAN_ITEMS.PLAN_ITEM_COMPLETED_STATE[type]).map(key => ({
    value: key,
    label: PLAN_ITEMS.PLAN_ITEM_COMPLETED_STATE[type][key],
  }));

export const removeFromPlanSelects = [
  { label: 'Removed by member', value: 'REMOVED_BY_MEMBER' },
  { label: 'Completed X times', value: 'COMPLETED_X_TIMES' },
];

export const assignedPlanItemsSelect = [
  { label: 'Assign Plan Item', value: 'ASSIGN_PLAN_ITEM' },
  { label: 'Assign Evaluation', value: 'ASSIGN_EVALUATION' },
];
export const transcriptReadySelections = [{ label: 'Run AI Job', value: 'RUN_AI_JOB' }];
