/* eslint-disable max-len */
import cx from 'clsx';

// types
// styles
import { useStyles } from './shared/styles';

const Quote = ({ className, color, onClick, ...rest }) => {
  const classes = useStyles();
  return (
    <svg
      className={cx({
        [classes.root]: true,
        [className || '']: className,
      })}
      onClick={onClick}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g id="ai-quote-icon">
        <path
          id="ai-quote-path"
          d="M3.81917 14.434C2.96083 13.5224 2.5 12.4999 2.5 10.8424C2.5 7.92571 4.5475 5.31154 7.525 4.01904L8.26917 5.16738C5.49 6.67071 4.94667 8.62154 4.73 9.85154C5.1775 9.61988 5.76333 9.53904 6.3375 9.59238C7.84083 9.73154 9.02583 10.9657 9.02583 12.4999C9.02583 13.2734 8.71854 14.0153 8.17156 14.5623C7.62458 15.1093 6.88271 15.4165 6.10917 15.4165C5.215 15.4165 4.36 15.0082 3.81917 14.434ZM12.1525 14.434C11.2942 13.5224 10.8333 12.4999 10.8333 10.8424C10.8333 7.92571 12.8808 5.31154 15.8583 4.01904L16.6025 5.16738C13.8233 6.67071 13.28 8.62154 13.0633 9.85154C13.5108 9.61988 14.0967 9.53904 14.6708 9.59238C16.1742 9.73154 17.3592 10.9657 17.3592 12.4999C17.3592 13.2734 17.0519 14.0153 16.5049 14.5623C15.9579 15.1093 15.216 15.4165 14.4425 15.4165C13.5483 15.4165 12.6933 15.0082 12.1525 14.434Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

export default Quote;
