// // colors
// import { colors } from '../../../colors';

// // types
// import { positionType } from './Toggle.types';

// export const styles = {
//   container: {
//     display: 'flex',
//     alignItems: 'center',
//     padding: '8px 0',
//   },
//   label: (labelPosition: positionType) => ({
//     fontWeight: 500,
//     fontFamily: 'Manrope',
//     fontSize: '14px',
//     lineHeight: '20px',
//     color: colors.textHighContrast,
//     order: labelPosition === positionType.LEFT ? 0 : 1,
//     paddingLeft: labelPosition === positionType.LEFT ? 0 : '12px',
//     paddingRight: labelPosition === positionType.RIGHT ? 0 : '12px',
//   }),
//   switch: (labelPosition: positionType) => ({
//     position: 'relative',
//     display: 'inline-block',
//     width: '40px',
//     height: '24px',
//     '& input': {
//       opacity: 0,
//       width: 0,
//       height: 0,
//     },
//     order: labelPosition === positionType.LEFT ? 1 : 0,
//   }),
//   slider: (checked: boolean) => ({
//     position: 'absolute',
//     cursor: 'pointer',
//     top: 0,
//     left: 0,
//     right: 0,
//     bottom: 0,
//     backgroundColor: checked ? colors.primary : colors.neutral200,
//     borderRadius: '20px',
//     transition: 'all 0.3s',
//     '&:before': {
//       position: 'absolute',
//       content: "''",
//       height: '20px',
//       width: '20px',
//       left: -2,
//       bottom: 2,
//       backgroundColor: colors.white,
//       transition: 'all 0.3s',
//       borderRadius: '50%',
//       transform: `translateX(${checked ? 20 : 4}px)`,
//       WebkitTransform: `translateX(${checked ? 20 : 4}px)`,
//       msTransform: `translateX(${checked ? 20 : 4}px)`,
//     },
//   }),
// };

// Colors
import { colors } from '../../../colors';

export const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    padding: '8px 0',
  },
  label: labelPosition => ({
    fontWeight: 500,
    fontFamily: 'Manrope',
    fontSize: '14px',
    lineHeight: '20px',
    color: colors.textHighContrast,
    order: labelPosition === 'left' ? 0 : 1,
    paddingLeft: labelPosition === 'left' ? 0 : '12px',
    paddingRight: labelPosition === 'right' ? 0 : '12px',
  }),
  switch: labelPosition => ({
    position: 'relative',
    display: 'inline-block',
    width: '40px',
    height: '24px',
    '& input': {
      opacity: 0,
      width: 0,
      height: 0,
    },
    order: labelPosition === 'left' ? 1 : 0,
  }),
  slider: checked => ({
    position: 'absolute',
    cursor: 'pointer',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: checked ? colors.primary : colors.neutral200,
    borderRadius: '20px',
    transition: 'all 0.3s',
    '&:before': {
      position: 'absolute',
      content: "''",
      height: '20px',
      width: '20px',
      left: -2,
      bottom: 2,
      backgroundColor: colors.white,
      transition: 'all 0.3s',
      borderRadius: '50%',
      transform: `translateX(${checked ? 20 : 4}px)`,
      WebkitTransform: `translateX(${checked ? 20 : 4}px)`,
      msTransform: `translateX(${checked ? 20 : 4}px)`,
    },
  }),
};
