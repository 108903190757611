import cx from 'clsx';

// components
import { Select, selectType } from '../../../atoms/select';
import { Pagination } from '../../../atoms/pagination';
import { Text, textLevel } from '../../../atoms/typography';

// mock
import { rowsPerPageOptions } from './TablePagination.constants';

// styles
import { useStyles } from './TablePagination.styles';

// types
import { iconBtnPosition, iconBtnStyle, iconBtnType, IconButton } from '../../../molecules/icon-button';

const TablePagination = ({
  className = '',
  currentRows = 0,
  totalCount = 0,
  noOfRows,
  value,
  disabled = false,
  onChange,
  showRowsPerPage = true,
  ...rest
}) => {
  const classes = useStyles();
  const rowsPerPage = value?.rowsPerPage || rowsPerPageOptions[0].value;
  const currentPage = value?.currentPage || 1;
  const totalPages = Math.ceil(totalCount / rowsPerPage);

  const onRowsPerPageChange = e => {
    onChange({ ...value, rowsPerPage: parseInt(e.target.value, 10), currentPage: 1 });
  };

  const onPageChange = (__, page) => {
    onChange({ ...value, currentPage: page });
  };

  const onPreviousPage = () => {
    onChange({ ...value, currentPage: currentPage - 1 });
  };

  const onNextPage = () => {
    onChange({ ...value, currentPage: currentPage + 1 });
  };

  return (
    <div
      className={cx({
        [classes.root]: true,
        [className || '']: className,
      })}
    >
      {showRowsPerPage ? (
        <div className={classes.rowsPerPage}>
          <Text className={classes.showText} level={textLevel.S}>
            Show:
          </Text>
          <Select
            className={classes.rowsSelect}
            variant={selectType.PRIMARY}
            options={rowsPerPageOptions}
            value={rowsPerPage}
            disabled={disabled}
            onChange={onRowsPerPageChange}
            renderValue={rows => `${rows} results`}
          />
        </div>
      ) : (
        <>
          {currentPage > 1 && (
            <IconButton
              icon="arrow-left"
              className={classes.prevButton}
              style={iconBtnStyle.LOW_CONTRAST}
              variant={iconBtnType.OUTLINE}
              disabled={currentPage === 1}
              onClick={onPreviousPage}
            >
              Previous
            </IconButton>
          )}
        </>
      )}
      <Pagination
        count={totalPages}
        page={currentPage}
        disabled={disabled}
        onChange={onPageChange}
        hideNextButton={!showRowsPerPage}
        hidePrevButton={!showRowsPerPage}
        style={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)' }}
        {...rest}
      />
      {showRowsPerPage ? (
        <Text className={classes.resultsShown} level={textLevel.XS}>
          {noOfRows} of {totalCount} results shown
        </Text>
      ) : (
        <>
          {currentPage < totalPages && (
            <IconButton
              icon="arrow-right"
              variant={iconBtnType.OUTLINE}
              style={iconBtnStyle.LOW_CONTRAST}
              iconPosition={iconBtnPosition.RIGHT}
              className={classes.nextButton}
              disabled={currentPage === totalPages}
              onClick={onNextPage}
            >
              Next
            </IconButton>
          )}
        </>
      )}
    </div>
  );
};

export { TablePagination };
