import { Box } from '@mui/material';
import { Rating } from '../../../../packages/ui/atoms/rating';
import { colors } from '../../../../packages/colors';

import Heading from '../../../../components/v2/Heading';
import Typography from '../../../../components/v2/Typography';
import { typography } from '../../../../components/v2/Typography/index.constant';
import { HeadingLevel, HeadingWeight } from '../../../../components/v2/Heading/index.types';

import { useStyles } from '../index.styles';

const Card = ({ label, count, isRating = false }) => {
  const classes = useStyles();

  return (
    <Box className={classes.card}>
      <Typography
        {...typography.overline.small}
        sx={{ textTransform: 'uppercase', color: colors.neutral600 }}
        style={{ letterSpacing: '1px' }}
      >
        {label}
      </Typography>
      <Box display="flex" gap={1} alignItems="center">
        <Heading variant={HeadingLevel.H4} fontWeight={HeadingWeight.BOLD}>
          {isRating ? Number(count).toFixed(1) ?? Number(0).toFixed(1) : count ?? 0}
        </Heading>
        {isRating && Number(count) !== 0 && (
          <Rating value={Number(count)} readOnly className={classes.rating} name="rating" showValue={false} />
        )}
        {isRating && Number(count) === 0 && (
          <Rating value={Number(0)} readOnly className={classes.rating} name="rating" showValue={false} />
        )}
      </Box>
    </Box>
  );
};

export default Card;
