/* eslint-disable no-empty */
import { FC, useCallback, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';

import { fontWeight, Heading } from '../../../../../../packages';
import { IconButton } from '../../../../../../packages';
import { AppointmentStatus } from '../../../../../../packages/constants/CommonConstants';
import { Avatar, avatarType } from '../../../../../../packages';

import { PrioritiesCard, priorityListCard } from '../../../priority-list/PriorityList.constants';
import CurrentView from '../../../current-view';
import { useDomainType } from '../../../../../../hooks/useDomainType';
import { DomainTypesEnum } from '../../../../../../constants/CommonConstants';
import { useHistoryPatient } from '../../../../../../hooks/useHistoryPatient';
import { getChatbots } from '../../../../../../services/conversation/conversation.service';
import { getUserGroups, getMemberNotes } from '../../../../../../services/member/member.service';
import { getPastAppointments } from '../../../../../../services/appointment/appointment.service';
import { profileActionCreators } from '../../../../../../redux/modules/profile';
import { conversationActionCreators } from '../../../../../../redux/modules/conversation';
import { selectAssignedPlanItemsById } from '../../../../../../redux/modules/conversation/selectors';

import InfoCard from '../../../info-card';
import ViewPriorityCard from '../view-priority-card';
import UpdatePriorityCard from '../update-priority-card';
import CreateNewReport from '../create-new-report';
import { useStyles } from './PriorityList.styles';

const PriorityList = ({ appointment, hasBack, onClose }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { participantId } = appointment || { participantId: '' };
  const [shouldUpdateDomainType, setShouldUpdateDomainType] = useState(false);
  const { lastUpdated, items: historyItems } = useHistoryPatient({ patientId: participantId });
  const planItemList = useSelector(selectAssignedPlanItemsById(participantId));
  const socialDeterminants = useDomainType({
    type: DomainTypesEnum.SOCIALS_DETERMINANTS,
    patientId: participantId,
    shouldUpdate: shouldUpdateDomainType,
  });
  const lifeEvents = useDomainType({
    type: DomainTypesEnum.LIFE_EVENTS,
    patientId: participantId,
    shouldUpdate: shouldUpdateDomainType,
  });
  const symptoms = useDomainType({
    type: DomainTypesEnum.SYMPTOMS,
    patientId: participantId,
    shouldUpdate: shouldUpdateDomainType,
  });
  const diagnoses = useDomainType({
    type: DomainTypesEnum.DIAGNOSES,
    patientId: participantId,
    shouldUpdate: shouldUpdateDomainType,
  });
  const sideEffects = useDomainType({
    type: DomainTypesEnum.SIDE_EFFECTS,
    patientId: participantId,
    shouldUpdate: shouldUpdateDomainType,
  });
  const medications = useDomainType({
    type: DomainTypesEnum.MEDICATIONS,
    patientId: participantId,
    shouldUpdate: shouldUpdateDomainType,
  });
  const substanceUse = useDomainType({
    type: DomainTypesEnum.SUBSTANCE_USE,
    patientId: participantId,
    shouldUpdate: shouldUpdateDomainType,
  });
  const [resumeState, setResumeState] = useState({});
  const [selectedSection, setSelectedSection] = useState(null);
  // const [labelFooter, setLabelFooter] = useState('History completed');
  const [showViewDetail, setShowViewDetail] = useState(null);
  const [selectedCard, setSelectedCard] = useState(null);
  const [isEditCard, setIsEditCard] = useState(false);
  const [showNewReport, setShowNewReport] = useState(false);

  const onClickViewDetail = view => {
    setShowViewDetail(view);
  };

  const onCloseViewDetail = () => {
    setShowViewDetail(null);
    if (onClose && !showViewDetail) {
      onClose();
    }
  };

  const onClickNewReport = () => {
    setShowNewReport(true);
  };

  useEffect(() => {
    const section = priorityListCard.find(item => item.type === showViewDetail);
    setSelectedSection(section);
  }, [showViewDetail]);

  const onClickCard = (card, isEdit = false) => {
    setSelectedCard(card);
    setIsEditCard(isEdit);
  };

  const onCloseCard = isSubmitted => {
    if (isSubmitted && typeof isSubmitted === 'boolean') {
      dispatch(profileActionCreators.fetchDomainTypes(participantId));
    }
    setSelectedCard(null);
    setIsEditCard(false);
    setShowNewReport(false);
  };

  const isDomainType = type => {
    return [
      PrioritiesCard.History,
      PrioritiesCard.SocialDeterminants,
      PrioritiesCard.LifeEvents,
      PrioritiesCard.Symptoms,
      PrioritiesCard.Diagnoses,
      PrioritiesCard.Medications,
      PrioritiesCard.SideEffects,
      PrioritiesCard.SubstanceUse,
    ].includes(type);
  };

  const getItemsDetail = type => {
    switch (type) {
      case PrioritiesCard.SocialDeterminants:
        return socialDeterminants[1]?.relatedElements || [];
      case PrioritiesCard.LifeEvents:
        return lifeEvents[1]?.relatedElements || [];
      case PrioritiesCard.Symptoms:
        return symptoms[1]?.relatedElements || [];
      case PrioritiesCard.Diagnoses:
        return diagnoses[1]?.relatedElements || [];
      case PrioritiesCard.Medications:
        return medications[1]?.relatedElements || [];
      case PrioritiesCard.SideEffects:
        return sideEffects[1]?.relatedElements || [];
      case PrioritiesCard.SubstanceUse:
        return substanceUse[1]?.relatedElements || [];
      default:
        return resumeState[type]?.items || [];
    }
  };

  const getInfoDataByType = useCallback(
    type => {
      if (isDomainType(type)) {
        if (type === PrioritiesCard.History) {
          return {
            badge: historyItems.length,
            subtitle: lastUpdated,
          };
        }
        let domainByType = null;
        switch (type) {
          case PrioritiesCard.SocialDeterminants:
            domainByType = socialDeterminants;
            break;
          case PrioritiesCard.LifeEvents:
            domainByType = lifeEvents;
            break;
          case PrioritiesCard.Symptoms:
            domainByType = symptoms;
            break;
          case PrioritiesCard.Diagnoses:
            domainByType = diagnoses;
            break;
          case PrioritiesCard.Medications:
            domainByType = medications;
            break;
          case PrioritiesCard.SideEffects:
            domainByType = sideEffects;
            break;
          case PrioritiesCard.SubstanceUse:
            domainByType = substanceUse;
            break;
          default:
            break;
        }
        if (domainByType) {
          return {
            badge: domainByType[1]?.relatedElements?.length || 0,
            domains: domainByType[1]?.relatedElements || [],
            subtitle: domainByType[1]?.relatedElements?.[0]?.assignedAt,
          };
        }
      }
      if (type === PrioritiesCard.PlanItems) {
        return {
          badge: planItemList?.length,
        };
      }
      return {
        subtitle: resumeState[type]?.lastUpdated,
        badge: resumeState[type]?.items?.length || 0,
      };
    },
    [
      planItemList,
      resumeState,
      substanceUse,
      sideEffects,
      medications,
      diagnoses,
      symptoms,
      lifeEvents,
      socialDeterminants,
      lastUpdated,
    ],
  );

  const getDomainOptionsByType = type => {
    let domain = substanceUse;
    switch (type) {
      case PrioritiesCard.SocialDeterminants:
        domain = socialDeterminants;
        break;
      case PrioritiesCard.LifeEvents:
        domain = lifeEvents;
        break;
      case PrioritiesCard.Symptoms:
        domain = symptoms;
        break;
      case PrioritiesCard.Diagnoses:
        domain = diagnoses;
        break;
      case PrioritiesCard.Medications:
        domain = medications;
        break;
      case PrioritiesCard.SideEffects:
        domain = sideEffects;
        break;
      case PrioritiesCard.SubstanceUse:
        domain = substanceUse;
        break;
      default:
        break;
    }
    return {
      domainOptions: domain[2] || [],
      lookupData: domain[3],
    };
  };

  const fetchPastAppointment = useCallback(async id => {
    try {
      const { data } = await getPastAppointments({ userId: id }, { fetchNoShow: false });
      setResumeState(s => ({
        ...s,
        pastAppointments: {
          items: data.map(item => ({
            ...item,
            status: AppointmentStatus.FULFILLED,
            startTime: item.completedAt,
            serviceDuration: 45,
          })),
          lastUpdated: data[data.length - 1]?.completedAt,
        },
      }));
    } catch (err) {}
  }, []);

  const fetchUserGroups = useCallback(async id => {
    try {
      const { data } = await getUserGroups({ userId: id });
      setResumeState(s => ({
        ...s,
        groups: {
          items: data,
          lastUpdated: data[data.length - 1]?.joinedAt,
        },
      }));
    } catch (err) {}
  }, []);

  const fetchChatbots = useCallback(async id => {
    try {
      const { data } = await getChatbots({ userId: id });
      setResumeState(s => ({
        ...s,
        chatbots: {
          items: data,
          lastUpdated: data[data.length - 1]?.assignedAt,
        },
      }));
    } catch (err) {}
  }, []);

  const fetchNotes = async id => {
    try {
      const { data } = await getMemberNotes({ userId: id });
      const date = data?.patientContactNotes[0]?.createdAt;
      let day;
      let month;
      let year;
      if (date) {
        [day, month, year] = date.split('/');
      }
      setResumeState(s => ({
        ...s,
        notes: {
          items: data?.patientContactNotes,
          lastUpdated: !day || !month || !year ? undefined : new Date(year, month, day).toDateString(),
        },
      }));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (participantId) {
      void fetchPastAppointment(participantId);
      void fetchUserGroups(participantId);
      void fetchChatbots(participantId);
      void fetchNotes(participantId);
      dispatch(conversationActionCreators.fetchAssignedPlanItems({ memberId: participantId }));
    }
  }, [participantId]);

  const onCloseAddNewReport = isSubmitted => {
    if (isSubmitted) {
      setShouldUpdateDomainType(s => !s);
    }
    setShowNewReport(false);
  };

  const refetchNotes = useCallback(() => {
    void fetchNotes(participantId);
  }, [participantId]);

  return (
    <>
      {selectedCard && (
        <Box className={classes.viewCard}>
          {isEditCard ? (
            <UpdatePriorityCard
              card={selectedCard}
              onCloseCard={onCloseCard}
              patientId={participantId}
              sectionType={selectedSection?.type}
              isNoDrawer
            />
          ) : (
            <ViewPriorityCard
              card={selectedCard}
              onClickNewReport={onClickNewReport}
              onCloseCard={onCloseCard}
              sectionType={selectedSection?.type}
              isNoDrawer
            />
          )}
        </Box>
      )}
      {showNewReport && (
        <Box className={classes.viewCard}>
          <CreateNewReport
            sectionType={selectedSection?.type}
            onCloseCard={onCloseAddNewReport}
            isNoDrawer
            memberId={participantId}
            {...getDomainOptionsByType(showViewDetail)}
          />
        </Box>
      )}
      <Box className={classes.container}>
        <Box className={classes.header}>
          {(!!showViewDetail || hasBack) && (
            <IconButton icon="chevron-left" onClick={onCloseViewDetail} className={classes.backBtn} />
          )}
          <Avatar
            name={appointment.participantName}
            src={appointment.participantImage}
            variant={avatarType.CIRCLE}
            size={56}
          />
          <Heading weight={fontWeight.BOLD} className={classes.title}>
            {appointment.participantName}&apos;s {selectedSection?.title || 'Priorities'}
          </Heading>
        </Box>
        <Box className={clsx(classes.body, { [classes.body2]: !showViewDetail })}>
          {showViewDetail ? (
            <CurrentView
              view={showViewDetail}
              isNoDrawer
              items={getItemsDetail(showViewDetail)}
              onClose={onCloseViewDetail}
              onClickNewReport={onClickNewReport}
              onClickCard={onClickCard}
              appointment={appointment}
              refetch={refetchNotes}
            />
          ) : (
            <Box display="flex" justifyContent="space-between" gap="16px" flexWrap="wrap" padding={4}>
              {priorityListCard.map(item => (
                <InfoCard
                  key={item.title}
                  {...item}
                  {...getInfoDataByType(item.type)}
                  onClick={onClickViewDetail}
                />
              ))}
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

export { PriorityList };
