import { makeStyles } from '@mui/styles';

// colors
import { colors } from '../../../../../packages';

export const useStyles = makeStyles({
  card: {
    position: 'relative',
    backgroundColor: colors.white,
    borderRadius: 8,
    padding: 32,
    width: '100%',
    boxShadow:
      '0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
    '& .legend-badge-value': {
      display: 'inline-block',
      marginLeft: '8px',
      fontSize: 12,
      height: 18,
      lineHeight: '16px',
      fontFamily: 'Manrope',
      fontWeight: '600',
      color: colors.neutral700,
      borderRadius: '20px',
      border: `1px solid ${colors.neutral200}`,
      padding: '1px 8px 0',
    },
    '& text.apexcharts-datalabel-label': {
      whiteSpace: 'normal !important',
    },
    '& .legend-wrap': {
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      gap: 32,
      '&-left': {
        width: 200,
      },
    },
    '& .badges': {
      display: 'flex',
      alignItems: 'center',
      gap: 8,
      '& .badge': {
        borderRadius: 4,
        padding: '4px 8px',
        fontSize: 12,
        lineHeight: '16px',
        fontWeight: 700,
        '&.badge-1': {
          backgroundColor: colors.primary100,
          color: colors.primary600,
        },
        '&.badge-2': {
          backgroundColor: colors.secondary100,
          color: colors.secondary500,
        },
        '&.badge-3': {
          backgroundColor: colors.neutral100,
          color: colors.neutral900,
        },
      },
    },
  },
});
