// colors
import { colors } from '../../../../../packages/colors';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  drawer: {
    '& .MuiPaper-root': {
      maxWidth: '800px !important',
    },
    '& .drawerBody': {
      backgroundColor: colors.neutral50,
      padding: 0,
      minHeight: 'calc(100vh - 80px)',
      overflowY: 'hidden',
    },
    '& $items': {
      height: 'calc(100vh - 113px - 80px)',
    },
  },
  drawerWithSubtitle: {
    '& .drawerBody': {
      minHeight: 'calc(100vh - 108px)',
    },
    '& $items': {
      height: 'calc(100vh - 113px - 108px)',
    },
  },
  container: {
    flex: 1,
    height: '100%',
    overflowY: 'hidden',
  },
  searchWrap: {
    backgroundColor: colors.white,
    padding: 32,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 16,
    borderBottom: `1px solid ${colors.neutral100}`,
  },
  searchInput: {
    width: '100%',
    flex: 1,
    padding: 0,
    borderRadius: 6,
    backgroundColor: colors.white,
    height: 48,
  },
  reportNewBtn: {
    color: colors.white,
  },
  items: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
    backgroundColor: colors.neutral50,
    padding: 32,
    overflowY: 'auto',
    height: 'calc(100vh - 113px - 108px)',
  },
  block: {
    backgroundColor: colors.white,
    borderRadius: 8,
    padding: 32,
    alignItems: 'center',
    boxShadow:
      ' 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04)',
    '&.item-center': {
      justifyContent: 'center',
    },
  },
  blockTitle: {
    fontSize: 12,
    lineHeight: '20px',
    fontWeight: 800,
    minWidth: 192,
    flex: 0.3,
    textTransform: 'uppercase',
    color: colors.secondary500,
  },
  blockSubtitle: {
    fontSize: 16,
    lineHeight: '24px',
    fontWeight: 500,
    flex: 0.4,
  },
  time: {
    fontSize: 14,
    lineHeight: '20px',
    fontWeight: 500,
    color: colors.neutral700,
  },
  ul: {
    margin: 0,
  },
  noItem: {
    color: colors.neutral600,
    fontSize: 16,
  },
  diagnosesCard: {
    '& .border-top': {
      borderTop: `1px solid ${colors.neutral100}`,
    },
  },
});
