import {
  DROPDOWN_FILTER_COLLAPSABLE_TYPE,
  FILTER_COLLAPSABLE_SECTION_TYPE,
} from '../../../packages/ui/organisms/table/filter';

export const tableColumns = [
  { id: 'selected', label: '', sortable: false },
  { id: 'timeStamp', label: 'Date & time', sortable: false },
  { id: 'provider', label: 'Provider', sortable: false },
  { id: 'member', label: 'Member', sortable: false },
  { id: 'status', label: 'Status', sortable: false },
  { id: 'paidAt', label: 'Paid at', sortable: false },
  { id: 'state', label: 'State', sortable: false },
  { id: 'insuranceProvider', label: 'Insurance provider', sortable: false },
  { id: 'amount', label: 'Amount', sortable: false },
  { id: 'cptList', label: 'CPT Codes', sortable: false },
  { id: 'actions', label: '', sortable: false },
];

export const getProviderFilterOptions = providers => {
  return {
    sectionTitle: DROPDOWN_FILTER_COLLAPSABLE_TYPE.PROVIDERS,
    sectionType: FILTER_COLLAPSABLE_SECTION_TYPE.DROPDOWN,
    sectionOptions: providers.map(provider => {
      return {
        label: provider.fullName,
        value: {
          name: provider?.fullName || '',
          id: provider?.providerId || '',
          designation: provider?.designation || '',
          image: provider?.profile || provider?.profileImage || '',
        },
      };
    }),
  };
};

export const multiSelectFilterOptionsMock = providers => {
  return [
    {
      sectionTitle: 'Status',
      sectionType: FILTER_COLLAPSABLE_SECTION_TYPE.DEFAULT,
      sectionOptions: [
        { label: 'Created', value: 'Created' },
        { label: 'Paid', value: 'Paid' },
        { label: 'Rejected', value: 'Rejected' },
      ],
    },
    {
      sectionTitle: 'State',
      sectionType: FILTER_COLLAPSABLE_SECTION_TYPE.DEFAULT,
      sectionOptions: [
        { label: 'Texas', value: 'Texas' },
        { label: 'Florida', value: 'Florida' },
        { label: 'Connecticut', value: 'Connecticut' },
        { label: 'Virginia', value: 'Virginia' },
        { label: 'New Hampshire', value: 'New Hampshire' },
      ],
    },
    {
      sectionTitle: 'Role',
      sectionType: FILTER_COLLAPSABLE_SECTION_TYPE.DEFAULT,
      sectionOptions: [
        { label: 'Nurse Practitioner', value: 'Nurse Practitioner' },
        { label: 'Therapist', value: 'Therapist' },
        { label: 'Counselor', value: 'Counselor' },
        { label: 'Coach', value: 'Coach' },
        { label: 'Care Manager', value: 'Care Manager' },
        { label: 'Physician', value: 'Physician' },
      ],
    },
    {
      sectionTitle: 'Date filter preference',
      sectionType: FILTER_COLLAPSABLE_SECTION_TYPE.TOGGLE,
      sectionOptions: [{ label: 'Filter by paid at date', value: false }],
    },
    getProviderFilterOptions(providers),
  ];
};
