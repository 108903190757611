import { Box, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';

import { colors } from '../../../../../../packages/colors';
import { Icons } from '../../../../../../packages/icons';

import { useStyles } from './InfoCard.styles';
import { CardStatus } from '../profile-section-card';
import { infoCardConstants } from './InfoCard.constants';

const InfoCard = ({ records }) => {
  const classes = useStyles();
  const [hasRecords, setHasRecords] = useState(false);
  const [count, setCount] = useState({
    verified: 0,
    reviewNeed: 0,
    documentNeeded: 0,
    documentAnalyzing: 0,
    discrepancy: 0,
    expired: 0,
    expiringSoon: 0,
    incomplete: 0,
    failed: 0,
  });

  useEffect(() => {
    const tempCount = {
      verified: 0,
      reviewNeed: 0,
      documentNeeded: 0,
      documentAnalyzing: 0,
      discrepancy: 0,
      expired: 0,
      expiringSoon: 0,
      incomplete: 0,
      failed: 0,
    };
    records?.forEach(item => {
      switch (item.documentStatus) {
        case CardStatus.VERIFIED:
          tempCount.verified += 1;
          break;
        case CardStatus.REVIEW_NEEDED:
          tempCount.reviewNeed += 1;
          break;
        case CardStatus.DOCUMENT_NEEDED:
          tempCount.documentNeeded += 1;
          break;
        case CardStatus.DOCUMENT_ANALYSIS:
          tempCount.documentAnalyzing += 1;
          break;
        case CardStatus.DISCREPANCY:
          tempCount.discrepancy += 1;
          break;
        case CardStatus.EXPIRED:
          tempCount.expired += 1;
          break;
        case CardStatus.EXPIRING_SOON:
          tempCount.expiringSoon += 1;
          break;
        case CardStatus.INCOMPLETE:
          tempCount.incomplete += 1;
          break;
        case CardStatus.FAILED:
          tempCount.failed += 1;
          break;
        default:
          break;
      }
    });
    setHasRecords(Object.values(tempCount).some(value => value > 0));
    setCount(tempCount);
  }, [records]);

  if (!hasRecords) {
    return null;
  }

  return (
    <div className={classes.root}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.5, mx: 2, mt: 2, mb: 2 }}>
        {Object.entries(count).map(
          ([key, value]) =>
            value > 0 && (
              <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }} key={key}>
                <Icons glyph={infoCardConstants[key].icon} color={infoCardConstants[key].color} />
                <Typography
                  color={colors.neutral900}
                  sx={{ fontWeight: 500, fontSize: 14, lineHeight: '20px', fontFamily: 'Manrope' }}
                >
                  {infoCardConstants[key].text}
                </Typography>
                <Typography
                  color={colors.neutral600}
                  sx={{ fontWeight: 500, fontSize: 14, lineHeight: '20px', fontFamily: 'Manrope' }}
                >
                  {value} total
                </Typography>
              </Box>
            ),
        )}
      </Box>
    </div>
  );
};

export { InfoCard };
