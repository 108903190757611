import { Box, Checkbox } from '@mui/material';
import { useStyles } from './EvaluationView.styles';
import { useStyles as useRadioStyles } from './SingleSelect.styles';
import { Icons } from '../../../../../../icons';
import { btnType, Button } from '../../../../../atoms/button';
import React, { useEffect, useRef, useState } from 'react';
import { IconButton } from '../../../../../molecules/icon-button';
import { useSelector } from 'react-redux';
import {
  selectAssignedEvaluationState,
  selectEvaluation,
} from '../../../../../../../redux/modules/conversation/selectors';
import { getTimeForEval } from '../../../../../../../utils/CommonUtils';
import { Radio } from '../../../../../atoms/radio';
import { Input, inputType } from '../../../../../atoms/input';
import { RadioGroup, radioGroupType } from '../../../../../molecules/radio-group';
import useEvaluationHead from '../../../../../../../hooks/useEvaluationHead';
import cx from 'clsx';
import { colors } from '../../../../../../colors';
import { Text } from '../../../../../atoms/typography';
import { EVALUATION_QUESTION_TYPES } from '../../../../../../../constants/CommonConstants';
import ElectroCardio from '../../../../../../icons/lotties/lottieIcons/ElectroCardio';
import ThinkingAnimated from '../../../../../../icons/lotties/lottieIcons/ThinkingAnimated';
import clsx from 'clsx';
import FireWorks from '../../../../../../icons/lotties/lottieIcons/FireWorks';
import { Menu } from '../../../../../molecules/menu';
import MedicalFile from '../../../../../../icons/lotties/lottieIcons/MedicalFile';
import QuestionnaireAnimated from '../../../../../../icons/lotties/lottieIcons/QuestionnaireAnimated';

const EvaluationCover = ({ currentEvaluation, promptsVisible, onSkipEvaluation }) => {
  const classes = useStyles();
  return (
    <Box className={classes.evaluationsBox}>
      <Box className={classes.evalHeader}>
        <MedicalFile className={classes.animatedIcon} />
        <Box className={classes.titleBoxWithIcon}>
          <p className={classes.questionTitle}>{currentEvaluation.name}</p>
          <p className={classes.questionSubtitle}>
            {currentEvaluation.evaluationStats?.dctCount} Sections •{' '}
            {getTimeForEval(currentEvaluation.evaluationStats?.totalCb)}
          </p>
        </Box>
      </Box>
      {promptsVisible && (
        <Box className={classes.promptContainer}>
          <Box className={classes.sayBox}>
            <Box className={classes.askContent}>
              <Box className={classes.firstLineLayout}>
                <p className={classes.firstLine}>
                  You may want to <span style={{ fontWeight: 700 }}>say</span> things like:
                </p>
                <Icons glyph={'chat-outlined'} className={classes.sayIcon} />
              </Box>
              <p className={classes.instruction}>
                If you have any questions about why I’m asking you a question, just let me know.
              </p>
            </Box>
          </Box>
        </Box>
      )}
      <Box className={classes.footer}>
        <p className={classes.footerTitle}>No longer relevant? Out of time?</p>
        <Button variant={btnType.TEXT} className={classes.textLink} onClick={onSkipEvaluation}>
          Skip Evaluation
        </Button>
      </Box>
    </Box>
  );
};

const FullPageQuestion = ({
  currentBlock,
  cb,
  viewKey,
  promptsVisible,
  aiMode,
  setInputFocused,
  dctPreviousResponses,
  bodyRef,
}) => {
  const classes = useStyles();
  const { updateValues } = useEvaluationHead();
  const lastResponse = dctPreviousResponses && dctPreviousResponses.length > 0
    && dctPreviousResponses.find(response => response.cbId === cb.cbId);
  const dispatchUserResponse = value => {
    updateValues({ id: currentBlock.cbId, value, blockId: cb.cbId });
  };

  const handleTextInputChange = e => {
    const newValue = e.target.value.trimStart();
    dispatchUserResponse(newValue);
  };

  const handleSelectChange = selectedValue => {
    dispatchUserResponse(selectedValue);
  };

  const usePreviousResponse = () => {
    if (
      cb.cbType === EVALUATION_QUESTION_TYPES.TEXT_INPUT ||
      cb.cbType === EVALUATION_QUESTION_TYPES.RATING_SCALE
    ) {
      dispatchUserResponse(lastResponse.answers[0]?.value);
    } else if (
      cb.cbType === EVALUATION_QUESTION_TYPES.SINGLE_SELECT ||
      cb.cbType === EVALUATION_QUESTION_TYPES.MULTI_SELECT
    ) {
      const choices = cb?.choices
        .filter(ch => {
          return lastResponse.answers.find(el => el.value === ch?.choice);
        })
        .map(ch => ch.choice);
      if (choices.length > 0) {
        if (cb.cbType === EVALUATION_QUESTION_TYPES.SINGLE_SELECT) {
          dispatchUserResponse(choices[0]);
        } else {
          choices.forEach(dispatchUserResponse);
        }
      }
    }
  };
  const singleSelectChoices = cb.ratingScale
    ? cb.ratingScale.values.map(v => ({
        label: String(v),
        value: String(v),
      }))
    : cb.choices?.map(item => ({
        label: item.choice,
        value: item.choice,
      }));
  const textInputValue =
    cb &&
    cb.cbType === 'text-input' &&
    cb.userResponse &&
    cb.userResponse.userResponseList.length > 0 &&
    cb.userResponse.userResponseList[0];
  return (
    <Box className={classes.fullPageQuestionBox} key={viewKey}>
      <Box className={classes.vBoxGap8}>
        <p className={classes.fullPageQuestionTitle}>{cb.text}</p>
        {cb.subtitle && cb.subtitle !== '' && (
          <p className={classes.fullPageQuestionSubtitle}>{cb.subtitle}</p>
        )}
      </Box>
      {cb.prompt && cb.prompt !== '' && promptsVisible && (
        <Box className={classes.hBoxGap4}>
          <Icons glyph="question-outlined" className={classes.fullPagePromptIcon} />
          <p className={classes.fullPageQuestionPrompt}>{cb.prompt}</p>
        </Box>
      )}
      {!aiMode && (
        <>
          {cb.choices &&
            (cb.cbType === 'single-select' ||
              cb.cbType === 'multi-select' ||
              cb.cbType === 'rating-scale') && (
              <Box className={classes.choicesContainer}>
                {cb.cbType === 'multi-select' ? (
                  cb.choices.map((item, index) => (
                    <MultiSelectOption
                      fullPage
                      answerTitle={item.choice}
                      value={item.choice}
                      key={index}
                      currentBlock={cb}
                      onChange={handleSelectChange}
                      scrollRef={bodyRef}
                    />
                  ))
                ) : (
                  <SingleSelectOptions
                    fullPage
                    options={singleSelectChoices}
                    currentBlock={cb}
                    onChange={handleSelectChange}
                    scrollRef={bodyRef}
                    selectedIndex={-1}
                  />
                )}
              </Box>
            )}
          {cb.cbType === 'text-input' && (
            <>
              <Input
                fullWidth
                variant={inputType.DEFAULT}
                placeholder="Enter your answer"
                onFocus={() => setInputFocused(true)}
                onBlur={() => setInputFocused(false)}
                multiline
                onChange={handleTextInputChange}
                minRows={3}
                maxRows={3}
                value={textInputValue || ''}
              />
              {cb.helperText && cb.helperText !== '' && <p className={classes.helperText}>{cb.helperText}</p>}
            </>
          )}
          {lastResponse && (
            <Box className={classes.useAllBoxFP}>
              <Box className={classes.prevResponseDeets}>
                <p className={classes.prevTitle}>Previous response:</p>
                <p className={classes.prevResponse}>
                  {lastResponse.answers.map(ans => ans.value).join(', ')}
                </p>
              </Box>
              <Button
                variant={btnType.OUTLINE}
                className={classes.useBtn}
                onClick={() => {
                  usePreviousResponse();
                }}
              >
                Use
              </Button>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

const DCTCover = ({
  currentBlock,
  promptsVisible,
  dctPreviousResponses,
  dctSelectedResponses,
  onPreviousResponseSelected,
  eligibleForAutodoc,
  aiMode,
  listHelper,
  toggleAiMode,
  setInputFocused,
  useAllPreviousResponses,
}) => {
  const classes = useStyles();
  const totalQuestions = currentBlock.children?.length;
  const requiredQuestions = currentBlock.children?.filter(q => q.required).length;
  const hasPreviousResponses = dctPreviousResponses && dctPreviousResponses.length > 0;
  const responsesText = currentBlock.fullPage
    ? 'Please make sure to ask all the questions here:'
    : hasPreviousResponses
    ? 'Select which previous responses to use:'
    : 'Typical responses:';
  const childQuestions = currentBlock.children.filter(
    cb => cb.cbType !== 'education' && cb.cbType !== 'exercise' && cb.isAttemptable,
  );
  const allPreviousNormal = hasPreviousResponses && dctPreviousResponses.every(response => {
    const childCb = childQuestions.find(cb => cb.cbId === response.cbId);
    const riskyChoices = childCb?.choices?.filter(ans => ans.riskFactor).map(ans => ans.value) || [];
    return response.answers.every(ans => !riskyChoices.includes(ans.value));
  });
  const allPreviousRisky = hasPreviousResponses && dctPreviousResponses.every(response => {
    const childCb = childQuestions.find(cb => cb.cbId === response.cbId);
    const riskyChoices = childCb?.choices?.filter(ans => ans.riskFactor).map(ans => ans.value) || [];
    return response.answers.some(ans => riskyChoices.includes(ans.value));
  });
  const getSectionNumber = dctId => {
    const index = listHelper.getDcts().findIndex(dct => dct.dctId === dctId);
    if (index !== -1) {
      return index + 1;
    }
    return index;
  };
  return (
    <Box className={classes.evaluationsBox}>
      <Box className={classes.titleBox}>
        <QuestionnaireAnimated className={classes.animatedIcon} />
        <p className={classes.questionTitle}>Section {getSectionNumber(currentBlock.dctId)}: {currentBlock.text}</p>
        <p className={classes.questionSubtitle}>
          {totalQuestions} Questions • {requiredQuestions > 0 ? `${requiredQuestions} Required •` : ''} {getTimeForEval(totalQuestions)}
        </p>
      </Box>
      {promptsVisible && currentBlock.prompt && currentBlock.prompt !== '' && (
        <Box className={classes.promptContainer}>
          <Box className={classes.sayBox}>
            <Box className={classes.askContent}>
              <Box className={classes.firstLineLayout}>
                <p className={classes.firstLine}>
                  You may want to <span style={{ fontWeight: 700 }}>say</span> things like:
                </p>
                <Icons glyph={'chat-outlined'} className={classes.sayIcon} />
              </Box>
              <p className={classes.instruction}>{currentBlock.prompt}</p>
            </Box>
          </Box>
        </Box>
      )}
      <Box className={classes.recommendationsBox}>
        {responsesText && <p className={classes.cbTypeDesc}>{responsesText}</p>}
        {currentBlock.fullPage ? (
          <>
            {childQuestions.map(cb => {
              return (
                <FullPageQuestion
                  viewKey={cb.cbId}
                  key={cb.cbId}
                  currentBlock={currentBlock}
                  cb={cb}
                  promptsVisible={promptsVisible}
                  aiMode={eligibleForAutodoc && aiMode}
                  dctPreviousResponses={dctPreviousResponses}
                  setInputFocused={setInputFocused}
                />
              );
            })}
          </>
        ) : (
          <>
            {childQuestions.map(cb => {
              const previousResponse = hasPreviousResponses
                ? dctPreviousResponses.find(prev => prev.cbId === cb.cbId)
                : null;
              const typicalResponse =
                !hasPreviousResponses && cb.choices ? cb.choices.find(choice => !choice.riskFactor) : null;
              const riskyChoices = cb.choices
                ? cb.choices.filter(choice => choice.riskFactor).map(choice => choice.choice)
                : [];
              const safeResponse =
                previousResponse && previousResponse.answers.every(ans => !riskyChoices.includes(ans.value));
              const riskyResponse =
                previousResponse && previousResponse.answers.some(ans => riskyChoices.includes(ans.value));
              return (
                <Box className={classes.dctQuestionBox} key={cb.cbId}>
                  <Box className={classes.questionAnswer}>
                    <p className={classes.cardTitle}>{cb.text}</p>
                    <p
                      className={clsx(classes.cardSubtitle, {
                        [classes.safeResponse]: safeResponse,
                        [classes.riskyResponse]: riskyResponse,
                        [classes.nonSafeNonRiskyResponse]:
                          previousResponse && !safeResponse && !riskyResponse,
                      })}
                    >
                      {!!previousResponse ? (
                        <>{previousResponse.answers.map(ans => ans.value).join(', ')}</>
                      ) : (
                        <>
                          {typicalResponse
                            ? typicalResponse.choice
                            : `No ${hasPreviousResponses ? 'previous' : 'typical'} response`}
                        </>
                      )}
                    </p>
                  </Box>
                  {(!!typicalResponse || !!previousResponse) && (
                    <Radio
                      checked={dctSelectedResponses.some(response => response.cbId === cb.cbId)}
                      onChange={e => {
                        if (previousResponse) {
                          onPreviousResponseSelected(cb.cbId, previousResponse.answers);
                        } else if (typicalResponse) {
                          onPreviousResponseSelected(cb.cbId, [{ value: typicalResponse.choice }]);
                        }
                      }}
                    />
                  )}
                </Box>
              );
            })}
          </>
        )}
      </Box>
      {hasPreviousResponses && !currentBlock.fullPage && (
        <Box className={classes.useAllBox}>
          <Box className={classes.prevResponseDeets}>
            <p className={classes.prevTitle}>Previous responses:</p>
            <p className={clsx(classes.prevResponse, {
              [classes.allSafeResponses]: allPreviousNormal,
              [classes.allRiskyResponses]: allPreviousRisky,
              [classes.allNonSafeNonRiskyResponses]: !allPreviousNormal && !allPreviousRisky,
            })}>
              {allPreviousNormal ? 'All in normal range'
                : allPreviousRisky ? 'All in risky range'
                : 'Some are in the typical range'
              }
            </p>
          </Box>
          <Button
            variant={btnType.OUTLINE}
            className={classes.useBtn}
            onClick={() => {
              useAllPreviousResponses(allPreviousNormal, allPreviousRisky, false);
            }}
          >
            Use
          </Button>
        </Box>
      )}
      {currentBlock.fullPage ? (
        <AIDocHint eligibleForAutodoc={eligibleForAutodoc} aiMode={aiMode} toggleAiMode={toggleAiMode} />
      ) : (
        <Box className={classes.notSelectionBox}>
          <p className={classes.footerTitle}>Anything not selected will be asked again</p>
        </Box>
      )}
    </Box>
  );
};

const SingleSelectOptions = ({ options, currentBlock, onChange, selectedIndex, scrollRef, fullPage }) => {
  const classes = useRadioStyles({ currentIndex: selectedIndex, choices: options });
  const ref = useRef(null);

  let value = null;
  if (currentBlock && currentBlock.userResponse && currentBlock.userResponse.userResponseList.length > 0) {
    const response = currentBlock.userResponse.userResponseList[0];
    value = options.find(op => op.value === response);
  }

  useEffect(() => {
    if (ref?.current && scrollRef?.current && selectedIndex > -1) {
      scrollRef.current.scrollTop =
        ref.current.offsetTop + selectedIndex * 60 - scrollRef.current.offsetTop - 60;
    }
  }, [scrollRef, selectedIndex, ref]);

  return (
    <Box className={fullPage ? classes.radioGroupRootFP : classes.radioGroupRoot} ref={ref}>
      <RadioGroup
        variant={radioGroupType.VERTICAL}
        options={options}
        value={value?.value || null}
        onChange={(e, v) => {
          onChange(v);
        }}
      />
    </Box>
  );
};

const MultiSelectOption = ({
  answerTitle,
  value,
  onChange,
  currentBlock,
  focusedChoice,
  scrollRef,
  fullPage,
}) => {
  const classes = useStyles();
  const ref = useRef(null);

  useEffect(() => {
    if (ref?.current && scrollRef?.current) {
      if (focusedChoice) {
        scrollRef.current.scrollTo({
          top: ref.current.offsetTop - 291,
          behavior: 'smooth',
        });
      }
    }
  }, [scrollRef, currentBlock, focusedChoice, ref]);

  let isChecked = false;
  if (
    currentBlock &&
    currentBlock.userResponse &&
    currentBlock.userResponse?.userResponseList.length > 0 &&
    currentBlock.userResponse.userResponseList.includes(value)
  ) {
    isChecked = true;
  }
  return (
    <Box
      className={cx({
        [classes.focusedChoice]: focusedChoice,
        [classes.multiSelectBox]: !fullPage,
        [classes.multiSelectBoxFP]: fullPage,
      })}
      onClick={() => onChange(value)}
      ref={ref}
    >
      <Text className={cx(classes.multiSelectLabel)}>{answerTitle}</Text>
      <Checkbox
        checked={isChecked}
        value={value}
        className={classes.checkbox}
        checkedIcon={
          <Icons
            glyph="checkbox-outlined"
            color={colors.primary}
            className={classes.adjustedRightSectionCheckIcon}
          />
        }
        icon={
          <Icons
            glyph="checkbox-blank-outlined"
            color={colors.neutral400}
            className={classes.adjustedRightSectionCheckIcon}
          />
        }
      />
    </Box>
  );
};

const Question = ({
  currentBlock,
  promptsVisible,
  aiMode,
  toggleAiMode,
  setInputFocused,
  bodyRef,
  selectedIndex,
  leftTrigger,
  rightTrigger,
  eligibleForAutodoc,
  previousResponses,
  dispatchUserResponse,
  usePreviousResponse,
}) => {
  const classes = useStyles();
  const chooseText =
    currentBlock.cbType === 'single-select'
      ? 'Choose the best option'
      : currentBlock.cbType === 'multi-select'
      ? 'Choose all that apply:'
      : null;

  const handleTextInputChange = e => {
    const newValue = e.target.value.trimStart();
    dispatchUserResponse(newValue);
  };

  const handleSelectChange = selectedValue => {
    dispatchUserResponse(selectedValue);
  };

  const getRatingValue = () => {
    if (currentBlock && currentBlock.userResponse && currentBlock.userResponse?.userResponseList.length > 0) {
      return parseInt(currentBlock.userResponse.userResponseList[0], 10);
    }
    return null;
  };

  useEffect(() => {
    if (currentBlock && currentBlock.cbType !== 'single-select') {
      if (currentBlock.cbType === 'rating-scale') {
        if (currentBlock.userResponse && currentBlock.userResponse.userResponseList.length > 0) {
          const ratingValue = getRatingValue();
          if (ratingValue > 0) {
            dispatchUserResponse(ratingValue - 1);
          }
        } else {
          dispatchUserResponse(1);
        }
      } else if (currentBlock.choices && currentBlock.choices.length > 0 && selectedIndex >= 0) {
        const value = currentBlock.choices[selectedIndex].choice;
        if (
          currentBlock.userResponse &&
          currentBlock.userResponse.userResponseList &&
          currentBlock.userResponse.userResponseList.length > 0 &&
          currentBlock.userResponse.userResponseList.includes(value)
        ) {
          dispatchUserResponse(value);
        }
      }
    }
  }, [leftTrigger]);

  useEffect(() => {
    if (currentBlock) {
      if (currentBlock.cbType === 'rating-scale') {
        if (currentBlock.userResponse && currentBlock.userResponse.userResponseList.length > 0) {
          const ratingValue = getRatingValue();
          if (ratingValue < currentBlock.ratingScale?.values?.length) {
            dispatchUserResponse(ratingValue + 1);
          }
        } else {
          dispatchUserResponse(1);
        }
      } else if (currentBlock.choices && currentBlock.choices.length > 0 && selectedIndex >= 0) {
        const value = currentBlock.choices[selectedIndex].choice;
        if (
          !currentBlock.userResponse ||
          !currentBlock.userResponse.userResponseList ||
          currentBlock.userResponse.userResponseList.length === 0 ||
          !currentBlock.userResponse.userResponseList.includes(value)
        ) {
          dispatchUserResponse(value);
        }
      }
    }
  }, [rightTrigger]);

  const textInputValue =
    currentBlock &&
    currentBlock.cbType === 'text-input' &&
    currentBlock.userResponse &&
    currentBlock.userResponse.userResponseList.length > 0 &&
    currentBlock.userResponse.userResponseList[0];
  const singleSelectChoices = currentBlock.cbType === 'rating-scale' && currentBlock.ratingScale
    ? currentBlock.ratingScale.values.map(v => ({
        label: String(v),
        value: String(v),
      }))
    : currentBlock.cbType === 'single-select' && currentBlock.choices?.map(item => ({
        label: item.choice,
        value: item.choice,
      }));
  const lastResponse = previousResponses && previousResponses.length > 0
    && previousResponses.find(response => response.cbId === currentBlock.cbId);
  return (
    <Box className={classes.evaluationsBox}>
      <Box className={classes.titleBox}>
        <p className={classes.questionTitle}>{currentBlock.text}</p>
        {currentBlock.subtitle && currentBlock.subtitle !== '' && (
          <p className={classes.questionSubtitle}>{currentBlock.subtitle}</p>
        )}
      </Box>
      {promptsVisible && currentBlock.prompt && currentBlock.prompt !== '' && (
        <Box className={classes.promptContainer}>
          <Box className={classes.promptBox}>
            <Box className={classes.askContent}>
              <p className={classes.instruction}>{currentBlock.prompt}</p>
            </Box>
          </Box>
        </Box>
      )}
      {(!aiMode || !eligibleForAutodoc) && (
        <>
          <Box className={classes.recommendationsBox}>
            {chooseText && <p className={classes.cbTypeDesc}>{chooseText}</p>}
            {(currentBlock.cbType === 'single-select' ||
                currentBlock.cbType === 'multi-select' ||
                currentBlock.cbType === 'rating-scale') && (
                <Box className={classes.choicesContainer}>
                  {currentBlock.cbType === 'multi-select' ? (
                    currentBlock.choices.map((item, index) => (
                      <MultiSelectOption
                        answerTitle={item.choice}
                        value={item.choice}
                        key={index}
                        currentBlock={currentBlock}
                        onChange={handleSelectChange}
                        scrollRef={bodyRef}
                        focusedChoice={index === selectedIndex}
                      />
                    ))
                  ) : (
                    <SingleSelectOptions
                      options={singleSelectChoices}
                      currentBlock={currentBlock}
                      onChange={handleSelectChange}
                      scrollRef={bodyRef}
                      selectedIndex={selectedIndex}
                    />
                  )}
                </Box>
              )}
            {currentBlock.cbType === 'text-input' && (
              <Box className={classes.inputBox}>
                <Input
                  fullWidth
                  variant={inputType.DEFAULT}
                  placeholder="Enter your answer"
                  onFocus={() => setInputFocused(true)}
                  onBlur={() => setInputFocused(false)}
                  multiline
                  onChange={handleTextInputChange}
                  minRows={3}
                  maxRows={3}
                  value={textInputValue || ''}
                />
                {currentBlock.helperText && currentBlock.helperText !== '' && (
                  <p className={classes.helperText}>{currentBlock.helperText}</p>
                )}
              </Box>
            )}
          </Box>
          {lastResponse && (
            <Box className={classes.useAllBox}>
              <Box className={classes.prevResponseDeets}>
                <p className={classes.prevTitle}>Previous response:</p>
                <p className={classes.prevResponse}>
                  {lastResponse.answers.map(ans => ans.value).join(', ')}
                </p>
              </Box>
              <Button
                variant={btnType.OUTLINE}
                className={classes.useBtn}
                onClick={() => {
                  usePreviousResponse();
                }}
              >
                Use
              </Button>
            </Box>
          )}
        </>
      )}
      <AIDocHint eligibleForAutodoc={eligibleForAutodoc} aiMode={aiMode} toggleAiMode={toggleAiMode} />
    </Box>
  );
};

const AIDocHint = ({ eligibleForAutodoc, aiMode, toggleAiMode }) => {
  if (!eligibleForAutodoc) {
    return null;
  }
  const classes = useStyles();
  return aiMode ? (
    <Box className={classes.aiBox}>
      <Icons glyph="star" color="#dd0374" />
      <p className={classes.magicText}>This is being automatically captured for you.</p>
      <Button variant={btnType.TEXT} className={classes.textLink} onClick={toggleAiMode}>
        Manually select
      </Button>
    </Box>
  ) : (
    <Box className={classes.manualBox}>
      <p className={classes.magicText}>Want AI to do it for you?</p>
      <Button variant={btnType.TEXT} className={classes.textLink} onClick={toggleAiMode}>
        Back to AI documentation mode
      </Button>
    </Box>
  );
};

const EvaluationRecommendations = ({
  hasEvaluations,
  recommendations,
  onAddEvaluation,
  openEvaluationsDrawer,
  onRejectEvaluation,
}) => {
  const classes = useStyles();
  return (
    <>
      <Box className={classes.recommendationsBox}>
        {!hasEvaluations && (
          <p className={classes.recommendationsTitle}>Not required, but recommended by us:</p>
        )}
        <Box className={classes.recommendations}>
          {recommendations.map(evaluation => {
            const stats = evaluation.evaluationStats;
            return (
              <Box className={classes.card} key={evaluation.evaluationId || evaluation.id}>
                <Box className={classes.cardContent}>
                  <p className={classes.cardTitle}>{evaluation.name}</p>
                  {stats && (
                    <p className={classes.cardSubtitle}>
                      {stats?.totalCb} Questions • {stats?.totalRequiredCb} Required •{' '}
                      {getTimeForEval(stats?.totalCb)}
                    </p>
                  )}
                </Box>
                {hasEvaluations ? (
                    <Menu
                      icon="more"
                      disabled={evaluation.status === 'COMPLETED'}
                      className={classes.menu}
                      itemsWrapperClassName={classes.menuItemsWrapper}
                      items={[
                        {
                          label: 'Reject evaluation',
                          icon: <Icons glyph="close-circle-outlined" className={classes.icon} />,
                          onClick: () => {
                            onRejectEvaluation(evaluation);
                          },
                        },
                      ]}
                    />
                  // <IconButton icon="more" className={classes.moreIcon} onClick={() => {}} />
                ) : (
                  <Button
                    variant={btnType.OUTLINE}
                    className={classes.useBtn}
                    onClick={() => {
                      onAddEvaluation([evaluation.evaluationId]);
                    }}
                  >
                    Use
                  </Button>
                )}
              </Box>
            );
          })}
        </Box>
      </Box>
      <Box className={classes.footer}>
        <p className={classes.footerTitle}>
          {hasEvaluations ? 'Missing something?' : 'Want to pick your own?'}
        </p>
        <Button variant={btnType.TEXT} className={classes.textLink} onClick={openEvaluationsDrawer}>
          Add Evaluation
        </Button>
      </Box>
    </>
  );
};

const EvaluationView = ({
  promptsVisible,
  openEvaluationsDrawer,
  jumpToOpenSession,
  onAddEvaluation,
  currentEvaluation,
  currentBlock,
  setTextInputInFocus,
  bodyRef,
  selectedIndex,
  leftTrigger,
  listHelper,
  rightTrigger,
  eligibleForAutodoc,
  dctPreviousResponses,
  dctSelectedResponses,
  evaluationsCompleted,
  onPreviousResponseSelected,
  previousResponses,
  useAllPreviousResponses,
  onSkipEvaluation,
  onRejectEvaluation,
}) => {
  const classes = useStyles();
  const [aiMode, setAiMode] = useState(true);
  const { evaluations } = useSelector(selectAssignedEvaluationState);
  const allEvaluations = useSelector(selectEvaluation);
  const hasEvaluations = evaluations?.filter(ev => ev.status !== 'REJECTED').length > 0;
  const unassignedEvaluations = allEvaluations.filter(
    e => !evaluations?.map(ev => ev.id).includes(e.evaluationId),
  );
  const recommendations = hasEvaluations ? evaluations.filter(ev => ev.status !== 'REJECTED') : unassignedEvaluations.slice(0, 3);
  const { updateValues } = useEvaluationHead();
  let multiSelectElementValues = [];
  if (
    currentBlock?.cbType === EVALUATION_QUESTION_TYPES.MULTI_SELECT ||
    currentBlock?.cbType === EVALUATION_QUESTION_TYPES.SINGLE_SELECT
  ) {
    multiSelectElementValues = previousResponses[0]?.answers || [];
  }

  const dispatchUserResponse = value => {
    updateValues({ id: currentBlock.cbId, value });
  };

  const usePreviousResponse = () => {
    if (
      currentBlock.cbType === EVALUATION_QUESTION_TYPES.TEXT_INPUT ||
      currentBlock.cbType === EVALUATION_QUESTION_TYPES.RATING_SCALE
    ) {
      dispatchUserResponse(previousResponses[0]?.answers[0]?.value);
      currentBlock.evaluationResponses = [previousResponses[0]?.answers[0]?.value];
    } else if (
      currentBlock.cbType === EVALUATION_QUESTION_TYPES.SINGLE_SELECT ||
      currentBlock.cbType === EVALUATION_QUESTION_TYPES.MULTI_SELECT
    ) {
      const choices = currentBlock?.choices
        .filter(ch => {
          return multiSelectElementValues?.find(el => el.value === ch?.choice);
        })
        .map(ch => ch.choice);
      if (choices.length > 0) {
        if (currentBlock.cbType === EVALUATION_QUESTION_TYPES.SINGLE_SELECT) {
          dispatchUserResponse(choices[0]);
          currentBlock.evaluationResponses = [choices[0]];
        } else {
          choices.forEach(dispatchUserResponse);
          currentBlock.evaluationResponses = choices;
        }
      }
    }
  };

  const toggleAiMode = () => {
    setAiMode(v => !v);
  };

  return (
    <Box className={classes.container}>
      {evaluationsCompleted ? (
        <Box className={classes.evaluationsBox}>
          <Box className={classes.headerFrame}>
            <FireWorks className={classes.animatedIcon} />
            <p className={classes.noEvaluationsTitle}>Nice job!</p>
            <p className={classes.cardTitle}>You’ve completed all assigned evaluations!</p>
          </Box>
          <EvaluationRecommendations
            recommendations={unassignedEvaluations.slice(0, 3)}
            onAddEvaluation={onAddEvaluation}
            openEvaluationsDrawer={openEvaluationsDrawer}
          />
        </Box>
      ) : (
        <>
          {!currentEvaluation && (
            <Box className={classes.evaluationsBox}>
              <Box className={classes.headerFrame}>
                {hasEvaluations ? (
                  <ElectroCardio className={classes.animatedIcon} />
                ) : (
                  <ThinkingAnimated className={classes.animatedIcon} />
                )}
                <p className={hasEvaluations ? classes.sectionTitle : classes.noEvaluationsTitle}>
                  {hasEvaluations ? 'It’s time to collect some important data' : 'Whoops!'}
                </p>
                <p className={classes.sectionSubtitle}>
                  {hasEvaluations
                    ? 'Make sure everything looks relevant'
                    : 'Looks like there are no assigned evaluations'}
                </p>
              </Box>
              <EvaluationRecommendations
                recommendations={recommendations}
                hasEvaluations={hasEvaluations}
                onAddEvaluation={onAddEvaluation}
                onRejectEvaluation={onRejectEvaluation}
                openEvaluationsDrawer={openEvaluationsDrawer}
              />
            </Box>
          )}
          {!currentEvaluation && (
            <Box className={classes.card}>
              <p className={classes.skipText}>Start with Open Session instead?</p>
              <Button
                variant={btnType.TEXT}
                className={classes.textLink}
                endIcon={<Icons glyph="square-arrow-up-right" />}
                onClick={jumpToOpenSession}
              >
                Skip ahead
              </Button>
            </Box>
          )}
          {currentEvaluation && !currentBlock && (
            <EvaluationCover currentEvaluation={currentEvaluation} promptsVisible={promptsVisible} onSkipEvaluation={onSkipEvaluation} />
          )}
          {currentBlock && (
            <>
              {currentBlock.cbType === 'dct' ? (
                <DCTCover
                  currentBlock={currentBlock}
                  promptsVisible={promptsVisible}
                  dctPreviousResponses={dctPreviousResponses}
                  dctSelectedResponses={dctSelectedResponses}
                  onPreviousResponseSelected={onPreviousResponseSelected}
                  aiMode={aiMode}
                  listHelper={listHelper}
                  eligibleForAutodoc={eligibleForAutodoc}
                  toggleAiMode={toggleAiMode}
                  setInputFocused={setTextInputInFocus}
                  useAllPreviousResponses={useAllPreviousResponses}
                />
              ) : (
                <Question
                  currentBlock={currentBlock}
                  promptsVisible={promptsVisible}
                  toggleAiMode={toggleAiMode}
                  setInputFocused={setTextInputInFocus}
                  bodyRef={bodyRef}
                  selectedIndex={selectedIndex}
                  leftTrigger={leftTrigger}
                  rightTrigger={rightTrigger}
                  aiMode={aiMode}
                  previousResponses={previousResponses}
                  eligibleForAutodoc={eligibleForAutodoc}
                  usePreviousResponse={usePreviousResponse}
                  dispatchUserResponse={dispatchUserResponse}
                />
              )}
            </>
          )}
        </>
      )}
    </Box>
  );
};
export { EvaluationView };
