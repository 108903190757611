import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Stack } from '@mui/material';
import {
  Badge,
  badgeStyle,
  badgeType,
  colors,
  fontWeight,
  Heading,
  headingLevel,
  Icons,
  Menu,
} from '../../../packages';
import { Filter, Table } from '../../../packages/ui/organisms/table';
import { ProfileInfo } from '../../../packages/ui/templates/profile-info';
import { CONTACT_NOTES_FLAGS, NoteStyle } from '../../../packages/constants/CommonConstants';
import { getALLContactNotesCount, getNotesWithFilters } from '../../../services/member/member.service';
import { getSpecialStatus } from '../../provider/member-detail/components/Notes/AddNoteDrawer/AddNoteForm';
import dayjs from '../../../utils/dayjs';
import { BaseLayout } from '../../../layouts/base';
import AddNoteDrawer from '../../../pages/provider/member-detail/components/Notes/AddNoteDrawer/AddNoteDrawer';
import NoteDrawer from '../../../pages/provider/member-detail/components/Notes/NoteDrawer';
import { GLOBAL_DATE_FORMAT } from '../../../constants/CommonConstants';
import { sentenceCase } from 'sentence-case';

import {
  allNotesFilterOption,
  generalNotesFilterOption,
  getFilterValue,
  getStatus,
  labRequestNotesNotesFilterOption,
  newNotesFilterOption,
  noteColumns,
  NOTES_STATUS_CATEGORIES,
  releaseOfInformationNotesFilterOption,
} from './NotesList.constants';

// styles
import { useStyles } from './NotesList.styles';
import MultiColorProgressBar from './multi-color-progress-bar/MultiColorProgressBar';

const getTitle = type => {
  switch (type) {
    case 'general':
      return 'General';
    case 'contact':
      return 'Contact';
    case 'prohibitive':
      return 'Prohibitive';
    case 'caution':
      return 'Cautionary';
    case 'release-of-information':
      return 'Release of Information';
    case 'lab-request':
      return 'Lab Request';
    case 'pharmacy-assistance':
      return 'Pharmacy Assistance';
    case 'referral-out':
      return 'Referral Out';
    case 'prior-authorization':
      return 'Prior Authorization';
    case 'all':
      return 'All';
    default:
      return 'General';
  }
};
const getNoteType = type => {
  switch (type) {
    case 'general':
      return CONTACT_NOTES_FLAGS.GENERAL;
    case 'contact':
      return CONTACT_NOTES_FLAGS.CONTACT;
    case 'prohibitive':
      return CONTACT_NOTES_FLAGS.PROHIBITIVE;
    case 'caution':
      return CONTACT_NOTES_FLAGS.CAUTION;
    case 'release-of-information':
      return CONTACT_NOTES_FLAGS.RELEASE_OF_INFORMATION;
    case 'lab-request':
      return CONTACT_NOTES_FLAGS.LAB_REQUEST;
    case 'pharmacy-assistance':
      return CONTACT_NOTES_FLAGS.PHARMACY_ASSISTANCE;
    case 'referral-out':
      return CONTACT_NOTES_FLAGS.REFERRAL_OUT;
    case 'prior-authorization':
      return CONTACT_NOTES_FLAGS.PRIOR_AUTHORIZATION;
    case 'all':
      return [
        CONTACT_NOTES_FLAGS.GENERAL,
        CONTACT_NOTES_FLAGS.CONTACT,
        CONTACT_NOTES_FLAGS.PROHIBITIVE,
        CONTACT_NOTES_FLAGS.CAUTION,
        CONTACT_NOTES_FLAGS.RELEASE_OF_INFORMATION,
        CONTACT_NOTES_FLAGS.LAB_REQUEST,
        CONTACT_NOTES_FLAGS.PHARMACY_ASSISTANCE,
        CONTACT_NOTES_FLAGS.REFERRAL_OUT,
        CONTACT_NOTES_FLAGS.PRIOR_AUTHORIZATION,
      ];
    default:
      return CONTACT_NOTES_FLAGS.GENERAL;
  }
};
const NotesList = () => {
  const classes = useStyles();
  const { type } = useParams();
  const [isOpenAddNote, setIsOpenAddNote] = useState(false);
  const [totalNotes, setTotalNotes] = useState(0);
  const [resetMultiSelectFilter, setResetMultiSelectFilter] = useState(false);
  const [screenChangeLoading, setScreenChangeLoading] = useState(false);

  const [tableParams, setTableParams] = useState({
    search: {
      searchKey: '',
      dateFilter: {
        startDate: '',
        endDate: '',
      },
      multiSelectFilter: {},
    },
    pagination: { currentPage: 0, rowsPerPage: 10 },
    sorter: { direction: 'desc', column: '' },
  });

  const multiSelectFilterOptions = useMemo(() => {
    setTableParams({
      search: {
        searchKey: '',
        dateFilter: {
          startDate: '',
          endDate: '',
        },
        multiSelectFilter: {},
      },
      pagination: { currentPage: 0, rowsPerPage: 10 },
      sorter: { direction: 'desc', column: '' },
    });
    if (
      tableParams.search?.multiSelectFilter &&
      Object.keys(tableParams.search?.multiSelectFilter)?.length > 0
    ) {
      setResetMultiSelectFilter(true);
    }
    setScreenChangeLoading(true);
    setTimeout(() => {
      setScreenChangeLoading(false);
    }, 100);
    switch (getNoteType(type)) {
      case CONTACT_NOTES_FLAGS.GENERAL:
      case CONTACT_NOTES_FLAGS.CONTACT:
      case CONTACT_NOTES_FLAGS.PROHIBITIVE:
      case CONTACT_NOTES_FLAGS.CAUTION:
        return generalNotesFilterOption();
      case CONTACT_NOTES_FLAGS.RELEASE_OF_INFORMATION:
        return releaseOfInformationNotesFilterOption();
      case CONTACT_NOTES_FLAGS.LAB_REQUEST:
        return labRequestNotesNotesFilterOption();
      case CONTACT_NOTES_FLAGS.PHARMACY_ASSISTANCE:
      case CONTACT_NOTES_FLAGS.PRIOR_AUTHORIZATION:
      case CONTACT_NOTES_FLAGS.REFERRAL_OUT:
        return newNotesFilterOption();

      default:
        return allNotesFilterOption();
    }
  }, [type]);

  const getNotesTypeWithFilters = () => {
    if (type === 'all') {
      if (
        tableParams.search?.multiSelectFilter &&
        Object.keys(tableParams.search?.multiSelectFilter)?.length > 0
      ) {
        return tableParams.search.multiSelectFilter['Note Types']?.map(item => {
          return getFilterValue(item) ?? item;
        });
      }
      return getNoteType(type);
    }
    return [getNoteType(type)];
  };

  const [notesOLD, setNotes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedNote, setSelectedNote] = useState(null);
  const [totalRecords, setTotalRecords] = useState(0);
  const [countsByType, setCountsByType] = useState([]);
  const [countsByStatus, setCountsByStatus] = useState([]);
  const [fetchNotesOnce, setFetchNotesOnce] = useState(true);
  const navigate = useNavigate();
  const generateParams = () => {
    return {
      orderBy: tableParams.sorter.direction || 'asc',
      pageNumber: tableParams.pagination.currentPage <= 0 ? 0 : tableParams.pagination.currentPage - 1,
      pageSize: tableParams.pagination.rowsPerPage || 10,
      sortBy: tableParams.sorter.column || 'lastModified',
    };
  };
  const formatNotes = noteList => {
    return noteList.map(note => {
      return {
        ...note,
        provider: note.createdBy,
        member: {
          firstName: note.memberFirstName,
          lastName: note.memberLastName,
          id: note.patientId,
          nickName: note.memberNickName,
          uuid: note?.memberUuid,
        },
        attachmentsCount: note.attachments?.length ?? 0,
        actions: { id: note.notesId, noteObj: note },
        lastModified: {
          date: dayjs(note.lastModified).format(GLOBAL_DATE_FORMAT),
          time: dayjs(note.lastModified).format('hh:mm A'),
        },
        type: note.flag ?? '-',
        status: note.status ?? '-',
      };
    });
  };
  const getStatusFilter = () => {
    const filteredStatuses = tableParams.search.multiSelectFilter?.['Note Status'] ?? [];
    if (filteredStatuses?.length <= 0) {
      return getStatus();
    }
    return filteredStatuses?.map(item => {
      return getFilterValue(item) ?? item;
    });
  };

  const getRequestBody = () => {
    const notesType = getNotesTypeWithFilters();
    const payloadDateFormat = 'YYYY-MM-DD';
    return {
      endDate: tableParams.search.dateFilter.endDate
        ? dayjs(tableParams.search.dateFilter.endDate).format(payloadDateFormat)
        : '',
      notesType: notesType?.filter(
        item =>
          item !== NOTES_STATUS_CATEGORIES.NEEDS_ACTION && item !== NOTES_STATUS_CATEGORIES.NO_ACTION_NEEDED,
      ),
      searchQuery: tableParams.search.searchKey,
      specialStatus: tableParams.search?.multiSelectFilter?.['Action Status']?.[0]
        ? getFilterValue(tableParams.search?.multiSelectFilter?.['Action Status']?.[0])
        : '',
      startDate: tableParams.search.dateFilter.startDate
        ? dayjs(tableParams.search.dateFilter.startDate).format(payloadDateFormat)
        : '',
      status: getStatusFilter(),
    };
  };

  const getTypeCountObject = (key, counts) => {
    switch (key) {
      case 'cautionNotesCount':
        return {
          name: 'Cautionary',
          value: counts[key],
          color: colors.warning500,
        };
      case 'contactNotesCount':
        return {
          name: 'Contact',
          value: counts[key],
          color: colors.rose500,
        };
      case 'generalNotesCount':
        return {
          name: 'General',
          value: counts[key],
          color: colors.primary500,
        };
      case 'labRequestNotesCount':
        return {
          name: 'Lab request',
          value: counts[key],
          color: colors.green500,
        };
      case 'pharmacyAssistanceNotesCount':
        return {
          name: 'Pharmacy assistance',
          value: counts[key],
          color: colors.cyan500,
        };
      case 'priorAuthorizationNotesCount':
        return {
          name: 'Prior authorization',
          value: counts[key],
          color: colors.primary500,
        };
      case 'prohibitiveNotesCount':
        return {
          name: 'Prohibitive',
          value: counts[key],
          color: colors.destructive500,
        };
      case 'referralOutNotesCount':
        return {
          name: 'Referral out',
          value: counts[key],
          color: colors.orange500,
        };
      case 'releaseOfInformationNotesCount':
        return {
          name: 'Release of information',
          value: counts[key],
          color: colors.indigo500,
        };
      default:
        return {
          name: 'General',
          value: counts[key],
          color: colors.primary500,
        };
    }
  };
  const getFormatedCountsByNoteTypes = counts => {
    if (counts) {
      return Object.keys(counts)
        ?.filter(function (key) {
          if (key !== 'totalNotesCount') return key;
          return null;
        })
        ?.map(function (key) {
          return getTypeCountObject(key, counts);
        });
    }
    return null;
  };
  const getStatusCountObject = (key, counts, statusCounts) => {
    switch (key) {
      case 'receivedByCareManagementCount':
      case 'reportedCount':
      case 'sentToMemberCount':
      case 'requestedCount':
      case 'sentToLabCount':
      case 'submittedCount':
      case 'pendingCount':
      case 'SentToRequestedContactCount':
        statusCounts[0].value += counts[key];
        break;
      case 'reviewedByManagementCount':
      case 'resolvedCount':
      case 'completedCount':
      case 'unsuccessfulCount':
      case 'removedCount':
      case 'rejectedCount':
      case 'noResponseCount':
        statusCounts[1].value += counts[key];
        break;
      default:
        statusCounts[1].value += counts[key];
        break;
    }
  };
  const getFormatedCountsByNotesStatus = counts => {
    if (counts) {
      const statusCounts = [
        {
          name: 'Needs action',
          color: colors.neutral900,
          value: 0,
        },
        {
          name: 'No action needed',
          color: colors.neutral200,
          value: 0,
        },
      ];
      Object.keys(counts)
        ?.filter(key => key !== 'totalNotesCount')
        ?.map(key => getStatusCountObject(key, counts, statusCounts));
      return statusCounts;
    }
    return null;
  };
  const fetchNotes = () => {
    getNotesWithFilters(generateParams(), getRequestBody())
      .then(res => {
        setNotes(formatNotes(res.data.patientContactNotes));
        setTotalRecords(res.data.totalRecords);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });

    getALLContactNotesCount({}, { noteTypes: getNoteType(type) })
      .then(res => {
        setTotalNotes(res.data?.ContactNotesCountByType?.totalNotesCount || 0);
        setCountsByType(getFormatedCountsByNoteTypes(res.data?.ContactNotesCountByType));
        setCountsByStatus(getFormatedCountsByNotesStatus(res.data?.ContactNotesCountByStatus));
      })
      .catch(err => console.log({ err }));
  };

  useEffect(() => {
    if (fetchNotesOnce) {
      setIsLoading(true);
      fetchNotes();
      setFetchNotesOnce(false);
    }
    setTimeout(() => setFetchNotesOnce(true), 500);
  }, [type, tableParams]);

  const onClickNote = note => {
    setSelectedNote(note);
  };

  const onUpdate = function (notesOldNew) {
    if (notesOldNew) {
      setSelectedNote(notesOldNew);
      setIsOpenAddNote(true);
    }
  };
  const onView = function (notesOldNew) {
    if (notesOldNew) {
      setSelectedNote(notesOldNew);
    }
  };
  const getReportTypeIconAndText = noteType => {
    const status = NoteStyle[noteType] || NoteStyle.GENERAL;
    switch (noteType) {
      case CONTACT_NOTES_FLAGS.PROHIBITIVE:
        return (
          <div className={classes.timeWrap}>
            <Icons color={colors.destructive500} glyph="close-circle-outlined" />
            <span>Prohibitive</span>
          </div>
        );
      case CONTACT_NOTES_FLAGS.CAUTION:
        return (
          <div className={classes.timeWrap}>
            <Icons color={colors.warning500} glyph="flag-outlined" />
            <span>Cautionary</span>
          </div>
        );
      case CONTACT_NOTES_FLAGS.RELEASE_OF_INFORMATION:
        return (
          <div className={classes.timeWrap}>
            <Icons color={colors.indigo500} glyph="info-outlined" />
            <span>Release of Information</span>
          </div>
        );
      case CONTACT_NOTES_FLAGS.LAB_REQUEST:
        return (
          <div className={classes.timeWrap}>
            <Icons color={colors.green500} glyph="lab-bottle-outlined" />
            <span>Lab Request</span>
          </div>
        );
      case CONTACT_NOTES_FLAGS.CONTACT:
        return (
          <div className={classes.timeWrap}>
            <Icons color={colors.rose500} glyph="user-report-outlined" />
            <span>Contact</span>
          </div>
        );
      case CONTACT_NOTES_FLAGS.REFERRAL_OUT:
        return (
          <div className={classes.timeWrap}>
            <Icons color={colors.orange500} glyph="profile-arrow-left-outlined" />
            <span>Referral Out</span>
          </div>
        );
      case CONTACT_NOTES_FLAGS.PHARMACY_ASSISTANCE:
        return (
          <div className={classes.timeWrap}>
            <Icons color={colors.cyan500} glyph="pharmacy-outlined" />
            <span>Pharmacy Assistance</span>
          </div>
        );
      case CONTACT_NOTES_FLAGS.PRIOR_AUTHORIZATION:
        return (
          <div className={classes.timeWrap}>
            <Icons color={colors.primary500} glyph="shield-outlined" />
            <span>Prior Authorization</span>
          </div>
        );
      default:
        return (
          <div className={classes.timeWrap}>
            <Icons color={colors[`${status}500`]} glyph="report-outlined" />
            <span>General</span>
          </div>
        );
    }
  };

  const renderColumns = useCallback(() => {
    return noteColumns.map(column => {
      if (column.id === 'provider') {
        return {
          ...column,
          renderCell: ({ profileImage, name, designation, providerId }) => (
            <ProfileInfo
              type="provider"
              photo={profileImage}
              role={designation}
              fullName={name}
              memberId={providerId}
            />
          ),
        };
      }
      if (column.id === 'member') {
        return {
          ...column,
          renderCell: ({ profileImage, firstName, lastName, nickName, id, uuid }) => (
            <ProfileInfo
              type="member"
              photo={profileImage}
              nickName={
                firstName && lastName ? `${firstName || ''} ${lastName || ''}` : nickName || 'No Name'
              }
              fullName={uuid}
              memberId={id}
            />
          ),
        };
      }
      if (column.id === 'lastModified') {
        return {
          ...column,
          renderCell: ({ date, time }) => (
            <div className={classes.tWrap}>
              <div className={classes.date}>{dayjs(date).format('MM/DD/YYYY')}</div>
              <div className={classes.time}>{time}</div>
            </div>
          ),
        };
      }
      if (column.id === 'name') {
        return {
          ...column,
          renderCell: name => <div className={classes.timeWrap}>{name}</div>,
        };
      }
      if (column.id === 'attachmentsCount') {
        return {
          ...column,
          renderCell: attachmentsCount =>
            attachmentsCount > 0 ? (
              <div className={classes.timeWrap}>
                <Icons glyph="attach" color={colors.neutral600} />
                {attachmentsCount}
              </div>
            ) : (
              <div className={classes.timeWrap}>-</div>
            ),
        };
      }
      if (column.id === 'status') {
        return {
          ...column,
          renderCell: status => (
            <Badge
              variant={
                getSpecialStatus(status) === NOTES_STATUS_CATEGORIES.NEEDS_ACTION
                  ? badgeType.OUTLINED
                  : badgeType.FILLED
              }
              style={badgeStyle.DARK}
              className={classes.status}
            >
              {sentenceCase(status || '')}
            </Badge>
          ),
        };
      }
      if (column.id === 'type') {
        return {
          ...column,
          renderCell: noteType => getReportTypeIconAndText(noteType),
        };
      }
      if (column.id === 'actions') {
        return {
          ...column,
          renderCell: ({ noteObj }) => (
            <Menu
              icon="more"
              className={classes.menu}
              itemsWrapperClassName={classes.menuItemsWrapper}
              items={[
                {
                  label: 'Update Note',
                  onClick: () => {
                    onUpdate(noteObj);
                  },
                },
                {
                  label: 'View Detail',
                  onClick: () => {
                    onView(noteObj);
                  },
                },
                {
                  label: 'Go to profile',
                  onClick: () => {
                    navigate(`/admin/members/${noteObj?.patientId}?name=general-information`);
                  },
                },
              ]}
            />
          ),
        };
      }
      return column;
    });
  }, []);
  const onClickAddNewNote = () => {
    setIsOpenAddNote(true);
  };
  const onUpdateNote = note => {
    setSelectedNote(note);
    onClickAddNewNote();
  };
  const handleDeselectNote = () => {
    setSelectedNote(null);
  };

  const onCloseAddNote = () => {
    setIsOpenAddNote(false);
    setSelectedNote(null);
  };
  const renderCountLabel = (count, label) => {
    return (
      <div className={classes.countLabel}>
        <span className={classes.count}>{count}</span>
        {label}
      </div>
    );
  };
  return (
    <BaseLayout>
      <AddNoteDrawer
        isOpen={isOpenAddNote}
        onClose={onCloseAddNote}
        fetchNotes={fetchNotes}
        note={selectedNote}
        memberId={selectedNote?.member?.id}
      />
      <NoteDrawer
        selectedNote={isOpenAddNote ? undefined : selectedNote}
        onClose={handleDeselectNote}
        onUpdateNote={onUpdateNote}
      />
      <Box className={classes.root}>
        <Stack direction="row" justifyContent="space-between" spacing={2} sx={{ marginBottom: 5 }}>
          <Stack direction="row" alignItems="center" gap={2} sx={{ height: 48 }}>
            <Heading className={classes.heading} level={headingLevel.XL} weight={fontWeight.BOLD}>
              {getTitle(type || 'general')} Notes
            </Heading>
            {!isLoading && (
              <Badge className={classes.totalBadge} variant={badgeType.OUTLINED} style={badgeStyle.UNRELATED}>
                {totalRecords ?? '-'} total
              </Badge>
            )}
          </Stack>
        </Stack>
        {totalNotes > 0 && (
          <Box className={classes.topSection} display="flex">
            <Box flex={1}>
              {renderCountLabel(totalNotes, `${totalNotes === 1 ? 'note' : 'notes'} total`)}
              <Stack direction="row" justifyContent="space-between" alignItems="center">
                <MultiColorProgressBar readings={countsByType} totalValue={totalNotes} />
              </Stack>
            </Box>
            <Box flex={1}>
              {renderCountLabel(countsByStatus?.[0]?.value, 'needs action')}
              <Stack direction="row" justifyContent="space-between" alignItems="center">
                <MultiColorProgressBar readings={countsByStatus} totalValue={totalNotes} />
              </Stack>
            </Box>
          </Box>
        )}
        <Box className={classes.appointmentList}>
          {screenChangeLoading ? (
            <div className={classes.loader}>
              <Icons className="rotate linear infinite" glyph="in-progress" color={colors.primary} />
              Loading...
            </div>
          ) : (
            <Table
              searchProps={{
                placeholder: 'Search notes by title, provider and member',
                resetMultiSelectFilter,
                filterProps: {
                  variant: Filter.tableFilterType.MULTIPLE,
                  multiSelectOptions: multiSelectFilterOptions,
                  dateFilter: {
                    startDate: tableParams.search.dateFilter.startDate,
                    endDate: tableParams.search.dateFilter.endDate,
                  },
                },
              }}
              gridProps={{
                columns: renderColumns(),
                data: notesOLD,
                isLoading,
                onRowClick: onClickNote,
              }}
              paginationProps={{
                currentRows: notesOLD?.length || 0,
                totalCount: totalRecords,
                showRowsPerPage: true,
              }}
              value={tableParams}
              onChange={setTableParams}
            />
          )}
        </Box>
      </Box>
    </BaseLayout>
  );
};

export { NotesList };
