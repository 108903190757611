import { badgeStyle } from '../ui/atoms/badge';
import { S3_BUCKET_URLL } from './AwsUrl';

export const AppointmentStatusText = {
  BOOKED: 'Scheduled',
  FULFILLED: 'Completed',
  CANCELLED: 'Cancelled',
  PROPOSED: 'Waiting on Member',
  ACCEPTED: 'Waiting on Member',
  NEEDS_ACTION: 'Waiting on Provider',
  NO_SHOW: 'No Show',
  SIGN_OFF: 'Waiting for Sign Off',
  WAITING_REVIEW: 'Waiting for Supervisor',
  PENDING: 'Pending',
};

export const AppointmentStatusBagde = {
  BOOKED: badgeStyle.LOW,
  FULFILLED: badgeStyle.RESOLVED,
  CANCELLED: badgeStyle.UNRELATED,
  PROPOSED: badgeStyle.HIGH,
  NEEDS_ACTION: badgeStyle.HIGH,
  PENDING: badgeStyle.HIGH,
  NO_SHOW: badgeStyle.CRITICAL,
  SIGN_OFF: badgeStyle.PURPLE,
  WAITING_REVIEW: badgeStyle.MISREPORTED,
};

export const AppointmentStatus = {
  BOOKED: 'BOOKED',
  FULFILLED: 'FULFILLED',
  CANCELLED: 'CANCELLED',
  PROPOSED: 'PROPOSED',
  NEEDS_ACTION: 'NEEDS_ACTION',
  NO_SHOW: 'NO_SHOW',
  PENDING: 'PENDING',
  DECLINED: 'DECLINED',
  INACTIVE: 'INACTIVE',
};

export const SignOffStatus = {
  APPROVED: 'APPROVED',
  REVIEW: 'REVIEW',
  DRAFTED: 'DRAFTED',
};

export const AppointmentType = {
  CURRENT: 'current',
  PENDING: 'pending',
  PAST: 'past',
  CANCELLED: 'cancelled',
};

export const AppointmentStatusDone = [
  AppointmentStatus.FULFILLED,
  AppointmentStatus.CANCELLED,
  AppointmentStatus.NO_SHOW,
];

export const AppointmentStatusPending = [
  AppointmentStatus.NEEDS_ACTION,
  AppointmentStatus.PROPOSED,
  AppointmentStatus.PENDING,
];

export const NoteStyle = {
  PROHIBITIVE: 'destructive',
  CAUTION: 'warning',
  RELEASE_OF_INFORMATION: 'normal',
  LAB_REQUEST: 'normal',
  CONTACT: 'normal',
  GENERAL: 'normal',
};

export const CONTACT_NOTES_FLAGS = {
  PROHIBITIVE: 'PROHIBITIVE',
  CAUTION: 'CAUTION',
  RELEASE_OF_INFORMATION: 'RELEASE_OF_INFORMATION',
  LAB_REQUEST: 'LAB_REQUEST',
  CONTACT: 'CONTACT',
  GENERAL: 'GENERAL',
  PHARMACY_ASSISTANCE: 'PHARMACY_ASSISTANCE',
  REFERRAL_OUT: 'REFERRAL_OUT',
  PRIOR_AUTHORIZATION: 'PRIOR_AUTHORIZATION',
};

export const CalendarView = {
  Month: 'dayGridMonth',
  Week: 'timeGridWeek',
  Day: 'timeGridDay',
};

export const DaysOfWeek = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'];

export const S3_BUCKET_URL = S3_BUCKET_URLL;

export const getTimeZoneText = timeZone => {
  return timeZone?.replace('/', ' ').replace('_', ' ');
};
