import { Box, Grid } from '@mui/material';
import { iconBtnPosition, IconButton } from '../../../packages';

import BoxFrame from '../../../components/v2/BoxFrame';
import Header from '../../../components/v2/Header';
import Typography from '../../../components/v2/Typography';
import { HeadingFont, HeadingLevel, HeadingWeight } from '../Heading/index.types';
import { typography } from '../Typography/index.constant';

const CheckPDMP = ({ patientState }) => {
  const handlePDMP = () => {
    const url =
      patientState === 'Connecticut'
        ? 'https://connecticut.pmpaware.net/login'
        : patientState === 'Texas'
        ? 'https://texas.pmpaware.net/login'
        : patientState === 'Florida'
        ? 'https://florida.pmpaware.net/login'
        : patientState === 'Virginia'
        ? 'https://virginia.pmpaware.net/login'
        : patientState === 'New Hampshire'
        ? 'https://newhampshire.pmpaware.net/login'
        : null;
    if (url) {
      window.open(url, '_blank');
    }
  };

  return (
    <BoxFrame>
      <Grid container>
        <Grid item xs={12} md={9}>
          <Box py={2.5} px={3}>
            <Header
              label={`Check ${patientState} PDMP`}
              fontFamily={HeadingFont.PRIMARY}
              fontVariant={HeadingLevel.SUB}
              fontWeight={HeadingWeight.SEMI_BOLD}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={3} display="flex" justifyContent="flex-end" alignItems="center">
          <Box
            sx={{
              '& button': { gap: 1 },
              '& button svg': {
                width: 16,
                height: 20,
              },
            }}
            pr={2}
          >
            <IconButton
              icon="square-arrow-up-right"
              iconPosition={iconBtnPosition.RIGHT}
              onClick={() => handlePDMP()}
            >
              <Typography {...typography.body.s.bold}>Check PDMP</Typography>
            </IconButton>
          </Box>
        </Grid>
      </Grid>
    </BoxFrame>
  );
};

export default CheckPDMP;
