export const medicationItems = [
  {
    label: 'Category name',
    groupId: 'Category name',
    items: [
      { label: 'Benzodiazepine', value: 'Benzodiazepine' },
      { label: 'Alcohol', value: 'Alcohol' },
      { label: 'Fluvoxamine', value: 'Fluvoxamine' },
      { label: 'Cannabis', value: 'Cannabis' },
    ],
  },
  {
    label: 'Category name 1',
    groupId: 'Category name 1',
    items: [
      { label: 'Category name 1 - 1', value: 'Category name 1 - 1' },
      { label: 'Category name 1 - 2', value: 'Category name 1 - 2' },
      { label: 'Category name 1 - 3', value: 'Category name 1 - 3' },
      { label: 'Category name 1 - 4', value: 'Category name 1 - 4' },
    ],
  },
  {
    label: 'Category name 2',
    groupId: 'Category name 2',
    items: [
      { label: 'Category name 2 - 1', value: 'Category name 2 - 1' },
      { label: 'Category name 2 - 2', value: 'Category name 2 - 2' },
      { label: 'Category name 2 - 3', value: 'Category name 2 - 3' },
      { label: 'Category name 2 - 4', value: 'Category name 2 - 4' },
    ],
  },
];

export const stepTitles = {
  0: 'Step 1 - select substances',
  1: 'Step 2 - substance details',
  2: 'Step 3 - review substances',
};

export const substanceDefaultValues = {
  status: '',
  notes: '',
  method: 'DRINKING',
  lastUse: 'TODAY',
  dose: {
    amount: 1,
    unit: 'MG',
  },
  frequency: 'MULTIPLE_TIMES_DAILY',
  duration: 'DAYS',
  refillable: true,
  refillTimes: {
    amount: 1,
    unit: 'TIMES',
  },
  isInterference: false,
};
