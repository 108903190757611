import { ApiEndpoints } from '../../constants/ApiEndpoints';
import { baseRequest } from '../../utils/HttpClient';

export const getAppointmentsMetrics = queryParams => {
  return baseRequest(ApiEndpoints.GET_APPOINTMENTS_METRICS, null, null, queryParams);
};

export const getAppointmentsMetricsV2 = requestBody => {
  return baseRequest(ApiEndpoints.GET_ELK_APPOINTMENT_REPORT, requestBody, null, null, false, false);
};

export const getFilteredAppointmentsMetrics = bodyRequest => {
  return baseRequest(ApiEndpoints.GET_FILTERED_APPOINTMENTS_METRICS, bodyRequest, null);
};
