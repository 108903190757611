import { ApiEndpoints } from '../../constants/ApiEndpoints';
import { baseRequest } from '../../utils/HttpClient';

export const downloadMemberList = (payload, requestBody) => {
  return baseRequest(
    ApiEndpoints.DOWNLOAD_PATIENTS_BY_USER,
    requestBody || {},
    null,
    payload,
    true,
    true,
    true,
  );
};
export const getProviderProfile = () => {
  return baseRequest(ApiEndpoints.PROVIDER_PROFILE, {}, null, null, true);
};

export const getProviderProfileById = pathParams => {
  return baseRequest(ApiEndpoints.PROVIDER_PROFILE_BY_ID, null, pathParams);
};

export const getProviderAll = (payload, requestBody) => {
  return baseRequest(ApiEndpoints.FETCH_ALL_PROVIDERS, requestBody || {}, null, payload);
};

export const getPatientsList = (payload, requestBody) => {
  return baseRequest(ApiEndpoints.GET_PATIENTS_BY_USER, requestBody || {}, null, payload);
};

export const getDemographicDetails = pathParams => {
  return baseRequest(ApiEndpoints.GET_DEMOGRAPHIC_DETAIL, null, pathParams);
};

export const updateDemographicDetails = (requestBody, pathParams) => {
  return baseRequest(ApiEndpoints.UPDATE_DEMOGRAPHIC_DETAIL, requestBody, pathParams, null, true);
};

export const addBalanceInWallet = bodyRequest => {
  return baseRequest(ApiEndpoints.UPDATE_WALLET_BALANCE, bodyRequest, null, null, true);
};

export const getWalletBalance = connectionId => {
  return baseRequest(ApiEndpoints.GET_WALLET, null, { connectionId }, null, true);
};

export const getUserGroups = pathParams => {
  return baseRequest(ApiEndpoints.USER_GROUPS, null, pathParams);
};

export const getAdminProfile = queryParams => {
  return baseRequest(ApiEndpoints.GET_PROFILE, {}, null, queryParams || null, true);
};

export const getMemberNotes = (pathParams, queryParams) => {
  return baseRequest(
    ApiEndpoints.GET_MEMBER_NOTES,
    null,
    pathParams,
    queryParams && Object.keys(queryParams).length > 0 ? queryParams : null,
  );
};
export const getNotesWithFilters = (queryParams, requestBody) => {
  return baseRequest(ApiEndpoints.GET_NOTES, requestBody, null, queryParams);
};
export const addMemberNotes = requestBody => {
  return baseRequest(ApiEndpoints.ADD_MEMBER_NOTES, requestBody);
};

export const updateMemberNotes = requestBody => {
  return baseRequest(ApiEndpoints.UPDATE_MEMBER_NOTES, requestBody);
};
export const getALLContactNotesCount = (pathParams, queryParams) => {
  return baseRequest(ApiEndpoints.GET_CONTACT_NOTES_COUNT, null, pathParams, queryParams);
};
export const getContactNotesCountByPatient = pathParams => {
  return baseRequest(ApiEndpoints.GET_CONTACT_NOTES_COUNT_BY_PATIENT, null, pathParams);
};
export const getConnections = queryParams => {
  return baseRequest(ApiEndpoints.GET_CONNECTIONS, {}, null, queryParams, true);
};

export const getPaginatedConnections = queryParams => {
  return baseRequest(ApiEndpoints.GET_PAGINATED_CONNECTIONS, {}, null, queryParams, true);
};

export const getConnectionsByStatus = queryParams => {
  return baseRequest(ApiEndpoints.GET_CONNECTIONS_BY_STATUS, {}, null, queryParams, true);
};
export const getDomainTypes = queryParams => {
  return baseRequest(ApiEndpoints.GET_DOMAIN_TYPES, null, null, queryParams);
};

export const getPatientHistory = queryParams => {
  return baseRequest(ApiEndpoints.GET_PATIENT_HISTORY, null, null, queryParams);
};

export const getDomainLookups = queryParams => {
  return baseRequest(ApiEndpoints.GET_DOMAIN_LOOKUP, null, null, queryParams);
};

export const getDomainElements = pathParams => {
  return baseRequest(ApiEndpoints.GET_DOMAIN_ELEMENTS, null, pathParams);
};

export const getDomainGroup = pathParams => {
  return baseRequest(ApiEndpoints.GET_DOMAIN_GROUPS, null, pathParams);
};

export const getDomainElementDetail = queryParams => {
  return baseRequest(ApiEndpoints.GET_DOMAIN_ELEMENT, null, null, queryParams);
};

export const createNewAssociatedTag = bodyRequest => {
  return baseRequest(ApiEndpoints.CREATE_ASSOCIATED_TAG, bodyRequest, null);
};

export const updateAssociatedTag = (bodyRequest, queryParams) => {
  return baseRequest(ApiEndpoints.UPDATE_ASSOCIATED_TAG, bodyRequest, null, queryParams);
};

export const getLevelOfEngagements = (filters = {}) => {
  return baseRequest(ApiEndpoints.GET_LEVEL_ENGAGEMENTS, null, null, filters);
};

export const addLevelOfEngagement = payload => {
  return baseRequest(ApiEndpoints.ADD_LEVEL_ENGAGEMENT, payload);
};

export const updateLevelOfEngagement = (id, payload) => {
  return baseRequest(ApiEndpoints.UPDATE_LEVEL_ENGAGEMENT, payload, { id });
};

export const deleteLevelOfEngagement = id => {
  return baseRequest(ApiEndpoints.DELETE_LEVEL_ENGAGEMENT, null, { id });
};

export const updateLevelOfEngagementUser = pathParams => {
  return baseRequest(ApiEndpoints.UPDATE_LEVEL_ENGAGEMENT_USER, null, pathParams);
};

export const updateChatStatusUser = (pathParams, queryParams) => {
  return baseRequest(ApiEndpoints.UPDATE_CHAT_STATUS_USER, null, pathParams, queryParams);
};

export const setCareTeamMemberData = (bodyRequest, pathParams) => {
  return baseRequest(ApiEndpoints.SET_CARE_TEAM_MEMBER_DATA, bodyRequest, pathParams);
};

export const getCareTeamMember = (pathParams, queryParams) => {
  return baseRequest(ApiEndpoints.GET_CARE_TEAM_MEMBER, null, pathParams, {
    scheduleAppointment: queryParams?.scheduleAppointment || false,
    careNavigator: queryParams?.careNavigator || false,
  });
};

export const getMatchmakers = () => {
  return baseRequest(ApiEndpoints.GET_MATCHMAKERS, null, null);
};
export const updateContactInfo = (bodyRequest, pathParams) => {
  return baseRequest(ApiEndpoints.UPDATE_CONTACT_INFO, bodyRequest, pathParams);
};
export const updateEmergencyInfo = (bodyRequest, pathParams) => {
  return baseRequest(ApiEndpoints.UPDATE_EMERGENCY_INFO, bodyRequest, pathParams);
};

export const suggestConnection = bodyRequest => {
  return baseRequest(ApiEndpoints.SUGGEST_CONNECTION_REQUEST, bodyRequest, null);
};

export const getConversations = () => {
  return baseRequest(ApiEndpoints.GET_CONVERSATIONS, null, null);
};

export const assignChatbot = payload => {
  return baseRequest(ApiEndpoints.ASSIGN_CONVERSATION, payload, null);
};
export const updateProvider = (providerId, payload) => {
  return baseRequest(ApiEndpoints.UPDATE_PROVIDER_PROFILE, payload, { providerId });
};

export const updateOperatingStates = payload => {
  return baseRequest(ApiEndpoints.UPDATE_OPERATING_STATES, payload);
};

export const updateOperatingStatesByAdmin = (payload, pathParams) => {
  return baseRequest(ApiEndpoints.UPDATE_PROVIDER_OPERATING_STATES, payload, pathParams);
};

export const updateService = (id, service) => {
  return baseRequest(ApiEndpoints.UPDATE_SERVICE, service, { id }, null);
};

export const addNewService = service => {
  return baseRequest(ApiEndpoints.ADD_NEW_SERVICE, service, null);
};

export const deleteService = (serviceId, providerId) => {
  return baseRequest(ApiEndpoints.DELETE_SERVICE, null, { serviceId }, { providerId });
};

export const getServiceTypes = () => {
  return baseRequest(ApiEndpoints.GET_SESSION_TYPES, null, null);
};

export const getGroups = queryParams => {
  return baseRequest(ApiEndpoints.GET_ALL_GROUPS, null, null, queryParams);
};

export const getDemographicMetadata = () => {
  return baseRequest(ApiEndpoints.GET_DEMOGRAPHIC_METADATA);
};

export const getMemberInsuranceInfo = pathParams => {
  return baseRequest(ApiEndpoints.GET_INSURANCE_INFO, null, pathParams);
};

// export const getPatientDataDomainElement = (pathParams: { patientId: string }) => {
//   return baseRequest(ApiEndpoints.GET_PATIENT_DOMAIN_ELEMENT, null, pathParams);
// };
export const updateMemberInsuranceInfo = (payload, userId) => {
  return baseRequest(ApiEndpoints.UPDATE_INSURANCE_INFO, payload, userId);
};

export const addMemberInsuranceInfo = payload => {
  return baseRequest(ApiEndpoints.ADD_INSURANCE_INFO, payload, null);
};

export const getServicesOnServiceType = payload => {
  return baseRequest(ApiEndpoints.FETCH_SERVICES_ON_SERVICE_TYPE, payload);
};
export const getServicesOnServiceName = payload => {
  return baseRequest(ApiEndpoints.FETCH_SERVICES_ON_SERVICES_NAME, payload);
};
export const inviteMember = pathParams => {
  return baseRequest(ApiEndpoints.INVITE_MEMBER, null, pathParams);
};
export const getReferralPartners = queryParams => {
  return baseRequest(ApiEndpoints.GET_REFERRAL_PARTNERS, null, null, queryParams);
};

export const createReferralPartner = requestBody => {
  return baseRequest(ApiEndpoints.CREATE_REFERRAL_PARTNER, requestBody);
};

export const updateReferralPartner = (requestBody, pathParams) => {
  return baseRequest(ApiEndpoints.UPDATE_REFERRAL_PARTNER, requestBody, pathParams);
};

export const getAllReferredPatientsByPartnerId = (queryParams, pathParams) => {
  return baseRequest(ApiEndpoints.GET_ALL_REFERRED_PATIENTS_BY_PARTNER, null, pathParams, queryParams);
};

export const getRefferedPartnerMemberStats = pathParams => {
  return baseRequest(ApiEndpoints.GET_REFERRED_PARTNER_MEMBER_STATS, null, pathParams);
};

export const validateInsuranceInfo = userAccountId => {
  return baseRequest(ApiEndpoints.VALIDATE_INSURANCE_INFO, null, { userAccountId });
};

export const getMemberCardTransactions = (pathParams, queryParams) => {
  return baseRequest(ApiEndpoints.GET_PATIENT_CARD_TRANSACTIONS, null, pathParams, queryParams);
};

export const updateMemberIdCard = requestBody => {
  return baseRequest(ApiEndpoints.UPDATE_MEMBER_ID_CARD, requestBody);
};
