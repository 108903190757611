import * as contentful from 'contentful/dist/contentful.browser.min';

const SPACE_ID = process.env.REACT_APP_CONTENTFUL_SPACE_ID || '3hywct1mp2jz';
const ACCESS_TOKEN =
  process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN || 'oxFyKS8f_Arp_I4T72FhTOri38MJUPU8KktmUbyqXpU';
const HOST = 'cdn.contentful.com';

export const ContentfulClient = contentful.createClient({
  space: SPACE_ID,
  accessToken: ACCESS_TOKEN,
  host: HOST,
});

const queryMethod = ContentfulClient.getEntries;

ContentfulClient.getEntries = query => {
  return queryMethod.apply(this, [query]).catch(e => {
    console.error(e);
    return {
      total: 0,
      items: [],
    };
  });
};
