import { Box, Stack } from '@mui/material';
import { btnType, Button, fontWeight, IconButton, Text } from '../../../../../packages';
import { Drawer, drawerType } from '../../../../../packages/ui/organisms/drawer';

import { useStyles } from './ViewAllDrawer.styles';
import DctTable from './dct-table';

const ViewAllDrawer = ({
  isOpen,
  onClose,
  title = '',
  subtitle = '',
  tableColumns = [],
  renderMenuAction,
  data,
  onRowClick,
}) => {
  const classes = useStyles();
  return (
    <Drawer open={isOpen} onClose={onClose} variant={drawerType.NORMAL} className={classes.drawer}>
      {isOpen && (
        <Box className={classes.drawerContent}>
          <Box className={classes.headerDetail}>
            <Stack direction="column">
              <Text className={classes.headerTitle} weight={fontWeight.BOLD}>
                {title}
              </Text>
              <Text className={classes.headerSubtitle}>{subtitle}</Text>
            </Stack>
            <IconButton icon="close" onClick={onClose} className={classes.closeIconBtn} />
          </Box>
          <Box className={classes.drawerTable}>
            <DctTable
              className={classes.tableBordered}
              isShowAll
              tableColumns={tableColumns}
              data={data}
              onRowClick={onRowClick}
              renderMenuAction={renderMenuAction}
            />
          </Box>
          <Box className={classes.drawerFooter}>
            <Button variant={btnType.PRIMARY} onClick={onClose}>
              Close
            </Button>
          </Box>
        </Box>
      )}
    </Drawer>
  );
};

export { ViewAllDrawer };
