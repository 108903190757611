import cx from 'clsx';
// components
import { fontWeight, Text, textLevel } from '../../../../../packages';
import { ProfileInfo } from '../../../../../packages/ui/templates/profile-info';
// styles
import { formatTimeMessage } from '../../../../../utils/CommonUtils';
import { useStyles } from './MessageItem.styles';

const MessageItem = ({ className = '', thumbnail, time, text, name, onClick, firstName, lastName }) => {
  const classes = useStyles();
  return (
    <div className={cx(classes.messageItem, className)} onClick={onClick}>
      <ProfileInfo
        fullName={`${firstName || ''} ${lastName || ''}`}
        nickName={name}
        photo={thumbnail}
        isProvider
      />
      <Text className={classes.text} level={textLevel.S} weight={fontWeight.MEDIUM}>
        {text}
      </Text>
      <Text className={classes.time} level={textLevel.S}>
        {formatTimeMessage(time)}
      </Text>
    </div>
  );
};
export { MessageItem };
