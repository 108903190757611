import { useEffect, useState } from 'react';
import cx from 'clsx';

// mui
import MuiFormGroup from '@mui/material/FormGroup';

// components
import { Checkbox } from '../../atoms/checkbox';
import { FormControlLabel } from '../../atoms/form-control-label';

// styles
import { useStyles } from './CheckboxGroup.styles';

const CheckboxGroup = ({ className = '', options = [], checkAllOption = null, onChange, ...rest }) => {
  const [value, setValue] = useState(rest.value || []);

  const classes = useStyles();

  const onChecked = e => {
    const { name, checked } = e.target;

    // get new value
    let newValue = [];
    if (checked) newValue = value.concat(name);
    else newValue = value.filter(optionValue => optionValue !== name);

    // update value
    setValue(newValue);
    onChange?.(newValue);
  };

  const onAllChecked = e => {
    const { checked } = e.target;

    // get new value
    const newValue = checked ? options.map(({ value: optionValue }) => optionValue) : [];

    // update value
    setValue(newValue);
    onChange?.(newValue);
  };

  useEffect(() => {
    if (rest.value) {
      setValue(rest.value);
    }
  }, [rest.value]);

  const allChecked = value.length === options.length;

  return (
    <MuiFormGroup
      className={cx({
        [classes.root]: true,
        [className || '']: className,
      })}
      {...rest}
    >
      {checkAllOption && (
        <FormControlLabel
          label={checkAllOption?.label}
          control={<Checkbox checked={allChecked} />}
          onChange={onAllChecked}
        />
      )}
      {options.map(({ label, value: optionValue }, idx) => (
        <FormControlLabel
          key={idx}
          name={optionValue}
          label={label}
          control={<Checkbox checked={value.includes(optionValue)} />}
          onChange={onChecked}
        />
      ))}
    </MuiFormGroup>
  );
};

export { CheckboxGroup };
