import { makeStyles } from '@mui/styles';
import { colors } from '../../../../../packages';

export const useStyles = makeStyles({
  container: {
    paddingLeft: '24px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '24px',
  },
  heading: {
    lineHeight: '32px',
    color: colors.neutral900,
    marginTop: '24px',
    marginBottom: '0px',
  },
  memberCount: {
    color: colors.neutral600,
    lineHeight: '24px',
  },
  closeIcon: {
    marginRight: '20px',
  },
  drawer: {
    '& .MuiPaper-root': {
      maxWidth: '800px !important',
    },
    '& .drawerBody': {
      padding: '0px 0px',
      paddingLeft: '32px',
      paddingRight: '32px',
    },
    '& .drawer-footer': {
      display: 'none !important',
    },
  },
  divider: {
    borderColor: colors.neutral100,
  },
  progressBar: {
    padding: '0 !important',
    margin: '0 !important',
    '& .MuiLinearProgress-bar': {
      backgroundColor: colors.neutral900,
      backgroundImage: 'linear-gradient(90deg, #0D0F11 99.5%, white 10px)',
    },
  },
  heading2: {
    display: 'block',
    marginTop: '0px !important',
    padding: '0 !important',
    marginBottom: '16px !important',
    fontSize: '18px',
  },
  memberInfoContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '20px 0px',
  },
  icon: {
    width: '24px !important',
    height: '24px !important',
    marginRight: '8px !important',
  },
  iconTextContainer: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  memberListContainer: {
    marginTop: '24px',
  },
  bodyDivider: {
    borderColor: 'neutral100',
  },
  outreachNeededBox: {
    marginBottom: '24px',
    '& .MuiLinearProgress-root, .MuiLinearProgress-bar': {
      borderRadius: '0px !important',
    },
  },
  profileContainer: {
    maxWidth: '85%',
  },
});
