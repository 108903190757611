import { makeStyles } from '@mui/styles';

// colors
import { colors } from '../../../packages';

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    paddingTop: 88,
    gap: 40,
  },
  header: {
    position: 'absolute',
    top: -48,
    left: -64,
    height: 88,
    width: '100vw',
    borderBottom: `1px solid ${colors.neutral100}`,
    paddingLeft: 32,
    paddingRight: 32,
    display: 'flex',
    alignItems: 'center',
  },
  badge: {
    width: 'fit-content',
    borderRadius: 20,
    fontSize: 14,
    lineHeight: '20px',
    padding: '4px 12px',
    border: 'none',
    color: colors.white,
    '&.CRITICAL': {
      backgroundColor: colors.rose500,
    },
    '&.NORMAL': {
      backgroundColor: colors.green200,
    },
    '&.HIGH': {
      backgroundColor: colors.rose50,
    },
    '&.LOW': {
      backgroundColor: colors.cyan500,
    },
    '&.NONE': {
      backgroundColor: colors.destructive500,
    },
  },
  editBtn: {
    height: 64,
    color: '#FFF',
    whiteSpace: 'nowrap',
    padding: '20px 24px',
    borderRadius: 8,
  },
  heading: {
    fontFamily: 'Source Serif Pro',
    margin: 0,
  },
  subtitle: {
    fontWeight: 400,
    fontSize: 18,
    lineHeight: '28px',
    margin: 0,
    color: colors.neutral600,
  },
});
