import { useState } from 'react';
import { useAsync } from 'react-async-hook';
import { GiphyFetch } from '@giphy/js-fetch-api';
import { Gif } from '@giphy/react-components';
import { Box, Dialog } from '@mui/material';
import { IconButton } from '../../../../packages';
import Heading from '../../../../components/v2/Heading';
import { HeadingFont, HeadingLevel, HeadingWeight } from '../../../../components/v2/Heading/index.types';

const giphyFetch = new GiphyFetch('4bect4Gt6ALJXRMxUx48NZW64ZeptAts');

const Giggle = ({ open, setOpen, keyword = 'celebrate', heading = "Let's celebrate!" }) => {
  const onClose = () => {
    setOpen(false);
  };

  function GiggleGif() {
    const [gif, setGif] = useState(null);

    useAsync(async () => {
      const { data } = await giphyFetch.search(keyword, { limit: 10 });
      const randomIndex = Math.floor(Math.random() * data.length);
      setGif(data[randomIndex]);
    }, []);

    return gif && <Gif gif={gif} width={575} height={323} />;
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <Box
        sx={{
          width: 639,
          height: 450,
          p: 4,
          display: 'flex',
          flexDirection: 'column',
          gap: 4,
          position: 'relative',
        }}
      >
        <Box sx={{ textAlign: 'center', display: 'flex', flexDirection: 'column', gap: 3.5 }}>
          <Heading
            variant={HeadingLevel.H3}
            fontWeight={HeadingWeight.SEMI_BOLD}
            fontFamily={HeadingFont.SECONDARY}
          >
            {heading}
          </Heading>
          <GiggleGif />
        </Box>
        <Box sx={{ position: 'absolute', top: 24, right: 0 }}>
          <IconButton icon="close" onClick={onClose} />
        </Box>
      </Box>
    </Dialog>
  );
};

export { Giggle };
