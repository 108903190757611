import { baseRequest } from '../../utils/HttpClient';
import { ApiEndpoints } from '../../constants/ApiEndpoints';

export const getPriorityDomainTypes = payload => {
  return baseRequest(ApiEndpoints.GET_PRIORITY_DOMAIN_TYPES, null, null, payload);
};

export const getPriorityDomainElements = payload => {
  return baseRequest(ApiEndpoints.GET_PRIORITY_DOMAIN_ELEMENTS, null, null, payload);
};

export const getDomainElementsByTypeId = typeId => {
  return baseRequest(ApiEndpoints.GET_DOMAIN_ELEMENTS_BY_TYPE_ID, null, { typeId });
};

export const getPriorityDomainProfileElements = payload => {
  return baseRequest(ApiEndpoints.GET_PROFILE_ELEMENTS, null, null, payload);
};

export const getPriorityDomainGroups = payload => {
  return baseRequest(ApiEndpoints.GET_PRIORITY_DOMAIN_GROUPS, null, null, payload);
};

export const createNewDomainType = payload => {
  return baseRequest(ApiEndpoints.ADD_DOMAIN_TYPE, payload);
};

export const updateDomainType = payload => {
  return baseRequest(ApiEndpoints.UPDATE_DOMAIN_TYPE, payload, { domainTypeId: payload.Id });
};

export const deleteDomainType = domainTypeId => {
  return baseRequest(ApiEndpoints.DELETE_DOMAIN_TYPE, null, { domainTypeId });
};

export const deleteDomainElement = domainElementId => {
  return baseRequest(ApiEndpoints.DELETE_DOMAIN_ELEMENT, null, { domainElementId });
};

export const deleteDomainGroup = domainGroupId => {
  return baseRequest(ApiEndpoints.DELETE_DOMAIN_GROUP, null, { domainGroupId });
};

export const createNewDomainGroup = payload => {
  return baseRequest(ApiEndpoints.ADD_DOMAIN_GROUP, payload);
};

export const updateDomainGroup = payload => {
  return baseRequest(ApiEndpoints.UPDATE_DOMAIN_GROUP, payload, { domainGroupId: payload.Id });
};

export const getDomainLookups = () => {
  return baseRequest(ApiEndpoints.GET_DOMAIN_LOOKUP, null, null);
};

export const createNewElementType = payload => {
  return baseRequest(ApiEndpoints.ADD_DOMAIN_ELEMENT, payload);
};

export const updateDomainElement = payload => {
  return baseRequest(ApiEndpoints.UPDATE_DOMAIN_ELEMENT, payload, { domainElementId: payload.Id });
};

export const getAssignedPrioritiesV2 = params => {
  if (!params.importanceLevels || params.importanceLevels === '') {
    delete params.importanceLevels;
  }
  return baseRequest(ApiEndpoints.GET_ASSIGNED_PRIORITIES, null, null, params);
};
