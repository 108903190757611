import { colors } from '../../../../../packages/colors';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
    marginRight: 24,
  },
  chevronIcon: {
    width: 24,
    minWidth: 24,
    height: 24,
    objectFit: 'contain',
    transition: '0.4s',
    marginLeft: '12px',
    marginRight: '12px',
    transform: ({ collapsed }) => `rotate(${collapsed ? '180deg' : 0})`,
    '& path': {
      fill: colors.neutral900,
    },
  },
  badge: {
    height: 24,
  },
  mainBox: {
    backgroundColor: 'white',
    minWidth: '300px',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    borderRadius: '8px',
    boxShadow:
      '0px 0px 1px 0px rgba(0, 0, 0, 0.04), 0px 2px 6px 0px rgba(0, 0, 0, 0.04), 0px 10px 20px 0px rgba(0, 0, 0, 0.04)',
  },
  mainParentBody: {
    flexDirection: 'column',
  },
  heading: {
    fontFamily: 'Manrope',
    paddingLeft: '24px',
    paddingRight: '16px',
  },
  headerBox: {
    display: 'flex',
    justifyContent: 'space-between', // Add this line to evenly space the items
    alignItems: 'center',
  },
  parentHeader: {
    display: 'flex',
    alignItems: 'center',
  },
  iconButtonBox: {
    paddingRight: '16px',
  },
  btn: {
    fontFamily: 'Manrope !important',
    fontSize: '16px !important',
    color: '#005ebe',
    '& svg': {
      marginLeft: 10,
    },
  },
  bodyDivider: {
    borderColor: colors.neutral100,
  },
  emptyTodos: {
    // minWidth: '400px',
    height: '190px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  textBox: {
    // width: '336px',
    textAlign: 'center',
  },
  icons: {
    width: '70px',
    height: '70px',
  },
  emptyTodosText: {
    color: 'neutral600',
  },
  menu: {
    float: 'right',
    right: 20,
    '& svg': {
      width: 16,
    },
  },
  menuItemsWrapper: {
    '& ul': {
      padding: 0,
    },
    '& ul li': {
      padding: '10px 16px',
      // '&:last-child': {
      //   color: colors.destructive600,
      //   '& svg path': {
      //     fill: colors.destructive600,
      //   },
      // },
    },
  },
  date: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItem: 'flex-start',
    alignSelf: 'stretch',
  },
  table: {
    overflowX: 'hidden',
  },
});
