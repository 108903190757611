// import { makeStyles } from '@mui/styles';

// // colors
// import { colors } from '../../../colors';

// export const useStyles = makeStyles({
//   root: {
//     margin: 0,
//     gap: 12,
//     alignItems: 'flex-start !important',
//     '& .MuiRadio-root': {
//       padding: 0,
//     },
//     '& .MuiCheckbox-root': {
//       padding: 0,
//     },
//     '& .MuiFormControlLabel-label': {
//       fontFamily: 'Manrope',
//       fontWeight: 500,
//       color: colors.textHighContrast,
//       fontSize: 16,
//       letterSpacing: '0.01em',
//     },
//   },
// });

import { makeStyles } from '@mui/styles';

// colors
import { colors } from '../../../colors';

export const useStyles = makeStyles({
  root: {
    margin: 0,
    gap: 12,
    alignItems: 'flex-start !important',
    '& .MuiRadio-root': {
      padding: 0,
    },
    '& .MuiCheckbox-root': {
      padding: 0,
    },
    '& .MuiFormControlLabel-label': {
      fontFamily: 'Manrope',
      fontWeight: 500,
      color: colors.textHighContrast,
      fontSize: 16,
      letterSpacing: '0.01em',
    },
  },
});
