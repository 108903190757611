import { call, put, takeLatest } from 'redux-saga/effects';

import * as ReportService from '../../../services/report-service/reportService.service';
import * as AppointmentService from '../../../services/appointment/appointment.service';

import { showSnackbar } from '../snackbar';

import {
  ADD_APPOINTMENT_CPT_CODE,
  ADD_APPOINTMENT_CPT_CODE_FAILED,
  ADD_APPOINTMENT_CPT_CODE_SUCCESSFUL,
  FETCH_APPOINTMENTS_V4,
  FETCH_APPOINTMENTS_V4_FAILED,
  FETCH_APPOINTMENTS_V4_SUCCESSFUL,
  GET_APPOINTMENT_CPT_CODE,
  GET_APPOINTMENT_CPT_CODE_FAILED,
  GET_APPOINTMENT_CPT_CODE_SUCCESSFUL,
  UPDATE_APPOINTMENT_CPT_CODE,
  UPDATE_APPOINTMENT_CPT_CODE_FAILED,
  UPDATE_APPOINTMENT_CPT_CODE_SUCCESSFUL,
} from './actions';

function* appointmentFetcher(action) {
  try {
    // yield put(profileActionCreators.fetchProviders());

    const response = yield call(AppointmentService.getAppointments, action.payload.queryParams);
    const appointments = response?.data?.results;

    yield put({
      type: FETCH_APPOINTMENTS_V4_SUCCESSFUL,
      payload: {
        appointments,
        currentPage: response?.data?.page,
        totalPages: Math.ceil(response?.data?.total / response?.data?.size),
        totalRecords: response?.data?.total,
      },
    });
  } catch (e) {
    const message = e?.errors?.[0]?.endUserMessage || 'Something went wrong';

    yield put({
      type: FETCH_APPOINTMENTS_V4_FAILED,
      payload: { message },
    });
  }
}

function* fetchAppointmentCptCodeById(action) {
  try {
    const { data } = yield call(ReportService.getAppointmentCptCodeById, action.payload);

    const appointmentCptCode = data?.data;

    yield put({
      type: GET_APPOINTMENT_CPT_CODE_SUCCESSFUL,
      payload: appointmentCptCode,
    });
  } catch (e) {
    const message = e?.errors?.[0]?.endUserMessage || 'Something went wrong';

    yield put({
      type: GET_APPOINTMENT_CPT_CODE_FAILED,
      payload: { message },
    });
  }
}

function* addAppointmentCptCode(action) {
  const { bodyRequest, callback } = action.payload;

  try {
    const { data } = yield call(ReportService.addAppointmentCptCode, bodyRequest);
    yield put({ type: ADD_APPOINTMENT_CPT_CODE_SUCCESSFUL, payload: data.data });
    yield callback();
    yield put(
      showSnackbar({
        snackType: 'success',
        snackMessage: 'Appointment CPT Code added successfully',
      }),
    );
  } catch (e) {
    const errorMsg = e.data?.message || 'Something went wrong!';
    yield put({
      type: ADD_APPOINTMENT_CPT_CODE_FAILED,
      payload: { message: e.data?.errors?.[0]?.endUserMessage || 'Something went wrong!' },
    });
    yield put(
      showSnackbar({
        snackType: 'error',
        snackMessage: errorMsg,
      }),
    );
  }
}

function* updateAppointmentCptCode(action) {
  const { bodyRequest, pathParams, callback } = action.payload;

  try {
    const { data } = yield call(ReportService.updateAppointmentCptCode, bodyRequest, pathParams);
    yield put({ type: UPDATE_APPOINTMENT_CPT_CODE_SUCCESSFUL, payload: data.data });
    yield callback();
    yield put(
      showSnackbar({
        snackType: 'success',
        snackMessage: 'Appointment CPT Code updated successfully',
      }),
    );
  } catch (e) {
    const errorMsg = e.data?.message || 'Something went wrong!';
    yield put({
      type: UPDATE_APPOINTMENT_CPT_CODE_FAILED,
      payload: { message: e.data?.errors?.[0]?.endUserMessage || 'Something went wrong!' },
    });
    yield put(
      showSnackbar({
        snackType: 'error',
        snackMessage: errorMsg,
      }),
    );
  }
}

function* reportServiceSaga() {
  yield takeLatest(FETCH_APPOINTMENTS_V4, appointmentFetcher);
  yield takeLatest(GET_APPOINTMENT_CPT_CODE, fetchAppointmentCptCodeById);
  yield takeLatest(ADD_APPOINTMENT_CPT_CODE, addAppointmentCptCode);
  yield takeLatest(UPDATE_APPOINTMENT_CPT_CODE, updateAppointmentCptCode);
}

export default reportServiceSaga;
