import { baseRequest } from '../../utils/HttpClient';
import { ApiEndpoints } from '../../constants/ApiEndpoints';

export const getStates = () => {
  return baseRequest(ApiEndpoints.GET_STATES, null, null, null);
};

export const updateState = (bodyRequest, pathParams) => {
  return baseRequest(ApiEndpoints.UPDATE_STATE, bodyRequest, pathParams);
};

export const getStateById = stateId => {
  return baseRequest(ApiEndpoints.GET_STATE_BY_ID, null, { stateId });
};

export const addRegisteredEntity = bodyRequest => {
  return baseRequest(ApiEndpoints.ADD_REGISTERED_ENTITY, bodyRequest);
};

export const updateRegisteredEntity = (bodyRequest, pathParams) => {
  return baseRequest(ApiEndpoints.UPDATE_REGISTERED_ENTITY, bodyRequest, pathParams);
};

export const getPayers = stateId => {
  return baseRequest(ApiEndpoints.GET_PAYERS, null, { stateId });
};

export const getAllPayers = () => {
  return baseRequest(ApiEndpoints.GET_ALL_PAYERS, null, null);
};

export const getPayerById = payerId => {
  return baseRequest(ApiEndpoints.GET_PAYER_BY_ID, null, { payerId });
};

export const addPayer = (bodyRequest, pathParams) => {
  return baseRequest(ApiEndpoints.ADD_PAYER, bodyRequest, pathParams);
};

export const updatePayer = (bodyRequest, pathParams) => {
  return baseRequest(ApiEndpoints.UPDATE_PAYER, bodyRequest, pathParams);
};

export const addPayerPlan = (bodyRequest, pathParams) => {
  return baseRequest(ApiEndpoints.ADD_PAYER_PLAN, bodyRequest, pathParams, null, true);
};

export const updatePayerPlan = (bodyRequest, pathParams) => {
  return baseRequest(ApiEndpoints.UPDATE_PAYER_PLAN, bodyRequest, pathParams, null, true);
};

export const addPayerProvider = (bodyRequest, pathParams) => {
  return baseRequest(
    ApiEndpoints.ADD_PAYER_PROVIDER,
    bodyRequest,
    pathParams,
    { getFullPayerInResponse: true },
    true,
  );
};

export const updatePayerProvider = (bodyRequest, pathParams) => {
  return baseRequest(
    ApiEndpoints.UPDATE_PAYER_PROVIDER,
    bodyRequest,
    pathParams,
    { getFullPayerInResponse: true },
    true,
  );
};

export const getPayerGroups = () => {
  return baseRequest(ApiEndpoints.GET_PAYER_GROUPED, null, null, null);
};
export const getUniquePayers = () => {
  return baseRequest(ApiEndpoints.GET_UNIQUE_PAYERS, null, null, null);
};
export const getCPTs = () => {
  return baseRequest(ApiEndpoints.GET_CPTS, null, null, null);
};

export const getIcd10codes = () => {
  return baseRequest(ApiEndpoints.GET_ICD10_CODES, null, null, null);
};

export const addPayerPlanCPT = (bodyRequest, pathParams) => {
  return baseRequest(
    ApiEndpoints.ADD_PAYER_PLAN_CPT,
    bodyRequest,
    pathParams,
    { getFullPayerInResponse: true },
    true,
  );
};

export const updatePayerPlanCPT = (bodyRequest, pathParams) => {
  return baseRequest(
    ApiEndpoints.UPDATE_PAYER_PLAN_CPT,
    bodyRequest,
    pathParams,
    { getFullPayerInResponse: true },
    true,
  );
};

export const getStatesSimple = () => {
  return baseRequest(ApiEndpoints.GET_STATES_SIMPLE, null, null, null);
};

export const uploadPayerImage = bodyRequest => {
  return baseRequest(ApiEndpoints.PAYER_UPLOAD_IMAGE, bodyRequest, null, null, true);
};
