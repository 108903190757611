import { colors } from '../../../../../packages';
import dayjs from 'dayjs';
import { getLast30Days, shortNumber } from '../../../../../utils/CommonUtils';

// random data in 30 days
export const getRandomData = () => {
  const data = [];
  for (let i = 0; i < 30; i += 3) {
    data.push(Math.floor(Math.random() * 30));
  }
  return data;
};

const days30 = getLast30Days();

export const chartOptions = {
  chart: {
    toolbar: {
      show: false,
    },
    zoom: {
      enabled: false,
    },
  },
  title: {
    text: '',
  },
  plotOptions: {
    bar: {
      horizontal: false,
    },
  },
  colors: [colors.primary500],
  stroke: {
    width: 2,
    colors: [colors.primary500],
    curve: 'smooth',
  },
  xaxis: {
    categories: days30,
    labels: {
      formatter: val => {
        return dayjs(val).format('MMM D');
      },
      style: {
        fontFamily: 'Manrope',
        fontWeight: 600,
      },
    },
    tooltip: {
      enabled: false,
    },
  },
  yaxis: {
    title: {
      text: undefined,
    },
  },
  tooltip: {
    style: {
      fontFamily: 'Manrope',
    },
    custom: ({ series, dataPointIndex, seriesIndex }) => {
      const time = dayjs(days30[dataPointIndex]).format('MMM DD, YYYY');
      const num = +series[seriesIndex][dataPointIndex];
      return `
        <div class="chart-tooltip">
          <div class="date">${time}</div>
          <div class="level">${num} ${num > 1 ? 'assignments' : 'assignment'} </div>
        </div>
      `;
    },
    y: {
      formatter: shortNumber,
      show: false,
    },
  },
  fill: {
    opacity: 1,
  },
  legend: {
    show: false,
  },
};

export const chartSeries = [
  {
    name: 'Tag assigned',
    data: getRandomData(),
  },
];
