import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import clsx from 'clsx';
import dayjs from 'dayjs';

import { Avatar, avatarType } from '../../../../../../packages/ui/atoms/avatar';
import { ProfileInfo } from '../../../../../../packages/ui/templates/profile-info';
import { Heading, Text, fontWeight, textLevel } from '../../../../../../packages/ui/atoms/typography';
import { Badge, badgeStyle, badgeType } from '../../../../../../packages/ui/atoms/badge';
import { Icons } from '../../../../../../packages/icons';
import { colors } from '../../../../../../packages/colors';
import { selectProviderSchedule } from '../../../../../../redux/modules/schedule/selectors';
import { getAuth } from '../../../../../../redux/modules/auth/selectors';
import { Tooltip } from '../../../../../../packages/ui/atoms/tooltip';

import { useStyles } from './ProfileContentCard.styles';
import ServiceItem from '../../services/service-item';

const ProfileContentCard = ({ content }) => {
  const classes = useStyles();
  const providerSchedule = useSelector(selectProviderSchedule);
  const { meta, isAdmin } = useSelector(getAuth);
  const { description, profile, badges, affiliation, workingDays, services, insurances } = content;
  const [availableStates, setAvailableStates] = useState([]);

  useEffect(() => {
    if (insurances) {
      const statesAndPayers = insurances.map(itm => {
        const state = [];
        state.push(itm.state);
        itm.insurances.map(insurance => {
          return state.push(insurance.name);
        });
        return state;
      });
      setAvailableStates(statesAndPayers);
    }
  }, [insurances]);
  const renderContentDuration = (title, durations, className = '') => {
    return (
      <Box display="flex" flexDirection="column" gap="16px">
        <Box className={classes.titleDuration}>{title}</Box>
        <Box display="flex" flexWrap="wrap" gap="8px">
          {!durations ? (
            <Box className={clsx(classes.descriptionAffiliation, className)}>Day is set as unavailable</Box>
          ) : (
            durations.map((item, index) => {
              const timeStart = dayjs(item.start).tz(providerSchedule.timezone).format('hh:mm a');
              const timeEnd = dayjs(item.end).tz(providerSchedule.timezone).format('hh:mm a');

              return (
                <Box key={index} className={classes.contentDuration}>
                  <span>{`${timeStart} - ${timeEnd}`}</span>
                </Box>
              );
            })
          )}
        </Box>
      </Box>
    );
  };

  const renderInfo = (label, text) => {
    return (
      <Grid item md={1} xs={3}>
        <Box className={classes.cutTextWrapper}>
          <Text className={classes.textLight} level={textLevel.S} weight={fontWeight.SEMI_BOLD}>
            {!label.includes('##') ? label : label.replace('##', '')}
          </Text>

          <Text
            className={clsx(classes.textDark, classes.cutText)}
            level={textLevel.M}
            weight={fontWeight.MEDIUM}
          >
            <Tooltip title={text} placement="top-start">
              <span>{text || 'N/A'}</span>
            </Tooltip>
          </Text>
        </Box>
      </Grid>
    );
  };

  return (
    <Box className={classes.cardContent}>
      {profile && (
        <Box>
          <Box className={classes.cardSection}>
            <Box className={classes.wrapperUserProfile}>
              <ProfileInfo
                type="provider"
                photo={profile.avatar}
                fullName={
                  profile?.name
                    ? profile?.name
                    : profile?.firstName && profile?.lastName
                    ? `${profile?.firstName} ${profile?.lastName}`
                    : meta.nickName
                }
                role={profile?.designation}
                isProvider
              />
              <Box display="flex" alignItems="center">
                <Badge
                  className={classes.badge}
                  variant={badgeType.OUTLINED}
                  style={profile.activeInApp ? badgeStyle.RESOLVED : badgeStyle.CRITICAL}
                >
                  <span className={clsx(classes.dot, { [classes.activeDot]: profile.activeInApp })} />{' '}
                  {profile.activeInApp ? 'Active' : 'Inactive'}
                </Badge>
              </Box>
            </Box>
            {description && (
              <Box className={classes.descriptionBox}>
                <Text className={classes.aboutText} level={textLevel.M} weight={fontWeight.MEDIUM}>
                  <Tooltip title={description} placement="top-start">
                    <span>{description}</span>
                  </Tooltip>
                </Text>
              </Box>
            )}
            <Box>
              <Grid container columns={3} rowSpacing="32px" paddingTop={2}>
                <Grid item md={1} xs={3}>
                  <Box className={classes.cutTextWrapper}>
                    <Text className={classes.textLight} level={textLevel.S} weight={fontWeight.SEMI_BOLD}>
                      Active Provider
                    </Text>

                    <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                      <Icons
                        glyph={profile.activeInApp ? 'checked-circle-outlined' : 'x-circle-outlined'}
                        color={profile.activeInApp ? colors.success600 : colors.destructive600}
                        className={profile.activeInApp ? classes.activeIcon : classes.inactiveIcon}
                      />

                      <Text
                        className={clsx(classes.textDark, classes.cutText)}
                        level={textLevel.M}
                        weight={fontWeight.MEDIUM}
                      >
                        {profile.activeInApp ? 'Active' : 'Inactive'}
                      </Text>
                    </Box>
                  </Box>
                </Grid>
                <Grid item md={1} xs={3}>
                  <Box className={classes.cutTextWrapper}>
                    <Text className={classes.textLight} level={textLevel.S} weight={fontWeight.SEMI_BOLD}>
                      New Patients
                    </Text>

                    <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                      <Icons
                        glyph={profile.acceptingNewPatients ? 'checked-circle-outlined' : 'x-circle-outlined'}
                        color={profile.acceptingNewPatients ? colors.success600 : colors.destructive600}
                        className={profile.acceptingNewPatients ? classes.activeIcon : classes.inactiveIcon}
                      />

                      <Text
                        className={clsx(classes.textDark, classes.cutText)}
                        level={textLevel.M}
                        weight={fontWeight.MEDIUM}
                      >
                        {profile.acceptingNewPatients ? 'Accepting' : 'Not accepting'}
                      </Text>
                    </Box>
                  </Box>
                </Grid>
                <Grid item md={1} xs={3}>
                  <Box className={classes.cutTextWrapper}>
                    <Text className={classes.textLight} level={textLevel.S} weight={fontWeight.SEMI_BOLD}>
                      Accepts Adolescents
                    </Text>

                    <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                      <Icons
                        glyph={profile.acceptingAdolescents ? 'checked-circle-outlined' : 'x-circle-outlined'}
                        color={profile.acceptingAdolescents ? colors.success600 : colors.destructive600}
                        className={profile.acceptingAdolescents ? classes.activeIcon : classes.inactiveIcon}
                      />

                      <Text
                        className={clsx(classes.textDark, classes.cutText)}
                        level={textLevel.M}
                        weight={fontWeight.MEDIUM}
                      >
                        {profile.acceptingAdolescents ? 'Accepting' : 'Not accepting'}
                      </Text>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Box padding={3}>
            <Box>
              <Grid container columns={3} rowSpacing="32px" paddingTop={2}>
                {renderInfo('First name', profile.firstName ?? '-')}
                {renderInfo('Last name', profile.lastName ?? '-')}
                {renderInfo('Billing Provider', profile.billingProvider ? 'Yes' : 'No')}
              </Grid>
            </Box>
            <Box>
              <Grid container columns={3} rowSpacing="32px" paddingTop={2}>
                {profile.emailAddress && renderInfo('Email', profile.emailAddress)}
                {profile.phoneNumber && renderInfo('Phone', profile.phoneNumber)}
              </Grid>
            </Box>
            <Box>
              <Grid container columns={3} rowSpacing="32px" paddingTop={2}>
                {renderInfo('Address', profile.practitionerAddress?.address1 ?? '-')}
                {renderInfo('City', profile.practitionerAddress?.city ?? '-')}
                {renderInfo('State', profile.practitionerAddress?.state ?? '-')}
                {/* {renderInfo('Zip', profile.practitionerAddress?.zipCode ?? '-')} */}
              </Grid>
            </Box>
            {(profile.emailAddress || profile.phoneNumber) && (
              <Grid container columns={3} rowSpacing="32px" paddingTop={2}>
                {renderInfo('NPI', profile.npi ?? '-')}
                {renderInfo('Time Zone', providerSchedule.timezone ?? '-')}
                {renderInfo(
                  'Current Time',
                  dayjs(new Date()).tz(providerSchedule.timezone).format('hh:mm a') ?? '-',
                )}
              </Grid>
            )}
          </Box>
        </Box>
      )}
      {description && !profile && (
        <Box padding={3}>
          <Text className={classes.textDescription}>{description}</Text>
        </Box>
      )}
      {insurances && (
        <>
          {insurances?.length > 0 ? (
            <Box display="flex" flexWrap="wrap" gap="5px" flexDirection="column" padding={3}>
              {availableStates.map((item, index) => (
                <Box key={index} display="flex" flexWrap="wrap" gap="5px" flexDirection="row" mb="10px">
                  {item.map((itm, idx) =>
                    idx === 0 ? (
                      <Box key={idx} className={classes.stateContent}>
                        {`${itm}  `}{' '}
                      </Box>
                    ) : (
                      <Box key={idx} className={classes.badgeContent}>
                        {itm}
                      </Box>
                    ),
                  )}
                </Box>
              ))}
            </Box>
          ) : (
            <Text className={classes.descriptionAffiliation}>None</Text>
          )}
        </>
      )}
      {badges && (
        <>
          {badges?.length > 0 ? (
            <Box display="flex" flexWrap="wrap" gap="5px" padding={3}>
              {badges.map((item, index) => (
                <Box key={index} className={classes.badgeContent}>
                  {item}
                </Box>
              ))}
            </Box>
          ) : (
            <Text className={classes.descriptionAffiliation}>None</Text>
          )}
        </>
      )}
      {affiliation && (
        <Box display="flex" justifyContent="space-between" alignItems="center" padding={3}>
          {affiliation.title ? (
            <>
              <Box display="flex" gap="24px" alignItems="center">
                <Avatar size={64} variant={avatarType.SQUARE} src={affiliation.avatar} />
                <Box>
                  <Heading className={classes.titleAffiliation}>{affiliation.title}</Heading>
                  <Text className={classes.descriptionAffiliation}>{affiliation.description}</Text>
                </Box>
              </Box>
              <Box display="flex" flexDirection="column" alignItems="flex-end">
                <Box className={classes.textDateAffiliation}>
                  {dayjs(affiliation.startDate).format('MMMM DD, YYYY -')}
                </Box>
                <Box className={classes.textDateAffiliation}>
                  {dayjs(affiliation.endDate).format('MMMM DD, YYYY')}
                </Box>
              </Box>
            </>
          ) : (
            <Text className={classes.descriptionAffiliation}>None</Text>
          )}
        </Box>
      )}
      {workingDays && (
        <Box display="flex" flexDirection="column" gap="32px" padding={3}>
          {renderContentDuration('Sunday', workingDays.sunday, classes.widthAuto)}
          {renderContentDuration('Monday', workingDays.monday, classes.widthAuto)}
          {renderContentDuration('Tuesday', workingDays.tuesday, classes.widthAuto)}
          {renderContentDuration('Wednesday', workingDays.wednesday, classes.widthAuto)}
          {renderContentDuration('Thursday', workingDays.thursday, classes.widthAuto)}
          {renderContentDuration('Friday', workingDays.friday, classes.widthAuto)}
          {renderContentDuration('Saturday', workingDays.saturday, classes.widthAuto)}
        </Box>
      )}
      {services && (
        <>
          {services.length > 0 ? (
            <Box display="flex" flexDirection="column" gap="32px" padding={3}>
              {services.map(item => (
                <ServiceItem service={item} key={item.id} />
              ))}
            </Box>
          ) : (
            <Text className={classes.descriptionAffiliation}>None</Text>
          )}
        </>
      )}
    </Box>
  );
};

export { ProfileContentCard };
