import cx from 'clsx';
import { fontWeight, Text, textLevel } from '../../../../../packages/ui/atoms/typography';
import { Box } from '@mui/material';
import { Icons } from '../../../../../packages/icons';
import { colors } from '../../../../../packages/colors';
import React, { FC, useEffect, useRef } from 'react';
import { Badge, badgeType } from '../../../../../packages/ui/atoms/badge';
import { badgeStyle } from '../../../../../packages/ui/atoms/badge/Badge.types';
import { CbStatus } from '../../../../../redux/modules/conversation/types';
import { useStyles } from './EvaluationSectionCollapse.styles';

export const Question = ({ currentBlock, item, setCurrentBlock, scrollRef }) => {
  const classes = useStyles();
  const ref = useRef(null);
  let riskFactor = false;

  if (item.userResponse?.answeredBy === 'AI') {
    const riskFactorChoices = item.choices?.filter(choice => choice.riskFactor);
    riskFactor = riskFactorChoices.some(choice => {
      return !!item.userResponse.userResponseList.includes(choice.choice);
    });
  }

  useEffect(() => {
    if (ref?.current && scrollRef?.current) {
      if (currentBlock?.cbId === item.cbId) {
        scrollRef.current.scrollTop = ref.current.offsetTop + 36 - 211;
      }
    }
  }, [scrollRef, currentBlock, item, ref]);

  return (
    <Box
      ref={ref}
      className={cx(classes.collapseItemBox, {
        [classes.selectedQuestion]: currentBlock?.cbId === item.cbId,
        [classes.nonSelectedQuestion]: currentBlock?.cbId !== item.cbId,
        [classes.noPointer]: !item.isAttemptable,
      })}
      onClick={() => {
        if (item.isAttemptable) setCurrentBlock(item);
      }}
    >
      {item.status === CbStatus.ANSWERED ? (
        <Icons
          className={classes.evalIcon}
          glyph="checkmark"
          color={
            item.status === CbStatus.ANSWERED ||
            (item.userResponse && item.userResponse.userResponseList.length > 0)
              ? colors.success500
              : colors.neutral400
          }
        />
      ) : !item.isAttemptable ? (
        <Box style={{ display: 'flex', minWidth: '20px', justifyContent: 'center' }}>
          <Icons className={classes.minusIcon} glyph="minus" color={colors.destructive600} />
        </Box>
      ) : (
        <Box
          className={cx({
            [classes.marginClass]: true,
          })}
        />
      )}
      <Text level={textLevel.S} weight={fontWeight.MEDIUM} className={classes.marginLeftQuestion}>
        {item.text}
      </Text>
      {item.required && item.cbType !== 'exercise' && item.cbType !== 'education' && (
        <Box style={{ marginLeft: 'auto', paddingLeft: '8px' }}>
          <Box className={classes.requiredBadge}>
            <Text level={textLevel.XS} weight={fontWeight.BOLD} color={colors.warning600}>
              Required
            </Text>
          </Box>
        </Box>
      )}
      {riskFactor && (
        <Box style={{ marginLeft: 'auto', paddingLeft: '8px' }}>
          <Badge className={classes.badge2} variant={badgeType.OUTLINED} style={badgeStyle.CRITICAL}>
            Risk factor
          </Badge>
        </Box>
      )}
    </Box>
  );
};
