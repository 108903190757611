import React, { useEffect, useState } from 'react';
import { Box, Chip, TextField } from '@mui/material';
import { useFormik } from 'formik';

import {
  btnType,
  Button,
  fontWeight,
  Heading,
  headingLevel,
  IconButton,
  Input,
  inputSize,
  positionType,
  Text,
  TextError,
  Toggle,
} from '../../../../../packages';
import { Drawer, drawerType } from '../../../../../packages/ui/organisms/drawer';
import { UploadFile } from '../../../../../packages/ui/templates/upload-file';
import UploadFileProgress from '../../../../../pages/provider/profile/components/upload-flie-progress';

// schema
import { ConversationSchema } from './AddConversation.schema';

// styles
import { useStyles } from './AddConversation.styles';

// types

const AddConversation = ({ isOpen, conversation, onClose, onSubmit }) => {
  const classes = useStyles();
  const [tagValue, setTagValue] = useState('');
  const [tags, setTags] = useState(conversation?.tags || []);
  const [tagsError, setTagsError] = useState('');
  const [benefitsValue, setBenefitsValue] = useState('');
  const [benefits, setBenefits] = useState(conversation?.whoCanBenefit || []);
  const [benefitsError, setBenefitsError] = useState('');
  const [imageFile, setImageFile] = useState();
  const [isFileChanged, setIsFileChanged] = useState(false);
  const [imageFileError, setImageFileError] = useState('');
  const [uploadedFileURL, setUploadedFileURL] = useState('');

  const defaultValues = {
    name: conversation?.name?.name || '',
    description: conversation?.description || '',
    tags,
    providerAssignable: conversation?.assignableBy?.providerAssignable || false,
    selfAssignable: conversation?.assignableBy?.selfAssignable || false,
    systemAssignable: conversation?.assignableBy?.systemAssignable || false,
    benefits: conversation?.whoCanBenefit || [],
    avatar: conversation?.avatar || null,
  };

  const { errors, values, handleChange, handleSubmit, touched, setFieldValue, ...rest } = useFormik({
    initialValues: defaultValues,
    validationSchema: ConversationSchema,
    onSubmit: payload => {
      if (tags.length > 0) {
        if (benefits.length > 0) {
          if (imageFile) {
            const data = {
              ...payload,
              tags,
              benefits,
              avatar: isFileChanged ? uploadedFileURL : conversation.avatar,
            };
            onSubmit(data, isFileChanged);
            onClose();
          } else {
            setImageFileError('File is required');
          }
        } else {
          setBenefitsError('Please add atleast one benefit');
        }
      } else {
        setTagsError('Please add atleast one tag');
      }
    },
  });

  useEffect(() => {
    if (!isOpen) {
      rest.handleReset({ ...defaultValues });
      rest.setErrors({});
    } else if (conversation) {
      Object.keys(conversation).forEach(key => {
        void setFieldValue(key, conversation[key]);
      });
    }
  }, [isOpen, conversation]);

  useEffect(() => {
    if (conversation?.avatar) {
      setImageFile(conversation.avatar);
    }
  }, []);

  /**
   * @Name setTouched
   * @description this function is used in formik to know the interaction with user
   */

  const setTouched = name => async () => {
    await rest.setTouched({ ...touched, [name]: true });
  };

  /**
   * @Name setTouched
   * @description this function is used in formik to know the interaction with user
   */

  const onChangeToggle = name => async () => {
    await setFieldValue(name, !values[name]);
  };

  /**
   * @Name onHandleDelete
   * @description this function is used in delete tags or benefits chips
   */

  const onAcceptedFiles = files => {
    const file = files[0];
    setIsFileChanged(true);
    setImageFile(file);
    setImageFileError('');
  };

  const renderLabel = label => (
    <Heading level={headingLevel.S} className={classes.label} weight={fontWeight.BOLD}>
      {label}
    </Heading>
  );

  /**
   * @Name onHandleDelete
   * @description this function is used in delete tags or benefits chips
   */

  const onHandleDelete = (index, isTag) => {
    if (isTag) {
      const tempValue = tags[index];
      const tempArr = tags.filter(tag => tempValue !== tag);
      setTags(tempArr);
    } else {
      const tempValue = benefits[index];
      const tempArr = benefits.filter(benefit => tempValue !== benefit);
      setBenefits(tempArr);
    }
  };

  return (
    <Drawer open={isOpen} onClose={onClose} variant={drawerType.NORMAL} className={classes.drawer}>
      <Box className={classes.wrapper}>
        <Box className={classes.header}>
          <Text className={classes.headerTitle}>
            {conversation ? 'Edit conversation detail' : 'Add conversation'}
          </Text>
          <IconButton icon="close" onClick={onClose} className={classes.backBtn} />
        </Box>
        <form className={classes.form} onSubmit={handleSubmit}>
          <Box className={classes.formContent}>
            <Box className={classes.section}>
              {renderLabel('Conversation name')}
              <Input
                value={values.name}
                name="name"
                placeholder="Enter a conversation name"
                onChange={handleChange}
                size={inputSize.M}
                onBlur={setTouched('name')}
                fullWidth
              />
              <TextError errorMsg={touched.name ? errors.name?.toString() : null} />
            </Box>
            <Box className={classes.section}>
              {renderLabel('Description')}
              <Input
                value={values.description}
                name="description"
                placeholder="Enter description"
                onChange={handleChange}
                size={inputSize.M}
                onBlur={setTouched('description')}
                fullWidth
              />
              <TextError errorMsg={touched.description ? errors.description?.toString() : null} />
            </Box>
            <Box className={classes.toggleWrap}>
              <Toggle
                checked={values.providerAssignable}
                labelPosition={positionType.RIGHT}
                onChange={onChangeToggle('providerAssignable')}
              >
                Provider assignable
              </Toggle>
              <Toggle
                checked={values.selfAssignable}
                labelPosition={positionType.RIGHT}
                onChange={onChangeToggle('selfAssignable')}
              >
                Member assignable
              </Toggle>
            </Box>
            <Box className={classes.section}>
              <TextField
                label="Tags"
                value={tagValue}
                onChange={e => {
                  setTagValue(e.target.value);
                }}
                onKeyDown={event => {
                  if (event.keyCode === 13 && tagValue) {
                    setTagValue('');
                    setTags([...tags, tagValue?.trim()]);
                  }
                }}
              />
              {tags.length < 1 && <TextError errorMsg={tagsError} />}
            </Box>
            {tags?.length > 0 && (
              <div>
                {tags?.map((data, index) => {
                  return (
                    <Chip
                      key={index}
                      label={data}
                      onDelete={() => {
                        onHandleDelete(index, true);
                      }}
                      className="chip"
                    />
                  );
                })}
              </div>
            )}
            <Box className={classes.section}>
              <TextField
                label="Benefits"
                value={benefitsValue}
                onChange={e => {
                  setBenefitsValue(e.target.value);
                }}
                onKeyDown={event => {
                  if (event.keyCode === 13 && benefitsValue) {
                    setBenefitsValue('');
                    setBenefits([...benefits, benefitsValue?.trim()]);
                  }
                }}
              />
              {benefits.length < 1 && <TextError errorMsg={benefitsError} />}
            </Box>
            {benefits?.length > 0 && (
              <div>
                {benefits?.map((data, index) => {
                  return (
                    <Chip
                      key={index}
                      label={data}
                      onDelete={() => {
                        onHandleDelete(index, false);
                      }}
                      className="chip"
                    />
                  );
                })}
              </div>
            )}

            <Box className={classes.section}>
              {renderLabel('Chatbot image')}
              {imageFile ? (
                <UploadFileProgress
                  file={imageFile}
                  fileName={imageFile.name}
                  fileSize={imageFile.size || 1024}
                  s3Folder="chatBot/"
                  onUploaded={url => {
                    setIsFileChanged(true);
                    setUploadedFileURL(url);
                  }}
                  onRemove={() => {
                    setImageFile(null);
                  }}
                />
              ) : (
                <UploadFile
                  onAcceptedFiles={onAcceptedFiles}
                  description="PNG, JPG, GIF up to 5MB"
                  fileType="image/*"
                />
              )}
              {imageFileError !== '' ? <TextError errorMsg={imageFileError} /> : <></>}
            </Box>
          </Box>

          <Box className={classes.footer}>
            <Button variant={btnType.TEXT} onClick={onClose}>
              Cancel
            </Button>
            <Button onClick={handleSubmit}>{conversation ? 'Save' : 'Add conversation'}</Button>
          </Box>
        </form>
      </Box>
    </Drawer>
  );
};

export { AddConversation };
