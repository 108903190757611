import { Welcome } from './welcome';
import { Sections, subsections } from '../../sessions.constants';
import { CheckIDs } from './check-ids';
import { OpenSession } from './open-session';
import { CloseSession } from './close-session';
import { EvaluationView } from './evaluation-view';

const CurrentSection = ({
  selectedSection,
  selectedSubsection,
  promptsVisible,
  idFront,
  idBack,
  scratchpad,
  addScratchpadNotes,
  openEvaluationsDrawer,
  jumpToOpenSession,
  onAddEvaluation,
  currentEvaluation,
  goToSchedule,
  currentBlock,
  setTextInputInFocus,
  eligibleForAutodoc,
  openGiggle,
  bodyRef,
  selectedIndex,
  leftTrigger,
  rightTrigger,
  dctPreviousResponses,
  nextAppointment,
  listHelper,
  nextGeneralAppointment,
  previousResponses,
  appointment,
  dctSelectedResponses,
  evaluationsCompleted,
  onSkipEvaluation,
  onRejectEvaluation,
  onPreviousResponseSelected,
  useAllPreviousResponses,
}) => {
  switch (selectedSection) {
    case Sections.WELCOME:
      switch (selectedSubsection) {
        case subsections.IMPORTANT_INFORMATION:
          return <Welcome promptsVisible={promptsVisible} />;
        case subsections.DOUBLE_CHECK_IDS:
          return <CheckIDs promptsVisible={promptsVisible} idCardFront={idFront} idCardBack={idBack} />;
        default:
          return null;
      }
    case Sections.EVALUATIONS:
      return (
        <EvaluationView
          promptsVisible={promptsVisible}
          openEvaluationsDrawer={openEvaluationsDrawer}
          jumpToOpenSession={jumpToOpenSession}
          onAddEvaluation={onAddEvaluation}
          setTextInputInFocus={setTextInputInFocus}
          currentEvaluation={currentEvaluation}
          currentBlock={currentBlock}
          bodyRef={bodyRef}
          listHelper={listHelper}
          dctSelectedResponses={dctSelectedResponses}
          onPreviousResponseSelected={onPreviousResponseSelected}
          eligibleForAutodoc={eligibleForAutodoc}
          dctPreviousResponses={dctPreviousResponses}
          evaluationsCompleted={evaluationsCompleted}
          selectedIndex={selectedIndex}
          leftTrigger={leftTrigger}
          rightTrigger={rightTrigger}
          onRejectEvaluation={onRejectEvaluation}
          previousResponses={previousResponses}
          onSkipEvaluation={onSkipEvaluation}
          appointment={appointment}
          useAllPreviousResponses={useAllPreviousResponses}
        />
      );
    case Sections.OPEN_SESSION:
      return (
        <OpenSession
          scratchpad={scratchpad}
          addScratchpadNotes={addScratchpadNotes}
          onAddEvaluation={onAddEvaluation}
        />
      );
    case Sections.CLOSE_SESSION:
      return (
        <CloseSession
          scratchpad={scratchpad}
          addScratchpadNotes={addScratchpadNotes}
          openEvaluationsDrawer={openEvaluationsDrawer}
          openGiggle={openGiggle}
          goToSchedule={goToSchedule}
          nextAppointment={nextGeneralAppointment}
        />
      );
    default:
      return null;
  }
};

export { CurrentSection };
