import { makeStyles } from '@mui/styles';

// colors
import { colors } from '../../../../colors';

export const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  headRow: {
    backgroundColor: colors.neutral100,
  },
  headCell: {
    padding: '16px 0 16px 24px',
    fontFamily: 'Manrope',
    fontWeight: 'bold',
    fontSize: 12,
    maxHeight: 48,
    height: 48,
    lineHeight: 1,
    color: colors.neutral700,
    borderColor: colors.dividerSecondary,
    '& > .MuiTableSortLabel-root': {
      color: colors.neutral700,
    },
  },
  adjustPadding: {
    padding: '16px 4px 16px 8px',
  },
  isLast: {
    position: 'sticky',
    right: 0,
  },
  isLastHeadCell: {
    background: colors.neutral50,
    minWidth: 100,
  },
  isLastBodyCell: { background: colors.white },
  bodyRow: {
    cursor: 'pointer',
    '&.MuiTableRow-hover:hover': {
      backgroundColor: colors.bgTertiary,
    },
  },
  bodyCell: {
    padding: '15px 10px',
    fontFamily: 'Manrope',
    fontWeight: 500,
    fontSize: 14,
    color: colors.neutral900,
    borderColor: colors.dividerSecondary,
  },
  loading: {
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 8,
  },
  arrowDropdownIcon: {
    marginLeft: 10,
    width: 9,
    height: 5,
    transition: '0.4s',
  },
  centerHead: {
    textAlign: 'center',
  },
});
