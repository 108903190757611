import { createEntityAdapter } from '@reduxjs/toolkit';
import { AppointmentStatus } from '../../../packages/constants/CommonConstants';
import dayjs from 'dayjs';

import {
  CANCEL_APPOINTMENT_SUCCESSFUL,
  CREATE_APPOINTMENT,
  CREATE_APPOINTMENT_FAILED,
  CREATE_APPOINTMENT_SUCCESSFUL,
  FETCH_ALL_APPOINTMENT_COUNTS,
  FETCH_ALL_APPOINTMENT_COUNTS_FAILED,
  FETCH_ALL_APPOINTMENT_COUNTS_SUCCESSFUL,
  FETCH_ALL_PROVIDER_SERVICES_FAILED,
  FETCH_ALL_PROVIDER_SERVICES_SUCCESSFUL,
  FETCH_APPOINTMENT_BY_ID_SUCCESSFUL,
  FETCH_APPOINTMENT_DETAILS,
  FETCH_APPOINTMENT_DETAILS_FAILED,
  FETCH_APPOINTMENT_DETAILS_SUCCESSFUL,
  FETCH_APPOINTMENTS,
  FETCH_APPOINTMENTS_FAILED,
  FETCH_APPOINTMENTS_PUBLIC,
  FETCH_APPOINTMENTS_SUCCESSFUL,
  FETCH_MASTER_SCHEDULE_REQUEST,
  FETCH_MASTER_SCHEDULE_REQUEST_FAILED,
  FETCH_MASTER_SCHEDULE_REQUEST_SUCCESSFUL,
  FETCH_MASTER_SCHEDULE_SUCCESSFUL,
  FETCH_PROVIDER_ROLES,
  FETCH_PROVIDER_ROLES_FAILED,
  FETCH_PROVIDER_ROLES_SUCCESSFUL,
  FETCH_PROVIDER_SERVICES,
  FETCH_PROVIDER_SERVICES_FAILED,
  FETCH_PROVIDER_SERVICES_SUCCESSFUL,
  RESET_ERROR_MSG,
  UPDATE_APPOINTMENT,
  UPDATE_APPOINTMENT_FAILED,
  UPDATE_APPOINTMENT_SUCCESSFUL,
} from './actions';
import { USER_LOGOUT } from '../auth/actions';

export const appointmentDetailsAdapter = createEntityAdapter({
  selectId: details => details.appointmentId,
  sortComparer: (a, b) => (a.appointmentId > b.appointmentId ? 1 : -1),
});

export const DEFAULT = {
  isLoading: false,
  isLoadingMore: false,
  isRequesting: false,
  errorMsg: '',
  masterSchedule2: [],
  appointments: [],
  currentPage: 1,
  totalPages: 1,
  total: 0,
  providerServices: {
    isLoading: false,
    errorMsg: null,
    services: [],
  },
  masterSchedule: {
    isLoading: false,
    errorMsg: null,
    masterScheduleItems: [],
    capacity: {
      providersCapacityPerDay: null,
      totalProvidersCapacity: null,
    },
  },
  providerRoles: {
    isLoading: false,
    errorMsg: null,
    roles: [],
  },
  appointmentDetails: appointmentDetailsAdapter.getInitialState({ isLoading: false, errorMsg: null }),
  appointmentDetailById2: {},
  allProviderServices: [],
};

export default function appointmentReducer(state = DEFAULT, action) {
  const { type, payload } = action;

  switch (type) {
    case RESET_ERROR_MSG:
      return {
        ...state,
        errorMsg: '',
      };
    case FETCH_APPOINTMENTS: {
      return {
        ...state,
        isLoading: !payload.loadMore,
        isLoadingMore: payload.loadMore,
        isRequesting: false,
        errorMsg: '',
      };
    }
    case FETCH_APPOINTMENTS_PUBLIC: {
      return {
        ...state,
        isLoading: !payload.loadMore,
        isLoadingMore: payload.loadMore,
        isRequesting: false,
        errorMsg: '',
      };
    }
    case FETCH_APPOINTMENTS_FAILED: {
      return {
        ...state,
        isLoading: false,
        isLoadingMore: false,
        appointments: [],
      };
    }
    case FETCH_APPOINTMENTS_SUCCESSFUL: {
      return {
        ...state,
        isLoading: false,
        isLoadingMore: false,
        appointments: payload.loadingMore
          ? [...state.appointments, ...payload.appointments]
          : payload.appointments,
        currentPage: payload.currentPage,
        totalPages: payload.totalPages,
        total: payload.total,
      };
    }
    case FETCH_APPOINTMENT_DETAILS: {
      return {
        ...state,
        appointmentDetails: {
          ...appointmentDetailsAdapter.removeAll(state.appointmentDetails),
          isLoading: true,
        },
      };
    }
    case FETCH_APPOINTMENT_BY_ID_SUCCESSFUL: {
      return {
        ...state,
        appointmentDetailById2: payload.data1,
      };
    }
    case FETCH_APPOINTMENT_DETAILS_FAILED: {
      return {
        ...state,
        appointmentDetails: {
          ...state.appointmentDetails,
          isLoading: false,
          errorMsg: payload?.message || '',
        },
      };
    }
    case FETCH_APPOINTMENT_DETAILS_SUCCESSFUL: {
      return {
        ...state,
        appointmentDetails: {
          ...appointmentDetailsAdapter.setAll(state.appointmentDetails, [action.payload.details]),
          isLoading: false,
        },
      };
    }
    case FETCH_PROVIDER_SERVICES: {
      return {
        ...state,
        providerServices: {
          ...state.providerServices,
          isLoading: true,
        },
      };
    }
    case FETCH_PROVIDER_SERVICES_SUCCESSFUL: {
      return {
        ...state,
        providerServices: {
          ...state.providerServices,
          isLoading: false,
          services: payload,
        },
      };
    }
    case FETCH_PROVIDER_SERVICES_FAILED: {
      return {
        ...state,
        providerServices: {
          ...state.providerServices,
          isLoading: false,
          services: [],
          errorMsg: payload.message,
        },
      };
    }
    case CREATE_APPOINTMENT:
    case UPDATE_APPOINTMENT: {
      return {
        ...state,
        isRequesting: true,
        errorMsg: '',
      };
    }
    case CREATE_APPOINTMENT_FAILED:
    case CREATE_APPOINTMENT_SUCCESSFUL:
    case UPDATE_APPOINTMENT_FAILED:
      return {
        ...state,
        isRequesting: false,
        errorMsg: payload?.message || '',
      };
    case UPDATE_APPOINTMENT_SUCCESSFUL:
      return {
        ...state,
        isRequesting: false,
        appointments: state.appointments.map(item =>
          item.appointmentId === payload.appointmentId ? payload : item,
        ),
        errorMsg: payload?.message || '',
      };
    case CANCEL_APPOINTMENT_SUCCESSFUL: {
      return {
        ...state,
        appointments: state.appointments
          .map(item => {
            if (item.appointmentId === payload.appointmentId) {
              return {
                ...item,
                status: AppointmentStatus.CANCELLED,
              };
            }
            return item;
          })
          .sort((a, b) => dayjs(a.startTime).unix() - dayjs(b.startTime).unix()),
      };
    }
    case FETCH_MASTER_SCHEDULE_REQUEST: {
      return {
        ...state,
        masterSchedule: { ...state.masterSchedule, isLoading: true, errorMsg: null },
      };
    }
    case FETCH_MASTER_SCHEDULE_REQUEST_SUCCESSFUL: {
      return {
        ...state,
        masterSchedule: {
          ...state.masterSchedule,
          isLoading: false,
          masterScheduleItems: payload.masterScheduleItems,
          capacity: payload.capacity,
        },
      };
    }
    case FETCH_MASTER_SCHEDULE_REQUEST_FAILED: {
      return {
        ...state,
        masterSchedule: {
          ...state.masterSchedule,
          isLoading: false,
          masterScheduleItems: [],
          errorMsg: payload?.message || '',
        },
      };
    }
    case FETCH_PROVIDER_ROLES: {
      return {
        ...state,
        providerRoles: {
          ...state.providerRoles,
          isLoading: true,
          errorMsg: null,
        },
      };
    }
    case FETCH_PROVIDER_ROLES_SUCCESSFUL: {
      return {
        ...state,
        providerRoles: {
          ...state.providerRoles,
          isLoading: false,
          roles: payload.providerRoles,
        },
      };
    }
    case FETCH_PROVIDER_ROLES_FAILED: {
      return {
        ...state,
        providerRoles: {
          ...state.providerRoles,
          isLoading: false,
          roles: [],
        },
      };
    }
    case FETCH_ALL_APPOINTMENT_COUNTS: {
      return {
        ...state,
        errorMsg: '',
      };
    }
    case FETCH_ALL_APPOINTMENT_COUNTS_SUCCESSFUL: {
      return {
        ...state,
        allAppointmentCounts: payload.allAppointmentCounts,
      };
    }
    case FETCH_ALL_APPOINTMENT_COUNTS_FAILED: {
      return {
        ...state,
        isLoading: false,
        errorMsg: payload?.message || '',
      };
    }
    case FETCH_ALL_PROVIDER_SERVICES_SUCCESSFUL: {
      return {
        ...state,
        allProviderServices: payload?.schedule,
      };
    }
    case FETCH_ALL_PROVIDER_SERVICES_FAILED: {
      return {
        ...state,
        allProviderServices: [],
      };
    }
    case FETCH_MASTER_SCHEDULE_SUCCESSFUL: {
      return {
        ...state,
        masterSchedule2: payload?.data,
      };
    }
    case USER_LOGOUT: {
      return DEFAULT;
    }
    default: {
      return state;
    }
  }
}
