import React, { useCallback, useEffect, useState } from 'react';
import { Box, Chip, InputAdornment } from '@mui/material';
import { useFormik } from 'formik';

import { Drawer, drawerType } from '../../../../../../packages/ui/organisms/drawer';
import {
  Button,
  colors,
  fontWeight,
  Heading,
  headingLevel,
  IconButton,
  Icons,
  Input,
  inputSize,
  inputType,
  positionType,
  Select,
  selectType,
  Text,
  Textarea,
  TextError,
  Toggle,
} from '../../../../../../packages';
import { useSelector } from 'react-redux';
import { stateSelector } from '../../../../../../redux/modules/state/selectors';
import { getServiceTypes } from '../../../../../../services/member/member.service';
import { DEFAULT_STATES_OPTIONS } from '../../../../../../constants/CommonConstants';
import { ServiceSchema } from './AddService.schema';
import { useStyles } from './AddService.styles';
import { timeSelects, timeSelectsBuffer } from './AddService.constants';

const defaultValues = {
  name: '',
  description: '',
  cost: 0,
  recommendedCost: 0,
  serviceTypes: [],
  duration: timeSelects[0]?.value,
  bufferTime: timeSelectsBuffer[0]?.value,
  operatingStates: [],
  cptCodes: [],
  primaryCptCodes: [],
  secondaryCptCodes: [],
  slidingScaleProviderFee: 0,
  slidingScaleRate: 0,
  stateLimited: false,
  providerFee: 0,
  cashRate: 0,
  acceptsSlidingScale: false,
  enableNotification: true,
};

const AddService = ({ isOpen, onClose, isEdit, service, onDeleteService, onUpdateService, onAddService }) => {
  const classes = useStyles();
  const [serviceTypeOptions, setServiceTypeOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const { errors, values, handleChange, handleSubmit, touched, setFieldValue, ...rest } = useFormik({
    initialValues: {
      ...defaultValues,
      name: service?.name || '',
      description: service?.description || '',
      cptCodes: service?.cptCodes || [],
      primaryCptCodes: service?.primaryCptCodes || [],
      secondaryCptCodes: service?.secondaryCptCodes || [],
      slidingScaleProviderFee: Number(service?.slidingScaleProviderFee) || 0,
      slidingScaleRate: Number(service?.slidingScaleRate) || 0,
      stateLimited: service?.stateLimited || false,
      providerFee: Number(service?.providerFee) || 0,
      cashRate: Number(service?.cashRate) || 0,
      acceptsSlidingScale: service?.acceptsSlidingScale || false,
      marketCost: service?.marketCost || 0,
      cost: service?.cost || 0,
      recommendedCost: service?.recommendedCost || 0,
      operatingStates: service?.operatingStates || [],
      bufferTime: Number(service?.bufferTime) || 0,
      duration: Number(service?.duration) || 15,
      privateService: service?.privateService || false,
      initialService: service?.initialService || false,
      enableNotification: service?.enableNotification || !isEdit,
      requireSupervisorSignOff: service?.requireSupervisorSignOff || false,
      stateUsageInAppointment: service?.stateUsageInAppointment || false,
    },
    enableReinitialize: true,
    validationSchema: ServiceSchema,
    onSubmit: payload => {
      if (isEdit) {
        onUpdateService && onUpdateService(payload);
      } else {
        onAddService && onAddService(payload);
      }
    },
  });

  const { cpts } = useSelector(stateSelector);
  const cptsList = cpts.map(cptsVal => {
    return { label: `${cptsVal?.code} ${cptsVal?.description || ''}`, value: cptsVal.code };
  });

  const fetchServiceTypes = useCallback(async () => {
    try {
      const { data } = await getServiceTypes();
      setServiceTypeOptions(
        data.map(item => ({
          value: item?.name,
          title: item?.name,
        })),
      );
      setStateOptions(
        DEFAULT_STATES_OPTIONS.map(item => ({
          value: item?.value,
          title: item?.label,
        })),
      );

      // eslint-disable-next-line no-empty
    } catch (error) {}
  }, []);

  useEffect(() => {
    void fetchServiceTypes();
  }, []);

  useEffect(() => {
    if (service && serviceTypeOptions?.length) {
      void (async () => {
        await setFieldValue(
          'serviceTypes',
          service?.serviceTypes?.map(type => ({ title: type, value: type })) ?? [],
        );
      })();
    }
  }, [service, serviceTypeOptions]);

  useEffect(() => {
    if (service) {
      Object.keys(service).forEach(async key => {
        if (key === 'operatingStates') {
          await setFieldValue(
            'operatingStates',
            service.operatingStates?.map(state =>
              DEFAULT_STATES_OPTIONS?.map(option => ({ title: option?.label, value: option?.value })).find(
                item => item?.value === state,
              ),
            ) ?? [],
          );
        }
      });
    }
  }, [service]);

  useEffect(() => {
    if (!isOpen) {
      rest.handleReset({ ...defaultValues });
      rest.setErrors({});
    }
  }, [isOpen]);

  const onChangeToggle = name => async () => {
    await setFieldValue(name, !values[name]);
  };

  const setTouched = name => async () => {
    await rest.setTouched({ ...touched, [name]: true });
  };

  const renderLabel = label => (
    <Heading level={headingLevel.S} className={classes.label} weight={fontWeight.BOLD}>
      {label}
    </Heading>
  );

  const onChangeTags = name => async (_, val) => {
    await setFieldValue(name, val);
  };

  const onHandleDelete = async (_, key, field) => {
    const updatedList = values?.[field]?.filter((__, index) => index !== key);
    await setFieldValue(field, updatedList);
  };

  return (
    <Drawer open={isOpen} onClose={onClose} variant={drawerType.NORMAL} className={classes.drawer}>
      <Box className={classes.wrapper}>
        <Box className={classes.header}>
          <IconButton icon="chevron-left" onClick={onClose} className={classes.backBtn} />
          <Text className={classes.headerTitle}>{isEdit ? service?.name : 'Add service'}</Text>
        </Box>
        <form className={classes.form} onSubmit={handleSubmit}>
          <Box className={classes.formContent}>
            <Box className={classes.section}>
              {renderLabel('Service Name')}
              <Input
                value={values.name}
                name="name"
                placeholder="Enter name"
                onChange={handleChange}
                size={inputSize.M}
                onBlur={setTouched('name')}
                fullWidth
              />
              <TextError errorMsg={touched.name ? errors.name?.toString() : null} />
            </Box>
            <Box className={classes.section}>
              {renderLabel('Service Types')}
              <Input
                value={values.serviceTypes}
                placeholder="Select service types"
                name="serviceTypes"
                isOptionEqualToValue={(option, value) => option.value === value.value}
                className={classes.valuesInput}
                options={serviceTypeOptions}
                onChange={onChangeTags('serviceTypes')}
                variant={inputType.TAGS}
                size={inputSize.M}
                onBlur={setTouched('serviceTypes')}
                fullWidth
              />
              <TextError errorMsg={touched.serviceTypes ? errors.serviceTypes?.toString() : null} />
            </Box>
            <Box className={classes.section}>
              <Toggle
                checked={values.requireSupervisorSignOff}
                labelPosition={positionType.RIGHT}
                onChange={onChangeToggle('requireSupervisorSignOff')}
                sx={{
                  '& span': {
                    fontWeight: 'bold',
                  },
                }}
              >
                Required Supervisor SignOff
              </Toggle>
            </Box>
            <Box className={classes.section}>
              <Toggle
                checked={values.stateUsageInAppointment}
                labelPosition={positionType.RIGHT}
                onChange={onChangeToggle('stateUsageInAppointment')}
                sx={{
                  '& span': {
                    fontWeight: 'bold',
                  },
                }}
              >
                State Used In Appointment
              </Toggle>
            </Box>
            {values.stateUsageInAppointment && (
              <Box className={classes.section}>
                {renderLabel('State')}
                <Input
                  value={values.operatingStates}
                  placeholder="Select States"
                  name="operatingStates"
                  isOptionEqualToValue={(option, value) => option.value === value.value}
                  className={classes.valuesInput}
                  options={stateOptions}
                  onChange={onChangeTags('operatingStates')}
                  variant={inputType.TAGS}
                  size={inputSize.M}
                  onBlur={setTouched('operatingStates')}
                  fullWidth
                />
                <TextError errorMsg={touched.operatingStates ? errors.operatingStates?.toString() : null} />
              </Box>
            )}
            <Box className={classes.section}>
              {renderLabel('Description')}
              <Textarea
                value={values.description}
                name="description"
                onChange={handleChange}
                onBlur={setTouched('description')}
                minRows={5}
                placeholder="Enter description"
              />
              <TextError errorMsg={touched.description ? errors.description?.toString() : null} />
            </Box>
            <Box className={classes.section}>
              {renderLabel('CPT codes')}
              <Select
                options={cptsList}
                value=""
                displayEmpty
                emptyText=""
                onChange={async ({ target }) => {
                  if (!values.cptCodes) {
                    await setFieldValue('cptCodes', [target.value]);
                  } else if (!values.cptCodes.includes(target.value)) {
                    const codes = [...values.cptCodes, target.value];
                    await setFieldValue('cptCodes', codes);
                  }
                }}
                variant={selectType.SECONDARY}
                fullWidth
              />
              {/* <TextError errorMsg={touched.name ? errors.name?.toString() : null} /> */}
            </Box>
            {values?.cptCodes?.length > 0 && (
              <div>
                {values?.cptCodes?.map((data, index) => {
                  return (
                    <Chip
                      key={index}
                      label={data}
                      onDelete={async () => {
                        // onHandleDelete(index);
                        await onHandleDelete(data, index, 'cptCodes');
                      }}
                      className="chip"
                    />
                  );
                })}
              </div>
            )}
            <Box className={classes.section}>
              {renderLabel('Primary CPT codes')}
              <Select
                options={cptsList}
                value=""
                displayEmpty
                emptyText=""
                onChange={async ({ target }) => {
                  if (!values.primaryCptCodes) {
                    await setFieldValue('primaryCptCodes', [target.value]);
                  } else if (!values.primaryCptCodes.includes(target.value)) {
                    const codes = [...values.primaryCptCodes, target.value];
                    await setFieldValue('primaryCptCodes', codes);
                  }
                }}
                variant={selectType.SECONDARY}
                fullWidth
              />
              {/* <TextError errorMsg={touched.name ? errors.name?.toString() : null} /> */}
            </Box>
            {values?.primaryCptCodes?.length > 0 && (
              <div>
                {values?.primaryCptCodes?.map((data, index) => {
                  return (
                    <Chip
                      key={index}
                      label={data}
                      onDelete={async () => {
                        // onHandleDelete(index);
                        await onHandleDelete(data, index, 'primaryCptCodes');
                      }}
                      className="chip"
                    />
                  );
                })}
              </div>
            )}
            <Box className={classes.section}>
              {renderLabel('Secondary CPT codes')}
              <Select
                options={cptsList}
                value=""
                displayEmpty
                emptyText=""
                onChange={async ({ target }) => {
                  if (!values.secondaryCptCodes) {
                    await setFieldValue('secondaryCptCodes', [target.value]);
                  } else if (!values.secondaryCptCodes.includes(target.value)) {
                    const codes = [...values.secondaryCptCodes, target.value];
                    await setFieldValue('secondaryCptCodes', codes);
                  }
                }}
                variant={selectType.SECONDARY}
                fullWidth
              />
              {/* <TextError errorMsg={touched.name ? errors.name?.toString() : null} /> */}
            </Box>
            {values?.secondaryCptCodes?.length > 0 && (
              <div>
                {values?.secondaryCptCodes?.map((data, index) => {
                  return (
                    <Chip
                      key={index}
                      label={data}
                      onDelete={async () => {
                        // onHandleDelete(index);
                        await onHandleDelete(data, index, 'secondaryCptCodes');
                      }}
                      className="chip"
                    />
                  );
                })}
              </div>
            )}
            {/* </Box> */}
            <Box display="flex" justifyContent="space-between" gap="16px">
              <Box className={classes.section} style={{ flex: 1 }}>
                {renderLabel('Duration')}
                <Select
                  options={timeSelects}
                  value={values.duration}
                  placeholder="Select a time"
                  name="duration"
                  onChange={handleChange}
                  variant={selectType.SECONDARY}
                  className={classes.timeSelect}
                  onBlur={setTouched('duration')}
                  fullWidth
                />
                <TextError errorMsg={touched.duration ? errors.duration?.toString() : null} />
                <Icons glyph="clock" className={classes.clockIcon} color={colors.neutral400} />
              </Box>
              <Box className={classes.section} style={{ flex: 1 }}>
                {renderLabel('Buffer Time')}
                <Select
                  options={timeSelectsBuffer}
                  value={values.bufferTime}
                  placeholder="Select a time"
                  name="bufferTime"
                  onChange={handleChange}
                  variant={selectType.SECONDARY}
                  className={classes.timeSelect}
                  fullWidth
                />
                <Icons glyph="clock" className={classes.clockIcon} color={colors.neutral400} />
              </Box>
            </Box>
            <Box display="flex" justifyContent="space-between" gap="8px" flexWrap="wrap">
              <Box className={classes.section}>
                {renderLabel('Cost')}
                <Input
                  value={+values?.cost}
                  name="cost"
                  type="number"
                  placeholder="Enter cost"
                  onChange={handleChange('cost')}
                  size={inputSize.M}
                  onBlur={setTouched('cost')}
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" className={classes.startAdorment}>
                        $
                      </InputAdornment>
                    ),
                  }}
                />
                <TextError errorMsg={touched.cost ? errors.cost?.toString() : null} />
              </Box>
              <Box className={classes.section}>
                {renderLabel('Market cost')}
                <Input
                  value={+values?.marketCost}
                  name="marketCost"
                  type="number"
                  placeholder="Enter market cost"
                  onChange={handleChange('marketCost')}
                  size={inputSize.M}
                  onBlur={setTouched('marketCost')}
                  fullWidth
                  className={classes.input}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" className={classes.startAdorment}>
                        $
                      </InputAdornment>
                    ),
                  }}
                />
                <TextError errorMsg={touched.marketCost ? errors.marketCost?.toString() : null} />
              </Box>
              <Box className={classes.section}>
                {renderLabel('Recommended cost')}
                <Input
                  value={+values.recommendedCost}
                  name="recommendedCost"
                  type="number"
                  placeholder="Enter recommended cost"
                  onChange={handleChange('recommendedCost')}
                  size={inputSize.M}
                  onBlur={setTouched('recommendedCost')}
                  fullWidth
                  className={classes.input}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" className={classes.startAdorment}>
                        $
                      </InputAdornment>
                    ),
                  }}
                />
                <TextError errorMsg={touched?.recommendedCost ? errors?.recommendedCost?.toString() : null} />
              </Box>
              <Box className={classes.section}>
                {renderLabel('Cash Rate')}
                <Input
                  value={+values?.cashRate}
                  name="cashRate"
                  type="number"
                  placeholder="Enter cash rate"
                  onChange={handleChange('cashRate')}
                  size={inputSize.M}
                  onBlur={setTouched('cashRate')}
                  fullWidth
                  className={classes.input}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" className={classes.startAdorment}>
                        $
                      </InputAdornment>
                    ),
                  }}
                />
                <TextError errorMsg={touched.cashRate ? errors.cashRate?.toString() : null} />
              </Box>
              <Box className={classes.section}>
                {renderLabel('Provider Fee')}
                <Input
                  value={+values?.providerFee}
                  name="providerFee"
                  type="number"
                  placeholder="Enter provider fee"
                  onChange={handleChange('providerFee')}
                  size={inputSize.M}
                  onBlur={setTouched('providerFee')}
                  fullWidth
                  className={classes.input}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" className={classes.startAdorment}>
                        $
                      </InputAdornment>
                    ),
                  }}
                />
                <TextError errorMsg={touched?.providerFee ? errors?.providerFee?.toString() : null} />
              </Box>
            </Box>
            <Box className={classes.section}>
              <Toggle
                checked={values?.acceptsSlidingScale}
                labelPosition={positionType.RIGHT}
                onChange={onChangeToggle('acceptsSlidingScale')}
                sx={{
                  '& span': {
                    fontWeight: 'bold',
                  },
                }}
              >
                Accepts Sliding Scale
              </Toggle>
            </Box>
            {values?.acceptsSlidingScale && (
              <>
                <Box className={classes.section} style={{ flex: 1 }}>
                  {renderLabel('Sliding Scale Rate')}
                  <Input
                    value={+values?.slidingScaleRate}
                    name="slidingScaleRate"
                    type="number"
                    placeholder="Enter sliding Scale Rate"
                    onChange={handleChange('slidingScaleRate')}
                    size={inputSize.M}
                    onBlur={setTouched('slidingScaleRate')}
                    fullWidth
                    className={classes.input}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start" className={classes.startAdorment}>
                          $
                        </InputAdornment>
                      ),
                    }}
                  />
                  <TextError
                    errorMsg={touched?.slidingScaleRate ? errors?.slidingScaleRate?.toString() : null}
                  />
                </Box>
                <Box className={classes.section} style={{ flex: 1 }}>
                  {renderLabel('Sliding Scale Provider Fee')}
                  <Input
                    value={+values?.slidingScaleProviderFee}
                    name="slidingScaleProviderFee"
                    type="number"
                    placeholder="Enter sliding provider fee"
                    onChange={handleChange('slidingScaleProviderFee')}
                    size={inputSize.M}
                    onBlur={setTouched('slidingScaleProviderFee')}
                    fullWidth
                    className={classes.input}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start" className={classes.startAdorment}>
                          $
                        </InputAdornment>
                      ),
                    }}
                  />
                  <TextError
                    errorMsg={
                      touched?.slidingScaleProviderFee ? errors?.slidingScaleProviderFee?.toString() : null
                    }
                  />
                </Box>
              </>
            )}
            <Box className={classes.section}>
              <Toggle
                checked={values.privateService}
                labelPosition={positionType.RIGHT}
                onChange={onChangeToggle('privateService')}
                sx={{
                  '& span': {
                    fontWeight: 'bold',
                  },
                }}
              >
                Private Service
              </Toggle>
            </Box>
            <Box className={classes.section}>
              <Toggle
                checked={values.initialService}
                labelPosition={positionType.RIGHT}
                onChange={onChangeToggle('initialService')}
                sx={{
                  '& span': {
                    fontWeight: 'bold',
                  },
                }}
              >
                Initial Service
              </Toggle>
            </Box>
            <Box className={classes.section}>
              <Toggle
                checked={values.enableNotification}
                labelPosition={positionType.RIGHT}
                onChange={onChangeToggle('enableNotification')}
                sx={{
                  '& span': {
                    fontWeight: 'bold',
                  },
                }}
              >
                Notifications
              </Toggle>
            </Box>
          </Box>
          <Box className={classes.footer}>
            <Box>
              {isEdit && (
                <IconButton icon="delete-outlined-2" onClick={onDeleteService} className={classes.removeBtn}>
                  Delete service
                </IconButton>
              )}
            </Box>
            <Button onClick={handleSubmit}>{isEdit ? 'Update' : 'Add service'}</Button>
          </Box>
        </form>
      </Box>
    </Drawer>
  );
};

export { AddService };
