/* eslint-disable max-len */
import cx from 'clsx';

// types
// styles
import { useStyles } from './shared/styles';

const PasswordLock = ({ className, color, onClick }) => {
  const classes = useStyles();
  return (
    <svg
      className={cx({
        [classes.root]: true,
        [className || '']: className,
      })}
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M13 6.66665H14.6667C14.8877 6.66665 15.0996 6.75444 15.2559 6.91072C15.4122 7.067 15.5 7.27897 15.5 7.49998V17.5C15.5 17.721 15.4122 17.933 15.2559 18.0892C15.0996 18.2455 14.8877 18.3333 14.6667 18.3333H1.33333C1.11232 18.3333 0.900358 18.2455 0.744078 18.0892C0.587797 17.933 0.5 17.721 0.5 17.5V7.49998C0.5 7.27897 0.587797 7.067 0.744078 6.91072C0.900358 6.75444 1.11232 6.66665 1.33333 6.66665H3V5.83331C3 4.50723 3.52678 3.23546 4.46447 2.29778C5.40215 1.3601 6.67392 0.833313 8 0.833313C9.32608 0.833313 10.5979 1.3601 11.5355 2.29778C12.4732 3.23546 13 4.50723 13 5.83331V6.66665ZM11.3333 6.66665V5.83331C11.3333 4.94926 10.9821 4.10141 10.357 3.47629C9.7319 2.85117 8.88405 2.49998 8 2.49998C7.11594 2.49998 6.2681 2.85117 5.64298 3.47629C5.01786 4.10141 4.66667 4.94926 4.66667 5.83331V6.66665H11.3333ZM7.16667 11.6666V13.3333H8.83333V11.6666H7.16667ZM3.83333 11.6666V13.3333H5.5V11.6666H3.83333ZM10.5 11.6666V13.3333H12.1667V11.6666H10.5Z"
        fill={color}
      />
    </svg>
  );
};

export default PasswordLock;
