import cx from 'clsx';
import { Icons } from '../../../icons';
import { Heading, headingLevel, Text, textLevel } from '../../atoms/typography';
import { colors } from '../../../colors';
import { NoteCardBottom } from './NoteCardBottom';
import { NoteStyle } from '../../../constants/CommonConstants';
import { useStyles } from './NoteCard.styles';
import { Badge, badgeStyle, badgeType } from '../../atoms/badge';

const NOTES_STATUS_CATEGORIES = {
  NO_ACTION_NEEDED: 'NO_ACTION_NEEDED',
  NEEDS_ACTION: 'NEEDS_ACTION',
};

const STATUS_TEXTS = {
  REPORTED: 'REPORTED',
  REVIEWED_BY_MANAGEMENT: 'REVIEWED_BY_MANAGEMENT',
  RESOLVED: 'RESOLVED',
  RECEIVED_BY_CARE_MANAGEMENT: 'RECEIVED_BY_CARE_MANAGEMENT',
  SENT_TO_MEMBER: 'SENT_TO_MEMBER',
  SENT_TO_REQUESTED_CONTACT: 'SENT_TO_REQUESTED_CONTACT',
  COMPLETED: 'COMPLETED',
  REQUESTED: 'REQUESTED',
  SENT_TO_LAB: 'SENT_TO_LAB',
  NO_RESPONSE: 'NO_RESPONSE',
  REJECTED: 'REJECTED',
  SUBMITTED: 'SUBMITTED',
  PENDING: 'PENDING',
  UNSUCCESSFUL: 'UNSUCCESSFUL',
  REMOVED: 'REMOVED',
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
};

export const getSpecialStatus = status => {
  switch (status) {
    case STATUS_TEXTS.REPORTED:
      return NOTES_STATUS_CATEGORIES.NEEDS_ACTION;
    case STATUS_TEXTS.REVIEWED_BY_MANAGEMENT:
      return NOTES_STATUS_CATEGORIES.NO_ACTION_NEEDED;
    case STATUS_TEXTS.RESOLVED:
      return NOTES_STATUS_CATEGORIES.NO_ACTION_NEEDED;
    case STATUS_TEXTS.RECEIVED_BY_CARE_MANAGEMENT:
      return NOTES_STATUS_CATEGORIES.NEEDS_ACTION;
    case STATUS_TEXTS.SENT_TO_MEMBER:
      return NOTES_STATUS_CATEGORIES.NEEDS_ACTION;
    case STATUS_TEXTS.SENT_TO_REQUESTED_CONTACT:
      return NOTES_STATUS_CATEGORIES.NEEDS_ACTION;
    case STATUS_TEXTS.COMPLETED:
      return NOTES_STATUS_CATEGORIES.NO_ACTION_NEEDED;
    case STATUS_TEXTS.REQUESTED:
      return NOTES_STATUS_CATEGORIES.NEEDS_ACTION;
    case STATUS_TEXTS.SENT_TO_LAB:
      return NOTES_STATUS_CATEGORIES.NEEDS_ACTION;
    case STATUS_TEXTS.NO_RESPONSE:
      return NOTES_STATUS_CATEGORIES.NO_ACTION_NEEDED;
    case STATUS_TEXTS.REJECTED:
      return NOTES_STATUS_CATEGORIES.NO_ACTION_NEEDED;
    case STATUS_TEXTS.SUBMITTED:
      return NOTES_STATUS_CATEGORIES.NEEDS_ACTION;
    case STATUS_TEXTS.PENDING:
      return NOTES_STATUS_CATEGORIES.NEEDS_ACTION;
    case STATUS_TEXTS.UNSUCCESSFUL:
      return NOTES_STATUS_CATEGORIES.NO_ACTION_NEEDED;
    case STATUS_TEXTS.REMOVED:
      return NOTES_STATUS_CATEGORIES.NO_ACTION_NEEDED;
    case STATUS_TEXTS.ACTIVE:
      return NOTES_STATUS_CATEGORIES.NEEDS_ACTION;
    case STATUS_TEXTS.INACTIVE:
      return NOTES_STATUS_CATEGORIES.NO_ACTION_NEEDED;
    default:
      return '';
  }
};

const transformText = text => {
  return text.toLowerCase().split('_').join(' ');
};

const NoteCard = ({
  className = '',
  icon,
  note,
  isAppointment = false,
  onClick = null,
  isProvider = false,
}) => {
  const type = NoteStyle[note?.flag] || NoteStyle.GENERAL;
  const classes = useStyles({ type });

  return (
    <div className={cx(classes.root, className)} onClick={onClick}>
      <div className={classes.topSection}>
        <div className={classes.row}>
          <div className={classes.topContentWrapper}>
            {icon && (
              <div className={cx(classes.iconWrapper, note?.flag)}>
                <Icons color={colors[`${type}500`]} glyph={icon} />
              </div>
            )}
            <div>
              <Heading className={classes.title} level={headingLevel.S}>
                {note.name}
              </Heading>
              <Text className={classes.subTitle} level={textLevel.S}>
                {transformText(note.type)}
              </Text>
            </div>
          </div>
          <Badge
            variant={
              getSpecialStatus(note?.status) === NOTES_STATUS_CATEGORIES.NEEDS_ACTION
                ? badgeType.OUTLINED
                : badgeType.FILLED
            }
            style={
              getSpecialStatus(note?.status) === NOTES_STATUS_CATEGORIES.NEEDS_ACTION
                ? badgeStyle.INACTIVE
                : badgeStyle.DARK
            }
            className={classes.status}
          >
            {transformText(note?.status)}
          </Badge>
        </div>
        <Text className={classes.description} level={textLevel.M}>
          {note.description}
        </Text>
      </div>
      <NoteCardBottom note={note} isAppointment={isAppointment} type="provider" isProvider={isProvider} />
    </div>
  );
};

export { NoteCard };
