import { Drawer, drawerType } from '../../../../packages/ui/organisms/drawer';
import React, { useEffect, useState } from 'react';
import cx from 'clsx';
import { Box } from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {
  Checkbox,
  fontWeight,
  Heading,
  headingLevel,
  Input,
  inputSize,
  inputType,
  positionType,
  RadioGroup,
  radioGroupType,
  Select,
  selectSize,
  selectType,
  TextError,
  Toggle,
} from '../../../../packages';
import { TabPanel, Tabs } from '../../../../packages/ui/atoms/tabs';
import { UploadFile } from '../../../../packages/ui/templates/upload-file';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { useStyles } from './AddMemberDrawer.styles';
import { AddMemberSchema } from './AddMember.schema';
import { getPayers, getStatesSimple } from '../../../../services/state/state.service';
import { getProfile } from '../../../../redux/modules/profile/selectors';
import { profileActionCreators } from '../../../../redux/modules/profile';
import UploadFileProgress from '../../conversations/components/upload-flie-progress';
import { showSnackbar } from '../../../../redux/modules/snackbar';
import { addMemberOrMembersInBulk } from '../../../../services/profile/profile.service';
import { stateSelector } from '../../../../redux/modules/state/selectors';
import DatePickerInput from '../../claim-detail/components/DatePicker';
import { MediaManager } from '../../../../services/mediaManager/mediaManager';

const tabs = [
  { label: 'Single member', tabKey: 'single-member' },
  { label: 'Bulk csv import', tabKey: 'bulk-members' },
];
const radioOptions = [
  { label: 'Male', value: 'male' },
  { label: 'Female', value: 'female' },
  { label: 'Intersex', value: 'intersex' },
];

const defaultValues = {
  firstName: '',
  lastName: '',
  gender: 'male',
  email: '',
  phoneNumber: '',
  dob: new Date().toISOString(),
  insuranceName: '',
  insuranceMemberId: '',
  insuranceGroupNumber: '',
  state: '',
  city: '',
  zipCode: '',
  address1: '',
  address2: '',
  referralSources: [],
  primaryInterests: [],
  profileImage: '',
  sendInvite: false,
  primaryInsured: true,
  primaryInsuredFirstName: '',
  primaryInsuredLastName: '',
  primaryInsuredDob: dayjs(),
};
const AddMemberDrawer = ({ open, onClose, closeAndRefresh, isAdmin }) => {
  const [referralSourcesOptions, setRefferalSourcesOptions] = useState([]);
  const [primaryInterestsOptions, setPrimaryInterestsOptions] = useState([]);
  const [imageFile, setImageFile] = useState();
  const [csvFile, setCsvFile] = useState();
  const [imageURL, setImageURL] = useState('');
  const [states, setStates] = useState([]);
  let payload;

  const processFile = async () => {
    const filesRead = await MediaManager.uploadDisplayPicture(imageFile, e => {
      console.log(e.percent);
    });
    return filesRead.response.key;
  };
  const dispatch = useDispatch();

  const { states: statesList } = useSelector(stateSelector);

  const getStatesList = () => {
    const orderedStates = statesList.map(({ state }) => ({ label: state.name, value: state.name }));
    setStates(orderedStates);
  };
  const addNewMember = async values => {
    try {
      let imageUrl = null;
      if (imageURL) {
        imageUrl = imageURL;
      }
      if (imageFile && !imageURL) {
        imageUrl = await processFile();
        setImageURL(imageUrl);
      }
      payload = {
        ...values,
        primaryInterests: values.primaryInterests.map(i => i.value),
        referralSources: values.referralSources.map(i => i.value),
        profileImage: imageUrl,
        dob: dayjs(values.dob).format('YYYY-MM-DD'),
        primaryInsuredDob: values.primaryInsured ? values.primaryInsuredDob.format('YYYY-MM-DD') : null,
      };
      const formData = new FormData();
      formData.append('memberDetails', JSON.stringify(payload));
      const response = await addMemberOrMembersInBulk(formData);
      if (response.status === 200) {
        dispatch(
          showSnackbar({
            snackType: 'success',
            snackMessage: `successful: ${response.data.successful}. failed: ${response.data.failed}`,
          }),
        );

        closeAndRefresh();
      }
    } catch (error) {
      dispatch(
        showSnackbar({
          snackType: 'error',
          snackMessage: error.data.errors[0].endUserMessage,
        }),
      );
    }
  };
  const [payers, setPayers] = useState([]);
  const { demographicMetadata = {} } = useSelector(getProfile);
  const { errors, values, handleChange, handleSubmit, touched, ...rest } = useFormik({
    initialValues: { ...defaultValues },
    enableReinitialize: true,
    validationSchema: AddMemberSchema,
    onSubmit: () => {
      const schema = yup.string().email();
      const result = schema.isValidSync(values.email);
      if (result) {
        void addNewMember(values);
      } else {
        dispatch(
          showSnackbar({
            snackType: 'error',
            snackMessage: 'Invalid email provided',
          }),
        );
      }
    },
  });
  const handleBulkSubmit = async () => {
    try {
      const formData = new FormData();
      formData.append('file', csvFile);
      const response = await addMemberOrMembersInBulk(formData);
      if (response.status === 200) {
        dispatch(
          showSnackbar({
            snackType: 'success',
            snackMessage: `successful: ${response.data.successful}. failed: ${response.data.failed}`,
          }),
        );
        setCsvFile(null);
        closeAndRefresh();
      }
    } catch (error) {
      dispatch(
        showSnackbar({
          snackType: 'error',
          snackMessage: error.data.errors[0].endUserMessage,
        }),
      );
    }
  };

  const setTouched = name => async () => {
    await rest.setTouched({ ...touched, [name]: true });
  };

  const [currentTab, setCurrentTab] = useState(tabs[0].tabKey);
  const classes = useStyles();

  const renderLabel = label => (
    <Heading level={headingLevel.S} className={classes.label} weight={fontWeight.BOLD}>
      {label}
    </Heading>
  );

  // const handleDateChange = (date: Date) => {
  //   void rest.setFieldValue('dob', date.toISOString());
  // };
  const handlePhoneChange = val => {
    void rest.setFieldValue('phoneNumber', val);
  };

  const onChangeTags = name => async (_, val) => {
    await rest.setFieldValue(name, val);
  };
  const onAcceptedFiles = files => {
    const file = files[0];
    if (file && file.size / 1024 < 5000) {
      setImageFile(file);
      return;
    }
    dispatch(
      showSnackbar({
        snackType: 'error',
        snackMessage: 'Sorry! file size is too large',
      }),
    );
  };
  const onAcceptedCSVFile = files => {
    const file = files[0];
    if (file && file.size / 1024 < 5000) {
      setCsvFile(file);
      return;
    }
    dispatch(
      showSnackbar({
        snackType: 'error',
        snackMessage: 'Sorry! file size is too large',
      }),
    );
  };
  const getPayers2 = async state => {
    const { data } = await getStatesSimple();
    const stateData = data?.data?.find(item => item?.name?.toLowerCase() === state?.toLowerCase());
    const response = await getPayers(stateData?._id);
    const statePayers = response.data.data.filter(item => item?.isActive);
    const uniquePayersOrdered = statePayers.map(({ _id, name }) => ({ label: name, value: _id }));
    setPayers(uniquePayersOrdered);
  };

  const resetDrawer = () => {
    void rest.resetForm();
    setImageFile(null);
    setCsvFile(null);
  };
  useEffect(() => {
    if (open) {
      // void getPayers();
      void getStatesList();
      dispatch(profileActionCreators.fetchDemographicMetadata());
      const orderedPrimaryInterests = demographicMetadata.primaryInterests.map(source => ({
        label: source,
        value: source,
        title: source,
      }));
      setPrimaryInterestsOptions(orderedPrimaryInterests);
      const orderedRefferalSources = demographicMetadata.referralSources.sort().map(source => ({
        label: source,
        value: source,
        title: source,
      }));
      setRefferalSourcesOptions(orderedRefferalSources);
    }
    if (!open) {
      resetDrawer();
    }
  }, [open]);

  useEffect(() => {
    if (values.state.trim() !== '') {
      void getPayers2(values.state);
    }
  }, [values.state]);

  const renderContent1 = () => (
    <Box className={classes.bodyContainer}>
      <form onSubmit={handleSubmit}>
        <Box className={classes.nameContainer}>
          <Box className={classes.firstName}>
            {renderLabel('First name')}
            <Input
              value={values.firstName}
              name="firstName"
              placeholder="Enter your first name"
              onChange={handleChange}
              size={inputSize.M}
              fullWidth
              onBlur={setTouched('firstName')}
            />
          </Box>
          <Box className={classes.lastName}>
            {renderLabel('Last name')}
            <Input
              value={values.lastName}
              name="lastName"
              placeholder="Enter your last name"
              onChange={handleChange}
              size={inputSize.M}
              fullWidth
              onBlur={setTouched('lastName')}
            />
          </Box>
        </Box>
        <Box className={classes.errorContainer}>
          <Box className={classes.errorBox}>
            <TextError errorMsg={touched.firstName ? errors.firstName?.toString() : null} />
          </Box>
          <Box className={classes.lastNameError}>
            <TextError errorMsg={touched.lastName ? errors.lastName?.toString() : null} />
          </Box>
        </Box>
        <Box
          className={cx({
            [classes.radioContainer]: true,
            [classes.marginClass]: true,
          })}
        >
          <RadioGroup
            variant={radioGroupType.HORIZONTAL}
            options={radioOptions}
            defaultValue={radioOptions[0].value}
            onChange={handleChange}
            name="gender"
            value={values.gender}
            className={classes.radio}
          />
        </Box>
        <Box
          className={cx({
            [classes.marginClass]: true,
          })}
        >
          <Box>
            {renderLabel('Email')}
            <Box className={classes.emailContainer}>
              <Input
                value={values.email}
                name="email"
                placeholder="Enter your email"
                onChange={handleChange}
                size={inputSize.M}
                fullWidth
              />
              <Box>
                <Heading level={headingLevel.S} className={classes.label} weight={fontWeight.MEDIUM}>
                  <Checkbox
                    checked={values.sendInvite}
                    onChange={handleChange}
                    name="sendInvite"
                    className={classes.checkbox}
                  />
                  Send invite
                </Heading>
              </Box>
            </Box>
            <Box className={classes.errorContainer}>
              <Box className={classes.errorBox}>
                <TextError errorMsg={touched.email ? errors.email?.toString() : null} />
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          className={cx({
            [classes.marginClass]: true,
          })}
        >
          <Box>
            {renderLabel('Phone')}
            <Box className={classes.phoneContainer}>
              <Input
                value={values.phoneNumber}
                name="phoneNumber"
                placeholder="Enter your phoneNumberNumber"
                onChange={handlePhoneChange}
                size={inputSize.M}
                onBlur={setTouched('phoneNumber')}
                variant={inputType.PHONE}
              />
            </Box>
            <Box className={classes.errorContainer}>
              <Box className={classes.errorBox}>
                <TextError errorMsg={touched.phoneNumber ? errors.phoneNumber?.toString() : null} />
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          className={cx({
            [classes.marginClass]: true,
          })}
        >
          {renderLabel('Date of birth')}
          <Box className={classes.dobContainer}>
            <DatePickerInput
              date={dayjs(values.dob)}
              onChange={date => {
                void rest.setFieldValue('dob', date);
              }}
            />
          </Box>
        </Box>
        {isAdmin && (
          <>
            <Box
              className={cx({
                [classes.marginClass]: true,
              })}
            >
              <Box className={classes.address}>
                {renderLabel('Address line 1')}
                <Input
                  value={values.address1}
                  name="address1"
                  placeholder="address line 1"
                  onChange={handleChange}
                  size={inputSize.M}
                  fullWidth
                />
              </Box>
            </Box>
            <Box
              className={cx({
                [classes.marginClass]: true,
              })}
            >
              <Box className={classes.address}>
                {renderLabel('Address line 2')}
                <Input
                  value={values.address2}
                  name="address2"
                  placeholder="address line 2"
                  onChange={handleChange}
                  size={inputSize.M}
                  fullWidth
                />
              </Box>
            </Box>
            <Box
              className={cx({
                [classes.marginClass]: true,
              })}
            >
              <Box className={classes.city}>
                {renderLabel('City')}
                <Input
                  value={values.city}
                  name="city"
                  placeholder="city"
                  onChange={handleChange}
                  size={inputSize.M}
                  fullWidth
                />
              </Box>
            </Box>
            <Box
              className={cx({
                [classes.marginClass]: true,
                [classes.cityAndZipCodeContainer]: true,
              })}
            >
              <Box className={classes.state}>
                {renderLabel('State')}
                <Select
                  variant={selectType.SECONDARY}
                  options={states}
                  size={selectSize.S}
                  name="state"
                  className={classes.select}
                  value={values.state}
                  onChange={handleChange}
                />
              </Box>
              <Box className={classes.zipCode}>
                {renderLabel('Zip code')}
                <Input
                  value={values.zipCode}
                  name="zipCode"
                  placeholder="zip code"
                  onChange={handleChange}
                  size={inputSize.M}
                  fullWidth
                />
              </Box>
            </Box>
            <Box
              className={cx({
                [classes.marginClass]: true,
              })}
            >
              <Box className={classes.memberId}>
                {renderLabel('Insurance carrier')}
                <Select
                  variant={selectType.SECONDARY}
                  options={payers}
                  size={selectSize.S}
                  className={classes.select}
                  name="insuranceName"
                  onChange={handleChange}
                  value={values.insuranceName}
                />
              </Box>
            </Box>
            {values.insuranceName && values.insuranceName !== '' && (
              <>
                <Box
                  className={cx({
                    [classes.marginClass]: true,
                    [classes.idAndInsuaranceContainer]: true,
                  })}
                >
                  <Box className={classes.insuarance}>
                    {renderLabel('Member id')}
                    <Input
                      value={values.insuranceMemberId}
                      name="insuranceMemberId"
                      placeholder="member id"
                      onChange={handleChange}
                      size={inputSize.M}
                      fullWidth
                    />
                  </Box>
                  <Box className={classes.memberId}>
                    {renderLabel('Group number')}
                    <Input
                      value={values.insuranceGroupNumber}
                      name="insuranceGroupNumber"
                      placeholder="Group number"
                      onChange={handleChange}
                      size={inputSize.M}
                      fullWidth
                    />
                  </Box>
                </Box>
                <Box
                  className={cx({
                    [classes.marginClass]: true,
                  })}
                >
                  <Box className={classes.memberId}>
                    <Toggle
                      labelPosition={positionType.RIGHT}
                      checked={values.primaryInsured}
                      onChange={checked => {
                        void rest.setFieldValue('primaryInsured', checked);
                        void rest.setFieldValue('primaryInsuredFirstName', '');
                        void rest.setFieldValue('primaryInsuredLastName', '');
                        void rest.setFieldValue('primaryInsuredDob', dayjs());
                      }}
                    >
                      Member is the primary insured
                    </Toggle>
                  </Box>
                </Box>
                {!values.primaryInsured && (
                  <>
                    <Box
                      className={cx({
                        [classes.marginClass]: true,
                        [classes.idAndInsuaranceContainer]: true,
                      })}
                    >
                      <Box className={classes.insuarance}>
                        {renderLabel('Primary Insured First Name')}
                        <Input
                          value={values.primaryInsuredFirstName || ''}
                          name="primaryInsuredFirstName"
                          placeholder="Enter first name"
                          onChange={handleChange}
                          size={inputSize.M}
                          fullWidth
                        />
                      </Box>
                      <Box className={classes.memberId}>
                        {renderLabel('Primary Insured Last Name')}
                        <Input
                          value={values.primaryInsuredLastName}
                          name="primaryInsuredLastName"
                          placeholder="Enter last name"
                          onChange={handleChange}
                          size={inputSize.M}
                          fullWidth
                        />
                      </Box>
                    </Box>
                    <Box
                      className={cx({
                        [classes.marginClass]: true,
                      })}
                    >
                      {renderLabel('Primary Insured DOB')}
                      <Box className={classes.dobContainer}>
                        <DatePickerInput
                          date={values.primaryInsuredDob}
                          onChange={date => {
                            void rest.setFieldValue('primaryInsuredDob', date);
                          }}
                        />
                        <TextError errorMsg={errors.dob} />
                      </Box>
                    </Box>
                  </>
                )}
              </>
            )}
            <Box
              className={cx({
                [classes.marginClass]: true,
              })}
            >
              <Box className={classes.referralSources}>
                {renderLabel('Referral source')}
                <Input
                  value={values.referralSources}
                  // placeholder="Select refferal sources"
                  name="referralSources"
                  isOptionEqualToValue={(option, value) => option.value === value.value}
                  options={referralSourcesOptions}
                  onChange={onChangeTags('referralSources')}
                  variant={inputType.TAGS}
                  size={inputSize.M}
                  fullWidth
                />
              </Box>
            </Box>
            <Box
              className={cx({
                [classes.marginClass]: true,
              })}
            >
              {renderLabel('Primary interests')}
              <Input
                value={values.primaryInterests}
                // placeholder="Select primary interests"
                name="primaryInterests"
                isOptionEqualToValue={(option, value) => option.value === value.value}
                options={primaryInterestsOptions}
                onChange={onChangeTags('primaryInterests')}
                variant={inputType.TAGS}
                size={inputSize.M}
                fullWidth
              />
            </Box>
            <Box
              className={cx({
                [classes.marginClass]: true,
                [classes.marginBottomClass]: true,
              })}
            >
              {renderLabel('Profile photo')}
              <Box>
                {imageFile ? (
                  <UploadFileProgress
                    file={imageFile}
                    onUploaded={() => {
                      console.log('uploaded');
                    }}
                    onRemove={() => {
                      setImageFile(null);
                    }}
                  />
                ) : (
                  <UploadFile
                    onFailedFiles={() => {
                      dispatch(
                        showSnackbar({
                          snackType: 'error',
                          snackMessage: 'Oops! Invalid file format',
                        }),
                      );
                    }}
                    description="File size up to 5mb"
                    onAcceptedFiles={onAcceptedFiles}
                    fileType="image/*"
                    className={classes.dashedBorder}
                  />
                )}
              </Box>
            </Box>
          </>
        )}
      </form>
    </Box>
  );

  const renderContent2 = () => (
    <Box
      className={cx({
        [classes.bodyContainer]: true,
        [classes.csvContainer]: true,
      })}
    >
      <Box className={classes.labelExtraMargin}>{renderLabel('Upload a CSV file')}</Box>
      <Box>
        {csvFile ? (
          <UploadFileProgress
            file={csvFile}
            onUploaded={() => {
              console.log('csv uploaded');
            }}
            onRemove={() => {
              console.log('removed');
              setCsvFile(null);
            }}
          />
        ) : (
          <UploadFile
            description="File size up to 5mb"
            onAcceptedFiles={onAcceptedCSVFile}
            fileType="text/csv"
            className={classes.dashedBorder}
            onFailedFiles={() => {
              dispatch(
                showSnackbar({
                  snackType: 'error',
                  snackMessage: 'Oops! Invalid file format',
                }),
              );
            }}
            iconAltered
          />
        )}
      </Box>
    </Box>
  );

  return (
    <Drawer
      open={open}
      onClose={onClose}
      title="Add member"
      variant={drawerType.FORM}
      className={classes.drawer}
      disableSubmit={currentTab === 'bulk-members' && !csvFile}
      submitBtnTitle={currentTab === 'single-member' ? 'Add member' : 'Add members'}
      onSubmit={currentTab === 'single-member' ? handleSubmit : handleBulkSubmit}
      narrowHeader
    >
      {isAdmin ? (
        <>
          <Tabs options={tabs} value={currentTab} onChange={setCurrentTab} className={classes.tabs} />
          <TabPanel value={currentTab} tabKey={tabs[0].tabKey}>
            {renderContent1()}
          </TabPanel>
          <TabPanel value={currentTab} tabKey={tabs[1].tabKey}>
            {renderContent2()}
          </TabPanel>
        </>
      ) : (
        renderContent1()
      )}
    </Drawer>
  );
};

export { AddMemberDrawer };
