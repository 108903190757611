import { useMemo } from 'react';
import { Box } from '@mui/material';
import clsx from 'clsx';

import { fontWeight, iconBtnType, IconButton, Icons, Text } from '../../../../../../packages';
import { Drawer, drawerType } from '../../../../../../packages/ui/organisms/drawer';
import { useSelector } from 'react-redux';
import { getProfile } from '../../../../../../redux/modules/profile/selectors';
import { getAuth } from '../../../../../../redux/modules/auth/selectors';
import { MedicationBottomCard } from '../medication-card/MedicationBottomCard';
import { SymptomChart } from '../../Symptoms/symptom-detail/symptom-chart';
import { useStyles } from './MedicationDetail.styles';
import { ProfileInfoRole } from '../../../../../../components/v2/ProfileInfo';

const MedicationDetail = ({ open, onClose, medication, isNoDrawer, onClickNewReport, priorityType }) => {
  const classes = useStyles();
  const { isAdmin } = useSelector(getAuth);
  const { profile } = useSelector(getProfile);

  const fullHistory = useMemo(() => {
    if (medication) {
      const { history, priority, ...rest } = medication;
      const current = { ...rest, priority, importanceLevel: priority };
      return history ? [current, ...history] : [current];
    }
    return [];
  }, [medication]);

  // const [selectedTime, setSelectedTime] = useState(AppointmentsFilterDates[2]);

  // const times = AppointmentsFilterDates.map(time => ({
  //   ...time,
  //   onClick: () => setSelectedTime(time),
  // }));

  const renderContent = () => (
    <Box className={classes.container}>
      <Box className={clsx(classes.header, { [classes.headerNoDrawer]: isNoDrawer })}>
        <Box>
          <Text weight={fontWeight.BOLD} className={classes.headTitle}>
            All Reports - {medication.name}
          </Text>
          <Text weight={fontWeight.BOLD} className={classes.headSubtitle}>
            {medication.description?.toLowerCase()}
          </Text>
        </Box>
        <IconButton icon="close" className={classes.closeBtn} onClick={onClose} />
      </Box>
      <Box className={clsx(classes.content, { [classes.contentNoDrawer]: isNoDrawer })}>
        {!isNoDrawer && (
          <Box className={classes.contentFilter}>
            <Text weight={fontWeight.BOLD} className={classes.contentTitle}>
              Importance level <Icons glyph="question-outlined" className={classes.question} />
            </Text>
            {/* <Menu icon="chevron-down" items={times} itemsWrapperClassName={classes.menuLabel}>
              <Text className={classes.menuLabel} level={textLevel.S}>
                {selectedTime.label}
              </Text>
            </Menu> */}
          </Box>
        )}
        <Box className={classes.contentChart}>
          <SymptomChart name={priorityType} items={fullHistory} />
        </Box>
        <Box>
          {fullHistory?.map((report, index) => (
            <Box key={index}>
              <MedicationBottomCard medication={report} showBadge />
            </Box>
          ))}
        </Box>
      </Box>
      {(profile?.designation === ProfileInfoRole.NURSE_PRACTITIONER ||
        profile?.designation === ProfileInfoRole.PHYSICIAN ||
        isAdmin) && (
        <Box className={classes.footer}>
          <IconButton variant={iconBtnType.TEXT} onClick={onClose}>
            Close
          </IconButton>
          <IconButton
            // icon="plus"
            onClick={onClickNewReport}
            className={classes.newReportBtn}
            variant={iconBtnType.PRIMARY}
          >
            Update report
          </IconButton>
        </Box>
      )}
    </Box>
  );

  if (isNoDrawer) {
    return renderContent();
  }

  return (
    <Drawer open={open} onClose={onClose} variant={drawerType.NORMAL} className={classes.drawer}>
      {medication && renderContent()}
    </Drawer>
  );
};

export { MedicationDetail };
