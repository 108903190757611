import { Box } from '@mui/material';
import cx from 'clsx';
import { iconBtnType, IconButton } from '../../../../../../packages';
import { useStyles } from './BottomView.styles';

const BottomView = ({
  isVideoCall,
  isAudio,
  isSharing,
  toggleVideCall,
  toggleMic,
  onShareScreenClick,
  onStopSharingClick,
  hidden,
}) => {
  const classes = useStyles();

  const onShareClick = () => {
    if (isSharing) {
      onStopSharingClick();
    } else {
      onShareScreenClick();
    }
  };
  if (hidden) {
    return null;
  }
  return (
    <Box className={classes.bottomView}>
      <Box className={classes.container}>
        <Box className={classes.actions}>
          <IconButton
            variant={iconBtnType.PRIMARY}
            className={cx(classes.iconBtn, { [classes.shareActive]: isSharing })}
            onClick={onShareClick}
            icon="share-screen"
          />
          <IconButton
            variant={iconBtnType.PRIMARY}
            className={cx(classes.iconBtn /*, { [classes.videoActive]: isVideoCall } */)}
            icon={isVideoCall ? 'camera-filled' : 'camera-off'}
            onClick={toggleVideCall}
          />
          <IconButton
            variant={iconBtnType.PRIMARY}
            className={cx(classes.iconBtn /* , { [classes.AudioActive]: isAudio } */)}
            onClick={toggleMic}
            icon={isAudio ? 'mic-on' : 'mic-off'}
          />
        </Box>
      </Box>
    </Box>
  );
};

export { BottomView };
