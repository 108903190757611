import {
  FETCH_DOMAIN_ELEMENTS_BY_TYPE_ID,
  FETCH_DOMAIN_ELEMENTS_BY_TYPE_ID_FAILED,
  FETCH_DOMAIN_ELEMENTS_BY_TYPE_ID_SUCCESSFUL,
  FETCH_PRIORITY_DOMAIN_ELEMENTS,
  FETCH_PRIORITY_DOMAIN_ELEMENTS_FAILED,
  FETCH_PRIORITY_DOMAIN_ELEMENTS_SUCCESSFUL,
  FETCH_PRIORITY_DOMAIN_GROUPS,
  FETCH_PRIORITY_DOMAIN_GROUPS_FAILED,
  FETCH_PRIORITY_DOMAIN_GROUPS_SUCCESSFUL,
  FETCH_PRIORITY_DOMAIN_PROFILE_ELEMENTS,
  FETCH_PRIORITY_DOMAIN_PROFILE_ELEMENTS_FAILED,
  FETCH_PRIORITY_DOMAIN_PROFILE_ELEMENTS_SUCCESSFUL,
  FETCH_PRIORITY_DOMAIN_TYPES,
  FETCH_PRIORITY_DOMAIN_TYPES_FAILED,
  FETCH_PRIORITY_DOMAIN_TYPES_SUCCESSFUL,
  FETCH_PRIORITY_LOOKUP,
  FETCH_PRIORITY_LOOKUP_FAILED,
  FETCH_PRIORITY_LOOKUP_SUCCESSFUL,
} from './actions';

const DEFAULT = {
  typesPayload: {
    currentPage: 1,
    totalPages: 0,
    totalRecords: 0,
    domainTypes: [],
  },
  elementsPayload: {
    currentPage: 1,
    totalPages: 0,
    totalRecords: 0,
    domainElements: [],
  },
  profileElementsPayload: {
    currentPage: 1,
    totalPages: 0,
    totalRecords: 0,
    profileElements: [],
  },
  groupsPayload: {
    currentPage: 1,
    totalPages: 0,
    totalRecords: 0,
    domainGroups: [],
  },
  lookupData: {},
  isLoading: false,
};

export default function priorityReducer(state = DEFAULT, action) {
  const { type, payload } = action;

  switch (type) {
    /* Priorities - All domain Types */
    case FETCH_PRIORITY_DOMAIN_TYPES: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case FETCH_PRIORITY_DOMAIN_TYPES_SUCCESSFUL: {
      return {
        ...state,
        isLoading: false,
        typesPayload: {
          currentPage: payload.currentPage,
          totalPages: payload.totalPages,
          totalRecords: payload.totalRecords,
          domainTypes: payload.domainTypes,
        },
      };
    }
    case FETCH_PRIORITY_DOMAIN_TYPES_FAILED: {
      return {
        ...state,
        isLoading: false,
        typesPayload: {
          currentPage: 1,
          totalPages: 0,
          totalRecords: 0,
          domainTypes: [],
        },
      };
    }

    /* Priorities - All domain elements */
    case FETCH_PRIORITY_DOMAIN_ELEMENTS: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case FETCH_PRIORITY_DOMAIN_ELEMENTS_SUCCESSFUL: {
      return {
        ...state,
        isLoading: false,
        elementsPayload: {
          currentPage: payload.currentPage,
          totalPages: payload.totalPages,
          totalRecords: payload.totalRecords,
          domainElements: payload.elementsTypes,
        },
      };
    }
    case FETCH_PRIORITY_DOMAIN_ELEMENTS_FAILED: {
      return {
        ...state,
        isLoading: false,
        elementsPayload: {
          currentPage: 1,
          totalPages: 0,
          totalRecords: 0,
          domainElements: [],
        },
      };
    }

    /* Domain elements by typeId */
    case FETCH_DOMAIN_ELEMENTS_BY_TYPE_ID: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case FETCH_DOMAIN_ELEMENTS_BY_TYPE_ID_SUCCESSFUL: {
      return {
        ...state,
        isLoading: false,
        elementsPayload: {
          currentPage: payload.currentPage,
          totalPages: payload.totalPages,
          totalRecords: payload.totalRecords,
          domainElements: payload.elementsTypes,
        },
      };
    }
    case FETCH_DOMAIN_ELEMENTS_BY_TYPE_ID_FAILED: {
      return {
        ...state,
        isLoading: false,
        elementsPayload: {
          currentPage: 1,
          totalPages: 0,
          totalRecords: 0,
          domainElements: [],
        },
      };
    }

    /* Priorities - profile elements */
    case FETCH_PRIORITY_DOMAIN_PROFILE_ELEMENTS: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case FETCH_PRIORITY_DOMAIN_PROFILE_ELEMENTS_SUCCESSFUL: {
      return {
        ...state,
        isLoading: false,
        profileElementsPayload: {
          currentPage: payload.currentPage,
          totalPages: payload.totalPages,
          totalRecords: payload.totalRecords,
          profileElements: payload.elementsTypes,
        },
      };
    }
    case FETCH_PRIORITY_DOMAIN_PROFILE_ELEMENTS_FAILED: {
      return {
        ...state,
        isLoading: false,
        profileElementsPayload: {
          currentPage: 1,
          totalPages: 0,
          totalRecords: 0,
          profileElements: [],
        },
      };
    }

    /* Priorities - All domain groups */
    case FETCH_PRIORITY_DOMAIN_GROUPS: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case FETCH_PRIORITY_DOMAIN_GROUPS_SUCCESSFUL: {
      return {
        ...state,
        isLoading: false,
        groupsPayload: {
          currentPage: payload.currentPage,
          totalPages: payload.totalPages,
          totalRecords: payload.totalRecords,
          domainGroups: payload.domainGroups,
        },
      };
    }
    case FETCH_PRIORITY_DOMAIN_GROUPS_FAILED: {
      return {
        ...state,
        isLoading: false,
        groupsPayload: {
          currentPage: 1,
          totalPages: 0,
          totalRecords: 0,
          domainGroups: [],
        },
      };
    }

    case FETCH_PRIORITY_LOOKUP: {
      return {
        ...state,
        lookupData: {},
        isLoading: true,
      };
    }
    case FETCH_PRIORITY_LOOKUP_SUCCESSFUL: {
      return {
        ...state,
        lookupData: payload,
        isLoading: false,
      };
    }

    case FETCH_PRIORITY_LOOKUP_FAILED: {
      return {
        ...state,
        lookupData: {},
        isLoading: false,
      };
    }
    default: {
      return state;
    }
  }
}
