/* eslint-disable max-len */
import { colors } from '../../../../../packages';
import { getReportFilterQuery, REPORT_CONSTANTS } from '../../ReportDetail.constants';

export const DEMOGRAPHICS_TABLES = {
  cities: 'cities',
  states: 'states',
};
const martialColors = [
  colors.secondary500,
  colors.primary500,
  colors.warning400,
  colors.cyan500,
  colors.success500,
  colors.indigo500,
  colors.purple500,
  colors.rose500,
  colors.destructive500,
];
export const getMartialStatusSeries = report =>
  report
    ? report.aggregations.demographics.maritalStatus.by_marital_status.buckets.map((item, i) => ({
        name: item.key,
        color: martialColors[i],
        data: [item.doc_count],
      }))
    : [];

const housingColors = [
  colors.primary500,
  colors.secondary500,
  colors.warning400,
  colors.success500,
  colors.cyan500,
  colors.purple500,
  colors.indigo500,
  colors.destructive500,
  colors.rose500,
];
export const getHousingSeries = report =>
  report
    ? report.aggregations.demographics.housingStatus.by_housing_status.buckets.map((item, i) => ({
        name: item.key,
        color: housingColors[i],
        data: [item.doc_count],
      }))
    : [];

const raceColors = [
  colors.primary500,
  colors.neutral600,
  colors.warning400,
  colors.secondary500,
  colors.cyan500,
  colors.purple500,
  colors.success500,
  colors.destructive500,
  colors.indigo500,
  colors.rose500,
];
export const getRaceSeries = report =>
  report
    ? report.aggregations.demographics.race.by_race.buckets.map((item, i) => ({
        name: item.key,
        color: raceColors[i],
        data: [item.doc_count],
      }))
    : [];

const genderColors = [
  colors.cyan500,
  '#FD6F8E',
  colors.purple500,
  colors.primary500,
  colors.rose500,
  colors.secondary500,
  colors.success500,
  colors.warning500,
  colors.orange600,
  colors.destructive500,
  colors.neutral400,
  colors.green200,
  colors.indigo200,
  colors.neutral900,
  colors.neutral600,
  colors.orange300,
];
export const getGenderSeries = report =>
  report
    ? report.aggregations.demographics?.gender?.by_housing_status?.buckets?.map((item, i) => ({
        name: item.key,
        color: genderColors[i],
        data: [item.doc_count],
      }))
    : [];

export const cityColumns = [
  { id: 'city', label: 'City', sortable: false },
  {
    id: 'members',
    label: 'Members',
    sortable: false,
    textAlign: 'center',
    className: 'color-secondary-500',
  },
];

export const stateColumns = [
  { id: 'state', label: 'State', sortable: false },
  {
    id: 'members',
    label: 'Members',
    sortable: false,
    textAlign: 'center',
    className: 'color-secondary-500',
  },
];

export const getCityList = report =>
  report
    ? report.aggregations.city.buckets.map(bucket => ({
        id: bucket.key,
        members: bucket.doc_count,
        city: bucket.key,
      }))
    : [];

export const getStateList = report =>
  report
    ? report.aggregations.state.buckets.map(bucket => ({
        id: bucket.key,
        members: bucket.doc_count,
        state: bucket.key,
      }))
    : [];

export const getProfileReportQuery = report => ({
  size: 0,
  query: getReportFilterQuery(report),
  aggs: {
    total_members: {
      cardinality: {
        field: 'mongo_id.keyword',
      },
    },
    demographics: {
      nested: {
        path: 'dataElementDetails',
      },
      aggs: {
        gender: {
          filter: {
            terms: {
              'dataElementDetails.dataElementKey.keyword': ['Gender Identity'],
            },
          },
          aggs: {
            by_housing_status: {
              terms: {
                field: 'dataElementDetails.dataElement.values.value.keyword',
                size: 10,
              },
            },
          },
        },
        race: {
          filter: {
            term: {
              'dataElementDetails.dataElementKey.keyword': 'Race',
            },
          },
          aggs: {
            by_race: {
              terms: {
                field: 'dataElementDetails.dataElement.values.value.keyword',
                size: 10,
              },
            },
          },
        },
        education: {
          filter: {
            term: {
              'dataElementDetails.dataElementKey.keyword': 'Level of Education',
            },
          },
          aggs: {
            by_education: {
              terms: {
                field: 'dataElementDetails.dataElement.values.value.keyword',
                size: 10,
              },
            },
          },
        },
        maritalStatus: {
          filter: {
            term: {
              'dataElementDetails.dataElementKey.keyword': 'Marital Status',
            },
          },
          aggs: {
            by_marital_status: {
              terms: {
                field: 'dataElementDetails.dataElement.values.value.keyword',
                size: 10,
              },
            },
          },
        },
        housingStatus: {
          filter: {
            term: {
              'dataElementDetails.dataElementKey.keyword': 'Housing',
            },
          },
          aggs: {
            by_housing_status: {
              terms: {
                field: 'dataElementDetails.dataElement.values.value.keyword',
                size: 10,
              },
            },
          },
        },
      },
    },
    city: {
      terms: {
        field: 'city.keyword',
        size: REPORT_CONSTANTS.MAX_RECORDS_IN_QUERY,
      },
    },
    state: {
      terms: {
        field: 'state.keyword',
        size: REPORT_CONSTANTS.MAX_RECORDS_IN_QUERY,
      },
    },
    age_range: {
      date_range: {
        field: 'dob',
        format: 'MM-yyyy',
        keyed: true,
        ranges: [
          { from: 'now-18y/d', key: 'upto 18' },
          { from: 'now-24y/d', to: 'now-18y/d', key: '18-24' },
          { from: 'now-32y/d', to: 'now-24y/d', key: '25-32' },
          { from: 'now-40y/d', to: 'now-32y/d', key: '33-40' },
          { from: 'now-45y/d', to: 'now-40y/d', key: '41-45' },
          { from: 'now-50y/d', to: 'now-45y/d', key: '46-50' },
          { from: 'now-55y/d', to: 'now-50y/d', key: '51-55' },
          { from: 'now-60y/d', to: 'now-55y/d', key: '56-60' },
          { from: 'now-65y/d', to: 'now-60y/d', key: '61-65' },
          { from: 'now-70y/d', to: 'now-65y/d', key: '66-70' },
          { from: 'now-75y/d', to: 'now-70y/d', key: '71-75' },
          { to: 'now-75y/d', key: '76+' },
        ],
      },
    },
  },
});
