import { makeStyles } from '@mui/styles';

// colors
import { colors } from '../../../../../../colors';

export const useStyles = makeStyles({
  container: {
    padding: '0px 16px 32px 16px',
    gap: '32px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    alignSelf: 'stretch',
  },
  welcomeBox: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: colors.white,
    borderRadius: '8px',
    border: `1px solid ${colors.neutral100}`,
    background: colors.white,
    boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.04), 0px 0px 2px 0px rgba(0, 0, 0, 0.06), 0px 0px 1px 0px rgba(0, 0, 0, 0.04)',
    alignSelf: 'stretch',
    padding: '24px',
    gap: '16px',
  },
  welcomeFrame: {
    display: 'flex',
    paddingBottom: '8px',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '8px',
    alignSelf: 'stretch'
  },
  sectionTitle: {
    color: colors.neutral900,
    textAlign: 'center',
    fontFamily: 'Manrope',
    fontSize: '22px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '28px',
    letterSpacing: '-0.22px',
    margin: 0,
  },
  sectionSubtitle: {
    color: colors.neutral900,
    textAlign: 'center',
    fontFamily: 'Manrope',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '24px',
    margin: 0,
  },
  askBox: {
    display: 'flex',
    padding: '24px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '12px',
    alignSelf: 'stretch',
    borderRadius: '8px',
    background: colors.green50,
  },
  sayBox: {
    display: 'flex',
    padding: '24px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '12px',
    alignSelf: 'stretch',
    borderRadius: '8px',
    background: colors.primary50,
  },
  askContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '8px',
    alignSelf: 'stretch'
  },
  firstLineLayout: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '8px',
    alignSelf: 'stretch'
  },
  firstLine: {
    color: colors.neutral900,
    fontFamily: 'Manrope',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '24px',
    flex: 1,
    margin: 0,
  },
  askIcon: {
    color: colors.success,
    height: '24px',
    width: '24px',
    '& path': {
      fill: colors.success,
    },
  },
  sayIcon: {
    color: colors.primary,
    height: '24px',
    width: '24px',
    // '& path': {
    //   fill: colors.primary,
    // },
  },
  instruction: {
    color: colors.neutral900,
    fontFamily: 'Manrope',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '20px',
    margin: 0,
  },
  animatedIcon: {
    width: '80px',
    height: '80px',
  },
});
