import * as React from 'react';
import { useEffect, useState } from 'react';
import cx from 'clsx';

// mui
import MuiTextField from '@mui/material/TextField';
import TextField from '@mui/material/TextField';
import MuiInputAdornment from '@mui/material/InputAdornment';
import MuiAutocomplete from '@mui/material/Autocomplete';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

// phone input
import PhoneInput from 'react-phone-input-2';

// icons
import { Icons } from '../../../icons';

// colors
import { colors } from '../../../colors';

// types
import { inputSize, inputType } from './Input.types';

// styles
import { useStyles } from './Input.styles';
import 'react-phone-input-2/lib/style.css';

const Input = ({
  className = '',
  drawerWidth = false,
  variant = inputType.DEFAULT,
  size = inputSize.L,
  label = '',
  fullWidth = false,
  options = [],
  customPopupIcon = null,
  placeholder = '',
  startAdornment = null,
  endAdornment = null,
  startAdornmentClassName = '',
  endAdornmentClassName = '',
  clearCalendar = false,
  endDateExists = true,
  ...rest
}) => {
  const isCalender = variant === 'CALENDARFILTER';
  const [value, setValue] = useState(rest?.value);
  const [calendarStartValue, setCalendarStartValue] = React.useState(isCalender ? rest.value?.startDate : '');
  const [calendarEndValue, setCalendarEndValue] = React.useState(isCalender ? rest.value?.endDate : '');
  const [openc1, setC1Open] = React.useState(false);
  const [openc2, setC2Open] = React.useState(false);
  const [isFocus, setIsFocus] = useState(false);
  const [showEndDate, setShowEndDate] = useState(endDateExists);
  const handleClick = event => {
    setIsFocus(current => !current);
  };
  const classes = useStyles();

  const rootClasses = cx({
    [classes.root]: true,
    [classes.default]: variant === inputType.DEFAULT,
    [classes.drawerDateRangeWidth]: drawerWidth,
    [classes.tags]: variant === inputType.TAGS,
    [classes.search]: variant === inputType.SEARCH,
    [classes.calendarFilter]: variant === inputType.CALENDARFILTER,
    [classes.sizeMedium]: size === inputSize.M,
    [classes.fullWidth]: fullWidth,
    [classes.autoCompleteSizeMedium]: size === inputSize.M && variant === inputType.AUTOCOMPLETE,
    [classes.tagSizeMedium]: size === inputSize.M && variant === inputType.TAGS,
    [classes.rootForStartAdornment]: startAdornment,
    [classes.rootForEndAdornment]: endAdornment,
    [classes.inputChipColor]: variant === inputType.TAGS && rest.showchipcolor,
    [className || '']: className,
  });

  const onChangeSearchText = e => {
    const newValue = e.target.value;
    setValue(newValue);
    rest?.onChange?.(newValue);
  };

  const onChangeText = val => {
    setValue(val);
    rest?.onChange?.(val);
  };
  const onChangeCalendarText = (e, isStartDate) => {
    if (isStartDate) {
      setCalendarStartValue(e);
      setShowEndDate(true);
    } else {
      setCalendarEndValue(e);
    }
    rest?.onChange?.(e, isStartDate);
  };

  const onClearSearchText = () => {
    setValue('');
    rest?.onChange?.('');
  };

  const onClearCalendarText = () => {
    if (clearCalendar) {
      setCalendarEndValue(null);
      setCalendarStartValue(null);
      setShowEndDate(endDateExists);
      rest?.onChange?.('');
    }
  };
  useEffect(onClearCalendarText, [clearCalendar]);
  useEffect(() => {
    if (rest.value !== value) {
      setValue(rest.value);
    }
  }, [rest.value]);

  const renderDatePicker = (dateValue, isOpen, onOpen, onClose, onChange, renderInput, styleClass) => {
    return (
      <DatePicker
        className={styleClass}
        value={dateValue}
        open={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        onChange={onChange}
        renderInput={renderInput}
      />
    );
  };

  if (variant === inputType.PHONE) {
    return (
      <PhoneInput
        buttonClass={classes.phoneButtonStyle}
        inputClass={cx({
          [classes.phoneInputStyle]: true,
        })}
        country="us"
        value={value}
        countryCodeEditable={false}
        onChange={x => onChangeText(x)}
      />
    );
  }
  if (variant === inputType.SEARCH) {
    return (
      <div className={rootClasses}>
        <MuiTextField
          {...rest}
          className={classes.searchInput}
          variant="outlined"
          placeholder={placeholder}
          InputProps={{
            startAdornment: (
              <MuiInputAdornment position="start">
                <Icons glyph="search-outlined" color={rest.iconcolor ?? colors.neutral500} />
              </MuiInputAdornment>
            ),
          }}
          value={value}
          onChange={onChangeSearchText}
        />
        {value && (
          <span className={classes.searchClear} onClick={onClearSearchText}>
            <Icons glyph="close" color={colors.white} />
          </span>
        )}
      </div>
    );
  }
  if (variant === inputType.TAGS) {
    return (
      <MuiAutocomplete
        className={rootClasses}
        options={options}
        getOptionLabel={option => option?.title}
        filterSelectedOptions
        popupIcon={false}
        clearIcon={false}
        renderInput={params => <MuiTextField {...params} placeholder={placeholder} type={rest?.type} />}
        multiple
        {...rest}
      />
    );
  }
  if (variant === inputType.AUTOCOMPLETE) {
    return (
      <MuiAutocomplete
        className={rootClasses}
        options={options}
        getOptionLabel={option => option?.title}
        filterSelectedOptions
        popupIcon={false}
        clearIcon={false}
        renderInput={params => <MuiTextField {...params} placeholder={placeholder} />}
        {...rest}
      />
    );
  }
  if (variant === inputType.CALENDARFILTER) {
    return (
      <div className={rootClasses} onClick={handleClick}>
        <div className={`calendarFilterInner ${isFocus ? 'calendarFilterFocus' : ''}`}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            {renderDatePicker(
              calendarStartValue,
              openc1,
              () => setC1Open(true),
              () => setC1Open(false),
              newValue => {
                if (dayjs(newValue).isValid()) {
                  onChangeCalendarText(dayjs(newValue).format('MM/DD/YYYY'), true);
                }
              },
              params => {
                return (
                  <TextField
                    variant="standard"
                    {...params}
                    onClick={e => setC1Open(true)}
                    InputProps={{
                      startAdornment: (
                        <MuiInputAdornment position="start">
                          <Icons
                            glyph="calendar-primary-outlined"
                            color={rest.iconcolor ?? colors.neutral500}
                          />
                        </MuiInputAdornment>
                      ),
                    }}
                    inputProps={{
                      ...params.inputProps,
                      placeholder: endDateExists ? 'From date' : 'All time',
                    }}
                  />
                );
              },
            )}
          </LocalizationProvider>
          {showEndDate && (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              {renderDatePicker(
                calendarEndValue,
                openc2,
                () => setC2Open(true),
                () => setC2Open(false),
                newValue => {
                  if (dayjs(newValue).isValid()) {
                    onChangeCalendarText(dayjs(newValue).format('MM/DD/YYYY'), false);
                  }
                },
                params => {
                  return (
                    <TextField
                      variant="standard"
                      {...params}
                      onClick={e => setC2Open(true)}
                      InputProps={{
                        startAdornment: (
                          <MuiInputAdornment position="start">
                            <Icons
                              glyph="calendar-primary-outlined"
                              color={rest.iconcolor ?? colors.neutral500}
                            />
                          </MuiInputAdornment>
                        ),
                      }}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: 'Till date',
                      }}
                    />
                  );
                },
                'c2-picker',
              )}
            </LocalizationProvider>
          )}
        </div>
      </div>
    );
  }

  return (
    <MuiTextField
      className={rootClasses}
      variant="standard"
      label={label}
      placeholder={placeholder}
      InputLabelProps={{
        shrink: true,
      }}
      InputProps={{
        startAdornment: startAdornment && (
          <MuiInputAdornment className={startAdornmentClassName} position="start">
            {startAdornment}
          </MuiInputAdornment>
        ),
        endAdornment: endAdornment && (
          <MuiInputAdornment className={endAdornmentClassName} position="end">
            {endAdornment}
          </MuiInputAdornment>
        ),
      }}
      {...rest}
    />
  );
};

export { Input };
