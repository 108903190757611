/* eslint-disable max-len */
import cx from 'clsx';

// types
// styles
import { useStyles } from './shared/styles';

const ArrowRightFilled = ({ className, color, onClick }) => {
  const classes = useStyles();
  return (
    <svg
      className={cx({
        [classes.root]: true,
        [className || '']: className,
      })}
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M9.06 15.1401C9.01352 15.1869 8.95822 15.2241 8.89729 15.2495C8.83636 15.2749 8.77101 15.288 8.705 15.288C8.63899 15.288 8.57364 15.2749 8.51271 15.2495C8.45178 15.2241 8.39648 15.1869 8.35 15.1401L8.15 14.9401C8.10169 14.8935 8.06344 14.8375 8.03764 14.7756C8.01184 14.7137 7.99903 14.6471 8 14.5801V11.0001H0.5C0.367392 11.0001 0.240215 10.9474 0.146447 10.8536C0.0526785 10.7598 0 10.6327 0 10.5001V5.50006C0 5.36745 0.0526785 5.24027 0.146447 5.1465C0.240215 5.05274 0.367392 5.00006 0.5 5.00006H8V1.42006C7.99903 1.35298 8.01184 1.28642 8.03764 1.22449C8.06344 1.16257 8.10169 1.1066 8.15 1.06006L8.35 0.860057C8.39648 0.813193 8.45178 0.775996 8.51271 0.750611C8.57364 0.725227 8.63899 0.712158 8.705 0.712158C8.77101 0.712158 8.83636 0.725227 8.89729 0.750611C8.95822 0.775996 9.01352 0.813193 9.06 0.860057L15.85 7.65006C15.8978 7.69495 15.936 7.74916 15.962 7.80936C15.9881 7.86956 16.0015 7.93446 16.0015 8.00006C16.0015 8.06565 15.9881 8.13055 15.962 8.19075C15.936 8.25095 15.8978 8.30517 15.85 8.35006L9.06 15.1401Z"
        fill={color}
      />
    </svg>
  );
};

export default ArrowRightFilled;
