import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import clsx from 'clsx';

import { Drawer, drawerType } from '../../../../../../packages/ui/organisms/drawer';
import {
  btnType,
  Button,
  colors,
  fontWeight,
  IconButton,
  Icons,
  Stepper,
  Text,
} from '../../../../../../packages';

// components
import StepOne from '../../AddNewReport/step-one/StepOne';
import StepTwo from './step-two/StepTwo';
import StepThree from './step-three/StepThree';

// types
import { medicationItems, stepTitles } from '../SubstanceUse.constants';
// styles
import { useStyles } from './AddNewReport.styles';

const AddNewReport = ({
  isOpen,
  isNoDrawer,
  onClose,
  options = medicationItems,
  lookupData,
  onSubmit,
  isLoadingOptions = false,
}) => {
  const classes = useStyles();
  const [currentStep, setCurrentStep] = React.useState(0);
  const [selectedItems, setSelectedMedication] = React.useState([]);
  const [selectedItemsForm, setSelectedMedicationForms] = React.useState([]);

  const onCloseClick = () => {
    if (currentStep === 0) {
      onClose();
    } else {
      setCurrentStep(currentStep - 1);
    }
  };

  const onRemoveItem = index => {
    const newSymptom = selectedItems.filter((_, i) => i !== index);
    if (newSymptom.length === 0) {
      setCurrentStep(0);
    }
    setSelectedMedication(newSymptom);
  };

  const onClickContinue = () => {
    if (currentStep === 0) {
      const newItemsForm = selectedItems.map(item => {
        let groupId = null;
        let option = null;
        options.forEach(opt => {
          const result = opt.items.find(it => it.value === item);
          if (result) {
            groupId = opt.groupId;
            option = result;
          }
        });
        return {
          // domainElementId,
          groupId,
          item: option,
          status: 'HIGH',
          notes: '',
          isInterference: false,
          tutorial: {
            lastUse: 'TODAY',
            method: 'DRINKING',
            dose: {
              amount: 1,
              unit: 'GRAMS',
            },
            frequency: 'MULTIPLE_TIMES_DAILY',
            duration: 'DAYS',
            refillable: true,
            refillTimes: {
              amount: 1,
              unit: 'TIMES',
            },
          },
        };
      });
      setSelectedMedicationForms(newItemsForm);
    }
    if (currentStep === 2) {
      onSubmit(selectedItemsForm);
    } else {
      setCurrentStep(currentStep + 1);
    }
  };

  useEffect(() => {
    if (!isOpen) {
      setCurrentStep(0);
      setSelectedMedication([]);
    }
  }, [isOpen]);

  const renderStep = step => {
    switch (step) {
      case 0:
        return (
          <StepOne
            selectedItems={selectedItems}
            setSelectedItems={setSelectedMedication}
            options={options}
            searchLabel="Select one or more substances"
          />
        );
      case 1:
        return (
          <StepTwo
            selectedItems={selectedItemsForm}
            updateItems={setSelectedMedicationForms}
            onRemoveItem={onRemoveItem}
            showInterference
            lookupData={lookupData}
            options={options}
          />
        );
      case 2:
        return <StepThree selectedItems={selectedItemsForm} lookupData={lookupData} />;
      default:
        return null;
    }
  };

  const checkDisabled = () => {
    if (currentStep === 0) {
      return selectedItems.length === 0;
    }
    if (currentStep === 1) {
      return selectedItemsForm.some(item => item.notes.trim().length === 0);
    }
    return false;
  };

  const renderContent = () => (
    <Box className={classes.wrapper}>
      <Box className={clsx(classes.header, { [classes.headerNoDrawer]: isNoDrawer })}>
        <Box>
          <Text weight={fontWeight.BOLD} className={classes.headTitle}>
            Report new substance
          </Text>
          <Text weight={fontWeight.BOLD} className={classes.headSubtitle}>
            {stepTitles[currentStep]}
          </Text>
        </Box>
        <Box className={classes.stepWrap}>
          <Stepper steps={['Select', 'Detail', 'Review']} activeStep={currentStep} />
        </Box>
        <IconButton icon="close" className={classes.closeBtn} onClick={onClose} />
      </Box>
      <Box
        className={clsx(classes.content, {
          [classes.contentNoDrawer]: isNoDrawer,
          [classes.contentStep2]: currentStep > 0,
        })}
      >
        {isLoadingOptions ? (
          <Box display="flex" justifyContent="center" alignItems="center" flexDirection="row" gap={4}>
            <Icons className="rotate linear infinite" glyph="in-progress" color={colors.primary} />
            Loading...
          </Box>
        ) : (
          renderStep(currentStep)
        )}
      </Box>

      <Box className={classes.footer}>
        <Button variant={btnType.TEXT} onClick={onCloseClick}>
          Cancel
        </Button>
        <Button onClick={onClickContinue} disabled={checkDisabled()}>
          {currentStep === 2 ? 'Send report' : 'Continue'}
        </Button>
      </Box>
    </Box>
  );

  if (isNoDrawer) {
    return renderContent();
  }

  return (
    <Drawer open={isOpen} onClose={onCloseClick} variant={drawerType.NORMAL} className={classes.drawer}>
      {renderContent()}
    </Drawer>
  );
};

export { AddNewReport };
