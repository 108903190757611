import { FC } from 'react';
import { Divider } from '@mui/material';

import ProfileHeader from './profile-header';
import ProfileContent from './profile-content';

import { useStyles } from './ProfileCard.styles';

const ProfileCard = ({ id, title, onEdit, content }) => {
  const classes = useStyles();

  return (
    <div className={classes.cardProfileRoot} id={id}>
      <ProfileHeader title={title} onEdit={onEdit} />
      <Divider className={classes.dividerCard} />
      <ProfileContent content={content} />
    </div>
  );
};

export { ProfileCard };
