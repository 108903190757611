import { makeStyles } from '@mui/styles';

// colors
import { colors } from '../../../../../packages/colors';

export const useStyles = makeStyles({
  scheduling: {
    padding: 24,
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
    boxShadow:
      '0 0 1px 0 rgba(0, 0, 0, 0.04), 0 2px 6px 0 rgba(0, 0, 0, 0.04), 0 10px 20px 0 rgba(0, 0, 0, 0.04)',
    borderRadius: 12,
    background: colors.white,
  },
  schedulingLink: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& .label': {
      fontSize: 18,
      fontWeight: 600,
      lineHeight: '24px',
    },
    '& .generateBtn': {
      gap: 8,
      height: 40,
      fontSize: 14,
      fontWeight: 700,
      lineHeight: '20px',
    },
  },
  copyLink: {
    display: 'flex',
    alignItems: 'center',
    gap: 16,
    flex: 1,
    '& > div': { flex: 1, color: colors.neutral900 },
    '& .btn': {
      gap: 8,
      height: 48,
      fontSize: 16,
      fontWeight: 700,
      lineHeight: '24px',
      background: colors.white,
      borderRadius: 6,
      border: `1px solid ${colors.primary300}`,
      '& svg': {
        width: 20,
        height: 18,
      },
    },
  },
  drawer: {
    '& .MuiPaper-root': {
      maxWidth: '640px !important',
    },
    '& .drawerBody': {
      padding: 0,
    },
  },
  closeBtn: {
    margin: 0,
    minWidth: 'auto',
    position: 'absolute',
    right: 24,
    top: 24,
    '& svg': {
      width: 24,
    },
  },
  header: {
    fontSize: 18,
    fontWeight: 600,
    lineHeight: '24px',
  },
  select: {
    width: '100%',
  },
  selectMember: {},
  backBtn: {
    width: 40,
    height: 40,
    background: colors.white,
    border: `1px solid ${colors.primary300}`,
    '& svg': {
      width: 7,
      height: 12,
    },
    '& span': {
      width: 0,
    },
  },
  copyLinkBtn: {
    height: 48,
    fontSize: 16,
    fontWeight: 700,
    lineHeight: '24px',
    background: colors.white,
    borderRadius: 6,
    border: `1px solid ${colors.primary300}`,
    '& svg': {
      width: 20,
      height: 18,
    },
    '&:hover': {
      background: colors.primary100,
    },
  },
});
