import Diagnoses from '../../../pages/provider-v2/member-detail/diagnoses';
import Medications from '../../../pages/provider-v2/member-detail/medications';
import SymptomaticFactors from '../../../pages/provider-v2/member-detail/symptomatic-factors';
import EnvironmentalFactors from '../../../pages/provider-v2/member-detail/environmental-factors';
import CognitiveIssues from '../../../pages/provider-v2/member-detail/cognitive-issues';
import {
  Activity,
  Appointments,
  CareTeam,
  Chatbots,
  GeneralInformation,
  Groups,
  History,
  LifeEvents,
  Notes,
  SideEffects,
  SocialDeterminants,
  SubstanceUse,
  Symptoms,
} from '../../../pages/provider/member-detail/components';
import ProviderReported from '../../../pages/provider/member-detail/components/ScoredOutcomes/provider-reported';
import MemberReported from '../../../pages/provider/member-detail/components/ScoredOutcomes/member-reported';
import Evaluations from '../../../pages/provider/member-detail/components/Evaluations';
import InsuranceInformation from '../../../pages/provider/member-detail/components/insurance-information';
import Dashboard from '../../../pages/provider/member-detail/components/Dashboard/MemberDashboard';

import ScoredOutcomes from './ScoredOutcomes';

const SelectedComponent = ({ selectedScreen }) => {
  switch (selectedScreen) {
    case 'general-information':
      return <GeneralInformation />;
    case 'care-team':
      return <CareTeam />;
    case 'chatbots':
      return <Chatbots />;
    case 'scored-outcomes':
      return <ScoredOutcomes />;
    case 'activity':
      return <Activity />;
    case 'groups':
      return <Groups />;
    case 'notes':
      return <Notes />;
    case 'appointments-current':
    case 'appointments-past':
    case 'appointments-pending':
      return <Appointments selectedScreen={selectedScreen} />;
    case 'history':
      return <History />;
    case 'symptoms':
      return <Symptoms />;
    case 'diagnoses':
      return <Diagnoses />;
    case 'medications':
      return <Medications />;
    case 'side-effects':
      return <SideEffects />;
    case 'substance-use':
      return <SubstanceUse />;
    case 'life-events':
      return <LifeEvents />;
    case 'social-determinants':
      return <SocialDeterminants />;
    case 'insurance-information':
      return <InsuranceInformation />;
    case 'provider-reported':
      return <ProviderReported />;
    case 'member-reported':
      return <MemberReported />;
    case 'evaluations':
      return <Evaluations />;
    // case 'invoices':
    //   return <Invoices />;
    case 'dashboard':
      return <Dashboard />;
    case 'symptomatic-factor':
      return <SymptomaticFactors />;
    case 'environmental-factor':
      return <EnvironmentalFactors />;
    case 'cognitive-issues':
      return <CognitiveIssues />;
    default:
      return <div>In Progress...</div>;
  }
};

export default SelectedComponent;
