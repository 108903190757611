import { colors } from '../../../../../packages';
import { Button, btnSize, btnType } from '../../../../../packages';
import { IconButton, iconBtnPosition } from '../../../../../packages';
import { Box } from '@mui/material';

import BoxFrame from '../../../../../components/v2/BoxFrame';
import Header from '../../../../../components/v2/Header';
import { HeadingFont, HeadingLevel, HeadingWeight } from '../../../../../components/v2/Heading/index.types';
import Typography from '../../../../../components/v2/Typography';
import { typography } from '../../../../../components/v2/Typography/index.constant';

import FormListItems from './form-list-item';

const FormList = ({
  title,
  list,
  lists,
  onEdit,
  onAddManually,
  onViewInfo,
  isImportDisabled,
  onClickImport,
  onClickViewInfo,
}) => {
  return (
    <BoxFrame>
      <Box py={2.5} px={3} sx={{ '& button': { minWidth: 27 } }}>
        <Header
          label={title}
          fontFamily={HeadingFont.PRIMARY}
          fontVariant={HeadingLevel.SUB}
          fontWeight={HeadingWeight.SEMI_BOLD}
          actions={
            list.length > 0
              ? onEdit
                ? [
                    {
                      children: <Typography {...typography.body.s.bold}>Edit</Typography>,
                      variant: btnType.TEXT,
                      onClick: onEdit,
                    },
                  ]
                : onAddManually
                ? [
                    {
                      children: <Typography {...typography.body.s.bold}>Add new</Typography>,
                      variant: btnType.TEXT,
                      onClick: onAddManually,
                    },
                  ]
                : []
              : []
          }
        />
      </Box>
      {lists?.length > 1 ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            borderTop: `1px solid ${colors.neutral100}`,
          }}
        >
          {lists.map(({ title: listTitle, id }, index) => (
            <Box
              key={index}
              sx={{
                p: 3,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                borderBottom: `1px solid ${colors.neutral100}`,
              }}
            >
              <Typography {...typography.body.s.medium}>{listTitle}</Typography>
              <IconButton
                icon="arrow-right"
                iconPosition={iconBtnPosition.RIGHT}
                onClick={() => onViewInfo(id)}
              >
                <Typography {...typography.body.s.bold} mr={1}>
                  View info
                </Typography>
              </IconButton>
            </Box>
          ))}
        </Box>
      ) : list.length === 0 ? (
        <Box
          p={3}
          sx={{
            borderTop: `1px solid ${colors.neutral100}`,
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography {...typography.body.s.medium} color={colors.neutral600}>
              No {title.toLowerCase()} added
            </Typography>
            <Box sx={{ display: 'flex', gap: 2 }}>
              <Button size={btnSize.XSMALL} variant={btnType.OUTLINE} onClick={onAddManually}>
                <Typography {...typography.body.s.bold}>Add manually</Typography>
              </Button>
              {/* <Button
                size={btnSize.XSMALL}
                variant={btnType.PRIMARY}
                disabled={isImportDisabled}
                onClick={onClickImport}
              >
              <Typography {...typography.body.s.bold}>Import from CAQH</Typography>
              </Button> */}
            </Box>
          </Box>
        </Box>
      ) : (
        <FormListItems list={list} onClickViewInfo={onClickViewInfo} />
      )}
    </BoxFrame>
  );
};

export default FormList;
