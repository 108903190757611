/* eslint-disable max-len */
import cx from 'clsx';

// types
// styles
import { useStyles } from './shared/styles';

const CaretUp = ({ className, color, onClick }) => {
  const classes = useStyles();

  return (
    <svg
      className={cx({
        [classes.root]: true,
        [className || '']: className,
      })}
      width="10"
      height="6"
      viewBox="0 0 10 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M0.859996 3.94135C0.813132 3.98783 0.775935 4.04313 0.75055 4.10406C0.725166 4.16499 0.712097 4.23035 0.712097 4.29635C0.712097 4.36236 0.725166 4.42771 0.75055 4.48864C0.775935 4.54957 0.813132 4.60487 0.859996 4.65135L1.06 4.85135C1.10654 4.89967 1.16251 4.93791 1.22443 4.96371C1.28635 4.98951 1.35292 5.00232 1.42 5.00135H8.58C8.64707 5.00232 8.71364 4.98951 8.77556 4.96371C8.83749 4.93791 8.89345 4.89967 8.94 4.85135L9.14 4.65135C9.18686 4.60487 9.22406 4.54957 9.24944 4.48864C9.27483 4.42771 9.2879 4.36236 9.2879 4.29635C9.2879 4.23035 9.27483 4.16499 9.24944 4.10406C9.22406 4.04313 9.18686 3.98783 9.14 3.94135L5.35 0.151352C5.30511 0.103521 5.25089 0.0653992 5.19069 0.0393391C5.13049 0.013279 5.06559 -0.000165939 5 -0.000165939C4.9344 -0.000165939 4.8695 0.013279 4.8093 0.0393391C4.7491 0.0653992 4.69489 0.103521 4.65 0.151352L0.859996 3.94135Z"
        fill={color}
      />
    </svg>
  );
};

export default CaretUp;
