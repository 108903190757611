import cx from 'clsx';

// mui
import MuiRadio from '@mui/material/Radio';

// styles
import { useStyles } from './Radio.styles';

const Radio = ({ className = '', disabled = false, ...rest }) => {
  const classes = useStyles();

  return (
    <MuiRadio
      className={cx({
        [classes.root]: true,
        [className || '']: className,
      })}
      disabled={disabled}
      {...rest}
    />
  );
};

export { Radio };
