import { makeStyles } from '@mui/styles';
import { colors } from '../../../../packages/colors';

export const useStyles = makeStyles({
  primaryBtn: {
    color: colors.white,
    height: 64,
    padding: '20px 24px',
    borderRadius: 8,
    whiteSpace: 'nowrap',
  },
  table: {
    backgroundColor: colors.white,
    overflowX: 'hidden',
  },
  actions: {
    height: 32,
    width: 32,
    float: 'inline-end',
    '& svg': {
      height: 16,
      '& path': {
        fill: colors.neutral400,
      },
    },
  },
});
