import { useCallback, useState, useEffect, useLayoutEffect } from 'react';

const useIsomorphicLayoutEffect = typeof window !== 'undefined' ? useLayoutEffect : useEffect;

export const SizeTo = {
  DOWN_TO: 'downTo',
  UP: 'up',
};

const getMatches = (el, size, option) => {
  // Prevents SSR issues
  if (typeof window !== 'undefined' && el) {
    if (option === SizeTo.DOWN_TO) {
      return el.offsetWidth <= size;
    }
    return el.offsetWidth > size;
  }
  return false;
};

function useContainerMediaQuery(size, option) {
  const [ref, setRef] = useState(null);
  const [matches, setMatches] = useState(getMatches(ref, size, option));

  // Prevent too many rendering using useCallback
  const handleSize = useCallback(() => {
    setMatches(getMatches(ref, size, option));
  }, [ref?.offsetHeight, ref?.offsetWidth]);

  useIsomorphicLayoutEffect(() => {
    handleSize();

    // Listen matchMedia
    if (window) {
      window.addEventListener('resize', handleSize);
    }

    return () => {
      if (window) {
        window.removeEventListener('resize', handleSize);
      }
    };
  }, [ref?.offsetWidth]);

  return [setRef, matches];
}

export default useContainerMediaQuery;
