/* eslint-disable react/destructuring-assignment */
import { useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import { useDownloadExcel } from 'table-to-excel-react';
import { Box, Stack } from '@mui/material';
import { colors, fontWeight, IconButton, Text } from '../../../../../packages';
import { DashboardBox } from '../../../../../packages/ui/templates/dashboard-box';
import {
  getElkAppointmentFeedback,
  getElkAppointmentReport,
} from '../../../../../services/reporting/reporting.service';
import RecentAssignmentsTable from '../../../../../pages/admin/plan-items/components/plan-item-detail/recent-assignments-table';

import {
  getDashboardBoxData,
  getLast30DaysCounts,
  getMostAppointments,
  getMostNoShows,
  getMostRecentAppointments,
  getPast30DaysCounts,
} from './Appointments.mock';
import {
  appointmentFeedbackQuery,
  getAppointmentReportQuery,
  mostAppointmentsColumns,
  mostRecentAppointmentsColumns,
} from './Appointments.constants';
import AssignmentsChart from '../assignments-chart';
import { ViewAllDrawer } from './ViewAllDrawer';
import HeadCard from '../head-card';
import { useStyles } from './Appointments.styles';

const Appointments = ({ report, showNewReport, componentRef }) => {
  const classes = useStyles();
  const [showViewAllType, setShowViewAllType] = useState(null);
  const [appointmentReport, setAppointmentReport] = useState(null);
  const [feedbackData, setFeedbackData] = useState(null);

  const { onDownload } = useDownloadExcel({
    fileName: 'apponitments_report',
    table: 'table_to_xls',
    sheet: 'sheet 1',
  });
  const dashboardBoxData = useMemo(
    () => getDashboardBoxData(appointmentReport, feedbackData, report).filter(box => box.value),
    [appointmentReport, feedbackData, report],
  );
  const mostAppointmentsData = useMemo(() => getMostAppointments(appointmentReport), [appointmentReport]);
  const mostNoShowsData = useMemo(() => getMostNoShows(appointmentReport), [appointmentReport]);
  const mostRecentAppointmentsData = useMemo(
    () => getMostRecentAppointments(appointmentReport),
    [appointmentReport],
  );
  const last30DaysCounts = useMemo(() => getLast30DaysCounts(appointmentReport), [appointmentReport]);
  const past30DaysCounts = useMemo(() => getPast30DaysCounts(appointmentReport), [appointmentReport]);
  const appointmentsSection = useMemo(() => report?.data?.appointmentsSection, [report]);

  useEffect(() => {
    !showNewReport &&
      void (async () => {
        const resp = await getElkAppointmentReport(getAppointmentReportQuery(report), null);
        setAppointmentReport(resp.data);
        const feedback = await getElkAppointmentFeedback(appointmentFeedbackQuery, null);
        setFeedbackData(feedback.data);
      })();
  }, [showNewReport]);

  const onCloseViewAll = () => {
    setShowViewAllType(null);
  };

  const onClickAll = table => () => {
    setShowViewAllType(table);
  };

  const renderMenu = () => () => {
    return <></>;
  };

  const renderTable = (
    title,
    appointments,
    total,
    columns,
    onClickViewAll,
    renderMenuAction,
    className = '',
  ) => (
    <Box className={clsx(classes.tableWrap, className)} flex={1}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Text className={classes.recentTitle} weight={fontWeight.SEMI_BOLD}>
          {`Top 5 ${title}`}
        </Text>
        <IconButton onClick={onClickViewAll} className={classes.viewAllBtn}>
          View top ({total})
        </IconButton>
      </Stack>
      <RecentAssignmentsTable
        className={classes.tableBordered}
        isShowAll={false}
        tableColumns={columns}
        data={appointments}
        renderMenuAction={renderMenuAction}
      />
    </Box>
  );

  const renderAllTables = () => {
    return (
      <div id="table_to_xls" hidden>
        {[
          {
            title: 'Most Recent Appointments',
            tableColumns: null,
            data: null,
          },
          {
            tableColumns: mostRecentAppointmentsColumns,
            data: mostRecentAppointmentsData,
          },
          {
            title: 'Most Completed Appointments',
            tableColumns: null,
            data: null,
          },
          {
            tableColumns: mostAppointmentsColumns,
            data: mostAppointmentsData,
          },
          {
            title: 'Most No Shows',
            tableColumns: null,
            data: null,
          },
          {
            tableColumns: mostAppointmentsColumns,
            data: mostNoShowsData,
          },
        ].map((item, index) => {
          return (
            <table key={`${index}_t`}>
              <thead key={`${index}_th`}>
                <tr key={`${index}_trh`}>
                  {item?.title && <th>{item.title}</th>}
                  {item?.tableColumns?.find(col => col.id === 'member') &&
                    ['Member_fullName', 'Member_Name', 'Member_Id'].map(colItem => (
                      <th key={`${colItem}_thr`}>{colItem}</th>
                    ))}
                  {item?.tableColumns?.find(col => col.id === 'provider') &&
                    ['Provider_Name', 'provider_Role'].map(colItem => (
                      <th key={`${colItem}_thr`}>{colItem}</th>
                    ))}
                  {item?.tableColumns
                    ?.filter(col => !(col.id === 'member' || col.id === 'provider'))
                    .map((column, indx) => {
                      return <th key={`${indx}_thr`}>{column.label}</th>;
                    })}
                </tr>
              </thead>
              <tbody>
                {item?.data?.map((row, ind) => {
                  return (
                    <tr key={`${ind}_tr`}>
                      {item.tableColumns.find(col => col.id === 'member') &&
                        ['fullname', 'name', 'memberId'].map(colItem => (
                          <td key={`${colItem}_thr`}>{row.member[colItem]}</td>
                        ))}
                      {item.tableColumns.find(col => col.id === 'provider') &&
                        ['name', 'role'].map(colItem => (
                          <td key={`${colItem}_thr`}>{row.provider[colItem]}</td>
                        ))}
                      {item.tableColumns
                        .filter(col => !(col.id === 'member' || col.id === 'provider'))
                        .map((column, i) => {
                          return <td key={`${i}_tir`}>{row[column.id]}</td>;
                        })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          );
        })}
      </div>
    );
  };
  const getContentsInViewAll = () => {
    if (showViewAllType === 'mostAppointments') {
      return {
        title: 'Most completed appointments',
        subtitle: `${mostAppointmentsData.length} appointments total`,
        tableColumns: mostAppointmentsColumns,
        data: mostAppointmentsData,
        renderMenuAction: renderMenu(),
      };
    }
    if (showViewAllType === 'mostNoShows') {
      return {
        title: 'Most no shows',
        subtitle: `${mostNoShowsData.length} no shows total`,
        tableColumns: mostAppointmentsColumns,
        data: mostNoShowsData,
        renderMenuAction: renderMenu(),
      };
    }
    return {
      title: 'Most recent appointments',
      subtitle: `${mostRecentAppointmentsData.length} recent appointments total`,
      tableColumns: mostRecentAppointmentsColumns,
      data: mostRecentAppointmentsData,
      renderMenuAction: renderMenu(),
    };
  };

  return (
    <>
      {renderAllTables()}
      <ViewAllDrawer isOpen={!!showViewAllType} onClose={onCloseViewAll} {...getContentsInViewAll()} />
      <Box className={classes.card}>
        <HeadCard title="Appointments" componentRef={componentRef} onDownloadClick={onDownload} />
        <Box className={classes.card}>
          <Box className={classes.boxes}>
            {dashboardBoxData.map(box => (
              <DashboardBox {...box} key={box.type} />
            ))}
          </Box>
          {appointmentsSection.appointmentsInLast30Days?.isChecked && (
            <Box className={classes.chartWrap}>
              <AssignmentsChart
                title="Appointments last 30 days"
                className={classes.chart}
                chart={{
                  series: [
                    { name: 'Current 30 days', color: colors.primary500, data: last30DaysCounts },
                    { name: 'Past 30 days', color: colors.neutral600, data: past30DaysCounts },
                  ],
                  tooltipConfig: {
                    isShowTotal: false,
                    title: 'Total',
                  },
                }}
              />
            </Box>
          )}
          <Box display="flex" justifyContent="space-between" gap={6}>
            {appointmentsSection.mostAppointments?.isChecked &&
              renderTable(
                'Most completed appointments',
                mostAppointmentsData,
                mostAppointmentsData?.length,
                mostAppointmentsColumns,
                onClickAll('mostAppointments'),
                renderMenu(),
                !appointmentsSection.mostNoShows?.isChecked ? classes.halfTable : '',
              )}
            {appointmentsSection.mostNoShows?.isChecked &&
              renderTable(
                'Most no shows',
                mostNoShowsData,
                mostNoShowsData?.length,
                mostAppointmentsColumns,
                onClickAll('mostNoShows'),
                renderMenu(),
                !appointmentsSection.mostAppointments?.isChecked ? classes.halfTable : '',
              )}
          </Box>
          {appointmentsSection.mostRecentAppointments?.isChecked &&
            renderTable(
              'Most recent appointments',
              mostRecentAppointmentsData,
              mostRecentAppointmentsData?.length,
              mostRecentAppointmentsColumns,
              onClickAll('mostRecentAppointments'),
              renderMenu(),
              classes.fullTable,
            )}
        </Box>
      </Box>
    </>
  );
};

export { Appointments };
