import { FC, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { Box } from '@mui/material';
import { ApexOptions } from 'apexcharts';
import ReactApexChart from 'react-apexcharts';
import cx from 'clsx';

import {
  fontWeight,
  Heading,
  headingLevel,
  Text,
  textLevel,
} from '../../../../../packages/ui/atoms/typography';
import { Badge, badgeType, badgeStyle } from '../../../../../packages/ui/atoms/badge';
import { Drawer, drawerType } from '../../../../../packages/ui/organisms/drawer';
import { Link } from '../../../../../packages/ui/atoms/link';
import { ProgressBar } from '../../../../../packages/ui/atoms/ProgressBar';

// icons
import { Icons } from '../../../../../packages/icons';

// colors
import { colors } from '../../../../../packages/colors';

import { useStyles } from '../../MemberDetail.styles';

const PreviousCompletions = ({
  open,
  onClose,
  chatbot,
  isLoading,
  attemptHistories,
  completeHistory,
  onClickView,
}) => {
  const classes = useStyles();
  const [series, setSeries] = useState(null);

  const options = {
    chart: {
      toolbar: { show: false },
      selection: { enabled: false },
      zoom: { enabled: false },
      offsetX: 100,
      offsetY: 100,
    },
    dataLabels: { enabled: false },
    colors: [
      ({ value }) => {
        if (value > 1) {
          return '#2da834';
        }
        if (value === 1) {
          return '#7edc84';
        }
        return '#d4dde5';
      },
    ],
    grid: { show: false },
    xaxis: {
      labels: { show: false },
      axisTicks: { show: false },
      axisBorder: { show: false },
    },
    yaxis: { show: false },
    tooltip: { enabled: false },
    legend: { show: false },
    plotOptions: {
      heatmap: {
        colorScale: {
          ranges: [
            {
              from: 0,
              to: 0,
              color: '#d4dde5',
              foreColor: '#d4dde5',
            },
            {
              from: 1,
              to: 1,
              color: '#7edc84',
              foreColor: '#7edc84',
            },
            {
              from: 10000,
              to: 2,
              color: '#2da834',
              foreColor: '#2da834',
            },
          ],
        },
      },
    },
  };

  useEffect(() => {
    if (attemptHistories) {
      const data = attemptHistories.map(attempt => attempt.attemptDetails.length);
      setSeries([{ data: data.reverse() }]);
    }
  }, [attemptHistories]);

  const renderCard = (attempt, index) => {
    let attemptTime = '';
    let status = '';
    let progress = 0;
    let contextId = '';
    if (attempt.attemptDetails.length) {
      attemptTime = dayjs(attempt.attemptDetails[0].attemptTime).format('MMMM D, YYYY - hh:mm a');
      progress = attempt.attemptDetails[0].progress;
      status = progress > 99 ? 'Completed' : 'In progress';
      contextId = attempt.attemptDetails[0].contextId;
    } else {
      attemptTime = dayjs(attempt.date).format('MMMM D, YYYY');
      status = 'Not started';
      progress = 0;
      contextId = null;
      return null;
    }

    return (
      <Box key={index} className={classes.chatBotCard}>
        <Box className={classes.chatBotCardHeaderWrapper}>
          <Box className={classes.chatBotCardHeaderCompletions}>
            <Box className={classes.chatBotCardHeaderContent}>
              <Box>{attemptTime}</Box>
              <Badge
                className={classes.badgeOutlined}
                style={status === 'Completed' ? badgeStyle.SUCCESS : badgeStyle.CRITICAL}
                variant={badgeType.OUTLINED}
              >
                {status}
              </Badge>
            </Box>
          </Box>

          {contextId && (
            <Box className={classes.actions}>
              <Link
                className={cx({ [classes.careTeamValueWrapper]: true, [classes.link]: true })}
                onClick={e => {
                  e.preventDefault();
                  onClickView(contextId);
                }}
              >
                View
                <Icons glyph="arrow-right" color={colors.primary600} />
              </Link>
            </Box>
          )}
        </Box>
        {progress < 100 && (
          <Box className={classes.progressWrapper}>
            <Text className={classes.chatBotProgressText} level={textLevel.XS} weight={fontWeight.MEDIUM}>
              {progress}% completed
            </Text>
            <ProgressBar value={progress} />
          </Box>
        )}
      </Box>
    );
  };

  return (
    <Drawer
      variant={drawerType.FORM}
      open={open}
      title="Previous completions"
      subTitle={chatbot.chatbotVersion ? `Chatbot ${chatbot.chatbotVersion}` : ''}
      className={classes.chatBotDrawer2}
      footer={<div />}
      onClose={onClose}
    >
      {isLoading ? (
        <Box display="flex" justifyContent="center" sx={{ paddingY: 5 }}>
          <Icons className="rotate linear infinite" glyph="in-progress" color={colors.primary} />
        </Box>
      ) : (
        <Box className={classes.completionsContainer}>
          {!!series && !!series[0].data.length && (
            <>
              <Heading weight={fontWeight.SEMI_BOLD} level={headingLevel.S}>
                Completions map within last 30 days
              </Heading>

              <Box className={classes.heatmapChart}>
                <ReactApexChart options={options} series={series} type="heatmap" height={70} />
              </Box>
            </>
          )}

          {completeHistory.length > 0 ? (
            <>
              <Heading weight={fontWeight.SEMI_BOLD} level={headingLevel.S}>
                All completions
              </Heading>

              {completeHistory.map((attempt, index) => renderCard(attempt, index))}
            </>
          ) : (
            <Text weight={fontWeight.SEMI_BOLD} level={textLevel.M}>
              No completions yet
            </Text>
          )}
        </Box>
      )}
    </Drawer>
  );
};

export default PreviousCompletions;
