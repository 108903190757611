// import { makeStyles } from '@mui/styles';

// // colors
// import { colors } from '../../../colors';

// // types
// import { iconTextPosition, iconTextStyle } from './IconText.types';

// interface StyleProps {
//   style?: iconTextStyle;
//   iconPosition?: iconTextPosition;
// }

// const textColors = {
//   [iconTextStyle.PRIMARY]: colors.textPrimary,
//   [iconTextStyle.SECONDARY]: colors.textSecondary,
//   [iconTextStyle.ERROR]: colors.textError,
//   [iconTextStyle.SUCCESS]: colors.textSuccess,
// };

// export const useStyles = makeStyles({
//   root: ({ style }: StyleProps) => ({
//     display: 'inline-flex',
//     alignItems: 'center',
//     gap: 14,
//     fontFamily: 'Manrope',
//     letterSpacing: '0.01em',
//     color: textColors[style],
//   }),
//   icon: ({ iconPosition }: StyleProps) => ({
//     width: 15,
//     height: 15,
//     objectFit: 'contain',
//     order: iconPosition === iconTextPosition.LEFT ? 0 : 1,
//   }),
// });

import { makeStyles } from '@mui/styles';

// colors
import { colors } from '../../../colors';

const iconTextStyle = {
  PRIMARY: 'PRIMARY',
  SECONDARY: 'SECONDARY',
  ERROR: 'ERROR',
  SUCCESS: 'SUCCESS',
};

const iconTextPosition = {
  LEFT: 'left',
  RIGHT: 'right',
};

const textColors = {
  [iconTextStyle.PRIMARY]: colors.textPrimary,
  [iconTextStyle.SECONDARY]: colors.textSecondary,
  [iconTextStyle.ERROR]: colors.textError,
  [iconTextStyle.SUCCESS]: colors.textSuccess,
};

export const useStyles = makeStyles({
  root: ({ style }) => ({
    display: 'inline-flex',
    alignItems: 'center',
    gap: 14,
    fontFamily: 'Manrope',
    letterSpacing: '0.01em',
    color: textColors[style],
  }),
  icon: ({ iconPosition }) => ({
    width: 15,
    height: 15,
    objectFit: 'contain',
    order: iconPosition === iconTextPosition.LEFT ? 0 : 1,
  }),
});
