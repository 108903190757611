import { colors } from '../../../../../packages/colors';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  card: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 24,
  },
  imageWrap: {
    width: 64,
    height: 64,
    borderRadius: 6,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.neutral50,
    '& svg path': {
      fill: colors.neutral400,
    },
  },
  imageWrapFailed: {
    backgroundColor: colors.destructive50,
    '& svg path': {
      fill: colors.destructive400,
    },
  },
  filename: {
    fontSize: 14,
    lineHeight: '20px',
    fontWeight: 500,
  },
  progressBar: {
    marginTop: 8,
    marginBottom: 8,
  },
  progressText: {
    fontSize: 12,
    lineHeight: '16px',
    fontWeight: 500,
    color: colors.neutral500,
  },
  failedText: {
    color: colors.destructive600,
  },
  removeBtn: {
    width: 36,
    height: 36,
    minWidth: 36,
    borderRadius: '50%',
    backgroundColor: colors.neutral50,
    '& svg path': {
      fill: colors.neutral500,
    },
  },
  recycleBtn: {
    backgroundColor: colors.destructive50,
    '& svg': {
      width: 16,
    },
    '& svg path': {
      fill: colors.destructive500,
    },
  },
  reloadBtn: {
    backgroundColor: colors.primary100,
    '& svg': {
      width: 16,
    },
    '& svg path': {
      fill: colors.primary500,
    },
  },
});
