import { makeStyles } from '@mui/styles';

// colors
import { colors } from '../../../../../packages';

export const useStyles = makeStyles({
  card: {
    backgroundColor: colors.white,
    boxShadow:
      '0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
    width: '100%',
    borderRadius: 8,
    overflow: 'hidden',
  },
  reports: {
    padding: 24,
    backgroundColor: colors.neutral50,
  },
  reportItem: {
    flex: 1,
    width: '100%',
  },
  chartWrap: {},
  chart: {
    padding: 0,
    borderRadius: 0,
    border: 'none !important',
    boxShadow: 'none !important',
    '& .chart-head': {
      padding: '30px 32px',
      borderBottom: 'none',
      position: 'relative',
      '&:before': {
        content: "''",
        position: 'absolute',
        bottom: 0,
        width: 'calc(100% - 64px)',
        height: 1,
        backgroundColor: colors.neutral100,
      },
    },
  },
  tableWrap: {
    '&:first-child': {
      padding: '8px 0 32px 32px',
    },
    '&:last-child': {
      padding: '8px 32px 32px 0',
    },
    '& tr td': {
      height: 56,
      '& button': {
        height: 'auto',
        padding: 0,
      },
    },
  },
  halfTable: {
    padding: '32px !important',
  },
  fullTable: {
    '&:last-child': {
      padding: '0 32px 32px 32px',
    },
  },
  tableBordered: {
    border: `1px solid ${colors.neutral100}`,
    borderBottom: 'none',
    borderRadius: 8,
    overflow: 'hidden',
    marginTop: 24,
    '& td.color-secondary-500': {
      color: colors.secondary500,
    },
  },
  recentTitle: {
    fontSize: 18,
    lineHeight: '24px',
    margin: 0,
  },
  viewAllBtn: {
    fontSize: 14,
    lineHeight: '20px',
    fontWeight: 700,
  },
  menu: {
    '& svg': {
      width: 14,
    },
  },
  badge: {
    fontSize: 14,
    lineHeight: '20px',
    height: 20,
    fontWeight: 600,
    backgroundColor: '#FFF1F3',
    borderColor: '#FFF1F3',
    color: '#E31B53',
    padding: '4px 6px',
    margin: 'auto',
    '& svg': {
      width: 16,
      marginRight: 4,
      transform: 'rotate(270deg)',
      '& path': {
        fill: '#E31B53',
      },
    },
  },
  badgeUp: {
    borderColor: colors.green50,
    backgroundColor: colors.green50,
    color: colors.green500,
    '& svg path': {
      fill: colors.green500,
    },
  },
});
