import {
  ADD_PAYER,
  ADD_PAYER_FAILED,
  ADD_PAYER_PLAN,
  ADD_PAYER_PLAN_CPT,
  ADD_PAYER_PLAN_CPT_FAILED,
  ADD_PAYER_PLAN_CPT_SUCCESSFUL,
  ADD_PAYER_PLAN_FAILED,
  ADD_PAYER_PLAN_SUCCESSFUL,
  ADD_PAYER_PROVIDER,
  ADD_PAYER_PROVIDER_FAILED,
  ADD_PAYER_PROVIDER_SUCCESSFUL,
  ADD_PAYER_SUCCESSFUL,
  ADD_REGISTERED_ENTITY,
  ADD_REGISTERED_ENTITY_FAILED,
  ADD_REGISTERED_ENTITY_SUCCESSFUL,
  FETCH_CPTS,
  FETCH_CPTS_FAILED,
  FETCH_CPTS_SUCCESSFUL,
  FETCH_ICD10,
  FETCH_ICD10_FAILED,
  FETCH_ICD10_SUCCESSFUL,
  FETCH_PAYER_DETAIL,
  FETCH_PAYER_DETAIL_FAILED,
  FETCH_PAYER_DETAIL_SUCCESSFUL,
  FETCH_PAYER_GROUPS,
  FETCH_PAYER_GROUPS_FAILED,
  FETCH_PAYER_GROUPS_SUCCESSFUL,
  FETCH_PAYERS,
  FETCH_PAYERS_FAILED,
  FETCH_PAYERS_SUCCESSFUL,
  FETCH_STATE_DETAIL,
  FETCH_STATE_DETAIL_FAILED,
  FETCH_STATE_DETAIL_SUCCESSFUL,
  FETCH_STATES,
  FETCH_STATES_FAILED,
  FETCH_STATES_SUCCESSFUL,
  UPDATE_PAYER,
  UPDATE_PAYER_FAILED,
  UPDATE_PAYER_PLAN,
  UPDATE_PAYER_PLAN_CPT,
  UPDATE_PAYER_PLAN_CPT_FAILED,
  UPDATE_PAYER_PLAN_CPT_SUCCESSFUL,
  UPDATE_PAYER_PLAN_FAILED,
  UPDATE_PAYER_PLAN_SUCCESSFUL,
  UPDATE_PAYER_PROVIDER,
  UPDATE_PAYER_PROVIDER_FAILED,
  UPDATE_PAYER_PROVIDER_SUCCESSFUL,
  UPDATE_PAYER_SUCCESSFUL,
  UPDATE_REGISTERED_ENTITY,
  UPDATE_REGISTERED_ENTITY_FAILED,
  UPDATE_REGISTERED_ENTITY_SUCCESSFUL,
  UPDATE_STATE,
  UPDATE_STATE_FAILED,
  UPDATE_STATE_SUCCESSFUL,
} from './actions';
import { USER_LOGOUT } from '../auth/actions';

export const DEFAULT = {
  isLoading: false,
  isSaving: false,
  isRequesting: false,
  errorMsg: '',
  states: [],
  selectedState: {},
  payers: [],
  payerGroups: [],
  selectedPayer: {},
  cpts: [],
  icd10: [],
};

export default function stateReducer(state = DEFAULT, action) {
  const { type, payload } = action;

  switch (type) {
    case FETCH_STATES: {
      return {
        ...state,
        isLoading: true,
        errorMsg: '',
      };
    }
    case FETCH_STATES_SUCCESSFUL: {
      return {
        ...state,
        isLoading: false,
        states: payload,
      };
    }
    case FETCH_STATES_FAILED: {
      return {
        ...state,
        isLoading: false,
        states: [],
      };
    }
    case UPDATE_STATE: {
      return {
        ...state,
        isSaving: true,
        errorMsg: null,
      };
    }
    case UPDATE_STATE_SUCCESSFUL: {
      return {
        ...state,
        isSaving: false,
        errorMsg: null,
      };
    }
    case UPDATE_STATE_FAILED: {
      return {
        ...state,
        isSaving: false,
        errorMsg: payload?.message || null,
      };
    }
    case ADD_REGISTERED_ENTITY: {
      return {
        ...state,
        isSaving: true,
        errorMsg: null,
      };
    }
    case ADD_REGISTERED_ENTITY_SUCCESSFUL: {
      return {
        ...state,
        // selectedPayer: payload,
      };
    }
    case ADD_REGISTERED_ENTITY_FAILED: {
      return {
        ...state,
        isSaving: false,
        errorMsg: payload?.message || null,
      };
    }
    case UPDATE_REGISTERED_ENTITY: {
      return {
        ...state,
        isSaving: true,
        errorMsg: null,
      };
    }
    case UPDATE_REGISTERED_ENTITY_SUCCESSFUL: {
      return {
        ...state,
        // selectedPayer: payload,
        isSaving: false,
        errorMsg: null,
      };
    }
    case UPDATE_REGISTERED_ENTITY_FAILED: {
      return {
        ...state,
        isSaving: false,
        errorMsg: payload?.message || null,
      };
    }
    case FETCH_STATE_DETAIL: {
      return {
        ...state,
        isLoading: true,
        errorMsg: '',
      };
    }
    case FETCH_STATE_DETAIL_SUCCESSFUL: {
      return {
        ...state,
        isLoading: false,
        selectedState: payload,
      };
    }
    case FETCH_STATE_DETAIL_FAILED: {
      return {
        ...state,
        isLoading: false,
        selectedState: {},
      };
    }
    case FETCH_PAYERS: {
      return {
        ...state,
        isLoading: true,
        errorMsg: '',
      };
    }
    case FETCH_PAYERS_SUCCESSFUL: {
      return {
        ...state,
        isLoading: false,
        payers: payload.data,
      };
    }
    case FETCH_PAYERS_FAILED: {
      return {
        ...state,
        isLoading: false,
        payers: [],
      };
    }
    case FETCH_PAYER_DETAIL: {
      return {
        ...state,
        isLoading: true,
        errorMsg: '',
      };
    }
    case FETCH_PAYER_DETAIL_SUCCESSFUL: {
      return {
        ...state,
        isLoading: false,
        selectedPayer: payload,
      };
    }
    case FETCH_PAYER_DETAIL_FAILED: {
      return {
        ...state,
        isLoading: false,
        selectedPayer: {},
      };
    }
    case ADD_PAYER: {
      return {
        ...state,
        isSaving: true,
        errorMsg: null,
      };
    }
    case ADD_PAYER_SUCCESSFUL: {
      return {
        ...state,
        selectedPayer: payload,
      };
    }
    case ADD_PAYER_FAILED: {
      return {
        ...state,
        isSaving: false,
        errorMsg: payload?.message || null,
      };
    }
    case UPDATE_PAYER: {
      return {
        ...state,
        isSaving: true,
        errorMsg: null,
      };
    }
    case UPDATE_PAYER_SUCCESSFUL: {
      return {
        ...state,
        selectedPayer: payload,
        isSaving: false,
        errorMsg: null,
      };
    }
    case UPDATE_PAYER_FAILED: {
      return {
        ...state,
        isSaving: false,
        errorMsg: payload?.message || null,
      };
    }
    case ADD_PAYER_PLAN: {
      return {
        ...state,
        isSaving: true,
        errorMsg: null,
      };
    }
    case ADD_PAYER_PLAN_SUCCESSFUL: {
      return {
        ...state,
        selectedPayer: payload,
      };
    }
    case ADD_PAYER_PLAN_FAILED: {
      return {
        ...state,
        isSaving: false,
        errorMsg: payload?.message || null,
      };
    }
    case UPDATE_PAYER_PLAN: {
      return {
        ...state,
        isSaving: true,
        errorMsg: null,
      };
    }
    case UPDATE_PAYER_PLAN_SUCCESSFUL: {
      return {
        ...state,
        selectedPayer: payload,
      };
    }
    case UPDATE_PAYER_PLAN_FAILED: {
      return {
        ...state,
        isSaving: false,
        errorMsg: payload?.message || null,
      };
    }
    case ADD_PAYER_PROVIDER: {
      return {
        ...state,
        isSaving: true,
        errorMsg: null,
      };
    }
    case ADD_PAYER_PROVIDER_SUCCESSFUL: {
      return {
        ...state,
        selectedPayer: payload,
      };
    }
    case ADD_PAYER_PROVIDER_FAILED: {
      return {
        ...state,
        isSaving: false,
        errorMsg: payload?.message || null,
      };
    }
    case UPDATE_PAYER_PROVIDER: {
      return {
        ...state,
        isSaving: true,
        errorMsg: null,
      };
    }
    case UPDATE_PAYER_PROVIDER_SUCCESSFUL: {
      return {
        ...state,
        selectedPayer: payload,
      };
    }
    case UPDATE_PAYER_PROVIDER_FAILED: {
      return {
        ...state,
        isSaving: false,
        errorMsg: payload?.message || null,
      };
    }
    case FETCH_PAYER_GROUPS: {
      return {
        ...state,
        isLoading: true,
        errorMsg: '',
      };
    }
    case FETCH_PAYER_GROUPS_SUCCESSFUL: {
      return {
        ...state,
        isLoading: false,
        payerGroups: payload,
      };
    }
    case FETCH_PAYER_GROUPS_FAILED: {
      return {
        ...state,
        isLoading: false,
        payerGroups: [],
      };
    }
    case FETCH_CPTS: {
      return {
        ...state,
        isLoading: true,
        errorMsg: '',
      };
    }
    case FETCH_CPTS_SUCCESSFUL: {
      return {
        ...state,
        isLoading: false,
        cpts: payload,
      };
    }
    case FETCH_CPTS_FAILED: {
      return {
        ...state,
        isLoading: false,
        cpts: [],
      };
    }
    case FETCH_ICD10: {
      return {
        ...state,
        isLoading: true,
        errorMsg: '',
      };
    }
    case FETCH_ICD10_SUCCESSFUL: {
      return {
        ...state,
        isLoading: false,
        icd10: payload,
      };
    }
    case FETCH_ICD10_FAILED: {
      return {
        ...state,
        isLoading: false,
        icd10: [],
      };
    }
    case ADD_PAYER_PLAN_CPT: {
      return {
        ...state,
        isSaving: true,
        errorMsg: null,
      };
    }
    case ADD_PAYER_PLAN_CPT_SUCCESSFUL: {
      return {
        ...state,
        selectedPayer: payload,
      };
    }
    case ADD_PAYER_PLAN_CPT_FAILED: {
      return {
        ...state,
        isSaving: false,
        errorMsg: payload?.message || null,
      };
    }
    case UPDATE_PAYER_PLAN_CPT: {
      return {
        ...state,
        isSaving: true,
        errorMsg: null,
      };
    }
    case UPDATE_PAYER_PLAN_CPT_SUCCESSFUL: {
      return {
        ...state,
        selectedPayer: payload,
      };
    }
    case UPDATE_PAYER_PLAN_CPT_FAILED: {
      return {
        ...state,
        isSaving: false,
        errorMsg: payload?.message || null,
      };
    }
    case USER_LOGOUT: {
      return {
        ...DEFAULT,
      };
    }
    default: {
      return state;
    }
  }
}
