// colors
import { colors } from '../../../../packages';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  drawer: {
    '& .MuiPaper-root': {
      maxWidth: '480px !important',
    },
    '& .drawerBody': {
      minHeight: 'calc(100vh - 50px)',
      flexDirection: 'column',
      display: 'flex',
      padding: '38px 32px',
    },
  },
  badge: {
    fontSize: 14,
    height: 28,
  },
  badge2: {
    fontSize: 12,
    height: 18,
  },
  title: {
    fontSize: 18,
    lineHeight: '24px',
    margin: 0,
  },
  text14: {
    fontSize: 14,
    lineHeight: '20px',
  },
  duration: {
    color: colors.neutral600,
    display: 'block',
    marginTop: 4,
  },
  info: {
    color: colors.neutral700,
  },
  fullname: {
    color: colors.neutral500,
    display: 'block',
  },
  phoneEmail: {
    display: 'flex',
    marginTop: 24,
    gap: 24,
    padding: 12,
    background: colors.neutral25,
    border: '1px solid #E5EBF0',
    borderRadius: 6,
  },
  boxInfo: {
    display: 'flex',
    alignItems: 'center',
    gap: 10,
    '& svg': {
      width: 14,
      height: 14,
    },
  },
  chatBtn: {
    fontSize: 14,
    color: colors.primary600,
    lineHeight: '20px',
    '& svg': {
      width: 16,
      marginLeft: 10,
    },
  },
  userType: {
    color: colors.neutral600,
    display: 'block',
  },
  userinfo: {
    padding: 12,
    display: 'flex',
    gap: 24,
    backgroundColor: colors.neutral25,
    border: `1px solid ${colors.neutral100}`,
    borderRadius: 6,
  },
  infoLabel: {
    display: 'block',
    marginBottom: 8,
    fontSize: 16,
    fontWeight: 600,
    lineHeight: '24px',
    color: colors.neutral900,
  },
  infoValue: {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: '20px',
    color: colors.neutral700,
  },
  footer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginTop: 40,
    paddingBottom: 20,
  },
  footerBtn: {
    height: 48,
    width: '100%',
  },
  evaluationFooter: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 80,
    padding: 16,
    borderTop: `1px solid ${colors.neutral100}`,
  },
  wrapper: {
    justifyItems: 'space-between',
    flexDirection: 'column',
  },
  backBtn: {
    width: 40,
    height: 40,
    minWidth: 40,
    borderRadius: 8,
    border: `1px solid ${colors.primary300}`,
    '& svg': {
      width: 8,
    },
  },
  header: {
    display: 'flex',
    gap: 24,
    alignItems: 'center',
    height: 80,
    padding: '20px 16px',
    borderBottom: `1px solid ${colors.neutral100}`,
  },
  headerTitle: {
    fontSize: 24,
    lineHeight: '32px',
    fontWeight: 700,
  },
  searchWrap: {
    padding: 24,
    maxHeight: 'calc(100% - 330px)',
    overflowY: 'scroll',
  },
  searchWrapNoApptDetails: {
    padding: 24,
    maxHeight: 'calc(100% - 200px)',
    overflowY: 'scroll',
  },
  searchInput: {
    height: 48,
    width: '100%',
    backgroundColor: colors.white,
  },
  evaluations: {
    '& > div': {
      borderBottom: `1px solid ${colors.neutral100}`,
      '&:last-child': {
        borderBottom: 'none',
      },
    },
  },
  evaluation: {
    flexDirection: 'column',
    display: 'flex',
    padding: '38px 30px',
    borderBottom: `1px solid ${colors.neutral100}`,
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    gap: 10,
    flexDirection: 'column',
  },
  btn: {
    width: '100%',
    color: colors.white,
  },
  btnCancel: {
    border: `1px solid ${colors.destructive300}`,
    color: colors.destructive600,
  },
  cancelPadding: {
    paddingTop: 20,
  },
  infoIcon: {
    width: 12,
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  flexAlignCenter: {
    display: 'flex',
    alignItems: 'center',
  },
  flexBetween: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  cancelledStatusText: {
    whiteSpace: 'nowrap',
  },
});

export const sxStyles = {
  durationWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
  },
  recurringIcon: {
    display: 'flex',
    marginTop: '5px',
    alignItems: 'center',
  },
};
