import React, { FC, useEffect, useRef } from 'react';
import cx from 'clsx';
import { Icons } from '../../../../packages/icons';
import { colors } from '../../../../packages/colors';
import { Text } from '../../../../packages/ui/atoms/typography';
import { Box, Checkbox } from '@mui/material';
import { useStyles } from './InPersonSession.styles';

export const MultiSelectOptions = ({
  answerTitle,
  answerSubtitle,
  value,
  onChange,
  currentBlock,
  focusedChoice,
  inPersonSessionFlow,
  scrollRef,
  fullPageMode = true,
}) => {
  const classes = useStyles();
  const ref = useRef(null);

  useEffect(() => {
    if (ref?.current && scrollRef?.current) {
      if (focusedChoice || !fullPageMode) {
        scrollRef.current.scrollTo({
          top: ref.current.offsetTop + 36 - 211,
          behavior: 'smooth',
        });
      }
    }
  }, [scrollRef, currentBlock, focusedChoice, ref]);

  let isChecked = false;
  if (
    currentBlock &&
    currentBlock.userResponse &&
    currentBlock.userResponse?.userResponseList.length > 0 &&
    currentBlock.userResponse.userResponseList.includes(value)
  ) {
    isChecked = true;
  }
  return (
    <Box
      className={cx(classes.answerOptionBox, {
        [classes.focusedChoice]: focusedChoice,
        [classes.adjustedAnswerOptionBox]: !inPersonSessionFlow,
      })}
      onClick={() => onChange(value)}
      ref={ref}
    >
      <Checkbox
        checked={isChecked}
        value={value}
        checkedIcon={
          inPersonSessionFlow ? (
            <Icons glyph="check-circle" color={colors.success500} className={classes.rightSectionCheckIcon} />
          ) : (
            <Icons
              glyph="checkbox-outlined"
              color={colors.primary}
              className={classes.adjustedRightSectionCheckIcon}
            />
          )
        }
        icon={
          inPersonSessionFlow ? (
            <Icons
              glyph="circle-outlined"
              color={colors.neutral400}
              className={classes.rightSectionCheckIcon}
            />
          ) : (
            <Icons
              glyph="checkbox-blank-outlined"
              color={colors.neutral400}
              className={classes.adjustedRightSectionCheckIcon}
            />
          )
        }
        onChange={() => {
          () => onChange(value);
        }}
      />
      <Box
        className={cx(classes.optionContent, {
          [classes.optionWithNoDescription]: !answerSubtitle,
        })}
      >
        <Text
          className={cx(classes.answerTitle, {
            [classes.optionWithNoDescription]: !answerSubtitle,
            [classes.adjustedAnswerTitle]: !inPersonSessionFlow,
          })}
        >
          {answerTitle}
        </Text>
        {answerSubtitle && inPersonSessionFlow && (
          <Text className={classes.answerSubTitle}>{answerSubtitle}</Text>
        )}
      </Box>
    </Box>
  );
};
