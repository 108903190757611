import { connect } from 'react-redux';

import ToastMessage from '../../packages/ui/templates/toast-message';
import { clearSnackbar } from '../../redux/modules/snackbar';

function SnackContainer(props) {
  return <ToastMessage {...props} />;
}

const mapStateToProps = state => state.snackbar;
const mapDispatchToProps = dispatch => ({
  clearSnackbarAction: () => dispatch(clearSnackbar()),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(SnackContainer);
