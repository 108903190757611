// colors
import { colors } from '../../../../packages/colors';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  card: {
    padding: 16,
    borderRadius: 8,
    backgroundColor: colors.white,
    width: '100%',
    cursor: 'pointer',
    boxShadow:
      '0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04)',
  },
  cardNotClickable: {
    cursor: 'default',
  },
  title: {
    fontSize: 16,
    lineHeight: '24px',
    fontWeight: 600,
    display: 'block',
  },
  subtitle: {
    fontSize: 14,
    lineHeight: '20px',
    fontWeight: 400,
    color: colors.neutral600,
  },
  infoIconBtn: {
    width: 40,
    minWidth: 40,
    height: 40,
    borderRadius: 4,
    padding: 10,
    '& svg': {
      width: '20px !important',
      height: '20px !important',
    },
  },
  badge: {
    borderRadius: 20,
    height: 28,
    padding: '0 8px',
    minWidth: 28,
  },
  completedBadge: {
    backgroundColor: colors.white,
    color: colors.textSuccess,
    '& svg path': {
      fill: colors.textSuccess,
    },
  },
  rightCard: {
    flex: 0.55,
  },
  progressWrap: {
    justifyContent: 'space-between',
    width: '100%',
  },
  progress: {
    height: 8,
    '&.CRITICAL, &.NEGATIVE': {
      backgroundColor: colors.destructive500,
    },
    '&.HIGH': {
      backgroundColor: colors.orange500,
    },
    '&.LOW': {
      backgroundColor: colors.cyan500,
    },
    '&.MEDIUM': {
      backgroundColor: colors.warning500,
    },
    '&.RESOLVED, &.POSITIVE': {
      backgroundColor: colors.success500,
    },
    '&.MISREPORTED': {
      backgroundColor: colors.secondary500,
    },
    '&.UNRELATED': {
      backgroundColor: colors.neutral500,
    },
  },
});
