/* eslint-disable max-len */
import cx from 'clsx';

// types
// styles
import { useStyles } from './shared/styles';

const Speed = ({ className, color, onClick }) => {
  const classes = useStyles();
  return (
    <svg
      className={cx(classes.root, className)}
      onClick={onClick}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 0C15.523 0 20 4.477 20 10C20 15.523 15.523 20 10 20C4.477 20 0 15.523 0 10C0 4.477 4.477 0 10 0ZM10 2C5.582 2 2 5.582 2 10C2 14.418 5.582 18 10 18C14.418 18 18 14.418 18 10C18 5.582 14.418 2 10 2ZM13.833 5.337C14.07 5.171 14.392 5.199 14.596 5.404C14.8 5.608 14.826 5.93 14.659 6.164C12.479 9.21 11.279 10.842 11.061 11.061C10.475 11.646 9.525 11.646 8.939 11.061C8.354 10.475 8.354 9.525 8.939 8.939C9.313 8.566 10.944 7.365 13.833 5.337ZM15.5 9C16.052 9 16.5 9.448 16.5 10C16.5 10.552 16.052 11 15.5 11C14.948 11 14.5 10.552 14.5 10C14.5 9.448 14.948 9 15.5 9ZM4.5 9C5.052 9 5.5 9.448 5.5 10C5.5 10.552 5.052 11 4.5 11C3.948 11 3.5 10.552 3.5 10C3.5 9.448 3.948 9 4.5 9ZM6.818 5.404C7.208 5.794 7.208 6.427 6.818 6.818C6.428 7.208 5.794 7.208 5.404 6.818C5.014 6.428 5.014 5.794 5.404 5.404C5.794 5.014 6.427 5.014 6.818 5.404ZM10 3.5C10.552 3.5 11 3.948 11 4.5C11 5.052 10.552 5.5 10 5.5C9.448 5.5 9 5.052 9 4.5C9 3.948 9.448 3.5 10 3.5Z"
        fill={color}
      />
    </svg>
  );
};

export default Speed;
