import { makeStyles } from '@mui/styles';

// colors
import { colors } from '../../../packages';

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: 40,
  },
  name: {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '20px',
  },
  heading: {
    fontFamily: 'Source Serif Pro',
    margin: 0,
  },
  totalMemberBadge: {
    borderRadius: 99,
    whiteSpace: 'nowrap',
    height: 30,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 12px',
    color: colors.neutral700,
  },
  addProfileBtn: {
    height: 64,
    color: '#FFF',
    whiteSpace: 'nowrap',
    padding: '20px 24px',
    borderRadius: 8,
  },
});
