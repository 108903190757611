import { makeStyles } from '@mui/styles';

// colors
import { colors } from '../../../../packages';

export const useStyles = makeStyles({
  card: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 64,
    padding: '16px 24px',
    borderRadius: 8,
    backgroundColor: colors.white,
    boxShadow: '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px -1px rgba(16, 24, 40, 0.1)',
    '&:hover $action': {
      display: 'flex',
    },
  },
  state: {
    marginLeft: -12,
    fontSize: 18,
    fontWeight: 600,
  },
  dot: {
    width: 12,
    height: 12,
    background: colors.success500,
    backgroundColor: active => (active ? colors.success500 : colors.neutral400),
    borderRadius: 100,
  },
  action: {
    display: 'none',
    gap: 20,
  },
  button: {
    fontSize: 14,
    '& svg': {
      marginRight: 8,
      width: 16,
    },
  },
});
