/* eslint-disable max-len */
import cx from 'clsx';

// types
// styles
import { useStyles } from './shared/styles';

const BookOpenedOutlined = ({ className, color, onClick }) => {
  const classes = useStyles();

  return (
    <svg
      className={cx({
        [classes.root]: true,
        [className || '']: className,
      })}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M13 21V23H11V21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73479 2.10536 3.48043 2.29289 3.2929C2.48043 3.10536 2.73478 3 3 3H9C9.56759 2.99933 10.1288 3.11976 10.6461 3.35325C11.1635 3.58674 11.625 3.92792 12 4.354C12.375 3.92792 12.8365 3.58674 13.3539 3.35325C13.8712 3.11976 14.4324 2.99933 15 3H21C21.2652 3 21.5196 3.10536 21.7071 3.2929C21.8946 3.48043 22 3.73479 22 4V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H13ZM20 19V5H15C14.4696 5 13.9609 5.21072 13.5858 5.58579C13.2107 5.96086 13 6.46957 13 7V19H20ZM11 19V7C11 6.46957 10.7893 5.96086 10.4142 5.58579C10.0391 5.21072 9.53043 5 9 5H4V19H11Z"
        fill={color}
      />
    </svg>
  );
};

export default BookOpenedOutlined;
