import { Box } from '@mui/material';
import { useStyles } from './ErrorPage.styles';

const ErrorPage = () => {
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      <Box>
        <img src="https://i.imgur.com/Tgbdv8K.png" alt="Alfie logo" className={classes.alfieImage} />
      </Box>
      <Box className={classes.errorText}>Error 404 Page Not Found.</Box>
    </Box>
  );
};

export { ErrorPage };
