import cx from 'clsx';

// icons
import { Icons } from '../../../icons';

// components
import { Button } from '../../atoms/button';

// styles
import { useStyles } from './IconButton.styles';

// types
import { iconBtnPosition, iconBtnStyle, iconBtnType } from './IconButton.types';
import { colors } from '../../../colors';

const IconButton = ({
  className = '',
  variant = iconBtnType.TEXT,
  style = iconBtnStyle.PRIMARY,
  icon = null,
  iconPosition = iconBtnPosition.LEFT,
  children = null,
  onKeyPress = null,
  onClick = null,
  loading = false,
  ...rest
}) => {
  const classes = useStyles({ style, iconPosition });

  return (
    <Button
      className={cx({
        [classes.root]: true,
        [variant === iconBtnType.TEXT ? classes.text : classes.btn]: true,
        [className || '']: className,
      })}
      variant={variant}
      onClick={onClick}
      onKeyPress={onKeyPress}
      {...rest}
    >
      {loading && <Icons className="rotate linear infinite" glyph="in-progress" color={colors.primary} />}
      {!loading && (
        <>
          {icon && <Icons className={classes.icon} glyph={icon} color="currentColor" />}
          {children}
        </>
      )}
    </Button>
  );
};

export { IconButton };
