/* eslint-disable max-len */
import cx from 'clsx';

// types
// styles
import { useStyles } from './shared/styles';

const Message = ({ className, color, onClick }) => {
  const classes = useStyles();
  return (

    <svg
      className={cx({
        [classes.root]: true,
        [className || '']: className,
      })}
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      onClick={onClick}
    >
      <path
        d='M9.6335 12.6667L8.00016 15L6.36683 12.6667H2.00016C1.82335 12.6667 1.65378 12.5964 1.52876 12.4714C1.40373 12.3464 1.3335 12.1768 1.3335 12V2.66667C1.3335 2.48986 1.40373 2.32029 1.52876 2.19526C1.65378 2.07024 1.82335 2 2.00016 2H14.0002C14.177 2 14.3465 2.07024 14.4716 2.19526C14.5966 2.32029 14.6668 2.48986 14.6668 2.66667V12C14.6668 12.1768 14.5966 12.3464 14.4716 12.4714C14.3465 12.5964 14.177 12.6667 14.0002 12.6667H9.6335ZM8.9395 11.3333H13.3335V3.33333H2.66683V11.3333H7.06083L8.00016 12.6747L8.9395 11.3333Z'
        fill={color}
      />
    </svg>
  );
};

export default Message;
