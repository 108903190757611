/* eslint-disable func-names */
/* eslint-disable object-shorthand */
import { createRef, useEffect, useState } from 'react';
import { ButtonGroup, Paper } from '@mui/material';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import dayjs from 'dayjs';
import cx from 'clsx';
import $ from 'jquery';

import { Button } from '../../atoms/button';
import { calendarToolbar, calendarToolbarMultipleView } from './Calendar.config';
import { useStyles } from './Calendar.styles';
import { AppointmentStatus, CalendarView, DaysOfWeek, SignOffStatus } from '../../../constants/CommonConstants';

const Calendar = ({
  className = '',
  appointments,
  isMultipleView = false,
  onClickAppointment,
  onDateClick,
  modeView = CalendarView.Month,
  providerSchedule,
  ...rest
}) => {
  const classes = useStyles();
  const calendarRef = createRef();
  const [currentView, setCurrentView] = useState(modeView);
  const [events, setEvents] = useState([]);

  const jqueryRenderTimeSlot = () => {
    $(document).on(
      {
        mouseenter: function () {
          const cellWidth = $('th.fc-col-header-cell').width();
          const cellHeight = $(this).height();
          const columnCount = $('thead table.fc-col-header th.fc-col-header-cell').children().length;

          if (!$(this).html()) {
            for (let i = 0; i < columnCount; i++) {
              $(this).append(
                `<td
                  class="temp-cell"
                  style="height: ${cellHeight - 1}px; width: ${+cellWidth + 3}px"
                />`,
              );
            }
          }
        },

        mouseleave: function () {
          $(this).children('.temp-cell').remove();
        },
      },
      'td.fc-timegrid-slot.fc-timegrid-slot-lane',
    );
  };

  useEffect(() => {
    if (currentView === CalendarView.Week) {
      jqueryRenderTimeSlot();
    }
  }, [currentView]);

  const getEventData = item => ({
    title: item.serviceName,
    name: item.participantName,
    start: item.startTime,
    appointmentId: item.appointmentId,
    status: item.status,
    isRequireSignOff: item.requireSupervisorSignOff && item.signOffStatus !== SignOffStatus.APPROVED,
    end: dayjs(item.startTime).add(item.serviceDuration, 'm').toISOString(),
    items: [item],
  });

  useEffect(() => {
    const newEvents = appointments.reduce((obj, item) => {
      const appointmentDate = dayjs(item.startTime).format('YYYY-MM-DD');
      const keyTime = currentView === CalendarView.Month ? appointmentDate : item.startTime;
      if (obj[keyTime]) {
        const prevEndTime = dayjs(obj[keyTime].end);
        const nextEndTime = dayjs(item.startTime).add(item.serviceDuration, 'm');

        if (nextEndTime.isAfter(prevEndTime)) {
          obj[keyTime].end = nextEndTime.toISOString();
        }
        obj[keyTime].items.push(item);
        obj[keyTime].title = `${obj[keyTime].items.length} sessions`;
      } else {
        obj[keyTime] = getEventData(item);
      }
      return obj;
    }, {});
    setEvents(Object.values(newEvents));
  }, [appointments, currentView]);

  useEffect(() => {
    calendarRef.current?.getApi().changeView(modeView);
    setCurrentView(modeView);
  }, [modeView]);

  const isAvailableDate = date => {
    const isToday = dayjs(date).isSame(dayjs(), 'day');
    const isPast = dayjs(date).isBefore(dayjs());
    if (isPast) {
      return false;
    }
    // const isFuture = dayjs(date).isAfter(dayjs());

    const dayOfWeek = DaysOfWeek[dayjs(date).day()];
    const filtered = providerSchedule?.availability?.find(
      dow => dow?.day?.toUpperCase() === dayOfWeek?.toUpperCase(),
    );

    if (!filtered || (filtered && !filtered.active)) {
      return false;
    }

    return true;
  };

  const onChangeView = view => () => {
    calendarRef.current?.getApi().changeView(view);
    setCurrentView(view);
  };

  const onClickEvent = items => () => {
    if (onClickAppointment) {
      onClickAppointment(items);
    }
  };

  const handleDateClick = e => {
    if (onDateClick && isAvailableDate(e.date)) {
      onDateClick(dayjs(e.dateStr).toISOString());
    }
  };

  const renderDayHeader = ({ date }) => {
    const day = dayjs(date);
    return (
      <div className={classes.dayHeadText}>
        <div className={classes.dayHeadDay}>{day.format('DD')}</div>
        <div className={classes.dayHeadMonth}>{day.format('MMMM')}</div>
      </div>
    );
  };

  const renderDayCell = ({ date, dayNumberText }) => {
    const isFirstMonthDay = new Date(date).getDate() === 1;
    return (
      <div className={classes.dayCellText}>
        {isFirstMonthDay ? dayjs(date).format('MMM D') : dayNumberText}
      </div>
    );
  };

  const formatTime = (time, formatStr = 'h:mm a') => {
    return dayjs(time).format(formatStr);
  };

  const renderBtn = (label, view) => {
    return (
      <Button
        className={cx(classes.btn, { [classes.btnActive]: currentView === view })}
        onClick={onChangeView(view)}
      >
        {label}
      </Button>
    );
  };

  const getEventClass = (status, isRequireSignOff) => {
    if (status === AppointmentStatus.FULFILLED) {
      return classes.eventCompleted;
    }
    if (status === AppointmentStatus.NO_SHOW) {
      return classes.eventNoShow;
    }
    if (status === AppointmentStatus.CANCELLED) {
      return classes.eventCanceled;
    }
    if (isRequireSignOff && status !== AppointmentStatus.BOOKED) {
      return classes.eventWaiting;
    }
    if (status === AppointmentStatus.NEEDS_ACTION) {
      return classes.eventRequested;
    }
    if (status === AppointmentStatus.PROPOSED) {
      return classes.eventRequested;
    }
    return classes.eventBooked;
  };

  const renderEventContent = event => {
    const {
      event: {
        title,
        start,
        end,
        extendedProps: { items, status, name, isRequireSignOff },
      },
    } = event;

    return (
      <div
        onClick={onClickEvent(items)}
        className={cx(classes.event, getEventClass(status, isRequireSignOff))}
      >
        <div className={classes.eventContent}>
          <div className={classes.eventTitle} title={title}>
            {title}
          </div>
          {currentView === CalendarView.Month && (
            <>
              {items.length === 1 && <div className={classes.eventTitle}>{name}</div>}
              <div className={classes.eventTime}>{`${formatTime(start)} ${
                items.length > 1 ? `- ${formatTime(end)}` : ''
              }`}</div>
            </>
          )}
        </div>
      </div>
    );
  };

  const generateDayCellClassNames = arg => {
    const classNames = [classes.dayCell];

    if (isAvailableDate(arg.date)) {
      classNames.push('day-available');
    } else {
      classNames.push('day-unavailable');
    }

    return classNames;
  };

  return (
    <Paper className={cx(classes.calendarView, className)}>
      {isMultipleView && (
        <ButtonGroup className={classes.btnGroup}>
          {renderBtn('Month', CalendarView.Month)}
          {renderBtn('Week', CalendarView.Week)}
          {renderBtn('Day', CalendarView.Day)}
        </ButtonGroup>
      )}
      <FullCalendar
        plugins={[interactionPlugin, dayGridPlugin, timeGridPlugin]}
        initialView={currentView}
        ref={calendarRef}
        dayHeaderClassNames={classes.dayHead}
        dayHeaders={currentView !== CalendarView.Day}
        dayCellClassNames={arg => generateDayCellClassNames(arg)}
        dayHeaderContent={currentView === CalendarView.Week ? renderDayHeader : undefined}
        headerToolbar={isMultipleView ? calendarToolbarMultipleView : calendarToolbar}
        events={events}
        // timeZone="America/New_York"
        dayCellContent={renderDayCell}
        eventContent={renderEventContent}
        slotDuration="01:00:00"
        dateClick={handleDateClick}
        allDaySlot={false}
        businessHours={false}
        {...rest}
      />
    </Paper>
  );
};

export default Calendar;
