import {
  Badge,
  badgeSize,
  badgeStyle,
  badgeType,
  colors,
  iconBtnPosition,
  IconButton,
  Icons,
} from '../../../../../packages';
import { Box } from '@mui/material';
import Typography from '../../../../../components/v2/Typography';
import { typography } from '../../../../../components/v2/Typography/index.constant';
import cx from 'clsx';
import React from 'react';
import { useStyles } from '../../index.styles';
import { removeIndex, shouldRenderKey } from '../common.utils';
import { CardStatus } from '../card/profile-section-card';
import { fieldVisibilityControl, showFieldsOnNoValueKeys } from '../../caqh-information/index.constants';

const FormListItems = ({ list, onClickViewInfo, filesDataList, record }) => {
  const classes = useStyles({});

  const renderValue = (item, isDiscrepant = false) => {
    const { key, value } = item;
    return (
      <Typography
        {...typography.body.s.medium}
        color={
          value === 'Missing' || isDiscrepant
            ? colors.destructive600
            : value === ''
            ? colors.neutral500
            : colors.neutral900
        }
        className={classes.valueContainer}
      >
        {!value || value === ''
          ? 'None'
          : key === 'ssn'
          ? `•••••${value.replaceAll('-', '').substring(5)}`
          : value}
      </Typography>
    );
  };

  const renderBadge = badge =>
    badge.label !== '' ? (
      <Badge
        variant={badgeType.OUTLINED}
        style={badge.style === 'ACTIVE' ? badgeStyle.ACTIVE : badgeStyle.CRITICAL}
        size={badgeSize.XSMALL}
        className={cx({
          [classes.activeBadge]: badge.style === 'ACTIVE',
          [classes.criticalBadge]: badge.style === 'CRITICAL',
        })}
      >
        {badge.label ? 'Yes' : 'No'}
      </Badge>
    ) : (
      renderValue({ value: '', key: '' })
    );

  const shouldRenderField = key => {
    let renderField = true;
    const controlKey = fieldVisibilityControl[removeIndex(key)];
    if (controlKey) {
      if (showFieldsOnNoValueKeys.includes(controlKey)) {
        renderField = list.find(item => item.key === controlKey?.replaceAll('.', '-'))?.badge.label === false;
      } else {
        renderField = list.find(item => item.key === controlKey?.replaceAll('.', '-'))?.badge.label === true;
      }
    }
    return renderField;
  };

  const renderContent = (item, isDiscrepant) => {
    if (item.badge) {
      return renderBadge(item.badge);
    }

    return renderValue(item, isDiscrepant);
  };
  const renderList = record?.documentStatus === CardStatus.DISCREPANCY ? filesDataList?.list : list;
  const filteredList = renderList?.filter(item => item.type !== 'add' && shouldRenderKey(item.key));
  return (
    <Box
      p={3}
      sx={{
        borderTop: `1px solid ${colors.neutral100}`,
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
      }}
    >
      {filteredList?.map((item, index) => {
        const mainDataItem = list?.find(dataItem => dataItem.key === item.key);
        const isDiscrepant =
          record?.issues?.some(key => key === item.key?.replaceAll('-', '.')) &&
          record?.documentStatus === CardStatus.DISCREPANCY;
        return (
          <React.Fragment key={index}>
            {item?.type === 'nested' ? (
              <Box
                key={index}
                sx={{
                  paddingBottom: index === filteredList.length - 1 ? '8px' : '24px',
                  paddingTop: index !== 0 ? '8px' : '0px',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  borderBottom: index === filteredList.length - 1 ? '' : `1px solid ${colors.neutral100}`,
                }}
              >
                <Typography {...typography.body.s.medium}>{item.label || 'Unknown practice name'}</Typography>
                <IconButton
                  icon="arrow-right"
                  iconPosition={iconBtnPosition.RIGHT}
                  onClick={() => onClickViewInfo(item.value)}
                >
                  <Typography {...typography.body.s.bold} mr={1}>
                    View info
                  </Typography>
                </IconButton>
              </Box>
            ) : shouldRenderField(item.key) ? (
              <Box
                key={item.key}
                sx={{
                  display: 'flex',
                  gap: 2,
                  alignItems: 'flex-start',
                  width: '100%',
                  flexDirection: 'row',
                }}
              >
                {item.section ? (
                  <Typography key={index} {...typography.overline.small} color={colors.neutral500}>
                    {item.label}
                  </Typography>
                ) : (
                  item.key && (
                    <>
                      <Typography {...typography.body.s.medium} color={colors.neutral700}>
                        {item.label}
                      </Typography>
                      <Typography
                        sx={{
                          display: 'flex',
                          flex: 1,
                          height: 20,
                          minWidth: 50,
                          backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='20' width='12'%3E%3Ccircle cx='10' cy='10' r='1' fill='%23bec9d3' /%3E%3C/svg%3E ")`,
                        }}
                        {...typography.body.s.medium}
                      />
                      {renderContent(item, isDiscrepant)}
                    </>
                  )
                )}
              </Box>
            ) : (
              <></>
            )}
            {isDiscrepant && (
              <Box
                key={index}
                sx={{
                  display: 'flex',
                  gap: 2,
                  alignItems: 'center',
                  width: '100%',
                  flexDirection: 'row',
                }}
              >
                <Box
                  className={classes.discrepancyMessage}
                  sx={{ display: 'flex', alignItems: 'center', paddingLeft: '16px', marginLeft: '16px' }}
                >
                  <Typography
                    {...typography.body.s.medium}
                    color={colors.neutral700}
                    sx={{ display: 'flex', alignItems: 'center' }}
                  >
                    <Box sx={{ minWidth: '20px', mr: '8px' }}>
                      <Icons
                        glyph="info-outlined"
                        color={colors.destructive500}
                        className={classes.inlineIcon}
                      />
                    </Box>
                    {mainDataItem.label} - CAQH
                  </Typography>
                </Box>
                <Typography
                  sx={{
                    display: 'flex',
                    flex: 1,
                    height: 20,
                    minWidth: 50,
                    backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='20' width='12'%3E%3Ccircle cx='10' cy='10' r='1' fill='%23bec9d3' /%3E%3C/svg%3E ")`,
                  }}
                  {...typography.body.s.medium}
                />
                {renderValue(mainDataItem)}
              </Box>
            )}
          </React.Fragment>
        );
      })}
    </Box>
  );
};

export default FormListItems;
