/* eslint-disable max-len */
import cx from 'clsx';

// types
// styles
import { useStyles } from './shared/styles';

const ChevronRight = ({ className = '', color, onClick = undefined }) => {
  const classes = useStyles();
  return (
    <svg
      className={cx({
        [classes.root]: true,
        [className || '']: className,
      })}
      width="7"
      height="12"
      viewBox="0 0 7 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M6.78 5.27997C6.92069 5.42049 6.99983 5.61113 7 5.80997V6.18997C6.9977 6.38839 6.91888 6.57825 6.78 6.71997L1.64 11.85C1.59352 11.8968 1.53822 11.934 1.47729 11.9594C1.41636 11.9848 1.35101 11.9979 1.285 11.9979C1.21899 11.9979 1.15364 11.9848 1.09271 11.9594C1.03178 11.934 0.976483 11.8968 0.930001 11.85L0.220001 11.14C0.173437 11.0944 0.136445 11.0399 0.111191 10.9798C0.085937 10.9197 0.0729294 10.8552 0.0729294 10.79C0.0729294 10.7248 0.085937 10.6603 0.111191 10.6002C0.136445 10.5401 0.173437 10.4856 0.220001 10.44L4.67 5.99997L0.220001 1.55997C0.173137 1.51349 0.13594 1.45819 0.110556 1.39726C0.0851713 1.33633 0.0721016 1.27098 0.0721016 1.20497C0.0721016 1.13897 0.0851713 1.07362 0.110556 1.01269C0.13594 0.951756 0.173137 0.896456 0.220001 0.849974L0.930001 0.149974C0.976483 0.10311 1.03178 0.0659131 1.09271 0.0405288C1.15364 0.0151444 1.21899 0.0020752 1.285 0.0020752C1.35101 0.0020752 1.41636 0.0151444 1.47729 0.0405288C1.53822 0.0659131 1.59352 0.10311 1.64 0.149974L6.78 5.27997Z"
        fill={color}
      />
    </svg>
  );
};

export default ChevronRight;
