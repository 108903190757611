/* eslint-disable max-len */
import cx from 'clsx';

// types
// styles
import { useStyles } from './shared/styles';

const GoalCircle = ({ className, color, onClick, ...rest }) => {
  const classes = useStyles();
  return (
    <svg
      className={cx(classes.root, className)}
      onClick={onClick}
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M6.99998 12.333C8.41447 12.333 9.77102 11.7711 10.7712 10.7709C11.7714 9.77072 12.3333 8.41416 12.3333 6.99967C12.3333 5.58519 11.7714 4.22863 10.7712 3.22844C9.77102 2.22824 8.41447 1.66634 6.99998 1.66634C5.58549 1.66634 4.22894 2.22824 3.22874 3.22844C2.22855 4.22863 1.66665 5.58519 1.66665 6.99967C1.66665 8.41416 2.22855 9.77072 3.22874 10.7709C4.22894 11.7711 5.58549 12.333 6.99998 12.333ZM6.99998 13.6663C3.31798 13.6663 0.333313 10.6817 0.333313 6.99967C0.333313 3.31767 3.31798 0.333008 6.99998 0.333008C10.682 0.333008 13.6666 3.31767 13.6666 6.99967C13.6666 10.6817 10.682 13.6663 6.99998 13.6663ZM6.99998 9.66634C7.70722 9.66634 8.3855 9.38539 8.8856 8.88529C9.3857 8.3852 9.66665 7.70692 9.66665 6.99967C9.66665 6.29243 9.3857 5.61415 8.8856 5.11406C8.3855 4.61396 7.70722 4.33301 6.99998 4.33301C6.29274 4.33301 5.61446 4.61396 5.11436 5.11406C4.61426 5.61415 4.33331 6.29243 4.33331 6.99967C4.33331 7.70692 4.61426 8.3852 5.11436 8.88529C5.61446 9.38539 6.29274 9.66634 6.99998 9.66634ZM6.99998 10.9997C5.93911 10.9997 4.9217 10.5782 4.17155 9.8281C3.42141 9.07796 2.99998 8.06054 2.99998 6.99967C2.99998 5.93881 3.42141 4.92139 4.17155 4.17125C4.9217 3.4211 5.93911 2.99967 6.99998 2.99967C8.06085 2.99967 9.07826 3.4211 9.82841 4.17125C10.5786 4.92139 11 5.93881 11 6.99967C11 8.06054 10.5786 9.07796 9.82841 9.8281C9.07826 10.5782 8.06085 10.9997 6.99998 10.9997ZM6.99998 8.33301C6.64636 8.33301 6.30722 8.19253 6.05717 7.94248C5.80712 7.69243 5.66665 7.3533 5.66665 6.99967C5.66665 6.64605 5.80712 6.30691 6.05717 6.05687C6.30722 5.80682 6.64636 5.66634 6.99998 5.66634C7.3536 5.66634 7.69274 5.80682 7.94279 6.05687C8.19284 6.30691 8.33331 6.64605 8.33331 6.99967C8.33331 7.3533 8.19284 7.69243 7.94279 7.94248C7.69274 8.19253 7.3536 8.33301 6.99998 8.33301Z"
        fill={color}
      />
    </svg>
  );
};

export default GoalCircle;
