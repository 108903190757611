import { Box } from '@mui/material';
import { iconBtnPosition, iconBtnType, IconButton } from '../../../../../packages/ui/molecules/icon-button';

import { useStyles } from './index.styles';

const CardHeader = ({ title, total, action }) => {
  const classes = useStyles();
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        py: 2.5,
        px: 3,
      }}
    >
      <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
        <Box className={classes.title}>{title}</Box>
        {total && <Box className={classes.badge}>{total} total</Box>}
      </Box>
      {action && (
        <Box sx={{ svg: { marginLeft: 1 }, '& button': { fontSize: 14 } }}>
          <IconButton
            onClick={action.onClick}
            icon={action.icon || 'arrow-right'}
            iconPosition={iconBtnPosition.RIGHT}
            variant={iconBtnType.TEXT}
          >
            {action.label}
          </IconButton>
        </Box>
      )}
    </Box>
  );
};

export default CardHeader;
