import { makeStyles } from '@mui/styles';
import { theme } from '../../../packages/theme';
import { colors } from '../../../packages/colors';

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: 56,
    padding: '64px 220px',
    [theme.breakpoints.down(1200)]: {
      padding: '24px 48px',
    },
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
  title: {
    fontWeight: 700,
    fontFamily: 'Source Serif Pro',
    fontSize: 40,
    lineHeight: '48px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 24,
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: 24,
  },
  dayCard: {
    display: 'flex',
    flexDirection: 'column',
    border: `1px solid ${colors.neutral100}`,
    borderRadius: 8,
    background: colors.white,
  },
  dayCardHeader: {
    padding: '26px 20px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: `1px solid ${colors.neutral100}`,
  },
  weekDayTitle: {
    fontWeight: 600,
    fontFamily: 'Manrope',
    fontSize: 22,
    lineHeight: '28px',
    textTransform: 'capitalize',
  },
  dayCardContent: {
    display: 'flex',
    padding: '32px',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      gap: 16,
    },
  },
  dayCardLeft: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    height: 156,
    paddingTop: 6,
    paddingBottom: 6,
    paddingLeft: 40,
    paddingRight: 40,
    '& .emptyLabel': {
      fontWeight: 600,
      fontSize: 16,
      lineHeight: '24px',
      color: colors.neutral900,
      '& .emptyDayLabel': {
        textTransform: 'capitalize',
      },
    },
    '& .emptyContent': {
      marginTop: '8px',
      fontWeight: 500,
      fontSize: 16,
      lineHeight: '24px',
      color: colors.neutral500,
    },
    '& .editBtn': {
      height: 24,
      marginTop: 16,
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  dayCardLeftSecondary: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    paddingTop: 6,
    paddingBottom: 6,
    paddingLeft: 40,
    paddingRight: 40,
  },
  dayCardRight: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
    padding: '16px 32px',
    height: 156,
    width: 256,
    background: colors.neutral50,
    [theme.breakpoints.down('md')]: {
      width: 'auto',
    },
  },
  dayCardRightHead: {
    fontWeight: 800,
    fontSize: 12,
    lineHeight: '20px',
    letterSpacing: '1px',
    color: colors.neutral600,
    textTransform: 'uppercase',
  },
  dayCardRightContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
  },
  icon: {
    display: 'flex',
    justifyContent: 'flex-start',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      justifyContent: 'space-between',
    },
  },
  addTimeSlot: {
    color: colors.primary600,
    border: `1px solid ${colors.primary300}`,
    width: 'calc(100% - 32px)',
    marginTop: 16,
    marginBottom: 16,
    height: 40,
  },
  saveAvailability: {
    height: 48,
    width: 'calc(100% - 32px)',
  },
});
