import React from 'react';
import { Drawer } from '../../../../../packages/ui/organisms/drawer';
import { Box } from '@mui/material';
import {
  btnType,
  Button,
  Checkbox,
  fontWeight,
  Heading,
  headingLevel,
  IconButton,
  Input,
  inputSize,
  Text,
  TextError,
  textLevel,
} from '../../../../../packages';
import { useFormik } from 'formik';
import { AddReferralSchema } from './AddReferralDrawer.schema';
import { useStyles } from './AddReferralDrawer.styles';

const defaultValues = {
  name: '',
  patientDetail: false,
  patientDetailAppointment: false,
  patientDetailDiagnosis: false,
  patientDetailEvaluation: false,
  patientDetailMedication: false,
  patientDetailPhq9Gad7: false,
  patientList: false,
  reportList: false,
};

const AddReferralDrawer = ({ isOpen, onClose, onSubmit, referralPartner, isSaving }) => {
  const classes = useStyles();
  const renderLabel = label => (
    <Heading level={headingLevel.S} className={classes.label} weight={fontWeight.BOLD}>
      {label}
    </Heading>
  );

  const { errors, values, handleChange, setValues, touched, ...rest } = useFormik({
    initialValues: referralPartner ?? defaultValues,
    validationSchema: AddReferralSchema,
    enableReinitialize: true,
    onSubmit,
  });
  const renderCheckbox = (key, label, tabSpaces = 0) => (
    <Text size={textLevel.M} weight={fontWeight.MEDIUM}>
      {tabSpaces > 0 && <span>&emsp;&emsp;</span>}
      {tabSpaces > 1 && <span>&emsp;&emsp;</span>}
      <Checkbox
        checked={values[key]}
        onChange={(_, checked) => {
          void setValues({
            ...values,
            [key]: checked,
          });
        }}
      />{' '}
      {label}
    </Text>
  );
  const setTouched = name => async () => {
    await rest.setTouched({ ...touched, [name]: true });
  };

  return (
    <Drawer open={isOpen} onClose={onClose}>
      <Box className={classes.wrapper}>
        <Box className={classes.header}>
          <Text className={classes.headerTitle}>{`${
            referralPartner ? 'Edit' : 'Add'
          } referral partner`}</Text>
          <IconButton icon="close" onClick={onClose} className={classes.backBtn} />
        </Box>
        <Box className={classes.formContent}>
          <Box className={classes.section}>
            {renderLabel('Name')}
            <Input
              value={values.name}
              name="name"
              placeholder="Enter referral partner name"
              onChange={handleChange}
              size={inputSize.M}
              onBlur={setTouched('name')}
              fullWidth
            />
            <TextError errorMsg={touched.name ? errors.name?.toString() : null} />
          </Box>
          <Box className={classes.section}>
            {renderCheckbox('reportList', 'Report list')}
            {renderCheckbox('patientList', 'Patient list')}
            {values.patientList && renderCheckbox('patientDetail', 'Patient detail', 1)}
            {values.patientDetail && (
              <div className={classes.checkboxes}>
                {renderCheckbox('patientDetailAppointment', 'Appointment', 2)}
                {renderCheckbox('patientDetailDiagnosis', 'Diagnosis', 2)}
                {renderCheckbox('patientDetailEvaluation', 'Evaluation', 2)}
                {renderCheckbox('patientDetailMedication', 'Medication', 2)}
                {renderCheckbox('patientDetailPhq9Gad7', 'Phq9Gad7', 2)}
              </div>
            )}
          </Box>
        </Box>
        <Box className={classes.footer}>
          <Button variant={btnType.TEXT} onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={() => onSubmit(values)}>
            {isSaving ? 'Saving...' : `${referralPartner ? 'Update' : 'Add'}`}
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
};

export default AddReferralDrawer;
