// types

export const DomainPriorities = {
  CRITICAL: 'CRITICAL',
  HIGH: 'HIGH',
  LOW: 'LOW',
  MEDIUM: 'MEDIUM',
  MISREPORTED: 'MISREPORTED',
  NEGATIVE: 'NEGATIVE',
  NEUTRAL: 'NEUTRAL',
  POSITIVE: 'POSITIVE',
  RESOLVED: 'RESOLVED',
  UNRELATED: 'UNRELATED',
};
