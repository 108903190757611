export const selectType = {
  PRIMARY: 'PRIMARY',
  SECONDARY: 'SECONDARY',
  AVATAR: 'AVATAR',
};

export const selectSize = {
  L: 'LARGE',
  M: 'MEDIUM',
  S: 'SMALL',
};
