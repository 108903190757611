/* eslint-disable max-len */
import cx from 'clsx';

// types
// styles
import { useStyles } from './shared/styles';

const Mentions = ({ className, color, onClick }) => {
  const classes = useStyles();
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cx({
        [classes.root]: true,
        [className || '']: className,
      })}
      onClick={onClick}
    >
      <path
        d="M42.6666 24C42.6662 19.8394 41.2757 15.7982 38.7161 12.5181C36.1564 9.23805 32.5743 6.90713 28.5386 5.89552C24.5028 4.88391 20.2448 5.24958 16.4406 6.93448C12.6364 8.61938 9.5042 11.5269 7.54129 15.1954C5.57839 18.8639 4.89733 23.083 5.60628 27.1827C6.31522 31.2825 8.37352 35.0279 11.4543 37.8242C14.5352 40.6205 18.4619 42.3073 22.6109 42.6169C26.76 42.9265 30.8936 41.8411 34.3553 39.533L36.9453 43.4157C33.1144 45.977 28.6082 47.3407 24 47.3333C11.113 47.3333 0.666626 36.887 0.666626 24C0.666626 11.113 11.113 0.666672 24 0.666672C36.887 0.666672 47.3333 11.113 47.3333 24V27.5C47.3335 29.2471 46.7735 30.9482 45.7355 32.3535C44.6974 33.7587 43.2361 34.7941 41.5661 35.3074C39.8961 35.8207 38.1055 35.7849 36.4574 35.2054C34.8092 34.6258 33.3904 33.5329 32.4093 32.0873C30.852 33.7067 28.8602 34.8419 26.6733 35.3566C24.4864 35.8714 22.1974 35.7437 20.0813 34.989C17.9652 34.2343 16.112 32.8847 14.7443 31.1022C13.3767 29.3198 12.5528 27.1804 12.3717 24.941C12.1905 22.7017 12.6598 20.4577 13.7231 18.4786C14.7864 16.4995 16.3985 14.8695 18.3658 13.7844C20.3331 12.6994 22.5718 12.2055 24.813 12.362C27.0542 12.5185 29.2026 13.3187 31 14.6667H35.6666V27.5C35.6666 28.4283 36.0354 29.3185 36.6918 29.9749C37.3481 30.6313 38.2384 31 39.1666 31C40.0949 31 40.9851 30.6313 41.6415 29.9749C42.2979 29.3185 42.6666 28.4283 42.6666 27.5V24ZM24 17C22.1434 17 20.363 17.7375 19.0502 19.0503C17.7375 20.363 17 22.1435 17 24C17 25.8565 17.7375 27.637 19.0502 28.9498C20.363 30.2625 22.1434 31 24 31C25.8565 31 27.637 30.2625 28.9497 28.9498C30.2625 27.637 31 25.8565 31 24C31 22.1435 30.2625 20.363 28.9497 19.0503C27.637 17.7375 25.8565 17 24 17Z"
        fill={color}
      />
    </svg>
  );
};

export default Mentions;
