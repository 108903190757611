import * as React from 'react';
import { useEffect } from 'react';
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { LocalizationProvider, PickersDay, StaticDatePicker } from '@mui/x-date-pickers-pro';
import cx from 'clsx';
import { weekDayNames } from './Calendar.constant';
import { useStyles } from './Calendar.styles';

dayjs.extend(isToday);

const Calendar = ({ scheduledDays, value, onChange, isRecurring = false }) => {
  const classes = useStyles();

  const today = dayjs();
  const [dateValue, setDateValue] = React.useState(value || today);

  useEffect(() => {
    if (value && value !== dateValue) {
      setDateValue(value);
    }
  }, [value]);

  function Day(props) {
    const { day } = props;
    // const twoWeeksLater = dateValue?.add(14, 'day');
    if (isRecurring) {
      let isAvailable = false;
      scheduledDays?.forEach(dateObject => {
        if (day.isSame(dateObject.date, 'day')) {
          isAvailable = dateObject.isAvailable ?? false;
        }
      });
      const isValidDate = day.isAfter(dayjs(), 'day') || day.isSame(dayjs(), 'day');
      return (
        <PickersDay
          {...props}
          disabled={!isValidDate}
          className={cx({
            [classes.isValidDate]: isValidDate,
            [classes.cashSlot]: isAvailable,
          })}
        />
      );
    }
    let isAvailable = false;
    let isInsurance = false;
    scheduledDays?.forEach(dateObject => {
      if (day.isSame(dateObject.date, 'day')) {
        isAvailable = true;
        isInsurance = dateObject.availableForInsurance;
      }
    });
    return (
      <PickersDay
        {...props}
        disabled={!isAvailable}
        className={cx({
          [classes.cashSlot]: isAvailable && !isInsurance,
          [classes.insuranceSlot]: isAvailable && isInsurance,
        })}
      />
    );
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <StaticDatePicker
        className={classes.staticCalendar}
        value={dateValue}
        onChange={newValue => {
          setDateValue(newValue);
          newValue && onChange && onChange(newValue || today);
        }}
        displayStaticWrapperAs="desktop"
        slots={{ day: Day }}
        dayOfWeekFormatter={day => weekDayNames[day]}
        onMonthChange={newValue => {
          setDateValue(newValue);
          newValue && onChange && onChange(newValue || today);
        }}
        disableHighlightToday
        disablePast
      />
    </LocalizationProvider>
  );
};

export { Calendar };
