import { colors } from '../../../../../packages';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  card: {
    position: 'relative',
    display: 'flex',
    width: '100%',
    gap: 16,
    flexWrap: 'wrap',
    padding: '32px 66px',
    borderRadius: 8,
    backgroundColor: colors.white,
    boxShadow:
      '0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
  },
  chip: {
    display: 'flex',
    alignItems: 'center',
    padding: '8px 12px',
    gap: 12,
    background: colors.neutral50,
    border: `1px solid ${colors.neutral100}`,
    borderRadius: 8,
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '20px',
    color: colors.neutral900,
  },
  badge: {
    display: 'flex',
    alignItems: 'center',
    padding: '0px 8px',
    gap: 4,
    background: colors.white,
    border: `1px solid ${colors.neutral200}`,
    borderRadius: 100,
  },
});
