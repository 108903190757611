// import { makeStyles } from '@mui/styles';

// // colors
// import { colors } from '../../../colors';

// // types
// import { selectType } from './Select.types';

// interface StyleProps {
//   variant?: selectType;
// }

// const sx = {
//   icon: {
//     [selectType.PRIMARY]: {
//       width: 24,
//       height: 24,
//       right: 10,
//     },
//     [selectType.SECONDARY]: {
//       width: 24,
//       height: 24,
//       right: 12,
//     },
//   },
//   item: {
//     [selectType.PRIMARY]: {
//       fontWeight: 600,
//       fontSize: 14,
//       color: colors.textPrimary,
//     },
//     [selectType.SECONDARY]: {
//       paddingLeft: 24,
//       fontWeight: 500,
//       fontSize: 16,
//       color: colors.textHighContrast,
//     },
//   },
// };

// export const useStyles = makeStyles({
//   root: {
//     display: 'inline-flex',
//     '& > .MuiInputBase-input': {
//       marginRight: 8,
//     },
//   },
//   rootSmall: {
//     height: 48,
//     '& > .MuiInputBase-input': {
//       height: '48px !important',
//       fontFamily: 'Manrope',
//       fontWeight: 500,
//       fontSize: '14px !important',
//       lineHeight: '20px',
//       color: colors.neutral900,
//       boxSizing: 'border-box',
//       display: 'flex',
//       alignItems: 'center',
//       paddingTop: '14px',
//       paddingBottom: '14px',
//       paddingLeft: '16px !important',
//       marginLeft: 26,
//       alignSelf: 'center',
//     },
//   },
//   rootMedium: {
//     height: 64,
//     '& > .MuiInputBase-input': {
//       height: '48px !important',
//       fontFamily: 'Manrope',
//       fontWeight: 500,
//       fontSize: '16px !important',
//       lineHeight: '24px',
//       color: colors.neutral900,
//       boxSizing: 'border-box',
//       display: 'flex',
//       alignItems: 'center',
//       paddingTop: '14px',
//       paddingBottom: '14px',
//       paddingLeft: '16px !important',
//     },
//   },
//   rootLarge: {
//     '& > .MuiInputBase-input': {
//       fontFamily: 'Manrope',
//       fontWeight: 500,
//       fontSize: '18px !important',
//       lineHeight: '24px',
//       color: colors.neutral900,
//       boxSizing: 'border-box',
//       display: 'flex',
//       alignItems: 'center',
//     },
//   },
//   primary: {
//     '& > .MuiInputBase-input': {
//       height: 'auto',
//       fontFamily: 'Manrope',
//       fontWeight: 700,
//       fontSize: 14,
//       lineHeight: 1,
//       color: colors.textPrimary,
//       boxSizing: 'border-box',
//       display: 'flex',
//       alignItems: 'center',
//     },
//     '& > fieldset': {
//       border: 'none',
//     },
//   },
//   secondary: {
//     '& > .MuiInputBase-input': {
//       height: 72,
//       paddingLeft: 24,
//       paddingRight: '48px !important',
//       fontFamily: 'Manrope',
//       fontWeight: 500,
//       fontSize: 16,
//       color: colors.neutral900,
//       boxSizing: 'border-box',
//       display: 'flex',
//       alignItems: 'center',
//       '& > .MuiSelect-select': {
//         padding: 14,
//       },
//     },
//     '& > fieldset': {
//       border: `1px solid ${colors.inputBorder}`,
//       borderRadius: 4,
//     },
//     '&:hover, &.Mui-focused': {
//       '& > fieldset': {
//         borderColor: `${colors.primary} !important`,
//         borderWidth: '1px !important',
//       },
//     },
//     '&.Mui-focused': {
//       '& > svg path': {
//         fill: colors.primary,
//       },
//     },
//   },
//   selectDropwdown: {
//     '& > .MuiInputBase-input': {
//       paddingLeft: '36px !important',
//     },
//   },
//   arrowDropdownIcon: ({ variant }: StyleProps) => ({
//     ...sx.icon[variant],
//     position: 'absolute',
//     top: '50%',
//     transition: '0.4s',
//     height: 24,
//     width: 24,
//     transform: 'translateY(-50%)',
//     '&.MuiSelect-iconOpen': {
//       transform: 'translateY(-50%) rotate(180deg)',
//     },
//   }),
//   item: ({ variant }: StyleProps) => ({
//     ...sx.item[variant],
//     fontFamily: 'Manrope',
//   }),
//   avatarContainer: {
//     marginRight: '8px',
//     marginLeft: '-8px',
//   },
//   unreadMessageBadge: {
//     background: colors.secondary500,
//     borderColor: colors.secondary500,
//     position: 'relative',
//     color: colors.white,
//     // right: 30,
//     // height: 18,
//     fontSize: 12,
//     // paddingLeft: 8,
//     // paddingRight: 8,
//     // borderRadius: 99,
//     width: '1.125rem',
//     height: '1.125rem',
//     borderRadius: '50%',
//     padding: 0,
//   },
//   labelCountWrapper: {
//     width: '100%',
//     display: 'flex',
//     flexDirection: 'row',
//     gap: 8,
//     alignItems: 'center',
//   },
// });

import { makeStyles } from '@mui/styles';

// colors
import { colors } from '../../../colors';

const sx = {
  icon: {
    PRIMARY: {
      width: 24,
      height: 24,
      right: 10,
    },
    SECONDARY: {
      width: 24,
      height: 24,
      right: 12,
    },
  },
  item: {
    PRIMARY: {
      fontWeight: 600,
      fontSize: 14,
      color: colors.textPrimary,
    },
    SECONDARY: {
      paddingLeft: 24,
      fontWeight: 500,
      fontSize: 16,
      color: colors.textHighContrast,
    },
  },
};

export const useStyles = makeStyles({
  root: {
    display: 'inline-flex',
  },
  rootSmall: {
    height: 48,
    '& > .MuiInputBase-input': {
      height: '48px !important',
      fontFamily: 'Manrope',
      fontWeight: 500,
      fontSize: '14px !important',
      lineHeight: '20px',
      color: colors.neutral900,
      boxSizing: 'border-box',
      display: 'flex',
      alignItems: 'center',
      paddingTop: '14px',
      paddingBottom: '14px',
      paddingLeft: '16px !important',
      alignSelf: 'center',
    },
  },
  rootMedium: {
    height: 64,
    '& > .MuiInputBase-input': {
      height: '48px !important',
      fontFamily: 'Manrope',
      fontWeight: 500,
      fontSize: '16px !important',
      lineHeight: '24px',
      color: colors.neutral900,
      boxSizing: 'border-box',
      display: 'flex',
      alignItems: 'center',
      paddingTop: '14px',
      paddingBottom: '14px',
      paddingLeft: '16px !important',
    },
  },
  rootLarge: {
    '& > .MuiInputBase-input': {
      fontFamily: 'Manrope',
      fontWeight: 500,
      fontSize: '18px !important',
      lineHeight: '24px',
      color: colors.neutral900,
      boxSizing: 'border-box',
      display: 'flex',
      alignItems: 'center',
    },
  },
  primary: {
    '& > .MuiInputBase-input': {
      height: 'auto',
      fontFamily: 'Manrope',
      fontWeight: 700,
      fontSize: 14,
      lineHeight: 1,
      color: colors.textPrimary,
      boxSizing: 'border-box',
      display: 'flex',
      alignItems: 'center',
    },
    '& > fieldset': {
      border: 'none',
    },
  },
  secondary: {
    '& > .MuiInputBase-input': {
      height: 72,
      paddingLeft: 0,
      paddingRight: '48px !important',
      fontFamily: 'Manrope',
      fontWeight: 500,
      fontSize: 16,
      color: colors.neutral900,
      boxSizing: 'border-box',
      display: 'flex',
      alignItems: 'center',
      '& > .MuiSelect-select': {
        padding: 14,
      },
    },
    '& > fieldset': {
      border: `1px solid ${colors.inputBorder}`,
      borderRadius: 4,
    },
    '&:hover, &.Mui-focused': {
      '& > fieldset': {
        borderColor: `${colors.primary} !important`,
        borderWidth: '1px !important',
      },
    },
    '&.Mui-focused': {
      '& > svg path': {
        fill: colors.primary,
      },
    },
  },
  selectDropwdown: {
    '& > .MuiInputBase-input': {
      paddingLeft: '36px !important',
    },
  },
  arrowDropdownIcon: ({ variant }) => ({
    ...sx.icon[variant],
    position: 'absolute',
    top: '50%',
    transition: '0.4s',
    height: 24,
    width: 24,
    transform: 'translateY(-50%)',
    '&.MuiSelect-iconOpen': {
      transform: 'translateY(-50%) rotate(180deg)',
    },
  }),
  item: ({ variant }) => ({
    ...sx.item[variant],
    fontFamily: 'Manrope',
  }),
  avatarContainer: {
    marginRight: '8px',
    marginLeft: '-8px',
  },
  unreadMessageBadge: {
    background: colors.secondary500,
    borderColor: colors.secondary500,
    position: 'relative',
    color: colors.white,
    width: '1.125rem',
    height: '1.125rem',
    borderRadius: '50%',
    padding: 0,
  },
  labelCountWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    gap: 8,
    alignItems: 'center',
  },
});
