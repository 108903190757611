import dayjs from 'dayjs';

export const dctColumns = [
  { id: 'name', label: 'Name', sortable: false },
  { id: 'version', label: 'Version', sortable: false },
  { id: 'mostUpdated', label: 'Most updated', sortable: false },
  { id: 'completeProgress', label: 'Completions', sortable: false },
  { id: 'scoreRange', label: 'Score range', sortable: false },
  { id: 'actions', label: '', sortable: false },
];

export const filterOptionsMock = [
  { label: 'Scoreable', value: 'scoreable' },
  { label: 'Not scoreable', value: 'not_scoreable' },
];

export const dctListMock = [
  {
    id: '1',
    name: 'DCT name',
    version: 'v2.0',
    mostVersion: 'v1.1',
    versionUpdatedDate: dayjs().subtract(1, 'd').toISOString(),
    lastUpdated: dayjs().subtract(1, 'd').toISOString(),
    completeProgress: 30,
    scoreRange: '10-20',
    isActive: true,
  },
  {
    id: '2',
    name: 'DCT name',
    version: 'v2.0',
    mostVersion: 'v1.1',
    dct: 8,
    assignableBy: ['P', 'M'],
    versionUpdatedDate: dayjs().subtract(1, 'd').toISOString(),
    lastUpdated: dayjs().subtract(1, 'd').toISOString(),
    assignments: 8,
    completeProgress: 50,
    scoreRange: null,
    isActive: true,
  },
  {
    id: '3',
    name: 'DCT name',
    version: 'v2.0',
    mostVersion: 'v1.1',
    dct: 4,
    versionUpdatedDate: dayjs().subtract(1, 'd').toISOString(),
    lastUpdated: dayjs().subtract(1, 'd').toISOString(),
    completeProgress: 30,
    scoreRange: null,
    isActive: false,
  },
  {
    id: '4',
    name: 'DCT name',
    version: 'v2.0',
    mostVersion: 'v1.1',
    dct: 5,
    versionUpdatedDate: dayjs().subtract(1, 'd').toISOString(),
    lastUpdated: dayjs().subtract(1, 'd').toISOString(),
    completeProgress: 10,
    scoreRange: '10-20',
    isActive: true,
  },
  {
    id: '5',
    name: 'DCT name',
    version: 'v2.0',
    mostVersion: 'v1.1',
    versionUpdatedDate: dayjs().subtract(1, 'd').toISOString(),
    lastUpdated: dayjs().subtract(1, 'd').toISOString(),
    assignments: 12,
    completeProgress: 30,
    scoreRange: '10-20',
  },
  {
    id: '6',
    name: 'DCT name',
    version: 'v2.0',
    mostVersion: 'v1.1',
    versionUpdatedDate: dayjs().subtract(1, 'd').toISOString(),
    lastUpdated: dayjs().subtract(1, 'd').toISOString(),
    completeProgress: 90,
    scoreRange: '10-20',
  },
];
