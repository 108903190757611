import { useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import clsx from 'clsx';
import { ButtonGroup, Stack } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import {
  Badge,
  badgeStyle,
  badgeType,
  Button,
  fontWeight,
  Heading,
  headingLevel,
  iconBtnType,
  IconButton,
  Link,
  Table,
  Text,
} from '../../../packages';
import { ProgressBar } from '../../../packages/ui/atoms/ProgressBar';
import history from '../../../utils/history';

// services
import { addConversation, updateConversation } from '../../../services/conversation/conversation.service';
import { conversationActionCreators } from '../../../redux/modules/conversation';
import { selectConversationState } from '../../../redux/modules/conversation/selectors';

// constants
import {
  CONVERSATION_TYPES,
  ConversationPriority,
  GLOBAL_DATE_FORMAT,
} from '../../../constants/CommonConstants';

// components
import { BaseLayout } from '../../../layouts/base';
import AddConversation from './components/add-conversation';

// mock
import { conversationColumns, viewGroups } from './Conversations.mock';

// styles
import { useStyles } from './Conversations.styles';

const Conversations = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [tableParams, setTableParams] = useState({
    search: { searchKey: '' },
    pagination: { currentPage: 1, rowsPerPage: 10 },
    sorter: { direction: 'asc', column: '' },
  });
  const [viewBy, setViewBy] = useState(viewGroups[0].type);
  const [selectedConversation, setSelectedConversation] = useState(null);
  const [openConversationDrawer, setOpenConversationDrawer] = useState(false);
  const { conversations, totalRecords, isLoading } = useSelector(selectConversationState);

  const onAddClick = () => {
    setSelectedConversation(null);
    setOpenConversationDrawer(true);
  };

  /**
   * @Name onChangeView
   * @description This method is used to switch between active and draft state
   */
  const onChangeView = type => () => {
    setViewBy(type);
    setTableParams({
      search: { searchKey: '' },
      pagination: { currentPage: 1, rowsPerPage: 10 },
      sorter: { direction: 'asc', column: '' },
    });
  };

  /**
   * @Name formatList
   * @description
   */
  const formatConversationList = list => {
    return list.map(({ lastUpdated, assignments, name, ...item }) => ({
      ...item,
      name: { conversationId: item.id, name, version: item.version },
      lastUpdated: dayjs(lastUpdated).format(GLOBAL_DATE_FORMAT),
      assignments: `${assignments} ${assignments > 1 ? 'Assignments' : 'Assignment'}`,
      id: { conversation: item },
    }));
  };
  const conversationList = useMemo(() => {
    return formatConversationList(conversations);
  }, [conversations]);
  /**
   * @Name getConversations
   * @description This method is used get conversation list from api
   */
  const getConversations = () => {
    const { pagination, search, sorter } = tableParams;
    const queryParams = {
      orderBy: sorter.direction,
      pageNumber: pagination.currentPage,
      pageSize: pagination.rowsPerPage,
      searchQuery: search.searchKey,
      sortBy: [sorter.column],
      status: viewBy,
      reportingView: false,
    };
    dispatch(conversationActionCreators.fetchConversations(queryParams));
  };

  /**
   * @Name onAddEditConversation
   * @description this use effect is use to add or update any coversation
   */
  const onAddEditConversation = data => {
    if (selectedConversation) {
      const { conversationId } = selectedConversation;
      const payload = {
        ...data,
        assignableBy: {
          providerAssignable: data.providerAssignable,
          selfAssignable: data.selfAssignable,
          systemAssignable: data.systemAssignable,
        },
        defaultPriority: ConversationPriority.LOW,
        reorder: false,
        selfContained: true,
        contentBlock: [],
        avatar: data.avatar,
        whoCanBenefit: data.benefits,
        tags: data.tags,
      };
      delete payload.providerAssignable;
      delete payload.systemAssignable;
      delete payload.selfAssignable;
      delete payload.benefits;

      updateConversation(payload, { conversationId })
        .then(res => {
          if (res.status === 200) {
            getConversations();
          }
        })
        .catch(err => console.log('err', err));
    } else {
      const payload = {
        ...data,
        assignableBy: {
          providerAssignable: data.providerAssignable,
          selfAssignable: data.selfAssignable,
          systemAssignable: data.systemAssignable,
        },
        defaultPriority: ConversationPriority.LOW,
        reorder: false,
        selfContained: true,
        contentBlock: [],
        whoCanBenefit: data.benefits,
        tags: data.tags,
      };
      delete payload.providerAssignable;
      delete payload.systemAssignable;
      delete payload.selfAssignable;
      delete payload.benefits;

      addConversation(payload)
        .then(resonse => {
          if (resonse.status === 200) {
            getConversations();
          }
        })
        .catch(err => console.log(err));
    }
  };

  /**
   * @function onRowClick
   * @params rowItem
   * @description This method is used to redirect to DataCollectionTemplateDetails page with Selected DCT's Id.
   */
  const onRowClick = rowItem => {
    history.push(
      `/admin/collection-conversations/${rowItem.conversationId}/${rowItem.version}?name=assignments`,
      {
        from: CONVERSATION_TYPES.CONVERSATION,
      },
    );
  };

  /**
   * @description this use effect is use to getConversationList
   */
  useEffect(() => {
    getConversations();
  }, [viewBy, tableParams]);

  const renderColumns = conversationColumns.map(column => {
    if (column.id === 'name') {
      return {
        ...column,
        renderCell: ({ conversationId, name, version }) => (
          <Link
            className={classes.link}
            to={`/admin/collection-conversations/${conversationId}/${version}?name=assignments`}
          >
            <Text className={classes.name} weight={fontWeight.MEDIUM}>
              {name}
            </Text>
          </Link>
        ),
      };
    }

    if (column.id === 'dctCount') {
      return {
        ...column,
        renderCell: dctCount => (
          <Text className={classes.name} weight={fontWeight.MEDIUM}>
            {dctCount}
            {dctCount > 1 ? 'DCTs' : 'DCT'}
          </Text>
        ),
      };
    }

    if (column.id === 'assignedTo') {
      return {
        ...column,
        renderCell: assignedTo => (
          <Text className={classes.name} weight={fontWeight.MEDIUM}>
            {assignedTo.length}
          </Text>
        ),
      };
    }
    if (column.id === 'progress') {
      return {
        ...column,
        renderCell: progress => <ProgressBar value={progress} />,
      };
    }

    if (column.id === 'id') {
      return {
        ...column,
        renderCell: ({ conversation }) => (
          <IconButton
            icon="chevron-right"
            className={classes.actBtn}
            onClick={() => {
              const selectedCoversationWithId = conversationList.filter(
                conv => conv.conversationId === conversation.conversationId,
              );
              setSelectedConversation(selectedCoversationWithId[0]);
              setOpenConversationDrawer(true);
            }}
          />
        ),
      };
    }
    return column;
  });

  return (
    <>
      <AddConversation
        isOpen={openConversationDrawer}
        conversation={selectedConversation}
        onClose={() => setOpenConversationDrawer(false)}
        onSubmit={onAddEditConversation}
      />
      <BaseLayout>
        <div className={classes.root}>
          <Stack direction="row" justifyContent="space-between" spacing={2}>
            <Stack direction="row" alignItems="center" gap={2}>
              <Heading className={classes.heading} level={headingLevel.XL} weight={fontWeight.BOLD}>
                Chatbots
              </Heading>
              {!!totalRecords && (
                <Badge
                  className={classes.totalMemberBadge}
                  variant={badgeType.OUTLINED}
                  style={badgeStyle.UNRELATED}
                >
                  {totalRecords} total
                </Badge>
              )}
            </Stack>

            <IconButton
              className={classes.addProfileBtn}
              icon="plus"
              variant={iconBtnType.PRIMARY}
              onClick={onAddClick}
            >
              Add new
            </IconButton>
          </Stack>
          <Table
            searchProps={{
              placeholder: 'Search chatbot by name',
              leftSearchComponent: (
                <ButtonGroup variant="outlined" aria-label="outlined button group">
                  {viewGroups.map(({ type, label }) => (
                    <Button
                      key={type}
                      className={clsx(classes.btnGroupIcon, {
                        [classes.btnGroupIconActive]: type === viewBy,
                      })}
                      onClick={onChangeView(type)}
                    >
                      {label}
                    </Button>
                  ))}
                </ButtonGroup>
              ),
            }}
            gridProps={{
              columns: renderColumns,
              data: conversationList,
              isLoading,
              onRowClick,
            }}
            paginationProps={{
              currentRows: tableParams.pagination.rowsPerPage,
              totalCount: totalRecords,
              showRowsPerPage: true,
            }}
            value={tableParams}
            onChange={setTableParams}
            className={classes.table}
          />
        </div>
      </BaseLayout>
    </>
  );
};

export { Conversations };
