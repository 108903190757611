import { FC } from 'react';
import { Box } from '@mui/material';

import { fontWeight, Heading, headingLevel, Text } from '../../../../../../../packages/ui/atoms/typography';
import { iconBtnType, IconButton } from '../../../../../../../packages/ui/molecules/icon-button';
import { Badge } from '../../../../../../../packages/ui/atoms/badge';
import { useStyles } from './EvaluationReview.styles';

const EvaluationReview = ({ selectedEvaluation, onClickPrevious, onClickComplete }) => {
  const classes = useStyles();
  const renderHeading = (text, dctScore, isDCTScoreable) => {
    return (
      <Box className={classes.heading}>
        <Heading level={headingLevel.S} className={classes.headingTitle} weight={fontWeight.BOLD}>
          {text}
        </Heading>
        {isDCTScoreable && <Badge className={classes.badge}>{`Score ${dctScore}`}</Badge>}
      </Box>
    );
  };

  const bodySection = item => {
    const userResponse = item?.evaluationResponses
      ? item?.evaluationResponses
      : item?.userResponse?.userResponseList;
    const responseLength = userResponse?.length - 1;
    return userResponse?.map((userResponseItem, index) => {
      return (
        <Text key={userResponseItem} weight={fontWeight.LIGHT} className={classes.subText}>
          {index === responseLength ? `${userResponseItem} ` : `${userResponseItem} , `}
        </Text>
      );
    });
  };

  const renderSection = (cbId, title, description, item) => (
    <Box key={cbId}>
      <Heading level={headingLevel.S} className={classes.title}>
        {title}
      </Heading>
      <Text weight={fontWeight.MEDIUM} className={classes.description}>
        {description}
      </Text>
      {bodySection(item)}
    </Box>
  );

  return (
    <>
      <Box className={classes.container}>
        <Box className={classes.header}>
          <Heading weight={fontWeight.BOLD} className={classes.headingText}>
            Review Evaluation
          </Heading>
        </Box>
        <Box className={classes.body}>
          <Box display="flex" flexDirection="column" gap="32px">
            {selectedEvaluation.sections.map(({ title, items, dctId, dctScore, isDCTScoreable }) => (
              <Box key={title}>
                {dctId && renderHeading(title, dctScore, isDCTScoreable)}
                <Box display="flex" flexDirection="column" gap="16px">
                  {items.map(item => renderSection(item.cbId, item.text, item.description, item))}
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
        <Box className={classes.footer}>
          <IconButton icon="arrow-left" onClick={onClickPrevious} className={classes.previousBtn}>
            Previous
          </IconButton>
          <IconButton
            variant={iconBtnType.PRIMARY}
            onClick={onClickComplete}
            className={classes.completeBtn}
            disabled={
              selectedEvaluation?.status === 'COMPLETED' ||
              selectedEvaluation?.aiCompletionStatus === 'IN_PROGRESS'
            }
          >
            Complete evaluation
          </IconButton>
        </Box>
      </Box>
    </>
  );
};

export { EvaluationReview };
