/* eslint-disable max-len */
import cx from 'clsx';

// types
// styles
import { useStyles } from './shared/styles';

const SwapIcon = ({ className, color, onClick }) => {
  const classes = useStyles();
  return (
    <svg
      className={cx({
        [classes.root]: true,
        [className || '']: className,
      })}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M11.95 7.95L10.536 9.364L8.00005 6.828V20H6.00005V6.828L3.46505 9.364L2.05005 7.95L7.00005 3L11.95 7.95ZM21.9501 16.05L17 21L12.05 16.05L13.464 14.636L16.001 17.172L16 4H18V17.172L20.536 14.636L21.9501 16.05Z"
        fill={color}
      />
    </svg>
  );
};

export default SwapIcon;
