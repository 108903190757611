export const profileSectionIds = {
  general: 'general-information',
  approach: 'approach',
  availableStates: 'available-states',
  speciality: 'speciality',
  certifications: 'certifications',
  credentials: 'credentials',
  clinicAffiliation: 'clinic-affiliation',
  pastEmployment: 'past-employment',
  education: 'education',
  services: 'services',
  generalAvailability: 'general-availability',
  resetPassword: 'reset-password',
};

export const profileSections = [
  {
    id: profileSectionIds.general,
    title: 'General information',
  },
  {
    id: profileSectionIds.approach,
    title: 'Approach',
  },
  {
    id: profileSectionIds.availableStates,
    title: 'Available states',
  },
  {
    id: profileSectionIds.speciality,
    title: 'Specialties',
  },
  {
    id: profileSectionIds.certifications,
    title: 'Certifications',
  },
  {
    id: profileSectionIds.credentials,
    title: 'Credentials',
  },
  {
    id: profileSectionIds.generalAvailability,
    title: 'General availability',
  },
  {
    id: profileSectionIds.services,
    title: 'Services',
  },
];

export const rightSidebarNavItems = [
  {
    id: 'general-information',
    value: 'General information',
  },
  {
    id: 'approach',
    value: 'Approach',
  },
  {
    id: 'available-states',
    value: 'Available states',
  },
  {
    id: 'certifications',
    value: 'Certifications',
  },
  {
    id: 'credentials',
    value: 'Credentials',
  },
  // {
  //   id: 'clinic-affiliation',
  //   value: 'Clinic affiliation',
  // },
  // {
  //   id: 'past-employment',
  //   value: 'Past employment',
  // },
  // {
  //   id: 'education',
  //   value: 'Education',
  // },
  {
    id: 'general-availability',
    value: 'General availability',
  },
  {
    id: 'services',
    value: 'Services',
  },
];

export const mockType = {
  speciality: [
    { label: 'ADHD', value: 'ADHD' },
    { label: 'Addiction', value: 'Addiction' },
    { label: 'Alcohol Use', value: 'Alcohol Use' },
    { label: 'Anxiety', value: 'Anxiety' },
    { label: 'Behavioral Health', value: 'Behavioral Health' },
    { label: 'Bipolar', value: 'Bipolar' },
    { label: 'CBT', value: 'CBT' },
    { label: 'Care Navigation', value: 'Care Navigation' },
    { label: 'Criminal Justice Involved', value: 'Criminal Justice Involved' },
    { label: 'DBT', value: 'DBT' },
    { label: 'Depression', value: 'Depression' },
    { label: 'Employment', value: 'Employment' },
    { label: 'Family Support', value: 'Family Support' },
    { label: 'Grief', value: 'Grief' },
    { label: 'Harm Reduction', value: 'Harm Reduction' },
    { label: 'LGBTQ*', value: 'LGBTQ*' },
    { label: 'MAT', value: 'MAT' },
    { label: 'Marriage and Family', value: 'Marriage and Family' },
    { label: 'Medication Management', value: 'Medication Management' },
    { label: 'Mindfulness', value: 'Mindfulness' },
    { label: 'Moderation', value: 'Moderation' },
    { label: 'Opioid Use', value: 'Opioid Use' },
    { label: 'PTSD', value: 'PTSD' },
    { label: 'Peer Support', value: 'Peer Support' },
    { label: 'Pregnancy', value: 'Pregnancy' },
    { label: 'Psychiatric Care', value: 'Psychiatric Care' },
    { label: 'Recovery Coaching', value: 'Recovery Coaching' },
    { label: 'Self Esteem', value: 'Self Esteem' },
    { label: 'Sex and Intimacy', value: 'Sex and Intimacy' },
    { label: 'Sleep', value: 'Sleep' },
    { label: 'Social Anxiety', value: 'Social Anxiety' },
    { label: 'Stress', value: 'Stress' },
    { label: 'Substance Use', value: 'Substance Use' },
    { label: 'Therapy', value: 'Therapy' },
    { label: 'Whole Person Health', value: 'Whole Person Health' },
    { label: 'Womens Health', value: 'Womens Health' },
  ],
  certifications: [
    { label: 'PMHNP-B', value: 'PMHNP-B' },
    { label: 'ABC', value: 'ABC' },
    { label: 'APRN', value: 'APRN' },
    { label: 'BCD', value: 'BCD' },
    { label: 'DEF', value: 'DEF' },
    { label: 'GHK', value: 'GHK' },
    { label: 'LMN', value: 'LMN' },
    { label: 'OPR', value: 'OPR' },
    { label: 'STU', value: 'STU' },
    { label: 'VWX', value: 'VWX' },
  ],
  credentials: [
    { label: 'DN', value: 'DN' },
    { label: 'APR', value: 'APR' },
    { label: 'DEF', value: 'DEF' },
    { label: 'GHK', value: 'GHK' },
    { label: 'LMN', value: 'LMN' },
    { label: 'OPR', value: 'OPR' },
    { label: 'STU', value: 'STU' },
    { label: 'VWX', value: 'VWX' },
  ],
};

export const US_TIMEZONES = [
  {
    label: 'America/New_York',
    value: 'America/New_York',
    states: [
      'Connecticut',
      'Delaware',
      'Florida',
      'Georgia',
      'Kentucky',
      'Maine',
      'Maryland',
      'Massachusetts',
      'New Hampshire',
      'New Jersey',
      'New York',
      'North Carolina',
      'Ohio',
      'Pennsylvania',
      'Rhode Island',
      'South Carolina',
      'Vermont',
      'Virginia',
      'Washington',
      'West Virginia',
    ],
  },
  {
    label: 'America/Chicago',
    value: 'America/Chicago',
    states: [
      'Alabama',
      'Arkansas',
      'Illinois',
      'Iowa',
      'Kansas',
      'Louisiana',
      'Minnesota',
      'Mississippi',
      'Missouri',
      'Nebraska',
      'North Dakota',
      'Oklahoma',
      'South Dakota',
      'Tennessee',
      'Texas',
      'Wisconsin',
    ],
  },
  {
    label: 'US/Alaska',
    value: 'US/Alaska',
    states: ['Alaska'],
  },
  {
    label: 'America/Phoenix',
    value: 'America/Phoenix',
    states: ['Arizona'],
  },
  {
    label: 'America/Los_Angeles',
    value: 'America/Los_Angeles',
    states: ['California', 'Nevada', 'Oregon'],
  },
  {
    label: 'America/Denver',
    value: 'America/Denver',
    states: ['Colorado', 'Montana', 'New Mexico', 'Utah', 'Wyoming'],
  },
  {
    label: 'US/Hawaii',
    value: 'US/Hawaii',
    states: ['Hawaii'],
  },
  {
    label: 'America/Boise',
    value: 'America/Boise',
    states: ['Idaho'],
  },
  {
    label: 'America/Indianapolis',
    value: 'America/Indianapolis',
    states: ['Indiana'],
  },
  {
    label: 'America/Detroit',
    value: 'America/Detroit',
    states: ['Michigan'],
  },
];

function generateTimeArray() {
  const timeArray = [];
  const timeFormatOptions = { hour12: true, hour: '2-digit', minute: '2-digit' };

  for (let hour = 0; hour < 24; hour++) {
    for (let minute = 0; minute < 60; minute += 15) {
      const time = new Date(0, 0, 0, hour, minute);
      const label = time.toLocaleTimeString('en-US', timeFormatOptions);
      const value = `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;

      timeArray.push({ label, value });
    }
  }

  return timeArray;
}

export const TIME_ARRAY = generateTimeArray();

export const helperTabs = [
  { label: 'Related information', tabKey: 'information' },
  // { label: 'General information', tabKey: 'general-information' },
  // { label: 'Ask AI', tabKey: 'ask_ai' },
  // { label: 'Contact us', tabKey: 'contact_us' },
];
