import { Drawer, drawerPosition, drawerType } from '../../../../../../packages/ui/organisms/drawer';
import { useState } from 'react';
import IdCardUpload from './IdCardUpload';

const IdCardUploadDrawer = ({ openDrawer, onClose, onSubmit, isInsurance = true }) => {
  const [urls, setUrls] = useState({
    front: '',
    back: '',
  });

  const onUpload = (url, type) => {
    if (type === 'front') {
      setUrls({ ...urls, front: url });
    } else {
      setUrls({ ...urls, back: url });
    }
  };

  const onSubmitUrls = () => {
    onSubmit && onSubmit(urls);
  };

  return (
    <Drawer
      variant={drawerType.FORM}
      position={drawerPosition.RIGHT}
      open={openDrawer}
      onClose={onClose}
      title={isInsurance ? 'Insurance card' : 'ID card'}
      submitBtnTitle="Save"
      onSubmit={() => onSubmitUrls()}
    >
      <IdCardUpload label="Front side" onUpload={onUpload} type="front" />
      <IdCardUpload label="Back side" onUpload={onUpload} type="back" />
    </Drawer>
  );
};

export default IdCardUploadDrawer;
