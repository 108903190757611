import { baseRequest } from '../../utils/HttpClient';
import { ApiEndpoints } from '../../constants/ApiEndpoints';

export const getProviderFeeList = (queryParams, body) => {
  return baseRequest(ApiEndpoints.GET_PROVIDER_FEE_LIST, body, null, queryParams);
};
export const downloadProviderFeeList = (payload, requestBody) => {
  return baseRequest(ApiEndpoints.GET_PROVIDER_FEE_LIST, requestBody || {}, null, payload, true, true, true);
};
export const getProviderFeeById = pathParams => {
  return baseRequest(ApiEndpoints.GET_PROVIDER_FEE_BY_ID, null, pathParams);
};

export const markProviderFeePaid = pathParams => {
  return baseRequest(ApiEndpoints.MARK_PAID_PROVIDER_FEE, null, pathParams);
};

export const markAllSelectedProviderFeePaid = requestBody => {
  return baseRequest(ApiEndpoints.ALL_SELECTED_MARK_PAID_PROVIDER_FEE, requestBody, null);
};
export const markAllSelectedProviderFeeRejected = requestBody => {
  return baseRequest(ApiEndpoints.ALL_SELECTED_MARK_REJECTED_PROVIDER_FEE, requestBody, null);
};

export const markProviderFeeRejected = pathParams => {
  return baseRequest(ApiEndpoints.MARK_REJECT_PROVIDER_FEE, null, pathParams);
};

export const getProviderFeeByClaimId = pathParams => {
  return baseRequest(ApiEndpoints.GET_PROVIDER_FEE_BY_CLAIM_ID, null, pathParams);
};
export const getProviderFeeByAppointmentId = pathParams => {
  return baseRequest(ApiEndpoints.GET_PROVIDER_FEE_BY_APPOINTMENT_ID, null, pathParams);
};
