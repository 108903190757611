import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/material';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';
import clsx from 'clsx';
import {
  Badge,
  badgeType,
  btnSize,
  btnType,
  Button,
  colors,
  fontWeight,
  iconBtnStyle,
  iconBtnType,
  IconButton,
  Icons,
  Text,
} from '../../../../../packages';
import { Drawer, drawerType } from '../../../../../packages/ui/organisms/drawer';
import { ProfileInfo } from '../../../../../packages/ui/templates/profile-info';
import {
  AppointmentStatus,
  AppointmentStatusBagde,
  AppointmentStatusText,
  SignOffStatus,
} from '../../../../../packages/constants/CommonConstants';
import { getAuth } from '../../../../../redux/modules/auth/selectors';
import { selectAssignedEvaluationState } from '../../../../../redux/modules/conversation/selectors';
import { conversationActionCreators } from '../../../../../redux/modules/conversation';
import history from '../../../../../utils/history';
import { getUserTimeZone } from '../../../../../utils/dayjs';
import { selectProviderSchedule } from '../../../../../redux/modules/schedule/selectors';
import { payeeTypes } from '../../../../admin/payments/Payments.constants';

import AddEvaluation from './AddEvaluation';
import ConfirmModal from './ConfirmModal';
import { sxStyles, useStyles } from './AppointmentDetail.styles';
import { showSnackbar } from '../../../../../redux/modules/snackbar';
import getConfig from '../../../../../config';
import EvaluationDetail from '../../../priorities/appointments/evaluation-detail';
import { recurringAppointmentTypes } from '../../../../admin/appointments/add-schedule/AddSchedule.constants';

const AppointmentDetail = ({
  open,
  onClose,
  appointment,
  evaluations,
  onCancelClick,
  onAcceptAppointment,
  onRescheduleClick,
}) => {
  const classes = useStyles();
  // const isPastAppointments = location?.search?.includes('appointments-past');
  const { appointmentType } = useParams();
  const providerSchedule = useSelector(selectProviderSchedule);
  const dispatch = useDispatch();
  const isRecurringAppointment = recurringAppointmentTypes?.includes(appointment?.recurringType);
  const { isLoading, evaluations: assignedEvaluationsData } = useSelector(selectAssignedEvaluationState);
  const { isAdmin } = useSelector(getAuth);

  const [selectedEvaluation, setSelectedEvaluation] = useState(null);
  const [openAddEvaluation, setOpenAddEvaluation] = useState(false);
  const [assignedEvaluations, setAssignedEvaluations] = useState([]);
  const [openCancelAppt, setOpenCancelApptModal] = useState(false);

  useEffect(() => {
    setAssignedEvaluations(assignedEvaluationsData);
  }, [assignedEvaluationsData]);

  useEffect(() => {
    if (appointment?.appointmentId) {
      dispatch(
        conversationActionCreators.fetchAssignedEvaluations({ appointmentId: appointment?.appointmentId }),
      );
      dispatch(conversationActionCreators.fetchEvaluations());
    }
  }, [appointment]);

  const onAddEvaluation = selectedValues => {
    dispatch(
      conversationActionCreators.addAssignedEvaluation({
        evaluationIds: selectedValues,
        appointmentId: appointment?.appointmentId,
      }),
    );
    setOpenAddEvaluation(false);
  };

  const onStartSession = () => {
    history.push(
      `/provider/appointments/${appointmentType ?? 'current'}/${appointment.appointmentId}/session`,
    );
  };

  const onAcceptAppointmentClick = () => {
    onAcceptAppointment && onAcceptAppointment(appointment);
  };

  const copyToClipboard = async link => {
    await navigator.clipboard.writeText(link);
    dispatch(
      showSnackbar({
        snackType: 'success',
        snackMessage: 'Scheduling link copied successfully',
        vertical: 'top',
        horizontal: 'center',
      }),
    );
  };

  const onCopyConfirmationClick = () => {
    void copyToClipboard(
      `${getConfig.memberAppUrl}public/book-appointment?appointmentId=${appointment.appointmentId}&memberId=${appointment.patientId}`,
    );
  };

  const cancelAppointment = () => {
    onCancelClick();
    setOpenCancelApptModal(false);
  };

  const Evaluation = ({ item, onDelete }) => {
    return (
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        py={2}
        sx={{ '& svg': { width: 20 } }}
      >
        <Text className={classes.infoValue}>{item.name}</Text>
        {appointment.status !== AppointmentStatus.FULFILLED && (
          <Box>
            <IconButton
              icon="delete-outlined-2"
              size={btnSize.SMALL}
              style={iconBtnStyle.ERROR}
              onClick={() => onDelete && onDelete(item.id)}
            />
            <IconButton
              className={classes.btnEvaluation}
              icon="arrow-right"
              size={btnSize.SMALL}
              style={iconBtnStyle.SECONDARY}
              onClick={() => setSelectedEvaluation(item)}
            />
          </Box>
        )}
      </Box>
    );
  };

  const isDone = [
    AppointmentStatus.CANCELLED,
    AppointmentStatus.NO_SHOW,
    AppointmentStatus.FULFILLED,
  ].includes(appointment?.status);

  const onOpenChat = () => {
    if (isAdmin) {
      history.push(`/admin/chats?userId=${appointment?.patientId}&type=members`);
    } else {
      history.push(`/provider/chats?userId=${appointment?.patientId}&type=care_team`);
    }
  };
  const { status, signOffStatus, patientStatus, practitionerStatus, statusChangingAuthority } =
    appointment || {};
  let statusText = AppointmentStatusText[status];
  if (status === AppointmentStatus.FULFILLED && signOffStatus === SignOffStatus.DRAFTED) {
    statusText = 'Waiting for Sign off';
  }
  if (status === AppointmentStatus.PROPOSED || status === AppointmentStatus.PENDING) {
    if (patientStatus === AppointmentStatus.NEEDS_ACTION) {
      statusText = 'Waiting on Member';
    } else if (practitionerStatus === AppointmentStatus.NEEDS_ACTION) {
      statusText = 'Waiting on Provider';
    }
  } else if (status === AppointmentStatus.CANCELLED) {
    if (patientStatus === AppointmentStatus.DECLINED) {
      statusText = 'Cancelled - M';
    } else if (practitionerStatus === AppointmentStatus.DECLINED) {
      statusText = 'Cancelled - P';
    }
  }
  if (status === AppointmentStatus.NO_SHOW) {
    if (statusChangingAuthority === 'PATIENT') {
      statusText = 'No Show - M';
    } else if (statusChangingAuthority === 'PRACTITIONER') {
      statusText = 'No Show - P';
    }
  }
  if (status === AppointmentStatus.BOOKED) {
    statusText = 'Scheduled';
  }

  const handleEvaluationDeletion = evaluationId => {
    const isAssigned = assignedEvaluations.some(evalId => evalId.id === evaluationId);
    if (isAssigned) {
      const { appointmentId } = appointment;
      dispatch(conversationActionCreators.deleteEvaluation({ appointmentId, evaluationId }));
    }
    // const { appointmentId } = appointment;
    // dispatch(conversationActionCreators.deleteEvaluation({ appointmentId, evaluationId }));
  };

  return (
    <Drawer
      open={open}
      onClose={() => {
        setAssignedEvaluations([]);
        onClose();
      }}
      title="Appointment details"
      variant={drawerType.FORM}
      footer={<div />}
      className={classes.drawer}
    >
      {!!appointment && (
        <>
          {/* {console.log({ appointment })} */}
          <Box flex={1}>
            <Box display="flex" justifyContent="space-between">
              <Box>
                <Text weight={fontWeight.BOLD} className={classes.title}>
                  {appointment.serviceName}
                </Text>
                <Box sx={sxStyles.durationWrapper}>
                  <Text weight={fontWeight.MEDIUM} className={clsx(classes.text16, classes.duration)}>
                    {appointment.serviceDuration} minutes session
                  </Text>
                  <Box sx={sxStyles.recurringIcon}>
                    {isRecurringAppointment && <Icons glyph="recurring" color={colors.secondary500} />}
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box style={{ height: 16 }} />
            <Box display="flex" justifyContent="left" alignItems="center" gap={1} paddingBottom={3}>
              <Badge
                variant={badgeType.FILLED}
                style={AppointmentStatusBagde[appointment.status]}
                className={classes.badge}
              >
                {statusText?.toLowerCase()?.includes('cancel') ? (
                  <span className={classes.cancelledStatusText}>{statusText}</span>
                ) : (
                  statusText
                )}
              </Badge>
              <Text weight={fontWeight.SEMI_BOLD} className={clsx(classes.text14, classes.info)}>
                {dayjs(appointment.startTime).format('MMMM DD, YYYY')} --{' '}
                {dayjs(appointment?.startTime)
                  .tz(providerSchedule?.timezone || getUserTimeZone())
                  .format('hh:mma')}{' '}
                -{' '}
                {dayjs(appointment.endTime)
                  .tz(providerSchedule?.timezone || getUserTimeZone())
                  .format('hh:mma')}
              </Text>
            </Box>
            <Box flex={1} className={classes.flexColumn} gap={4}>
              <Box
                className={classes.flexColumn}
                gap={2}
                padding="24px 0"
                borderTop={`1px solid ${colors.neutral200}`}
              >
                <Text className={classes.infoLabel}>Member</Text>
                <Box className={classes.flexBetween}>
                  <ProfileInfo
                    type="member"
                    photo={appointment?.member?.photo ?? appointment?.participantImage}
                    nickName={
                      appointment?.patientFirstName && appointment?.patientLastName
                        ? `${appointment?.patientFirstName || ''} ${appointment?.patientLastName || ''}`
                        : appointment?.participantName ?? 'No Name'
                    }
                    isProvider={!isAdmin}
                    fullName={appointment?.patientUuid}
                    memberId={appointment?.participantId || appointment?.patientId}
                  />
                  <Box display="flex" flexDirection="column" justifyContent="left">
                    <Text weight={fontWeight.SEMI_BOLD} className={clsx(classes.text14, classes.info)}>
                      {appointment?.participantPhoneNumber}
                    </Text>
                    <Button variant={btnType.TEXT} className={classes.btnOpenChat} onClick={onOpenChat}>
                      Message member
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box
              className={classes.flexBetween}
              gap={2}
              padding="24px 0"
              borderTop={`1px solid ${colors.neutral200}`}
            >
              <Box>
                <Text className={classes.infoLabel}>State</Text>
                <Text weight={fontWeight.SEMI_BOLD} className={clsx(classes.text16, classes.info)}>
                  {appointment?.patientState}
                </Text>
              </Box>
              {appointment?.prePayment && (
                <>
                  <Box>
                    <Text className={classes.infoLabel}>Payment method</Text>
                    <Text weight={fontWeight.SEMI_BOLD} className={clsx(classes.text16, classes.info)}>
                      {appointment?.prePayment?.paymentMethod
                        ? payeeTypes[appointment?.prePayment?.paymentMethod]
                        : '-'}
                    </Text>
                  </Box>
                  <Box>
                    <Text className={classes.infoLabel}>Amount paid</Text>
                    <Text weight={fontWeight.SEMI_BOLD} className={clsx(classes.text16, classes.info)}>
                      ${appointment.prePayment.amountPaid}
                    </Text>
                  </Box>
                </>
              )}
            </Box>
            <Box className={classes.flexColumn} borderTop={`1px solid ${colors.neutral200}`}>
              <Box mt={4} className={classes.flexBetween}>
                {assignedEvaluations && assignedEvaluations?.length > 0 && (
                  <Text className={classes.infoLabel}>Assigned evaluations</Text>
                )}
                {statusText !== 'Cancelled' &&
                  statusText !== 'Cancelled - M' &&
                  statusText !== 'Cancelled - P' &&
                  appointment.status !== AppointmentStatus.FULFILLED && (
                    <IconButton
                      variant={iconBtnType.OUTLINE}
                      className={clsx(classes.btnReschedule)}
                      icon="plus"
                      onClick={() => setOpenAddEvaluation(true)}
                    >
                      Assign evaluation
                    </IconButton>
                  )}
              </Box>
              {isLoading ? (
                <Box display="flex" justifyContent="center" paddingY="10px">
                  <Icons className="rotate linear infinite" glyph="in-progress" color={colors.primary} />
                </Box>
              ) : (
                <>
                  <Box className={assignedEvaluations?.length > 1 && classes.evaluations}>
                    {assignedEvaluations?.map((evaluation, index) => (
                      <Evaluation
                        key={`${index}-${evaluation.id}`}
                        item={evaluation}
                        onDelete={() => handleEvaluationDeletion(evaluation.id)}
                      />
                    ))}
                  </Box>
                </>
              )}
            </Box>
            {openAddEvaluation && evaluations?.length > 0 && (
              <AddEvaluation
                isOpen={openAddEvaluation}
                onClose={() => setOpenAddEvaluation(false)}
                evaluations={evaluations}
                assignedEvaluations={assignedEvaluations}
                appointment={appointment}
                onAddEvaluation={onAddEvaluation}
                onRemoveEvaluation={handleEvaluationDeletion}
              />
            )}
          </Box>
          {!isDone && (
            <Box>
              {!isAdmin && (
                <Box className={classes.flexBetween} gap={2}>
                  <IconButton
                    variant={iconBtnType.OUTLINE}
                    className={clsx(classes.btn, classes.btnReschedule)}
                    onClick={onRescheduleClick}
                  >
                    Reschedule
                  </IconButton>
                  {(appointment.status === AppointmentStatus.PROPOSED ||
                    appointment.status === AppointmentStatus.PENDING ||
                    appointment.status === AppointmentStatus.BOOKED) && (
                    <IconButton
                      variant={iconBtnType.PRIMARY}
                      className={classes.btn}
                      onClick={
                        practitionerStatus === AppointmentStatus.NEEDS_ACTION
                          ? onAcceptAppointmentClick
                          : patientStatus === AppointmentStatus.NEEDS_ACTION
                          ? onCopyConfirmationClick
                          : onStartSession
                      }
                    >
                      {practitionerStatus === AppointmentStatus.NEEDS_ACTION
                        ? 'Accept appointment'
                        : patientStatus === AppointmentStatus.NEEDS_ACTION
                        ? 'Copy confirmation link'
                        : 'Start session'}
                    </IconButton>
                  )}
                </Box>
              )}
              {isAdmin && appointment.status !== 'FULFILLED' && (
                <IconButton
                  variant={iconBtnType.OUTLINE}
                  className={clsx(classes.btn, classes.btnCancel)}
                  onClick={() => setOpenCancelApptModal(true)}
                >
                  Cancel appointment
                </IconButton>
              )}
              {openCancelAppt && (
                <ConfirmModal
                  icon="close-circle-outlined"
                  open={openCancelAppt}
                  title="Cancel this appointment?"
                  content="Are you sure you want to cancel this appointment?"
                  lbtnLabel="Keep appointment"
                  rbtnLabel="Cancel appointment"
                  onClose={() => setOpenCancelApptModal(false)}
                  onCancel={() => setOpenCancelApptModal(false)}
                  onSubmit={cancelAppointment}
                  isLoading={isLoading}
                />
              )}
              {selectedEvaluation && (
                <EvaluationDetail
                  isOpen={!!selectedEvaluation}
                  onClose={() => setSelectedEvaluation(null)}
                  evaluation={selectedEvaluation}
                  appointmentId={appointment.appointmentId}
                  memberId={appointment.patientId}
                  isView={false}
                />
              )}
            </Box>
          )}
        </>
      )}
    </Drawer>
  );
};

export { AppointmentDetail };
