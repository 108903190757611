import dayjs from 'dayjs';
import { GLOBAL_DATE_FORMAT } from '../../../../../constants/CommonConstants';
import { getFullLast30Days, shortNumber } from '../../../../../utils/CommonUtils';

const days30 = getFullLast30Days();

export const getChartOptions = (data, tooltipConfig, opt) => {
  const lineColors = data.map(({ color }) => color);
  return {
    chart: {
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    title: {
      text: '',
    },
    plotOptions: {
      bar: {
        horizontal: false,
      },
    },
    colors: lineColors,
    stroke: {
      width: 2,
      colors: lineColors,
      curve: 'smooth',
    },
    xaxis: {
      categories: days30,
      tickAmount: 10,
      labels: {
        formatter: val => {
          return dayjs(val).format('MMM D');
        },
        style: {
          fontFamily: 'Manrope',
          fontWeight: 600,
        },
      },
      tooltip: {
        enabled: false,
      },
    },
    yaxis: {
      title: {
        text: undefined,
      },
    },
    tooltip: {
      style: {
        fontFamily: 'Manrope',
      },
      custom: ({ series, dataPointIndex }) => {
        const time = dayjs(days30[dataPointIndex]).format(GLOBAL_DATE_FORMAT);
        const num = tooltipConfig.isShowTotal
          ? series.reduce((total, item) => +total + Number(item[dataPointIndex]), 0)
          : 0;

        return `
          <div class="chart-tooltip">
            <div class="date">${time}</div>
            <div class="level">
                ${tooltipConfig.isShowTotal ? `${num} ` : ''}
                ${
                  // eslint-disable-next-line no-nested-ternary
                  tooltipConfig.isShowTotal
                    ? num > 1
                      ? tooltipConfig.unit2
                      : tooltipConfig.unit1
                    : tooltipConfig.title
                }
            </div>
            ${series.reduce(
              (s, item, i) =>
                `${s}<div class="row">
                <div class="row-left">
                  <div class="dot dot-${i}" style="background-color: ${lineColors[i]}"></div>
                  <div class="title">${data[i].name}</div>
                </div>
                <div class="value">${tooltipConfig.syntax || ''}${item[dataPointIndex] || '-'}</div>
              </div>`,
              '',
            )}
          </div>
        `;
      },
      y: {
        formatter: shortNumber,
        show: false,
      },
    },
    fill: {
      opacity: 1,
    },
    legend: {
      show: true,
      offsetY: 10,
      horizontalAlign: 'left',
    },
    ...opt,
  };
};
