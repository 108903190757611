// colors
import { colors } from '../../../../../../packages/colors';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  drawer: {
    '& .drawerBody': {
      backgroundColor: colors.neutral50,
      height: 'calc(100vh - 160px)',
    },
  },
  listItem: {
    overflowY: 'auto',
    borderTop: `1px solid ${colors.neutral100}`,
    height: 'calc(100% - 48px)',
  },
  bodyWhite: {
    '& .drawerBody': {
      backgroundColor: colors.white,
    },
  },
  form: {
    flex: 1,
  },
  label: {},
  section: {
    marginBottom: 16,
  },
  searchInput: {
    height: 48,
  },
  searchBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: 32,
    marginBottom: 30,
    paddingRight: 24,
  },
  backBtn: {
    width: 40,
    height: 40,
    minWidth: 40,
    minHeight: 40,
    padding: '18px 12px',
    borderColor: colors.primary300,
    '& svg': {
      color: colors.primary500,
      width: 18,
      height: 15,
    },
  },
  categories: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    padding: '20px 16px',
    gap: 24,
    borderBottom: `1px solid ${colors.neutral100}`,
  },
  headerTitle: {
    margin: 0,
  },
});
