import { Box } from '@mui/material';

const Typography = ({ children, color, ...rest }) => {
  return (
    <Box color={color} {...rest}>
      {children}
    </Box>
  );
};

export default Typography;
