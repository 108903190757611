import { useCallback, useEffect, useRef, useState } from 'react';
import { Box } from '@mui/material';
import clsx from 'clsx';
import debounce from 'debounce';

import {
  btnType,
  Button,
  Checkbox,
  colors,
  fontWeight,
  Icons,
  Input,
  inputType,
  Text,
  TextError,
  TextField,
  textLevel,
} from '../../../../../packages';
import { ProfileInfo } from '../../../../../packages/ui/templates/profile-info';

import { getFullImagePath } from '../../../../../utils/CommonUtils';
import { useStyles2 } from './StepOne.styles';
import dayjs from '../../../../../utils/dayjs';
import { insuranceFilterText, stateFilterText } from '../../AppointmentList.constants';
import { stateCodes } from '../../../member-list/MemberList.constants';

const StepOne = ({
  selectedItem,
  selectedPatient,
  selectType,
  onChange,
  options,
  error,
  className = '',
  userId = null,
  fetchMembers,
  fetchProviders,
  isMemberList,
  isLoading,
  setPageNumber,
  pageNumber,
  setSearchKeyWithPagination,
  showSimpleSearch = false,
  setSimpleSearchKey,
  hidePrimarySearch = false,
}) => {
  const listInnerRef = useRef();
  const classes = useStyles2();
  const [searchKey, setSearchKey] = useState('');
  const [membersFiltered, setMembersFiltered] = useState([]);
  const [providerFiltered, setProviderFiltered] = useState([]);
  const [checkMemberList, setCheckMemberList] = useState(false);
  const [firstCheck, setFirstCheck] = useState(false);
  const [insuranceChecked, setInsuranceChecked] = useState(false);
  const [stateChecked, setStateChecked] = useState(false);

  useEffect(() => {
    selectedPatient?.state ? setStateChecked(true) : setStateChecked(false);
    selectedPatient?.insurance ? setInsuranceChecked(true) : setInsuranceChecked(false);
  }, [selectedPatient]);

  useEffect(() => {
    if (options.length > 0 && selectType === 'member') {
      setMembersFiltered(options);
    } else {
      setProviderFiltered(options);
    }
  }, [options]);

  useEffect(() => {
    if (userId) {
      if (!checkMemberList) {
        if (!firstCheck) {
          setFirstCheck(true);
          const preSelected = options.find(option => option.id === userId);
          if (preSelected) {
            options.sort((x, y) => {
              return x.id === preSelected.id ? -1 : y.id === preSelected.id ? 1 : 0;
            });
            onChange(preSelected);
          }
        }
      }
    }
  }, [userId, options]);

  const fetchMembersCallback = useCallback(
    debounce((key, page, step) => {
      if (step === 'member' && fetchMembers) {
        fetchMembers(key, page);
      } else if (step === 'provider' && fetchProviders) {
        fetchProviders(key, page);
      } else {
        const newItems = options.filter(item =>
          `${item.firstName} ${item.lastName}`.toLowerCase().includes(key),
        );
        setMembersFiltered(newItems);
      }
    }, 300),
    [options],
  );

  const onClickItem = item => () => {
    if (isMemberList) {
      setCheckMemberList(true);
    }
    onChange(item);
  };

  const onChangeSearchKey = val => {
    fetchMembersCallback(val.toLowerCase(), 0, selectType);
    setPageNumber(0);
    setSearchKey(val);
  };

  useEffect(() => {
    setMembersFiltered(options);
  }, [options]);

  useEffect(() => {
    setSearchKey('');
  }, [selectType]);

  const onLoadMoreClick = () => {
    fetchMembersCallback(searchKey.toLocaleLowerCase() || '', 1 + pageNumber, selectType);
    setPageNumber(pageNumber + 1);
  };

  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (parseFloat(scrollTop) + parseFloat(clientHeight) === scrollHeight) {
        // This will be triggered after hitting the last element.
        // API call should be made here while implementing pagination.
      }
    }
  };

  const renderProvider = item => {
    const { id, nickName, fullName } = item;
    const start = item?.schedule?.slots ? item?.schedule?.slots?.[0]?.start : null;
    return (
      <Box
        className={clsx(classes.userinfo, { [classes.selected]: selectedItem?.id === id })}
        key={id}
        onClick={onClickItem(item)}
      >
        <Box className={classes.providerProfile}>
          <ProfileInfo
            type={selectType}
            photo={getFullImagePath(item.photo)}
            nickName={nickName}
            fullName={fullName !== ' ' ? fullName : nickName}
            role={item.designation}
          />
        </Box>
        {start ? (
          <Box alignItems="center" gap="12px" width="48%">
            <TextField label="Next available" text={showNextSlot(start)} />
          </Box>
        ) : (
          ''
        )}

        <Box className={classes.ticBox}>
          {selectedItem?.id === id && <Icons glyph="checkmark" color={colors.success500} />}
        </Box>
      </Box>
    );
  };

  const showNextSlot = tomorrowSlot => {
    const currentDateTimestamp = dayjs().format('DD/MM/YYYY');
    const tomorrowDateTimestamp = dayjs(tomorrowSlot).add(1, 'day').format('DD/MM/YYYY');
    const todayDateTimestamp = dayjs(tomorrowSlot).format('DD/MM/YYYY');
    if (currentDateTimestamp === todayDateTimestamp) {
      return `Today -  ${dayjs(tomorrowSlot).format('hh:mm A')}`;
    }
    if (currentDateTimestamp === tomorrowDateTimestamp) {
      return `Tomorrow -  ${dayjs(tomorrowSlot).format('hh:mm A')}`;
    }
    return dayjs(tomorrowSlot).format('dddd, MMMM D, YYYY h:mm A');
  };

  const renderMember = item => {
    const { id, nickName, state, dob, insurance, firstName, lastName, uuid, photo } = item;
    // const patientFullName = fullName?.trim();
    // const patientNickName = nickName?.trim();
    return (
      <Box
        className={clsx(classes.userinfo, { [classes.selected]: selectedItem?.id === id })}
        key={id}
        onClick={onClickItem(item)}
        display="flex"
        gap="4px"
      >
        <Box className={classes.profile}>
          <ProfileInfo
            type="member"
            nickName={firstName && lastName ? `${firstName || ''} ${lastName || ''}` : nickName || 'No Name'}
            fullName={uuid}
            photo={photo}
            // role={item.designation}
          />
        </Box>
        <Box className={classes.dobBox} alignItems="center">
          <TextField label="DOB" text={dob || '-'} />
        </Box>
        <Box className={classes.stateBox} alignItems="center">
          <TextField label="State" text={stateCodes[state] || '-'} />
        </Box>
        <Box className={classes.insuranceBox} alignItems="center" gap="12px">
          <Box display="flex" flexDirection="column" gap="4px">
            <TextField label="Insurance" text="" />
            {insurance ? (
              <Box display="flex" alignItems="center" justifyContent="stretch" gap="6px">
                <Box>
                  <Icons glyph="check-circle" color={colors.green600} className={classes.insuranceIcon} />
                </Box>
                <Box>
                  <Text weight={fontWeight.MEDIUM} className={classes.insuranceText}>
                    {insurance}
                  </Text>
                </Box>
              </Box>
            ) : (
              <Box display="flex" alignItems="center" gap="6px">
                <Icons
                  glyph="clear-circle"
                  color={colors.destructive500}
                  className={classes.noInsuranceIcon}
                />
                <Text weight={fontWeight.MEDIUM} className={classes.insuranceText}>
                  None
                </Text>
              </Box>
            )}
          </Box>
        </Box>

        <Box className={classes.ticBox}>
          {selectedItem?.id === id && <Icons glyph="checkmark" color={colors.success500} />}
        </Box>
      </Box>
    );
  };
  if (selectedItem) {
    options.sort((a, b) => {
      if (a.id === selectedItem.id) return -1;
      if (b.id === selectedItem.id) return 1;
      return 0;
    });
  }

  const renderProviderList = () => {
    const insuranceIDs = [];
    let providerStateList = providerFiltered;
    let providerInsuranceList = [];
    if (selectedPatient) {
      providerInsuranceList = providerFiltered.filter(item => {
        if (item.insurances && Array.isArray(item.insurances)) {
          return item.insurances.some(insurance => {
            if (insurance.state === selectedPatient?.state) {
              if (insurance.insurances && Array.isArray(insurance.insurances)) {
                return insurance.insurances.some(nestedInsurance => {
                  if (nestedInsurance.name === selectedPatient?.insurance) {
                    insuranceIDs.push(item.id);
                    return true;
                  }
                  return false;
                });
              }
            }
            return false;
          });
        }
        return false;
      });

      providerStateList = providerFiltered.filter(item => {
        if (selectedPatient.insurance && !insuranceIDs.includes(item.id) && item?.operatingStates) {
          return item?.operatingStates.includes(selectedPatient?.state);
        }
        if (stateChecked && selectedPatient.insurance === null && item?.operatingStates) {
          return item?.operatingStates.includes(selectedPatient?.state);
        }
        return false;
      });
    }
    return (
      <>
        {stateChecked || insuranceChecked ? (
          <Box>
            {insuranceChecked && providerInsuranceList.length > 0 ? (
              <Box>
                {renderProviderListHeadings(
                  'Providers accepting',
                  selectedPatient?.state,
                  selectedPatient?.insurance,
                )}
                {providerInsuranceList.map(renderProvider)}
              </Box>
            ) : (
              ''
            )}

            {stateChecked && providerStateList.length > 0 ? (
              <Box>
                {renderProviderListHeadings('Other providers accepting', selectedPatient?.state, 'cash')}
                {!insuranceChecked ? providerInsuranceList.map(renderProvider) : ''}
                {providerStateList.map(renderProvider)}
              </Box>
            ) : (
              ''
            )}
          </Box>
        ) : insuranceChecked ? (
          providerInsuranceList.map(renderProvider)
        ) : stateChecked ? (
          providerStateList.map(renderProvider)
        ) : (
          providerFiltered?.map(renderProvider)
        )}
      </>
    );
  };

  const renderProviderListHeadings = (text, state, insurance) => {
    return (
      <Box padding="19px">
        <Text weight={fontWeight.SEMI_BOLD} level={textLevel.M} className={classes.filterText}>
          {text}
        </Text>
        <Text weight={fontWeight.SEMI_BOLD} level={textLevel.M} color={colors.secondary500}>
          {insurance}
        </Text>
        <Text weight={fontWeight.SEMI_BOLD} level={textLevel.M} className={classes.filterText}>
          In {state}
        </Text>
      </Box>
    );
  };

  const filterOnChange = text => () => {
    if (text === stateFilterText) {
      setStateChecked(!stateChecked);
    } else if (text === insuranceFilterText) {
      setInsuranceChecked(!insuranceChecked);
    }
  };

  const onChangeSimpleSearch = val => {
    setSimpleSearchKey(val?.toLocaleLowerCase() || '');
  };
  const renderProviderFilter = (text, value, isChecked) => {
    return (
      <Box>
        <Checkbox checked={isChecked} onChange={filterOnChange(text)} />
        <Text weight={fontWeight.SEMI_BOLD} level={textLevel.M} className={classes.filterText}>
          {text}
        </Text>
        <Text weight={fontWeight.SEMI_BOLD} level={textLevel.M} color={colors.secondary500}>
          {value}
        </Text>
      </Box>
    );
  };
  return (
    <Box className={className}>
      {!hidePrimarySearch && (
        <Box className={classes.searchBox} sx={{ borderBottom: `1px solid ${colors.neutral100}` }}>
          <Input
            className={classes.searchInput}
            variant={inputType.SEARCH}
            placeholder="Search"
            value={searchKey}
            onChange={onChangeSearchKey}
          />
          {error && <TextError errorMsg={error} className={clsx(classes.nickName, classes.error)} />}
        </Box>
      )}
      {showSimpleSearch && (
        <Box className={classes.searchBox} sx={{ borderBottom: `1px solid ${colors.neutral100}` }}>
          <Input
            className={classes.searchInput}
            variant={inputType.SEARCH}
            placeholder="Search"
            value={searchKey}
            onChange={onChangeSimpleSearch}
          />
          {error && <TextError errorMsg={error} className={clsx(classes.nickName, classes.error)} />}
        </Box>
      )}
      {selectType === 'provider' && selectedPatient && (
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-around"
          alignItems="center"
          padding="20px 20px 0px"
        >
          {selectedPatient?.state
            ? renderProviderFilter(stateFilterText, selectedPatient?.state, stateChecked)
            : ''}
          {selectedPatient?.insurance
            ? renderProviderFilter(insuranceFilterText, selectedPatient?.insurance, insuranceChecked)
            : ''}
        </Box>
      )}
      <Box className="list-item" onScroll={onScroll} ref={listInnerRef} sx={{ padding: 3 }}>
        {options?.length > 0 ? (
          selectType === 'member' ? (
            membersFiltered?.map(renderMember)
          ) : (
            renderProviderList()
          )
        ) : (
          <Text weight={fontWeight.MEDIUM} className={clsx(classes.nickName, classes.emptyList)}>
            There is no item
          </Text>
        )}
        <Box display="flex" justifyContent="center" alignItems="center" className={classes.loadingWrap}>
          {isLoading ? (
            <>
              <Icons className="rotate linear infinite" glyph="in-progress" color={colors.primary} />
              Loading...
            </>
          ) : setSearchKeyWithPagination ? (
            <Button variant={btnType.TEXT} className={classes.loadMoreBtn} onClick={onLoadMoreClick}>
              Load more
            </Button>
          ) : null}
        </Box>
      </Box>
    </Box>
  );
};

export default StepOne;
