import { useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';
import { Drawer } from '../../../../packages/ui/organisms/drawer';
import { btnType, Button, colors, Icons } from '../../../../packages';
import { getAuth } from '../../../../redux/modules/auth/selectors';
import { useDispatch, useSelector } from 'react-redux';

import Heading from '../../../../components/v2/Heading';
import { HeadingLevel, HeadingWeight } from '../../../../components/v2/Heading/index.types';
import Typography from '../../../../components/v2/Typography';
import { typography } from '../../../../components/v2/Typography/index.constant';
import avatarIcon from '../../../../assets/images/avatar.png';
import { getAllPhq9Gad7AverageScore } from '../../../../services/reporting/reporting.service';
import { showSnackbar } from '../../../../redux/modules/snackbar';
import { profileActionCreators } from '../../../../redux/modules/profile';
import history from '../../../../utils/history';
import dayjs from 'dayjs';
import { S3_BUCKET_URL } from '../../../../packages/constants/CommonConstants';
import DrawerHead from './DrawerHead';

import { useStyles } from '../index.styles';

const AverageScoreDrawer = ({ open, onClose, data }) => {
  const { meta } = useSelector(getAuth);
  const classes = useStyles();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [averagerScoreData, setAverageScoreData] = useState({
    totalPages: 0,
    page: 0,
    totalRecords: 0,
    data: [],
  });
  const [outcomeRange, setOutcomeRange] = useState({
    vNegativeMin: 0,
    vNegativeMax: 0,
    negativeMin: 0,
    negativeMax: 0,
    neutralMin: 0,
    neutralMax: 0,
    positiveMin: 0,
    positiveMax: 0,
  });

  const fetchAverageScore = async () => {
    try {
      setLoading(true);
      const params = {
        pageNumber: averagerScoreData.page,
        pageSize: 10,
        orderBy: 'asc',
        sortBy: 'createdAt',
        providerId: meta?.userId,
      };
      if (data.type === 'phq9' || data.type === 'gad7') {
        const res = await getAllPhq9Gad7AverageScore(params, {
          type: data.type === 'phq9' ? 'PHQ-9 Score' : 'GAD-7 Score',
        });
        const dataOutCome = res?.data?.metaData?.outComeRange;
        setOutcomeRange({
          vNegativeMin: dataOutCome?.veryNegativeRange?.minScore,
          vNegativeMax: dataOutCome?.veryNegativeRange?.maxScore,
          negativeMin: dataOutCome?.negativeRange?.minScore,
          negativeMax: dataOutCome?.negativeRange?.maxScore,
          neutralMin: dataOutCome?.neutralRange?.minScore,
          neutralMax: dataOutCome?.neutralRange?.maxScore,
          positiveMin: dataOutCome?.positiveRange?.minScore,
          positiveMax: dataOutCome?.positiveRange?.maxScore,
        });
        setAverageScoreData({
          totalPages: res.data.metaData.totalPages,
          page: averagerScoreData.page + 1,
          totalRecords: res.data.metaData.totalRecords,
          data: [...averagerScoreData.data, ...res.data.data],
        });
      }
    } catch (e) {
      const message = e?.data?.message || 'Something went wrong';
      dispatch(
        showSnackbar({
          snackType: 'error',
          snackMessage: message,
        }),
      );
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (open) void fetchAverageScore();
  }, []);

  const gotoMemberDetail = async item => {
    const queryParams = {
      searchQuery: item?.userNickName,
      pageNumber: 1,
      pageSize: 50,
      orderBy: '',
      sortBy: '',
    };
    await dispatch(profileActionCreators.fetchPatients(queryParams));
    setTimeout(function () {
      history.push(`/provider/members/${item?.userAccountId}?name=dashboard`);
    }, 1500);
  };

  const getColors = count => {
    if (count >= outcomeRange.vNegativeMin && count <= outcomeRange.vNegativeMax) {
      return { borderColor: colors.destructive600, color: colors.destructive600 };
    }
    if (count >= outcomeRange.negativeMin && count <= outcomeRange.negativeMax) {
      return { borderColor: colors.warning600, color: colors.warning600 };
    }
    if (count >= outcomeRange.neutralMin && count <= outcomeRange.neutralMax) {
      return { borderColor: colors.neutral400, color: colors.neutral400 };
    }
    if (count >= outcomeRange.positiveMin && count <= outcomeRange.positiveMax) {
      return { borderColor: colors.success600, color: colors.success600 };
    }
    return { borderColor: colors.neutral400, color: colors.neutral400 };
  };

  return (
    <Drawer
      open={open}
      onClose={() => {
        setAverageScoreData({
          totalPages: 0,
          page: 0,
          totalRecords: 0,
          data: [],
        });
        onClose();
      }}
      className={classes.averageScoreDrawer}
    >
      <DrawerHead
        title={data?.drawerTitle}
        subTitle={
          averagerScoreData?.totalRecords === undefined
            ? '0 completion'
            : averagerScoreData?.totalRecords === 0 || averagerScoreData?.totalRecords === 1
            ? `${averagerScoreData?.totalRecords} completion`
            : `${averagerScoreData?.totalRecords} completions`
        }
        onClose={() => {
          setAverageScoreData({
            totalPages: 0,
            page: 0,
            data: [],
            totalRecords: 0,
          });
          onClose();
        }}
      />
      <Grid className={classes.dataContainer2}>
        <Grid paddingBottom="10px">
          <Box p={3} display="flex" flexDirection="column" gap={3} sx={{ overflowY: 'auto' }}>
            {averagerScoreData?.data.map((item, index) => (
              <Box key={index} display="flex" gap={3} alignItems="center" style={{ cursor: 'pointer' }}>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    width: 48,
                    height: 48,
                    border: `1px solid ${getColors(Number(item?.lastAttemptDetails?.value)).borderColor}`,
                    borderRadius: 1.5,
                  }}
                >
                  <Heading
                    variant={HeadingLevel.SUB}
                    fontWeight={HeadingWeight.BOLD}
                    color={getColors(Number(item?.lastAttemptDetails?.value)).color}
                  >
                    {item?.lastAttemptDetails?.value}
                  </Heading>
                </Box>
                <Box
                  display="flex"
                  gap={1.5}
                  alignItems="center"
                  onClick={() => {
                    void gotoMemberDetail(item);
                  }}
                >
                  <img
                    alt="none avatar icon"
                    className={classes.imageContainer}
                    src={item?.profilePicture ? `${S3_BUCKET_URL}${item?.profilePicture}` : avatarIcon}
                  />
                  <Box display="flex" flexDirection="column" flex={1}>
                    <Typography {...typography.body.normal.small.semibold}>{item?.userNickName}</Typography>
                    <Typography {...typography.body.normal.small.medium} color={colors.neutral600}>
                      {`${item?.uuid} - ${dayjs(item?.dob).format('MM/DD/YYYY')}`}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            ))}
          </Box>
          {!loading && averagerScoreData?.data.length === 0 && (
            <Grid className={classes.noResult}>
              <Typography {...typography.body.normal.small.medium} color={colors.neutral600}>
                No {data.type === 'phq9' ? 'PHQ 9' : 'GAD 7'} available
              </Typography>
            </Grid>
          )}
          {loading && (
            <Box display="flex" justifyContent="center" paddingY="10px">
              <Icons className="rotate linear infinite" glyph="in-progress" color={colors.primary} />
              Loading...
            </Box>
          )}
        </Grid>
        {averagerScoreData.totalPages !== 0 && averagerScoreData.page < averagerScoreData.totalPages && (
          <Box className={classes.btnContainer}>
            <Button
              variant={btnType.TEXT}
              className={classes.loadMoreBtn}
              onClick={() => void fetchAverageScore()}
            >
              Load more
            </Button>
          </Box>
        )}
      </Grid>
    </Drawer>
  );
};

export default AverageScoreDrawer;
