/* eslint-disable max-len */
import cx from 'clsx';

// types
// styles
import { useStyles } from './shared/styles';

const Pencil = ({ className, color, onClick }) => {
  const classes = useStyles();
  return (
    <svg
      className={cx(classes.root, className)}
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M14.596 1.04001L20.943 7.38601C21.0084 7.4513 21.0544 7.53344 21.0759 7.62331C21.0975 7.71318 21.0937 7.80726 21.065 7.89511C21.0363 7.98296 20.9838 8.06113 20.9134 8.12096C20.843 8.18078 20.7573 8.21991 20.666 8.23401L19.192 8.46401L13.536 2.80801L13.748 1.32301C13.7612 1.23118 13.7996 1.14483 13.8591 1.07361C13.9185 1.0024 13.9966 0.949125 14.0846 0.919757C14.1726 0.89039 14.2671 0.886083 14.3574 0.90732C14.4477 0.928557 14.5303 0.974499 14.596 1.04001ZM2.59498 20.15C6.31698 16.819 10.59 15.822 15.238 14.63L15.684 10.612L11.387 6.31501L7.36898 6.76101C6.17698 11.409 5.17998 15.681 1.84898 19.404L0.453979 18.01C3.28198 14.71 4.34398 11.057 5.75698 4.92901L12.121 4.22201L17.778 9.87901L17.071 16.243C10.943 17.657 7.28898 18.718 3.98998 21.546L2.59498 20.15ZM7.87898 14.12C7.68796 13.9355 7.53559 13.7148 7.43078 13.4708C7.32596 13.2268 7.27079 12.9644 7.26848 12.6988C7.26617 12.4333 7.31677 12.1699 7.41734 11.9241C7.5179 11.6783 7.6664 11.455 7.85419 11.2672C8.04198 11.0794 8.26528 10.9309 8.51107 10.8304C8.75686 10.7298 9.02022 10.6792 9.28578 10.6815C9.55134 10.6838 9.81378 10.739 10.0578 10.8438C10.3018 10.9486 10.5225 11.101 10.707 11.292C11.0663 11.6701 11.2637 12.1735 11.2571 12.6951C11.2505 13.2166 11.0405 13.7149 10.6717 14.0838C10.303 14.4526 9.80475 14.6629 9.28322 14.6696C8.7617 14.6764 8.25817 14.4792 7.87998 14.12H7.87898Z"
        fill={color}
      />
    </svg>
  );
};

export default Pencil;
