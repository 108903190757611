import React, { useEffect, useMemo, useState } from 'react';
import { Box, Stack } from '@mui/material';
import clsx from 'clsx';

import {
  btnType,
  Button,
  fontWeight,
  Heading,
  headingLevel,
  iconBtnType,
  IconButton,
  Icons,
  Input,
  inputSize,
  inputType,
  Text,
  TextError,
} from '../../../../../packages';
import { REPORT_FILTERS } from '../../../../../constants/CommonConstants';
import { useDispatch, useSelector } from 'react-redux';
import { profileActionCreators } from '../../../../../redux/modules/profile';
import { getProfile } from '../../../../../redux/modules/profile/selectors';

import AutomationFilterForm from './automation-filter-form';
import { useStyles } from './ReportFilters.styles';
import { filterLogicSelectOptions } from './automation-filter-form/AutomationFilterForm.constants';

const ReportFilters = ({ onClose, onContinue, name, filters, referralPartnerId }) => {
  const classes = useStyles();
  const [reportName, setReportName] = useState(name || '');
  const [errorMsg, setErrorMsg] = useState(null);
  const [currentFilters, setCurrentFilters] = useState(filters);
  const [selectedReferralPartner, setSelectedReferralPartner] = useState(null);
  const profile = useSelector(getProfile);
  const dispatch = useDispatch();

  const getFormatQueryParams = () => {
    return {
      pageNumber: 0,
      pageSize: 10000,
      search: '',
    };
  };

  const referralPartnerOptions = useMemo(
    () =>
      profile?.referralPartners?.partners?.map(rPartners => {
        return {
          label: rPartners.name,
          value: rPartners.refPartnerId,
        };
      }) || [],
    [profile?.referralPartners?.partners],
  );

  const fetchReferralPartnersCall = () => {
    dispatch(profileActionCreators.fetchReferralPartners(getFormatQueryParams()));
  };

  const onAddFilterClick = () => {
    if (currentFilters?.length)
      setCurrentFilters([
        ...currentFilters,
        {
          type: '',
          profileElement: '',
          automationRule: '',
          automationValue: '',
          openForm: true,
          states: [],
          memberState: '',
          insuranceCarriers: [],
          levelOfEngagements: [],
          providers: [],
        },
      ]);
    else
      setCurrentFilters([
        {
          type: '',
          profileElement: '',
          automationRule: '',
          automationValue: '',
          openForm: true,
          states: [],
          memberState: '',
          insuranceCarriers: [],
          levelOfEngagements: [],
          providers: [],
        },
      ]);
  };

  const onContinueClick = () => {
    if (reportName.trim().length === 0) {
      setErrorMsg('Report name is required');
      return;
    }
    onContinue({
      filters: currentFilters,
      reportName,
      referralPartner: selectedReferralPartner?.value || '',
    });
  };

  const onChangeReportName = event => {
    if (errorMsg) {
      setErrorMsg(null);
    }
    setReportName(event.target.value);
  };

  const onChangeReferralPartner = (_, value) => {
    if (errorMsg) {
      setErrorMsg(null);
    }
    setSelectedReferralPartner(value);
  };
  const onDeleteFilter = filterIndex => () => {
    const newFilters = JSON.parse(JSON.stringify(currentFilters));
    newFilters.splice(filterIndex, 1);
    setCurrentFilters(newFilters);
  };
  const onChangeFilter = filterIndex => payload => {
    setCurrentFilters(s => s.map((filter, index) => (index === filterIndex ? payload : filter)));
  };

  useEffect(() => {
    fetchReferralPartnersCall();
  }, []);
  useEffect(() => {
    if (referralPartnerId) {
      const foundReferralPartner = referralPartnerOptions?.find(item => item.value === referralPartnerId);
      if (foundReferralPartner) {
        setSelectedReferralPartner(foundReferralPartner);
      }
    }
  }, [referralPartnerOptions]);

  return (
    <Box className={classes.container}>
      <Box className={clsx(classes.topWrap, classes.topWrap2)}>
        <Box className={classes.section}>
          <Heading level={headingLevel.S} className={classes.label} weight={fontWeight.BOLD}>
            Report name
          </Heading>
          <Input
            value={reportName}
            name="name"
            placeholder="Report name"
            onChange={onChangeReportName}
            size={inputSize.M}
            fullWidth
          />
          <TextError errorMsg={errorMsg} />
        </Box>
      </Box>
      <Box className={classes.topWrap}>
        <Box className={classes.section}>
          <Heading level={headingLevel.S} className={classes.label} weight={fontWeight.BOLD}>
            Referral partner
          </Heading>
          <Input
            fullWidth
            name="referralPartner"
            value={selectedReferralPartner || null}
            options={referralPartnerOptions}
            getOptionLabel={option => `${option.label}`}
            renderOption={(props, option) => (
              <Box key={option.value} {...props}>
                {option.label}
              </Box>
            )}
            size={inputSize.M}
            placeholder="Select referral partner"
            variant={inputType.AUTOCOMPLETE}
            onChange={onChangeReferralPartner}
          />

          {/* <TextError errorMsg={errorMsg} /> */}
        </Box>
      </Box>
      <Box className={classes.topWrap}>
        <Box className={classes.section}>
          <Heading level={headingLevel.S} className={classes.label} weight={fontWeight.BOLD}>
            Referral partner
          </Heading>
          <Input
            fullWidth
            name="referralPartner"
            value={selectedReferralPartner || null}
            options={referralPartnerOptions}
            getOptionLabel={option => `${option.label}`}
            renderOption={(props, option) => (
              <Box key={option.value} {...props}>
                {option.label}
              </Box>
            )}
            size={inputSize.M}
            placeholder="Select referral partner"
            variant={inputType.AUTOCOMPLETE}
            onChange={onChangeReferralPartner}
          />

          {/* <TextError errorMsg={errorMsg} /> */}
        </Box>
      </Box>
      <Box className={classes.topWrap}>
        <Stack direction="row" gap={1.2} alignItems="center">
          <Icons glyph="filter" />
          <Text className={classes.title}>Filters</Text>
        </Stack>
        <IconButton
          icon="plus"
          variant={iconBtnType.OUTLINE}
          className={classes.addFilterBtn}
          onClick={onAddFilterClick}
        >
          Add filter
        </IconButton>
      </Box>
      <Box className={classes.contentWrap}>
        <Box className={classes.forms}>
          {currentFilters?.length > 0 ? (
            currentFilters.map((filter, index) => (
              <AutomationFilterForm
                key={index}
                filter={filter}
                filterLogicOptions={[
                  ...filterLogicSelectOptions.filter(
                    logic =>
                      !currentFilters.map(f => f.type)?.includes(logic.value) ||
                      logic.value === REPORT_FILTERS.PROFILE_ELEMENT ||
                      logic.value === REPORT_FILTERS.INSURANCE,
                  ),
                  filterLogicSelectOptions?.find(logic => logic.value === filter.type),
                ]}
                open={filter.openForm}
                onDelete={onDeleteFilter(index)}
                onChange={onChangeFilter(index)}
              />
            ))
          ) : (
            <Text className={classes.noFilter}>Click to add more filter</Text>
          )}
        </Box>
        <Box className={classes.footer}>
          <Button variant={btnType.TEXT} onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={onContinueClick}>Continue</Button>
        </Box>
      </Box>
    </Box>
  );
};

export { ReportFilters };
