import { useCallback, useState } from 'react';
import { Box } from '@mui/material';
import clsx from 'clsx';
import dayjs from 'dayjs';

import { Badge, badgeType, Heading, iconBtnPosition, IconButton, Menu } from '../../../../../packages';
import { Filter, Table } from '../../../../../packages/ui/organisms/table';
import { ProfileInfo } from '../../../../../packages/ui/templates/profile-info';

import {
  assignmentColumns,
  conversationStatus,
  filterOptionsMock,
  statusStyle,
} from './ConversationTableCard.mock';
import { useStyles } from './ConversationTableCard.styles';

const ConversationTableCard = ({ conversation }) => {
  const classes = useStyles();
  const [tableParams, setTableParams] = useState({
    search: { searchKey: '' },
    pagination: { currentPage: 1, rowsPerPage: 10 },
    sorter: { direction: 'asc', column: '' },
  });

  const renderColumns = assignmentColumns.map(column => {
    if (column.id === 'patientName') {
      return {
        ...column,
        renderCell: ({ patientName, userAccountId, image }) => (
          <ProfileInfo
            type="member"
            nickName={patientName}
            memberId={userAccountId}
            fullName={patientName}
            photo={image}
          />
        ),
      };
    }
    if (column.id === 'status') {
      return {
        ...column,
        renderCell: status => (
          <Badge
            style={statusStyle[status]}
            variant={badgeType.FILLED}
            className={clsx(classes.badge, classes.statusBadge)}
          >
            {conversationStatus[status]}
          </Badge>
        ),
      };
    }
    if (column.id === 'date') {
      return {
        ...column,
        renderCell: date => dayjs(date).format('MM/DD/YYYY'),
      };
    }
    if (column.id === 'actions') {
      return {
        ...column,
        renderCell: () => (
          <Menu
            icon="more"
            className={classes.menu}
            itemsWrapperClassName={classes.menuItemsWrapper}
            items={[{ label: 'Menu Item' }, { label: 'Menu Item' }, { label: 'Menu Item' }]}
          />
        ),
      };
    }
    return column;
  });

  const formatList = list =>
    list.map(item => ({
      ...item,
      patientName: {
        patientName: item.patientName,
        patientId: item.patientId,
        userAccountId: item.userAccountId,
        image: item.imageUrl,
      },
      actions: {
        conversationId: item.id,
      },
    }));
  const dataSourceCallback = useCallback(() => {
    const { search, sorter, pagination } = tableParams;
    const currentPage = pagination?.currentPage || 1;
    const rowsPerPage = pagination?.rowsPerPage || 6;
    const searchKey = search?.searchKey.toLowerCase();
    const { filter } = search;
    let appointmentsFiltered = [];

    if (conversation?.assignedTo?.length > 0)
      appointmentsFiltered = conversation.assignedTo
        ?.filter(item => {
          if (searchKey) {
            return item?.patientName.toLowerCase().includes(searchKey);
          }
          return true;
        })
        .filter(item => (!filter ? true : conversationStatus[item.status] === filter))
        .sort((a, b) => {
          const sortColumn = sorter?.column;
          const compare = a[sortColumn]?.toString()?.localeCompare(b[sortColumn]?.toString());
          return sorter?.direction === 'desc' ? compare * -1 : compare;
        })
        .slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage);

    return formatList(appointmentsFiltered);
  }, [tableParams]);

  const dataSource = dataSourceCallback();

  return (
    <Box className={classes.card}>
      <Box className={classes.head}>
        <Heading className={classes.title}>Conversation assignments</Heading>
        <IconButton icon="export" iconPosition={iconBtnPosition.RIGHT} className={classes.exportBtn} disabled>
          Export
        </IconButton>
      </Box>
      <Box>
        <Table
          searchProps={{
            placeholder: 'Search',
            filterProps: {
              variant: Filter.tableFilterType.SINGLE,
              options: filterOptionsMock,
              allOptionLabel: 'Select Status',
            },
          }}
          gridProps={{
            columns: renderColumns,
            data: dataSource,
            isLoading: false,
          }}
          paginationProps={{
            currentRows: tableParams.pagination.currentPage,
            totalCount: dataSource.length,
            showRowsPerPage: true,
          }}
          value={tableParams}
          onChange={setTableParams}
          className={classes.table}
        />
      </Box>
    </Box>
  );
};

export { ConversationTableCard };
