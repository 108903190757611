import { useEffect, useState } from 'react';
import cx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Grid } from '@mui/material';
import MuiInputAdornment from '@mui/material/InputAdornment';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { btnType, Button, colors, Icons, Input } from '../../packages';

import { getAuth } from '../../redux/modules/auth/selectors';
import { authActionCreators } from '../../redux/modules/auth';
import { LoginType } from '../../constants/CommonConstants';

import Heading from '../../components/v2/Heading';
import { HeadingFont, HeadingLevel } from '../../components/v2/Heading/index.types';
import Typography from '../../components/v2/Typography';
import { typography } from '../../components/v2/Typography/index.constant';
import VSlider from '../../components/v2/VSlider';
import { LoginSchema } from './LoginSchema';
import { useStyles } from './Login.styles';
import './login.css';

const Login = () => {
  const [showPassword, setShowPassword] = useState(true);
  const { isLoading, isAuthenticated, errorMsg, requiresOTP, linkToNavigate } = useSelector(getAuth);
  const [email, setEmail] = useState('');
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      type: LoginType.PROVIDER,
      email: '',
      password: '',
    },
    validationSchema: LoginSchema,
    onSubmit: values => {
      setEmail(values.email);
      dispatch(authActionCreators.login(values));
    },
  });
  const classes = useStyles();
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      const link =
        linkToNavigate && linkToNavigate.length > 0 && linkToNavigate !== '/login' ? linkToNavigate : '/';
      navigate(link);
      setTimeout(() => {
        dispatch(authActionCreators.authenticatedLinkNavigation(''));
      }, 10);
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (requiresOTP) {
      navigate('/twofactorauthentication', {
        state: {
          emailOrPhone: email,
        },
      });
      // navigate.navigate()
    }
  }, [requiresOTP]);

  const switchLogin = () => {
    formik.handleChange({
      target: {
        name: 'type',
        value: formik.values.type === LoginType.ADMIN ? LoginType.PROVIDER : LoginType.ADMIN,
      },
    });
  };

  const onClickEye = () => {
    setShowPassword(!showPassword);
  };

  const onBlurInput = async e => {
    await formik.setFieldTouched(e.target.name);
  };

  const onClickForgotPassword = () => {
    navigate('/forgot-password');
  };

  return (
    <div className={cx({ [classes.root]: true })}>
      <Grid container>
        <Grid item xs={12} sm={6}>
          <Box width="100%" height="100%" display="flex" justifyContent="center" alignItems="center">
            <Box width={448} display="flex" alignItems="center">
              <Box className={classes.flex} gap={7} flex={1}>
                <Box className={classes.between}>
                  <Heading
                    variant={HeadingLevel.H2}
                    fontFamily={HeadingFont.SECONDARY}
                    letterSpacing="-0.7px"
                  >
                    Log in as{' '}
                    {formik.values.type === LoginType.ADMIN
                      ? LoginType.ADMIN_LABEL.toLowerCase()
                      : LoginType.PROVIDER_LABEL.toLowerCase()}
                  </Heading>
                  <Button
                    variant={btnType.OUTLINE}
                    className={classes.outlineBtn}
                    onClick={switchLogin}
                    id="login-type-switcher"
                  >
                    Log in as{' '}
                    {formik.values.type === LoginType.ADMIN
                      ? LoginType.PROVIDER_LABEL
                      : LoginType.ADMIN_LABEL}
                  </Button>
                </Box>
                <Box className={classes.flex} gap={4}>
                  {errorMsg && (
                    <Box className={classes.errorMsg}>
                      <Icons
                        glyph="info-outlined"
                        color={colors.destructive500}
                        className={classes.errorIcon}
                      />
                      <Typography {...typography.body.s.medium}>{errorMsg}</Typography>
                    </Box>
                  )}
                  <Box display="flex" flexDirection="column" gap={2}>
                    <form onSubmit={formik.handleSubmit} className={classes.form}>
                      <div className={classes.inputContainer}>
                        <Typography {...typography.body.normal.medium.semibold}>Email</Typography>
                        <Input
                          className={classes.input}
                          placeholder="Enter your email"
                          name="email"
                          onChange={formik.handleChange}
                          onBlur={onBlurInput}
                          InputProps={{
                            startAdornment: (
                              <MuiInputAdornment position="start">
                                <Icons
                                  glyph="email-outlined"
                                  color={formik.errors.email ? colors.destructive600 : colors.neutral500}
                                />
                              </MuiInputAdornment>
                            ),
                          }}
                        />
                        {formik.touched.email ? (
                          <Typography {...typography.body.normal.small.medium} color={colors.destructive600}>
                            {formik.errors.email?.toString()}
                          </Typography>
                        ) : null}
                      </div>
                      <div className={classes.inputContainer}>
                        <Typography {...typography.body.normal.medium.semibold}>Password</Typography>
                        <Input
                          className={classes.input}
                          name="password"
                          placeholder="Enter your password"
                          type={showPassword ? 'password' : 'text'}
                          onChange={formik.handleChange}
                          onBlur={onBlurInput}
                          InputProps={{
                            startAdornment: (
                              <MuiInputAdornment position="start">
                                <Icons glyph="password-lock" color={colors.neutral500} />
                              </MuiInputAdornment>
                            ),
                            endAdornment: (
                              <MuiInputAdornment position="end">
                                <Icons
                                  glyph="eye-outlined"
                                  className={classes.eye}
                                  color={colors.neutral500}
                                  onClick={onClickEye}
                                />
                              </MuiInputAdornment>
                            ),
                          }}
                        />
                        {formik.touched.password ? (
                          <Typography {...typography.body.normal.small.medium} color={colors.destructive600}>
                            {formik.errors.password?.toString()}
                          </Typography>
                        ) : null}
                      </div>
                      <div className={classes.inputContainer}>
                        <Button
                          className={classes.buttonContainer}
                          onClick={formik.handleSubmit}
                          disabled={(!formik.values.email && !formik.values.password) || isLoading}
                        >
                          {isLoading ? (
                            <Icons
                              className="rotate linear infinite"
                              glyph="in-progress"
                              color={colors.white}
                            />
                          ) : (
                            'Login'
                          )}
                        </Button>
                      </div>
                    </form>
                    {formik.values.type === LoginType.PROVIDER ? (
                      <div className={classes.inputContainer}>
                        <Button
                          onClick={onClickForgotPassword}
                          variant={btnType.TEXT}
                          className={classes.buttonTextContainer}
                        >
                          Forgot password?
                        </Button>
                      </div>
                    ) : null}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box className={classes.rightContainer} sx={{ px: { xs: 5, sm: 5, md: 10 } }}>
            <Box className={classes.startBackground} />
            <VSlider />
            <Box className={classes.endBackground} />
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export { Login };
