/* eslint-disable no-underscore-dangle */
/* eslint-disable max-len */
import { DctType, ReportFilterRule } from '../../../../../redux/modules/reporting/types';
import { REPORT_FILTERS } from '../../../../../constants/CommonConstants';
import { toDateString } from '../../../../../utils/CommonUtils';
import {
  getReportAllFiltersSubQuery,
  getReportAllFiltersSubQueryReferralPartner,
  getReportFilterSubQuery,
  getReportInternalFiltersSubQuery,
  REPORT_CONSTANTS,
  REPORT_SECTION_NAMES,
} from '../../ReportDetail.constants';

export const DCT_TABLES = {
  mostCompletedMembers: 'mostCompletedMembers',
  leastCompletedMembers: 'leastCompletedMembers',
  mostCompletedAttemps: 'mostCompletedAttemps',
  leastCompletedAttemps: 'leastCompletedAttemps',
  averageInitialScore: 'averageInitialScore',
  averageCurrentScore: 'averageCurrentScore',
  greatestChangeInScore: 'greatestChangeInScore',
  averageNumberOfCompletions: 'averageNumberOfCompletions',
};
const boxDataMap = {
  'Total DCT’s': 'totalDCTs',
  'Total completed': 'totalCompleted',
  'Total attempts': 'totalAttempts',
};

export const getBoxData = (dctReport, report, dctType) => {
  const boxData = [
    { label: 'Total DCT’s', value: dctReport?.aggregations.total_dcts.value ?? 0 },
    { label: 'Total completed', value: dctReport?.aggregations.total_completed.doc_count ?? 0 },
    { label: 'Total attempts', value: dctReport?.aggregations.total_attempts.doc_count ?? 0 },
  ];

  const dctSection =
    dctType === DctType.CHATBOT ? report?.data?.chatbotDctSection : report?.data?.evaluationDctSection;

  return boxData.filter(item => dctSection[boxDataMap[item.label]]?.isChecked) ?? [];
};

export const normalizePastDaysCounts = (buckets, size) => {
  const data = [];
  let lastDate = null;

  const bucketData = buckets ? buckets.sort((a, b) => a.key - b.key) : [];

  let i = 0;
  for (i = 0; i < bucketData.length; i++) {
    if (lastDate) {
      const diff = (bucketData[i].key - lastDate) / 86400000;
      if (diff > 1) {
        for (let j = 0; j < diff - 1; j++) {
          data.push(0);
        }
      }
      data.push(bucketData[i].doc_count);
    } else {
      data.push(bucketData[i].doc_count);
    }
    lastDate = bucketData[i].key;
  }

  if (bucketData.length > 0 && bucketData[i - 1].key < Date.now()) {
    const diff = (Date.now() - bucketData[i - 1].key) / 86400000;
    for (let j = 0; j < diff - 1; j++) {
      data.push(0);
    }
  }

  let dummyData = [];
  // if the data is less than 30 days, fill the rest with 0
  if (data.length < size) {
    const days = size - data.length;
    dummyData = new Array(days).fill(0);
  }
  return dummyData.concat(data);
};

export const getLast30DaysCounts = report => {
  return normalizePastDaysCounts(report ? report.aggregations.last_30_days.counts.buckets : null, 30);
};

export const getPast30DaysCounts = report => {
  return normalizePastDaysCounts(report ? report.aggregations.past_30_days.counts.buckets : null, 30);
};

export const getMostCompletedDcts = report =>
  report
    ? report.aggregations.most_completed.by_members.buckets.map(bucket => ({
        name: bucket.key,
        total: bucket.uniqueMembers?.value,
        lastUpdated: toDateString(bucket.last_assigned_date.hits.hits[0]._source.lastModifiedDate),
      }))
    : [];

export const getLeastCompletedDcts = report =>
  report
    ? report.aggregations.least_completed.by_members.buckets.map(bucket => ({
        name: bucket.key,
        total: bucket.uniqueMembers?.value,
        lastUpdated: toDateString(bucket.last_assigned_date.hits.hits[0]._source.lastModifiedDate),
      }))
    : [];

export const getMostAttemptedDcts = report =>
  report
    ? report.aggregations.most_attempted.by_members.buckets.map(bucket => ({
        name: bucket.key,
        total: bucket.doc_count,
        lastUpdated: toDateString(bucket.last_assigned_date.hits.hits[0]._source.lastModifiedDate),
      }))
    : [];

export const getLeastAttemptedDcts = report =>
  report
    ? report.aggregations.least_attempted.by_members.buckets.map(bucket => ({
        name: bucket.key,
        total: bucket.doc_count,
        lastUpdated: toDateString(bucket.last_assigned_date.hits.hits[0]._source.lastModifiedDate),
      }))
    : [];

export const getAverageInitialScore = report =>
  report
    ? report.aggregations.average_initial_score.buckets.map(bucket => ({
        name: bucket.key,
        total: bucket.avg_score.value,
        lastUpdated: toDateString(bucket.last_assigned_date.hits.hits[0]._source.lastModifiedDate),
      }))
    : [];

export const getAverageCurrentScore = report =>
  report
    ? report.aggregations.average_current_score.buckets.map(bucket => ({
        name: bucket.key,
        total: bucket.avg_score.value,
        lastUpdated: toDateString(bucket.last_assigned_date.hits.hits[0]._source.lastModifiedDate),
      }))
    : [];

export const getGreatestChangeInScore = report =>
  report
    ? report.aggregations.greatest_change_in_score.buckets.map(bucket => ({
        name: bucket.key,
        change: bucket.change_score.value,
        lastUpdated: toDateString(bucket.last_assigned_date.hits.hits[0]._source.lastModifiedDate),
      }))
    : [];

export const getAverageNumberOfCompletions = report =>
  report
    ? report.aggregations.average_number_completion.buckets.map(bucket => ({
        name: bucket.key,
        avg: bucket.avg_score.value,
        lastUpdated: toDateString(bucket.last_assigned_date.hits.hits[0]._source.lastModifiedDate),
      }))
    : [];

export const memberColumns = [
  {
    id: 'name',
    label: 'DCT',
    sortable: false,
  },
  {
    id: 'total',
    label: 'Members',
    sortable: false,
    textAlign: 'center',
    className: 'color-secondary-500',
  },
  {
    id: 'lastUpdated',
    label: 'Last',
    sortable: false,
  },
  {
    id: 'actions',
    label: '',
    sortable: false,
  },
];

export const attemptColumns = [
  {
    id: 'name',
    label: 'DCT',
    sortable: false,
  },
  {
    id: 'total',
    label: 'Attempts',
    sortable: false,
    textAlign: 'center',
    className: 'color-secondary-500',
  },
  {
    id: 'lastUpdated',
    label: 'Last',
    sortable: false,
  },
  {
    id: 'actions',
    label: '',
    sortable: false,
  },
];

export const scoreColumns = [
  {
    id: 'name',
    label: 'DCT',
    sortable: false,
  },
  {
    id: 'total',
    label: 'Score',
    sortable: false,
    textAlign: 'center',
    className: 'color-secondary-500',
  },
  {
    id: 'lastUpdated',
    label: 'Last',
    sortable: false,
  },
  {
    id: 'actions',
    label: '',
    sortable: false,
  },
];

export const changeColumns = [
  {
    id: 'name',
    label: 'DCT',
    sortable: false,
  },
  {
    id: 'change',
    label: 'Change',
    sortable: false,
    textAlign: 'center',
  },
];

export const avgColumns = [
  {
    id: 'name',
    label: 'DCT',
    sortable: false,
  },
  {
    id: 'avg',
    label: 'Avg number',
    sortable: false,
    textAlign: 'center',
    className: 'color-secondary-500',
  },
  {
    id: 'lastUpdated',
    label: 'Last',
    sortable: false,
  },
  {
    id: 'actions',
    label: '',
    sortable: false,
  },
];

export const getChatbotDctReportQuery = report => {
  let allFilters = JSON.parse(JSON.stringify(report.reportFilters));
  if (report?.reportFilters?.length || report?.referralPartnerId) {
    const allInsuranceFilters = report.reportFilters?.filter(
      filter => filter.type === REPORT_FILTERS.INSURANCE,
    );
    if (allInsuranceFilters?.length) {
      allFilters = [
        ...report.reportFilters?.filter(filter => filter.type !== REPORT_FILTERS.INSURANCE),
        {
          type: REPORT_FILTERS.INSURANCE,
          insurance: {
            carriers: allInsuranceFilters?.map(filter => filter.insurance?.carriers)?.flat(),
            state: '',
          },
          value: '',
          profileElement: '',
          states: [],
          levelOfEngagements: [],
          providers: [],
          rule: ReportFilterRule.EQUALS,
        },
      ];
    }
    return {
      size: 0,
      query: {
        bool: {
          ...(report?.data?.chatbotDctSection?.dataCollectionTemplateIds?.dctTemplateIds?.length
            ? {
                filter: [
                  {
                    terms: {
                      'dct_id.keyword':
                        report.data.chatbotDctSection.dataCollectionTemplateIds.dctTemplateIds,
                    },
                  },
                ],
              }
            : {}),
          must: [
            ...allFilters?.map(filter => filter.profileElement && getReportFilterSubQuery(filter)),
            ...allFilters?.map(filter => getReportAllFiltersSubQuery(filter)),
            ...getReportAllFiltersSubQueryReferralPartner(report?.referralPartnerId),
            ...getReportInternalFiltersSubQuery(report, REPORT_SECTION_NAMES.chatbotDctSection),
          ]?.filter(obj => Object.keys(obj)?.length > 0),
        },
      },
      aggs: {
        total_members: {
          cardinality: {
            field: 'member_id.keyword',
          },
        },
        total_dcts: {
          cardinality: {
            field: 'dct_name.keyword',
          },
        },
        total_completed: {
          filter: {
            term: {
              'status.keyword': 'COMPLETED',
            },
          },
        },
        total_attempts: {
          filter: {
            exists: {
              field: 'total_attempt',
            },
          },
        },
        last_30_days: {
          filter: {
            bool: {
              must: [
                {
                  term: {
                    'status.keyword': {
                      value: 'COMPLETED',
                    },
                  },
                },
                {
                  range: {
                    lastModifiedDate: {
                      gte: 'now-35d/d',
                      lte: 'now',
                    },
                  },
                },
              ],
            },
          },
          aggs: {
            counts: {
              date_histogram: {
                field: 'lastModifiedDate',
                calendar_interval: '1d',
              },
            },
          },
        },
        past_30_days: {
          filter: {
            bool: {
              must: [
                {
                  term: {
                    'status.keyword': {
                      value: 'COMPLETED',
                    },
                  },
                },
                {
                  range: {
                    lastModifiedDate: {
                      gte: 'now-60d/d',
                      lt: 'now-30d/d',
                    },
                  },
                },
              ],
            },
          },
          aggs: {
            counts: {
              date_histogram: {
                field: 'lastModifiedDate',
                calendar_interval: '1d',
              },
            },
          },
        },
        last_assigned: {
          top_hits: {
            _source: 'lastModifiedDate',
            sort: [{ lastModifiedDate: { order: 'desc' } }],
            size: 1,
          },
        },
        most_completed: {
          filter: {
            term: {
              'status.keyword': 'COMPLETED',
            },
          },
          aggs: {
            by_members: {
              terms: {
                field: 'dct_name.keyword',
                size: REPORT_CONSTANTS.MAX_RECORDS_IN_QUERY,
                order: {
                  _count: 'desc',
                  uniqueMembers: 'desc',
                },
              },
              aggs: {
                uniqueMembers: {
                  cardinality: {
                    field: 'member_id.keyword',
                  },
                },
                last_assigned_date: {
                  top_hits: {
                    _source: 'lastModifiedDate',
                    sort: [
                      {
                        lastModifiedDate: {
                          order: 'desc',
                        },
                      },
                    ],
                    size: 1,
                  },
                },
              },
            },
          },
        },

        least_completed: {
          filter: {
            term: {
              'status.keyword': 'COMPLETED',
            },
          },
          aggs: {
            by_members: {
              terms: {
                field: 'dct_name.keyword',
                size: REPORT_CONSTANTS.MAX_RECORDS_IN_QUERY,
                order: {
                  _count: 'asc',
                  uniqueMembers: 'asc',
                },
              },
              aggs: {
                uniqueMembers: {
                  cardinality: {
                    field: 'member_id.keyword',
                  },
                },
                last_assigned_date: {
                  top_hits: {
                    _source: 'lastModifiedDate',
                    sort: [
                      {
                        lastModifiedDate: {
                          order: 'desc',
                        },
                      },
                    ],
                    size: 1,
                  },
                },
              },
            },
          },
        },
        most_attempted: {
          filter: {
            exists: {
              field: 'total_attempt',
            },
          },
          aggs: {
            by_members: {
              terms: {
                field: 'dct_name.keyword',
                size: REPORT_CONSTANTS.MAX_RECORDS_IN_QUERY,
              },
              aggs: {
                last_assigned_date: {
                  top_hits: {
                    _source: 'lastModifiedDate',
                    sort: [{ lastModifiedDate: { order: 'desc' } }],
                    size: 1,
                  },
                },
              },
            },
          },
        },
        least_attempted: {
          filter: {
            exists: {
              field: 'total_attempt',
            },
          },
          aggs: {
            by_members: {
              terms: {
                field: 'dct_name.keyword',
                size: REPORT_CONSTANTS.MAX_RECORDS_IN_QUERY,
                order: {
                  _count: 'asc',
                },
              },
              aggs: {
                last_assigned_date: {
                  top_hits: {
                    _source: 'lastModifiedDate',
                    sort: [{ lastModifiedDate: { order: 'desc' } }],
                    size: 1,
                  },
                },
              },
            },
          },
        },
        most_scored: {
          terms: {
            field: 'dct_name.keyword',
            size: REPORT_CONSTANTS.MAX_RECORDS_IN_QUERY,
          },
          aggs: {
            total_score: {
              sum: {
                field: 'total_score',
              },
            },
            bucket_sort: {
              bucket_sort: {
                sort: [{ total_score: { order: 'desc' } }],
              },
            },
            last_assigned_date: {
              top_hits: {
                _source: 'lastModifiedDate',
                sort: [{ lastModifiedDate: { order: 'desc' } }],
                size: 1,
              },
            },
          },
        },
        average_current_score: {
          terms: {
            field: 'dct_name.keyword',
            size: REPORT_CONSTANTS.MAX_RECORDS_IN_QUERY,
          },
          aggs: {
            members: {
              cardinality: {
                field: 'member_id.keyword',
              },
            },
            member_list: {
              terms: {
                field: 'member_name.keyword',
                size: REPORT_CONSTANTS.MAX_RECORDS_IN_QUERY,
              },
              aggs: {
                initial_amount: {
                  scripted_metric: {
                    map_script: `
                if (state.oldest_date == null || doc.lastModifiedDate.value.isAfter(state.oldest_date)) {
                  state.oldest_date = doc.lastModifiedDate.value;
                   if(doc['total_score'].size() > 0)
                  {
                    state.total_score = doc['total_score'].value;
                  }else{
                    state.total_score = 0;
                  }
                }`,
                    combine_script: 'return state',
                    reduce_script:
                      'return states.stream().min(Comparator.comparing(i->i.oldest_date)).map(i->i.total_score).get()',
                  },
                },
              },
            },
            score: {
              sum_bucket: {
                buckets_path: 'member_list>initial_amount.value',
              },
            },
            avg_score: {
              bucket_script: {
                buckets_path: {
                  tShirtSales: 'score',
                  totalSales: 'members',
                },
                script: '(int) (params.tShirtSales / params.totalSales)',
              },
            },
            last_assigned_date: {
              top_hits: {
                _source: 'lastModifiedDate',
                sort: [{ lastModifiedDate: { order: 'desc' } }],
                size: 1,
              },
            },
            final_sort: {
              bucket_sort: {
                sort: [{ avg_score: { order: 'desc' } }],
              },
            },
          },
        },
        average_initial_score: {
          terms: {
            field: 'dct_name.keyword',
            size: REPORT_CONSTANTS.MAX_RECORDS_IN_QUERY,
          },
          aggs: {
            members: {
              cardinality: {
                field: 'member_id.keyword',
              },
            },
            member_list: {
              terms: {
                field: 'member_name.keyword',
                size: REPORT_CONSTANTS.MAX_RECORDS_IN_QUERY,
              },
              aggs: {
                initial_amount: {
                  scripted_metric: {
                    map_script: `
                if (state.oldest_date == null || doc.lastModifiedDate.value.isBefore(state.oldest_date)) {
                  state.oldest_date = doc.lastModifiedDate.value;
                   if(doc['total_score'].size() > 0)
                  {
                    state.total_score = doc['total_score'].value;
                  }else{
                    state.total_score = 0;
                  }
                }`,
                    combine_script: 'return state',
                    reduce_script:
                      'return states.stream().min(Comparator.comparing(i->i.oldest_date)).map(i->i.total_score).get()',
                  },
                },
              },
            },
            score: {
              sum_bucket: {
                buckets_path: 'member_list>initial_amount.value',
              },
            },
            avg_score: {
              bucket_script: {
                buckets_path: {
                  tShirtSales: 'score',
                  totalSales: 'members',
                },
                script: '(int) (params.tShirtSales / params.totalSales)',
              },
            },
            last_assigned_date: {
              top_hits: {
                _source: 'lastModifiedDate',
                sort: [{ lastModifiedDate: { order: 'desc' } }],
                size: 1,
              },
            },
            final_sort: {
              bucket_sort: {
                sort: [{ avg_score: { order: 'desc' } }],
              },
            },
          },
        },
        greatest_change_in_score: {
          terms: {
            field: 'dct_name.keyword',
            size: REPORT_CONSTANTS.MAX_RECORDS_IN_QUERY,
          },
          aggs: {
            members: {
              cardinality: {
                field: 'member_id.keyword',
              },
            },
            member_list: {
              terms: {
                field: 'member_name.keyword',
                size: REPORT_CONSTANTS.MAX_RECORDS_IN_QUERY,
              },
              aggs: {
                initial_score: {
                  scripted_metric: {
                    map_script: `
                if (state.oldest_date == null || doc.lastModifiedDate.value.isBefore(state.oldest_date)) {
                  state.oldest_date = doc.lastModifiedDate.value;
                   if(doc['total_score'].size() > 0)
                  {
                    state.total_score = doc['total_score'].value;
                  }else{
                    state.total_score = 0;
                  }
                }`,
                    combine_script: 'return state',
                    reduce_script:
                      'return states.stream().min(Comparator.comparing(i->i.oldest_date)).map(i->i.total_score).get()',
                  },
                },
                current_score: {
                  scripted_metric: {
                    map_script: `
                if (state.oldest_date == null || doc.lastModifiedDate.value.isAfter(state.oldest_date)) {
                  state.oldest_date = doc.lastModifiedDate.value;
                   if(doc['total_score'].size() > 0)
                  {
                    state.total_score = doc['total_score'].value;
                  }else{
                    state.total_score = 0;
                  }
                }`,
                    combine_script: 'return state',
                    reduce_script:
                      'return states.stream().min(Comparator.comparing(i->i.oldest_date)).map(i->i.total_score).get()',
                  },
                },
              },
            },
            initial_total_score: {
              sum_bucket: {
                buckets_path: 'member_list>initial_score.value',
              },
            },
            current_total_score: {
              sum_bucket: {
                buckets_path: 'member_list>current_score.value',
              },
            },
            change_score: {
              bucket_script: {
                buckets_path: {
                  initialScore: 'initial_total_score',
                  totalScore: 'current_total_score',
                },
                script: '(int)  (( (params.initialScore - params.totalScore)/params.initialScore ) * 100)',
              },
            },
            last_assigned_date: {
              top_hits: {
                _source: 'lastModifiedDate',
                sort: [{ lastModifiedDate: { order: 'desc' } }],
                size: 1,
              },
            },
            final_sort: {
              bucket_sort: {
                sort: [{ change_score: { order: 'desc' } }],
              },
            },
          },
        },
        average_number_completion: {
          terms: {
            field: 'dct_name.keyword',
            size: REPORT_CONSTANTS.MAX_RECORDS_IN_QUERY,
          },
          aggs: {
            members: {
              cardinality: {
                field: 'member_id.keyword',
              },
            },
            member_list: {
              terms: {
                field: 'member_name.keyword',
                size: REPORT_CONSTANTS.MAX_RECORDS_IN_QUERY,
              },
              aggs: {
                initial_amount: {
                  scripted_metric: {
                    map_script: `
                if (state.oldest_date == null || doc.lastModifiedDate.value.isBefore(state.oldest_date)) {
                  state.oldest_date = doc.lastModifiedDate.value;
                   if(doc['total_score'].size() > 0)
                  {
                    state.total_score = doc['total_score'].value;
                  }else{
                    state.total_score = 0;
                  }
                }`,
                    combine_script: 'return state',
                    reduce_script:
                      'return states.stream().min(Comparator.comparing(i->i.oldest_date)).map(i->i.total_score).get()',
                  },
                },
                number_completion: {
                  filter: {
                    term: {
                      'status.keyword': {
                        value: 'COMPLETED',
                      },
                    },
                  },
                },
              },
            },
            score: {
              sum_bucket: {
                buckets_path: 'member_list>number_completion>_count',
              },
            },
            avg_score: {
              bucket_script: {
                buckets_path: {
                  tShirtSales: 'score',
                  totalSales: 'members',
                },
                script: '(int) (params.tShirtSales / params.totalSales)',
              },
            },
            last_assigned_date: {
              top_hits: {
                _source: 'lastModifiedDate',
                sort: [{ lastModifiedDate: { order: 'desc' } }],
                size: 1,
              },
            },
            final_sort: {
              bucket_sort: {
                sort: [{ avg_score: { order: 'desc' } }],
              },
            },
          },
        },
      },
    };
  }
  return {
    size: 0,
    query: {
      bool: {
        ...(report?.data?.chatbotDctSection?.dataCollectionTemplateIds?.dctTemplateIds?.length
          ? {
              filter: [
                {
                  terms: {
                    'dct_id.keyword': report.data.chatbotDctSection.dataCollectionTemplateIds.dctTemplateIds,
                  },
                },
              ],
            }
          : {}),
        must: [],
      },
    },
    aggs: {
      total_members: {
        cardinality: {
          field: 'member_id.keyword',
        },
      },
      total_dcts: {
        cardinality: {
          field: 'dct_name.keyword',
        },
      },
      total_completed: {
        filter: {
          term: {
            'status.keyword': 'COMPLETED',
          },
        },
      },
      total_attempts: {
        filter: {
          exists: {
            field: 'total_attempt',
          },
        },
      },
      last_30_days: {
        filter: {
          bool: {
            must: [
              {
                term: {
                  'status.keyword': {
                    value: 'COMPLETED',
                  },
                },
              },
              {
                range: {
                  lastModifiedDate: {
                    gte: 'now-35d/d',
                    lte: 'now',
                  },
                },
              },
            ],
          },
        },
        aggs: {
          counts: {
            date_histogram: {
              field: 'lastModifiedDate',
              calendar_interval: '1d',
            },
          },
        },
      },
      past_30_days: {
        filter: {
          bool: {
            must: [
              {
                term: {
                  'status.keyword': {
                    value: 'COMPLETED',
                  },
                },
              },
              {
                range: {
                  lastModifiedDate: {
                    gte: 'now-60d/d',
                    lt: 'now-30d/d',
                  },
                },
              },
            ],
          },
        },
        aggs: {
          counts: {
            date_histogram: {
              field: 'lastModifiedDate',
              calendar_interval: '1d',
            },
          },
        },
      },
      last_assigned: {
        top_hits: {
          _source: 'lastModifiedDate',
          sort: [{ lastModifiedDate: { order: 'desc' } }],
          size: 1,
        },
      },
      most_completed: {
        filter: {
          term: {
            'status.keyword': 'COMPLETED',
          },
        },
        aggs: {
          by_members: {
            terms: {
              field: 'dct_name.keyword',
              size: REPORT_CONSTANTS.MAX_RECORDS_IN_QUERY,
              order: {
                _count: 'desc',
                uniqueMembers: 'desc',
              },
            },
            aggs: {
              uniqueMembers: {
                cardinality: {
                  field: 'member_id.keyword',
                },
              },
              last_assigned_date: {
                top_hits: {
                  _source: 'lastModifiedDate',
                  sort: [
                    {
                      lastModifiedDate: {
                        order: 'desc',
                      },
                    },
                  ],
                  size: 1,
                },
              },
            },
          },
        },
      },

      least_completed: {
        filter: {
          term: {
            'status.keyword': 'COMPLETED',
          },
        },
        aggs: {
          by_members: {
            terms: {
              field: 'dct_name.keyword',
              size: REPORT_CONSTANTS.MAX_RECORDS_IN_QUERY,
              order: {
                _count: 'asc',
                uniqueMembers: 'asc',
              },
            },
            aggs: {
              uniqueMembers: {
                cardinality: {
                  field: 'member_id.keyword',
                },
              },
              last_assigned_date: {
                top_hits: {
                  _source: 'lastModifiedDate',
                  sort: [
                    {
                      lastModifiedDate: {
                        order: 'desc',
                      },
                    },
                  ],
                  size: 1,
                },
              },
            },
          },
        },
      },
      most_attempted: {
        filter: {
          exists: {
            field: 'total_attempt',
          },
        },
        aggs: {
          by_members: {
            terms: {
              field: 'dct_name.keyword',
              size: REPORT_CONSTANTS.MAX_RECORDS_IN_QUERY,
            },
            aggs: {
              last_assigned_date: {
                top_hits: {
                  _source: 'lastModifiedDate',
                  sort: [{ lastModifiedDate: { order: 'desc' } }],
                  size: 1,
                },
              },
            },
          },
        },
      },
      least_attempted: {
        filter: {
          exists: {
            field: 'total_attempt',
          },
        },
        aggs: {
          by_members: {
            terms: {
              field: 'dct_name.keyword',
              size: REPORT_CONSTANTS.MAX_RECORDS_IN_QUERY,
              order: {
                _count: 'asc',
              },
            },
            aggs: {
              last_assigned_date: {
                top_hits: {
                  _source: 'lastModifiedDate',
                  sort: [{ lastModifiedDate: { order: 'desc' } }],
                  size: 1,
                },
              },
            },
          },
        },
      },
      most_scored: {
        terms: {
          field: 'dct_name.keyword',
          size: REPORT_CONSTANTS.MAX_RECORDS_IN_QUERY,
        },
        aggs: {
          total_score: {
            sum: {
              field: 'total_score',
            },
          },
          bucket_sort: {
            bucket_sort: {
              sort: [{ total_score: { order: 'desc' } }],
            },
          },
          last_assigned_date: {
            top_hits: {
              _source: 'lastModifiedDate',
              sort: [{ lastModifiedDate: { order: 'desc' } }],
              size: 1,
            },
          },
        },
      },
      average_current_score: {
        terms: {
          field: 'dct_name.keyword',
          size: REPORT_CONSTANTS.MAX_RECORDS_IN_QUERY,
        },
        aggs: {
          members: {
            cardinality: {
              field: 'member_id.keyword',
            },
          },
          member_list: {
            terms: {
              field: 'member_name.keyword',
              size: REPORT_CONSTANTS.MAX_RECORDS_IN_QUERY,
            },
            aggs: {
              initial_amount: {
                scripted_metric: {
                  map_script: `
                if (state.oldest_date == null || doc.lastModifiedDate.value.isAfter(state.oldest_date)) {
                  state.oldest_date = doc.lastModifiedDate.value;
                   if(doc['total_score'].size() > 0)
                  {
                    state.total_score = doc['total_score'].value;
                  }else{
                    state.total_score = 0;
                  }
                }`,
                  combine_script: 'return state',
                  reduce_script:
                    'return states.stream().min(Comparator.comparing(i->i.oldest_date)).map(i->i.total_score).get()',
                },
              },
            },
          },
          score: {
            sum_bucket: {
              buckets_path: 'member_list>initial_amount.value',
            },
          },
          avg_score: {
            bucket_script: {
              buckets_path: {
                tShirtSales: 'score',
                totalSales: 'members',
              },
              script: '(int) (params.tShirtSales / params.totalSales)',
            },
          },
          last_assigned_date: {
            top_hits: {
              _source: 'lastModifiedDate',
              sort: [{ lastModifiedDate: { order: 'desc' } }],
              size: 1,
            },
          },
          final_sort: {
            bucket_sort: {
              sort: [{ avg_score: { order: 'desc' } }],
            },
          },
        },
      },
      average_initial_score: {
        terms: {
          field: 'dct_name.keyword',
          size: REPORT_CONSTANTS.MAX_RECORDS_IN_QUERY,
        },
        aggs: {
          members: {
            cardinality: {
              field: 'member_id.keyword',
            },
          },
          member_list: {
            terms: {
              field: 'member_name.keyword',
              size: REPORT_CONSTANTS.MAX_RECORDS_IN_QUERY,
            },
            aggs: {
              initial_amount: {
                scripted_metric: {
                  map_script: `
                if (state.oldest_date == null || doc.lastModifiedDate.value.isBefore(state.oldest_date)) {
                  state.oldest_date = doc.lastModifiedDate.value;
                   if(doc['total_score'].size() > 0)
                  {
                    state.total_score = doc['total_score'].value;
                  }else{
                    state.total_score = 0;
                  }
                }`,
                  combine_script: 'return state',
                  reduce_script:
                    'return states.stream().min(Comparator.comparing(i->i.oldest_date)).map(i->i.total_score).get()',
                },
              },
            },
          },
          score: {
            sum_bucket: {
              buckets_path: 'member_list>initial_amount.value',
            },
          },
          avg_score: {
            bucket_script: {
              buckets_path: {
                tShirtSales: 'score',
                totalSales: 'members',
              },
              script: '(int) (params.tShirtSales / params.totalSales)',
            },
          },
          last_assigned_date: {
            top_hits: {
              _source: 'lastModifiedDate',
              sort: [{ lastModifiedDate: { order: 'desc' } }],
              size: 1,
            },
          },
          final_sort: {
            bucket_sort: {
              sort: [{ avg_score: { order: 'desc' } }],
            },
          },
        },
      },
      greatest_change_in_score: {
        terms: {
          field: 'dct_name.keyword',
          size: REPORT_CONSTANTS.MAX_RECORDS_IN_QUERY,
        },
        aggs: {
          members: {
            cardinality: {
              field: 'member_id.keyword',
            },
          },
          member_list: {
            terms: {
              field: 'member_name.keyword',
              size: REPORT_CONSTANTS.MAX_RECORDS_IN_QUERY,
            },
            aggs: {
              initial_score: {
                scripted_metric: {
                  map_script: `
                if (state.oldest_date == null || doc.lastModifiedDate.value.isBefore(state.oldest_date)) {
                  state.oldest_date = doc.lastModifiedDate.value;
                   if(doc['total_score'].size() > 0)
                  {
                    state.total_score = doc['total_score'].value;
                  }else{
                    state.total_score = 0;
                  }
                }`,
                  combine_script: 'return state',
                  reduce_script:
                    'return states.stream().min(Comparator.comparing(i->i.oldest_date)).map(i->i.total_score).get()',
                },
              },
              current_score: {
                scripted_metric: {
                  map_script: `
                if (state.oldest_date == null || doc.lastModifiedDate.value.isAfter(state.oldest_date)) {
                  state.oldest_date = doc.lastModifiedDate.value;
                   if(doc['total_score'].size() > 0)
                  {
                    state.total_score = doc['total_score'].value;
                  }else{
                    state.total_score = 0;
                  }
                }`,
                  combine_script: 'return state',
                  reduce_script:
                    'return states.stream().min(Comparator.comparing(i->i.oldest_date)).map(i->i.total_score).get()',
                },
              },
            },
          },
          initial_total_score: {
            sum_bucket: {
              buckets_path: 'member_list>initial_score.value',
            },
          },
          current_total_score: {
            sum_bucket: {
              buckets_path: 'member_list>current_score.value',
            },
          },
          change_score: {
            bucket_script: {
              buckets_path: {
                initialScore: 'initial_total_score',
                totalScore: 'current_total_score',
              },
              script: '(int)  (( (params.initialScore - params.totalScore)/params.initialScore ) * 100)',
            },
          },
          last_assigned_date: {
            top_hits: {
              _source: 'lastModifiedDate',
              sort: [{ lastModifiedDate: { order: 'desc' } }],
              size: 1,
            },
          },
          final_sort: {
            bucket_sort: {
              sort: [{ change_score: { order: 'desc' } }],
            },
          },
        },
      },
      average_number_completion: {
        terms: {
          field: 'dct_name.keyword',
          size: REPORT_CONSTANTS.MAX_RECORDS_IN_QUERY,
        },
        aggs: {
          members: {
            cardinality: {
              field: 'member_id.keyword',
            },
          },
          member_list: {
            terms: {
              field: 'member_name.keyword',
              size: REPORT_CONSTANTS.MAX_RECORDS_IN_QUERY,
            },
            aggs: {
              initial_amount: {
                scripted_metric: {
                  map_script: `
                if (state.oldest_date == null || doc.lastModifiedDate.value.isBefore(state.oldest_date)) {
                  state.oldest_date = doc.lastModifiedDate.value;
                   if(doc['total_score'].size() > 0)
                  {
                    state.total_score = doc['total_score'].value;
                  }else{
                    state.total_score = 0;
                  }
                }`,
                  combine_script: 'return state',
                  reduce_script:
                    'return states.stream().min(Comparator.comparing(i->i.oldest_date)).map(i->i.total_score).get()',
                },
              },
              number_completion: {
                filter: {
                  term: {
                    'status.keyword': {
                      value: 'COMPLETED',
                    },
                  },
                },
              },
            },
          },
          score: {
            sum_bucket: {
              buckets_path: 'member_list>number_completion>_count',
            },
          },
          avg_score: {
            bucket_script: {
              buckets_path: {
                tShirtSales: 'score',
                totalSales: 'members',
              },
              script: '(int) (params.tShirtSales / params.totalSales)',
            },
          },
          last_assigned_date: {
            top_hits: {
              _source: 'lastModifiedDate',
              sort: [{ lastModifiedDate: { order: 'desc' } }],
              size: 1,
            },
          },
          final_sort: {
            bucket_sort: {
              sort: [{ avg_score: { order: 'desc' } }],
            },
          },
        },
      },
    },
  };
};

export const getEvaluationDctReportQuery = report => {
  let allFilters = JSON.parse(JSON.stringify(report.reportFilters));
  if (report?.reportFilters?.length || report?.referralPartnerId) {
    const allInsuranceFilters = report.reportFilters?.filter(
      filter => filter.type === REPORT_FILTERS.INSURANCE,
    );
    if (allInsuranceFilters?.length) {
      allFilters = [
        ...report.reportFilters?.filter(filter => filter.type !== REPORT_FILTERS.INSURANCE),
        {
          type: REPORT_FILTERS.INSURANCE,
          insurance: {
            carriers: allInsuranceFilters?.map(filter => filter.insurance?.carriers)?.flat(),
            state: '',
          },
          value: '',
          profileElement: '',
          states: [],
          levelOfEngagements: [],
          providers: [],
          rule: ReportFilterRule.EQUALS,
        },
      ];
    }
    return {
      size: 0,
      query: {
        bool: {
          ...(report?.data?.evaluationDctSection?.dataCollectionTemplateIds?.dctTemplateIds?.length
            ? {
                filter: [
                  {
                    terms: {
                      'dct_id.keyword':
                        report.data.evaluationDctSection.dataCollectionTemplateIds.dctTemplateIds,
                    },
                  },
                ],
              }
            : {}),
          must: [
            ...allFilters?.map(filter => filter.profileElement && getReportFilterSubQuery(filter)),
            ...allFilters?.map(filter => getReportAllFiltersSubQuery(filter)),
            ...getReportAllFiltersSubQueryReferralPartner(report?.referralPartnerId),
            ...getReportInternalFiltersSubQuery(report, REPORT_SECTION_NAMES.evaluationDctSection),
          ]?.filter(obj => Object.keys(obj)?.length > 0),
        },
      },
      aggs: {
        total_members: {
          cardinality: {
            field: 'member_id.keyword',
          },
        },
        total_dcts: {
          cardinality: {
            field: 'dct_name.keyword',
          },
        },
        total_completed: {
          filter: {
            term: {
              'status.keyword': 'COMPLETED',
            },
          },
        },
        total_attempts: {
          filter: {
            exists: {
              field: 'total_attempt',
            },
          },
        },
        last_30_days: {
          filter: {
            bool: {
              must: [
                {
                  term: {
                    'status.keyword': {
                      value: 'COMPLETED',
                    },
                  },
                },
                {
                  range: {
                    lastModifiedDate: {
                      gte: 'now-35d/d',
                      lte: 'now',
                    },
                  },
                },
              ],
            },
          },
          aggs: {
            counts: {
              date_histogram: {
                field: 'lastModifiedDate',
                calendar_interval: '1d',
              },
            },
          },
        },
        past_30_days: {
          filter: {
            bool: {
              must: [
                {
                  term: {
                    'status.keyword': {
                      value: 'COMPLETED',
                    },
                  },
                },
                {
                  range: {
                    lastModifiedDate: {
                      gte: 'now-60d/d',
                      lt: 'now-30d/d',
                    },
                  },
                },
              ],
            },
          },
          aggs: {
            counts: {
              date_histogram: {
                field: 'lastModifiedDate',
                calendar_interval: '1d',
              },
            },
          },
        },
        last_assigned: {
          top_hits: {
            _source: 'lastModifiedDate',
            sort: [{ lastModifiedDate: { order: 'desc' } }],
            size: 1,
          },
        },
        most_completed: {
          filter: {
            term: {
              'status.keyword': 'COMPLETED',
            },
          },
          aggs: {
            by_members: {
              terms: {
                field: 'dct_name.keyword',
                size: REPORT_CONSTANTS.MAX_RECORDS_IN_QUERY,
                order: {
                  _count: 'desc',
                  uniqueMembers: 'desc',
                },
              },
              aggs: {
                uniqueMembers: {
                  cardinality: {
                    field: 'member_id.keyword',
                  },
                },
                last_assigned_date: {
                  top_hits: {
                    _source: 'lastModifiedDate',
                    sort: [{ lastModifiedDate: { order: 'desc' } }],
                    size: 1,
                  },
                },
              },
            },
          },
        },
        least_completed: {
          filter: {
            term: {
              'status.keyword': 'COMPLETED',
            },
          },
          aggs: {
            by_members: {
              terms: {
                field: 'dct_name.keyword',
                size: REPORT_CONSTANTS.MAX_RECORDS_IN_QUERY,
                order: {
                  _count: 'asc',
                  uniqueMembers: 'asc',
                },
              },
              aggs: {
                uniqueMembers: {
                  cardinality: {
                    field: 'member_id.keyword',
                  },
                },
                last_assigned_date: {
                  top_hits: {
                    _source: 'lastModifiedDate',
                    sort: [{ lastModifiedDate: { order: 'desc' } }],
                    size: 1,
                  },
                },
              },
            },
          },
        },
        most_attempted: {
          filter: {
            exists: {
              field: 'total_attempt',
            },
          },
          aggs: {
            by_members: {
              terms: {
                field: 'dct_name.keyword',
                size: REPORT_CONSTANTS.MAX_RECORDS_IN_QUERY,
              },
              aggs: {
                last_assigned_date: {
                  top_hits: {
                    _source: 'lastModifiedDate',
                    sort: [{ lastModifiedDate: { order: 'desc' } }],
                    size: 1,
                  },
                },
              },
            },
          },
        },
        least_attempted: {
          filter: {
            exists: {
              field: 'total_attempt',
            },
          },
          aggs: {
            by_members: {
              terms: {
                field: 'dct_name.keyword',
                size: REPORT_CONSTANTS.MAX_RECORDS_IN_QUERY,
                order: {
                  _count: 'asc',
                },
              },
              aggs: {
                last_assigned_date: {
                  top_hits: {
                    _source: 'lastModifiedDate',
                    sort: [{ lastModifiedDate: { order: 'desc' } }],
                    size: 1,
                  },
                },
              },
            },
          },
        },
        most_scored: {
          terms: {
            field: 'dct_name.keyword',
            size: REPORT_CONSTANTS.MAX_RECORDS_IN_QUERY,
          },
          aggs: {
            total_score: {
              sum: {
                field: 'total_score',
              },
            },
            bucket_sort: {
              bucket_sort: {
                sort: [{ total_score: { order: 'desc' } }],
              },
            },
            last_assigned_date: {
              top_hits: {
                _source: 'lastModifiedDate',
                sort: [{ lastModifiedDate: { order: 'desc' } }],
                size: 1,
              },
            },
          },
        },
        average_current_score: {
          terms: {
            field: 'dct_name.keyword',
            size: REPORT_CONSTANTS.MAX_RECORDS_IN_QUERY,
          },
          aggs: {
            members: {
              cardinality: {
                field: 'member_id.keyword',
              },
            },
            member_list: {
              terms: {
                field: 'member_name.keyword',
                size: REPORT_CONSTANTS.MAX_RECORDS_IN_QUERY,
              },
              aggs: {
                initial_amount: {
                  scripted_metric: {
                    map_script: `
                if (state.oldest_date == null
                    || doc.lastModifiedDate.value.isAfter(state.oldest_date)) {
                  state.oldest_date = doc.lastModifiedDate.value;
                   if(doc['total_score'].size() > 0)
                  {
                    state.total_score = doc['total_score'].value;
                  }else{
                    state.total_score = 0;
                  }
                }`,
                    combine_script: 'return state',
                    reduce_script:
                      'return states.stream().min(Comparator.comparing(i->i.oldest_date)).map(i->i.total_score).get()',
                  },
                },
              },
            },
            score: {
              sum_bucket: {
                buckets_path: 'member_list>initial_amount.value',
              },
            },
            avg_score: {
              bucket_script: {
                buckets_path: {
                  tShirtSales: 'score',
                  totalSales: 'members',
                },
                script: '(int) (params.tShirtSales / params.totalSales)',
              },
            },

            last_assigned_date: {
              top_hits: {
                _source: 'lastModifiedDate',
                sort: [{ lastModifiedDate: { order: 'desc' } }],
                size: 1,
              },
            },
            final_sort: {
              bucket_sort: {
                sort: [{ avg_score: { order: 'desc' } }],
              },
            },
          },
        },
        average_initial_score: {
          terms: {
            field: 'dct_name.keyword',
            size: REPORT_CONSTANTS.MAX_RECORDS_IN_QUERY,
          },
          aggs: {
            members: {
              cardinality: {
                field: 'member_id.keyword',
              },
            },
            member_list: {
              terms: {
                field: 'member_name.keyword',
                size: REPORT_CONSTANTS.MAX_RECORDS_IN_QUERY,
              },
              aggs: {
                initial_amount: {
                  scripted_metric: {
                    map_script: `
                if (state.oldest_date == null
                    || doc.lastModifiedDate.value.isBefore(state.oldest_date)) {
                  state.oldest_date = doc.lastModifiedDate.value;
                   if(doc['total_score'].size() > 0)
                  {
                    state.total_score = doc['total_score'].value;
                  }else{
                    state.total_score = 0;
                  }
                }`,
                    combine_script: 'return state',
                    reduce_script:
                      'return states.stream().min(Comparator.comparing(i->i.oldest_date)).map(i->i.total_score).get()',
                  },
                },
              },
            },
            score: {
              sum_bucket: {
                buckets_path: 'member_list>initial_amount.value',
              },
            },
            avg_score: {
              bucket_script: {
                buckets_path: {
                  tShirtSales: 'score',
                  totalSales: 'members',
                },
                script: '(int) (params.tShirtSales / params.totalSales)',
              },
            },

            last_assigned_date: {
              top_hits: {
                _source: 'lastModifiedDate',
                sort: [{ lastModifiedDate: { order: 'desc' } }],
                size: 1,
              },
            },
            final_sort: {
              bucket_sort: {
                sort: [{ avg_score: { order: 'desc' } }],
              },
            },
          },
        },
        greatest_change_in_score: {
          terms: {
            field: 'dct_name.keyword',
            size: REPORT_CONSTANTS.MAX_RECORDS_IN_QUERY,
          },
          aggs: {
            members: {
              cardinality: {
                field: 'member_id.keyword',
              },
            },
            member_list: {
              terms: {
                field: 'member_name.keyword',
                size: REPORT_CONSTANTS.MAX_RECORDS_IN_QUERY,
              },
              aggs: {
                initial_score: {
                  scripted_metric: {
                    map_script: `
                if (state.oldest_date == null
                    || doc.lastModifiedDate.value.isBefore(state.oldest_date)) {
                  state.oldest_date = doc.lastModifiedDate.value;
                   if(doc['total_score'].size() > 0)
                  {
                    state.total_score = doc['total_score'].value;
                  }else{
                    state.total_score = 0;
                  }
                }`,
                    combine_script: 'return state',
                    reduce_script:
                      'return states.stream().min(Comparator.comparing(i->i.oldest_date)).map(i->i.total_score).get()',
                  },
                },
                current_score: {
                  scripted_metric: {
                    map_script: `
                if (state.oldest_date == null
                    || doc.lastModifiedDate.value.isAfter(state.oldest_date)) {
                  state.oldest_date = doc.lastModifiedDate.value;
                   if(doc['total_score'].size() > 0)
                  {
                    state.total_score = doc['total_score'].value;
                  }else{
                    state.total_score = 0;
                  }
                }`,
                    combine_script: 'return state',
                    reduce_script:
                      'return states.stream().min(Comparator.comparing(i->i.oldest_date)).map(i->i.total_score).get()',
                  },
                },
              },
            },
            initial_total_score: {
              sum_bucket: {
                buckets_path: 'member_list>initial_score.value',
              },
            },
            current_total_score: {
              sum_bucket: {
                buckets_path: 'member_list>current_score.value',
              },
            },
            change_score: {
              bucket_script: {
                buckets_path: {
                  initialScore: 'initial_total_score',
                  totalScore: 'current_total_score',
                },
                script: '(int)  (( (params.initialScore - params.totalScore)/params.initialScore ) * 100)',
              },
            },

            last_assigned_date: {
              top_hits: {
                _source: 'lastModifiedDate',
                sort: [{ lastModifiedDate: { order: 'desc' } }],
                size: 1,
              },
            },
            final_sort: {
              bucket_sort: {
                sort: [{ change_score: { order: 'desc' } }],
              },
            },
          },
        },
        average_number_completion: {
          terms: {
            field: 'dct_name.keyword',
            size: REPORT_CONSTANTS.MAX_RECORDS_IN_QUERY,
          },
          aggs: {
            members: {
              cardinality: {
                field: 'member_id.keyword',
              },
            },
            member_list: {
              terms: {
                field: 'member_name.keyword',
                size: REPORT_CONSTANTS.MAX_RECORDS_IN_QUERY,
              },
              aggs: {
                initial_amount: {
                  scripted_metric: {
                    map_script: `
                if (state.oldest_date == null
                    || doc.lastModifiedDate.value.isBefore(state.oldest_date)) {
                  state.oldest_date = doc.lastModifiedDate.value;
                   if(doc['total_score'].size() > 0)
                  {
                    state.total_score = doc['total_score'].value;
                  }else{
                    state.total_score = 0;
                  }
                }`,
                    combine_script: 'return state',
                    reduce_script:
                      'return states.stream().min(Comparator.comparing(i->i.oldest_date)).map(i->i.total_score).get()',
                  },
                },
                number_completion: {
                  filter: {
                    term: {
                      'status.keyword': {
                        value: 'COMPLETED',
                      },
                    },
                  },
                },
              },
            },
            score: {
              sum_bucket: {
                buckets_path: 'member_list>number_completion>_count',
              },
            },

            avg_score: {
              bucket_script: {
                buckets_path: {
                  tShirtSales: 'score',
                  totalSales: 'members',
                },
                script: '(int) (params.tShirtSales / params.totalSales)',
              },
            },

            last_assigned_date: {
              top_hits: {
                _source: 'lastModifiedDate',
                sort: [{ lastModifiedDate: { order: 'desc' } }],
                size: 1,
              },
            },
            final_sort: {
              bucket_sort: {
                sort: [{ avg_score: { order: 'desc' } }],
              },
            },
          },
        },
      },
    };
  }
  return {
    size: 0,
    query: {
      bool: {
        ...(report?.data?.evaluationDctSection?.dataCollectionTemplateIds?.dctTemplateIds?.length
          ? {
              filter: [
                {
                  terms: {
                    'dct_id.keyword':
                      report.data.evaluationDctSection.dataCollectionTemplateIds.dctTemplateIds,
                  },
                },
              ],
            }
          : {}),
        must: [],
      },
    },
    aggs: {
      total_members: {
        cardinality: {
          field: 'member_id.keyword',
        },
      },
      total_dcts: {
        cardinality: {
          field: 'dct_name.keyword',
        },
      },
      total_completed: {
        filter: {
          term: {
            'status.keyword': 'COMPLETED',
          },
        },
      },
      total_attempts: {
        filter: {
          exists: {
            field: 'total_attempt',
          },
        },
      },
      last_30_days: {
        filter: {
          bool: {
            must: [
              {
                term: {
                  'status.keyword': {
                    value: 'COMPLETED',
                  },
                },
              },
              {
                range: {
                  lastModifiedDate: {
                    gte: 'now-35d/d',
                    lte: 'now',
                  },
                },
              },
            ],
          },
        },
        aggs: {
          counts: {
            date_histogram: {
              field: 'lastModifiedDate',
              calendar_interval: '1d',
            },
          },
        },
      },
      past_30_days: {
        filter: {
          bool: {
            must: [
              {
                term: {
                  'status.keyword': {
                    value: 'COMPLETED',
                  },
                },
              },
              {
                range: {
                  lastModifiedDate: {
                    gte: 'now-60d/d',
                    lt: 'now-30d/d',
                  },
                },
              },
            ],
          },
        },
        aggs: {
          counts: {
            date_histogram: {
              field: 'lastModifiedDate',
              calendar_interval: '1d',
            },
          },
        },
      },
      last_assigned: {
        top_hits: {
          _source: 'lastModifiedDate',
          sort: [{ lastModifiedDate: { order: 'desc' } }],
          size: 1,
        },
      },
      most_completed: {
        filter: {
          term: {
            'status.keyword': 'COMPLETED',
          },
        },
        aggs: {
          by_members: {
            terms: {
              field: 'dct_name.keyword',
              size: REPORT_CONSTANTS.MAX_RECORDS_IN_QUERY,
              order: {
                _count: 'desc',
                uniqueMembers: 'desc',
              },
            },
            aggs: {
              uniqueMembers: {
                cardinality: {
                  field: 'member_id.keyword',
                },
              },
              last_assigned_date: {
                top_hits: {
                  _source: 'lastModifiedDate',
                  sort: [{ lastModifiedDate: { order: 'desc' } }],
                  size: 1,
                },
              },
            },
          },
        },
      },
      least_completed: {
        filter: {
          term: {
            'status.keyword': 'COMPLETED',
          },
        },
        aggs: {
          by_members: {
            terms: {
              field: 'dct_name.keyword',
              size: REPORT_CONSTANTS.MAX_RECORDS_IN_QUERY,
              order: {
                _count: 'asc',
                uniqueMembers: 'asc',
              },
            },
            aggs: {
              uniqueMembers: {
                cardinality: {
                  field: 'member_id.keyword',
                },
              },
              last_assigned_date: {
                top_hits: {
                  _source: 'lastModifiedDate',
                  sort: [{ lastModifiedDate: { order: 'desc' } }],
                  size: 1,
                },
              },
            },
          },
        },
      },
      most_attempted: {
        filter: {
          exists: {
            field: 'total_attempt',
          },
        },
        aggs: {
          by_members: {
            terms: {
              field: 'dct_name.keyword',
              size: REPORT_CONSTANTS.MAX_RECORDS_IN_QUERY,
            },
            aggs: {
              last_assigned_date: {
                top_hits: {
                  _source: 'lastModifiedDate',
                  sort: [{ lastModifiedDate: { order: 'desc' } }],
                  size: 1,
                },
              },
            },
          },
        },
      },
      least_attempted: {
        filter: {
          exists: {
            field: 'total_attempt',
          },
        },
        aggs: {
          by_members: {
            terms: {
              field: 'dct_name.keyword',
              size: REPORT_CONSTANTS.MAX_RECORDS_IN_QUERY,
              order: {
                _count: 'asc',
              },
            },
            aggs: {
              last_assigned_date: {
                top_hits: {
                  _source: 'lastModifiedDate',
                  sort: [{ lastModifiedDate: { order: 'desc' } }],
                  size: 1,
                },
              },
            },
          },
        },
      },
      most_scored: {
        terms: {
          field: 'dct_name.keyword',
          size: REPORT_CONSTANTS.MAX_RECORDS_IN_QUERY,
        },
        aggs: {
          total_score: {
            sum: {
              field: 'total_score',
            },
          },
          bucket_sort: {
            bucket_sort: {
              sort: [{ total_score: { order: 'desc' } }],
            },
          },
          last_assigned_date: {
            top_hits: {
              _source: 'lastModifiedDate',
              sort: [{ lastModifiedDate: { order: 'desc' } }],
              size: 1,
            },
          },
        },
      },
      average_current_score: {
        terms: {
          field: 'dct_name.keyword',
          size: REPORT_CONSTANTS.MAX_RECORDS_IN_QUERY,
        },
        aggs: {
          members: {
            cardinality: {
              field: 'member_id.keyword',
            },
          },
          member_list: {
            terms: {
              field: 'member_name.keyword',
              size: REPORT_CONSTANTS.MAX_RECORDS_IN_QUERY,
            },
            aggs: {
              initial_amount: {
                scripted_metric: {
                  map_script: `
                if (state.oldest_date == null
                    || doc.lastModifiedDate.value.isAfter(state.oldest_date)) {
                  state.oldest_date = doc.lastModifiedDate.value;
                   if(doc['total_score'].size() > 0)
                  {
                    state.total_score = doc['total_score'].value;
                  }else{
                    state.total_score = 0;
                  }
                }`,
                  combine_script: 'return state',
                  reduce_script:
                    'return states.stream().min(Comparator.comparing(i->i.oldest_date)).map(i->i.total_score).get()',
                },
              },
            },
          },
          score: {
            sum_bucket: {
              buckets_path: 'member_list>initial_amount.value',
            },
          },
          avg_score: {
            bucket_script: {
              buckets_path: {
                tShirtSales: 'score',
                totalSales: 'members',
              },
              script: '(int) (params.tShirtSales / params.totalSales)',
            },
          },

          last_assigned_date: {
            top_hits: {
              _source: 'lastModifiedDate',
              sort: [{ lastModifiedDate: { order: 'desc' } }],
              size: 1,
            },
          },
          final_sort: {
            bucket_sort: {
              sort: [{ avg_score: { order: 'desc' } }],
            },
          },
        },
      },
      average_initial_score: {
        terms: {
          field: 'dct_name.keyword',
          size: REPORT_CONSTANTS.MAX_RECORDS_IN_QUERY,
        },
        aggs: {
          members: {
            cardinality: {
              field: 'member_id.keyword',
            },
          },
          member_list: {
            terms: {
              field: 'member_name.keyword',
              size: REPORT_CONSTANTS.MAX_RECORDS_IN_QUERY,
            },
            aggs: {
              initial_amount: {
                scripted_metric: {
                  map_script: `
                if (state.oldest_date == null
                    || doc.lastModifiedDate.value.isBefore(state.oldest_date)) {
                  state.oldest_date = doc.lastModifiedDate.value;
                   if(doc['total_score'].size() > 0)
                  {
                    state.total_score = doc['total_score'].value;
                  }else{
                    state.total_score = 0;
                  }
                }`,
                  combine_script: 'return state',
                  reduce_script:
                    'return states.stream().min(Comparator.comparing(i->i.oldest_date)).map(i->i.total_score).get()',
                },
              },
            },
          },
          score: {
            sum_bucket: {
              buckets_path: 'member_list>initial_amount.value',
            },
          },
          avg_score: {
            bucket_script: {
              buckets_path: {
                tShirtSales: 'score',
                totalSales: 'members',
              },
              script: '(int) (params.tShirtSales / params.totalSales)',
            },
          },

          last_assigned_date: {
            top_hits: {
              _source: 'lastModifiedDate',
              sort: [{ lastModifiedDate: { order: 'desc' } }],
              size: 1,
            },
          },
          final_sort: {
            bucket_sort: {
              sort: [{ avg_score: { order: 'desc' } }],
            },
          },
        },
      },
      greatest_change_in_score: {
        terms: {
          field: 'dct_name.keyword',
          size: REPORT_CONSTANTS.MAX_RECORDS_IN_QUERY,
        },
        aggs: {
          members: {
            cardinality: {
              field: 'member_id.keyword',
            },
          },
          member_list: {
            terms: {
              field: 'member_name.keyword',
              size: REPORT_CONSTANTS.MAX_RECORDS_IN_QUERY,
            },
            aggs: {
              initial_score: {
                scripted_metric: {
                  map_script: `
                if (state.oldest_date == null
                    || doc.lastModifiedDate.value.isBefore(state.oldest_date)) {
                  state.oldest_date = doc.lastModifiedDate.value;
                   if(doc['total_score'].size() > 0)
                  {
                    state.total_score = doc['total_score'].value;
                  }else{
                    state.total_score = 0;
                  }
                }`,
                  combine_script: 'return state',
                  reduce_script:
                    'return states.stream().min(Comparator.comparing(i->i.oldest_date)).map(i->i.total_score).get()',
                },
              },
              current_score: {
                scripted_metric: {
                  map_script: `
                if (state.oldest_date == null
                    || doc.lastModifiedDate.value.isAfter(state.oldest_date)) {
                  state.oldest_date = doc.lastModifiedDate.value;
                   if(doc['total_score'].size() > 0)
                  {
                    state.total_score = doc['total_score'].value;
                  }else{
                    state.total_score = 0;
                  }
                }`,
                  combine_script: 'return state',
                  reduce_script:
                    'return states.stream().min(Comparator.comparing(i->i.oldest_date)).map(i->i.total_score).get()',
                },
              },
            },
          },
          initial_total_score: {
            sum_bucket: {
              buckets_path: 'member_list>initial_score.value',
            },
          },
          current_total_score: {
            sum_bucket: {
              buckets_path: 'member_list>current_score.value',
            },
          },
          change_score: {
            bucket_script: {
              buckets_path: {
                initialScore: 'initial_total_score',
                totalScore: 'current_total_score',
              },
              script: '(int)  (( (params.initialScore - params.totalScore)/params.initialScore ) * 100)',
            },
          },

          last_assigned_date: {
            top_hits: {
              _source: 'lastModifiedDate',
              sort: [{ lastModifiedDate: { order: 'desc' } }],
              size: 1,
            },
          },
          final_sort: {
            bucket_sort: {
              sort: [{ change_score: { order: 'desc' } }],
            },
          },
        },
      },
      average_number_completion: {
        terms: {
          field: 'dct_name.keyword',
          size: REPORT_CONSTANTS.MAX_RECORDS_IN_QUERY,
        },
        aggs: {
          members: {
            cardinality: {
              field: 'member_id.keyword',
            },
          },
          member_list: {
            terms: {
              field: 'member_name.keyword',
              size: REPORT_CONSTANTS.MAX_RECORDS_IN_QUERY,
            },
            aggs: {
              initial_amount: {
                scripted_metric: {
                  map_script: `
                if (state.oldest_date == null
                    || doc.lastModifiedDate.value.isBefore(state.oldest_date)) {
                  state.oldest_date = doc.lastModifiedDate.value;
                   if(doc['total_score'].size() > 0)
                  {
                    state.total_score = doc['total_score'].value;
                  }else{
                    state.total_score = 0;
                  }
                }`,
                  combine_script: 'return state',
                  reduce_script:
                    'return states.stream().min(Comparator.comparing(i->i.oldest_date)).map(i->i.total_score).get()',
                },
              },
              number_completion: {
                filter: {
                  term: {
                    'status.keyword': {
                      value: 'COMPLETED',
                    },
                  },
                },
              },
            },
          },
          score: {
            sum_bucket: {
              buckets_path: 'member_list>number_completion>_count',
            },
          },

          avg_score: {
            bucket_script: {
              buckets_path: {
                tShirtSales: 'score',
                totalSales: 'members',
              },
              script: '(int) (params.tShirtSales / params.totalSales)',
            },
          },

          last_assigned_date: {
            top_hits: {
              _source: 'lastModifiedDate',
              sort: [{ lastModifiedDate: { order: 'desc' } }],
              size: 1,
            },
          },
          final_sort: {
            bucket_sort: {
              sort: [{ avg_score: { order: 'desc' } }],
            },
          },
        },
      },
    },
  };
};
