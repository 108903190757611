export const connectionColumns = [
  { id: 'member', label: 'Member', sortable: false },
  { id: 'email', label: 'Email', sortable: false },
  { id: 'phone', label: 'Phone', sortable: false },
  { id: 'actions', label: '', sortable: false },
];

export const filterOptionsMock = [
  { label: 'Active in chat', value: 'active_chat' },
  { label: 'Inactive in chat', value: 'inactive_chat' },
  { label: 'At Least 2 Sessions', value: '2_sessions' },
  { label: 'No Sessions', value: 'no_sessions' },
];
