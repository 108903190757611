import { makeStyles } from '@mui/styles';

import { colors } from '../../../../../packages/colors';

export const useStyles = makeStyles({
  root: {
    width: 280,
    top: 0,
    right: 0,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: colors.white,
    transition: '0.25s',
    borderLeft: `1px solid ${colors.neutral100}`,
    borderRight: `1px solid ${colors.neutral100}`,

    '& .active': {
      backgroundColor: colors.neutral50,
    },
  },
  headerSideProfile: {
    fontWeight: 700,
    fontSize: 18,
    lineHeight: '24px',
    color: colors.neutral900,
  },
  wrapperButton: {
    padding: '0 12px',
  },
  buttonContent: {
    fontWeight: 700,
    fontSize: 14,
    lineHeight: '20px',
    width: '100%',
    backgroundColor: colors.white,
    color: colors.neutral700,
    display: 'flex',
    justifyContent: 'flex-start',
    padding: '14px 16px',
    borderRadius: 6,
    textDecoration: 'none',

    '&:hover': {
      cursor: 'pointer',
      backgroundColor: colors.neutral50,
    },
  },
  buttonContentWithBadge: {
    fontWeight: 700,
    fontSize: 14,
    lineHeight: '20px',
    width: '100%',
    backgroundColor: colors.white,
    color: colors.neutral700,
    display: 'flex',
    justifyContent: 'space-between',
    padding: '14px 16px',
    borderRadius: 6,
    textDecoration: 'none',

    '&:hover': {
      cursor: 'pointer',
      backgroundColor: colors.neutral50,
    },
  },
  isActive: {
    backgroundColor: `${colors.white} !important`,
  },
  isActiveService: {
    backgroundColor: colors.neutral50,
  },
  drawerCollapsableAccordion: {
    padding: '14px 16px',
    '& .collapsableHeader': {
      justifyContent: 'space-between',
      // height: 48,
      gap: 8,
      width: '100%',
      '& span': {
        fontWeight: 700,
        fontSize: 14,
        lineHeight: '20px',
        color: colors.neutral700,
      },
    },
    '& .collapsableIcon': {
      margin: 0,
      '& path': {
        fill: colors.neutral900,
      },
    },
  },
  childBadge: {
    borderRadius: 100,
    backgroundColor: colors.neutral50,
    color: colors.neutral700,
    fontSize: 12,
    borderColor: 'transparent',
    fontWeight: 600,
  },
  badgeActive: {
    backgroundColor: colors.white,
  },
  navItems: {
    display: 'flex',
    flexDirection: 'column',
    gap: 0,
    padding: '0 12px',
  },
  navText: {
    color: colors.neutral700,
    whiteSpace: 'break-spaces',
    fontWeight: 700,
    overflowX: 'auto',
  },
  subNavText: {
    fontWeight: 500,
  },
});
