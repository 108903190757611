import { makeStyles } from '@mui/styles';

// colors
import { colors } from '../../../../../packages/colors';

export const useStyles = makeStyles({
  cflex: { display: 'flex', flexDirection: 'column' },
  sbflex: { display: 'flex', justifyContent: 'space-between', alignItems: 'center' },
  scorebox: {
    display: 'flex',
    flexDirection: 'column',
    background: colors.white,
    borderRadius: 8,
    boxShadow: '0px 0px 1px 0px #0000000A, 0px 0px 2px 0px #0000000F,0px 4px 8px 0px #0000000A',
  },
  borderTop: { borderTop: `1px solid ${colors.neutral100}` },
  circle: {
    width: 48,
    height: 48,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: colors.white,
  },
  width64: {
    width: 64,
    height: 64,
  },
  width52: {
    width: 52,
    height: 52,
  },
});
