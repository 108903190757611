import cx from 'clsx';

// components
import { Button } from '@mui/material';
import { fontWeight, Text, textLevel } from '../typography';

// types
import { textFieldType } from './TextField.types';

// styles
import { useStyles } from './TextField.styles';
import { Badge, badgeType } from '../badge';
import { Icons } from '../../../icons';
import { Tooltip } from '../tooltip';

const TextField = ({
  text,
  label,
  setOpenDrawer = flag => {},
  setOpenInstantAppointmentDrawer = flag => {},
  variant = textFieldType.M,
  tooltip = null,
  className = '',
}) => {
  const classes = useStyles();

  return (
    <div className={label === 'Next appointment##' || label === 'Visit Frequency##' ? classes.root2 : ''}>
      <div className={classes.root}>
        <Text
          className={cx({
            [classes.textLight]: true,
            [classes.smLabelLineHeight]: variant === textFieldType.S,
          })}
          level={variant === textFieldType.S ? textLevel.XS : textLevel.S}
          weight={fontWeight.SEMI_BOLD}
        >
          {!label.includes('##') ? label : label.replace('##', '')}
        </Text>
        {label !== 'Level of Engagement##' ? (
          tooltip ? (
            <Text
              className={cx(label === 'Primary interests' ? classes.isPrimary : '', {
                [classes.textDark]: true,
                [classes.smTextLineHeight]: variant === textFieldType.S,
              })}
              level={variant === textFieldType.S ? textLevel.S : textLevel.M}
              weight={fontWeight.MEDIUM}
            >
              <Tooltip title={tooltip} placement="top-start">
                <span>{text}</span>
              </Tooltip>
            </Text>
          ) : (
            <Text
              className={cx(
                label === 'Primary interests' ? classes.isPrimary : '',
                {
                  [classes.textDark]: true,
                  [classes.smTextLineHeight]: variant === textFieldType.S,
                },
                className,
              )}
              level={variant === textFieldType.S ? textLevel.S : textLevel.M}
              weight={fontWeight.MEDIUM}
            >
              {text}
            </Text>
          )
        ) : (
          <div className={classes.levelofEngagementContainer}>
            {text !== '-' ? (
              <>
                <Badge
                  variant={badgeType.FILLED}
                  className={`${classes.active} ${
                    text.split('|')[1] === 'Engaged'
                      ? classes.engaged
                      : text.split('|')[1] === 'Outreach needed'
                      ? classes.outreachNeeded
                      : classes.notEngaged
                  }`}
                >
                  <span>{text.split('|')[0] === 'null' ? '-' : text.split('|')[0]}</span>
                </Badge>
                {/* <Badge variant={badgeType.OUTLINED} className={`${classes.subStatus} ${classes.activeOutlined}`}>
                  <Tooltip title={text.split('|')[1] === 'null' ? '-' : text.split('|')[1]}>
                    <span>
                      {text.split('|')[1] === 'null' || text.split('|')[1] === ' null' ? '-' : text.split('|')[1]}
                    </span>
                  </Tooltip>
                </Badge> */}
              </>
            ) : tooltip ? (
              <Tooltip title={tooltip} placement="top-start">
                <span> {text}</span>
              </Tooltip>
            ) : (
              text
            )}
          </div>
        )}
      </div>
      <div className={classes.root} onClick={() => setOpenInstantAppointmentDrawer(true)}>
        {label === 'Next appointment##' && (
          <Button className={classes.btn}>
            <Icons glyph="plus" color="#0365C1" />
          </Button>
        )}
        {label === 'Visit Frequency##' && (
          <Button className={classes.btn} onClick={() => setOpenDrawer(true)}>
            <Icons glyph="edit-underline" color="#0365C1" />
          </Button>
        )}
      </div>
    </div>
  );
};

export { TextField };
