import ApexChart from 'react-apexcharts';

import { generateChartOptions } from './Chart.utils';
import { useStyles } from './Chart.styles';

const AppointmentsChart = ({ chartSeriesData, chartDays }) => {
  const classes = useStyles();

  return (
    <div className={classes.chart}>
      <ApexChart options={generateChartOptions(chartDays)} series={chartSeriesData} type="bar" height={340} />
    </div>
  );
};

export { AppointmentsChart };
