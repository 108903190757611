import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import { Breadcrumbs, colors, Icons, useQuery } from '../../../packages';
import { selectAppointmentDetailsById } from '../../../redux/modules/appointment/selectors';
import { conversationActionCreators } from '../../../redux/modules/conversation';
import { selectEvaluationContext } from '../../../redux/modules/conversation/selectors';
import { appointmentActionCreators } from '../../../redux/modules/appointment';
import { BaseLayout } from '../../../layouts/base';
import SelectedComponent from './MemberAppointmentEvaluation.selected';
import { useStyles } from './MemberAppointmentEvaluation.styles';
import RightSidebar from '../profile/components/right-sidebar';

const MemberAppointmentEvaluation = () => {
  const classes = useStyles();
  const { memberId, appointmentId } = useParams();
  const appointmentDetails = useSelector(selectAppointmentDetailsById(appointmentId));
  const evaluationContext = useSelector(selectEvaluationContext);
  const dispatch = useDispatch();
  const query = useQuery();
  const navigate = useNavigate();
  const location = useLocation();
  const userRole = location.pathname.split('/')[1];
  const [selectedScreen, setSelectedScreen] = useState('');
  const [loading, setLoading] = useState(true);

  const member = useMemo(() => {
    if (appointmentDetails) {
      return appointmentDetails.participants.filter(participant => !participant.isPractitioner)[0];
    }
    return null;
  }, [appointmentDetails]);

  const evaluationId = useMemo(() => query.get('evaluationId'), [query]);

  useEffect(() => {
    if (!appointmentDetails && !loading) {
      navigate(`/${userRole}/members/${memberId}/appointments/${appointmentId}`);
    }
  }, []);

  useEffect(() => {
    if (appointmentDetails) {
      setLoading(false);
    } else {
      dispatch(appointmentActionCreators.fetchAppointmentDetails(appointmentId));
    }
  }, [appointmentDetails]);

  useEffect(() => {
    setSelectedScreen(query.get('name') || 'general');
    dispatch(conversationActionCreators.fetchEvaluationContext({ appointmentId, evaluationId }));
  }, [query]);

  const rightSidebarNavItems = useMemo(
    () =>
      evaluationContext?.cbResponseList?.map(cbResponse => ({
        id: cbResponse.cbId,
        value: cbResponse.text,
        children: cbResponse.dctContentBlockList?.map(item => ({
          id: item.cbId,
          value: item.text,
        })),
      })) ?? [],
    [evaluationContext],
  );

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" sx={{ paddingY: 5 }}>
        <Icons className="rotate linear infinite" glyph="in-progress" color={colors.primary} />
      </Box>
    );
  }

  return (
    <BaseLayout
      customRightSideBar={<RightSidebar title="Evaluation" navItems={rightSidebarNavItems} />}
      rightSideBarWidth="320px"
    >
      <div className={classes.root}>
        <div className={classes.breadcrumbs}>
          <Breadcrumbs
            links={[
              { href: `/${userRole}/members`, text: 'Members' },
              {
                href: `/${userRole}/members/${member?.participantId}`,
                text: member?.name,
              },
              {
                href: `/${userRole}/members/${member?.participantId}/appointments/${appointmentId}`,
                text: appointmentDetails?.serviceName,
              },
              { text: evaluationContext?.name },
            ]}
          />
        </div>
        <SelectedComponent selectedScreen={selectedScreen} />
      </div>
    </BaseLayout>
  );
};

export { MemberAppointmentEvaluation };
