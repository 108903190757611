import { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import clsx from 'clsx';

import {
  fontWeight,
  Heading,
  headingLevel,
  Select,
  selectType,
  Textarea,
  TextError,
} from '../../../../../../packages';
import { getDomainElements } from '../../../../../../services/member/member.service';

import SelectUnit from '../../select-unit';
import SelectLevel from '../../select-level';
import { useStyles } from './SubstanceForm.styles';

const SubstanceForm = ({ form, error, lookupData, options, substance }) => {
  const [importanceLevel, setImportanceLevels] = useState([]);
  const classes = useStyles();
  const { values, touched, setFieldValue, errors, handleChange, ...rest } = form;

  const setTouched = name => async () => {
    await rest.setTouched({ ...touched, [name]: true });
  };

  const onChangeSelectUnit = name => async value => {
    await setFieldValue(name, value);
  };

  const renderLabel = (str, className = '') => (
    <Heading level={headingLevel.S} className={clsx(classes.label, className)} weight={fontWeight.BOLD}>
      {str}
    </Heading>
  );

  useEffect(() => {
    if (substance) {
      if (substance?.domainElementId) {
        const tempImportanceLevels = [];
        getDomainElements({ domainTypeId: substance?.domainElementId })
          .then(res => {
            if (res.status === 200) {
              Object.keys(res?.data?.metaData?.metaDataSpec?.importanceLevels).forEach(key => {
                if (res?.data?.metaData?.metaDataSpec?.importanceLevels[key]) {
                  tempImportanceLevels.push({ value: key, label: key });
                }
              });
              setImportanceLevels(tempImportanceLevels);
            }
          })
          .catch(err => {
            console.log({ err });
          });
      } else {
        const selectedDiagnosis = options?.filter(option => option?.groupId === substance?.groupId);
        const selectedRelatedElement = selectedDiagnosis[0].relatedElements?.filter(
          element => element.Id === substance.item.value,
        );

        // console.log('selected related element', selectedRelatedElement);

        const tempImportanceLevels = [];
        // const tempIcd10List = [];
        Object.keys(selectedRelatedElement[0]?.metaData?.metaDataSpec?.importanceLevels).forEach(key => {
          if (selectedRelatedElement[0]?.metaData?.metaDataSpec?.importanceLevels[key]) {
            tempImportanceLevels.push({ value: key, label: key });
          }
        });
        // if (selectedRelatedElement[0]?.metaData?.metaDataValues?.icd10Codes) {
        //   selectedRelatedElement[0]?.metaData?.metaDataValues?.icd10Codes.forEach(val => {
        //     tempIcd10List.push({ value: val, label: val });
        //   });

        // setIcd10(selectedRelatedElement[0]?.metaData?.metaDataValues?.icd10Codes);
        // }
        setImportanceLevels(tempImportanceLevels);
      }
    }
  }, [substance]);

  return (
    <>
      <Box className={classes.formContent}>
        {error && (
          <Box display="flex" justifyContent="center">
            <TextError errorMsg={error} />
          </Box>
        )}
        <Box className={classes.section}>
          {renderLabel('Importance level')}
          <SelectLevel value={values.status} isLifeEvent options={importanceLevel} onChange={handleChange} />
        </Box>
        <Box className={classes.section}>
          {renderLabel('Method of use')}
          <Select
            options={lookupData?.methodsOfSubstanceUse.map(item => ({
              label: item.value,
              value: item.name,
            }))}
            value={values.method}
            name="method"
            onChange={handleChange}
            variant={selectType.SECONDARY}
            fullWidth
          />
        </Box>
        <Box display="flex" justifyContent="space-between" gap="16px">
          <Box className={classes.section} flex={1}>
            {renderLabel('Dose')}
            <SelectUnit
              value={values.dose.amount}
              unit={values.dose.unit}
              options={lookupData?.unitsOfSubstanceUse.map(item => ({
                label: item.value,
                value: item.name,
              }))}
              onChange={onChangeSelectUnit('dose')}
            />
            <TextError errorMsg={errors.dose?.amount} />
          </Box>
          <Box className={classes.section} flex={1}>
            {renderLabel('Frequency')}
            {/* <Select
              value={values.frequency.amount as any}
              unit={values.frequency.unit}
              onChange={onChangeSelectUnit('frequency')}
            /> */}
            <Select
              options={lookupData?.currentFrequencyOfSubstanceUse.map(item => ({
                label: item.value,
                value: item.name,
              }))}
              value={values.frequency}
              name="frequency"
              onChange={handleChange}
              variant={selectType.SECONDARY}
            />
            <TextError errorMsg={errors.frequency} />
          </Box>
          <Box className={classes.section} flex={1}>
            {renderLabel('Duration')}
            {/* <SelectUnit
              value={values.duration.amount as any}
              unit={values.duration.unit}
              onChange={onChangeSelectUnit('duration')}
            /> */}
            <Select
              options={lookupData?.continuousLevelOfSubstanceUse.map(item => ({
                label: item.value,
                value: item.name,
              }))}
              value={values.duration}
              name="duration"
              onChange={handleChange}
              variant={selectType.SECONDARY}
            />
            <TextError errorMsg={errors.duration?.amount} />
          </Box>
        </Box>
        <Box className={classes.section}>
          {renderLabel('Last use')}
          <Select
            options={lookupData?.lastSubstanceUse.map(item => ({ label: item.value, value: item.name }))}
            value={values.lastUse}
            name="lastUse"
            onChange={handleChange}
            variant={selectType.SECONDARY}
            fullWidth
          />
        </Box>
        <Box className={classes.section}>
          {renderLabel('Report notes')}
          <Textarea
            value={values.notes}
            name="notes"
            onChange={handleChange}
            onBlur={setTouched('notes')}
            minRows={5}
            placeholder="Enter your notes"
          />
          <TextError errorMsg={touched.notes ? errors.notes?.toString() : null} />
        </Box>
      </Box>
    </>
  );
};

export { SubstanceForm };
