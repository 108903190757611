import { CONTACT_NOTES_FLAGS } from '../../../packages/constants/CommonConstants';
import { FILTER_COLLAPSABLE_SECTION_TYPE } from '../../../packages/ui/organisms/table/filter';

export const NOTES_STATUSES = {
  REPORTED: 'REPORTED',
  REVIEWED_BY_MANAGEMENT: 'REVIEWED_BY_MANAGEMENT',
  RESOLVED: 'RESOLVED',
  RECEIVED_BY_CARE_MANAGEMENT: 'RECEIVED_BY_CARE_MANAGEMENT',
  SENT_TO_MEMBER: 'SENT_TO_MEMBER',
  SENT_TO_REQUESTED_CONTACT: 'SENT_TO_REQUESTED_CONTACT',
  COMPLETED: 'COMPLETED',
  REQUESTED: 'REQUESTED',
  SENT_TO_LAB: 'SENT_TO_LAB',
  NO_RESPONSE: 'NO_RESPONSE',
  REJECTED: 'REJECTED',
  SUBMITTED: 'SUBMITTED',
  PENDING: 'PENDING',
  UNSUCCESSFUL: 'UNSUCCESSFUL',
  REMOVED: 'REMOVED',
};
export const NOTES_STATUS_CATEGORIES = {
  NO_ACTION_NEEDED: 'NO_ACTION_NEEDED',
  NEEDS_ACTION: 'NEEDS_ACTION',
};
export const noteColumns = [
  { id: 'name', label: 'Title', sortable: false },
  { id: 'provider', label: 'Provider', sortable: false },
  { id: 'member', label: 'Member', sortable: false },
  { id: 'type', label: 'Type', sortable: false },
  { id: 'status', label: 'Status', sortable: true },
  { id: 'lastModified', label: 'Date & time', sortable: true },
  { id: 'attachmentsCount', label: 'Attachments', sortable: false },
  { id: 'actions', label: '', sortable: false },
];
export const getStatus = () => {
  return [
    'REPORTED',
    'REVIEWED_BY_MANAGEMENT',
    'RESOLVED',
    'RECEIVED_BY_CARE_MANAGEMENT',
    'SENT_TO_MEMBER',
    'SENT_TO_REQUESTED_CONTACT',
    'COMPLETED',
    'REQUESTED',
    'SENT_TO_LAB',
    'NO_RESPONSE',
    'REJECTED',
    'SUBMITTED',
    'PENDING',
    'UNSUCCESSFUL',
    'REMOVED',
    'ACTIVE',
    'INACTIVE',
  ];
};
const tagFilterValues = {
  'General Notes': CONTACT_NOTES_FLAGS.GENERAL,
  'Contact Notes': CONTACT_NOTES_FLAGS.CONTACT,
  'Cautionary Notes': CONTACT_NOTES_FLAGS.CAUTION,
  'Prohibitive Notes': CONTACT_NOTES_FLAGS.PROHIBITIVE,
  'Release of Information Notes': CONTACT_NOTES_FLAGS.RELEASE_OF_INFORMATION,
  'Lab Request Notes': CONTACT_NOTES_FLAGS.LAB_REQUEST,
  'Pharmacy Assistance Notes': CONTACT_NOTES_FLAGS.PHARMACY_ASSISTANCE,
  'Referral Out Notes': CONTACT_NOTES_FLAGS.REFERRAL_OUT,
  'Prior Authorization Notes': CONTACT_NOTES_FLAGS.PRIOR_AUTHORIZATION,
  'No Action Needed': NOTES_STATUS_CATEGORIES.NO_ACTION_NEEDED,
  'Needs Action': NOTES_STATUS_CATEGORIES.NEEDS_ACTION,
  Reported: NOTES_STATUSES.REPORTED,
  'Reviewed by Management': NOTES_STATUSES.REVIEWED_BY_MANAGEMENT,
  Resolved: NOTES_STATUSES.RESOLVED,
  'Received by Care Management': NOTES_STATUSES.RECEIVED_BY_CARE_MANAGEMENT,
  'Sent to Member/Client/Guest': NOTES_STATUSES.SENT_TO_MEMBER,
  'Sent to Requested Contact/Department/Company': NOTES_STATUSES.SENT_TO_REQUESTED_CONTACT,
  Completed: NOTES_STATUSES.COMPLETED,
  'Sent to Lab': NOTES_STATUSES.SENT_TO_LAB,
  'No Response - Client Inaction': NOTES_STATUSES.NO_RESPONSE,
  'Completed - Results': NOTES_STATUSES.COMPLETED,
  Rejected: NOTES_STATUSES.REJECTED,
  Submitted: NOTES_STATUSES.SUBMITTED,
  Pending: NOTES_STATUSES.PENDING,
  'No Response': NOTES_STATUSES.NO_RESPONSE,
  Unsuccessful: NOTES_STATUSES.UNSUCCESSFUL,
};
export const getFilterValue = valueText => {
  return tagFilterValues[valueText];
};
/// ////////////////////////////////////////
const defaultNotesFilterOptions = [
  {
    sectionTitle: 'Action Status',
    sectionType: FILTER_COLLAPSABLE_SECTION_TYPE.RADIO,
    sectionOptions: [
      { label: 'No Action Needed', value: 'No Action Needed' },
      { label: 'Needs Action', value: 'Needs Action' },
    ],
  },
];

export const allNotesFilterOption = () => {
  return [
    ...defaultNotesFilterOptions,
    {
      sectionTitle: 'Note Types',
      sectionType: FILTER_COLLAPSABLE_SECTION_TYPE.DEFAULT,
      sectionOptions: [
        { label: 'General Notes', value: 'General Notes' },
        { label: 'Contact Notes', value: 'Contact Notes' },
        { label: 'Cautionary Notes', value: 'Cautionary Notes' },
        { label: 'Prohibitive Notes', value: 'Prohibitive Notes' },
        { label: 'Release of Information Notes', value: 'Release of Information Notes' },
        { label: 'Lab Request Notes', value: 'Lab Request Notes' },
        { label: 'Pharmacy Assistance Notes', value: 'Pharmacy Assistance Notes' },
        { label: 'Referral Out Notes', value: 'Referral Out Notes' },
        { label: 'Prior Authorization Notes', value: 'Prior Authorization Notes' },
      ],
    },
  ];
};

// General Notes - General Notes
// General Notes - Contact Notes
// General Notes - Prohibitive Notes
// General Notes - Cautionary Notes

export const generalNotesFilterOption = () => {
  return [
    ...defaultNotesFilterOptions,
    {
      sectionTitle: 'Note Status',
      sectionType: FILTER_COLLAPSABLE_SECTION_TYPE.DEFAULT,
      sectionOptions: [
        { label: 'Reported', value: 'Reported' },
        { label: 'Reviewed by Management', value: 'Reviewed by Management' },
        { label: 'Resolved', value: 'Resolved' },
      ],
    },
  ];
};

export const releaseOfInformationNotesFilterOption = () => {
  return [
    ...defaultNotesFilterOptions,
    {
      sectionTitle: 'Note Status',
      sectionType: FILTER_COLLAPSABLE_SECTION_TYPE.DEFAULT,
      sectionOptions: [
        { label: 'Received by Care Management', value: 'Received by Care Management' },
        { label: 'Sent to Member/Client/Guest', value: 'Sent to Member/Client/Guest' },
        {
          label: 'Sent to Requested Contact/Department/Company',
          value: 'Sent to Requested Contact/Department/Company',
        },
        { label: 'Completed', value: 'Completed' },
      ],
    },
  ];
};

export const labRequestNotesNotesFilterOption = () => {
  return [
    ...defaultNotesFilterOptions,
    {
      sectionTitle: 'Note Status',
      sectionType: FILTER_COLLAPSABLE_SECTION_TYPE.DEFAULT,
      sectionOptions: [
        { label: 'Sent to Lab', value: 'Sent to Lab' },
        { label: 'No Response - Client Inaction', value: 'No Response - Client Inaction' },
        { label: 'Completed - Results', value: 'Completed - Results' },
        { label: 'Rejected', value: 'Rejected' },
      ],
    },
  ];
};

// General Notes - Pharmacy Assistance Notes
// General Notes - Referral Out Notes
// General Notes - Prior Authorization Notes
export const newNotesFilterOption = () => {
  return [
    ...defaultNotesFilterOptions,
    {
      sectionTitle: 'Note Status',
      sectionType: FILTER_COLLAPSABLE_SECTION_TYPE.DEFAULT,
      sectionOptions: [
        { label: 'Submitted', value: 'Submitted' },
        { label: 'Pending', value: 'Pending' },
        { label: 'Resolved', value: 'Resolved' },
        { label: 'No Response', value: 'No Response' },
        { label: 'Unsuccessful', value: 'Unsuccessful' },
      ],
    },
  ];
};
