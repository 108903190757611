export const flowTypeSelect = [
  { label: 'Multi select', value: 'Multi select' },
  { label: 'Single select', value: 'Single select' },
  { label: 'Rating scale', value: 'Rating scale' },
];

export const flowProfileElementSelect = [{ label: 'Recent issue', value: 'Recent issue' }];

export const flowLogicOptions = [
  { label: 'Always Show', value: 'always' },
  { label: 'If Conversation Variable', value: 'R' },
  { label: 'If Profile Element', value: 'P' },
];

export const noAnswerOptions = [{ label: 'Not Discussed', value: 'Not Discussed' }];
export const notApplicableOptions = [{ label: 'Not Applicable', value: 'Not Applicable' }];
export const responseLengthOptions = [
  { label: 'Only select from list of choices', value: 'select-choice' },
  { label: 'Short', value: 'short' },
  { label: 'Short with description', value: 'short-with-description' },
  { label: 'Short with description and bullet points', value: 'short-with-bullets' },
  { label: 'As short as possible', value: 'shortest-possible' },
  { label: 'None', value: 'none' },
];

export const flowRoutingSelect = [{ label: 'Next content block', value: 'Next content block' }];

export const flowMenuItems = [{ value: 'use-template', label: 'Use template' }];

export const formatTypeSelectOptionsFromArray = options => {
  return options.map(option => {
    return { label: option, value: option };
  });
};
export const formatTypeSelectOptions = options => {
  // const selectOptions = [];
  return Object.keys(options).map(optionKey => {
    return { label: options[optionKey], value: options[optionKey] };
  });
};
