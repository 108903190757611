import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/material';
import { MemberDisconnected } from '../../../../packages/ui/templates/sessions/section-detail/components/member-disconnected';
import { SessionHeader } from '../../../../packages/ui/templates/sessions/session-header';
import { SessionNavigator } from '../../../../packages/ui/templates/sessions/session-navigator';
import { SectionDetail } from '../../../../packages/ui/templates/sessions/section-detail';
import EndSession from '../../../../packages/ui/organisms/end-session-modal';
import { KeyboardShortcutsModal } from '../../../../packages/ui/organisms/keyboard-shortcuts/KeyboardShortcutsModal';
import { Sections, subsections } from '../../../../packages/ui/templates/sessions/sessions.constants';
import { EVALUATION_QUESTION_TYPES, OPENTOK_APIKEY } from '../../../../constants/CommonConstants';
import TopbarView from './components/topbar-view';
import { OTPublisher, OTSession, OTStreams, OTSubscriber } from 'opentok-react';
import { Avatar, avatarType, fontWeight, Heading, headingLevel, Text } from '../../../../packages';
import BottomView from './components/bottom-view';
import { useStyles } from './VideoCallScreen.styles';
import { getProfile } from '../../../../redux/modules/profile/selectors';
import history from '../../../../utils/history';
import dayjs from 'dayjs';
import { getUserTimeZone } from '../../../../utils/dayjs';
import { getAuth } from '../../../../redux/modules/auth/selectors';
import {
  applyPreviousDCTAnswers,
  fetchDctPreviousResponses,
  updateAppointmentEvaluationStatus,
  updateEvaluationContext,
} from '../../../../services/conversation/conversation.service';
import { conversationActionCreators } from '../../../../redux/modules/conversation';
import AddEvaluation from '../../../admin/appointments/appointment-detail-drawer/AddEvaluation';
import {
  selectAssignedEvaluationState,
  selectEvaluation,
  selectEvaluationContext,
} from '../../../../redux/modules/conversation/selectors';
import useEvaluationHead from '../../../../hooks/useEvaluationHead';
import { EvaluationHelper } from '../../../../utils/EvaluationHelper';
import { showSnackbar } from '../../../../redux/modules/snackbar';
import { commonActionCreators } from '../../../../redux/modules/common';
import { getAppointmentById } from '../../../../services/appointment/appointment.service';
import { CbStatus } from '../../../../redux/modules/conversation/types';
import RejectEvaluationModal from '../evaluations-v2/evaluation-modals';
import ConfirmUsePreviousModal from '../../../../packages/ui/organisms/confirm-use-previous-modal';

const list = new EvaluationHelper();

const UpdatedSessionExperience = ({
  appointment,
  endSession,
  telesessionId,
  telesessionToken,
  isInPersonSessionCall,
  isWaiting,
  setIsWaiting,
  onRestartSessionClick,
  setToastProperties,
  nextAppointment,
  nextGeneralAppointment,
  handleMemberActionClick,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { demographicDetails } = useSelector(getProfile);
  const { meta } = useSelector(getAuth);
  const [selectedSection, setSelectedSection] = useState(Sections.WELCOME);
  const [openAddEvaluation, setOpenAddEvaluation] = useState(false);
  const { evaluations } = useSelector(selectAssignedEvaluationState);
  const activeEvaluations = evaluations?.filter(evaluation => evaluation.status !== 'REJECTED') || [];
  const [currentBlock, setCurrentBlock] = useState(null);
  const [currentCbId, setCurrentCbId] = useState(null);
  const [nextInProgress, setNextInProgress] = useState(false);
  const [selectedSubsection, setSelectedSubsection] = useState(subsections.IMPORTANT_INFORMATION);
  const [duration, setDuration] = useState(0);
  const [sessionStarted, setSessionStarted] = useState(true);
  const [sessionEnded, setSessionEnded] = useState(false);
  const [endSessionModalVisible, setEndSessionModalVisible] = useState(false);
  const [shortcutsVisible, setShortcutsVisible] = useState(false);
  const [publishVideo, setPublishVideo] = useState(true);
  const [publishAudio, setPublishAudio] = useState(true);
  const [textInputInFocus, setTextInputInFocus] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [leftTrigger, setLeftTrigger] = useState(false);
  const [rightTrigger, setRightTrigger] = useState(false);
  const [showShareScreenSelection, setShowShareScreenSelection] = useState(false);
  const [memberVideoEnable, setMemberVideoEnable] = useState(true);
  const [publisherJoined, setPublisherJoined] = useState(true);
  const [subscriberJoined, setSubscriberJoined] = useState(true);
  const [currentEvaluation, setCurrentEvaluation] = useState(null);
  const [appointmentDetails, setAppointmentDetails] = useState(null);
  const isSessionEndedRef = useRef(false);
  const [dctPreviousResponses, setDctPreviousResponses] = useState([]);
  const [previousResponses, setPreviousResponses] = useState([]);
  const [dctSelectedResponses, setDctSelectedResponses] = useState([]);
  const [evaluationsCompleted, setEvaluationsCompleted] = useState(false);
  const [usedEvaluationId, setUsedEvaluationId] = useState(null);
  const [evaluationToReject, setEvaluationToReject] = useState(null);
  const [previousResponseFlags, setPreviousResponseFlags] = useState(null);
  const allEvaluations = useSelector(selectEvaluation);
  const evaluationContext = useSelector(selectEvaluationContext);
  const { head, processEvaluationData } = useEvaluationHead();
  const checkMediaPermissions = async () => {
    try {
      const cameraPermission = await navigator.permissions.query({ name: 'camera' });
      const microphonePermission = await navigator.permissions.query({ name: 'microphone' });

      const videoGranted = cameraPermission.state === 'granted';
      const audioGranted = microphonePermission.state === 'granted';
      if (videoGranted && audioGranted) {
        console.log('Video and audio permissions granted.');
        return true;
      } else if (cameraPermission.state === 'prompt' || microphonePermission.state === 'prompt') {
        cameraPermission.onchange = () => handlePermissionChange();
        microphonePermission.onchange = () => handlePermissionChange();
      } else if (cameraPermission.state === 'denied' || microphonePermission.state === 'denied') {
        handlePermissionChange();
      }
      alert('Please enable both camera and microphone permissions.');
      return false;
    } catch (error) {
      console.error('Error checking media permissions:', error);
      alert('An error occurred while checking media permissions.');
      return false;
    }
  };

  const handlePermissionChange = async () => {
    const cameraPermission = await navigator.permissions.query({ name: 'camera' });
    const microphonePermission = await navigator.permissions.query({ name: 'microphone' });

    if (cameraPermission.state === 'granted' && microphonePermission.state === 'granted') {
      console.log('Permissions granted, reloading page...');
      window.location.reload();
    }
  };

  const startSignoff = () => {
    history.replace(`/provider/appointments/current/${appointment.appointmentId}/session-signoff/v2`);
  };

  const goToSchedule = () => {
    history.replace(
      `/provider/${meta.userId}/day-schedule?date=${dayjs().tz(getUserTimeZone()).format('YYYY-MM-DD')}`,
    );
  };

  const onAddEvaluation = selectedValues => {
    dispatch(
      conversationActionCreators.addAssignedEvaluation({
        evaluationIds: selectedValues,
        appointmentId: appointment?.appointmentId,
      }),
    );
    setOpenAddEvaluation(false);
    if (evaluationsCompleted) {
      setUsedEvaluationId(selectedValues[0]);
    }
  };

  const handleEvaluationDeletion = evaluationId => {
    const isAssigned = evaluations.some(evalId => evalId.id === evaluationId);
    if (isAssigned) {
      const { appointmentId } = appointment;
      dispatch(conversationActionCreators.deleteEvaluation({ appointmentId, evaluationId }));
    }
  };

  const publisherEventHandlers = {
    error: error => {
      console.log(`There was an error with the publisher: ${error}`);
    },
    streamCreated: async event => {
      await checkMediaPermissions();
      console.log('Publisher stream created', event);
      setPublisherJoined(true);
    },
    streamDestroyed: event => {
      console.log(`Publisher stream destroyed because: ${event}`);
      setPublisherJoined(false);
    },
    mediaStopped: event => {
      console.log('Media Stopped');
      console.log(event);
    },
  };

  const sessionEventHandlers = {
    streamCreated: async event => {
      await checkMediaPermissions();
      console.log('Stream created event', event);
      setSubscriberJoined(true);
    },
    signal: event => {
      if (event.type === 'session-ended' || event.type === 'signal:session-ended') {
        isSessionEndedRef.current = true;
        setSessionEnded(true);
      }
    },
    streamDestroyed: event => {
      console.log(`Stream destroyed ${event}`);
      setSubscriberJoined(false);
    },
    connectionDestroyed: event => {
      console.log(`Connection destroyed ${event}`);
      setSubscriberJoined(false);
    },
    sessionConnected: async event => {
      const mediaStream = await checkMediaPermissions();
      if (mediaStream) {
        console.log(`sessionConnected: ${event}`);
      }
    },
    sessionDisconnected: event => {
      console.log(`sessionDisconnected: ${event}`);
    },
  };

  const subscriberEventHandlers = {
    error: error => {
      console.log(`There was an error with the subscriber: ${error}`);
    },
    connected: async event => {
      const mediaStream = await checkMediaPermissions();
      if (mediaStream) {
        console.log(`Subscriber connected to the stream`);
        setSessionStarted(true);
        setIsWaiting(false);
        console.log(event);
      }
    },
    videoDisabled: event => {
      console.log('Subscriber video disabled!', event);
      setMemberVideoEnable(false);
    },
    videoEnabled: event => {
      setMemberVideoEnable(true);
      console.log('Subscriber video enabled!', event);
    },
    disconnected: event => {
      console.log(`Subscriber disconnected from the stream`);
      console.log(event);
    },
  };

  const showEndSessionWarning = () => {
    setEndSessionModalVisible(true);
  };

  const onEndSession = () => {
    setEndSessionModalVisible(false);
    setSessionEnded(true);
    setSelectedSection(Sections.CLOSE_SESSION);
    setSelectedSubsection(null);
    endSession();
  };

  const onShareScreenClick = () => {
    setShowShareScreenSelection(true);
  };

  const onStopSharingClick = () => {
    if (showShareScreenSelection) {
      setShowShareScreenSelection(false);
    }
  };

  const toggleVideCall = () => {
    setPublishVideo(!publishVideo);
  };

  const toggleMic = () => {
    setPublishAudio(!publishAudio);
  };

  const openEvaluationsDrawer = () => {
    setOpenAddEvaluation(true);
  };

  const fetchAppointmentDetails = () => {
    getAppointmentById({ appointmentId: appointment.appointmentId })
      .then(res => {
        setAppointmentDetails(res.data);
      })
      .catch(e => console.log(e));
  };

  useEffect(() => {
    void fetchAppointmentDetails();
  }, []);

  useEffect(() => {
    if (usedEvaluationId && evaluations) {
      const foundEvaluation = evaluations.find(evaluation => evaluation.id === usedEvaluationId);
      if (foundEvaluation && foundEvaluation.status !== 'REJECTED') {
        setUsedEvaluationId(null);
        setEvaluationsCompleted(null);
        setCurrentEvaluation(foundEvaluation);
      }
    }
  }, [evaluations, usedEvaluationId]);

  useEffect(() => {
    if (appointment) {
      dispatch(
        conversationActionCreators.fetchAssignedEvaluations({ appointmentId: appointment.appointmentId }),
      );
      dispatch(conversationActionCreators.fetchEvaluations());
    }
  }, [appointment]);

  useEffect(() => {
    if (currentEvaluation) {
      list.setHead(null);
      setEvaluationsCompleted(false);
      dispatch(
        conversationActionCreators.fetchEvaluationContext({
          appointmentId: appointment.appointmentId,
          evaluationId: currentEvaluation.id,
        }),
      );
    }
    return () => {
      dispatch(
        conversationActionCreators.evaluationContextUpdated({
          evaluationContext: null,
        }),
      );
    };
  }, [currentEvaluation]);

  useEffect(() => {
    if (evaluationContext) {
      processEvaluationData(evaluationContext);
    }
  }, [evaluationContext]);

  useEffect(() => {
    if (head) {
      if (!list.getHead()) {
        list.setHead(head);
      } else {
        list.setHead(head);
        setNextBlock(currentBlock, head);
      }
    }
  }, [head]);

  const updateEvaluationStatus = async request => {
    let payload;
    if (request) {
      payload = request;
    } else {
      payload = {
        evaluationUpdatedStatus: 'COMPLETED',
        evaluationId: currentEvaluation.id,
        remarks: 'Evaluation completed',
      };
    }
    const { data } = await updateAppointmentEvaluationStatus(payload, {
      appointmentId: appointment.appointmentId,
    });
    if (data.errors) {
      console.warn(data.errors);
      dispatch(
        showSnackbar({
          snackType: 'error',
          snackMessage: data.errors?.[0]?.endUserMessage || 'Something went wrong',
        }),
      );
    } else {
      if (request) {
        dispatch(
          conversationActionCreators.fetchAssignedEvaluations({
            appointmentId: appointment.appointmentId,
          }),
        );
      }
    }
  };

  const completeEvaluation = () => {
    const evalCompleted = list.isEvaluationCompleted();
    if (evalCompleted) {
      void updateEvaluationStatus();
    }
    if (currentEvaluation && activeEvaluations.length > 0) {
      const currentIndex = activeEvaluations.findIndex(e => e.id === currentEvaluation.id);
      if (currentIndex < activeEvaluations.length - 1) {
        list.setHead(null);
        setCurrentBlock(null);
        setCurrentEvaluation(activeEvaluations[currentIndex + 1]);
      } else {
        setEvaluationsCompleted(true);
        list.setHead(null);
        setCurrentBlock(null);
        setCurrentEvaluation(null);
      }
    }
  };

  const setNextBlock = (block, context, forceNext = false) => {
    if (block) {
      if (nextInProgress || forceNext) {
        const next = list.getNextApplicableBlock(block.cbId, true);
        if (next) {
          setCurrentBlock(next);
        } else {
          completeEvaluation();
        }
        setNextInProgress(false);
      } else {
        setCurrentBlock(list.getContentBlock(block.cbId));
      }
    } else {
      if (context.status !== CbStatus.ANSWERED) {
        setCurrentBlock(context);
      } else {
        setNextBlock(context, context, true);
      }
    }
  };

  const submitAnswer = async () => {
    if (currentBlock) {
      let payload = null;
      if (currentBlock.cbType === 'dct') {
        if (currentBlock.fullPage) {
          const { children } = currentBlock;
          if (children.length > 0) {
            payload = children
              .map(child => {
                if (child.userResponse) {
                  const evaluationResponses = child.userResponse.userResponseList;
                  if (
                    !evaluationResponses ||
                    evaluationResponses.length === 0 ||
                    (evaluationResponses && evaluationResponses.length === 1 && evaluationResponses[0] === '')
                  ) {
                    return null;
                  }
                  return {
                    contentBlockId: child.cbId,
                    dctId: currentBlock.dctId,
                    evaluationResponses,
                    providerNarrative: '',
                  };
                }
                return null;
              })
              .filter(Boolean);
          }
        } else {
          if (dctSelectedResponses.length > 0) {
            payload = dctSelectedResponses.map(response => {
              return {
                contentBlockId: response.cbId,
                dctId: currentBlock.dctId,
                evaluationResponses: response.answers.map(ans => ans.value),
                providerNarrative: '',
              };
            });
          }
        }
      } else {
        if (currentBlock.userResponse) {
          const { userResponse } = currentBlock;
          let evaluationResponses = userResponse?.userResponseList;

          if (evaluationResponses && evaluationResponses.length === 1 && evaluationResponses[0] === '')
            evaluationResponses = [];
          payload = [
            {
              contentBlockId: currentBlock.cbId,
              dctId: currentBlock.dctId,
              evaluationResponses,
              providerNarrative: userResponse?.providerNarrative || '',
            },
          ];
        }
      }
      if (payload) {
        try {
          const { data } = await updateEvaluationContext(payload, {
            appointmentId: appointment.appointmentId,
            evaluationId: currentEvaluation.id,
          });
          if (data.errors) {
            console.warn(data.errors);
          } else {
            list.transformEvaluation(data);
            dispatch(
              conversationActionCreators.evaluationContextUpdated({
                evaluationContext: data,
                linkedEvaluationNodes: list.getHead(),
              }),
            );
          }
        } catch (e) {
          console.warn(e);
        }
      } else {
        setNextBlock(currentBlock, head, true);
      }
    }
  };

  const respondAndGoNext = () => {
    setNextInProgress(true);
    void submitAnswer();
  };

  const onPrevious = () => {
    if (selectedSection === Sections.WELCOME) {
      if (selectedSubsection === subsections.DOUBLE_CHECK_IDS) {
        setSelectedSubsection(subsections.IMPORTANT_INFORMATION);
      }
    } else if (selectedSection === Sections.EVALUATIONS) {
      if (currentBlock) {
        const prev = list.getPreviousApplicableBlock(currentBlock.cbId, true);
        if (prev) {
          setCurrentBlock(prev);
        } else {
          setCurrentBlock(null);
        }
      } else {
        if (currentEvaluation) {
          const currentIndex = activeEvaluations.findIndex(e => e.id === currentEvaluation.id);
          if (currentIndex > 0) {
            setCurrentEvaluation(activeEvaluations[currentIndex - 1]);
          } else {
            setCurrentEvaluation(null);
            setSelectedSubsection(null);
          }
        } else {
          setSelectedSection(Sections.WELCOME);
          setEvaluationsCompleted(false);
          const firstAppointment = appointmentDetails?.firstAppointment;
          if (firstAppointment) {
            setSelectedSubsection(subsections.DOUBLE_CHECK_IDS);
          } else {
            setSelectedSubsection(subsections.IMPORTANT_INFORMATION);
          }
          setCurrentBlock(null);
          setCurrentEvaluation(null);
        }
      }
    } else if (selectedSection === Sections.OPEN_SESSION) {
      setSelectedSection(Sections.EVALUATIONS);
    } else if (selectedSection === Sections.CLOSE_SESSION) {
      setSelectedSection(Sections.OPEN_SESSION);
    }
  };

  const onNext = () => {
    if (selectedSection === Sections.WELCOME) {
      if (selectedSubsection === subsections.IMPORTANT_INFORMATION) {
        const firstAppointment = appointmentDetails?.firstAppointment;
        if (firstAppointment) {
          setSelectedSubsection(subsections.DOUBLE_CHECK_IDS);
        } else {
          setSelectedSection(Sections.EVALUATIONS);
          setSelectedSubsection(null);
        }
      } else {
        setSelectedSection(Sections.EVALUATIONS);
        setSelectedSubsection(null);
      }
    } else if (selectedSection === Sections.EVALUATIONS) {
      if (evaluationsCompleted) {
        setSelectedSection(Sections.OPEN_SESSION);
        setEvaluationsCompleted(false);
        setCurrentEvaluation(null);
        setCurrentBlock(null);
        list.setHead(null);
      } else {
        if (!currentEvaluation) {
          if (activeEvaluations.length > 0) {
            setCurrentEvaluation(activeEvaluations[0]);
          } else {
            setSelectedSection(Sections.OPEN_SESSION);
          }
        } else {
          if (list.getHead()) {
            if (currentBlock) {
              respondAndGoNext();
            } else {
              setNextBlock(currentBlock, head, true);
            }
          }
        }
      }
    } else if (selectedSection === Sections.OPEN_SESSION && sessionEnded) {
      setSelectedSection(Sections.CLOSE_SESSION);
    }
  };

  const nextQuestionByKey = e => {
    e.preventDefault();
    e.target.blur();
    onNext();
  };

  const getPreviousResponses = async () => {
    const queryParams = {
      evaluationId: evaluationContext?.evaluationId,
      userId: appointment?.participantId,
      practitionerId: appointment?.practitionerId,
      appointmentId: appointment?.appointmentId,
      dctId: currentBlock?.dctId,
    };

    if (currentBlock.cbType !== 'dct') {
      delete queryParams.dctId;
      queryParams.cbId = currentBlock?.cbId;
    }

    try {
      const response = await fetchDctPreviousResponses(queryParams);
      const cbResponseList = response?.data?.cbResponseList || [];

      if (currentBlock.cbType === 'dct') {
        setDctPreviousResponses(cbResponseList);
      } else {
        setPreviousResponses(cbResponseList);
      }
    } catch (error) {
      console.log({ error });
    }
  };

  const handleKeyboardShortcuts = useCallback(
    e => {
      if (shortcutsVisible === true || textInputInFocus) {
        return;
      }

      switch (e.key) {
        case ' ':
          if (e.shiftKey) {
            e.preventDefault();
            e.target.blur();
            onPrevious();
          } else {
            nextQuestionByKey(e);
          }
          break;

        case 'ArrowUp':
          e.preventDefault();
          if (selectedIndex > 0) {
            setSelectedIndex(selectedIndex - 1);
          }
          break;

        case 'ArrowDown':
          e.preventDefault();
          if (selectedIndex < currentBlock.choices.length - 1) {
            setSelectedIndex(selectedIndex + 1);
          }
          break;

        case 'ArrowRight':
          if (selectedIndex > -1 || (currentBlock && currentBlock.cbType === 'rating-scale')) {
            setRightTrigger(prev => !prev);
          }
          break;

        case 'ArrowLeft':
          if (selectedIndex > -1 || (currentBlock && currentBlock.cbType === 'rating-scale')) {
            setLeftTrigger(prev => !prev);
          }
          break;

        case 'K':
          if (e.shiftKey) {
            e.preventDefault();
            e.target.blur();
            setShortcutsVisible(prev => !prev);
          }
          break;

        default:
          break;
      }
    },
    [
      shortcutsVisible,
      textInputInFocus,
      selectedIndex,
      currentBlock,
      onPrevious,
      nextQuestionByKey,
      setSelectedIndex,
      setRightTrigger,
      setLeftTrigger,
      setShortcutsVisible,
    ],
  );

  useEffect(() => {
    window.addEventListener('keydown', handleKeyboardShortcuts);
    return () => {
      window.removeEventListener('keydown', handleKeyboardShortcuts);
    };
  }, [handleKeyboardShortcuts]);

  useEffect(() => {
    setSelectedIndex(-1);
    setDctPreviousResponses([]);
    setDctSelectedResponses([]);
    setPreviousResponses([]);
    if (currentBlock) {
      setCurrentCbId(currentBlock.cbId);
    }
  }, [currentBlock]);

  useEffect(() => {
    if (currentCbId) {
      void getPreviousResponses();
    }
  }, [currentCbId]);

  const handleOpenSessionJump = () => {
    setSelectedSection(Sections.OPEN_SESSION);
    setCurrentEvaluation(null);
    setCurrentBlock(null);
    setSelectedSubsection(null);
  };

  const onPreviousResponseSelected = (cbId, answers) => {
    if (dctSelectedResponses.some(response => response.cbId === cbId)) {
      setDctSelectedResponses(prev => prev.filter(response => response.cbId !== cbId));
    } else {
      setDctSelectedResponses(prev => [...prev, { cbId, answers }]);
    }
  };

  const onUseAllPreviousResponses = async (allNormal, allRisky) => {
    if (allNormal) {
      setPreviousResponseFlags(null);
      if (currentBlock.cbType === 'dct') {
        const requestBody = {
          appointmentId: appointment?.appointmentId,
          evaluationId: currentEvaluation?.id,
          userId: appointment?.participantId,
          dctId: currentBlock.dctId,
          practitionerId: appointment?.practitionerId,
        };

        try {
          setNextInProgress(true);
          const response = await applyPreviousDCTAnswers(requestBody);
          if (response?.status === 200) {
            dispatch(
              conversationActionCreators.fetchEvaluationContext({
                appointmentId: appointment?.appointmentId,
                evaluationId: currentEvaluation?.id,
              }),
            );
          }
        } catch (error) {
          console.log({ error });
        }
      }
    } else {
      setPreviousResponseFlags({ allNormal, allRisky });
    }
  };

  const onSkipEvaluation = () => {
    // find index of currentEvaluation from evaluations and jump to next
    const currentIndex = activeEvaluations.findIndex(e => e.id === currentEvaluation.id);
    if (currentIndex < activeEvaluations.length - 1) {
      list.setHead(null);
      setCurrentEvaluation(activeEvaluations[currentIndex + 1]);
    } else {
      setEvaluationsCompleted(true);
      setCurrentEvaluation(null);
    }
  };

  const onRejectEvaluation = evaluation => {
    setEvaluationToReject(evaluation);
  };

  return (
    <>
      <Box display="flex" flex={1}>
        {!sessionEnded && (
          <Box flex={isInPersonSessionCall ? 0 : 1}>
            <OTSession
              apiKey={OPENTOK_APIKEY}
              sessionId={telesessionId}
              token={telesessionToken}
              eventHandlers={sessionEventHandlers}
            >
              {publisherJoined && (
                <TopbarView
                  hidden={isInPersonSessionCall}
                  publisher={
                    <OTPublisher
                      properties={{
                        publishVideo: isInPersonSessionCall ? false : publishVideo,
                        publishAudio,
                        width: 200,
                        height: 114,
                        style: { borderRadius: '8px' },
                      }}
                      eventHandlers={publisherEventHandlers}
                    />
                  }
                  screen={
                    showShareScreenSelection && (
                      <OTPublisher properties={{ videoSource: 'screen', width: 200, height: 114 }} />
                    )
                  }
                />
              )}
              {subscriberJoined ? (
                <>
                  {memberVideoEnable && !isInPersonSessionCall && (
                    <div className={classes.subscriberVideo}>
                      <OTStreams>
                        <OTSubscriber
                          properties={{ height: '100vh', width: '100%', fitMode: 'cover' }}
                          eventHandlers={subscriberEventHandlers}
                        />
                      </OTStreams>
                    </div>
                  )}
                  {!memberVideoEnable && (
                    <Box className={classes.noVideo}>
                      <Box
                        display="flex"
                        alignItems="center"
                        flexDirection="column"
                        height="100vh"
                        justifyContent="center"
                      >
                        <Avatar
                          src={appointment?.participantImage}
                          name={appointment?.participantName}
                          size={128}
                          variant={avatarType.CIRCLE}
                        />
                        <Heading level={headingLevel.M} weight={fontWeight.BOLD} className={classes.username}>
                          {appointment?.participantName?.split(' ').slice(0, -1).join(' ')}
                        </Heading>
                        <Text className={classes.fullname} weight={fontWeight.SEMI_BOLD}>
                          {appointment?.participantName}
                        </Text>
                      </Box>
                    </Box>
                  )}
                </>
              ) : (
                <MemberDisconnected
                  firstName={appointment.patientFirstName}
                  phoneNumber={appointment.patientPhoneNumber}
                  duration={duration}
                  serviceDuration={appointment.serviceDuration}
                  onMemberActionClick={handleMemberActionClick}
                />
              )}
              <BottomView
                hidden={isInPersonSessionCall}
                isSharing={showShareScreenSelection}
                isVideoCall={publishVideo}
                isAudio={publishAudio}
                onShareScreenClick={onShareScreenClick}
                onStopSharingClick={onStopSharingClick}
                toggleVideCall={toggleVideCall}
                toggleMic={toggleMic}
              />
            </OTSession>
          </Box>
        )}
        <Box flex={1.8} flexDirection="column" height="100vh" display="flex">
          {!isWaiting && (
            <SessionHeader
              appointment={appointment}
              isEndedSession={sessionEnded}
              isSessionStarted={sessionStarted}
              onDurationUpdated={setDuration}
              onEndSession={showEndSessionWarning}
              startSignoff={startSignoff}
              goToSchedule={goToSchedule}
            />
          )}
          {!isWaiting && (
            <Box width="100%" display="flex" flexGrow={1}>
              <SessionNavigator
                isFirstAppointment={appointmentDetails?.firstAppointment}
                sessionEnded={sessionEnded}
                selectedSection={selectedSection}
                setSelectedSection={setSelectedSection}
                selectedEvaluationId={selectedSubsection}
                setSelectedEvaluationId={setSelectedSubsection}
                currentEvaluation={currentEvaluation}
                setCurrentEvaluation={setCurrentEvaluation}
                currentBlock={currentBlock}
                setCurrentBlock={setCurrentBlock}
                evaluationsCompleted={evaluationsCompleted}
                listHelper={list}
                onMemberActionClick={handleMemberActionClick}
              />
              <SectionDetail
                section={selectedSection}
                subSection={selectedSubsection}
                idFront={demographicDetails && demographicDetails.idCardFront}
                idBack={demographicDetails && demographicDetails.idCardBack}
                onEndSession={showEndSessionWarning}
                showKeyboardShortcuts={setShortcutsVisible}
                startSignoff={startSignoff}
                appointment={appointment}
                nextAppointment={nextAppointment}
                nextGeneralAppointment={nextGeneralAppointment}
                onRestartSessionClick={onRestartSessionClick}
                openEvaluationsDrawer={openEvaluationsDrawer}
                onAddEvaluation={onAddEvaluation}
                jumpToOpenSession={handleOpenSessionJump}
                scratchPadText={appointmentDetails?.scratchPad ?? ''}
                selectedIndex={selectedIndex}
                goToSchedule={goToSchedule}
                currentBlock={currentBlock}
                currentEvaluation={currentEvaluation}
                setTextInputInFocus={setTextInputInFocus}
                evaluationsCompleted={evaluationsCompleted}
                sessionEnded={sessionEnded}
                onNext={onNext}
                listHelper={list}
                onSkipEvaluation={onSkipEvaluation}
                onRejectEvaluation={onRejectEvaluation}
                dctPreviousResponses={dctPreviousResponses}
                dctSelectedResponses={dctSelectedResponses}
                onPreviousResponseSelected={onPreviousResponseSelected}
                leftTrigger={leftTrigger}
                rightTrigger={rightTrigger}
                onPrevious={onPrevious}
                onMemberActionClick={handleMemberActionClick}
                previousResponses={previousResponses}
                useAllPreviousResponses={onUseAllPreviousResponses}
              />
            </Box>
          )}
        </Box>
      </Box>
      <EndSession
        open={endSessionModalVisible}
        onClose={() => setEndSessionModalVisible(false)}
        onEndSession={onEndSession}
      />
      {previousResponseFlags && (
        <ConfirmUsePreviousModal
          open={!!previousResponseFlags}
          onClose={() => setPreviousResponseFlags(null)}
          previousResponseFlags={previousResponseFlags}
          onConfirm={() => {
            void onUseAllPreviousResponses(true, false);
          }}
        />
      )}

      <KeyboardShortcutsModal onClose={() => setShortcutsVisible(false)} open={shortcutsVisible} />
      {openAddEvaluation && allEvaluations?.length > 0 && (
        <AddEvaluation
          isOpen={openAddEvaluation}
          onClose={() => setOpenAddEvaluation(false)}
          evaluations={allEvaluations}
          assignedEvaluations={evaluations}
          appointment={appointment}
          onAddEvaluation={onAddEvaluation}
          onRemoveEvaluation={handleEvaluationDeletion}
        />
      )}
      {evaluationToReject && (
        <RejectEvaluationModal
          open={!!evaluationToReject}
          onClose={() => {
            setEvaluationToReject(null);
          }}
          evaluation={evaluationToReject}
          updateEvaluationStatus={updateEvaluationStatus}
          setToastProperties={setToastProperties}
        />
      )}
    </>
  );
};
export { UpdatedSessionExperience };
