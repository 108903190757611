export const tableColumns = [
  { id: 'dctName', label: 'DCT', sortable: false },
  { id: 'dctVersion', label: 'Version', sortable: false },
  { id: 'conversationName', label: 'Conversation name', sortable: false },
  { id: 'conversationType', label: 'Conversation type', sortable: false },
  { id: 'conversationVariable', label: 'Conversation variable', sortable: false },
  { id: 'assignment', label: 'Assignment', sortable: false },
  { id: 'onClick', label: '', sortable: false },
];

export const getProfileElementDctEvaluationListQuery = params => {
  return {
    size: 0,
    query: {
      bool: {
        must: [
          {
            nested: {
              path: 'dataElementDetails',
              query: {
                bool: {
                  must: [
                    {
                      term: {
                        'dataElementDetails.dataElementKey.keyword': {
                          value: params.profileKey,
                        },
                      },
                    },
                  ],
                },
              },
              inner_hits: {},
            },
          },
        ],
      },
    },
    aggs: {
      by_assignments: {
        terms: {
          field: 'dct_name.keyword',
          size: 100,
          order: {
            _count: 'desc',
            uniqueMembers: 'desc',
          },
        },
        aggs: {
          uniqueMembers: {
            cardinality: {
              field: 'member_id.keyword',
            },
          },
          by_last_assigned_date: {
            top_hits: {
              _source: [
                'cdct_id',
                'dct_id',
                'dct_fullname',
                'dct_version',
                'conversation_name',
                'conversation_version',
                'referenceId_conversation_variable',
                'conversation_id',
                'member_name',
                'lastModifiedDate',
              ],
              sort: [
                {
                  lastModifiedDate: {
                    order: 'desc',
                  },
                },
              ],
              size: 1,
            },
          },
        },
      },
    },
  };
};

export const getProfileElementDctConvoListQuery = params => {
  return {
    size: 0,
    query: {
      bool: {
        must: [
          {
            nested: {
              path: 'dataElementDetails',
              query: {
                bool: {
                  must: [
                    {
                      term: {
                        'dataElementDetails.dataElementKey.keyword': {
                          value: params.profileKey,
                        },
                      },
                    },
                  ],
                },
              },
              inner_hits: {},
            },
          },
        ],
      },
    },
    aggs: {
      by_assignments: {
        terms: {
          field: 'dct_name.keyword',
          size: 100,
          order: {
            _count: 'desc',
            uniqueMembers: 'desc',
          },
        },
        aggs: {
          uniqueMembers: {
            cardinality: {
              field: 'member_id.keyword',
            },
          },
          by_last_assigned_date: {
            top_hits: {
              _source: [
                'cdct_id',
                'dct_id',
                'dct_fullname',
                'dct_version',
                'conversation_name',
                'conversation_version',
                'referenceId_conversation_variable',
                'conversation_id',
                'member_name',
                'lastModifiedDate',
              ],
              sort: [
                {
                  lastModifiedDate: {
                    order: 'desc',
                  },
                },
              ],
              size: 1,
            },
          },
        },
      },
    },
  };
};

export const mapChatBotResponseToTableObject = (data, type) => {
  const list = { ...data.aggregations.by_assignments };
  if (list.buckets.length > 0) {
    const mappedList = list.buckets.map(hit => {
      const { value: assignment } = hit.uniqueMembers;
      const {
        dct_id: dctId,
        dct_fullname: dctName,
        dct_version: dctVersion,
        conversation_name: conversationName,
        referenceId_conversation_variable: conversationVariable,
      } = hit.by_last_assigned_date.hits.hits[0]._source;
      return {
        dctName,
        dctVersion,
        conversationName,
        conversationType: type,
        conversationVariable,
        assignment,
        dctId,
      };
    });
    return mappedList;
  }
  return [];
};
