import { makeStyles } from '@mui/styles';

// colors
import { colors } from '../../../../../../packages';

export const useStyles = makeStyles({
  cardTop: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '26px 24px',
  },
  cardTopTitle: {
    fontSize: 18,
    lineHeight: '24px',
    margin: 0,
  },
  cardTopSubtitle: {
    fontSize: 16,
    lineHeight: '24px',
    display: 'block',
    margin: 0,
    marginTop: 4,
    color: colors.neutral600,
  },
  badge: {
    borderRadius: 20,
    padding: '4px 8px',
    width: 'fit-content',
    height: 24,
    fontSize: 14,
    fontWeight: 600,
  },
  viewResponseBtn: {
    fontSize: 14,
    lineHeight: '20px',
  },
});
