import React, { FC } from 'react';
import clsx from 'clsx';

import { Select, selectType } from '../../../../../packages/ui/atoms/select';
import { levels } from './SelectLevel.constants';
import { useStyles } from './SelectLevel.styles';

const SelectLevel = ({
  value,
  name,
  isLifeEvent = false,
  placeholder,
  options,
  onChange,
  displayEmpty,
  emptyText,
}) => {
  const classes = useStyles();
  return (
    <Select
      options={isLifeEvent ? options : levels}
      value={value}
      placeholder={placeholder || 'Select a level'}
      name={name || 'status'}
      onChange={onChange}
      variant={selectType.SECONDARY}
      fullWidth
      emptyText={emptyText}
      displayEmpty={displayEmpty}
      className={clsx(classes.select, classes[`select${value}`])}
    />
  );
};

export { SelectLevel };
