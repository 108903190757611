// colors
import { colors } from '../../../../../packages/colors';
import { makeStyles } from '@mui/styles';
import { theme } from '../../../../../packages/theme';

export const useStyles = makeStyles({
  badge: {
    fontSize: 12,
    height: 18,
    textWrap: 'nowrap',
  },
  wrapper: {
    // overflow: 'hidden',
  },
  noPointer: {
    '& .collapsableHeader': {
      cursor: 'auto !important',
    },
  },
  previewButton: {
    fontSize: '14px',
    fontWeight: 700,
    padding: 0,
    textWrap: 'nowrap',
  },
  backBtn: {
    width: 40,
    height: 40,
    minWidth: 40,
    borderRadius: 8,
    border: `1px solid ${colors.primary300}`,
    '& svg': {
      width: 8,
    },
  },
  previewEvaluationName: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    color: colors.neutral700,
  },
  drawer: {
    '& .MuiPaper-root': {
      maxWidth: '600px !important',
    },
    '& .drawerBody': {
      minHeight: 'calc(100vh - 80px)',
      flexDirection: 'column',
      display: 'flex',
      padding: '38px 32px',
    },
  },
  fullScreen: {
    '& .MuiPaper-root': {
      minWidth: '-webkit-fill-available',
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 24,
    borderBottom: `1px solid ${colors.neutral200}`,
  },
  title: {
    fontSize: 22,
    lineHeight: '28px',
    fontWeight: 600,
  },
  subTitle: {
    fontSize: 16,
    lineHeight: '24px',
    fontWeight: 500,
    color: colors.neutral600,
  },
  countBadge: {
    border: `1px solid ${colors.neutral200}`,
    borderRadius: 100,
    color: colors.neutral700,
    background: colors.white,
    height: 20,
    fontSize: 12,
    lineHeight: '16px',
    fontWeight: 600,
  },
  evBadge: {
    borderRadius: 100,
    height: 20,
    fontSize: 12,
    lineHeight: '16px',
    fontWeight: 600,
    textWrap: 'nowrap',
  },
  editEvaluationDetail: {
    width: 60,
    height: 40,
  },
  evaluationTabs: {
    '& .MuiTabs-flexContainer': {
      overflowX: 'auto',
      paddingBottom: 8,
      '&.MuiTabs-flexContainer::-webkit-scrollbar': {
        height: 8,
      },
      '&.MuiTabs-flexContainer::-webkit-scrollbar-track': {
        background: '#f1f1f1',
      },
      '&.MuiTabs-flexContainer::-webkit-scrollbar-thumb': {
        background: '#888',
        borderRadius: 5,
      },
      '&.MuiTabs-flexContainer::-webkit-scrollbar-thumb:hover': {
        background: '#555',
      },
    },
    '& .MuiTabs-indicator': { display: 'none' },
    '& .MuiButtonBase-root.MuiTab-root.MuiTab-labelIcon': {
      margin: 0,
      minHeight: 44,
      padding: '12px 16px',
      height: 44,
      textTransform: 'capitalize',
      color: colors.neutral700,
      '&.Mui-selected': {
        color: colors.neutral900,
        border: `1px solid ${colors.neutral200}`,
        borderRadius: 8,
        fontWeight: 600,
        '& div': {
          background: colors.neutral700,
          color: colors.white,
          border: 'none',
        },
      },
    },
  },
  exerciseName: {
    '& .collapsableHeader': {
      paddingBottom: '16px',
    },
  },
  exerciseDesc: {
    fontSize: '16px',
    fontFamily: 'Manrope',
    fontWeight: 500,
    lineHeight: '24px',
    color: colors.neutral600,
  },
  exerciseContentBox: {
    padding: '24px',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    borderRadius: '8px',
    border: `1px solid ${colors.neutral100}`,
    backgroundColor: colors.neutral25,
    '& img': {
      maxWidth: '100%',
    },
  },
  tabContainer: {
    padding: '16px 16px 8px 16px',
    borderBottom: `1px solid ${colors.neutral200}`,
  },
  tabContent: {
    padding: 24,
    overflowY: 'auto',
  },
  headlessTabContent: {
    padding: 16,
  },
  drawerTabContent: {
    height: 'calc(100vh - 175px)',
  },
  evaluationTitle: {
    fontSize: 18,
    lineHeight: '24px',
    fontWeight: 600,
  },
  capitalize: {
    textTransform: 'capitalize',
  },
  collapsable: {
    '& .collapsableHeader': {
      justifyContent: 'flex-start',
    },
    '& .collapsableIcon': {
      marginLeft: 0,
      '& path': {
        fill: colors.neutral900,
      },
    },
    padding: '18px 0px',
  },
  questionInDct: {
    marginLeft: '16px',
  },
  questionBorderBottom: {
    borderBottom: `1px solid ${colors.neutral100}`,
  },
  questionName: {
    fontSize: 16,
    lineHeight: '24px',
    fontWeight: 500,
  },
  questionType: {
    fontSize: 16,
    lineHeight: '24px',
    fontWeight: 400,
  },
  question: {
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  content: {
    border: `1px solid ${colors.neutral100}`,
    borderRadius: 8,
    margin: '16px 0px 16px 32px',
  },
  contentHeader: {
    background: colors.neutral25,
    borderBottom: `1px solid ${colors.neutral100}`,
  },
  contentBody: {
    padding: 24,
  },
  tabs: {
    '& .MuiTabs-indicator': { display: 'none' },
    '& .MuiButtonBase-root.MuiTab-root.MuiTab-labelIcon': {
      margin: 0,
      minHeight: 52,
      padding: '12px 16px',
      height: 52,
      textTransform: 'capitalize',
      color: colors.neutral600,
      '&.Mui-selected': {
        color: colors.neutral900,
        fontWeight: 600,
        '& div': {
          background: colors.neutral700,
          color: colors.white,
          border: 'none',
        },
      },
    },
  },
  centeredSection: {
    margin: '80px 280px',
    border: `1px solid ${colors.neutral100}`,
    [theme.breakpoints.down(1440)]: {
      margin: '40px 80px',
    },
  },
  noCommentsIcon: {
    color: colors.neutral400,
    width: 26,
    height: 26,
  },
  commentInput: {
    height: '48px !important',
    padding: 12,
  },
  btn: {
    minWidth: 'auto',
    height: 48,
    textWrap: 'nowrap',
    '&.Mui-disabled': {
      backgroundColor: colors.primary50,
      color: colors.primary300,
    },
  },
  divider: {},
  section: {
    height: '20vh',
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      width: 0,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'transparent',
    },
  },
  mentionUserList: {
    border: `1px solid ${colors.neutral100}`,
    borderRadius: 6,
    boxShadow: '0px 0px 1px 0px #0000000A, 0px 2px 6px 0px #0000000A, 0px 10px 20px 0px #0000000A',
    background: 'white',
    // position: 'fixed',
    width: 261,
    overflowY: 'auto',
    listStyleType: 'none',
    height: 62,
    padding: '8px 16px 8px 16px',
    cursor: 'pointer',
  },
  oneUser: {
    height: 72,
    marginTop: -82,
  },
  twoUser: {
    height: 135,
    marginTop: -145,
  },
  threeUser: {
    height: 200,
    marginTop: -210,
  },
  allUser: {
    height: 280,
    marginTop: -290,
  },
  mentionUserListData: {
    height: 62,
    padding: '8px 16px 8px 16px',
    cursor: 'pointer',
  },
  focused: {
    backgroundColor: colors.primary100,
  },
  member: {
    display: 'flex',
    alignItems: 'center',
    gap: 12,
  },
  info: {
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
  },
  userName: {
    width: 160,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: 14,
    fontWeight: 600,
    lineHeight: '20px',
  },
  link: {
    fontFamily: 'Manrope',
    fontWeight: 600,
    fontSize: 14,
    lineHeight: '19px',
    color: colors.primary600,
    transition: '0.4s',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
      opacity: 0.8,
    },
  },
});
