// types
import { linkType } from '../../atoms/link';

export const iconLinkType = linkType;

export const iconLinkStyle = {
  PRIMARY: 'PRIMARY',
  SECONDARY: 'SECONDARY',
  ERROR: 'ERROR',
  SUCCESS: 'SUCCESS',
  HIGH_CONTRAST: 'HIGH_CONTRAST',
  MEDIUM_CONTRAST: 'MEDIUM_CONTRAST',
  LOW_CONTRAST: 'LOW_CONTRAST',
  NEUTRAL_700: 'NEUTRAL_700',
};

export const iconLinkPosition = {
  LEFT: 'left',
  RIGHT: 'right',
};
