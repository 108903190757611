// colors
import { colors } from '../../../../../packages/colors';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  drawer: {
    width: '100%',
  },
  paperDrawer: {
    width: '100% !important',
    maxWidth: '100% !important',
  },
  drawHead: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '18px 20px 18px 24px',
    borderBottom: `1px solid ${colors.neutral100}`,
  },
  backBtn: {
    width: 40,
    height: 40,
    minWidth: 40,
    border: `1px solid ${colors.neutral300}`,
    backgroundColor: colors.white,
    '& svg': {
      height: 14,
    },
  },
  headTitle: {
    margin: 0,
    fontSize: 22,
    lineHeight: '28px',
  },
  headSubtitle: {
    fontSize: 14,
    lineHeight: '20px',
    color: colors.neutral500,
  },
  username: {
    fontSize: 14,
    lineHeight: '20px',
    color: colors.neutral900,
    margin: 0,
  },
  fullname: {
    fontSize: 14,
    lineHeight: '20px',
    color: colors.neutral600,
  },
  avtWrap: {
    position: 'relative',
  },
  avtStatus: {
    position: 'absolute',
    bottom: -2,
    right: 0,
    width: 12,
    height: 12,
    border: `2px solid ${colors.white}`,
    backgroundColor: colors.neutral300,
    borderRadius: 6,
  },
  avtStatusActive: {
    backgroundColor: colors.success500,
  },
  signoffBtn: {
    backgroundColor: colors.primary500,
    color: colors.white,
    height: 48,
    padding: '20px 22px',
    borderRadius: 8,
    '& svg': {
      marginRight: 9,
    },
    '&:hover': {
      backgroundColor: colors.primary600,
    },
  },
  filters: {
    padding: 16,
    display: 'flex',
    gap: 16,
  },
  btnGroupIcon: {
    color: colors.neutral700,
    backgroundColor: 'transparent',
    padding: '14px 18px',
    transition: '0s',
    fontSize: 14,
    height: 48,
    border: `1px solid ${colors.neutral200}`,
    '&:hover': {
      color: colors.white,
      backgroundColor: colors.neutral900,
      border: `1px solid ${colors.neutral900}`,
    },
  },
  btnGroupIconActive: {
    color: colors.white,
    border: `1px solid ${colors.neutral900}`,
    backgroundColor: colors.neutral900,
  },
  badge: {
    height: 18,
    width: 35,
    fontSize: 12,
    lineHeight: '16px',
    color: colors.secondary500,
    border: `1px solid ${colors.secondary300}`,
    marginLeft: 12,
  },
  badgeActive: {
    border: 'none',
  },
  drawBody: {
    backgroundColor: colors.neutral50,
    flex: 1,
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: 64,
    paddingBottom: 64,
  },
  title: {
    fontSize: 32,
    margin: 0,
    marginBottom: 4,
  },
  subtitle: {
    fontSize: 18,
    color: colors.neutral600,
    lineHeight: '28px',
  },
  checklist: {
    maxWidth: 680,
    width: '100%',
  },
});
