import { makeStyles } from '@mui/styles';

// colors
import { colors } from '../../../colors';

export const useStyles = makeStyles({
  root: {
    width: '100%',
    height: 72,
    padding: '16px 26px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: colors.white,
  },
  leftPanel: {
    display: 'flex',
    alignItems: 'center',
    gap: 44,
  },
  logo: {
    width: 36,
    height: 38,
  },
  navItems: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    gap: 42,
  },
  rightPanel: {
    display: 'flex',
  },
  welcomeText: {
    marginRight: 4,
  },
});
