import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { fontWeight, Heading, headingLevel, Table, Text, textLevel } from '../../../../../packages';
import { GLOBAL_DATE_FORMAT } from '../../../../../constants/CommonConstants';
import { getMemberCardTransactions } from '../../../../../services/member/member.service';
import { Box } from '@mui/material';
import { useStyles } from '../../MemberDetail.styles';

export const tableColumns = [
  { id: 'serviceName', label: 'Service', sortable: false },
  { id: 'paymentCategory', label: 'Payment category', sortable: false },
  { id: 'amountPaid', label: 'Amount paid', sortable: false },
  { id: 'providerName', label: 'Provider', sortable: false },
  { id: 'cardBrand', label: 'Card brand', sortable: false },
  { id: 'cardLast4', label: 'Card last 4 digits', sortable: false },
  { id: 'paidAt', label: 'Date and time', sortable: false },
];

const CardTransactions = ({ memberId }) => {
  const classes = useStyles({});
  const [payments, setPayments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const handleDateFormat = signedDate => {
    return signedDate ? dayjs(signedDate).format(`${GLOBAL_DATE_FORMAT} [at] h:mm a`) : '';
  };
  const toSentenceCase = text => {
    if (!text) return '-';
    const spacedText = text?.replace('_', ' ').toLocaleLowerCase();
    return spacedText.charAt(0).toUpperCase() + spacedText.slice(1);
  };

  useEffect(() => {
    setIsLoading(true);
    getMemberCardTransactions({ memberId }, { appointmentMeta: true, pageNumber: 0, pageSize: 5 })
      .then(res => {
        setPayments(
          res.data?.LedgerHistory?.map(item => {
            return {
              ...item,
              paidAt: handleDateFormat(item.paidAt),
            };
          }),
        );
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, []);

  const renderColumns = tableColumns.map(column => {
    if (column.id === 'paymentCategory') {
      return {
        ...column,
        renderCell: paymentCategory => (
          <Box display="flex" gap={1.5} alignItems="center" justifyContent="flex-start">
            <Box display="flex" flexDirection="column">
              <Text level={textLevel.M}>{toSentenceCase(paymentCategory)}</Text>
            </Box>
          </Box>
        ),
      };
    }
    if (column.id === 'amountPaid') {
      return {
        ...column,
        renderCell: amountPaid => (
          <Box display="flex" gap={1.5} alignItems="center" justifyContent="flex-start">
            <Box display="flex" flexDirection="column">
              <Text level={textLevel.M}>{`$${amountPaid}`}</Text>
            </Box>
          </Box>
        ),
      };
    }
    return {
      ...column,
      renderCell: value => (
        <Box display="flex" gap={1.5} alignItems="center" justifyContent="flex-start">
          <Box display="flex" flexDirection="column">
            <Text level={textLevel.M}>{value ?? '-'}</Text>
          </Box>
        </Box>
      ),
    };
  });

  // prettier-ignore
  return (
    <div className={classes.card}>
      <div className={classes.cardTopSection}>
        <Heading level={headingLevel.S} weight={fontWeight.BOLD}>
          Last 5 card payments
        </Heading>
      </div>
      <div>
      <Table
      gridProps={{
        columns: renderColumns,
        data: payments,
        isLoading,
      }}
    />
      </div>
    </div>
  );
};

export default CardTransactions;
