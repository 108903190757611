/* eslint-disable max-len */
import cx from 'clsx';

// types
// styles
import { useStyles } from './shared/styles';

const BookClosedOutlined = ({ className, color, onClick }) => {
  const classes = useStyles();
  return (
    <svg
      className={cx({
        [classes.root]: true,
        [className || '']: className,
      })}
      width="16"
      height="20"
      viewBox="0 0 16 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M15 0H3C2.20435 0 1.44129 0.316071 0.87868 0.87868C0.316071 1.44129 0 2.20435 0 3V17C0 17.7956 0.316071 18.5587 0.87868 19.1213C1.44129 19.6839 2.20435 20 3 20H15.5C15.6326 20 15.7598 19.9473 15.8536 19.8536C15.9473 19.7598 16 19.6326 16 19.5V1C16 0.734784 15.8946 0.48043 15.7071 0.292893C15.5196 0.105357 15.2652 0 15 0ZM2 3C2 2.73478 2.10536 2.48043 2.29289 2.29289C2.48043 2.10536 2.73478 2 3 2H14V14H3C2.65943 13.9995 2.32127 14.057 2 14.17V3ZM2 17C2 16.7348 2.10536 16.4804 2.29289 16.2929C2.48043 16.1054 2.73478 16 3 16H14V18H3C2.73478 18 2.48043 17.8946 2.29289 17.7071C2.10536 17.5196 2 17.2652 2 17ZM4.5 6H11.5C11.6326 6 11.7598 5.94732 11.8536 5.85355C11.9473 5.75979 12 5.63261 12 5.5V4.5C12 4.36739 11.9473 4.24021 11.8536 4.14645C11.7598 4.05268 11.6326 4 11.5 4H4.5C4.36739 4 4.24021 4.05268 4.14645 4.14645C4.05268 4.24021 4 4.36739 4 4.5V5.5C4 5.63261 4.05268 5.75979 4.14645 5.85355C4.24021 5.94732 4.36739 6 4.5 6ZM4.5 10H7.5C7.63261 10 7.75979 9.94732 7.85355 9.85355C7.94732 9.75979 8 9.63261 8 9.5V8.5C8 8.36739 7.94732 8.24021 7.85355 8.14645C7.75979 8.05268 7.63261 8 7.5 8H4.5C4.36739 8 4.24021 8.05268 4.14645 8.14645C4.05268 8.24021 4 8.36739 4 8.5V9.5C4 9.63261 4.05268 9.75979 4.14645 9.85355C4.24021 9.94732 4.36739 10 4.5 10Z"
        fill={color}
      />
    </svg>
  );
};

export default BookClosedOutlined;
