import { colors } from '../../../../../packages';

export const getChartOpts = (series, donutOpts, legendRight, chartId) => {
  const { legend = {} } = donutOpts || { legend: {} };
  const labels = series?.map(({ name }) => name);
  const seriColors = series?.map(({ color }) => color);
  return {
    chart: {
      height: '100%',
      redrawOnParentResize: true,
      id: chartId,
    },
    title: {
      text: '',
      show: false,
    },
    labels,

    plotOptions: {
      pie: {
        offsetY: -8,
        offsetX: -24,
        donut: {
          size: donutOpts?.size || '60%',
          labels: {
            show: !!donutOpts?.title,
            total: {
              showAlways: true,
              show: true,
              label: donutOpts?.title,
              fontSize: '14px',
              fontFamily: 'Manrope',
              fontWeight: 600,
              color: colors.neutral600,
              formatter: () => String(donutOpts?.total),
            },
            name: {
              show: true,
              offsetY: 20,
              formatter: v => {
                if (v.length > 15) {
                  return `${v.substring(0, 15)}...`;
                }
                return v;
              },
            },
            value: {
              show: true,
              fontSize: '18px',
              label: 12,
              fontFamily: 'Manrope',
              fontWeight: 700,
              color: colors.neutral900,
              offsetY: -20,
            },
          },
        },
      },
    },
    tooltip: {
      style: {
        fontFamily: 'Manrope',
      },
      custom: ({ seriesIndex, w }) => {
        return `
          <div class="chartWrap-tooltip">
            <div class="date">${labels[seriesIndex]}</div>
            <div class="level">${w.config.series[seriesIndex]}</div>
          </div>
        `;
      },
    },
    dataLabels: {
      enabled: false,
    },
    colors: seriColors,
    stroke: {
      width: 1,
    },
    legend: {
      markers: {
        radius: 16,
        height: '12px',
        width: '12px',
        offsetY: 0,
        offsetX: -8,
      },
      position: legendRight ? 'right' : 'left',
      fontSize: '14px',
      fontFamily: 'Manrope',
      fontWeight: 500,
      offsetX: -90,
      width: 750,
      offsetY: -10,
      height: 215,
      itemMargin: {
        horizontal: 0,
        vertical: 8,
      },
      ...legend,
    },
  };
};
