import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Drawer, drawerType } from '../../../packages/ui/organisms/drawer';
import {
  addUpdateDefaultCareNavigator,
  getCareNavigatorAliases,
  getDefaultCareNavigator,
} from '../../../services/profile/profile.service';
import { showSnackbar } from '../../../redux/modules/snackbar';
import { fontWeight, Heading, headingLevel, Input, inputType, Select, selectType } from '../../../packages';
import { Box } from '@mui/material';
import Loader, { LoaderEnums } from '../../../packages/ui/templates/loader';
import { getAuth } from '../../../redux/modules/auth/selectors';
import { CONNECTIONS_TYPES } from '../../../constants/CommonConstants';

import CareTeamMemberItem from './CareTeamMemberItem';

import { useStyles } from './Chats.styles';

const CareTeamMembersDrawer = ({
  title,
  open,
  careTeamMembers,
  onClose,
  onSelectCareTeamMemberChat,
  type,
  isProvider,
  channelUrl = null,
}) => {
  const classes = useStyles({});
  const dispatch = useDispatch();
  const { meta, isAdmin } = useSelector(getAuth);
  const [currentProviderId, setCurrentProviderId] = useState('');
  const [chatClicked, setChatClicked] = useState(false);
  const [careNavigatorAliasAccounts, setCareNavigatorAliasAccounts] = useState([]);
  const [defaultCareNavigator, setDefaultCareNavigator] = useState({});
  const [selectedDefaultCareNavigator, setSelectedDefaultCareNavigator] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const fetchDefaultCareNavigatorData = async () => {
    if (channelUrl) {
      try {
        setIsLoading(true);
        const aliasesResponse = await getCareNavigatorAliases();
        setCareNavigatorAliasAccounts(
          aliasesResponse.data.careNavigatorAliasAccounts?.map(item => ({
            label: item.email,
            value: item.aliasId,
          })),
        );
        getDefaultCareNavigator({ channelUrl })
          .then(res => {
            setDefaultCareNavigator(res.data);
            const foundDefaultCareNavigator = aliasesResponse.data.careNavigatorAliasAccounts?.find(
              item => item.aliasId === res.data?.defaultCareNavigator,
            );
            if (foundDefaultCareNavigator) {
              setSelectedDefaultCareNavigator(foundDefaultCareNavigator.aliasId);
            }
            setIsLoading(false);
          })
          .catch(() => {
            setIsLoading(false);
          });
      } catch (error) {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => void fetchDefaultCareNavigatorData(), []);

  const onUpdateDefaultCareNavigator = careNavigatorId => {
    if (channelUrl && careNavigatorId !== defaultCareNavigator.defaultCareNavigator) {
      addUpdateDefaultCareNavigator({
        channelURL: channelUrl,
        defaultCareNavigator: careNavigatorId,
      })
        .then(() => {
          dispatch(
            showSnackbar({
              snackType: 'success',
              snackMessage: 'Default care navigator updated successfully',
            }),
          );
        })
        .catch(err => {
          dispatch(
            showSnackbar({
              snackType: 'error',
              snackMessage:
                err.data?.errors?.[0]?.endUserMessage || 'Unable to update default care navigator',
            }),
          );
        });
    }
  };
  const onChangeDefaultCareNavigator = e => {
    if (e.target?.value) {
      onUpdateDefaultCareNavigator(e.target?.value);
      setSelectedDefaultCareNavigator(e.target?.value);
    }
  };
  useEffect(() => {
    if (chatClicked) {
      onSelectCareTeamMemberChat(currentProviderId);
      onClose();
    }
  }, [chatClicked]);

  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={title}
      variant={drawerType.FORM}
      footer={<></>}
      className={classes.drawer}
    >
      {type === CONNECTIONS_TYPES.CARE_TEAM && (
        <Box className={classes.content}>
          <Box className={classes.section}>
            <Heading level={headingLevel.S} className={classes.label} weight={fontWeight.BOLD}>
              Default care navigator
            </Heading>
            {isLoading ? (
              <Box padding="26px 16px">
                <Loader type={LoaderEnums.SEARCH} numItems={1} />
              </Box>
            ) : !isAdmin ? (
              <Input
                className={classes.defaultCareNavigatorInput}
                variant={inputType.DEFAULT}
                placeholder="No default care navigator"
                value={
                  selectedDefaultCareNavigator
                    ? careNavigatorAliasAccounts?.find(item => item.value === selectedDefaultCareNavigator)
                        ?.label
                    : 'No default care navigator'
                }
                disabled
              />
            ) : (
              <Select
                value={selectedDefaultCareNavigator}
                name="defaultCareNavigator"
                variant={selectType.SECONDARY}
                emptyText={`${isAdmin ? 'Select' : 'No'} default care navigator`}
                displayEmpty
                disabled={meta.authority !== CONNECTIONS_TYPES.CARE_NAVIGATOR}
                options={careNavigatorAliasAccounts}
                onChange={onChangeDefaultCareNavigator}
              />
            )}
          </Box>
        </Box>
      )}
      {careTeamMembers?.map((careTeamMember, index) => (
        <CareTeamMemberItem
          key={index}
          isProvider={isProvider}
          type={type}
          careTeamMember={careTeamMember}
          setCurrentProviderId={setCurrentProviderId}
          setChatClicked={setChatClicked}
        />
      ))}
    </Drawer>
  );
};

export default CareTeamMembersDrawer;
