// import { makeStyles } from '@mui/styles';

// // colors
// import { colors } from '../../../colors';

// // Type
// interface StyleProps {
//   sideBarIsActive: boolean;
// }

// export const useStyles = makeStyles({
//   root: {
//     height: '100%',
//     width: 280,
//     display: 'flex',
//     flexDirection: 'column',
//     justifyContent: 'space-between',
//     backgroundColor: colors.white,
//     transition: '0.25s',
//     borderLeft: `1px solid ${colors.neutral100}`,
//     borderRight: `1px solid ${colors.neutral100}`,
//   },
//   activeBar: {
//     width: 280,
//   },
//   inactiveBar: {
//     width: 83,
//   },
//   topPanel: {
//     display: 'flex',
//     flexDirection: 'column',
//     gap: 0,
//   },
//   logo: {
//     width: 40,
//     height: 40,
//     cursor: 'pointer',
//   },
//   navItems: {
//     display: 'flex',
//     flexDirection: 'column',
//     gap: 0,
//     padding: '0 12px',
//   },
//   rightSideNavItems: {
//     padding: '20px 16px !important',
//   },
//   subNavItems: {
//     display: 'flex',
//     flexDirection: 'column',
//     gap: 0,
//     padding: 0,
//     paddingLeft: 16,
//   },
//   navNoIconItem: {
//     padding: '14px 16px !important',
//     justifyContent: 'space-between !important',
//   },
//   disableNavItem: {
//     cursor: 'default',
//     '& $navText': {
//       textDecorationLine: 'line-through',
//     },
//   },
//   childBadgeZero: {
//     borderRadius: 100,
//     backgroundColor: `${colors.neutral50} !important`,
//     color: `${colors.neutral700} !important`,
//     fontSize: 12,
//     borderColor: 'transparent',
//     fontWeight: 600,
//   },
//   childBadge: {
//     borderRadius: 100,
//     backgroundColor: colors.primary500,
//     color: colors.white,
//     fontSize: 12,
//     borderColor: 'transparent',
//     fontWeight: 600,
//   },
//   childBadgeLeft: {
//     borderRadius: 100,
//     backgroundColor: colors.redPill,
//     color: colors.white,
//     fontSize: 12,
//     borderColor: 'transparent',
//     fontWeight: 600,
//   },
//   dot: {
//     height: 7,
//     width: 7,
//     backgroundColor: colors.redPill,
//     borderRadius: '50%',
//     display: 'inline-block',
//     marginLeft: 5,
//     marginBottom: 5,
//   },
//   chatConnectedDot: {
//     height: 7,
//     width: 7,
//     backgroundColor: colors.green500,
//     borderRadius: '50%',
//     display: 'inline-block',
//     marginLeft: 5,
//     marginBottom: 5,
//   },
//   chatConnectingDot: {
//     height: 7,
//     width: 7,
//     backgroundColor: colors.warning500,
//     borderRadius: '50%',
//     display: 'inline-block',
//     marginLeft: 5,
//     marginBottom: 5,
//   },
//   chatDisconnectedDot: {
//     height: 7,
//     width: 7,
//     backgroundColor: colors.destructive600,
//     borderRadius: '50%',
//     display: 'inline-block',
//     marginLeft: 5,
//     marginBottom: 5,
//   },
//   badgeActive: {
//     backgroundColor: colors.redPill,
//   },
//   childBadgeAppoint: {
//     borderRadius: 100,
//     backgroundColor: colors.redPill,
//     color: colors.white,
//     fontSize: 12,
//     borderColor: 'transparent',
//     fontWeight: 600,
//     height: 21,
//     width: 29,
//   },
//   badgeActiveAppoint: {
//     backgroundColor: colors.redPill,
//   },
//   navActiveItem: ({ sideBarIsActive }: StyleProps) => ({
//     padding: 16,
//     minHeight: 48,
//     backgroundColor: colors.neutral50,
//     justifyContent: sideBarIsActive ? 'flex-start' : 'center',
//     alignItems: 'center !important',
//     display: 'flex !important',
//     flexDirection: 'row',
//     '& > svg, & > * > svg': {
//       minHeight: 24,
//       minWidth: 24,
//     },
//   }),
//   navItem: ({ sideBarIsActive }: StyleProps) => ({
//     padding: 16,
//     minHeight: 48,
//     justifyContent: sideBarIsActive ? 'flex-start' : 'center',
//     alignItems: 'center',
//     display: 'flex',
//     flexDirection: 'row',
//     '& > svg, & > * > svg': {
//       minHeight: 24,
//       minWidth: 24,
//     },
//   }),
//   linkBadge: {
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'space-between',
//   },
//   childBadge2: {
//     height: 20,
//   },
//   bottomPanel: {
//     display: 'flex',
//     flexDirection: 'column',
//     gap: 16,
//   },
//   welcomeText: {
//     marginRight: 4,
//   },
//   userNameText: {
//     display: 'block',
//     lineHeight: '20px',
//     color: colors.neutral900,
//     textAlign: 'left',
//     overflow: 'hidden',
//     textOverflow: 'ellipsis',
//     whiteSpace: 'nowrap',
//     maxWidth: '160px',
//   },
//   userInfoText: {
//     display: 'block',
//     lineHeight: '20px',
//     color: colors.neutral600,
//     textAlign: 'left',
//     overflow: 'hidden',
//     textOverflow: 'ellipsis',
//     whiteSpace: 'nowrap',
//     maxWidth: '160px',
//   },
//   menuArrowWrapper: {
//     transform: 'rotate(-90deg)',
//     paddingRight: 5,
//     position: 'absolute',
//     right: -2,
//     '& svg path': {
//       fill: colors.neutral400,
//     },
//   },
//   userCard: {
//     display: 'flex',
//     flexDirection: 'row',
//     justifyContent: 'space-between',
//     alignItems: 'center',
//     width: '100%',
//     padding: '20px 19px',
//     position: 'relative',
//   },
//   userProfileCard: {
//     display: 'flex',
//     flexDirection: 'row',
//     justifyContent: 'space-between',
//     alignItems: 'center',
//     width: '100%',
//     padding: '20px 19px',
//     position: 'relative',
//     '& > :nth-child(1)': {
//       flex: 1,
//     },
//   },
//   rightSideUserCard: {
//     padding: '24px !important',
//     maxHeight: 88,
//   },
//   borderTop: {
//     borderTop: `1px solid ${colors.neutral100}`,
//     borderColor: colors.neutral100,
//   },
//   borderBottom: {
//     borderBottom: `1px solid ${colors.neutral100}`,
//   },
//   userWrapper: {
//     display: 'flex  ',
//     flexDirection: 'row',
//     width: '100%',
//     alignItems: 'center',
//     justifyContent: 'flex-start',
//     gap: 12,
//     position: 'relative',
//   },
//   menu: {
//     padding: 0,
//     width: '100%',
//   },
//   navActiveText: {
//     color: `${colors.neutral900} !important`,
//   },
//   navText: {
//     color: colors.neutral700,
//     whiteSpace: 'break-spaces',
//     fontWeight: 700,
//     overflowX: 'auto',
//   },
//   subNavText: {
//     fontWeight: 500,
//   },
//   sideBarToggler: {
//     height: 40,
//     minWidth: 40,
//     maxWidth: 40,
//     borderRadius: 99,
//     padding: '0px !important',
//     border: `1px solid ${colors.neutral300}`,
//     color: colors.neutral700,
//   },
//   togglerIcon: {
//     width: 24,
//     height: 24,
//   },
//   sideBarActiveBtn: {
//     minWidth: '40px !important',
//     width: 40,
//     height: 40,
//     padding: '0px !important',
//     '& > svg , & > * > svg': { padding: 0 },
//   },
//   logoAndTogglerWrapper: ({ sideBarIsActive }: StyleProps) => ({
//     flexDirection: 'row',
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: sideBarIsActive ? 'space-between' : 'center',
//     padding: sideBarIsActive ? '0px 16px 0px 22px' : '0 16px',
//     height: 88,
//   }),
//   bottomNavItemWrapper: {
//     display: 'flex',
//     flexDirection: 'row',
//     alignItems: 'center',
//     justifyContent: 'space-between',
//     position: 'relative',
//   },
//   badge: {
//     color: colors.secondary500,
//     backgroundColor: '#FFF1F8',
//     height: 22,
//     fontSize: 12,
//     borderRadius: 99,
//     paddingLeft: 8,
//     paddingRight: 8,
//     border: 'none',
//   },
//   badgeText: {
//     fontSize: 12,
//     lineHeight: '20px',
//     position: 'absolute',
//     color: colors.secondary500,
//     top: -12,
//     right: -2,
//   },
//   textFieldsWrapper: {
//     display: 'flex',
//     flexDirection: 'column',
//     padding: 24,
//     paddingBottom: 23,
//     gap: 16,
//   },
//   bottomNavItem: {
//     color: colors.neutral400,
//     position: 'relative',
//   },
//   btn: {
//     height: 40,
//     minWidth: '40px !important',
//     width: 40,
//     background: '#F1F8FF',
//     padding: 10,
//     borderRadius: 5,
//   },
//   moreInfo: {
//     marginLeft: -15,
//     flexDirection: 'column-reverse',
//     '& #collapseLabel': {
//       color: '#0365C1',
//     },
//   },
//   moreInfoCollapsed: {
//     '& .collapsableHeader': {
//       paddingTop: 16,
//     },
//   },
// });

import { makeStyles } from '@mui/styles';

// colors
import { colors } from '../../../colors';

export const useStyles = makeStyles({
  root: {
    height: '100%',
    width: 280,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundColor: colors.white,
    transition: '0.25s',
    borderLeft: `1px solid ${colors.neutral100}`,
    borderRight: `1px solid ${colors.neutral100}`,
  },
  activeBar: {
    width: 280,
  },
  inactiveBar: {
    width: 83,
  },
  topPanel: {
    display: 'flex',
    flexDirection: 'column',
    gap: 0,
  },
  logo: {
    width: 40,
    height: 40,
    cursor: 'pointer',
  },
  navItems: {
    display: 'flex',
    flexDirection: 'column',
    gap: 0,
    padding: '0 12px',
  },
  rightSideNavItems: {
    padding: '20px 16px !important',
  },
  subNavItems: {
    display: 'flex',
    flexDirection: 'column',
    gap: 0,
    padding: 0,
    paddingLeft: 16,
  },
  navNoIconItem: {
    padding: '14px 16px !important',
    justifyContent: 'space-between !important',
  },
  disableNavItem: {
    cursor: 'default',
    '& $navText': {
      textDecorationLine: 'line-through',
    },
  },
  childBadgeZero: {
    borderRadius: 100,
    backgroundColor: `${colors.neutral50} !important`,
    color: `${colors.neutral700} !important`,
    fontSize: 12,
    borderColor: 'transparent',
    fontWeight: 600,
  },
  childBadge: {
    borderRadius: 100,
    backgroundColor: colors.primary500,
    color: colors.white,
    fontSize: 12,
    borderColor: 'transparent',
    fontWeight: 600,
  },
  childBadgeLeft: {
    borderRadius: 100,
    backgroundColor: colors.redPill,
    color: colors.white,
    fontSize: 12,
    borderColor: 'transparent',
    fontWeight: 600,
  },
  dot: {
    height: 7,
    width: 7,
    backgroundColor: colors.redPill,
    borderRadius: '50%',
    display: 'inline-block',
    marginLeft: 5,
    marginBottom: 5,
  },
  chatConnectedDot: {
    height: 7,
    width: 7,
    backgroundColor: colors.green500,
    borderRadius: '50%',
    display: 'inline-block',
    marginLeft: 5,
    marginBottom: 5,
  },
  chatConnectingDot: {
    height: 7,
    width: 7,
    backgroundColor: colors.warning500,
    borderRadius: '50%',
    display: 'inline-block',
    marginLeft: 5,
    marginBottom: 5,
  },
  chatDisconnectedDot: {
    height: 7,
    width: 7,
    backgroundColor: colors.destructive600,
    borderRadius: '50%',
    display: 'inline-block',
    marginLeft: 5,
    marginBottom: 5,
  },
  badgeActive: {
    backgroundColor: colors.redPill,
  },
  childBadgeAppoint: {
    borderRadius: 100,
    backgroundColor: colors.redPill,
    color: colors.white,
    fontSize: 12,
    borderColor: 'transparent',
    fontWeight: 600,
    height: 21,
    width: 29,
  },
  badgeActiveAppoint: {
    backgroundColor: colors.redPill,
  },
  navActiveItem: ({ sideBarIsActive }) => ({
    padding: 16,
    minHeight: 48,
    backgroundColor: colors.neutral50,
    justifyContent: sideBarIsActive ? 'flex-start' : 'center',
    alignItems: 'center !important',
    display: 'flex !important',
    flexDirection: 'row',
    '& > svg, & > * > svg': {
      minHeight: 24,
      minWidth: 24,
    },
  }),
  navItem: ({ sideBarIsActive }) => ({
    padding: 16,
    minHeight: 48,
    justifyContent: sideBarIsActive ? 'flex-start' : 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    '& > svg, & > * > svg': {
      minHeight: 24,
      minWidth: 24,
    },
  }),
  linkBadge: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  childBadge2: {
    height: 20,
  },
  bottomPanel: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
  },
  welcomeText: {
    marginRight: 4,
  },
  userNameText: {
    display: 'block',
    lineHeight: '20px',
    color: colors.neutral900,
    textAlign: 'left',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: '160px',
  },
  userInfoText: {
    display: 'block',
    lineHeight: '20px',
    color: colors.neutral600,
    textAlign: 'left',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: '160px',
  },
  menuArrowWrapper: {
    transform: 'rotate(-90deg)',
    paddingRight: 5,
    position: 'absolute',
    right: -2,
    '& svg path': {
      fill: colors.neutral400,
    },
  },
  userCard: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    padding: '20px 19px',
    position: 'relative',
  },
  userProfileCard: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    padding: '20px 19px',
    position: 'relative',
    '& > :nth-child(1)': {
      flex: 1,
    },
  },
  rightSideUserCard: {
    padding: '24px !important',
    maxHeight: 88,
  },
  borderTop: {
    borderTop: `1px solid ${colors.neutral100}`,
    borderColor: colors.neutral100,
  },
  borderBottom: {
    borderBottom: `1px solid ${colors.neutral100}`,
  },
  userWrapper: {
    display: 'flex  ',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: 12,
    position: 'relative',
  },
  menu: {
    padding: 0,
    width: '100%',
  },
  navActiveText: {
    color: `${colors.neutral900} !important`,
  },
  navText: {
    color: colors.neutral700,
    whiteSpace: 'break-spaces',
    fontWeight: 700,
    overflowX: 'auto',
  },
  subNavText: {
    fontWeight: 500,
  },
  sideBarToggler: {
    height: 40,
    minWidth: 40,
    maxWidth: 40,
    borderRadius: 99,
    padding: '0px !important',
    border: `1px solid ${colors.neutral300}`,
    color: colors.neutral700,
  },
  togglerIcon: {
    width: 24,
    height: 24,
  },
  sideBarActiveBtn: {
    minWidth: '40px !important',
    width: 40,
    height: 40,
    padding: '0px !important',
    '& > svg , & > * > svg': { padding: 0 },
  },
  logoAndTogglerWrapper: ({ sideBarIsActive }) => ({
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
    justifyContent: sideBarIsActive ? 'space-between' : 'center',
    padding: sideBarIsActive ? '0px 16px 0px 22px' : '0 16px',
    height: 88,
  }),
  bottomNavItemWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'relative',
  },
  badge: {
    color: colors.secondary500,
    backgroundColor: '#FFF1F8',
    height: 22,
    fontSize: 12,
    borderRadius: 99,
    paddingLeft: 8,
    paddingRight: 8,
    border: 'none',
  },
  badgeText: {
    fontSize: 12,
    lineHeight: '20px',
    position: 'absolute',
    color: colors.secondary500,
    top: -12,
    right: -2,
  },
  textFieldsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    padding: 24,
    paddingBottom: 23,
    gap: 16,
  },
  bottomNavItem: {
    color: colors.neutral400,
    position: 'relative',
  },
  btn: {
    height: 40,
    minWidth: '40px !important',
    width: 40,
    background: '#F1F8FF',
    padding: 10,
    borderRadius: 5,
  },
  moreInfo: {
    marginLeft: -15,
    flexDirection: 'column-reverse',
    '& #collapseLabel': {
      color: '#0365C2',
    },
    '& svg': {
      fill: '#0365C2',
    },
  },
  moreInfoIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 14,
    width: 14,
    marginLeft: 12,
  },
  settingsTabItem: {
    display: 'flex',
    padding: '16px',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  sideBarLinkWrapper: {
    width: '100%',
  },
});
