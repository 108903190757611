import { makeStyles } from '@mui/styles';
import {
  Badge,
  badgeSize,
  badgeStyle,
  badgeType,
  colors,
  Heading,
  iconBtnPosition,
  IconButton,
} from '../../../../../packages';
import { Box } from '@mui/material';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '20px 24px',
  },
  title: {
    color: colors.neutral900,
    fontSize: 18,
    fontWeight: 600,
    margin: 0,
  },
  btn: {
    '& svg': {
      marginLeft: 12,
    },
  },
});

const DashboardHeading = ({ title, onClick, count }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
        <Heading className={classes.title}>{title}</Heading>
        {(count === 0 || count) && (
          <Badge size={badgeSize.SMALL} style={badgeStyle.UNRELATED} variant={badgeType.OUTLINED}>
            {count} total
          </Badge>
        )}
      </Box>
      <IconButton
        icon="arrow-right"
        iconPosition={iconBtnPosition.RIGHT}
        className={classes.btn}
        onClick={onClick}
      >
        View all
      </IconButton>
    </div>
  );
};

export { DashboardHeading };
