import { createSelector } from 'reselect';

export const getProfile = state => state.profile;

export const selectGroupState = createSelector(getProfile, profileState => profileState.group);

export const selectGroups = createSelector(selectGroupState, groupState => groupState.groups);

export const selectDomainTypes = createSelector(getProfile, profileState => profileState.domainTypes);

export const selectConnections = createSelector(getProfile, profileState => profileState.connections);

export const selectMemberInsurance = createSelector(getProfile, profileState => profileState.memberInsurance);

export const selectCalendarsState = createSelector(getProfile, profileState => profileState.syncedCalendars);
export const selectGoogleCalendarsEventState = createSelector(
  getProfile,
  profileState => profileState.googleCalendarEvents,
);

export const selectReferralPartners = createSelector(
  getProfile,
  profileState => profileState.referralPartners,
);
