import { makeStyles } from '@mui/styles';

// colors
import { colors } from '../../../../../packages';

export const useStyles = makeStyles({
  card: {
    position: 'relative',
    display: 'flex',
    backgroundColor: colors.white,
    borderRadius: 8,
    padding: 32,
    width: '100%',
    boxShadow:
      '0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
  },
  legend: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0 80px',
  },
  legendTitle: {
    fontSize: 18,
    fontWeight: 600,
    color: colors.neutral900,
  },
  legendItem: {
    display: 'flex',
    alignItems: 'center',
    color: colors.neutral900,
  },
  legendMarker: {
    display: 'flex',
    minWidth: 12,
    minHeight: 12,
    marginRight: 8,
    borderRadius: 100,
  },
  legendBadge: {
    display: 'flex',
    marginLeft: 8,
    padding: '1px 8px 0',
    fontFamily: 'Manrope',
    fontSize: 12,
    fontWeight: '600',
    color: colors.neutral700,
    borderRadius: 20,
    border: `1px solid ${colors.neutral200}`,
  },
  stats: {
    position: 'absolute',
    top: 104,
    left: 87,
    width: 130,
    height: 90,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  statsTotal: {
    display: 'block',
    fontSize: 28,
    lineHeight: '36px',
    marginBottom: 8,
  },
  statsLabel: {
    fontSize: 14,
    lineHeight: '20px',
    color: colors.neutral600,
    display: 'block',
    textAlign: 'center',
  },
});
