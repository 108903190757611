/* eslint-disable max-len */
import cx from 'clsx';

// types
// styles
import { useStyles } from './shared/styles';

const StoryIconLogo = ({ className, onClick }) => {
  const classes = useStyles();
  return (
    <svg
      className={cx({
        [classes.root]: true,
        [className || '']: className,
      })}
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <g clipPath="url(#clip0_1116_49396)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.70728 23.9162L4.48419 25.4986L2.70728 28.4063C2.70728 28.4063 5.2377 30.9382 7.35319 32.3937C6.90293 33.0441 6.59348 33.6503 6.59348 33.6503C6.59348 33.6503 12.3078 39.2293 19.9722 39.2293C27.6368 39.2293 33.6502 33.6503 33.6502 33.6503L32.9601 32.3937C32.9601 32.3937 36.5644 29.7534 37.601 28.4063C36.8339 27.224 35.986 25.4986 35.986 25.4986L37.9999 23.9162C37.9999 23.9162 34.395 18.6992 34.4619 16.3218C34.5287 13.9444 34.4619 3.87526 34.4619 3.87526C34.4619 3.87526 34.0877 2.1883 32.9601 1.42669C31.8324 0.665077 30.1969 1.42669 30.1969 1.42669L23.4411 7.33848C23.4411 7.33848 21.7475 6.93943 20.3711 6.93943C18.9948 6.93943 17.8894 7.33848 17.8894 7.33848L10.7504 1.42669C10.7504 1.42669 9.57202 0.270649 8.01698 1.02793C6.46193 1.78521 6.59348 3.05549 6.59348 3.05549C6.59348 3.05549 6.46907 9.95057 6.14815 16.3218C5.3915 20.3834 2.70728 23.9162 2.70728 23.9162Z"
          fill="white"
          stroke="#036AC9"
          strokeWidth="1.56411"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.6276 25.1443C16.5017 24.5776 16.933 24.04 17.5135 24.04H22.4312C23.0117 24.04 23.4429 24.5776 23.317 25.1443C23.0201 26.4809 21.9059 27.4629 20.5707 27.6119V28.4297C20.5707 29.4215 21.3743 30.2255 22.3657 30.2255C23.357 30.2255 24.1606 29.4215 24.1606 28.4297V27.8309C24.1606 27.5004 24.4285 27.2325 24.7589 27.2325C25.0894 27.2325 25.3573 27.5004 25.3573 27.8309V28.4297C25.3573 30.0827 24.0179 31.4227 22.3657 31.4227C21.387 31.4227 20.5181 30.9526 19.9723 30.2258C19.4265 30.9526 18.5577 31.4227 17.579 31.4227C15.9268 31.4227 14.5874 30.0827 14.5874 28.4297V27.8309C14.5874 27.5004 14.8553 27.2325 15.1857 27.2325C15.5162 27.2325 15.7841 27.5004 15.7841 27.8309V28.4297C15.7841 29.4215 16.5877 30.2255 17.579 30.2255C18.5704 30.2255 19.374 29.4215 19.374 28.4297V27.6119C18.0388 27.4629 16.9245 26.4809 16.6276 25.1443Z"
          fill="#0374DD"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.4211 25.4378C18.3848 25.3153 18.4766 25.1924 18.6043 25.1924H21.3008C21.4286 25.1924 21.5204 25.3153 21.4841 25.4378C21.3158 26.0059 20.7939 26.3956 20.2014 26.3956H19.7038C19.1113 26.3956 18.5894 26.0059 18.4211 25.4378Z"
          fill="#7CBFFD"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.5901 22.0447C14.6916 22.0447 15.5845 21.1514 15.5845 20.0494C15.5845 18.9474 14.6916 18.0541 13.5901 18.0541C12.4886 18.0541 11.5957 18.9474 11.5957 20.0494C11.5957 21.1514 12.4886 22.0447 13.5901 22.0447ZM12.9918 19.6503C13.3222 19.6503 13.5901 19.3823 13.5901 19.0517C13.5901 18.7211 13.3222 18.4531 12.9918 18.4531C12.6613 18.4531 12.3935 18.7211 12.3935 19.0517C12.3935 19.3823 12.6613 19.6503 12.9918 19.6503Z"
          fill="#036AC9"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.3543 22.0447C27.4558 22.0447 28.3487 21.1514 28.3487 20.0494C28.3487 18.9474 27.4558 18.0541 26.3543 18.0541C25.2528 18.0541 24.3599 18.9474 24.3599 20.0494C24.3599 21.1514 25.2528 22.0447 26.3543 22.0447ZM25.756 19.6503C26.0864 19.6503 26.3543 19.3823 26.3543 19.0517C26.3543 18.7211 26.0864 18.4531 25.756 18.4531C25.4255 18.4531 25.1576 18.7211 25.1576 19.0517C25.1576 19.3823 25.4255 19.6503 25.756 19.6503Z"
          fill="#036AC9"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M30.6309 11.4801C30.8325 11.7328 31.2726 11.5907 31.2726 11.2674V6.16436C31.2726 5.60513 30.6161 5.3041 30.1923 5.66902L27.2691 8.18631C27.082 8.34741 27.1304 8.65827 27.3511 8.76904C27.7667 8.97775 28.3978 9.335 29.0642 9.85978C29.6564 10.3261 30.2209 10.9662 30.6309 11.4801Z"
          fill="#7CBFFD"
          stroke="#036AC9"
          strokeWidth="1.17308"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.1669 11.4801C9.96526 11.7328 9.52515 11.5907 9.52515 11.2674V6.16436C9.52515 5.60513 10.1817 5.3041 10.6054 5.66902L13.5286 8.18631C13.7157 8.34741 13.6673 8.65827 13.4466 8.76904C13.0311 8.97775 12.3999 9.335 11.7335 9.85978C11.1413 10.3261 10.5769 10.9662 10.1669 11.4801Z"
          fill="#7CBFFD"
          stroke="#036AC9"
          strokeWidth="1.17308"
        />
      </g>
      <defs>
        <clipPath id="clip0_1116_49396">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default StoryIconLogo;
