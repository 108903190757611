import { colors } from '../../../../../packages';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  drawer: {
    '& .MuiPaper-root': {
      maxWidth: '720px !important',
    },
    '& .drawerBody': {
      padding: 0,
    },
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    paddingTop: 88,
    gap: 40,
  },
  header: {
    position: 'absolute',
    top: -48,
    left: -64,
    height: 88,
    width: '100vw',
    borderBottom: `1px solid ${colors.neutral100}`,
    paddingLeft: 32,
    paddingRight: 32,
    display: 'flex',
    alignItems: 'center',
  },
  heading: {
    fontFamily: 'Source Serif Pro',
    margin: 0,
  },
  headerTitle: {
    fontSize: 24,
    lineHeight: '32px',
    fontWeight: 700,
  },
  subtitle: {
    fontWeight: 400,
    fontSize: 18,
    lineHeight: '28px',
    margin: 0,
    color: colors.neutral600,
    marginBottom: 32,
  },
  headerSubtitle: {
    fontSize: 14,
    lineHeight: '24px',
    fontWeight: 500,
    margin: 0,
    color: colors.neutral600,
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 80,
    padding: 16,
    borderTop: `1px solid ${colors.neutral100}`,
  },
  backBtn: {
    width: 40,
    height: 40,
    minWidth: 40,
  },
  content: {
    flex: 1,
  },
  infoWrap: {
    backgroundColor: colors.neutral25,
    borderBottom: `1px solid ${colors.neutral100}`,
    padding: 32,
  },
  chartWrap: {
    borderBottom: `1px solid ${colors.neutral100}`,
  },
  chart: {
    padding: 0,
    borderRadius: 0,
    border: 'none',
    boxShadow: 'none',
  },
  assignmentTable: {
    padding: 32,
  },
  tableBordered: {
    border: `1px solid ${colors.neutral100}`,
    borderBottom: 'none',
    borderRadius: 8,
    overflow: 'hidden',
    marginTop: 32,
    '& table': {},
  },
  recentTitle: {
    fontSize: 18,
    lineHeight: '24px',
    margin: 0,
  },
  viewAllBtn: {
    fontSize: 14,
    lineHeight: '20px',
    fontWeight: 700,
  },
  headerDetail: {
    display: 'flex',
    alignItems: 'center',
    gap: 12,
    padding: 16,
  },
  backBtnSquare: {
    width: 40,
    height: 40,
    minWidth: 40,
    borderRadius: 4,
    border: `1px solid ${colors.primary100}`,
    '& svg': {
      width: 14,
      height: 14,
    },
  },
  breakdownChart: {
    padding: 24,
  },
  searchWrap: {
    padding: 24,
    borderTop: `1px solid ${colors.neutral100}`,
    borderBottom: `1px solid ${colors.neutral100}`,
  },
  searchInput: {
    height: 48,
    width: '100%',
    backgroundColor: colors.white,
  },
  donut: {
    padding: 24,
    flex: 1,
    width: '100%',
  },
  tableWrap: {
    '&:first-child': {
      padding: '32px 0 32px 32px',
    },
    '&:last-child': {
      padding: '32px 32px 32px 0',
    },
  },
  fullTable: {
    '&:last-child': {
      padding: '0 32px 32px 32px',
    },
  },
  halfTable: {
    padding: '32px !important',
  },
});
