import { badgeStyle } from '../../../../../packages';

export const assignmentColumns = [
  { id: 'patientName', label: 'Assigned to', sortable: false },
  // { id: 'assignBy', label: 'Assigned by', sortable: false },
  { id: 'status', label: 'Status', sortable: false },
  { id: 'date', label: 'Updated', sortable: false },
  // { id: 'completions', label: 'Completions', sortable: false },
  // { id: 'actions', label: '', sortable: false },
];

export const conversationStatus = {
  QUEUED: 'Not Started',
  RUNNING: 'In Progress',
  PAUSED: 'In Progress',
  CREATED: 'In Progress',
  IN_PROGRESS: 'In Progress',
  COMPLETED: 'Completed',
  NOT_STARTED: 'Not Started',
};

export const filterOptionsMock = [
  { label: conversationStatus.IN_PROGRESS, value: conversationStatus.IN_PROGRESS },
  { label: conversationStatus.QUEUED, value: conversationStatus.QUEUED },
  { label: conversationStatus.COMPLETED, value: conversationStatus.COMPLETED },
];

export const assignmentListMock = [
  {
    date: '2022-08-03T10:46:27.879Z',
    patientId: '62ea5154c5f2250001da162a',
    patientName: 'Asad102',
    status: 'COMPLETED',
  },
  {
    date: '2022-08-03T10:46:27.879Z',
    patientId: '62ea5154c5f2250001da162a',
    patientName: 'Asad103',
    status: 'NOT_STARTED',
  },
  {
    date: '2022-08-03T10:46:27.879Z',
    patientId: '62ea5154c5f2250001da162a',
    patientName: 'Asad105',
    status: 'IN_PROGRESS',
  },
];

export const statusStyle = {
  IN_PROGRESS: badgeStyle.MEDIUM,
  COMPLETED: badgeStyle.SUCCESS,
  NOT_STARTED: badgeStyle.UNRELATED,
  QUEUED: badgeStyle.LOW,
};
