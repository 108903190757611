import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { Box, Typography } from '@mui/material';
import cx from 'clsx';

import { Drawer, drawerType } from '../../../../../packages/ui/organisms/drawer';
import {
  btnType,
  Button,
  fontWeight,
  Heading,
  headingLevel,
  Input,
  inputSize,
  inputType,
  Select,
  selectType,
  Textarea,
  TextError,
} from '../../../../../packages';

import { getDCTDetails } from '../../../../../services/conversation/conversation.service';
import { getConversation } from '../../../../../redux/modules/conversation/selectors';
import { conversationActionCreators } from '../../../../../redux/modules/conversation';

import { DctScoringSchema } from './DctScoring.schema';

// styles
import { useStyles } from './DctScoring.styles';

// constants
import { defaultScoringValues } from './DctScoring.constants';

const DctScoring = ({ dctId, isOpen, onClose, onSubmit }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [scoring, setScoring] = useState(defaultScoringValues);
  const [scoringProfileElement, setScoringCurrentProfileElement] = useState();
  const [outcomeProfileElement, setOutcomeCurrentProfileElement] = useState();

  const {
    profileElements: { profileElementList },
  } = useSelector(getConversation);

  const {
    errors,
    values,
    touched,
    validateForm,
    handleChange,
    handleSubmit,
    setFieldError,
    setFieldValue,
    ...rest
  } = useFormik({
    initialValues: scoring || defaultScoringValues,
    validationSchema: DctScoringSchema,
    enableReinitialize: true,
    onSubmit: async payload => {
      const { minScore, maxScore, veryNegativeRange, negativeRange, neutralRange, positiveRange } = payload;
      await validateForm(payload);

      if (veryNegativeRange.minScore < minScore) {
        setFieldError('veryNegativeRange.minScore', `This field must be greater than ${minScore}`);
      }
      if (veryNegativeRange.maxScore > maxScore) {
        setFieldError('veryNegativeRange.maxScore', `This field must be less than ${maxScore}`);
      }
      if (negativeRange.minScore < minScore) {
        setFieldError('negativeRange.minScore', `This field must be greater than ${minScore}`);
      }
      if (negativeRange.maxScore > maxScore) {
        setFieldError('negativeRange.maxScore', `This field must be less than ${maxScore}`);
      }
      if (neutralRange.minScore < minScore) {
        setFieldError('neutralRange.minScore', `This field must be greater than ${minScore}`);
      }
      if (neutralRange.maxScore > maxScore) {
        setFieldError('neutralRange.maxScore', `This field must be less than ${maxScore}`);
      }
      if (positiveRange.minScore < minScore) {
        setFieldError('positiveRange.minScore', `This field must be greater than ${minScore}`);
      }
      if (positiveRange.maxScore > maxScore) {
        setFieldError('positiveRange.maxScore', `This field must be less than ${maxScore}`);
      }

      if (Object.keys(errors).length === 0 && errors.constructor === Object) {
        onSubmit(payload);
      }
    },
  });

  const scoringProfileElementOptions = useMemo(
    () => profileElementList?.filter(element => element.profileElementInfo.type === 'SCORE_BASED'),
    [profileElementList],
  );

  const outcomeProfileElementOptions = useMemo(
    () => profileElementList?.filter(element => element.profileElementInfo.type === 'USER_DEFINED_VALUES'),
    [profileElementList],
  );

  const setTouched = name => async () => {
    await rest.setTouched({ ...touched, [name]: true });
  };

  const fetchDctDetails = async () => {
    try {
      const { data } = await getDCTDetails(dctId);

      setScoring(data.scoring);
      setScoringCurrentProfileElement(null);
      setOutcomeCurrentProfileElement(null);

      profileElementList?.forEach(element => {
        if (element.profileElementInfo.key === data.scoring?.scoreElementKey) {
          setScoringCurrentProfileElement(element);
        }
        if (element.profileElementInfo.key === data.scoring?.outcomeElementKey) {
          setOutcomeCurrentProfileElement(element);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (isOpen) {
      dispatch(
        conversationActionCreators.fetchProfileElements({
          searchQuery: '',
          type: '',
          method: '',
          pageNumber: 0,
          pageSize: 10000,
          orderBy: '',
          sortBy: [],
        }),
      );
      void fetchDctDetails();
    }
  }, [isOpen]);

  const renderLabel = label => (
    <Heading level={headingLevel.S} className={classes.label} weight={fontWeight.BOLD}>
      {label}
    </Heading>
  );

  const renderRange = (fieldName, label) => (
    <Box display="flex" gap={3}>
      <Box display="flex" alignItems="center" width={170}>
        <Box className={cx(classes.section, classes.dot)} bgcolor={values[fieldName]?.colorCode} />
        <Typography className={classes.label}>{label}</Typography>
      </Box>
      <Box className={classes.section} flex={1}>
        {renderLabel('Min Score')}
        <Input
          fullWidth
          name={`${fieldName}.minScore`}
          type="number"
          value={values[fieldName]?.minScore}
          placeholder=""
          size={inputSize.M}
          onChange={handleChange}
          onBlur={setTouched(`${fieldName}.minScore`)}
        />
        <TextError errorMsg={touched[fieldName]?.minScore ? errors[fieldName]?.minScore : null} />
      </Box>
      <Box className={classes.section} flex={1}>
        {renderLabel('Max Score')}
        <Input
          fullWidth
          name={`${fieldName}.maxScore`}
          type="number"
          value={values[fieldName]?.maxScore}
          placeholder=""
          size={inputSize.M}
          onChange={handleChange}
          onBlur={setTouched(`${fieldName}.minScore`)}
        />
        <TextError errorMsg={touched[fieldName]?.maxScore ? errors[fieldName]?.maxScore : null} />
      </Box>
    </Box>
  );

  return (
    <Drawer
      open={isOpen}
      onClose={onClose}
      title="Update DCT Scoring Details"
      variant={drawerType.FORM}
      className={classes.drawer}
      footer={
        <Box className={classes.footer}>
          <Button variant={btnType.TEXT} onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={handleSubmit}>Save</Button>
        </Box>
      }
    >
      <form className={classes.form} onSubmit={handleSubmit}>
        <Box className={classes.formContent}>
          <Box display="flex" gap={3}>
            <Box className={classes.section} flex={1}>
              {renderLabel('Min Score')}
              <Input
                fullWidth
                name="minScore"
                type="number"
                value={values.minScore}
                placeholder=""
                size={inputSize.M}
                onChange={handleChange}
                onBlur={setTouched('minScore')}
              />
              <TextError errorMsg={touched.minScore ? errors.minScore : null} />
            </Box>
            <Box className={classes.section} flex={1}>
              {renderLabel('Max Score')}
              <Input
                fullWidth
                name="maxScore"
                type="number"
                value={values.maxScore}
                placeholder=""
                size={inputSize.M}
                onChange={handleChange}
                onBlur={setTouched('maxScore')}
              />
              <TextError errorMsg={touched.maxScore ? errors.maxScore : null} />
            </Box>
          </Box>
          <Box className={classes.section}>
            {renderLabel('Direction of Positive Change')}
            <Select
              name="positivityDirection"
              variant={selectType.SECONDARY}
              value={values.positivityDirection}
              options={[
                { label: 'Score Increase', value: 'INCREASE' },
                { label: 'Score Decrease', value: 'DECREASE' },
              ]}
              displayEmpty
              emptyText="Select direction"
              onChange={handleChange}
              onBlur={setTouched('positivityDirection')}
            />
            <TextError errorMsg={touched.positivityDirection ? errors.positivityDirection : null} />
          </Box>
          <Box className={classes.section}>
            {renderLabel('Description')}
            <Textarea
              value={values.description}
              name="description"
              placeholder="Enter description"
              minRows={2}
              maxRows={3}
              onChange={handleChange}
              onBlur={setTouched('description')}
            />
            <TextError errorMsg={touched.description ? errors.description : null} />
          </Box>
          <Box className={classes.section}>
            {renderLabel('Scoring Method')}
            <Select
              name="scoreRule"
              variant={selectType.SECONDARY}
              value={values.scoreRule}
              options={[{ label: 'Sum', value: 'AGGREGATE' }]}
              onChange={handleChange}
              onBlur={setTouched('scoreRule')}
              // displayEmpty
              // emptyText="Select direction"
            />
            <TextError errorMsg={touched.scoreRule ? errors.scoreRule : null} />
          </Box>
          <Box className={classes.section}>
            {renderLabel('Scoring Profile Element')}
            <Input
              fullWidth
              name="scoreElementKey"
              placeholder=""
              size={inputSize.M}
              variant={inputType.AUTOCOMPLETE}
              value={scoringProfileElement || {}}
              options={scoringProfileElementOptions}
              getOptionLabel={option => option?.profileElementInfo?.key || ''}
              onBlur={setTouched('scoreElementKey')}
              onChange={(_, newValue) => {
                void setFieldValue('scoreElementKey', newValue.profileElementInfo.key);
                setScoringCurrentProfileElement(newValue);
              }}
            />
            <TextError errorMsg={touched.scoreElementKey ? errors.scoreElementKey : null} />
          </Box>
          <Box className={classes.section}>
            {renderLabel('Outcome Range Profile Element')}
            <Input
              fullWidth
              name="outcomeElementKey"
              placeholder=""
              size={inputSize.M}
              variant={inputType.AUTOCOMPLETE}
              value={outcomeProfileElement || {}}
              options={outcomeProfileElementOptions}
              getOptionLabel={option => option?.profileElementInfo?.key || ''}
              onBlur={setTouched('outcomeElementKey')}
              onChange={(_, newValue) => {
                void setFieldValue('outcomeElementKey', newValue.profileElementInfo.key);
                setOutcomeCurrentProfileElement(newValue);
              }}
            />
            <TextError errorMsg={touched.outcomeElementKey ? errors.outcomeElementKey : null} />
          </Box>
          {renderRange('veryNegativeRange', 'Very Negative Range')}
          {renderRange('negativeRange', 'Negative Range')}
          {renderRange('neutralRange', 'Neutral Range')}
          {renderRange('positiveRange', 'Positive Range')}
        </Box>
      </form>
    </Drawer>
  );
};

export { DctScoring };
