import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Stack } from '@mui/material';
import {
  Badge,
  badgeStyle,
  badgeType,
  fontWeight,
  Heading,
  headingLevel,
  iconBtnType,
  IconButton,
  Icons,
  Menu,
} from '../../../packages';
import { Filter, Table } from '../../../packages/ui/organisms/table';
import ToastMessage from '../../../packages/ui/templates/toast-message';
import { BaseLayout } from '../../../layouts/base';
import history from '../../../utils/history';
import { sentenceCase } from 'sentence-case';
import { getConversation } from '../../../redux/modules/conversation/selectors';
import { conversationActionCreators } from '../../../redux/modules/conversation';
import { PROFILE_ELEMENT_DEF_TYPE } from '../../../constants/CommonConstants';
import AddProfileElement from './components/add-profile-element';
import {
  elementsFilterOptions,
  multiSelectFilterOptionsMock,
  profileElementsColumns,
} from './ProfileElementList.constants';
import { useStyles } from './ProfileElementList.styles';

const ProfileElementList = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const {
    profileElements: { errorMsg, isLoading, totalRecords, profileElementList },
  } = useSelector(getConversation);
  // const [selectedFilter, setSelectedFilter] = useState('All');
  const [showAddProfile, setShowAddProfile] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState();
  const [profileElements, setProfileElements] = useState([]);
  const [error, setError] = useState(errorMsg);
  const [tableParams, setTableParams] = useState({
    search: { searchKey: '' },
    pagination: { currentPage: 1, rowsPerPage: 10 },
    sorter: { direction: 'desc', column: '' },
  });

  const viewProfileElement = item => {
    history.push(`/admin/data-management-elements/${item.profileElementInfo.id}`, {
      profileElement: item,
    });
  };

  const addProfileElement = () => {
    setSelectedProfile(null);
    setShowAddProfile(true);
  };

  const editProfileElement = item => () => {
    setSelectedProfile(item);
    setShowAddProfile(true);
  };

  const renderColumns = profileElementsColumns.map(column => {
    if (column.id === 'values') {
      return {
        ...column,
        renderCell: values => values?.join(', '),
      };
    }
    if (column.id === 'actions') {
      return {
        ...column,
        renderCell: item => (
          <Menu
            icon="more"
            className={classes.menu}
            itemsWrapperClassName={classes.menuItemsWrapper}
            items={[
              {
                label: 'View Profile Element',
                icon: <Icons glyph="eye-outlined" className={classes.icon} />,
                onClick: () => viewProfileElement(item),
              },
              {
                label: 'Edit Profile Element',
                icon: <Icons glyph="edit-underline" className={classes.icon} />,
                onClick: editProfileElement(item),
              },
            ]}
          />
        ),
      };
    }
    return column;
  });

  const getStorageValue = valueArray => {
    switch (valueArray[0]) {
      case 'Most Recent Response':
        return 'MOST_RECENT_RESPONSE';
      case 'All Responses and No Duplicates':
        return 'ALL_RESPONSES_AND_NO_DUPLICATES';
      case 'All Responses with Date/Time stamps':
        return 'ALL_RESPONSES_WITH_DATE_TIME_STAMPS';
      default:
        return '';
    }
  };

  const getProfileElementsListMultiSelectRequestBody = () => {
    const multiselectFilters = tableParams.search?.multiSelectFilter;
    const storageMethod =
      multiselectFilters?.['Storage Method']?.length > 0
        ? getStorageValue(multiselectFilters?.['Storage Method'])
        : '';

    const type = [];
    if (multiselectFilters?.Type) {
      multiselectFilters.Type.forEach(filter => {
        switch (filter) {
          case 'Score Based':
            type.push('SCORE_BASED');
            break;
          case 'Text Input':
            type.push('TEXT_INPUT');
            break;
          case 'Rating Scale':
            type.push('RATING_SCALE');
            break;
          case 'Date':
            type.push('DATE');
            break;
          case 'Date Time':
            type.push('DATE_TIME');
            break;
          case 'Yes/No':
            type.push('YES_NO');
            break;
          case 'Numeric':
            type.push('NUMERIC');
            break;
          case 'User Defined Values':
            type.push('USER_DEFINED_VALUES');
            break;
          default:
            break;
        }
      });
    }

    return {
      type,
      method: storageMethod,
      searchQuery: tableParams.search.searchKey,
      pageNumber: tableParams.pagination.currentPage - 1,
      pageSize: tableParams.pagination.rowsPerPage,
      orderBy: tableParams.sorter.direction,
      sortBy: tableParams.sorter.column,
      requireStats: true,
    };
  };

  const fetchProfileElements = () => {
    dispatch(conversationActionCreators.fetchProfileElements(getProfileElementsListMultiSelectRequestBody()));
  };

  const onSubmitProfileElement = payload => {
    if (selectedProfile) {
      dispatch(
        conversationActionCreators.updateProfileElement({
          payload,
          profileElementId: selectedProfile.profileElementInfo.id,
          callback: () => {
            setShowAddProfile(false);
            fetchProfileElements();
          },
        }),
      );
    } else {
      dispatch(
        conversationActionCreators.addProfileElement({
          payload,
          callback: () => {
            setShowAddProfile(false);
            fetchProfileElements();
          },
        }),
      );
    }
  };

  useEffect(() => {
    fetchProfileElements();
  }, [tableParams]);

  useEffect(() => {
    setError(errorMsg);
  }, [errorMsg]);

  useEffect(() => {
    const formatProfileElements = (profileElementList || []).map(element => ({
      ...element,
      name: element?.profileElementInfo?.key,
      type: PROFILE_ELEMENT_DEF_TYPE[element.profileElementInfo.type],
      values: element?.profileElementInfo?.values,
      method: sentenceCase(element?.profileElementInfo?.method || ''),
      appearIn: `${element?.usage?.dctIds?.length || '0'} DCT / ${
        element?.usage?.conversationIds?.length || '0'
      } Convo`,
      actions: { ...element },
    }));

    setProfileElements(formatProfileElements);
  }, [profileElementList]);

  return (
    <>
      <ToastMessage
        show={!!error}
        snackType="error"
        snackMessage={error}
        vertical="top"
        horizontal="center"
        clearSnackbarAction={() => setError('')}
      />
      <AddProfileElement
        isOpen={showAddProfile}
        profileElement={selectedProfile}
        onClose={() => setShowAddProfile(false)}
        onSubmit={onSubmitProfileElement}
      />
      <BaseLayout>
        <div className={classes.root}>
          <Stack direction="row" justifyContent="space-between" spacing={2}>
            <Stack direction="row" alignItems="center" gap={2}>
              <Heading className={classes.heading} level={headingLevel.XL} weight={fontWeight.BOLD}>
                Profile elements
              </Heading>
              <Badge
                className={classes.totalMemberBadge}
                variant={badgeType.OUTLINED}
                style={badgeStyle.UNRELATED}
              >
                {totalRecords} total
              </Badge>
            </Stack>

            <IconButton
              className={classes.addProfileBtn}
              icon="plus"
              variant={iconBtnType.PRIMARY}
              onClick={addProfileElement}
            >
              Add profile element
            </IconButton>
          </Stack>
          <Table
            searchProps={{
              placeholder: 'Search name and values',
              filterProps: {
                variant: Filter.tableFilterType.MULTIPLE,
                options: elementsFilterOptions,
                allOptionLabel: 'All profile',
                multiSelectOptions: multiSelectFilterOptionsMock,
              },
            }}
            gridProps={{
              columns: renderColumns,
              data: profileElements || [],
              isLoading,
              onRowClick: viewProfileElement,
            }}
            paginationProps={{
              currentRows: tableParams.pagination.rowsPerPage,
              totalCount: totalRecords,
              showRowsPerPage: true,
            }}
            value={tableParams}
            onChange={setTableParams}
            className={classes.table}
          />
        </div>
      </BaseLayout>
    </>
  );
};

export { ProfileElementList };
