import GeneralAvailability from './availability';
import BoardCertifications from './board-certifications';
import CAQHAccess from './caqh-access';
import DEACDSNumbers from './dea-cds-number';
import Degrees from './degrees';
import EducationExperience from './education-experience';
import Licenses from './licences';
import MalpracticeCoverage from './malpractice';
import Services from './services';
import CAQHInformation from './caqh-information';
import AvailableStates from './available-states';
import GeneralInfo from '../../admin/provider-detail/general-info';

const SelectedComponent = ({ selectedScreen, providerId, navItemStatus, setNavItemStatus, refresh }) => {
  switch (selectedScreen) {
    case 'generalInformation':
      return <GeneralInfo />;
    case 'CAQHInformation':
      return <CAQHInformation />;
    case 'education-and-experience':
      return <EducationExperience />;
    case 'services':
      return <Services />;
    case 'availability':
      return <GeneralAvailability providerId={providerId} />;
    case 'available-states':
      return <AvailableStates />;
    case 'boardCertifications':
      return <BoardCertifications refresh={refresh} />;
    case 'licenses':
      return <Licenses refresh={refresh} />;
    case 'DEACDSNumbers':
      return <DEACDSNumbers refresh={refresh} />;
    case 'CAQHAccess':
      return (
        <CAQHAccess navItemStatus={navItemStatus} setNavItemStatus={setNavItemStatus} refresh={refresh} />
      );
    case 'degrees':
      return <Degrees refresh={refresh} />;
    case 'malpracticeCoverage':
      return <MalpracticeCoverage refresh={refresh} />;

    default:
      return <div>In Progress...</div>;
  }
};

export default SelectedComponent;
