import Box from '@mui/material/Box';

import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Badge,
  badgeStyle,
  badgeType,
  colors,
  iconBtnPosition,
  iconBtnType,
  IconButton,
  Icons,
  Menu,
  RadioGroup,
  radioGroupType,
} from '../../../../packages';
import { Drawer, drawerType } from '../../../../packages/ui/organisms/drawer';

import { profileActionCreators } from '../../../../redux/modules/profile';
import { reportServiceActionCreators } from '../../../../redux/modules/report-service';
import { priorityActionCreators } from '../../../../redux/modules/priority';
import { getPriority } from '../../../../redux/modules/priority/selectors';
import { getProfile } from '../../../../redux/modules/profile/selectors';

import { useDomainType } from '../../../../hooks/useDomainType';

import { DomainTypesEnum } from '../../../../constants/CommonConstants';

import { AddDiagnosis } from '../video-call-screen/components/add-diagnosis';

import { radioOptions } from './SessionSignOffV2.constant';
import { useStyles } from './SessionSignOffV2.styles';

const Diagnoses = ({ appointment, onBack, onNext, preFill, template }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const {
    isLoading,
    typesPayload: { domainTypes },
    elementsPayload: { domainElements },
  } = useSelector(getPriority);

  const domainType = useMemo(() => domainTypes?.find(type => type.name === 'Diagnoses'), [domainTypes]);
  const domainTypeId = domainType?.Id;

  const { isLoading: isLoadingProfile } = useSelector(getProfile);

  const [isLoadingDiagnoses, diagnoses] = useDomainType({
    type: DomainTypesEnum.DIAGNOSES,
    patientId: appointment?.participantId,
  });

  const [selectedOption, setSelectedOption] = useState(preFill?.selectedOption ?? 'no');
  const [openAddDiagnosis, setOpenAddDiagnosis] = useState(false);
  const [selectedDomainElement, setSelectedDomainElement] = useState();

  useEffect(() => {
    dispatch(priorityActionCreators.fetchDomainElementsByTypeId(domainTypeId));
  }, [domainTypeId]);

  useEffect(() => {
    if (appointment?.participantId) {
      dispatch(profileActionCreators.fetchDomainTypes(appointment?.participantId));
      dispatch(reportServiceActionCreators.fetchAppointmentCptCodeById(appointment?.appointmentId));
    }
  }, [appointment?.participantId]);

  const isDisabled = useMemo(() => {
    return (!diagnoses || diagnoses?.relatedElements?.length === 0) && selectedOption === 'no';
  }, [diagnoses, selectedOption]);

  useEffect(() => {
    const handleKeyPress = event => {
      if (event.key === 'Enter' && !isDisabled) {
        onNextClick();
      }
    };
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [isDisabled]);

  useEffect(() => {
    if (template === 'yes' && !preFill?.selectedOption) {
      setSelectedOption('yes');
    }
  }, [template]);

  const onNextClick = () => {
    onNext({
      diagnoses: {
        icon: 'diagnoses',
        title: 'Diagnoses',
        description: 'active',
        number: diagnoses?.relatedElements.length ?? 0,
        selectedOption,
      },
    });
  };

  const onSelect = e => {
    setSelectedOption(e.target.value);
  };

  const onAdd = () => {
    setSelectedDomainElement(null);
    setOpenAddDiagnosis(true);
  };

  const onUpdate = item => {
    setSelectedDomainElement(item);
    setOpenAddDiagnosis(true);
  };

  const onSubmit = payload => {
    const data = {
      patientId: appointment.participantId,
      tagItems: [
        {
          assignedBy: appointment.participantName,
          domainElementId: payload.diagnosis,
          selectedIct10code: payload.icd10Code,
          metaData: {
            notes: payload.additionalNotes,
            selectedImportance: payload.status,
          },
        },
      ],
    };
    dispatch(
      profileActionCreators.createNewAssociatedTag({
        data,
        callback: errorMsg => {
          if (!errorMsg) {
            setOpenAddDiagnosis(false);
          }
        },
      }),
    );
  };

  if (isLoadingProfile || isLoading || isLoadingDiagnoses) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" flex={1}>
        <Icons className="rotate linear infinite" glyph="in-progress" color={colors.primary} />
      </Box>
    );
  }

  return (
    <>
      <Box className={classes.wrapper}>
        <Box className={classes.contentWrapper}>
          <Box className={classes.head}>
            <Box className={classes.contentHeader}>
              <Icons glyph="diagnoses" color={colors.neutral700} />
              <Box className={classes.contentHeaderTitle}>Review diagnoses</Box>
            </Box>
            <IconButton icon="plus" onClick={onAdd}>
              Add new
            </IconButton>
          </Box>
          <Box className={classes.content}>
            {diagnoses?.relatedElements?.length > 0 ? (
              diagnoses?.relatedElements.map((diagnosis, index) => (
                <Box className={classes.evaluation} key={index} onClick={() => onUpdate(diagnosis)}>
                  <Box className={classes.evaluationContent}>
                    <Box className="title">{diagnosis.name}</Box>
                    {diagnosis.selectedIct10code && (
                      <Box className="subTitle">{diagnosis.selectedIct10code}</Box>
                    )}
                  </Box>
                  <Box display="flex" gap={3} alignItems="center">
                    <Badge
                      variant={badgeType.FILLED}
                      style={badgeStyle.HIGH}
                      size={28}
                      className={classes.badge}
                    >
                      {diagnosis.priority.name}
                    </Badge>
                    <Menu
                      icon="more"
                      className={classes.menu}
                      itemsWrapperClassName={classes.menuItemsWrapper}
                      onMenuClick={e => e.stopPropagation()}
                      items={[
                        {
                          label: 'Update Diagnosis',
                          onClick: () => onUpdate(diagnosis),
                        },
                      ]}
                    />
                  </Box>
                </Box>
              ))
            ) : (
              <Box className={classes.empty}>
                <Box className="title">Diagnosis Needed</Box>
                <Box className="content">
                  Enter a diagnosis here that represents the client’s current experience and your diagnostic
                  evaluation. You can enter more than one.
                </Box>
                <IconButton icon="plus" variant={iconBtnType.OUTLINE} onClick={onAdd}>
                  New diagnosis
                </IconButton>
              </Box>
            )}
          </Box>
          {(!diagnoses || diagnoses?.relatedElements?.length === 0) && (
            <>
              <Box className={classes.question}>Do you want to continue without adding diagnoses?</Box>
              <RadioGroup
                name="radio-buttons-group"
                options={radioOptions}
                value={selectedOption}
                onChange={onSelect}
                variant={radioGroupType.HORIZONTAL}
              />
            </>
          )}
          <Drawer
            open={openAddDiagnosis}
            onClose={() => setOpenAddDiagnosis(false)}
            variant={drawerType.NORMAL}
          >
            <AddDiagnosis
              selectedDomainElement={selectedDomainElement}
              domainElements={domainElements}
              onSubmit={onSubmit}
              onClose={() => setOpenAddDiagnosis(false)}
            />
          </Drawer>
        </Box>
      </Box>
      <Box className={classes.footer}>
        <IconButton
          icon="chevron-left"
          iconPosition={iconBtnPosition.LEFT}
          className={classes.previousBtn}
          onClick={onBack}
          variant={iconBtnType.TEXT}
        >
          Previous
        </IconButton>
        <IconButton
          icon="chevron-right"
          iconPosition={iconBtnPosition.RIGHT}
          className={classes.nextBtn}
          onClick={onNextClick}
          disabled={isDisabled}
        >
          Next
        </IconButton>
      </Box>
    </>
  );
};

export default Diagnoses;
