import React, { useEffect, useRef, useState } from 'react';
import { Box } from '@mui/material';
import { useFormik } from 'formik';

import { Drawer, drawerType } from '../../../../../packages/ui/organisms/drawer';
import { IconButton } from '../../../../../packages/ui/molecules/icon-button';
import {
  fontWeight,
  Heading,
  headingLevel,
  Text,
  TextError,
} from '../../../../../packages/ui/atoms/typography';
import { Button, btnType } from '../../../../../packages/ui/atoms/button';
import { Textarea } from '../../../../../packages/ui/atoms/textarea';
import { Input, inputSize, inputType } from '../../../../../packages/ui/atoms/input';
import { Select, selectSize, selectType } from '../../../../../packages/ui/atoms/select';
import { RadioGroup, radioGroupType } from '../../../../../packages/ui/molecules/radio-group';
import { positionType, Toggle } from '../../../../../packages/ui/atoms/toggle';
import { tz } from 'moment-timezone';
import { useSelector } from 'react-redux';
import { stateSelector } from '../../../../../redux/modules/state/selectors';

// schema
import { ProfileSchema } from './GeneralInfo.schema';
// styles
import { useStyles } from './GeneralInfo.styles';
// constants
import { roles, specialities, signOffRoles, designation } from './GeneralInfo.constants';
import { getAuth } from '../../../../../redux/modules/auth/selectors';
import { SUPPORTED_AUTHORITIES } from '../../../../../constants/CommonConstants';
import UploadFileProgress from '../../../../admin/conversations/components/upload-flie-progress';
import { UploadFile } from '../../../../../packages/ui/templates/upload-file';
import { MediaManager } from '../../../../../services/mediaManager/mediaManager';
import { object } from 'yup';

const defaultValues = {
  name: '',
  firstName: '',
  lastName: '',
  role: '',
  description: '',
  specialities: [],
  signOffRole: '',
  activeInApp: false,
  acceptingNewPatients: false,
  timezone: '',
  twoFAEnabled: false,
  phoneNumber: '',
  designation: '',
  npi: '',
  acceptingAdolescents: false,
  taxId: '',
  billingProvider: false,
  providerAvailabilityCalendar: '',
  // -- practitionerAddress fields
  state: '',
  city: '',
  zipCode: '',
  zipPlusFourCode: '',
  address1: '',
  address2: '',
  enabled: false,
  // --
};

const GeneralInfo = ({ isOpen, onClose, profile, isSaving, error, onSubmit }) => {
  const classes = useStyles();
  const effectRun = useRef(false);
  const [imageFile, setImageFile] = useState(null);

  const processFile = async () => {
    const filesRead = await MediaManager.uploadDisplayPicture(imageFile, e => {
      console.log(e.percent);
    });
    return filesRead.response.key;
  };

  const { errors, values, handleChange, handleSubmit, touched, setFieldValue, ...rest } = useFormik({
    initialValues: { ...defaultValues },
    validationSchema: ProfileSchema,
    enableReinitialize: true,
    onSubmit: async payload => {
      let imageUrl = imageFile;
      if (imageFile && typeof imageFile !== 'string') {
        imageUrl = await processFile();
      }
      const bodyRequest = {
        fullName: payload?.name,
        firstName: payload.firstName,
        lastName: payload.lastName,
        providerRole: payload?.role,
        active: payload?.activeInApp,
        acceptingNewPatients: payload?.acceptingNewPatients,
        bio: payload?.description,
        signOffRole: payload?.signOffRole,
        speciality: payload?.specialities.map(item => item.value),
        timezone: payload?.timezone,
        twoFAEnabled: payload?.twoFAEnabled,
        phoneNumber: payload?.phoneNumber,
        designation: payload?.designation,
        npi: payload?.npi,
        acceptingAdolescents: payload?.acceptingAdolescents,
        taxId: payload?.taxId,
        billingProvider: payload?.billingProvider,
        state: payload?.practitionerAddress?.state,
        city: payload?.practitionerAddress?.city,
        zipCode: payload?.practitionerAddress?.zipCode,
        zipPlusFourCode: payload?.practitionerAddress?.zipPlusFourCode,
        address1: payload?.practitionerAddress?.address1,
        address2: payload?.practitionerAddress?.address2,
        providerAvailabilityCalendar: payload?.providerAvailabilityCalendar,
        enabled: payload?.enabled,
        profilePhoto: imageUrl,
      };
      onSubmit && onSubmit(bodyRequest);
    },
  });

  const [states, setStates] = useState([]);
  const { states: statesList } = useSelector(stateSelector);
  const { isAdmin, meta } = useSelector(getAuth);
  const getStatesList = () => {
    const orderedStates = statesList.map(({ state }) => ({ label: state.name, value: state.name }));
    setStates(orderedStates);
  };
  const [savingButton, setSavingButton] = useState(false);

  const onUpdateButtonClick = () => {
    setSavingButton(true);
  };

  useEffect(() => {
    if (!effectRun.current && isOpen) {
      effectRun.current = true;
      if (profile?.avatar && profile?.avatar?.trim() !== '') {
        setImageFile(profile.avatar);
      } else if ((!profile.avatar || profile?.avatar?.trim()) === '' && !imageFile) {
        setImageFile(null);
      }
    }
  }, [profile]);

  useEffect(() => {
    if (!isOpen) {
      setSavingButton(false);
    }
    getStatesList();
  }, [isOpen]);

  // Re enable update button when there are errors
  useEffect(() => {
    if (Object.keys(errors).length >= 1) {
      setSavingButton(false);
    }
  }, [errors, savingButton]);

  useEffect(() => {
    if (profile && !isSaving) {
      Object.keys(profile).forEach(async key => {
        if (key === 'practitionerAddress') {
          Object.keys(profile[key]).forEach(innerKey => {
            void setFieldValue(innerKey, profile?.practitionerAddress?.[innerKey]);
          });
        }
        if (key === 'specialities') {
          const items = profile.specialities?.map(item => ({ title: item, value: item }));
          await setFieldValue(key, items || []);
        } else {
          await setFieldValue(key, profile[key]);
        }
      });
    }
  }, [profile, isSaving]);

  useEffect(() => {
    if (!isOpen) {
      rest.handleReset({ ...defaultValues });
      rest.setErrors({});
    }
  }, [isOpen]);

  const setTouched = name => async () => {
    await rest.setTouched({ ...touched, [name]: true });
  };

  const onChangeTags = async (_, val) => {
    await setFieldValue('specialities', val);
  };

  const renderLabel = label => (
    <Heading level={headingLevel.S} className={classes.label} weight={fontWeight.BOLD}>
      {label}
    </Heading>
  );

  const onChange2FAToggle = async val => {
    await setFieldValue('twoFAEnabled', val);
  };
  const onChangeAdolescentsToggle = async val => {
    await setFieldValue('acceptingAdolescents', val);
  };
  const onChangeBillingProviderToggle = async val => {
    await setFieldValue('billingProvider', val);
  };
  const handlePractitionerAddressChange = event => {
    void setFieldValue(`practitionerAddress.${event.target.name}`, event.target.value);
  };
  const onChangeEnabledToggle = async val => {
    await setFieldValue('enabled', val);
  };
  const onAcceptedFiles = files => {
    const file = files[0];
    setImageFile(file);
  };

  return (
    <Drawer open={isOpen} onClose={onClose} variant={drawerType.NORMAL} className={classes.drawer}>
      <Box className={classes.wrapper}>
        <Box className={classes.header}>
          <Text className={classes.headerTitle}>Edit general information</Text>
          <IconButton icon="close" onClick={onClose} className={classes.backBtn} />
        </Box>
        <form className={classes.form} onSubmit={handleSubmit}>
          <Box className={classes.formContent}>
            {error && (
              <Box display="flex" justifyContent="center" paddingY={2}>
                <TextError errorMsg={error} />
              </Box>
            )}
            <Box className={classes.section}>
              {renderLabel('Full Name')}
              <Input
                value={values.name}
                name="name"
                placeholder="Enter name"
                onChange={handleChange}
                size={inputSize.M}
                onBlur={setTouched('name')}
                fullWidth
              />
              <TextError errorMsg={touched.name ? errors.name?.toString() : null} />
            </Box>
            <Box className={classes.section}>
              {renderLabel('First name')}
              <Input
                value={values.firstName}
                name="firstName"
                placeholder="Enter first name"
                onChange={handleChange}
                size={inputSize.M}
                onBlur={setTouched('firstName')}
                fullWidth
              />
              <TextError errorMsg={touched.firstName ? errors.firstName?.toString() : null} />
            </Box>
            <Box className={classes.section}>
              {renderLabel('Last name')}
              <Input
                value={values.lastName}
                name="lastName"
                placeholder="Enter last name"
                onChange={handleChange}
                size={inputSize.M}
                onBlur={setTouched('lastName')}
                fullWidth
              />
              <TextError errorMsg={touched.lastName ? errors.lastName?.toString() : null} />
            </Box>
            {isAdmin && (
              <>
                <Box className={classes.section}>
                  {renderLabel('Role')}
                  <Select
                    options={roles}
                    value={values.role}
                    placeholder="Select a role"
                    name="role"
                    onChange={handleChange}
                    variant={selectType.SECONDARY}
                    fullWidth
                  />
                  <TextError errorMsg={touched.role ? errors.role?.toString() : null} />
                </Box>
                <Box className={classes.section}>
                  {renderLabel('Designation')}
                  <Select
                    value={values.designation}
                    name="designation"
                    variant={selectType.SECONDARY}
                    options={designation}
                    size={selectSize.S}
                    onChange={handleChange}
                  />
                  <TextError errorMsg={touched.designation ? errors.designation?.toString() : null} />
                </Box>
                <Box className={classes.section}>
                  {renderLabel('External calendar')}
                  <Input
                    value={values.providerAvailabilityCalendar}
                    name="providerAvailabilityCalendar"
                    placeholder="Enter external calendar"
                    onChange={handleChange}
                    size={inputSize.M}
                    onBlur={setTouched('providerAvailabilityCalendar')}
                    fullWidth
                  />
                  <TextError
                    errorMsg={
                      touched.providerAvailabilityCalendar
                        ? errors.providerAvailabilityCalendar?.toString()
                        : null
                    }
                  />
                </Box>
              </>
            )}
            <Box className={classes.section}>
              {renderLabel('Phone number')}
              <Input
                value={values.phoneNumber}
                name="phoneNumber"
                placeholder="Enter phone number"
                onChange={e => {
                  void setFieldValue('phoneNumber', e);
                }}
                size={inputSize.M}
                variant={inputType.PHONE}
                onBlur={setTouched('phoneNumber')}
                fullWidth
              />
              <TextError errorMsg={touched.phoneNumber ? errors.phoneNumber?.toString() : null} />
            </Box>
            <Box className={classes.section}>
              {renderLabel('Time zone')}
              <Select
                options={tz.names().map(item => ({ label: item, value: item }))}
                value={values.timezone}
                placeholder="Select a timezone"
                name="timezone"
                onChange={handleChange}
                displayEmpty
                emptyText="Select a timezone"
                variant={selectType.SECONDARY}
                fullWidth
              />
            </Box>
            <Box className={classes.section}>
              {renderLabel('Specialities')}
              <Input
                value={values.specialities}
                name="specialities"
                variant={inputType.TAGS}
                placeholder="Enter an speciality"
                onChange={onChangeTags}
                options={specialities.filter(item => {
                  const foundItem = values.specialities?.find(itm => itm.value === item.value);
                  if (foundItem) {
                    return false;
                  }
                  return item;
                })}
                size={inputSize.M}
                onBlur={setTouched('specialities')}
                fullWidth
              />
              <TextError errorMsg={touched.specialities ? errors.specialities?.toString() : null} />
            </Box>
            <Box className={classes.section}>
              {renderLabel('About')}
              <Textarea
                value={values.description}
                name="description"
                onChange={handleChange}
                onBlur={setTouched('description')}
                minRows={5}
                placeholder="Enter about"
              />
              <TextError errorMsg={touched.description ? errors.description?.toString() : null} />
            </Box>
            <Box className={classes.section}>
              {renderLabel('NPI')}
              <Input
                value={values.npi}
                name="npi"
                placeholder="Enter NPI"
                onChange={handleChange}
                size={inputSize.M}
                onBlur={setTouched('npi')}
                fullWidth
              />
              <TextError errorMsg={touched.npi ? errors.npi?.toString() : null} />
            </Box>
            <Box className={classes.section}>
              {renderLabel('Tax ID')}
              <Input
                value={values.taxId}
                name="taxId"
                placeholder="Enter tax ID"
                onChange={handleChange}
                size={inputSize.M}
                onBlur={setTouched('taxId')}
                fullWidth
              />
              <TextError errorMsg={touched.taxId ? errors.taxId?.toString() : null} />
            </Box>
            {isAdmin && (
              <Box className={classes.section}>
                {renderLabel('Active in app?')}
                <RadioGroup
                  value={String(values.activeInApp)}
                  name="activeInApp"
                  variant={radioGroupType.HORIZONTAL}
                  onChange={handleChange}
                  options={[
                    { label: 'Yes', value: 'true' },
                    { label: 'No', value: 'false' },
                  ]}
                />
              </Box>
            )}
            <Box className={classes.section}>
              {renderLabel('Accept new patients?')}
              <RadioGroup
                value={String(values.acceptingNewPatients)}
                name="acceptingNewPatients"
                variant={radioGroupType.HORIZONTAL}
                onChange={handleChange}
                options={[
                  { label: 'Yes', value: 'true' },
                  { label: 'No', value: 'false' },
                ]}
              />
            </Box>
            <Box className={classes.section}>
              {renderLabel('Adolescents')}
              <Toggle
                checked={values.acceptingAdolescents}
                onChange={onChangeAdolescentsToggle}
                labelPosition={positionType.RIGHT}
              >
                Accepting adolescents
              </Toggle>
            </Box>
            {isAdmin && (
              <Box className={classes.section}>
                {renderLabel('Select SignOff Role:')}
                <Select
                  options={signOffRoles}
                  value={values.signOffRole}
                  placeholder="Select a signOffRole"
                  name="signOffRole"
                  onChange={handleChange}
                  variant={selectType.SECONDARY}
                  fullWidth
                />
              </Box>
            )}
            {isAdmin && (
              <Box className={classes.section}>
                {renderLabel('Billing Provider')}
                <Toggle
                  checked={values.billingProvider}
                  onChange={onChangeBillingProviderToggle}
                  labelPosition={positionType.RIGHT}
                >
                  Billing Provider
                </Toggle>
              </Box>
            )}
            <Box className={classes.section}>
              {renderLabel('Address 1')}
              <Input
                value={values.practitionerAddress?.address1}
                name="address1"
                placeholder="Address line 1"
                onChange={handlePractitionerAddressChange}
                size={inputSize.M}
                onBlur={setTouched('address1')}
                fullWidth
              />
              <TextError
                errorMsg={
                  touched.practitionerAddress?.address1
                    ? errors.practitionerAddress?.address1?.toString()
                    : null
                }
              />
            </Box>
            <Box className={classes.section}>
              {renderLabel('Address 2')}
              <Input
                value={values.practitionerAddress?.address2}
                name="address2"
                placeholder="Address line 2"
                onChange={handlePractitionerAddressChange}
                size={inputSize.M}
                onBlur={setTouched('address2')}
                fullWidth
              />
              <TextError
                errorMsg={
                  touched.practitionerAddress?.address2
                    ? errors.practitionerAddress?.address2?.toString()
                    : null
                }
              />
            </Box>
            <Box className={classes.section}>
              {renderLabel('City')}
              <Input
                value={values.practitionerAddress?.city}
                name="city"
                placeholder="city"
                onChange={handlePractitionerAddressChange}
                size={inputSize.M}
                onBlur={setTouched('city')}
                fullWidth
              />
              <TextError
                errorMsg={
                  touched.practitionerAddress?.city ? errors.practitionerAddress?.city?.toString() : null
                }
              />
            </Box>
            <Box className={classes.section}>
              {renderLabel('State')}
              <Select
                variant={selectType.SECONDARY}
                options={states}
                size={selectSize.S}
                name="state"
                value={values.practitionerAddress?.state}
                onChange={handlePractitionerAddressChange}
              />
              <TextError
                errorMsg={
                  touched.practitionerAddress?.state ? errors.practitionerAddress?.state?.toString() : null
                }
              />
            </Box>
            <Box className={classes.section}>
              {renderLabel('Zip code')}
              <Input
                value={values.practitionerAddress?.zipCode}
                name="zipCode"
                placeholder="zip code"
                onChange={handlePractitionerAddressChange}
                size={inputSize.M}
                onBlur={setTouched('zipCode')}
                fullWidth
              />
              <TextError
                errorMsg={
                  touched.practitionerAddress?.zipCode
                    ? errors.practitionerAddress?.zipCode?.toString()
                    : null
                }
              />
            </Box>
            {isAdmin && (
              <Box className={classes.section}>
                {renderLabel('Zip +4 code')}
                <Input
                  value={values.practitionerAddress?.zipPlusFourCode}
                  name="zipPlusFourCode"
                  placeholder="zip +4 code"
                  onChange={handlePractitionerAddressChange}
                  size={inputSize.M}
                  onBlur={setTouched('zipPlusFourCode')}
                  fullWidth
                />
                <TextError
                  errorMsg={
                    touched.practitionerAddress?.zipPlusFourCode
                      ? errors.practitionerAddress?.zipPlusFourCode?.toString()
                      : null
                  }
                />
              </Box>
            )}
            <Box className={classes.section}>
              {renderLabel('Profile photo')}
              <Box>
                {imageFile ? (
                  <UploadFileProgress
                    file={imageFile}
                    onUploaded={() => {
                      console.log('uploaded');
                    }}
                    onRemove={() => {
                      setImageFile(null);
                    }}
                  />
                ) : (
                  <UploadFile
                    description="File size up to 5MB"
                    onAcceptedFiles={onAcceptedFiles}
                    fileType="image/*"
                    className={classes.dashedBorder}
                  />
                )}
              </Box>
            </Box>
            <Box className={classes.section}>
              {renderLabel('2 Factor Authentication')}
              <Toggle
                checked={values.twoFAEnabled}
                onChange={onChange2FAToggle}
                labelPosition={positionType.RIGHT}
              >
                2FA is enabled
              </Toggle>
            </Box>
            {(meta.authority === SUPPORTED_AUTHORITIES.ADMIN ||
              meta.authority === SUPPORTED_AUTHORITIES.CARE_NAVIGATOR) && (
              <Box className={classes.section}>
                {renderLabel('Enable Account')}
                <Toggle
                  checked={values.enabled}
                  onChange={onChangeEnabledToggle}
                  labelPosition={positionType.RIGHT}
                >
                  Enabled
                </Toggle>
              </Box>
            )}
          </Box>
          <Box className={classes.footer}>
            <Button variant={btnType.TEXT} onClick={onClose}>
              Cancel
            </Button>
            <Button
              onClick={() => {
                onUpdateButtonClick();
                handleSubmit();
              }}
              disabled={savingButton}
            >
              {savingButton ? 'Saving...' : 'Update'}
            </Button>
          </Box>
        </form>
      </Box>
    </Drawer>
  );
};

export { GeneralInfo };
