/* eslint-disable no-nested-ternary */
import { useEffect, useCallback } from 'react';
import { Box } from '@mui/material';

import ReportFormCollapseSubstance from '../report-form-collapse/ReportFormCollapseSubstance';

import { reportItemProgress } from '../AddNewReport.constants';
import { useStyles } from './StepTwo.styles';

const StepTwoSubstance = ({
  showInterference,
  selectedItems,
  lookupData,
  onRemoveItem,
  updateItems,
  options,
}) => {
  const classes = useStyles();

  useEffect(() => {
    updateItems(
      selectedItems.map((symp, index) => ({
        ...symp,
        progress: !index ? reportItemProgress.InProgress : reportItemProgress.Incomplete,
      })),
    );
  }, []);

  const onUpdate = useCallback(
    substance => {
      const nextIndex = selectedItems.findIndex(
        item =>
          item.item.value !== substance.item.value &&
          item.progress !== reportItemProgress.Completed &&
          item.notes.trim().length === 0,
      );
      updateItems(
        selectedItems.map((selectedItem, index) =>
          selectedItem.item.value === substance.item.value
            ? { ...substance, progress: reportItemProgress.Completed }
            : index === nextIndex
            ? { ...selectedItem, progress: reportItemProgress.InProgress }
            : selectedItem,
        ),
      );
    },
    [selectedItems, updateItems],
  );

  const onRemoveClick = index => () => {
    const updatedSymptoms = selectedItems.filter((_, i) => i !== index);
    onRemoveItem(index);
    updateItems([...updatedSymptoms]);
  };

  const onOpenCollapse = substance => open => {
    let newProgress = substance.notes.trim().length
      ? reportItemProgress.Completed
      : reportItemProgress.Incomplete;
    if (open) {
      newProgress = reportItemProgress.InProgress;
    }

    updateItems(
      selectedItems.map(selectedItem =>
        selectedItem.item.value === substance.item.value
          ? { ...selectedItem, progress: newProgress }
          : selectedItem,
      ),
    );
  };

  return (
    <Box className={classes.container}>
      {selectedItems.map((item, index) => (
        <ReportFormCollapseSubstance
          key={index}
          item={item}
          showInterference={showInterference}
          lookupData={lookupData}
          onChange={onUpdate}
          onRemoveClick={onRemoveClick(index)}
          onOpenCollapse={onOpenCollapse(item)}
          options={options}
        />
      ))}
    </Box>
  );
};

export default StepTwoSubstance;
