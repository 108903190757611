import cx from 'clsx';

// mui
import { LinearProgress } from '@mui/material';

// styles
import { useStyles } from './ProgressBar.styles';

const ProgressBar = ({ className = '', value = 0, ...rest }) => {
  const classes = useStyles();

  return (
    <LinearProgress
      className={cx({
        [classes.root]: true,
        [className]: true,
      })}
      value={value}
      variant="determinate"
      {...rest}
    />
  );
};

export { ProgressBar };
