/* eslint-disable max-len */
import cx from 'clsx';

// types
// styles
import { useStyles } from './shared/styles';

const CheckCircle = ({ className, color, onClick }) => {
  const classes = useStyles();
  return (
    <svg
      className={cx({
        [classes.root]: true,
        [className || '']: className,
      })}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M10 0C8.02219 0 6.08879 0.58649 4.4443 1.6853C2.79981 2.78412 1.51809 4.3459 0.761209 6.17317C0.00433284 8.00043 -0.193701 10.0111 0.192152 11.9509C0.578004 13.8907 1.53041 15.6725 2.92894 17.0711C4.32746 18.4696 6.10929 19.422 8.0491 19.8079C9.98891 20.1937 11.9996 19.9957 13.8268 19.2388C15.6541 18.4819 17.2159 17.2002 18.3147 15.5557C19.4135 13.9112 20 11.9778 20 10C20 8.68678 19.7413 7.38642 19.2388 6.17317C18.7363 4.95991 17.9997 3.85752 17.0711 2.92893C16.1425 2.00035 15.0401 1.26375 13.8268 0.761205C12.6136 0.258658 11.3132 0 10 0ZM14.35 7.73L8.73 13.35C8.68511 13.3978 8.6309 13.436 8.5707 13.462C8.5105 13.4881 8.4456 13.5015 8.38 13.5015C8.31441 13.5015 8.24951 13.4881 8.18931 13.462C8.12911 13.436 8.07489 13.3978 8.03 13.35L5.65 11C5.60314 10.9535 5.56594 10.8982 5.54056 10.8373C5.51517 10.7764 5.50211 10.711 5.50211 10.645C5.50211 10.579 5.51517 10.5136 5.54056 10.4527C5.56594 10.3918 5.60314 10.3365 5.65 10.29L6.18 9.76C6.22489 9.71217 6.27911 9.67405 6.33931 9.64799C6.39951 9.62193 6.46441 9.60848 6.53 9.60848C6.5956 9.60848 6.6605 9.62193 6.7207 9.64799C6.7809 9.67405 6.83511 9.71217 6.88 9.76L8.38 11.25L13.12 6.51C13.2135 6.41839 13.3391 6.36707 13.47 6.36707C13.6009 6.36707 13.7265 6.41839 13.82 6.51L14.35 7.04C14.4387 7.13299 14.4881 7.25653 14.4881 7.385C14.4881 7.51347 14.4387 7.63701 14.35 7.73Z"
        fill={color}
      />
    </svg>
  );
};

export default CheckCircle;
