// colors
import { colors } from '../../../../../packages';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  drawer: {
    '& .drawerBody': {
      minHeight: 'calc(100vh - 80px)',
      flexDirection: 'column',
      display: 'flex',
      padding: 0,
    },
  },
  badge: {
    borderRadius: 100,
  },
  memberBadge: {
    fontSize: 12,
    lineHeight: '20px',
    height: 20,
    backgroundColor: '#FFF1F3',
    color: '#E31B53',
    border: 'none',
  },
  appointment: {
    borderBottom: `1px solid ${colors.neutral100}`,
    padding: '40px 32px 32px',
  },
  title: {
    fontSize: 18,
    lineHeight: '24px',
    margin: 0,
  },
  text14: {
    fontSize: 14,
    lineHeight: '20px',
  },
  duration: {
    color: colors.neutral600,
    display: 'block',
    marginTop: 4,
  },
  info: {
    color: colors.neutral700,
  },
  fullname: {
    color: colors.neutral500,
    display: 'block',
  },
  icon: {},
  boxInfo: {
    display: 'flex',
    alignItems: 'center',
    gap: 10,
    '& svg': {
      width: 16,
    },
  },
  viewBtn: {
    fontSize: 14,
    '& svg': {
      width: 16,
      marginLeft: 10,
    },
  },
});
