import { ChangeEvent, FC, useState } from 'react';
import { Box } from '@mui/material';

import {
  fontWeight,
  Heading,
  headingLevel,
  TextError,
} from '../../../../../../../packages/ui/atoms/typography';
import { iconBtnType, IconButton } from '../../../../../../../packages/ui/molecules/icon-button';
import { Textarea } from '../../../../../../../packages/ui/atoms/textarea';

import { useStyles } from './EvaluationAddText.styles';

const EvaluationAddText = ({ narrative, onClose, isAddPersonalNote = false, onUpdateNarrative }) => {
  const classes = useStyles();
  const [text, setText] = useState(narrative);
  const [errorMsg, setErrorMsg] = useState('');

  const handleChange = event => {
    setText(event.target.value);
  };

  const onAddText = () => {
    if (text.length === 0) {
      setErrorMsg('Please enter some text');
      return;
    }
    if (isAddPersonalNote) onUpdateNarrative(text);
    setErrorMsg('');
    onClose();
  };

  return (
    <>
      <Box className={classes.container}>
        <Box className={classes.header}>
          <Heading weight={fontWeight.BOLD} className={classes.title}>
            {isAddPersonalNote ? 'Add narrative' : 'Add text'}
          </Heading>
          <IconButton icon="close" onClick={onClose} className={classes.backBtn} />
        </Box>
        <Box className={classes.body}>
          <Box className={classes.section}>
            <Heading level={headingLevel.S} className={classes.label} weight={fontWeight.SEMI_BOLD}>
              {isAddPersonalNote ? '' : 'Previously diagnosed psychiatric condition'}
            </Heading>
            <Textarea value={text} name="text" onChange={handleChange} minRows={5} placeholder="Enter text" />
            <TextError errorMsg={errorMsg} />
          </Box>
        </Box>
        <Box className={classes.footer}>
          <IconButton onClick={onClose}>Cancel</IconButton>
          <IconButton onClick={onAddText} variant={iconBtnType.PRIMARY} className={classes.addTextBtn}>
            {isAddPersonalNote ? 'Add note' : 'Add text'}
          </IconButton>
        </Box>
      </Box>
    </>
  );
};

export { EvaluationAddText };
