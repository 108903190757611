import cx from 'clsx';

// icons
import { Icons } from '../../../icons';

// components
import { fontWeight, Text, textLevel } from '../../atoms/typography';

// styles
import { useStyles } from './IconText.styles';

// types
import { iconTextPosition, iconTextStyle } from './IconText.types';

const IconText = ({
  className = '',
  style = iconTextStyle.PRIMARY,
  icon = undefined,
  iconPosition = iconTextPosition.LEFT,
  children,
  ...rest
}) => {
  const classes = useStyles({ style, iconPosition });

  return (
    <Text
      className={cx({
        [classes.root]: true,
        [className || '']: className,
      })}
      weight={fontWeight.BOLD}
      level={textLevel.S}
      {...rest}
    >
      <Icons className={classes.icon} glyph={icon} color="currentColor" />
      {children}
    </Text>
  );
};

export { IconText };
