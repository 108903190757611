import cx from 'clsx';

// mui
import MuiStepper from '@mui/material/Stepper';
import MuiStep from '@mui/material/Step';

// icons
import { Icons } from '../../../icons';

// colors
import { colors } from '../../../colors';

// components
import { Pill, pillType } from '../../atoms/pill';

// styles
import { useStyles } from './Stepper.styles';

const Stepper = ({ className = '', activeStep = -1, minusConnector = false, steps = [], ...rest }) => {
  const classes = useStyles();

  const connectorIcon = minusConnector ? (
    <Icons className={classes.minusConnector} glyph="minus" color={colors.neutral200} />
  ) : (
    <Icons className={classes.connector} glyph="long-arrow-right" color={colors.neutral600} />
  );

  const getPillType = idx => {
    if (idx > activeStep) return pillType.NOT_DONE;
    if (idx < activeStep) return pillType.DONE;
    return pillType.CURRENT;
  };

  return (
    <MuiStepper
      className={cx({
        [classes.root]: true,
        [className || '']: className,
      })}
      activeStep={activeStep}
      connector={connectorIcon}
      {...rest}
    >
      {steps.map((label, idx) => (
        <MuiStep className={classes.step} key={idx}>
          <Pill label={label} variant={getPillType(idx)}>
            {idx + 1}
          </Pill>
        </MuiStep>
      ))}
    </MuiStepper>
  );
};

export { Stepper };
