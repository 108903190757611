import { makeStyles } from '@mui/styles';
// colors
import { colors } from '../../../../../packages';

export const useStyles = makeStyles({
  upcomingItems: {
    height: 360,
  },
  item: {
    '&:not(:last-child)': {
      borderBottom: `1px solid ${colors.neutral100}`,
    },
  },
});
