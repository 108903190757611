import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'clsx';
import { Box, Collapse } from '@mui/material';
import { useFormik } from 'formik';
import {
  Button,
  colors,
  fontWeight,
  Heading,
  headingLevel,
  iconBtnStyle,
  iconBtnType,
  IconButton,
  Icons,
  Input,
  inputSize,
  inputType,
  Select,
  selectType,
  TextError,
} from '../../../../../../packages';
import * as ConversationService from '../../../../../../services/conversation/conversation.service';
import { stateActionCreators } from '../../../../../../redux/modules/state';
import { selectStatePayers } from '../../../../../../redux/modules/state/selectors';

import AutomationEventItem from '../../../duplicate-automation/automation-event-item';
import {
  AUTOMATION_FILTERS,
  automationLogicSelect,
  automationRuleSelect,
  automationRuleSelectDate,
  automationRuleSelectNumeric,
  automationRuleSelectOption,
  automationRuleSelectText,
  BUILDER_CONSTANTS,
  FilterTypeMap,
  ProfileElementDefType,
} from './AutomationFilterForm.constants';
import { useStyles } from './AutomationFilterForm.styles';

export const DEFAULT_STRUCTURED_VALUES = {
  key: '',
  rule: '',
  type: '',
  value: [],
};

const AutomationFilterForm = ({
  open = true,
  filter,
  onChange,
  onDelete,
  profileElementList,
  tagList,
  event,
  isEditMode,
  levelOfEngagements,
  providers,
  states,
  isLoadingPayers,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [expandedForm, setExpandedForm] = useState(open || false);
  const [displayLogic, setDisplayLogic] = useState(automationLogicSelect);
  const [filterProfileElementList, setFilterProfileElementList] = useState([]);
  const [filterProfileValue, setFilterProfileValue] = useState([]);
  const [filterRuleValue, setFilterRuleValue] = useState(automationRuleSelect);
  const [contentBlocks, setContentBlocks] = useState([]);
  const [displayLabel, setDisplayLabel] = useState('Select Profile Element');
  const [selectedKey, setSelectedKey] = useState(null);
  const payers = useSelector(selectStatePayers);
  const onSubmit = payload => {
    const newobj = {
      key: payload.key,
      rule: payload.rule,
      type: payload.type,
      value: payload.value,
    };
    setExpandedForm(false);
    onChange(newobj);
  };
  const payerOptions = useMemo(() => {
    if (payers?.[0]) {
      return payers?.map(payer => ({ label: payer.name, value: payer._id }));
    }
    return [];
  }, [payers]);
  const providerOptions = useMemo(
    () => providers?.map(provider => ({ label: provider.fullName, value: provider.providerId })) || [],
    [providers],
  );
  const stateOptions = useMemo(
    () => states?.map(state => ({ label: state.state.name, value: state.state.name })) || [],
    [states],
  );
  const levelOfEngagementOptions = useMemo(
    () =>
      levelOfEngagements?.map(levelOfEngagement => ({
        label: levelOfEngagement.name,
        value: levelOfEngagement.id,
      })) || [],
    [levelOfEngagements],
  );

  const { errors, values, handleChange, handleSubmit, touched, setFieldValue, ...rest } = useFormik({
    initialValues: isEditMode ? { ...filter } : { ...DEFAULT_STRUCTURED_VALUES },
    // validationSchema: AutomationFilterSchema,
    onSubmit,
  });
  const onEditClick = () => {
    setExpandedForm(true);
  };

  const getSelectedElementType = typeValue => {
    const selectedProfileElement =
      profileElementList &&
      profileElementList?.filter(element => element.profileElementInfo.key === typeValue)[0];
    return ProfileElementDefType[selectedProfileElement?.profileElementInfo?.type];
  };

  const getOperatorValues = logic => {
    const selectedElementType = getSelectedElementType(logic);
    switch (selectedElementType) {
      case ProfileElementDefType.TEXT_INPUT:
        return automationRuleSelectText;
      case ProfileElementDefType.NUMERIC:
      case ProfileElementDefType.SCORE_BASED:
      case ProfileElementDefType.RATING_SCALE:
        return automationRuleSelectNumeric;
      case ProfileElementDefType.YES_NO:
      case ProfileElementDefType.USER_DEFINED_VALUES:
        return automationRuleSelectOption;
      case ProfileElementDefType.DATE:
      case ProfileElementDefType.DATE_TIME:
        return automationRuleSelectDate;

      default:
        return automationRuleSelectText;
    }
  };

  const getListByType = useMemo(() => {
    if (values.type === 'R') {
      const conversationListTemp =
        contentBlocks &&
        contentBlocks.length > 0 &&
        contentBlocks
          ?.filter(cb => cb.basicInfo && cb.basicInfo.referenceId)
          .map(cb => {
            return {
              label: cb.basicInfo.text,
              value: cb.basicInfo.referenceId,
            };
          });
      return conversationListTemp && conversationListTemp.length > 0
        ? conversationListTemp
        : [
            {
              label: '',
              value: '',
            },
          ];
    }
    if (values.type === 'T') {
      const tagListTemp =
        tagList &&
        tagList.length > 0 &&
        tagList.map(tl => {
          return {
            label: tl?.name,
            value: tl?.name,
          };
        });
      return tagListTemp && tagListTemp.length > 0
        ? tagListTemp
        : [
            {
              label: '',
              value: '',
            },
          ];
    }
    if (values.type === 'STATE') {
      const stateListTemp =
        states &&
        states.length > 0 &&
        states.map(st => {
          return {
            label: st?.state?.name,
            value: st?.state?.name,
          };
        });
      return stateListTemp && stateListTemp.length > 0
        ? stateListTemp
        : [
            {
              label: '',
              value: '',
            },
          ];
    }
    if (values.type === 'PROVIDER') {
      const providerListTemp =
        providers &&
        providers.length > 0 &&
        providers.map(pr => {
          return {
            label: pr?.fullName,
            value: pr?.providerId,
          };
        });
      return providerListTemp && providerListTemp.length > 0
        ? providerListTemp
        : [
            {
              label: '',
              value: '',
            },
          ];
    }
    if (values.type === 'LEVEL_OF_ENGAGEMENT') {
      const levelOfEngagementsTemp =
        levelOfEngagements &&
        levelOfEngagements.length > 0 &&
        levelOfEngagements.map(lo => {
          return {
            label: lo?.name,
            value: lo?.id,
          };
        });
      return levelOfEngagementsTemp && levelOfEngagementsTemp.length > 0
        ? levelOfEngagementsTemp
        : [
            {
              label: '',
              value: '',
            },
          ];
    }
    if (values.type === 'INSURANCE') {
      const insuranceStateTemp =
        states &&
        states.length > 0 &&
        states.map(st => {
          return {
            label: st?.state?.name,
            value: st?.state?._id,
          };
        });
      return insuranceStateTemp && insuranceStateTemp.length > 0
        ? insuranceStateTemp
        : [
            {
              label: '',
              value: '',
            },
          ];
    }
    return (
      profileElementList &&
      profileElementList.map(item => {
        return {
          label: item.profileElementInfo.key,
          value: item.profileElementInfo.key,
        };
      })
    );
  }, [
    values.type,
    contentBlocks,
    tagList,
    profileElementList,
    states,
    providers,
    levelOfEngagements,
    payers,
  ]);

  const onSelectKey = async (_, keyValue) => {
    const val = keyValue && keyValue?.value !== '' ? keyValue?.value : isEditMode ? filter.key : '';
    setSelectedKey(
      getListByType?.find(x => x?.value?.trim() === val?.trim() || x?.label?.trim() === val?.trim()),
    );
    const filterRule = getOperatorValues(val);
    const filteredElement = profileElementList?.filter(element => element.profileElementInfo.key === val);
    await setFieldValue('key', val);
    if (
      values.type === 'P' &&
      filteredElement.length > 0 &&
      filteredElement[0].profileElementInfo &&
      filteredElement[0].profileElementInfo.values
    ) {
      const filteredElements = filteredElement[0].profileElementInfo.values.map(item => {
        return {
          label: item,
          value: item,
        };
      });
      setFilterProfileValue(filteredElements);
      setFilterRuleValue(filterRule);
      await setFieldValue('rule', filter.rule);
      await setFieldValue('value', filter.value);
    } else if (values.type === 'R') {
      const selectedCb = contentBlocks?.filter(cb => cb?.basicInfo && cb?.basicInfo?.referenceId === val);
      if (
        (selectedCb.length > 0 &&
          selectedCb?.[0]?.basicInfo.type === BUILDER_CONSTANTS.ContentBlockTypes.SINGLE_SELECT) ||
        selectedCb?.[0]?.basicInfo.type === BUILDER_CONSTANTS.ContentBlockTypes.MULTI_SELECT
      ) {
        const filterValue =
          selectedCb?.[0]?.basicInfo.choices &&
          selectedCb?.[0]?.basicInfo.choices.map(choice => {
            return {
              label: choice.choice,
              value: choice.choice,
            };
          });
        setFilterProfileValue(filterValue);
      }
    } else if (values.type === 'T') {
      await setFieldValue('rule', 'equals');
      await setFieldValue('value', [val]);
    } else if (
      values.type === 'STATE' ||
      values.type === 'PROVIDER' ||
      values.type === 'INSURANCE' ||
      values.type === 'LEVEL_OF_ENGAGEMENT'
    ) {
      await setFieldValue('rule', '');
      if (values.type === 'INSURANCE' && (values.key || val)) {
        const foundState = states.find(state => state.state.name === val);
        if (foundState) {
          dispatch(stateActionCreators.fetchPayers(foundState._id));
        }
      }
    }
  };
  const onSelectType = async e => {
    const val = e && e?.target?.value !== '' ? e.target.value : isEditMode ? filter.type : '';
    await setFieldValue('key', '');
    await setFieldValue('rule', '');
    await setFieldValue('value', []);
    await setFieldValue('type', val);

    setFilterProfileElementList([]);
    setSelectedKey(null);
    let elementList = [];
    if (e?.target.value === 'R') {
      const conversationListTemp =
        contentBlocks &&
        contentBlocks.length > 0 &&
        contentBlocks
          ?.filter(cb => cb.basicInfo && cb.basicInfo.referenceId)
          .map(cb => {
            return {
              label: cb.basicInfo.text,
              value: cb.basicInfo.referenceId,
            };
          });
      elementList =
        conversationListTemp && conversationListTemp.length > 0
          ? conversationListTemp
          : [
              {
                label: '',
                value: '',
              },
            ];
      setDisplayLabel('Select Content Block');
    } else if (e?.target.value === 'T') {
      const tagListTemp =
        tagList &&
        tagList.length > 0 &&
        tagList.map(tl => {
          return {
            label: tl?.name,
            value: tl?.name,
          };
        });
      elementList =
        tagListTemp && tagListTemp.length > 0
          ? tagListTemp
          : [
              {
                label: '',
                value: '',
              },
            ];
      setDisplayLabel('Select Avatar Tags');
    } else if (e?.target.value === 'STATE') {
      const stateListTemp =
        states &&
        states.length > 0 &&
        states.map(st => {
          return {
            label: st?.state?.name,
            value: st?.state?.name,
          };
        });
      elementList =
        stateListTemp && stateListTemp.length > 0
          ? stateListTemp
          : [
              {
                label: '',
                value: '',
              },
            ];
      setDisplayLabel('Select State');
    } else if (e?.target.value === 'LEVEL_OF_ENGAGEMENT') {
      const lvlOfEngagmentListTemp =
        levelOfEngagements &&
        levelOfEngagements.length > 0 &&
        levelOfEngagements.map(lvl => {
          return {
            label: lvl?.name,
            value: lvl?.id,
          };
        });
      elementList =
        lvlOfEngagmentListTemp && lvlOfEngagmentListTemp.length > 0
          ? lvlOfEngagmentListTemp
          : [
              {
                label: '',
                value: '',
              },
            ];
      setDisplayLabel('Select level of engagement');
    } else if (e?.target.value === 'PROVIDER') {
      const stateListTemp =
        providers &&
        providers.length > 0 &&
        providers.map(prvdr => {
          return {
            label: prvdr?.fullName,
            value: prvdr?.providerId,
          };
        });
      elementList =
        stateListTemp && stateListTemp.length > 0
          ? stateListTemp
          : [
              {
                label: '',
                value: '',
              },
            ];
      setDisplayLabel('Select Provider');
    } else if (e?.target.value === 'INSURANCE') {
      const stateListTemp =
        states &&
        states.length > 0 &&
        states.map(st => {
          return {
            label: st?.state?.name,
            value: st?.state?._id,
          };
        });
      elementList =
        stateListTemp && stateListTemp.length > 0
          ? stateListTemp
          : [
              {
                label: '',
                value: '',
              },
            ];
      setDisplayLabel('Select Insurance state');
    } else {
      elementList =
        profileElementList &&
        profileElementList.map(item => {
          return {
            label: item.profileElementInfo.key,
            value: item.profileElementInfo.key,
          };
        });
      setDisplayLabel('Select Profile Element');
    }
    setFilterProfileElementList(elementList);
  };

  const getContentBlocks = async conversationId => {
    const blocksResponse = await ConversationService.getContentBlocksByConversation(conversationId);
    return (
      blocksResponse.data.contentBlocks?.filter(
        cb =>
          cb.basicInfo.type === BUILDER_CONSTANTS.ContentBlockTypes.SINGLE_SELECT ||
          cb.basicInfo.type === BUILDER_CONSTANTS.ContentBlockTypes.MULTI_SELECT ||
          cb.basicInfo.type === BUILDER_CONSTANTS.ContentBlockTypes.RATING_SCALE ||
          cb.basicInfo.type === BUILDER_CONSTANTS.ContentBlockTypes.TEXT_INPUT,
      ) || []
    );
  };

  const dispalyLogic = async () => {
    if (event && event.name === BUILDER_CONSTANTS.AUTOMATION_RULES.EVENTS.Values.CONVERSATION_COMPLETED) {
      const automationLogicValue = [
        { label: 'If Conversation Variable', value: 'R' },
        { label: 'If Profile Element', value: 'P' },
        // This option is not in use as of now
        // { label: 'If Avatar Tags', value: 'T' },
        { label: 'States', value: 'STATE' },
        { label: 'Level of Engagement', value: 'LEVEL_OF_ENGAGEMENT' },
        { label: 'Provider', value: 'PROVIDER' },
        { label: 'Insurance', value: 'INSURANCE' },
      ];
      setContentBlocks(await getContentBlocks(event.reference));
      setDisplayLogic(automationLogicValue);
    } else {
      setDisplayLogic(automationLogicSelect);
    }
  };

  useEffect(() => {
    if (
      filter.type?.trim() !== '' &&
      filter.rule?.trim() !== '' &&
      filter.value.length &&
      filter.key?.trim() !== ''
    ) {
      void (async () => {
        await onSelectType();
        await onSelectKey();
        await setFieldValue('rule', filter.rule);
        await setFieldValue('value', filter.value);
      })();
    }
  }, []);
  useEffect(() => {
    void onSelectKey();
  }, [states, providers, levelOfEngagements]);
  useEffect(() => {
    void (async () => {
      await dispalyLogic();
    })();
  }, [event && event.name]);

  useEffect(() => {
    setExpandedForm(open || false);
  }, [open]);

  const onDeleteClick = () => {
    setExpandedForm(false);
    onDelete();
  };

  const setTouched = name => async () => {
    await rest.setTouched({ ...touched, [name]: true });
  };

  const renderLabel = str => (
    <Heading level={headingLevel.S} className={classes.label} weight={fontWeight.BOLD}>
      {str}
    </Heading>
  );

  const renderSelect = (label, keyName, opts, emptyText, onChangeHandler) => (
    <Box className={classes.section}>
      {renderLabel(label)}
      <Select
        value={values[keyName]}
        name={keyName}
        variant={selectType.SECONDARY}
        options={opts}
        emptyText={emptyText}
        displayEmpty
        onChange={onChangeHandler ?? handleChange}
      />
      <TextError errorMsg={touched[keyName] ? errors[keyName]?.toString() : null} />
    </Box>
  );
  const handleMultiSelectChange = name => async (_, value) => {
    const hasAll = value.find(val => val.value === 'all');
    if (hasAll) {
      void setFieldValue(name, [{ label: 'Select All', value: 'all' }]);
      return;
    }
    await setFieldValue(name, value);
    await setTouched(name)();
  };
  const renderMultiSelect = (label, keyName, opts, emptyText) => (
    <Box className={classes.section}>
      {renderLabel(label)}
      <Input
        fullWidth
        name={keyName}
        placeholder="Select multiple options"
        size={inputSize.M}
        variant={inputType.TAGS}
        value={values[keyName]}
        // options={[{ label: 'Select All', value: 'all' }, ...opts] || []}
        options={opts || []}
        getOptionLabel={option => option?.label || ''}
        onBlur={setTouched(keyName)}
        emptyText={emptyText}
        onChange={handleMultiSelectChange(keyName)}
      />
      <TextError errorMsg={touched[keyName] ? errors[keyName]?.toString() : null} />
    </Box>
  );

  const renderEndValue = filtr => {
    switch (filtr?.type) {
      case AUTOMATION_FILTERS.PROFILE_ELEMENT:
        return filter?.value || '';
      case AUTOMATION_FILTERS.STATE:
        return filtr.states?.map(stt => stt.title)?.toString();
      case AUTOMATION_FILTERS.LEVEL_OF_ENGAGEMENT:
        return filtr.levelOfEngagements?.map(lvl => lvl.name ?? lvl.title)?.toString();
      case AUTOMATION_FILTERS.INSURANCE:
        return (
          states.find(state => state?.state?.name === filter.key || state?.state?.name === filter.key)?.state
            ?.name || ''
        );
      case AUTOMATION_FILTERS.PROVIDER:
        return filtr.providers?.map(prvdr => prvdr.fullName ?? prvdr.title)?.toString();
      default:
        return '';
    }
  };
  const renderFields = () => {
    switch (values.type) {
      case automationLogicSelect[0].value:
        return (
          <>
            <Box className={classes.section}>
              {renderLabel(displayLabel)}
              <Input
                fullWidth
                name="key"
                placeholder={displayLabel}
                isOptionEqualToValue={(option, value) => option.value === value.value}
                size={inputSize.M}
                variant={inputType.AUTOCOMPLETE}
                value={selectedKey || {}}
                options={filterProfileElementList}
                getOptionLabel={option => option?.label || ''}
                onBlur={setTouched('key')}
                onChange={onSelectKey}
              />
              <TextError errorMsg={touched.key ? errors.key?.toString() : null} />
            </Box>
            {renderSelect('Select rule', 'rule', filterRuleValue, 'Select rule')}
            {renderSelect('Select value', 'value', filterProfileValue, 'Select value')}
          </>
        );
      // This case is not in use at the moment
      // case automationLogicSelect[1].value:
      //   return renderMultiSelect('Select Avatar Tags', 'tags', [], 'Select Avatar Tags');

      case automationLogicSelect[1].value:
        return renderMultiSelect('Select State', 'value', stateOptions, 'Select state');
      case automationLogicSelect[2].value:
        return renderMultiSelect(
          'Select Level of Engagement',
          'value',
          levelOfEngagementOptions,
          'Select Level of Engagement',
        );
      case automationLogicSelect[3].value:
        return renderMultiSelect('Select Provider', 'value', providerOptions, 'Select Provider');
      case automationLogicSelect[4].value:
        return (
          <>
            <Box className={classes.section}>
              {renderLabel(displayLabel)}
              <Input
                fullWidth
                name="key"
                placeholder={displayLabel}
                isOptionEqualToValue={(option, value) => option.value === value.value}
                size={inputSize.M}
                variant={inputType.AUTOCOMPLETE}
                value={selectedKey || {}}
                options={stateOptions}
                getOptionLabel={option => option?.label || ''}
                onBlur={setTouched('key')}
                onChange={(_, keyValue) => {
                  void setFieldValue('value', []);
                  void onSelectKey(_, keyValue);
                }}
              />
              <TextError errorMsg={touched.key ? errors.key?.toString() : null} />
            </Box>
            {isLoadingPayers ? (
              <Icons className="rotate linear infinite" glyph="in-progress" color={colors.primary} />
            ) : (
              renderMultiSelect('Select payers', 'value', payerOptions, 'Select payer')
            )}
          </>
        );

      default:
        return null;
    }
  };
  return (
    <Box>
      <Collapse in={!expandedForm}>
        <AutomationEventItem
          event={{
            startLabel: FilterTypeMap[filter.type],
            startValue: filter.rule ? filter.key : '',
            endLabel: renderEndValue(filter),
            endValue:
              filter.rule !== ''
                ? filter.rule
                : filter.value?.length
                ? filter.value?.map(val => val.label)?.toString()
                : filter.value,
            isDeleteable: true,
            ...filter,
          }}
          onEdit={onEditClick}
          onDelete={onDelete}
        />
      </Collapse>
      <Collapse in={expandedForm}>
        <form className={classes.form} onSubmit={handleSubmit}>
          <Box className={classes.formContent}>
            <Box className={classes.section}>
              {renderSelect('Select filter logic', 'type', displayLogic, 'Select filter logic', onSelectType)}
              <TextError errorMsg={touched.type ? errors.type?.toString() : null} />
            </Box>
            {renderFields()}
          </Box>
          <Box className={classes.footer}>
            <Button onClick={handleSubmit} className={classes.btnFooter}>
              Save
            </Button>
            <IconButton
              className={cx(classes.btnFooter, classes.removeBtn)}
              variant={iconBtnType.TEXT}
              style={iconBtnStyle.SECONDARY}
              onClick={onDeleteClick}
            >
              Delete
            </IconButton>
          </Box>
        </form>
      </Collapse>
    </Box>
  );
};

export { AutomationFilterForm };
