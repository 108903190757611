// colors
import { colors } from '../../../../../packages/colors';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  modal: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalContent: {
    position: 'relative',
    width: 480,
  },
  editIconWrap: {
    border: `8px solid ${colors.white}`,
    borderRadius: '50%',
    width: 72,
    height: 72,
    position: 'absolute',
    top: -36,
    left: 36,
    backgroundColor: colors.secondary100,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  editIcon: {
    '& path': {
      fill: colors.secondary500,
    },
  },
  content: {
    background: colors.white,
    boxShadow:
      '0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
    borderRadius: 8,
    '&:hover $cardLink': {
      display: 'flex',
    },
    padding: '56px 40px 40px',
  },
  closeBtn: {
    position: 'absolute',
    top: 25,
    right: 25,
    cursor: 'pointer',
  },
  title: {
    fontSize: 32,
    color: colors.neutral900,
    fontWeight: 700,
    margin: 0,
    marginBottom: 8,
    fontFamily: 'Source Serif Pro',
  },
  subtitle: {
    fontSize: 16,
    lineHeight: '24px',
    fontWeight: 500,
  },
  label: {
    fontSize: 16,
    fontWeight: 600,
    color: colors.neutral900,
    display: 'block',
    marginTop: 24,
    marginBottom: 8,
  },
  input: {
    height: 56,
  },
  btn: {
    height: 64,
    borderRadius: 8,
    border: `1px solid ${colors.neutral300}`,
    width: '100%',
    marginTop: 40,
  },
  cancelBtn: {
    backgroundColor: colors.white,
    color: colors.neutral900,
  },
  okBtn: {
    backgroundColor: colors.primary500,
    color: colors.white,
    border: 'none',
  },
  loading: {
    marginRight: 8,
  },
});
