import { uploadMedia } from './mediaManager.service';

const uploadMediaFiles = async (file, prefix, onProgress) => {
  try {
    const keyPrefix = prefix + new Date().getTime().toString();

    const formData = new FormData();
    formData.append('file', file);
    formData.append('prefix', keyPrefix);
    formData.append('contentType', file?.type);

    const response = await uploadMedia(formData);
    // Simulate progress since it's handled server-side
    if (onProgress) {
      onProgress({ percent: 1 });
    }
    if (response && response.status === 200) {
      if (response.data instanceof Blob) {
        const blobToJson = await response.data.text();
        response.data = JSON.parse(blobToJson);
      }
      return {
        response: response.data?.postResponse,
        success: true,
      };
    }
    return { success: false };
  } catch (error) {
    console.error('Error uploading media: ', error);
    return { success: false };
  }
};

// Helper methods to upload different types of media
const uploadChatMedia = (file, onProgress) => {
  return uploadMediaFiles(file, 'chatMedia/', onProgress);
};

const uploadExercisesMedia = (file, onProgress) => {
  return uploadMediaFiles(file, 'exercises/', onProgress);
};

const uploadContactNotesMedia = (file, keyPrefix, onProgress) => {
  return uploadMediaFiles(file, keyPrefix, onProgress);
};

const uploadDisplayPicture = (file, onProgress) => {
  return uploadMediaFiles(file, 'displayPics/', onProgress);
};

const uploadChatAvatar = (file, onProgress) => {
  return uploadMediaFiles(file, 'chatBot/', onProgress);
};

const uploadInsuranceImage = (file, onProgress) => {
  return uploadMediaFiles(file, 'idCardImages/', onProgress);
};

// Exporting the functions as a MediaManager module
export const MediaManager = {
  uploadMediaFiles,
  uploadChatMedia,
  uploadExercisesMedia,
  uploadContactNotesMedia,
  uploadDisplayPicture,
  uploadChatAvatar,
  uploadInsuranceImage,
};
