import { btnSize, btnType, Button, colors, Icons } from '../../../../../../packages';
import { Box } from '@mui/material';

import downloadImg from '../../../../../../assets/images/download.png';
import BoxFrame from '../../../../../../components/v2/BoxFrame';
import Heading from '../../../../../../components/v2/Heading';
import { HeadingLevel } from '../../../../../../components/v2/Heading/index.types';
import Typography from '../../../../../../components/v2/Typography';
import { typography } from '../../../../../../components/v2/Typography/index.constant';
import React from 'react';
import { useStyles } from '../../../index.styles';

const ImportFromCAQH = ({ isImportDisabled, onClickImport, isInProgess, importFailed, caqhStepStatus }) => {
  const classes = useStyles({});
  return (
    <BoxFrame
      sx={{
        position: 'relative',
        backgroundClip: 'padding-box',
        zIndex: 1,

        '&:after': {
          position: 'absolute',
          top: -2,
          left: -2,
          right: -2,
          bottom: -2,
          background: isInProgess
            ? '#A4B4C3'
            : importFailed
            ? '#DC2626'
            : 'linear-gradient(274.96deg, #0374DD 9.38%, #DD0374 94.49%)',
          content: '""',
          zIndex: -1,
          borderRadius: 3,
          boxShadow: '0px 0px 1px 0px #0000000A, 0px 0px 2px 0px #0000000F, 0px 4px 8px 0px #0000000A',
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: { lg: 'row', xs: 'column' },
          gap: { lg: 0, xs: 3 },
          justifyContent: 'space-between',
          py: 3,
          paddingLeft: '16px',
          paddingRight: '24px',
          borderRadius: 3,
          background: 'white',
          alignItems: 'center',
        }}
      >
        <Box sx={{ display: 'flex', gap: 1, justifyContent: 'center', alignItems: 'center' }}>
          {importFailed ? (
            <Icons
              glyph="close-circle-outlined"
              className={classes.errorIcon}
              color={colors.destructive600}
              // onClick={onClickEye}
            />
          ) : isInProgess ? (
            <Icons className={classes.inProgressIcon} glyph="server-connection" />
          ) : (
            <img src={downloadImg} alt="downloading" style={{ width: 80, height: 80 }} />
          )}
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            <Heading variant={HeadingLevel.SUB}>
              {isInProgess ? 'Gathering' : 'Import'} information from CAQH {importFailed ? 'failed' : ''}
            </Heading>
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
              <Typography {...typography.body.s.medium} color={colors.neutral700}>
                {isInProgess ? (
                  <>
                    Please note that this process may take some time to complete,
                    <br />
                    depending on the information available on the CAQH portal.
                  </>
                ) : (
                  <>
                    We can connect with your CAQH profile & pull down all of your
                    <br />
                    important information, so you don&apos;t have to copy/paste it.
                  </>
                )}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Button
          variant={btnType.PRIMARY}
          size={btnSize.XSMALL}
          disabled={isImportDisabled}
          onClick={onClickImport}
        >
          <Typography {...typography.body.s.bold}>
            {isInProgess ? 'Please wait...' : !caqhStepStatus ? 'Provide CAQH access' : 'Import from CAQH'}
          </Typography>
        </Button>
      </Box>
    </BoxFrame>
  );
};

export default ImportFromCAQH;
