import { Box } from '@mui/material';
import { Drawer, drawerType } from '../../../packages/ui/organisms/drawer';
import { Badge, badgeStyle, badgeType, colors, Icons } from '../../../packages';
import { UploadFile } from '../../../packages/ui/templates/upload-file';

import { useMemo, useState } from 'react';
import clsx from 'clsx';

import uploadImg from '../../../assets/images/upload.png';
import completeImg from '../../../assets/images/complete.png';
import Typography from '../../../components/v2/Typography';
import { typography } from '../../../components/v2/Typography/index.constant';
import Heading from '../../../components/v2/Heading';
import { HeadingLevel } from '../../../components/v2/Heading/index.types';
import {
  uploadCandidPartFour,
  uploadCandidPartOne,
  uploadCandidPartThree,
  uploadCandidPartTwo,
} from '../../../services/reporting/reporting.service';
import { sentenceCase } from 'sentence-case';

import { useDispatch } from 'react-redux';
import { showSnackbar } from '../../../redux/modules/snackbar';
import { useStyles } from './Claims.styles';

const uploadList = [
  {
    id: 'claims-export',
    icon: 'dollar-outlined',
    title: 'Claims export',
    description: 'Upload the exported claims (xlsx)',
    style: {
      background: colors.success50,
      border: `1px solid ${colors.success200}`,
      color: colors.success500,
    },
  },
  {
    id: 'check-table',
    icon: 'dollar-outlined',
    title: 'Check table',
    description: 'Upload the check table export (xlsx)',
    style: {
      background: colors.secondary50,
      border: `1px solid ${colors.secondary200}`,
      color: colors.secondary500,
    },
  },
  {
    id: 'bank-export',
    icon: 'dollar-outlined',
    title: 'Bank export',
    description: 'Upload the parsed bank export (xlsx)',
    style: {
      background: colors.indigo50,
      border: `1px solid ${colors.indigo200}`,
      color: colors.indigo500,
    },
  },
  {
    id: 'denied-claims',
    icon: 'dollar-outlined',
    title: 'Denied claims',
    description: 'Upload the denied claims (xlsx)',
    style: {
      background: colors.orange50,
      border: `1px solid ${colors.orange200}`,
      color: colors.orange500,
    },
  },
];

const UploadDrawer = ({ open, onClose, setIsFileUpdated }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [step, setStep] = useState(0);
  const [type, setType] = useState(undefined);
  const [isParsing, setIsParsing] = useState(false);
  const [isDone, setIsDone] = useState(false);
  const [submissionSummary, setSubmissionSummary] = useState({});

  const titleLabel = useMemo(() => {
    if (step === 0) return 'What do you want to upload?';
    if (step === 1) return 'Upload a xlsx';
    return '';
  }, [step]);

  const uploadFileHandler = file => {
    switch (type) {
      case 'claims-export':
        uploadCandidPartOne({ file })
          .then(res => {
            setIsDone(true);
            setIsFileUpdated(true);
            setSubmissionSummary(res.data?.data);
          })
          .catch(err => {
            dispatch(
              showSnackbar({
                snackMessage: err.data?.errors?.[0]?.endUserMessage || 'Unable to upload file',
                snackType: 'error',
              }),
            );
          });
        break;
      case 'check-table':
        uploadCandidPartTwo({ file })
          .then(res => {
            setIsDone(true);
            setIsFileUpdated(true);
            setSubmissionSummary(res.data.data);
          })
          .catch(err => {
            dispatch(
              showSnackbar({
                snackMessage: err.data?.errors?.[0]?.endUserMessage || 'Unable to upload file',
                snackType: 'error',
              }),
            );
          });
        break;
      case 'bank-export':
        uploadCandidPartThree({ file })
          .then(res => {
            setIsDone(true);
            setIsFileUpdated(true);
            setSubmissionSummary(res.data.data);
          })
          .catch(err => {
            dispatch(
              showSnackbar({
                snackMessage: err.data?.errors?.[0]?.endUserMessage || 'Unable to upload file',
                snackType: 'error',
              }),
            );
          });
        break;
      case 'denied-claims':
        uploadCandidPartFour({ file })
          .then(res => {
            setIsDone(true);
            setIsFileUpdated(true);
            setSubmissionSummary(res.data.data);
          })
          .catch(err => {
            dispatch(
              showSnackbar({
                snackMessage: err.data?.errors?.[0]?.endUserMessage || 'Unable to upload file',
                snackType: 'error',
              }),
            );
          });
        break;

      default:
        break;
    }
  };
  const onAcceptedFiles = files => {
    setIsParsing(true);
    uploadFileHandler(files[0]);
  };

  const RenderItem = ({ onClick, icon, title, description, style }) => {
    return (
      <Box className={classes.uploadItem} onClick={onClick}>
        <Box
          sx={{
            width: 40,
            height: 40,
            borderRadius: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            ...style,
          }}
        >
          <Icons glyph={icon} color={style.color} />
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
          <Heading variant={HeadingLevel.SUB}>{title}</Heading>
          <Typography {...typography.body.s.semibold} color={colors.neutral500}>
            {description}
          </Typography>
        </Box>
      </Box>
    );
  };

  const renderStepOne = () => (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, p: 3 }}>
      {uploadList.map((item, index) => (
        <RenderItem
          key={index}
          onClick={() => {
            setType(item.id);
            setStep(1);
          }}
          {...item}
        />
      ))}
    </Box>
  );

  const renderStepTwo = () => (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        background: colors.white,
        height: '100%',
      }}
    >
      {/* <Box sx={{ p: 3, display: 'flex', gap: 3, borderBottom: `1px solid ${colors.neutral100}` }}>
        <Box
          sx={{ display: 'flex', gap: 1, alignItems: 'center', cursor: 'pointer', color: colors.primary600 }}
        >
          <Typography {...typography.body.s.bold}>Open guide</Typography>
          <Icons glyph="long-arrow-right" />
        </Box>
        <Box
          sx={{ display: 'flex', gap: 1, alignItems: 'center', cursor: 'pointer', color: colors.primary600 }}
        >
          <Typography {...typography.body.s.bold}>Link</Typography>
          <Icons glyph="long-arrow-right" />
        </Box>
      </Box> */}
      <Box sx={{ p: 5, flex: 1 }}>
        <Heading variant={HeadingLevel.SUB}>Upload a xlsx to analyze</Heading>
        <Typography {...typography.body.s.medium} color={colors.neutral700} sx={{ mt: 1, mb: 4 }}>
          After uploading the xlsx, our Alfie AI will analyze the file and automatically update all of the
          referenced claims in the Confidant Platform.
        </Typography>
        <UploadFile
          onAcceptedFiles={onAcceptedFiles}
          description="xlsx up to 5MB"
          fileType=".xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          iconAltered
        />
      </Box>
      {isParsing && (
        <Box className={classes.waitContainer} sx={{ opacity: 0.9 }}>
          <img src={uploadImg} alt="uploading" />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Typography {...typography.body.s.medium} sx={{ mt: 2 }}>
              Uploading and analyzing your file.
            </Typography>
            <Typography {...typography.body.s.medium}>It usually takes about 15 seconds.</Typography>
          </Box>
        </Box>
      )}
      {isDone && (
        <Box className={classes.waitContainer}>
          <img src={completeImg} alt="completed" />
          <Box
            sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
          >
            <Heading variant={HeadingLevel.SUB}>All done!</Heading>
            <Typography {...typography.body.s.medium} sx={{ mt: 1 }}>
              We have updated{' '}
              {Object.values(submissionSummary)?.length > 0
                ? Object.values(submissionSummary)?.reduce(
                    (pre, cur) => parseInt(pre, 10) + parseInt(cur, 10),
                  )
                : ''}{' '}
              claims.
            </Typography>
            {type === 'claims-export' && (
              <Typography {...typography.body.s.medium}>Here is the breakdown:</Typography>
            )}
          </Box>

          {Object.keys(submissionSummary)?.length > 0 && (
            <>
              {Object.keys(submissionSummary)?.map((key, index) => {
                return (
                  <Box
                    key={`summary-${index}`}
                    sx={{ width: 280, display: 'flex', flexDirection: 'column', mt: 4 }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        borderBottom: `1px solid ${colors.neutral100}`,
                        p: 2,
                      }}
                    >
                      <Badge
                        variant={badgeType.ICON}
                        style={
                          key === 'paid' || key === 'finalized_paid'
                            ? badgeStyle.RESOLVED
                            : key === 'denied'
                            ? badgeStyle.CRITICAL
                            : badgeStyle.MEDIUM
                        }
                        className={clsx(classes.badge)}
                      >
                        {sentenceCase(key)}
                      </Badge>
                      <Typography {...typography.body.s.medium} color={colors.neutral700}>
                        {submissionSummary[key] ?? ''} claims
                      </Typography>
                    </Box>
                  </Box>
                );
              })}
            </>
          )}
        </Box>
      )}
    </Box>
  );

  return (
    <Drawer
      open={open}
      title={titleLabel}
      onClose={() => {
        setType(undefined);
        setStep(0);
        setIsDone(false);
        setIsParsing(false);
        onClose();
      }}
      variant={drawerType.FORM}
      className={classes.drawer}
      footer={<div />}
    >
      {step === 0 && renderStepOne()}
      {step === 1 && renderStepTwo()}
    </Drawer>
  );
};

export { UploadDrawer };
