export const PatchAction = {
  REQUEST_CHANGES: 'REQUEST_CHANGES',
  ACCEPT: 'ACCEPT',
  REJECT: 'REJECT',
  NO_SHOW: 'NO_SHOW',
  ADD_METAINFO: 'ADD_METAINFO',
  COMPLETE_APPOINTMENT: 'COMPLETE_APPOINTMENT',
  ARRIVE_FOR_APPOINTMENT: 'ARRIVE_FOR_APPOINTMENT',
  ADD_SUPERVISOR_NOTES: 'ADD_SUPERVISOR_NOTES',
  ADD_PROVIDER_FEEDBACK: 'ADD_PROVIDER_FEEDBACK',
  PROVIDER_SIGN_OFF: 'PROVIDER_SIGN_OFF',
  START_IN_PERSON: 'START_IN_PERSON',
  RESTART_APPOINTMENT: 'RESTART_APPOINTMENT',
};
