import { FC } from 'react';
import clsx from 'clsx';
import { Box, Modal } from '@mui/material';

import { Heading, Text } from '../../../../../packages/ui/atoms/typography';
import { Icons } from '../../../../../packages/icons';
import { btnType, Button } from '../../../../../packages/ui/atoms/button';
import { colors } from '../../../../../packages/colors';

import { useStyles } from '../../../../../layouts/base/CreateConnectionModal.styles';

const ErrorMessageModal = ({ icon, open, title, content, btnLabel, onClose }) => {
  const classes = useStyles();

  return (
    <Modal open={open} onClose={onClose}>
      <Box className={classes.modal}>
        <Box className={classes.modalContent}>
          {icon && (
            <Box className={classes.editIconWrap}>
              <Icons glyph={icon} className={classes.editIcon} />
            </Box>
          )}
          <Box className={classes.content}>
            <Box>
              <Heading className={classes.title}>{title}</Heading>
              <Text className={classes.subtitle}>{content}</Text>
              <Icons glyph="close" onClick={onClose} className={classes.closeBtn} color={colors.neutral400} />
            </Box>
            <Box display="flex" justifyContent="center" alignItems="center">
              <Button
                className={clsx(classes.modalBtn, classes.cancelBtn)}
                onClick={onClose}
                variant={btnType.OUTLINE}
              >
                {btnLabel}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default ErrorMessageModal;
