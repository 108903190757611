import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Stack } from '@mui/material';
import {
  Badge,
  badgeStyle,
  badgeType,
  btnType,
  fontWeight,
  Heading,
  headingLevel,
  IconButton,
  Menu,
  Table,
} from '../../../packages';
import { BaseLayout } from '../../../layouts/base';
import { conversationActionCreators } from '../../../redux/modules/conversation';
import { selectSessionTypes } from '../../../redux/modules/conversation/selectors';
import { tableColumns } from './SessionTypes.constants';
import { useStyles } from './SessionTypes.styles';
import AddSession from './add-session';

const SessionTypes = () => {
  const classes = useStyles();
  const [showAddNew, setShowAddNew] = useState(false);
  const [selectedSession, setSelectedSession] = useState(null);
  const sessionTypes = useSelector(selectSessionTypes);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(conversationActionCreators.fetchSessionTypes());
  }, []);

  const tableData = useMemo(
    () =>
      sessionTypes?.map((sessionType, index) => ({
        ...sessionType,
        no: String(index + 1),
        actions: { id: sessionType.name },
      })),
    [sessionTypes],
  );

  const toggleAddNew = () => {
    setShowAddNew(!showAddNew);
  };

  const onEditSession = useCallback(
    id => {
      const item = sessionTypes.find(i => i.id === id);
      setSelectedSession(item);
      toggleAddNew();
    },
    [sessionTypes, setSelectedSession, toggleAddNew],
  );

  const onAddSession = useCallback(() => {
    setSelectedSession(null);
    toggleAddNew();
  }, [setSelectedSession, toggleAddNew]);

  const handleDeleteSession = useCallback(
    id => {
      dispatch(conversationActionCreators.deleteSessionType(id));
      setShowAddNew(false);
    },
    [dispatch, conversationActionCreators, setShowAddNew],
  );

  const handleAddSession = useCallback(
    payload => {
      dispatch(conversationActionCreators.createSessionType(payload));
      toggleAddNew();
    },
    [dispatch, conversationActionCreators, toggleAddNew],
  );

  const handleUpdateSession = useCallback(
    payload => {
      dispatch(conversationActionCreators.updateSessionType(payload));
      toggleAddNew();
    },
    [dispatch, conversationActionCreators, toggleAddNew],
  );

  const renderColumns = useCallback(() => {
    return tableColumns.map(column => {
      if (column.id === 'no') {
        return {
          ...column,
          renderCell: val => {
            return (
              <Badge variant={badgeType.OUTLINED} style={badgeStyle.UNRELATED} className={classes.stBadge}>
                {val}
              </Badge>
            );
          },
        };
      }
      if (column.id === 'serviceByTypeCount') {
        return {
          ...column,
          renderCell: serviceByTypeCount => {
            return (
              <Badge variant={badgeType.OUTLINED} style={badgeStyle.UNRELATED} className={classes.stBadge}>
                {serviceByTypeCount}
              </Badge>
            );
          },
        };
      }
      if (column.id === 'actions') {
        return {
          ...column,
          renderCell: ({ id }) => {
            return (
              <Menu
                icon="more"
                className={classes.menu}
                itemsWrapperClassName={classes.menuItemsWrapper}
                items={[
                  { label: 'Edit session', onClick: () => onEditSession(id) },
                  { label: 'Delete session', onClick: () => handleDeleteSession(id) },
                ]}
              />
            );
          },
        };
      }
      return column;
    });
  }, [tableColumns, sessionTypes]);

  return (
    <BaseLayout>
      <AddSession
        isOpen={showAddNew}
        isEdit={!!selectedSession}
        session={selectedSession}
        onClose={toggleAddNew}
        onDeleteSession={handleDeleteSession}
        onAddSession={handleAddSession}
        onUpdateSession={handleUpdateSession}
      />
      <Box className={classes.root}>
        <Stack direction="row" justifyContent="space-between" spacing={2} sx={{ marginBottom: 5 }}>
          <Stack direction="row" alignItems="center" gap={2} sx={{ height: 48 }}>
            <Heading className={classes.heading} level={headingLevel.XL} weight={fontWeight.BOLD}>
              Session type{sessionTypes?.length > 1 ? 's' : ''}
            </Heading>
            <Badge className={classes.totalBadge} variant={badgeType.OUTLINED} style={badgeStyle.UNRELATED}>
              {sessionTypes?.length || 0} total
            </Badge>
          </Stack>
          <IconButton icon="plus" variant={btnType.PRIMARY} className={classes.addBtn} onClick={onAddSession}>
            Add new
          </IconButton>
        </Stack>
        <Table
          gridProps={{
            columns: renderColumns(),
            data: tableData || [],
          }}
          className={classes.table}
        />
      </Box>
    </BaseLayout>
  );
};

export { SessionTypes };
