import { makeStyles } from '@mui/styles';

// colors
import { colors } from '../../../../../../packages';

export const useStyles = makeStyles({
  loader: {
    height: 'calc(100vh - 158px)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  drawer: {
    '& .MuiPaper-root': {
      maxWidth: '720px !important',
    },
    '& .drawerBody': {
      padding: 0,
    },
  },
  drawerContent: {
    flex: 1,
  },
  headerDetail: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 16,
    borderBottom: `1px solid ${colors.neutral100}`,
  },
  headerTitle: {
    fontSize: 18,
    lineHeight: '24px',
    margin: 0,
  },
  headerSubtitle: {
    fontSize: 14,
    lineHeight: '24px',
    fontWeight: 500,
    margin: 0,
    color: colors.neutral600,
  },
  closeIconBtn: {
    width: 40,
    height: 40,
    minWidth: 40,
  },
  content: {
    flex: 1,
    overflowY: 'auto',
    height: 'calc(100vh - 160px)',
    paddingBottom: 40,
    '& td.color-secondary-500': {
      color: colors.secondary500,
    },
  },
  drawerFooter: {
    display: 'flex',
    height: 80,
    borderTop: `1px solid ${colors.neutral100}`,
    justifyContent: 'flex-end',
    padding: 16,
    '& button': {
      height: 48,
    },
  },
  breakdownChart: {
    left: 0,
  },
  recentText: {
    fontSize: 18,
    lineHeight: '24px',
    display: 'block',
    margin: 0,
    fontWeight: 600,
  },
  inputSearch: {
    height: 48,
    padding: 0,
    width: '100%',
    margin: '32px 0',
  },
  donutCharts: {
    display: 'flex',
    padding: 32,
    gap: 32,
    flex: 1,
    paddingTop: 0,
    '&:first-child': {
      paddingTop: 24,
    },
  },
  donut: {
    padding: 24,
    flex: 1,
    width: '100%',
  },
  chartWrap: {},
  chart: {
    padding: 0,
    borderRadius: 0,
    border: 'none !important',
    boxShadow: 'none !important',
    '& .chart-head': {
      padding: '30px 32px 0',
      borderBottom: 'none',
    },
  },
  tableWrap: {
    padding: '0 24px',
  },
  tableBordered: {
    border: `1px solid ${colors.neutral100}`,
    borderBottom: 'none',
    borderRadius: 8,
    overflow: 'hidden',
    '& tr td:last-child': {
      position: 'relative',
      '& button': {
        position: 'absolute',
        right: 10,
        top: 12,
      },
    },
  },
  menu: {
    '& svg': {
      width: 14,
    },
  },
});
