export const AutomationFilterRule = {
  EQUALS: 'equals',
  NOT_EQUALS: 'not-equals',
  GREATER_THAN: 'greater-than',
  LESS_THAN: 'less-than',
  CONTAINS: 'contains',
  NOT_CONTAIN: 'not-contain',
  BEFORE_DATE: 'before-date',
  AFTER_DATE: 'after-date',
};
