import cx from 'clsx';

// components
import { iconBtnType, IconButton } from '../../../molecules/icon-button';

import { Icons } from '../../../../icons';

// styles
import { useStyles } from './TableExport.styles';

const TableExport = ({ className = '', isLoadingExport = false, btnTitle = 'Export', onClick, ...rest }) => {
  const classes = useStyles();

  const renderFilterText = () => {
    return isLoadingExport ? 'Loading' : btnTitle;
  };
  return (
    <>
      <IconButton
        variant={iconBtnType.SECONDARY}
        onClick={onClick}
        disabled={isLoadingExport}
        className={cx(classes.filterBtn, { [classes.filterBtnDisabled]: isLoadingExport })}
      >
        <Icons glyph="export" color="currentColor" />
        {renderFilterText()}
      </IconButton>
    </>
  );
};

export { TableExport };
