import { Box } from '@mui/material';
import { useStyles } from './CheckIDs.styles';
import { Icons } from '../../../../../../icons';
import { Badge, badgeType } from '../../../../../atoms/badge';
import { btnType, Button } from '../../../../../atoms/button';
import { IconButton } from '../../../../../molecules/icon-button';
import FaceIdScan from '../../../../../../icons/lotties/lottieIcons/FaceIdScan';

const CheckIDs = ({ promptsVisible, idCardFront, idCardBack }) => {
  const classes = useStyles();
  const hasID = idCardFront && idCardBack;
  return (
    <Box className={classes.container}>
      <Box className={classes.welcomeBox}>
        <Box className={classes.welcomeFrame}>
          <FaceIdScan className={classes.animatedIcon} />
          <p className={classes.sectionTitle}>
            Double-check their ID
          </p>
          <p className={classes.sectionSubtitle}>
            {hasID ? (
              <>
                We have one on file, but it’s always good to check.
              </>
            ) : (
              <>
                We <span className={classes.danger}>DO NOT</span> have their ID on file, make sure you check it now.
              </>
            )}
          </p>
        </Box>
        {promptsVisible && (
          <Box className={classes.sayBox}>
            <Box className={classes.askContent}>
              <Box className={classes.firstLineLayout}>
                <p className={classes.firstLine}>
                  You may want to <span style={{fontWeight: 700}}>say</span> things like:
                </p>
                <Icons glyph={"chat-outlined"} className={classes.sayIcon}/>
              </Box>
              <p className={classes.instruction}>
                • We may be prescribing you medications and it’s important that we double-check your identity before doing so.
              </p>
            </Box>
          </Box>
        )}
      </Box>
      <Box className={classes.idBox}>
        <Box className={classes.idBoxHeader}>
          <p className={classes.idBoxTitle}>
            Driver’s license or photo ID card
          </p>
          <Badge variant={badgeType.OUTLINED} size={24} className={classes.badge}>
            For you
          </Badge>
        </Box>
        <Box className={classes.idBoxContent}>
          {idCardFront ? (
            <img src={idCardFront} alt={"ID Card Front"} className={classes.cardImage}/>
          ) : (
            <Box className={classes.missingBox}>
              <IconButton icon="profile" variant={btnType.OUTLINE} className={classes.missingBtn}>
                Missing
              </IconButton>
            </Box>
          )}
          {idCardBack ? (
            <img src={idCardBack} alt={"ID Card Back"} className={classes.cardImage}/>
          ) : (
            <Box className={classes.missingBox}>
              <IconButton icon="profile" variant={btnType.OUTLINE} className={classes.missingBtn}>
                Missing
              </IconButton>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
}
export { CheckIDs };
