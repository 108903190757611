import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  Badge,
  badgeStyle,
  badgeType,
  colors,
  fontWeight,
  Heading,
  headingLevel,
  Icons,
} from '../../../../../../packages';
import { Box, Stack } from '@mui/material';
import { getProfile } from '../../../../../../redux/modules/profile/selectors';
import { getEvaluationDctOutcomes } from '../../../../../../services/reporting/reporting.service';
import { getCompareFn } from '../../../../../../utils';
import { useStyles } from '../ScoredOutcomes.styles';
import EmptyState from '../../EmptyState';
import ScoredCard from '../scored-card';
import CompletionDrawer from './completion-drawer';
import ResponseDrawer from './responses-drawer';

const ProviderReported = () => {
  const { memberId } = useParams();
  const [evaluationsDCTOutcomes, setEvaluationsDCTOutcomes] = useState([]);
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const [isCompletionDrawer, setCompletionDrawer] = useState(false);
  const [isResponseDrawer, setResponseDrawer] = useState(false);
  const [selectedCompletion, setSelectedCompletion] = useState(null);
  const { selectedPatient } = useSelector(getProfile);

  const sortByDctName = getCompareFn(x => x.dctName);

  useEffect(() => {
    void (async () => {
      setLoading(true);
      try {
        const {
          data: { data },
        } = await getEvaluationDctOutcomes({ userId: memberId });
        setEvaluationsDCTOutcomes(data.sort(sortByDctName));
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  const onPreviousCompletion = data => {
    setCompletionDrawer(true);
    setSelectedCompletion(data);
  };

  const onEvaluationDcts = data => {
    setResponseDrawer(true);
    setSelectedCompletion(data);
  };

  return (
    <>
      <Stack direction="row" justifyContent="space-between" spacing={2}>
        <Stack direction="row" alignItems="center" gap={2}>
          <Heading className={classes.heading} level={headingLevel.XL} weight={fontWeight.BOLD}>
            Scored outcomes
          </Heading>
          <Badge
            className={classes.totalMemberBadge}
            variant={badgeType.OUTLINED}
            style={badgeStyle.UNRELATED}
          >
            {evaluationsDCTOutcomes.length} total
          </Badge>
        </Stack>
      </Stack>
      {loading ? (
        <Box display="flex" alignItems="center" justifyContent="center">
          <Icons className="rotate linear infinite" glyph="in-progress" color={colors.primary} />
        </Box>
      ) : (
        <>
          {evaluationsDCTOutcomes.length > 0 ? (
            evaluationsDCTOutcomes.map((item, index) => (
              <ScoredCard
                key={index}
                item={item}
                onPreviousCompletion={onPreviousCompletion}
                onEvaluation={onEvaluationDcts}
              />
            ))
          ) : (
            <EmptyState
              title="No scored outcomes yet"
              subtitle={`${selectedPatient?.member?.firstName} doesn’t have scored outcomes yet.`}
            />
          )}
        </>
      )}

      {isCompletionDrawer && (
        <CompletionDrawer
          open={isCompletionDrawer}
          onClose={() => setCompletionDrawer(false)}
          title={selectedCompletion?.dctName}
          params={{
            userId: memberId,
            dctId: selectedCompletion?.dctId,
          }}
        />
      )}
      {isResponseDrawer && (
        <ResponseDrawer
          open={isResponseDrawer}
          onClose={() => setResponseDrawer(false)}
          title={selectedCompletion.dctName}
          params={{
            contextId: selectedCompletion?.currentDCTOutcomeDetail?.contextId,
            dctId: selectedCompletion?.dctId,
          }}
        />
      )}
    </>
  );
};

export default ProviderReported;
