import cx from 'clsx';

// mui
import MuiCheckbox from '@mui/material/Checkbox';

// icons
import { Icons } from '../../../icons';

// colors
import { colors } from '../../../colors';

// types
// styles
import { useStyles } from './Checkbox.styles';

const Checkbox = ({ className = '', disabled = false, color = undefined, ...rest }) => {
  const classes = useStyles();

  return (
    <MuiCheckbox
      className={cx({
        [classes.root]: true,
        [className || '']: className,
      })}
      icon={<Icons className={classes.icon} glyph="checkbox-blank-outlined" color={colors.checkboxBorder} />}
      checkedIcon={
        <Icons className={classes.icon} glyph="checkbox-outlined" color={color || colors.primary} />
      }
      disabled={disabled}
      {...rest}
    />
  );
};

export { Checkbox };
