// colors
import { colors } from '../../../../../packages';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  serviceContainer: {
    padding: 24,
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
  },
  serviceListItem: {
    border: '1px solid',
    borderColor: colors.neutral200,
    padding: 24,
    borderRadius: 8,
    display: 'flex',
    justifyContent: 'space-between',
    cursor: 'pointer',
  },
  serviceNameContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
  },
  serviceName: {
    fontSize: 18,
    lineHeight: '24px',
    fontStyle: 'normal',
  },
  serviceDuration: {
    fontSize: 16,
    lineHeight: '24px',
    fontStyle: 'normal',
    color: colors.neutral600,
  },
  serviceCostContainer: {
    display: 'flex',
    padding: '8px 12px',
    alignItems: 'center',
    gap: 8,
    border: '1px solid',
    borderColor: colors.neutral200,
    background: colors.white,
    borderRadius: 4,
    fontSize: 14,
    color: colors.neutral700,
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '20px',
    height: 36,
  },
  activeService: {
    borderColor: '#B8DDFE',
    background: colors.primary50,
  },
  section: {
    position: 'relative',
    padding: '16px 24px',
  },
  timeSelect: {
    paddingLeft: 26,
  },
  clockIcon: {
    position: 'absolute',
    top: 58,
    left: 36,
  },
  footer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 80,
    padding: 16,
    borderTop: `1px solid ${colors.neutral100}`,
  },
  title: {
    fontSize: 18,
    lineHeight: '24px',
    margin: 0,
  },
  text14: {
    fontSize: 14,
    lineHeight: '20px',
  },
  selectWrap: {
    padding: '16px 32px 24px',
    borderBottom: `1px solid ${colors.neutral100}`,
  },
  tabPanel: {
    // width: 440,
  },
  select: {
    width: '100%',
    '& .MuiInputBase-input': {
      padding: '0px !important',
      margin: '15px',
    },
  },
  label: {
    display: 'block',
    marginBottom: 8,
  },
  tabs: {
    // borderBottom: `1px solid ${colors.neutral100}`,
    '& > div > div': {
      position: 'relative',
      '&:before': {
        content: "''",
        position: 'absolute',
        width: '100%',
        height: 1,
        backgroundColor: colors.neutral100,
        bottom: 0,
      },
    },
    '& button': {
      flex: 1,
      textTransform: 'none',
      paddingTop: 10,
      height: 44,
      color: colors.neutral600,
      fontWeight: 700,
      fontSize: 14,
      lineHeight: '20px',
      '&.Mui-selected': {
        color: colors.neutral900,
      },
    },
    '& .MuiTabs-indicator': {
      height: 1,
      backgroundColor: colors.neutral900,
    },
  },
  day: {
    fontSize: 14,
    lineHeight: '20px',
    color: '#0D0F11',
    marginBottom: 10,
    display: 'block',
  },
  textCenter: {
    textAlign: 'center',
    color: colors.neutral400,
  },
  timeSlots: {
    display: 'flex',
    flexDirection: 'column',
    gap: 24,
    padding: 24,
  },
  timeSlotItems: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 8,
    justifyContent: 'space-between',
  },
  timeSlot: {
    fontSize: 14,
    lineHeight: '20px',
    color: colors.neutral700,
    cursor: 'pointer',
    width: '49%',
    borderRadius: 100,
    border: `1px solid ${colors.neutral200}`,
    textAlign: 'center',
    padding: 4,
    '&:hover, &.timeSlotActive': {
      backgroundColor: colors.primary500,
      color: colors.white,
      borderColor: colors.primary500,
    },
  },
});
