// /* eslint-disable import/no-cycle */
import { useEffect, useState } from 'react';
import debounce from 'debounce';
import cx from 'clsx';

// components
import { Input, inputSize, inputType } from '../../../atoms/input';
import { Chip } from '../../../atoms/chip';
import { IconLink, iconLinkPosition, iconLinkStyle, iconLinkType } from '../../../molecules/icon-link';
import { DROPDOWN_FILTER_COLLAPSABLE_TYPE, TableFilter, tableFilterType } from '../filter';

// styles
import { useStyles } from './TableSearch.styles';

// constants
import { DEBOUNCE_DURATION } from './TableSearch.constants';

// types
// colors
import { colors } from '../../../../colors';
import { TableExport } from '../export';

const TableSearch = ({
  className = '',
  placeholder = '',
  filterProps,
  dateProps = {
    startDate: filterProps?.dateFilter?.startDate,
    endDate: filterProps?.dateFilter?.endDate,
  },
  value = '',
  disabled = false,
  exportProps = null,
  leftSearchComponent = null,
  onClickExport = () => {},
  onChange = v => {},
  resetMultiSelectFilter = undefined,
  ...rest
}) => {
  const classes = useStyles();
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [clearCalendarFilter, setClearCalendarFilter] = useState(false);
  const [multiSelectValue, setMultiSelectValue] = useState(filterProps?.multiSelectFiltersValue || {});
  const onChangeSearchKey = debounce(searchKey => {
    const newValue = { ...value, searchKey };
    onChange(newValue);
  }, DEBOUNCE_DURATION);

  const onClearFilter = () => {
    onChange({ ...value, filter: [], dateFilter: { startDate: '', endDate: '' }, multiSelectFilter: {} });
    setMultiSelectValue({});
    setClearCalendarFilter(true);
  };

  useEffect(() => {
    if (resetMultiSelectFilter) onClearFilter();
  }, [resetMultiSelectFilter]);
  const onChangeDateFilter = (date, isStartDate) => {
    if (clearCalendarFilter) {
      onChange({
        ...value,
        dateFilter: { ...value?.dateFilter, startDate: '', endDate: '' },
      });
      setClearCalendarFilter(false);
      setStartDate('');
      setEndDate('');
      return;
    }
    if (isStartDate) {
      if (endDate) {
        onChange({
          ...value,
          dateFilter: { ...value?.dateFilter, startDate: date, endDate },
        });
      }
      setStartDate(date);
      setClearCalendarFilter(false);
      return;
    }
    if (startDate) {
      onChange({
        ...value,
        dateFilter: { ...value?.dateFilter, startDate, endDate: date },
      });
    }
    setEndDate(date);
    setClearCalendarFilter(false);
  };

  const onChangeFilter = filter => {
    const newValue = { ...value, filter };
    onChange(newValue);
  };
  const onChangeMultiSelectFilter = multiSelectFilter => {
    const newValue = { ...value, multiSelectFilter };
    onChange(newValue);
    setMultiSelectValue(multiSelectFilter);
  };
  const onRemoveFilter = tagOption => {
    let newFilter = null;
    let newMultiselectFilter = null;
    if (filterProps?.variant === tableFilterType.MULTIPLE) {
      newFilter = value?.filter?.filter(option => option !== tagOption);
    }
    if (filterProps?.variant === tableFilterType.MULTIPLE && filterProps.multiSelectOptions?.length > 0) {
      const msFilterKeys = value?.multiSelectFilter ? Object.keys(value?.multiSelectFilter) : [];
      let allMSFilters = {};
      if (msFilterKeys?.length > 0) {
        msFilterKeys?.forEach(item => {
          if (
            item === DROPDOWN_FILTER_COLLAPSABLE_TYPE.PROVIDERS &&
            value?.multiSelectFilter[item]
              ?.map(msItem => {
                return msItem.id;
              })
              ?.includes(tagOption)
          ) {
            const filteredItems = value?.multiSelectFilter[item].filter(option => option?.id !== tagOption);
            if (filteredItems?.length > 0) {
              allMSFilters = { ...allMSFilters, [item]: filteredItems };
            }
            return;
          }
          if (value?.multiSelectFilter[item].includes(tagOption)) {
            const filteredItems = value?.multiSelectFilter[item].filter(option => option !== tagOption);
            if (filteredItems?.length > 0) {
              allMSFilters = { ...allMSFilters, [item]: filteredItems };
            }
            return;
          }
          allMSFilters = { ...allMSFilters, [item]: value?.multiSelectFilter[item] };
        });
      }
      newMultiselectFilter = allMSFilters;
      setMultiSelectValue(allMSFilters);
    }

    const multiselectFiltersChanged = () => {
      if (!value?.multiSelectFilter && Object.keys(newMultiselectFilter)?.length === 0) {
        return true;
      }
      return JSON.stringify(newMultiselectFilter) === JSON.stringify(value?.multiSelectFilter);
    };
    if (JSON.stringify(newFilter) === JSON.stringify(value?.filter) && multiselectFiltersChanged()) {
      onChange({
        ...value,
        filter: newFilter,
        multiSelectFilter: newMultiselectFilter,
        dateFilter: { startDate: '', endDate: '' },
      });
      setClearCalendarFilter(true);
      return;
    }
    onChange({ ...value, filter: newFilter, multiSelectFilter: newMultiselectFilter });
  };

  const getFilterTagOptions = () => {
    return filterProps?.options?.filter(
      option => value?.filter === option?.value || value?.filter?.includes?.(option?.value),
    );
  };

  const filterTags = getFilterTagOptions() || [];
  if (value?.dateFilter?.startDate && value?.dateFilter?.endDate) {
    const dateFilterTag = `${value?.dateFilter?.startDate} - ${value?.dateFilter?.endDate}`;
    filterTags?.push({ value: dateFilterTag, label: dateFilterTag });
  }
  if (value?.multiSelectFilter) {
    const keys = Object.keys(value.multiSelectFilter);
    if (keys.length > 0) {
      keys.forEach(item => {
        value.multiSelectFilter[item]?.forEach(opt => {
          if (DROPDOWN_FILTER_COLLAPSABLE_TYPE.PROVIDERS === item) {
            filterTags?.push({ label: opt?.name, value: opt?.id });
            return;
          }
          filterTags?.push({ label: opt, value: opt });
        });
      });
    }
  }
  return (
    <div
      className={cx({
        [classes.root]: true,
        [className || '']: className,
      })}
      {...rest}
    >
      <div className={classes.top}>
        {leftSearchComponent}
        <Input
          className={cx(classes.input, { [classes.inputDisabled]: disabled })}
          variant={inputType.SEARCH}
          placeholder={placeholder}
          onChange={onChangeSearchKey}
          disabled={disabled}
          value={value?.searchKey || ''}
          size={inputSize.S}
          iconcolor={colors.neutral400}
        />
        {filterProps?.dateFilter && (
          <Input
            className={cx(classes.inputDate, { [classes.inputDisabled]: disabled })}
            variant={inputType.CALENDARFILTER}
            onChange={onChangeDateFilter}
            disabled={disabled}
            value={value?.dateFilter || dateProps}
            iconcolor={colors.neutral400}
            clearCalendar={clearCalendarFilter}
            endDateExists={filterProps.endDateExists}
          />
        )}
        {filterProps && (
          <TableFilter
            value={value?.filter}
            onChange={onChangeFilter}
            onChangeMultiSelectFilter={onChangeMultiSelectFilter}
            multiSelectFiltersValue={multiSelectValue}
            filterTags={filterTags}
            {...filterProps}
          />
        )}
        {exportProps && (
          <TableExport
            isLoadingExport={exportProps.isLoadingExport}
            btnTitle={exportProps.btnTitle}
            onClick={onClickExport}
          />
        )}
      </div>
      {filterTags?.length > 0 && (
        <div className={classes.bottom}>
          <div className={classes.tags}>
            {filterTags?.map((tag, idx) => (
              <Chip key={idx} onDelete={() => onRemoveFilter(tag?.value)}>
                {tag?.label}
              </Chip>
            ))}
          </div>
          <IconLink
            className={classes.clearBtn}
            variant={iconLinkType.CALLBACK}
            style={iconLinkStyle.PRIMARY}
            icon="clear-circle"
            iconPosition={iconLinkPosition.LEFT}
            onClick={onClearFilter}
          >
            Clear all filters
          </IconLink>
        </div>
      )}
    </div>
  );
};

export { TableSearch };
