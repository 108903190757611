import { Alert, Snackbar } from '@mui/material';

function ToastMessage({
  show,
  snackType,
  snackMessage,
  clearSnackbarAction,
  vertical = 'bottom',
  horizontal = 'right',
  duration = 5000,
}) {
  return (
    <Snackbar
      open={show}
      autoHideDuration={duration}
      onClose={clearSnackbarAction}
      anchorOrigin={{ vertical, horizontal }}
      id="snackbar"
    >
      <div>
        <Alert onClose={clearSnackbarAction} severity={snackType}>
          {snackMessage}
        </Alert>
      </div>
    </Snackbar>
  );
}

export { ToastMessage };
