/* eslint-disable max-len */
import cx from 'clsx';

// types
// styles
import { useStyles } from './shared/styles';

const Intelligent = ({ className, color, onClick }) => {
  const classes = useStyles();
  return (
    <svg
      className={cx({
        [classes.root]: true,
        [className || '']: className,
      })}
      onClick={onClick}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.6667 2.66663C20.0907 2.66663 24.568 6.71463 25.2453 11.9533L28.2453 16.672C28.4427 16.9826 28.4027 17.4453 27.9453 17.6426L25.3333 18.76V22.6666C25.3333 24.14 24.14 25.3333 22.6667 25.3333H20.0013L20 29.3333H8V24.408C8 22.8346 7.41867 21.3453 6.34133 20.0013C4.876 18.1746 4 15.856 4 13.3333C4 7.44263 8.776 2.66663 14.6667 2.66663ZM14.6667 5.33329C10.248 5.33329 6.66667 8.91463 6.66667 13.3333C6.66667 15.18 7.29067 16.924 8.42133 18.3333C9.88 20.152 10.6667 22.2226 10.6667 24.408V26.6666H17.3333L17.336 22.6666H22.6667V17.0026L24.7333 16.1173L22.676 12.884L22.6 12.2946C22.088 8.33463 18.6987 5.33329 14.6667 5.33329ZM13.96 10.3506L14.6667 11.0573L15.3733 10.3506C16.2853 9.43863 17.7627 9.43863 18.6733 10.3506C19.5853 11.2613 19.5853 12.7386 18.6733 13.6493L14.6667 17.6573L10.66 13.6493C9.748 12.7386 9.748 11.2613 10.66 10.3506C11.5707 9.43863 13.048 9.43863 13.96 10.3506Z"
        fill={color}
      />
    </svg>
  );
};

export default Intelligent;
