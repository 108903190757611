import { Drawer, drawerPosition, drawerType } from './../../../../../packages/ui/organisms/drawer';
import Box from '@mui/material/Box';
import { fontWeight, Text, textLevel } from './../../../../../packages/ui/atoms/typography';
import { Select, selectType } from './../../../../../packages/ui/atoms/select';
import { FormControlLabel } from './../../../../../packages/ui/atoms/form-control-label';
import { Checkbox } from './../../../../../packages/ui/atoms/checkbox';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getProfile } from '../../../../../redux/modules/profile/selectors';
import { useStyles } from './Appointments.styles';
import { profileActionCreators } from '../../../../../redux/modules/profile';
import { RESTRICTION_REASONS } from './Appointments.constants';

const BookingRestrictionDrawer = ({ open, onClose, memberId }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { demographicDetails } = useSelector(getProfile);
  const [bookingRestricted, setBookingRestricted] = useState(demographicDetails?.bookingRestricted || false);
  const [restrictionReason, setRestrictionReason] = useState(
    demographicDetails?.bookingRestrictionReason || '',
  );

  useEffect(() => {
    if (open && demographicDetails) {
      setBookingRestricted(demographicDetails.bookingRestricted);
      setRestrictionReason(demographicDetails.bookingRestrictionReason || '');
    }
  }, [demographicDetails, open]);
  const handleSubmit = () => {
    dispatch(
      profileActionCreators.updateDemographicDetails({
        demographicDetails: {
          ...demographicDetails,
          bookingRestricted,
          bookingRestrictionReason: bookingRestricted ? restrictionReason : '',
        },
        memberId,
      }),
    );
    onClose();
  };

  return (
    <Drawer
      variant={drawerType.FORM}
      position={drawerPosition.RIGHT}
      open={open}
      onClose={onClose}
      disableSubmit={bookingRestricted && restrictionReason === ''}
      title="Edit Booking Status"
      submitBtnTitle="Save"
      onSubmit={handleSubmit}
    >
      <Box className={classes.inputsContainer}>
        <Box className={classes.inputWrapper}>
          <FormControlLabel
            className={classes.checkboxField}
            control={<Checkbox />}
            checked={bookingRestricted}
            name="bookingRestricted"
            onChange={() => {
              setBookingRestricted(v => !v);
            }}
            label="Booking Restricted"
          />
        </Box>
        {bookingRestricted && (
          <Box className={classes.inputWrapper}>
            <Text className="label" level={textLevel.S} weight={fontWeight.BOLD}>
              <span>Reason for restriction</span>
            </Text>
            <Select
              variant={selectType.SECONDARY}
              value={restrictionReason}
              onChange={e => {
                setRestrictionReason(e.target.value);
              }}
              options={RESTRICTION_REASONS.map(reason => ({ label: reason, value: reason }))}
            />
          </Box>
        )}
      </Box>
    </Drawer>
  );
};

export { BookingRestrictionDrawer };
