export const tableFilterType = {
  SINGLE: 'SINGLE',
  MULTIPLE: 'MULTIPLE',
};

export const FILTER_COLLAPSABLE_SECTION_TYPE = {
  DEFAULT: 'DEFAULT',
  RADIO: 'RADIO',
  DROPDOWN: 'DROPDOWN',
  TOGGLE: 'TOGGLE',
};

export const DROPDOWN_FILTER_COLLAPSABLE_TYPE = {
  PROVIDERS: 'Specific providers',
  SERVICES: 'Specific services',
};
