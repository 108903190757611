import { FC } from 'react';
import { Box } from '@mui/system';
import { Modal } from '@mui/material';
import { fontWeight, Heading, Text, textLevel } from '../../../../packages/ui/atoms/typography';
import { Icons } from '../../../../packages/icons';
import { colors } from '../../../../packages/colors';
import { btnType, Button } from '../../../../packages/ui/atoms/button';
import { useStyles } from './SessionSignOffV2.styles';

const SkipReviewModal = ({ open, onClose, onContinue }) => {
  const classes = useStyles();
  return (
    <Modal open={open} onClose={onClose}>
      <Box className={classes.modal}>
        <Box className={classes.modalContainer}>
          <Box className={classes.modalContent}>
            <Box>
              <Heading className={classes.modalTitle}>
                Continue without reviewing AI selections in evaluations?
              </Heading>
              <Text level={textLevel.M} weight={fontWeight.MEDIUM} color={colors.neutral600}>
                AI generated answers that have not been reviewed <strong>ARE NOT</strong> saved to the patient
                chart.
              </Text>
              <Icons glyph="close" onClick={onClose} className={classes.closeBtn} color={colors.neutral400} />
            </Box>
            <Box className={classes.modalButtons}>
              <Button onClick={onClose} className={classes.modalCloseButton}>
                Cancel
              </Button>
              <Button variant={btnType.PRIMARY} onClick={onContinue} className={classes.modalButton}>
                Continue
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
export { SkipReviewModal };
