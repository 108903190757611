import * as React from 'react';
import { createStyles, withStyles } from '@mui/styles';
import { Box, Collapse } from '@mui/material';
import { Link, linkType } from '../../packages';
import { iconBtnType, IconButton } from '../../packages';

const styles = () =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      height: '100vh',
      padding: 250,
    },
    alfieImage: {
      height: 100,
      width: 100,
    },
    errorText: {
      fontWeight: 700,
      fontFamily: 'Manrope',
      margin: '10px 0px !important',
      textAlign: 'center',
    },
    toggleStack: {
      margin: '10px 0px !important',
      fontFamily: 'Manrope',
    },
    errorStack: {
      fontFamily: 'Manrope',
      textAlign: 'left',
      paddingLeft: '25px',
    },
    btn: {
      // color: colors.white,
    },
  });
class ConfidantErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, errorMessage: '', errorStack: null, expanded: false, copied: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, errorMessage: error.message };
  }

  componentDidCatch(error, info) {
    this.setState({ errorMessage: error.message, errorStack: info.componentStack });
  }

  render() {
    const { hasError, errorMessage, errorStack, expanded, copied } = this.state;
    const { children, classes } = this.props;
    if (hasError) {
      return (
        <Box className={classes.container}>
          <Box>
            <img src="https://i.imgur.com/Tgbdv8K.png" alt="Alfie logo" className={classes.alfieImage} />
          </Box>
          <Box className={classes.errorText}>
            Something went wrong. Application is unable to recover from crash
          </Box>
          <Collapse in={expanded}>
            <Box className={classes.errorText}>{JSON.stringify(errorMessage)}</Box>
            <Box className={classes.errorStack}>
              {errorStack?.split('\n').map((stackLine, idx) => (
                <Box key={idx}>{stackLine}</Box>
              ))}
            </Box>
          </Collapse>
          <Link
            variant={linkType.CALLBACK}
            className={classes.toggleStack}
            onClick={e => {
              e.preventDefault();
              this.setState({ expanded: !expanded });
            }}
          >
            {expanded ? 'Hide' : 'Show'} diagnostic information
          </Link>
          <Box display="flex" gap="16px" my="10px">
            <IconButton
              variant={iconBtnType.OUTLINE}
              className={classes.btn}
              onClick={() => {
                void navigator.clipboard.writeText(`${errorMessage}\n${errorStack}`);
                this.setState({ copied: true });
                setTimeout(() => {
                  this.setState({ copied: false });
                }, 2000);
              }}
            >
              {copied ? 'Copied' : 'Copy Crash Info'}
            </IconButton>
            <IconButton
              variant={iconBtnType.OUTLINE}
              className={classes.btn}
              onClick={() => {
                window.location.reload();
              }}
            >
              Reload Page
            </IconButton>
          </Box>
        </Box>
      );
    }
    return children;
  }
}
export default withStyles(styles, { withTheme: false })(ConfidantErrorBoundary);
