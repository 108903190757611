import { useCallback, useEffect, useState } from 'react';
import { getDomainLookups, getPatientHistory } from '../services/member/member.service';

const useHistoryPatient = ({ patientId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [error, setError] = useState(null);
  const [lastUpdated, setLastUpdated] = useState(null);

  const getLookupValue = (lookItems = [], value) => {
    const item = lookItems.find(i => i.name === value);
    return item?.value || '-';
  };

  const fetchData = useCallback(async () => {
    const query = { patientId };
    setIsLoading(true);
    const tempItems = [];
    try {
      const [patientRes, domainLookupRes] = await Promise.all([
        getPatientHistory(query),
        getDomainLookups(query),
      ]);
      const historyData = patientRes.data.tagItems[0]?.metaData.historyInfo;
      const lookupData = domainLookupRes.data.lookupMap;
      tempItems.push({
        name: 'Sex assigned',
        value: getLookupValue(lookupData?.sex, historyData?.sexAssigned),
      });
      tempItems.push({
        name: 'Gender identity',
        value: getLookupValue(lookupData?.genderIdentity, historyData?.genderIdentity),
      });
      tempItems.push({
        name: 'Gender pronoun',
        value: getLookupValue(lookupData?.genderPronoun, historyData?.genderPronoun),
      });
      tempItems.push({
        name: 'Providers',
        value: 'Previously seen a provider',
        checked: historyData?.previouslySeenProvider,
      });
      tempItems.push({
        name: 'Diagnoses',
        value: 'Previously diagnosed',
        checked: historyData?.previouslyDiagnosed,
        child: historyData?.previouslyDiagnosedMentalHealthConditions,
      });
      tempItems.push({
        name: 'Overdoses',
        value: 'Previously had overdoses',
        checked: historyData?.previousOverDose,
      });
      tempItems.push({
        name: 'Suicide attempts',
        value: 'Previous suicide attempts',
        checked: historyData?.previousSuicideAttempt,
      });
      tempItems.push({
        name: 'Previous conditions',
        value: 'Has previously diagnosed conditions',
        checked: historyData?.previouslyDiagnosedMedicalConditions,
        child: historyData?.previouslyDiagnosedMedicalConditions,
      });
      tempItems.push({
        name: 'Current conditions',
        value: 'Current conditions',
        checked: historyData?.medicalConditionsCurrentlyTreatedFor.length === 0,
        child: historyData?.medicalConditionsCurrentlyTreatedFor,
      });
      tempItems.push({
        name: 'Family conditions',
        value: 'Has family conditions',
        checked: historyData?.familyMentalHealthConditions.length === 0,
        child: historyData?.familyMentalHealthConditions,
      });
      tempItems.push({
        name: 'Substance use treatment',
        value: 'Previously received substance use treatment',
        checked: historyData?.previouslyReceivedSubstanceUseTreatment,
        child: historyData?.familyMentalHealthConditions,
      });
      tempItems.push({
        name: 'Problems with medication',
        value: 'No previous problems with medications',
        checked: !historyData?.previousProblemsWithMedication,
        child: [historyData?.previousProblemsWithMedication],
      });
      tempItems.push({
        name: 'Allergies',
        value: 'No allergies diagnosed',
        checked: historyData?.allergies.length === 0,
        child: historyData?.allergies,
      });
      setItems(tempItems);
      setLastUpdated(patientRes.data.tagItems[0]?.assignedAt);
    } catch (err) {
      setError(err?.data?.error);
    }
    setIsLoading(false);
  }, [patientId]);

  useEffect(() => {
    if (patientId) {
      void fetchData();
    }
  }, [patientId]);

  return {
    isLoading,
    items,
    error,
    lastUpdated,
  };
};

export { useHistoryPatient };
