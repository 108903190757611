import { useEffect, useState } from 'react';
import cx from 'clsx';

// mui
import Collapse from '@mui/material/Collapse';

// icons
import { Icons } from '../../../icons';

// colors
import { colors } from '../../../colors';

// types
import { IiconPosition } from './Collapsable.types';

// styles
import { useStyles } from './Collapsable.styles';
import { Tooltip } from '../tooltip';
import { IconButton } from '../../molecules/icon-button';

const Collapsable = ({
  className = '',
  label = undefined,
  iconPosition = IiconPosition.LEFT,
  children,
  active = false,
  open = false,
  headerHidden = false,
  iconHidden = false,
  iconColor = colors.neutral400,
  labelExtend = null,
  subLabel = null,
  action = null,
  tooltip = null,
  onChange = null,
  isDCT = false,
  onDelete = undefined,
  onEdit = undefined,
  ...rest
}) => {
  const [collapsed, setCollapsed] = useState(open || false);
  const classes = useStyles({ collapsed, active });

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
    if (onChange) {
      onChange(!collapsed);
    }
  };

  useEffect(() => {
    setCollapsed(open || false);
  }, [open]);

  return (
    <div className={cx(classes.root, className)}>
      <div className={classes.container}>
        {!headerHidden && (
          // <Tooltip title={tooltip} placement="top-start">
          <div className={cx(classes.header, 'collapsableHeader')} onClick={toggleCollapsed}>
            {!iconHidden && iconPosition === IiconPosition.LEFT && (
              <Icons
                className={cx(classes.chevronIcon, 'collapsableIcon')}
                glyph="chevron-down"
                color={iconColor}
              />
            )}
            <div className={cx(classes.flexRow, classes.flex1)}>
              <span className={classes.label} id="collapseLabel">
                {tooltip ? (
                  <div className={classes.labelIcon}>
                    {label}
                    <Tooltip title={tooltip} placement="top-end">
                      <span className={classes.labelIcon}>
                        <Icons color={colors.primary300} className={classes.infoIcon} glyph="info-outlined" />
                      </span>
                    </Tooltip>
                  </div>
                ) : (
                  label
                )}
                <div className={classes.subLabel}>{subLabel && subLabel}</div>
              </span>
              {labelExtend}
            </div>
            {!iconHidden && iconPosition === IiconPosition.RIGHT && (
              <div className={classes.flexRow}>
                <Icons
                  className={cx(classes.chevronIcon, 'collapsable-icon')}
                  glyph="chevron-down"
                  color={iconColor}
                />
                {action}
              </div>
            )}
          </div>
          // </Tooltip>
        )}
        {isDCT && (
          <div id="btnDiv" className={classes.btnDiv}>
            <IconButton icon="delete-outlined-2" className={classes.dctDeleteBtn} onClick={onDelete} />
            <IconButton icon="edit-outlined" className={classes.dctEditBtn} onClick={onEdit}>
              Edit DCT
            </IconButton>
          </div>
        )}
      </div>
      <Collapse in={collapsed} {...rest}>
        {children}
      </Collapse>
    </div>
  );
};

export { Collapsable };
