import Box from '@mui/material/Box';

import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import cx from 'clsx';

import {
  btnType,
  colors,
  fontWeight,
  Heading,
  headingLevel,
  iconBtnPosition,
  iconBtnType,
  IconButton,
  Icons,
  RadioGroup,
  radioGroupType,
} from '../../../../packages';
import { NoteCard } from '../../../../packages/ui/templates/note-card';
import { CONTACT_NOTES_FLAGS } from '../../../../packages/constants/CommonConstants';
import { Drawer, drawerType } from '../../../../packages/ui/organisms/drawer';

import { getAuth } from '../../../../redux/modules/auth/selectors';
import { getProfile } from '../../../../redux/modules/profile/selectors';
import { addMemberNotes, getMemberNotes } from '../../../../services/member/member.service';
import { CONTACT_NOTES, NoteIcons } from '../../../../constants/CommonConstants';

import AddNoteForm from '../../member-detail/components/Notes/AddNoteDrawer/AddNoteForm';

import { radioOptions } from './SessionSignOffV2.constant';
import { useStyles } from './SessionSignOffV2.styles';
import { MediaManager } from '../../../../services/mediaManager/mediaManager';

const labNote = {
  icon: 'lab-bottle-outlined',
  flag: CONTACT_NOTES_FLAGS.LAB_REQUEST,
  title: 'Lab request note',
  subTitle: 'Document lab requests and upload related documents',
};

const releaseNote = {
  icon: 'info-outlined',
  flag: CONTACT_NOTES_FLAGS.RELEASE_OF_INFORMATION,
  title: 'Release of information note',
  subTitle: 'Document ROI requests and and upload signed releases',
};

const Labs = ({ onBack, onNext, appointment, preFill, template }) => {
  const classes = useStyles();

  const { meta } = useSelector(getAuth);
  const { profile } = useSelector(getProfile);

  const [isLabNotes, setIsLabNotes] = useState(preFill?.isLabNotes || 'no');
  const [isRequestNotes, setIsRequestNotes] = useState(preFill?.isRequestNotes || 'no');
  const [loading, setLoading] = useState(false);
  const [labNotes, setLabNotes] = useState([]);
  const [requestNotes, setRequestNotes] = useState([]);
  const [openAddNote, setOpenAddNote] = useState(false);
  const [selectedNote, setSelectedNote] = useState(labNote);
  const [isSaving, setIsSaving] = useState(false);

  const fetchNotes = async () => {
    setLoading(true);
    try {
      const { data } = await getMemberNotes({ userId: appointment?.participantId });
      setLabNotes(data.patientContactNotes?.filter(note => note.flag === 'LAB_REQUEST') || []);
      setRequestNotes(data.patientContactNotes?.filter(note => note.flag === 'RELEASE_OF_INFORMATION') || []);
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (appointment?.participantId) {
      void fetchNotes();
    }
  }, []);

  const isDisabled = useMemo(() => {
    return (
      (labNotes.length === 0 && isLabNotes === 'no') ||
      (requestNotes.length === 0 && isRequestNotes === 'no') ||
      openAddNote
    );
  }, [labNotes, isLabNotes, requestNotes, isRequestNotes, openAddNote]);

  useEffect(() => {
    const handleKeyPress = event => {
      if (event.key === 'Enter' && !isDisabled) {
        onNextClick();
      }
    };
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [isDisabled]);

  useEffect(() => {
    if (template === 'yes' && !preFill?.isLabNotes && !preFill?.isRequestNotes) {
      setIsLabNotes('yes');
      setIsRequestNotes('yes');
    }
  }, [template]);

  const onNextClick = () => {
    onNext({
      notes: {
        icon: 'lab-bottle-outlined',
        title: 'Labs & releases',
        description: 'added',
        labs: labNotes.length ?? 0,
        releases: requestNotes.length ?? 0,
        isLabNotes,
        isRequestNotes,
      },
    });
  };

  const onSelectLab = e => {
    setIsLabNotes(e.target.value);
  };

  const onSelectRequest = e => {
    setIsRequestNotes(e.target.value);
  };

  const onSubmit = async payload => {
    const flag = selectedNote?.flag;
    const body = {
      ...payload,
      flag,
      attachments: payload.attachments ?? [],
      // description: payload.description,
      name: payload.title,
      notes: payload.description,
      patientId: appointment.participantId,
      userId: meta?.userId,
      providerId: meta?.userId,

      // relationship: payload.relationship,
      type: flag,
      createdBy: profile.fullName || meta.nickName,
    };
    setIsSaving(true);
    try {
      if (payload.attachments.length > 0) {
        const attachments = [];
        const allPromises = [];
        payload.attachments.forEach(({ originFile }) => {
          if (originFile)
            allPromises.push(
              MediaManager.uploadContactNotesMedia(
                originFile,
                `${CONTACT_NOTES}/${appointment.participantId}/`,
                e => console.log(e),
              ),
            );
        });
        const allPromisesResponded = await Promise.all(allPromises);
        allPromisesResponded.forEach(item => {
          if (item.success) {
            attachments.push(item.response.location);
          }
        });
        if (attachments.length > 0) {
          body.attachments = payload?.attachments?.map((attachmnt, indx) => {
            return {
              ...attachmnt,
              attachmentUrl: attachments[indx],
              attachmentSize: attachmnt?.attachmentSize?.replace('B', ''),
            };
          });
        }
      }

      await addMemberNotes(body);
      setOpenAddNote(false);
      await fetchNotes();
    } catch (error) {
      console.log('error', error);
    }
    setIsSaving(false);
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" flex={1}>
        <Icons className="rotate linear infinite" glyph="in-progress" color={colors.primary} />
      </Box>
    );
  }

  const renderHeader = () => {
    return (
      <div className={classes.modalHeader}>
        <IconButton
          icon="chevron-left"
          variant={btnType.OUTLINE}
          onClick={() => setOpenAddNote(false)}
          className={classes.modalBackBtn}
        />
        <Heading level={headingLevel.M} className={classes.headerTitle} weight={fontWeight.BOLD}>
          {`Add ${selectedNote?.title.toLocaleLowerCase()}`}
        </Heading>
      </div>
    );
  };

  return (
    <>
      <Box className={classes.wrapper}>
        <Box display="flex" flexDirection="column" gap={5} sx={{ height: 'calc(100vh - 257px)' }}>
          <Box className={classes.contentWrapper}>
            <Box className={classes.head}>
              <Box className={classes.contentHeader}>
                <Icons glyph="lab-bottle-outlined" color={colors.neutral700} />
                <Box className={classes.contentHeaderTitle}>Lab Notes</Box>
              </Box>
              <IconButton
                icon="plus"
                onClick={() => {
                  setSelectedNote(labNote);
                  setOpenAddNote(true);
                }}
              >
                Add new
              </IconButton>
            </Box>
            <Box className={classes.content}>
              {labNotes.length > 0 ? (
                labNotes.map((note, index) => (
                  <NoteCard
                    key={index}
                    className={classes.noteCard}
                    note={{
                      ...note,
                      memberNickName:
                        appointment?.participantName ||
                        `${appointment?.patientFirstName} ${appointment?.patientLastName}`,
                      memberFirstName: appointment?.patientFirstName,
                      memberLastName: appointment?.patientLastName,
                      memberProfileImage: appointment?.participantImage,
                    }}
                    icon={NoteIcons[note.flag]}
                    onClick={() => null}
                    isProvider
                  />
                ))
              ) : (
                <Box className={classes.empty}>
                  <Box className="title">No Labs Requested</Box>
                  <Box className="content">
                    If you would like to request a lab test for this member, enter your request here. Results
                    will be uploaded to the member’s chart when they are received.
                  </Box>
                  <IconButton
                    icon="plus"
                    variant={iconBtnType.OUTLINE}
                    onClick={() => {
                      setSelectedNote(labNote);
                      setOpenAddNote(true);
                    }}
                  >
                    New lab note
                  </IconButton>
                </Box>
              )}
            </Box>
            {labNotes.length === 0 && (
              <>
                <Box className={classes.question}>Do you want to continue without adding Lab notes?</Box>
                <RadioGroup
                  name="radio-buttons-group"
                  options={radioOptions}
                  value={isLabNotes}
                  onChange={onSelectLab}
                  variant={radioGroupType.HORIZONTAL}
                />
              </>
            )}
          </Box>

          <Box className={classes.contentWrapper}>
            <Box className={classes.head}>
              <Box className={classes.contentHeader}>
                <Icons glyph="info-outlined" color={colors.neutral700} />
                <Box className={classes.contentHeaderTitle}>Release of Information Type</Box>
              </Box>
              <IconButton
                icon="plus"
                onClick={() => {
                  setSelectedNote(releaseNote);
                  setOpenAddNote(true);
                }}
              >
                Add new
              </IconButton>
            </Box>
            <Box className={classes.content}>
              {requestNotes.length > 0 ? (
                requestNotes.map((note, index) => (
                  <NoteCard
                    key={index}
                    className={classes.noteCard}
                    note={{
                      ...note,
                      memberNickName:
                        appointment?.participantName ||
                        `${appointment?.patientFirstName} ${appointment?.patientLastName}`,
                      memberFirstName: appointment?.patientFirstName,
                      memberLastName: appointment?.patientLastName,
                    }}
                    icon={NoteIcons[note.flag]}
                    onClick={() => null}
                    isProvider
                  />
                ))
              ) : (
                <Box className={classes.empty}>
                  <Box className="title">No Releases Requested</Box>
                  <Box className="content">
                    If a release of information is needed to support care coordination or include past patient
                    records, complete the request here. Completed ROIs will be uploaded to the member’s chart.
                  </Box>
                  <IconButton
                    icon="plus"
                    variant={iconBtnType.OUTLINE}
                    onClick={() => {
                      setSelectedNote(releaseNote);
                      setOpenAddNote(true);
                    }}
                  >
                    New release note
                  </IconButton>
                </Box>
              )}
            </Box>
            {requestNotes.length === 0 && (
              <Box paddingBottom={4}>
                <Box className={classes.question}>
                  Do you want to continue without adding Release of Information Type?
                </Box>
                <RadioGroup
                  name="radio-buttons-group"
                  options={radioOptions}
                  value={isRequestNotes}
                  onChange={onSelectRequest}
                  variant={radioGroupType.HORIZONTAL}
                />
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      <Box className={classes.footer}>
        <IconButton
          icon="chevron-left"
          iconPosition={iconBtnPosition.LEFT}
          className={classes.previousBtn}
          onClick={onBack}
          variant={iconBtnType.TEXT}
        >
          Previous
        </IconButton>
        <IconButton
          icon="chevron-right"
          iconPosition={iconBtnPosition.RIGHT}
          className={classes.nextBtn}
          onClick={onNextClick}
          disabled={isDisabled}
        >
          Next
        </IconButton>
      </Box>

      <Drawer
        title="Add note"
        open={openAddNote}
        onClose={() => setOpenAddNote(false)}
        variant={drawerType.FORM}
        className={cx(classes.drawer, { [classes.bodyWhite]: !!selectedNote })}
        footer={<div />}
        submitBtnTitle="Add note"
        header={selectedNote ? renderHeader() : null}
      >
        <AddNoteForm
          onSubmit={onSubmit}
          onCancel={() => setOpenAddNote(false)}
          noteCategory={selectedNote}
          note={null}
          isSaving={isSaving}
        />
      </Drawer>
    </>
  );
};

export default Labs;
