import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import { useFormik } from 'formik';

import { Drawer, drawerType } from '../../../../../packages/ui/organisms/drawer';
import {
  btnType,
  Button,
  colors,
  fontWeight,
  Heading,
  headingLevel,
  IconButton,
  Icons,
  Input,
  inputSize,
  Select,
  selectType,
  Text,
  TextError,
} from '../../../../../packages';
import { TagSchema } from './AddTag.schema';
import { TAGS } from './AddTag.constants';
import { useStyles } from './AddTag.styles';

const defaultValues = {
  name: '',
  type: '',
  color: colors.primary500,
  importance: '',
  assignmentMethod: '',
};
const AddTag = ({ tag, isOpen, isEditMode, onSubmit, onClose }) => {
  const classes = useStyles();

  const { errors, values, handleChange, handleSubmit, touched, setFieldValue, ...rest } = useFormik({
    initialValues: isEditMode ? { ...tag } : { ...defaultValues },
    validationSchema: TagSchema,
    onSubmit: payload => {
      onSubmit(payload);
      onClose();
    },
  });

  useEffect(() => {
    if (!isOpen) {
      rest.handleReset({ ...defaultValues });
      rest.setErrors({});
    }
  }, [isOpen]);

  const setTouched = name => async () => {
    await rest.setTouched({ ...touched, [name]: true });
  };

  /**
   * @Name onChangeColor
   * @description This method is used to change color value
   */
  const onChangeColor = color => async () => {
    await setFieldValue('color', color);
  };

  /**
   * @Name renderLabel
   * @description This method is used to render label
   */
  const renderLabel = label => (
    <Heading level={headingLevel.S} className={classes.label} weight={fontWeight.BOLD}>
      {label}
    </Heading>
  );

  return (
    <Drawer open={isOpen} onClose={onClose} variant={drawerType.NORMAL} className={classes.drawer}>
      <Box className={classes.wrapper}>
        <Box className={classes.header}>
          <Text className={classes.headerTitle}>Add tag</Text>
          <IconButton icon="close" onClick={onClose} className={classes.backBtn} />
        </Box>
        <form className={classes.form} onSubmit={handleSubmit}>
          <Box className={classes.formContent}>
            <Box className={classes.section}>
              {renderLabel('Tag name')}
              <Input
                value={values.name}
                name="name"
                placeholder="Enter a tag name"
                onChange={handleChange}
                size={inputSize.M}
                onBlur={setTouched('name')}
                fullWidth
              />
              <TextError errorMsg={touched.name ? errors.name?.toString() : null} />
            </Box>
            <Box className={classes.section}>
              {renderLabel('Tag type')}
              <Select
                options={TAGS?.TAGS_TYPES}
                value={values.type}
                displayEmpty
                emptyText="Select a type"
                name="type"
                onChange={handleChange}
                variant={selectType.SECONDARY}
                fullWidth
              />
              <TextError errorMsg={touched.type ? errors.type?.toString() : null} />
            </Box>
            <Box className={classes.section}>
              {renderLabel('Tag color')}
              <Box display="flex" gap={2}>
                {[
                  colors.primary500,
                  colors.secondary500,
                  colors.destructive500,
                  colors.success500,
                  colors.purple500,
                  colors.warning500,
                ].map(color => (
                  <Box
                    key={color}
                    sx={{ backgroundColor: color }}
                    className={classes.colorWrap}
                    onClick={onChangeColor(color)}
                  >
                    {color === values.color && <Icons glyph="checkmark" />}
                  </Box>
                ))}
              </Box>
            </Box>
            <Box className={classes.section}>
              {renderLabel('Tag priority')}
              <Select
                options={TAGS?.TAGS_PRIORITY_LEVELS}
                value={values.importance}
                displayEmptyx
                emptyText="Select tag priority"
                name="importance"
                onChange={handleChange}
                variant={selectType.SECONDARY}
                fullWidth
              />
              <TextError errorMsg={touched.importance ? errors.importance?.toString() : null} />
            </Box>
            <Box className={classes.section}>
              {renderLabel('Assignment method')}
              <Select
                options={TAGS?.TAGS_ASSIGNMENT_METHOD}
                value={values.assignmentMethod}
                displayEmpty
                emptyText="Select assignment method"
                name="assignmentMethod"
                onChange={handleChange}
                variant={selectType.SECONDARY}
                fullWidth
              />
              <TextError errorMsg={touched.assignmentMethod ? errors.assignmentMethod?.toString() : null} />
            </Box>
          </Box>
          <Box className={classes.footer}>
            <Button variant={btnType.TEXT} onClick={onClose}>
              Cancel
            </Button>
            <Button onClick={handleSubmit}>{`${isEditMode ? 'Update' : 'Add'} tag`}</Button>
          </Box>
        </form>
      </Box>
    </Drawer>
  );
};

export { AddTag };
