import React from 'react';
import clsx from 'clsx';
import { Box } from '@mui/material';
import dayjs from 'dayjs';

import { Text } from '../../../../../../packages';
import { Badge, badgeStyle, badgeType } from '../../../../../../packages';
import { Avatar, avatarType } from '../../../../../../packages';
import { useStyles } from './LifeEventCard.styles';

const LifeEventBottomCard = ({ item, showBadge, showNotes = true }) => {
  const classes = useStyles();

  const renderContent = () => {
    return (
      <Box flex={1}>
        {showNotes && <Text className={clsx(classes.time, classes.notes)}>{item.notes}</Text>}
      </Box>
    );
  };

  const priority = item.priority || item.importanceLevel;

  return (
    <Box className={classes.borderTop}>
      <Box className={clsx(classes.rowCenter, classes.flexStart)}>
        <Box display="flex" gap="12px" alignItems="center" flex={showBadge ? 1 : 0.3}>
          <Avatar src={item.avatar} size={40} variant={avatarType.CIRCLE} name={item.assignedBy} />
          <Box display="flex" gap="4px" flexDirection="column" justifyContent="flex-start">
            <Text className={classes.username}>By {item.assignedBy}</Text>
            <Text className={classes.time}>{dayjs(item.assignedAt).format('MMMM DD, YYYY')}</Text>
          </Box>
        </Box>
        {showBadge ? (
          <Badge
            variant={badgeType.FILLED}
            className={clsx(classes.badge, classes[`badge${priority.name}`])}
            style={badgeStyle[priority.name] || badgeStyle.UNRELATED}
          >
            {priority.name}
          </Badge>
        ) : (
          <Box
            display="flex"
            alignItems="center"
            className={clsx(classes.interference, {
              // [classes.interferenceResolved]: item.priority.name === DomainPriorities.RESOLVED,
            })}
            flex="0.7"
          >
            {renderContent()}
          </Box>
        )}
      </Box>
      {showBadge && <Box className={classes.bottomBox}>{renderContent()}</Box>}
    </Box>
  );
};

export { LifeEventBottomCard };
