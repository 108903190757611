import { FC, useEffect } from 'react';
import dayjs from 'dayjs';
import { Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import TodoList from './TodosList';
import { TodoType, TodoVariant } from './TodosList/TodoList.constants';
import { getAppointment } from '../../../../redux/modules/appointment/selectors';
import { formatDateAndStatus, getProfileFullName, getDesignation } from '../../../../utils';
import history from '../../../../utils/history';
import { profileActionCreators } from '../../../../redux/modules/profile';
import { getProfile } from '../../../../redux/modules/profile/selectors';
import { getConversation } from '../../../../redux/modules/conversation/selectors';
import { getAuth } from '../../../../redux/modules/auth/selectors';
import { appointmentActionCreators } from '../../../../redux/modules/appointment';
import { SendBirdAction } from '../../../../lib/sendbird';

export const ActiveTodos = () => {
  const { isLoading, appointments: appointmentsState = [] } = useSelector(getAppointment);

  const {
    chats: { channels },
  } = useSelector(getConversation);
  const { meta, isAdmin } = useSelector(getAuth);

  const { todos, profile, notesTodos, todosStats } = useSelector(getProfile);
  const dispatch = useDispatch();

  const fetchChannel = async channelUrl => {
    try {
      const sendbird = SendBirdAction.getInstance();
      const channel = await sendbird.getChannel(channelUrl, false);
      return channel;
    } catch (e) {
      console.log(e);
      return null;
    }
  };

  const handleChatTodo = async id => {
    const todoItem = todos.find(item => item.id === id);
    // if (todoItem.status === true) {
    //   // Completed Todo items should not navigate anywhere.
    //   return;
    // }
    const channelUrl = todoItem.channelId;
    const chatChannel = await fetchChannel(channelUrl);
    const messageId = todoItem.contentIdentifier;
    let tabType = 'providers';
    let chatId = channelUrl;

    if (chatChannel) {
      tabType = chatChannel.isDistinct ? 'providers' : 'groups';
      if (tabType === 'providers') {
        const otherMember = chatChannel.members.find(member => member.userId !== meta.userId);
        chatId = otherMember.userId;
      }
      if (todoItem.sourceSubtitle === 'Care Team' || (isAdmin && meta.authority === 'CARE_NAVIGATOR')) {
        history.push(
          `/${isAdmin ? 'admin' : 'provider'}/chats?userId=${todoItem?.memberId}&type=all&isDirectCall=true`,
        );
      } else {
        history.push(`/${isAdmin ? 'admin' : 'provider'}/chats`, { tabType, chatId, messageId });
      }
    }
  };

  const handleToDoClicked = type => id => {
    if (type === 'notes') {
      const todoItem = notesTodos.find(item => item.id === id);
      // if (todoItem.status === true) {
      //   // Completed Todo items should not navigate anywhere.
      //   return;
      // }
      const noteId = todoItem.contentIdentifier;
      const member = todoItem.memberId;
      history.push(`${isAdmin ? '/admin' : '/provider'}/members/${member}?name=notes`, { noteId });
    }
    if (type === 'chat') {
      void handleChatTodo(id);
    }
    // if (type === 'evaluation') {
    //   const todoItem = evaluationTodos.find(item => item.id === id);
    //   const { memberId, appointmentId, evaluationId, contentIdentifier } = todoItem;
    //   history.push(
    //     `/provider/members/${memberId}/appointments/${appointmentId}/evaluation?evaluationId=${evaluationId}&contentIdentifier=${contentIdentifier}`,
    //   );
    // }
    if (type === 'sign-off') {
      const appointment = appointmentsState.find(item => item.appointmentId === id);
      if (appointment) {
        if (appointment.practitionerId === meta.userId) {
          history.push(`/provider/appointments/current/${appointment.appointmentId}/session-signoff/v2`);
        } else if (profile.signOffRole === 'SUPERVISOR') {
          history.push(
            `/provider/members/${appointment.patientId}/appointments/${appointment.appointmentId}`,
          );
        }
      }
    }
  };

  const handleToDoCheckUnCheck = checked => id => {
    dispatch(
      profileActionCreators.todoCompleted({
        data: {
          status: checked,
          id,
        },
        status: false,
        getAll: true,
      }),
    );
  };

  const payload = type => {
    return {
      assignedTo: isAdmin ? meta.userId : profile.providerId,
      pageSize: 10000,
      pageNumber: 0,
      type,
      status: false,
      refresh: true,
    };
  };

  const fetchAppointments = () => {
    const queryParams = {
      orderBy: 'asc',
      page: 0,
      sortBy: 'startTime',
      type: 'PENDING',
      statuses: 'FULFILLED',
      size: 1000,
    };
    dispatch(
      appointmentActionCreators.fetchAppointments({
        queryParams,
      }),
    );
  };

  useEffect(() => {
    if (!isAdmin) {
      // dispatch(profileActionCreators.fetchEvaluationTodo(payload('EVALUATION')));
      fetchAppointments();
    }
    if (!isAdmin || (isAdmin && meta.authority === 'CARE_NAVIGATOR')) {
      dispatch(profileActionCreators.fetchTodo(payload('CHAT')));
    }
    dispatch(profileActionCreators.fetchNotesTodo(payload('NOTES')));
  }, []);

  const formatNoteType = type => {
    return type
      ?.toLowerCase()
      ?.replace(/_/g, ' ')
      ?.replace(/^\w/, c => c?.toUpperCase());
  };

  return (
    <Box display="flex" flexDirection="column" gap={3}>
      {!isAdmin && (
        <>
          <TodoList
            open
            heading="Appointments to sign off"
            isLoading={isLoading}
            count={todosStats.activeTodoStats?.signOffCount}
            items={appointmentsState.map(item => {
              return {
                text: item.serviceName,
                date: dayjs(item.startTime).format('MM/DD/YYYY - h:mm A'),
                dueDate: formatDateAndStatus(item.endTime, true),
                patientUuid: item.patientUuid,
                profileId: item.participantId,
                assignedByPhoto: item.participantImage,
                assignedByProfilePhotoText:
                  item.patientFirstName && item.patientLastName
                    ? `${item.patientFirstName || ''} ${item.patientLastName || ''}`
                    : item?.participantName || 'No Name',
                assignedByFullName:
                  item.patientFirstName && item.patientLastName
                    ? `${item.patientFirstName || ''} ${item.patientLastName || ''}`
                    : item?.participantName || 'No Name',
                appointmentId: item.appointmentId,
                participantId: item.participantId,
                waitingForAI: item.waitingForAI,
                id: '',
              };
            })}
            emptyTodosIcon="sign-off-todo"
            emptyTodosHeading="You're not actively assigned any sign off appointment"
            emptyTodosText="Once an appointment is assigned to you for sign-off, you will see it here."
            onItemUnchecked={handleToDoCheckUnCheck(false)}
            onItemChecked={handleToDoCheckUnCheck(true)}
            onItemClicked={handleToDoClicked('sign-off')}
            variant={TodoVariant.SECONDARY}
          />
          {/* <TodoList
            open
            heading="Evaluation mentions"
            count={todosStats.activeTodoStats?.evaluationsCount}
            items={evaluationTodos.map(item => {
              return {
                text: item.message,
                dueDate: formatDateAndStatus(item.dueDate),
                profileId: item.assignedBy,
                date: dayjs(item.createdDate).format('MM/DD/YYYY'),
                assignedByPhoto: item.practitionerProfileImage,
                assignedByFullName: getProfileFullName(item),
                assignedByProfilePhotoText: getProfileFullName(item),
                sourcePhoto: item.memberProfileImage,
                sourceFullName: item.memberFullName,
                patientUuid: item.memberUuid,
                checked: item.status,
                id: item.id,
                designation: getDesignation(item),
              };
            })}
            emptyTodosIcon="chat-mentions-todo"
            emptyTodosHeading="You're not actively tagged in any evaluations"
            emptyTodosText="Once someone mentions you in an evaluation comment, you will see it here."
            onItemUnchecked={handleToDoCheckUnCheck(false)}
            onItemChecked={handleToDoCheckUnCheck(true)}
            onItemClicked={handleToDoClicked('evaluation')}
          /> */}
        </>
      )}
      {(!isAdmin || (isAdmin && meta.authority === 'CARE_NAVIGATOR')) && (
        <TodoList
          open
          heading="Chat mentions"
          count={todosStats.activeTodoStats?.chatCount}
          items={todos.map(item => {
            return {
              text: item.message,
              dueDate: formatDateAndStatus(item.dueDate),
              profileId: item.assignedBy,
              date: dayjs(item.createdDate).format('MM/DD/YYYY'),
              assignedByPhoto: item.practitionerProfileImage,
              assignedByFullName: getProfileFullName(item),
              assignedByProfilePhotoText: getProfileFullName(item),
              sourcePhoto: item.sourceProfileImage,
              sourceFullName: item.source,
              sourceSubtitle: item.sourceSubtitle,
              checked: item.status,
              id: item.id,
              designation: getDesignation(item),
            };
          })}
          emptyTodosIcon="chat-mentions-todo"
          emptyTodosHeading="You're not actively tagged in any chats"
          emptyTodosText="Once someone mentions you in a chat room, you will see it here."
          onItemUnchecked={handleToDoCheckUnCheck(false)}
          onItemChecked={handleToDoCheckUnCheck(true)}
          onItemClicked={handleToDoClicked('chat')}
        />
      )}
      <TodoList
        open
        heading="Case notes"
        count={todosStats.activeTodoStats?.notesCount}
        todoType={TodoType.NOTES}
        items={notesTodos.map(item => {
          return {
            text: item.message,
            dueDate: formatDateAndStatus(item.dueDate),
            profileId: item.assignedBy,
            date: dayjs(item.createdDate).format('MM/DD/YYYY'),
            assignedByPhoto: item.practitionerProfileImage,
            assignedByFullName: getProfileFullName(item),
            assignedByProfilePhotoText: getProfileFullName(item),
            sourcePhoto: item.memberProfileImage,
            sourceFullName: item.memberFullName,
            patientUuid: item.memberUuid,
            checked: item.status,
            id: item.id,
            subtext: `${formatNoteType(item.contactNoteType)} note - ${formatNoteType(
              item.contactNoteStatus,
            )}`,
            designation: getDesignation(item),
          };
        })}
        emptyTodosIcon="lottie-case-notes"
        emptyTodosHeading="You're not actively tagged in any notes"
        emptyTodosText="Once someone assigns you a to-do in a case note, you will see it here."
        onItemUnchecked={handleToDoCheckUnCheck(false)}
        onItemChecked={handleToDoCheckUnCheck(true)}
        onItemClicked={handleToDoClicked('notes')}
      />
    </Box>
  );
};
