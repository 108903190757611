import { Box } from '@mui/material';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import { fontWeight, Text } from '../../atoms/typography';
import { Avatar } from '../../atoms/avatar';
import { Badge, badgeStyle } from '../../atoms/badge';
// styles
import { useStyles } from './ProfileInfo.styles';

const ProfileInfo = ({
  type = 'member',
  photo = '',
  nickName = '',
  fullName = '',
  profilePhotoName = '',
  role = '',
  memberId = '',
  showProfilePhotoName = false,
  isProvider = false,
  isChat = false,
  isOnline = false,
  uuid = null,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const handleClick = () => {
    return memberId
      ? navigate(
          type === 'member'
            ? `/${isProvider ? 'provider' : 'admin'}/members/${memberId}?name=dashboard`
            : type === 'provider'
            ? `/${isProvider ? 'provider' : 'admin'}/providers/${memberId}?name=general-information`
            : '',
        )
      : null;
  };

  let profileName = fullName;
  if (type === 'member') {
    profileName = nickName;
  }
  if (fullName === null || fullName === undefined || fullName.trim() === '') {
    profileName = nickName;
  }

  return (
    <Box
      sx={{ cursor: memberId ? 'pointer' : 'default' }}
      display="flex"
      alignItems="center"
      gap="12px"
      onClick={handleClick}
    >
      <Avatar
        variant="CIRCLE"
        src={photo}
        name={type === 'todos' || showProfilePhotoName ? profilePhotoName : profileName}
        size={40}
      />
      {isChat && (
        <Box
          className={classes.onlinePresenceIndicator}
          style={{ backgroundColor: isOnline ? '#51A365' : '#7d7d7d' }}
        />
      )}
      <Box>
        <Text weight={fontWeight.SEMI_BOLD} className={classes.nickName} id="nickName">
          {type === 'provider' ? fullName : nickName} {!!uuid ? `• ${uuid}` : ''}
        </Text>
        {type === 'provider' ? (
          <Badge style={badgeStyle.PRIMARY} className={clsx(classes.badge, role)} variant="FILLED">
            {role}
          </Badge>
        ) : (
          <Text
            weight={fontWeight.MEDIUM}
            className={clsx({
              [classes.fullName]: true,
              [classes.pastDue]: fullName === 'Past due',
              [classes.dueTomorrow]: fullName === 'Due tomorrow',
              [classes.dueToday]: fullName === 'Due today',
            })}
            id="fullName"
          >
            {fullName}
          </Text>
        )}
      </Box>
    </Box>
  );
};

export { ProfileInfo };
