import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Badge, badgeStyle, badgeType, colors, theme } from '../../../../../packages';
import { ProfileInfo } from '../../../../../packages/ui/templates/profile-info';
import ChevronRight from '../../../../../packages/icons/glyphs/ChevronRight';
import Typography from '../../../../../components/v2/Typography';
import { typography } from '../../../../../components/v2/Typography/index.constant';
import { useStyles } from './TodoItem.styles';

const TodoItemAppt = ({
  id,
  text,
  date,
  dueDate,
  sourcePhoto,
  sourceFullName,
  patientUuid,
  onItemClicked,
  waitingForAI,
}) => {
  const classes = useStyles();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box>
      <Box sx={{ cursor: 'pointer' }} p={isTablet ? 2 : 3} display="flex" alignItems="center">
        <Box
          display="flex"
          gap={2}
          flex={1}
          onClick={() => {
            onItemClicked(id);
          }}
          sx={{
            alignItems: {
              md: 'center',
            },
            flexDirection: {
              md: 'row',
              xs: 'column',
            },
          }}
        >
          {!isTablet && (
            <Box width="22%">
              <ProfileInfo
                type="member"
                photo={sourcePhoto}
                nickName={sourceFullName}
                fullName={patientUuid}
                showProfilePhotoName
                profilePhotoName={sourceFullName}
              />
            </Box>
          )}
          <Box
            display="flex"
            flex={1}
            flexDirection="column"
            gap={0.5}
            sx={{
              order: {
                md: 0,
                xs: 1,
              },
            }}
          >
            {waitingForAI && (
              <Badge variant={badgeType.OUTLINED} style={badgeStyle.MISREPORTED} className={classes.badge}>
                Waiting for AI
              </Badge>
            )}
            <Typography {...typography.body.m.medium}>{text}</Typography>
            <Typography {...typography.body.s.medium} color={colors.neutral600}>
              {date}
            </Typography>
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              order: {
                md: 1,
                xs: 0,
              },
            }}
          >
            {isTablet && (
              <ProfileInfo
                type="member"
                photo={sourcePhoto}
                nickName={sourceFullName}
                fullName={patientUuid}
                showProfilePhotoName
                profilePhotoName={sourceFullName}
              />
            )}
            <Box display="flex" justifyContent="end">
              <Box
                className={classes.badge}
                sx={{
                  border: `1px solid ${
                    dueDate?.includes('Past')
                      ? colors.destructive300
                      : dueDate?.includes('No')
                      ? colors.neutral300
                      : dueDate?.includes('Completed')
                      ? colors.success400
                      : colors.primary300
                  }`,
                }}
              >
                <Typography
                  {...typography.body.normal.small.semibold}
                  color={
                    dueDate?.includes('Past')
                      ? colors.destructive600
                      : dueDate?.includes('No')
                      ? colors.neutral600
                      : dueDate?.includes('Completed')
                      ? colors.success600
                      : colors.primary600
                  }
                >
                  {dueDate}
                </Typography>
              </Box>
            </Box>
          </Box>
          {!isTablet && (
            <Box sx={{ order: 2 }}>
              <ChevronRight color="#A4B4C3" />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};
export default TodoItemAppt;
