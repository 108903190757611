import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Grid, useMediaQuery } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { fontWeight, Heading, headingLevel } from '../../../packages';
import { AppointmentEvaluationTopBar } from './evaluation-analysis-topbar/AppointmentEvaluationTopBar';
import { ApointmentEvaluationSubtitles } from '../alfie-evaluation-validation/evaluation-validation-subtitles/ApointmentEvaluationSubtitles';
import { AppointmentEvaluationAudioPlayer } from '../alfie-evaluation-validation/evaluation-validation-audio-player/AppointmentEvaluationAudioPlayer';
import { selectEvaluationContext } from '../../../redux/modules/conversation/selectors';
import { selectAppointmentDetailsById } from '../../../redux/modules/appointment/selectors';
import { updateSessionTranscript } from '../../../services/conversation/conversation.service';
import { conversationActionCreators } from '../../../redux/modules/conversation';
import AIReminderModal from './ai-reminder-modal';
import AlfieAnalysisCollapse from './alifie-analysis-collapse';
import AlfieAnalysisPriorityDetail from './alfie-analysis-priority-detail';
import { priorityListCard } from './AlfieAnalysisPlayground.contstant';
import { useStyles } from './AlfieAnalysisPlayground.styles';

const AlfieAnalysisPlayground = () => {
  const classes = useStyles();

  const [selectedType, setSelectedType] = useState('symptoms');
  const [selectedElement, setSelectedElement] = useState(null);
  const [openAdd, setOpenAdd] = useState(false);
  const [timedBlock, setTimedBlock] = useState(null);
  const [reminderModalOpen, setReminderModalOpen] = useState(false);
  const [sessionDuration, setSessionDuration] = useState(null);
  const [audioTimestamp, setAudioTimestamp] = useState(0);
  const [providerSpeaker, setProviderSpeaker] = useState('spk_0');
  const [oldProviderSpeaker, setOldProviderSpeaker] = useState('spk_0');
  const [transcriptions, setTranscriptions] = useState([]);
  const [oldTranscriptions, setOldTranscriptions] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [audioScrollLocked, setAudioScrollLocked] = useState(false);
  const { appointmentId, evaluationId } = useParams();
  const evaluationContext = useSelector(selectEvaluationContext);
  const appointmentDetails = useSelector(selectAppointmentDetailsById(appointmentId));
  const matches = useMediaQuery('(max-width:920px)');
  const provider = useMemo(
    () => appointmentDetails?.participants.filter(participant => participant.isPractitioner)?.[0],
    [appointmentDetails],
  );
  const member = useMemo(
    () => appointmentDetails?.participants.filter(participant => !participant.isPractitioner)?.[0],
    [appointmentDetails],
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(conversationActionCreators.fetchEvaluationContext({ appointmentId, evaluationId }));
    setReminderModalOpen(true);
  }, []);

  useEffect(() => {
    if (evaluationContext) {
      const { transcriptParts, speaker0 } = evaluationContext.alfieEvaluations;
      if (speaker0 && speaker0.toLowerCase() === 'provider') {
        setProviderSpeaker('spk_0');
      } else {
        setProviderSpeaker('spk_1');
      }
      if (transcriptParts.length > 0) {
        setTranscriptions(transcriptParts);
      }
    }
  }, [evaluationContext]);

  const onInvertSpeakers = () => {
    if (providerSpeaker === 'spk_0') {
      setProviderSpeaker('spk_1');
    } else {
      setProviderSpeaker('spk_0');
    }
  };

  const onChangeTranscriptSpeaker = useCallback(
    (index, isProvider) => {
      const memberSpeaker = providerSpeaker === 'spk_0' ? 'spk_1' : 'spk_0';
      const newTranscriptParts = transcriptions.map((part, idx) => {
        return {
          ...part,
          speaker: index === idx ? (isProvider ? memberSpeaker : providerSpeaker) : part.speaker,
          switched: index === idx ? true : part.switched,
        };
      });
      setTranscriptions([...newTranscriptParts]);
    },
    [transcriptions, oldTranscriptions],
  );

  const onSelectElement = detail => {
    setSelectedElement(detail);
    if (detail) {
      setTimedBlock({ from: detail.timestampFrom, to: detail.timestampTo, id: null });
    }
    setOpenAdd(false);
  };

  let inferredPriorities = evaluationContext?.alfieEvaluations.inferredPriorities;
  if ((!inferredPriorities || inferredPriorities.length === 0) && process.env.REACT_APP_ENV !== 'prod') {
    // TODO: This is dummy data for non-prod environment. Remove this when integration is completed
    inferredPriorities = [];
  }

  return (
    <>
      <AIReminderModal open={reminderModalOpen} onClose={() => setReminderModalOpen(false)} />
      <Box className={classes.root}>
        <AppointmentEvaluationTopBar
          appointment={appointmentDetails}
          sessionDuration={sessionDuration}
          analysisInProgress={evaluationContext?.alfieValidationInProgress}
          onReprocess={() => {
            dispatch(
              conversationActionCreators.reRunAIAnalysis({ appointmentId, evaluationId, mode: 'ANALYSIS' }),
            );
          }}
        />
        {evaluationContext && (
          <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
            <Grid container={!matches} className={classes.container}>
              <Grid item xs={!matches ? 3.5 : 12} className={classes.column}>
                <Box className={classes.column1_1_1}>
                  <Heading level={headingLevel.M} weight={fontWeight.BOLD} className={classes.title}>
                    Priorities
                  </Heading>
                  <KeyboardArrowDownIcon />
                </Box>
                <Box sx={{ height: 'calc(100vh - 162px)', overflowY: 'scroll' }}>
                  {priorityListCard.map((card, index) => {
                    const priorities = [
                      ...new Map(inferredPriorities.map(item => [item.name, item])).values(),
                    ].filter(priority => priority.type === card.title);

                    return (
                      <AlfieAnalysisCollapse
                        cardInfo={card}
                        key={index}
                        selectedType={selectedType}
                        setSelectedType={setSelectedType}
                        selectedElement={selectedElement}
                        setSelectedElement={onSelectElement}
                        cardDetails={priorities}
                      />
                    );
                  })}
                </Box>
              </Grid>
              <Grid item xs={!matches ? 5 : 12}>
                <Grid container className={classes.column2_1_1}>
                  <AppointmentEvaluationAudioPlayer
                    src={evaluationContext?.alfieEvaluations.sessionAudioUrl}
                    onTimeUpdated={timestamp => {
                      setAudioTimestamp(timestamp);
                    }}
                    editMode={editMode}
                    onEditClicked={() => {
                      if (!editMode) {
                        setEditMode(true);
                        setOldTranscriptions([...transcriptions]);
                        setOldProviderSpeaker(providerSpeaker);
                      }
                    }}
                    scrollLocked={audioScrollLocked}
                    onLockChanged={setAudioScrollLocked}
                    onSessionDurationCaptured={duration => {
                      setSessionDuration(duration);
                    }}
                    timedCB={timedBlock}
                    analysisInProgress={evaluationContext?.alfieValidationInProgress}
                  />
                </Grid>
                <ApointmentEvaluationSubtitles
                  audioTimestamp={audioTimestamp}
                  scrollLocked={audioScrollLocked}
                  providerSpeaker={providerSpeaker}
                  parts={transcriptions}
                  member={member}
                  provider={provider}
                  onChangeTranscriptSpeaker={onChangeTranscriptSpeaker}
                  onCancelChanges={() => {
                    setEditMode(false);
                    setProviderSpeaker(oldProviderSpeaker);
                    setTranscriptions([...oldTranscriptions]);
                  }}
                  onSaveChanges={() => {
                    setEditMode(false);
                    setOldProviderSpeaker(providerSpeaker);
                    setOldTranscriptions([...transcriptions]);
                    updateSessionTranscript(appointmentId, {
                      providerSpeaker,
                      transcriptParts: transcriptions,
                    });
                  }}
                  onInvertSpeakers={onInvertSpeakers}
                  editMode={editMode}
                  timedCB={timedBlock}
                  analysisInProgress={evaluationContext?.alfieValidationInProgress}
                />
              </Grid>
              <Grid
                item
                xs={!matches ? 3.5 : 12}
                className={classes.column}
                sx={{ borderLeft: '1px solid #EDF1F5' }}
              >
                <AlfieAnalysisPriorityDetail
                  inferredPriorities={inferredPriorities}
                  type={selectedType}
                  selectedElement={selectedElement}
                  onSelectElement={onSelectElement}
                  openAdd={openAdd}
                  setOpenAdd={setOpenAdd}
                />
              </Grid>
            </Grid>
          </Box>
        )}
      </Box>
    </>
  );
};

export { AlfieAnalysisPlayground };
