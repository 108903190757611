import { Box } from '@mui/material';
import { Icons } from '../../../../packages/icons';
import { colors } from '../../../../packages/colors';
import Heading from '../../../../components/v2/Heading';
import { HeadingLevel } from '../../../../components/v2/Heading/index.types';
import Typography from '../../../../components/v2/Typography';
import { typography } from '../../../../components/v2/Typography/index.constant';
import { useStyles } from '../index.styles';

export const FactorType = {
  SYMPTOM: 'SYMPTOM',
  SUBSTANCE_USE: 'SUBSTANCE_USE',
  SIDE_EFFECT: 'SIDE_EFFECT',
  SOCIAL_DETERMINANT: 'SOCIAL_DETERMINANT',
  LIFE_EVENT: 'LIFE_EVENT',
  DIAGONOSES: 'DIAGONOSES',
  MEDICATIONS: 'MEDICATIONS',
};

const factorColors = {
  SYMPTOM: {
    border: colors.indigo300,
    background: colors.indigo50,
    icon: colors.indigo500,
  },
  SUBSTANCE_USE: {
    border: colors.secondary300,
    background: colors.secondary50,
    icon: colors.secondary500,
  },
  SIDE_EFFECT: {
    border: colors.warning300,
    background: colors.warning50,
    icon: colors.warning500,
  },
  SOCIAL_DETERMINANT: {
    border: colors.green300,
    background: colors.green50,
    icon: colors.green500,
  },
  LIFE_EVENT: {
    border: colors.purple300,
    background: colors.purple50,
    icon: colors.purple500,
  },
};

const FactorItem = ({ icon, title, description, selected, type, onClick }) => {
  const classes = useStyles({});
  return (
    <Box
      p={3}
      display="flex"
      alignItems="center"
      gap={3}
      sx={{
        background: colors.white,
        borderRadius: 2,
        border: selected && `1px solid ${colors.primary500}`,
        cursor: 'pointer',
      }}
      onClick={onClick}
      style={{ cursor: 'pointer' }}
    >
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          minWidth: 64,
          height: 64,
          border: `1px solid ${factorColors[type].border}`,
          borderRadius: 2,
          background: factorColors[type].background,
          '& svg path': {
            fill: factorColors[type].icon,
          },
        }}
      >
        <Icons glyph={icon} className={classes.icon} />
      </Box>
      <Box>
        <Heading variant={HeadingLevel.SUB}>{title}</Heading>
        <Typography {...typography.body.s.medium}>{description}</Typography>
      </Box>
    </Box>
  );
};

export default FactorItem;
