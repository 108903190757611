import { memo } from 'react';
import clsx from 'clsx';
import { Box } from '@mui/material';
import dayjs from 'dayjs';

import {
  Avatar,
  avatarType,
  Badge,
  badgeStyle,
  badgeType,
  iconBtnType,
  IconButton,
  Text,
} from '../../../../../../packages';

import { DomainPriorities } from '../../../../../../redux/modules/profile/types';
import { useStyles } from './DiagnosCard.styles';

const DiagnosBottomCard = memo(({ item, showBadge, isEditing, onClickFullReport }) => {
  const classes = useStyles();

  const onFullReport = () => {
    onClickFullReport(item);
  };

  const priority = item.priority || item.importanceLevel;

  return (
    <Box className={clsx(classes.borderTop, 'border-top')}>
      <Box className={clsx(classes.rowCenter, classes.flexStart)}>
        <Box display="flex" gap="12px" alignItems="center" flex={showBadge ? 1 : 0.4}>
          <Avatar src={item.avatar} size={40} variant={avatarType.CIRCLE} name={item.assignedBy} />
          <Box display="flex" gap="4px" flexDirection="column" justifyContent="flex-start">
            <Text className={classes.username}>By {item.assignedBy}</Text>
            <Text className={classes.time}>{dayjs(item.assignedAt).format('MMMM DD, YYYY')}</Text>
          </Box>
        </Box>

        {showBadge ? (
          <Badge
            variant={badgeType.FILLED}
            className={clsx(classes.badge, {
              [classes.badgeMisreported]: priority?.name === DomainPriorities.MISREPORTED,
            })}
            style={badgeStyle[priority?.name]}
          >
            {priority?.name}
          </Badge>
        ) : (
          <Box
            display="flex"
            alignItems="center"
            className={clsx(classes.interference, {
              // [classes.interferenceResolved]: item.priority.name === DomainPriorities.RESOLVED,
            })}
            flex="0.6"
          >
            <Text className={clsx(classes.time)}>{item.notes}</Text>
          </Box>
        )}
      </Box>
      {showBadge && !isEditing && (
        <>
          <Box className={classes.bottomBox}>
            <Text className={clsx(classes.time)}>{item.notes}</Text>
          </Box>
          {onClickFullReport && (
            <IconButton
              icon="chart-outlined"
              variant={iconBtnType.TEXT}
              className={classes.btn}
              onClick={onFullReport}
            >
              Full report
            </IconButton>
          )}
        </>
      )}
    </Box>
  );
});

DiagnosBottomCard.displayName = 'DiagnosBottomCard';

export { DiagnosBottomCard };
