import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Divider, Paper } from '@mui/material';
import { AppointmentStatus, AppointmentType } from '../../../../../packages/constants/CommonConstants';
import AppointmentDetail from '../../../../../pages/provider/appointments/components/appointment-detail';
import AppointmentCancel from '../../../../../pages/provider/appointments/components/appointment-cancel';
import AppointmentScheduling from '../../../../../pages/provider/appointments/components/appointment-scheduling';

import { appointmentActionCreators } from '../../../../../redux/modules/appointment';
import history from '../../../../../utils/history';

import { DashboardHeading } from '../dashboard-heading';
import { UpcomingItem } from './UpcomingItem';

const Upcoming = ({ upcomingAppointments, onClickViewAllItems }) => {
  const dispatch = useDispatch();
  const [showCancel, setShowCancel] = useState(false);
  const [showReschedule, setShowReschedule] = useState(false);
  const [showAppointmentDetail, setShowAppointmentDetail] = useState(false);
  const [selectedAppointment, setSelectedAppointment] = useState(null);

  const onClickAppointmentAction = id => {
    const appointment = upcomingAppointments.find(item => item.appointmentId === id);
    if (appointment) {
      setShowAppointmentDetail(false);
      setSelectedAppointment(appointment);
    }
  };

  const onAppointmentClick = item => {
    // setShowAppointmentDetail(true);
    // setSelectedAppointment(item);
  };

  const onCloseDetailDrawer = () => {
    setSelectedAppointment(null);
    setShowAppointmentDetail(false);
  };

  const onStartAppointmentClick = appointment => {
    const appointmentType = AppointmentType.CURRENT;
    if (appointment.status === AppointmentStatus.BOOKED) {
      history.push(`/provider/appointments/${appointmentType}/${appointment.appointmentId}/session`);
    }
    if (appointment.status === AppointmentStatus.NEEDS_ACTION) {
      dispatch(
        appointmentActionCreators.acceptAppointment({
          appointmentId: appointment.appointmentId,
          callback: isSucceed => {
            if (isSucceed) {
              // forceRefresh: true
              // fetch the latest record from the database
              // instead of querying an internal map
              // fetchAppointments(true);
            }
          },
        }),
      );
    }
  };

  return (
    <Paper>
      <AppointmentDetail
        open={showAppointmentDetail}
        onClose={onCloseDetailDrawer}
        onCancelClick={() => setShowCancel(!showCancel)}
        onRescheduleClick={() => setShowReschedule(!showReschedule)}
        onAcceptAppointment={onStartAppointmentClick}
        appointment={selectedAppointment}
      />
      <AppointmentScheduling
        open={showReschedule}
        onClose={() => setShowReschedule(!showReschedule)}
        updateAppointment={setSelectedAppointment}
        appointment={selectedAppointment}
      />
      <AppointmentCancel
        open={showCancel}
        onClose={() => setShowCancel(!showCancel)}
        appointment={selectedAppointment}
        updateAppointment={setSelectedAppointment}
      />
      <DashboardHeading title="Current appointments" onClick={onClickViewAllItems} />
      <Divider />
      {upcomingAppointments
        ?.filter(appt => appt?.status !== AppointmentStatus.CANCELLED)
        ?.map(item => (
          <UpcomingItem
            key={item.appointmentId}
            appointment={item}
            onAppointmentClick={() => onAppointmentClick(item)}
            setShowReschedule={() => setShowReschedule(true)}
            setShowCancel={() => setShowCancel(true)}
            onClickAppointmentAction={onClickAppointmentAction}
          />
        ))}
    </Paper>
  );
};

export default Upcoming;
