import { makeStyles } from '@mui/styles';

// colors
import { colors } from '../../../../../packages/colors';

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: 40,
  },
  table: {
    backgroundColor: colors.white,
  },
  link: {
    textDecoration: 'none',
    cursor: 'pointer',
  },
  name: {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '20px',
  },
  iconSize: {
    width: 16,
    height: 16,
  },
  heading: {
    fontFamily: 'Source Serif Pro',
    margin: 0,
  },
  evaluationDates: {
    paddingRight: 5,
  },
  btnGroupIcon: {
    color: colors.neutral700,
    backgroundColor: 'transparent',
    padding: '14px 18px',
    transition: '0s',
    fontSize: 14,
    height: 48,
    border: `1px solid ${colors.neutral200}`,
    '&:hover': {
      color: colors.white,
      backgroundColor: colors.neutral900,
      border: `1px solid ${colors.neutral900}`,
    },
  },
  btnGroupIconActive: {
    color: colors.white,
    border: `1px solid ${colors.neutral900}`,
    backgroundColor: colors.neutral900,
  },
  badge: {
    borderRadius: 4,
    backgroundColor: colors.neutral50,
    color: colors.neutral700,
    border: 'none',
    whiteSpace: 'nowrap',
    fontSize: 12,
    lineHeight: '16px',
    fontWeight: 700,
    padding: '4px 8px',
    minWidth: 'auto',
    '&.P': {
      backgroundColor: colors.primary50,
      color: colors.primary500,
    },
    '&.M': {
      backgroundColor: colors.secondary50,
      color: colors.secondary500,
    },
  },
  badgeActive: {
    backgroundColor: colors.secondary50,
    color: colors.secondary500,
  },
  actBtn: {
    minWidth: 'auto',
    position: 'relative',
    left: 10,
    '@media (max-width: 1440px)': {
      left: 0,
    },
    '& svg': {
      width: 12,
      height: 12,
      '& path': {
        fill: colors.neutral400,
      },
    },
  },
  totalMemberBadge: {
    borderRadius: 99,
    whiteSpace: 'nowrap',
    height: 30,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 12px',
    color: colors.neutral700,
  },
  addProfileBtn: {
    height: 64,
    color: '#FFF',
    whiteSpace: 'nowrap',
    padding: '20px 24px',
    borderRadius: 8,
  },
  loadingWrap: {
    '& svg': {
      marginRight: 12,
    },
  },
  loadMoreBtn: {
    fontSize: 14,
  },
  btnContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 10,
    paddingBottom: 10,
  },
  menu: {
    float: 'right',
    right: 20,
    '& svg': {
      width: 16,
    },
  },
  menuItemsWrapper: {
    '& ul': {
      padding: 0,
    },
    '& ul li': {
      padding: '10px 16px',
    },
  },
  heatmapChart: {
    height: 73,
    padding: '24px 0 24px 24px',
    backgroundColor: colors.white,
    boxShadow:
      '0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04)',
    borderRadius: 8,
    '& div': {
      minHeight: 'unset !important',
    },
    '& .apexcharts-svg': {
      transform: 'translate(0, 0) !important',
    },
    '& .apexcharts-inner.apexcharts-graphical': {
      transform: 'translate(0, 0) !important',
    },
  },
  chatBotCard: {
    background: '#FFF',
    filter:
      // eslint-disable-next-line
      'drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.04)) drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.06)) drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.04))',
    borderRadius: 8,
    '&:hover $actions': {
      display: 'flex',
    },
  },
  chatBotCardHeaderWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingRight: 24,
    alignItems: 'baseline',
  },
  chatBotCardHeaderCompletions: {
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
    padding: '18px 24px',
  },
  chatBotCardHeaderContent: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 16,
  },
  badgeOutlined: {
    height: 28,
    paddingLeft: 10,
    paddingRight: 10,
    borderRadius: 50,
  },
  actions: {
    display: 'none',
    gap: 24,
  },
  careTeamValueWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 12,
    '& svg': {
      minWidth: 20,
    },
  },
  progressWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 24,
    paddingRight: 24,
    paddingBottom: 12,
    gap: 24,
  },
  chatBotProgressText: {
    color: colors.neutral600,
    lineHeight: '16px',
    whiteSpace: 'nowrap',
  },
  drawer: {
    '& .MuiDrawer-paper': {
      maxWidth: 800,
      '& .drawerBody': {
        padding: 0,
      },
    },
  },
  drawerHeader: {
    backgroundColor: colors.neutral25,
    borderBottom: `1px solid ${colors.neutral100}`,
    padding: 40,
  },
  emptyNote: {
    color: colors.neutral300,
    fontSize: 16,
    textAlign: 'center',
  },
  prevButton: {
    paddingLeft: 0,
    marginTop: 10,
  },
  profile: {
    padding: '12px 24px',
  },
  drawerCollapsableAccordion: {
    '& .collapsableHeader': {
      justifyContent: 'flex-start',
      height: 48,
      gap: 8,
    },
    '& .collapsableIcon': {
      margin: 0,
      '& path': {
        fill: colors.neutral900,
      },
    },
  },
  collapsableWrapper: {
    paddingLeft: 12,
    paddingRight: 12,
    position: 'relative',
    '& .collapsableInfoCards': {
      padding: '24px 28px',
      display: 'flex',
      flexDirection: 'column',
      gap: 16,
    },
    '& .collapsableCardScoreText': {
      lineHeight: '48px',
      color: colors.neutral500,
      position: 'absolute',
      right: 12,
      top: 0,
    },
    '& .collapsableInfoCardWrapper': {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: colors.white,
      padding: 24,
      border: '1px solid #e5ebf0',
      borderRadius: 8,
    },
    '& .collapsableInfoCardTitle': {
      color: colors.neutral900,
      lineHeight: '24px',
    },
    '& .collapsableInfoCardSubTitle': {
      color: colors.neutral700,
      lineHeight: '24px',
    },
  },
  collapsableCardsWrapper: {
    padding: 24,
  },
  chatBotAnswerWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '16px 0',
    marginBottom: 16,
    borderBottom: `1px solid ${colors.neutral100}`,
  },
  chatBotPrevHistory: {
    maxWidth: 370,
  },
  chatBotDrawerFooter: {
    borderTop: `1px solid ${colors.neutral100}`,
    padding: '0 24px',
    height: 72,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  drawerCancelBtn: {
    padding: 0,
    color: colors.primary600,
    fontSize: 14,
    fontWeight: 700,
    minWidth: 48,
    height: 48,
  },
  neutral700: { color: colors.neutral700 },
});
