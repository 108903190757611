import { ApiEndpoints } from '../../constants/ApiEndpoints';
import { baseRequest } from '../../utils/HttpClient';

export const fetchCaqhCredentials = pathParams => {
  return baseRequest(ApiEndpoints.FETCH_CAQH_CREDENTIALS, null, pathParams, null);
};

export const postCaqhCredentials = bodyRequest => {
  return baseRequest(ApiEndpoints.POST_CAQH_CREDENTIALS, bodyRequest, null, null);
};

export const caqhDataSummary = pathParams => {
  return baseRequest(ApiEndpoints.CAQH_DATA_SUMMARY, null, pathParams, null);
};

export const extractCredentialingData = pathParams => {
  return baseRequest(ApiEndpoints.EXTRACT_CREDENTIALING_DATA, {}, pathParams, null);
};

export const fetchNavItemStatus = pathParams => {
  return baseRequest(ApiEndpoints.GET_NAV_ITEM_STATUS, {}, pathParams, null);
};

export const putProviderData = (sectionType, bodyRequest) => {
  return baseRequest(ApiEndpoints.PUT_PROVIDER_DATA, bodyRequest, { sectionType }, null);
};

export const uploadDocumentData = bodyRequest => {
  return baseRequest(ApiEndpoints.UPLOAD_DOCUMENT_DATA, bodyRequest, null, null);
};

export const deleteCredentialingDocument = bodyRequest => {
  return baseRequest(ApiEndpoints.DELETE_CREDENTIALING_DOCUMENT, bodyRequest, null, null);
};

export const fetchAvailableStates = pathParams => {
  return baseRequest(ApiEndpoints.GET_AVAILABLE_STATES, null, pathParams, null);
};

export const requestPayerSubmission = (bodyRequest, userAccountId) => {
  return baseRequest(ApiEndpoints.REQUEST_PAYER_SUBMISSION, bodyRequest, { userAccountId }, null);
};
