import { Box, Collapse } from '@mui/material';
import { sentenceCase } from 'sentence-case';

import { fontWeight, Heading, headingLevel, Input, inputSize, inputType } from '../../../../../../packages';
import MyCheckbox from '../../../../../../packages/ui/molecules/my-checkbox';

import ReportTop from '../../report-top';
import { useStyles } from './DataIncludedSection.styles';

const DataIncludedSection = ({
  icon,
  label,
  checkList,
  listChecked,
  setListChecked,
  isIncluded,
  setIsIncluded,
  tagLabel,
  tagOptions,
  tags,
  setTags,
}) => {
  const classes = useStyles();
  const onChangeCheckedType = type => () => {
    const newList = listChecked.includes(type)
      ? listChecked.filter(item => item !== type)
      : [...listChecked, type];
    setListChecked(newList);
  };

  const toggleIncludeData = () => {
    setIsIncluded(!isIncluded);
  };

  const onChangeTags = (_, val) => {
    setTags(val);
  };

  const renderLabel = text => (
    <Heading level={headingLevel.S} className={classes.label} weight={fontWeight.BOLD}>
      {text}
    </Heading>
  );

  return (
    <Box className={classes.card}>
      <ReportTop
        icon={icon}
        label={label}
        isChecked={isIncluded}
        onChange={toggleIncludeData}
        checkList={checkList}
        listChecked={listChecked}
        setListChecked={setListChecked}
      />
      <Collapse in={isIncluded}>
        <Box className={classes.checkboxWrap}>
          {tagOptions?.length ? (
            <Box className={classes.section}>
              {renderLabel(tagLabel)}
              <Input
                value={tags}
                placeholder="Add items"
                name="tags"
                className={classes.valuesInput}
                options={tagOptions}
                onChange={onChangeTags}
                variant={inputType.TAGS}
                size={inputSize.M}
                fullWidth
              />
            </Box>
          ) : null}
          <Box className={classes.section}>
            <Box sx={{ marginBottom: 2 }}>{renderLabel('Visualizations in report:')}</Box>

            {checkList.map(({ title, viewType, type }, idx) => (
              <MyCheckbox
                key={idx}
                label={title}
                sublabel={sentenceCase(viewType || '')}
                isChecked={listChecked.includes(type)}
                onChange={onChangeCheckedType(type)}
              />
            ))}
          </Box>
        </Box>
      </Collapse>
    </Box>
  );
};

export { DataIncludedSection };
