/* eslint-disable max-len */
import cx from 'clsx';

// types
// styles
import { useStyles } from './shared/styles';

const ArrowUpIcon = ({ className, color, onClick }) => {
  const classes = useStyles();
  return (
    <svg
      className={cx({
        [classes.root]: true,
        [className || '']: className,
      })}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M8.66667 5.21863V13.3333H7.33333V5.21863L3.75733 8.79463L2.81467 7.85196L8 2.66663L13.1853 7.85196L12.2427 8.79463L8.66667 5.21863Z"
        fill={color}
      />
    </svg>
  );
};

export default ArrowUpIcon;
