/* eslint-disable react/destructuring-assignment */
import { useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import { useDownloadExcel } from 'table-to-excel-react';

import { colors, fontWeight, IconButton, Text } from '../../../../../packages';
import { Box, Grid, Stack } from '@mui/material';
import { boxType, DashboardBox } from '../../../../../packages/ui/templates/dashboard-box';

import {
  getElkChatbotDctReport,
  getElkEvaluationDctReport,
} from '../../../../../services/reporting/reporting.service';
import { DctType } from '../../../../../redux/modules/reporting/types';
import { DctTable } from './dct-table/DctTable';
import AssignmentsChart from '../assignments-chart';
import HeadCard from '../head-card';

import {
  attemptColumns,
  avgColumns,
  changeColumns,
  DCT_TABLES,
  getAverageCurrentScore,
  getAverageInitialScore,
  getAverageNumberOfCompletions,
  getBoxData,
  getChatbotDctReportQuery,
  getEvaluationDctReportQuery,
  getGreatestChangeInScore,
  getLast30DaysCounts,
  getLeastAttemptedDcts,
  getLeastCompletedDcts,
  getMostAttemptedDcts,
  getMostCompletedDcts,
  getPast30DaysCounts,
  memberColumns,
  scoreColumns,
} from './DataCollectionTemplates.constants';
import { useStyles } from '../profile-elements/ProfileElements.styles';
import { ViewAllDrawer } from './ViewAllDrawer';
import { DCTDetail } from './dct-detail/DCTDetail';

const DataCollectionTemplates = ({ dctType, report, showNewReport, componentRef }) => {
  const [dctReport, setDctReport] = useState(null);
  const [selectedItem, setSelectedItemDetail] = useState(null);
  const classes = useStyles();
  const [showViewAllType, setShowViewAllType] = useState(null);
  const { onDownload } = useDownloadExcel({
    fileName: 'dct_report',
    table: 'table_to_xls',
    sheet: 'sheet 1',
  });
  // const [selectedItem, setSelectedItemDetail] = useState(null);
  const boxData = useMemo(() => getBoxData(dctReport, report, dctType), [dctReport, report, dctType]);
  const last30DaysCounts = useMemo(() => getLast30DaysCounts(dctReport), [dctReport]);
  const past30DaysCounts = useMemo(() => getPast30DaysCounts(dctReport), [dctReport]);
  const mostCompletedData = useMemo(() => getMostCompletedDcts(dctReport), [dctReport]);
  const leastCompletedData = useMemo(() => getLeastCompletedDcts(dctReport), [dctReport]);
  const mostAttemptedData = useMemo(() => getMostAttemptedDcts(dctReport), [dctReport]);
  const leastAttemptedData = useMemo(() => getLeastAttemptedDcts(dctReport), [dctReport]);
  const averageInitialScoreData = useMemo(() => getAverageInitialScore(dctReport), [dctReport]);
  const averageCurrentScoreData = useMemo(() => {
    return getAverageCurrentScore(dctReport);
  }, [dctReport]);
  const greatestChangeInScoreData = useMemo(() => getGreatestChangeInScore(dctReport), [dctReport]);
  const averageNumberOfCompletionsData = useMemo(() => getAverageNumberOfCompletions(dctReport), [dctReport]);
  const dctSection = useMemo(
    () =>
      dctType === DctType.CHATBOT ? report?.data?.chatbotDctSection : report?.data?.evaluationDctSection,
    [dctType, report],
  );

  useEffect(() => {
    !showNewReport &&
      void (async () => {
        const resp = await (dctType === DctType.CHATBOT
          ? getElkChatbotDctReport(getChatbotDctReportQuery(report), null)
          : getElkEvaluationDctReport(getEvaluationDctReportQuery(report), null));
        setDctReport(resp.data);
      })();
  }, [showNewReport]);

  const onCloseViewAll = () => {
    setShowViewAllType(null);
  };

  const onClickAll = type => () => {
    setShowViewAllType(type || showViewAllType);
  };

  // eslint-disable-next-line react/display-name
  const renderMenu = () => () => {
    return <></>;
    // return (
    //   <Menu
    //     icon="more"
    //     className={classes.menu}
    //     items={[
    //       { label: 'Menu Item 1', onClick: onClickMenu(menuTable, action?.id, 'Menu Item 1') },
    //       { label: 'Menu Item 2', onClick: onClickMenu(menuTable, action?.id, 'Menu Item 2') },
    //       { label: 'Menu Item 3', onClick: onClickMenu(menuTable, action?.id, 'Menu Item 3') },
    //     ]}
    //   />
    // );
  };
  const onRowClick = item => {
    setSelectedItemDetail(item);
  };
  const onCloseViewDetail = () => {
    setSelectedItemDetail(null);
  };
  const getContentsInViewAll = () => {
    if (showViewAllType === DCT_TABLES.mostCompletedMembers) {
      return {
        title: 'Most completed',
        subtitle: `${mostCompletedData.length} total`,
        tableColumns: memberColumns,
        data: mostCompletedData.map(item => ({ ...item, type: DCT_TABLES.mostCompletedMembers })),
        onRowClick,
        renderMenuAction: renderMenu(),
      };
    }
    if (showViewAllType === DCT_TABLES.leastCompletedMembers) {
      return {
        title: 'Least completed',
        subtitle: `${leastCompletedData.length} total`,
        tableColumns: memberColumns,
        data: leastCompletedData.map(item => ({ ...item, type: DCT_TABLES.leastCompletedMembers })),
        onRowClick,
        renderMenuAction: renderMenu(),
      };
    }
    if (showViewAllType === DCT_TABLES.mostCompletedAttemps) {
      return {
        title: 'Most attempts',
        subtitle: `${mostAttemptedData.length} total`,
        tableColumns: attemptColumns,
        data: mostAttemptedData.map(item => ({ ...item, type: DCT_TABLES.mostCompletedAttemps })),
        onRowClick,
        renderMenuAction: renderMenu(),
      };
    }
    if (showViewAllType === DCT_TABLES.leastCompletedAttemps) {
      return {
        title: 'Least attempts',
        subtitle: `${leastAttemptedData.length} total`,
        tableColumns: attemptColumns,
        data: leastAttemptedData.map(item => ({ ...item, type: DCT_TABLES.leastCompletedAttemps })),
        onRowClick,
        renderMenuAction: renderMenu(),
      };
    }
    if (showViewAllType === DCT_TABLES.averageInitialScore) {
      return {
        title: 'Average initial score',
        subtitle: `${averageInitialScoreData.length} total`,
        tableColumns: scoreColumns,
        data: averageInitialScoreData.map(item => ({ ...item, type: DCT_TABLES.averageInitialScore })),
        renderMenuAction: renderMenu(),
      };
    }
    if (showViewAllType === DCT_TABLES.averageCurrentScore) {
      return {
        title: 'Average current score',
        subtitle: `${averageCurrentScoreData.length} total`,
        tableColumns: scoreColumns,
        data: averageCurrentScoreData.map(item => ({ ...item, type: DCT_TABLES.averageCurrentScore })),
        renderMenuAction: renderMenu(),
      };
    }
    if (showViewAllType === DCT_TABLES.greatestChangeInScore) {
      return {
        title: 'Greatest change in score',
        subtitle: `${greatestChangeInScoreData.length} total`,
        tableColumns: changeColumns,
        data: greatestChangeInScoreData.map(item => ({ ...item, type: DCT_TABLES.greatestChangeInScore })),
        renderMenuAction: renderMenu(),
      };
    }
    if (showViewAllType === DCT_TABLES.averageNumberOfCompletions) {
      return {
        title: 'Average number of completions',
        subtitle: `${averageNumberOfCompletionsData.length} total`,
        tableColumns: avgColumns,
        data: averageNumberOfCompletionsData.map(item => ({
          ...item,
          type: DCT_TABLES.averageNumberOfCompletions,
        })),
        renderMenuAction: renderMenu(),
      };
    }
    return null;
  };

  const renderTable = (
    title,
    data,
    columns,
    onClickViewAll,
    renderMenuAction,
    className = '',
    type = null,
  ) => (
    <Box className={clsx(classes.tableWrap, className)} flex={1}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Text className={classes.recentTitle} weight={fontWeight.SEMI_BOLD}>
          {title}
        </Text>
        <IconButton onClick={onClickViewAll} className={classes.viewAllBtn}>
          View top ({data.length})
        </IconButton>
      </Stack>
      <DctTable
        className={classes.tableBordered}
        isShowAll={false}
        tableColumns={columns}
        onRowClick={item => type && onRowClick({ ...item, type })}
        data={data}
        renderMenuAction={renderMenuAction}
      />
    </Box>
  );

  const renderAllTables = () => {
    return (
      <div id="table_to_xls" hidden>
        {[
          {
            title: 'Most Completed',
            tableColumns: null,
            data: null,
          },
          {
            tableColumns: memberColumns,
            data: mostCompletedData,
          },
          {
            title: 'Least Completed',
            tableColumns: null,
            data: null,
          },
          {
            tableColumns: memberColumns,
            data: leastCompletedData,
          },
          {
            title: 'Most Attempts',
            tableColumns: null,
            data: null,
          },
          {
            tableColumns: attemptColumns,
            data: mostAttemptedData,
          },
          {
            title: 'Least Attempts',
            tableColumns: null,
            data: null,
          },
          {
            tableColumns: attemptColumns,
            data: leastAttemptedData,
          },
          {
            title: 'Average Initial Score',
            tableColumns: null,
            data: null,
          },
          {
            tableColumns: scoreColumns,
            data: averageInitialScoreData,
          },
          {
            title: 'Average Current Score',
            tableColumns: null,
            data: null,
          },
          {
            tableColumns: scoreColumns,
            data: averageCurrentScoreData,
          },
          {
            title: 'Greatest Change Score',
            tableColumns: null,
            data: null,
          },
          {
            tableColumns: changeColumns,
            data: greatestChangeInScoreData,
          },
          {
            title: 'Average Number of Completions',
            tableColumns: null,
            data: null,
          },
          {
            tableColumns: avgColumns,
            data: averageNumberOfCompletionsData,
          },
        ].map((item, index) => {
          return (
            <table key={`${index}_t`}>
              <thead key={`${index}_th`}>
                <tr key={`${index}_trh`}>
                  {item?.title && <th>{item.title}</th>}
                  {item?.tableColumns?.find(col => col.id === 'member') &&
                    ['Member_fullName', 'Member_Name', 'Member_Id'].map(colItem => (
                      <th key={`${colItem}_thr`}>{colItem}</th>
                    ))}
                  {item?.tableColumns?.find(col => col.id === 'provider') &&
                    ['Provider_Name', 'provider_Role'].map(colItem => (
                      <th key={`${colItem}_thr`}>{colItem}</th>
                    ))}
                  {item?.tableColumns
                    ?.filter(col => !(col.id === 'member' || col.id === 'provider'))
                    .map((column, indx) => {
                      return <th key={`${indx}_thr`}>{column.label}</th>;
                    })}
                </tr>
              </thead>
              <tbody>
                {item?.data?.map((row, ind) => {
                  return (
                    <tr key={`${ind}_tr`}>
                      {item.tableColumns.find(col => col.id === 'member') &&
                        ['fullname', 'name', 'memberId'].map(colItem => (
                          <td key={`${colItem}_thr`}>{row.member[colItem]}</td>
                        ))}
                      {item.tableColumns.find(col => col.id === 'provider') &&
                        ['name', 'role'].map(colItem => (
                          <td key={`${colItem}_thr`}>{row.provider[colItem]}</td>
                        ))}
                      {item.tableColumns
                        .filter(col => !(col.id === 'member' || col.id === 'provider'))
                        .map((column, i) => {
                          return <td key={`${i}_tir`}>{row[column.id]}</td>;
                        })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          );
        })}
      </div>
    );
  };

  return (
    <>
      {renderAllTables()}
      {/* selectedItem */}
      <DCTDetail
        open={!!selectedItem}
        onClose={onCloseViewDetail}
        dctType={dctType}
        dctDetail={selectedItem}
      />
      <ViewAllDrawer isOpen={!!showViewAllType} onClose={onCloseViewAll} {...getContentsInViewAll()} />
      <Box className={classes.card}>
        <HeadCard
          title="Data collection templates"
          componentRef={componentRef}
          onDownloadClick={onDownload}
        />
        <Box className={classes.card}>
          <Box className={classes.reports}>
            <Grid container spacing={2}>
              {boxData.map(box => (
                <Grid item xs={4} key={box.label}>
                  <DashboardBox className={classes.reportItem} {...box} type={boxType.TOTAL} />
                </Grid>
              ))}
            </Grid>
          </Box>
          {dctSection.completedLastThirtyDays?.isChecked && (
            <Box className={classes.chartWrap}>
              <AssignmentsChart
                title="Completed in the last 30 days"
                className={classes.chart}
                chart={{
                  series: [
                    { name: 'Current 30 days', color: colors.success500, data: last30DaysCounts },
                    { name: 'Previous 30 days', color: colors.neutral600, data: past30DaysCounts },
                  ],
                  tooltipConfig: {
                    isShowTotal: false,
                    title: 'Completed',
                  },
                }}
              />
            </Box>
          )}
          <Box display="flex" justifyContent="space-between" gap={6}>
            {dctSection.mostCompleted?.isChecked &&
              renderTable(
                'Most completed',
                mostCompletedData,
                memberColumns,
                onClickAll(DCT_TABLES.mostCompletedMembers),
                renderMenu(),
                !dctSection.leastCompleted?.isChecked ? classes.halfTable : '',
                DCT_TABLES.mostCompletedMembers,
              )}
            {dctSection.leastCompleted?.isChecked &&
              renderTable(
                'Least completed',
                leastCompletedData,
                memberColumns,
                onClickAll(DCT_TABLES.leastCompletedMembers),
                renderMenu(),
                !dctSection.mostCompleted?.isChecked ? classes.halfTable : '',
                DCT_TABLES.leastCompletedMembers,
              )}
          </Box>
          <Box display="flex" justifyContent="space-between" gap={6}>
            {dctSection.mostAttempted?.isChecked &&
              renderTable(
                'Most attempts',
                mostAttemptedData,
                attemptColumns,
                onClickAll(DCT_TABLES.mostCompletedAttemps),
                renderMenu(),
                !dctSection.leastAttempted?.isChecked ? classes.halfTable : '',
                DCT_TABLES.mostCompletedAttemps,
              )}
            {dctSection.leastAttempted?.isChecked &&
              renderTable(
                'Least attempts',
                leastAttemptedData,
                attemptColumns,
                onClickAll(DCT_TABLES.leastCompletedAttemps),
                renderMenu(),
                !dctSection.mostAttempted?.isChecked ? classes.halfTable : '',
                DCT_TABLES.leastCompletedAttemps,
              )}
          </Box>
          {/* <Box display="flex" justifyContent="space-between" gap={6}>
        {renderTable(
          'Highest Avg Initial Score',
          tableListMock,
          scoreColumns,
          onClickAll('highestAvgInitScore'),
          renderMenu('highestAvgInitScore'),
        )}
        {renderTable(
          'Lowest Avg Initial Score',
          tableListMock,
          scoreColumns,
          onClickAll('lowestAvgInitScore'),
          renderMenu('lowestAvgInitScore'),
        )}
      </Box> */}
          <Box display="flex" justifyContent="space-between" gap={6}>
            {dctSection.averageInitialScore?.isChecked &&
              renderTable(
                'Average initial score',
                averageInitialScoreData,
                scoreColumns,
                onClickAll(DCT_TABLES.averageInitialScore),
                renderMenu(),
                !dctSection.averageCurrentScore?.isChecked ? classes.halfTable : '',
              )}
            {dctSection.averageCurrentScore?.isChecked &&
              renderTable(
                'Average currrent score',
                averageCurrentScoreData,
                scoreColumns,
                onClickAll(DCT_TABLES.averageCurrentScore),
                renderMenu(),
                !dctSection.averageInitialScore?.isChecked ? classes.halfTable : '',
              )}
          </Box>
          <Box display="flex" justifyContent="space-between" gap={6}>
            {dctSection.greatestChangeInScore?.isChecked &&
              renderTable(
                'Greatest change in score',
                greatestChangeInScoreData,
                changeColumns,
                onClickAll(DCT_TABLES.greatestChangeInScore),
                renderMenu(),
                !dctSection.averageNumberOfCompletions?.isChecked ? classes.halfTable : '',
              )}
            {dctSection.averageNumberOfCompletions?.isChecked &&
              renderTable(
                'Average number of completions',
                averageNumberOfCompletionsData,
                avgColumns,
                onClickAll(DCT_TABLES.averageNumberOfCompletions),
                renderMenu(),
                !dctSection.greatestChangeInScore?.isChecked ? classes.halfTable : '',
              )}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export { DataCollectionTemplates };
