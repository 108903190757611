import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import dayjs from 'dayjs';
import { tz } from 'moment-timezone';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { getAuth } from '../../../../../redux/modules/auth/selectors';
import {
  colors,
  fontWeight,
  iconBtnType,
  IconButton,
  Icons,
  Select,
  selectType,
  Text,
  TextError,
  textLevel,
} from '../../../../../packages';
import { Drawer, drawerType } from '../../../../../packages/ui/organisms/drawer';
import { AppointmentStatus } from '../../../../../packages/constants/CommonConstants';
import { ProfileInfo } from '../../../../../packages/ui/templates/profile-info';
import { appointmentActionCreators } from '../../../../../redux/modules/appointment';
import { recurringAppointmentTypes } from '../../../../admin/appointments/add-schedule/AddSchedule.constants';
import CreateConnectionModal from '../../../../../layouts/base/CreateConnectionModal';
import { useStyles } from './AppointmentCancel.styles';

const AppointmentCancel = ({
  open,
  markNoShow,
  onClose,
  appointment,
  updateAppointment,
  handleMarkNoShowClick,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { isAdmin } = useSelector(getAuth);

  const [reason, setReason] = React.useState('0');
  const [error, setError] = React.useState('');
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const isRecurringAppointment = recurringAppointmentTypes?.includes(appointment?.recurringType);

  useEffect(() => {
    if (!open) {
      setReason('0');
      setError('');
    }
  }, [open]);

  const onCancel = () => {
    if (reason === '0') {
      setError('Please select a reason');
      return;
    }
    setError(null);
    dispatch(
      appointmentActionCreators.cancelAppointment({
        appointmentId: appointment.appointmentId,
        appointmentCancelParams: { reason },
        callback: errorApi => {
          if (errorApi) {
            setError(errorApi);
          } else {
            updateAppointment({ ...appointment, status: AppointmentStatus.CANCELLED });
            onClose();
          }
        },
      }),
    );
  };

  const onCancelRecurring = () => {
    if (reason === '0') {
      setError('Please select a reason');
      setIsModalOpen(false);
      return;
    }
    setError(null);
    dispatch(
      appointmentActionCreators.cancelRecurringAppointment({
        appointmentId: appointment.appointmentId,
        appointmentCancelParams: { reason },
        callback: errorApi => {
          if (errorApi) {
            setError(errorApi);
          } else {
            updateAppointment({ ...appointment, status: AppointmentStatus.CANCELLED });
            setIsModalOpen(false);
            onClose();
          }
        },
      }),
    );
  };

  const onChangeReason = ({ target }) => {
    if (target.value !== '0' && error) {
      setError('');
    }
    setReason(target.value);
  };

  const renderInfo = (icon, value) => (
    <Box className={classes.boxInfo}>
      <Icons color={colors.neutral600} glyph={icon} />
      <Text weight={fontWeight.SEMI_BOLD} className={clsx(classes.text14, classes.info)}>
        {value}
      </Text>
    </Box>
  );

  return (
    <>
      <Drawer
        open={open}
        onClose={onClose}
        title={markNoShow ? 'Mark As No Show' : 'Cancel appointment'}
        variant={drawerType.FORM}
        footer={<div />}
        className={classes.drawer}
      >
        {!!appointment && (
          <>
            <Box flex={1}>
              <Box className={classes.boxTop}>
                <Box>
                  <Text weight={fontWeight.BOLD} className={classes.title}>
                    {appointment.serviceName}
                  </Text>
                  <Text weight={fontWeight.MEDIUM} className={clsx(classes.text14, classes.duration)}>
                    {appointment?.serviceDuration} minutes session
                  </Text>
                </Box>
                <Box style={{ height: 24 }} />

                <Box display="flex" alignItems="center" justifyContent="space-between">
                  <ProfileInfo
                    type={appointment?.providerRole ? 'provider' : 'member'}
                    photo={appointment?.participantImage}
                    nickName={appointment?.participantName}
                    fullName={
                      appointment?.providerName ||
                      `${appointment?.patientFirstName || ''} ${appointment.patientLastName || ''}`
                    }
                    role={appointment?.providerRole}
                    memberId={
                      appointment?.providerRole
                        ? appointment?.provider?.userAccountId
                        : appointment?.patientId
                    }
                    isProvider={!isAdmin}
                  />
                  <Box sx={{ paddingRight: 6 }}>
                    {renderInfo('event-outlined', dayjs(appointment.startTime).format('MMMM DD, YYYY'))}
                    {renderInfo('clock', `${dayjs(appointment.startTime).format('hh:mma')} ${tz.guess()}`)}
                  </Box>
                </Box>
              </Box>
              {!markNoShow && (
                <Box className={classes.selectWrap}>
                  <Text
                    className={clsx(classes.text14, classes.reason)}
                    level={textLevel.S}
                    weight={fontWeight.BOLD}
                  >
                    Reason {!markNoShow && 'for Cancellation'}
                  </Text>
                  <Select
                    variant={selectType.SECONDARY}
                    value={reason}
                    className={classes.select}
                    onChange={onChangeReason}
                    options={[
                      { label: 'Select a reason', value: '0' },
                      { label: 'Member requested', value: 'Member requested' },
                      {
                        label: 'Member needs higher level of care',
                        value: 'Member needs higher level of care',
                      },
                      {
                        label: 'Member not responding to messages',
                        value: 'Member not responding to messages',
                      },
                      { label: 'Change in provider availability', value: 'Change in provider availability' },
                    ]}
                  />
                  <TextError errorMsg={error} />
                </Box>
              )}
            </Box>
            <Box className={classes.actions}>
              {isRecurringAppointment && (
                <IconButton
                  variant={iconBtnType.PRIMARY}
                  className={clsx(classes.cancelBtn)}
                  onClick={() => setIsModalOpen(true)}
                >
                  Cancel recurring
                </IconButton>
              )}
              <IconButton
                variant={iconBtnType.PRIMARY}
                className={clsx(classes.btn)}
                onClick={markNoShow ? handleMarkNoShowClick : onCancel}
              >
                {markNoShow ? 'Mark as no show' : 'Cancel appointment'}
              </IconButton>
            </Box>
          </>
        )}
      </Drawer>
      <CreateConnectionModal
        icon="x-circle-outlined"
        open={isModalOpen}
        title="Cancel appointments"
        content="Do you want to cancel all recurring appointments?"
        lbtnLabel="Cancel"
        rbtnLabel="Confirm"
        onClose={() => setIsModalOpen(false)}
        onCancel={() => setIsModalOpen(false)}
        onSubmit={onCancelRecurring}
        isLoading={false}
        isDelete
      />
    </>
  );
};

export { AppointmentCancel };
