import React, { useCallback, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { useStyles } from './SectionDetail.styles';
import { Box } from '@mui/material';
import dayjs from 'dayjs';
import { Icons } from '../../../../icons';
import { btnType, Button } from '../../../atoms/button';
import { CurrentSection } from './components';
import { IconButton } from '../../../molecules/icon-button';
import { MEMBER_ACTIONS, Sections, subsections } from '../sessions.constants';
import { PatchAction } from '../../../../../redux/modules/appointment/types';
import useDebounce from '../../../../../hooks/useDebounce';
import { patchAppointment } from '../../../../../services/appointment/appointment.service';
import Giggle from '../../../../../pages/provider/sessions/giggle';
import MedicalFile from '../../../../icons/lotties/lottieIcons/MedicalFile';
import { getTimeForEval } from '../../../../../utils/CommonUtils';

const SectionDetail = ({
  section,
  subSection,
  idFront,
  idBack,
  nextAppointment,
  nextGeneralAppointment,
  onEndSession,
  showKeyboardShortcuts,
  startSignoff,
  goToSchedule,
  appointment,
  onRestartSessionClick,
  openEvaluationsDrawer,
  jumpToOpenSession,
  onAddEvaluation,
  listHelper,
  currentEvaluation,
  currentBlock,
  selectedIndex,
  sessionEnded,
  setTextInputInFocus,
  dctPreviousResponses,
  dctSelectedResponses,
  onPreviousResponseSelected,
  onNext,
  scratchPadText,
  onPrevious,
  onMemberActionClick,
  evaluationsCompleted,
  leftTrigger,
  rightTrigger,
  previousResponses,
  useAllPreviousResponses,
  onSkipEvaluation,
  onRejectEvaluation,
}) => {
  const bodyRef = useRef(null);
  const giggleKeywords = ['giggle', 'laugh', 'chuckle'];
  const getRandomKeyword = () => giggleKeywords[Math.floor(Math.random() * giggleKeywords.length)];
  const [promptsVisible, setPromptsVisible] = useState(true);
  const [open, setOpen] = useState(false);
  const classes = useStyles({ withBanner: section === Sections.OPEN_SESSION });
  const [scratchpad, setScratchpad] = useState(scratchPadText ?? '');
  const scratchpadNotes = useDebounce(scratchpad, 1500);

  const addScratchpadNotes = notes => {
    setScratchpad(notes);
  };

  const saveScratchpadNotes = async () => {
    patchAppointment(
      { action: PatchAction.ADD_METAINFO, appointmentMetaInfoRequestDto: { scratchpad } },
      { appointmentId: appointment.appointmentId },
    );
  };

  useEffect(() => {
    setScratchpad(scratchPadText ?? '');
  }, [scratchPadText]);

  useEffect(() => {
    if (scratchpad && scratchpad !== '') void saveScratchpadNotes();
  }, [scratchpadNotes]);

  const togglePrompts = useCallback(() => {
    setPromptsVisible(p => !p);
  }, []);

  const getTotalSections = () => {
    return listHelper.getDcts().length;
  }

  const getSectionNumber = dctId => {
    const index = listHelper.getDcts().findIndex(dct => dct.dctId === dctId);
    if (index !== -1) {
      return index + 1;
    }
    return index;
  };
  const getSectionName = dctId => {
    const dct = listHelper.getDcts().find(dct => dct.dctId === dctId);
    if (dct) {
      return dct.text;
    }
    return null;
  };

  return (
    <Box className={classes.wrapper}>
      <Box className={classes.mainContent} ref={bodyRef}>
        {section === Sections.EVALUATIONS && currentBlock ? (
          <Box className={classes.topBanner}>
            <MedicalFile className={classes.animatedIconMini} />
            <Box className={classes.topBannerContent}>
              <p className={classes.evaluationTitle}>
                {currentEvaluation.name}
              </p>
              {currentBlock.dctId && currentBlock.cbType !== 'dct' ? (
                <p className={classes.evaluationSubtitle}>
                  Section {getSectionNumber(currentBlock.dctId)}: {getSectionName(currentBlock.dctId)}
                </p>
              ) : (
                  <p className={classes.evaluationSubtitle}>
                    {getTotalSections()} Sections • {getTimeForEval(currentEvaluation.evaluationStats?.totalCb)}
                  </p>
              )}
            </Box>
            <IconButton
              icon="keyboard"
              className={classes.topIcon}
              onClick={() => {
                showKeyboardShortcuts(true);
              }}
            />
          </Box>
        ) : (
          <Box className={classes.topIconsContainer}>
            <IconButton
              icon="keyboard"
              className={classes.topIcon}
              onClick={() => {
                showKeyboardShortcuts(true);
              }}
            />
            <IconButton
              icon="gear"
              className={clsx(classes.topIcon, {
                [classes.topIconInactive]: !promptsVisible,
              })}
              onClick={togglePrompts}
            />
          </Box>
        )}
        <CurrentSection
          selectedSection={section}
          selectedSubsection={subSection}
          promptsVisible={promptsVisible}
          idFront={idFront}
          idBack={idBack}
          eligibleForAutodoc={appointment.aiJobTriggerPostAppointment}
          dctPreviousResponses={dctPreviousResponses}
          dctSelectedResponses={dctSelectedResponses}
          onPreviousResponseSelected={onPreviousResponseSelected}
          scratchpad={scratchpad}
          addScratchpadNotes={addScratchpadNotes}
          openEvaluationsDrawer={openEvaluationsDrawer}
          jumpToOpenSession={jumpToOpenSession}
          onAddEvaluation={onAddEvaluation}
          currentEvaluation={currentEvaluation}
          currentBlock={currentBlock}
          goToSchedule={goToSchedule}
          listHelper={listHelper}
          evaluationsCompleted={evaluationsCompleted}
          setTextInputInFocus={setTextInputInFocus}
          openGiggle={() => setOpen(true)}
          onSkipEvaluation={onSkipEvaluation}
          onRejectEvaluation={onRejectEvaluation}
          bodyRef={bodyRef}
          selectedIndex={selectedIndex}
          leftTrigger={leftTrigger}
          rightTrigger={rightTrigger}
          nextAppointment={nextAppointment}
          nextGeneralAppointment={nextGeneralAppointment}
          previousResponses={previousResponses}
          appointment={appointment}
          useAllPreviousResponses={useAllPreviousResponses}
        />
      </Box>
      {section === Sections.OPEN_SESSION && (
        <Box className={classes.bookAppointmentBanner}>
          <Box className={classes.nextApptContent}>
            <p className={classes.nextApptTitle}>Schedule your next appointment</p>
            <p className={clsx(classes.nextApptTime, { [classes.danger]: !nextAppointment })}>
              {nextAppointment
                ? `Next appointment ${dayjs(nextAppointment.startTime).format('MM/DD/YY - hh:mm a')}`
                : 'Nothing scheduled'}
            </p>
          </Box>
          <Button
            variant={btnType.OUTLINE}
            className={clsx([classes.actionBtn, classes.apptBtn])}
            onClick={() => onMemberActionClick(MEMBER_ACTIONS.SCHEDULE)}
          >
            <Icons glyph="calendar-checked-outlined" />
          </Button>
        </Box>
      )}
      <Box className={classes.footer}>
        {section === Sections.WELCOME && subSection === subsections.IMPORTANT_INFORMATION ? (
          <Button
            variant={btnType.TEXT}
            className={classes.textLink}
            onClick={() => {
              showKeyboardShortcuts(true);
            }}
          >
            Shortcuts (Shift + K)
          </Button>
        ) : section === Sections.CLOSE_SESSION ? (
          <Button
            className={classes.prevBtn}
            endIcon={<Icons glyph="restart" />}
            onClick={onRestartSessionClick}
          >
            Restart session
          </Button>
        ) : (
          <Button className={classes.prevBtn} startIcon={<Icons glyph="arrow-left" />} onClick={onPrevious}>
            Previous section
          </Button>
        )}

        {section === Sections.OPEN_SESSION && !sessionEnded ? (
          <Button className={classes.endBtn} onClick={onEndSession}>
            End Session
          </Button>
        ) : section === Sections.CLOSE_SESSION ? (
          <Button className={classes.signoffBtn} variant={btnType.OUTLINE} onClick={startSignoff}>
            Start note sign off
          </Button>
        ) : (
          <Button className={classes.nextBtn} endIcon={<Icons glyph="arrow-right" />} onClick={onNext}>
            Next section
          </Button>
        )}
      </Box>
      <Giggle open={open} setOpen={setOpen} keyword={getRandomKeyword()} heading={"Let's giggle!"} />
    </Box>
  );
};

export { SectionDetail };
