import { FC, useEffect, useState } from 'react';
import ApexChart from 'react-apexcharts';
import { Box } from '@mui/material';
import clsx from 'clsx';
import dayjs from 'dayjs';

import { colors, fontWeight, Heading, Icons, Menu, Text, textLevel } from '../../../../../packages';

import { AppointmentsFilterDates } from '../../../../../constants/CommonConstants';
import { chartOptions } from '../../../data-collection-template-detail/components/assignments-chart-card/AssignmentsCard.utils';
import { useStyles } from './AssignmentsChart.styles';

const AssignmentsChart = ({ title, className, chart, showHeader = true, data, isLoading }) => {
  const classes = useStyles();
  const { series, showLegend = true } = chart;
  const [selectedTime, setSelectedTime] = useState(AppointmentsFilterDates[1]);

  const [chartDates, setChartDates] = useState([]);
  const [chartSeries, setChartSeries] = useState([]);

  const chartDays = [];
  const localSeries = [];

  let filtered = [];

  switch (selectedTime.value) {
    case 7:
      filtered = data?.last7Days;
      break;
    case 30:
      filtered = data?.current30Days;
      break;
    case 90:
      filtered = data?.last3Months;
      break;
    case 180:
      filtered = data?.last6Months;
      break;
    case 360:
      filtered = data?.last12Months;
      break;
    default:
      filtered = data?.current30Days;
      break;
  }

  const hasAssignments = () => {
    if (
      data?.last7Days?.length > 0 ||
      data?.current30Days?.length > 0 ||
      data?.last3Months?.length > 0 ||
      data?.last6Months?.length > 0 ||
      data?.last12Months?.length > 0 ||
      data?.current30Days?.length > 0
    ) {
      return true;
    }
    return false;
  };

  const hasDataInRange = () => {
    if (filtered?.length <= 0) {
      return false;
    }
    return true;
  };

  filtered?.forEach(item => {
    chartDays.push(dayjs(item.date).format('DD-MM-YYYY'));
    localSeries.push(item.count);
  });

  const times = AppointmentsFilterDates.map(time => ({
    ...time,
    onClick: () => setSelectedTime(time),
  }));

  useEffect(() => {
    setChartSeries(localSeries);
    setChartDates(chartDays);
  }, [selectedTime, data]);

  return (
    <Box className={clsx(classes.card, className)}>
      {showHeader && (
        <Box className={clsx(classes.head, 'chart-head')}>
          <Heading className={classes.title}>{title}</Heading>
          <Box className={classes.headRight}>
            <Menu
              icon="chevron-down"
              items={times}
              itemsWrapperClassName={classes.menuLabel}
              className={classes.menu}
            >
              <Text className={classes.menuLabel} level={textLevel.S}>
                {selectedTime.label}
              </Text>
            </Menu>
          </Box>
        </Box>
      )}
      {isLoading ? (
        <div className={classes.loader}>
          <Icons className="rotate linear infinite" glyph="in-progress" color={colors.primary} />
        </div>
      ) : hasAssignments() && hasDataInRange() ? (
        <Box className={classes.chart}>
          <ApexChart
            options={chartOptions(chartDates, title)}
            series={[{ data: chartSeries }]}
            type="line"
            height={330}
          />
          {showLegend && series.length === 1 && (
            <Box className={classes.chartLegend}>
              <Box className={classes.circle} />
              <Text className={classes.legendText}>{series[0].name}</Text>
            </Box>
          )}
        </Box>
      ) : (
        <Box display="flex" justifyContent="center" paddingY="90px">
          <Text weight={fontWeight.SEMI_BOLD} level={textLevel.S}>
            {!hasAssignments() ? 'No Record Found' : 'No Record Found In This Range'}
          </Text>
        </Box>
      )}
    </Box>
  );
};

export { AssignmentsChart };
