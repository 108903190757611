import { makeStyles } from '@mui/styles';

// colors
import { colors } from '../../../../../../packages';

export const useStyles = makeStyles({
  root: {
    backgroundColor: colors.white,
    borderRadius: 8,
    boxShadow:
      '0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
  },
  top: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '20px 24px',
    borderBottom: `1px solid ${colors.neutral100}`,
  },
  title: {
    margin: 0,
    fontSize: 18,
    lineHeight: '28px',
    color: colors.neutral900,
  },
  badge: {
    height: 24,
    color: colors.secondary500,
    borderColor: colors.secondary300,
  },
  editBtn: {
    fontSize: 14,
    lineHeight: '20px',
    '& svg': {
      width: 14,
      marginLeft: 12,
    },
  },
  textWrap: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 0 16px',
    '&:not(:first-child)': {
      padding: '16px 0',
    },
  },
  dot: {
    width: 4,
    height: 4,
    borderRadius: '50%',
    backgroundColor: colors.neutral900,
    marginRight: 8,
  },
  content: {
    padding: 24,
    display: 'flex',
    flexDirection: 'column',
  },
  description: {
    fontSize: 18,
    lineHeight: '28px',
  },
});
