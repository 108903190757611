export const RELATIONSHIPS_TO_CLIENTS = ['HUSBAND', 'WIFE', 'CHILD', 'OTHER', 'NONE'];
export const RELATIONSHIPS_TO_CLIENTS_ENUM = {
  HUSBAND: 'Husband',
  WIFE: 'Wife',
  CHILD: 'Child',
  OTHER: 'Other',
  NONE: 'None',
};

export const GENDERS = ['Male', 'Female', 'Other'];
export const GENDERS_ENUM = {
  MALE: 'Male',
  FEMALE: 'Female',
  OTHER: 'Other',
};

export const ID_CARD_SIDE_IDENTIFIERS = {
  FRONT: 'id-card-front-side',
  BACK: 'id-card-back-side',
};

export const validationKeyLabels = {
  patientId: 'Patient id',
  verificationId: 'Verification id',
  timestamp: 'Date - time',
  sandbox: 'Sandbox',
  status: 'Status',
  memberId: 'Member id',
  coverageStartDate: 'Coverage start date',
  coverageEndDate: 'Coverage end date',
  payerId: 'Payer id',
  payerName: 'Payer name',
  planName: 'Plan name',
  inn_coinsurance: 'In coinsurance',
  inn_copay: 'In copay',
  inn_cost: 'In cost',
  inn_priorAuthRequired: 'In priorAuthRequired',
  inn_remainingDeductible: 'In remainingDeductible',
  inn_totalDeductible: 'In totalDeductible',
  oon_coinsurance: 'On coinsurance',
  oon_copay: 'On copay',
  oon_cost: 'On cost',
  oon_priorAuthRequired: 'On priorAuthRequired',
  oon_remainingDeductible: 'On remainingDeductible',
  oon_totalDeductible: 'On totalDeductible',
  carveOut_payerId: 'Carve out payer id',
  carveOut_payerName: 'Carve out payer name',
  vob: 'Verification of benefits required',
  insuranceEligibilityStatus: 'Insurance eligibility status',
  insuranceEligibilityEligible: 'Insurance eligibility eligible',
  soharSystemId: 'Sohar system id',
};
