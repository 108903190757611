import { ChangeEvent, FC, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import ApexChart from 'react-apexcharts';
import { Box } from '@mui/material';
import {
  colors,
  fontWeight,
  Heading,
  Icons,
  Select,
  selectType,
  Text,
  textLevel,
} from '../../../../../packages';
import { path2type } from '../../../conversation/ConversationDetail.mock';

import {
  AppointmentsFilterDates,
  AppointmentsFilterStatus,
  CONVERSATION_TYPES,
} from '../../../../../constants/CommonConstants';

import {
  getDctAssignmentReport,
  getEvaluationDCTAssignmentReport,
} from '../../../../../services/conversation/conversation.service';

import { chartOptions } from './AssignmentsCard.utils';
import { useStyles } from './AssignmentsCard.styles';

const AssignmentsCard = ({ version }) => {
  const classes = useStyles();
  const { templateId } = useParams();
  const [days, setDays] = useState(AppointmentsFilterDates[2].value);
  const [status] = useState(AppointmentsFilterStatus[0].value);
  const [chartDates, setChartDates] = useState([]);
  const [chartSeries, setChartSeries] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [noData, setNoData] = useState(false);
  const location = useLocation();
  const path = location.pathname.split('/')[2];

  const fetchDctAssignmentReports = () => {
    if (path2type[path].type === CONVERSATION_TYPES.EVALUATION_DCT) {
      setIsLoading(true);
      getEvaluationDCTAssignmentReport(templateId, days, version)
        .then(res => {
          const dates = [];
          const series = [];
          if (res.data?.length <= 0) {
            setNoData(true);
          }
          res.data.forEach(item => {
            dates.push(item.assignmentDate);
            series.push(item.assignmentCount);
          });

          setChartSeries(series);
          setChartDates(dates);
          setIsLoading(false);
        })
        .catch(err => {
          console.log('err ', err);
          setIsLoading(false);
          setNoData(true);
        });
      return;
    }
    setIsLoading(true);
    getDctAssignmentReport(templateId, days, version)
      .then(res => {
        const dates = [];
        const series = [];
        if (res.data?.length <= 0) {
          setNoData(true);
        }
        res.data.forEach(item => {
          dates.push(item.assignmentDate);
          series.push(item.assignmentCount);
        });

        setChartSeries(series);
        setChartDates(dates);
        setIsLoading(false);
      })
      .catch(err => {
        console.log('err ', err);
        setIsLoading(false);
        setNoData(true);
      });
  };

  useEffect(() => {
    fetchDctAssignmentReports();
  }, [days, status]);

  return (
    <Box className={classes.card}>
      <Box className={classes.head}>
        <Heading className={classes.title}>Assignments chart</Heading>
        <Box className={classes.headRight}>
          <Select
            value={days}
            name="days"
            variant={selectType.PRIMARY}
            options={AppointmentsFilterDates}
            onChange={e => setDays(Number(e.target.value))}
          />
          {/* <Select
            value={status}
            name="status"
            variant={selectType.PRIMARY}
            options={AppointmentsFilterStatus}
            onChange={(e: ChangeEvent<HTMLSelectElement>) => setStatus(e.target.value)}
          /> */}
        </Box>
      </Box>
      <Box className={classes.chart}>
        {!isLoading ? (
          !noData ? (
            <ApexChart
              options={chartOptions(chartDates, 'Assignments')}
              series={[{ data: chartSeries }]}
              type="line"
              height={330}
            />
          ) : (
            <Box display="flex" justifyContent="center">
              <Text weight={fontWeight.SEMI_BOLD} level={textLevel.S}>
                No Record Found
              </Text>
            </Box>
          )
        ) : (
          <div className={classes.loader}>
            <Icons className="rotate linear infinite" glyph="in-progress" color={colors.primary} />
          </div>
        )}
      </Box>
    </Box>
  );
};

export { AssignmentsCard };
