import { Box } from '@mui/material';
import { Text, textLevel } from '../typography';
// types
// styles
import { styles } from './Toggle.styles';

const Toggle = ({
  labelPosition = undefined,
  checked = false,
  children = undefined,
  onChange = null,
  sx = {},
}) => {
  const handleChange = () => {
    onChange(!checked);
  };

  return (
    <Box sx={{ ...styles.container, ...sx }}>
      {typeof children === 'string' ? (
        <Text level={textLevel.S} sx={styles.label(labelPosition)}>
          {children}
        </Text>
      ) : (
        <Box sx={styles.label(labelPosition)}>{children}</Box>
      )}
      <Box component="label" sx={styles.switch(labelPosition)}>
        <input type="checkbox" checked={checked} onChange={handleChange} />
        <Box component="span" sx={styles.slider(checked)} />
      </Box>
    </Box>
  );
};

export { Toggle };
