import React from 'react';
import { Box } from '@mui/material';
import { useDispatch } from 'react-redux';
import {
  Avatar,
  avatarType,
  btnType,
  Button,
  colors,
  fontWeight,
  Heading,
  headingLevel,
  Icons,
  Text,
} from '../../../../packages';
import history from '../../../../utils/history';
import { useStyles } from './WaitingRoomTopBar.styles';
import { commonActionCreators } from '../../../../redux/modules/common';
import { MEMBER_ACTIONS } from '../../../../packages/ui/templates/sessions/sessions.constants';

const WaitingRoomTopBar = ({ appointment }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const onOpenChatClick = () => {
    dispatch(commonActionCreators.openDrawer(MEMBER_ACTIONS.CHAT));
  };

  return (
    <Box className={classes.container}>
      <Button
        variant={btnType.OUTLINE}
        className={classes.btnBack}
        onClick={() => {
          history.back();
        }}
      >
        <Icons color={colors.primary500} glyph="arrow-left" />
      </Button>
      <Avatar
        src={appointment?.participantImage}
        name={appointment?.participantName}
        size={40}
        variant={avatarType.CIRCLE}
      />
      <Box className={classes.info}>
        <Heading level={headingLevel.M} weight={fontWeight.SEMI_BOLD} className={classes.title}>
          {appointment?.serviceName}
        </Heading>
        <Text weight={fontWeight.MEDIUM} className={classes.subtitle}>
          {appointment?.serviceDuration} minutes session with {appointment?.patientFirstName || ''}{' '}
          {appointment?.patientLastName || ''}
        </Text>
      </Box>
      <Button
        variant={btnType.TEXT}
        endIcon={<Icons glyph="chat-square" />}
        className={classes.btnOpenChat}
        onClick={onOpenChatClick}
      >
        Open chat
      </Button>
    </Box>
  );
};

export { WaitingRoomTopBar };
