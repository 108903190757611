import { colors } from '../../../../../../packages';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  drawer: {
    '& .MuiPaper-root': {
      maxWidth: '640px !important',
      backgroundColor: colors.neutral50,
      '& .drawerHeader': {
        backgroundColor: colors.white,
      },
    },
    '& .drawerBody': {
      padding: 0,
    },
  },
  drawer2: {
    '& .MuiPaper-root': {
      maxWidth: '640px !important',
      backgroundColor: colors.white,
    },
    '& .drawerBody': {
      padding: '80px 0',
    },
  },
  header: {
    height: 108,
    padding: 24,
    borderBottom: `1px solid ${colors.neutral100}`,
  },
  backBtn: {
    width: 40,
    height: 40,
    minWidth: 40,
    minHeight: 40,
    padding: '18px 12px',
    borderColor: colors.primary300,
    '& svg': {
      color: colors.primary500,
      width: 18,
      height: 15,
    },
  },
  header2: {
    position: 'fixed',
    display: 'flex',
    top: 0,
    width: '100%',
    maxWidth: 'inherit',
    alignItems: 'center',
    padding: '20px 16px',
    gap: 24,
    backgroundColor: colors.white,
    borderBottom: `1px solid ${colors.neutral100}`,
    zIndex: 100,
  },
  headerTitle: {
    fontSize: 24,
    lineHeight: '32px',
    margin: 0,
  },
  headerSubTitle: {
    fontSize: 16,
    lineHeight: '24px',
    margin: 0,
    color: colors.neutral600,
  },
  closeBtn: {
    width: 40,
    height: 40,
    minWidth: 40,
    '& svg': {
      width: 20,
    },
  },
  formBlock: {
    borderTop: `1px solid ${colors.neutral100}`,
    padding: 40,
  },
  formBlockNoBorder: {
    borderTop: 'none',
  },
  topWrap: {
    display: 'flex',
    gap: 12,
    alignItems: 'center',
    marginBottom: 40,
  },
  topWrap2: {
    marginBottom: 24,
  },
  title: {
    fontSize: 18,
    lineHeight: '24px',
    fontWeight: 'bold',
  },
  twoSection: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: 32,
    '& $section': {
      flex: 1,
    },
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    position: 'relative',
    marginBottom: 24,
  },
  noMb: {
    marginBottom: 0,
  },
  checkbox: {
    paddingTop: 40,
    '& .MuiTypography-body1': {
      fontSize: 14,
      lineHeight: '20px',
      fontWeight: 500,
    },
  },
  label: {
    fontSize: 14,
    lineHeight: '20px',
    margin: 0,
  },
  form: {
    flex: 1,
    height: 'calc(100% - 108px)',
  },
  selectTypeForm: {
    display: 'flex',
    flexDirection: 'column',
    gap: 36,
    padding: 32,
  },
  blockTypeSection: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '56px 24px',
    cursor: 'pointer',
    borderRadius: 8,
    background: colors.white,
  },
  blockCircle1: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 72,
    height: 72,
    background: colors.primary50,
    border: '1px solid #b8ddfe',
    borderRadius: 100,
  },
  blockCircle2: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 72,
    height: 72,
    background: colors.secondary50,
    border: '1px solid #feb8dd',
    borderRadius: 100,
  },
  blockDesc: {
    marginTop: 6,
    lineHeight: '20px',
    color: colors.neutral500,
  },
  formContent: {
    flex: 1,
    overflowY: 'auto',
    height: 'calc(100% - 80px)',
  },
  footer: {
    position: 'fixed',
    display: 'flex',
    bottom: 0,
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    maxWidth: 'inherit',
    height: 80,
    padding: 16,
    borderTop: `1px solid ${colors.neutral100}`,
    '& button': {
      height: 48,
    },
    backgroundColor: colors.white,
  },
  saveTempBtn: {
    fontSize: 14,

    '& svg': {
      width: 14,
      marginRight: 6,
    },
  },
  removeContentBtn: {
    fontSize: 14,
    color: colors.destructive500,
    '& svg': {
      width: 18,
      marginRight: 6,
      '& path': {
        fill: colors.destructive500,
      },
    },
  },
  menu: {
    '& svg path': {
      fill: colors.primary600,
    },
  },
  menuLabel: {
    fontWeight: 700,
    color: colors.primary600,
  },
  searchBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: 32,
    padding: 24,
    backgroundColor: colors.neutral100,
  },
  searchBoxCB: {
    display: 'flex',
    flexDirection: 'row',
    gap: 16,
    padding: 24,
    // backgroundColor: colors.neutral100,
  },
  searchInput: {
    height: 48,
  },
  dct: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '16px 24px',
    gap: 24,
    cursor: 'pointer',
    background: colors.white,

    '&:not(:last-child)': {
      borderBottom: `1px solid ${colors.neutral100}`,
    },
    '&:hover': {
      backgroundColor: colors.primary25,
    },
  },
  selected: {
    backgroundColor: colors.primary25,
  },
  iconBtn: {
    gap: 8,
    background: colors.white,
    border: `1px solid ${colors.primary}`,
    height: 48,
    paddingLeft: 20,
    paddingRight: 20,
  },
  btnContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 10,
    paddingBottom: 10,
  },
  loadMoreBtn: {
    fontSize: 14,
  },
  loader: {
    display: 'flex',
    gap: 10,
    marginLeft: '46%',
    marginTop: '18%',
    alignItems: 'center',
  },
});
