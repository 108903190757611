import { FILTER_COLLAPSABLE_SECTION_TYPE } from '../../../../packages/ui/organisms/table/filter';

export const tableColumns = [
  { id: 'ruleName', label: 'Name', sortable: false },
  { id: 'event', label: 'Event trigger', sortable: false },
  { id: 'filters', label: 'Filters', sortable: false },
  { id: 'planItemType', label: 'Plan item type', sortable: false },
  { id: 'planItemName', label: 'Plan item name', sortable: false },
  { id: 'delay', label: 'Delay', sortable: false },
  { id: 'repeat', label: 'Repeat', sortable: false },
  { id: 'actions', label: '', sortable: false },
];

export const filterOptionsMock = [{ label: 'Filter 1', value: 'filter_01' }];

export const defaultTableParams = {
  search: {
    searchKey: '',
  },
  pagination: {
    currentPage: 1,
    rowsPerPage: 10,
  },
  sorter: {
    direction: 'desc',
    column: '',
  },
};

export const automationMultiSelectFilterOptionsMock = [
  {
    sectionTitle: 'EventType',
    sectionType: FILTER_COLLAPSABLE_SECTION_TYPE.DEFAULT,
    sectionOptions: [
      { label: 'Appointment Requested', value: 'Appointment Requested' },
      { label: 'Appointment Scheduled', value: 'Appointment Scheduled' },
      { label: 'Appointment Completed', value: 'Appointment Completed' },
      { label: 'Appointment Canceled', value: 'Appointment Canceled' },
      { label: 'Appointment No Show', value: 'Appointment No Show' },
      { label: 'Transcript Ready', value: 'Transcript Ready' },
      { label: 'AI Job Completed', value: 'AI Job Completed' },
      { label: 'Conversation Completed', value: 'Conversation Completed' },
      { label: 'Conversation Assigned', value: 'Conversation Assigned' },
      { label: 'DCT Completed', value: 'DCT Completed' },
      { label: 'Profile Element Updated', value: 'Profile Element Updated' },
      { label: 'Plan Item Assigned', value: 'Plan Item Assigned' },
      { label: 'Plan Item Started', value: 'Plan Item Started' },
      { label: 'Plan Item Completed', value: 'Plan Item Completed' },
    ],
  },
  {
    sectionTitle: 'AssignmentType',
    sectionType: FILTER_COLLAPSABLE_SECTION_TYPE.RADIO,
    sectionOptions: [
      { label: 'Evaluation', value: 'Evaluation' },
      { label: 'Plan Item', value: 'Plan Item' },
    ],
  },
  {
    sectionTitle: 'PlanItemType',
    sectionType: FILTER_COLLAPSABLE_SECTION_TYPE.DEFAULT,
    sectionOptions: [
      { label: 'Education', value: 'Education' },
      { label: 'Topic', value: 'Topic' },
      { label: 'Activity', value: 'Activity' },
      { label: 'Group', value: 'Group' },
      { label: 'Conversation', value: 'Conversation' },
      { label: 'Provider', value: 'Provider' },
      { label: 'Provider Type', value: 'Provider Type' },
      { label: 'Service', value: 'Service' },
    ],
  },
];
