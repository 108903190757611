// colors
import { colors } from '../../../../packages/colors';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  apptCard: {
    background: colors.white,
    boxShadow: '0px 0px 1px 0px #0000000A, 0px 0px 2px 0px #0000000F, 0px 4px 8px 0px #0000000A',
    borderRadius: 8,
    display: 'flex',
    flexDirection: 'column',
  },
  top: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingLeft: 24,
    paddingRight: 16,
    paddingTop: 20,
    paddingBottom: 20,
  },
  text18: {
    fontWeight: 600,
    fontSize: 18,
    lineHeight: '24px',
  },
  text16: {
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '24px',
  },
  text16Second: {
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '24px',
    color: colors.neutral600,
  },
  btn: {
    height: 28,
    borderRadius: 100,
    fontSize: 14,
    fontWeight: 600,
    lineHeight: '20px',
    padding: '0px 12px',
    '&.primary': {
      backgroundColor: colors.primary500,
    },
    '&.success': {
      backgroundColor: colors.success500,
    },
    '&.neutral': {
      backgroundColor: colors.neutral500,
    },
  },
  mid: {
    paddingTop: 16,
    paddingBottom: 16,
    paddingLeft: 24,
    paddingRight: 24,
    borderTop: `1px solid ${colors.neutral100}`,
  },
  evaluation: {
    padding: 16,
    display: 'flex',
    justifyContent: 'space-between',
    borderTop: `1px solid ${colors.neutral200}`,
    cursor: 'pointer',
  },
  text12: {
    fontWeight: 600,
    fontSize: 12,
    lineHeight: '16px',
  },
  text14: {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '20px',
  },
  badge: {
    borderRadius: 100,
    height: 18,
    fontSize: 12,
    lineHeight: '16px',
    fontWeight: 600,
  },
});
