import { AppointmentStatus, SignOffStatus } from '../../../packages/constants/CommonConstants';
import {
  DROPDOWN_FILTER_COLLAPSABLE_TYPE,
  FILTER_COLLAPSABLE_SECTION_TYPE,
} from '../../../packages/ui/organisms/table/filter';
import { getUserTimeZone } from '../../../utils/dayjs';

export const appointmentSchedulingTabs = [
  { label: 'Next available', tabKey: 'next_available' },
  { label: 'Specific Date', tabKey: 'specific_week' },
  { label: 'Date Range', tabKey: 'date_range' },
];

export const AppointmentTitles = {
  pending: 'Pending appointments',
  past: 'Past appointments',
  current: 'Current appointments',
  cancelled: 'Cancelled appointments',
};

export const stateFilterText = 'Only providers in';
export const insuranceFilterText = 'Only providers accepting';

export const appointmentColumns = [
  { id: 'provider', label: 'Provider', sortable: true },
  { id: 'member', label: 'Member', sortable: true },
  { id: 'serviceName', label: 'Session type', sortable: true },
  { id: 'appointmentStatus', label: 'Status', sortable: true },
  { id: 'appointmentTime', label: 'Date & time', sortable: true },
  { id: 'patientState', label: 'State', sortable: true },
  { id: 'actions', label: '', sortable: false },
];

export const appointmentFilterStatusOptions = {
  current: [
    { label: 'Texas', value: 'Texas', filterBy: 'state' },
    { label: 'Florida', value: 'Florida', filterBy: 'state' },
    { label: 'Virginia', value: 'Virginia', filterBy: 'state' },
    { label: 'Connecticut', value: 'Connecticut', filterBy: 'state' },
    { label: 'Cash Pay', value: 'cash', filterBy: 'paymentMethod' },
    { label: 'Insurance', value: 'payer', filterBy: 'paymentMethod' },
  ],
  past: [
    { label: 'No Show', value: AppointmentStatus.NO_SHOW, filterBy: 'status' },
    { label: 'Completed', value: AppointmentStatus.FULFILLED, filterBy: 'status' },
    { label: 'Texas', value: 'Texas', filterBy: 'state' },
    { label: 'Florida', value: 'Florida', filterBy: 'state' },
    { label: 'Virginia', value: 'Virginia', filterBy: 'state' },
    { label: 'Connecticut', value: 'Connecticut', filterBy: 'state' },
    { label: 'Cash Pay', value: 'cash', filterBy: 'paymentMethod' },
    { label: 'Insurance', value: 'payer', filterBy: 'paymentMethod' },
  ],
  cancelled: [
    { label: 'Texas', value: 'Texas', filterBy: 'state' },
    { label: 'Florida', value: 'Florida', filterBy: 'state' },
    { label: 'Virginia', value: 'Virginia', filterBy: 'state' },
    { label: 'Connecticut', value: 'Connecticut', filterBy: 'state' },
    { label: 'Cash Pay', value: 'cash', filterBy: 'paymentMethod' },
    { label: 'Insurance', value: 'payer', filterBy: 'paymentMethod' },
  ],
  pending: [
    { label: 'Waiting on Member', value: 'Member_Needs_Action', filterBy: 'patientStatus' },
    { label: 'Waiting on Provider', value: 'Provider_Needs_Action', filterBy: 'practitionerStatus' },
    { label: 'Texas', value: 'Texas', filterBy: 'state' },
    { label: 'Florida', value: 'Florida', filterBy: 'state' },
    { label: 'Virginia', value: 'Virginia', filterBy: 'state' },
    { label: 'Connecticut', value: 'Connecticut', filterBy: 'state' },
    { label: 'Cash Pay', value: 'cash', filterBy: 'paymentMethod' },
    { label: 'Insurance', value: 'payer', filterBy: 'paymentMethod' },
  ],
};

export const appointmentSorterMap = {
  provider: 'providerName',
  member: 'participantName',
  serviceName: 'sessionType',
  appointmentStatus: 'status',
  appointmentTime: 'startTime',
  patientState: 'patientState',
};

const getProviderFilterOptions = providers => {
  return {
    sectionTitle: DROPDOWN_FILTER_COLLAPSABLE_TYPE.PROVIDERS,
    sectionType: FILTER_COLLAPSABLE_SECTION_TYPE.DROPDOWN,
    sectionOptions: providers.map(provider => {
      return {
        label: provider.fullName,
        value: {
          name: provider.fullName,
          id: provider.providerId,
          designation: provider.designation,
          image: provider.profile,
        },
      };
    }),
  };
};

const defaultAppointmentFilterOptions = [
  {
    sectionTitle: 'State',
    sectionType: FILTER_COLLAPSABLE_SECTION_TYPE.DEFAULT,
    sectionOptions: [
      { label: 'Texas', value: 'Texas' },
      { label: 'Florida', value: 'Florida' },
      { label: 'Connecticut', value: 'Connecticut' },
      { label: 'Virginia', value: 'Virginia' },
      { label: 'New Hampshire', value: 'New Hampshire' },
    ],
  },
  {
    sectionTitle: 'Role',
    sectionType: FILTER_COLLAPSABLE_SECTION_TYPE.DEFAULT,
    sectionOptions: [
      { label: 'Nurse Practitioner', value: 'Nurse Practitioner' },
      { label: 'Therapist', value: 'Therapist' },
      { label: 'Counselor', value: 'Counselor' },
      { label: 'Coach', value: 'Coach' },
      { label: 'Care Manager', value: 'Care Manager' },
      { label: 'Physician', value: 'Physician' },
    ],
  },
  {
    sectionTitle: 'VBC',
    sectionType: FILTER_COLLAPSABLE_SECTION_TYPE.DEFAULT,
    sectionOptions: [{ label: 'VBC flag', value: 'VBC flag' }],
  },
];
export const pendingAppointmentsFilterOption = providers => {
  return [...defaultAppointmentFilterOptions, getProviderFilterOptions(providers)];
};

export const currentAppointmentsFilterOption = providers => {
  return [
    {
      sectionTitle: 'Status',
      sectionType: FILTER_COLLAPSABLE_SECTION_TYPE.RADIO,
      sectionOptions: [
        { label: 'Today', value: 'Today' },
        { label: 'Scheduled', value: 'Scheduled' },
      ],
      sectionTooltip: 'This filter will precede date range filter.',
    },
    ...defaultAppointmentFilterOptions,
    getProviderFilterOptions(providers),
  ];
};

export const pastAppointmentsFilterOption = providers => {
  return [
    {
      sectionTitle: 'Status',
      sectionType: FILTER_COLLAPSABLE_SECTION_TYPE.DEFAULT,
      sectionOptions: [
        { label: 'Completed', value: AppointmentStatus.FULFILLED },
        { label: 'No show', value: 'NO SHOW' },
      ],
    },
    ...defaultAppointmentFilterOptions,
    getProviderFilterOptions(providers),
  ];
};

export const TAB_PENDING_FILTERS = {
  ALL: 'total',
  WAITING_FOR_SIGNOFF: 'waitingForSignOff',
  WAITING_ON_MEMBER: 'waitingOnMember',
};

export const TAB_CURRENT_FILTERS = {
  ALL: 'total',
  TODAY_APPOINTMENTS: 'todayAppointments',
  FIRST_APPOINTMENT: 'firstAppointment',
  MISSING_DATA: 'missingData',
  INSURANCE_ISSUES: 'insuranceIssues',
  VBC: 'vbc',
};

export const TAB_PAST_FILTERS = {
  ALL: 'total',
  NO_SHOWS: 'noShows',
  INSURANCE_ISSUES: 'insuranceIssues',
  VBC: 'vbc',
};

export const defaultPendingTabs = [
  { tabKey: TAB_PENDING_FILTERS.ALL, label: 'All Appointments', countKey: 'total' },
  {
    tabKey: TAB_PENDING_FILTERS.WAITING_FOR_SIGNOFF,
    label: 'Waiting for Sign-off',
    countKey: 'waitingForSignOff',
  },
  {
    tabKey: TAB_PENDING_FILTERS.WAITING_ON_MEMBER,
    label: 'Waiting on Member',
    countKey: 'waitingOnMember',
  },
];

export const defaultCurrentTabs = [
  { tabKey: TAB_CURRENT_FILTERS.ALL, label: 'All Appointments', countKey: 'total' },
  {
    tabKey: TAB_CURRENT_FILTERS.TODAY_APPOINTMENTS,
    label: `Today's Appointments`,
    countKey: 'todaysAppointmentsCount',
  },
  {
    tabKey: TAB_CURRENT_FILTERS.FIRST_APPOINTMENT,
    label: 'First appointments',
    countKey: 'firstAppointmentCount',
  },
  {
    tabKey: TAB_CURRENT_FILTERS.MISSING_DATA,
    label: 'Missing Data',
    countKey: 'missingPatientDataCount',
  },
  {
    tabKey: TAB_CURRENT_FILTERS.INSURANCE_ISSUES,
    label: 'Insurance Issues',
    countKey: 'insuranceIssuesCount',
  },
  {
    tabKey: TAB_CURRENT_FILTERS.VBC,
    label: 'Value-Based Care',
    countKey: 'vbcCount',
  },
];

export const defaultPastTabs = [
  { tabKey: TAB_PAST_FILTERS.ALL, label: 'All Appointments', countKey: 'total' },
  {
    tabKey: TAB_PAST_FILTERS.NO_SHOWS,
    label: 'No Shows',
    countKey: 'noShowsCount',
  },
  {
    tabKey: TAB_PAST_FILTERS.INSURANCE_ISSUES,
    label: 'Insurance Issues',
    countKey: 'insuranceIssuesCount',
  },
  {
    tabKey: TAB_PAST_FILTERS.VBC,
    label: 'Value-Based Care',
    countKey: 'vbcCount',
  },
];

export const tabQueryParams = {
  [TAB_PENDING_FILTERS.WAITING_FOR_SIGNOFF]: {
    statuses: AppointmentStatus.FULFILLED,
    signOffStatus: SignOffStatus.DRAFTED,
  },
  [TAB_PENDING_FILTERS.WAITING_ON_MEMBER]: {
    patientStatus: AppointmentStatus.NEEDS_ACTION,
  },
  [TAB_CURRENT_FILTERS.FIRST_APPOINTMENT]: {
    firstAppointment: true,
  },
  [TAB_CURRENT_FILTERS.MISSING_DATA]: {
    missingData: true,
  },
  [TAB_CURRENT_FILTERS.INSURANCE_ISSUES]: {
    insuranceIssues: true,
  },
  [TAB_CURRENT_FILTERS.VBC]: {
    vbcFlag: true,
  },
  [TAB_CURRENT_FILTERS.TODAY_APPOINTMENTS]: {
    dateStatus: 'TODAY',
    timeZone: getUserTimeZone(),
  },
  [TAB_PAST_FILTERS.NO_SHOWS]: {
    statuses: AppointmentStatus.NO_SHOW,
  },
  [TAB_PAST_FILTERS.INSURANCE_ISSUES]: {
    insuranceIssues: true,
  },
  [TAB_PAST_FILTERS.VBC]: {
    vbcFlag: true,
  },
};
