export const iconTextStyle = {
  PRIMARY: 'PRIMARY',
  SECONDARY: 'SECONDARY',
  ERROR: 'ERROR',
  SUCCESS: 'SUCCESS',
};

export const iconTextPosition = {
  LEFT: 'left',
  RIGHT: 'right',
};
