/* eslint-disable max-len */
import { FILTER_COLLAPSABLE_SECTION_TYPE } from '../../../packages/ui/organisms/table/filter';

export const evaluationColumns = [
  { id: 'name', label: 'Name', sortable: true },
  { id: 'description', label: 'Description', sortable: false },
  { id: 'version', label: 'Version', sortable: false },
  { id: 'status', label: 'Status', sortable: false },
  { id: 'rejectionTemplate', label: 'Rejection Reason', sortable: false },
  { id: 'dctCount', label: 'DCT Count', sortable: false },
  { id: 'appointmentsCount', label: 'Appointment Count', sortable: false },
  { id: 'lastUpdated', label: 'Last Updated', sortable: false },
  { id: 'actions', label: '', sortable: false },
];

export const filterOptionsMock = [{ label: 'Type 1', value: 'type_01' }];

export const EvaluationFilters = [
  {
    sectionTitle: 'Creation',
    sectionType: FILTER_COLLAPSABLE_SECTION_TYPE.RADIO,
    sectionOptions: [{ label: 'AI Generated', value: 'AI Generated' }],
  },
];
export const viewGroups = [
  { type: 'ACTIVE', label: 'Active' },
  { type: 'DRAFT', label: 'Drafts' },
];
