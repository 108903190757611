/* eslint-disable max-len */
// types

const CameraOutlined = ({ color }) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_8082_68615)">
        <path
          d="M11.3332 6.13366L14.8085 3.70033C14.8585 3.6653 14.9171 3.64466 14.978 3.64066C15.0389 3.63666 15.0997 3.64946 15.1538 3.67766C15.208 3.70585 15.2533 3.74836 15.2849 3.80056C15.3166 3.85276 15.3332 3.91263 15.3332 3.97366V12.027C15.3332 12.088 15.3166 12.1479 15.2849 12.2001C15.2533 12.2523 15.208 12.2948 15.1538 12.323C15.0997 12.3512 15.0389 12.364 14.978 12.36C14.9171 12.356 14.8585 12.3354 14.8085 12.3003L11.3332 9.86699V12.667C11.3332 12.8438 11.2629 13.0134 11.1379 13.1384C11.0129 13.2634 10.8433 13.3337 10.6665 13.3337H1.33317C1.15636 13.3337 0.98679 13.2634 0.861766 13.1384C0.736742 13.0134 0.666504 12.8438 0.666504 12.667V3.33366C0.666504 3.15685 0.736742 2.98728 0.861766 2.86225C0.98679 2.73723 1.15636 2.66699 1.33317 2.66699H10.6665C10.8433 2.66699 11.0129 2.73723 11.1379 2.86225C11.2629 2.98728 11.3332 3.15685 11.3332 3.33366V6.13366ZM11.3332 8.23966L13.9998 10.1063V5.89366L11.3332 7.76033V8.23899V8.23966ZM1.99984 4.00033V12.0003H9.99984V4.00033H1.99984ZM3.33317 5.33366H4.6665V6.66699H3.33317V5.33366Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_8082_68615">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CameraOutlined;
