import { FC } from 'react';
import { Box } from '@mui/material';
import { FieldProps, useField } from 'formik';
import clsx from 'clsx';

import { IconButton } from '../../../../../../packages/ui/molecules/icon-button';
import { Select, selectType } from '../../../../../../packages/ui/atoms/select';

import { TIME_ARRAY } from '../../../Profile.constants';
import { useStyles } from './TimeSlot.styles';

const TimeSlot = ({ fieldName, onRemove, id: parentId, ...props }) => {
  const classes = useStyles();
  const { form } = props;
  const [field, meta] = useField({
    name: fieldName,
  });
  const onChangeTime = key => time => {
    void form.setFieldValue(`${field.name}.${key}`, time.target.value);
  };
  return (
    <Box className={clsx(classes.wrapper, meta.error ? classes.error : {})}>
      <Select
        variant={selectType.SECONDARY}
        value={field.value.start}
        emptyText="Select timezone"
        className={classes.timeSelectionMenu}
        onChange={onChangeTime('start')}
        options={TIME_ARRAY}
        id={`${parentId}-start`}
      />
      <Select
        variant={selectType.SECONDARY}
        value={field.value.end}
        emptyText="Select timezone"
        className={classes.timeSelectionMenu}
        onChange={onChangeTime('end')}
        options={TIME_ARRAY}
        id={`${parentId}-end`}
      />
      <IconButton icon="minus-outlined" onClick={onRemove} className={classes.removeBtn} />
    </Box>
  );
};

export { TimeSlot };
