import { colors } from '../../../../../packages';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  container: {
    flex: 1,
    height: '100%',
  },
  topWrap: {
    display: 'flex',
    gap: 12,
    alignItems: 'center',
    padding: 40,
  },
  title: {
    fontSize: 18,
    lineHeight: '24px',
    fontWeight: 'bold',
  },
  twoSection: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 16,
    '& $section': {
      flex: 1,
    },
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    position: 'relative',
  },
  label: {
    fontSize: 14,
    lineHeight: '20px',
    margin: 0,
  },
  form: {
    flex: 1,
    height: 'calc(100% - 104px)',
  },
  formContent: {
    flex: 1,
    display: 'flex',
    paddingLeft: 42,
    paddingRight: 42,
    overflowY: 'auto',
    flexDirection: 'column',
    gap: 24,
    height: 'calc(100% - 80px)',
    paddingBottom: 40,
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 80,
    padding: 16,
    borderTop: `1px solid ${colors.neutral100}`,
    '& button': {
      height: 48,
    },
  },
});
