import { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import clsx from 'clsx';

import {
  Avatar,
  avatarType,
  Badge,
  badgeType,
  Breadcrumbs,
  fontWeight,
  Heading,
  headingLevel,
  Text,
  useQuery,
} from '../../../packages';
import { getProfile } from '../../../redux/modules/profile/selectors';
import { profileActionCreators } from '../../../redux/modules/profile';
import { BaseLayout } from '../../../layouts/base';
import SelectedComponent from './ProviderDetail.selected';

import { headings, rightSideNavList } from './ProviderDetail.mock';
import { useStyles } from './ProviderDetail.styles';
import { scheduleActionCreators } from '../../../redux/modules/schedule';

const ProviderDetail = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const query = useQuery();
  const navigate = useNavigate();
  const { providerId } = useParams();
  const { providers, providerDetails } = useSelector(getProfile);
  const [selectedScreen, setSelectedScreen] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsLoading(false);
    }, 1000);
    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    const getQuery = query.get('name');
    if (!getQuery) {
      query.set('name', 'general-information');
      window.location.search = query.toString();
    } else {
      setSelectedScreen(getQuery);
    }
  }, [query]);

  useEffect(() => {
    if (providerId) {
      dispatch(profileActionCreators.fetchProviderDetails(providerId));
      dispatch(scheduleActionCreators.fetchProviderSchedule({ userId: providerId }));
    } else {
      navigate('/admin/providers');
    }
  }, [providerId, providers]);

  return (
    <BaseLayout
      rightSideNavList={rightSideNavList}
      rightSideBarWidth="320px"
      rightSidebarHeadCustom={
        <Box className={classes.head}>
          <Avatar
            src={providerDetails?.profileImage}
            name={providerDetails?.fullName}
            variant={avatarType.CIRCLE}
            size={40}
          />
          <Box>
            <Text className={classes.name}>{providerDetails?.fullName}</Text>
            <Badge variant={badgeType.FILLED} className={clsx(classes.badge, providerDetails?.designation)}>
              {providerDetails?.designation}
            </Badge>
          </Box>
        </Box>
      }
    >
      <div className={classes.root}>
        <Box className={classes.header}>
          <Breadcrumbs
            links={[
              { href: '/admin/providers', text: 'Providers' },
              { text: isLoading ? '' : providerDetails?.fullName },
            ]}
          />
        </Box>
        {headings[selectedScreen] && (
          <Heading className={classes.heading} level={headingLevel.XL} weight={fontWeight.BOLD}>
            {headings[selectedScreen]}
          </Heading>
        )}
        <SelectedComponent selectedScreen={selectedScreen} />
      </div>
    </BaseLayout>
  );
};

export { ProviderDetail };
