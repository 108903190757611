import { baseRequest } from '../../utils/HttpClient';
import { ApiEndpoints } from '../../constants/ApiEndpoints';

export const getProviderSchedule = (actor, queryParams = null) => {
  return baseRequest(ApiEndpoints.GET_PROVIDER_SCHEDULE, null, { actor }, queryParams);
};

export const updateProviderSchedule = (actor, scheduleUpdateRequest) => {
  return baseRequest(ApiEndpoints.UPDATE_PROVIDER_SCHEDULE, scheduleUpdateRequest, null, { actor });
};

export const getProviderServices1 = providerId => {
  const queryParams = {
    providerId,
    inactiveService: true,
  };
  return baseRequest(ApiEndpoints.GET_PROVIDER_SERVICES_BY_ID, null, null, queryParams);
};

export const getAllServices = () => {
  return baseRequest(ApiEndpoints.GET_SYSTEM_SERVICES, null, null);
};

export const getAppointmentReport = bodyRequest => {
  return baseRequest(
    ApiEndpoints.GET_APPOINTMENT_REPORT,
    bodyRequest,
    null,
    null,
    true,
    true,
    bodyRequest?.download,
  );
};
export const getAIJobs = () => {
  return baseRequest(ApiEndpoints.GET_ALL_AI_JOBS);
};

export const getLevelOfEngagements = () => {
  return baseRequest(ApiEndpoints.GET_LEVEL_OF_ENGAGEMENTS);
};

export const getLoeDetails = payload => {
  return baseRequest(ApiEndpoints.GET_LOE_DETAILS, null, null, payload);
};

export const getLoeMembersList = payload => {
  return baseRequest(ApiEndpoints.GET_LOE_MEMBERS_LIST, null, null, payload);
};

export const getProviderReviews = params => {
  return baseRequest(ApiEndpoints.GET_PROVIDER_REVIEWS, null, null, params);
};
