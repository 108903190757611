import { useMemo } from 'react';

// components
import { Box, Stack } from '@mui/material';
import clsx from 'clsx';
import ReactApexChart from 'react-apexcharts';

import { fontWeight, Heading, headingLevel, Text } from '../../../../../packages';
import { getChartOpts } from './BreakdownChart.config';
// styles
import { useStyles } from './BreakdownChart.styles';

const BreakdownChart = ({
  title,
  subtitle,
  className,
  chartData,
  chartHeight = 100,
  chartWidth = 420,
  donutOpts,
  legendRight,
  onHeadingClick,
}) => {
  const classes = useStyles();
  const series = chartData?.map(({ data }) => data[0]);
  const renderDonutChart = useMemo(
    () => (
      <ReactApexChart
        options={getChartOpts(chartData, donutOpts, legendRight)}
        series={series}
        type="donut"
        width={chartWidth}
        height={chartHeight}
      />
    ),
    [donutOpts, chartData, chartHeight, chartWidth],
  );
  return (
    <Box className={clsx(classes.card, className)}>
      <Stack onClick={() => (subtitle ? onHeadingClick({ name: title }) : '')}>
        <Heading
          level={headingLevel.S}
          className={subtitle ? classes.titleClickable : classes.title}
          weight={fontWeight.SEMI_BOLD}
        >
          {title}
          {series.length === 5 && !!subtitle && (
            <Text weight={fontWeight.MEDIUM} className={classes.subtitle}>
              (Top 5)
            </Text>
          )}
        </Heading>
        {subtitle && (
          <Text weight={fontWeight.MEDIUM} className={classes.subtitle}>
            {subtitle}
          </Text>
        )}
      </Stack>
      <Box className={classes.chartWrap}>{renderDonutChart}</Box>
    </Box>
  );
};

export { BreakdownChart };
