import AsyncStorage from '@callstack/async-storage';
import { persistCombineReducers } from 'redux-persist';

import { common } from '../../redux/modules/common';
import { auth } from '../modules/auth';
import { profile } from '../modules/profile';
import { conversation } from '../modules/conversation';
import { appointment } from '../modules/appointment';
import { schedule } from '../modules/schedule';
import { audit } from '../modules/audit';
import { notification } from '../modules/notification';
import { snackbar } from '../modules/snackbar';
import { priority } from '../modules/priority';
import { reporting } from '../modules/reporting';
import { reportService } from '../modules/report-service';
import { stateReducer } from '../modules/state';
import { socket } from '../modules/socket';

const config = {
  key: 'root',
  storage: AsyncStorage,
  blacklist: ['auth'],
};

const appReducer = persistCombineReducers(config, {
  auth,
  profile,
  common,
  conversation,
  appointment,
  schedule,
  audit,
  notification,
  snackbar,
  priority,
  reporting,
  reportService,
  state: stateReducer,
  socket,
});

export default function rootReducer(state, action) {
  return appReducer(state, action);
}
