/* eslint-disable max-len */
import cx from 'clsx';

// types
// styles
import { useStyles } from './shared/styles';

const ArrowDownIcon = ({ className, color, onClick }) => {
  const classes = useStyles();
  return (
    <svg
      className={cx({
        [classes.root]: true,
        [className || '']: className,
      })}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        id="Vector"
        d="M8.66667 10.7813L12.2427 7.20529L13.1853 8.14796L8 13.3333L2.81467 8.14796L3.75733 7.20529L7.33333 10.7813V2.66663H8.66667V10.7813Z"
        fill={color}
      />
    </svg>
  );
};

export default ArrowDownIcon;
