import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { useFormik } from 'formik';

import { Drawer, drawerType } from '../../../../../packages/ui/organisms/drawer';
import { IconButton } from '../../../../../packages/ui/molecules/icon-button';
import {
  fontWeight,
  Heading,
  headingLevel,
  Text,
  TextError,
} from '../../../../../packages/ui/atoms/typography';
import { Button, btnType } from '../../../../../packages/ui/atoms/button';
import { Textarea } from '../../../../../packages/ui/atoms/textarea';

// schema
import { ApproachSchema } from './Approach.schema';
// styles
import { useStyles } from './Approach.styles';
// constants

const EditApproach = ({ isOpen, onClose, description, onSubmit, error }) => {
  const classes = useStyles();
  const { errors, values, handleChange, handleSubmit, touched, ...rest } = useFormik({
    initialValues: { description },
    enableReinitialize: true,
    validationSchema: ApproachSchema,
    onSubmit: payload => {
      onSubmit && onSubmit({ approach: payload.description });
    },
  });

  const [savingButton, setSavingButton] = useState(false);

  const onUpdateButtonClick = () => {
    setSavingButton(true);
  };

  // Re enable update button when there are errors
  useEffect(() => {
    if (Object.keys(errors).length >= 1) {
      setSavingButton(false);
    }
  }, [errors, savingButton]);

  useEffect(() => {
    if (!isOpen) {
      setSavingButton(false);
    }
  }, [isOpen]);

  useEffect(() => {
    if (!isOpen) {
      rest.handleReset({ description });
      rest.setErrors({});
    }
  }, [isOpen]);

  const setTouched = name => async () => {
    await rest.setTouched({ ...touched, [name]: true });
  };

  const renderLabel = label => (
    <Heading level={headingLevel.S} className={classes.label} weight={fontWeight.BOLD}>
      {label}
    </Heading>
  );

  return (
    <Drawer open={isOpen} onClose={onClose} variant={drawerType.NORMAL} className={classes.drawer}>
      <Box className={classes.wrapper}>
        <Box className={classes.header}>
          <Text className={classes.headerTitle}>Edit approach</Text>
          <IconButton icon="close" onClick={onClose} className={classes.closeBtn} />
        </Box>
        <form className={classes.form} onSubmit={handleSubmit}>
          <Box className={classes.formContent}>
            {error && (
              <Box display="flex" justifyContent="center" paddingY={2}>
                <TextError errorMsg={error} />
              </Box>
            )}
            <Box className={classes.section}>
              {renderLabel('Approach')}
              <Textarea
                value={values.description}
                name="description"
                onChange={handleChange}
                onBlur={setTouched('description')}
                minRows={5}
                placeholder="Enter approach"
              />
              <TextError errorMsg={touched.description ? errors.description?.toString() : null} />
            </Box>
          </Box>
          <Box className={classes.footer}>
            <Button variant={btnType.TEXT} onClick={onClose}>
              Cancel
            </Button>
            <Button
              onClick={() => {
                onUpdateButtonClick();
                handleSubmit();
              }}
              disabled={savingButton}
            >
              {savingButton ? 'Saving...' : 'Update'}
            </Button>
          </Box>
        </form>
      </Box>
    </Drawer>
  );
};

export { EditApproach };
