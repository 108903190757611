export const drawerPosition = {
  TOP: 'top',
  LEFT: 'left',
  BOTTOM: 'bottom',
  RIGHT: 'right',
};

export const drawerType = {
  NORMAL: 'NORMAL',
  FORM: 'FORM',
};

export const drawerWidth = {
  NORMAL: 'NORMAL',
  NARROW: 'NARROW',
};
