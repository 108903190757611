import { colors } from '../../../../../../../packages';
import dayjs from 'dayjs';

const IMPORTANCE_VALUES = {
  socialDeterminants: {
    CRITICAL: 6,
    HIGH: 5,
    MEDIUM: 4,
    RESOLVED: 3,
    LOW: 2,
    MISREPORTED: 1,
    UNRELATED: 0,
  },
  lifeEvents: {
    POSITIVE: 2,
    NEUTRAL: 1,
    NEGATIVE: 0,
  },
  symptoms: {
    CRITICAL: 6,
    HIGH: 5,
    MEDIUM: 4,
    RESOLVED: 3,
    LOW: 2,
    MISREPORTED: 1,
    UNRELATED: 0,
  },
  diagnoses: {
    ACTIVE: 4,
    POSSIBLE: 3,
    RESOLVED: 2,
    INACTIVE: 1,
    MISREPORTED: 0,
  },
  sideEffects: {
    CRITICAL: 6,
    HIGH: 5,
    MEDIUM: 4,
    RESOLVED: 3,
    LOW: 2,
    MISREPORTED: 1,
    UNRELATED: 0,
  },
  medications: {
    ACTIVE_EXTERNAL_RX: 3,
    ACTIVE: 2,
    INACTIVE_EXTERNAL_RX: 1,
    INACTIVE: 0,
  },
  substanceUse: {
    CRITICAL: 6,
    HIGH: 5,
    MEDIUM: 4,
    RESOLVED: 3,
    LOW: 2,
    MISREPORTED: 1,
    UNRELATED: 0,
  },
  'cognitive-issues': {
    CRITICAL: 4,
    HIGH: 3,
    MEDIUM: 2,
    LOW: 1,
    MISREPORTED: 0,
  },
};

const PRIORITY_CHART_STATUSES = {
  socialDeterminants: ['Unrelated', 'Misreported', 'Low', 'Resolved', 'Medium', 'High', 'Critical'],
  lifeEvents: ['Negative', 'Neutral', 'Positive'],
  symptoms: ['Unrelated', 'Misreported', 'Low', 'Resolved', 'Medium', 'High', 'Critical'],
  diagnoses: ['Misreported', 'Inactive', 'Resolved', 'Possible', 'Active'],
  sideEffects: ['Unrelated', 'Misreported', 'Low', 'Resolved', 'Medium', 'High', 'Critical'],
  medications: ['Inactive', 'Inactive external rx', 'Active', 'Active external rx'],
  substanceUse: ['Unrelated', 'Misreported', 'Low', 'Resolved', 'Medium', 'High', 'Critical'],
  'cognitive-issues': ['Misreported', 'Low', 'Medium', 'High', 'Critical'],
};

export const shortNumber = num => {
  if (num >= 1000000) {
    return `${(num / 1000000).toFixed(1)}M`;
  }
  if (num >= 1000) {
    return `${(num / 1000).toFixed(1)}K`;
  }
  return num.toString();
};

const PRIORITY_CARD_NAME_MAPING = {
  socialDeterminants: 'Social Determinants',
  lifeEvents: 'Life Events',
  symptoms: 'Symptom',
  diagnoses: 'Diagnosis',
  sideEffects: 'Side Effect',
  medications: 'Medication',
  substanceUse: 'Substance Use',
  'environmental-factor': 'Environmental Factor',
  'symptomatic-factor': 'Symptomatic Factor',
  'cognitive-issues': 'Cognitive Issues',
};

export const getChartConfig = ({ items, name }) => {
  const times = items.map(item => item.assignedAt).reverse();
  const yAxisList = PRIORITY_CHART_STATUSES[name];
  const importanceValues = IMPORTANCE_VALUES[name];
  return {
    options: {
      chart: {
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
      },
      title: {
        text: '',
      },
      plotOptions: {
        bar: {
          horizontal: false,
        },
      },
      colors: [colors.primary500],
      stroke: {
        width: 2,
        colors: [colors.primary500],
        curve: 'smooth',
      },
      xaxis: {
        categories: times,
        labels: {
          formatter: val => {
            return dayjs(val).format('MMM D');
          },
          style: {
            fontFamily: 'Manrope',
            fontWeight: 600,
          },
        },
        tooltip: {
          enabled: false,
        },
      },
      yaxis: {
        title: {
          text: undefined,
        },
        labels: {
          align: 'left',
          formatter: val => {
            return yAxisList[val];
          },
          minWidth: 60,
        },
        tickAmount: yAxisList?.length - 1,
        min: 0,
        max: yAxisList?.length - 1,
      },
      tooltip: {
        style: {
          fontFamily: 'Manrope',
        },
        custom: ({ series, dataPointIndex, seriesIndex }) => {
          const time = dayjs(times[dataPointIndex]).format('MMM DD, YYYY');
          return `
            <div class="chart-tooltip">
              <div class="date">${time}</div>
              <div class="level">${yAxisList[series[seriesIndex][dataPointIndex]]}</div>
            </div>
          `;
        },
        y: {
          formatter: shortNumber,
          show: false,
        },
      },
      fill: {
        opacity: 1,
      },
      legend: {
        show: true,
        horizontalAlign: 'left',
      },
    },
    series: [
      {
        name: PRIORITY_CARD_NAME_MAPING[name],
        data: items
          .map(item => {
            return {
              x: item.assignedAt,
              y: importanceValues[item.importanceLevel.name],
            };
          })
          .reverse(),
      },
    ],
  };
};
