import { getAuth } from '../../../../redux/modules/auth/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';
import { Drawer } from '../../../../packages/ui/organisms/drawer';
import { colors } from '../../../../packages/colors';
import { Rating } from '../../../../packages/ui/atoms/rating';
import * as scheduleServices from '../../../../services/schedule/schedule.service';

import Typography from '../../../../components/v2/Typography';
import { typography } from '../../../../components/v2/Typography/index.constant';
import { Button, btnType } from '../../../../packages/ui/atoms/button';
import { getDateLabels } from '../../../../utils/CommonUtils';
import { showSnackbar } from '../../../../redux/modules/snackbar';
import DrawerHead from './DrawerHead';
import ReviewCard from './Card';
import { useStyles } from '../index.styles';

const AllReviewsDrawer = ({ open, onClose }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { meta } = useSelector(getAuth);
  const [reviews, setReviews] = useState([]);
  const [page, setPage] = useState(0);
  const [reviewsData, setReviewsData] = useState({
    totalReviews: '0',
    averageRating: '0',
    lastReview: '0',
  });
  const [totalPages, setTotalPages] = useState(0);

  const fetchProviderReviews = async () => {
    try {
      const { data } = await scheduleServices.getProviderReviews({
        actor: meta.userId,
        pageNumber: page,
        pageSize: 10,
      });
      setPage(page + 1);
      setTotalPages(data?.totalPages);
      const reviewData = [...reviews, ...data?.feedbackList];
      setReviews(reviewData);
      setReviewsData({
        totalReviews: String(data?.totalReviews),
        averageRating: String(data?.averageRating),
        lastReview: String(reviewData[0]?.rating ?? 0),
      });
    } catch (e) {
      const message = e?.data?.message || 'Something went wrong';
      dispatch(
        showSnackbar({
          snackType: 'error',
          snackMessage: message,
        }),
      );
      console.warn(e);
    }
  };

  useEffect(() => {
    if (open) void fetchProviderReviews();
  }, [open]);

  return (
    <Drawer
      open={open}
      onClose={() => {
        setPage(0);
        setTotalPages(0);
        setReviews([]);
        setReviewsData({
          totalReviews: '0',
          averageRating: '0',
          lastReview: '0',
        });
        onClose();
      }}
    >
      <DrawerHead
        title="All reviews"
        onClose={() => {
          setPage(0);
          setTotalPages(0);
          setReviews([]);
          setReviewsData({
            totalReviews: '0',
            averageRating: '0',
            lastReview: '0',
          });
          onClose();
        }}
      />
      <Box p={3} display="flex" flexDirection="column" gap={3} sx={{ overflowY: 'auto' }}>
        <ReviewCard label="total reviews" count={reviewsData?.totalReviews} />
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <ReviewCard label="average rating" count={reviewsData?.averageRating} isRating />
          </Grid>
          <Grid item xs={12} md={6}>
            <ReviewCard label="last review" count={reviewsData?.lastReview} isRating />
          </Grid>
        </Grid>
        <Grid className={classes.dataContainer}>
          <Grid paddingBottom="10px">
            {reviews.map((_item, index) => (
              <Box key={index} display="flex" flexDirection="column" gap={1}>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Rating
                    value={Number(_item?.rating)}
                    readOnly
                    name="rating"
                    showValue={false}
                    className={classes.rating}
                  />
                  <Typography {...typography.body.s.medium} color={colors.neutral600}>
                    {getDateLabels(_item?.lastModified, 'MMMM DD, h:mm a')}
                  </Typography>
                </Box>
                <Typography {...typography.body.s.medium}>
                  {_item?.publicComment?.trim() === '' ? '-' : _item?.publicComment}
                </Typography>
              </Box>
            ))}
            {reviews.length === 0 && (
              <Grid className={classes.noResult}>
                <Typography {...typography.body.normal.small.medium} color={colors.neutral600}>
                  No reviews available
                </Typography>
              </Grid>
            )}
          </Grid>
          {totalPages !== 0 && page < totalPages && (
            <Box className={classes.btnContainer}>
              <Button
                variant={btnType.TEXT}
                className={classes.loadMoreBtn}
                onClick={() => void fetchProviderReviews()}
              >
                Load more
              </Button>
            </Box>
          )}
        </Grid>
      </Box>
    </Drawer>
  );
};

export default AllReviewsDrawer;
