import { FC, memo, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import clsx from 'clsx';

import { Drawer, drawerType } from '../../../../../packages/ui/organisms/drawer';
import { Input, inputType, inputSize } from '../../../../../packages/ui/atoms/input';
import { iconBtnType, IconButton } from '../../../../../packages/ui/molecules/icon-button';

import SymptomCard from '../../../member-detail/components/Symptoms/symptom-card';
import { useStyles } from '../history-drawer/HistoryDrawer.styles';
import EmptyState from '../empty-state';

const SymptomDrawer = memo(
  ({ open, isNoDrawer, onClose, onClickNewReport, onClickCard, isOnlyView, items = [], appointment }) => {
    const classes = useStyles();
    const [search, setSearch] = useState('');
    const [list, setList] = useState([]);

    useEffect(() => {
      const filteredList = items.filter(item => item.name.toLowerCase().includes(search.toLowerCase()));
      setList(filteredList);
    }, [search, items]);

    const renderContent = () => {
      return (
        <Box className={classes.container}>
          <Box className={classes.searchWrap}>
            <Input
              variant={inputType.SEARCH}
              className={classes.searchInput}
              placeholder="Search"
              value={search}
              size={inputSize.M}
              onChange={setSearch}
            />
            <IconButton
              variant={iconBtnType.PRIMARY}
              className={classes.reportNewBtn}
              onClick={onClickNewReport}
            >
              Report new
            </IconButton>
          </Box>
          <Box className={classes.items}>
            {list.length > 0 ? (
              list.map((item, index) => (
                <SymptomCard key={index} symptom={item} isOnlyView={isOnlyView} onClick={onClickCard} />
              ))
            ) : (
              <EmptyState
                title={`No Symptoms reported for ${appointment.participantName || 'this member'} yet.`}
                subtitle="If you've spoken with them about their symptoms, make sure you save that information here."
                glyph="face-talking-sad"
              />
            )}
          </Box>
        </Box>
      );
    };

    if (isNoDrawer) {
      return renderContent();
    }

    return (
      <Drawer
        open={open}
        title="Symptoms"
        subTitle={`${list.length} total`}
        onClose={onClose}
        variant={drawerType.FORM}
        className={clsx(classes.drawer, classes.drawerWithSubtitle)}
        footer={<span />}
      >
        {renderContent()}
      </Drawer>
    );
  },
);

SymptomDrawer.displayName = 'SymptomDrawer';

export { SymptomDrawer };
