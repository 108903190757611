// colors
import { colors } from '../../../../packages/colors';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  header: {
    paddingTop: 16,
    paddingBottom: 16,
    paddingLeft: 24,
    paddingRight: 24,
    border: `1px solid ${colors.neutral100}`,
    background: colors.white,
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
    position: 'relative',
  },
  close: {
    position: 'absolute',
    top: 20,
    right: 20,
    cursor: 'pointer',
  },
  content: {
    background: colors.neutral50,
    padding: 32,
    height: 'calc(100vh - 86px)',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
  },
  card: {
    border: `1px solid ${colors.neutral100}`,
    borderRadius: 8,
    boxShadow: '0px 0px 1px 0px #0000000A, 0px 0px 2px 0px #0000000F, 0px 4px 8px 0px #0000000A',
    background: colors.white,
    cursor: 'pointer',
  },
  cardContent: {
    padding: `8px 8px 16px 16px`,
    display: 'flex',
    flexDirection: 'column',
    borderBottom: `1px solid ${colors.neutral100}`,
  },
  cardFooter: {
    padding: 16,
    display: 'flex',
    gap: 24,
  },
  dflex: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 4,
    gap: 8,
  },
  progress: {
    fontSize: 12,
    lineHeight: '16px',
    color: colors.neutral600,
    margin: 0,
  },
  progressBar: {
    flex: 1,
    minWidth: 166,
    '& .MuiLinearProgress-bar': {
      backgroundColor: colors.primary500,
    },
  },
  badge: {
    borderRadius: 100,
    height: 18,
    fontSize: 12,
    lineHeight: '16px',
    fontWeight: 600,
  },
  text22: {
    fontWeight: 600,
    fontSize: 22,
    lineHeight: '28px',
  },
  text14: {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '20px',
  },
  text18: {
    fontWeight: 600,
    fontSize: 18,
    lineHeight: '24px',
  },
  text16: {
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '24px',
  },
  text16Second: {
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '24px',
    color: colors.neutral600,
  },
  text12: {
    fontWeight: 600,
    fontSize: 12,
    lineHeight: '16px',
  },
  menu: {
    float: 'right',
    padding: '8px 12px',
    '& svg': {
      width: 16,
      marginLeft: 'unset',
    },
  },
  menuItemsWrapper: {
    '& ul': {
      padding: 0,
    },
    '& ul li': {
      padding: '10px 16px',
      '&:last-child': {
        color: colors.destructive500,
        '& svg path': {
          fill: colors.destructive500,
        },
      },
    },
    '& .menu-item-custom': {
      borderBottom: `1px solid ${colors.neutral100}`,
    },
  },
});
