import { FETCH_USER_ACTIVITY, FETCH_USER_ACTIVITY_FAILED, FETCH_USER_ACTIVITY_SUCCESSFUL } from './actions';

export const DEFAULT = {
  isLoading: false,
  errorMsg: '',
  activity: {},
};

export default function auditReducer(state = DEFAULT, action) {
  const { type, payload } = action;

  switch (type) {
    case FETCH_USER_ACTIVITY: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case FETCH_USER_ACTIVITY_FAILED: {
      return {
        ...state,
        isLoading: false,
        activity: {},
      };
    }
    case FETCH_USER_ACTIVITY_SUCCESSFUL: {
      return {
        ...state,
        isLoading: false,
        activity: payload,
      };
    }
    default: {
      return state;
    }
  }
}
