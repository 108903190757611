import { makeStyles } from '@mui/styles';
import { colors } from '../../../packages/colors';

export const useStyles = makeStyles({
  dropdownItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 8,
    fontWeight: 700,
    width: '100%',
    fontSize: 14,
  },
  avatar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  logo: {
    width: 40,
    minWidth: 40,
    height: 40,
    minHeight: 40,
  },
  primary: {
    height: 40,
    padding: '8px 16px !important',
    fontSize: 14,
    lineHeight: '20px',
  },
  outline: {
    height: 40,
    color: '#0365C1',
    borderColor: '#8EC8FD',
    padding: '8px 16px !important',
    fontSize: 14,
    lineHeight: '20px',
  },
  btnContainer: {
    display: 'flex',
    gap: 16,
    justifyContent: 'flex-end',
    minWidth: '255px',
  },
  linkBtn: {
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Manrope',
    fontWeight: 600,
    fontSize: 14,
    lineHeight: '19px',
    color: colors.primary600,
    transition: '0.4s',
    height: 'unset !important',
    '&:hover': {
      opacity: 0.8,
    },
    padding: '0px !important',
  },
  linkIcon: {
    marginRight: '8px',
  },
  btnText: {
    gap: 13,
  },
  mentionUserList: {
    border: `1px solid ${colors.neutral100}`,
    borderRadius: 6,
    boxShadow: '0px 0px 1px 0px #0000000A, 0px 2px 6px 0px #0000000A, 0px 10px 20px 0px #0000000A',
    background: 'white',
    position: 'absolute',
    maxWidth: 280,
    overflowY: 'auto',
    listStyleType: 'none',
    padding: 0,
    bottom: '9rem',
    left: '1rem',
    zIndex: 1,
  },
  mentionUserListData: {
    height: 62,
    padding: '8px 16px 8px 16px',
    cursor: 'pointer',
  },
  focused: {
    backgroundColor: colors.primary100,
  },
  member: {
    display: 'flex',
    alignItems: 'center',
    gap: 12,
  },
  info: {
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
  },
  badge: {
    borderRadius: 20,
    height: 18,
    padding: '2px 8px',
    fontSize: 12,
    width: 'fit-content',
    border: 'none',
    marginTop: 2,
    maxWidth: 160,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  userName: {
    width: 160,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: 14,
    fontWeight: 600,
    lineHeight: '20px',
  },
  inputHeaderFooter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '1rem',
    borderTop: `1px solid #E5EBF0`,
    backgroundColor: '#fff',
  },
  inputHeaderLeft: {
    display: 'flex',
    gap: '4px',
    '& .inputHeaderText': {
      fontSize: '14px',
      fontWeight: 700,
    },
    '& svg': {
      width: 20,
      height: 20,
    },
  },
  inputHeaderRight: {
    fontSize: '12px',
    gap: '4px',
    '& svg': {
      width: 20,
      height: 20,
    },
  },
  deactive: {
    color: colors.neutral500,
    '& svg': { color: colors.neutral500 },
  },
  inputBody: {
    display: 'flex',
    flexDirection: 'column',
    borderTop: `1px solid #E5EBF0`,
    backgroundColor: '#fff',
    gap: '12px',
    '& .MuiInput-root': { fontSize: 12, padding: 0 },
  },
  inputBodyBackground: {
    backgroundColor: colors.neutral25,
  },
  messageInputIcons: {
    marginRight: '16px',
    fontSize: '12px',
    color: colors.primary500,
    '& svg': {
      width: 16,
      marginRight: '4px',
      '& path': {
        fill: colors.primary500,
      },
    },
  },
  sendBtn: {
    border: `1px solid ${colors.primary500}`,
    color: colors.primary500,
    fontSize: '12px',
    padding: '8px 12px',
    '& svg': {
      width: 16,
      '& path': {
        fill: colors.primary500,
      },
    },
  },
  deactiveBtn: {
    opacity: 0.4,
    backgroundColor: '#fff !important',
    color: `${colors.primary500} !important`,
  },
  menuItemsWrapper: {
    padding: 0,
  },
  removeAttachmentButton: {
    color: colors.secondary500,
    fontSize: 12,
    lineHeight: '16px',
    fontWeight: 700,
    padding: 0,
    height: 'unset !important',
    justifyContent: 'left',
  },
  fileAttachmentText: {
    fontWeight: 700,
    maxWidth: '140px',
    overflow: 'hidden',
    display: 'inline-block',
    marginRight: '5px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
});
