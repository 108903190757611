/* eslint-disable no-nested-ternary */
import { useEffect, useState } from 'react';
import cx from 'clsx';
import { Collapse, Box } from '@mui/material';
import { useFormik } from 'formik';

import {
  fontWeight,
  Heading,
  headingLevel,
  Text,
  TextError,
} from './../../../../../../packages/ui/atoms/typography';
import { FormControlLabel } from './../../../../../../packages/ui/atoms/form-control-label';
import { Checkbox } from './../../../../../../packages/ui/atoms/checkbox';
import { Textarea } from './../../../../../../packages/ui/atoms/textarea';
import { Button } from './../../../../../../packages/ui/atoms/button';
import { Input, inputSize, inputType } from './../../../../../../packages/ui/atoms/input';
import { iconBtnStyle, iconBtnType, IconButton } from './../../../../../../packages/ui/molecules/icon-button';
import { Badge, badgeStyle, badgeType } from './../../../../../../packages/ui/atoms/badge';
import { DomainTypesEnum } from './../../../../../../constants/CommonConstants';

import SelectLevel from '../../select-level';
// types

// styles
import { useStyles } from './ReportFormCollapse.styles';
import { SymptomEditSchema } from '../../Symptoms/symptom-editing/SymptomEditing.schema';
import { reportItemProgress } from '../../../MemberDetail.constants';

const defaultValues = {
  status: '',
  notes: '',
  icd10: '',
  medicalConditions: [],
  isInterference: false,
};

const ReportFormCollapse = ({
  open = false,
  showInterference,
  type,
  symptom,
  onChange,
  onRemoveClick,
  onOpenCollapse,
  options,
  medicalConditions,
}) => {
  const medicalConditionList = medicalConditions?.map(condition => condition?.value);
  const [collapsed, setCollapsed] = useState(open || false);
  const [importanceLevels, setImportanceLevels] = useState([]);
  const [icd10codes, setIcd10] = useState([]);
  const classes = useStyles();

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
    onOpenCollapse && onOpenCollapse(!collapsed);
  };

  useEffect(() => {
    const selectedDiagnosis = options?.filter(option => option?.groupId === symptom?.groupId);
    const selectedRelatedElement = selectedDiagnosis[0].relatedElements?.filter(
      element => element.Id === symptom.item.value,
    );

    const tempImportanceLevels = [];
    const tempIcd10List = [];
    Object.keys(selectedRelatedElement[0]?.metaData?.metaDataSpec?.importanceLevels).forEach(key => {
      if (selectedRelatedElement[0]?.metaData?.metaDataSpec?.importanceLevels[key]) {
        tempImportanceLevels.push({ value: key, label: key });
      }
    });
    if (selectedRelatedElement[0]?.metaData?.metaDataValues?.icd10Codes) {
      selectedRelatedElement[0]?.metaData?.metaDataValues?.icd10Codes.forEach(val => {
        tempIcd10List.push({ value: val, label: val });
      });

      // setIcd10(selectedRelatedElement[0]?.metaData?.metaDataValues?.icd10Codes);
    }
    setImportanceLevels(tempImportanceLevels);
    setIcd10(tempIcd10List);
  }, []);

  const { errors, values, handleChange, handleSubmit, touched, setFieldValue, ...rest } = useFormik({
    initialValues: { ...defaultValues },
    validationSchema: SymptomEditSchema,
    onSubmit: payload => {
      onChange(payload);
    },
  });

  useEffect(() => {
    if (symptom) {
      Object.keys(symptom).forEach(async key => {
        if (type === 'Life Events' && key === 'status') {
          await setFieldValue(key, 'NEGATIVE');
        } else if (type === 'Diagnoses' && key === 'status') {
          await setFieldValue(key, 'MISREPORTED');
        } else {
          await setFieldValue(key, symptom[key]);
        }
      });
    }
    if (symptom.progress) {
      setCollapsed(symptom.progress === reportItemProgress.InProgress);
    }
    if (type === 'Diagnoses') {
      void setFieldValue('icd10', icd10codes?.length !== 0 ? icd10codes[0]?.value : '');
    }
  }, [symptom]);

  useEffect(() => {
    setCollapsed(open || false);
  }, [open]);

  const setTouched = name => async () => {
    await rest.setTouched({ ...touched, [name]: true });
  };

  const getType = () => {
    if (type) {
      if (type === 'Diagnoses') {
        return 'diagnosis';
      }
      if (type.charAt(type.length - 1) === 's') {
        return type.substring(0, type.length - 1);
      }
      return type;
    }
    return 'symptom';
  };

  const renderLabel = str => (
    <Heading level={headingLevel.S} className={classes.label} weight={fontWeight.BOLD}>
      {str}
    </Heading>
  );

  return (
    <Box className={classes.root}>
      <Box className={cx(classes.header, { [classes.headerBorder]: collapsed })} onClick={toggleCollapsed}>
        <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
          <Text className={classes.headTitle}>{symptom.item.label}</Text>
          <Badge
            variant={badgeType.OUTLINED}
            className={symptom?.progress === 'In progress' ? classes.badge2 : classes.badge}
            style={
              symptom.progress === reportItemProgress.Completed
                ? badgeStyle.SUCCESS
                : symptom.progress === reportItemProgress.Incomplete
                ? badgeStyle.MISREPORTED
                : badgeStyle.CRITICAL
            }
          >
            {symptom.progress}
          </Badge>
        </Box>
      </Box>
      <Collapse in={collapsed}>
        <form className={classes.form} onSubmit={handleSubmit}>
          <Box className={classes.formContent}>
            <Box className={classes.section}>
              {renderLabel('Importance level')}
              <SelectLevel
                value={values?.status}
                isLifeEvent
                options={importanceLevels}
                onChange={handleChange}
                name="status"
              />
            </Box>
            {icd10codes.length > 0 && (
              <Box className={classes.section}>
                {renderLabel('Icd 10 codes')}
                <SelectLevel
                  name="icd10"
                  value={values.icd10}
                  isLifeEvent
                  options={icd10codes}
                  onChange={handleChange}
                />
              </Box>
            )}
            {showInterference && (
              <Box className={classes.section}>
                {renderLabel('Interference with life')}
                <FormControlLabel
                  control={<Checkbox />}
                  checked={values.isInterference}
                  name="isInterference"
                  onChange={() => {
                    void setFieldValue('isInterference', !values.isInterference);
                  }}
                  className={classes.checkboxLabel}
                  label="This symptom has interference with patient’s life"
                />
              </Box>
            )}

            <Box className={classes.section}>
              {renderLabel('Report notes')}
              <Textarea
                value={values.notes}
                name="notes"
                onChange={handleChange}
                onBlur={setTouched('notes')}
                minRows={5}
                placeholder="Enter your notes"
              />
              <TextError errorMsg={touched.notes ? errors.notes?.toString() : null} />
            </Box>

            {medicalConditionList?.length > 0 && type === DomainTypesEnum.SYMPTOMS && (
              <>
                {renderLabel('Related info')}
                <Box className={classes.section}>
                  {renderLabel('Related to medical conditions')}
                  <Input
                    // freeSolo
                    fullWidth
                    name="medical conditions"
                    value={values.medicalConditions}
                    options={medicalConditionList}
                    variant={inputType.TAGS}
                    size={inputSize.M}
                    getOptionLabel={option => option}
                    onBlur={setTouched('medicalConditions')}
                    onChange={async (_, newValue) => {
                      await setFieldValue('medicalConditions', newValue);
                    }}
                  />
                  {/* <TextError errorMsg={touched.symptoms ? errors.symptoms?.toString() : null} /> */}
                </Box>
              </>
            )}
          </Box>
          <Box className={classes.footer}>
            <IconButton
              icon="delete-outlined-2"
              className={classes.removeBtn}
              variant={iconBtnType.TEXT}
              style={iconBtnStyle.SECONDARY}
              onClick={onRemoveClick}
            >
              Remove this {getType().toLocaleLowerCase()}
            </IconButton>
            <Button onClick={handleSubmit}>Done</Button>
          </Box>
        </form>
      </Collapse>
    </Box>
  );
};

export { ReportFormCollapse };
