import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Stack } from '@mui/material';
import {
  Badge,
  badgeStyle,
  badgeType,
  colors,
  fontWeight,
  Heading,
  headingLevel,
  iconBtnType,
  IconButton,
  Icons,
  Menu,
  Text,
} from '../../../packages';
import { Filter, Table } from '../../../packages/ui/organisms/table';
import { ConfirmationModal } from '../../../packages/ui/templates/confirmation-modal';
import { BaseLayout } from '../../../layouts/base';
import { priorityActionCreators } from '../../../redux/modules/priority';
import { getPriority } from '../../../redux/modules/priority/selectors';
import { stateActionCreators } from '../../../redux/modules/state';
import AddEditPriorityElement from './add-edit-elements';
import { filterOptionsMock, multiSelectFilterOptionsMock, tableColumns } from './PriorityElements.constants';
import { useStyles } from './PriorityElements.styles';

const PriorityElements = () => {
  const [showAddNew, setShowAddNew] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [tableParams, setTableParams] = useState({
    search: { searchKey: '' },
    pagination: { currentPage: 1, rowsPerPage: 10 },
    sorter: { direction: 'asc', column: '' },
  });
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    isLoading,
    elementsPayload: { totalRecords, domainElements },
    typesPayload: { domainTypes },
    profileElementsPayload: { profileElements },
    lookupData,
  } = useSelector(getPriority);

  const toggleAddNew = () => {
    setShowAddNew(!showAddNew);
  };

  const handleFetchElementsParamsFormat = ({ search, pagination, sorter }) => {
    const multiselectFilters = tableParams.search?.multiSelectFilter;
    const sortBy = sorter.column || '';
    const typeIds = [];
    if (multiselectFilters?.['Domain Type']) {
      multiselectFilters['Domain Type'].forEach(filter => {
        switch (filter) {
          case 'Diagnoses':
            typeIds.push(domainTypes.find(domainType => domainType.name === 'Diagnoses')?.Id);
            break;
          case 'Symptoms':
            typeIds.push(domainTypes.find(domainType => domainType.name === 'Symptoms')?.Id);
            break;
          case 'Social Determinants':
            typeIds.push(domainTypes.find(domainType => domainType.name === 'Social Determinants')?.Id);
            break;
          case 'Substance Use':
            typeIds.push(domainTypes.find(domainType => domainType.name === 'Substance Use')?.Id);
            break;
          case 'Medications':
            typeIds.push(domainTypes.find(domainType => domainType.name === 'Medications')?.Id);
            break;
          case 'Side Effects':
            typeIds.push(domainTypes.find(domainType => domainType.name === 'Side Effects')?.Id);
            break;
          case 'Life Events':
            typeIds.push(domainTypes.find(domainType => domainType.name === 'Life Events')?.Id);
            break;
          default:
            break;
        }
      });
    }

    const typeIdsStringFormat = typeIds.join(',');
    const params = {
      searchQuery: search.searchKey,
      pageNumber: pagination.currentPage,
      pageSize: pagination.rowsPerPage,
      orderBy: sorter.direction,
      typeIds: typeIdsStringFormat,
      sortBy,
    };
    return params;
  };

  const closeConfirmation = () => {
    setSelectedRecord(null);
    setShowConfirmModal(false);
  };

  const fetchDomainElements = () => {
    dispatch(priorityActionCreators.fetchPriorityDomainTypes());
    dispatch(priorityActionCreators.fetchPriorityDomainProfileElements());
    dispatch(priorityActionCreators.fetchPriorityLookup());
    dispatch(
      priorityActionCreators.fetchPriorityDomainElements(handleFetchElementsParamsFormat(tableParams)),
    );
    dispatch(stateActionCreators.fetchICD10());
  };

  useEffect(() => {
    fetchDomainElements();
  }, [tableParams]);

  const onDeleteElement = () => {
    dispatch(
      priorityActionCreators.deletePriorityDomainElement({
        data: selectedRecord?.Id,
        callback: isSucceed => {
          if (isSucceed) {
            fetchDomainElements();
          }
          closeConfirmation();
        },
      }),
    );
  };

  const renderCheckIcon = val => (
    <Icons
      glyph={val ? 'checkmark' : 'close-circle-outlined'}
      color={val ? colors.success500 : colors.destructive500}
    />
  );

  const renderDomainTypeName = typeId => {
    if (domainTypes && domainTypes.length > 0) {
      const filterDomainTypes = domainTypes.filter(dataDomainType => dataDomainType?.Id === typeId);
      if (filterDomainTypes && filterDomainTypes.length > 0) {
        return filterDomainTypes[0].name;
      }
    }
    return '';
  };

  const getObjectSize = obj => {
    if (obj) {
      let size = 0;
      Object.keys(obj).forEach(key => {
        if (key) {
          size++;
        }
      });
      return size.toString().concat(` key : value pairs`);
    }
    return 'N/A';
  };

  const renderRxDrugsInfo = rxDrugInfo => {
    const hasRx = rxDrugInfo && Object.keys(rxDrugInfo).length > 0;
    return renderCheckIcon(hasRx);
  };

  const renderName = name => {
    return (
      <Text className={classes.name} weight={fontWeight.MEDIUM}>
        {name}
      </Text>
    );
  };

  const renderColumns = tableColumns.map(column => {
    if (column.id === 'name') {
      return {
        ...column,
        renderCell: renderName,
      };
    }
    if (column.id === 'type') {
      return {
        ...column,
        renderCell: renderDomainTypeName,
      };
    }
    if (column.id === 'importanceMapping') {
      return {
        ...column,
        renderCell: getObjectSize,
      };
    }
    if (column.id === 'rxDrugsInfo') {
      return {
        ...column,
        renderCell: renderRxDrugsInfo,
      };
    }
    if (column.id === 'relatedToMapping') {
      return {
        ...column,
        renderCell: getObjectSize,
      };
    }
    if (column.id === 'interferenceMapping') {
      return {
        ...column,
        renderCell: getObjectSize,
      };
    }
    if (column.id === 'actions') {
      return {
        ...column,
        renderCell: data => (
          <Menu
            icon="more"
            className={classes.menu}
            itemsWrapperClassName={classes.menuItemsWrapper}
            items={[
              {
                label: 'Edit',
                onClick: () => {
                  setSelectedRecord(data);
                  setShowAddNew(true);
                },
              },
              {
                label: 'Delete',
                onClick: () => {
                  setSelectedRecord(data);
                  setShowConfirmModal(true);
                },
              },
            ]}
          />
        ),
      };
    }
    return column;
  });

  return (
    <>
      {showAddNew && (
        <AddEditPriorityElement
          isOpen={showAddNew}
          onClose={() => {
            setSelectedRecord(null);
            toggleAddNew();
          }}
          fetchDomainElements={fetchDomainElements}
          editData={selectedRecord}
          domainTypes={domainTypes}
          profileElements={profileElements}
          lookupMap={lookupData}
        />
      )}
      <ConfirmationModal
        open={showConfirmModal}
        onClose={closeConfirmation}
        onSave={onDeleteElement}
        title="Delete Domain Element"
        message="Are you sure?"
      />
      <BaseLayout>
        <div className={classes.root}>
          <Stack direction="row" justifyContent="space-between" spacing={2}>
            <Stack direction="row" alignItems="center" gap={2}>
              <Heading className={classes.heading} level={headingLevel.XL} weight={fontWeight.BOLD}>
                Data domain elements
              </Heading>
              <Badge
                className={classes.totalMemberBadge}
                variant={badgeType.OUTLINED}
                style={badgeStyle.UNRELATED}
              >
                {totalRecords || 0} total
              </Badge>
            </Stack>

            <IconButton
              className={classes.addBtn}
              icon="plus"
              variant={iconBtnType.PRIMARY}
              onClick={toggleAddNew}
            >
              Add element
            </IconButton>
          </Stack>
          <Table
            searchProps={{
              placeholder: 'Search name',
              filterProps: {
                variant: Filter.tableFilterType.MULTIPLE,
                options: filterOptionsMock,
                allOptionLabel: 'All elements',
                multiSelectOptions: multiSelectFilterOptionsMock,
              },
            }}
            gridProps={{
              columns: renderColumns,
              data: domainElements,
              isLoading,
            }}
            paginationProps={{
              currentRows: domainElements?.length || 0,
              totalCount: totalRecords,
              showRowsPerPage: true,
            }}
            value={tableParams}
            onChange={setTableParams}
            className={classes.table}
          />
        </div>
      </BaseLayout>
    </>
  );
};

export { PriorityElements };
