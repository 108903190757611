import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import clsx from 'clsx';

import { ButtonGroup, Stack } from '@mui/material';
import {
  Badge,
  badgeStyle,
  badgeType,
  Button,
  fontWeight,
  Heading,
  headingLevel,
  iconBtnType,
  IconButton,
  Link,
  Menu,
  Text,
} from '../../../packages';
import { Filter, Table } from '../../../packages/ui/organisms/table';
// API calls
import {
  addEvaluationDct,
  updateDCTScoring,
  updateEvaluationDCT,
} from '../../../services/conversation/conversation.service';
import { conversationActionCreators } from '../../../redux/modules/conversation';
import { selectDctState } from '../../../redux/modules/conversation/selectors';
import { showSnackbar } from '../../../redux/modules/snackbar';

// components
import history from '../../../utils/history';

import { BaseLayout } from '../../../layouts/base';
import AddCollectionTemplate from '../../../pages/admin/evaluation-dcts/components/add-collection-template';
import DctScoring from '../../../pages/admin/data-collection-templates/components/dct-scoring';
import { CONVERSATION_TYPES } from '../../../constants/CommonConstants';
import {
  filterOptionsMock,
  templatesColumns,
  viewGroups,
} from '../data-collection-templates/DataCollectionTemplates.mock';
import { useStyles } from '../data-collection-templates/DataCollectionTemplates.styles';
import { DCTMultiSelectFilterOptionsMock } from './EvaluationDcts.mock';

const EvaluationDcts = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [tableParams, setTableParams] = useState({
    search: { searchKey: '' },
    pagination: { currentPage: 1, rowsPerPage: 10 },
    sorter: { direction: 'desc', column: '' },
  });

  const { isLoading, dcts, totalRecords } = useSelector(selectDctState);
  const [viewBy, setViewBy] = useState(viewGroups[0].type);
  const [openDctDrawer, setOpenDctDrawer] = useState(false);
  const [openDctScoringDrawer, setOpenDctScoringDrawer] = useState(false);
  const [selectedDct, setSelectedDct] = useState({ dctId: '' });
  const [dctList, setDctList] = useState([]);

  /**
   * @function onChangeView
   * @params type
   * @description This method is used to handle toggle between ACTIVE and DRAFT DCT Lists.
   */
  const onChangeView = type => () => {
    setViewBy(type);
    setTableParams({
      search: { searchKey: '' },
      pagination: { currentPage: 1, rowsPerPage: 10 },
      sorter: { direction: 'desc', column: '' },
    });
  };

  const onAddClick = () => {
    setSelectedDct(null);
    setOpenDctDrawer(true);
  };

  /**
   * @function onEditClick
   * @params dctId
   * @description This method is used to handle Edit menu option click.
   */
  const onEditClick = dctId => () => {
    setSelectedDct(dcts.find(template => template.dctId === dctId));
    setOpenDctDrawer(true);
  };

  const onEditScoringClick = dctId => () => {
    setSelectedDct(dcts.find(template => template.dctId === dctId));
    setOpenDctScoringDrawer(true);
  };

  /**
   * @function onRowClick
   * @params rowItem
   * @description This method is used to redirect to DctDetails page with Selected DCT's Id.
   */
  const onRowClick = rowItem => {
    history.push(
      `/admin/collection-evaluation-dcts/${rowItem.dctId}/${rowItem.version}?name=assignments&isDraft=${
        viewBy === viewGroups[1].type
      }`,
      {
        from: CONVERSATION_TYPES.EVALUATION_DCT,
      },
    );
  };
  const renderColumns = templatesColumns?.map(column => {
    if (column.id === 'name') {
      return {
        ...column,
        renderCell: ({ templateId, name, version }) => (
          <Link
            className={classes.link}
            to={`/admin/collection-evaluation-dcts/${templateId}/${version}?isDraft=${
              viewBy === viewGroups[1].type
            }`}
          >
            <Text className={classes.name} weight={fontWeight.MEDIUM}>
              {name}
            </Text>
          </Link>
        ),
      };
    }
    if (column.id === 'scorable') {
      return {
        ...column,
        renderCell: scorable => (
          <Badge
            className={clsx(classes.badge, { [classes.badgeActive]: scorable })}
            variant={badgeType.FILLED}
          >
            {scorable ? 'Scorable' : 'Not Scorable'}
          </Badge>
        ),
      };
    }
    if (column.id === 'actions') {
      return {
        ...column,
        renderCell: ({ id: dctId }) => (
          <Menu
            icon="more"
            className={classes.menu}
            itemsWrapperClassName={classes.menuItemsWrapper}
            items={[
              { label: 'Edit', onClick: onEditClick(dctId) },
              { label: 'Edit Scoring', onClick: onEditScoringClick(dctId) },
            ]}
          />
        ),
      };
    }
    return column;
  });

  useEffect(() => {
    const formatDcts = dcts.map(
      ({ contentBlocksCount, lastUpdated, usedInConversations, assignmentCount, name, ...item }) => ({
        ...item,
        name: { templateId: item.dctId, name, version: item.version },
        cbs: `${contentBlocksCount} CB's`,
        lastUpdated: dayjs(lastUpdated).format('MM/DD/YYYY'),
        usedIn: `${usedInConversations?.length || 0} ${
          usedInConversations?.length > 1 ? 'Conversations' : 'Conversation'
        }`,
        assignments: `${assignmentCount} ${assignmentCount > 1 ? 'Assignments' : 'Assignment'}`,
        actions: { id: item?.dctId },
      }),
    );
    setDctList(formatDcts);
  }, [dcts]);

  const getDCTs = () => {
    const multiselectFilters = tableParams.search?.multiSelectFilter;
    const { pagination, search, sorter } = tableParams;
    const aiGenerated =
      multiselectFilters?.Creation && multiselectFilters?.Creation?.includes('AI Generated');
    const queryParams = {
      orderBy: sorter.direction,
      pageNumber: pagination.currentPage - 1,
      pageSize: pagination.rowsPerPage,
      searchQuery: search.searchKey,
      sortBy: [sorter.column],
      status: viewBy,
      type: CONVERSATION_TYPES.EVALUATION,
      isScorable: multiselectFilters?.Scorable?.length
        ? multiselectFilters?.Scorable[0] === 'Scorable'
        : null,
      isAiGenerated: !!aiGenerated,
    };
    dispatch(conversationActionCreators.fetchDCTs(queryParams));
  };

  /**
   * @function saveUpdateDct
   * @params dctRequestPayload
   * @description This method is used to save or update DCT.
   */
  const saveUpdateDct = dctRequestPayload => {
    if (selectedDct) {
      updateEvaluationDCT(selectedDct.dctId, dctRequestPayload)
        .then(() => {
          setOpenDctDrawer(false);
          getDCTs();
          dispatch(
            showSnackbar({
              snackType: 'success',
              snackMessage: 'Dct successfully updated',
            }),
          );
        })
        .catch(err => {
          dispatch(
            showSnackbar({
              snackType: 'error',
              snackMessage: err?.data?.errors[0]?.endUserMessage || 'Something went wrong!',
            }),
          );
        });
    } else {
      addEvaluationDct({ ...dctRequestPayload, type: 'EVALUATION' })
        .then(() => {
          dispatch(
            showSnackbar({
              snackType: 'success',
              snackMessage: 'Dct successfully added',
            }),
          );
          setOpenDctDrawer(false);
          getDCTs();
        })
        .catch(err => {
          dispatch(
            showSnackbar({
              snackType: 'error',
              snackMessage: err?.data?.errors[0]?.endUserMessage || 'Something went wrong!',
            }),
          );
        });
    }
  };

  const updateDctScoring = scoring => {
    setOpenDctScoringDrawer(false);
    updateDCTScoring(scoring, selectedDct.dctId)
      .then(() => {
        dispatch(
          showSnackbar({
            snackType: 'success',
            snackMessage: 'Dct scoring successfully updated',
          }),
        );
      })
      .catch(err => {
        dispatch(
          showSnackbar({
            snackType: 'error',
            snackMessage: err?.data?.errors[0]?.endUserMessage || 'Something went wrong!',
          }),
        );
      });
  };

  useEffect(() => {
    getDCTs();
  }, [viewBy, tableParams]);

  return (
    <>
      <AddCollectionTemplate
        dct={selectedDct}
        isOpen={openDctDrawer}
        onClose={() => setOpenDctDrawer(false)}
        onSubmit={saveUpdateDct}
      />
      <DctScoring
        dctId={selectedDct?.dctId}
        isOpen={openDctScoringDrawer}
        onClose={() => setOpenDctScoringDrawer(false)}
        onSubmit={updateDctScoring}
      />
      <BaseLayout>
        <div className={classes.root}>
          <Stack direction="row" justifyContent="space-between" spacing={2}>
            <Stack direction="row" alignItems="center" gap={2}>
              <Heading className={classes.heading} level={headingLevel.XL} weight={fontWeight.BOLD}>
                Evaluation DCTs
              </Heading>
              {!!totalRecords && (
                <Badge
                  className={classes.totalMemberBadge}
                  variant={badgeType.OUTLINED}
                  style={badgeStyle.UNRELATED}
                >
                  {totalRecords} total
                </Badge>
              )}
            </Stack>

            <IconButton
              className={classes.addProfileBtn}
              icon="plus"
              variant={iconBtnType.PRIMARY}
              onClick={onAddClick}
            >
              Add new
            </IconButton>
          </Stack>
          <Table
            searchProps={{
              placeholder: 'Search evaluation DCT by name',
              filterProps: {
                variant: Filter.tableFilterType.MULTIPLE,
                options: filterOptionsMock,
                multiSelectOptions: DCTMultiSelectFilterOptionsMock,
                allOptionLabel: 'All template',
              },
              leftSearchComponent: (
                <ButtonGroup variant="outlined" aria-label="outlined button group">
                  {viewGroups.map(({ type, label }) => (
                    <Button
                      key={type}
                      className={clsx(classes.btnGroupIcon, {
                        [classes.btnGroupIconActive]: type === viewBy,
                      })}
                      onClick={onChangeView(type)}
                    >
                      {label}
                    </Button>
                  ))}
                </ButtonGroup>
              ),
            }}
            gridProps={{
              columns: renderColumns,
              data: dctList,
              isLoading,
              onRowClick,
            }}
            paginationProps={{
              currentRows: tableParams.pagination.rowsPerPage,
              totalCount: totalRecords,
              showRowsPerPage: true,
            }}
            value={tableParams}
            onChange={setTableParams}
            className={classes.table}
          />
        </div>
      </BaseLayout>
    </>
  );
};

export { EvaluationDcts };
