import AssignmentDetail from './components/assignments';
import CompletionDetail from './components/completions';
import ConversationDetail from './components/detail';

const SelectedComponent = ({ selectedScreen, version, dct }) => {
  switch (selectedScreen) {
    case 'assignments':
      return <AssignmentDetail version={version} dct={dct} />;
    case 'responses-completion':
      return <CompletionDetail />;
    default:
      return <ConversationDetail />;
  }
};

export default SelectedComponent;
