import React from 'react';
import clsx from 'clsx';
import { Box } from '@mui/material';
import { Badge, badgeStyle, badgeType, iconBtnType, IconButton, Text } from '../../../../../../packages';
import { useSelector } from 'react-redux';
import { DomainPriorities } from '../../../../../../redux/modules/profile/types';
import { getAuth } from '../../../../../../redux/modules/auth/selectors';
import { getProfile } from '../../../../../../redux/modules/profile/selectors';
import { MedicationBottomCard } from './MedicationBottomCard';
import { ProfileInfoRole } from '../../../../../../components/v2/ProfileInfo';
import { useStyles } from './MedicationCard.styles';

const MedicationCard = ({ medication, isOnlyView, onClick }) => {
  const classes = useStyles();
  const { isAdmin } = useSelector(getAuth);
  const { profile } = useSelector(getProfile);

  const onClickTitle = () => {
    onClick && onClick(medication, false);
  };

  const onClickEdit = () => {
    onClick && onClick(medication, true);
  };

  return (
    <Box className={classes.card}>
      <Box className={classes.rowCenter} sx={{ p: 3 }}>
        <Box className={classes.topLeft}>
          <Box className={classes.topLeftHead}>
            <Text className={classes.title} onClick={onClickTitle}>
              {medication.name}
            </Text>
            <Badge
              variant={badgeType.FILLED}
              className={clsx(classes.badge, {
                [classes.badgeMisreported]: medication.priority.name === DomainPriorities.MISREPORTED,
              })}
              style={badgeStyle[medication.priority.name] || badgeStyle.UNRELATED}
            >
              {medication.priority.name}
            </Badge>
          </Box>
          <Text className={classes.subtitle}>
            {medication.tagMetaData?.rxDrugInfo.dosage} {medication.tagMetaData.rxDrugInfo.frequency}
          </Text>
          {/* <Box>
            <Text className={classes.title2}>{medication.description?.toLocaleLowerCase()}</Text>
          </Box> */}
        </Box>
        <Box className={classes.topRight}>
          {/* <IconButton
            icon="clock-rotate"
            variant={iconBtnType.TEXT}
            className={classes.btn}
            onClick={onClickTitle}
          >
            See All Reports ({(medication.history ?? []).length + 1})
          </IconButton> */}
          {!isOnlyView &&
            (profile?.designation === ProfileInfoRole.NURSE_PRACTITIONER ||
              profile?.designation === ProfileInfoRole.PHYSICIAN ||
              isAdmin) && (
              <IconButton variant={iconBtnType.SECONDARY} className={classes.btn} onClick={onClickEdit}>
                Update
              </IconButton>
            )}
        </Box>
      </Box>
      <MedicationBottomCard medication={medication} />
    </Box>
  );
};

export { MedicationCard };
