import { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { colors } from '../../../packages';

import AverageFrame from '../../../components/v2/frame/AverageFrame';
import {
  getAllPhq9Gad7AverageScore,
  getPhq9Gad7AverageScoreSummary,
} from '../../../services/reporting/reporting.service';
import { useDispatch, useSelector } from 'react-redux';
import { showSnackbar } from '../../../redux/modules/snackbar';
import { getAuth } from '../../../redux/modules/auth/selectors';
import AverageScoreDrawer from './components/AverageScoreDrawer';

const AverageScores = () => {
  const dispatch = useDispatch();
  const { meta } = useSelector(getAuth);
  const [phq9, setPhq9] = useState({
    title: 'Average PHQ 9 score',
    drawerTitle: 'Current PHQ-9 scores',
    count: 0,
    point: 0,
    initialScore: 0,
    color: colors.warning500,
    type: 'phq9',
  });
  const [gad7, setGad7] = useState({
    title: 'Average GAD 7 score',
    drawerTitle: 'Current GAD 7 scores',
    count: 0,
    point: 0,
    initialScore: 0,
    color: colors.warning500,
    type: 'gad7',
  });
  const [selectedAverageScore, setSelectedAverageScore] = useState(null);
  const params = {
    pageNumber: 0,
    pageSize: 1,
    orderBy: 'asc',
    sortBy: 'createdAt',
    providerId: meta?.userId,
  };

  const getColors = (
    count,
    vNegativeMin,
    vNegativeMax,
    negativeMin,
    negativeMax,
    neutralMin,
    neutralMax,
    positiveMin,
    positiveMax,
  ) => {
    if (count >= vNegativeMin && count <= vNegativeMax) {
      return { borderColor: colors.destructive600, color: colors.destructive600 };
    }
    if (count >= negativeMin && count <= negativeMax) {
      return { borderColor: colors.warning600, color: colors.warning600 };
    }
    if (count >= neutralMin && count <= neutralMax) {
      return { borderColor: colors.neutral400, color: colors.neutral400 };
    }
    if (count >= positiveMin && count <= positiveMax) {
      return { borderColor: colors.success600, color: colors.success600 };
    }
    return { borderColor: colors.neutral400, color: colors.neutral400 };
  };

  const fetchPHQ9 = async () => {
    try {
      const res = await getAllPhq9Gad7AverageScore(params, { type: 'PHQ-9 Score' });
      const dataOutCome = res?.data?.metaData?.outComeRange;
      const { data } = await getPhq9Gad7AverageScoreSummary(
        { providerId: meta?.userId },
        { type: 'PHQ-9 Score' },
      );
      setPhq9({
        ...phq9,
        count: data?.metaData?.totalPatients ?? 0,
        point: data?.data?.currentAverageScore ?? 0,
        initialScore: data?.data?.initialAverageScore ?? 0,
        color: getColors(
          data?.data?.currentAverageScore ?? 0,
          dataOutCome?.veryNegativeRange?.minScore,
          dataOutCome?.veryNegativeRange?.maxScore,
          dataOutCome?.negativeRange?.minScore,
          dataOutCome?.negativeRange?.maxScore,
          dataOutCome?.neutralRange?.minScore,
          dataOutCome?.neutralRange?.maxScore,
          dataOutCome?.positiveRange?.minScore,
          dataOutCome?.positiveRange?.maxScore,
        ).color,
      });
    } catch (e) {
      const message = e?.data?.message || 'Something went wrong';
      dispatch(
        showSnackbar({
          snackType: 'error',
          snackMessage: message,
        }),
      );
      console.log(e);
    }
  };

  const fetchGAD7 = async () => {
    try {
      const res = await getAllPhq9Gad7AverageScore(params, { type: 'GAD-7 Score' });
      const dataOutCome = res?.data?.metaData?.outComeRange;
      const { data } = await getPhq9Gad7AverageScoreSummary(
        { providerId: meta?.userId },
        { type: 'GAD-7 Score' },
      );
      setGad7({
        ...gad7,
        count: data?.metaData?.totalPatients ?? 0,
        point: data?.data?.currentAverageScore ?? 0,
        initialScore: data?.data?.initialAverageScore ?? 0,
        color: getColors(
          data?.data?.currentAverageScore ?? 0,
          dataOutCome?.veryNegativeRange?.minScore,
          dataOutCome?.veryNegativeRange?.maxScore,
          dataOutCome?.negativeRange?.minScore,
          dataOutCome?.negativeRange?.maxScore,
          dataOutCome?.neutralRange?.minScore,
          dataOutCome?.neutralRange?.maxScore,
          dataOutCome?.positiveRange?.minScore,
          dataOutCome?.positiveRange?.maxScore,
        ).color,
      });
    } catch (e) {
      const message = e?.data?.message || 'Something went wrong';
      dispatch(
        showSnackbar({
          snackType: 'error',
          snackMessage: message,
        }),
      );
      console.log(e);
    }
  };

  useEffect(() => {
    void fetchPHQ9();
    void fetchGAD7();
  }, []);

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <AverageFrame avgScore={phq9} onClick={() => setSelectedAverageScore(phq9)} />
        </Grid>
        <Grid item xs={12} md={6}>
          <AverageFrame avgScore={gad7} onClick={() => setSelectedAverageScore(gad7)} />
        </Grid>
      </Grid>
      {!!selectedAverageScore && (
        <AverageScoreDrawer
          open={!!selectedAverageScore}
          onClose={() => setSelectedAverageScore(null)}
          data={selectedAverageScore}
        />
      )}
    </>
  );
};

export default AverageScores;
