/* eslint-disable max-len */
import cx from 'clsx';

// types
// styles
import { useStyles } from './shared/styles';

const ConversationsOutlined = ({ className, color, onClick }) => {
  const classes = useStyles();
  return (
    <svg
      className={cx({
        [classes.root]: true,
        [className || '']: className,
      })}
      width="20"
      height="19"
      viewBox="0 0 20 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M12.5 12.3333C13.3841 12.3333 14.2319 11.9821 14.857 11.357C15.4822 10.7319 15.8333 9.88402 15.8333 8.99996V3.99996C15.8333 3.1159 15.4822 2.26806 14.857 1.64294C14.2319 1.01782 13.3841 0.666626 12.5 0.666626H4.16668C3.28262 0.666626 2.43478 1.01782 1.80965 1.64294C1.18453 2.26806 0.833344 3.1159 0.833344 3.99996V14.4166C0.833344 14.5271 0.877242 14.6331 0.955382 14.7113C1.03352 14.7894 1.1395 14.8333 1.25001 14.8333C1.30451 14.833 1.35839 14.8218 1.40848 14.8003C1.45857 14.7788 1.50386 14.7475 1.54168 14.7083L3.20834 13.0416C3.44529 12.8109 3.72566 12.6295 4.0332 12.5079C4.34075 12.3863 4.66936 12.327 5.00001 12.3333H12.5ZM2.50001 11.5V3.99996C2.50001 3.55793 2.6756 3.13401 2.98817 2.82145C3.30073 2.50889 3.72465 2.33329 4.16668 2.33329H12.5C12.942 2.33329 13.366 2.50889 13.6785 2.82145C13.9911 3.13401 14.1667 3.55793 14.1667 3.99996V8.99996C14.1667 9.44199 13.9911 9.86591 13.6785 10.1785C13.366 10.491 12.942 10.6666 12.5 10.6666H5.00001C4.09847 10.6666 3.22124 10.959 2.50001 11.5ZM17.5 3.99996V10.6666C17.5 11.5507 17.1488 12.3985 16.5237 13.0236C15.8986 13.6488 15.0507 14 14.1667 14H4.16668C4.16668 14.442 4.34227 14.8659 4.65483 15.1785C4.96739 15.491 5.39132 15.6666 5.83334 15.6666H15C15.3296 15.6658 15.6561 15.7302 15.9607 15.856C16.2654 15.9819 16.5421 16.1668 16.775 16.4L18.4417 18.0666C18.4795 18.1059 18.5248 18.1371 18.5749 18.1586C18.625 18.1801 18.6788 18.1913 18.7333 18.1916C18.8438 18.1916 18.9498 18.1477 19.028 18.0696C19.1061 17.9914 19.15 17.8855 19.15 17.775V5.66663C19.15 5.22747 18.9767 4.80605 18.6678 4.49397C18.3588 4.18188 17.9391 4.00435 17.5 3.99996Z"
        fill={color}
      />
    </svg>
  );
};

export default ConversationsOutlined;
