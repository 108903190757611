// import { makeStyles } from '@mui/styles';

// export const useStyles = makeStyles({
//   root: {
//     fontFamily: 'Manrope',
//     fontWeight: 'bold',
//     fontSize: 18,
//   },
// });

import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  root: {
    fontFamily: 'Manrope',
    fontWeight: 'bold',
    fontSize: 18,
  },
});
