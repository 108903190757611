import { colors } from '../../../../../packages/colors';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: 0,
    paddingBottom: 16,
  },
  noPb: {
    paddingBottom: 0,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
    padding: '12px 24px',
  },
  selectedHeader: {
    backgroundColor: colors.neutral50,
  },
  headerLeftBorder: {
    borderLeft: `2px solid ${colors.neutral900} !important`,
  },
  chevronIcon: {
    width: 24,
    minWidth: 24,
    height: 24,
    objectFit: 'contain',
    transition: '0.4s',
    transform: ({ collapsed }) => `rotate(${collapsed ? '180deg' : 0})`,
    '& path': {
      fill: colors.neutral900,
    },
  },
  selects: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    fontWeight: 'bold',
    fontSize: 14,
    lineHeight: '20px',
  },
  checkCircleIcon: {},
  label: {
    fontSize: 14,
    lineHeight: '20px',
    fontWeight: 500,
    color: colors.neutral700,
    marginRight: '10px',
  },
  cList: {
    display: 'flex',
    borderWidth: '1px 0px',
    borderStyle: 'solid',
    borderColor: '#E5EBF0',
    alignItems: 'flex-start',
    padding: '12px 24px 12px 30px',
    gap: 24,
    '& .full-match': {
      color: '#29aa31',
      width: 12,
      height: 12,
    },
    '& .partial-match': {
      color: '#ef681a',
      width: 12,
      height: 12,
    },
    '& .no-match': {
      color: '#ef4242',
      width: 12,
      height: 12,
    },
    '& .resolved': {
      color: '#6172F3',
      width: 12,
      height: 12,
    },
  },
  count: {
    fontWeight: 700,
    fontSize: 14,
    lineHeight: '20px',
    '& .full-match-text': {
      color: '#29aa31',
    },
    '& .partial-match-text': {
      color: '#ef681a',
    },
    '& .no-match-text': {
      color: '#ef4242',
    },
    '& .resolved-text': {
      color: '#6172F3',
    },
  },
  selectItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '10px 24px 10px 48px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: colors.neutral50,
    },
  },
  selectItemNotSelectable: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '10px 24px 10px 48px',
  },
  selectItemActive: {
    backgroundColor: colors.primary50,
  },
  activeColor: {
    color: colors.primary600,
  },
  evalIcon: {
    height: 20,
    width: 20,
    flexShrink: 0,
  },
  fullMatchIcon: {
    border: `3px solid #A1E6A5`,
    height: 32,
    width: 32,
    borderRadius: '50%',
    padding: '2.5px',
    '& > path': {
      fill: colors.success600,
    },
  },
  partialMatchIcon: {
    border: `3px solid ${colors.orange300}`,
    height: 32,
    width: 32,
    borderRadius: '50%',
    padding: '2.5px',
    '& > path': {
      fill: colors.orange600,
    },
  },
  noMatchIcon: {
    border: `3px solid ${colors.destructive300}`,
    height: 32,
    width: 32,
    borderRadius: '50%',
    padding: '2.5px',
    '& > path': {
      fill: colors.destructive600,
    },
  },
  resolvedIcon: {
    border: `3px solid #A4BCFD`,
    height: 32,
    width: 32,
    borderRadius: '50%',
    padding: '2.5px',
    '& > path': {
      fill: colors.indigo600,
    },
  },
});
