import { Box } from '@mui/material';
import { Drawer } from '../../../../packages/ui/organisms/drawer';
import { Icons } from '../../../../packages/icons';
import { colors } from '../../../../packages/colors';
import { useStyles } from '../kpis/index.styles';
import EvaluationCard from '../../sessions/evaluations-v2/evaluation-card';

const AssignedEvaluationDrawer = ({ open, onClose, onEvaluationSelected, evaluations, past }) => {
  const classes = useStyles();

  return (
    <Drawer open={open} onClose={onClose}>
      <div className={classes.header}>
        <Box className={classes.text22}>{`${past ? 'Past' : 'Assigned'}`} evaluations</Box>
        <Box className={classes.text14} color={colors.neutral600}>
          {evaluations.length} total
        </Box>
        <Box onClick={onClose} className={classes.close}>
          <Icons glyph="close" />
        </Box>
      </div>
      <div className={classes.content}>
        {evaluations.map(evaluation => (
          <EvaluationCard key={evaluation?.id} evaluation={evaluation} onClick={onEvaluationSelected} />
        ))}
      </div>
    </Drawer>
  );
};

export default AssignedEvaluationDrawer;
