// /* eslint-disable no-nested-ternary */
// import { makeStyles } from '@mui/styles';
// import { IPaginationProps } from './Pagination.types';

// // colors
// import { colors } from '../../../colors';

// export const useStyles = (props: IPaginationProps) =>
//   makeStyles({
//     root: {
//       '& .MuiPagination-ul': { flexFlow: 'nowrap' },
//       '& > ul > li': {
//         '& > button, > div': {
//           display: 'flex',
//           alignItems: 'center',
//           justifyContent: 'center',
//           margin: '0 4px',
//           width: props.size === 'large' ? 50 : props.size === 'small' ? 30 : 40,
//           height: props.size === 'large' ? 50 : props.size === 'small' ? 30 : 40,
//           border: `1px solid ${colors.inputBorder}`,
//           borderRadius: 4,
//           backgroundColor: colors.white,
//           color: colors.textMediumContrast,
//           fontFamily: 'Manrope',
//           fontWeight: 'bold',
//           fontSize: props.size === 'large' ? 14 : props.size === 'small' ? 10 : 12,
//           lineHeight: '16px',
//           letterSpacing: '0.01em',
//           '&.Mui-selected, &:hover': {
//             backgroundColor: colors.neutral200,
//           },
//           '&.Mui-disabled': {
//             color: colors.neutral500,
//             opacity: 1,
//           },
//           '& svg': {
//             width: '1.2em',
//             height: '1.2em',
//           },
//         },
//       },
//     },
//   });

import { makeStyles } from '@mui/styles';

// colors
import { colors } from '../../../colors';

export const useStyles = makeStyles({
  root: props => ({
    '& .MuiPagination-ul': { flexFlow: 'nowrap' },
    '& > ul > li': {
      '& > button, > div': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '0 4px',
        width: props.size === 'large' ? 50 : props.size === 'small' ? 30 : 40,
        height: props.size === 'large' ? 50 : props.size === 'small' ? 30 : 40,
        border: `1px solid ${colors.inputBorder}`,
        borderRadius: 4,
        backgroundColor: colors.white,
        color: colors.textMediumContrast,
        fontFamily: 'Manrope',
        fontWeight: 'bold',
        fontSize: props.size === 'large' ? 14 : props.size === 'small' ? 10 : 12,
        lineHeight: '16px',
        letterSpacing: '0.01em',
        '&.Mui-selected, &:hover': {
          backgroundColor: colors.neutral200,
        },
        '&.Mui-disabled': {
          color: colors.neutral500,
          opacity: 1,
        },
        '& svg': {
          width: '1.2em',
          height: '1.2em',
        },
      },
    },
  }),
});
