import { Box, Stack } from '@mui/material';
import clsx from 'clsx';

import { Badge, badgeStyle, badgeType, fontWeight, Text } from '../../../../../packages';

import { useStyles } from './InfoCard.styles';

const InfoCard = ({ title, value, changedValue, isBordered }) => {
  const classes = useStyles();
  return (
    <Box className={clsx(classes.root, { [classes.bordered]: isBordered })}>
      <Stack direction="column" gap={1}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Text className={classes.title} weight={fontWeight.EXTRA_BOLD}>
            {title}
          </Text>
          {changedValue && (
            <Badge
              className={classes.badge}
              variant={badgeType.OUTLINED}
              style={changedValue < 0 ? badgeStyle.HIGH : badgeStyle.SUCCESS}
            >
              {changedValue < 0 ? '' : '+'}
              {changedValue}% from 1st
            </Badge>
          )}
        </Stack>
        <Text className={classes.subtitle} weight={fontWeight.BOLD}>
          {value}
        </Text>
      </Stack>
    </Box>
  );
};

export { InfoCard };
