import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import clsx from 'clsx';
import debounce from 'debounce';
import dayjs from 'dayjs';

import {
  btnType,
  Button,
  colors,
  fontWeight,
  Icons,
  Input,
  inputType,
  Text,
  textLevel,
} from '../../../../../packages';
import { ProfileInfo } from '../../../../../packages/ui/templates/profile-info';
import { useStyles } from '../create-scheduling-link/CreateSchedulingLink.styles';
import * as memberService from '../../../../../services/member/member.service';

const SelectMember = ({ selectedItem, setSelectedItem, formData }) => {
  const classes = useStyles();
  const [searchKey, setSearchKey] = useState('');
  const [members, setMembers] = useState([]);
  const [totalMembers, setTotalMembers] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [filteredPage, setFilteredPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (searchKey) {
      setPageNumber(0);
    } else {
      setFilteredPage(0);
    }
    void fetchMembers();
  }, [searchKey]);

  const onChangeSearchKey = debounce(newValue => {
    setSearchKey(newValue);
  }, 500);

  const fetchMembers = async (page = null) => {
    const currentPage = page !== null ? page : searchKey ? filteredPage : pageNumber;

    const queryParams = {
      searchQuery: searchKey,
      pageNumber: currentPage,
      pageSize: 10,
      orderBy: 'asc',
      sortBy: '',
    };

    setIsLoading(true);

    const response = await memberService.getPatientsList(queryParams, {
      states: formData?.state ? [formData?.state] : [],
      payerIds: formData?.insurance ? formData?.insurance : undefined,
    });

    if (response.status === 200) {
      const { data } = response;

      setTotalMembers(data?.totalRecords);

      const updatedPatientsList = data?.patientsList?.map(member => {
        return {
          nickName: member.fullName,
          firstName: member.firstName,
          lastName: member.lastName,
          photo: member?.photo,
          uuid: member.uuid,
          id: member.userId,
          insurance: member.insurance,
          state: member.state,
          dob: member.dob ? dayjs(member.dob, 'YYYY-MM-DD').format('MM/DD/YYYY') : null,
        };
      });

      if (currentPage === 0) {
        setMembers(updatedPatientsList);
      } else {
        setMembers([...members, ...updatedPatientsList]);
      }
    }
    setIsLoading(false);
  };

  const onLoadMore = () => {
    if (totalMembers > members?.length) {
      if (searchKey) {
        setPageNumber(0);
        setFilteredPage(prevFilteredPage => {
          const newPage = prevFilteredPage + 1;
          void fetchMembers(newPage);
          return newPage;
        });
      } else {
        setFilteredPage(0);
        setPageNumber(prevPageNumber => {
          const newPage = prevPageNumber + 1;
          void fetchMembers(newPage);
          return newPage;
        });
      }
    }
  };

  const renderMember = item => {
    const { id, nickName, state, dob, insurance, firstName, lastName, uuid, photo } = item;
    return (
      <Box
        className={clsx(classes.userinfo, { [classes.selected]: selectedItem?.id === id })}
        key={id}
        onClick={() => setSelectedItem(item)}
        display="flex"
        gap="4px"
      >
        <Box sx={styles.profile}>
          <ProfileInfo
            type="member"
            nickName={firstName && lastName ? `${firstName || ''} ${lastName || ''}` : nickName || 'No Name'}
            fullName={uuid}
            photo={photo}
          />
        </Box>
        <Box sx={styles.listItemGroup}>
          <Box sx={styles.listItemContainer}>
            <Text level={textLevel.S} weight={fontWeight.MEDIUM} color={colors.neutral500}>
              Insurance
            </Text>
            {insurance ? (
              <Box display="flex" alignItems="center" justifyContent="stretch" gap="6px">
                <Icons glyph="check-circle" color={colors.green600} className={classes.insuranceIcon} />
                <Text level={textLevel.S} weight={fontWeight.MEDIUM} className={classes.valueText}>
                  {insurance}
                </Text>
              </Box>
            ) : (
              <Box display="flex" alignItems="center" gap="6px">
                <Icons
                  glyph="clear-circle"
                  color={colors.destructive500}
                  className={classes.noInsuranceIcon}
                />
                <Text level={textLevel.S} weight={fontWeight.MEDIUM} color={colors.neutral900}>
                  None
                </Text>
              </Box>
            )}
          </Box>
          <Box sx={styles.listItemContainer}>
            <Text
              level={textLevel.S}
              weight={fontWeight.MEDIUM}
              color={colors.neutral500}
              className={classes.valueText}
            >
              State
            </Text>
            <Text
              level={textLevel.S}
              weight={fontWeight.MEDIUM}
              color={colors.neutral900}
              className={classes.valueText}
            >
              {state || '-'}
            </Text>
          </Box>
          <Box sx={styles.listItemContainer}>
            <Text level={textLevel.S} weight={fontWeight.MEDIUM} color={colors.neutral500}>
              DOB
            </Text>
            <Text level={textLevel.S} weight={fontWeight.MEDIUM} color={colors.neutral900}>
              {dob || '-'}
            </Text>
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <Box>
      <Box padding="24px 24px 0px 24px">
        <Text level={textLevel.L} weight={fontWeight.BOLD}>
          Select member
        </Text>
      </Box>
      <Box sx={styles.searchBox}>
        <Input
          className={classes.searchInput}
          variant={inputType.SEARCH}
          placeholder="Search"
          value={searchKey}
          onChange={value => {
            onChangeSearchKey(value);
          }}
        />
      </Box>
      <Box sx={{ padding: 3 }}>
        {members?.length > 0 ? (
          members?.map(renderMember)
        ) : (
          <Text weight={fontWeight.MEDIUM} className={clsx(classes.nickName, classes.emptyList)}>
            There is no item
          </Text>
        )}
        <Box sx={styles.loadingWrap}>
          {isLoading ? (
            <>
              <Icons className="rotate linear infinite" glyph="in-progress" color={colors.primary} />
              Loading...
            </>
          ) : totalMembers > members?.length ? (
            <Button variant={btnType.TEXT} className={classes.loadMoreBtn} onClick={onLoadMore}>
              Load more
            </Button>
          ) : null}
        </Box>
      </Box>
    </Box>
  );
};

const styles = {
  searchBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: 24,
    padding: '24px 24px 0px 24px',
  },
  loadingWrap: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&svg': {
      marginRight: 12,
    },
  },
  listItemGroup: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  listItemContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '4px',
    minWidth: '136px',
  },
  profile: {
    width: 400,
    '& #fullName': {
      display: 'inline-block',
      width: 140,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
};

export default SelectMember;
