import clsx from 'clsx';
import { Box, Modal } from '@mui/material';

import { Button, colors, Heading, Icons, Text } from '../../../../../../packages';

import { useStyles } from './UpgradeVersion.styles';

const UpgradeVersion = ({ open, onClose, onUpgradeVersion }) => {
  const classes = useStyles();

  return (
    <Modal open={open} onClose={onClose}>
      <Box className={classes.modal}>
        <Box className={classes.modalContent}>
          <Box className={classes.upIconWrap}>
            <Icons glyph="up-circle" className={classes.upIcon} />
          </Box>
          <Box className={classes.content}>
            <Box>
              <Heading className={classes.title}>Upgrade version?</Heading>
              <Icons glyph="close" onClick={onClose} className={classes.closeBtn} color={colors.neutral400} />
            </Box>
            <Box>
              <Text className={classes.label}>
                You need to upgrade the conversation version in order to make changes.
              </Text>
            </Box>
            <Box display="flex" justifyContent="space-between" alignItems="center" gap="16px">
              <Button className={clsx(classes.btn, classes.cancelBtn)} onClick={onClose}>
                Cancel
              </Button>
              <Button onClick={onUpgradeVersion} className={clsx(classes.btn, classes.okBtn)}>
                Upgrade version
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export { UpgradeVersion };
