/* eslint-disable react/destructuring-assignment */
import { Component, createContext } from 'react';

import createSession from './createSession';

export const SessionContext = createContext({
  session: {},
  streams: [],
});

export default class OTSession extends Component {
  constructor(props) {
    super(props);

    this.state = {
      streams: [],
    };
  }

  componentDidMount() {
    this.createSession();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.apiKey !== this.props.apiKey ||
      prevProps.sessionId !== this.props.sessionId ||
      prevProps.token !== this.props.token
    ) {
      this.createSession();
    }
  }

  componentWillUnmount() {
    this.destroySession();
  }

  sessionHelper;

  createSession() {
    this.destroySession();

    this.sessionHelper = createSession({
      apiKey: this.props.apiKey,
      sessionId: this.props.sessionId,
      token: this.props.token,
      onStreamsUpdated: streams => {
        this.setState({ streams });
      },
      onConnect: this.props.onConnect,
      onError: this.props.onError,
      options: this.props.options,
    });

    if (this.props.eventHandlers && typeof this.props.eventHandlers === 'object') {
      this.sessionHelper.session.on(this.props.eventHandlers);
    }

    const { streams } = this.sessionHelper;
    this.setState({ streams });
  }

  destroySession() {
    if (this.sessionHelper) {
      if (this.props.eventHandlers && typeof this.props.eventHandlers === 'object') {
        this.sessionHelper.session.off(this.props.eventHandlers);
      }
      this.sessionHelper.disconnect();
    }
  }

  render() {
    return (
      <SessionContext.Provider value={{ session: this.sessionHelper?.session, streams: this.state?.streams }}>
        <div>{this.props.children}</div>
      </SessionContext.Provider>
    );
  }
}
