import { FILTER_COLLAPSABLE_SECTION_TYPE } from '../../../packages/ui/organisms/table/filter';

export const profileElementsColumns = [
  { id: 'name', label: 'Name', sortable: true },
  { id: 'type', label: 'Type', sortable: true },
  { id: 'values', label: 'Values', sortable: false },
  { id: 'method', label: 'Method', sortable: true },
  { id: 'appearIn', label: 'Appears In', sortable: false },
  { id: 'actions', label: '', sortable: false },
];

export const elementsFilterOptions = [{ label: 'Type 1', value: 'type_01' }];

export const profileElementDefMethods = {
  MOST_RECENT_RESPONSE: 'Most Recent Response',
  ALL_RESPONSES_AND_NO_DUPLICATES: 'All Responses and No Duplicates',
  ALL_RESPONSES_WITH_DATE_TIME_STAMPS: 'All Responses with Date/Time stamps',
};

export const multiSelectFilterOptionsMock = [
  {
    sectionTitle: 'Type',
    sectionType: FILTER_COLLAPSABLE_SECTION_TYPE.DEFAULT,
    sectionOptions: [
      { label: 'Score Based', value: 'Score Based' },
      { label: 'Text Input', value: 'Text Input' },
      { label: 'Rating Scale', value: 'Rating Scale' },
      { label: 'Date', value: 'Date' },
      { label: 'Date Time', value: 'Date Time' },
      { label: 'Yes/No', value: 'Yes/No' },
      { label: 'Numeric', value: 'Numeric' },
      { label: 'User Defined Values', value: 'User Defined Values' },
    ],
  },
  {
    sectionTitle: 'Storage Method',
    sectionType: FILTER_COLLAPSABLE_SECTION_TYPE.RADIO,
    sectionOptions: [
      { label: 'Most Recent Response', value: 'Most Recent Response' },
      { label: 'All Responses and No Duplicates', value: 'All Responses and No Duplicates' },
      { label: 'All Responses with Date/Time stamps', value: 'All Responses with Date/Time stamps' },
    ],
  },
];
