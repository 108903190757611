import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import clsx from 'clsx';

import { IconButton } from '../../../../../packages/ui/molecules/icon-button';
import { fontWeight, Heading, headingLevel, TextError } from '../../../../../packages/ui/atoms/typography';
import { Input, inputSize, inputType } from '../../../../../packages/ui/atoms/input';
import { Select, selectType } from '../../../../../packages/ui/atoms/select';

import { getAuth } from '../../../../../redux/modules/auth/selectors';
import { useStyles } from './AvailableState.styles';

const AvailableStateForm = ({ stateList, payersGroup, item, onUpdate, onDelete, errorObj, touched }) => {
  const classes = useStyles();
  const [currentStateList, setCurrentStateList] = useState([]);
  const { isAdmin } = useSelector(getAuth);

  useEffect(() => {
    if (item.state) {
      const index = Object.keys(payersGroup).findIndex(key => key === item.state);
      if (index === -1) return;
      setCurrentStateList(Object.values(payersGroup)[index]);
    }
  }, [item.state]);
  const payersInState = useMemo(
    () =>
      currentStateList
        .map(
          itm =>
            ({
              title: itm.name,
              value: itm._id,
            } || []),
        )
        .filter(obj => {
          return !item.insurancesInState.some(obj2 => obj2.value === obj.value);
        }),
    [currentStateList],
  );

  const handleChangeState = ({ target }) => {
    if (target.value !== item.state) {
      onUpdate({ ...item, insurancesInState: [], state: target.value });
    } else {
      onUpdate({ ...item, state: target.value });
    }
  };

  const onChangeTags = (_, val) => {
    onUpdate({ ...item, insurancesInState: val });
  };

  const renderLabel = (label, className = '') => (
    <Heading level={headingLevel.S} className={clsx(classes.label, className)} weight={fontWeight.BOLD}>
      {label}
    </Heading>
  );

  return (
    <Box className={classes.formItem}>
      <Box className={classes.section}>
        {renderLabel('Select state')}
        <Select
          options={stateList?.sort((a, b) => a.label.localeCompare(b.label))}
          value={item.state}
          getOptionLabel={option => `${option.label}`}
          emptyText="Select state"
          name="state"
          onChange={handleChangeState}
          variant={selectType.SECONDARY}
          fullWidth
        />
        <TextError errorMsg={touched?.state ? errorObj?.state : null} />
      </Box>
      <Box className={classes.section}>
        {renderLabel('Insurance available in this state')}
        <Input
          value={item.insurancesInState}
          name="insurancesInState"
          variant={inputType.TAGS}
          placeholder="Enter an insurance"
          onChange={onChangeTags}
          options={payersInState}
          getOptionLabel={option => `${option.title}`}
          size={inputSize.M}
          fullWidth
        />
        <TextError errorMsg={touched?.insurancesInState ? errorObj?.insurancesInState : null} />
      </Box>
      {isAdmin && (
        <IconButton icon="delete-outlined-2" onClick={onDelete} className={classes.deleteBtn}>
          Delete state
        </IconButton>
      )}
    </Box>
  );
};

export { AvailableStateForm };
