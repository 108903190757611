import { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import clsx from 'clsx';

import { fontWeight, Heading, headingLevel, TextError } from '../../../../../packages/ui/atoms/typography';
import { Select, selectType } from '../../../../../packages/ui/atoms/select';
import { Textarea } from '../../../../../packages/ui/atoms/textarea';
import { Input, inputSize, inputType } from '../../../../../packages/ui/atoms/input';
import { useDispatch, useSelector } from 'react-redux';
import { profileActionCreators } from '../../../../../redux/modules/profile';
import { getProfile } from '../../../../../redux/modules/profile/selectors';
import { getDomainElements } from '../../../../../services/member/member.service';

import SelectLevel from '../select-level';

// styles
import { useStyles } from './MedicationForm.styles';

const MedicationForm = ({
  form,
  error,
  symptomsList,
  lookupData,
  options,
  medication,
  onChangeHandler,
  state,
}) => {
  const [importanceLevels, setImportanceLevels] = useState([]);
  const { providers } = useSelector(getProfile);
  const providerListOptions = providers.map(provider => ({
    label: provider.fullName,
    value: provider.providerId,
  }));

  const frequencies = [
    'Daily',
    'Twice a Day',
    'Three times a Day',
    'Every other Day',
    'As needed',
    'Other',
  ].map(frequency => ({ label: frequency, value: frequency }));

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      profileActionCreators.fetchProviders({
        searchQuery: '',
        pageNumber: 1,
        pageSize: 1000,
        orderBy: 'asc',
        sortBy: '',
      }),
    );
  }, []);

  useEffect(() => {
    if (medication) {
      if (medication?.domainElementId) {
        const tempImportanceLevels = [];
        getDomainElements({ domainTypeId: medication?.domainElementId })
          .then(res => {
            if (res.status === 200) {
              Object.keys(res?.data?.metaData?.metaDataSpec?.importanceLevels).forEach(key => {
                if (res?.data?.metaData?.metaDataSpec?.importanceLevels[key]) {
                  tempImportanceLevels.push({ value: key, label: key });
                }
              });
              setImportanceLevels(tempImportanceLevels);
            }
          })
          .catch(err => {
            console.log({ err });
          });
      } else {
        const selectedDiagnosis = options?.filter(option => option?.groupId === medication?.groupId);
        const selectedRelatedElement = selectedDiagnosis[0]?.relatedElements?.filter(
          element => element.Id === medication.item.value,
        );

        const tempImportanceLevels = [];
        Object.keys(selectedRelatedElement[0]?.metaData?.metaDataSpec?.importanceLevels).forEach(key => {
          if (selectedRelatedElement[0]?.metaData?.metaDataSpec?.importanceLevels[key]) {
            tempImportanceLevels.push({ value: key, label: key });
          }
        });

        setImportanceLevels(tempImportanceLevels);
      }
    }
  }, []);
  const classes = useStyles();
  const { values, touched, errors, setFieldValue, ...rest } = form;

  const setTouched = name => async () => {
    await rest.setTouched({ ...touched, [name]: true });
  };

  const renderLabel = (str, className = '') => (
    <Heading level={headingLevel.S} className={clsx(classes.label, className)} weight={fontWeight.BOLD}>
      {str}
    </Heading>
  );

  useEffect(() => {
    if (values?.status === '') {
      setFieldValue('status', 'INACTIVE');
    }
  }, [values]);

  return (
    <Box className={classes.formContent}>
      {error && (
        <Box display="flex" justifyContent="center">
          <TextError errorMsg={error} />
        </Box>
      )}
      <Box className={classes.section}>
        {renderLabel('Importance level')}
        <SelectLevel
          name="status"
          value={state.status}
          isLifeEvent
          options={importanceLevels}
          onChange={event => onChangeHandler('status', event.target.value)}
        />
      </Box>
      <Box className={classes.section}>
        {renderLabel('Dosage')}
        <Input
          fullWidth
          name="dosage"
          value={state.dosage}
          size={inputSize.M}
          placeholder="Enter dosage"
          onChange={event => onChangeHandler('dosage', event.target.value)}
          onBlur={setTouched('dosage')}
        />
        <TextError errorMsg={touched.dosage ? errors.dosage?.toString() : null} />
      </Box>
      <Box className={classes.section}>
        {renderLabel('Frequency')}
        <Select
          name="frequency"
          value={state.frequency}
          variant={selectType.SECONDARY}
          options={frequencies?.sort((a, b) => a.label.localeCompare(b.label))}
          emptyText="Select item"
          displayEmpty
          onChange={event => onChangeHandler('frequency', event.target.value)}
        />
        <TextError errorMsg={touched.frequency ? errors.frequency?.toString() : null} />
      </Box>
      {symptomsList.length > 0 && (
        <Box className={classes.section}>
          {renderLabel('Symptoms being treated')}
          <Input
            // freeSolo
            fullWidth
            name="symptoms"
            value={state.symptoms}
            options={symptomsList}
            variant={inputType.TAGS}
            size={inputSize.M}
            getOptionLabel={option => option}
            onBlur={setTouched('symptoms')}
            onChange={async (_, newValue) => {
              await onChangeHandler('symptoms', newValue);
            }}
          />
          <TextError errorMsg={touched.symptoms ? errors.symptoms?.toString() : null} />
        </Box>
      )}
      <Box className={classes.section}>
        {renderLabel('Prescribing Provider')}
        <Select
          name="provider"
          value={state.provider}
          variant={selectType.SECONDARY}
          options={[
            { label: 'Confidant Provider', value: 'Confidant Provider' },
            { label: 'External Provider', value: 'External Provider' },
          ]}
          onChange={event => {
            setFieldValue('provider', event.target.value);
            onChangeHandler('provider', event.target.value);
          }}
        />
        <TextError errorMsg={touched.provider ? errors.provider?.toString() : null} />
      </Box>
      {state.provider === 'Confidant Provider' ? (
        <Box className={classes.section}>
          {renderLabel('Confidant Provider')}
          <Select
            name="confidantProvider"
            value={state.confidantProvider}
            variant={selectType.SECONDARY}
            options={providerListOptions?.sort((a, b) => a.label.localeCompare(b.label)) || []}
            emptyText="Select item"
            displayEmpty
            onChange={event => onChangeHandler('confidantProvider', event.target.value)}
          />
          <TextError errorMsg={touched.confidantProvider ? errors.confidantProvider?.toString() : null} />
        </Box>
      ) : (
        <Box className={classes.section}>
          {renderLabel('Provider Name')}
          <Input
            fullWidth
            name="providerName"
            value={state.providerName}
            size={inputSize.M}
            placeholder="Enter provider name"
            onChange={event => onChangeHandler('providerName', event.target.value)}
            onBlur={setTouched('providerName')}
          />
          <TextError errorMsg={touched.providerName ? errors.providerName?.toString() : null} />
        </Box>
      )}
      <Box className={classes.section}>
        {renderLabel('Additional notes for team')}
        <Textarea
          name="additionalNotes"
          value={state.notes}
          placeholder="Enter your notes"
          onChange={event => onChangeHandler('notes', event.target.value)}
          minRows={2}
          maxRows={3}
          onBlur={setTouched('notes')}
        />
        <TextError errorMsg={touched.additionalNotes ? errors.additionalNotes?.toString() : null} />
      </Box>
    </Box>
  );
};

export { MedicationForm };
