import { useEffect, useState } from 'react';
import clsx from 'clsx';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import {
  btnSize,
  btnType,
  Button,
  colors,
  fontWeight,
  Heading,
  headingLevel,
  Input,
  inputSize,
  inputType,
  positionType,
  Text,
  textLevel,
  Toggle,
} from '../../../../packages';
import { Drawer, drawerPosition, drawerType } from '../../../../packages/ui/organisms/drawer';
import { useStyles } from '../ClaimDetail.styles';
import ProcedureCodeFields from './ProcedureCodeFields';

const defaultData = {
  amount: 0,
  sessionType: '',
  cptList: [],
};

const SubscriberDrawer = ({ data, open, onClose, onSubmit, payers, payer }) => {
  const classes = useStyles();
  const [formData, setFormData] = useState(() => {
    const { insuranceDetail, amount, cptList } = data;
    return { ...insuranceDetail, amount, cptList };
  });
  const [errors, setErrors] = useState(defaultData);
  const [cptListErrors, setCptListErrors] = useState([]);
  const [selectedPayer, setSelectedPayer] = useState(null);
  const [selectedPayerPlan, setSelectedPayerPlan] = useState(null);

  useEffect(() => {
    const selectPayer = payers.find(p => p?.name === data?.insuranceDetail?.payer);
    if (selectPayer) {
      setSelectedPayer({ label: selectPayer._id, value: selectPayer.name });
      setFormData({ ...formData, payer: selectPayer?._id });
    }
  }, [data?.insuranceDetail?.payer]);

  useEffect(() => {
    const selectPayerPlan = payer?.plans?.find(p => p?.code === data?.insuranceDetail?.plan);
    if (selectPayerPlan) {
      setSelectedPayerPlan({ label: selectPayerPlan.name, value: selectPayerPlan.code });
      setFormData({ ...formData, payer: selectPayerPlan?.code });
    }
  }, [data?.insuranceDetail?.plan]);

  const labels = {
    payer: 'Insurance Payer',
    plan: 'Insurance Plan',
    isActive: 'Active',
    amount: 'Amount',
  };

  const onChangeField = field => e => {
    if (errors[field]) {
      setErrors(s => ({ ...s, [field]: '' }));
    }
    setFormData(old => ({
      ...old,
      [field]: e.target?.value,
    }));
  };

  const onChangePayer = (_, keyValue) => {
    const val = keyValue && keyValue?.value !== '' ? keyValue?.value : '';
    setSelectedPayer(keyValue);
    setFormData({ ...formData, payer: val });
  };

  const onChangePlan = (_, keyValue) => {
    const val = keyValue && keyValue?.value !== '' ? keyValue?.value : '';
    setSelectedPayerPlan(keyValue);
    setFormData({ ...formData, plan: val });
  };

  const onEditInfo = () => {
    const cptErrs = JSON.parse(JSON.stringify(cptListErrors));
    formData.cptList.forEach((item, index) => {
      cptErrs.push({
        cptCode: 0,
        relatedDiagnosis: 0,
        icd10Code: 0,
        providerFee: 0,
      });
      if (item.cptCode) cptErrs[index].cptCode = 0;
      else cptErrs[index].cptCode = 1;
      item.relatedDiagnosis || item.diagnosis
        ? (cptErrs[index].relatedDiagnosis = 0)
        : (cptErrs[index].relatedDiagnosis = 1);
      item.icd10Code ? (cptErrs[index].icd10Code = 0) : (cptErrs[index].icd10Code = 1);
      item.providerFee ? (cptErrs[index].providerFee = 0) : (cptErrs[index].providerFee = 1);
    });
    setCptListErrors(cptErrs);
    if (!cptErrs.find(item => item.cptCode || item.relatedDiagnosis || item.icd10Code || item.providerFee))
      onSubmit(formData, 'Provider_Fee');
  };
  return (
    <Drawer
      open={open}
      onClose={onClose}
      variant={drawerType.FORM}
      position={drawerPosition.RIGHT}
      title="Edit Provider Fee"
      submitBtnTitle="Update"
      onSubmit={onEditInfo}
    >
      <Box className={classes.inputWrapper}>
        <label htmlFor="payer">
          <Text level={textLevel.S} weight={fontWeight.BOLD}>
            {labels.payer}
          </Text>
        </label>
        <Input
          fullWidth
          name="payer"
          placeholder="Select Insurance Payer"
          size={inputSize.M}
          variant={inputType.AUTOCOMPLETE}
          value={selectedPayer}
          options={payers?.map(p => ({ label: p.name, value: p?._id })) || []}
          getOptionLabel={option => option?.label || ''}
          onChange={onChangePayer}
        />
      </Box>
      <Box className={classes.inputWrapper}>
        <label htmlFor="plan">
          <Text level={textLevel.S} weight={fontWeight.BOLD}>
            {labels.plan}
          </Text>
        </label>
        <Input
          fullWidth
          name="plan"
          placeholder="Select Insurance Plan"
          size={inputSize.M}
          variant={inputType.AUTOCOMPLETE}
          value={selectedPayerPlan}
          options={payer?.plans?.map(p => ({ label: p.name, value: p?.code })) || []}
          getOptionLabel={option => option?.label || ''}
          onChange={onChangePlan}
        />
      </Box>
      <Toggle
        checked={formData?.isActive}
        labelPosition={positionType.RIGHT}
        onChange={() => setFormData({ ...formData, isActive: !formData?.isActive })}
      >
        Active
      </Toggle>
      <Box className={classes.inputWrapper}>
        <label htmlFor="amount">
          <Text level={textLevel.S} weight={fontWeight.BOLD}>
            {labels.amount}
          </Text>
        </label>
        <Input
          name="amount"
          value={formData.amount || ''}
          onChange={onChangeField('amount')}
          size={inputSize.M}
          placeholder="Enter your amount"
        />
      </Box>
      <ProcedureCodeFields values={data} cptListErrors={cptListErrors} />
    </Drawer>
  );
};

const ProviderFee = ({ onSubmit, data = defaultData, payers, payer }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const Col = ({ label, value }) => (
    <Box display="flex" flexDirection="column" minWidth={185}>
      <Text className={classes.colLabel} level={textLevel.S} weight={fontWeight.SEMI_BOLD}>
        {label}
      </Text>
      <Text className={classes.marginTop8} level={textLevel.M} weight={fontWeight.MEDIUM}>
        {value || 'N/A'}
      </Text>
    </Box>
  );

  return (
    <Box
      sx={{
        background: colors.white,
        paddingX: 3,
        paddingBottom: 4,
        marginBottom: 2,
        boxShadow:
          '0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04)',
        borderRadius: 2,
      }}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Heading level={headingLevel.S} weight={fontWeight.SEMI_BOLD}>
          Provider fee
        </Heading>
        <Button
          className={classes.btn}
          variant={btnType.TEXT}
          onClick={() => setOpen(true)}
          size={btnSize.SMALL}
        >
          Edit info
        </Button>
      </Box>
      <Divider sx={{ marginX: -3 }} />
      <Box display="flex" flexDirection="column">
        <Box display="flex" mt={5}>
          <Box display="flex" flexDirection="column" minWidth={185}>
            <Text className={classes.colLabel} level={textLevel.S} weight={fontWeight.SEMI_BOLD}>
              Total amount
            </Text>
            <Heading
              className={clsx(classes.marginTop8, classes.headingFont)}
              level={headingLevel.XL}
              weight={fontWeight.REGULAR}
            >
              {data?.amount ? `$${data?.amount}` : 'N/A'}
            </Heading>
          </Box>
          <Box display="flex" flexDirection="column" minWidth={185}>
            <Text className={classes.colLabel} level={textLevel.S} weight={fontWeight.SEMI_BOLD}>
              Session type
            </Text>
            <Text className={classes.marginTop8} level={textLevel.M} weight={fontWeight.MEDIUM}>
              {data?.sessionType ?? 'N/A'}
            </Text>
          </Box>
        </Box>

        {data?.cptList?.map((c, index) => (
          <Box
            key={`${index}-${c.cptCode}`}
            display="flex"
            mx={-3}
            py={2}
            px={3}
            sx={{ borderTop: `1px solid ${colors.neutral100}` }}
          >
            <Col label="CPT code" value={c?.cptCode} />
            <Col label="Fee" value={c?.providerFee?.toString() ? `$${c?.providerFee?.toString()}` : ''} />
          </Box>
        ))}
      </Box>
      {open && (
        <SubscriberDrawer
          data={data}
          open={open}
          onClose={() => setOpen(false)}
          onSubmit={onSubmit}
          payers={payers}
          payer={payer}
        />
      )}
    </Box>
  );
};

export default ProviderFee;
