import { useCallback, useState } from 'react';
import dayjs from 'dayjs';
import clsx from 'clsx';

// components
import { Box } from '@mui/material';
import {
  Badge,
  badgeStyle,
  badgeType,
  fontWeight,
  Heading,
  headingLevel,
  iconBtnPosition,
  IconButton,
  Text,
} from '../../../../../packages';
import { Filter, Table } from '../../../../../packages/ui/organisms/table';
import { ProgressBar } from '../../../../../packages/ui/atoms/ProgressBar';

// mock
import { dctColumns, dctListMock, filterOptionsMock } from './DctList.mock';

// styles
import { useStyles } from './DctList.styles';

const DctList = () => {
  const [value, setValue] = useState({
    search: {
      searchKey: '',
    },
    pagination: {
      currentPage: 1,
      rowsPerPage: 6,
    },
    sorter: {
      direction: 'asc',
      column: '',
    },
  });
  const classes = useStyles();

  const onExportClick = () => {
    console.log('export');
  };

  const renderColumns = dctColumns.map(column => {
    if (column.id === 'name') {
      return {
        ...column,
        renderCell: ({ name }) => (
          <Text className={classes.name} weight={fontWeight.MEDIUM}>
            {name}
          </Text>
        ),
      };
    }
    if (column.id === 'version') {
      return {
        ...column,
        renderCell: ({ version, versionUpdatedDate }) => (
          <Box>
            <Text className={classes.name} weight={fontWeight.SEMI_BOLD}>
              {version}
            </Text>
            <Text className={classes.name} weight={fontWeight.MEDIUM}>
              {dayjs(versionUpdatedDate).format('MMM DD, YYYY')}
            </Text>
          </Box>
        ),
      };
    }
    if (column.id === 'mostUpdated') {
      return {
        ...column,
        renderCell: ({ mostVersion, lastUpdated }) => (
          <Box>
            <Text className={classes.name} weight={fontWeight.SEMI_BOLD}>
              {mostVersion}
            </Text>
            <Text className={classes.name} weight={fontWeight.MEDIUM}>
              {dayjs(lastUpdated).format('MMM DD, YYYY')}
            </Text>
          </Box>
        ),
      };
    }
    if (column.id === 'scoreRange') {
      return {
        ...column,
        renderCell: val => (
          <Badge
            className={clsx(classes.badge)}
            variant={badgeType.FILLED}
            style={val ? badgeStyle.HIGH : badgeStyle.UNRELATED}
          >
            {val || 'Not scoreable'}
          </Badge>
        ),
      };
    }
    if (column.id === 'completeProgress') {
      return {
        ...column,
        renderCell: progress => <ProgressBar value={progress} />,
      };
    }

    if (column.id === 'actions') {
      return {
        ...column,
        renderCell: () => <IconButton icon="chevron-right" className={classes.actBtn} />,
      };
    }
    return column;
  });

  const formatList = list => {
    return list.map(({ lastUpdated, version, versionUpdatedDate, mostVersion, name, ...item }) => ({
      ...item,
      name: { dctId: item.id, name },
      version: { versionUpdatedDate, version },
      mostUpdated: { mostVersion, lastUpdated },
    }));
  };

  const dataSourceCallback = useCallback(() => {
    const { search, sorter, pagination } = value;
    const currentPage = pagination?.currentPage || 1;
    const rowsPerPage = pagination?.rowsPerPage || 6;

    const listFiltered = dctListMock
      .filter(({ name }) => {
        if (search?.searchKey) {
          return name.toLowerCase().includes(search.searchKey.toLowerCase());
        }
        return true;
      })

      .filter(({ scoreRange }) => {
        if (
          !search?.filter ||
          search?.filter?.length === 0 ||
          search?.filter?.length === filterOptionsMock.length
        ) {
          return true;
        }
        if (search.filter.includes('scoreable') && !!scoreRange) {
          return true;
        }
        if (search.filter.includes('not_scoreable') && !scoreRange) {
          return true;
        }
        return false;
      })

      .sort((a, b) => {
        const sortColumn = sorter?.column;
        const compare = a[sortColumn]?.toString()?.localeCompare(b[sortColumn]?.toString());
        return sorter?.direction === 'desc' ? compare * -1 : compare;
      })
      .slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage);

    return formatList(listFiltered);
  }, [value]);

  const dataSource = dataSourceCallback();

  return (
    <>
      <Box className={classes.root}>
        <Box className={classes.top}>
          <Heading className={classes.heading} level={headingLevel.S} weight={fontWeight.BOLD}>
            Data collection templates
          </Heading>

          <IconButton
            className={classes.exportBtn}
            icon="export"
            iconPosition={iconBtnPosition.RIGHT}
            onClick={onExportClick}
          >
            Export
          </IconButton>
        </Box>
        <Table
          searchProps={{
            placeholder: 'Search',
            filterProps: {
              variant: Filter.tableFilterType.MULTIPLE,
              options: filterOptionsMock,
              allOptionLabel: 'All template',
            },
          }}
          gridProps={{
            columns: renderColumns,
            data: dataSource,
            isLoading: false,
          }}
          paginationProps={{
            currentRows: dataSource.length,
            totalCount: dataSource.length,
          }}
          value={value}
          onChange={setValue}
        />
      </Box>
    </>
  );
};

export { DctList };
