export const NotificationType = {
  READ_EDUCATION: 'READ_EDUCATION',
  SCHEDULED_APPOINTMENT: 'SCHEDULED_APPOINTMENT',
  COMPLETED_APPOINTMENT: 'COMPLETED_APPOINTMENT',
  COMPLETED_CONVERSATION: 'COMPLETED_CONVERSATION',
  POSTED_PROVIDER_REVIEW: 'POSTED_PROVIDER_REVIEW',
  ASKED_QUESTION: 'ASKED_QUESTION',
  WROTE_SESSION_NOTES: 'WROTE_SESSION_NOTES',
  POSTED_APPOINTMENT_RECAP: 'POSTED_APPOINTMENT_RECAP',
  GROUP_MESSAGE: 'GROUP_MESSAGE',
  NEW_MESSAGE: 'NEW_MESSAGE',
};
