/* eslint-disable max-len */
import cx from 'clsx';

// types
// styles
import { useStyles } from './shared/styles';

const Wand = ({ className, color, onClick, ...rest }) => {
  const classes = useStyles();
  return (
    <svg
      className={cx({
        [classes.root]: true,
        [className || '']: className,
      })}
      onClick={onClick}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g id="wand-icon">
        <path
          id="want-vector"
          d="M12.6869 12.9235L10.8427 16.7985C10.8078 16.8718 10.7556 16.9354 10.6906 16.984C10.6256 17.0326 10.5498 17.0646 10.4696 17.0774C10.3895 17.0901 10.3074 17.0831 10.2306 17.057C10.1538 17.0309 10.0844 16.9865 10.0286 16.9277L7.07689 13.8118C6.99803 13.7287 6.89301 13.6751 6.77939 13.6602L2.52356 13.1035C2.44315 13.0929 2.36653 13.0629 2.30029 13.0161C2.23406 12.9693 2.18021 12.9071 2.14339 12.8348C2.10656 12.7626 2.08786 12.6824 2.08891 12.6013C2.08995 12.5202 2.11071 12.4406 2.14939 12.3693L4.20106 8.59933C4.2556 8.49851 4.27384 8.382 4.25272 8.26933L3.46689 4.05017C3.45197 3.97031 3.45673 3.88801 3.48076 3.81041C3.5048 3.7328 3.54738 3.66222 3.60483 3.60477C3.66228 3.54733 3.73286 3.50474 3.81046 3.48071C3.88807 3.45667 3.97036 3.45191 4.05022 3.46683L8.26939 4.25267C8.38206 4.27378 8.49856 4.25554 8.59939 4.201L12.3694 2.14933C12.4407 2.11065 12.5204 2.08991 12.6016 2.08891C12.6827 2.0879 12.7629 2.10667 12.8352 2.14359C12.9075 2.18051 12.9697 2.23446 13.0164 2.3008C13.0632 2.36713 13.0931 2.44385 13.1036 2.52433L13.6602 6.77933C13.6752 6.89295 13.7287 6.99798 13.8119 7.07683L16.9277 10.0285C16.9866 10.0844 17.031 10.1537 17.0571 10.2305C17.0831 10.3074 17.0901 10.3894 17.0774 10.4696C17.0647 10.5497 17.0327 10.6256 16.9841 10.6906C16.9355 10.7556 16.8718 10.8078 16.7986 10.8427L12.9236 12.6868C12.8198 12.7362 12.7362 12.8198 12.6869 12.9235ZM13.3511 14.5293L14.5294 13.351L18.0652 16.886L16.8861 18.0652L13.3511 14.5293Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

export default Wand;
