/* eslint-disable max-len */
import cx from 'clsx';

// types
// styles
import { useStyles } from './shared/styles';

const EchoIcon = ({ className, color, onClick }) => {
  const classes = useStyles();
  return (
    <svg
      className={cx({
        [classes.root]: true,
        [className || '']: className,
      })}
      onClick={onClick}
      width="13"
      height="12"
      viewBox="0 0 13 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="1" height="12" fill={color} />
      <rect width="1" height="8" transform="translate(3 2)" fill={color} />
      <rect width="1" height="12" transform="translate(6)" fill={color} />
      <rect width="1" height="8" transform="translate(9 2)" fill={color} />
      <rect width="1" height="12" transform="translate(12)" fill={color} />
    </svg>
  );
};

export default EchoIcon;
