import { Box } from '@mui/material';
import { useStyles } from './ValuesListCard.styles';

const ValuesListCard = ({ profileElementValues }) => {
  const classes = useStyles();

  const Chip = ({ name, percentage }) => {
    return (
      <Box className={classes.chip}>
        {name}
        <span className={classes.badge}>{Math.round(percentage)}%</span>
      </Box>
    );
  };

  return (
    <Box className={classes.card}>
      {profileElementValues.map((value, index) => (
        <Chip key={index} name={value.name} percentage={value.percentage} />
      ))}
    </Box>
  );
};

export { ValuesListCard };
