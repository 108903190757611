import { makeStyles } from '@mui/styles';

// colors
import { colors } from '../../../packages/colors';

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: 40,
  },
  body: { display: 'flex', flexDirection: 'column', gap: 24 },
  rflex: { display: 'flex', alignItems: 'center' },
  cflex: { display: 'flex', flexDirection: 'column' },
  sbflex: { display: 'flex', justifyContent: 'space-between', alignItems: 'center' },
  scorebox: {
    display: 'flex',
    flexDirection: 'column',
    background: colors.white,
    borderRadius: 8,
    boxShadow: '0px 0px 1px 0px #0000000A, 0px 0px 2px 0px #0000000F,0px 4px 8px 0px #0000000A',
  },
  cardContainer: {
    padding: 24,
    borderTop: `1px solid ${colors.neutral100}`,
    borderBottom: `1px solid ${colors.neutral100}`,
  },
  card: {
    border: `1px solid ${colors.neutral200}`,
    borderRadius: 8,
    padding: 16,
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
  },
  rating: {
    height: 20,
    '& path': {
      fill: colors.warning500,
    },
  },
  drawer: {
    '& .MuiPaper-root': {
      maxWidth: '920px !important',
    },
    '& .drawerBody': {
      padding: 0,
    },
  },
  averageScoreDrawer: {
    '& .MuiPaper-root': {
      maxWidth: '400px !important',
    },
    '& .drawerBody': {
      padding: 0,
    },
  },
  inputSearch: {
    height: 56,
    padding: 0,
    width: '100%',
    margin: '32px 0',
    '& fieldset': {
      borderRadius: '4px !important',
    },
  },
  btn: {
    width: 10,
  },
  paymentBadgeContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  loadMoreBtn: {
    fontSize: 14,
  },
  dataContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100vh - 272px)',
    justifyContent: 'space-between',
  },
  dataContainer2: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'space-between',
  },
  btnContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 10,
    paddingBottom: 10,
  },
  noResult: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  imageContainer: {
    width: 40,
    height: 40,
    borderRadius: 20,
  },
  badgeStyle1: {
    backgroundColor: '#2DA834',
    color: 'white',
    fontWeight: 600,
    fontSize: 12,
    lineHeight: '16px',
  },
  badgeStyle2: {
    backgroundColor: '#D97706',
    color: 'white',
    fontWeight: 600,
    fontSize: 12,
    lineHeight: '16px',
  },
  badgeStyle3: {
    backgroundColor: '#EF4444',
    color: 'white',
    fontWeight: 600,
    fontSize: 12,
    lineHeight: '16px',
  },
  drawerHeading: {
    fontFamily: 'Manrope',
    fontWeight: 700,
    fontSize: 24,
    lineHeight: '32px',
    letterSpacing: '-1%',
  },
  datePickerContainer: {
    '& .MuiInputBase-adornedStart': {
      cursor: 'pointer',
    },
    '& fieldset': {
      borderColor: '#A4B4C3',
      borderRadius: 4,
    },
    /* '& input': {
      cursor: 'pointer',
    }, */
  },
});
