import { useStyles } from './SessionHeader.styles';
import { Box } from '@mui/material';
import { ProfileInfo } from '../../profile-info';
import { Text } from '../../../atoms/typography';
import cx from 'clsx';
import { convertSecondToTime } from '../../../../../utils/CommonUtils';
import { useEffect, useRef, useState } from 'react';
import { btnType, Button } from '../../../atoms/button';
import { ProgressBar } from '../../../atoms/ProgressBar';

const SessionHeader = ({ appointment, isSessionStarted, isEndedSession, onDurationUpdated, onEndSession, startSignoff, goToSchedule }) => {
  const classes = useStyles({ isEndedSession });
  const [serviceDuration, setServiceDuration] = useState(0);
  const [progressBarValue, setProgressBarValue] = useState(0);
  const serviceCountdownRef = useRef(null);

  useEffect(() => {
    let duration = serviceDuration;
    setServiceDuration(0);
    if (isSessionStarted) {
      serviceCountdownRef.current = setInterval(() => {
        duration++;
        setServiceDuration(duration);
        if (onDurationUpdated) {
          onDurationUpdated(duration);
        }
        const percentage = (duration / (appointment?.serviceDuration * 60)) * 100;
        if (percentage <= 100) {
          setProgressBarValue(percentage);
        } else {
          setProgressBarValue(100);
        }

        if (isEndedSession) {
          clearInterval(serviceCountdownRef.current);
        }
      }, 1000);
    }
    return () => clearInterval(serviceCountdownRef.current);
  }, [isSessionStarted]);

  useEffect(() => {
    if (isEndedSession && serviceCountdownRef.current) {
      clearInterval(serviceCountdownRef.current);
    }
  }, [isEndedSession]);

  return (
    <Box className={classes.wrapper}>
      <Box className={classes.container}>
        {/* Header content */}
        <ProfileInfo
          type="member"
          photo={appointment.participantImage}
          nickName={`${appointment.patientFirstName} ${appointment.patientLastName}`}
          fullName={appointment.serviceName}
          uuid={appointment.patientUuid}
        />
        <Box className={classes.rightActions}>
          {isEndedSession ? (
            <Button className={classes.leaveBtn} variant={btnType.OUTLINE} onClick={goToSchedule}>
              Leave session
            </Button>
          ) : (
            <Text className={classes.time}>
              <span>
                <Text
                  className={cx(
                    serviceDuration > appointment?.serviceDuration * 60 ? classes.timeRed : classes.time,
                  )}
                >
                  {convertSecondToTime(serviceDuration)}
                </Text>
              </span>
              {` / ${appointment?.serviceDuration || 0} min`}
            </Text>
          )}
          {isEndedSession ? (
            <Button className={classes.signoffBtn} variant={btnType.OUTLINE} onClick={startSignoff}>
              Start note sign off
            </Button>
          ) : (
            <Button className={classes.endSessionBtn} onClick={onEndSession}>
              End Session
            </Button>
          )}
        </Box>
      </Box>
      <ProgressBar value={progressBarValue} className={classes.progressBar} />
    </Box>
  );
};

export { SessionHeader };
