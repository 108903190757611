import React from 'react';
import clsx from 'clsx';
import { Box } from '@mui/material';
import dayjs from 'dayjs';

import { Text } from '../../../../../../packages/ui/atoms/typography';
import { Badge, badgeStyle, badgeType } from '../../../../../../packages/ui/atoms/badge';
import { Avatar, avatarType } from '../../../../../../packages/ui/atoms/avatar';
import { Icons } from '../../../../../../packages/icons';

import { DomainPriorities } from '../../../../../../redux/modules/profile/types';
import { useStyles } from './SymptomCard.styles';

const SymptomBottomCard = ({ symptom, showBadge }) => {
  const classes = useStyles();
  const priority = symptom.priority || symptom.importanceLevel;

  return (
    <Box className={clsx(classes.rowCenter, classes.borderTop)}>
      <Box display="flex" gap="12px" alignItems="center">
        <Avatar src={symptom.avatar} size={40} variant={avatarType.CIRCLE} name={symptom.assignedBy} />
        <Box display="flex" gap="4px" flexDirection="column" justifyContent="flex-start">
          <Text className={classes.username}>{symptom.assignedBy}</Text>
          <Text className={classes.time}>{dayjs(symptom.assignedAt).format('MMMM DD, YYYY')}</Text>
        </Box>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        gap="12px"
        className={clsx(classes.interference, {
          [classes.interferenceResolved]: symptom.tagMetaData.interferenceInLife,
        })}
      >
        <Icons glyph="interference" />
        <Text className={classes.time}>
          {symptom.tagMetaData.interferenceInLife ? 'Interference with life' : 'No interference with life'}
        </Text>
      </Box>
      {showBadge && (
        <Badge
          variant={badgeType.FILLED}
          className={clsx(classes.badge, {
            [classes.badgeMisreported]: priority.name === DomainPriorities.MISREPORTED,
          })}
          style={badgeStyle[priority.name.toUpperCase()]}
        >
          {priority.name}
        </Badge>
      )}
    </Box>
  );
};

export { SymptomBottomCard };
