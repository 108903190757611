// import { makeStyles } from '@mui/styles';

// // colors
// import { colors } from '../../../colors';

// interface StyleProps {
//   open?: boolean;
//   animated?: boolean;
// }

// export const useStyles = makeStyles({
//   root: {
//     '& > .MuiPaper-root': {
//       minWidth: 162,
//       borderRadius: 6,
//       boxShadow: '0px 10px 20px rgb(0 0 0 / 10%)',
//     },
//     '& ul': {
//       paddingTop: 0,
//       paddingBottom: 0,
//     },
//   },
//   link: {
//     display: 'flex',
//     alignItems: 'center',
//     fontWeight: 500,
//     fontSize: 14,
//     padding: '14px 4px',
//     color: ({ open }: StyleProps) => (open ? colors.textHighContrast : colors.textPrimary),
//     '& > *': {
//       color: ({ open }: StyleProps) => (open ? colors.textHighContrast : colors.textMediumContrast),
//     },
//   },
//   menuItem: {
//     fontFamily: 'Manrope',
//     fontWeight: 600,
//     fontSize: 14,
//     lineHeight: '19px',
//     color: colors.textPrimary,
//   },
//   menuItemDisabled: {
//     opacity: 0.7,
//     cursor: 'default',
//   },
//   arrow: {
//     width: 24,
//     height: 24,
//     marginLeft: 10,
//     transition: '0.4s',
//     transform: ({ open, animated }: StyleProps) => `rotate(${open && animated ? '180deg' : 0})`,
//   },
// });

import { makeStyles } from '@mui/styles';

// colors
import { colors } from '../../../colors';

export const useStyles = makeStyles({
  root: {
    '& > .MuiPaper-root': {
      minWidth: 162,
      borderRadius: 6,
      boxShadow: '0px 10px 20px rgb(0 0 0 / 10%)',
    },
    '& ul': {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  link: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 500,
    fontSize: 14,
    padding: '14px 4px',
    color: props => (props.open ? colors.textHighContrast : colors.textPrimary),
    '& > *': {
      color: props => (props.open ? colors.textHighContrast : colors.textMediumContrast),
    },
  },
  menuItem: {
    fontFamily: 'Manrope',
    fontWeight: 600,
    fontSize: 14,
    lineHeight: '19px',
    color: colors.textPrimary,
  },
  menuItemDisabled: {
    opacity: 0.7,
    cursor: 'default',
  },
  arrow: {
    width: 24,
    height: 24,
    marginLeft: 10,
    transition: '0.4s',
    transform: props => `rotate(${props.open && props.animated ? '180deg' : 0})`,
  },
});
