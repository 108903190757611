import React, { useState } from 'react';
import { Badge, badgeSize, badgeType } from '../../../../packages/ui/atoms/badge';
import { Button, btnSize } from '../../../../packages/ui/atoms/button';
import { colors } from '../../../../packages/colors';
import { Icons } from '../../../../packages/icons';
import { Box } from '@mui/material';

import clsx from 'clsx';

import BoxShadow from '../../../../components/v2/BoxShadow';
import Typography from '../../../../components/v2/Typography';
import { typography } from '../../../../components/v2/Typography/index.constant';

import { useDispatch, useSelector } from 'react-redux';
import SubmissionStatusDrawer from './SubmissionStatusDrawer';

import { useStyles } from './index.styles';
import { InsuranceStates } from './index.constants';
import { getAuth } from '../../../../redux/modules/auth/selectors';
import { requestPayerSubmission } from '../../../../services/datalab/datalab.service';
import { showSnackbar } from '../../../../redux/modules/snackbar';
import dayjs from '../../../../utils/dayjs';

const InsuranceCard = ({ insurance, state, getAvailableStates }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { meta } = useSelector(getAuth);
  const [openSubmitDrawer, setOpenSubmitDrawer] = useState(false);

  const onSubmit = async () => {
    try {
      const bodyRequest = {
        state,
        insurance: insurance.name,
        insuranceId: insurance._id,
      };
      const userAccountId = meta.userId;
      const response = await requestPayerSubmission(bodyRequest, userAccountId);
      if (response.status === 200) {
        dispatch(
          showSnackbar({
            snackType: 'success',
            snackMessage: 'Submission successful',
          }),
        );
      }
      getAvailableStates();
    } catch (e) {
      console.warn(e);
      dispatch(
        showSnackbar({
          snackType: 'error',
          snackMessage: e?.data?.error,
        }),
      );
    }
  };

  const renderContentByStatus = () => {
    if (insurance?.submission?.currentStatus === InsuranceStates.IN_PROGRESS) {
      return (
        <Box sx={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
          <Icons glyph="rotate" color={colors.warning500} className={classes.rotate} />
          <Badge variant={badgeType.OUTLINED} className={clsx(classes.badge, classes.submittingBadge)}>
            <Typography {...typography.body.s.semibold}>Submitting</Typography>
          </Badge>
        </Box>
      );
    }
    if (insurance?.submission?.currentStatus === InsuranceStates.SUBMITTED) {
      return (
        <Badge variant={badgeType.OUTLINED} className={clsx(classes.badge, classes.submittedBadge)}>
          <Typography {...typography.body.s.semibold}>Submitted</Typography>
        </Badge>
      );
    }
    if (insurance?.submission?.currentStatus === InsuranceStates.FAILED) {
      return (
        <Badge variant={badgeType.OUTLINED} className={clsx(classes.badge, classes.failedBadge)}>
          <Typography {...typography.body.s.semibold}>Failed</Typography>
        </Badge>
      );
    }
    if (insurance?.submission?.currentStatus === InsuranceStates.REQUESTED) {
      return (
        <Badge variant={badgeType.OUTLINED} className={clsx(classes.badge, classes.submittedBadge)}>
          <Typography {...typography.body.s.semibold}>Requested</Typography>
        </Badge>
      );
    }
    if (insurance?.submission?.currentStatus === InsuranceStates.ACTION_REQUIRED) {
      return (
        <Badge variant={badgeType.OUTLINED} className={clsx(classes.badge, classes.actionRequiredBadge)}>
          <Typography {...typography.body.s.semibold}>Action required</Typography>
        </Badge>
      );
    }
    if (insurance?.submission?.currentStatus === InsuranceStates.REJECTED) {
      return (
        <Badge variant={badgeType.OUTLINED} className={clsx(classes.badge, classes.failedBadge)}>
          <Typography {...typography.body.s.semibold}>Rejected</Typography>
        </Badge>
      );
    }
    if (insurance?.submission?.currentStatus === InsuranceStates.APPROVED) {
      return (
        <Box sx={{ display: 'flex', gap: 5, alignItems: 'center' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
            <Typography {...typography.body.s.semibold} color={colors.neutral600}>
              Approval date
            </Typography>
            <Typography {...typography.body.s.medium}>
              {dayjs(insurance?.submission?.approvalDate).format('DD/MM/YYYY')}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
            <Typography {...typography.body.s.semibold} color={colors.neutral600}>
              PIN number
            </Typography>
            <Typography {...typography.body.s.medium}>{insurance?.submission?.providerPin}</Typography>
          </Box>
          <Icons glyph="check-circle" color={colors.success500} />
        </Box>
      );
    }

    return insurance?.name === 'Aetna' ? (
      <Button size={btnSize.XSMALL} onClick={onSubmit}>
        <Typography {...typography.body.s.bold}>Submit</Typography>
      </Button>
    ) : null;
  };

  return (
    <BoxShadow>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          '&:hover': {
            cursor: insurance.submission ? 'pointer' : 'default',
          },
        }}
        onClick={() => (insurance.submission ? setOpenSubmitDrawer(true) : null)}
      >
        <Typography {...typography.body.l.semibold}>{insurance?.name}</Typography>
        {renderContentByStatus()}
      </Box>
      {openSubmitDrawer && (
        <SubmissionStatusDrawer
          open={openSubmitDrawer}
          onClose={() => setOpenSubmitDrawer(false)}
          insurance={insurance}
        />
      )}
    </BoxShadow>
  );
};

export default InsuranceCard;
