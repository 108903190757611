import { FC, Fragment, useState } from 'react';

// components
import { Box, Grid } from '@mui/material';

import {
  Badge,
  badgeStyle,
  badgeType,
  colors,
  fontWeight,
  Heading,
  headingLevel,
  Icons,
  Link,
  Text,
  textLevel,
} from '../../../../../packages';
import { boxType, DashboardBox } from '../../../../../packages/ui/templates/dashboard-box';
import AssignmentsChart, {
  getRandomData,
} from '../../../../../pages/admin/plan-items/components/assignments-chart';

import { useStyles } from './ScoredCard.styles';

const ScoredCard = ({ item }) => {
  const [, setOpenDrawer] = useState(false);
  const classes = useStyles({});

  const handleOpenDrawer = e => {
    e.preventDefault();
    setOpenDrawer(true);
  };

  return (
    <Fragment>
      <Box className={classes.card}>
        <Box className={classes.cardHeadWrap}>
          <Box className={classes.dFlexColumn}>
            <Box className={classes.dFlexRow}>
              <Heading className={classes.headTitle} weight={fontWeight.SEMI_BOLD} level={headingLevel.M}>
                {item.name}
              </Heading>
              <Badge className={classes.badgeOutlined} style={badgeStyle.HIGH} variant={badgeType.OUTLINED}>
                {item.responses} responses
              </Badge>
            </Box>
            <Text className={classes.headSubtitle} level={textLevel.M} weight={fontWeight.MEDIUM}>
              {item.version}
            </Text>
          </Box>

          <Link className={classes.link} onClick={handleOpenDrawer}>
            View responses
            <Icons glyph="arrow-right" color={colors.primary600} />
          </Link>
        </Box>
        <Box className={classes.content}>
          <Box className={classes.reports}>
            <Grid container spacing={2}>
              {item.reports.map(report => (
                <Grid item xs={4} key={report.title}>
                  <DashboardBox
                    className={classes.reportItem}
                    label={report.title}
                    value={report.value}
                    showIncrement={report.changeValue !== 0}
                    isIncrease={report.changeValue > 0}
                    badge={report.changeValue > 0 ? report.changeValue : report.changeValue * -1}
                    type={report.changeValue === 0 ? boxType.TOTAL : undefined}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
          <AssignmentsChart
            title="Reports"
            className={classes.chart}
            chart={{
              series: [{ name: 'Reports', color: colors.primary500, data: getRandomData() }],
              tooltipConfig: {
                isShowTotal: false,
                title: 'Total',
              },
              grid: {
                row: {
                  colors: [
                    colors.success50,
                    colors.success50,
                    colors.primary50,
                    colors.warning50,
                    colors.destructive50,
                  ],
                },
              },
              showLegend: false,
            }}
          />
        </Box>
      </Box>
    </Fragment>
  );
};

export { ScoredCard };
