import { makeStyles } from '@mui/styles';

// colors
import { colors } from '../../../packages';

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    paddingTop: 88,
    gap: 40,
  },
  breadcrumbs: {
    position: 'absolute',
    top: -48,
    left: -64,
    height: 88,
    width: '100vw',
    borderBottom: `1px solid ${colors.neutral100}`,
    paddingLeft: 32,
    paddingRight: 32,
    display: 'flex',
    alignItems: 'center',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  title: {
    fontFamily: 'Source Serif Pro',
    fontWeight: 700,
    fontSize: 40,
    lineHeight: '48px',
  },
  iconBtn: {
    gap: 8,
    background: colors.white,
    border: `1px solid ${colors.primary}`,
    height: 48,
    paddingLeft: 20,
    paddingRight: 20,
  },
  content: {
    background: colors.white,
    borderRadius: 12,
    boxShadow: '0px 0px 1px 0px #0000000A, 0px 0px 2px 0px #0000000F, 0px 4px 8px 0px #0000000A',
  },
  badge3: {
    border: `1px solid ${colors.neutral200}`,
    borderRadius: 100,
    color: colors.neutral700,
    background: colors.white,
    height: 20,
    fontSize: 12,
    lineHeight: '16px',
    fontWeight: 600,
  },
  tabContainer: {
    padding: 16,
    borderBottom: `1px solid ${colors.neutral200}`,
  },
  tabContent: {
    padding: 24,
  },
  evaluationTabs: {
    '& .MuiTabs-indicator': { display: 'none' },
    '& .MuiButtonBase-root.MuiTab-root.MuiTab-labelIcon': {
      margin: 0,
      minHeight: 44,
      padding: '12px 16px',
      height: 44,
      textTransform: 'capitalize',
      color: colors.neutral700,
      '&.Mui-selected': {
        color: colors.neutral900,
        border: `1px solid ${colors.neutral200}`,
        borderRadius: 8,
      },
    },
  },
  evaluationTitle: {
    fontSize: 18,
    lineHeight: '24px',
    fontWeight: 600,
  },
  capitalize: {
    textTransform: 'capitalize',
  },
  loader: {
    display: 'flex',
    gap: 10,
    marginLeft: '46%',
    marginTop: '18%',
    alignItems: 'center',
  },
  collapsable: {
    width: '100%',
    position: 'relative',
    paddingBottom: 12,
    '& #collapseLabel': {
      fontSize: 16,
      lineHeight: '24px',
      fontWeight: 600,
      color: colors.neutral900,
    },
    '& .collapsableHeader': {
      justifyContent: 'flex-start',
    },
    '& .collapsableIcon': {
      marginLeft: 0,
      '& path': {
        fill: colors.neutral900,
      },
    },
  },
  questionName: {
    fontSize: 16,
    lineHeight: '24px',
    fontWeight: 500,
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 3,
    overflow: 'hidden',
    textOverflow: 'hidden',
  },
  questionTypeLabel: {
    fontSize: 16,
    lineHeight: '24px',
    fontWeight: 400,
    color: colors.neutral800,
  },
  questionType: {
    fontSize: 16,
    lineHeight: '24px',
    fontWeight: 400,
  },
  questionDesc: {
    fontSize: 14,
    lineHeight: '20px',
    fontWeight: 500,
    color: colors.neutral600,
  },
  questionDCTLabel: {
    fontSize: 14,
    lineHeight: '20px',
    fontWeight: 600,
    color: colors.neutral900,
  },
  question: {
    display: 'flex',
    gap: 8,
    paddingTop: 16,
    paddingBottom: 16,
  },
  badge2: {
    fontSize: 12,
    height: 18,
    border: `1px solid #FCD34D`,
    color: '#D97706',
  },
  badge4: {
    fontSize: 12,
    height: 18,
    border: `1px solid ${colors.primary300}`,
    color: colors.primary600,
    minWidth: 100,
  },
  addQuestionBtn: {
    fontSize: 14,
    lineHeight: '20px',
    fontWeight: 700,
    gap: 4,
  },
  addQuestionBtn2: {
    fontSize: 14,
    lineHeight: '20px',
    fontWeight: 700,
    gap: 4,
    width: 122,
    paddingTop: 0,
    paddingBottom: 16,
  },
  dctDeleteBtn: {
    position: 'absolute',
    right: 0,
    '& svg': { width: 20, '& path': { color: colors.destructive500 } },
  },
  icon: {
    minWidth: 24,
    marginRight: -4,
    '& svg': { width: 20, '& path': { color: colors.destructive500 } },
  },
  editIcon: {
    '& svg path': { color: colors.primary500 },
  },
  // Question Drawer
  drawer: {
    '& .MuiPaper-root': {
      maxWidth: '640px !important',
      backgroundColor: colors.neutral50,
      '& .drawerHeader': {
        backgroundColor: colors.white,
      },
    },
    '& .drawerBody': {
      padding: 0,
    },
  },
  searchInput: {
    height: 48,
  },
  // QuestionDrawer
  drawer2: {
    '& .MuiPaper-root': {
      maxWidth: '640px !important',
      backgroundColor: colors.white,
    },
    '& .drawerBody': {
      padding: '80px 0',
    },
  },
  header2: {
    position: 'fixed',
    display: 'flex',
    justifyContent: 'space-between',
    top: 0,
    width: 640,
    maxWidth: 'inherit',
    alignItems: 'center',
    padding: 24,
    backgroundColor: colors.white,
    borderBottom: `1px solid ${colors.neutral100}`,
    zIndex: 100,
  },
  headerTitle: {
    fontWeight: 700,
    fontSize: 24,
    lineHeight: '32px',
  },
  footer: {
    position: 'fixed',
    display: 'flex',
    bottom: 0,
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    maxWidth: 'inherit',
    height: 80,
    padding: 16,
    borderTop: `1px solid ${colors.neutral100}`,
    '& button': {
      height: 48,
    },
    backgroundColor: colors.white,
  },
  backBtn: {
    width: 40,
    height: 40,
    minWidth: 40,
    minHeight: 40,
    padding: '18px 12px',
    borderColor: colors.primary300,
    '& svg': {
      color: colors.primary500,
      width: 18,
      height: 15,
    },
  },
  questionLabel: {
    fontSize: 14,
    lineHeight: '20px',
    fontWeight: 700,
  },
  section: { display: 'flex', flexDirection: 'column', gap: 8, position: 'relative', marginBottom: 24 },
  section2: { display: 'flex', flexDirection: 'column', gap: 24, position: 'relative', marginBottom: 24 },
  editDCTHeading: {
    fontSize: 18,
    fontWeight: 700,
    lineHeight: '24px',
    color: colors.neutral900,
  },
  DCTLabel: {
    fontSize: 14,
    fontWeight: 700,
    lineHeight: '20px',
    color: colors.neutral900,
    paddingBottom: 8,
  },
  DCTCheckBox: {
    marginRight: '12px',
  },
  DCTCheckBoxLabel: {
    fontSize: 14,
    lineHeight: '20px',
    fontWeight: 500,
  },
  dctDangerBtn: {
    color: colors.destructive600,
    gap: 8,
    fontSize: 14,
    lineHeight: '20px',
    fontWeight: 700,
  },
  headerSubTitle: {
    fontSize: 16,
    lineHeight: '24px',
    fontWeight: 500,
    color: colors.neutral600,
  },
  btnContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 10,
    paddingBottom: 10,
  },
  loadMoreBtn: {
    fontSize: 14,
  },
  noResult: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
});
