import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ButtonGroup, Stack } from '@mui/material';
// styles
import {
  Badge,
  badgeStyle,
  badgeType,
  Button,
  fontWeight,
  Heading,
  headingLevel,
  iconBtnType,
  IconButton,
  Menu,
  Text,
} from '../../../packages';
import { Filter, Table } from '../../../packages/ui/organisms/table';
// API calls
import { conversationActionCreators } from '../../../redux/modules/conversation';
import { getConversation } from '../../../redux/modules/conversation/selectors';

// components
import { BaseLayout } from '../../../layouts/base';
import CreateConnectionModal from '../../../layouts/base/CreateConnectionModal';
import clsx from 'clsx';
import { useStyles } from './contentBlockTemplates.styles';
import { CbMultiSelectFilterOptionsMock } from './ContentBlockLibrary.constants';
import { ContentBlockDrawer } from './components/ContentBlockDrawer';
import { templatesColumns } from './contentBlock.mock';
import { viewGroups } from '../data-collection-templates/DataCollectionTemplates.mock';

const ContentBlockLibrary = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [tableParams, setTableParams] = useState({
    search: { searchKey: '' },
    pagination: { currentPage: 1, rowsPerPage: 10 },
    sorter: { direction: 'desc', column: '' },
  });
  const {
    profileElements: { profileElementList },
  } = useSelector(getConversation);

  const { libraryContentBlocks } = useSelector(getConversation);
  const { isLoading } = libraryContentBlocks;
  const [openCbDrawer, setOpenCbDrawer] = useState(false);
  const [CBList, setCBList] = useState([]);
  const [addCbButtonPressed, setAddCbButtonPressed] = useState(false);
  const [contentBlockData, setContentBlockData] = useState(null);
  const isDelete = true;
  const [isDeleteContentBlock, setIsDeleteContentBlock] = useState(false);
  const [selectedCBID, setselectedCBID] = useState(null);
  const [viewBy, setViewBy] = useState(viewGroups[0].type);

  const onAddClick = () => {
    setOpenCbDrawer(true);
    setAddCbButtonPressed(true);
    setContentBlockData(null);
  };

  const onEditClick = cbId => {
    setOpenCbDrawer(true);
    setAddCbButtonPressed(false);
    const contentBlock = libraryContentBlocks.results.find(block => block.cbId === cbId);
    if (contentBlock) {
      setContentBlockData(contentBlock);
    } else {
      console.log('ContentBlock not found.');
    }
  };

  const onApproveClick = cbId => {
    const contentBlock = libraryContentBlocks.results.find(block => block.cbId === cbId);
    if (contentBlock) {
      const contentBlockId = contentBlock.cbId;
      contentBlock.status = viewGroups[0].type;
      const data = contentBlock;
      dispatch(conversationActionCreators.updateLibraryContentBlocks({ data, contentBlockId }));
      getCBs();
    } else {
      console.log('ContentBlock not found.');
    }
  };

  const onRowClick = rowItem => {
    setOpenCbDrawer(true);
    setAddCbButtonPressed(false);
    const contentBlock = libraryContentBlocks.results.find(block => block.cbId === rowItem.actions.id);
    if (contentBlock) {
      setContentBlockData(contentBlock);
    } else {
      console.log('ContentBlock not found.');
    }
  };

  const renderColumns = templatesColumns?.map(column => {
    if (column.id === 'name') {
      return {
        ...column,
        renderCell: name => (
          <Text className={classes.name} weight={fontWeight.MEDIUM}>
            {name}
          </Text>
        ),
      };
    }
    if (column.id === 'type') {
      return {
        ...column,
        renderCell: type => (
          <Text className={classes.name} weight={fontWeight.MEDIUM}>
            {type}
          </Text>
        ),
      };
    }
    if (column.id === 'actions') {
      return {
        ...column,
        renderCell: ({ id: cbId, dctId }) => {
          if (dctId && dctId !== '') {
            return null;
          }
          const items = [
            { label: 'Edit', onClick: () => onEditClick(cbId) },
            {
              label: 'Delete',
              onClick: () => {
                setIsDeleteContentBlock(true);
                setselectedCBID(cbId);
              },
            },
          ];
          if (viewBy === viewGroups[1].type) {
            items.unshift({ label: 'Approve', onClick: () => onApproveClick(cbId) });
          }
          return (
            <Menu
              icon="more"
              className={classes.menu}
              itemsWrapperClassName={classes.menuItemsWrapper}
              items={items}
            />
          );
        },
      };
    }
    return column;
  });

  useEffect(() => {
    const formatCB = libraryContentBlocks.results.map(item => ({
      name: item.basicInfo.text,
      type: item.basicInfo.type
        .replaceAll('-', ' ')
        .toLowerCase()
        .replace(/\b\w/g, s => s.toUpperCase()),
      actions: { id: item.cbId, dctId: item.dctId },
    }));
    setCBList(formatCB);
  }, [libraryContentBlocks]);

  const getCBs = () => {
    const multiselectFilters = tableParams.search?.multiSelectFilter;
    const { pagination, search, sorter } = tableParams;
    const queryParams = {
      orderBy: sorter.direction,
      pageNumber: pagination.currentPage - 1,
      pageSize: pagination.rowsPerPage,
      searchQuery: search.searchKey,
      sortBy: [sorter.column],
      status: viewBy,
    };
    if (multiselectFilters && multiselectFilters.Type && multiselectFilters.Type.length > 0) {
      queryParams.types = multiselectFilters.Type.join(',');
    }
    dispatch(conversationActionCreators.fetchLibraryContentBlocks(queryParams));
  };

  const addContentBlock = data => {
    setOpenCbDrawer(false);
    if (!addCbButtonPressed) {
      const contentBlockId = data.cbId;
      data.status = viewBy;
      dispatch(conversationActionCreators.updateLibraryContentBlocks({ data, contentBlockId }));
    } else {
      data.status = viewGroups[0].type;
      dispatch(conversationActionCreators.addContentBlock(data));
    }
    getCBs();
  };

  const closeCbDrawer = () => {
    setOpenCbDrawer(false);
    setAddCbButtonPressed(false);
  };

  useEffect(() => {
    getCBs();
  }, [tableParams]);

  useEffect(() => {
    getCBs();
  }, [viewBy]);

  const handleContentBlockDeletion = cbId => {
    setOpenCbDrawer(false);
    const contentBlockId = cbId;
    dispatch(conversationActionCreators.deleteLibraryContentBlocks(contentBlockId));
    getCBs();
  };

  /**
   * @function onChangeView
   * @params type
   * @description This method is used to handle toggle between ACTIVE and DRAFT DCT Lists.
   */
  const onChangeView = type => () => {
    setTableParams({
      search: { searchKey: '' },
      pagination: { currentPage: 1, rowsPerPage: 10 },
      sorter: { direction: 'desc', column: '' },
    });
    setViewBy(type);
  };

  return (
    <>
      <ContentBlockDrawer
        isOpen={openCbDrawer}
        blockToUpdate={contentBlockData}
        profileElements={profileElementList}
        onClose={closeCbDrawer}
        onDelete={cbId => {
          setIsDeleteContentBlock(true);
          setselectedCBID(cbId);
        }}
        onSave={addContentBlock}
        addCbButtonPressed={addCbButtonPressed}
        getCBs={getCBs}
      />
      <BaseLayout>
        <div className={classes.root}>
          <Stack direction="row" justifyContent="space-between" spacing={2}>
            <Stack direction="row" alignItems="center" gap={2}>
              <Heading className={classes.heading} level={headingLevel.XL} weight={fontWeight.BOLD}>
                Content Block Library
              </Heading>
              <Badge
                className={classes.totalMemberBadge}
                variant={badgeType.OUTLINED}
                style={badgeStyle.UNRELATED}
              >
                {libraryContentBlocks.totalRecords} total
              </Badge>
            </Stack>

            <IconButton
              className={classes.addProfileBtn}
              icon="plus"
              variant={iconBtnType.PRIMARY}
              onClick={onAddClick}
            >
              Add new
            </IconButton>
          </Stack>
          <Table
            searchProps={{
              placeholder: 'Search name',
              filterProps: {
                variant: Filter.tableFilterType.MULTIPLE,
                multiSelectOptions: CbMultiSelectFilterOptionsMock,
                allOptionLabel: 'All template',
              },
              leftSearchComponent: (
                <ButtonGroup variant="outlined" aria-label="outlined button group">
                  {viewGroups.map(({ type, label }, index) => (
                    <Button
                      key={index}
                      className={clsx(classes.btnGroupIcon, {
                        [classes.btnGroupIconActive]: type === viewBy,
                      })}
                      onClick={onChangeView(type)}
                    >
                      {label}
                    </Button>
                  ))}
                </ButtonGroup>
              ),
            }}
            gridProps={{
              columns: renderColumns,
              data: CBList,
              isLoading,
              onRowClick,
            }}
            paginationProps={{
              currentRows: tableParams.pagination.rowsPerPage,
              totalCount: libraryContentBlocks.totalRecords,
              showRowsPerPage: true,
            }}
            value={tableParams}
            onChange={setTableParams}
            className={classes.table}
          />
        </div>
        {isDeleteContentBlock && (
          <CreateConnectionModal
            icon="x-circle-outlined"
            open={isDeleteContentBlock}
            title="Delete Content Block"
            content="Do you want to delete this content block?"
            lbtnLabel="Cancel"
            rbtnLabel="Confirm"
            onClose={() => setIsDeleteContentBlock(false)}
            onCancel={() => setIsDeleteContentBlock(false)}
            onSubmit={() => {
              handleContentBlockDeletion(selectedCBID);
              setIsDeleteContentBlock(false);
            }}
            isLoading={false}
            isDelete={isDelete}
          />
        )}
      </BaseLayout>
    </>
  );
};

export { ContentBlockLibrary };
