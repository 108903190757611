import { useEffect } from 'react';
import { Box } from '@mui/material';
import { fontWeight, Heading, headingLevel } from '../../../packages';
import { Icons } from '../../../packages';
import { colors } from '../../../packages';
import { useDispatch, useSelector } from 'react-redux';
import { notificationActionCreators } from '../../../redux/modules/notification';
import { BaseLayout } from '../../../layouts/base';
import { useStyles } from './NotificationsList.styles';
import { NotificationItem } from './components/notification-item';
import PriorityEmpty from '../../provider/member-detail/components/PriorityEmpty';
import { getNotifications } from '../../../redux/modules/notification/selectors';

const Notifications = () => {
  const classes = useStyles();
  const { isLoading, adminNotifications } = useSelector(getNotifications);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(notificationActionCreators.fetchAdminNotifications());
  }, []);

  const handleNotificationRemove = itemId => {
    setTimeout(() => {
      dispatch(notificationActionCreators.removeAdminNotification({ notificationId: itemId }));
    }, 2000);
  };

  return (
    <BaseLayout>
      <Box className={classes.root}>
        <Box className={classes.header}>
          <Heading className={classes.heading} level={headingLevel.XL} weight={fontWeight.BOLD}>
            Notifications
          </Heading>
        </Box>
        <Box gap="16px" display="flex" flexDirection="column">
          {isLoading && adminNotifications.length === 0 ? (
            <Box display="flex" justifyContent="center" sx={{ paddingY: 5 }}>
              <Icons className="rotate linear infinite" glyph="in-progress" color={colors.primary} />
            </Box>
          ) : (
            <>
              {adminNotifications.length > 0 ? (
                adminNotifications.map(item => (
                  <NotificationItem key={item.id} item={item} onClick={handleNotificationRemove} />
                ))
              ) : (
                <PriorityEmpty
                  title="No more Notifications."
                  description="All caught up. Currently there are no additional updates."
                  glyph="dog-14-treat"
                  className={classes.noNotification}
                  glyphOrientation="portrait"
                />
              )}
            </>
          )}
        </Box>
      </Box>
    </BaseLayout>
  );
};

export { Notifications };
