import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import clsx from 'clsx';
import {
  Badge,
  badgeStyle,
  badgeType,
  colors,
  fontWeight,
  Heading,
  headingLevel,
  iconBtnType,
  IconButton,
  Icons,
} from '../../../../../packages';

import { createNewAssociatedTag } from '../../../../../services/member/member.service';
import { useDomainType } from '../../../../../hooks/useDomainType';
import { profileActionCreators } from '../../../../../redux/modules/profile';
import { getProfile } from '../../../../../redux/modules/profile/selectors';

import { getAuth } from '../../../../../redux/modules/auth/selectors';
import { DomainTypesEnum } from '../../../../../constants/CommonConstants';
import PriorityEmpty from '../PriorityEmpty';
import SymptomCard from './symptom-card';
import SymptomDetail from './symptom-detail';
import SymptomEditing from './symptom-editing';
import AddNewReport from '../AddNewReport';

import { stepTitles } from '../AddNewReport/AddNewReport.constants';
import { useStyles } from '../../MemberDetail.styles';

const Symptoms = () => {
  const classes = useStyles({});
  const dispatch = useDispatch();
  const { memberId } = useParams();
  const { meta } = useSelector(getAuth);
  const { profile, selectedPatient } = useSelector(getProfile);
  const [isLoading, domainData, domainOptions, lookupData] = useDomainType({
    type: DomainTypesEnum.SYMPTOMS,
  });

  const medicalConditions = lookupData?.medicalCondition;
  // console.log('look up data ', lookupData);
  const [isSaving, setIsSaving] = useState(false);
  const [errorApi, setError] = useState(null);
  const [showAddSymptom, setShowAddSymptom] = useState(false);
  const [selectedSymptom, setSelectedSymptom] = useState(null);
  const [isEditingSymptom, setIsEditingSymptom] = useState(false);

  const onAddNewSymptom = () => {
    setShowAddSymptom(true);
  };

  const onSelectedSymptom = (symptom, isEdit = false) => {
    setSelectedSymptom(symptom);
    setIsEditingSymptom(isEdit);
  };

  const onCloseDrawer = () => {
    if (!isSaving) {
      setSelectedSymptom(null);
      setIsEditingSymptom(false);
      setShowAddSymptom(false);
    }
  };

  const onClickNewReport = () => {
    setShowAddSymptom(true);
  };

  const onUpdateSymptom = async data => {
    const { status: importanceLevel, notes, isInterference, ...remaining } = data;
    setIsSaving(true);
    setError(null);
    try {
      await createNewAssociatedTag({
        patientId: memberId,
        tagItems: [
          {
            assignedBy: profile.fullName || meta.nickName,
            domainElementId: selectedSymptom.domainElementId,
            metaData: {
              notes,
              interferenceInLife: isInterference,
              selectedImportance: importanceLevel,
              specification: {
                relatedToMedicalCondition: remaining.medicalConditions,
              },
            },
          },
        ],
      });
      setIsSaving(false);
      dispatch(profileActionCreators.fetchDomainTypes(memberId));
      onCloseDrawer();
    } catch (error) {
      setError(error.data.message || error.data.errors[0]?.endUserMessage);
      setIsSaving(false);
    }
  };

  const onSubmitNewReport = payload => {
    // console.log('payload = ', payload);
    const data = {
      patientId: memberId,
      tagItems: payload.map(item => ({
        assignedBy: profile.fullName || meta.nickName,
        domainElementId: item.item.value,
        metaData: {
          interferenceInLife: item.isInterference,
          notes: item.notes,
          selectedImportance: item.status,
          specification: {
            relatedToMedicalCondition: item.medicalConditions,
          },
        },
      })),
    };
    dispatch(
      profileActionCreators.createNewAssociatedTag({
        data,
        callback: errorMsg => {
          if (!errorMsg) {
            onCloseDrawer();
          }
        },
      }),
    );
  };

  return (
    <>
      <AddNewReport
        isOpen={showAddSymptom}
        onClose={onCloseDrawer}
        title="Report new symptoms"
        stepTitles={stepTitles}
        showInterference
        searchLabel="Select one or more symptoms"
        options={domainOptions}
        onSubmit={onSubmitNewReport}
        type={DomainTypesEnum.SYMPTOMS}
      />
      <SymptomDetail
        open={!!selectedSymptom && !isEditingSymptom}
        onClose={onCloseDrawer}
        onClickNewReport={onClickNewReport}
        symptom={selectedSymptom}
      />
      <SymptomEditing
        title="Update symptom"
        isOpen={!!selectedSymptom && isEditingSymptom}
        onClose={onCloseDrawer}
        symptom={selectedSymptom}
        isSaving={isSaving}
        error={errorApi}
        onSave={onUpdateSymptom}
        type={DomainTypesEnum.SYMPTOMS}
        medicalConditions={medicalConditions}
      />
      <div className={classes.headingWithBtnWrapper}>
        <div className={classes.noteHeaderLeftSide}>
          <Heading className={classes.heading} level={headingLevel.XL} weight={fontWeight.BOLD}>
            Symptoms
          </Heading>
          <Badge variant={badgeType.OUTLINED} style={badgeStyle.UNRELATED} className={classes.totalBadge}>
            {domainData?.relatedElements.length || 0} total
          </Badge>
        </div>
        {domainData?.relatedElements.length > 0 && (
          <IconButton
            className={classes.addBtn}
            icon="plus"
            variant={iconBtnType.PRIMARY}
            onClick={onAddNewSymptom}
          >
            Add Symptom
          </IconButton>
        )}
      </div>
      <div className={clsx(classes.noteCardWrapper)}>
        {isLoading ? (
          <Icons className="rotate linear infinite" glyph="in-progress" color={colors.primary} />
        ) : (
          <>
            {domainData?.relatedElements.length ? (
              <>
                {domainData.relatedElements.map((item, idx) => (
                  <SymptomCard symptom={item} key={idx} onClick={onSelectedSymptom} />
                ))}
              </>
            ) : (
              <PriorityEmpty
                title={`No Symptoms reported for ${selectedPatient?.member.nickName} yet.`}
                // eslint-disable-next-line max-len
                description="If you've spoken with them about their symptoms, make sure you save that information here."
                addText="Add Symptom"
                onAdd={onAddNewSymptom}
                glyph="face-talking-sad"
              />
            )}
          </>
        )}
      </div>
    </>
  );
};

export { Symptoms };
