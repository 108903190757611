import { makeStyles } from '@mui/styles';

// colors
import { colors } from '../../../../../packages';

export const useStyles = makeStyles({
  drawer: {
    '& .MuiDrawer-paper': {
      maxWidth: '100vw',
      width: '100vw',
      minHeight: '100vh',
      '& .drawerBody': {
        padding: 0,
      },
    },
  },
  root: {
    backgroundColor: colors.white,
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 72,
    padding: 16,
    borderBottom: `1px solid ${colors.neutral100}`,
    backgroundColor: colors.white,
    zIndex: 1000,
  },
  heading: {
    fontFamily: 'Source Serif Pro',
    margin: 0,
  },
  subtitle: {
    fontWeight: 400,
    fontSize: 18,
    lineHeight: '28px',
    margin: 0,
    color: colors.neutral600,
  },
  backBtn: {
    width: 40,
    height: 40,
    minWidth: 40,
    borderRadius: 4,
    backgroundColor: colors.primary500,
    '&:hover': {
      backgroundColor: colors.primary600,
    },
    '& svg': {
      '& path': {
        fill: colors.white,
      },
      width: 20,
    },
  },
  headerTitle: {
    margin: 0,
    fontSize: 18,
    lineHeight: '24px',
  },
  viewChangeBtn: {
    backgroundColor: colors.primary50,
    color: colors.primary600,
    height: 40,
    fontSize: 14,
    lineHeight: '20px',
    '&:hover': {
      color: colors.white,
    },
  },
  upgradeBtn: {
    color: colors.white,
    height: 40,
    fontSize: 14,
    lineHeight: '20px',
  },
  dividerWrap: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '32px 20px',
    gap: 32,
  },
  divider: {
    height: 1,
    flex: 1,
    width: '100%',
    backgroundColor: colors.neutral100,
  },
  content: {
    display: 'block',
    width: 'calc(100% - 320px)',
    height: '100%',
    backgroundColor: colors.neutral50,
  },
  contentLeft: {
    paddingTop: 72,
    backgroundColor: colors.neutral50,
  },
  contentRight: {
    position: 'fixed',
    top: 72,
    right: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: 320,
    height: 'calc(100vh - 152px)',
    backgroundColor: colors.white,
    padding: 16,
    transition: '0.25s',
    borderLeft: `1px solid ${colors.neutral100}`,
    borderRight: `1px solid ${colors.neutral100}`,
    overflowY: 'auto',

    '& .active': {
      backgroundColor: colors.neutral50,
    },
  },
  contentRightTexts: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  buttonContent: {
    display: 'flex',
    justifyContent: 'flex-start',
    width: '100%',
    padding: '12px 16px',
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '20px',
    color: colors.neutral700,
    textDecoration: 'none',
    borderRadius: 6,
    backgroundColor: colors.white,

    '&:hover': {
      cursor: 'pointer',
      backgroundColor: colors.neutral50,
    },
  },
  label: {
    fontSize: 14,
    lineHeight: '20px',
  },
  changeOrderBtn: {
    position: 'fixed',
    width: 288,
    bottom: 16,
    right: 16,
    backgroundColor: colors.white,
    color: colors.neutral700,
  },
  tableSearch: {
    marginTop: 16,
    marginBottom: 16,
    '& > div': {
      padding: 0,
      '& > div': {
        backgroundColor: colors.white,
      },
    },
  },
  addCBIcon: {
    color: colors.primary500,
  },
});
