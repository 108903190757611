import { Box, Stack } from '@mui/material';
import dayjs from 'dayjs';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import {
  Badge,
  badgeStyle,
  badgeType,
  btnType,
  Button,
  fontWeight,
  Heading,
  Text,
} from '../../../../packages';
import { Drawer, drawerType } from '../../../../packages/ui/organisms/drawer';
import { ProfileInfo } from '../../../../packages/ui/templates/profile-info';
import { useStyles } from './ProviderFeeDetailDrawer.styles';

const InvoiceDetailDrawer = ({ open, onClose, invoice, onMarkAsPaid, onMarkAsReject }) => {
  const classes = useStyles();

  const navigate = useNavigate();

  const renderLabel = label => <Text className={classes.infoBlockLabel}>{label}</Text>;

  const renderBoldLabel = label => <Text className={classes.infoBlockBold}>{label}</Text>;

  const renderCPTRow = (label, value) => {
    return (
      <Box display="flex" justifyContent="space-between">
        <Text className={clsx(classes.infoBlockBold, classes.fontWeight400)}>{label}</Text>
        <Text className={classes.infoBlockBold}>{value}</Text>
      </Box>
    );
  };

  const renderInsuranceRow = (label, value) => {
    return (
      <Box display="flex" flexDirection="column" marginBottom={1}>
        <Text className={clsx(classes.infoBlockLabel, classes.nobottom)}>{label}</Text>
        <Text className={clsx(classes.infoBlockBold, classes.fontWeight500)}>{value || 'N/A'}</Text>
      </Box>
    );
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      title="Provider details"
      variant={drawerType.FORM}
      footer={<Box />}
      className={classes.drawer}
    >
      {invoice && (
        <Box>
          <Box className={classes.top}>
            <Stack direction="row" alignItems="center" gap={1.5}>
              <Heading className={classes.amount}>${invoice?.amount?.amountPaid || 0}</Heading>
              <Stack direction="column" justifyContent="space-between" flex={1}>
                <Badge
                  variant={badgeType.OUTLINED}
                  style={
                    invoice?.status === 'Paid'
                      ? badgeStyle.RESOLVED
                      : invoice?.status === 'Submitted' || invoice?.status === 'Created'
                      ? badgeStyle.PRIMARY
                      : badgeStyle.HIGH
                  }
                  className={clsx(
                    classes.status,
                    { [classes.unpaidStatus]: invoice?.status === 'Rejected' },
                    {
                      [classes.submittedStatus]:
                        invoice?.status === 'Submitted' || invoice?.status === 'Created',
                    },
                  )}
                >
                  {invoice?.status}
                </Badge>
                {invoice?.signOffAt ? (
                  <Text className={classes.time}>
                    {dayjs(invoice?.signOffAt).format('MM/DD/YYYY - h:mm a')}
                  </Text>
                ) : null}
              </Stack>
            </Stack>
          </Box>
          <Box className={classes.infoWrap}>
            <Box className={classes.infoBlock}>
              {renderLabel('Member')}
              <ProfileInfo
                type="member"
                photo={invoice?.member?.profileImage}
                nickName={invoice?.member?.nickName}
                fullName={invoice?.member?.fullName}
                memberId={invoice?.member?.id}
              />
            </Box>
            <Box className={classes.infoBlock}>
              {renderLabel('Provider')}
              <ProfileInfo
                type="provider"
                photo={invoice?.provider?.profileImage}
                // eslint-disable-next-line jsx-a11y/aria-role
                role="provider"
                fullName={invoice?.provider?.fullName}
                memberId={invoice?.provider?.id}
              />
            </Box>
            <Box
              onClick={() =>
                navigate(`/admin/members/${invoice?.member?.id}/appointments/${invoice.appointmentId}`)
              }
              className={classes.infoBlock}
            >
              {renderLabel('Session type')}
              <Box className={classes.member}>
                <Text className={classes.id} weight={fontWeight.MEDIUM}>
                  {invoice?.sessionType || 'N/A'}
                </Text>
              </Box>
            </Box>
            <Box
              onClick={() =>
                navigate(`/admin/members/${invoice?.member?.id}/appointments/${invoice.appointmentId}`)
              }
              className={classes.infoBlock}
            >
              {renderLabel('Date')}
              <Box className={classes.member}>
                <Text className={classes.appointmentTime} weight={fontWeight.MEDIUM}>
                  {dayjs(invoice?.timeStamp).format('MMM D, YYYY - h:mm a')}
                </Text>
              </Box>
            </Box>
          </Box>
          <Box className={classes.infoWrap}>
            <Box className={classes.infoBlock}>
              <Box display="flex">
                {invoice?.cptList?.length > 0 && (
                  <Box className={classes.row} flex={1}>
                    {renderBoldLabel('CPT codes & fees')}
                    {invoice?.cptList?.map(cpt =>
                      renderCPTRow(cpt?.cptCode, `$${cpt?.fee || cpt?.providerFee || '0'}`),
                    )}
                  </Box>
                )}
                <Box className={classes.row} marginLeft={4} flex={1.5}>
                  {renderBoldLabel('Insurance details')}
                  {renderInsuranceRow('Insurance Carrier', invoice?.insuranceDetail?.payer)}
                  {renderInsuranceRow('Plan Carrier', invoice?.insuranceDetail?.plan)}
                  {renderInsuranceRow('Status', invoice?.insuranceDetail?.isActive ? 'Active' : 'Inactive')}
                </Box>
              </Box>
            </Box>
          </Box>
          <Box className={classes.footer}>
            <Button variant={btnType.TEXT} onClick={onClose}>
              Close
            </Button>
            <Box
              className={classes.btnGroup}
              sx={{
                '& > button': {
                  height: 48,
                },
              }}
            >
              {invoice?.status === 'Submitted' && (
                <>
                  <Button className={classes.btn} onClick={onMarkAsReject}>
                    Reject
                  </Button>
                  <Button onClick={onMarkAsPaid}>Mark as Paid</Button>
                </>
              )}
            </Box>
          </Box>
        </Box>
      )}
    </Drawer>
  );
};

export { InvoiceDetailDrawer };
