import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
  cashSlot: {
    border: 'solid 1px #D4DDE5',
    color: 'black',
    background: 'white',
    fontSize: '14px',
    lineHeight: '20px',
  },
  conflictSlot: {
    border: 'solid 1px #D4DDE5',
    color: '#B80261',
    background: 'white',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '20px',
  },
}));
