// import { FC } from 'react';
// import cx from 'clsx';
// import FactCheck from '@mui/icons-material/FactCheck';
// import FactCheckOutlined from '@mui/icons-material/FactCheckOutlined';

// // colors
// import { colors } from '../colors';

// // glyphs
// import { glyphs } from './glyphs';

// // types
// import { IIconsProps } from './Icons.types';

// // styles
// import { useStyles } from './Icons.styles';

// const Icons: FC<IIconsProps> = ({ className = '', color = colors.primary, glyph, onClick, ...rest }) => {
//   const classes = useStyles();

//   const Glyph = glyphs[glyph];
//   if (glyph === 'fact-check') {
//     return <FactCheck className={cx(classes.root, className)} sx={{ color }} onClick={onClick} />;
//   }

//   if (glyph === 'fact-check-outline') {
//     return <FactCheckOutlined className={cx(classes.root, className)} sx={{ color }} onClick={onClick} />;
//   }

//   if (Glyph) {
//     return (
//       <Glyph
//         className={cx(classes.root, className)}
//         color={color}
//         onClick={onClick}
//         width={rest?.width ?? null}
//         height={rest?.height ?? null}
//       />
//     );
//   }
//   return null;
// };

// export { Icons };

import cx from 'clsx';
import FactCheck from '@mui/icons-material/FactCheck';
import FactCheckOutlined from '@mui/icons-material/FactCheckOutlined';

// colors
import { colors } from '../colors';

// glyphs
import { glyphs } from './glyphs';

// styles
import { useStyles } from './Icons.styles';

const Icons = ({ className = '', color = colors.primary, glyph, onClick = null, ...rest }) => {
  const classes = useStyles();

  const Glyph = glyphs[glyph];
  if (glyph === 'fact-check') {
    return <FactCheck className={cx(classes.root, className)} sx={{ color }} onClick={onClick} />;
  }

  if (glyph === 'fact-check-outline') {
    return <FactCheckOutlined className={cx(classes.root, className)} sx={{ color }} onClick={onClick} />;
  }

  if (Glyph) {
    return (
      <Glyph
        className={cx(classes.root, className)}
        color={color}
        onClick={onClick}
        width={rest?.width ?? null}
        height={rest?.height ?? null}
      />
    );
  }

  return null;
};

export { Icons };
