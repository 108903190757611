import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { useFormik } from 'formik';
import dayjs from 'dayjs';

import { Box } from '@mui/material';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { Drawer, drawerPosition, drawerType } from '../../../../packages/ui/organisms/drawer';
import { TabPanel, Tabs } from '../../../../packages/ui/atoms/tabs';
import {
  Button,
  colors,
  fontWeight,
  Heading,
  headingLevel,
  Icons,
  Menu,
  positionType,
  Select,
  selectType,
  TextError,
  Toggle,
} from '../../../../packages';
import { ProfileInfo } from '../../../../packages/ui/templates/profile-info';

import googleIcon from '../../../../assets/images/google-meet.png';
import getConfig from '../../../../config';

import { selectCalendarsState } from '../../../../redux/modules/profile/selectors';
import { profileActionCreators } from '../../../../redux/modules/profile';
import { showSnackbar } from '../../../../redux/modules/snackbar';
import {
  alllowCalendarAccess,
  createCalendar,
  deleteCalendar,
  deleteGoogleCalendarEvents,
  getAllCalendarsFromAccessToken,
  syncConfidantToGoogle,
  syncGoogleToConfidant,
  updateCalendar,
} from '../../../../services/profile/profile.service';

import CustomGoogleButton from './CustomGoogleButton';

import { useStyles } from './common.styles';

const settingsTabs = [
  { label: 'Connected calendars', tabKey: 'connection' },
  { label: 'Notifications', tabKey: 'notification' },
  // { label: 'Scheduling', tabKey: 'schedule-link' },
];
const AddEditCalendarSchema = yup.object({
  type: yup.string().required('Type is required'),
  visibilityTag: yup.string().required('Visibility is required'),
});

const SettingsDrawer = ({ isOpen, onClose, currentDate }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { userId } = JSON.parse(localStorage.getItem('meta'));
  const { calendars } = useSelector(selectCalendarsState);
  const [allSubCalendars, setAllSubCalendars] = useState([]);

  const [currentTab, setCurrentTab] = useState(settingsTabs[0].tabKey);
  const [notificationData, setNotificationData] = useState({
    isSmsNoti: true,
    isEmailNoti: true,
    isPushNoti: true,
    isRequestAppt: true,
    isAcceptedAppt: true,
    isRescheduledAppt: true,
    isCanceledAppt: true,
    isDailyAppt: true,
    isOneHourStart: true,
    isTenMinuteStart: true,
  });
  const [profileObject, setProfileObject] = useState({});
  const [isAddCalendar, setIsAddCalendar] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isAllowing, setIsAllowing] = useState(false);
  const [selectedCalendar, setSelectedCalendar] = useState(null);
  const calendarAccessPayload = useRef(null);

  const syncedCalendars = useMemo(
    () =>
      calendars?.map(item => {
        const { calendar, email } = item;
        return {
          ...calendar,
          calendar: {
            calenderName: calendar?.email || '',
            photo: '', // decodedToken.photo,
            email: calendar?.email || '',
          },
          'pull-event': {
            syncGoogleToConfidantSystem: calendar?.calendarSettings?.syncGoogleToConfidantSystem,
          },
          'push-event': {
            syncConfidantSystemToGoogle: calendar?.calendarSettings?.syncConfidantSystemToGoogle,
          },
          'last-sync': {
            lastSyncAt: calendar?.lastSyncAt || '',
          },
          name: calendar?.name ?? calendar.email,
          actions: {
            ...calendar,
            email,
          },
          email,
        };
      }),
    [calendars],
  );

  const defaultValues = {
    type: selectedCalendar?.type || '',
    syncConfidantSystemToGoogle: selectedCalendar?.calendarSettings
      ? selectedCalendar.calendarSettings.syncConfidantSystemToGoogle
      : true,
    syncGoogleToConfidantSystem: selectedCalendar?.calendarSettings
      ? selectedCalendar.calendarSettings.syncGoogleToConfidantSystem
      : true,
    visibilityTag: selectedCalendar?.calendarSettings?.visibilityTag || '',
    status: selectedCalendar ? selectedCalendar.status : true,
    calenderExternalId: selectedCalendar?.email ?? null,
  };

  const getCalendarName = id => {
    const foundCalendar = allSubCalendars.find(item => item.id === id);
    if (foundCalendar) {
      return foundCalendar.summary;
    }
    return id;
  };

  const { errors, values, handleChange, handleSubmit, touched, setFieldValue } = useFormik({
    initialValues: { ...defaultValues },
    validationSchema: AddEditCalendarSchema,
    enableReinitialize: true,
    onSubmit: () => {
      const data = {
        authToken: selectedCalendar?.authToken,
        calenderExternalId: selectedCalendar?.calenderExternalId,
        email: values?.calenderExternalId ?? selectedCalendar?.email ?? profileObject?.email,
        name: getCalendarName(values?.calenderExternalId ?? selectedCalendar?.name),
        expiry: selectedCalendar?.expiry,
        lastSyncAt: selectedCalendar?.lastSyncAt ?? null,
        calendarSettings: {
          syncConfidantSystemToGoogle: values.syncConfidantSystemToGoogle,
          syncGoogleToConfidantSystem: values.syncGoogleToConfidantSystem,
          visibilityTag: values.visibilityTag || '',
        },
        status: values.status,
        type: values.type || '',
      };
      saveAndSyncCalendar(data);
    },
  });
  useEffect(() => {
    if (allSubCalendars?.length > 0) {
      const foundPrimaryCalendar = allSubCalendars.find(item => item.primary);
      if (foundPrimaryCalendar) {
        void setFieldValue('calenderExternalId', foundPrimaryCalendar?.id);
      }
    }
  }, [allSubCalendars]);

  const saveAndSyncCalendar = data => {
    const saveCalendar = isAddCalendar ? createCalendar : updateCalendar;
    const selectedObject = isAddCalendar ? profileObject : selectedCalendar;
    setIsLoading(true);
    const accessPayload = calendarAccessPayload.current;
    if (accessPayload?.accessToken) {
      if (values.calenderExternalId) {
        alllowCalendarAccess({
          ...accessPayload,
          calendarId: values?.calenderExternalId ?? accessPayload?.calendarId,
          syncConfidantSystemToGoogle: data?.calendarSettings?.syncConfidantSystemToGoogle,
          syncGoogleToConfidantSystem: data?.calendarSettings?.syncGoogleToConfidantSystem,
        })
          .then(res => {
            if (res.data?.statusCode === 200) {
              saveCalendar(data, { userId })
                .then(() => {
                  if (data?.calendarSettings?.syncConfidantSystemToGoogle) {
                    const syncPayload = {
                      practitionerRef: userId,
                      calendarId: values?.calenderExternalId ?? selectedObject?.email,
                      from: '',
                      to: '',
                    };
                    void syncConfidantToGoogle(syncPayload);
                  }
                  if (data?.calendarSettings?.syncGoogleToConfidantSystem) {
                    const syncPayload = {
                      practitionerRef: userId,
                      calendarId: values?.calenderExternalId ?? selectedObject?.email,
                      from: '',
                      to: '',
                    };
                    void syncGoogleToConfidant(syncPayload);
                  }
                  dispatch(profileActionCreators.fetchSyncedGoogleCalendars(userId));
                  if (calendars?.length > 0) {
                    dispatch(
                      profileActionCreators.fetchAllGoogleCalendarEvents({
                        from: dayjs(currentDate).subtract(1, 'month').format('DD-MM-YYYY'),
                        to: dayjs(currentDate).add(1, 'month').format('DD-MM-YYYY'),
                        calenderEmail:
                          calendars?.length > 0
                            ? [...calendars?.map(obj => obj.email), selectedObject?.email]
                            : [selectedObject?.email],
                      }),
                    );
                  }
                  setIsLoading(false);
                  setSelectedCalendar(null);
                  setIsAddCalendar(false);
                })
                .catch(() => {
                  dispatch(showSnackbar({ snackType: 'error', snackMessage: 'Error saving calendar!' }));
                  setIsLoading(false);
                  setSelectedCalendar(null);
                  setIsAddCalendar(false);
                });
            } else {
              dispatch(showSnackbar({ snackType: 'error', snackMessage: 'Error allowing calendar access!' }));
              setIsLoading(false);
              setSelectedCalendar(null);
              setIsAddCalendar(false);
            }
          })
          .catch(err => {
            const errorMessage = err.data?.message || 'Something went wrong';
            dispatch(showSnackbar({ snackType: 'error', snackMessage: errorMessage }));
            setIsLoading(false);
            setSelectedCalendar(null);
            setIsAddCalendar(false);
          });
      } else {
        setIsLoading(false);
        setSelectedCalendar(null);
        setIsAddCalendar(false);
      }
    } else {
      setIsLoading(false);
      setSelectedCalendar(null);
      setIsAddCalendar(false);
    }
  };

  const getAllCalendarsFromAccessTokenCall = accessToken => {
    if (accessToken) {
      setIsLoading;
      getAllCalendarsFromAccessToken({ accessToken })
        .then(res => {
          setAllSubCalendars(res?.data?.items?.filter(item => !item.id.includes('#')) || []);
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
  };

  const googleLoginSuccess = response => {
    setProfileObject(response?.profileObj);
    calendarAccessPayload.current = {
      accessToken: response?.accessToken,
      calendarId: response?.profileObj?.email,
    };
    getAllCalendarsFromAccessTokenCall(response?.accessToken);
    setIsAddCalendar(true);
  };

  const onChangeToggle = async name => {
    await setFieldValue(name, !values[name]);
  };

  const onSelectCalendar = item => {
    setSelectedCalendar(item);
  };

  const onDeleteCalender = calendar => {
    setIsAllowing(true);
    deleteCalendar({ userId, email: calendar?.email })
      .then(() => {
        deleteGoogleCalendarEvents({ calendarId: calendar?.email })
          ?.then(res => console.log(res))
          .catch(err => console.log(err));
        dispatch(profileActionCreators.fetchSyncedGoogleCalendars(userId));
        setTimeout(() => {
          const calenderEmails = calendars?.map(obj => obj.email)?.filter(x => x !== calendar?.email);
          if (calenderEmails?.length > 0) {
            dispatch(
              profileActionCreators.fetchAllGoogleCalendarEvents({
                from: dayjs(currentDate).subtract(1, 'month').format('DD-MM-YYYY'),
                to: dayjs(currentDate).add(1, 'month').format('DD-MM-YYYY'),
                calenderEmail: calenderEmails,
              }),
            );
          }
        }, 1000);
        dispatch(showSnackbar({ snackType: 'success', snackMessage: 'Calendar deleted successfully!' }));
        setIsAllowing(false);
      })
      .catch(() => {
        setIsAllowing(false);
        dispatch(showSnackbar({ snackType: 'error', snackMessage: 'Error deleting calendar!' }));
      });
  };

  const renderTitle = ({ title, subTitle }) => (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
      <Box sx={{ fontWeight: 700, fontSize: 18, lineHeight: '24px' }}>{title}</Box>
      <Box sx={{ fontWeight: 500, fontSize: 14, lineHeight: '20px', color: colors.neutral600 }}>
        {subTitle}
      </Box>
    </Box>
  );

  const renderSelectCalendar = () => (
    <>
      <Box sx={{ p: 3, display: 'flex', flexDirection: 'column', gap: 4 }}>
        {renderTitle({
          title: 'Select Calendar',
          subTitle: `Connect your calendar to let Confidant know when you're available and update your calendar as events are scheduled.`,
        })}
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Box sx={{ display: 'flex', gap: 3 }}>
              <img alt="" src={googleIcon} className={classes.imageIcon} />
              <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                <Box sx={{ fontWeight: 600, fontSize: 16, lineHeight: '24px' }}>Google Calendar</Box>
                <Box sx={{ fontWeight: 500, fontSize: 14, lineHeight: '20px', color: colors.neutral600 }}>
                  Gmail, G Suite
                </Box>
              </Box>
            </Box>
            <GoogleOAuthProvider clientId={getConfig.api.GC_WEBAPP_AUTH_ID}>
              <CustomGoogleButton onClick={googleLoginSuccess} />
            </GoogleOAuthProvider>
          </Box>
        </Box>
      </Box>
      <Box sx={{ p: 3, display: 'flex', flexDirection: 'column', gap: 1 }}>
        {syncedCalendars?.length > 0 &&
          renderTitle({
            title: 'Connected Calendars',
            subTitle: '',
          })}
        {syncedCalendars?.length > 0 &&
          syncedCalendars.map((calendar, index) => (
            <Box key={index} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Box>{calendar?.name && calendar?.name !== '' ? calendar.name : calendar?.email}</Box>
              <Menu
                icon="more"
                itemsWrapperClassName={classes.menuItemsWrapper}
                items={[
                  {
                    label: 'Edit',
                    icon: <Icons glyph="edit-underline" className={classes.editCalendarIcon} />,
                    onClick: () => {
                      onSelectCalendar(calendar);
                    },
                  },
                  {
                    label: 'Delete',
                    icon: <Icons glyph="delete-outlined-2" className={classes.deleteIcon} />,
                    onClick: () => {
                      onDeleteCalender(calendar);
                    },
                  },
                ]}
              />
            </Box>
          ))}
      </Box>
    </>
  );

  const renderNotification = () => (
    <Box sx={{ p: 3, display: 'flex', flexDirection: 'column', gap: 3 }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        {renderTitle({
          title: 'Notification channels',
          subTitle:
            'Get scheduling and appointment updates from Confidant Health in real time. From the selections offered below, choose what type of communication works best for you.',
        })}
        <Box>
          <Toggle
            checked={notificationData.isSmsNoti}
            labelPosition={positionType.RIGHT}
            onChange={() =>
              setNotificationData({ ...notificationData, isSmsNoti: !notificationData.isSmsNoti })
            }
          >
            SMS notifications
          </Toggle>
          <Toggle
            checked={notificationData.isEmailNoti}
            labelPosition={positionType.RIGHT}
            onChange={() =>
              setNotificationData({ ...notificationData, isEmailNoti: !notificationData.isEmailNoti })
            }
          >
            Email notifications
          </Toggle>
          <Toggle
            checked={notificationData.isPushNoti}
            labelPosition={positionType.RIGHT}
            onChange={() =>
              setNotificationData({ ...notificationData, isPushNoti: !notificationData.isPushNoti })
            }
          >
            Push notifications
          </Toggle>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        {renderTitle({
          title: 'Notification types',
          subTitle:
            'Select what type of scheduling and appointment updates you would like Confidant Health to send in real time. You can choose as many as you want, but we recommend selecting all types.',
        })}
        <Box>
          <Toggle
            checked={notificationData.isRequestAppt}
            labelPosition={positionType.RIGHT}
            onChange={() =>
              setNotificationData({ ...notificationData, isRequestAppt: !notificationData.isRequestAppt })
            }
          >
            Appointment requests
          </Toggle>
          <Toggle
            checked={notificationData.isAcceptedAppt}
            labelPosition={positionType.RIGHT}
            onChange={() =>
              setNotificationData({ ...notificationData, isAcceptedAppt: !notificationData.isAcceptedAppt })
            }
          >
            Accepted appointments
          </Toggle>
          <Toggle
            checked={notificationData.isRequestAppt}
            labelPosition={positionType.RIGHT}
            onChange={() =>
              setNotificationData({ ...notificationData, isRequestAppt: !notificationData.isRequestAppt })
            }
          >
            Rescheduled appointments
          </Toggle>
          <Toggle
            checked={notificationData.isCanceledAppt}
            labelPosition={positionType.RIGHT}
            onChange={() =>
              setNotificationData({ ...notificationData, isCanceledAppt: !notificationData.isCanceledAppt })
            }
          >
            Canceled appointments
          </Toggle>
          <Toggle
            checked={notificationData.isDailyAppt}
            labelPosition={positionType.RIGHT}
            onChange={() =>
              setNotificationData({ ...notificationData, isDailyAppt: !notificationData.isDailyAppt })
            }
          >
            Daily appointments summary
          </Toggle>
        </Box>
      </Box>
    </Box>
  );

  const renderLabel = label => (
    <Heading level={headingLevel.S} className={classes.label} weight={fontWeight.BOLD}>
      {label}
    </Heading>
  );

  const renderSelect = (label, name, options) => (
    <Box className={classes.section}>
      {renderLabel(label)}
      <Select
        options={options}
        value={values[name]}
        displayEmpty
        emptyText="Select item"
        name={name}
        onChange={handleChange}
        variant={selectType.SECONDARY}
        fullWidth
      />
      <TextError errorMsg={touched[name] ? errors[name]?.toString() : null} />
    </Box>
  );

  return (
    <>
      <Drawer
        open={isOpen}
        onClose={onClose}
        variant={drawerType.FORM}
        position={drawerPosition.RIGHT}
        title="Calendar settings"
        className={classes.drawer}
        footer={<></>}
      >
        {(isLoading || isAllowing) && (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              background: 'white',
              zIndex: 1000,
              opacity: 0.8,
            }}
          >
            <Icons className="rotate linear infinite" glyph="in-progress" color={colors.primary} />
            Loading...
          </Box>
        )}
        <Box>
          <Tabs options={settingsTabs} value={currentTab} onChange={setCurrentTab} className={classes.tabs} />
          <TabPanel value={currentTab} tabKey={settingsTabs[0].tabKey}>
            {renderSelectCalendar()}
          </TabPanel>
          <TabPanel value={currentTab} tabKey={settingsTabs[1].tabKey}>
            {renderNotification()}
          </TabPanel>
        </Box>
      </Drawer>
      <Drawer
        open={isAddCalendar || selectedCalendar || false}
        onClose={() => {
          dispatch(profileActionCreators.fetchSyncedGoogleCalendars(userId));
          onClose();
        }}
        className={classes.drawer}
      >
        {isLoading ? (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              background: 'white',
              zIndex: 1000,
              opacity: 0.8,
            }}
          >
            <Icons className="rotate linear infinite" glyph="in-progress" color={colors.primary} />
            Loading...
          </Box>
        ) : (
          <form className={classes.form} onSubmit={handleSubmit}>
            <Box className={classes.header}>
              {selectedCalendar ? 'Edit Calendar Settings' : 'Add Calendar Settings'}
            </Box>
            <Box className={classes.formContent}>
              <Box className={classes.section}>
                <ProfileInfo
                  type="member"
                  nickName={
                    isAddCalendar
                      ? profileObject?.email
                      : !selectedCalendar?.name
                      ? selectedCalendar?.email
                      : selectedCalendar?.name
                  }
                  fullName={isAddCalendar ? profileObject?.email : selectedCalendar?.email}
                  isProvider
                />
              </Box>
              {allSubCalendars?.length > 0 &&
                renderSelect(
                  'Select calendar',
                  'calenderExternalId',
                  allSubCalendars?.map(item => ({ value: item.id, label: item.summary })),
                )}
              {renderSelect('Calender use', 'type', [
                { value: 'Personal', label: 'Personal' },
                { value: 'Work', label: 'Work' },
              ])}
              <Box className={classes.section}>
                <Toggle
                  checked={values.syncGoogleToConfidantSystem}
                  onChange={() => onChangeToggle('syncGoogleToConfidantSystem')}
                  labelPosition={positionType.LEFT}
                >
                  Pull events to confidant
                </Toggle>
              </Box>
              <Box className={classes.section}>
                <Toggle
                  checked={values.syncConfidantSystemToGoogle}
                  onChange={() => onChangeToggle('syncConfidantSystemToGoogle')}
                  labelPosition={positionType.LEFT}
                >
                  Push events to external calendar
                </Toggle>
              </Box>

              {renderSelect('Events will appear as', 'visibilityTag', [
                { value: 'Busy', label: 'Busy' },
                { value: 'Personal', label: 'Personal' },
                { value: 'Confidant', label: 'Confidant' },
              ])}
            </Box>
            <Box className={classes.footer}>
              <Button onClick={handleSubmit} disabled={isLoading}>
                {isLoading ? 'Saving...' : `Update`}
              </Button>
            </Box>
          </form>
        )}
      </Drawer>
    </>
  );
};

export default SettingsDrawer;
