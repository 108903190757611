import { useEffect } from 'react';
import { useFormik } from 'formik';
import { Box } from '@mui/material';

import { Drawer, drawerType } from '../../../../../../packages/ui/organisms/drawer';
import {
  btnType,
  Button,
  Checkbox,
  fontWeight,
  FormControlLabel,
  Heading,
  headingLevel,
  IconButton,
  Input,
  inputSize,
  Text,
  TextError,
} from '../../../../../../packages';

// schema
import { AddPlanSchema } from './PayerDetail.schema';

// styles
import { useStyles } from './PayerDetail.styles';

const AddPlanDrawer = ({ isOpen, selectedPlan, onSubmit, onClose }) => {
  const classes = useStyles();

  const defaultValues = {
    name: selectedPlan?.name || '',
    code: selectedPlan?.code || '',
    isActive: selectedPlan?.isActive || false,
  };

  const { errors, values, handleChange, handleSubmit, touched, setFieldValue, ...rest } = useFormik({
    initialValues: { ...defaultValues },
    enableReinitialize: true,
    validationSchema: AddPlanSchema,
    onSubmit: payload => {
      onSubmit(payload);
    },
  });

  const setTouched = name => async () => {
    await rest.setTouched({ ...touched, [name]: true });
  };

  useEffect(() => {
    if (!isOpen) {
      rest.handleReset({ ...defaultValues });
      rest.setErrors({});
    }
  }, [isOpen]);

  const renderLabel = label => (
    <Heading level={headingLevel.S} className={classes.label} weight={fontWeight.BOLD}>
      {label}
    </Heading>
  );

  return (
    <Drawer open={isOpen} onClose={onClose} variant={drawerType.NORMAL} className={classes.drawer}>
      <Box className={classes.wrapper}>
        <Box className={classes.header}>
          <Text className={classes.headerTitle}>{selectedPlan ? 'Edit' : 'Add'} Plan</Text>
          <IconButton icon="close" onClick={onClose} className={classes.backBtn} />
        </Box>
        <form className={classes.form} onSubmit={handleSubmit}>
          <Box className={classes.formContent}>
            <Box className={classes.section}>
              {renderLabel('Plan Name')}
              <Input
                fullWidth
                name="name"
                value={values.name}
                placeholder="Enter a plan name"
                size={inputSize.M}
                onBlur={setTouched('name')}
                onChange={handleChange}
              />
              <TextError errorMsg={touched.name ? errors.name : null} />
            </Box>
            <Box className={classes.section}>
              {renderLabel('Plan Code')}
              <Input
                fullWidth
                name="code"
                value={values.code}
                placeholder="Enter a plan code"
                size={inputSize.M}
                onBlur={setTouched('code')}
                onChange={handleChange}
              />
              <TextError errorMsg={touched.code ? errors.code : null} />
            </Box>
            <Box className={classes.section}>
              {renderLabel('Status')}
              <FormControlLabel
                control={<Checkbox />}
                checked={values?.isActive}
                name="isActive"
                onChange={() => {
                  void setFieldValue('isActive', !values?.isActive);
                }}
                label="Active"
              />
              <TextError errorMsg={touched.isActive ? errors.isActive : null} />
            </Box>
          </Box>
          <Box className={classes.footer}>
            <Button variant={btnType.TEXT} onClick={onClose}>
              Cancel
            </Button>
            <Button onClick={handleSubmit}>Save</Button>
          </Box>
        </form>
      </Box>
    </Drawer>
  );
};

export { AddPlanDrawer };
