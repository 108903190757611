/* eslint-disable max-len */
import cx from 'clsx';

// types
// styles
import { useStyles } from './shared/styles';

const Stack = ({ className, color, onClick }) => {
  const classes = useStyles();
  return (
    <svg
      className={cx(classes.root, className)}
      width="20"
      height="22"
      viewBox="0 0 20 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M18.0829 14.1998L19.2849 14.9208C19.359 14.9652 19.4204 15.0281 19.463 15.1033C19.5056 15.1784 19.528 15.2634 19.528 15.3498C19.528 15.4363 19.5056 15.5212 19.463 15.5964C19.4204 15.6716 19.359 15.7344 19.2849 15.7788L10.5149 21.0408C10.3593 21.1343 10.1813 21.1836 9.99986 21.1836C9.81841 21.1836 9.64039 21.1343 9.48485 21.0408L0.714855 15.7788C0.640701 15.7344 0.57932 15.6716 0.536701 15.5964C0.494082 15.5212 0.47168 15.4363 0.47168 15.3498C0.47168 15.2634 0.494082 15.1784 0.536701 15.1033C0.57932 15.0281 0.640701 14.9652 0.714855 14.9208L1.91685 14.1998L9.99986 19.0498L18.0829 14.1998ZM18.0829 9.49983L19.2849 10.2208C19.359 10.2652 19.4204 10.3281 19.463 10.4033C19.5056 10.4784 19.528 10.5634 19.528 10.6498C19.528 10.7363 19.5056 10.8212 19.463 10.8964C19.4204 10.9716 19.359 11.0344 19.2849 11.0788L9.99986 16.6498L0.714855 11.0788C0.640701 11.0344 0.57932 10.9716 0.536701 10.8964C0.494082 10.8212 0.47168 10.7363 0.47168 10.6498C0.47168 10.5634 0.494082 10.4784 0.536701 10.4033C0.57932 10.3281 0.640701 10.2652 0.714855 10.2208L1.91685 9.49983L9.99986 14.3498L18.0829 9.49983ZM10.5139 0.308825L19.2849 5.57083C19.359 5.61522 19.4204 5.67807 19.463 5.75326C19.5056 5.82845 19.528 5.9134 19.528 5.99983C19.528 6.08625 19.5056 6.1712 19.463 6.24639C19.4204 6.32158 19.359 6.38443 19.2849 6.42883L9.99986 11.9998L0.714855 6.42883C0.640701 6.38443 0.57932 6.32158 0.536701 6.24639C0.494082 6.1712 0.47168 6.08625 0.47168 5.99983C0.47168 5.9134 0.494082 5.82845 0.536701 5.75326C0.57932 5.67807 0.640701 5.61522 0.714855 5.57083L9.48485 0.308825C9.64039 0.215382 9.81841 0.166016 9.99986 0.166016C10.1813 0.166016 10.3593 0.215382 10.5149 0.308825H10.5139ZM9.99986 2.33183L3.88685 5.99983L9.99986 9.66783L16.1129 5.99983L9.99986 2.33183Z"
        fill={color}
      />
    </svg>
  );
};

export default Stack;
