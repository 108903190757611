import { remove } from 'lodash';
import { badgeStyle } from '../../../../packages';
import {
  dataLabels,
  dataSectionLabels,
  emptyArrays,
  excludeKeys,
  fieldVisibilityControl,
  priorities,
  showFieldsOnNoValueKeys,
  specialItemKeys,
} from '../caqh-information/index.constants';

export const flattenObject = (obj, prefix = '') => {
  if (obj === null || typeof obj !== 'object') {
    return {};
  }

  const flatObject = Object.keys(obj).reduce((acc, key) => {
    const pre = prefix.length ? `${prefix}.` : '';

    if (Array.isArray(obj[key])) {
      acc[pre + key] = obj[key];
    } else if (typeof obj[key] === 'object' && obj[key] !== null) {
      Object.assign(acc, flattenObject(obj[key], pre + key));
    } else {
      acc[pre + key] = obj[key];
    }

    return acc;
  }, {});
  delete flatObject.providerId;
  return flatObject;
};

export const checkIfOnlyEmptyArrayExists = obj => {
  if (obj) {
    return Object.values(obj).every(value => Array.isArray(value) && value.length === 0);
  }
  return false;
};

const hasItemsToRender = (list, filledCheck = false) => {
  return (
    list?.length > 0 &&
    list?.some(item => {
      const lastKey = item.key?.split('-')?.pop();
      return (
        !item.section && shouldRenderKey(lastKey) && (filledCheck ? item.value && item.value !== '' : true)
      );
    })
  );
};

const handleArrayValue = (arr, dataKeys, sectionKey, key, type) => {
  arr.forEach((item, index) => {
    const arrItem = getDataList(item, false, key, `${index + 1}`);
    const section = {
      section: !!sectionKey,
      key: sectionKey,
      label: `${dataSectionLabels[sectionKey?.replace(/\.\d+\./g, '.')] || sectionKey} ${index + 1}`,
    };

    const tempDataKeys = { filled: [], missing: [], list: [] };
    getFilledAndMissingKeys(arrItem, tempDataKeys, type);
    if (hasItemsToRender(tempDataKeys.filled, true)) {
      dataKeys.filled?.push(section, ...tempDataKeys.filled);
    }
    if (hasItemsToRender(tempDataKeys.missing)) {
      dataKeys.missing?.push(section, ...tempDataKeys.missing);
    }
    if (hasItemsToRender(tempDataKeys.list)) {
      dataKeys.list?.push(section, ...tempDataKeys.list);
    }
  });
  if (sectionKey) {
    dataKeys.missing?.push({
      key: `${sectionKey.replaceAll('.', '-')}-add`,
      label: `Add new ${camelCaseToText(sectionKey)?.toLowerCase()}`,
      type: 'add',
    });
  }
};

export const getDataList = (obj, initial = false, mainKey = '', formIndex = '') => {
  if (checkIfOnlyEmptyArrayExists(obj)) {
    return [];
  }
  if (!obj) {
    return [];
  }
  return Object.keys(obj)
    .flatMap(key => {
      const value = obj[key];
      // eslint-disable-next-line no-param-reassign
      key = formIndex.length ? `${mainKey}.${formIndex}.${key}` : mainKey.length ? `${mainKey}.${key}` : key;
      const labelKey = key.replace(/\.\d+\./g, '.');
      if (Array.isArray(value)) {
        return {
          label: dataLabels[labelKey] || key,
          value,
          key,
        };
      }
      if (typeof value === 'object' && value !== null) {
        const flatValue = flattenObject(value, key);
        return getDataList(flatValue, initial, '');
      }

      if (!initial && (value === true || value === false || key.toLowerCase().includes('has'))) {
        return {
          label: dataLabels[labelKey] || key,
          badge: {
            label: value === true ? true : value === false ? false : '',
            style: value ? badgeStyle.ACTIVE : badgeStyle.CRITICAL,
          },
          key,
        };
      }

      return {
        label: dataLabels[labelKey] || key,
        value: initial ? 'Missing' : value || '',
        key,
      };
    })
    .flat();
};

// methods for restructuring data

export const unFlattenData = flatData => {
  const result = {}; // Initialize result
  Object.keys(flatData).forEach(key => {
    const keySegments = key.split('-');
    if (keySegments.every(segment => segment !== 'add')) {
      structureData(keySegments, flatData[key], result);
    }
  });
  return result;
};

const structureData = (keySegments, value, result) => {
  keySegments.reduce((acc, curr, index) => {
    if (index === keySegments.length - 1) {
      // Assign value if it's the last segment
      acc[curr] = value;
    } else {
      const nextKeySegment = keySegments[index + 1];

      if (!Number.isNaN(Number(curr))) {
        // Current segment is a number, use it as an index (adjusted for 0-based index)
        const arrayIndex = Number(curr) - 1;
        // eslint-disable-next-line no-param-reassign
        if (!Array.isArray(acc)) acc = []; // Ensure acc is an array
        if (!acc[arrayIndex]) {
          // Determine if the next segment is a number, if so, current segment should be an array, otherwise an object
          acc[arrayIndex] = Number.isNaN(Number(nextKeySegment)) ? {} : [];
        }
        // eslint-disable-next-line no-param-reassign
        acc = acc[arrayIndex];
      } else {
        // Current segment is not a number
        if (!acc[curr]) {
          // Determine if the next segment is a number, if so, current segment should be an array, otherwise an object
          acc[curr] = Number.isNaN(Number(nextKeySegment)) ? {} : [];
        }
        // eslint-disable-next-line no-param-reassign
        acc = acc[curr];
      }
    }
    return acc;
  }, result);
};

// methods for transforming data

export const transformData = (data, addManually = false, key = '', type = '') => {
  const transformedData = flattenObject(data, '');
  const list = getDataList(transformedData, false, key);
  if (!addManually) {
    const specialItems = remove(list, item => Object.keys(specialItemKeys).includes(item.key));
    specialItems.forEach(item => {
      const { labelKey, hidden } = specialItemKeys[item.key];
      if (!hidden) {
        item.value.forEach(subItem => {
          const dataKeys = {
            filled: [],
            missing: [],
            list: [],
            title: subItem[labelKey],
          };
          const transformedSubItem = flattenObject(subItem, '');
          const subItemList = getDataList(transformedSubItem, false, 'activeLocation');
          getFilledAndMissingKeys(subItemList, dataKeys, type);
          sortByRef(dataKeys, type);
          list.push({ key: labelKey, label: subItem[labelKey], value: dataKeys, type: 'nested' });
        });
      }
    });
  }
  const dataKeys = {
    filled: [],
    missing: [],
    list: [],
    title: undefined,
  };
  getFilledAndMissingKeys(list, dataKeys, type);
  sortByRef(dataKeys, type);
  return {
    list: dataKeys.list,
    filled: dataKeys.filled,
    missing: dataKeys.missing,
  };
};

const getPriority = (key, type) => {
  if (!key) {
    return undefined;
  }
  const p = priorities[type][key];
  if (Number.isNaN(Number(p))) {
    const nestedKeys = key.split('.');
    if (nestedKeys.length > 0) {
      // remove last element of nested keys and join with dots again to get nearest parent key
      const parentKey = nestedKeys.slice(0, -1).join('.');
      if (parentKey && parentKey !== '') {
        return getPriority(parentKey, type);
      }
    }
  }
  return p;
};
export const sortByRef = (data, type) => {
  if (data) {
    data.filled = sortList(data.filled, type);
    data.missing = sortList(data.missing, type);
    data.list = sortList(data.list, type);
  }
};
export const sortList = (list, type) => {
  return list?.sort((a, b) => {
    if (!shouldRenderKey(a.key)) {
      return 1;
    }
    if (!shouldRenderKey(b.key)) {
      return -1;
    }
    const k1 = removeIndex(a.key);
    const k2 = removeIndex(b.key);
    const p1 = getPriority(k1, type) || 0;
    const p2 = getPriority(k2, type) || 0;
    if (p1 === 0 && p2 !== 0) {
      return 1;
    }
    if (p1 !== 0 && p2 === 0) {
      return -1;
    }
    return p1 - p2;
  });
};

const getFilledAndMissingKeys = (data, dataKeys, type) => {
  data.forEach(item => {
    const { value, label, key, badge } = item;
    if (Array.isArray(value)) {
      if (value?.length > 1 || (value?.length === 1 && value[0] !== '')) {
        handleArrayValue(value, dataKeys, label, key, type);
      } else {
        const emptyArray = emptyArrays[key?.replace(/\.\d+\./g, '.')];
        if (emptyArray) {
          handleArrayValue(emptyArray, dataKeys, label, key, type);
        }
      }
    } else {
      item.key = key.replaceAll('.', '-');
      if ((value && value !== '') || badge?.label === true || badge?.label === false) {
        dataKeys.filled?.push(item);
        dataKeys.list?.push(item);
      } else {
        dataKeys.missing?.push(item);
        dataKeys.list?.push(item);
      }
    }
  });
};

export const camelCaseToText = camelCase => {
  return camelCase
    ?.replace(/.*[.-]/, '')
    ?.replace(/([A-Z])/g, ' $1')
    ?.replace(/_/g, ' ')
    ?.replace(/-(\d+)-/g, '')
    ?.replace(/\.(\d+)\./g, '')
    ?.replace(/-/g, ' ')
    ?.replace(/[0-9]+/g, ' $&')
    ?.trim();
};

export const shouldRenderKey = key => {
  if (!key || key === 'id') {
    return false;
  }
  return !excludeKeys.some(excludeKey => key?.includes(excludeKey));
};

export const removeIndex = key => {
  return key?.replaceAll('-', '.')?.replaceAll(/.(\d+)./g, '.');
};

export const isFieldVisible = (key, values) => {
  let renderField = true;
  const controlKey = fieldVisibilityControl[removeIndex(key)];
  if (controlKey) {
    if (showFieldsOnNoValueKeys.includes(controlKey)) {
      renderField = values[controlKey?.replaceAll('.', '-')] === false;
    } else {
      renderField = values[controlKey?.replaceAll('.', '-')] === true;
    }
  }
  return renderField;
};
