import { makeStyles } from '@mui/styles';

// colors
import { colors } from '../../../../../packages';

export const useStyles = makeStyles({
  root: {},
  table: {
    backgroundColor: colors.white,
  },
  menu: {
    float: 'right',
    right: 20,
    '& svg': {
      width: 16,
    },
  },
  menuItemsWrapper: {
    '& ul': {
      padding: 0,
    },
    '& ul li': {
      padding: '10px 16px',
    },
  },
});
