/* eslint-disable max-len */
import cx from 'clsx';

// types
// styles
import { useStyles } from './shared/styles';

const ArrowUpFilled = ({ className, color, onClick }) => {
  const classes = useStyles();
  return (
    <svg
      className={cx({
        [classes.root]: true,
        [className || '']: className,
      })}
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M15.14 7.94005C15.1869 7.98654 15.2241 8.04184 15.2494 8.10277C15.2748 8.1637 15.2879 8.22905 15.2879 8.29505C15.2879 8.36106 15.2748 8.42641 15.2494 8.48734C15.2241 8.54827 15.1869 8.60357 15.14 8.65005L14.94 8.85005C14.8935 8.89837 14.8375 8.93661 14.7756 8.96241C14.7136 8.98822 14.6471 9.00103 14.58 9.00005H11V16.5001C11 16.6327 10.9473 16.7598 10.8535 16.8536C10.7598 16.9474 10.6326 17.0001 10.5 17.0001H5.5C5.36739 17.0001 5.24021 16.9474 5.14644 16.8536C5.05267 16.7598 5 16.6327 5 16.5001V9.00005H1.42C1.35292 9.00103 1.28635 8.98822 1.22443 8.96241C1.16251 8.93661 1.10654 8.89837 1.06 8.85005L0.859996 8.65005C0.813132 8.60357 0.775935 8.54827 0.75055 8.48734C0.725166 8.42641 0.712097 8.36106 0.712097 8.29505C0.712097 8.22905 0.725166 8.1637 0.75055 8.10277C0.775935 8.04184 0.813132 7.98654 0.859996 7.94005L7.65 1.15005C7.69489 1.10222 7.7491 1.0641 7.8093 1.03804C7.8695 1.01198 7.9344 0.998535 8 0.998535C8.06559 0.998535 8.13049 1.01198 8.19069 1.03804C8.25089 1.0641 8.30511 1.10222 8.35 1.15005L15.14 7.94005Z"
        fill={color}
      />
    </svg>
  );
};

export default ArrowUpFilled;
