import { badgeStyle } from '../../../../packages/ui/atoms/badge';

export const EVALUATION_STATUS = {
  REJECTED: 'Rejected',
  COMPLETED: 'Completed',
  NOT_STARTED: 'Not started',
  IN_PROGRESS: 'In progress',
  INCOMPLETE: 'Incomplete',
  NEEDS_REVIEW: 'For review',
};

export const EVALUATION_STATUS_V2 = {
  REJECTED: 'Rejected',
  COMPLETED: 'Completed',
  NOT_STARTED: 'Assigned',
  IN_PROGRESS: 'In progress',
  INCOMPLETE: 'Incomplete',
  NEEDS_REVIEW: 'For review',
};

export const evaluationStatusStyle = {
  REJECTED: badgeStyle.CRITICAL,
  COMPLETED: badgeStyle.ACTIVE,
  NOT_STARTED: badgeStyle.INACTIVE,
  IN_PROGRESS: badgeStyle.MISREPORTED,
  INCOMPLETE: badgeStyle.PRIMARY,
  NEEDS_REVIEW: badgeStyle.MEDIUM,
};

export const evaluationStatusStyleV2 = {
  REJECTED: badgeStyle.CRITICAL,
  COMPLETED: badgeStyle.ACTIVE,
  NOT_STARTED: badgeStyle.INACTIVE,
  IN_PROGRESS: badgeStyle.IN_PROGRESS,
  INCOMPLETE: badgeStyle.PRIMARY,
  NEEDS_REVIEW: badgeStyle.MEDIUM,
};

export const EVALUATION_STATUS_TYPES = {
  REJECTED: 'REJECTED',
  COMPLETED: 'COMPLETED',
  NOT_STARTED: 'NOT_STARTED',
  IN_PROGRESS: 'IN_PROGRESS',
};

export const EVALUATION_STATES = {
  ACTIVE: 'ACTIVE',
  DRAFT: 'DRAFT',
};

export const EVALUATION_TYPES = {
  DCT: 'dct',
  OTHER_QUESTIONS: 'other questions',
};

export const RouteState = {
  INITIAL: 'INITIAL',
  IN_PROGRESS: 'IN_PROGRESS',
  COMPLETED: 'COMPLETED',
};
