import { useEffect, useState } from 'react';
import { Box, Stack } from '@mui/material';

import { Checkbox, colors, fontWeight, FormControlLabel, Icons, Text } from '../../../../../packages';

import { useStyles } from './ReportTop.styles';

const ReportTop = ({ icon, label, isChecked, onChange, checkList, listChecked, setListChecked }) => {
  const classes = useStyles();
  const [selectAll, setSelectAll] = useState(false);
  useEffect(() => {
    if (listChecked.length === checkList.length) {
      setSelectAll(true);
      return;
    }
    setSelectAll(false);
  }, [listChecked]);

  return (
    <Box className={classes.cardTop}>
      <Stack direction="row" gap={2} alignItems="center">
        <Icons glyph={icon} color={colors.neutral400} />
        <Text className={classes.cardTopTitle} weight={fontWeight.SEMI_BOLD}>
          {label}
        </Text>
      </Stack>
      <FormControlLabel
        control={<Checkbox />}
        checked={isChecked}
        className={classes.checkbox}
        onChange={onChange}
        label="Include in report"
      />
      {isChecked && (
        <FormControlLabel
          control={<Checkbox />}
          checked={selectAll}
          className={classes.checkbox}
          onChange={() => {
            if (!selectAll) {
              setListChecked(checkList.map(({ type }) => type));
            } else setListChecked([]);
            setSelectAll(!selectAll);
          }}
          label="Select all"
        />
      )}
    </Box>
  );
};

export { ReportTop };
