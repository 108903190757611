import * as React from 'react';
import { useEffect } from 'react';
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';

import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { LocalizationProvider, PickersDay, StaticDateRangePicker } from '@mui/x-date-pickers-pro';
import clsx from 'clsx';
import { useStyles } from './CalendarRange.styles';

dayjs.extend(isToday);

const CalendarRange = ({ value, onChange, conflictedDates = [] }) => {
  const classes = useStyles();

  const today = dayjs();
  const [dateRangeValue, setDateRangeValue] = React.useState(value || [today, today]);
  useEffect(() => {
    if (value && value !== dateRangeValue) {
      setDateRangeValue(value);
    }
  }, [value]);

  const onRangeChange = dateRange => {
    if (dateRange?.[0] && dateRange?.[1]) {
      setDateRangeValue(dateRange);
      onChange(dateRange);
    }
  };

  function Day(props) {
    const { day } = props;
    let isAvailable = false;
    let isConflict = false;
    const startOfRange = dayjs(dateRangeValue[0]);
    const endOfRange = dayjs(dateRangeValue[1]);

    let currentDate = dayjs(startOfRange);
    const ranges = [];

    while (currentDate.isBefore(endOfRange)) {
      currentDate = currentDate.add(1, 'day');
      ranges.push(currentDate.format());
    }

    ranges?.forEach(dateObject => {
      if (day.isSame(dateObject, 'day')) {
        isAvailable = true;
      }
    });
    conflictedDates?.forEach(conflictedDate => {
      if (day.isSame(conflictedDate)) {
        isConflict = true;
      }
    });
    return (
      <PickersDay
        {...props}
        className={clsx({
          [classes.cashSlot]: isAvailable,
          [classes.conflictSlot]: isConflict,
        })}
      />
    );
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <StaticDateRangePicker
        calendars={1}
        disablePast
        disableHighlightToday
        slots={{ day: Day }}
        displayStaticWrapperAs="desktop"
        value={dateRangeValue}
        onChange={onRangeChange}
      />
    </LocalizationProvider>
  );
};

export { CalendarRange };
