import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import { useFormik } from 'formik';

import { Drawer, drawerType } from '../../../../packages/ui/organisms/drawer';
import {
  Button,
  fontWeight,
  Heading,
  headingLevel,
  IconButton,
  Input,
  inputSize,
  Text,
  Textarea,
  TextError,
} from '../../../../packages';
import { SessionSchema } from './AddSession.schema';
import { useStyles } from './AddSession.styles';

const defaultValues = {
  name: '',
  description: '',
};

const AddSession = ({ isOpen, onClose, isEdit, session, onDeleteSession, onUpdateSession, onAddSession }) => {
  const classes = useStyles();
  const { errors, values, handleChange, handleSubmit, touched, setFieldValue, ...rest } = useFormik({
    initialValues: { ...defaultValues },
    enableReinitialize: true,
    validationSchema: SessionSchema,
    onSubmit: payload => {
      if (isEdit) {
        onUpdateSession && onUpdateSession({ ...payload, id: session.id });
      } else {
        onAddSession && onAddSession(payload);
      }
    },
  });

  useEffect(() => {
    if (session && isOpen) {
      Object.keys(session).forEach(async key => {
        await setFieldValue(key, session[key]);
      });
    }
  }, [session, isOpen]);

  useEffect(() => {
    if (!isOpen) {
      rest.handleReset({ ...defaultValues });
      rest.setErrors({});
    }
  }, [isOpen]);

  const setTouched = name => async () => {
    await rest.setTouched({ ...touched, [name]: true });
  };

  const renderLabel = label => (
    <Heading level={headingLevel.S} className={classes.label} weight={fontWeight.BOLD}>
      {label}
    </Heading>
  );

  return (
    <Drawer open={isOpen} onClose={onClose} variant={drawerType.NORMAL} className={classes.drawer}>
      <Box className={classes.wrapper}>
        <Box className={classes.header}>
          <Text className={classes.headerTitle}>{`${isEdit ? 'Edit' : 'Add'} session type`}</Text>
          <IconButton icon="close" onClick={onClose} className={classes.closeBtn} />
        </Box>
        <form className={classes.form} onSubmit={handleSubmit}>
          <Box className={classes.formContent}>
            <Box className={classes.section}>
              {renderLabel('Name')}
              <Input
                value={values.name}
                name="name"
                placeholder="Enter session type name"
                onChange={handleChange}
                size={inputSize.M}
                onBlur={setTouched('name')}
                fullWidth
              />
              <TextError errorMsg={touched.name ? errors.name?.toString() : null} />
            </Box>
            <Box className={classes.section}>
              {renderLabel('Description')}
              <Textarea
                value={values.description}
                name="description"
                onChange={handleChange}
                onBlur={setTouched('description')}
                minRows={5}
                placeholder="Enter description"
              />
              <TextError errorMsg={touched.description ? errors.description?.toString() : null} />
            </Box>
          </Box>
          <Box className={classes.footer}>
            <Box>
              {isEdit && (
                <IconButton
                  icon="delete-outlined-2"
                  onClick={() => onDeleteSession(session.id)}
                  className={classes.removeBtn}
                >
                  Delete session
                </IconButton>
              )}
            </Box>
            <Button onClick={handleSubmit}>{isEdit ? 'Update' : 'Add session'}</Button>
          </Box>
        </form>
      </Box>
    </Drawer>
  );
};

export { AddSession };
