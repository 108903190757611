import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import clsx from 'clsx';
import { Box, Grid } from '@mui/material';
import {
  Badge,
  badgeStyle,
  badgeType,
  fontWeight,
  Heading,
  headingLevel,
  iconBtnPosition,
  IconButton,
  TextField,
} from '../../../../../packages';
import { stateSelector } from '../../../../../redux/modules/state/selectors';
import { stateActionCreators } from '../../../../../redux/modules/state';
import { AddRegisteredEntity } from './add-registered-entity';
import { useStyles } from '../../StateDetail.styles';

const GeneralInformation = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { stateId } = useParams();
  const [selectedEntity, setSelectedEntity] = useState();
  const [openEntityDrawer, setOpenEntityDrawer] = useState(false);

  const { selectedState } = useSelector(stateSelector);

  const onClickAdd = () => {
    setSelectedEntity(null);
    setOpenEntityDrawer(true);
  };

  const onClickEdit = entity => {
    setSelectedEntity(entity);
    setOpenEntityDrawer(true);
  };

  const onSubmit = payload => {
    if (selectedEntity) {
      dispatch(
        stateActionCreators.updateRegisteredEntity({
          bodyRequest: payload,
          pathParams: { stateId: selectedState._id },
          callback: () => {
            dispatch(stateActionCreators.fetchStateDetail(stateId));
            setOpenEntityDrawer(false);
          },
        }),
      );
    } else {
      dispatch(
        stateActionCreators.updateRegisteredEntity({
          bodyRequest: payload,
          pathParams: { stateId: selectedState._id },
          callback: () => {
            dispatch(stateActionCreators.fetchStateDetail(stateId));
            setOpenEntityDrawer(false);
          },
        }),
      );
      // dispatch(
      //   stateActionCreators.addRegisteredEntity({
      //     bodyRequest: payload,
      //     callback: () => {
      //       setOpenEntityDrawer(false);
      //     },
      //   }),
      // );
    }
  };

  useEffect(() => {
    dispatch(stateActionCreators.fetchStateDetail(stateId));
  }, []);

  const renderInfo = (label, text, className = '') => {
    return (
      <Box className={className}>
        <TextField label={label} text={text || '-'} />
      </Box>
    );
  };

  return (
    <>
      <Box display="flex" alignItems="center" gap={2}>
        <Heading className={classes.heading} level={headingLevel.XL} weight={fontWeight.BOLD}>
          {selectedState.name}
        </Heading>
        <Badge
          variant={badgeType.FILLED}
          style={selectedState.isActive ? badgeStyle.ACTIVE : badgeStyle.INACTIVE}
        >
          {selectedState.isActive ? 'Active' : 'Inactive'}
        </Badge>
      </Box>

      <Box className={classes.card}>
        <Box className={classes.cardTopSection}>
          <Heading level={headingLevel.S} weight={fontWeight.BOLD} className={classes.headingText}>
            General information
          </Heading>
          {selectedState?.registeredEntities?.length === 0 && (
            <IconButton className={classes.textBtn} onClick={onClickAdd}>
              Add Entity
            </IconButton>
          )}
        </Box>

        <AddRegisteredEntity
          isOpen={openEntityDrawer}
          selectedEntity={selectedEntity}
          onSubmit={onSubmit}
          onClose={() => setOpenEntityDrawer(false)}
        />

        {selectedState?.registeredEntities?.map(entity => {
          const {
            _id,
            address1,
            address2,
            city,
            fax,
            isActive,
            name,
            phone,
            state,
            zip,
            taxId,
            npi,
            zipPlusFourCode,
          } = entity;
          return (
            <Box key={_id} className={classes.cardContentSection}>
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Box display="flex" alignItems="center" gap={2}>
                  <Heading level={headingLevel.S} weight={fontWeight.BOLD} className={classes.headingText}>
                    {name}
                  </Heading>
                  <Badge variant={badgeType.OUTLINED} style={badgeStyle.ACTIVE} className={classes.badge}>
                    <span className={clsx(classes.dot, { [classes.dotActive]: isActive })} />{' '}
                    <span>{isActive ? 'Active' : 'Inactive'}</span>
                  </Badge>
                </Box>
                <IconButton
                  iconPosition={iconBtnPosition.RIGHT}
                  className={classes.textBtn}
                  onClick={() => onClickEdit(entity)}
                >
                  Edit
                </IconButton>
              </Box>

              <Grid container spacing={3} mt={1}>
                <Grid item xs={4}>
                  {renderInfo('Address 1', address1)}
                </Grid>
                <Grid item xs={8}>
                  {renderInfo('Address 2', address2)}
                </Grid>
                <Grid item xs={4}>
                  {renderInfo('City', city)}
                </Grid>
                <Grid item xs={8}>
                  {renderInfo('State', state)}
                </Grid>
                <Grid item xs={4}>
                  {renderInfo('Zip', zip)}
                </Grid>
                <Grid item xs={8}>
                  {renderInfo('Phone', phone)}
                </Grid>
                <Grid item xs={4}>
                  {renderInfo('Fax', fax)}
                </Grid>
                <Grid item xs={8}>
                  {renderInfo('Tax ID', taxId)}
                </Grid>
                <Grid item xs={4}>
                  {renderInfo('NPI', npi)}
                </Grid>
                <Grid item xs={8}>
                  {renderInfo('Zip +4 code', zipPlusFourCode)}
                </Grid>
              </Grid>
            </Box>
          );
        })}
      </Box>
    </>
  );
};

export default GeneralInformation;
