/* eslint-disable import/no-cycle */
import { useEffect, useState } from 'react';
import cx from 'clsx';
import clsx from 'clsx';

// mui
import MuiMenu from '@mui/material/Menu';
import { Box } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

// components
import { Link } from '../../../atoms/link';
import { Pill } from '../../../atoms/pill';
import { CheckboxGroup } from '../../../molecules/checkbox-group';
import { IconButton } from '../../../molecules/icon-button';

import { Icons } from '../../../../icons';

// styles
import { useStyles } from './TableFilter.styles';
import { Drawer } from '../../drawer';
import { Button } from '../../../atoms/button';
import { fontWeight, Heading, headingLevel, Text, textLevel } from '../../../atoms/typography';
import { Collapsable } from '../../../atoms/collapsable';
import { Input } from '../../../atoms/input';
import { Select } from '../../../atoms/select';
import { Toggle } from '../../../atoms/toggle';
import { Avatar } from '../../../atoms/avatar';
import { Badge, badgeStyle } from '../../../atoms/badge';
import { colors } from '../../../../colors';
import { Tooltip } from '../../../atoms/tooltip';

const TableFilter = ({
  className = '',
  variant = 'SINGLE',
  value,
  disabled = false,
  options = [],
  allOptionLabel = '',
  multiSelectOptions = null,
  onChangeMultiSelectFilter,
  onChange,
  ...rest
}) => {
  const [selectedDropdownItem, setSelectedDropdownItem] = useState('');
  const getFormatedSectionObject = filteredOutSectionsList => {
    if (!filteredOutSectionsList && filteredOutSectionsList?.length <= 0) {
      return {};
    }
    const formatedSections = {};
    for (let index = 0; index < filteredOutSectionsList?.length; index++) {
      const section = filteredOutSectionsList[index];
      formatedSections[section.sectionTitle] =
        section.sectionType === 'DEFAULT' ||
        section.sectionType === 'DROPDOWN' ||
        section.sectionType === 'RADIO'
          ? []
          : section.sectionOptions;
    }
    return formatedSections;
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const [isOpenMultipleFiltersDrawer, setIsOpenMultipleFiltersDrawer] = useState(false);
  const open = Boolean(anchorEl);
  const [filteredMultiSelectOptions, setFilteredMultiSelectOptions] = useState(multiSelectOptions);
  const [searchKeyword, setSearchKeyword] = useState('');

  const [totalFilterCount, setTotalFilterCount] = useState(0);
  const [btnTotalCount, setBtnTotalCount] = useState(0);
  const [multiselectFiltersAdded, setMultiselectFiltersAdded] = useState(false);
  // Selected filters
  const [addedToggle, setAddedToggleOptions] = useState(
    multiSelectOptions
      ? getFormatedSectionObject(multiSelectOptions?.filter(item => item.sectionType === 'TOGGLE'))
      : {},
  );
  const [addedCheckboxes, setAddedCheckboxes] = useState(
    multiSelectOptions
      ? getFormatedSectionObject(multiSelectOptions?.filter(item => item.sectionType === 'DEFAULT'))
      : {},
  );
  const [addedRadio, setAddedRadio] = useState(
    multiSelectOptions
      ? getFormatedSectionObject(multiSelectOptions?.filter(item => item.sectionType === 'RADIO'))
      : {},
  );
  const [addedDropdown, setAddedDropdownOptions] = useState(
    multiSelectOptions
      ? getFormatedSectionObject(multiSelectOptions?.filter(item => item.sectionType === 'DROPDOWN'))
      : null,
  );

  const getAllMultiselectFilters = () => {
    const getActiveToggle = key => {
      const selectedToggles = addedToggle[key].map(item => {
        if (item.value) {
          return item.label;
        }
        return null;
      });
      const filteredSelectedToggles = selectedToggles.filter(item => item);
      if (selectedToggles.length > 0 && filteredSelectedToggles.length > 0) return filteredSelectedToggles;
      return null;
    };
    const filterOutEmptySelections = sectionSelections => {
      if (!sectionSelections) {
        return 0;
      }
      const sectionKeys = Object.keys(sectionSelections);
      const newSection = {};
      if (sectionKeys.length > 0) {
        Object.values(sectionSelections).forEach((item, index) => {
          if (item?.length > 0) {
            newSection[sectionKeys[index]] = item;
          }
        });
        return newSection;
      }
      return null;
    };
    const togglesActive = {};
    Object.keys(addedToggle)?.forEach(item => {
      if (getActiveToggle(item)) {
        togglesActive[item] = getActiveToggle(item);
      }
    });
    return {
      ...filterOutEmptySelections(addedCheckboxes),
      ...filterOutEmptySelections(addedDropdown),
      ...filterOutEmptySelections(addedRadio),
      ...filterOutEmptySelections(togglesActive),
    };
  };
  const [showDropdown, setShowDropdown] = useState(false);

  const toggleShowDropdown = () => {
    setSelectedDropdownItem('');
    setShowDropdown(!showDropdown);
  };
  const addedSectionsHasThisSectionName = (addedSections, sectionKey) => {
    // eslint-disable-next-line no-prototype-builtins
    const hasProperty = addedSections?.hasOwnProperty(sectionKey);
    return hasProperty;
  };
  useEffect(() => {
    if (multiSelectOptions) {
      const newAddedCheckBoxes = {};
      const newAddedToggles = getFormatedSectionObject(
        multiSelectOptions.filter(item => item.sectionType === 'TOGGLE'),
      );
      const newAddedRadios = {};
      const newAddeddropdowns = {};
      if (rest?.multiSelectFiltersValue) {
        const addedKeys = Object.keys(rest.multiSelectFiltersValue);

        if (addedKeys && addedKeys?.length > 0) {
          addedKeys.forEach(key => {
            if (addedSectionsHasThisSectionName(addedDropdown, key)) {
              newAddeddropdowns[key] = rest?.multiSelectFiltersValue[key];
            } else if (addedSectionsHasThisSectionName(addedRadio, key)) {
              newAddedRadios[key] = rest?.multiSelectFiltersValue[key];
            } else if (addedSectionsHasThisSectionName(addedToggle, key)) {
              rest?.multiSelectFiltersValue[key]?.forEach(item => {
                const hasLabel = newAddedToggles[key].filter(opt => opt.label === item);
                if (hasLabel && hasLabel.length > 0) {
                  newAddedToggles[key] = [
                    { label: item, value: true },
                    ...newAddedToggles[key].filter(opt => opt.label !== item),
                  ];
                }
              });
            } else if (addedSectionsHasThisSectionName(addedCheckboxes, key)) {
              newAddedCheckBoxes[key] = rest?.multiSelectFiltersValue[key];
            }
          });
        } else setTotalFilterCount(0);
      }
      setAddedCheckboxes(newAddedCheckBoxes);
      setAddedDropdownOptions(newAddeddropdowns);
      setAddedRadio(newAddedRadios);
      setAddedToggleOptions(newAddedToggles);
    }
  }, [rest.multiSelectFiltersValue]);

  const getSumOfAllSectionLengths = (addedSection, isToggleType) => {
    if (!addedSection) {
      return 0;
    }
    const sectionKeys = Object.keys(addedSection);
    let totalCount = 0;
    if (sectionKeys && sectionKeys.length > 0) {
      sectionKeys.forEach(key => {
        if (isToggleType) {
          totalCount += addedSection[key]?.filter(item => item.value)?.length ?? 0;
          return;
        }
        totalCount += addedSection[key]?.length ?? 0;
      });
      return totalCount;
    }
    return 0;
  };

  useEffect(() => {
    let totalCount = 0;
    [
      { section: addedCheckboxes, isToggle: false },
      { section: addedDropdown, isToggle: false },
      { section: addedRadio, isToggle: false },
      { section: addedToggle, isToggle: true },
    ].forEach(item => {
      totalCount += getSumOfAllSectionLengths(item.section, item.isToggle);
    });
    if (!isOpenMultipleFiltersDrawer && rest?.filterTags?.length > 0) {
      setTotalFilterCount(rest?.filterTags?.length);
      if (multiselectFiltersAdded) {
        setMultiselectFiltersAdded(false);
      }
    }
    setBtnTotalCount(totalCount);
  }, [
    isOpenMultipleFiltersDrawer,
    addedCheckboxes,
    addedDropdown,
    addedRadio,
    addedToggle,
    rest?.filterTags,
  ]);
  const isSelected = () => {
    if (variant === 'SINGLE' && value) return true;
    if (variant === 'MULTIPLE' && value?.length > 0) return true;
    if (variant === 'MULTIPLE' && totalFilterCount > 0) return true;
    return false;
  };
  useEffect(() => {
    if (!searchKeyword || searchKeyword === '') {
      setFilteredMultiSelectOptions(multiSelectOptions);
      return;
    }
    setFilteredMultiSelectOptions(
      multiSelectOptions
        .map(multiSelectOption => ({
          ...multiSelectOption,
          sectionOptions: multiSelectOption.sectionOptions?.filter(sectionOptionList => {
            if (!sectionOptionList.label?.toLocaleLowerCase().includes(searchKeyword.toLocaleLowerCase())) {
              return null;
            }
            return sectionOptionList;
          }),
        }))
        .filter(item => item.sectionOptions?.length > 0),
    );
  }, [searchKeyword]);
  const onOpen = e => {
    if (variant === 'MULTIPLE' && multiSelectOptions?.length > 0) {
      setIsOpenMultipleFiltersDrawer(true);
      return;
    }
    setAnchorEl(e.currentTarget);
  };

  const onClose = () => {
    setAnchorEl(null);
  };

  const classes = useStyles({ open, selected: isSelected() });

  const renderFilterText = () => {
    if (!isSelected()) return 'Add filter';

    if (variant === 'SINGLE') {
      return options.find(({ value: optionValue }) => optionValue === value)?.label;
    }

    return (
      <Pill
        className={classes.appliedCount}
        variant="CURRENT"
        label={`${value?.length + totalFilterCount > 1 ? 'filters' : 'filter'} applied`}
      >
        {value?.length ? value.length + totalFilterCount : totalFilterCount}
      </Pill>
    );
  };
  const toggleMultipleFilterDrawer = () => {
    setIsOpenMultipleFiltersDrawer(!isOpenMultipleFiltersDrawer);
  };
  // Multiselect filters change handlers
  const handleAddMultiselectFilters = () => {
    setMultiselectFiltersAdded(true);
    onChangeMultiSelectFilter(getAllMultiselectFilters());
    toggleMultipleFilterDrawer();
  };
  const renderFilterIcon = () => {
    if (!isSelected()) return <Icons glyph="plus" color="currentColor" />;
    return null;
  };
  const handleAddDropdownItem = (event, sectionTitle) => {
    if (event.target.value) {
      setSelectedDropdownItem(event.target.value);
      setTimeout(() => {
        setSelectedDropdownItem('');
      }, 200);
      const newDropdownselection = JSON.parse(JSON.stringify(addedDropdown));
      if (newDropdownselection[sectionTitle]) {
        newDropdownselection[sectionTitle].push(event.target.value);
        setAddedDropdownOptions(newDropdownselection);
        return;
      }
      newDropdownselection[sectionTitle] = [event.target.value];
      setAddedDropdownOptions(newDropdownselection);
    }
  };
  const handleDeleteDropdownItem = (item, sectionTitle) => {
    if (addedDropdown[sectionTitle].length <= 1) {
      setAddedDropdownOptions({
        ...addedDropdown,
        [sectionTitle]: [],
      });
      return;
    }
    setAddedDropdownOptions({
      ...addedDropdown,
      [sectionTitle]: addedDropdown[sectionTitle].filter(opt => opt !== item),
    });
  };
  const handleToggleChange = (label, values, sectionTitle) => {
    setAddedToggleOptions({
      ...addedToggle,
      [sectionTitle]: [
        ...addedToggle[sectionTitle].filter(item => item.label !== label),
        { label, value: values },
      ],
    });
  };
  const handleCheckboxChange = (values, sectionTitle) => {
    setAddedCheckboxes({
      ...addedCheckboxes,
      [sectionTitle]: values,
    });
  };
  const handleRadioChange = (values, sectionTitle) => {
    if (!values) {
      setAddedRadio({
        ...addedRadio,
        [sectionTitle]: [],
      });
      return;
    }
    setAddedRadio({
      ...addedRadio,
      [sectionTitle]: values[values.length - 1] ? [values[values.length - 1]] : [],
    });
  };
  const renderDropdown = (filterOptions, componentType, sectionTitle) => {
    return (
      <Box className={classes.dropdownWrapper}>
        <Box
          display="flex"
          flexDirection="column"
          gap={2}
          marginBottom="16px"
          marginTop={getCountByFilterTypeSectionTitle(componentType, sectionTitle) > 0 ? '16px' : '0px'}
        >
          {
            // added list
            addedDropdown[sectionTitle]?.length > 0 &&
              addedDropdown[sectionTitle].map(item => (
                <Box className={classes.profileWrapper}>
                  {sectionTitle === 'Specific providers' ? (
                    <Box display="flex" alignItems="center" gap="12px">
                      <Avatar variant="CIRCLE" src={item.image} name={item.name} size={40} />
                      <Box display="flex" flexDirection="column" gap="4px">
                        {item.name.length >= 20 ? (
                          <Tooltip title={item.name} placement="top-start">
                            <div>
                              <Text
                                weight={fontWeight.SEMI_BOLD}
                                level={textLevel.S}
                                className={classes.nickName}
                              >
                                {item.name}
                              </Text>
                            </div>
                          </Tooltip>
                        ) : (
                          <Text
                            weight={fontWeight.SEMI_BOLD}
                            level={textLevel.S}
                            className={classes.nickName}
                          >
                            {item.name}
                          </Text>
                        )}
                        <Badge
                          style={badgeStyle.PRIMARY}
                          className={clsx(classes.badge, item.designation)}
                          variant="FILLED"
                          tooltip={item.designation.length >= 20 ? item.designation : null} // adds tooltip and ellipsis to bleeding text
                        >
                          {item.designation}
                        </Badge>
                      </Box>
                    </Box>
                  ) : (
                    <Text level={textLevel.S} weight={fontWeight.SEMI_BOLD}>
                      {filterOptions.find(opt => opt.value === item)?.label}
                    </Text>
                  )}{' '}
                  <Icons
                    glyph="delete-outlined-2"
                    className={classes.deleteText}
                    color={colors.destructive500}
                    onClick={() => handleDeleteDropdownItem(item, sectionTitle)}
                  />
                </Box>
              ))
          }
        </Box>
        {
          // dropdown

          <Select
            name="dropdown"
            value={selectedDropdownItem}
            options={
              filterOptions
                ?.filter(item => !addedDropdown[sectionTitle]?.includes(item.value))
                .sort((a, b) => a.label.localeCompare(b.label)) || []
            }
            IconComponent={props => <AddIcon style={{ color: '#005ebe' }} {...props} />}
            emptyText="Add specific providers"
            variant="PRIMARY"
            displayEmpty
            sx={{
              '& .MuiSelect-icon': {
                right: 'unset',
                left: '10px',
              },
            }}
            adjustText
            truncate={false}
            onChange={event => handleAddDropdownItem(event, sectionTitle)}
          />
        }
      </Box>
    );
  };
  const isAddedToggleOption = (sectionTitle, option) => {
    return addedToggle[sectionTitle]
      ?.filter(item => item.value)
      ?.map(item => item.label)
      ?.includes(option);
  };
  const renderFilterComponent = (filterOptions, componentType, sectionTitle) => {
    switch (componentType) {
      case 'DROPDOWN':
        return renderDropdown(filterOptions, componentType, sectionTitle);
      case 'TOGGLE':
        return filterOptions.map((opt, index) => (
          <Toggle
            key={`${index}-toggle-${opt.label}`}
            labelPosition="RIGHT"
            checked={isAddedToggleOption(sectionTitle, opt.label) ?? opt.value}
            onChange={values => handleToggleChange(opt.label, values, sectionTitle)}
          >
            {opt.label}
          </Toggle>
        ));
      case 'RADIO':
        return (
          <CheckboxGroup
            className={cx(classes.options, classes.multiselectOptions)}
            options={filterOptions}
            value={addedRadio[sectionTitle] ?? value}
            onChange={values => handleRadioChange(values, sectionTitle)}
          />
          // <RadioGroup
          //   defaultValue={addedRadio[sectionTitle]?.[0] ?? ''}
          //   options={[{ label: 'No selection', value: '' }, ...filterOptions]}
          //   onChange={event => handleRadioChange(event.target.value, sectionTitle)}
          // />
        );
      default:
        return (
          <CheckboxGroup
            className={cx(classes.options, classes.multiselectOptions)}
            options={filterOptions}
            value={addedCheckboxes[sectionTitle] ?? value}
            onChange={values => handleCheckboxChange(values, sectionTitle)}
          />
        );
    }
  };
  const getCountByFilterTypeSectionTitle = (componentType, sectionTitle) => {
    switch (componentType) {
      case 'DROPDOWN':
        return addedDropdown[sectionTitle]?.length || 0;
      case 'RADIO':
        return addedRadio[sectionTitle]?.length || 0;
      case 'TOGGLE':
        return addedToggle[sectionTitle]?.filter(item => item.value)?.length || 0;
      default:
        return addedCheckboxes[sectionTitle]?.length || 0;
    }
  };
  const renderFilterSection = (
    filteroptions,
    sectionTitle,
    componentType,
    // allOptionsLabel?: string,
    tooltip,
  ) => {
    return (
      <Collapsable
        label={sectionTitle}
        iconPosition="RIGHT"
        open
        tooltip={tooltip}
        subLabel={`${
          getCountByFilterTypeSectionTitle(componentType, sectionTitle) > 0
            ? getCountByFilterTypeSectionTitle(componentType, sectionTitle)
            : 'Not '
        } selected`}
        className={classes.sectionWrapper}
        iconColor={colors.neutral900}
      >
        {renderFilterComponent(filteroptions, componentType, sectionTitle)}
      </Collapsable>
    );
  };

  return (
    <>
      {variant === 'MULTIPLE' && multiSelectOptions?.length > 0 && (
        <Drawer
          onClose={() => toggleMultipleFilterDrawer()}
          open={isOpenMultipleFiltersDrawer}
          title="Apply filters"
          subTitle="test"
          widthVariant="NARROW"
        >
          <Box className={classes.wrapper}>
            <Box className={classes.header}>
              <Heading level={headingLevel.S} weight={fontWeight.BOLD}>
                Apply filters
              </Heading>
              <IconButton icon="close" onClick={toggleMultipleFilterDrawer} className={classes.backBtn} />
            </Box>
            <Box className={classes.collapsableSectionsWrapper}>
              <Input
                onChange={values => setSearchKeyword(values)}
                value={searchKeyword}
                className={classes.input}
                variant="SEARCH"
                placeholder="Search filter"
              />
              {filteredMultiSelectOptions.map(sectionOptions =>
                renderFilterSection(
                  sectionOptions.sectionOptions,
                  sectionOptions.sectionTitle,
                  sectionOptions.sectionType,
                  sectionOptions.sectionTooltip,
                ),
              )}
            </Box>
          </Box>
          <Box className={classes.footer}>
            <Button variant="TEXT" onClick={toggleMultipleFilterDrawer}>
              Cancel
            </Button>
            <Button onClick={handleAddMultiselectFilters}>{`Add ${
              btnTotalCount > 0 ? btnTotalCount : ''
            } filters`}</Button>
          </Box>
        </Drawer>
      )}
      <MuiMenu
        className={cx({
          [classes.root]: true,
          [className || '']: className,
        })}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        onClose={onClose}
        {...rest}
      >
        {variant === 'SINGLE' && (
          <div className={classes.options}>
            {[{ label: allOptionLabel, value: null }, ...options].map(
              ({ label, value: optionValue }, idx) => (
                <Link
                  key={idx}
                  variant="callback"
                  className={cx({
                    [classes.optionItem]: true,
                    [classes.allOptionItem]: idx === 0,
                  })}
                  onClick={() => {
                    onChange(optionValue);
                    onClose();
                  }}
                >
                  {label}
                </Link>
              ),
            )}
          </div>
        )}
        {variant === 'MULTIPLE' && (
          <CheckboxGroup
            className={classes.options}
            options={options}
            checkAllOption={allOptionLabel ? { label: allOptionLabel } : null}
            value={value}
            onChange={onChange}
          />
        )}
      </MuiMenu>
      <IconButton
        variant="SECONDARY"
        onClick={onOpen}
        disabled={disabled}
        className={cx(classes.filterBtn, { [classes.filterBtnDisabled]: disabled })}
      >
        {renderFilterIcon()}
        {renderFilterText()}
      </IconButton>
    </>
  );
};

export { TableFilter };
