import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import { colors, fontWeight, Icons, Text, textLevel } from '../../../../../packages';
import { ProfileInfo } from '../../../../../packages/ui/templates/profile-info';
import { getProfile } from '../../../../../redux/modules/profile/selectors';
import { useStyles } from './StepSix.styles';
import { getDuration, getFullImagePath, toSentenceCase } from '../../../../../utils/CommonUtils';
import dayjs from '../../../../../utils/dayjs';
import { appointmentType, RECURRING_APPOINTMENT_FREQUENCIED } from '../AddSchedule.constants';

const weekDays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

const StepSixNew = ({
  formData,
  isNewProviderSelected,
  selectedAppointmentType,
  provider,
  recurringSlots,
  recurringType,
}) => {
  const classes = useStyles();
  const { profile } = useSelector(getProfile);
  const isHide = false;

  const renderLabel = label => (
    <Text className={clsx(classes.text18, classes.label)} weight={fontWeight.BOLD}>
      {label}
    </Text>
  );

  const getFrequencyTextForRecurringSlots = (slots, recurrenceType) => {
    if (slots?.length > 0 && recurrenceType) {
      switch (recurrenceType) {
        case RECURRING_APPOINTMENT_FREQUENCIED.DAILY:
          return `Every day at ${dayjs(slots?.[0]?.startTime).format('hh:mm a')}`;
        case RECURRING_APPOINTMENT_FREQUENCIED.WEEKLY:
          return `Every ${weekDays[dayjs(slots?.[0]?.startTime).get('day')]} at ${dayjs(
            slots?.[0]?.startTime,
          ).format('hh:mm a')}`;
        case RECURRING_APPOINTMENT_FREQUENCIED.BI_WEEKLY:
          return `Every other week on ${weekDays[dayjs(slots?.[0]?.startTime).get('day')]} at ${dayjs(
            slots?.[0]?.startTime,
          ).format('hh:mm a')}`;
        case RECURRING_APPOINTMENT_FREQUENCIED.MONTHLY:
          return `Every month on ${dayjs(slots?.[0]?.startTime).format('DD')} at ${dayjs(
            slots?.[0]?.startTime,
          ).format('hh:mm a')}`;

        default:
          return '';
      }
    }
    return '-';
  };

  return (
    <Box className={classes.reviewWrap}>
      <Box
        className={classes.reviewSection}
        display="flex"
        justifyContent="space-around"
        borderBottom="solid 1px #EDF1F5"
        alignItems="center"
      >
        <Box className={classes.title}> {renderLabel('Member')}</Box>
        <Box className={classes.valueSection}>
          <ProfileInfo
            type="member"
            photo={getFullImagePath(formData?.member?.photo)}
            nickName={`${formData?.member?.firstName || ''} ${formData?.member?.lastName || ''}`}
            fullName={formData?.member?.uuid}
            role={formData?.member?.designation}
          />
        </Box>
      </Box>
      <Box
        className={classes.reviewSection}
        display="flex"
        justifyContent="space-around"
        borderBottom="solid 1px #EDF1F5"
        alignItems="center"
      >
        <Box className={classes.title}> {renderLabel('Provider')}</Box>
        <Box className={classes.valueSection}>
          {selectedAppointmentType === appointmentType.SCHEDULING_LINK ? (
            <span className={classes.text16_3}>TBD</span>
          ) : (
            <ProfileInfo
              type="provider"
              photo={
                (selectedAppointmentType === appointmentType.SPECIFIC_DATE ||
                  selectedAppointmentType === appointmentType.RECURRING) &&
                !isNewProviderSelected
                  ? getFullImagePath(formData?.provider?.profileImage)
                  : (selectedAppointmentType === appointmentType.SPECIFIC_DATE ||
                      selectedAppointmentType === appointmentType.RECURRING) &&
                    isNewProviderSelected
                  ? getFullImagePath(provider?.profileImage)
                  : getFullImagePath(profile?.profileImage)
              }
              fullName={
                (selectedAppointmentType === appointmentType.SPECIFIC_DATE ||
                  selectedAppointmentType === appointmentType.RECURRING) &&
                !isNewProviderSelected
                  ? formData?.provider?.fullName
                  : (selectedAppointmentType === appointmentType.SPECIFIC_DATE ||
                      selectedAppointmentType === appointmentType.RECURRING) &&
                    isNewProviderSelected
                  ? provider?.fullName
                  : 'You'
              }
              role={
                (selectedAppointmentType === appointmentType.SPECIFIC_DATE ||
                  selectedAppointmentType === appointmentType.RECURRING) &&
                !isNewProviderSelected
                  ? formData?.provider?.designation
                  : (selectedAppointmentType === appointmentType.SPECIFIC_DATE ||
                      selectedAppointmentType === appointmentType.RECURRING) &&
                    isNewProviderSelected
                  ? provider?.designation
                  : ''
              }
            />
          )}
        </Box>
      </Box>
      <Box
        className={classes.reviewSection}
        display="flex"
        justifyContent="space-around"
        borderBottom="solid 1px #EDF1F5"
        alignItems="center"
      >
        <Box className={classes.title}> {renderLabel('Service')}</Box>
        <Box className={classes.valueSection2}>
          <Text className={classes.text16} level={textLevel.M} weight={fontWeight.MEDIUM}>
            {formData?.selectedService?.name}
          </Text>
          <Text className={classes.text16_2} level={textLevel.M} weight={fontWeight.MEDIUM}>
            {getDuration(formData?.selectedService)}, ${formData?.selectedService?.cost}
          </Text>
        </Box>
      </Box>
      {selectedAppointmentType === appointmentType.RECURRING && (
        <Box
          className={classes.reviewSection}
          display="flex"
          justifyContent="space-around"
          borderBottom="solid 1px #EDF1F5"
          alignItems="center"
        >
          <Box className={classes.title}> {renderLabel('Type')}</Box>
          <Box className={classes.valueSection2}>
            <Text className={classes.text16} level={textLevel.M} weight={fontWeight.MEDIUM}>
              {toSentenceCase(selectedAppointmentType)}
            </Text>
            <Text className={classes.text16_2} level={textLevel.M} weight={fontWeight.MEDIUM}>
              {getFrequencyTextForRecurringSlots(recurringSlots, recurringType)}
            </Text>
          </Box>
        </Box>
      )}
      {isHide && (
        <Box
          className={classes.reviewSection}
          display="flex"
          justifyContent="space-around"
          borderBottom="solid 1px #EDF1F5"
          alignItems="center"
        >
          <Box className={classes.title}> {renderLabel('Type')}</Box>
          <Box className={classes.valueSection3}>
            <Icons
              glyph={
                selectedAppointmentType === appointmentType.SPECIFIC_DATE
                  ? 'specific-date'
                  : selectedAppointmentType === appointmentType.INSTANT_SESSION
                  ? 'instant-appointment-outlined'
                  : 'connect'
              }
              color={colors.primary}
              // className={classes.bookNewProviderIcon}
            />
            <Text className={classes.text16_2} level={textLevel.M} weight={fontWeight.MEDIUM}>
              {selectedAppointmentType === appointmentType.SPECIFIC_DATE
                ? 'Specific date'
                : selectedAppointmentType === appointmentType.INSTANT_SESSION
                ? 'Instant session'
                : 'Scheduling link'}
            </Text>
          </Box>
        </Box>
      )}
      <Box className={classes.reviewSection} display="flex" justifyContent="space-around" alignItems="center">
        <Box className={classes.title}> {renderLabel('Date')}</Box>
        <Box className={classes.valueSection2} gap="16px">
          {selectedAppointmentType === appointmentType.SCHEDULING_LINK ? (
            <span className={classes.text16_3}>TBD</span>
          ) : (
            <>
              <Text className={classes.text16} level={textLevel.M} weight={fontWeight.MEDIUM}>
                {selectedAppointmentType === appointmentType.SPECIFIC_DATE
                  ? dayjs(formData?.time?.start).format('MM/DD/YYYY')
                  : dayjs().format('MM/DD/YYYY')}
              </Text>
              <Text className={classes.text16_2} level={textLevel.M} weight={fontWeight.MEDIUM}>
                {selectedAppointmentType === appointmentType.SPECIFIC_DATE
                  ? dayjs(formData?.time?.start).format('hh:mm A')
                  : dayjs().format('hh:mm A')}
              </Text>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default StepSixNew;
