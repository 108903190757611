import { makeStyles } from '@mui/styles';
// colors
import { colors } from '../../../../../packages';

export const useStyles = makeStyles({
  timeWrap: {
    fontSize: 14,
    lineHeight: '20px',
    height: 44,
  },
  date: {
    color: colors.neutral900,
    fontWeight: 600,
  },
  time: {
    color: colors.neutral600,
    fontWeight: 500,
  },
  upcomingItem: {
    padding: '16px 24px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 32,
    cursor: 'pointer',
  },
  userInfo: {
    display: 'flex',
    gap: 12,
    alignItems: 'center',
    width: 200,
  },
  name: {
    fontWeight: 600,
    lineHeight: '20px',
    display: 'block',
  },
  fullname: {},
  bottomText: {
    fontWeight: '600',
    color: colors.neutral700,
    lineHeight: '20px',
  },
  bottomIcon: {
    height: 16,
    width: 16,
  },
  contents: {
    display: 'flex',
    justifyContent: 'flex-start',
    gap: 32,
    flex: 1,
  },
  statusWrap: {
    justifyContent: 'space-between',
    // width: '20%',
    '& svg': {
      width: 11,
      height: 16,
      objectFit: 'cover',
      '& path': {
        fill: colors.neutral400,
      },
    },
  },
  contentWrap: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
  },
  badge: {
    borderRadius: '20px',
  },
  arrBtn: {
    cursor: 'pointer',
  },
  item: {
    '&:not(:last-child)': {
      borderBottom: `1px solid ${colors.neutral100}`,
    },
  },
  menu: {
    float: 'right',
    right: 20,
    '& svg': {
      width: 16,
    },
  },
  menuItemsWrapper: {
    '& ul': {
      padding: 0,
    },
    '& ul li': {
      padding: '10px 16px',
      '&:last-child': {
        color: colors.destructive600,
        '& svg path': {
          fill: colors.destructive600,
        },
      },
    },
  },
  icon: {
    marginRight: 12,
  },
});
