import cx from 'clsx';

// mui
import MuiPagination from '@mui/material/Pagination';

// types
// styles
import { useStyles } from './Pagination.styles';

const Pagination = ({ className = '', count, page, size, onChange, ...rest }) => {
  const classes = useStyles({ size });

  return (
    <MuiPagination
      className={cx({
        [classes.root]: true,
        [className || '']: className,
      })}
      shape="rounded"
      count={count}
      page={page}
      onChange={onChange}
      size={size}
      {...rest}
    />
  );
};

export { Pagination };
