import { FC, Fragment, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import clsx from 'clsx';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

import { iconBtnStyle, iconBtnType, IconButton } from '../../../../packages/ui/molecules/icon-button';
import { Badge, badgeStyle, badgeType } from '../../../../packages/ui/atoms/badge';
import { Text } from '../../../../packages/ui/atoms/typography';
import { DomainPriorities } from '../../../../redux/modules/profile/types';

import { useStyles } from './InfoCard.styles';

dayjs.extend(relativeTime);

const InfoCard = ({ title, icon, type, subtitle, badge, domains, isCompleted, onClick }) => {
  const classes = useStyles();
  const [progressBar, setProgressBar] = useState(null);

  const isDomainType = () => {
    return [
      'socialDeterminants',
      'lifeEvents',
      'symptoms',
      'diagnoses',
      'medications',
      'sideEffects',
      'substanceUse',
    ].includes(type);
  };

  const onClickView = () => {
    onClick && onClick(type);
  };

  useEffect(() => {
    if (domains && isDomainType()) {
      const domainTotal =
        type === 'lifeEvents'
          ? {
              [DomainPriorities.NEGATIVE]: 0,
              [DomainPriorities.POSITIVE]: 0,
            }
          : {
              [DomainPriorities.CRITICAL]: 0,
              [DomainPriorities.HIGH]: 0,
              [DomainPriorities.MEDIUM]: 0,
              [DomainPriorities.LOW]: 0,
              [DomainPriorities.MISREPORTED]: 0,
              [DomainPriorities.RESOLVED]: 0,
              [DomainPriorities.UNRELATED]: 0,
            };

      let total = 0;
      domains.forEach(item => {
        domainTotal[item.priority?.name] += 1;
        total += 1;
      });
      const result = Object.keys(domainTotal).map(key => ({
        percent: +Number((domainTotal[key] / total) * 100).toFixed(2),
        type: key,
      }));
      setProgressBar(result);
    }
  }, [domains, type]);

  const progressBarView = isDomainType();

  return (
    <Box
      onClick={onClickView}
      display="flex"
      justifyContent="space-between"
      alignItems={progressBarView ? 'center' : 'flex-start'}
      style={{ width: !progressBarView ? 'calc(50% - 8px)' : '100%' }}
      className={clsx(classes.card, { [classes.cardNotClickable]: !onClick })}
    >
      <Box display="flex" alignItems="center" gap="16px" flex={progressBarView ? 0.45 : 1}>
        <IconButton
          icon={icon}
          className={classes.infoIconBtn}
          style={iconBtnStyle.PRIMARY}
          variant={iconBtnType.SECONDARY}
        />
        <Box>
          <Text className={classes.title}>{title}</Text>
          <Text className={classes.subtitle}>
            {subtitle ? `Updated ${dayjs(subtitle).fromNow()}` : 'Not updated yet'}
          </Text>
        </Box>
      </Box>
      <Box
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        gap={3}
        className={clsx({ [classes.rightCard]: !!progressBar })}
      >
        {progressBar && (
          <Box display="flex" alignItems="center" gap="4px" className={classes.progressWrap}>
            {progressBar
              .sort((a, b) => a.percent - b.percent)
              .map((item, index) => (
                <Fragment key={index}>
                  {item.percent > 0 && (
                    <Box
                      className={clsx(classes.progress, item.type)}
                      style={{ width: `${item.percent}%` }}
                    />
                  )}
                </Fragment>
              ))}
          </Box>
        )}
        <Badge
          variant={isCompleted ? badgeType.ICON : badgeType.OUTLINED}
          style={isCompleted ? badgeStyle.RESOLVED : badgeStyle.UNRELATED}
          className={clsx(classes.badge, { [classes.completedBadge]: isCompleted })}
          icon={isCompleted ? 'checkmark' : undefined}
        >
          {badge}
        </Badge>
      </Box>
    </Box>
  );
};

export { InfoCard };
