import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/material';
import clsx from 'clsx';
import { Drawer, drawerType } from '../../../../../packages/ui/organisms/drawer';
import {
  Avatar,
  avatarType,
  Badge,
  badgeType,
  btnType,
  Button,
  colors,
  fontWeight,
  IconButton,
  Icons,
  Select,
  selectType,
  Stepper,
  Text,
  textLevel,
} from '../../../../../packages';
import { ProfileInfo } from '../../../../../packages/ui/templates/profile-info';
import { getAppointment } from '../../../../../redux/modules/appointment/selectors';
import webImage from '../../../../../assets/images/world-map.png';
import { stateSelector } from '../../../../../redux/modules/state/selectors';
import { useStyles } from './CreateSchedulingLink.styles';
import { stateActionCreators } from '../../../../../redux/modules/state';
import SelectMember from '../select-member/SelectMember';
import SelectProvider from '../select-provider/SelectProvider';
import { typography } from '../../../../../components/v2/Typography/index.constant';
import Typography from '../../../../../components/v2/Typography';
import getConfig from '../../../../../config';
import { showSnackbar } from '../../../../../redux/modules/snackbar';

const createSchedulingLinkSteps = ['General information', 'Member', 'Provider'];

const CreateSchedulingLink = ({ isOpen, onClose, onSubmit, stateOptions }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const contentRef = useRef(null);
  const { payers, isLoading: payerLoader } = useSelector(stateSelector);
  const { allProviderServices } = useSelector(getAppointment);
  const [formData, setFormData] = useState({
    service: '',
    state: '',
    insurance: '',
    member: null,
    provider: null,
  });
  const [currentStep, setCurrentStep] = useState(0);
  const [linkReady, setLinkReady] = useState(false);

  useEffect(() => {
    if (formData?.state) {
      const selectedStateId = stateOptions.find(state => state.name === formData?.state)?._id;
      dispatch(stateActionCreators.fetchPayers(selectedStateId));
    }
  }, [formData?.state]);

  const generateAppointmentLink = () => {
    const baseUrl = `${getConfig.memberAppUrl}public/book-appointment`;

    const insuranceName = payers?.find(payer => payer?._id === formData?.insurance)?.name;

    const values = {
      currentState: formData?.state?.replaceAll(' ', '-') || null,
      selectedInsurance: insuranceName?.replaceAll(' ', '-') || null,
      selectedServiceId: formData?.service || null,
      selectedProvider: formData?.provider?.id || null,
      memberId: formData?.member?.id || null,
    };

    const queryParams = Object.entries(values)
      .filter(([, value]) => value !== null && value !== undefined && value !== '')
      .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
      .join('&');
    return queryParams ? `${baseUrl}?${queryParams}` : baseUrl;
  };

  const copyToClipboard = async link => {
    await navigator.clipboard.writeText(link);
    dispatch(
      showSnackbar({
        snackType: 'success',
        snackMessage: 'Scheduling link copied successfully',
        vertical: 'top',
        horizontal: 'center',
      }),
    );
  };

  const schedulingLink = generateAppointmentLink();

  const renderGeneralInfo = () => {
    return (
      <Box sx={styles.generalInfoContainer}>
        <Text level={textLevel.L} weight={fontWeight.BOLD}>
          General information
        </Text>
        <Box sx={styles.filterContainer}>
          <Text level={textLevel.S} weight={fontWeight.BOLD}>
            Select service
          </Text>
          <Select
            variant={selectType.SECONDARY}
            emptyText="All services"
            displayEmpty
            value={formData.service}
            onChange={e => setFormData({ ...formData, service: e.target.value })}
            options={allProviderServices?.map(service => {
              return { label: service?.name, value: service?.serviceId };
            })}
          />
        </Box>
        <Box sx={styles.filterContainer}>
          <Text level={textLevel.S} weight={fontWeight.BOLD}>
            Select state
          </Text>
          <Select
            variant={selectType.SECONDARY}
            emptyText="All States"
            displayEmpty
            value={formData?.state}
            onChange={e => setFormData({ ...formData, state: e.target.value, insurance: '' })}
            options={stateOptions.map(state => {
              return { label: state?.name, value: state?.name };
            })}
          />
        </Box>
        {payerLoader ? (
          <>
            <Icons className="rotate linear infinite" glyph="in-progress" color={colors.primary} />
            Loading...
          </>
        ) : (
          <Box sx={styles.filterContainer}>
            <Text level={textLevel.S} weight={fontWeight.BOLD}>
              Select insurance
            </Text>
            <Select
              variant={selectType.SECONDARY}
              emptyText="All Insurances"
              displayEmpty
              value={formData.insurance}
              onChange={e => setFormData({ ...formData, insurance: e.target.value })}
              options={payers
                ?.filter(payer => payer.isActive)
                .map(payer => {
                  return { label: payer?.name, value: payer?._id };
                })}
            />
          </Box>
        )}
      </Box>
    );
  };

  const renderValue = key => {
    if (key === 'member' && formData?.member) {
      return (
        <ProfileInfo
          type="member"
          photo={formData?.member?.photo}
          nickName={
            formData?.member?.firstName && formData?.member?.lastName
              ? `${formData?.member?.firstName || ''} ${formData?.member?.lastName || ''}`
              : formData?.member?.nickName || 'No Name'
          }
          fullName={formData?.member?.uuid}
          memberId={formData?.member?.id}
        />
      );
    }
    if (key === 'provider' && formData?.provider) {
      return (
        <Box sx={styles.providerInfo}>
          <Avatar
            size={40}
            variant={avatarType.CIRCLE}
            src={formData?.provider?.photo}
            name={formData?.provider?.name}
          />
          <Box sx={styles.info}>
            <Text level={textLevel.S} weight={fontWeight.SEMI_BOLD} className={classes.valueText}>
              {formData?.provider?.name}
            </Text>
            <Badge
              className={clsx(classes.providerRole, formData?.provider?.designation?.split(' ').join(''))}
              variant={badgeType.FILLED}
            >
              {formData?.provider?.designation}
            </Badge>
          </Box>
        </Box>
      );
    }
    if (key === 'insurance' && formData?.insurance) {
      return (
        <Text level={textLevel.S} weight={fontWeight.MEDIUM}>
          {payers?.find(payer => payer?._id === formData?.insurance)?.name}
        </Text>
      );
    }
    if (key === 'service' && formData?.service) {
      return (
        <Text level={textLevel.S} weight={fontWeight.MEDIUM} className={classes.alignEnd}>
          {allProviderServices?.find(service => service.serviceId === formData?.service)?.name}
        </Text>
      );
    }
    if (key === 'state' && formData?.state) {
      return (
        <Text level={textLevel.S} weight={fontWeight.MEDIUM}>
          {formData?.state}
        </Text>
      );
    }
    return 'N/A';
  };

  const renderLink = () => {
    return (
      <Box sx={styles.linkContainer}>
        <Box display="flex" flexDirection="column" alignItems="center" gap="16px">
          <img src={webImage} alt="link" />
          <Text level={textLevel.XL} weight={fontWeight.SEMI_BOLD}>
            Your link is ready!
          </Text>
        </Box>
        <Box sx={styles.selectedData}>
          {Object.keys(formData).map((item, index) => {
            const key = `${item[0].toUpperCase()}${item.slice(1)}`;
            return (
              <Box key={index} sx={styles.selectedDataItem}>
                <Text level={textLevel.S} weight={fontWeight.MEDIUM} color={colors.neutral700}>
                  {key}
                </Text>
                <Typography
                  sx={{
                    display: 'flex',
                    flex: 1,
                    height: 20,
                    minWidth: 50,
                    backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='20' width='12'%3E%3Ccircle cx='10' cy='10' r='1' fill='%23bec9d3' /%3E%3C/svg%3E ")`,
                  }}
                  {...typography.body.s.medium}
                />
                {renderValue(item)}
              </Box>
            );
          })}
        </Box>
        <Box display="flex" flexDirection="column" width="100%" gap="16px">
          <Box sx={styles.linkBox}>
            <Box width="24px" height="24px">
              <Icons glyph="copy-link" color={colors.neutral400} />
            </Box>
            <Box sx={styles.linkText}>{schedulingLink}</Box>
          </Box>
          <IconButton
            icon="copy-outlined"
            variant={btnType.OUTLINE}
            className={classes.linkButton}
            onClick={() => copyToClipboard(schedulingLink)}
          >
            Copy link
          </IconButton>
        </Box>
      </Box>
    );
  };

  const renderStep = () => {
    switch (currentStep) {
      case 0: {
        return renderGeneralInfo();
      }
      case 1:
        return (
          <SelectMember
            selectedItem={formData.member}
            setSelectedItem={item => setFormData({ ...formData, member: item })}
            formData={formData}
          />
        );
      case 2:
        return (
          <SelectProvider
            selectedItem={formData.provider}
            setSelectedItem={item => setFormData({ ...formData, provider: item })}
            formData={formData}
          />
        );
      default: {
        return renderGeneralInfo();
      }
    }
  };

  const handleContinue = () => {
    switch (currentStep) {
      case 0: {
        setCurrentStep(1);
        return;
      }
      case 1: {
        setCurrentStep(2);
        return;
      }
      case 2: {
        setLinkReady(true);
        return;
      }
      default: {
        onClose();
      }
    }
  };

  const onDrawerClose = () => {
    setCurrentStep(0);
    setLinkReady(false);
    setFormData({
      service: '',
      state: '',
      insurance: '',
      member: null,
      provider: null,
    });
    onClose();
  };

  const handleSaveLink = () => {
    void copyToClipboard(schedulingLink);
    onDrawerClose();
  };

  return (
    <Drawer open={isOpen} onClose={onDrawerClose} variant={drawerType.NORMAL} className={classes.drawer}>
      <Box sx={styles.wrapper}>
        {linkReady ? (
          <Box sx={styles.linkHeader}>
            <Box sx={styles.iconContainer} onClick={handleSaveLink}>
              <Icons glyph="chevron-left" />
            </Box>
            <Text level={textLevel.L} weight={fontWeight.BOLD}>
              Copy & save your link
            </Text>
          </Box>
        ) : (
          <Box sx={styles.header}>
            <Box mb="8px">
              <Text level={textLevel.L} weight={fontWeight.BOLD}>
                Generate new link
              </Text>
            </Box>
            <Box className={classes.stepWrap}>
              <Stepper steps={createSchedulingLinkSteps} activeStep={currentStep} minusConnector />
            </Box>
            <IconButton icon="close" className={classes.closeBtn} onClick={onClose} />
          </Box>
        )}
        {linkReady ? (
          renderLink()
        ) : (
          <Box paddingBottom="80px" paddingTop="125px" ref={contentRef}>
            {renderStep(currentStep)}
          </Box>
        )}
        <Box sx={styles.footer}>
          <Button variant={btnType.TEXT} onClick={onDrawerClose}>
            Cancel
          </Button>
          <Button onClick={linkReady ? handleSaveLink : handleContinue}>
            {linkReady ? 'Save link' : 'Continue'}
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
};

const styles = {
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  header: {
    padding: '16px 8px 24px 24px',
    borderBottom: `1px solid ${colors.neutral100}`,
    position: 'fixed',
    top: 0,
    right: 0,
    width: '733px',
    zIndex: 1,
    backgroundColor: colors.white,
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'fixed',
    bottom: 0,
    right: 0,
    zIndex: 1,
    backgroundColor: colors.white,
    height: 80,
    width: '733px',
    padding: '16px',
    borderTop: `1px solid ${colors.neutral100}`,
  },
  loader: {
    height: 'calc(100vh - 158px)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  closeBtn: {
    margin: 0,
    minWidth: 'auto',
    position: 'absolute',
    right: 24,
    top: 24,
    '& svg': {
      width: 30,
    },
  },
  generalInfoContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    padding: '40px',
  },
  filterContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
  },
  linkHeader: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '24px 16px',
    gap: '24px',
    height: 80,
    borderBottom: `1px solid ${colors.neutral100}`,
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 40,
    width: 40,
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: colors.primary50,
    },
    border: `1px solid ${colors.primary300}`,
    borderRadius: '4px',
  },
  linkContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '98px',
    marginLeft: '166px',
    gap: '40px',
    width: '400px',
  },
  icon: {
    '& svg': {
      height: '20px',
      width: '20px',
    },
  },
  selectedData: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    width: '100%',
  },
  selectedDataItem: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    width: '100%',
  },
  linkBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: '8px',
    border: `1px solid ${colors.neutral200}`,
    borderRadius: '6px',
    width: '100%',
    padding: '12px',
  },
  linkText: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '20px',
    color: colors.neutral900,
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  providerInfo: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  },
  info: {
    display: 'flex',
    flexDirection: 'column',
    gap: 0,
  },
};

export { CreateSchedulingLink };
