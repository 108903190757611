import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Box, Stack } from '@mui/material';
import { sentenceCase } from 'sentence-case';

import { Breadcrumbs, colors, fontWeight, Heading, headingLevel, Text } from '../../../../../packages';

import { BaseLayout } from '../../../../../layouts/base';
import { selectPlanItemById } from '../../../../../redux/modules/conversation/selectors';
import { getElkPlanItemsDetail } from '../../../../../services/reporting/reporting.service';

import AssignmentsChart from '../../../automations/automation-detail-page/assignments-chart';
import BreakdownChart from './breakdown-chart';
import RecentAssignmentsTable from './recent-assignments-table';
import InfoCard from './info-card';

// styles
import { useStyles } from './PlanItemDetail.styles';
import {
  getFormatedPlanItemDetail,
  getFormatedRecentAssignedData,
  planItemDetailElkQuery,
  planItemRecentAssignemtColumns,
  planItemRecentAssignmentElkQuery,
} from './PlanItemDetail.constants';

const BREAKDOWN_BY_METHOD_PROGRESS_LABELS = {
  NOT_STARTED: 'Not started',
  COMPLETED: 'Completed',
  IN_PROGRESS: 'In progress',
  AUTOMATION: 'Automation',
  PRACTITIONER: 'Practitioner',
};

const PlanItemDetail = () => {
  const classes = useStyles();
  const [tableParams, setTableParams] = useState({
    search: { searchKey: '' },
    pagination: { currentPage: 1, rowsPerPage: 10 },
  });
  const [planItemDetail, setPlanItemDetail] = useState({});
  const [planItemRecentAssignemt, setPlanItemRecentAssignemt] = useState([]);
  const [planItemTotalRecentAssignemt, setPlanItemTotalRecentAssignemt] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const { planId } = useParams();
  const planItem = useSelector(selectPlanItemById(planId));
  const getRecentAssignmentDataCall = () => {
    setIsLoading(true);
    getElkPlanItemsDetail(
      planItemRecentAssignmentElkQuery(
        planId,
        tableParams.search.searchKey,
        tableParams.pagination.currentPage,
        tableParams.pagination.rowsPerPage,
      ),
      null,
    )
      .then(planItemRecentAssignmentResponse => {
        const formatedData = getFormatedRecentAssignedData(planItemRecentAssignmentResponse?.data?.hits);
        setPlanItemRecentAssignemt(formatedData);
        setPlanItemTotalRecentAssignemt(planItemRecentAssignmentResponse?.data?.hits?.total?.value || 0);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };
  useEffect(() => {
    setIsLoading(true);
    getElkPlanItemsDetail(planItemDetailElkQuery(), null)
      .then(planItemDetailResponse => {
        setPlanItemDetail(getFormatedPlanItemDetail(planItemDetailResponse.data));
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, []);
  useEffect(() => {
    getRecentAssignmentDataCall();
  }, [tableParams]);
  const getLabelText = value => {
    return BREAKDOWN_BY_METHOD_PROGRESS_LABELS[value];
  };
  return (
    <BaseLayout>
      <Box className={classes.root}>
        <Box className={classes.header}>
          <Breadcrumbs
            links={[
              { href: '/admin/data-management-plan-items', text: 'Plan Items' },
              { text: planItem?.name },
            ]}
          />
        </Box>
      </Box>
      <Box>
        <Stack direction="column" gap={1}>
          <Heading className={classes.heading} level={headingLevel.XL} weight={fontWeight.BOLD}>
            {planItem?.name}
          </Heading>
          <Text className={classes.subtitle}>Navigates to {sentenceCase(planItem.navigatesTo || '')}</Text>
        </Stack>
        <Box className={classes.content}>
          <Stack direction="row" gap={2} className={classes.infoWrap}>
            <InfoCard title="assingments - last 30 days" value={planItemDetail?.assignmentLast30Days || 0} />
            <InfoCard title="assingments - total" value={planItemDetail?.totalAssignments || 0} />
          </Stack>
          <Box className={classes.chartWrap}>
            <AssignmentsChart
              title="Assignments"
              className={classes.chart}
              data={planItemDetail?.assignments}
              isLoading={isLoading}
              chart={{
                series: [
                  {
                    name: 'Current 30 days',
                    color: colors.primary500,
                    data: planItemDetail?.assignments?.current30Days?.map(item => item.count),
                  },
                ],
                tooltipConfig: {
                  isShowTotal: false,
                  title: 'Total',
                },
              }}
            />
          </Box>
          <Box className={classes.chartWrap} display="flex" gap={1} paddingY="8px">
            <BreakdownChart
              className={classes.breakdownChart}
              title="Breakdown by method"
              chartHeight={200}
              chartWidth={450}
              chartData={[
                {
                  name: `${planItemDetail?.breakdownByMethod?.[0]?.count} ${getLabelText(
                    planItemDetail?.breakdownByMethod?.[0]?.key,
                  )}`,
                  color: colors.secondary500,
                  data: [planItemDetail?.breakdownByMethod?.[0]?.count],
                },
                {
                  name: `${planItemDetail?.breakdownByMethod?.[1]?.count} ${getLabelText(
                    planItemDetail?.breakdownByMethod?.[1]?.key,
                  )}`,
                  color: colors.primary500,
                  data: [planItemDetail?.breakdownByMethod?.[1]?.count],
                },
              ]}
            />
            <BreakdownChart
              title="Breakdown by progress"
              className={classes.breakdownChart}
              chartHeight={200}
              chartWidth={450}
              chartData={[
                {
                  name: `${planItemDetail?.breakdownByProgress?.[0]?.count} ${getLabelText(
                    planItemDetail?.breakdownByProgress?.[0]?.key,
                  )}`,
                  color: colors.success500,
                  data: [planItemDetail?.breakdownByProgress?.[0]?.count],
                },
                {
                  name: `${planItemDetail?.breakdownByProgress?.[1]?.count} ${getLabelText(
                    planItemDetail?.breakdownByProgress?.[1]?.key,
                  )}`,
                  color: colors.primary500,
                  data: [planItemDetail?.breakdownByProgress?.[1]?.count],
                },
                {
                  name: `${planItemDetail?.breakdownByProgress?.[2]?.count} ${getLabelText(
                    planItemDetail?.breakdownByProgress?.[2]?.key,
                  )}`,
                  color: colors.neutral300,
                  data: [planItemDetail?.breakdownByProgress?.[2]?.count],
                },
              ]}
            />
          </Box>
          <Box className={classes.infoWrap}>
            <Box className={classes.headerDetail}>
              <Stack direction="column">
                <Text className={classes.recentTitle} weight={fontWeight.BOLD}>
                  Recent plan item assignments
                </Text>
                <Text className={classes.headerSubtitle}>{planItem.name}</Text>
              </Stack>
            </Box>
            <RecentAssignmentsTable
              isShowAll
              data={planItemRecentAssignemt || []}
              tableColumns={planItemRecentAssignemtColumns}
              isLoading={isLoading}
              value={tableParams}
              onChange={setTableParams}
              paginationProps={{
                currentRows: 10,
                totalCount: planItemTotalRecentAssignemt,
                showRowsPerPage: true,
              }}
              searchProps={{
                placeholder: 'Search',
              }}
            />
          </Box>
        </Box>
      </Box>
    </BaseLayout>
  );
};

export { PlanItemDetail };
