import { useSelector } from 'react-redux';
import cx from 'clsx';
import { Box } from '@mui/material';
import { Avatar, avatarType } from '../../../packages/ui/atoms/avatar';
import { Button, btnType } from '../../../packages/ui/atoms/button';
import { colors } from '../../../packages/colors';

import { selectProviderSchedule } from '../../../redux/modules/schedule/selectors';
import dayjs, { getUserTimeZone } from '../../../utils/dayjs';

import Heading from '../../../components/v2/Heading';
import { HeadingLevel, HeadingWeight } from '../../../components/v2/Heading/index.types';
import BoxFrame from '../../../components/v2/BoxFrame';
import Typography from '../../../components/v2/Typography';
import { typography } from '../../../components/v2/Typography/index.constant';
import { IconButton } from '../../../packages/ui/molecules/icon-button';
import history from '../../../utils/history';

import { useStyles, durationWrapper } from './index.styles';
import { recurringAppointmentTypes } from '../../admin/appointments/add-schedule/AddSchedule.constants';
import { Icons } from '../../../packages';

const NextAppointments = ({
  appointment,
  onReschedule,
  selectAppointment,
  onStartSessionClick,
  viewDetail,
}) => {
  const classes = useStyles();
  // const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const providerSchedule = useSelector(selectProviderSchedule);
  const isRecurringAppointment = recurringAppointmentTypes?.includes(appointment?.recurringType);
  const redirectToMemberDashboard = () => {
    history.push(`/provider/members/${appointment.patientId}?name=dashboard`);
  };

  const rescheduleAppointment = () => {
    selectAppointment(appointment);
    onReschedule();
  };

  const handleStartSessionClick = () => {
    onStartSessionClick(appointment);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
      <Heading variant={HeadingLevel.SUB} fontWeight={HeadingWeight.SEMI_BOLD}>
        Next appointment today
      </Heading>
      <BoxFrame>
        <Box
          sx={{
            // p: 3,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box
            onClick={() => {
              if (viewDetail) viewDetail(appointment);
            }}
            sx={{
              p: 3,
              width: '70%',
              cursor: 'pointer',
            }}
          >
            <Heading variant={HeadingLevel.H5} fontWeight={HeadingWeight.SEMI_BOLD}>
              {dayjs(appointment?.startTime)
                .tz(providerSchedule?.timezone || getUserTimeZone())
                .format('h:mm A') || ''}
            </Heading>
          </Box>
          <Box sx={{ display: 'flex', gap: 2, p: 3 }}>
            {!appointment?.instantAppointment && (
              <IconButton
                variant={btnType.OUTLINE}
                className={cx(classes.outlineBtn, classes.xsmallBtn)}
                onClick={rescheduleAppointment}
                icon="calendar-today-outlined"
              >
                <Typography {...typography.body.m.bold}>Reschedule</Typography>
              </IconButton>
            )}
            <Button
              variant={btnType.PRIMARY}
              className={cx(classes.primaryBtn, classes.xsmallBtn)}
              onClick={handleStartSessionClick}
            >
              <Typography {...typography.body.m.bold}>Start session</Typography>
            </Button>
          </Box>
        </Box>
        <Box
          onClick={() => {
            if (viewDetail) viewDetail(appointment);
          }}
          sx={{
            p: 3,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderTop: `1px solid ${colors.neutral100}`,
            cursor: 'pointer',
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
            <Typography {...typography.body.m.semibold}>{appointment?.serviceName}</Typography>
            <Box sx={durationWrapper}>
              <Typography {...typography.body.m.medium} color={colors.neutral600}>
                {appointment?.serviceDuration} minutes session
              </Typography>
              {isRecurringAppointment && <Icons glyph="recurring" color={colors.secondary500} />}
            </Box>
          </Box>
          <Box
            onClick={() => redirectToMemberDashboard()}
            sx={{ display: 'flex', gap: 1.5, alignItems: 'center' }}
          >
            <Avatar
              size={40}
              variant={avatarType.CIRCLE}
              src={appointment?.participantImage || ''}
              name={appointment?.participantName || ''}
            />
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography {...typography.body.normal.small.semibold}>
                {`${appointment?.patientFirstName || ''} ${appointment?.patientLastName || ''}`}
              </Typography>
              <Typography {...typography.body.normal.small.medium} color={colors.neutral600}>
                {appointment?.patientUuid}
              </Typography>
            </Box>
          </Box>
        </Box>
      </BoxFrame>
    </Box>
  );
};

export default NextAppointments;
