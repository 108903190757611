import { colors } from '../../packages';
import { AvatarColor } from './Avatar.types';

export const bgColors = {
  [AvatarColor.IMAGE]: colors.neutral800,
  [AvatarColor.PRIMARY]: colors.primary600,
  [AvatarColor.SECONDARY]: colors.secondary500,
  [AvatarColor.INDIGO]: colors.indigo600,
  [AvatarColor.ORANGE]: colors.orange600,
  [AvatarColor.LIME]: colors,
  [AvatarColor.NEUTRAL]: colors.neutral800,
};
