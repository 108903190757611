/* eslint-disable max-len */
import cx from 'clsx';

// types
// styles
import { useStyles } from './shared/styles';

const Login = ({ className, color, onClick }) => {
  const classes = useStyles();
  return (
    <svg
      className={cx({
        [classes.root]: true,
        [className || '']: className,
      })}
      width="18"
      height="12"
      viewBox="0 0 18 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M18 0.5C18 0.367392 17.9473 0.240215 17.8536 0.146447C17.7598 0.0526785 17.6326 0 17.5 0H16.5C16.3674 0 16.2402 0.0526785 16.1464 0.146447C16.0527 0.240215 16 0.367392 16 0.5V5H3.33L6.77 1.56C6.81656 1.51438 6.85356 1.45992 6.87881 1.39982C6.90406 1.33972 6.91707 1.27519 6.91707 1.21C6.91707 1.14481 6.90406 1.08028 6.87881 1.02018C6.85356 0.960079 6.81656 0.905623 6.77 0.86L6.06 0.15C6.01352 0.103136 5.95822 0.0659389 5.89729 0.0405545C5.83636 0.0151702 5.77101 0.00210094 5.705 0.00210094C5.63899 0.00210094 5.57364 0.0151702 5.51271 0.0405545C5.45178 0.0659389 5.39648 0.103136 5.35 0.15L0.22 5.28C0.0811161 5.42172 0.00230401 5.61159 0 5.81V6.19C0.000175052 6.38884 0.0793075 6.57948 0.22 6.72L5.36 11.85C5.40648 11.8969 5.46178 11.9341 5.52271 11.9594C5.58364 11.9848 5.64899 11.9979 5.715 11.9979C5.78101 11.9979 5.84636 11.9848 5.90729 11.9594C5.96822 11.9341 6.02352 11.8969 6.07 11.85L6.78 11.15C6.82686 11.1035 6.86406 11.0482 6.88945 10.9873C6.91483 10.9264 6.9279 10.861 6.9279 10.795C6.9279 10.729 6.91483 10.6636 6.88945 10.6027C6.86406 10.5418 6.82686 10.4865 6.78 10.44L3.33 7H16C16.5304 7 17.0391 6.78929 17.4142 6.41421C17.7893 6.03914 18 5.53043 18 5V0.5Z"
        fill={color}
      />
    </svg>
  );
};

export default Login;
