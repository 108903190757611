import { colors } from '../../../../../../packages/colors';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  drawer: {
    '& .drawerBody': {
      padding: 0,
    },
  },
  loader: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    padding: '20px 16px',
    borderBottom: `1px solid ${colors.neutral100}`,
  },
  headerTitle: {
    display: 'block',
    fontSize: 24,
    lineHeight: '32px',
    fontWeight: 700,
  },
  subtitle: {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: '20px',
    color: colors.neutral900,
  },
  description: {
    fontSize: 14,
    fontWeight: 500,
    fontStyle: 'normal',
    lineHeight: '20px',
    color: colors.neutral600,
  },
  checkbox: {
    '& .MuiFormControlLabel-label': {
      fontSize: 14,
      fontWeight: 600,
      lineHeight: '20px',
      color: colors.neutral900,
    },
  },
  checkboxSubtitle: {
    marginLeft: 36,
    fontSize: 14,
    lineHeight: '20px',
    color: colors.neutral600,
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 80,
    padding: 16,
    borderTop: `1px solid ${colors.neutral100}`,
  },
  backBtn: {
    width: 40,
    height: 40,
    minWidth: 40,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
    height: 'calc(100vh - 170px)',
    overflowY: 'auto',
  },
  form2: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
    height: '100%',
    overflowY: 'auto',
  },
  formContent: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: 24,
    padding: 40,
    overflowY: 'auto',
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    position: 'relative',
  },
  noGap: {
    gap: 'unset',
  },
  label: {
    fontSize: 14,
    lineHeight: '20px',
    margin: 0,
  },
  addBtn: {
    fontSize: 14,
    lineHeight: '20px',
    fontWeight: 700,
    marginTop: 16,
    alignSelf: 'self-start',
    '& svg': {
      width: 18,
      marginRight: 6,
    },
  },
  removeIconBtn: {
    width: 40,
    height: 40,
    minWidth: 'unset',
    border: `1px solid ${colors.destructive300}`,
    borderRadius: 4,

    '& svg': {
      width: 20,
      height: 20,
      '& path': {
        fill: colors.destructive500,
      },
    },
  },
});
