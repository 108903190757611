import { createEntityAdapter } from '@reduxjs/toolkit';

import {
  FETCH_APPOINTMENTS_V4,
  FETCH_APPOINTMENTS_V4_FAILED,
  FETCH_APPOINTMENTS_V4_SUCCESSFUL,
  GET_APPOINTMENT_CPT_CODE,
  GET_APPOINTMENT_CPT_CODE_FAILED,
  GET_APPOINTMENT_CPT_CODE_SUCCESSFUL,
  UPDATE_APPOINTMENT_CPT_CODE,
  UPDATE_APPOINTMENT_CPT_CODE_FAILED,
  UPDATE_APPOINTMENT_CPT_CODE_SUCCESSFUL,
} from './actions';

export const appointmentDetailsAdapter = createEntityAdapter({
  selectId: details => details.appointmentId,
  sortComparer: (a, b) => (a.appointmentId > b.appointmentId ? 1 : -1),
});

export const DEFAULT = {
  isLoading: false,
  isRequesting: false,
  errorMsg: '',
  appointments: [],
  currentPage: 1,
  totalPages: 1,
  totalRecords: 0,
  services: [],
  masterSchedule: {
    isLoading: false,
    errorMsg: null,
    masterScheduleItems: [],
  },
  providerRoles: {
    isLoading: false,
    errorMsg: null,
    roles: [],
  },
  appointmentDetails: appointmentDetailsAdapter.getInitialState({ isLoading: false, errorMsg: null }),
  appointmentCptCode: {},
};

export default function reportServiceReducer(state = DEFAULT, action) {
  const { type, payload } = action;

  switch (type) {
    case FETCH_APPOINTMENTS_V4: {
      return {
        ...state,
        isLoading: true,
        errorMsg: '',
      };
    }
    case FETCH_APPOINTMENTS_V4_FAILED: {
      return {
        ...state,
        isLoading: false,
        appointments: [],
      };
    }
    case FETCH_APPOINTMENTS_V4_SUCCESSFUL: {
      return {
        ...state,
        isLoading: false,
        appointments: payload.appointments,
        currentPage: payload.currentPage,
        totalPages: payload.totalPages,
        totalRecords: payload.totalRecords,
      };
    }
    case GET_APPOINTMENT_CPT_CODE: {
      return {
        ...state,
        isLoading: true,
        errorMsg: '',
      };
    }
    case GET_APPOINTMENT_CPT_CODE_FAILED: {
      return {
        ...state,
        isLoading: false,
        appointmentCptCode: {},
      };
    }
    case GET_APPOINTMENT_CPT_CODE_SUCCESSFUL: {
      return {
        ...state,
        isLoading: false,
        appointmentCptCode: payload,
      };
    }
    case UPDATE_APPOINTMENT_CPT_CODE: {
      return {
        ...state,
        isLoading: true,
        errorMsg: '',
      };
    }
    case UPDATE_APPOINTMENT_CPT_CODE_FAILED: {
      return {
        ...state,
        isLoading: false,
        appointmentCptCode: {},
      };
    }
    case UPDATE_APPOINTMENT_CPT_CODE_SUCCESSFUL: {
      return {
        ...state,
        isLoading: false,
        appointmentCptCode: payload,
      };
    }
    default: {
      return state;
    }
  }
}
