import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import clsx from 'clsx';

import { fontWeight, Heading, headingLevel, Text, textLevel } from '../../atoms/typography';
import { Icons } from '../../../icons';
import { useStyles } from './UploadFile.styles';

const UploadFile = ({
  className = '',
  fileType = '',
  description = '',
  onAcceptedFiles,
  onFailedFiles = null,
  ...rest
}) => {
  const classes = useStyles();

  const onDropCallback = useCallback(acceptedFiles => {
    onAcceptedFiles(acceptedFiles);
  }, []);

  const onDropFailedCallback = useCallback(failedFiles => {
    if (onFailedFiles) {
      onFailedFiles(failedFiles);
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop: onDropCallback,
    onDropRejected: onDropFailedCallback,
    noClick: true,
    accept: fileType,
    ...rest,
  });

  return (
    <div
      className={clsx(
        classes.root,
        { [classes.dragging]: isDragActive, [classes.disabled]: rest.disabled },
        className,
      )}
      id="file-drop-zone"
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      {isDragActive ? (
        <Text className={classes.filesize} level={textLevel.S}>
          Drop files here to upload
        </Text>
      ) : (
        <div className={classes.content}>
          {rest.iconAltered && <Icons glyph="upload-file-outlined" className={classes.icon} />}
          {!rest.iconAltered && <Icons glyph="add-image-outlined" className={classes.icon} />}
          <Heading level={headingLevel.M} className={classes.uploadText} weight={fontWeight.MEDIUM}>
            <span onClick={open}>Upload a file</span> or drag and drop
          </Heading>
          <Text className={classes.filesize} level={textLevel.S}>
            {description || 'File size up to 200MB'}
          </Text>
        </div>
      )}
    </div>
  );
};

export { UploadFile };
