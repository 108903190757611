/* eslint-disable max-len */
import cx from 'clsx';

// types
// styles
import { useStyles } from './shared/styles';

const RatingFilled = ({ className, color, onClick }) => {
  const classes = useStyles();
  return (
    <svg
      className={cx({
        [classes.root]: true,
        [className || '']: className,
      })}
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M1.17999 8.85994C1.09749 8.79988 1.03581 8.71557 1.00354 8.61876C0.97127 8.52195 0.970029 8.41749 0.999989 8.31994L1.07999 8.06994C1.10854 7.9735 1.16608 7.88818 1.2448 7.82556C1.32351 7.76294 1.41959 7.72607 1.51999 7.71994L7.90999 7.20994L10.36 1.31994C10.4 1.21939 10.471 1.13418 10.5627 1.07666C10.6544 1.01913 10.762 0.992261 10.87 0.999944H11.13C11.2319 0.997782 11.3319 1.02744 11.4162 1.0848C11.5004 1.14216 11.5647 1.22435 11.6 1.31994L14.06 7.20994L20.45 7.71994C20.5504 7.72607 20.6465 7.76294 20.7252 7.82556C20.8039 7.88818 20.8614 7.9735 20.89 8.06994L20.97 8.31994C21.0032 8.41449 21.0066 8.51695 20.9798 8.61351C20.953 8.71006 20.8972 8.79607 20.82 8.85994L16 12.9999L17.48 19.2099C17.5038 19.3072 17.4985 19.4093 17.4648 19.5035C17.431 19.5978 17.3702 19.68 17.29 19.7399L17 19.8999C16.9173 19.9558 16.8198 19.9857 16.72 19.9857C16.6202 19.9857 16.5227 19.9558 16.44 19.8999L11 16.5999L5.52999 19.9299C5.44731 19.9858 5.34979 20.0157 5.24999 20.0157C5.15019 20.0157 5.05267 19.9858 4.96999 19.9299L4.74999 19.7799C4.66979 19.72 4.60901 19.6378 4.57522 19.5435C4.54144 19.4493 4.53614 19.3472 4.55999 19.2499L5.99999 12.9999L1.17999 8.85994Z"
        fill={color}
      />
    </svg>
  );
};

export default RatingFilled;
