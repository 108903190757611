import { REPORT_FILTERS } from '../../../../../constants/CommonConstants';
import { ReportFilterRule } from '../../../../../redux/modules/reporting/types';
import {
  getReportAllFiltersSubQuery,
  getReportAllFiltersSubQueryReferralPartner,
  getReportFilterSubQuery,
  REPORT_CONSTANTS,
} from '../../ReportDetail.constants';

export const mostAppointmentsColumns = [
  { id: 'member', label: 'Member', sortable: false },
  { id: 'total', label: 'Total', sortable: false, className: 'color-secondary-500' },
  { id: 'lastUpdated', label: 'Last', sortable: false },
  { id: 'actions', label: '', sortable: false },
];

export const mostRecentAppointmentsColumns = [
  { id: 'member', label: 'Member', sortable: false },
  { id: 'provider', label: 'Provider', sortable: false },
  { id: 'daysSince', label: 'Days since', sortable: false, className: 'color-secondary-500' },
  { id: 'createdDay', label: 'Date', sortable: false },
  { id: 'actions', label: '', sortable: false },
];

export const getAppointmentReportQuery = report => {
  let allFilters = JSON.parse(JSON.stringify(report.reportFilters));
  if (report?.reportFilters?.length || report?.referralPartnerId) {
    const allInsuranceFilters = report.reportFilters?.filter(
      filter => filter.type === REPORT_FILTERS.INSURANCE,
    );
    if (allInsuranceFilters?.length) {
      allFilters = [
        ...report.reportFilters?.filter(filter => filter.type !== REPORT_FILTERS.INSURANCE),
        {
          type: REPORT_FILTERS.INSURANCE,
          insurance: {
            carriers: allInsuranceFilters?.map(filter => filter.insurance?.carriers)?.flat(),
            state: '',
          },
          value: '',
          profileElement: '',
          states: [],
          levelOfEngagements: [],
          providers: [],
          rule: ReportFilterRule.EQUALS,
        },
      ];
    }
    return {
      size: 0,
      query: {
        bool: {
          filter: [
            {
              terms: {
                'status.keyword': ['FULFILLED', 'NO_SHOW', 'CANCELLED'],
              },
            },
            ...(report?.data?.appointmentsSection?.serviceTypeIds?.serviceTypeIds?.length
              ? [
                  {
                    terms: {
                      'serviceTypes.keyword': report.data.appointmentsSection.serviceTypeIds.serviceTypeIds,
                    },
                  },
                ]
              : []),
          ],
          must: [
            ...allFilters?.map(filter => filter.profileElement && getReportFilterSubQuery(filter)),
            ...allFilters?.map(filter => getReportAllFiltersSubQuery(filter)),
            ...getReportAllFiltersSubQueryReferralPartner(report?.referralPartnerId),
          ]?.filter(obj => Object.keys(obj)?.length > 0),
        },
      },
      aggs: {
        total_appointments: {
          value_count: {
            field: 'mongo_id.keyword',
          },
        },
        total_members: {
          cardinality: {
            field: 'patientId.keyword',
          },
        },
        by_status: {
          terms: {
            field: 'status.keyword',
            size: 10,
          },
        },
        last_30_days: {
          filter: {
            range: {
              'period.start': {
                gte: 'now-30d/d',
                lte: 'now/d',
              },
            },
          },
          aggs: {
            count: {
              date_histogram: {
                field: 'period.start',
                calendar_interval: '1d',
              },
            },
          },
        },
        past_30_days: {
          filter: {
            range: {
              'period.start': {
                gte: 'now-60d/d',
                lt: 'now-30d/d',
              },
            },
          },
          aggs: {
            counts: {
              date_histogram: {
                field: 'period.start',
                calendar_interval: '1d',
              },
            },
          },
        },
        most_no_shows: {
          filter: {
            term: {
              'status.keyword': 'NO_SHOW',
            },
          },
          aggs: {
            most_appointment: {
              terms: {
                field: 'patientId.keyword',
                size: REPORT_CONSTANTS.MAX_RECORDS_IN_QUERY,
              },
              aggs: {
                latest_appointment: {
                  top_hits: {
                    sort: [
                      {
                        lastModified: {
                          order: 'desc',
                        },
                      },
                    ],
                    size: 1,
                    _source: [
                      'first_name',
                      'last_name',
                      'nick_name',
                      'lastModified',
                      'status',
                      'user_account_id',
                    ],
                  },
                },
              },
            },
          },
        },

        most_appointments: {
          filter: {
            term: {
              'status.keyword': 'FULFILLED',
            },
          },
          aggs: {
            most_appointment: {
              terms: {
                field: 'patientId.keyword',
                size: REPORT_CONSTANTS.MAX_RECORDS_IN_QUERY,
              },
              aggs: {
                latest_appointment: {
                  top_hits: {
                    sort: [
                      {
                        lastModified: {
                          order: 'desc',
                        },
                      },
                    ],
                    size: 1,
                    _source: [
                      'first_name',
                      'last_name',
                      'nick_name',
                      'lastModified',
                      'status',
                      'user_account_id',
                    ],
                  },
                },
              },
            },
          },
        },

        most_recent_appointments: {
          top_hits: {
            sort: [
              {
                lastModified: {
                  order: 'desc',
                },
              },
            ],
            size: REPORT_CONSTANTS.MAX_RECORDS_IN_QUERY,
            _source: [
              'first_name',
              'last_name',
              'nick_name',
              'status',
              'lastModified',
              'providerName',
              'user_account_id',
              'providerId',
            ],
          },
        },
      },
    };
  }
  return {
    size: 0,
    query: {
      bool: {
        filter: [
          {
            terms: {
              'status.keyword': ['FULFILLED', 'NO_SHOW', 'CANCELLED'],
            },
          },
          ...(report?.data?.appointmentsSection?.serviceTypeIds?.serviceTypeIds?.length
            ? [
                {
                  terms: {
                    'serviceTypes.keyword': report.data.appointmentsSection.serviceTypeIds.serviceTypeIds,
                  },
                },
              ]
            : []),
        ],
        must: [],
      },
    },
    aggs: {
      total_appointments: {
        value_count: {
          field: 'mongo_id.keyword',
        },
      },
      total_members: {
        cardinality: {
          field: 'patientId.keyword',
        },
      },
      by_status: {
        terms: {
          field: 'status.keyword',
          size: 10,
        },
      },
      last_30_days: {
        filter: {
          range: {
            'period.start': {
              gte: 'now-30d/d',
              lte: 'now/d',
            },
          },
        },
        aggs: {
          count: {
            date_histogram: {
              field: 'period.start',
              calendar_interval: '1d',
            },
          },
        },
      },
      past_30_days: {
        filter: {
          range: {
            'period.start': {
              gte: 'now-60d/d',
              lt: 'now-30d/d',
            },
          },
        },
        aggs: {
          counts: {
            date_histogram: {
              field: 'period.start',
              calendar_interval: '1d',
            },
          },
        },
      },
      most_no_shows: {
        filter: {
          term: {
            'status.keyword': 'NO_SHOW',
          },
        },
        aggs: {
          most_appointment: {
            terms: {
              field: 'patientId.keyword',
              size: REPORT_CONSTANTS.MAX_RECORDS_IN_QUERY,
            },
            aggs: {
              latest_appointment: {
                top_hits: {
                  sort: [
                    {
                      lastModified: {
                        order: 'desc',
                      },
                    },
                  ],
                  size: 1,
                  _source: [
                    'first_name',
                    'last_name',
                    'nick_name',
                    'lastModified',
                    'status',
                    'user_account_id',
                  ],
                },
              },
            },
          },
        },
      },

      most_appointments: {
        filter: {
          term: {
            'status.keyword': 'FULFILLED',
          },
        },
        aggs: {
          most_appointment: {
            terms: {
              field: 'patientId.keyword',
              size: REPORT_CONSTANTS.MAX_RECORDS_IN_QUERY,
            },
            aggs: {
              latest_appointment: {
                top_hits: {
                  sort: [
                    {
                      lastModified: {
                        order: 'desc',
                      },
                    },
                  ],
                  size: 1,
                  _source: [
                    'first_name',
                    'last_name',
                    'nick_name',
                    'lastModified',
                    'status',
                    'user_account_id',
                  ],
                },
              },
            },
          },
        },
      },

      most_recent_appointments: {
        top_hits: {
          sort: [
            {
              lastModified: {
                order: 'desc',
              },
            },
          ],
          size: REPORT_CONSTANTS.MAX_RECORDS_IN_QUERY,
          _source: [
            'first_name',
            'last_name',
            'nick_name',
            'status',
            'lastModified',
            'providerName',
            'user_account_id',
            'providerId',
          ],
        },
      },
    },
  };
};

export const appointmentFeedbackQuery = {
  size: 0,
  query: {
    match_all: {},
  },
  aggs: {
    avg_rating: {
      avg: {
        field: 'rating',
      },
    },
    review_count: {
      filter: { exists: { field: 'publicComment' } },
    },
  },
};
