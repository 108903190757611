/* eslint-disable no-underscore-dangle */
import { boxType } from '../../../../../packages/ui/templates/dashboard-box';
import { diffDays, toDateString } from '../../../../../utils/CommonUtils';

const dashboardBoxDataMap = {
  'total appointments': 'totalAppointments',
  'total members with appointments': 'totalMembers',
  'total rating': 'totalRating',
  'completed appointments': 'completedAppointments',
  'canceled appointments': 'canceledAppointments',
  'no shows': 'noShows',
};

export const getDashboardBoxData = (appointmentReport, feedbackData, report) => {
  if (!appointmentReport || !feedbackData) return [];
  const boxData = [
    {
      label: 'total appointments',
      value: appointmentReport.hits?.total?.value,
      type: boxType.TOTAL,
    },
    {
      label: 'total members with appointments',
      value: appointmentReport.aggregations.total_members.value,
      type: boxType.CONNECTIONS,
      badge: '20 active',
    },
    {
      label: 'total rating',
      value: feedbackData.aggregations?.avg_rating?.value?.toFixed(2) || 0,
      type: boxType.RATING,
      badge: `${feedbackData.aggregations.review_count.doc_count} reviews`,
    },
    {
      label: 'completed appointments',
      value:
        appointmentReport.aggregations.by_status.buckets.find(bucket => bucket.key === 'FULFILLED')
          ?.doc_count ?? 0,
      type: boxType.COMPLETED,
      badge: `${Math.round(
        ((appointmentReport.aggregations.by_status.buckets.find(bucket => bucket.key === 'FULFILLED')
          ?.doc_count ?? 0) /
          appointmentReport.hits.total.value) *
          100,
      )}%`,
    },
    {
      label: 'canceled appointments',
      value:
        appointmentReport.aggregations.by_status.buckets.find(bucket => bucket.key === 'CANCELLED')
          ?.doc_count ?? 0,
      type: boxType.CANCELED,
      badge: `${Math.round(
        ((appointmentReport.aggregations.by_status.buckets.find(bucket => bucket.key === 'CANCELLED')
          ?.doc_count ?? 0) /
          appointmentReport.hits.total.value) *
          100,
      )}%`,
    },
    {
      label: 'no shows',
      value:
        appointmentReport.aggregations.by_status.buckets.find(bucket => bucket.key === 'NO_SHOW')
          ?.doc_count ?? 0,
      type: boxType.NO_SHOW,
      badge: `${Math.round(
        ((appointmentReport.aggregations.by_status.buckets.find(bucket => bucket.key === 'NO_SHOW')
          ?.doc_count ?? 0) /
          appointmentReport.hits.total.value) *
          100,
      )}%`,
    },
  ];

  return (
    boxData.filter(item => report?.data?.appointmentsSection?.[dashboardBoxDataMap[item.label]]?.isChecked) ??
    []
  );
};

export const getMostRecentAppointments = appointmentReport => {
  return appointmentReport
    ? appointmentReport.aggregations.most_recent_appointments.hits.hits.map(appointment => {
        return {
          id: appointment._id,
          member: {
            name: appointment._source.nick_name || '',
            fullname: `${appointment._source.first_name || ''} ${appointment._source.last_name || ''}`,
            memberId: appointment._source.user_account_id,
            // thumbnail: 'https://i.pravatar.cc/300',
          },
          provider: {
            name: appointment._source.providerName,
            role: 'Provider',
            providerId: appointment._source.providerId,
            // thumbnail: 'https://i.pravatar.cc/300',
          },
          total: 12,
          daysSince: diffDays(appointment._source.lastModified, new Date()),
          createdDay: toDateString(appointment._source.lastModified),
          lastUpdated: 'unknown',
        };
      })
    : [];
};

export const getMostAppointments = appointmentReport => {
  return appointmentReport
    ? appointmentReport.aggregations.most_appointments.most_appointment?.buckets.map(appointment => ({
        member: {
          fullname: `${appointment.latest_appointment.hits.hits[0]?._source.first_name || ''} ${
            appointment.latest_appointment.hits.hits[0]?._source.last_name || ''
          }`,
          name: appointment.latest_appointment.hits.hits[0]?._source.nick_name || '',
          memberId: appointment.latest_appointment.hits.hits[0]?._source.user_account_id,
        }, // need to replace with member name
        total: appointment.doc_count,
        lastUpdated: toDateString(appointment.latest_appointment.hits.hits[0]?._source.lastModified),
      }))
    : [];
};

export const getMostNoShows = appointmentReport =>
  appointmentReport
    ? appointmentReport.aggregations.most_no_shows.most_appointment?.buckets.map(appointment => ({
        member: {
          fullname: `${appointment.latest_appointment.hits.hits[0]?._source.first_name || ''} ${
            appointment.latest_appointment.hits.hits[0]?._source.last_name || ''
          }`,
          name: appointment.latest_appointment.hits.hits[0]?._source.nick_name || '',
          memberId: appointment.latest_appointment.hits.hits[0]?._source.user_account_id,
        },
        total: appointment.doc_count,
        lastUpdated: toDateString(appointment?.latest_appointment.hits.hits[0]?._source.lastModified),
      }))
    : [];

export const getLast30DaysCounts = appointmentReport => {
  return appointmentReport
    ? appointmentReport.aggregations.last_30_days.count.buckets.map(bucket => bucket.doc_count).slice(0, 30)
    : [];
};

export const getPast30DaysCounts = appointmentReport =>
  appointmentReport
    ? appointmentReport.aggregations.past_30_days.counts.buckets.map(bucket => bucket.doc_count).slice(0, 30)
    : [];
