import { colors } from '../../../../packages';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  drawer: {
    '& .drawerBody': {
      padding: 0,
    },
  },
  chip: {
    width: '20%',
    marginRight: '4px',
  },
  wrapper: {
    flex: 1,
    justifyItems: 'space-between',
    flexDirection: 'column',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 60,
    padding: '15px 10px',
    borderBottom: `1px solid ${colors.neutral100}`,
  },
  headerTitle: {
    fontSize: 24,
    lineHeight: '32px',
    fontWeight: 700,
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 80,
    padding: 16,
    borderTop: `1px solid ${colors.neutral100}`,
  },
  backBtn: {
    width: 40,
    height: 40,
    minWidth: 40,
  },
  form: {
    flex: 1,
  },
  formContent: {
    flex: 1,
    display: 'flex',
    height: 'calc(100vh - 160px)',
    overflowY: 'auto',
    flexDirection: 'column',
    gap: 24,
    padding: 40,
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    gap: 5,
    position: 'relative',
  },
  label: {
    fontSize: 14,
    lineHeight: '20px',
    margin: 0,
  },
  importanceLevel: {
    margin: 0,
  },
  addBtn: {
    color: '#fff',
    fontSize: 16,
    whiteSpace: 'nowrap',
    '& svg': {
      width: 20,
      marginRight: 4,
      '& path': {
        fill: '#fff',
      },
    },
  },
  badge: {
    fontSize: 14,
    lineHeight: '20px',
    border: 'none',
    '&.Critical': {
      backgroundColor: colors.destructive50,
      color: colors.destructive600,
    },
    '&.High': {
      backgroundColor: '#FEF6EE',
      color: '#E04F16',
    },
    '&.Medium': {
      backgroundColor: '#FFFBEB',
      color: '#D97706',
    },
    '&.Misreported': {
      backgroundColor: '#FFF1F8',
      color: '#DD0374',
    },
  },
  arrowRight: {
    width: 40,
  },
  btnFooter: {
    height: 40,
    fontSize: 14,
  },
  removeBtn: {
    color: colors.destructive500,
  },
  headerBorder: {
    borderBottom: `1px solid ${colors.neutral100}`,
  },
  headTitle: {
    fontSize: 18,
    lineHeight: '24px',
    fontWeight: 600,
  },
  loader: {
    height: 'calc(100vh - 158px)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});
