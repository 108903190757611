import { useState } from 'react';
import clsx from 'clsx';
import { Box, Collapse, Stack } from '@mui/material';
import { colors, fontWeight, IconButton, Icons, Text } from '../../../../../../packages';
import { useStyles } from './FlowItem.styles';

const FlowItem = ({ item }) => {
  const classes = useStyles();
  const [showDetail, setShowDetail] = useState(false);

  const toggleDetail = () => {
    if (item.choices) {
      setShowDetail(!showDetail);
    }
  };

  const onConnectClick = () => {
    console.log('connect');
  };

  return (
    <Box className={classes.root}>
      <Box
        className={clsx(classes.top, { [classes.topClickable]: !!item.choices && showDetail })}
        onClick={toggleDetail}
      >
        <Box flexDirection="row" display="flex" gap={1.5} flex={0.3}>
          <Icons glyph={item.icon} color={colors.neutral900} />
          <Text className={classes.title} weight={fontWeight.MEDIUM}>
            {item.name}
          </Text>
        </Box>
        <Stack direction="column" gap={0.5} justifyContent="center" flex={0.7}>
          <Text className={classes.title} weight={fontWeight.MEDIUM}>
            {item.title}
          </Text>
          {item.description && (
            <Text className={clsx(classes.title, classes.subtitle)} weight={fontWeight.MEDIUM}>
              {item.description}
            </Text>
          )}
        </Stack>
        <Box>
          {item.link ? (
            <IconButton icon="connect" onClick={onConnectClick} className={classes.linkBtn} />
          ) : (
            <>
              {item.choices && (
                <IconButton
                  icon="chevron-right"
                  onClick={onConnectClick}
                  className={clsx(classes.arrowBtn, { [classes.arrowBtnRotate]: showDetail })}
                />
              )}
            </>
          )}
        </Box>
      </Box>
      {item.choices && (
        <Collapse in={showDetail}>
          <Box className={classes.detail}>
            {item.choices.map((child, idx) => (
              <Stack direction="row" key={idx} justifyContent="space-between" className={classes.childItem}>
                <Text className={clsx(classes.title)} weight={fontWeight.MEDIUM}>
                  {child.name}
                </Text>
                <Text className={clsx(classes.title)} weight={fontWeight.MEDIUM}>
                  Score: {child.score}
                </Text>
              </Stack>
            ))}
          </Box>
        </Collapse>
      )}
    </Box>
  );
};

export { FlowItem };
