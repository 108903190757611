/* eslint-disable max-len */
import cx from 'clsx';
// types
// styles
import { useStyles } from './shared/styles';

const Star = ({ className, color, onClick }) => {
  const classes = useStyles();

  return (
    <svg
      className={cx(classes.root, className)}
      onClick={onClick}
      width="24"
      height="23"
      viewBox="0 0 24 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.9999 0.5L16.2259 6.683L23.4129 8.792L18.8379 14.722L19.0529 22.208L11.9999 19.69L4.94691 22.208L5.16191 14.722L0.586914 8.792L7.77391 6.683L11.9999 0.5ZM11.9999 4.044L9.02191 8.402L3.95691 9.887L7.18191 14.065L7.02891 19.34L11.9999 17.566L16.9699 19.34L16.8179 14.065L20.0419 9.887L14.9779 8.402L11.9999 4.044ZM9.99991 12C9.99991 12.5304 10.2106 13.0391 10.5857 13.4142C10.9608 13.7893 11.4695 14 11.9999 14C12.5303 14 13.0391 13.7893 13.4141 13.4142C13.7892 13.0391 13.9999 12.5304 13.9999 12H15.9999C15.9999 13.0609 15.5785 14.0783 14.8283 14.8284C14.0782 15.5786 13.0608 16 11.9999 16C10.939 16 9.92163 15.5786 9.17149 14.8284C8.42134 14.0783 7.99991 13.0609 7.99991 12H9.99991Z"
        fill={color}
      />
    </svg>
  );
};

export default Star;
