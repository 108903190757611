import { makeStyles } from '@mui/styles';

// colors
import { colors } from '../../../../colors';

export const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  mainContent: ({withBanner}) => ({
    flexGrow: 1,
    backgroundColor: colors.indigo50,
    maxHeight: withBanner ? 'calc(100vh - 271px)' : 'calc(100vh - 177px)',
    overflowY: 'auto',
  }),
  topIconsContainer: {
    padding: '16px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  animatedIconMini: {
    width: '48px',
    height: '48px',
  },
  topBanner: {
    display: 'flex',
    padding: '16px 24px',
    gap: '16px',
    alignItems: 'center',
    backgroundColor: colors.white,
    marginBottom: '16px',
  },
  topBannerContent: {
    display: 'flex',
    gap: '4px',
    flexDirection: 'column',
    flex: 1,
  },
  evaluationTitle: {
    fontSize: '18px',
    fontWeight: 600,
    lineHeight: '24px',
    fontFamily: 'Manrope',
    color: colors.neutral900,
    margin: 0,
  },
  evaluationSubtitle: {
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '20px',
    fontFamily: 'Manrope',
    color: colors.neutral600,
    margin: 0,
    textTransform: 'capitalize',
  },
  topIcon: {
    height: '40px',
    padding: 0,
    minWidth: '24px',
    maxWidth: '24px',
    width: '24px',
  },
  topIconInactive: {
    color: `${colors.neutral300} !important`,
  },
  footer: {
    padding: '24px 16px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderTop: `1px solid ${colors.neutral100}`,
  },
  textLink: {
    padding: 0,
    height: '19px',
    fontSize: '14px',
    fontWeight: 600,
  },
  nextBtn: {
    height: '44px',
    padding: '12px',
    fontSize: '14px',
    fontWeight: 700,
    borderRadius: '4px',
    lineHeight: '20px',
    '& svg path': {
      fill: colors.white,
    },
  },
  signoffBtn: {
    height: '44px',
    padding: '12px',
    fontSize: '14px',
    color: colors.success600,
    fontWeight: 700,
    borderRadius: '4px',
    lineHeight: '20px',
    '& svg path': {
      fill: colors.success600,
    },
    border: `1px solid ${colors.green100}`,
    '&:hover': {
      backgroundColor: colors.green50,
    }
  },
  bookAppointmentBanner: {
    display: 'flex',
    padding: '24px 16px',
    justifyContent: 'space-between',
    alignItems: 'center',
    alignSelf: 'stretch',
    borderTop: `1px solid ${colors.neutral100}`,
    background: colors.white,
  },
  nextApptContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    flex: '1 0 0'
  },
  nextApptTitle: {
    color: colors.neutral900,
    fontFamily: 'Manrope',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '24px',
    margin: 0,
  },
  nextApptTime: {
    color: colors.neutral600,
    fontFamily: 'Manrope',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '20px',
    margin: 0,
  },
  actionBtn: {
    padding: 0,
    borderRadius: '4px',
    minWidth: '40px !important',
    width: '40px !important',
    height: '40px !important',
    '& svg': {
      width: '16px',
      height: '16px',
    }
  },
  apptBtn: {
    border: `1px solid ${colors.green500}`,
    color: colors.green500,
    '& svg': {
      '& path': {
        fill: colors.green500,
      },
    },
    '&:hover': {
      backgroundColor: colors.green50,
    }
  },
  danger: {
    color: colors.destructive600,
  },
  endBtn: {
    height: '44px',
    display: 'flex',
    padding: '12px',
    alignItems: 'center',
    gap: '4px',
    borderRadius: '4px',
    border: `1px solid ${colors.destructive100}`,
    color: colors.destructive600,
    backgroundColor: colors.destructive50,
    '&:hover': {
      backgroundColor: colors.destructive600,
      color: colors.white,
    },
  },
  prevBtn: {
    height: '44px',
    display: 'flex',
    padding: '12px',
    alignItems: 'center',
    gap: '4px',
    borderRadius: '4px',
    border: `1px solid ${colors.neutral300}`,
    background: colors.white,
    color: colors.neutral800,
    textAlign: 'right',
    fontFamily: 'Manrope',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '20px',
    '&:hover': {
      backgroundColor: colors.white,
    },
    '& svg path': {
      fill: colors.neutral800,
    },
    '& .MuiButton-endIcon': {
      marginLeft: 0
    }
  }
});
