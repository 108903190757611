// import { makeStyles } from '@mui/styles';

// // colors
// import { colors } from '../../../colors';

// export const useStyles = makeStyles({
//   iconWrapper: {
//     height: 64,
//     width: 64,
//     borderRadius: 4,
//     border: '1px solid',
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'center  ',
//     backgroundColor: colors.indigo50,
//     borderColor: colors.indigo200,
//     '& svg path': {
//       fill: colors.indigo500,
//     },
//   },
//   status: {
//     borderRadius: 100,
//     minWidth: 'auto',
//     fontSize: 14,
//     height: 30,
//   },
//   statusBadge: {
//     margin: '0 0 24px 88px',
//   },
//   row: {
//     display: 'flex',
//     flexDirection: 'row',
//     justifyContent: 'space-between',
//     alignItems: 'center',
//     gap: 24,
//   },
//   row1: {
//     display: 'flex',
//     flexDirection: 'row',
//     justifyContent: 'space-between',
//     alignItems: 'center',
//   },
//   root: {
//     '& 	.MuiBackdrop-root': {
//       backgroundColor: `${colors.neutral700}59`,
//     },
//   },
//   formRoot: {
//     '&  .MuiDrawer-paper': {
//       display: 'flex',
//       flexDirection: 'column',
//       justifyContent: 'space-between',
//       alignItems: 'stretch',
//     },
//   },
//   vertical: {
//     '& .MuiDrawer-paper': {
//       boxShadow: 'none',
//       width: '90%',
//       maxWidth: 590,
//       height: '100%',
//     },
//   },
//   verticalNarrow: {
//     '& .MuiDrawer-paper': {
//       boxShadow: 'none',
//       width: '100%',
//       maxWidth: 320,
//       height: '100%',
//     },
//   },
//   horizontal: {
//     '& .MuiDrawer-paper': {
//       boxShadow: 'none',
//       width: '100%',
//       maxHeight: 390,
//       height: '90%',
//     },
//   },

//   drawerHeader: {
//     display: 'flex',
//     flexDirection: 'row',
//     justifyContent: 'space-between',
//     alignItems: 'center',
//     borderBottom: `1px solid ${colors.neutral100}`,
//     height: 80,
//     padding: 24,
//   },
//   bigDrawerHeader: {
//     height: 108,
//   },
//   biggerDrawerHeader: {
//     height: 152,
//   },
//   drawerFooter: {
//     display: 'flex',
//     flexDirection: 'row',
//     justifyContent: 'space-between',
//     alignItems: 'center',
//     borderTop: `1px solid ${colors.neutral100}`,
//     height: 80,
//     padding: 24,
//   },
//   closeIcon: {
//     width: 24,
//     height: 24,
//     color: colors.primary500,
//     cursor: 'pointer',
//   },
//   drawerCancelBtn: {
//     padding: 0,
//     color: colors.primary600,
//     fontSize: 14,
//     fontWeight: 700,
//     minWidth: 48,
//     height: 48,
//   },
//   drawerUpdateBtn: {
//     height: 48,
//     padding: '12px 20px ',
//     minWidth: 97,
//   },
//   drawerBody: {
//     padding: 40,
//   },
//   headSubTitle: {
//     marginTop: 4,
//     lineHeight: '24px',
//     color: colors.neutral600,
//     display: 'inline-block',
//   },
//   headTitle: {
//     margin: 0,
//     lineHeight: '32px',
//     color: colors.neutral900,
//   },
//   headTitleSmall: {
//     margin: 0,
//     lineHeight: '32px',
//     color: colors.neutral900,
//     fontSize: '18px',
//   },
//   topContentWrapper: {
//     display: 'flex',
//     flexDirection: 'column',
//     alignItems: 'flex-start',
//     marginTop: 24,
//   },
//   topContentWrapper1: {
//     display: 'flex',
//     flexDirection: 'column',
//     alignItems: 'flex-start',
//   },
//   topContentWrapperfortitle: {
//     display: 'flex',
//     flexDirection: 'column',
//     alignItems: 'flex-start',
//   },
//   subtitleComponent: {
//     marginTop: 16,
//   },
//   narrowHeader: {
//     display: 'flex',
//     flexDirection: 'column',
//     alignItems: 'flex-start',
//   },
//   textElipsis: {
//     whiteSpace: 'nowrap',
//     overflow: 'hidden',
//     textOverflow: 'ellipsis',
//   },
// });

import { makeStyles } from '@mui/styles';

// colors
import { colors } from '../../../colors';

export const useStyles = makeStyles({
  iconWrapper: {
    height: 64,
    width: 64,
    borderRadius: 4,
    border: '1px solid',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center  ',
    backgroundColor: colors.indigo50,
    borderColor: colors.indigo200,
    '& svg path': {
      fill: colors.indigo500,
    },
  },
  status: {
    borderRadius: 100,
    minWidth: 'auto',
    fontSize: 14,
    height: 30,
    color: 'white', // Change text color to white
    backgroundColor: colors.neutral500, // Set background to gray
  },
  statusBadge: {
    margin: '0 0 24px 88px',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 24,
  },
  row1: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  root: {
    '& 	.MuiBackdrop-root': {
      backgroundColor: `${colors.neutral700}59`,
    },
  },
  formRoot: {
    '&  .MuiDrawer-paper': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'stretch',
    },
  },
  vertical: {
    '& .MuiDrawer-paper': {
      boxShadow: 'none',
      width: '90%',
      maxWidth: 590,
      height: '100%',
    },
  },
  verticalNarrow: {
    '& .MuiDrawer-paper': {
      boxShadow: 'none',
      width: '100%',
      maxWidth: 320,
      height: '100%',
    },
  },
  horizontal: {
    '& .MuiDrawer-paper': {
      boxShadow: 'none',
      width: '100%',
      maxHeight: 390,
      height: '90%',
    },
  },
  drawerHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: `1px solid ${colors.neutral100}`,
    height: 80,
    padding: 24,
  },
  stickyHeader: {
    top: 0,
    position: 'sticky',
    background: 'white',
    zIndex: 1,
  },
  bigDrawerHeader: {
    height: 108,
  },
  biggerDrawerHeader: {
    height: 152,
  },
  drawerFooter: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderTop: `1px solid ${colors.neutral100}`,
    height: 80,
    padding: 24,
  },
  closeIcon: {
    width: 24,
    height: 24,
    color: colors.primary500,
    cursor: 'pointer',
  },
  drawerCancelBtn: {
    padding: 0,
    color: colors.primary, // Change to blue color for the text
    fontSize: 14,
    fontWeight: 700,
    minWidth: 48,
    height: 48,
    backgroundColor: colors.white, // Set background to white
  },
  drawerUpdateBtn: {
    height: 48,
    padding: '12px 20px ',
    minWidth: 97,
  },
  drawerBody: {
    padding: 40,
  },
  headSubTitle: {
    marginTop: 4,
    lineHeight: '24px',
    color: colors.neutral600,
    display: 'inline-block',
  },
  headTitle: {
    margin: 0,
    lineHeight: '32px',
    color: colors.neutral900,
  },
  headTitleSmall: {
    margin: 0,
    lineHeight: '32px',
    color: colors.neutral900,
    fontSize: '18px',
  },
  topContentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginTop: 24,
  },
  topContentWrapper1: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  topContentWrapperfortitle: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  subtitleComponent: {
    marginTop: 16,
  },
  narrowHeader: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  textElipsis: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});
