/* eslint-disable no-nested-ternary */
import { useCallback, useEffect } from 'react';
import { Box } from '@mui/material';

import ReportFormCollapseMedications from '../report-form-collapse/ReportFormCollapseMedications';

import { reportItemProgress } from '../AddNewReport.constants';
import { useStyles } from './StepTwo.styles';

const StepTwoMedications = ({
  showInterference,
  selectedItems,
  onRemoveItem,
  updateItems,
  lookupData,
  memberId,
  options,
}) => {
  const classes = useStyles();

  useEffect(() => {
    updateItems(
      selectedItems.map((symp, index) => ({
        ...symp,
        progress: !index ? reportItemProgress.InProgress : reportItemProgress.Incomplete,
      })),
    );
  }, []);

  const onUpdateItem = useCallback(
    medication => {
      const nextIndex = selectedItems.findIndex(
        item =>
          item.item.value !== medication.item.value &&
          item.progress !== reportItemProgress.Completed &&
          item.notes.trim().length === 0,
      );
      updateItems(
        selectedItems.map((selectedItem, index) =>
          selectedItem.item.value === medication.item.value
            ? { ...medication, progress: reportItemProgress.Completed }
            : index === nextIndex
            ? { ...selectedItem, progress: reportItemProgress.InProgress }
            : selectedItem,
        ),
      );
    },
    [selectedItems, updateItems],
  );

  const onOpenCollapse = medication => open => {
    let newProgress = medication.notes.trim().length
      ? reportItemProgress.Completed
      : reportItemProgress.Incomplete;
    if (open) {
      newProgress = reportItemProgress.InProgress;
    }

    updateItems(
      selectedItems.map(selectedItem =>
        selectedItem.item.value === medication.item.value
          ? { ...selectedItem, progress: newProgress }
          : selectedItem,
      ),
    );
  };

  const onRemoveClick = index => () => {
    const updatedSymptoms = selectedItems.filter((_, i) => i !== index);
    onRemoveItem(index);
    updateItems([...updatedSymptoms]);
  };

  return (
    <Box className={classes.container}>
      {selectedItems.map((medication, index) => (
        <ReportFormCollapseMedications
          key={index}
          lookupData={lookupData}
          medication={medication}
          showInterference={showInterference}
          onChange={onUpdateItem}
          onRemoveClick={onRemoveClick(index)}
          onOpenCollapse={onOpenCollapse(medication)}
          memberId={memberId}
          options={options}
        />
      ))}
    </Box>
  );
};

export default StepTwoMedications;
