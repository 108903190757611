/* eslint-disable react/destructuring-assignment */
import { useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import { useDownloadExcel } from 'table-to-excel-react';

import { colors, fontWeight, IconButton, Text } from '../../../../../packages';
import { Box, Grid, Stack } from '@mui/material';
import { boxType, DashboardBox } from '../../../../../packages/ui/templates/dashboard-box';
import {
  getElkProfileElementCount,
  getElkProfileElementReport2,
} from '../../../../../services/reporting/reporting.service';
import RecentAssignmentsTable from '../../../../../pages/admin/plan-items/components/plan-item-detail/recent-assignments-table';
import HeadCard from '../head-card';
import AssignmentsChart from '../assignments-chart';
import ColumnChart from '../demographics/column-chart';
import ProfileElementDetail from './profile-element-detail';

import {
  getBoxes,
  getChartSeries,
  getLast30DaysCounts,
  getLeastAssigned,
  getMostAssigned,
  getPast30DaysCounts,
  getProfileElementsQuery,
  tableColumns,
} from './ProfileElements.constants';
import ChartSeries from '../chatbots/chart-series';
import { ViewAllDrawer } from '../data-collection-templates/ViewAllDrawer';
import { useStyles } from './ProfileElements.styles';
import { getReportAllFiltersQuery, REPORT_SECTION_NAMES } from '../../ReportDetail.constants';

const ProfileElements = ({ report, showNewReport, componentRef }) => {
  const classes = useStyles();
  const [showViewAllType, setShowViewAllType] = useState(null);
  const [selectedProfile, setSelectedProfile] = useState(null);
  const [profileElementReport, setProfileElementReport] = useState(null);
  const [profileElementCount, setProfileElementCount] = useState(0);
  const { onDownload } = useDownloadExcel({
    fileName: 'profile_element_report',
    table: 'table_to_xls',
    sheet: 'sheet 1',
  });
  const topTenProfileElements = useMemo(
    () => profileElementReport?.aggregations?.top10ProfileElement.buckets ?? [],
    [profileElementReport],
  );
  const boxes = useMemo(
    () => getBoxes(profileElementReport, report, profileElementCount),
    [profileElementReport, report, profileElementCount],
  );
  const last30DaysCounts = useMemo(() => getLast30DaysCounts(profileElementReport), [profileElementReport]);
  const past30DaysCounts = useMemo(() => getPast30DaysCounts(profileElementReport), [profileElementReport]);
  const mostAssginedData = useMemo(() => getMostAssigned(profileElementReport), [profileElementReport]);
  const leastAssignedData = useMemo(() => getLeastAssigned(profileElementReport), [profileElementReport]);
  const chartSeries = useMemo(() => getChartSeries(profileElementReport), [profileElementReport]);
  const profileElementsSection = useMemo(() => report?.data?.profileElementsSection, [report]);

  useEffect(() => {
    !showNewReport &&
      void (async () => {
        const resp = await getElkProfileElementReport2(getProfileElementsQuery(report), null);
        setProfileElementReport(resp.data);
        const respCount = await getElkProfileElementCount(
          { query: getReportAllFiltersQuery(report, REPORT_SECTION_NAMES.profileElementsSection).query },
          {},
        );
        setProfileElementCount(
          respCount.data.count ||
            Math.round(resp.data?.aggregations?.total_assigned_profile_elements.value ?? 0).toString(),
        );
      })();
  }, [showNewReport]);

  const onCloseViewDetail = () => {
    setSelectedProfile(null);
  };

  const onCloseViewAll = () => {
    setShowViewAllType(null);
  };

  const onClickAll = type => () => {
    setShowViewAllType(type || showViewAllType);
  };

  const onRowClick = item => {
    setSelectedProfile(item);
  };

  // eslint-disable-next-line react/display-name
  const renderMenu = () => () => {
    return <></>;
  };

  const getContentsInViewAll = () => {
    if (showViewAllType === 'mostAssigned') {
      return {
        title: 'Most assigned',
        subtitle: `${mostAssginedData.length} total`,
        tableColumns,
        onRowClick,
        data: mostAssginedData,
        renderMenuAction: renderMenu(),
      };
    }
    if (showViewAllType === 'leastAssigned') {
      return {
        title: 'Least assigned',
        subtitle: `${leastAssignedData.length} total`,
        tableColumns,
        onRowClick,
        data: leastAssignedData,
        renderMenuAction: renderMenu(),
      };
    }

    return null;
  };

  const renderTable = (title, data, columns, onClickViewAll, renderMenuAction, className = '') => (
    <Box className={clsx(classes.tableWrap, className)} flex={1}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Text className={classes.recentTitle} weight={fontWeight.SEMI_BOLD}>
          {title}
        </Text>
        <IconButton onClick={onClickViewAll} className={classes.viewAllBtn}>
          View top ({data?.length})
        </IconButton>
      </Stack>
      <RecentAssignmentsTable
        className={classes.tableBordered}
        isShowAll={false}
        tableColumns={columns}
        onRowClick={onRowClick}
        data={data}
        renderMenuAction={renderMenuAction}
      />
    </Box>
  );

  const renderAllTables = () => {
    return (
      <div id="table_to_xls" hidden>
        {[
          {
            title: 'Most Assigned Appointments',
            tableColumns: null,
            data: null,
          },
          {
            tableColumns,
            data: mostAssginedData,
          },
          {
            title: 'Most Completed Appointments',
            tableColumns: null,
            data: null,
          },
          {
            tableColumns,
            data: leastAssignedData,
          },
        ].map((item, index) => {
          return (
            <table key={`${index}_t`}>
              <thead key={`${index}_th`}>
                <tr key={`${index}_trh`}>
                  {item?.title && <th>{item.title}</th>}
                  {item?.tableColumns?.find(col => col.id === 'member') &&
                    ['Member_fullName', 'Member_Name', 'Member_Id'].map(colItem => (
                      <th key={`${colItem}_thr`}>{colItem}</th>
                    ))}
                  {item?.tableColumns?.find(col => col.id === 'provider') &&
                    ['Provider_Name', 'provider_Role'].map(colItem => (
                      <th key={`${colItem}_thr`}>{colItem}</th>
                    ))}
                  {item?.tableColumns
                    ?.filter(col => !(col.id === 'member' || col.id === 'provider'))
                    .map((column, indx) => {
                      return <th key={`${indx}_thr`}>{column.label}</th>;
                    })}
                </tr>
              </thead>
              <tbody>
                {item?.data?.map((row, ind) => {
                  return (
                    <tr key={`${ind}_tr`}>
                      {item.tableColumns.find(col => col.id === 'member') &&
                        ['fullname', 'name', 'memberId'].map(colItem => (
                          <td key={`${colItem}_thr`}>{row.member[colItem]}</td>
                        ))}
                      {item.tableColumns.find(col => col.id === 'provider') &&
                        ['name', 'role'].map(colItem => (
                          <td key={`${colItem}_thr`}>{row.provider[colItem]}</td>
                        ))}
                      {item.tableColumns
                        .filter(col => !(col.id === 'member' || col.id === 'provider'))
                        .map((column, i) => {
                          return <td key={`${i}_tir`}>{row[column.id]}</td>;
                        })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          );
        })}
      </div>
    );
  };
  return (
    <>
      {renderAllTables()}
      <ProfileElementDetail
        open={!!selectedProfile}
        onClose={onCloseViewDetail}
        profileElement={selectedProfile}
      />
      <ViewAllDrawer isOpen={!!showViewAllType} onClose={onCloseViewAll} {...getContentsInViewAll()} />
      <Box className={classes.card}>
        <HeadCard title="Profile elements" componentRef={componentRef} onDownloadClick={onDownload} />
        <Box className={classes.card}>
          <Box className={classes.reports}>
            <Grid container spacing={2}>
              {boxes.map(box => (
                <Grid item xs={4} key={box.label}>
                  <DashboardBox className={classes.reportItem} {...box} type={boxType.TOTAL} />
                </Grid>
              ))}
            </Grid>
          </Box>
          {profileElementsSection.updatedInLast30Days?.isChecked && (
            <Box className={classes.chartWrap}>
              <AssignmentsChart
                title="Updated in the last 30 days"
                className={classes.chart}
                chart={{
                  series: [
                    { name: 'Current 30 days', color: colors.primary500, data: last30DaysCounts },
                    { name: 'Past 30 days', color: colors.neutral600, data: past30DaysCounts },
                  ],
                  tooltipConfig: {
                    isShowTotal: false,
                    title: 'Total',
                  },
                }}
              />
            </Box>
          )}
          <Box display="flex" justifyContent="space-between" gap={6}>
            {profileElementsSection.mostAssigned?.isChecked &&
              renderTable(
                'Most assigned',
                mostAssginedData,
                tableColumns,
                onClickAll('mostAssigned'),
                renderMenu(),
                !profileElementsSection.leastAssigned?.isChecked ? classes.halfTable : '',
              )}
            {profileElementsSection.leastAssigned?.isChecked &&
              renderTable(
                'Least assigned',
                leastAssignedData,
                tableColumns,
                onClickAll('leastAssigned'),
                renderMenu(),
                !profileElementsSection.mostAssigned?.isChecked ? classes.halfTable : '',
              )}
          </Box>
          {profileElementsSection.top10ProfileElements?.isChecked && (
            <Box className={classes.chartWrap}>
              <ColumnChart
                title="Top 10 profile elements by member"
                className={classes.chart}
                chart={{
                  series: [
                    { name: 'Members', data: topTenProfileElements.map(item => item.uniqueMembers.value) },
                  ],
                  color: colors.primary500,
                  categories: topTenProfileElements.map(item => item.key),
                }}
              />
            </Box>
          )}
          {profileElementsSection.topProfileElements?.isChecked && (
            <ChartSeries data={chartSeries} pagination onHeadingClick={onRowClick} />
          )}
        </Box>
      </Box>
    </>
  );
};

export { ProfileElements };
