/* eslint-disable max-len */
import cx from 'clsx';

// types
// styles
import { useStyles } from './shared/styles';

const Heart = ({ className, color, onClick }) => {
  const classes = useStyles();
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cx(classes.root, className)}
      onClick={onClick}
    >
      <path
        d="M3.16104 4.46898C4.32111 3.30871 5.87704 2.63007 7.51664 2.56923C9.15624 2.5084 10.7582 3.06987 12.001 4.14098C13.2428 3.07165 14.8426 2.51081 16.4803 2.57074C18.1179 2.63067 19.6724 3.30695 20.8327 4.46419C21.993 5.62142 22.6733 7.17421 22.7375 8.81167C22.8018 10.4491 22.2451 12.0504 21.179 13.295L13.414 21.085C13.0573 21.4419 12.5788 21.6507 12.0746 21.6696C11.5703 21.6885 11.0775 21.5161 10.695 21.187L10.585 21.086L2.82104 13.295C1.75553 12.0515 1.19862 10.4517 1.26166 8.81536C1.32469 7.17904 2.00303 5.6268 3.16104 4.46898ZM4.57504 5.88298C3.75728 6.70097 3.2859 7.80242 3.25871 8.95875C3.23152 10.1151 3.65063 11.2375 4.42904 12.093L4.57504 12.247L12 19.672L17.303 14.368L13.768 10.833L12.708 11.893C12.4295 12.1716 12.0988 12.3927 11.7348 12.5436C11.3709 12.6944 10.9807 12.7721 10.5868 12.7722C9.79103 12.7724 9.02783 12.4565 8.46504 11.894C7.90225 11.3315 7.58598 10.5684 7.58579 9.77269C7.5856 8.97697 7.90152 8.21377 8.46404 7.65098L10.566 5.54798C9.72951 4.88036 8.68245 4.53267 7.61273 4.56731C6.543 4.60195 5.52063 5.01664 4.72904 5.73698L4.57504 5.88298ZM13.061 8.71098C13.2486 8.52351 13.5029 8.4182 13.768 8.4182C14.0332 8.4182 14.2875 8.52351 14.475 8.71098L18.717 12.953L19.425 12.247C20.2565 11.4161 20.7299 10.2929 20.7439 9.11754C20.7579 7.94219 20.3115 6.808 19.5001 5.95754C18.6887 5.10707 17.5767 4.60785 16.402 4.56663C15.2273 4.52542 14.0831 4.94548 13.214 5.73698L13.061 5.88298L9.87904 9.06498C9.70571 9.2382 9.60202 9.46902 9.58764 9.71365C9.57326 9.95827 9.6492 10.1997 9.80104 10.392L9.87904 10.479C10.0523 10.6523 10.2831 10.756 10.5277 10.7704C10.7723 10.7848 11.0137 10.7088 11.206 10.557L11.293 10.479L13.061 8.71098Z"
        fill={color}
      />
    </svg>
  );
};

export default Heart;
