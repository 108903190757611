import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { useDownloadExcel } from 'table-to-excel-react';

import { Box, Modal, Stack } from '@mui/material';
import {
  btnSize,
  btnType,
  Button,
  colors,
  fontWeight,
  Heading,
  headingLevel,
  iconBtnType,
  IconButton,
  Icons,
  Select,
  selectSize,
  selectType,
} from '../../../../../packages';
import { NoteCard } from '../../../../../packages/ui/templates/note-card';
import { useReactToPrint } from 'react-to-print';
import { useDispatch, useSelector } from 'react-redux';
import * as memberService from '../../../../../services/member/member.service';
import { getContactNotesCountByPatient, getMemberNotes } from '../../../../../services/member/member.service';
import { FilterNotesOptions, GLOBAL_DATE_FORMAT, NoteIcons } from '../../../../../constants/CommonConstants';
import MultiColorProgressBar from '../../../../admin/notes/multi-color-progress-bar/MultiColorProgressBar';
import { getAuth } from '../../../../../redux/modules/auth/selectors';
import logo from '../../../../../assets/images/ch-icon.png';
import NoteDrawer from './NoteDrawer';
import AddNoteDrawer from './AddNoteDrawer/AddNoteDrawer';
import { useStyles } from '../../MemberDetail.styles';
import PriorityEmpty from '../PriorityEmpty';
import { downloadDateRange } from '../../MemberDetail.constants';
import { showSnackbar } from '../../../../../redux/modules/snackbar';

const Notes = ({ memberID, refetch }) => {
  const classes = useStyles({});
  const dispatch = useDispatch();
  const { isAdmin } = useSelector(getAuth);
  const [selectedNote, setSelectedNote] = useState(null);
  const [isOpenAddNote, setIsOpenAddNote] = useState(false);
  const [selectedFlag, setSelectedFlag] = useState('all');
  const { memberId = memberID } = useParams();
  const [notes, setNotes] = useState([]);
  const [, setTotalRecords] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [countsByType, setCountsByType] = useState([]);
  const [countsByStatus, setCountsByStatus] = useState([]);
  const [totalNotes, setTotalNotes] = useState(0);
  const [isLoadingExport, setIsLoadingExport] = useState(false);
  const [renderTableToDownload, setRenderTableToDownload] = useState(null);
  const [renderTableToPrint, setRenderTableToPrint] = useState(<></>);
  const [openTableToPrintModal, setOpenTableToPrintModal] = useState(false);
  const [isExportDisabled, setIsExportDisabled] = useState(false);
  const location = useLocation();
  const openTableToPrint = () => {
    setOpenTableToPrintModal(true);
  };

  const closeTableToPrint = () => {
    setOpenTableToPrintModal(false);
  };
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => {
      const currentComponent = componentRef.current;
      currentComponent.style.overflow = 'visible';
      return currentComponent;
    },
  });
  useEffect(() => {
    if (location?.state) {
      const locationState = location.state;
      if (locationState.noteId) {
        const filteredNote = notes.find(note => note.notesId === locationState.noteId);
        if (filteredNote) {
          setSelectedNote({ ...filteredNote, patientId: '' });
        }
      }
    }
  }, [notes]);
  useEffect(() => {
    memberService
      .getAdminProfile({ userId: memberId || '' })
      .then(patientResponse => {
        getMemberNotes(
          { userId: memberId },
          { startDate: downloadDateRange.startDate, endDate: downloadDateRange.endDate },
        )
          .then(res => {
            if (res.data?.patientContactNotes?.length > 0) {
              setIsExportDisabled(false);
              setRenderTableToPrint(
                <div className={classes.documentToPrint} ref={componentRef}>
                  <div className={classes.coverPage}>
                    <div className={classes.coverDownlad}>
                      <div className={classes.topBox}>
                        <p className={classes.c4}>FOR CLINICAL USE ONLY</p>
                      </div>
                      <div className={classes.middleBox}>
                        <p className={classes.c4}>
                          <span className={classes.imageBox}>
                            <img alt="" src={logo} className={classes.imageTag} title="" />
                          </span>
                        </p>
                        <p className={classes.c2}>Patient Clinical Notes Export</p>
                      </div>
                      <div className={classes.bottomBox}>
                        <p className={classes.c5}>Confidential:</p>
                        <div className={classes.bottomInnerBox}>
                          <p className={classes.c1}>
                            These clinical notes contain sensitive patient information. If received in error,
                            please notify us immediately and destroy all copies. Unauthorized access,
                            disclosure, or use is prohibited by law and subject to penalties.
                          </p>
                          <p className={classes.c1}>
                            Protect patient privacy, it&#39;s our shared responsibility.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Patient information */}
                  <div className={classes.sectionBox}>
                    <p className={classes.title}>FOR CLINICAL USE ONLY</p>
                    <div className={classes.exportDate}>
                      <p className={classes.textRight}>
                        Date of Export: {dayjs().format(GLOBAL_DATE_FORMAT)}
                      </p>
                    </div>

                    <p className={classes.question}>
                      <b>
                        Patient:{' '}
                        <span className={classes.answer}>
                          {patientResponse.data?.firstName || patientResponse.data?.lastName
                            ? `${patientResponse.data?.firstName || ''} ${
                                patientResponse.data?.lastName || ''
                              }`
                            : patientResponse.data?.fullName ?? ''}{' '}
                        </span>
                      </b>
                    </p>
                    <p className={`${classes.question} ${classes.cptList}`}>
                      <b>
                        Date of birth:{' '}
                        {patientResponse.data?.dob
                          ? dayjs(patientResponse.data?.dob).format(GLOBAL_DATE_FORMAT)
                          : ''}
                      </b>
                    </p>
                  </div>
                  <div className={classes.appointmentBox}>
                    <p className={classes.sectionTitleCenter}>Additional Notes</p>
                    {res.data?.patientContactNotes?.map((note, index) => (
                      <div key={`note-${index}`} className={classes.appointmentBox}>
                        <p className={classes.sectionSubTitleLeft}>{note.name}</p>
                        <div>
                          <p className={classes.cptList}>Type: {note.type}</p>
                          <p className={classes.cptList}>Status: {note.status}</p>
                          <p className={classes.question}>Auther: {note.createdBy?.name ?? ''}</p>
                          <p className={classes.cptList}>Updated on: {note.lastModified}</p>
                          <p className={classes.question}>Additional notes:</p>
                          <p className={classes.cptList}>{note.description}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>,
              );
              setRenderTableToDownload(
                <table id="table_to_xls" hidden>
                  <thead>
                    <tr>
                      <th>Patient</th>
                      <th>Type</th>
                      <th>Note date</th>
                      <th>Signature on note</th>
                      <th>Attachments</th>
                      <th>Description</th>
                    </tr>
                  </thead>
                  <tbody>
                    {res.data?.patientContactNotes?.map((note, i) => (
                      <tr key={`note-${i}`}>
                        <td>
                          {patientResponse.data?.firstName || patientResponse.data?.lastName
                            ? `${patientResponse.data?.firstName || ''} ${
                                patientResponse.data?.lastName || ''
                              }`
                            : patientResponse.data?.fullName ?? ''}{' '}
                          {patientResponse.data?.dob
                            ? dayjs(patientResponse.data?.dob).format('MM/DD/YYYY')
                            : ''}
                        </td>
                        <td>{note.type}</td>
                        <td>{note.createdAt}</td>
                        <td>{note.createdBy?.name ?? ''}</td>
                        <td>{note.attachments?.map(item => item.attachmentUrl)?.toString()}</td>
                        <td>{note.description}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>,
              );
              return;
            }
            setRenderTableToDownload('');
          })
          .catch(err => {
            console.warn(err);
            dispatch(
              showSnackbar({
                snackType: 'error',
                snackMessage: err?.data?.errors[0]?.endUserMessage || 'Could not fetch notes.',
              }),
            );
          });
      })
      .catch(err => {
        console.log(err);
      });
  }, [isOpenAddNote, selectedNote]);
  const handleSelectNote = note => () => {
    setSelectedNote(note);
  };

  const handleDeselectNote = () => {
    setSelectedNote(null);
  };

  const onClickAddNewNote = () => {
    setIsOpenAddNote(true);
  };
  const onChangFlag = e => {
    const { value: flag } = e.target;
    setSelectedFlag(flag);
  };

  const onCloseAddNote = () => {
    setIsOpenAddNote(false);
    setSelectedNote(null);
  };
  const getTypeCountObject = (key, counts) => {
    switch (key) {
      case 'cautionNotesCount':
        return {
          name: 'Coutionary',
          value: counts[key],
          color: colors.warning500,
        };
      case 'contactNotesCount':
        return {
          name: 'Contact',
          value: counts[key],
          color: colors.primary500,
        };
      case 'generalNotesCount':
        return {
          name: 'General',
          value: counts[key],
          color: colors.primary500,
        };
      case 'labRequestNotesCount':
        return {
          name: 'Lab Request',
          value: counts[key],
          color: colors.green500,
        };
      case 'pharmacyAssistanceNotesCount':
        return {
          name: 'Pharmacy Assistance',
          value: counts[key],
          color: colors.cyan500,
        };
      case 'priorAuthorizationNotesCount':
        return {
          name: 'Prior Authorization',
          value: counts[key],
          color: colors.primary500,
        };
      case 'prohibitiveNotesCount':
        return {
          name: 'Prohibitive',
          value: counts[key],
          color: colors.destructive500,
        };
      case 'referralOutNotesCount':
        return {
          name: 'Referral out',
          value: counts[key],
          color: colors.orange500,
        };
      case 'releaseOfInformationNotesCount':
        return {
          name: 'Release of info',
          value: counts[key],
          color: colors.indigo500,
        };
      default:
        return {
          name: 'General',
          value: counts[key],
          color: colors.primary500,
        };
    }
  };
  const getFormatedCountsByNoteTypes = counts => {
    if (counts) {
      return Object.keys(counts)
        ?.filter(function (key) {
          if (key !== 'totalNotesCount') return key;
          return null;
        })
        ?.map(function (key) {
          return getTypeCountObject(key, counts);
        });
    }
    return null;
  };
  const getStatusCountObject = (key, counts, statusCounts) => {
    switch (key) {
      case 'receivedByCareManagementCount':
      case 'reportedCount':
      case 'sentToMemberCount':
      case 'requestedCount':
      case 'sentToLabCount':
      case 'submittedCount':
      case 'pendingCount':
      case 'SentToRequestedContactCount':
        statusCounts[0].value += counts[key];
        break;
      case 'reviewedByManagementCount':
      case 'resolvedCount':
      case 'completedCount':
      case 'unsuccessfulCount':
      case 'removedCount':
      case 'rejectedCount':
      case 'noResponseCount':
        statusCounts[1].value += counts[key];
        break;
      default:
        break;
    }
  };
  const getFormatedCountsByNotesStatus = counts => {
    if (counts) {
      const statusCounts = [
        {
          name: 'Needs action',
          color: colors.neutral900,
          value: 0,
        },
        {
          name: 'No action needed',
          color: colors.neutral200,
          value: 0,
        },
      ];
      Object.keys(counts)
        ?.filter(key => key !== 'totalNotesCount')
        ?.map(key => getStatusCountObject(key, counts, statusCounts));
      return statusCounts;
    }
    return null;
  };
  const fetchNotes = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await getMemberNotes({ userId: memberId });
      const listSorted = data.patientContactNotes;
      setNotes(listSorted);
      setTotalRecords(data.totalRecords);
      getContactNotesCountByPatient({ patientId: memberId })
        .then(res => {
          setTotalNotes(res.data?.ContactNotesCountByType?.totalNotesCount || 0);
          setCountsByType(getFormatedCountsByNoteTypes(res.data?.ContactNotesCountByType));
          setCountsByStatus(getFormatedCountsByNotesStatus(res.data?.ContactNotesCountByStatus));
        })
        .catch(err => {
          console.log({ err });
          dispatch(
            showSnackbar({
              snackType: 'error',
              snackMessage: err?.data?.errors[0]?.endUserMessage || 'Could not fetch notes.',
            }),
          );
        });
      // eslint-disable-next-line no-empty
    } catch (error) {}
    setIsLoading(false);
  }, [memberId]);

  useEffect(() => {
    void fetchNotes();
  }, [fetchNotes]);

  const onUpdateNote = note => {
    setSelectedNote(note);
    onClickAddNewNote();
  };

  const filteredNotes =
    selectedFlag === 'all'
      ? notes.map(item => ({ ...item, patientId: '' }))
      : notes.filter(item => item.flag === selectedFlag).map(item => ({ ...item, patientId: '' }));

  const handleRefetch = () => {
    void fetchNotes();

    if (refetch) {
      refetch();
    }
  };
  const renderCountLabel = (count, label) => {
    return (
      <div className={classes.countLabel}>
        <span className={classes.count}>{count}</span>
        {label}
      </div>
    );
  };
  const { onDownload } = useDownloadExcel({
    fileName: 'member notes',
    table: 'table_to_xls',
    sheet: 'sheet 1',
  });
  const downloadNotes = () => {
    setIsLoadingExport(true);
    onDownload();
    setIsLoadingExport(false);
  };
  const resetOverflow = () => {
    const currentComponent = componentRef.current;
    currentComponent.style.overflow = 'scroll';
    setOpenTableToPrintModal(false);
  };
  return (
    <>
      <Modal open={openTableToPrintModal} onClose={closeTableToPrint}>
        <Box className={classes.modal}>
          <Box className={classes.modalContent}>
            <Box className={classes.content}>
              <Box className={classes.sectionAutoSize}>{renderTableToPrint}</Box>
              <Box display="flex" alignItems="center" gap={3} mt={3}>
                <Button
                  size={btnSize.SMALL}
                  onClick={e => {
                    handlePrint(e);
                    resetOverflow();
                  }}
                >
                  Export/Print
                </Button>
                <Button size={btnSize.SMALL} onClick={closeTableToPrint}>
                  Cancel
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
      {renderTableToDownload}
      <AddNoteDrawer
        isOpen={isOpenAddNote}
        onClose={onCloseAddNote}
        fetchNotes={handleRefetch}
        note={selectedNote}
        memberId={memberId}
      />
      <NoteDrawer
        selectedNote={isOpenAddNote ? undefined : selectedNote}
        onClose={handleDeselectNote}
        onUpdateNote={onUpdateNote}
      />
      <div className={classes.headingWithBtnWrapper}>
        <Heading className={classes.heading} level={headingLevel.XL} weight={fontWeight.BOLD}>
          Case Notes
        </Heading>
        <div className={classes.noteHeaderRightSide}>
          <Select
            className={classes.activitySelect}
            variant={selectType.SECONDARY}
            size={selectSize.M}
            value={selectedFlag}
            onChange={onChangFlag}
            options={FilterNotesOptions}
          />
          <IconButton
            className={classes.addBtn}
            icon="plus"
            variant={iconBtnType.PRIMARY}
            onClick={onClickAddNewNote}
          >
            Add new
          </IconButton>
          {isAdmin && (
            <IconButton
              icon="export"
              variant={btnType.PRIMARY}
              className={classes.addBtn}
              disabled={isLoadingExport && isExportDisabled}
              onClick={downloadNotes}
            >
              {isLoadingExport ? 'Loading' : 'XLS'}
            </IconButton>
          )}
          {isAdmin && (
            <IconButton
              icon="export"
              variant={btnType.PRIMARY}
              className={classes.addBtn}
              onClick={openTableToPrint}
              disabled={isLoadingExport && isExportDisabled}
            >
              Export
            </IconButton>
          )}
        </div>
      </div>
      {totalNotes > 0 && (
        <Box className={classes.topSection} display="flex">
          <Box flex={1}>
            {renderCountLabel(totalNotes, `${totalNotes === 1 ? 'note' : 'notes'} total`)}
            <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
              <MultiColorProgressBar readings={countsByType} totalValue={totalNotes} />
            </Stack>
          </Box>
          <Box flex={1}>
            {renderCountLabel(countsByStatus?.[0]?.value, 'needs action')}
            <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
              <MultiColorProgressBar readings={countsByStatus} totalValue={totalNotes} />
            </Stack>
          </Box>
        </Box>
      )}
      <div className={classes.noteCardWrapper}>
        {isLoading ? (
          <Box display="flex" justifyContent="center" sx={{ paddingY: 5 }}>
            <Icons className="rotate linear infinite" glyph="in-progress" color={colors.primary} />
          </Box>
        ) : (
          <>
            {filteredNotes.length > 0 ? (
              filteredNotes.map((note, index) => (
                <NoteCard
                  key={index}
                  note={note}
                  icon={NoteIcons[note.flag]}
                  onClick={handleSelectNote(note)}
                />
              ))
            ) : (
              <Box sx={{ width: '100%' }}>
                <Box className={classes.card}>
                  <PriorityEmpty
                    title="No case notes have been recorded for this member yet."
                    description=""
                    glyph="lottie-case-notes"
                    glyphOrientation="landscape"
                    isEmptyWithLottie
                    lottieSize={128}
                  />
                </Box>
              </Box>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default Notes;
