/* eslint-disable max-len */
import cx from 'clsx';

// types
// styles
import { useStyles } from './shared/styles';

const WandOutlined = ({ className, color, onClick, ...rest }) => {
  const classes = useStyles();
  return (
    <svg
      className={cx({
        [classes.root]: true,
        [className || '']: className,
      })}
      onClick={onClick}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g id="wand-icon">
        <path
          d="M10.1326 6.62994C9.84351 6.35602 9.65753 5.99088 9.60598 5.59594L9.33732 3.5406L7.51732 4.53127C7.16738 4.72195 6.76243 4.78622 6.37065 4.71327L4.33332 4.33327L4.71332 6.3706C4.78627 6.76238 4.72199 7.16734 4.53132 7.51727L3.54065 9.33727L5.59598 9.60594C5.99068 9.65763 6.35557 9.8436 6.62932 10.1326L8.05465 11.6373L8.94532 9.76594C9.11627 9.40632 9.40583 9.11652 9.76532 8.94527L11.6373 8.0546L10.1326 6.62994ZM10.1493 10.3386L8.67398 13.4386C8.64607 13.4972 8.60431 13.5482 8.55231 13.587C8.50031 13.6259 8.43963 13.6515 8.37551 13.6617C8.31139 13.6719 8.24576 13.6663 8.18428 13.6454C8.12281 13.6245 8.06733 13.589 8.02265 13.5419L5.66132 11.0493C5.59823 10.9827 5.51421 10.9399 5.42332 10.9279L2.01865 10.4826C1.95432 10.4741 1.89303 10.4501 1.84004 10.4127C1.78706 10.3752 1.74397 10.3255 1.71451 10.2677C1.68505 10.2099 1.6701 10.1458 1.67093 10.0809C1.67177 10.016 1.68837 9.9523 1.71932 9.89527L3.36065 6.87927C3.40428 6.79861 3.41887 6.70541 3.40198 6.61527L2.77332 3.23994C2.76138 3.17605 2.76519 3.11021 2.78442 3.04813C2.80364 2.98605 2.83771 2.92958 2.88367 2.88362C2.92963 2.83767 2.98609 2.8036 3.04817 2.78437C3.11026 2.76514 3.17609 2.76133 3.23998 2.77327L6.61532 3.40194C6.70545 3.41883 6.79866 3.40424 6.87932 3.3606L9.89532 1.71927C9.95239 1.68832 10.0161 1.67173 10.0811 1.67093C10.146 1.67013 10.2101 1.68514 10.2679 1.71468C10.3258 1.74421 10.3755 1.78738 10.4129 1.84044C10.4503 1.89351 10.4743 1.95489 10.4826 2.01927L10.928 5.42327C10.94 5.51417 10.9828 5.59819 11.0493 5.66127L13.542 8.0226C13.5891 8.06729 13.6246 8.12276 13.6455 8.18424C13.6663 8.24572 13.6719 8.31135 13.6617 8.37547C13.6516 8.43959 13.6259 8.50027 13.5871 8.55227C13.5482 8.60427 13.4973 8.64603 13.4387 8.67394L10.3386 10.1493C10.2557 10.1888 10.1888 10.2556 10.1493 10.3386ZM10.6806 11.6233L11.6233 10.6806L14.452 13.5086L13.5086 14.4519L10.6806 11.6233Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

export default WandOutlined;
