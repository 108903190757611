/* eslint-disable max-len */
import cx from 'clsx';

// types
// styles
import { useStyles } from './shared/styles';

const EndCallFilled = ({ className, color, onClick }) => {
  const classes = useStyles();
  return (
    <svg
      className={cx({
        [classes.root]: true,
        [className || '']: className,
      })}
      width="22"
      height="10"
      viewBox="0 0 22 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M22 5.33V8.25C21.9992 8.36422 21.9722 8.47673 21.9213 8.57896C21.8704 8.68119 21.7968 8.77043 21.7061 8.83988C21.6154 8.90934 21.5101 8.95716 21.3981 8.97971C21.2861 9.00226 21.1705 8.99894 21.06 8.97L16.74 7.84C16.5218 7.7814 16.33 7.65066 16.1956 7.46908C16.0612 7.2875 15.9923 7.06577 16 6.84V3.68C16.0017 3.57141 15.9672 3.46534 15.9021 3.37846C15.8369 3.29158 15.7447 3.2288 15.64 3.2L14.93 3C12.3651 2.23982 9.63486 2.23982 7.07001 3L6.36001 3.22C6.25529 3.2488 6.16311 3.31158 6.09795 3.39846C6.03279 3.48534 5.99833 3.59141 6.00001 3.7V6.88C6.00774 7.10577 5.93881 7.3275 5.80443 7.50908C5.67006 7.69066 5.47817 7.8214 5.26001 7.88L0.940006 9C0.82704 9.02965 0.708711 9.03251 0.594446 9.00835C0.480181 8.98419 0.373137 8.93368 0.281843 8.86084C0.190549 8.78799 0.117528 8.69484 0.0685978 8.58879C0.0196673 8.48274 -0.00382038 8.36673 5.92484e-06 8.25V5.34C-0.000203195 5.07609 0.051822 4.81475 0.153083 4.57103C0.254344 4.32732 0.402838 4.10606 0.590006 3.92L1.09001 3.42C2.25927 2.25184 3.69835 1.38977 5.28001 0.910003L5.78001 0.760004C9.19014 -0.249937 12.8199 -0.249937 16.23 0.760004L16.72 0.910003C18.3017 1.38977 19.7407 2.25184 20.91 3.42L21.41 3.92C21.7856 4.29335 21.9978 4.80043 22 5.33Z"
        fill={color}
      />
    </svg>
  );
};

export default EndCallFilled;
