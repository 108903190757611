/* eslint-disable max-len */
/* eslint-disable no-empty */
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { dayConstants, DomainTypesEnum, frequencyConstants } from '../constants/CommonConstants';

import { getProfile } from '../redux/modules/profile/selectors';
import { conversationActionCreators } from '../redux/modules/conversation';
import { getDomainElementDetail, getDomainGroup } from '../services/member/member.service';

const useDomainType = ({ type, patientId, shouldUpdate }) => {
  const dispatch = useDispatch();
  const { domainTypes, lookupData } = useSelector(getProfile);
  const { memberId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [domainOptions, setDomainOptions] = useState([]);
  const [domainData, setDomainData] = useState(null);

  const isRelatedTo = (key, item) => {
    return item.tagMetaData.specification[key] && item.tagMetaData.specification[key].length > 0;
  };

  const getRelatedToText = item => {
    let subText = null;
    if (item.tagMetaData && item.tagMetaData.specification) {
      if (isRelatedTo('relatedToMedicalCondition', item)) {
        subText = 'Related To Medical Condition';
      }
      if (isRelatedTo('relatedToMedication', item)) {
        subText = 'Related To Medication';
      }
      if (isRelatedTo('relatedToSubstanceUse', item)) {
        subText = 'Related To Substance Use';
      }
      if (isRelatedTo('relatedToWithdrawal', item)) {
        subText = 'Related To Withdrawal';
      }
    }
    return subText;
  };

  const getMedication = item => {
    const {
      tagMetaData: { rxDrugInfo },
    } = item;
    return `${rxDrugInfo.dose} ${(rxDrugInfo.doseUnit || '').toLowerCase()}
      ${frequencyConstants[rxDrugInfo.doseFrequency] || `${rxDrugInfo.doseFrequency} times`}
      ${dayConstants[rxDrugInfo.supplyUnit]}`;
  };

  const getSubstanceDescription = item => {
    const {
      tagMetaData: { substanceUse },
    } = item;
    const method = lookupData.methodsOfSubstanceUse?.find(opt => opt.name === substanceUse.methodOfUse);
    const frequency = lookupData.currentFrequencyOfSubstanceUse?.find(
      opt => opt.name === substanceUse.currentFrequencyOfUse,
    );
    const duration = lookupData.continuousLevelOfSubstanceUse?.find(
      opt => opt.name === substanceUse.howLongUsingThisLevel,
    );
    return `${method?.value || ''}
      ${frequency?.value?.toLowerCase() || ''} for a ${duration?.value?.toLowerCase() || 'days'}`;
  };

  const getDescription = item => {
    switch (type) {
      case DomainTypesEnum.SYMPTOMS:
      case DomainTypesEnum.DIAGNOSES:
        return getRelatedToText(item);
      case DomainTypesEnum.MEDICATIONS:
        return getMedication(item);
      case DomainTypesEnum.SUBSTANCE_USE:
        return getSubstanceDescription(item);
      default:
        return '';
    }
  };

  const fetchDomainForm = async typeId => {
    setIsLoading(true);
    try {
      const { data } = await getDomainGroup({ domainTypeId: typeId });
      const opts = data.map(item => ({
        label: item.name,
        groupId: item.Id,
        items: item.relatedElements.map(element => ({ label: element.name, value: element.Id })),
        relatedElements: item.relatedElements,
      }));
      setDomainOptions(opts);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const sortHistory = (items = []) => {
    return items.sort((a, b) => dayjs(b.assignedAt).unix() - dayjs(a.assignedAt).unix());
  };

  const formatElementItem = ({ data }, id) => {
    const { importanceLevel, elementName, history, ...rest } = data;
    return {
      ...rest,
      id,
      name: elementName,
      priority: importanceLevel,
      description: getDescription(data),
      history: sortHistory(history),
      icd10Codes: data?.icd10Codes || data?.dataElementIcd10CodeList || [],
    };
  };

  const fetchDomainCardDetail = async (domain, userId) => {
    setIsLoading(true);
    try {
      const ids = domain.relatedElements.map(item => item.id);
      const promises = ids.map(id =>
        getDomainElementDetail({
          associatedTagId: id,
          patientId: userId,
        }),
      );
      const res = await Promise.all(promises);
      const data = {
        ...domain,
        relatedElements: res
          .map((item, idx) => formatElementItem(item, ids[idx]))
          .sort((a, b) => {
            const aTime = dayjs(a.assignedAt).unix();
            const bTime = dayjs(b.assignedAt).unix();
            return bTime - aTime;
          }),
      };
      setDomainData(data);
      dispatch(conversationActionCreators.fetchAssociatedTagSuccess({ domainType: data }));
    } catch (error) {
      console.log('Error');
      setDomainData({
        ...domain,
        relatedElements: domain.relatedElements.map(item => ({
          ...item,
          description: getDescription(item),
        })),
      });
    } finally {
      setIsLoading(false);
    }
  };

  const fetchData = useCallback(async (domainType, items, userId) => {
    setIsLoading(true);
    if (userId) {
      const domain = items.find(item => item.typeName === domainType);
      if (domain) {
        void (await fetchDomainForm(domain.typeId));
        void (await fetchDomainCardDetail(domain, userId));
      }
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    const userId = patientId || memberId;
    void fetchData(type, domainTypes, userId);
  }, [type, domainTypes, patientId, memberId, shouldUpdate]);
  return [isLoading, domainData, domainOptions, lookupData];
};

export { useDomainType };
