// colors
import { colors } from '../../../../../packages/colors';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  historyContainer: {
    backgroundColor: colors.white,
    borderRadius: 8,
    boxShadow:
      '0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
    padding: 56,
  },
  row: {
    '&:not(:last-child)': {
      '& td': {
        border: 'none',
        borderBottom: `1px solid ${colors.neutral100}`,
      },
    },
    '& td': {
      verticalAlign: 'top',
      padding: 32,
    },
  },
  rowCell: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  label: {
    fontSize: 16,
    lineHeight: '24px',
    fontWeight: 600,
  },
  value: {
    fontSize: 16,
    lineHeight: '24px',
    fontWeight: 500,
    color: colors.neutral500,
  },
  icon: {
    marginRight: 12,
    top: 2,
    position: 'relative',
  },
  liItem: {
    padding: 0,
    margin: 0,
    listStyle: 'disc',
    paddingLeft: 18,
  },
});
