// colors
import { colors } from '../../../../../packages/colors';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  card: {
    background: colors.white,
    boxShadow:
      '0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
    borderRadius: 8,
    '&:hover $cardLink': {
      display: 'flex',
    },
  },
  flexBetween: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: 'full',
  },
  flexStart: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    width: 'fit-content',
    alignItems: 'center',
    gap: 12,
  },
  cardHead: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'baseline',
    padding: '8px 16px 16px 16px',
    gap: 4,
    borderBottom: `1px solid ${colors.neutral100}`,
  },
  cardHeadLeft: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 16,
  },
  cardTitle: {
    lineHeight: '24px',
    fontSize: 16,
    color: colors.neutral900,
    margin: 0,
  },
  cardLink: {
    fontSize: 14,
    lineHeight: '20px',
    fontWeight: 700,
    color: colors.primary600,
    flexDirection: 'row',
    alignItems: 'center',
    gap: 12,
    display: 'none',
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    padding: 16,
    paddingBottom: 0,
  },
  table: {
    width: '100%',
    '& tr': {
      width: '100%',
      '&:last-child td': {
        borderBottom: 0,
      },
      '& td': {
        padding: '20px 16px',
        borderBottom: `1px solid ${colors.neutral100}`,
        '&:not(:last-child)': {
          borderRight: `1px solid ${colors.neutral100}`,
        },
      },
    },
  },
  badgeOutlined: {
    height: 28,
    paddingLeft: 10,
    paddingRight: 10,
    borderRadius: 50,
    paddingBottom: 2,
  },
  badgeComment: {
    color: colors.neutral700,
    '& svg': {
      width: 13,
      height: 12,
      marginRight: 4,
    },
    '& svg path': {
      fill: colors.neutral700,
    },
  },
  progress: {
    fontSize: 12,
    lineHeight: '16px',
    color: colors.neutral600,
    margin: 0,
  },
  progressBar: {
    flex: 1,
    minWidth: 166,
    '& .MuiLinearProgress-bar': {
      backgroundColor: colors.primary500,
    },
  },
  footerItems: {
    display: 'flex',
    gap: 16,
    flexFlow: 'wrap',
    flex: 1,
    justifyContent: 'flex-end',
  },
  flexStartItems: { justifyContent: 'flex-start' },
  dflex: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 4,
    gap: 8,
  },
  cardFooter: {
    padding: 16,
    display: 'flex',
    justifyContent: 'space-between',
    gap: 24,
  },
  badge: {
    borderRadius: 100,
    height: 18,
    fontSize: 12,
    lineHeight: '16px',
    fontWeight: 600,
  },
  text22: {
    fontWeight: 600,
    fontSize: 22,
    lineHeight: '28px',
  },
  text14: {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '20px',
  },
  evaluationCardDetails: { margin: 0 },
});
