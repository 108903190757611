import { useEffect, useState } from 'react';

import { Box } from '@mui/material';
import { useStyles } from './ShareNoteBox.styles';
import { fontWeight, Heading, headingLevel, Text, TextError, textLevel } from '../../atoms/typography';
import { IconButton } from '../../molecules/icon-button';
import { Select } from '../../atoms/select';
import DateSelect from '../date-select';
import { Input } from '../../atoms/input';

const ShareNoteBox = ({
  key,
  sequenceNumber,
  providerId,
  providers,
  dueDate,
  message,
  notifyViaSms,
  onSelectProvider,
  onSelectDueDate,
  onChangeMessage,
  onChangeNotifyToggle,
  onDelete,
  dirty,
}) => {
  const classes = useStyles();
  const [internalMessage, setInternalMessage] = useState(message);
  const handleDateChange = date => {
    onSelectDueDate(date ? date.toISOString() : null);
  };

  useEffect(() => {
    if (internalMessage !== message) {
      onChangeMessage(internalMessage);
    }
  }, [internalMessage]);

  return (
    <Box className={classes.container} key={key}>
      <Box className={classes.headerSection}>
        <Text level={textLevel.M} className={classes.heading}>
          Recipient {sequenceNumber}
        </Text>
        <IconButton icon="delete-outlined-2" className={classes.deleteIconBtn} onClick={onDelete} />
      </Box>
      <Box className={classes.contentBox} gap="16px">
        <Box className={classes.firstRow} gap="16px">
          <Box className={classes.controlGroup}>
            <Heading level={headingLevel.S} className={classes.label} weight={fontWeight.BOLD}>
              Select provider
              <span className={classes.asterick}>*</span>
            </Heading>
            <Select
              variant="AVATAR"
              options={providers}
              displayEmpty
              emptyText="Select provider"
              value={providerId}
              truncate
              onChange={e => {
                onSelectProvider(e.target.value);
              }}
            />
          </Box>
          <Box className={classes.controlGroup}>
            <Heading level={headingLevel.S} className={classes.label} weight={fontWeight.BOLD}>
              Select due date
            </Heading>
            <DateSelect
              placeholder="No due date"
              value={dueDate}
              onChange={handleDateChange}
              disablePast
              noValidate
            />
          </Box>
        </Box>
        <TextError
          className={classes.error}
          errorMsg={dirty && providerId === '' ? 'Provider selection is required' : null}
        />
        <Box className={classes.firstRow} gap="16px">
          <Box className={classes.controlGroup}>
            <Heading level={headingLevel.S} className={classes.label} weight={fontWeight.BOLD}>
              Message
              {!location.pathname.includes('/admin/alfie/validation') && (
                <span className={classes.asterick}>*</span>
              )}
            </Heading>
            <Input
              variant="DEFAULT"
              placeholder="Enter your message"
              value={internalMessage}
              onChange={e => {
                setInternalMessage(e.target.value);
              }}
              size="M"
            />
          </Box>
        </Box>
        <TextError
          className={classes.error}
          errorMsg={dirty && internalMessage === '' ? 'Message is required' : null}
        />
        {/* <Box className={classes.firstRow} gap="16px"> */}
        {/*  <Toggle labelPosition={positionType.RIGHT} checked={notifyViaSms} onChange={onChangeNotifyToggle}> */}
        {/*    Notify via sms */}
        {/*  </Toggle> */}
        {/* </Box> */}
      </Box>
    </Box>
  );
};

export { ShareNoteBox };
