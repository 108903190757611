import dayjs from 'dayjs';
import cx from 'clsx';
import { getAuth } from '../../../../../redux/modules/auth/selectors';
import { useSelector } from 'react-redux';
import { Badge, badgeStyle, badgeType, Icons, Menu, Text, textLevel } from '../../../../../packages';
import { AppointmentStatus, AppointmentStatusText } from '../../../../../packages/constants/CommonConstants';
import { ProfileInfo } from '../../../../../packages/ui/templates/profile-info';
import { getTimeZoneText } from '../../../../../constants/CommonConstants';
import history from '../../../../../utils/history';
import { useStyles } from './UpcomingItem.styles';

const UpcomingItem = ({
  appointment,
  setShowReschedule,
  setShowCancel,
  onAppointmentClick,
  onClickAppointmentAction,
}) => {
  const classes = useStyles();
  const { isAdmin } = useSelector(getAuth);
  const date = dayjs(appointment.startTime);
  const startTimeDate = dayjs(appointment.startTime);
  const endTimeDate = dayjs(appointment.endTime);
  const isToday = date.isSame(dayjs(), 'day');
  const appointmentStatus = appointment.status;
  // const statusText = isRequested ? 'Requested' : 'Scheduled';
  let statusText = AppointmentStatusText[appointmentStatus];

  if (appointmentStatus === AppointmentStatus.BOOKED) {
    statusText = isToday ? 'Today' : 'Scheduled';
  }

  return (
    <div className={cx(classes.upcomingItem, classes.item)} onClick={() => onAppointmentClick()}>
      <ProfileInfo
        type="member"
        photo={appointment.participantImage}
        nickName={appointment.participantName}
        fullName={`${appointment.patientFirstName || ''} ${appointment.patientLastName || ''}`}
        memberId={appointment?.member?.userAccountId}
        isProvider={!isAdmin}
      />

      <div className={classes.contentWrap}>
        <Text className={classes.bottomText} level={textLevel.S}>
          {appointment.serviceName}
        </Text>
      </div>
      <div className={cx(classes.contentWrap, classes.statusWrap)}>
        <Badge
          className={classes.badge}
          // eslint-disable-next-line no-nested-ternary
          style={
            isToday
              ? badgeStyle.HIGH
              : statusText === AppointmentStatus.BOOKED
              ? badgeStyle.MEDIUM
              : badgeStyle.LOW
          }
          variant={badgeType.FILLED}
        >
          {isToday ? 'Today' : statusText}
        </Badge>
      </div>
      <div className={classes.timeWrap}>
        <div className={classes.date}>{dayjs(date).format('MMMM DD, YYYY')}</div>
        <div className={classes.date}>{getTimeZoneText(appointment.providerTimezone) ?? ''}</div>
        <div className={classes.time}>
          {`${startTimeDate.format('hh:mm a')} - ${endTimeDate.format('hh:mm a')}`}
        </div>
      </div>
      <Menu
        icon="more"
        className={classes.menu}
        itemsWrapperClassName={classes.menuItemsWrapper}
        items={[
          {
            label: 'Start session',
            icon: <Icons glyph="play-filled" className={classes.icon} />,
            onClick: () => {
              history.push(`/provider/appointments/current/${appointment.appointmentId}/session`);
            },
          },
          {
            label: 'Change appointment',
            icon: <Icons glyph="edit-underline" className={classes.icon} />,
            onClick: () => {
              onClickAppointmentAction(appointment.appointmentId);
              setShowReschedule();
            },
          },
          {
            label: 'Cancel appointment',
            icon: <Icons glyph="x-circle-outlined" className={classes.icon} />,
            onClick: () => {
              onClickAppointmentAction(appointment.appointmentId);
              setShowCancel();
            },
          },
        ]}
      />
    </div>
  );
};

export { UpcomingItem };
