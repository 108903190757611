import { CONTACT_NOTES_FLAGS } from '../../../packages/constants/CommonConstants';

export const NoteCategoriesMock = [
  {
    icon: 'close-circle-outlined',
    flag: CONTACT_NOTES_FLAGS.PROHIBITIVE,
    title: 'Prohibitive note',
    subTitle: 'High Risk: Block appointments pending clinical risk review',
  },
  {
    icon: 'flag-outlined',
    flag: CONTACT_NOTES_FLAGS.CAUTION,
    title: 'Cautionary note',
    subTitle: 'Low/Moderate Risk: Alert team for consideration',
  },
  {
    icon: 'info-outlined',
    flag: CONTACT_NOTES_FLAGS.RELEASE_OF_INFORMATION,
    title: 'Release of information note',
    subTitle: 'Document ROI requests and and upload signed releases',
  },
  {
    icon: 'lab-bottle-outlined',
    flag: CONTACT_NOTES_FLAGS.LAB_REQUEST,
    title: 'Lab request note',
    subTitle: 'Document lab requests and upload related documents',
  },
  {
    icon: 'user-report-outlined',
    flag: CONTACT_NOTES_FLAGS.CONTACT,
    title: 'Contact note',
    subTitle: 'Record any client interactions outside of the app',
  },
  {
    icon: 'report-outlined',
    flag: CONTACT_NOTES_FLAGS.GENERAL,
    title: 'General note',
    subTitle: 'Record any action taken when client was not present',
  },
  {
    icon: 'pharmacy-outlined',
    flag: CONTACT_NOTES_FLAGS.PHARMACY_ASSISTANCE,
    title: 'Pharmacy assistance note',
    subTitle: 'Document or request assistance with a pharmacy issue',
  },
  {
    icon: 'profile-arrow-left-outlined',
    flag: CONTACT_NOTES_FLAGS.REFERRAL_OUT,
    title: 'Referral out note',
    subTitle: 'Document or request assistance with an external referral',
  },
  {
    icon: 'screen-checked-outlined',
    flag: CONTACT_NOTES_FLAGS.PRIOR_AUTHORIZATION,
    title: 'Prior authorization note',
    subTitle: 'Complete or request support with a prior authorization',
  },
];
