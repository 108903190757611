/* eslint-disable max-len */
import cx from 'clsx';

// types
// styles
import { useStyles } from './shared/styles';

const AlertFilled = ({ className, color, onClick }) => {
  const classes = useStyles();
  return (
    <svg
      className={cx({
        [classes.root]: true,
        [className || '']: className,
      })}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M10 0C8.02219 0 6.08879 0.58649 4.4443 1.6853C2.79981 2.78412 1.51809 4.3459 0.761209 6.17317C0.00433284 8.00043 -0.193701 10.0111 0.192152 11.9509C0.578004 13.8907 1.53041 15.6725 2.92894 17.0711C4.32746 18.4696 6.10929 19.422 8.0491 19.8079C9.98891 20.1937 11.9996 19.9957 13.8268 19.2388C15.6541 18.4819 17.2159 17.2002 18.3147 15.5557C19.4135 13.9112 20 11.9778 20 10C20 8.68678 19.7413 7.38642 19.2388 6.17317C18.7363 4.95991 17.9997 3.85752 17.0711 2.92893C16.1425 2.00035 15.0401 1.26375 13.8268 0.761205C12.6136 0.258658 11.3132 0 10 0V0ZM11 13.5C11 13.6326 10.9473 13.7598 10.8536 13.8536C10.7598 13.9473 10.6326 14 10.5 14H9.5C9.3674 14 9.24022 13.9473 9.14645 13.8536C9.05268 13.7598 9 13.6326 9 13.5V12.5C9 12.3674 9.05268 12.2402 9.14645 12.1464C9.24022 12.0527 9.3674 12 9.5 12H10.5C10.6326 12 10.7598 12.0527 10.8536 12.1464C10.9473 12.2402 11 12.3674 11 12.5V13.5ZM10.78 9.78C10.7726 9.84098 10.7431 9.89711 10.697 9.93769C10.6509 9.97826 10.5914 10.0004 10.53 10H9.47001C9.40859 10.0004 9.34915 9.97826 9.30304 9.93769C9.25693 9.89711 9.22737 9.84098 9.22001 9.78L8.82 6.56C8.81148 6.48948 8.81808 6.41796 8.83936 6.35019C8.86064 6.28242 8.89611 6.21996 8.94342 6.16698C8.99073 6.11399 9.04878 6.0717 9.11372 6.0429C9.17865 6.01411 9.24898 5.99949 9.32 6H10.68C10.751 5.99949 10.8214 6.01411 10.8863 6.0429C10.9512 6.0717 11.0093 6.11399 11.0566 6.16698C11.1039 6.21996 11.1394 6.28242 11.1607 6.35019C11.1819 6.41796 11.1885 6.48948 11.18 6.56L10.78 9.78Z"
        fill={color}
      />
    </svg>
  );
};

export default AlertFilled;
