import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Box } from '@mui/material';

import { fontWeight, Icons, Text } from '../../../../../../packages';
import { Drawer, drawerType } from '../../../../../../packages/ui/organisms/drawer';
import { AddDctDrawer } from './AddDctDrawer';
import { useStyles } from './FlowAdd.styles';
import { SelectCBDrawer } from './SelectCBDrawer';

const FlowAdd = ({ isOpen, onClose, addCB, addDcts, dctId }) => {
  const classes = useStyles();
  const { pathname } = useLocation();
  const [, setCbDrawerOpen] = useState(false);
  const [selectCbDrawerOpen, setSelectCbDrawerOpen] = useState(false);
  const [dctDrawerOpen, setDctDrawerOpen] = useState(false);

  useEffect(() => {
    if (dctId && isOpen) {
      setCbDrawerOpen(true);
    }
  }, [dctId, isOpen]);

  return (
    <>
      {/* <AddCbDrawer
        isOpen={cbDrawerOpen}
        conversationType={conversationType}
        nextCbNumber={nextCbNumber}
        conversationId={conversationId}
        dctId={dctId}
        onClose={() => {
          setCbDrawerOpen(false);
          onClose();
        }}
        onSave={(payload: IConversationPayload) => {
          if (payload.text.trim() === '') {
            dispatch(
              showSnackbar({
                snackType: 'error',
                snackMessage: 'Title is required.',
              }),
            );
          } else {
            setCbDrawerOpen(false);
            addCB(payload);
          }
        }}
      /> */}
      <SelectCBDrawer
        isOpen={selectCbDrawerOpen}
        // dctId={dctId}
        onClose={() => setSelectCbDrawerOpen(false)}
        onSave={payload => {
          onClose();
          addCB(payload);
        }}
      />
      <AddDctDrawer
        isOpen={dctDrawerOpen}
        // dctId={dctId}
        onClose={() => setDctDrawerOpen(false)}
        onSave={payload => {
          setDctDrawerOpen(false);
          addDcts(payload);
        }}
      />
      <Drawer
        title="Select block type"
        open={isOpen}
        onClose={onClose}
        variant={drawerType.FORM}
        className={classes.drawer}
        footer={<div />}
      >
        <Box className={classes.selectTypeForm}>
          <Box className={classes.blockTypeSection} onClick={() => setSelectCbDrawerOpen(true)}>
            <Box className={classes.blockCircle1} mb={3}>
              <Icons glyph="message-outlined" />
            </Box>
            <Text size={18} weight={fontWeight.SEMI_BOLD}>
              Add Content Block
            </Text>
            <Text size={14} weight={fontWeight.SEMI_BOLD} className={classes.blockDesc}>
              Add an individual question to the conversation.
            </Text>
          </Box>

          {!dctId && !pathname.includes('collection-evaluation-dcts') && (
            <Box className={classes.blockTypeSection} onClick={() => setDctDrawerOpen(true)}>
              <Box className={classes.blockCircle2} mb={3}>
                <Icons glyph="conversations-outlined" color="#dd0374" />
              </Box>
              <Text size={18} weight={fontWeight.SEMI_BOLD}>
                Add Data Collection Template (DCT)
              </Text>
              <Text size={14} weight={fontWeight.SEMI_BOLD} className={classes.blockDesc}>
                Add a templated set of questions to the conversation.
              </Text>
            </Box>
          )}
        </Box>
      </Drawer>
    </>
  );
};

export { FlowAdd };
