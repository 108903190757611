// colors
import { colors } from '../../../../../packages/colors';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  card: {
    flex: 1,
    backgroundColor: '#fff',
    borderRadius: 12,
    padding: 56,
    boxShadow:
      '0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: 16,
    alignItems: 'center',
  },
  title: {
    fontSize: 28,
    lineHeight: '36px',
    margin: 0,
    textAlign: 'center',
  },
  subtitle: {
    fontSize: 16,
    lineHeight: '24px',
    margin: 0,
    textAlign: 'center',
    color: colors.neutral600,
    maxWidth: 464,
  },
  btn: {
    marginTop: 24,
  },
  alfie: {
    width: 200,
    height: 200,
  },
  alfie_portrait: {
    width: 'auto',
    height: 350,
  },
  alifie_landscape: {
    height: 'auto',
    width: '100%',
    maxWidth: 500,
  },
});
