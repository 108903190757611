import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/material';
import {
  Button,
  colors,
  fontWeight,
  Heading,
  headingLevel,
  Icons,
  Input,
  inputSize,
  Text,
  Textarea,
  TextError,
  textLevel,
} from '../../../packages';
import { getProfile } from '../../../redux/modules/profile/selectors';
import { sendSupportEmail } from '../../../services/profile/profile.service';
import { showSnackbar } from '../../../redux/modules/snackbar';
import { BaseLayout } from '../../../layouts/base';
import { useStyles } from './Support.styles';
import { SupportSchema } from './Support.schema';

const Support = () => {
  const classes = useStyles();
  const { profile } = useSelector(getProfile);
  const dispatch = useDispatch();
  const { errors, values, handleChange, handleSubmit, touched, ...rest } = useFormik({
    initialValues: {
      reason: '',
      message: '',
    },
    validationSchema: SupportSchema,
    onSubmit: payload => {
      const { emailAddress } = profile;
      const requestBody = {
        from: emailAddress,
        subject: payload?.reason,
        body: payload?.message,
      };
      sendSupportEmail(requestBody)
        .then(() => {
          dispatch(showSnackbar({ snackMessage: 'Email sent successfully', snackType: 'success' }));
        })
        .catch(err => {
          console.log(err);
          dispatch(
            showSnackbar({ snackMessage: 'Email sent failed! please try again later', snackType: 'error' }),
          );
        });
    },
  });

  const renderBox = (icon, text) => (
    <Box className={classes.box}>
      <Icons glyph={icon} color={colors.secondary500} />
      <Text level={textLevel.S} weight={fontWeight.SEMI_BOLD}>
        {text}
      </Text>
    </Box>
  );

  const renderLabel = label => (
    <Heading level={headingLevel.S} className={classes.label} weight={fontWeight.SEMI_BOLD}>
      {label}
    </Heading>
  );

  const setTouched = name => async () => {
    await rest.setTouched({ ...touched, [name]: true });
  };

  return (
    <BaseLayout>
      <Box className={classes.root}>
        <Box className={classes.content}>
          <Heading className={classes.heading} level={headingLevel.XL} weight={fontWeight.BOLD}>
            Contact support
          </Heading>
          <Box gap="16px" display="flex">
            {renderBox('phone', '203.747.8696')}
            {renderBox('email', 'support@confidanthealth.com')}
          </Box>
          <form onSubmit={handleSubmit} className={classes.form}>
            <Box className={classes.blockInput}>
              {renderLabel('Contact reason')}
              <Input
                value={values.reason}
                name="reason"
                placeholder="What happened?"
                onChange={handleChange}
                size={inputSize.M}
                onBlur={setTouched('reason')}
                fullWidth
                className={classes.input}
              />
              <TextError errorMsg={touched.reason ? errors.reason?.toString() : null} />
            </Box>
            <Box className={classes.blockInput}>
              {renderLabel('Message')}
              <Textarea
                value={values.message}
                name="message"
                onChange={handleChange}
                onBlur={setTouched('message')}
                minRows={5}
                placeholder="How can we help you?"
              />
              <TextError errorMsg={touched.message ? errors.message?.toString() : null} />
            </Box>
            <Button onClick={handleSubmit}>Send message</Button>
          </form>
        </Box>
      </Box>
    </BaseLayout>
  );
};

export { Support };
