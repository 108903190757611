/* eslint-disable max-len */
import cx from 'clsx';

// types
// styles
import { useStyles } from './shared/styles';

const CopyLink = ({ className, color = '#A4B4C3', onClick }) => {
  const classes = useStyles();
  return (
    <svg
      className={cx({
        [classes.root]: true,
        [className || '']: className,
      })}
      onClick={onClick}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.3033 12.9467L14.125 11.7667L15.3033 10.5883C15.693 10.202 16.0025 9.74258 16.2142 9.23633C16.4258 8.73008 16.5354 8.18701 16.5366 7.6383C16.5378 7.0896 16.4306 6.54605 16.2212 6.03888C16.0118 5.53171 15.7042 5.0709 15.3162 4.6829C14.9282 4.2949 14.4674 3.98737 13.9603 3.77794C13.4531 3.56851 12.9095 3.46132 12.3608 3.46252C11.8121 3.46372 11.2691 3.57329 10.7628 3.78494C10.2566 3.99659 9.7971 4.30614 9.4108 4.69583L8.23247 5.875L7.0533 4.69667L8.2333 3.51833C9.32732 2.42431 10.8111 1.8097 12.3583 1.8097C13.9055 1.8097 15.3893 2.42431 16.4833 3.51833C17.5773 4.61235 18.1919 6.09616 18.1919 7.64333C18.1919 9.19051 17.5773 10.6743 16.4833 11.7683L15.3041 12.9467H15.3033ZM12.9466 15.3033L11.7675 16.4817C10.6735 17.5757 9.18964 18.1903 7.64247 18.1903C6.09529 18.1903 4.61149 17.5757 3.51747 16.4817C2.42345 15.3876 1.80884 13.9038 1.80884 12.3567C1.80884 10.8095 2.42345 9.32568 3.51747 8.23167L4.69664 7.05333L5.87497 8.23333L4.69664 9.41167C4.30694 9.79796 3.99739 10.2574 3.78574 10.7637C3.5741 11.2699 3.46453 11.813 3.46333 12.3617C3.46212 12.9104 3.56931 13.4539 3.77874 13.9611C3.98817 14.4683 4.29571 14.9291 4.6837 15.3171C5.0717 15.7051 5.53251 16.0126 6.03968 16.2221C6.54685 16.4315 7.0904 16.5387 7.63911 16.5375C8.18781 16.5363 8.73088 16.4267 9.23713 16.2151C9.74338 16.0034 10.2028 15.6939 10.5891 15.3042L11.7675 14.1258L12.9466 15.3042V15.3033ZM12.3566 6.46417L13.5358 7.64333L7.6433 13.535L6.46414 12.3567L12.3566 6.465V6.46417Z"
        fill={color}
      />
    </svg>
  );
};

export default CopyLink;
