import { FC, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import clsx from 'clsx';
import { useSelector } from 'react-redux';

import { Drawer, drawerType } from '../../../../../packages/ui/organisms/drawer';
import { Input, inputType, inputSize } from '../../../../../packages/ui/atoms/input';
import { iconBtnType, IconButton } from '../../../../../packages/ui/molecules/icon-button';
import { colors } from '../../../../../packages/colors';
import { Icons } from '../../../../../packages/icons';

import DiagnosesCard from '../../../member-detail/components/Diagnoses/diagnoses-card';
import { useStyles } from '../history-drawer/HistoryDrawer.styles';
import EmptyState from '../empty-state';
import { ProfileInfoRole } from '../../../../../components/v2/ProfileInfo';
import { getProfile } from '../../../../../redux/modules/profile/selectors';
import { getAuth } from '../../../../../redux/modules/auth/selectors';

const DiagnosesDrawer = ({
  open,
  isNoDrawer,
  onClose,
  onClickNewReport,
  onClickCard,
  items = [],
  appointment,
  loading,
  isSession = true,
}) => {
  const classes = useStyles();
  const { isAdmin } = useSelector(getAuth);
  const { profile } = useSelector(getProfile);
  const [search, setSearch] = useState('');
  const [list, setList] = useState(items);

  useEffect(() => {
    const filteredList = items.filter(item => item.name.toLowerCase().includes(search.toLowerCase()));
    setList(filteredList);
  }, [search, items]);

  const renderContent = () => {
    return (
      <Box className={classes.container}>
        <Box className={classes.searchWrap}>
          <Input
            variant={inputType.SEARCH}
            className={classes.searchInput}
            placeholder="Search"
            size={inputSize.M}
            value={search}
            onChange={setSearch}
          />
          {(profile?.designation === ProfileInfoRole.NURSE_PRACTITIONER ||
            profile?.designation === ProfileInfoRole.PHYSICIAN ||
            profile?.designation === ProfileInfoRole.THERAPIST ||
            isAdmin) && (
            <IconButton
              variant={iconBtnType.PRIMARY}
              className={classes.reportNewBtn}
              onClick={onClickNewReport}
            >
              Report new
            </IconButton>
          )}
        </Box>

        <Box className={classes.items}>
          {loading ? (
            <Box display="flex" justifyContent="center" paddingY="10px">
              <Icons className="rotate linear infinite" glyph="in-progress" color={colors.primary} />
            </Box>
          ) : list.length > 0 ? (
            list.map((item, index) => (
              <DiagnosesCard
                key={index}
                item={item}
                onClick={onClickCard}
                isOnlyView={!onClickCard}
                onClickFullReport={onClose}
                className={classes.diagnosesCard}
              />
            ))
          ) : (
            <EmptyState
              title={`No Diagnoses reported for ${appointment?.participantName || 'this member'} yet.`}
              subtitle="If you're ready to make a Diagnosis for them, you can do so here."
              glyph="face-talking-interested"
            />
          )}
        </Box>
      </Box>
    );
  };

  if (isNoDrawer) {
    return renderContent();
  }

  return (
    <Drawer
      open={open}
      title={isSession ? 'Diagnoses' : 'Active diagnosis'}
      subTitle={loading ? '' : `${list.length} total`}
      onClose={onClose}
      variant={drawerType.FORM}
      className={clsx(classes.drawer, classes.drawerWithSubtitle)}
      footer={<span />}
    >
      {renderContent()}
    </Drawer>
  );
};

export { DiagnosesDrawer };
