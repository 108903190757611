import { colors } from '../../../../../../packages';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  table: {
    width: '100%',
    boxShadow: 'none',
    '& tbody tr': {
      cursor: 'default',
    },
  },
  badge: {
    fontSize: 14,
    lineHeight: '20px',
    height: 20,
    fontWeight: 600,
    backgroundColor: '#FFF1F3',
    borderColor: '#FFF1F3',
    color: '#E31B53',
    padding: '4px 6px',
    margin: 'auto',
    '& svg': {
      width: 16,
      marginRight: 4,
      transform: 'rotate(270deg)',
      '& path': {
        fill: '#E31B53',
      },
    },
  },
  badgeNoPercentage: {
    fontSize: 14,
    lineHeight: '20px',
    height: 20,
    fontWeight: 600,
    backgroundColor: '#d6d6d6',
    borderColor: '#d6d6d6',
    color: '#a3a3a3',
    padding: '4px 6px',
    margin: 'auto',
    '& svg': {
      width: 16,
      marginRight: 4,
      transform: 'rotate(270deg)',
      '& path': {
        fill: '#E31B53',
      },
    },
  },
  badgeUp: {
    borderColor: colors.green50,
    backgroundColor: colors.green50,
    color: colors.green500,
    '& svg path': {
      fill: colors.green500,
    },
  },
});
