import { colors } from '../../../../../packages';

export const getChartOpts = (series, donutOpts) => {
  const labels = series.map(({ name }) => name);
  return {
    chart: {
      height: '100%',
    },
    title: {
      text: '',
      offsetX: 285,
      offsetY: 20,
      style: {
        fontSize: '18px',
        fontWeight: '600',
        lineHieght: '28px',
        fontFamily: 'Manrope',
        color: colors.neutral900,
      },
    },
    labels,
    plotOptions: {
      pie: {
        offsetX: -30,
        offsetY: -10,
        donut: {
          size: '80%',
          labels: {
            show: true,
            total: {
              showAlways: true,
              show: true,
              label: donutOpts.title,
              fontSize: '14px',
              fontFamily: 'Manrope',
              fontWeight: 600,
              color: colors.neutral600,
              formatter: () => String(donutOpts.total),
            },
            name: {
              show: true,
              offsetY: 20,
            },
            value: {
              show: true,
              fontSize: '18px',
              label: 12,
              fontFamily: 'Manrope',
              fontWeight: 700,
              color: colors.neutral900,
              offsetY: -20,
            },
          },
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    colors: [colors.success500, colors.orange500, colors.neutral500],
    stroke: {
      width: 4,
    },
    legend: {
      fontSize: '16px',
      fontFamily: 'Manrope',
      fontWeight: 500,
      offsetX: 36,
      offsetY: 0,
      formatter: (seriesName, opt) => {
        return `<div class="legend-wrap">
            <div class="legend-wrap-left">
              <span>${seriesName}</span>
              <span class="legend-badge-value">${opt.w.globals.series[opt.seriesIndex]}% </span>
            </div>
            <div class="legend-text-changed">
              ${series[opt.seriesIndex].changedValue}
            </div>
          </div>`;
      },
      itemMargin: {
        horizontal: 0,
        vertical: 8,
      },
    },
  };
};
