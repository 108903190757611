import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Box, Stack } from '@mui/material';
import clsx from 'clsx';

// components
import {
  Badge,
  badgeSize,
  badgeStyle,
  badgeType,
  btnType,
  colors,
  fontWeight,
  Heading,
  headingLevel,
  IconButton,
  Icons,
  Menu,
} from '../../../packages';
import { Filter, Table } from '../../../packages/ui/organisms/table';
import { ProfileInfo } from '../../../packages/ui/templates/profile-info';
import {
  AppointmentStatus,
  AppointmentStatusBagde,
  AppointmentStatusText,
  AppointmentType,
  SignOffStatus,
} from '../../../packages/constants/CommonConstants';
import { Tabs } from '../../../packages/ui/atoms/tabs';
import { showSnackbar } from '../../../redux/modules/snackbar';
import history from '../../../utils/history';
import dayjs, { getUserTimeZone } from '../../../utils/dayjs';
import { isAppointmentDone } from '../../../utils/CommonUtils';
import { BaseLayout } from '../../../layouts/base';
import { conversationActionCreators } from '../../../redux/modules/conversation';
import { appointmentActionCreators } from '../../../redux/modules/appointment';
import { reportServiceActionCreators } from '../../../redux/modules/report-service';
import { profileActionCreators } from '../../../redux/modules/profile';
import { getReportService } from '../../../redux/modules/report-service/selectors';
import { selectProviderSchedule } from '../../../redux/modules/schedule/selectors';
import { selectEvaluation } from '../../../redux/modules/conversation/selectors';
import { getAuth } from '../../../redux/modules/auth/selectors';
import { DROPDOWN_FILTER_COLLAPSABLE_TYPE } from '../../../packages/ui/organisms/table/filter';
import { getProfile } from '../../../redux/modules/profile/selectors';
import { AppointmentCancel } from '../../provider/appointments/components/appointment-cancel/AppointmentCancel';
import { SUPPORTED_AUTHORITIES } from '../../../constants/CommonConstants';
import { saveAs } from 'file-saver';
import { exportAppointmentStats } from '../../../services/reporting/reporting.service';

import AppointmentDetailDrawer from './appointment-detail-drawer';
import AddSchedule from './add-schedule';

import {
  appointmentColumns,
  appointmentFilterStatusOptions,
  appointmentSorterMap,
  AppointmentTitles,
  currentAppointmentsFilterOption,
  defaultCurrentTabs,
  defaultPastTabs,
  defaultPendingTabs,
  pastAppointmentsFilterOption,
  pendingAppointmentsFilterOption,
  tabQueryParams,
} from './AppointmentList.constants';

// styles
import { useStyles } from './AppointmentList.styles';
import { exportAppointments, getAppointmentCounts } from '../../../services/appointment/appointment.service';
import AppointmentScheduling from '../../provider/appointments/components/appointment-scheduling';

const AppointmentList = () => {
  const classes = useStyles();
  const { appointmentType } = useParams();
  const { isAdmin, meta } = useSelector(getAuth);
  const dispatch = useDispatch();
  const { profile } = useSelector(getProfile);
  const [isLoadingExport] = useState(false);
  const filterOptions = appointmentFilterStatusOptions[appointmentType];
  const [title, setTitle] = useState(AppointmentTitles.current);
  const [cancelAppointment, setCancelAppointment] = useState(null);
  const [rescheduleAppointment, setRescheduleAppointment] = useState(null);
  const [appointmentStatsExportLoading, setAppointmentStatsExportLoading] = useState(false);

  const [tableParams, setTableParams] = useState({
    search: { searchKey: '' },
    pagination: { currentPage: 1, rowsPerPage: 10 },
    sorter: {
      direction:
        appointmentType === AppointmentType.PAST ||
        appointmentType === AppointmentType.CURRENT ||
        appointmentType === AppointmentType.CANCELLED
          ? 'desc'
          : 'asc',
      column: '',
    },
  });

  const [appointments, setAppointments] = useState([]);
  const [showAddSchedule, setShowAddSchedule] = useState(false);
  const [isProviderSelected] = useState(false);
  const [resetMultiSelectFilter, setResetMultiSelectFilter] = useState(false);
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [screenChangeLoading, setScreenChangeLoading] = useState(false);

  const providerSchedule = useSelector(selectProviderSchedule);
  const providers = useSelector(state => state.profile.providers);
  const { isLoading, appointments: appointmentsState = [], totalRecords } = useSelector(getReportService);
  const evaluations = useSelector(selectEvaluation);
  const signOffStatuses = SignOffStatus;

  const [tabType, setTabType] = useState('total');
  const [tabs, setTabs] = useState([...defaultPendingTabs]);
  const [counts, setCounts] = useState(null);

  const renderTab = tab => {
    return {
      tabKey: tab.tabKey,
      label: tab.label || tab.tabKey,
      icon: (
        <Badge className={classes.countBadge} size={badgeSize.XSMALL}>
          {counts?.[tab.countKey] || 0}
        </Badge>
      ),
      iconPosition: 'end',
    };
  };

  const generateMenuItems = appointment => {
    const items = [{ label: 'View', onClick: () => onClickAppointment(appointment) }];
    if (
      (appointment.status === AppointmentStatus.PROPOSED &&
        appointment.patientStatus === AppointmentStatus.NEEDS_ACTION) ||
      appointment.status === AppointmentStatus.BOOKED
    ) {
      items.push({ label: 'Reschedule', onClick: () => setRescheduleAppointment(appointment) });
    }
    if (isAdmin && appointment.status !== AppointmentStatus.CANCELLED) {
      items.push({ label: 'Cancel', onClick: () => setCancelAppointment(appointment) });
    }
    return items;
  };
  const multiSelectFilterOptions = useMemo(() => {
    setTableParams({
      search: { searchKey: '' },
      pagination: { currentPage: 1, rowsPerPage: 10 },
      sorter: {
        direction:
          appointmentType === AppointmentType.PAST ||
          appointmentType === AppointmentType.CURRENT ||
          appointmentType === AppointmentType.CANCELLED
            ? 'desc'
            : 'asc',
        column: '',
      },
    });
    if (
      tableParams.search?.multiSelectFilter &&
      Object.keys(tableParams.search?.multiSelectFilter)?.length > 0
    ) {
      setResetMultiSelectFilter(true);
    }
    setScreenChangeLoading(true);
    setTimeout(() => {
      setScreenChangeLoading(false);
    }, 100);
    switch (appointmentType) {
      case AppointmentType.CURRENT:
        return currentAppointmentsFilterOption(providers ?? []);
      case AppointmentType.PAST:
        return pastAppointmentsFilterOption(providers ?? []);
      default:
        return pendingAppointmentsFilterOption(providers ?? []);
    }
  }, [appointmentType, providers]);

  const onClickAppointment = appointment => {
    if (appointmentType === 'past') {
      dispatch(profileActionCreators.updateAppointmentNote(appointment));
      history.push(`/admin/members/${appointment.participantId}/appointments/${appointment.appointmentId}`);
      return;
    }
    if (appointmentType !== AppointmentType.CANCELLED) {
      dispatch(
        conversationActionCreators.fetchAssignedEvaluations({ appointmentId: appointment.appointmentId }),
      );
    }
    setSelectedAppointment(appointment);
  };
  const getEmptyStartEndDates = () => {
    const startDate = '';
    const endDate = '';
    return { startDate, endDate };
  };
  const { startDate, endDate } = getEmptyStartEndDates();

  const generateMultiselectParams = () => {
    // const filters = generateFilters();
    const { pagination, sorter } = tableParams;
    const sortBy = appointmentSorterMap[sorter.column] || 'startTime';
    const page = pagination.currentPage - 1;
    const size = pagination.rowsPerPage;
    const orderBy = sorter.direction;
    const statuses =
      appointmentType === AppointmentType.CURRENT
        ? 'BOOKED'
        : appointmentType === AppointmentType.PAST
        ? 'FULFILLED,NO_SHOW,BOOKED'
        : appointmentType === AppointmentType.CANCELLED
        ? 'CANCELLED'
        : '';
    const queryParams = {
      orderBy,
      page,
      size,
      sortBy,
      statuses,
      type: appointmentType === AppointmentType.CANCELLED ? 'PAST' : appointmentType.toUpperCase(),
      states: null,
      searchQuery: tableParams.search.searchKey,
    };
    if (tableParams?.search?.dateFilter?.startDate || (startDate && startDate !== '')) {
      queryParams.startTime = dayjs(tableParams?.search?.dateFilter?.startDate || startDate)?.toISOString();
    }
    if (tableParams?.search?.dateFilter?.endDate || (endDate && endDate !== '')) {
      queryParams.endTime = dayjs(tableParams?.search?.dateFilter?.endDate || endDate)?.toISOString();
    }
    const multiselectFilters = tableParams.search?.multiSelectFilter;
    if (multiselectFilters && Object.keys(multiselectFilters)?.length > 0) {
      if (multiselectFilters.State?.length > 0) {
        queryParams.states = multiselectFilters.State?.toString() ?? '';
      }
      if (multiselectFilters.Role?.length > 0) {
        queryParams.practitionerDesignations = multiselectFilters.Role?.toString() ?? '';
      }
      queryParams.vbcFlag = multiselectFilters?.VBC?.length > 0 || false;
      if (multiselectFilters[DROPDOWN_FILTER_COLLAPSABLE_TYPE.PROVIDERS]?.length > 0) {
        queryParams.participantIds =
          multiselectFilters[DROPDOWN_FILTER_COLLAPSABLE_TYPE.PROVIDERS]
            ?.map(provider => {
              return provider.id;
            })
            ?.toString() ?? '';
      }
      if (appointmentType === AppointmentType.CURRENT && multiselectFilters.Status?.length > 0) {
        if (multiselectFilters.Status[0] === 'Scheduled') {
          queryParams.dateStatus = 'NOT_TODAY';
        } else if (multiselectFilters.Status[0] === 'Today') {
          queryParams.dateStatus = 'TODAY';
        }
        queryParams.timeZone = getUserTimeZone();
      } else if (appointmentType === AppointmentType.PAST && multiselectFilters.Status?.length > 0) {
        queryParams.statuses =
          multiselectFilters.Status.map(item => {
            if (item === 'NO SHOW') {
              return AppointmentStatus.NO_SHOW;
            }
            return item;
          })?.toString() ?? '';
      }

      // if (appointmentType === AppointmentType.CURRENT) {
      //   const sortByy = queryParams.orderBy === 'asc' ? 'desc' : 'asc';
      //   queryParams.orderBy = sortByy;
      // }
    }

    if (tabType !== 'total') {
      const tabParams = tabQueryParams[tabType] || {};
      Object.assign(queryParams, tabParams);
    }

    if (!queryParams.states) {
      delete queryParams.states;
    }

    return { queryParams };
  };

  const fetchAppointments2 = (providerId, timeZone) => {
    const filters = [{ searchField: 'status', searchQuery: 'BOOKED' }];
    const queryParams = {
      orderBy: 'desc',
      pageNumber: 0,
      size: 180,
      sortBy: 'startTime',
      statuses: 'BOOKED',
      type: 'CURRENT',
      searchQuery: '',
    };
    const bodyRequest = {
      providerId,
      refDate: dayjs().format('DD-MM-YYYY'),
      timezone: timeZone || getUserTimeZone(),
      type: 'current',
      textSearch: '',
      size: 180,
      filters,
    };
    dispatch(
      appointmentActionCreators.fetchAppointments({
        bodyRequest,
        queryParams: { ...queryParams },
      }),
    );
  };

  const fetchAppointments = (fetchCount = true) => {
    const { queryParams } = generateMultiselectParams();
    dispatch(reportServiceActionCreators.fetchAppointments({ queryParams }));
    if (isAdmin && fetchCount) dispatch(appointmentActionCreators.fetchAllAppointmentCounts());
  };

  const onSubmitNewSchedule = payload => {
    dispatch(
      appointmentActionCreators.createAppointment({
        data: { ...payload, timezone: providerSchedule?.timezone },
        callback: (isSucceed, isInstantSession, appointmentId) => {
          if (isSucceed) {
            setShowAddSchedule(false);
            fetchAppointments();
            if (!isAdmin) {
              fetchAppointments2(profile.providerId, providerSchedule.timezone);
            }
            if (isInstantSession) {
              history.push(`/provider/appointments/current/${appointmentId}/session`);
            }
          }
        },
      }),
    );
  };

  useEffect(() => {
    const queryParams = {
      searchQuery: '',
      pageNumber: 1,
      pageSize: 1000,
      orderBy: '',
      sortBy: '',
    };
    dispatch(conversationActionCreators.fetchEvaluations());
    dispatch(profileActionCreators.fetchProviders(queryParams));
  }, []);

  const getCounts = async () => {
    if (appointmentType === AppointmentType.CANCELLED) {
      return;
    }
    const queryParams = { type: appointmentType.toUpperCase(), timeZone: getUserTimeZone() };
    const response = await getAppointmentCounts(queryParams);
    if (response?.data) {
      setCounts(response?.data);
    }
  };

  const changeTab = apptType => {
    switch (apptType) {
      case AppointmentType.PENDING:
        return [...defaultPendingTabs];
      case AppointmentType.CURRENT:
        return [...defaultCurrentTabs];
      case AppointmentType.PAST:
        return [...defaultPastTabs];
      default:
        return [];
    }
  };

  useEffect(() => {
    if (appointmentType) {
      setTabs(changeTab(appointmentType));
      setTitle(AppointmentTitles[appointmentType]);
      if (tabType !== 'total') {
        setTabType('total');
      } else {
        fetchAppointments();
      }
      void getCounts();
    }
  }, [appointmentType]);

  useEffect(() => {
    if (tabType) {
      tableParams.pagination.currentPage = 1;
      fetchAppointments(false);
    }
  }, [tabType]);

  useEffect(() => {
    setResetMultiSelectFilter(false);
    if (appointmentType) {
      fetchAppointments();
    }
  }, [tableParams]);

  useEffect(() => {
    const formatAppointments = appointmentsState.map(item => ({
      ...item,
      appointmentStatus: {
        status: item.status,
        time: item.startTime,
        patientStatus: item.patientStatus,
        statusChangingAuthority: item.statusChangingAuthority,
        practitionerStatus: item.practitionerStatus,
        signOffStatus: item.signOffStatus,
      },
      appointmentTime: {
        timezone: item.providerTimezone,
        startTime: item.startTime,
        endTime: item.endTime,
        isRecurring: !!item.recurringType,
      },
      actions: {
        appointmentId: item.appointmentId,
        disabled: isAppointmentDone(item.status),
        ...item,
      },
      provider: {
        practitionerName: item.practitionerName,
        practitionerImage: item.practitionerImage,
        practitionerDesignation: item.practitionerDesignation,
        practitionerId: item.practitionerId,
      },
      member: {
        participantImage: item.participantImage,
        patientFirstName: item.patientFirstName,
        patientLastName: item.patientLastName,
        participantName: item.participantName,
        participantId: item.participantId,
        patientUuid: item.patientUuid,
      },
    }));
    setAppointments(formatAppointments);
  }, [appointmentsState]);

  const renderColumns = useCallback(columns => {
    return columns.map(column => {
      if (column.id === 'provider') {
        return {
          ...column,
          renderCell: ({ practitionerId, practitionerName, practitionerImage, practitionerDesignation }) => (
            <ProfileInfo
              type="provider"
              photo={practitionerImage}
              role={practitionerDesignation}
              fullName={practitionerName}
              memberId={practitionerId}
            />
          ),
        };
      }
      if (column.id === 'member') {
        return {
          ...column,
          renderCell: ({
            participantId,
            participantImage,
            patientFirstName,
            patientLastName,
            participantName,
            patientUuid,
          }) => (
            <ProfileInfo
              type="member"
              photo={participantImage}
              nickName={
                patientFirstName && patientLastName
                  ? `${patientFirstName || ''} ${patientLastName || ''}`
                  : participantName ?? 'No Name'
              }
              fullName={patientUuid}
              memberId={participantId}
            />
          ),
        };
      }
      if (column.id === 'appointmentStatus') {
        return {
          ...column,
          renderCell: ({
            status,
            time,
            practitionerStatus,
            patientStatus,
            signOffStatus,
            statusChangingAuthority,
          }) => {
            const isToday = dayjs(time).isSame(dayjs(), 'day');
            let statusText = AppointmentStatusText[status];
            let waitingForSignOff = false;

            if (status === AppointmentStatus.FULFILLED && signOffStatus === signOffStatuses.DRAFTED) {
              statusText = 'Waiting for Sign off';
              waitingForSignOff = true;
            }
            if (status === AppointmentStatus.PROPOSED || status === 'PENDING') {
              if (patientStatus === AppointmentStatus.NEEDS_ACTION) {
                statusText = 'Waiting on Member';
              } else if (practitionerStatus === AppointmentStatus.NEEDS_ACTION) {
                statusText = 'Waiting on Provider';
              }
            } else if (status === AppointmentStatus.CANCELLED) {
              if (patientStatus === AppointmentStatus.DECLINED) {
                statusText = 'Cancelled - M';
              } else if (practitionerStatus === AppointmentStatus.DECLINED) {
                statusText = 'Cancelled - P';
              }
            } else if (status === AppointmentStatus.INACTIVE) {
              statusText = 'Cancelled';
            }
            if (status === AppointmentStatus.NO_SHOW) {
              if (statusChangingAuthority === 'PATIENT') {
                statusText = 'No Show - M';
              } else if (statusChangingAuthority === 'PRACTITIONER') {
                statusText = 'No Show - P';
              }
            }

            if (status === AppointmentStatus.BOOKED) {
              statusText = isToday ? 'Today' : 'Scheduled';
            }

            return (
              <Badge
                variant={badgeType.FILLED}
                style={
                  waitingForSignOff
                    ? AppointmentStatusBagde.SIGN_OFF
                    : isToday
                    ? badgeStyle.MISREPORTED
                    : AppointmentStatusBagde[status] || badgeStyle.UNRELATED
                }
                className={classes.status}
              >
                {statusText?.toLowerCase()?.includes('cancel') ? (
                  <span className={classes.cancelledStatusText}>{statusText}</span>
                ) : (
                  statusText
                )}
              </Badge>
            );
          },
        };
      }
      if (column.id === 'appointmentTime') {
        return {
          ...column,
          renderCell: ({ startTime, endTime, isRecurring }) => (
            <div className={classes.timeWrap}>
              <div className={clsx(classes.date, classes.row)}>
                {dayjs(startTime).format('MM/DD/YYYY')}
                {isRecurring && <Icons glyph="recurring" color={colors.secondary500} />}
              </div>
              <div className={classes.time}>
                {dayjs(startTime).format('h:mm a')} - {dayjs(endTime).format('h:mm a')}
              </div>
            </div>
          ),
        };
      }
      if (column.id === 'actions') {
        return {
          ...column,
          renderCell: appointment => {
            const menuItems = generateMenuItems(appointment);
            return (
              <Menu
                icon="more"
                className={classes.menu}
                itemsWrapperClassName={classes.menuItemsWrapper}
                items={menuItems}
              />
            );
          },
        };
      }
      return column;
    });
  }, []);
  const exportList = () => {
    const { queryParams } = generateMultiselectParams();
    exportAppointments(queryParams)
      .then(() => {
        dispatch(
          showSnackbar({
            snackType: 'success',
            snackMessage: `Export started. We'll notify you when it's ready.`,
          }),
        );
      })
      .catch(err => {
        console.warn(err);
        dispatch(
          showSnackbar({
            snackType: 'error',
            snackMessage: 'Error downloading!',
          }),
        );
      });
  };

  const onExportClick = () => {
    setAppointmentStatsExportLoading(true);

    exportAppointmentStats()
      .then(res => {
        if (res.status === 200) {
          const blob = new Blob([res.data], {
            type: 'application/vnd.ms-excel',
          });
          saveAs(blob, 'Past appointments stats.xlsx');
          setAppointmentStatsExportLoading(false);
          dispatch(
            showSnackbar({
              snackType: 'success',
              snackMessage: 'File exported successfully',
            }),
          );
        }
      })
      .catch(err => {
        setAppointmentStatsExportLoading(false);
        dispatch(
          showSnackbar({
            snackType: 'error',
            snackMessage: err?.data?.errors?.[0]?.endUserMessage || 'Downloading failed',
          }),
        );
      });
  };

  return (
    <BaseLayout>
      {!isProviderSelected && (
        <AppointmentDetailDrawer
          open={!!selectedAppointment}
          onClose={() => setSelectedAppointment(null)}
          appointment={selectedAppointment}
          evaluations={evaluations}
          fetchAppointments={() => fetchAppointments()}
        />
      )}
      <AddSchedule
        isOpen={showAddSchedule}
        // isSaving={isRequesting}
        onClose={() => setShowAddSchedule(false)}
        onSubmit={onSubmitNewSchedule}
      />
      <Box className={classes.root}>
        <Stack direction="row" justifyContent="space-between" spacing={2} sx={{ marginBottom: 5 }}>
          <Stack direction="row" alignItems="center" gap={2} sx={{ height: 48 }}>
            <Heading className={classes.heading} level={headingLevel.XL} weight={fontWeight.BOLD}>
              {title}
            </Heading>
            {!isLoading && (
              <Badge className={classes.totalBadge} variant={badgeType.OUTLINED} style={badgeStyle.UNRELATED}>
                {totalRecords} total
              </Badge>
            )}
          </Stack>
          <Stack direction="row" gap={2}>
            {appointmentType === AppointmentType.PAST && (
              <IconButton
                icon="export"
                variant={btnType.OUTLINE}
                className={classes.outlineBtn}
                onClick={onExportClick}
                disabled={appointmentStatsExportLoading}
              >
                {appointmentStatsExportLoading ? 'Loading' : 'Export stats'}
              </IconButton>
            )}
            <IconButton
              icon="appointment"
              variant={btnType.PRIMARY}
              className={classes.addBtn}
              onClick={() => setShowAddSchedule(true)}
            >
              Schedule new
            </IconButton>
          </Stack>
        </Stack>
        <Box className={classes.appointmentList}>
          {screenChangeLoading ? (
            <div className={classes.loader}>
              <Icons className="rotate linear infinite" glyph="in-progress" color={colors.primary} />
              Loading...
            </div>
          ) : (
            <>
              {appointmentType !== AppointmentType.CANCELLED && (
                <Tabs
                  value={tabType}
                  onChange={setTabType}
                  options={tabs.map(renderTab)}
                  className={classes.tabs}
                />
              )}
              <Table
                searchProps={{
                  placeholder: 'Search appointment by member and provider',
                  resetMultiSelectFilter,
                  filterProps: {
                    variant: Filter.tableFilterType.MULTIPLE,
                    options: filterOptions,
                    multiSelectOptions: multiSelectFilterOptions,
                    dateFilter: {
                      startDate:
                        tableParams?.search?.dateFilter?.startDate || getEmptyStartEndDates()?.startDate,
                      endDate: tableParams?.search?.dateFilter?.endDate || getEmptyStartEndDates()?.endDate,
                    },
                  },
                  exportProps:
                    appointmentType === AppointmentType.PAST
                      ? {
                          btnTitle: 'Export',
                          isLoadingExport,
                        }
                      : null,
                }}
                onClickExport={exportList}
                gridProps={{
                  columns: renderColumns(appointmentColumns),
                  data: appointments,
                  isLoading,
                  onRowClick: onClickAppointment,
                }}
                paginationProps={{
                  currentRows: appointments?.length || 0,
                  totalCount: totalRecords,
                  showRowsPerPage: true,
                }}
                value={tableParams}
                onChange={setTableParams}
              />
            </>
          )}
        </Box>
      </Box>
      {cancelAppointment && (
        <AppointmentCancel
          open={cancelAppointment}
          onClose={() => {
            fetchAppointments();
            setCancelAppointment(null);
          }}
          appointment={cancelAppointment}
          updateAppointment={() => console.log('appointment updated')}
        />
      )}
      {rescheduleAppointment && (
        <AppointmentScheduling
          open={rescheduleAppointment}
          onClose={() => {
            fetchAppointments();
            setRescheduleAppointment(null);
          }}
          appointment={rescheduleAppointment}
          updateAppointment={setRescheduleAppointment}
          hideCancelBtn
        />
      )}
    </BaseLayout>
  );
};

export { AppointmentList };
