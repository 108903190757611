import { makeStyles } from '@mui/styles';
// colors
import { colors } from '../../../../../packages';

export const useStyles = makeStyles({
  root: {
    backgroundColor: colors.white,
  },
  head: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '20px 24px',
  },
  headRight: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    color: colors.neutral900,
    fontSize: 18,
    fontWeight: 600,
  },
  reportMonthWrap: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: 16,
    padding: 24,
    flexWrap: 'wrap',
    backgroundColor: colors.neutral25,
    borderTop: `1px solid ${colors.neutral100}`,
    borderBottom: `1px solid ${colors.neutral100}`,
  },
  reportMonthItem: {
    flex: 1,
    '@media (max-width: 996px)': {
      flex: 'unset',
      width: '48%',
    },
    '@media (max-width: 640px)': {
      flex: 'unset',
      width: '100%',
    },
  },
  menuLabel: {
    color: colors.neutral700,
    '& ul li': {
      color: colors.neutral700,
    },
  },
  chartWrap: {
    padding: '24px 10px',
  },
});
