import { FC } from 'react';

import { PrioritiesCard } from '../../../priority-list/PriorityList.constants';
import SymptomDetail from '../../../../member-detail/components/Symptoms/symptom-detail';
import DiagnosesDetail from '../../../../member-detail/components/Diagnoses/diagnoses-detail';
import SideEffectDetail from '../../../../member-detail/components/SideEffects/components/side-effect-detail';
import MedicationDetail from '../../../../member-detail/components/Medications/medication-detail';
import SubstanceUseDetail from '../../../../member-detail/components/SubstanceUse/substance-use-detail';

const ViewPriorityCard = ({ sectionType, card, isNoDrawer, lookupData, onCloseCard, onClickNewReport }) => {
  const getCard = () => {
    switch (sectionType) {
      case PrioritiesCard.SocialDeterminants:
      case PrioritiesCard.SideEffects:
        return (
          <SideEffectDetail
            open
            item={card}
            onClose={onCloseCard}
            isNoDrawer={isNoDrawer}
            onClickNewReport={onClickNewReport}
            priorityType={sectionType}
          />
        );
      case PrioritiesCard.Symptoms:
        return (
          <SymptomDetail
            open
            symptom={card}
            onClose={onCloseCard}
            isNoDrawer={isNoDrawer}
            onClickNewReport={onClickNewReport}
            priorityType={PrioritiesCard.Symptoms}
          />
        );
      case PrioritiesCard.Diagnoses:
      case PrioritiesCard.LifeEvents:
        return (
          <DiagnosesDetail
            open
            diagnoses={card}
            onClose={onCloseCard}
            isNoDrawer={isNoDrawer}
            onClickNewReport={onClickNewReport}
            priorityType={sectionType}
          />
        );
      case PrioritiesCard.Medications:
      case PrioritiesCard.CognitiveIssues:
        return (
          <MedicationDetail
            open
            medication={card}
            onClose={onCloseCard}
            isNoDrawer={isNoDrawer}
            onClickNewReport={onClickNewReport}
            priorityType={sectionType}
          />
        );
      case PrioritiesCard.SubstanceUse:
        return (
          <SubstanceUseDetail
            open
            item={card}
            onClose={onCloseCard}
            lookupData={lookupData}
            isNoDrawer={isNoDrawer}
            onClickNewReport={onClickNewReport}
            priorityType={PrioritiesCard.SubstanceUse}
          />
        );
      default:
        return null;
    }
  };

  return <>{getCard()}</>;
};

export { ViewPriorityCard };
