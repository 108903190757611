import addNotification from 'react-push-notification';
import SocketClient from '../utils/SocketClient';
import { CONNECTIONS_TYPES } from '../constants/CommonConstants';
import getConfig from '../config';
import { socketActionCreators } from '../redux/modules/socket';
import SocketNotificationTypes from './SocketNotificationTypes';
import SocketScreens from './SocketScreens';

class SocketListener {
  static initSocket(meta, store) {
    SocketClient.init(getConfig.api.socketClientUrl, SocketListener.attachLocalListeners);
    SocketClient.getInstance().connect(meta?.aliasId ?? meta.userId, meta.authority);

    SocketListener.store = store;
    SocketClient.getInstance().getOnlineUsers(users => {
      SocketListener.dispatchReduxAction(socketActionCreators.getOnlineUsers, users);
    });
  }

  static dispatchReduxAction(action, payload) {
    SocketListener.store.dispatch(action(payload));
  }

  static createNotification(notification, clickListener) {
    addNotification({
      title: notification.title,
      message: notification.subtitle,
      theme: 'darkblue',
      native: true, // when using native, your OS will handle theming.
      onClick: clickListener,
    });
  }

  static attachLocalListeners(socket) {
    console.log('Listeners attached');
    if (socket) {
      socket.on('app-notification-received', data => {
        console.log('Got a live app notification');
        SocketListener.dispatchReduxAction(socketActionCreators.inAppNotification, {
          open: true,
          data,
        });
        let clickListener;
        switch (data.notificationType) {
          case SocketNotificationTypes.chatMessageReceived:
          case SocketNotificationTypes.groupMessageReceived:
            clickListener = this.createMessageNoitificationClickListener(data);
            SocketListener.createNotification(data, clickListener);
            break;
          case SocketNotificationTypes.appointmentRequested:
            clickListener = this.createAppointmentRequestedNotificationListener(data);
            SocketListener.createNotification(data, clickListener);
            break;
          case SocketNotificationTypes.appointmentNeedsAction:
            clickListener = this.createAppointmentNeedsActionNotificationListener(data);
            SocketListener.createNotification(data, clickListener);
            break;
          case SocketNotificationTypes.appointmentConfirmed:
            clickListener = this.createAppointmentConfirmedNotificationListener(data);
            SocketListener.createNotification(data, clickListener);
            break;
          case SocketNotificationTypes.appointmentCancelled:
            clickListener = this.createAppointmentCancelledNotificationListener(data);
            this.createNotification(data, clickListener);
            break;
          default:
            break;
        }
      });
      socket.on('online-users-update', users => {
        SocketListener.dispatchReduxAction(socketActionCreators.getOnlineUsers, users);
      });
    }
  }

  static createAppointmentRequestedNotificationListener(data) {
    /**
     * Psyload Recieved From Socket:
     * {
          "subtitle": "Provider ckent requested an appointment with you and gchgfhg",
          "to": "623425f136d1a000017b8779",
          "notificationType": "APPOINTMENT_REQUESTED",
          "title": "New appointment requested"
      }
    */
    const state = SocketListener.store.getState();
    const { isAdmin } = state.auth;
    // Not enough data to navigate to exact appointment... Navingate to pending route
    const appointmentNotificationClickListener = () => {
      SocketListener.dispatchReduxAction(socketActionCreators.navigateToScreen, {
        screen: isAdmin ? SocketScreens.ADMIN_APPOINTMENT : SocketScreens.APPOINTMENT,
        payload: {
          appointmentType: 'pending',
          appointmentId: data?.eventMeta?.appointmentId || null,
        },
      });
    };
    return appointmentNotificationClickListener;
  }

  static createAppointmentNeedsActionNotificationListener(data) {
    // TODO: Needs Testing for this scenario
    const appointmentNotificationClickListener = () => {
      SocketListener.dispatchReduxAction(socketActionCreators.navigateToScreen, {
        screen: SocketScreens.APPOINTMENT,
        payload: {
          appointmentType: 'pending',
          appointmentId: data?.eventMeta?.appointmentId || null,
        },
      });
    };
    return appointmentNotificationClickListener;
  }

  static createAppointmentConfirmedNotificationListener(data) {
    // TODO: Handle Navigation
    /** RESPONSE:
     * {
        "eventMeta": {
            "appointmentType": "30 Minute Telehealth Session",
            "providerRole": "Coach",
            "appointmentId": "63760accb3cb1f000118b978",
            "providerId": "5d3eef0b2ab79c00017b862c",
            "memberName": "Captain",
            "startTime": "2022-11-21T01:30:00.000Z",
            "endTime": "2022-11-21T02:00:00.000Z",
            "serviceId": "62b065d836d1a000015fea8b",
            "serviceName": "30 Minute Telehealth Session",
            "providerName": "ckent",
            "memberId": "61a75fb0cbff270001a07d37",
            "status": "BOOKED"
        },
        "subtitle": "Captain accepted your appointment",
        "to": "5d3eef0b2ab79c00017b862c",
        "notificationType": "APPOINTMENT_CONFIRMED",
        "title": "Requested appointment confirmed"
      }
     */
    const appointmentNotificationClickListener = () => {
      SocketListener.dispatchReduxAction(socketActionCreators.navigateToScreen, {
        screen: SocketScreens.APPOINTMENT,
        payload: {
          appointmentType: 'current',
          appointmentId: data?.eventMeta?.appointmentId || null,
        },
      });
    };
    return appointmentNotificationClickListener;
  }

  static createAppointmentCancelledNotificationListener(data) {
    // SocketListener.dispatchReduxAction(appointmentActionCreators.fetchAppointments, );
    const appointmentNotificationClickListener = () => {
      SocketListener.dispatchReduxAction(socketActionCreators.navigateToScreen, {
        screen: SocketScreens.APPOINTMENT,
        payload: {
          appointmentType: 'past',
          appointmentId: data?.eventMeta?.appointmentId || null,
        },
      });
    };
    return appointmentNotificationClickListener;
  }

  static createMessageNoitificationClickListener(notification) {
    console.log('Got a live message notification');
    const state = SocketListener.store.getState();
    const { isAdmin } = state.auth;
    const sender = state.profile.activeConnections.filter(
      connection => connection.connectionId === notification.metadata.senderId,
    )[0];
    let tabType = '';
    if (sender !== undefined) {
      if (notification.notificationType === SocketNotificationTypes.groupMessageReceived) {
        tabType = 'groups';
      } else {
        sender?.type === CONNECTIONS_TYPES.PATIENT ? (tabType = 'members') : (tabType = 'providers');
      }
      const messageNotificationClickListener = () => {
        SocketListener.dispatchReduxAction(socketActionCreators.navigateToScreen, {
          screen: isAdmin ? SocketScreens.ADMINCHAT : SocketScreens.CHAT,
          payload: {
            senderId:
              tabType === 'groups' ? notification.metadata.channelUrl : notification.metadata.senderId,
            tabType,
          },
        });
      };
      return messageNotificationClickListener;
    }
    return null;
  }
}

export default SocketListener;
