/* eslint-disable max-len */
import cx from 'clsx';

// types
// styles
import { useStyles } from './shared/styles';

const Phone = ({ className, color, onClick }) => {
  const classes = useStyles();
  return (
    <svg
      className={cx({
        [classes.root]: true,
        [className || '']: className,
      })}
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M14 19C12.3463 18.9986 10.7183 18.5898 9.26 17.81L8.81 17.56C5.70075 15.8883 3.15169 13.3392 1.48 10.23L1.23 9.77999C0.429824 8.31336 0.00712772 6.67069 6.2793e-07 4.99999V4.32999C-0.000208492 4.06608 0.0518167 3.80473 0.153078 3.56102C0.254338 3.31731 0.402832 3.09605 0.590001 2.90999L2.28 1.21999C2.36098 1.13838 2.45963 1.07648 2.56834 1.03907C2.67706 1.00167 2.79291 0.98975 2.90696 1.00425C3.02101 1.01875 3.1302 1.05927 3.22609 1.12269C3.32198 1.1861 3.40202 1.27072 3.46 1.36999L5.71 5.22999C5.82065 5.42105 5.86495 5.64335 5.83601 5.86223C5.80707 6.08111 5.7065 6.28426 5.55 6.43999L3.66 8.32999C3.58356 8.40559 3.53353 8.50381 3.51732 8.61009C3.5011 8.71637 3.51958 8.82504 3.57 8.91999L3.92 9.57999C5.17704 11.9084 7.08926 13.8172 9.42 15.07L10.08 15.43C10.175 15.4804 10.2836 15.4989 10.3899 15.4827C10.4962 15.4665 10.5944 15.4164 10.67 15.34L12.56 13.45C12.7157 13.2935 12.9189 13.1929 13.1378 13.164C13.3566 13.135 13.5789 13.1793 13.77 13.29L17.63 15.54C17.7293 15.598 17.8139 15.678 17.8773 15.7739C17.9407 15.8698 17.9812 15.979 17.9957 16.093C18.0102 16.2071 17.9983 16.3229 17.9609 16.4316C17.9235 16.5404 17.8616 16.639 17.78 16.72L16.09 18.41C15.9039 18.5972 15.6827 18.7457 15.439 18.8469C15.1953 18.9482 14.9339 19.0002 14.67 19H14Z"
        fill={color}
      />
    </svg>
  );
};

export default Phone;
