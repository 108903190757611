import { fork, all } from 'redux-saga/effects';

import { authSaga } from '../modules/auth';
import { profileSaga } from '../modules/profile';
import { chatSaga, conversationSaga, sessionTypeSaga } from '../modules/conversation';
import { appointmentSaga } from '../modules/appointment';
import { scheduleSaga } from '../modules/schedule';
import { auditSaga } from '../modules/audit';
import { notificationSaga } from '../modules/notification';
import { prioritySaga } from '../modules/priority';
import { reportingSaga } from '../modules/reporting';
import { reportServiceSaga } from '../modules/report-service';
import { stateSaga } from '../modules/state';

export default function* rootSaga(store) {
  yield all([
    fork(authSaga, store),
    fork(profileSaga),
    fork(chatSaga, store),
    fork(sessionTypeSaga),
    fork(conversationSaga),
    fork(appointmentSaga),
    fork(scheduleSaga),
    fork(auditSaga),
    fork(notificationSaga),
    fork(prioritySaga),
    fork(reportingSaga),
    fork(reportServiceSaga),
    fork(stateSaga),
  ]);
}
