import { makeStyles } from '@mui/styles';

// colors
import { colors } from '../../../colors';

// types
import { iconBtnPosition, iconBtnStyle } from './IconButton.types';

const btnColors = {
  [iconBtnStyle.PRIMARY]: colors.textPrimary,
  [iconBtnStyle.SECONDARY]: colors.textSecondary,
  [iconBtnStyle.ERROR]: colors.textError,
  [iconBtnStyle.SUCCESS]: colors.textSuccess,
  [iconBtnStyle.HIGH_CONTRAST]: colors.textHighContrast,
  [iconBtnStyle.MEDIUM_CONTRAST]: colors.textMediumContrast,
  [iconBtnStyle.LOW_CONTRAST]: colors.textMediumContrast,
};

export const useStyles = makeStyles({
  root: ({ style }) => ({
    fontFamily: 'Manrope',
    fontWeight: 700,
    fontSize: 16,
    color: btnColors[style],
    borderRadius: 4,
  }),
  btn: {
    height: 48,
    gap: 8,
    padding: '20px 12px',
  },
  text: {
    height: 'auto',
    padding: 0,
    whiteSpace: 'nowrap',
    '&:hover': {
      background: 'none',
    },
  },
  icon: ({ iconPosition }) => ({
    width: 24,
    height: 24,
    objectFit: 'contain',
    order: iconPosition === iconBtnPosition.LEFT ? 0 : 1,
  }),
});
