/* eslint-disable no-underscore-dangle */
/* eslint-disable max-len */
import { colors } from '../../../../../packages';
import dayjs from 'dayjs';
import { ReportFilterRule } from '../../../../../redux/modules/reporting/types';
import { getLocalDateAsUTC, getTimeDifference } from '../../../../../utils/dayjs';
import { GLOBAL_DATE_FORMAT, REPORT_FILTERS } from '../../../../../constants/CommonConstants';
import { toDateString } from '../../../../../utils/CommonUtils';
import {
  getReportAllFiltersSubQuery,
  getReportAllFiltersSubQueryReferralPartner,
  getReportFilterSubQueryForProfileElementReport,
} from '../../ReportDetail.constants';

const boxDataMap = {
  'profile elements in report': 'totalProfileElements',
  'total assignment': 'averageAssignmentsCount',
  'last assigned': 'lastAssigned',
};
export const getBoxes = (profileElementReport, report, total) => {
  const boxData = [
    {
      label: 'profile elements in report',
      value: total.toString(),
    },
    {
      label: 'total assignment',
      value: Math.round(
        profileElementReport?.aggregations?.total_assigned_profile_elements.value ?? 0,
      ).toString(),
    },
    profileElementReport?.aggregations?.last_assigned.hits.hits[0]?._source?.dataElementTimestamp && {
      label: 'last assigned',
      value: profileElementReport?.aggregations?.last_assigned.hits.hits[0]?._source?.dataElementTimestamp
        ? getTimeDifference(
            new Date(
              profileElementReport?.aggregations?.last_assigned.hits.hits[0]._source.dataElementTimestamp,
            ),
            getLocalDateAsUTC(),
          )
        : '',
    },
  ];
  return (
    boxData.filter(
      item => item?.label && report?.data?.profileElementsSection?.[boxDataMap[item.label]]?.isChecked,
    ) ?? []
  );
};
export const getLast30DaysCounts = profileElementReport =>
  profileElementReport
    ? profileElementReport.aggregations?.last_30_days.count.buckets.map(bucket => bucket.doc_count)
    : [];

export const getPast30DaysCounts = profileElementReport =>
  profileElementReport
    ? profileElementReport.aggregations?.past_30_days.counts.buckets.map(bucket => bucket.doc_count)
    : [];

export const getMostAssigned = profileElementReport =>
  profileElementReport
    ? profileElementReport.aggregations?.most_assigned.buckets.map(bucket => ({
        name: bucket.key,
        total: bucket.uniqueMembers.value,
        lastUpdated: toDateString(bucket.last_assigned_date.hits.hits[0]?._source.dataElementTimestamp),
      }))
    : [];
export const getLeastAssigned = profileElementReport =>
  profileElementReport
    ? profileElementReport.aggregations?.least_assigned.buckets.map(bucket => ({
        name: bucket.key,
        total: bucket.uniqueMembers.value,
        lastUpdated: toDateString(bucket.last_assigned_date.hits.hits[0]?._source.dataElementTimestamp),
      }))
    : [];
const chartColors = [
  colors.success500,
  colors.orange400,
  colors.neutral500,
  colors.secondary500,
  colors.primary500,
  colors.cyan500,
  colors.indigo500,
  colors.purple500,
  colors.rose500,
  colors.destructive500,
];
export const getChartSeries = profileElementReport =>
  profileElementReport
    ? profileElementReport.aggregations?.topProfileElement.buckets.map(bucket => ({
        title: bucket.key,
        subtitle: `Last assigned: ${toDateString(bucket.latest.hits.hits[0]._source.dataElementTimestamp)}`,
        chartData: bucket.topValuesOfThisElement.buckets.map((item, i) => ({
          name: item.key,
          color: chartColors[i],
          data: [item.doc_count],
        })),
        donutOptTitle: 'Assignments',
        donutOptTotal: bucket.uniqueMembers.value,
      }))
    : [];
export const tableColumns = [
  {
    id: 'name',
    label: 'Profile element',
    sortable: false,
  },
  {
    id: 'total',
    label: 'Total',
    sortable: false,
    textAlign: 'center',
    className: 'color-secondary-500',
  },
  {
    id: 'lastUpdated',
    label: 'Last',
    sortable: false,
  },
  {
    id: 'actions',
    label: '',
    sortable: false,
  },
];

export const tableListMock = [
  {
    id: '1',
    name: 'Profile element name',
    total: Math.round(Math.random() * 50),
    daysSince: Math.round(Math.random() * 20),
    createdDay: dayjs().subtract(1, 'd').format(GLOBAL_DATE_FORMAT),
    lastUpdated: dayjs().subtract(1, 'd').format(GLOBAL_DATE_FORMAT),
  },
];

export const getProfileElementsQuery = report => {
  let allFilters = JSON.parse(JSON.stringify(report.reportFilters));
  if (report?.reportFilters?.length || report?.referralPartnerId) {
    const allInsuranceFilters = report.reportFilters?.filter(
      filter => filter.type === REPORT_FILTERS.INSURANCE,
    );
    if (allInsuranceFilters?.length) {
      allFilters = [
        ...report.reportFilters?.filter(filter => filter.type !== REPORT_FILTERS.INSURANCE),
        {
          type: REPORT_FILTERS.INSURANCE,
          insurance: {
            carriers: allInsuranceFilters?.map(filter => filter.insurance?.carriers)?.flat(),
            state: '',
          },
          value: '',
          profileElement: '',
          states: [],
          levelOfEngagements: [],
          providers: [],
          rule: ReportFilterRule.EQUALS,
        },
      ];
    }
    const profileElementFilters = allFilters.filter(filt => filt.type === REPORT_FILTERS.PROFILE_ELEMENT);
    return {
      size: 0,
      query: {
        bool: {
          must: [
            profileElementFilters.length > 0 && {
              bool: {
                should: [
                  ...profileElementFilters.map(
                    filter => filter.profileElement && getReportFilterSubQueryForProfileElementReport(filter),
                  ),
                ],
              },
            },
            ...allFilters?.map(filter => getReportAllFiltersSubQuery(filter)),
            ...getReportAllFiltersSubQueryReferralPartner(report?.referralPartnerId),
          ]?.filter(obj => Object.keys(obj)?.length > 0),
        },
      },
      aggs: {
        total_assigned_profile_elements: {
          cardinality: {
            field: 'dataElementKey.keyword',
          },
        },
        last_assigned: {
          top_hits: {
            _source: 'dataElementTimestamp.keyword',
            size: 1,
            sort: [
              {
                'dataElementTimestamp.keyword': {
                  order: 'desc',
                },
              },
            ],
          },
        },
        most_assigned: {
          terms: {
            field: 'dataElementKey.keyword',
            size: 100,
            order: {
              _count: 'desc',
              uniqueMembers: 'desc',
            },
          },
          aggs: {
            uniqueMembers: {
              cardinality: {
                field: 'userAccountId.keyword',
              },
            },
            last_assigned_date: {
              top_hits: {
                _source: 'dataElementTimestamp',
                sort: [
                  {
                    'dataElementTimestamp.keyword': {
                      order: 'desc',
                    },
                  },
                ],
                size: 1,
              },
            },
          },
        },
        least_assigned: {
          terms: {
            field: 'dataElementKey.keyword',
            size: 100,
            order: {
              _count: 'asc',
              uniqueMembers: 'asc',
            },
          },
          aggs: {
            uniqueMembers: {
              cardinality: {
                field: 'userAccountId.keyword',
              },
            },
            last_assigned_date: {
              top_hits: {
                _source: 'dataElementTimestamp',
                sort: [
                  {
                    'dataElementTimestamp.keyword': {
                      order: 'desc',
                    },
                  },
                ],
                size: 1,
              },
            },
          },
        },
        topProfileElement: {
          terms: {
            field: 'dataElementKey.keyword',
            size: 1000,
            order: {
              uniqueMembers: 'desc',
            },
          },
          aggs: {
            uniqueMembers: {
              cardinality: {
                field: 'userAccountId.keyword',
              },
            },
            topValuesOfThisElement: {
              terms: {
                field: 'dataElementValue.keyword',
                size: 5,
              },
            },
            latest: {
              top_hits: {
                size: 1,
                sort: [
                  {
                    'dataElementTimestamp.keyword': 'desc',
                  },
                ],
                _source: ['dataElementTimestamp'],
              },
            },
          },
        },
        top10ProfileElement: {
          terms: {
            field: 'dataElementKey.keyword',
            size: 10,
            order: {
              uniqueMembers: 'desc',
            },
          },
          aggs: {
            uniqueMembers: {
              cardinality: {
                field: 'userAccountId.keyword',
              },
            },
          },
        },
        last_30_days: {
          filter: {
            range: {
              dataElementTimestamp: {
                gte: 'now-30d/d',
                lt: 'now',
              },
            },
          },
          aggs: {
            count: {
              date_histogram: {
                field: 'dataElementTimestamp',
                calendar_interval: '1d',
              },
            },
          },
        },
        past_30_days: {
          filter: {
            range: {
              dataElementTimestamp: {
                gte: 'now-60d/d',
                lt: 'now-30d/d',
              },
            },
          },
          aggs: {
            counts: {
              date_histogram: {
                field: 'dataElementTimestamp',
                calendar_interval: '1d',
              },
            },
          },
        },
      },
    };
  }
  return {
    size: 0,
    aggs: {
      total_assigned_profile_elements: {
        cardinality: {
          field: 'dataElementKey.keyword',
        },
      },
      last_assigned: {
        top_hits: {
          _source: 'dataElementTimestamp',
          size: 1,
          sort: [
            {
              'dataElementTimestamp.keyword': {
                order: 'desc',
              },
            },
          ],
        },
      },
      most_assigned: {
        terms: {
          field: 'dataElementKey.keyword',
          size: 100,
          order: {
            _count: 'desc',
            uniqueMembers: 'desc',
          },
        },
        aggs: {
          uniqueMembers: {
            cardinality: {
              field: 'userAccountId.keyword',
            },
          },
          last_assigned_date: {
            top_hits: {
              _source: 'dataElementTimestamp',
              sort: [
                {
                  'dataElementTimestamp.keyword': {
                    order: 'desc',
                  },
                },
              ],
              size: 1,
            },
          },
        },
      },
      least_assigned: {
        terms: {
          field: 'dataElementKey.keyword',
          size: 100,
          order: {
            _count: 'asc',
            uniqueMembers: 'asc',
          },
        },
        aggs: {
          uniqueMembers: {
            cardinality: {
              field: 'userAccountId.keyword',
            },
          },
          last_assigned_date: {
            top_hits: {
              _source: 'dataElementTimestamp',
              sort: [
                {
                  'dataElementTimestamp.keyword': {
                    order: 'desc',
                  },
                },
              ],
              size: 1,
            },
          },
        },
      },
      topProfileElement: {
        terms: {
          field: 'dataElementKey.keyword',
          size: 1000,
          order: {
            uniqueMembers: 'desc',
          },
        },
        aggs: {
          uniqueMembers: {
            cardinality: {
              field: 'userAccountId.keyword',
            },
          },
          topValuesOfThisElement: {
            terms: {
              field: 'dataElementValue.keyword',
              size: 5,
            },
          },
          latest: {
            top_hits: {
              size: 1,
              sort: [
                {
                  'dataElementTimestamp.keyword': 'desc',
                },
              ],
              _source: ['dataElementTimestamp'],
            },
          },
        },
      },
      top10ProfileElement: {
        terms: {
          field: 'dataElementKey.keyword',
          size: 10,
          order: {
            uniqueMembers: 'desc',
          },
        },
        aggs: {
          uniqueMembers: {
            cardinality: {
              field: 'userAccountId.keyword',
            },
          },
        },
      },
      last_30_days: {
        filter: {
          range: {
            dataElementTimestamp: {
              gte: 'now-30d/d',
              lt: 'now',
            },
          },
        },
        aggs: {
          count: {
            date_histogram: {
              field: 'dataElementTimestamp',
              calendar_interval: '1d',
            },
          },
        },
      },
      past_30_days: {
        filter: {
          range: {
            dataElementTimestamp: {
              gte: 'now-60d/d',
              lt: 'now-30d/d',
            },
          },
        },
        aggs: {
          counts: {
            date_histogram: {
              field: 'dataElementTimestamp',
              calendar_interval: '1d',
            },
          },
        },
      },
    },
  };
};
