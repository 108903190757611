/* eslint-disable max-len */
import cx from 'clsx';

// types
// styles
import { useStyles } from './shared/styles';

const CameraOff = ({ className, color, onClick }) => {
  const classes = useStyles();
  return (
    <svg
      className={cx({
        [classes.root]: true,
        [className || '']: className,
      })}
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M16.3536 12.6464L8.20711 4.5H14C14.3978 4.5 14.7794 4.65804 15.0607 4.93934C15.342 5.22064 15.5 5.60218 15.5 6V9V10.2071L16.3536 9.35355L19.0621 6.645C19.0623 6.64482 19.0625 6.64464 19.0626 6.64446C19.1555 6.55262 19.2806 6.50077 19.4113 6.5H19.5V15.5H19.4112C19.2806 15.4992 19.1555 15.4474 19.0627 15.3556C19.0625 15.3554 19.0623 15.3552 19.0621 15.355L16.3536 12.6464ZM14.5732 17.3627C14.3908 17.4469 14.1933 17.4936 13.9926 17.5H2C1.60218 17.5 1.22064 17.342 0.93934 17.0607C0.658035 16.7794 0.5 16.3978 0.5 16V6C0.5 5.60218 0.658035 5.22064 0.93934 4.93934C1.22064 4.65804 1.60218 4.5 2 4.5V4L2.36016 3.65318L0.80016 2.03318L0.79733 2.03024L1.34369 1.51315L1.35003 1.50715L19.4779 19.665L18.97 20.1729L15.7836 16.9864L15.4112 16.6141L15.0586 17.0052C14.9227 17.156 14.7575 17.2776 14.5732 17.3627ZM8.20355 10.0736L8.56584 9.71127L8.19483 9.35793L6.51483 7.75793L6.37 7.62H6.17H3.5C3.23478 7.62 2.98043 7.72536 2.79289 7.91289C2.60536 8.10043 2.5 8.35478 2.5 8.62V9.38C2.5 9.64522 2.60536 9.89957 2.79289 10.0871C2.98043 10.2746 3.23478 10.38 3.5 10.38H7.5V10.3801L7.50787 10.3799C7.74129 10.3763 7.96573 10.2894 8.14079 10.1349L8.15251 10.1246L8.16355 10.1136L8.20355 10.0736Z"
        fill={color}
        stroke={color}
      />
    </svg>
  );
};

export default CameraOff;
