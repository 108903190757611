import { useCallback, useEffect, useState } from 'react';
import { Box } from '@mui/material';

import { colors, Heading, Icons, Table } from '../../../../../packages';

import { useStyles } from './ProfileDctConvoTableCard.styles';
import {
  getProfileElementDctConvoListQuery,
  getProfileElementDctEvaluationListQuery,
  mapChatBotResponseToTableObject,
  tableColumns,
} from './ProfileDctConvoTableCard.constants';
import {
  getElkProfileElementDctConvoList,
  getElkProfileElementDctEvaluationList,
} from '../../../../../services/profile/profile.service';
import history from '../../../../../utils/history';
import { CONVERSATION_TYPES } from '../../../../../constants/CommonConstants';

const ProfileDctConvoTableCard = ({ profile }) => {
  const classes = useStyles();
  const [variablesList, setVariablesList] = useState([]);
  const [tableParams, setTableParams] = useState({
    search: { searchKey: '', filter: null },
    pagination: { currentPage: 1, rowsPerPage: 10 },
    sorter: { direction: 'asc', column: '' },
  });
  const [listTotalCount, setListTotalCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const getQueryParams = () => {
    const queryParams = {
      profileKey: profile.profileElementInfo.key,
    };
    return queryParams;
  };
  const fetchProfileElementList = async () => {
    setIsLoading(true);
    let combineList;
    const dctConversation = await getElkProfileElementDctConvoList(
      getProfileElementDctConvoListQuery(getQueryParams()),
    );
    const evaluationList = await getElkProfileElementDctEvaluationList(
      getProfileElementDctEvaluationListQuery(getQueryParams()),
    );

    combineList = [
      ...mapChatBotResponseToTableObject(evaluationList.data, 'evaluation'),
      ...mapChatBotResponseToTableObject(dctConversation.data, 'conversation'),
    ];
    const listCount =
      Number(dctConversation?.data?.aggregations?.by_assignments?.buckets?.length) +
      Number(evaluationList?.data?.aggregations?.by_assignments?.buckets?.length);
    setListTotalCount(listCount);
    setIsLoading(false);
    return combineList;
  };

  const dctListCallBack = useCallback(() => {
    const { pagination } = tableParams;
    const currentPage = pagination?.currentPage || 1;
    const rowsPerPage = pagination?.rowsPerPage || 6;
    let appointmentsFiltered = [];

    if (variablesList.length > 0)
      appointmentsFiltered = variablesList?.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage);

    return appointmentsFiltered;
  }, [tableParams, variablesList]);

  const renderColumns = tableColumns.map(column => {
    if (column.id === 'dctName') {
      return {
        ...column,
        renderCell: dctName => <span>{dctName || '-'}</span>,
      };
    }
    if (column.id === 'dctVersion') {
      return {
        ...column,
        renderCell: dctVersion => <span>{dctVersion || '-'}</span>,
      };
    }
    if (column.id === 'conversationName') {
      return {
        ...column,
        renderCell: conversationName => <span>{conversationName || '-'}</span>,
      };
    }
    if (column.id === 'conversationType') {
      return {
        ...column,
        renderCell: conversationType => <span>{conversationType || '-'}</span>,
      };
    }
    if (column.id === 'conversationVariable') {
      return {
        ...column,
        renderCell: conversationVariable => <span>{conversationVariable || '-'}</span>,
      };
    }
    if (column.id === 'assignment') {
      return {
        ...column,
        renderCell: assignment => <span>{assignment || '-'}</span>,
      };
    }
    if (column.id === 'onClick') {
      return {
        ...column,
        renderCell: () => <Icons color={colors.neutral200} glyph="chevron-right" />,
      };
    }
    return column;
  });

  const dctList = dctListCallBack();

  useEffect(() => {
    void (async () => {
      const list = await fetchProfileElementList();
      setVariablesList(list);
    })();
  }, [profile]);

  const onRowClick = rowItem => {
    if (rowItem.conversationType === CONVERSATION_TYPES.EVALUATION.toLowerCase()) {
      history.push(
        `/admin/collection-evaluation-dcts/${rowItem.dctId}/${rowItem.dctVersion}?name=assignments`,
        {
          from: CONVERSATION_TYPES.EVALUATION_DCT,
        },
      );
    } else {
      // redirection for chatbot
      history.push(`/admin/collection-templates/${rowItem.dctId}/${rowItem.dctVersion}?name=assignments`, {
        from: CONVERSATION_TYPES.CONVERSATION,
      });
    }
  };

  return (
    <Box className={classes.card}>
      <Box className={classes.head}>
        <Heading className={classes.title}>Connected DCTs and Conversational Variables</Heading>
      </Box>
      <Box>
        <Table
          gridProps={{
            columns: renderColumns,
            data: dctList,
            isLoading,
            onRowClick,
          }}
          paginationProps={{
            currentRows: tableParams.pagination.currentPage,
            totalCount: listTotalCount,
            showRowsPerPage: false,
          }}
          value={tableParams}
          onChange={newValues => {
            setTableParams(newValues);
          }}
          className={classes.table}
        />
      </Box>
    </Box>
  );
};

export { ProfileDctConvoTableCard };
