import { colors } from '../../../../../../packages/colors';

export const infoCardConstants = {
  verified: {
    icon: 'checked-circle-outlined',
    color: colors.success500,
    text: 'Documents verified',
  },
  reviewNeed: {
    icon: 'alert-outlined',
    color: colors.warning500,
    text: 'Review data uploaded from CAQH',
  },
  documentNeeded: {
    icon: 'file-warning',
    color: colors.indigo500,
    text: 'Documents needed',
  },
  documentAnalyzing: {
    icon: 'file-warning',
    color: colors.indigo500,
    text: 'Documents are being analyzed',
  },
  discrepancy: {
    icon: 'alert-outlined',
    color: colors.secondary500,
    text: 'Fix discrepancy between your documents and CAQH',
  },
  expired: {
    icon: 'file-warning',
    color: colors.destructive500,
    text: 'Update expired document',
  },
  expiringSoon: {
    icon: 'file-warning',
    color: colors.orange500,
    text: 'Update expiring document',
  },
  incomplete: {
    icon: 'alert-outlined',
    color: colors.destructive500,
    text: 'Some documents are incomplete',
  },
  failed: {
    icon: 'alert-outlined',
    color: colors.destructive500,
    text: 'Document analysis failed',
  },
};
