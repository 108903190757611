import cx from 'clsx';

// icons
import { Tooltip } from '@mui/material';
import { Icons } from '../../../icons';

// colors
import { colors } from '../../../colors';

// types
import { badgeStyle, badgeType } from './Badge.types';

// styles
import { useStyles } from './Badge.styles';

const Badge = ({
  className = '',
  variant = badgeType.FILLED,
  style = badgeStyle.CRITICAL,
  size = undefined,
  icon = undefined,
  tooltip = undefined,
  children,
}) => {
  const classes = useStyles({ variant, size, style });

  const rootClasses = cx({
    [classes.root]: true,
    [className || '']: className,
  });

  return (
    <div className={rootClasses}>
      {icon && <Icons className={classes.icon} glyph={icon} color={colors.white} />}
      {tooltip ? (
        <Tooltip title={tooltip} placement="top-start">
          <div className={classes.tooltip}>{children}</div>
        </Tooltip>
      ) : (
        children
      )}
    </div>
  );
};

export { Badge };
