// colors
import { colors } from '../../../../../../packages/colors';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  header: {
    width: '100%',
    height: 80,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '16px 8px 16px 24px',
    backgroundColor: colors.white,
    borderBottom: `1px solid ${colors.neutral100}`,
  },
  title: {
    fontSize: 18,
    lineHeight: '24px',
    margin: 0,
  },
  backBtn: {
    width: 40,
    minWidth: 40,
    height: 40,
    '& svg': {
      height: 20,
    },
  },
  body: {
    height: 'calc(100vh - 250px)',
    padding: '12px 28px',
    overflowY: 'auto',
    backgroundColor: colors.neutral50,
    gap: '24px',
    display: 'flex',
    flexDirection: 'column',
  },
  latest: {
    position: 'absolute',
    bottom: '10rem',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  latestBtn: {
    height: 32,
    borderRadius: 30,
    color: colors.white,
    '& svg': {
      transform: 'rotate(-90deg)',
      width: 20,
    },
  },
  replyMsgContainer: {
    bottom: '10.5rem',
  },
  footer: {
    height: 'auto',
    background: '#FFF',
    bottom: 0,
    width: '100%',
    position: 'absolute',
    '& .MuiFormControl-root': {
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
    },
  },
});
