export const inputType = {
  DEFAULT: 'DEFAULT',
  TAGS: 'TAGS',
  SEARCH: 'SEARCH',
  PHONE: 'PHONE',
  AUTOCOMPLETE: 'AUTOCOMPLETE',
  CALENDARFILTER: 'CALENDARFILTER',
};

export const inputSize = {
  L: 'L',
  M: 'M',
  S: 'S',
};
