export const TodoVariant = {
  PRIMARY: 'PRIMARY',
  SECONDARY: 'SECONDARY',
};

export const TodoType = {
  CHAT: 'CHAT',
  NOTES: 'NOTES',
  APPOINTMENT: 'APPOINTMENT',
};
