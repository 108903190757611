import { makeStyles } from '@mui/styles';
import { colors, theme } from '../../packages';

export const useStyles = makeStyles({
  root: {
    backgroundColor: colors.primary50,
    color: colors.neutral900,
    display: 'flex',
    alignItems: 'center',
  },
  message: {
    color: colors.neutral900,
    paddingLeft: theme.spacing(1),
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    fontFamily: 'Manrope',
    width: '24ch',
    cursor: 'pointer',
  },
  title: {
    fontSize: '0.9rem',
    fontWeight: 600,
  },
  subtitle: {
    fontSize: '0.8rem',
    fontWeight: 400,
  },
});
