export const rightSideNavList = [
  {
    text: 'General information',
    link: '?name=general-information',
    query: { type: 'name', value: 'general-information' },
  },
  {
    text: 'Payers',
    link: '?name=payers',
    query: { type: 'name', value: 'payers' },
  },
  // {
  //   text: 'Providers',
  //   link: '?name=providers',
  //   query: { type: 'name', value: 'providers' },
  // },
  // {
  //   text: 'Members',
  //   link: '?name=members',
  //   query: { type: 'name', value: 'members' },
  // },
];
