// colors
import { colors } from '../../../../../packages';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  card: {
    background: '#FFF',
    filter:
      // eslint-disable-next-line
      'drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.04)) drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.06)) drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.04))',
    borderRadius: 8,
    '&:hover $viewChatbot': {
      display: 'flex',
    },
  },
  cardHeadWrap: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingRight: 24,
    alignItems: 'baseline',
    borderBottom: `1px solid ${colors.neutral100}`,
  },
  dFlexColumn: {
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
    padding: 24,
  },
  dFlexRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 16,
  },
  headTitle: {
    lineHeight: '32px',
    color: colors.neutral900,
    margin: 0,
  },
  badgeOutlined: {
    height: 28,
    paddingLeft: 10,
    paddingRight: 10,
    borderRadius: 50,
  },
  headSubtitle: {
    color: colors.neutral600,
    lineHeight: '24px',
    margin: 0,
  },
  link: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 12,
    fontSize: 14,
    lineHeight: '20px',
    fontWeight: 700,
    color: colors.primary600,
  },
  content: {
    overflow: 'hidden',
  },
  reports: {
    padding: 24,
    backgroundColor: colors.neutral50,
  },
  reportItem: {
    flex: 1,
    width: '100%',
  },
  chart: {
    '& > div:first-child': {
      display: 'none',
    },
  },
});
