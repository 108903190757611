import { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useStyles } from './CloseSession.styles';
import WinTrophy from './../../../../../../../assets/images/win-trophy.png';
import { Icons } from '../../../../../../icons';
import { btnType, Button } from '../../../../../atoms/button';
import { Input, inputType } from '../../../../../atoms/input';
import clsx from 'clsx';
import dayjs from '../../../../../../../utils/dayjs';
import { MEMBER_ACTIONS } from '../../../sessions.constants';
import { commonActionCreators } from '../../../../../../../redux/modules/common';
import { convertSecondToTime } from '../../../../../../../utils/CommonUtils';
import Podium from '../../../../../../icons/lotties/lottieIcons/Podium';

const CloseSession = ({
  nextAppointment,
  scratchpad,
  addScratchpadNotes,
  openEvaluationsDrawer,
  openGiggle,
  goToSchedule,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const hasNextAppointment = nextAppointment && dayjs(nextAppointment.startTime).isSame(dayjs(), 'day');
  const [timerValue, setTimerValue] = useState(
    hasNextAppointment ? dayjs(nextAppointment.startTime).diff(dayjs(), 'second') : 0,
  );

  useEffect(() => {
    if (!nextAppointment || timerValue <= 0) return;

    const intervalId = setInterval(() => {
      setTimerValue(prev => prev - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [nextAppointment, timerValue]);

  const handleMemberActionClick = type => {
    dispatch(commonActionCreators.openDrawer(type));
  };

  return (
    <Box className={classes.container}>
      <Box className={classes.leftSection}>
        <Box className={classes.closeBox}>
          <Box className={classes.header}>
            <p className={classes.sectionTitle}>Close the session</p>
            <p className={classes.sectionSubtitle}>Can we help you with anything?</p>
          </Box>
          <Box className={classes.contentBox}>
            <Input
              fullWidth
              variant={inputType.DEFAULT}
              placeholder="Add any notes here"
              multiline
              minRows={3}
              maxRows={3}
              value={scratchpad}
              onChange={event => addScratchpadNotes(event.target.value)}
              onKeyDown={event => {
                if (event.key === ' ') {
                  event.stopPropagation();
                }
              }}
            />
            <p className={classes.aboutText}>
              Write down anything that you’d like help with and we’ll handle it.
            </p>
          </Box>
          <Box className={classes.footer}>
            <p className={classes.footerTitle}>Missing some data collection?</p>
            <Button variant={btnType.TEXT} className={classes.textLink} onClick={openEvaluationsDrawer}>
              Add evaluation
            </Button>
          </Box>
        </Box>
        <Box className={classes.nextApptSection}>
          <Box className={classes.nextApptContent}>
            {hasNextAppointment ? (
              <p className={classes.nextApptTitle}>
                You have an appointment in{' '}
                <span className={classes.nextApptRemainingTime}>{convertSecondToTime(timerValue)}</span>
              </p>
            ) : (
              <p className={classes.nextApptTitle}>You have no more appointments today</p>
            )}
            {hasNextAppointment && (
              <p className={classes.nextApptSubTitle}>
                {nextAppointment.serviceName} at {dayjs(nextAppointment.startTime).format('hh:mm a')}
              </p>
            )}
          </Box>
          <Button
            variant={btnType.TEXT}
            className={classes.textLink}
            endIcon={<Icons glyph="square-arrow-up-right" />}
            onClick={goToSchedule}
          >
            Back to my schedule
          </Button>
        </Box>
      </Box>
      <Box className={classes.rightSection}>
        <Box className={classes.rightHeader}>
          <Podium className={classes.animatedIcon} />
          <Box className={classes.rightHeaderContentBox}>
            <p className={classes.rightHeaderTitle}>Nice session!</p>
            <p className={classes.rightHeaderSubtitle}>Let’s keep it moving!</p>
          </Box>
        </Box>
        <Box className={classes.memberActionsBox}>
          <Box className={classes.actionsList}>
            <Button
              variant={btnType.OUTLINE}
              className={clsx(classes.actionBtn, classes.profileBtn)}
              endIcon={<Icons glyph="member" />}
              onClick={() => handleMemberActionClick(MEMBER_ACTIONS.PROFILE)}
            >
              View member profile
            </Button>
            <Button
              variant={btnType.OUTLINE}
              className={clsx(classes.actionBtn, classes.amaBtn)}
              endIcon={<Icons glyph="question-outlined" />}
              onClick={() => handleMemberActionClick(MEMBER_ACTIONS.AMA)}
            >
              Ask us anything
            </Button>
            <Button
              variant={btnType.OUTLINE}
              className={clsx(classes.actionBtn, classes.apptBtn)}
              endIcon={<Icons glyph="calendar-checked-outlined" />}
              onClick={() => handleMemberActionClick(MEMBER_ACTIONS.SCHEDULE)}
            >
              Schedule appointment
            </Button>
            <Button
              variant={btnType.OUTLINE}
              className={clsx(classes.actionBtn, classes.chatBtn)}
              endIcon={<Icons glyph="message" />}
              onClick={() => handleMemberActionClick(MEMBER_ACTIONS.CHAT)}
            >
              Chat with care team
            </Button>
          </Box>
        </Box>
        <Box className={classes.giggleBox}>
          <p className={classes.breakText}>Want a quick break?</p>
          <Button variant={btnType.TEXT} className={classes.textLink} onClick={() => openGiggle()}>
            Induce giggle
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
export { CloseSession };
