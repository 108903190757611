/* eslint-disable max-len */
import cx from 'clsx';

// types
// styles
import { useStyles } from './shared/styles';

const GoalOutlined = ({ className, color, onClick }) => {
  const classes = useStyles();
  return (
    <svg
      className={cx({
        [classes.root]: true,
        [className || '']: className,
      })}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M10 0C7.68906 0 5.56875 0.790938 3.87437 2.10687L2.1825 0.415C1.69437 -0.073125 0.869062 -0.10875 0.380937 0.380937C-0.107187 0.869062 -0.073125 1.69437 0.415 2.18281L2.10687 3.87469C0.790938 5.56875 0 7.68906 0 10C0 15.5225 4.4775 20 10 20C15.5225 20 20 15.5225 20 10C20 4.4775 15.5225 0 10 0ZM10 17.5C5.85812 17.5 2.5 14.1419 2.5 10C2.5 8.38125 3.01875 6.88469 3.89156 5.65906L5.70687 7.47531C5.26844 8.21781 5 9.07469 5 10C5 12.7612 7.23875 15 10 15C12.7612 15 15 12.7612 15 10C15 7.23875 12.7612 5 10 5C9.07469 5 8.21781 5.26844 7.47437 5.70813L5.65906 3.89187C6.88469 3.01875 8.38 2.5 10 2.5C14.1419 2.5 17.5 5.85812 17.5 10C17.5 14.1419 14.1419 17.5 10 17.5ZM9.11625 10.8837C9.60438 11.3719 10.3956 11.3719 10.8837 10.8837C11.3731 10.3956 11.3719 9.60438 10.8837 9.11625L9.36031 7.59281C9.56531 7.53906 9.77781 7.5 10 7.5C11.3806 7.5 12.5 8.61937 12.5 10C12.5 11.3806 11.3806 12.5 10 12.5C8.61937 12.5 7.5 11.3806 7.5 10C7.5 9.77781 7.53781 9.56531 7.59281 9.36031L9.11625 10.8837Z"
        fill={color}
      />
    </svg>
  );
};

export default GoalOutlined;
