import { FC, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Modal } from '@mui/material';
import { useDownloadExcel } from 'table-to-excel-react';
import dayjs from 'dayjs';

import { fontWeight, Heading, headingLevel } from '../../../../../packages/ui/atoms/typography';
import { btnSize, btnType, Button } from '../../../../../packages/ui/atoms/button';
import { Icons } from '../../../../../packages/icons';
import { colors } from '../../../../../packages/colors';
import { iconBtnType, IconButton } from '../../../../../packages/ui/molecules/icon-button';
import { useReactToPrint } from 'react-to-print';
import * as memberService from '../../../../../services/member/member.service';

import { conversationActionCreators } from '../../../../../redux/modules/conversation/actions';
import { getConversation } from '../../../../../redux/modules/conversation/selectors';
import { getProfile } from '../../../../../redux/modules/profile/selectors';
import { getCompareFn } from '../../../../../utils';
import { getChatbots } from '../../../../../services/conversation/conversation.service';
import { GLOBAL_DATE_FORMAT } from '../../../../../constants/CommonConstants';
import { getAuth } from '../../../../../redux/modules/auth/selectors';
import logo from '../../../../../assets/images/ch-icon.png';

import ChatbotCard from './ChatbotCard';
import ViewChatbot from './ViewChatbot';
import PreviousCompletions from './PreviousCompletions';
import EmptyState from '../EmptyState';

import AssignChatbot from './AssignChatbot';
import { useStyles } from '../../MemberDetail.styles';
import { downloadDateRange } from '../../MemberDetail.constants';

const Chatbots = ({ participantId, showHeader = true, searchKey = '' }) => {
  const classes = useStyles({});
  const dispatch = useDispatch();
  const { memberId } = useParams();
  const { isAdmin } = useSelector(getAuth);
  const { demographicDetails } = useSelector(getProfile);
  const {
    chatbots = [],
    isLoading,
    chatbotAttemptHistory,
    isCBAttemptHistoryLoading,
    chatbotcompleteHistory,
  } = useSelector(getConversation);
  const [selectedChatbot, setSelectedChatbot] = useState();
  const [showAssignChatbot, setShowAssignChatbot] = useState(false);
  const [showPrevCompletions, setShowPrevCompletions] = useState(false);
  const [showViewChatbot, setShowViewChatbot] = useState(false);
  const [chatbotList, setChatbotList] = useState(chatbots);
  const [renderTableToDownload, setRenderTableToDownload] = useState(null);
  const [isLoadingExport, setIsLoadingExport] = useState(false);

  const sortByChatbotName = getCompareFn(x => x.chatbotName);
  const [renderTableToPrint, setRenderTableToPrint] = useState(<></>);
  const [openTableToPrintModal, setOpenTableToPrintModal] = useState(false);
  const [isExportDisabled, setIsExportDisabled] = useState(true);

  const openTableToPrint = () => {
    setOpenTableToPrintModal(true);
  };

  const closeTableToPrint = () => {
    setOpenTableToPrintModal(false);
  };
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => {
      const currentComponent = componentRef.current;
      currentComponent.style.overflow = 'visible';
      return currentComponent;
    },
  });
  useEffect(() => {
    const filteredList = chatbots.filter(chatbot =>
      chatbot.chatbotName.toLowerCase().includes(searchKey.toLowerCase()),
    );
    setChatbotList(filteredList.sort(sortByChatbotName));
  }, [searchKey, chatbots.length]);

  const toggleAssignChatbot = () => {
    setShowAssignChatbot(s => !s);
  };

  const fetchChatbots = () => {
    dispatch(
      conversationActionCreators.fetchChatbots({
        pathParams: { userId: memberId || participantId },
        queryParams: { includeResponses: false },
      }),
    );
  };

  const onClickPrevCompletions = chatbot => {
    dispatch(
      conversationActionCreators.fetchChatbotAttemptHistory({
        userId: memberId || participantId,
        conversationId: chatbot.conversationId,
        days: 30,
      }),
    );
    setSelectedChatbot(chatbot);
    setShowPrevCompletions(true);
  };

  const onClickView = contextId => {
    dispatch(conversationActionCreators.fetchChatbotContacts(contextId));
    setShowViewChatbot(true);
  };

  const onClickChatbot = latestContextId => {
    if (latestContextId) {
      dispatch(conversationActionCreators.fetchChatbotContacts(latestContextId));
      setShowViewChatbot(true);
    }
  };

  useEffect(() => {
    if (memberId || participantId) {
      fetchChatbots();
    }
  }, []);
  useEffect(() => {
    if (memberId || participantId) {
      memberService
        .getAdminProfile({ userId: memberId || participantId })
        .then(patientResponse => {
          getChatbots(
            { userId: memberId ?? participantId },
            {
              includeResponses: false,
              startDate: downloadDateRange.startDate,
              endDate: downloadDateRange.endDate,
            },
          )
            .then(res => {
              if (res.data?.length > 0) {
                setIsExportDisabled(false);
                setRenderTableToPrint(
                  <div className={classes.documentToPrint} ref={componentRef}>
                    <div className={classes.coverPage}>
                      <div className={classes.coverDownlad}>
                        <div className={classes.topBox}>
                          <p className={classes.c4}>FOR CLINICAL USE ONLY</p>
                        </div>
                        <div className={classes.middleBox}>
                          <p className={classes.c4}>
                            <span className={classes.imageBox}>
                              <img alt="" src={logo} className={classes.imageTag} title="" />
                            </span>
                          </p>
                          <p className={classes.c2}>Patient Clinical Notes Export</p>
                        </div>
                        <div className={classes.bottomBox}>
                          <p className={classes.c5}>Confidential:</p>
                          <div className={classes.bottomInnerBox}>
                            <p className={classes.c1}>
                              These clinical notes contain sensitive patient information. If received in
                              error, please notify us immediately and destroy all copies. Unauthorized access,
                              disclosure, or use is prohibited by law and subject to penalties.
                            </p>
                            <p className={classes.c1}>
                              Protect patient privacy, it&#39;s our shared responsibility.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* Patient information */}
                    <div className={classes.sectionBox}>
                      <p className={classes.title}>FOR CLINICAL USE ONLY</p>
                      <div className={classes.exportDate}>
                        <p className={classes.textRight}>
                          Date of Export: {dayjs().format(GLOBAL_DATE_FORMAT)}
                        </p>
                      </div>

                      <p className={classes.question}>
                        <b>
                          Patient:{' '}
                          <span className={classes.answer}>
                            {patientResponse.data?.firstName || patientResponse.data?.lastName
                              ? `${patientResponse.data?.firstName || ''} ${
                                  patientResponse.data?.lastName || ''
                                }`
                              : patientResponse.data?.fullName ?? ''}{' '}
                          </span>
                        </b>
                      </p>
                      <p className={`${classes.question} ${classes.cptList}`}>
                        <b>
                          Date of birth:{' '}
                          {patientResponse.data?.dob
                            ? dayjs(patientResponse.data?.dob).format(GLOBAL_DATE_FORMAT)
                            : ''}
                        </b>
                      </p>
                    </div>
                    <div className={classes.appointmentBox}>
                      <p className={classes.sectionTitleCenter}>Chatbots</p>
                      {res.data?.map((chatbotItem, index) => (
                        <div key={`note-${index}`} className={classes.appointmentBox}>
                          <p className={classes.sectionSubTitleLeft}>{chatbotItem.chatbotName}</p>
                          <div>
                            <p className={classes.cptList}>Type: {chatbotItem.type}</p>
                            <p className={classes.cptList}>
                              Status: {chatbotItem.completed ? 'Completed' : 'In progress'}
                            </p>
                            <p className={classes.question}>Auther: {chatbotItem.createdBy?.name ?? ''}</p>
                            <p className={classes.cptList}>Updated on: {chatbotItem.lastModified}</p>
                            <p className={classes.question}>DCTs:</p>
                            <p className={classes.cptList}>
                              <ul>
                                {chatbotItem.dcts
                                  ?.filter(item => item.finalScore)
                                  ?.map((dct, indx) => (
                                    <li key={`dct-${indx}`} className={classes.dctBox}>
                                      <p className={classes.question}>
                                        {dct.dctName} - Score: {dct.finalScore}
                                      </p>
                                      <div className={classes.qnaBox}>
                                        <ul>
                                          {dct.responses?.map((ans, i) => (
                                            <li key={`ans-${i}`} className={classes.answer}>
                                              {ans}
                                            </li>
                                          ))}
                                        </ul>
                                      </div>
                                    </li>
                                  ))}
                              </ul>
                            </p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>,
                );
                setRenderTableToDownload(
                  <table id="table_to_xls" hidden>
                    <thead>
                      <tr>
                        <th>Patient</th>
                        <th>Chatbot name</th>
                        <th>Completion status</th>
                        <th>Completion date</th>
                        <th>Score(s)</th>
                      </tr>
                    </thead>
                    <tbody>
                      {res.data?.map((item, ind) => (
                        <tr key={`chatbot-${ind}`}>
                          <td>
                            {patientResponse.data?.firstName || patientResponse.data?.lastName
                              ? `${patientResponse.data?.firstName || ''} ${
                                  patientResponse.data?.lastName || ''
                                }`
                              : patientResponse.data?.fullName ?? ''}{' '}
                            {patientResponse.data?.dob
                              ? dayjs(patientResponse.data?.dob).format('MM/DD/YYYY')
                              : ''}
                          </td>
                          <td>{item.chatbotName}</td>
                          <td>{item.completed ? 'Completed' : 'In progress'}</td>
                          <td>{item.completedAt ? dayjs(item.completedAt).format('MM/DD/YYYY') : ''}</td>
                          {item.dcts?.map((dct, i) => (
                            <td key={`dct-${i}`}>
                              {dct.dctName}: {dct.finalScore}
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>,
                );
                return;
              }
              setRenderTableToDownload('');
            })
            .catch(err => {
              console.log(err);
              setIsExportDisabled(true);
              console.warn(err);
            });
        })
        .catch(err => {
          console.log(err);
          setIsExportDisabled(true);
          console.log(err);
        });
    }
  }, [showAssignChatbot, selectedChatbot]);
  const { onDownload } = useDownloadExcel({
    fileName: 'chatbots',
    table: 'table_to_xls',
    sheet: 'sheet 1',
  });
  const downloadChatbots = () => {
    setIsLoadingExport(true);
    onDownload();
    setIsLoadingExport(false);
  };
  const resetOverflow = () => {
    const currentComponent = componentRef.current;
    currentComponent.style.overflow = 'scroll';
    setOpenTableToPrintModal(false);
  };
  return (
    <>
      <Modal open={openTableToPrintModal} onClose={closeTableToPrint}>
        <Box className={classes.modal}>
          <Box className={classes.modalContent}>
            <Box className={classes.content}>
              <Box className={classes.sectionAutoSize}>{renderTableToPrint}</Box>
              <Box display="flex" alignItems="center" gap={3} mt={3}>
                <Button
                  size={btnSize.SMALL}
                  onClick={e => {
                    handlePrint(e);
                    resetOverflow();
                  }}
                >
                  Export/Print
                </Button>
                <Button size={btnSize.SMALL} onClick={closeTableToPrint}>
                  Cancel
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
      {renderTableToDownload}
      <AssignChatbot open={showAssignChatbot} onClose={toggleAssignChatbot} fetchChatbots={fetchChatbots} />
      {selectedChatbot && chatbotAttemptHistory && (
        <PreviousCompletions
          open={showPrevCompletions}
          onClose={() => setShowPrevCompletions(!showPrevCompletions)}
          chatbot={selectedChatbot}
          isLoading={isCBAttemptHistoryLoading}
          attemptHistories={chatbotAttemptHistory}
          completeHistory={chatbotcompleteHistory}
          onClickView={contextId => onClickView(contextId)}
        />
      )}
      <ViewChatbot
        open={showViewChatbot}
        onClose={() => setShowViewChatbot(!showViewChatbot)}
        userId={memberId || participantId}
      />
      {showHeader && (
        <div className={classes.headingWithBtnWrapper}>
          <Heading className={classes.heading} level={headingLevel.XL} weight={fontWeight.BOLD}>
            Chatbots
          </Heading>
          {chatbotList?.length > 0 && (
            <Box display="flex" gap={1}>
              <IconButton
                className={classes.addBtn}
                icon="plus"
                variant={iconBtnType.PRIMARY}
                onClick={toggleAssignChatbot}
              >
                Assign chatbot
              </IconButton>
              {isAdmin && (
                <IconButton
                  icon="export"
                  variant={btnType.PRIMARY}
                  className={classes.addBtn}
                  disabled={isLoadingExport || isExportDisabled}
                  onClick={downloadChatbots}
                >
                  {isLoadingExport ? 'Loading' : 'XLS'}
                </IconButton>
              )}
              {isAdmin && (
                <IconButton
                  icon="export"
                  variant={btnType.PRIMARY}
                  className={classes.addBtn}
                  onClick={openTableToPrint}
                  disabled={isLoadingExport || isExportDisabled}
                >
                  Export
                </IconButton>
              )}
            </Box>
          )}
        </div>
      )}

      <div className={classes.chatbotCardsWrapper}>
        {isLoading ? (
          <Box display="flex" justifyContent="center" sx={{ paddingY: 5 }}>
            <Icons className="rotate linear infinite" glyph="in-progress" color={colors.primary} />
          </Box>
        ) : (
          <>
            {chatbotList?.length > 0 ? (
              chatbotList?.map((chatbot, index) => (
                <ChatbotCard
                  key={index}
                  chatbot={chatbot}
                  onClickPrevCompletions={() => onClickPrevCompletions(chatbot)}
                  onClickChatbot={() => onClickChatbot(chatbot.latestContextId)}
                />
              ))
            ) : (
              <EmptyState
                title="No chatbots have been assigned to this member yet."
                subtitle={`${demographicDetails.nickName} doesn’t have chatbots assigned yet.
                You can assign her a chatbot by clicking the button below.`}
                btnVariant={btnType.PRIMARY}
                btnLabel="Assign chatbot"
                btnClick={toggleAssignChatbot}
                glyph="lottie-chatbot"
                isEmptyWithLottie
                lottieSize={80}
              />
            )}
          </>
        )}
      </div>
    </>
  );
};

export default Chatbots;
