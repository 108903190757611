/* eslint-disable max-len */
import cx from 'clsx';

// types
// styles
import { useStyles } from './shared/styles';

const DataManagementFilled = ({ className, color, onClick }) => {
  const classes = useStyles();
  return (
    <svg
      className={cx({
        [classes.root]: true,
        [className || '']: className,
      })}
      width="17"
      height="20"
      viewBox="0 0 17 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M8.65841 15.3417L12.6751 11.325C12.8311 11.17 12.9548 10.9856 13.0392 10.7825C13.1236 10.5794 13.1669 10.3616 13.1667 10.1417V2.50004C13.1667 2.05801 12.9912 1.63409 12.6786 1.32153C12.366 1.00897 11.9421 0.833374 11.5001 0.833374H2.33341C1.89139 0.833374 1.46746 1.00897 1.1549 1.32153C0.842343 1.63409 0.666748 2.05801 0.666748 2.50004V14.1667C0.666748 14.6087 0.842343 15.0327 1.1549 15.3452C1.46746 15.6578 1.89139 15.8334 2.33341 15.8334H7.47508C7.69501 15.8335 7.91279 15.7902 8.11589 15.7058C8.31898 15.6214 8.50337 15.4977 8.65841 15.3417ZM7.33341 10.8334C7.33341 10.6124 7.42121 10.4004 7.57749 10.2441C7.73377 10.0878 7.94573 10 8.16675 10H11.6501L7.33341 14.3167V10.8334ZM14.8334 4.16671V15.8334C14.8334 16.2754 14.6578 16.6993 14.3453 17.0119C14.0327 17.3244 13.6088 17.5 13.1667 17.5H4.00008C4.00008 17.9421 4.17568 18.366 4.48824 18.6786C4.8008 18.9911 5.22472 19.1667 5.66675 19.1667H13.1667C14.0508 19.1667 14.8986 18.8155 15.5238 18.1904C16.1489 17.5653 16.5001 16.7174 16.5001 15.8334V5.83337C16.5001 5.39135 16.3245 4.96742 16.0119 4.65486C15.6994 4.3423 15.2754 4.16671 14.8334 4.16671Z"
        fill={color}
      />
    </svg>
  );
};

export default DataManagementFilled;
