import { colors } from '../../../packages/colors';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  badge: {
    height: 22,
    borderRadius: 100,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: 160,
  },
  provider: {
    color: colors.indigo600,
    background: colors.indigo50,
  },
  supervising: {
    color: colors.green600,
    background: colors.green50,
  },
  care: {
    color: colors.orange600,
    background: colors.orange50,
  },
  coach: {
    color: colors.warning600,
    background: colors.warning50,
  },
  onlinePresenceIndicator: {
    position: 'absolute',
    width: 15,
    height: 15,
    backgroundColor: '#F9911D',
    border: '3px solid white',
    borderRadius: '50%',
    marginLeft: 28,
    marginTop: 27,
  },
  member: {
    color: colors.rose600,
    background: colors.rose50,
  },
  designation: {
    color: colors.destructive600,
    background: colors.destructive50,
  },
  time: {
    lineHeight: '16px',
    color: colors.neutral500,
  },
});
