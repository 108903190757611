export const badgeType = {
  FILLED: 'FILLED',
  OUTLINED: 'OUTLINED',
  ICON: 'ICON',
};

export const badgeStyle = {
  PRIMARY: 'PRIMARY',
  CRITICAL: 'CRITICAL',
  HIGH: 'HIGH',
  MEDIUM: 'MEDIUM',
  LOW: 'LOW',
  MISREPORTED: 'MISREPORTED',
  RESOLVED: 'RESOLVED',
  UNRELATED: 'UNRELATED',
  POSITIVE: 'POSITIVE',
  SUCCESS: 'SUCCESS',
  GREEN: 'GREEN',
  PURPLE: 'PURPLE',
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  DARK: 'DARK',
  IN_PROGRESS: 'IN_PROGRESS',
  REQUIRED: 'REQUIRED',
  PROVIDER: 'PROVIDER',
};

export const badgeSize = {
  XSMALL: 18,
  SMALL: 28,
  MEDIUM: 48,
  LARGE: 56,
};

// export interface IBadgeProps {
//   className?: string;
//   variant?: badgeType;
//   size?: badgeSize;
//   style?: badgeStyle;
//   tooltip?: string;
//   icon?: IGlyphs['glyph'];
//   children?: ReactNode;
// }
