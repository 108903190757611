import { useCallback, useEffect, useRef, useState } from 'react';
import { Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import debounce from 'debounce';
import { btnType, fontWeight, Heading, IconButton, Icons } from '../../../../../../packages';
import { MessageInput } from '../../../../chats/MessageInput';
import { sortingDesToAsc } from '../../../../../../utils/CommonUtils';
import { getProfile } from '../../../../../../redux/modules/profile/selectors';
import { getConversation } from '../../../../../../redux/modules/conversation/selectors';
import { useStyles } from './Chats.styles';
import NewUserChatCard from '../../../../chats/NewUserChatCard';
import { chatsDesignationMapping } from '../../../../chats/ChatUtils';
import { MessageThread } from '../../../../chats/MessageThread';
import { useMessageThread } from '../../../../../../hooks/useMessageThread';
import { conversationActionCreators } from '../../../../../../redux/modules/conversation';
import * as MemberService from '../../../../../../services/member/member.service';
import Loader, { LoaderEnums } from '../../../../../../packages/ui/templates/loader';
import { uuid4 } from '../../../../../../lib/sendbird';

const Chats = ({ careTeamList, appointment, onClose }) => {
  const dispatch = useDispatch();
  const classes = useStyles({});
  const containerRef = useRef(null);
  const { profile, isLoading } = useSelector(getProfile);
  const { messages } = useSelector(getConversation);
  const [showLatestBtn, setShowLatestBtn] = useState(false);
  const [careTeamChannel, setCareTeamChannel] = useState(null);
  const [chatListItemList, setChatListItemList] = useState(null);
  const [isLoadingChat, setIsLoadingChat] = useState(false);
  const { messageThread, setMessageThread, resetMessageThread } = useMessageThread();
  const sortedMessages = messages.sort(sortingDesToAsc);

  const populateChatListItems = useCallback(() => {
    if (careTeamList) {
      const updatedChatListItemList = careTeamList?.map(item => {
        return {
          userId: item?.connectionId,
          name: item.name || `${item?.firstName || ''} ${item?.lastName || ''}`,
          avatar: item?.profilePicture || '',
          role: item?.designation,
          colorCode: item?.colorCode,
          metaData: { type: item.type },
        };
      });
      setChatListItemList(updatedChatListItemList);
    }
  }, [careTeamList]);

  const populateChats = useCallback(() => {
    if (!isLoading) {
      const queryParams = {
        userId: appointment?.participantId,
        pageNumber: 0,
        type: 'care_team',
      };
      MemberService.getPaginatedConnections(queryParams)
        .then(res => {
          if (res.data?.results?.length > 0) {
            const careTeamConnection = res.data?.results?.find(item => item?.type === 'CARE_TEAM');
            setCareTeamChannel(careTeamConnection);
            populateChatListItems();
          }
          setIsLoadingChat(false);
        })
        .catch(err => {
          console.log({ err });
          setIsLoadingChat(false);
        });
    }
  }, [appointment, isLoading, populateChatListItems]);

  useEffect(() => {
    setIsLoadingChat(true);
    populateChats();
  }, [populateChats]);

  useEffect(() => {
    if (careTeamChannel) {
      dispatch(
        conversationActionCreators.initChat({
          payload: {
            channelUrl: careTeamChannel?.channelUrl,
            connection: {
              ...careTeamChannel,
              messages: careTeamChannel?.messages || [],
              nickname:
                careTeamChannel?.name ||
                `${careTeamChannel?.firstName || ''} ${careTeamChannel?.lastName || ''}`,
            },
            currentUser: {
              userId: profile?.providerId,
              nickname: profile?.fullName,
            },
          },
        }),
      );
    }
  }, [careTeamChannel, dispatch, profile]);

  /**
   * @Name handleAddMessage
   * @param msg
   * @description This method is used to handle add message functionality through redux call .
   */
  const handleAddMessage = msg => {
    const newMessage = !msg.hasFile
      ? {
          _id: uuid4(),
          parentMessageId: messageThread.messageId === 0 ? 0 : messageThread.messageId,
          message: {
            text: msg,
          },
          user: {
            userId: profile?.providerId,
            name: profile?.fullName,
            avatar: profile?.profileImage,
          },
          nickName: profile.fullName ?? 'no name',
          createdAt: new Date().getTime(),
          type: 'user',
          system: false,
          data: messageThread,
        }
      : {
          ...msg,
          parentMessageId: messageThread.messageId === 0 ? 0 : messageThread.messageId,
          nickName: profile.fullName ?? 'no name',
          data: messageThread,
        };
    dispatch(
      conversationActionCreators.sendMessage({
        payload: {
          message: newMessage,
        },
      }),
    );
    resetMessageThread();
  };

  const handleScrollToLatestMessage = useCallback(() => {
    if (containerRef?.current) {
      containerRef.current.scroll({
        top: containerRef.current.scrollHeight,
        behavior: 'smooth',
        left: 0,
      });
    }
  }, [containerRef]);

  useEffect(() => {
    handleScrollToLatestMessage();
  }, [messages, handleScrollToLatestMessage]);

  const onScrollElement = useCallback(
    debounce(() => {
      if (containerRef?.current) {
        const { scrollTop, scrollHeight, clientHeight } = containerRef.current;

        if (Number(scrollTop) + Number(clientHeight) >= scrollHeight - 5) {
          setShowLatestBtn(false);
        }

        if (Number(scrollTop) + Number(clientHeight) + 100 < scrollHeight) {
          setShowLatestBtn(true);
        }
      }
    }, 50),
    [containerRef?.current],
  );

  const getConnectionDetailsByUserId = userId => {
    return chatListItemList?.find(item => item?.connectionId === userId);
  };

  const getDesignation = designation => {
    const { icon, label, color } = chatsDesignationMapping[designation] || chatsDesignationMapping.default;
    return (
      <Box display="flex" gap="4px" alignItems="center" color={color}>
        <Icons color={color} className={classes.headerIcons} glyph={icon} />
        <Box className={classes.headerDesignationText}>{label}</Box>
      </Box>
    );
  };

  if (isLoading || isLoadingChat) {
    return (
      <Box padding="16px">
        <Loader type={LoaderEnums.CHAT_USER_CARD} numItems={12} />
      </Box>
    );
  }

  return (
    <Box>
      <Box className={classes.header}>
        <Heading weight={fontWeight.BOLD} className={classes.title}>
          Care Team Chat
        </Heading>
        <IconButton icon="close" onClick={onClose} className={classes.backBtn} />
      </Box>
      <Box className={classes.body} ref={containerRef} onScroll={onScrollElement}>
        {sortedMessages?.map(msg => (
          <NewUserChatCard
            key={msg._id}
            {...msg}
            drawerView
            messageId={msg._id}
            senderId={msg.user.userId}
            currentUserId={profile.providerId ?? meta.userId}
            currentUserImage={profile?.profileImage}
            mentionedUsers={msg.mentionedUsers}
            createdDate={msg.createdAt}
            setMessageThread={setMessageThread}
            selectedContact={getConnectionDetailsByUserId}
            prevMsg={msg.prevMsg}
            thumbnail={!msg?.user?.avatar?.includes('/sample/') ? msg.user.avatar : ''}
            colorCode={
              profile.providerId !== msg.user?.userId
                ? getConnectionDetailsByUserId(msg.user?.userId)?.colorCode
                : null
            }
            getDesignation={getDesignation}
            careTeamDetail={chatListItemList ?? []}
          />
        ))}
        {showLatestBtn && (
          <Box className={classes.latest}>
            <IconButton
              icon="arrow-left"
              className={classes.latestBtn}
              variant={btnType.PRIMARY}
              onClick={handleScrollToLatestMessage}
            >
              Latest messages
            </IconButton>
          </Box>
        )}
      </Box>
      {messageThread.messageId !== 0 && (
        <MessageThread
          messageThread={messageThread}
          className={classes.replyMsgContainer}
          onClose={resetMessageThread}
        />
      )}
      <Box className={classes.footer}>
        <MessageInput onSubmit={handleAddMessage} />
      </Box>
    </Box>
  );
};

export { Chats };
