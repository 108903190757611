import { Heading } from '../../../../../packages';
import { Box } from '@mui/material';
import clsx from 'clsx';
import ApexChart from 'react-apexcharts';

import { getChartOptions } from './StackChart.config';
import { useStyles } from './StackChart.styles';

const StackChart = ({ title, className, chart }) => {
  const classes = useStyles();
  const { series } = chart;

  return (
    <Box className={clsx(classes.card, className)}>
      <Box className={clsx(classes.head, 'chart-head')}>
        <Heading className={classes.title}>{title}</Heading>
        {/* <Box className={classes.headRight}>
          {renderMenu(selectedTime.label, times)}
          {renderMenu(selectedStatus.label, statuses)}
        </Box> */}
      </Box>
      <div className={classes.chart}>
        <ApexChart options={getChartOptions(chart)} series={series} type="bar" height={440} />
      </div>
    </Box>
  );
};

export { StackChart };
