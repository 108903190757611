import { FC, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import clsx from 'clsx';

import { Drawer, drawerType } from '../../../../../packages/ui/organisms/drawer';
import { Input, inputType, inputSize } from '../../../../../packages/ui/atoms/input';
import { iconBtnType, IconButton } from '../../../../../packages/ui/molecules/icon-button';

import LifeEventCard from '../../../member-detail/components/LifeEvents/life-event-card';
import { useStyles } from '../history-drawer/HistoryDrawer.styles';
import EmptyState from '../empty-state';

const LifeEventDrawer = ({
  open,
  onClose,
  isNoDrawer,
  isOnlyView,
  onClickCard,
  onClickNewReport,
  items = [],
  appointment,
}) => {
  const classes = useStyles();
  const [search, setSearch] = useState('');
  const [list, setList] = useState(items);

  useEffect(() => {
    const filteredList = items.filter(item => item.name.toLowerCase().includes(search.toLowerCase()));
    setList(filteredList);
  }, [search, items]);

  const renderContent = () => {
    return (
      <Box className={classes.container}>
        <Box className={classes.searchWrap}>
          <Input
            variant={inputType.SEARCH}
            className={classes.searchInput}
            placeholder="Search"
            size={inputSize.M}
            value={search}
            onChange={setSearch}
          />
          <IconButton
            variant={iconBtnType.PRIMARY}
            className={classes.reportNewBtn}
            onClick={onClickNewReport}
          >
            Report new
          </IconButton>
        </Box>
        <Box display="flex" flexDirection="column" gap="16px" className={classes.items}>
          {list.length > 0 ? (
            list.map((item, index) => (
              <LifeEventCard
                key={index}
                item={item}
                showNotes={false}
                isOnlyView={isOnlyView}
                onClick={onClickCard}
              />
            ))
          ) : (
            <EmptyState
              title={`No Life Events reported for ${appointment.participantName || 'this member'} yet.`}
              subtitle={`If you've spoken with them about
              something that's recent happened that
              the team should know about it, record it here.`}
              glyph="face-talking-celebrating"
            />
          )}
        </Box>
      </Box>
    );
  };

  if (isNoDrawer) {
    return renderContent();
  }

  return (
    <Drawer
      open={open}
      title="Life events"
      subTitle={`${list.length} total`}
      onClose={onClose}
      variant={drawerType.FORM}
      className={clsx(classes.drawer, classes.drawerWithSubtitle)}
      footer={<span />}
    >
      {renderContent()}
    </Drawer>
  );
};

export { LifeEventDrawer };
